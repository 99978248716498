import React from "react";
import { TILE_EDITOR_MENUS } from "../../photoTilesUtils";
import PhotoTilesColorEditor from "../PhotoTilesColorEditor";
import PhotoTilesBorderEditor from "../PhotoTilesBorderEditor";
import PhotoTilesBorderColourEditor from "../PhotoTilesBorderColourEditor";

export function PhotoTilesFooterEditors({ activeMenu }) {
  if (activeMenu === TILE_EDITOR_MENUS.CROP) {
    return (
      <div
        className="editor__tool"
        style={{
          backgroundColor: "white",
          paddingTop: 20,
          paddingBottom: 20,
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        <small>Tap image to crop and rotate</small>
      </div>
    );
  }

  if (activeMenu === TILE_EDITOR_MENUS.COLOURS) {
    return (
      <div
        className="editor__tool"
        style={{
          backgroundColor: "white",
          paddingTop: 20,
          paddingBottom: 20,
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        <PhotoTilesBorderColourEditor />
      </div>
    );
  }

  if (activeMenu === TILE_EDITOR_MENUS.EFFECTS) {
    return (
      <div
        className="editor__tool"
        style={{
          backgroundColor: "white",
          paddingTop: 20,
          paddingBottom: 20,
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        <PhotoTilesColorEditor />
      </div>
    );
  }

  if (activeMenu === TILE_EDITOR_MENUS.BORDER) {
    return (
      <div
        className="editor__tool"
        style={{
          backgroundColor: "white",
          paddingTop: 20,
          paddingBottom: 20,
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        <PhotoTilesBorderEditor />
      </div>
    );
  }

  return null;
}
