import React, { Component } from "react";
import PhotoPrintPreview from "../../components/PhotoPrintPreview/PhotoPrintPreview";
import PhotoMagazinePreview from "../../components/PhotoMagazinePreview/PhotoMagazinePreview";
import GreetingCardPreview from "../../components/GreetingCardPreview/GreetingCardPreview";
import PostcardPreview from "../../components/PostcardPreview/PostcardPreview";
import AnnouncementPreview from "../../components/AnnouncementPreview/AnnouncementPreview";
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";
import MASKS from "../../constants/masks";
import * as LAYER_TYPES from "../../constants/layer-types";
import { Progress } from "antd";
import { WarningTwoTone } from "@ant-design/icons";

import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";

import { BounceLoader } from "react-spinners";
import { PhotoTilesPreviewComponent2 } from "../../components/PhotoTilePreview/PhotoTilesPreviewComponent2";
import { getTileWidthFromContainerWidth } from "../PhotoTileEditor/photoTilesUtils";

class ProductApproval extends Component {
  // componentDidMount(){
  //   if(!!this.props.itemsToApprove || this.props.itemsToApprove.length === 0){
  //     this.props.onDone();
  //   }
  // }

  constructor(props) {
    super(props);
    console.log("window.innerWidth", window.innerWidth);
    this.state = {
      showProgressBar: this.props.itemsToApprove.size > 1,
      approvalMetaData: {
        canCompleteApproval: true,
      },
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemsToApprove.size > 0) {
      const itemToApprove = this.props.itemsToApprove.first();

      if (itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
        if (
          this.state.approvalMetaData.canCompleteApproval &&
          !this.state.approvalMetaData.pageObject
        ) {
          this.setState({
            approvalMetaData: {
              canCompleteApproval: false,
              pageObject: null,
            },
          });
        }
      } else if (
        [
          PRODUCT_TYPE_IDS.GREETING_CARD,
          PRODUCT_TYPE_IDS.POSTCARD,
          PRODUCT_TYPE_IDS.ANNOUNCEMENT,
        ].includes(itemToApprove.get("productTypeId"))
      ) {
        console.log("canCompleteApproval", this.state.approvalMetaData.canCompleteApproval);
        console.log("hasSeenAll", this.state.approvalMetaData.hasSeenAllPages);
        if (
          this.state.approvalMetaData.canCompleteApproval &&
          !this.state.approvalMetaData.hasSeenAllPages
        ) {
          console.log("Can't complete approval");
          this.setState({
            approvalMetaData: {
              canCompleteApproval: false,
            },
          });
        }
      } else if (!this.state.approvalMetaData.canCompleteApproval) {
        console.log("Can complete approval");
        this.setState({
          approvalMetaData: {
            canCompleteApproval: true,
          },
        });
      }
    }
  }

  handleApprove = itemId => {
    console.log("Approved", itemId);
  };

  handleChangeMagazineApprovalPage = pageObject => {
    //console.log("pageObject", pageObject);
    this.setState({
      approvalMetaData: {
        ...this.state.approvalMetaData,
        canCompleteApproval:
          this.state.canCompleteApproval === true ? true : pageObject.lastPage === true,
        pageObject: pageObject,
      },
    });
  };

  handleChangeProductApprovalPage = (hasSeenAllPages, pageNumber) => {
    // console.log("hasSeenAllPages", hasSeenAllPages);
    // console.log("pageNumber", pageNumber);
    // console.log("state.canCompleteApproval", this.state.canCompleteApproval);
    // console.log("set canCompleteApproval to", this.state.canCompleteApproval === true ? true : hasSeenAllPages === true)
    this.setState({
      approvalMetaData: {
        ...this.state.approvalMetaData,
        canCompleteApproval:
          this.state.canCompleteApproval === true ? true : hasSeenAllPages === true,
        hasSeenAllPages: hasSeenAllPages,
      },
    });
  };

  render() {
    const { itemsToApprove } = this.props;

    const itemToApprove = itemsToApprove.first();

    const areAnyItemsRendering = itemsToApprove.some(i => i.get("isRendering"));
    const rendersDone = itemsToApprove.count(item => {
      return item.get("isRendering") === false && item.get("preview_s3_key") != null;
    });

    const percentRendered =
      itemsToApprove && (rendersDone * (100 / itemsToApprove.size)).toFixed(2);
    const loadingSpinner = <BounceLoader color="#4b5566" size={30} />;

    const showProgressBar = this.state.showProgressBar;

    // const remainingToApprove = (
    //   <p
    //     className="text-center"
    //     style={{visibility: itemsToApprove.size === 1 ? 'hidden' : 'visible' }}
    //   >
    //     <span className="text-primary">{itemsToApprove.size}</span> items left to approve
    //   </p>
    // )

    const loadingText =
      itemsToApprove.size === 1
        ? "Preparing a preview for you to approve... This may take a few moments"
        : "Preparing previews for you to approve...";

    let pageCount = productsByProductId.get(itemToApprove.get("productId")).get("pages");
    if (PRODUCT_TYPE_IDS.PHOTO_MAGAZINE === itemToApprove.get("productTypeId")) {
      pageCount = itemToApprove
        .get("layers")
        .filter(
          layer =>
            layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
        ).size;
    }
    //const isCanvas = itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
    //const productSlug = isCanvas && productsByProductId.get(itemToApprove.get("productId")).get("url_slug");

    const isSinglePageWallArt = [PRODUCT_TYPE_IDS.CANVAS, PRODUCT_TYPE_IDS.PHOTO_TILE].includes(
      itemToApprove.get("productTypeId")
    );
    const product = productsByProductId.get(itemToApprove.get("productId"));
    const productSlug = isSinglePageWallArt && product.get("url_slug");
    const maskedProduct = isSinglePageWallArt;
    const bleedMasks = MASKS[isSinglePageWallArt ? productSlug : product.get("appKey")];

    let approvalComponent = null;

    let availableWidth = this.state.width * 0.8;
    availableWidth = Math.min(availableWidth, 500);

    switch (itemToApprove.get("productTypeId")) {
      case PRODUCT_TYPE_IDS.CANVAS:
        // approvalComponent = (
        //   <React.Fragment>
        //     <ThreeDimensionalViewer
        //       model={MODELS[productSlug] || MODELS.canvas}
        //       image={itemToApprove.get("preview_s3_key")}
        //     />
        //     <p className="help-text text-center" style={{ zIndex: 1 }}>
        //       This preview is for illustrative purposes only. The actual product may differ
        //       slightly from what is shown here.
        //     </p>
        //     <p className="help-text text-center" style={{ zIndex: 1 }}>
        //       Please tap below to confirm that you have triple-checked your product. All sales are
        //       final. Please note that we do not check orders before printing and shipping.
        //     </p>
        //   </React.Fragment>
        // );
        approvalComponent = (
          <React.Fragment>
            <div>
              <HtmlRenderer
                page={0}
                item={itemToApprove.toJS()}
                isInteractive={false}
                previewMode={true}
                width={availableWidth}
                mask={maskedProduct && bleedMasks && bleedMasks.canvasPreview}
              />
            </div>
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.PHOTO_PRINT:
        approvalComponent = (
          <React.Fragment>
            <PhotoPrintPreview item={itemToApprove} />
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
        approvalComponent = (
          <React.Fragment>
            <PhotoMagazinePreview
              item={itemToApprove}
              pageCount={pageCount}
              onChangePage={this.handleChangeMagazineApprovalPage}
            />
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.GREETING_CARD:
        approvalComponent = (
          <React.Fragment>
            <GreetingCardPreview
              item={itemToApprove}
              pageCount={pageCount}
              onChangePage={(hasSeenAllPages, pageNumber) =>
                this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
              }
            />
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.POSTCARD:
        approvalComponent = (
          <React.Fragment>
            <PostcardPreview
              item={itemToApprove}
              pageCount={pageCount}
              onChangePage={(hasSeenAllPages, pageNumber) =>
                this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
              }
            />
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
        approvalComponent = (
          <React.Fragment>
            <AnnouncementPreview
              item={itemToApprove}
              pageCount={pageCount}
              onChangePage={(hasSeenAllPages, pageNumber) =>
                this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
              }
            />
          </React.Fragment>
        );
        break;
      case PRODUCT_TYPE_IDS.PHOTO_TILE:
        approvalComponent = (
          // must be done 370 or width container
          <PhotoTilesPreviewComponent2
            itemToApprove={itemToApprove.toJS()}
            width={getTileWidthFromContainerWidth(window.innerWidth)}
          />
        );
        break;
      default:
        break;
    }

    const approvalContainerHeight =
      itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS ? "auto" : "370px"; //auto

    return (
      <React.Fragment>
        {itemsToApprove && areAnyItemsRendering ? (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
                flexDirection: "column",
              }}
            >
              {loadingSpinner}
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                {loadingText}
              </h2>
              {showProgressBar && (
                <div style={{ width: 170 }}>
                  <Progress
                    size="small"
                    percent={percentRendered}
                    width={50}
                    status="active"
                    strokeWidth={4}
                    strokeColor={"#FD5C63"}
                    showInfo={false}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE ? (
              <div
                className="photo-print-previews-container"
                style={{
                  padding: "2px 0px",
                  width: "85vw",
                  maxWidth: "650px",
                  display: "flex",
                  alignItems: "center",
                  height: "auto", //approvalContainerHeight 'auto'
                }}
              >
                {approvalComponent}
              </div>
            ) : itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS ? (
              <div>{approvalComponent}</div>
            ) : (
              <>
                <div
                  className="photo-print-previews-container"
                  style={{
                    padding: "2px 0px",
                    width: "400px",
                    display: "flex",
                    alignItems: itemToApprove.get("isLowRes") ? "flex-start" : "center",
                    height: approvalContainerHeight,
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {approvalComponent}
                  {itemToApprove.get("isLowRes") && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        marginTop: "5px",
                        background: "#ff00000a",
                        padding: "7px",
                        border: "1px solid red",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          display: "block",
                          fontWeight: 500,
                          color: "#ff5a5f",
                        }}
                      >
                        <span>
                          <WarningTwoTone
                            style={{ color: "#ff5a5f", fontSize: "18px" }}
                            twoToneColor="#ff5a5f"
                          />
                          <span style={{ marginLeft: "5px" }}>LOW RESOLUTION WARNING!</span>
                        </span>
                        <br />
                        <span>
                          This photo may be pixelated or grainy when printed. For best results try
                          using a higher resolution photo.
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default ProductApproval;
