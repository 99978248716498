import React from "react";
import { Button, Col, Row, Modal as AntModal } from "antd";
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";

const TextEditingTools = ({
  setFontModalOpen,
  setFontSizeSliderModalOpen,
  forCover,
  isEditingCoverPage,
  setFontColourModalOpen,
  handleTextAlignmentChange,
  handleTextHorizontalAlignmentChange,
  selectedAlignment,
  selectedVerticalAlignment,
  handleTextVerticalAlignmentChange,
  subText,
  renderEdit
  }) => {
    console.log("renderEdit", renderEdit)
    const activeText = subText ? "subtext" : "text"
    return (
      <div
        className="actions"
        style={{marginRight: 0, width: '100%', maxWidth: '570px', padding: '10px 5px', marginBottom: '20px'}}
      >
        <span className="actions__help-title">Text Editing Tools</span>
        {true && ( //!forCover
          <Row
            style={{
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
              justifyContent: "space-evenly",
            }}
          >
            <Col>
              <div
                className="optionGroup"
                style={{ fontWeight: "bold" }}
              >
                <Button size="large" onClick={() => setFontModalOpen(true)}>
                  <span style={{ fontSize: "21px", marginTop: '-3px'}}>Aa</span>
                </Button>
                <Button size="large" onClick={() => setFontSizeSliderModalOpen(true)}>
                  <FontSizeOutlined style={{ fontSize: "22px", marginTop: '2px'}}/>
                </Button>
                {((forCover || isEditingCoverPage) || renderEdit) && (
                  <>
                    <Button size="large" onClick={() => setFontColourModalOpen(true)}>
                      <img
                        className="colour-icon"
                        alt="colours"
                        src={`${process.env.PUBLIC_URL}/images/text-colours.png`}
                        style={{width: '28px'}}
                      />
                    </Button>
                  </>
                )}
            
                <Button
                  icon={<AlignLeftOutlined />}
                  size="large"
                  style={{ marginRight: '-8px'}}
                  type={selectedAlignment === "left" ? "primary" : "default"}
                  onClick={() => {
                    handleTextAlignmentChange(activeText,"left");
                    handleTextHorizontalAlignmentChange(activeText,"left");
                  }}
                />
                <Button
                  size="large"
                  style={{ marginRight: '-8px'}}
                  icon={<AlignCenterOutlined />}
                  type={selectedAlignment === "center" ? "primary" : "default"}
                  onClick={() => {
                    handleTextAlignmentChange(activeText, "center");
                    handleTextHorizontalAlignmentChange(activeText, "center");
                  }}
                />
                <Button
                  size="large"
                  icon={<AlignRightOutlined />}
                  type={selectedAlignment === "right" ? "primary" : "default"}
                  onClick={() => {
                    handleTextAlignmentChange(activeText, "right");
                    handleTextHorizontalAlignmentChange(activeText, "right");
                  }}
                />
              </div>
            </Col>
            {!isEditingCoverPage && !forCover && (
              <Col>
                <div className="optionGroup">
                  <Button
                    size="large"
                    type={selectedVerticalAlignment === "top" ? "primary" : "default"}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={() => handleTextVerticalAlignmentChange("top")}
                  />
                  <Button
                    size="large"
                    type={selectedVerticalAlignment === "center" ? "primary" : "default"}
                    icon={<VerticalAlignMiddleOutlined />}
                    onClick={() => handleTextVerticalAlignmentChange("center")}
                  />
                  <Button
                    size="large"
                    type={selectedVerticalAlignment === "bottom" ? "primary" : "default"}
                    icon={<VerticalAlignBottomOutlined />}
                    onClick={() => handleTextVerticalAlignmentChange("bottom")}
                  />
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
    );
};
export default TextEditingTools;
