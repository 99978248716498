import React, { useEffect, useState } from "react";
import {
  addTile,
  deleteTileAtIndex,
  FRAME_COLORS,
  getEmptyTile,
  MAIN_VIEW_MODES,
  photoTilesActions,
  photoTilesSelectors,
} from "../../../store/ducks/ui-photo-tiles";
import {
  confirmBeforeNavAway,
  findNextTileToEdit,
  getFrameColorFromUrl,
  getItemDataForPack,
  getItemDataSinglePack,
} from "../photoTilesUtils";
import { fromJS, Map } from "immutable";
import { connect } from "react-redux";
import { getConfigurationFromTile, scaleConfiguration } from "./TilePreview/tilePreviewCalculator";
import { TilePreview } from "./TilePreview/TilePreview";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import Footer from "../../../components/Footer/Footer";
import Grid from "../../../components/Grid/Grid";
import { actions as basketActions } from "../../../store/ducks/basket";
import { productsByProductId } from "../../../data/products";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import Icon from "../../../components/Icon/Icon";
import { Popconfirm } from "antd";

function PhotoTilesPrebag({
  tiles,
  product,
  setTileBeingEditedIndex,
  setTiles,
  setMainViewMode,
  openFilesModal,
  addPendingApprovalItem,
  navigateToApproval,
}) {
  const [confirmVisible, setConfirmVisible] = useState(() =>
    new Array(product.photo_tile_pack_quantity).fill(false)
  );

  const updateConfirmVisible = (index, value) => {
    setConfirmVisible(confirmVisible => {
      const newConfirmVisible = [...confirmVisible];
      newConfirmVisible[index] = value;
      return newConfirmVisible;
    });
  };

  const onEditTileClick = index => {
    setTileBeingEditedIndex(index);
    setMainViewMode(MAIN_VIEW_MODES.EDITOR);
  };

  const onDeleteTile = index => {
    updateConfirmVisible(index, true);
    // const newList = deleteTileAtIndex(tiles, index);
    // setTiles(newList);
  };

  const onAddClick = () => {
    const frameColor = getFrameColorFromUrl();
    const missingTiles = product.photo_tile_pack_quantity - tiles.length;

    let newList = tiles;

    for (let i = 0; i < missingTiles; i++) {
      newList = addTile(newList, getEmptyTile(frameColor));
    }
    setTiles(newList);

    const firstTileWithNoImage = newList.findIndex(tile => !tile.image);

    setTileBeingEditedIndex(firstTileWithNoImage);
    setMainViewMode(MAIN_VIEW_MODES.EDITOR);
    openFilesModal(missingTiles);
  };

  const shouldAddTileBeDisabled = () => {
    return product.photo_tile_pack_quantity > 1 && tiles.length >= product.photo_tile_pack_quantity;
  };

  const shouldPreviewBeDisabled = () => {
    if (product.photo_tile_pack_quantity > 1 && tiles.length !== product.photo_tile_pack_quantity)
      return true;

    // single quantity
    return tiles.length === 0;
  };

  const onPreviewClick = async () => {
    const itemDataList = [];
    if (product.photo_tile_pack_quantity > 1) {
      const packItemData = await getItemDataForPack(tiles, product);
      itemDataList.push(packItemData);
    } else {
      for (const tile of tiles) {
        const itemData = await getItemDataSinglePack(tile, product);
        itemDataList.push(itemData);
      }
    }
    itemDataList.forEach(itemData => {
      addPendingApprovalItem(itemData.toJS());
    });
    navigateToApproval();
    // const itemData = await getRenderData(tiles[0]);
    // console.log(itemData.toJS());
  };

  const [itemData, setItemData] = useState([]);
  useEffect(() => {
    const fetchItemData = async () => {
      const newMap = [];
      for (const tile of tiles) {
        const r = await getItemDataSinglePack(tile, product);
        newMap.push([tile.id, r.toJS()]);
      }
      setItemData(newMap);
    };

    fetchItemData();
  }, [tiles, product]);

  const onConfirm = index => {
    const newList = deleteTileAtIndex(tiles, index);
    setTiles(newList);

    updateConfirmVisible(index, false);
  };

  const onCancel = index => {
    updateConfirmVisible(index, false);
  };

  return (
    <div style={{ overflow: "auto", height: "100dvh", display: "flex", flexDirection: "column" }}>
      <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}>
        <Header
          title="Photo Tiles"
          leftAction={
            <Button
              theme="muted"
              priority="tertiary"
              label="Cancel"
              onClick={confirmBeforeNavAway}
            />
          }
        />
      </div>
      <div style={{ flex: 1, overflowY: "scroll" }}>
        <div
          style={{
            textAlign: "center",
            marginBottom: 5,
            fontSize: 14,
            marginTop: 32,
          }}
        >
          Pack of {product.photo_tile_pack_quantity}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            whiteSpace: "nowrap",
            overflowX: "auto",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {tiles.length > 0 &&
            tiles.map((tile, i) => {
              const configuration = getConfigurationFromTile(tile);
              const withFrame = {
                ...configuration,
                withFrame: product?.photo_tile_framed,
                frameColor: getFrameColorFromUrl(),
              };

              const scaledConfiguration = scaleConfiguration(withFrame, 200);

              const itemDataItem = itemData.find(([id]) => id === tile.id);

              return (
                <div
                  key={tile.id}
                  style={{
                    margin: "20px",
                    position: "relative",
                  }}
                >
                  <TilePreview
                    configuration={scaledConfiguration}
                    imageUrl={tile?.image?.lowResUrl}
                    onClick={() => onEditTileClick(i)}
                    cropData={tile?.crop}
                    itemData={itemDataItem?.[1]}
                    width={scaledConfiguration.width}
                  />
                  <Popconfirm
                    style={{ marginRight: "10px" }}
                    visible={confirmVisible[i]}
                    title={
                      <div style={{ width: "40vw", maxWidth: "200px" }}>Delete this tile?</div>
                    }
                    onConfirm={() => onConfirm(i)}
                    onCancel={() => onCancel(i)}
                    okButtonProps={{ style: { height: "32px" } }}
                    cancelButtonProps={{ style: { height: "32px" } }}
                    okText="Yes"
                    cancelText="No"
                    icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                    placement="left"
                  >
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 28, color: "red", opacity: "0.5", display: "block" }}
                        onClick={() => onDeleteTile(i)}
                      />
                      <div
                        style={{ marginLeft: 20, marginTop: 3, opacity: 0.8 }}
                        onClick={() => onEditTileClick(i)}
                      >
                        <Icon name="edit" size="large" />
                      </div>
                    </div>
                  </Popconfirm>
                </div>
              );
            })}
        </div>
      </div>
      <Footer padded topShadow style={{ borderTop: "1px solid #ededed" }}>
        <Grid.Row>
          <Grid.Column>
            <div style={{ paddingRight: "0.5rem" }}>
              <Button
                block
                theme="dark-blue"
                priority="secondary"
                label={"Add Tile"}
                onClick={onAddClick}
                disabled={shouldAddTileBeDisabled()}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.5rem" }}>
              <Button
                label="Preview"
                block
                theme="dark-blue"
                onClick={() => {
                  onPreviewClick({ tiles });
                }}
                disabled={shouldPreviewBeDisabled()}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Footer>
    </div>
  );
}

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  product: photoTilesSelectors.getProduct(state),
});

const mapDispatchToProps = dispatch => ({
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), { fromPreBag: true })),
  setTileBeingEditedIndex: index => dispatch(photoTilesActions.setTileBeingEditedIndex(index)),
  setMainViewMode: mode => dispatch(photoTilesActions.setMainViewMode(mode)),
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
  openFilesModal: (amount = 1) => dispatch(photoTilesActions.setShowFilesModal(true, amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesPrebag);
