module.exports = {
  1: `
    🟥 🟦
    🟥 🟦
  `,
  2: `
    🟥 🟥
    🟦 🟦
  `,
  4: `
    🟥 🟥 🟥
    🟥 🟥 🟥
    🟦 🟦 🟦
  `,
  5: `
    🟥 🟦
    🟥 🟪
  `,
  6: `
    🟥 🟦
    🟪 🟦
  `,
  7: `
    🟥 🟥
    🟦 🟪
  `,
  8: `
    🟥 🟦
    🟪 🟪
  `,
  10: `
    🟥 🟥 🟥
    🟦 🟦 🟦
    🟪 🟪 🟪
  `,
  12: `
    🟥 🟥 🟦
    🟥 🟥 🟦
    🟪 🟪 🟪
  `,
  16: `
    🟥 🟥 🟥
    🟦 🟪 🟪
    🟦 🟪 🟪
  `,
  18: `
    🟥 🟦
    🟪 🟧
  `,
  21: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟪 🟪 🟪
    🟥 🟥 🟥 🟪 🟪 🟪
    🟥 🟥 🟥 🟧 🟧 🟧
    🟥 🟥 🟥 🟧 🟧 🟧
  `,
  22: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟦 🟦 🟦
    🟪 🟪 🟪 🟦 🟦 🟦
    🟧 🟧 🟧 🟦 🟦 🟦
    🟧 🟧 🟧 🟦 🟦 🟦
  `,
  23: `
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟪 🟪 🟧 🟧
    🟦 🟦 🟪 🟪 🟧 🟧
    🟦 🟦 🟪 🟪 🟧 🟧
  `,
  24: `
    🟦 🟦 🟪 🟪 🟧 🟧
    🟦 🟦 🟪 🟪 🟧 🟧
    🟦 🟦 🟪 🟪 🟧 🟧
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
  `,
  26: `
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟪 🟪 🟪
    🟦 🟦 🟦 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 🟧 🟧
    🟧 🟧 🟧 🟧 🟧 🟧
  `,
  27: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟪 🟪
    🟪 🟪 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 🟧 🟧
    🟧 🟧 🟧 🟧 🟧 🟧
  `,
  28: `
    🟧 🟧 🟧 🟧 🟧 🟧
    🟧 🟧 🟧 🟧 🟧 🟧
    🟪 🟪 🟪 🟪 🟪 🟪
    🟪 🟪 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
  `,
  31: `
    🟥 🟥 🟥 🟥 🟦 🟦
    🟥 🟥 🟥 🟥 🟦 🟦
    🟥 🟥 🟥 🟥 🟦 🟦
    🟪 🟪 🟧 🟧 🟧 🟧
    🟪 🟪 🟧 🟧 🟧 🟧
    🟪 🟪 🟧 🟧 🟧 🟧
  `,
  32: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟪 🟪 🟪
    🟥 🟥 🟥 🟪 🟪 🟪
    🟧 🟧 🟧 🟪 🟪 🟪
    🟧 🟧 🟧 🟪 🟪 🟪
  `,
  40: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟪 🟪
    🟪 🟪 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  41: `
    🟪 🟪 🟪 🟪 🟪 🟪
    🟪 🟪 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  42: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟪 🟪 🟪 🟪 🟪 🟪
    🟪 🟪 🟪 🟪 🟪 🟪
  `,
  43: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟪 🟪 🟪
    🟧 🟧 🟧 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  44: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟦 🟦 🟦
    🟪 🟪 🟪 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  45: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟦 🟦 🟦
    🟪 🟪 🟪 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  46: `
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
  `,
  47: `
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
  `,
  48: `
    🟥 🟥 🟥
    🟦 🟦 🟦
    🟪 🟧 ⬛️
  `,
  49: `
    🟥 🟦 🟪
    🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️
  `,
  52: `
    🟥 🟥 🟦 🟦
    🟥 🟥 🟪 🟪
    🟥 🟥 🟧 🟧
    🟥 🟥 ⬛️ ⬛️
  `,
  53: `
    🟥 🟥 ⬛️ ⬛️
    🟦 🟦 ⬛️ ⬛️
    🟪 🟪 ⬛️ ⬛️
    🟧 🟧 ⬛️ ⬛️
  `,
  55: `
    🟥 🟥 🟥
    🟦 🟪 🟧
    ⬛️ ⬛️ ⬛️
  `,
  58: `
    🟥 🟥 🟦
    🟪 🟪 🟪
    🟧 ⬛️ ⬛️
  `,
  59: `
    🟥 🟥 🟦
    🟥 🟥 🟪
    🟧 🟧 ⬛️
  `,
  61: `
    🟥 🟦 🟦
    🟪 ⬛️ ⬛️
    🟧 ⬛️ ⬛️
  `,
  62: `
    🟥 🟦 🟪
    🟧 🟧 ⬛️
    🟧 🟧 ⬛️
  `,
  63: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟪 🟧 🟧 🟧
    🟪 🟪 🟪 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
  `,
  64: `
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
  `,
  65: `
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟪 🟪 🟪
    🟦 🟦 🟦 🟪 🟪 🟪
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
  `,
  66: `
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
    🟧 🟧 ⬛️ ⬛️ 🟨 🟨
    🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟪 🟪 🟪
    🟦 🟦 🟦 🟪 🟪 🟪
  `,
  69: `
    🟥 🟥 🟦
    🟥 🟥 🟪
    🟨 ⬛️ 🟧
  `,
  70: `
    🟥 🟨 🟨
    🟦 🟨 🟨
    🟪 🟧 ⬛️
  `,
  71: `
    🟥 🟦 🟪
    🟧 🟨 🟨
    ⬛️ 🟨 🟨
  `,
  77: `
    🟥 🟥 🟥 🟦 🟦 🟦
    🟥 🟥 🟥 🟦 🟦 🟦
    🟪 🟪 🟧 🟧 ⬛️ ⬛️
    🟪 🟪 🟧 🟧 ⬛️ ⬛️
    🟨 🟨 🟨 🟩 🟩 🟩
    🟨 🟨 🟨 🟩 🟩 🟩
  `,
  78: `
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟧 🟧 🟪 🟪
    ⬛️ ⬛️ 🟧 🟧 🟩 🟩
    ⬛️ ⬛️ 🟨 🟨 🟩 🟩
    ⬛️ ⬛️ 🟨 🟨 🟩 🟩
  `,
  80: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧 🟧 🟧
    🟪 🟪 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
  `,
  82: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟧 🟧
    🟩 🟩 🟩 🟩 🟩 🟩 🟧 🟧 🟧 🟧 🟧 🟧
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 🟨 🟨
  `,
  83: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
  `,
  84: `
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
  `,
  86: `
    🟥 🟦 🟪
    🟧 🟧 🟧
    ⬛️ 🟨 🟩
  `,
  91: `
    🟥 🟥 🟥 🟦
    🟥 🟥 🟥 🟪
    🟥 🟥 🟥 🟧
    ⬛️ 🟨 🟩 ⬜️
  `,
  92: `
    🟥 ⬜️ ⬜️ ⬜️
    🟦 ⬜️ ⬜️ ⬜️
    🟪 ⬜️ ⬜️ ⬜️
    🟧 ⬛️ 🟨 🟩
  `,
  93: `
    🟥 🟦 🟪 🟧
    ⬛️ ⬜️ ⬜️ ⬜️
    🟨 ⬜️ ⬜️ ⬜️
    🟩 ⬜️ ⬜️ ⬜️
  `,
  94: `
    🟥 🟦 🟪 🟧
    ⬜️ ⬜️ ⬜️ ⬛️
    ⬜️ ⬜️ ⬜️ 🟨
    ⬜️ ⬜️ ⬜️ 🟩
  `,
  95: `
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️
    🟨 🟨 🟩 🟩 ⬜️ ⬜️
    🟨 🟨 🟩 🟩 ⬜️ ⬜️
  `,
  96: `
    🟥 🟥 🟦 🟦 🟪 🟪
    🟥 🟥 🟦 🟦 🟪 🟪
    🟧 🟧 🟦 🟦 ⬛️ ⬛️
    🟧 🟧 🟩 🟩 ⬛️ ⬛️
    🟨 🟨 🟩 🟩 ⬜️ ⬜️
    🟨 🟨 🟩 🟩 ⬜️ ⬜️
  `,
  99: `
    🟥 🟥 🟦 🟦
    🟥 🟥 🟪 🟪
    🟧 🟨 🟨 🟩
    ⬛️ 🟨 🟨 ⬜️
  `,
  102: `
    🟥 🟥 🟦 🟦
    🟪 🟪 🟧 🟧
    ⬛️ ⬛️ 🟨 🟨
    🟩 🟩 ⬜️ ⬜️
  `,
  103: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
  `,
  104: `
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟪 🟪
    🟨 🟨 🟨 🟩 🟩 🟩 🟪 🟪 🟪 🟪 🟪 🟪
    ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟪 🟪 🟪 🟪 🟪 🟪
    ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟪 🟪 🟪 🟪 🟪 🟪
    ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟪 🟪 🟪 🟪 🟪 🟪
  `,
  105: `
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
    🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥
  `,
  107: `
    🟥 🟦 🟪
    🟧 ⬛️ 🟨
    🟩 ⬜️ 🟫
  `,
  108: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
  `,
  109: `
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ ⬜️ 🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩
  `,
  110: `
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
  `,
  111: `
    🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥
    🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥
    🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥
    🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧 🟥 🟥 🟥 🟥
    🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️
    🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️
    🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ ⬛️
    🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ ⬛️
    🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨 ⬜️ ⬜️ ⬜️ ⬜️
    🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️
    🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️
    🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️
  `,
  112: `
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
  `,
  113: `
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥
    🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥
    🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥
    🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪 ⬛️ ⬛️ ⬛️ ⬛️
    🟧 🟧 🟧 🟧 🟪 🟪 🟪 🟪 ⬛️ ⬛️ ⬛️ ⬛️
    🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️
    🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️
    🟨 🟨 🟨 🟨 🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️
    🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️
    🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️
    🟫 🟫 🟫 🟫 🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️
  `,
  114: `
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
  `,
  115: `
    🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪 🟧 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ ⬛️ 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨
    ⬛️ ⬛️ ⬛️ ⬛️ 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨
    ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩 🟨 🟨 🟨 🟨
    ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩 🟫 🟫 🟫 🟫
  `,
  116: `
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
  `,
  117: `
    🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦
    🟪 🟪 🟪 🟪 🟥 🟥 🟥 🟥 🟧 🟧 🟧 🟧
    🟪 🟪 🟪 🟪 ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧
    🟪 🟪 🟪 🟪 ⬛️ ⬛️ ⬛️ ⬛️ 🟧 🟧 🟧 🟧
    🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
  `,
  118: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟪 🟪 🟪 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
  `,
  119: `
    🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪
    🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪
    🟦 🟦 🟦 🟦 🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 🟥 🟥 🟥 🟥 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪
    🟨 🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ ⬛️ 🟩 🟩 🟩 🟩
    🟨 🟨 🟨 🟨 ⬛️ ⬛️ ⬛️ ⬛️ 🟩 🟩 🟩 🟩
    🟨 🟨 🟨 🟨 ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩
    🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩
    🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩
    🟫 🟫 🟫 🟫 ⬜️ ⬜️ ⬜️ ⬜️ 🟩 🟩 🟩 🟩
  `,
  120: `
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫
  `,
  121: `
    🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪
    🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪
    🟥 🟥 🟥 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨
    🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟫 🟫 🟫
    🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫
    🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫
    🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫
  `,
  122: `
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟥 🟥 🟥 🟥 🟥 🟥 🟦 🟦 🟦 🟦 🟦 🟦 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟪 🟪 🟪 🟪
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟧 🟧 🟧 🟧 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟫 🟫
    🟩 🟩 🟩 🟩 ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ ⬜️ 🟫 🟫 🟫 🟫 🟫 🟫
  `,
  123: `
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟨 🟨 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟨 🟨 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🟨 🟨 🟨 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ 🟨 🟨 🟨 🟨 🟨 🟨 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ 🟨 🟨 🟨 🟨 🟨 🟨 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ 🟨 🟨 🟨 🟨 🟨 🟨 🟫 🟫 🟫
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
  `,
  124: `
    🟥 🟩 🟫
    🟪 🟦 ⬛️
    🅰️ 🟧 ⏹
    ⬜️ 9️⃣ 5️⃣
  `,
  130: `
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ . . . . . . 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ . . . . . . 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ . . . . . . 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ . . . . . . 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ . . . . . . 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ . . . . . . 🟫 🟫 🟫
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
  `,
  125: `
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    🟥 🟥 🟥 🟦 🟦 🟦 🟪 🟪 🟪 🟧 🟧 🟧
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🅰️ 🅰️ 🅰️ 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🅰️ 🅰️ 🅰️ 🟩 🟩 🟩
    ⬛️ ⬛️ ⬛️ 🟨 🟨 🟨 🅰️ 🅰️ 🅰️ 🟩 🟩 🟩
    ⬜️ ⬜️ ⬜️ 9️⃣ 9️⃣ 9️⃣ 5️⃣ 5️⃣ 5️⃣ 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ 9️⃣ 9️⃣ 9️⃣ 5️⃣ 5️⃣ 5️⃣ 🟫 🟫 🟫
    ⬜️ ⬜️ ⬜️ 9️⃣ 9️⃣ 9️⃣ 5️⃣ 5️⃣ 5️⃣ 🟫 🟫 🟫
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
    🔲 🔲 🔲 ✅ ✅ ✅ ⏹ ⏹ ⏹ ☑️ ☑️ ☑️
  `,
  126: `
    🟥 🟥 
    🟥 🟥 
    🟦 🟩
  `,
  127: `
    🟥 🟥 🟥
    🟥 🟥 🟥
    🟥 🟥 🟥
    🟦 🟩 🟫
  `,
  128: `
    🟥 🟥 🟩
    🟥 🟥 🟫
    🟧 🟨 🟨
    🟪 🟨 🟨
  `,
  129: `
    🟥 🟩 🟫
    🟨 🟨 🟨
    🟨 🟨 🟨
    🟪 🟦 ⬛️
  `
}
