import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";

import Button from "../Button/Button";
import Grid from "../Grid/Grid";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import moment from "moment";
import "./GreetingCardPreview.scss";

const Page = React.memo(({ item, pageNumber, debug, width, isEvelopePage }) => {
  
  const isPortraitProduct =
      item.getIn(["productDimensions", "width"]) <
      item.getIn(["productDimensions", "height"]);
  console.log("width", width);
  return (
    <div className="page">
      {debug && <div className="page__number">{pageNumber}</div>}
      <HtmlRenderer
        key={`from-page-${Date.now()}`} // hack to ensure setImagesStylesForRegions fires in HtmlRendererPhotoLayer#componentDidUpdate
        photoMagazine={false}
        showingPage={true}
        page={isEvelopePage ? "ENVELOPE" : pageNumber}
        item={item.toJS()}
        isInteractive={false}
        previewMode={true}
        width={width}
        isPortraitProduct={isPortraitProduct}
      />
      
      <div className="greeting-card__additional-info" style={{opacity: isEvelopePage ? 1 : 0, height: isEvelopePage ? 'auto': 0}}>
        <p>Shipping Date: <span style={{fontWeight: 400}}>{moment(item.get("postDate")).format("DD MMM YYYY")}</span></p>
      </div>
    </div>
  );
});

const GreetingCardPreview = forwardRef(({ item, onChangePage, pageCount }, ref) => {
  const containerRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(0);
  const renderRef = useRef(pageNumber);
  const [pageWidth, setPageWidth] = useState(90);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [isEvelopePage, setIsEvelopePage] = useState(false);
  const [hasSeenAllPages, setHasSeenAllPages] = useState(false);
  const [currentApprovalItem, setCurrentApprovalItem] = useState(null);

  useEffect(() => {
    if (currentApprovalItem !== item.get('id')){
      resetPagesForNewItem()
    }
    setCurrentApprovalItem(item.get('id'))

    const aspectRatio = item.getIn(["productDimensions", "width"]) / item.getIn(["productDimensions", "height"]);
    const isPortraitProduct = item.getIn(["productDimensions", "width"]) <
    item.getIn(["productDimensions", "height"]);
    let { width, height } = containerRef.current.getBoundingClientRect();
    let scaleFactor = isEvelopePage ? 0.8 : 0.8
    let applyWidth = width * scaleFactor;

    if (height > 0 ){
      if (isPortraitProduct && !isEvelopePage) {
        let proposedHeight = applyWidth / aspectRatio
        if (proposedHeight > height){
          applyWidth = height * aspectRatio;
        }
      }
    }
    if (width) {
      setPageWidth(applyWidth);
    }
  // eslint-disable-next-line
  }, [setPageWidth, item, isEvelopePage]);

  useEffect(() => {
    if (hasSeenAllPages){
      console.log("Has seen all", hasSeenAllPages)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  // eslint-disable-next-line
  }, [hasSeenAllPages]);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      goToNextPage();
    }
    if (touchStart - touchEnd < -150) {
      goToPreviousPage();
    }
  }

  const resetPagesForNewItem = () => {
    setPageNumber(0)
    setIsEvelopePage(false)
    setHasSeenAllPages(false)
    onChangePage(false, 0)
  }

  const goToPreviousPage = () => {
    if(pageNumber !== 0){
      setPageNumber(pageNumber-2)
      setIsEvelopePage(false)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  };

  const goToNextPage = () => {
    if(pageNumber !== 2){
      setPageNumber(pageNumber+2)
    } else if (pageNumber === 2){
      setPageNumber(pageNumber+2)
      setIsEvelopePage(true)
      setHasSeenAllPages(true)
      console.log("Has seen all pages", hasSeenAllPages)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  };

  useImperativeHandle(ref, () => ({
    goToPreviousPage,
    goToNextPage,
  }));

  return (
    <div className="greeting-card-preview">
      <div className="greeting-card-preview__render" style={{flex: 1, minHeight: '100%'}} ref={containerRef}>
        <div
          onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
          onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
          onTouchEnd={handleTouchEnd}
          style={{maxHeight: '100%'}}
          ref={renderRef}
        >
          <Page
            item={item}
            pageNumber={pageNumber}
            debug={false}
            width={pageWidth}
            isEvelopePage={isEvelopePage}
            ref={renderRef}
          />
        </div>
      </div>
      <div className="greeting-card-preview__navigation" style={{marginTop: 'auto'}}>
        {/* <p className="help-text text-center" style={{marginTop:'5px', marginBottom: '5px'}}>
          {isEvelopePage ? "Envelope" : (pageNumber === 0 ? 'Front' : 'Inside')}
        </p> */}
        <Grid.Row className="preview-nav-buttons" style={{maxWidth: '300px', margin: '0 auto', marginBottom: '0px',marginTop: '10px'}}>
          <Grid.Column>
            
              <div style={{ paddingRight: "10px" }}>
                <Button
                  block
                  size="small"
                  label="Previous"
                  theme={item.get('picCollage') ? "pc" : "dark-blue"}
                  onClick={goToPreviousPage}
                  priority={pageNumber === 0 ? "secondary" : "primary"}
                />
              </div>
            
          </Grid.Column>
          <Grid.Column>
            
              <div style={{ paddingLeft: "10px" }}>
                <Button
                  block
                  size="small"
                  label="Next"
                  theme={item.get('picCollage') ? "pc" : "dark-blue"}
                  onClick={goToNextPage}
                  priority={isEvelopePage ? "secondary" : "primary"}
                />
              </div>
            
            
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
});

export default GreetingCardPreview;
