import { connect } from "react-redux";
import ShopButton from "./ShopButton";

const mapDispatchToProps = dispatch => ({
  goToShop: () => {
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  }
});

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopButton);
