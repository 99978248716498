import React from "react";
import PropTypes from "prop-types";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererBleed extends React.Component {
  static propTypes = {
    canvasDimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    productDimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      bleed: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  static defaultProps = {};

  render() {
    let bleedElements = [];
    const productDimensions = this.props.productDimensions;
    const bleed = productDimensions.bleed;
    
    const height = (productDimensions.height + bleed.top + bleed.bottom);
    const width = (productDimensions.width + bleed.left + bleed.right);

    const relativeBleed = {
      top: bleed.top * (100.0/height),
      bottom: bleed.bottom * (100.0/height),
      right: bleed.right * (100.0/width),
      left: bleed.left * (100.0/width),
    }
    
    bleedElements = [
        <div
        key="top"
        className="html-renderer-mask__band html-renderer-mask__band--horizontal"
        style={{
            top: -2,
            height:
            `calc(${relativeBleed.top}% + 2px)`,
        }}
        />,
        <div
        key="bottom"
        className="html-renderer-mask__band html-renderer-mask__band--horizontal"
        style={{
            bottom: -3,
            height: `calc(${relativeBleed.bottom}% + 3px)`,
        }}
        />,
        <div
        key="left"
        className="html-renderer-mask__band html-renderer-mask__band--vertical"
        style={{
            left: -2,
            width: `calc(${relativeBleed.left}% + 2px)`,
        }}
        />,
        <div
        key="right"
        className="html-renderer-mask__band html-renderer-mask__band--vertical"
        style={{
            right: -2,
            width: `calc(${relativeBleed.right}% + 2px)`,
        }}
        />,
    ];
    let bleedMaskBorders = [
        <div
            key="top-bleed-border"
            className="html-renderer-bleed__band html-renderer-bleed__band--border html-renderer-bleed__band--horizontal"
            style={{
            top: -2,
            width: `calc(100% - ${relativeBleed.left+relativeBleed.right}% + 2px)`,
            height: `calc(${relativeBleed.top}% + 2px)`,
            left: `${relativeBleed.left}%`,
            borderBottomWidth: '1px',
            }}
        />,
        <div
            key="bottom-bleed-border"
            className="html-renderer-bleed__band html-renderer-bleed__band--border html-renderer-bleed__band--horizontal"
            style={{
            bottom: -2,
            width: `calc(100% - ${relativeBleed.left+relativeBleed.right}% + 2px)`,
            height: `calc(${relativeBleed.bottom}% + 2px)`,
            left: `${relativeBleed.left}%`,
            borderTopWidth: '1px',
            }}
        />,
        <div
            key="left-bleed-border"
            className="html-renderer-bleed__band html-renderer-bleed__band--border html-renderer-bleed__band--vertical"
            style={{
            left: -2,
            width: `calc(${relativeBleed.left}% + 2px)`,
            height:`calc(100% - ${relativeBleed.top*2}%)`,
            borderRightWidth: '1px',
            top:`${relativeBleed.top}%`,
            }}
        />,
        <div
            key="right-bleed-border"
            className="html-renderer-bleed__band html-renderer-bleed__band--border html-renderer-bleed__band--vertical"
            style={{
            right: -2,
            width: `calc(${relativeBleed.right}% + 2px)`,
            height:`calc(100% - ${relativeBleed.right*2}%)`,
            borderLeftWidth: '1px',
            top:`${relativeBleed.top}%`,
            }}
        />,
    ]
    bleedElements.push(bleedMaskBorders);
    

    return (
      <HtmlRendererRect className={`html-renderer-mask ${this.props.heavyBorders ? 'html-renderer-mask__heavy-borders' : ''}`} rect={this.props.rect}>
        {bleedElements}
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererBleed;
