import React from "react";
import { PhotoTilePreviewSinglePack } from "./PhotoTilePreviewSinglePack";
import { PhotoTilePreviewMultiPack } from "./PhotoTilePreviewMultiPack";
import { useProduct } from "../../utils/useProduct";

export function PhotoTilesPreviewComponent2({ itemToApprove, width = 250 }) {
  const product = useProduct(itemToApprove.productId);

  return product.photo_tile_pack ? (
    <PhotoTilePreviewMultiPack itemToApprove={itemToApprove} width={width} />
  ) : (
    <PhotoTilePreviewSinglePack itemToApprove={itemToApprove} width={width} />
  );
}
