import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import {
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  PRODUCT_TYPE_IDS,
  getProductTypeforProductTypeId,
  singleShippingAddressProductTypes,
  orderSummaryFormat,
  containsMultipleShipments
 } from "../../data/products";
import { getAllPostageSchemesForProductTypeAndDestination } from "../../data/postage-schemes";
import Currency from "../../components/Formatters/Currency";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import { EditOutlined } from '@ant-design/icons';

import {
  Radio,
} from "antd";

//import { PlusCircleFilled } from '@ant-design/icons';

import "./OrderSummary.scss";


class SingleShipmentOrderSummaryV2 extends React.Component {
  static propTypes = {
    summary: PropTypes.object
  };

  onChangePostageScheme = (item, event) => {
    const postageSchemeId = event.target.value;
    if (item){
      this.props.onChangePostageSchemeForItem(item, postageSchemeId);
    }
  };

  render() {
    const { summary, currency } = this.props;
    
    let postageSchemes, postageSchemeOptions;
    let allPostageSchemesWithOptions = []
    let allPostageSchemesWithoutOptions = []
    let allPostageSchemeOptions = []
    let allPostageSchemesWithOptionsProductTypeIds = []
    let allPostageSchemesWithoutOptionsProductTypeIds = []
    let allPostageSchemesProductTypeIds = []
    let hasPostageSchemeOptions = false;
    let hasShippingDetails = false;

    let hasMoreThanOneShippingOption = false;

    let productsSummary = []
    let productsTotal = 0;
    let itemsTotal = 0;
    let productShippingTotal = 0;
    let shippingTotal = 0;
    let numberOfProductTypes = 0;
    let photoPrintItemAddress = null;
    let addressComplete = false;
    let postageSchemeId = false;
    const radioStyle = {
      lineHeight: '16px',
      display: 'flex',
      justifyContent: 'start',
      fontSize: '12px',
      margin: '10px 0px 10px 0px',
      fontWeight: 300,
      paddingBottom: '5px'
    };
    let shippingSummary = [];
    let useItemForAddress
    let itemSharedAddress
    let singleShippingQtyDescription = ''


    const itemsGroupedByProductType = summary && summary.get("items").groupBy((item) => item.get('productTypeId')).toJS();
    const countOfSharedShippingType = singleShippingAddressProductTypes.filter((sharedShippingProductTypeId) => {
      return itemsGroupedByProductType[sharedShippingProductTypeId];
    }).length
    const nonSharedShippingAddressItems = Object.keys(itemsGroupedByProductType).filter((productTypeId) => !singleShippingAddressProductTypes.includes(parseInt(productTypeId))).reduce((obj, key) => {
      return {
        ...obj,
        [key]: itemsGroupedByProductType[key]
      };
    }, {});

    const countOfTotalProductTypes = countOfSharedShippingType + Object.keys(nonSharedShippingAddressItems).length

    singleShippingAddressProductTypes.forEach((sharedShippingProductTypeId) => {

      let photoPrintItemWithRealPostageScheme
      let productTypeId = sharedShippingProductTypeId //PRODUCT_TYPE_IDS.PHOTO_PRINT //summary && summary.get("items").first().get('productTypeId');
      let productsForProductType = itemsGroupedByProductType[productTypeId];
      if (productsForProductType){

        let productsGroupedByProduct = fromJS(productsForProductType).groupBy((productTypeProduct) => productTypeProduct.get('productId')).toJS();

        if(sharedShippingProductTypeId === PRODUCT_TYPE_IDS.PHOTO_PRINT || sharedShippingProductTypeId === PRODUCT_TYPE_IDS.PHOTO_TILE){
          photoPrintItemWithRealPostageScheme = summary && summary.get('items').find(item => item.get("productTypeId") === productTypeId && item.getIn(["postageScheme", "cost"]));
        } else{
          photoPrintItemWithRealPostageScheme = summary && summary.get('items').find(item => item.get("productTypeId") === productTypeId);
        }
        
        useItemForAddress = photoPrintItemWithRealPostageScheme
        if (photoPrintItemWithRealPostageScheme){
          hasShippingDetails = true
          //console.log("photoPrintItemWithRealPostageScheme", photoPrintItemWithRealPostageScheme.toJS())
          if(photoPrintItemWithRealPostageScheme.get("address") && Object.keys(photoPrintItemWithRealPostageScheme.get("address")).length > 0){
            photoPrintItemAddress = photoPrintItemWithRealPostageScheme.get("address")
          } else if (photoPrintItemWithRealPostageScheme.get("addressBookEntry")){
            photoPrintItemAddress = photoPrintItemWithRealPostageScheme.get("addressBookEntry")
          } else {
            photoPrintItemAddress = null
          }
          
          if (photoPrintItemAddress){
            itemSharedAddress = photoPrintItemAddress
          }

          //TODO: a better validation here
          addressComplete = photoPrintItemAddress ? 
           Object.keys(photoPrintItemWithRealPostageScheme.get("address")?.toJS()).includes("line1")
            : Boolean(photoPrintItemWithRealPostageScheme.get("addressBookEntry"))

          productShippingTotal = Object.keys(productsGroupedByProduct).reduce((total, productId) => {
            const productsForProductId = fromJS(productsGroupedByProduct[productId]);
            const totalForProductType = productsForProductId.reduce((total, product) => total + product.getIn(["postageScheme", "cost"]), 0);
            return total + totalForProductType;
          }, 0);
    
          postageSchemeId = photoPrintItemWithRealPostageScheme && photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']);
          let totalWeightForAllItemsOfProductType = 0;
          if (photoPrintItemWithRealPostageScheme && itemSharedAddress){
            totalWeightForAllItemsOfProductType = fromJS(productsForProductType).reduce((totalWeight, itemForProduct) => {
              if (itemForProduct.get("productTypeId") === productTypeId) {
                const itemQuantity = itemForProduct.get("quantity");
                const itemWeight   = itemForProduct.get('weight') === undefined ? 1 : itemForProduct.get('weight');
                return totalWeight + (itemQuantity * itemWeight);
              }
              return totalWeight;
            }, 0);
            
            const uniqProductIds = [...new Set(fromJS(productsForProductType).map((item => item.get("productId"))))]

            postageSchemes = getAllPostageSchemesForProductTypeAndDestination({
              productTypeId: parseInt(productTypeId),
              destinationCountry: itemSharedAddress.get("country"),
              currency: currency,
              weight: totalWeightForAllItemsOfProductType,
              useFreshPostageSchemes: false,
              productIds: uniqProductIds,
              postcode: itemSharedAddress.get("postcode")
            });
            // TODO: get all print item weights... ^
            //console.log("Setting hasPostageSchemeOptions to ", postageSchemes && postageSchemes.size > 1)

            if (hasMoreThanOneShippingOption === false){
              hasPostageSchemeOptions = postageSchemes && postageSchemes.size > 1
              if (hasPostageSchemeOptions){
                hasMoreThanOneShippingOption = true
              }
            }
            

            //if (hasPostageSchemeOptions){
            if (postageSchemes && postageSchemes.size > 0){
              postageSchemeOptions = postageSchemes.sortBy(scheme => parseFloat(scheme.get('cost'))).map((scheme) => {
                //console.log("photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) ", photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) )
                const isChecked = photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) === scheme.get("id");

                if(isChecked && this.props.checkoutShippingOption.size === 0){
                  this.props.onChangePostageSchemeForItem(photoPrintItemWithRealPostageScheme, scheme.get("id"));
                }

                return (
                  <Radio checked={isChecked} style={radioStyle} value={scheme.get("id")}>
                    <React.Fragment>
                      <div style={{marginBottom:'3px'}}>
                        <span>
                          {`${scheme.get("description")}`} — {` `}
                        </span>
                        <span>
                          <Currency amount={scheme.get("cost")} showFree/>
                        </span>
                      </div>
                      <div style={{marginBottom:'3px'}}>
                        <span style={{fontSize: '12px', whiteSpace: 'pre-wrap'}}>
                          {scheme.get("service_description")}
                        </span>
                      </div>
                    </React.Fragment>
                  </Radio>
                );
              })
            }
            //}
            const productTypeName = orderSummaryFormat(productTypeId)

            let postageSchemeOptionsWrapper
            if (postageSchemes && postageSchemes.size > 1 ){
              postageSchemeOptionsWrapper = (
                <div className={`order-summary--product-shipping-options ${countOfTotalProductTypes > 1 ? 'multiple' : ''}`}>
                  {countOfTotalProductTypes > 1 && (
                    <p className="shipping-option__product-name">{productTypeName}</p>
                  )}
                    <Radio.Group
                      onChange={(e) => this.onChangePostageScheme(photoPrintItemWithRealPostageScheme, e)}
                      value={postageSchemeId}
                      buttonStyle={"solid"}
                    >
                      {postageSchemeOptions}
                    </Radio.Group>
                </div>
              )
              allPostageSchemesWithOptionsProductTypeIds.push(productTypeId)
              allPostageSchemesWithOptions.push(postageSchemeOptionsWrapper)
            } else {
              postageSchemeOptionsWrapper = (
                <div className={`order-summary--product-shipping-options single-option ${countOfTotalProductTypes > 1 ? 'multiple' : ''}`}>
                  {countOfTotalProductTypes > 1 && (
                    <p className="shipping-option__product-name">{productTypeName}</p>
                  )}
                    <Radio.Group
                      onChange={(e) => this.onChangePostageScheme(photoPrintItemWithRealPostageScheme, e)}
                      value={postageSchemeId}
                      buttonStyle={"solid"}
                    >
                      {postageSchemeOptions}
                    </Radio.Group>
                </div>
              )
            }
            
            if ( postageSchemes && postageSchemes.size ===1 ){
              allPostageSchemesWithoutOptionsProductTypeIds.push(productTypeId)
              allPostageSchemesWithoutOptions.push(postageSchemeOptionsWrapper)
            }

          }
        }
      }
    })

    

    Object.keys(nonSharedShippingAddressItems).forEach((productTypeId) => {
      const productTypeName = orderSummaryFormat(productTypeId)
      const productTypeShippingNotification = (
        <div className="order-summary--product-shipping-options multiple">
          <p className="shipping-option__product-name">{productTypeName}</p>
          <p>These items will be sent using 1st Class mail to the addresses provided.</p>
        </div>
      )
      allPostageSchemesWithoutOptionsProductTypeIds.push(parseInt(productTypeId))
      allPostageSchemesWithoutOptions.push(productTypeShippingNotification)
    })

    // console.log("nonSharedShippingAddressItems", nonSharedShippingAddressItems)
    // console.log("hasMoreThanOneShippingOption",hasMoreThanOneShippingOption)
    // console.log("hasPostageSchemeOptions",hasPostageSchemeOptions)
    // console.log("hasShippingDetails",hasShippingDetails)
    // console.log("nonSharedShippingAddressItems", nonSharedShippingAddressItems)

    allPostageSchemeOptions = allPostageSchemesWithOptions.concat(allPostageSchemesWithoutOptions)
    allPostageSchemesProductTypeIds = allPostageSchemesWithOptionsProductTypeIds.concat(allPostageSchemesWithoutOptionsProductTypeIds)
    let productTypeGroups = itemsGroupedByProductType && Object.keys(itemsGroupedByProductType)

    if (productTypeGroups){
      if (allPostageSchemesProductTypeIds.length > 1){
        productTypeGroups = productTypeGroups.sort(function(a, b){  
          return allPostageSchemesProductTypeIds.indexOf(parseInt(a)) - allPostageSchemesProductTypeIds.indexOf(parseInt(b));
        });
      }
      

      numberOfProductTypes = productTypeGroups.length
      productsSummary = productTypeGroups.map((productTypeId, productTypeIndex) => {
        const productsForProductType = itemsGroupedByProductType[productTypeId];
        const productType = getProductTypeforProductTypeId(parseInt(productTypeId));
        const productsGroupedByProduct = fromJS(productsForProductType).groupBy((productTypeProduct) => productTypeProduct.get('productId')).toJS();

        let productTypeSummary = Object.keys(productsGroupedByProduct).map((productId, index) => {
          const productsForProductId = fromJS(productsGroupedByProduct[productId]);
          let productQty = productsForProductId.reduce((total, product) => total + product.get("quantity"), 0);
          const product = productsForProductId.first();
          const productID = product.get("productId");
          let totalForProduct = productsForProductId.reduce((total, product) => total + (product.get("quantity") * product.getIn(["pricingScheme", "cost"])), 0);
          const hasMinimumQty = hasMinimumQuantityForProduct(productID);
          let showQty = true

          let productUnitPrice = product.getIn(["pricingScheme", "cost"])

          if (hasMinimumQty){
            const packQty = minimumQuantityForProduct(productID)
            const packCount = productQty % packQty === 0 ? (productQty/packQty) : Math.floor(productQty/packQty);
            if(packCount === 1){
              productQty = `${packCount} pack of ${packQty}`
            } else{
              productQty = `${packCount} packs of ${packQty}`
            }
            if(packCount === 0){
              productQty = 'Incomplete pack of'
            }

            itemsTotal += packCount;
          } else{
            itemsTotal += productQty;
          }
    
          let productName = product.get('description') ? product.get('description').replace(" × ", "×") : ""
          if (productQty === 1 ){
            productName = productName.replace("Prints", "Print")
          }

          if (product.get('isCustomPrint') && product.get('tiled')){
            productName = `${productName} (tiled)`
          }

          if (product.get('isPassportPrint')){
            productName = `Passport Photos`
          }

          if (product.get('packMode')){
            productName = `${product.get('quantity')} x Pack of 8 cards`
            productUnitPrice = productUnitPrice/product.get('quantity')
            totalForProduct = productsForProductId.reduce((total, product) => total + (product.getIn(["pricingScheme", "cost"])), 0);
            showQty = false
          }

          
          
          productsTotal += totalForProduct;
          return (
            <div className={`basket__summary_row ${productTypeIndex+1 === numberOfProductTypes ? 'last' :''}`}>
              <div className="basket__summary_col-left">
                {hasMinimumQty ? (
                  <span>{productQty} {productName}</span>
                ) : (
                  <span>{showQty ? `${productQty} × ` : ''}{productName} @ {<Currency amount={productUnitPrice} />}</span>
                )}
                
              </div>
              {productQty !== 'Incomplete pack of' && (
                <div className="basket__summary_col-right">
                  <span>{<Currency amount={totalForProduct}/>}</span>
                </div>
              )}
              
            </div>
          );
        });

        if (numberOfProductTypes > 1){
          return (
            <div className="order-summary--product-type-section">
              <div key={`productType-${productTypeId}`} className="order-summary--product-type-header-section">
                <span className="order-summary--product-type-heading">{productType.get('name')}</span>
              </div>
              <div className="order-summary--product-groupings">
                {productTypeSummary}
              </div>
            </div>
          );
        } else{
          return productTypeSummary
        }
      })

      shippingSummary = productTypeGroups.map((productTypeId, index) => {
        const productsForProductType = itemsGroupedByProductType[productTypeId];
        const productType = getProductTypeforProductTypeId(parseInt(productTypeId));
        const productsGroupedByProduct = fromJS(productsForProductType).groupBy((productTypeProduct) => productTypeProduct.get('productId')).toJS();

        let numberOfProductsForType = 0
        let unitCost
        let differentUnitCosts = Object.values(productsGroupedByProduct).length > 1
        productShippingTotal = Object.keys(productsGroupedByProduct).reduce((total, productId) => {
          const productsForProductId = fromJS(productsGroupedByProduct[productId]);
          const totalForProductType = productsForProductId.reduce((total, product) => total + product.getIn(["postageScheme", "cost"]), 0);
          const qtyForProductType = productsForProductId.reduce((total, item) => total + item.get('quantity'), 0);
          if(parseInt(productTypeId) !== PRODUCT_TYPE_IDS.PHOTO_PRINT && parseInt(productTypeId) !== PRODUCT_TYPE_IDS.PHOTO_TILE){
            if(!productsForProductId.some(item => item.get('packMode') || item.get('productTypeId') === PRODUCT_TYPE_IDS.GREETING_CARD)){
              numberOfProductsForType += qtyForProductType
            }
            unitCost = productsForProductId.first().getIn(["postageScheme", "cost"])
          }
          return total + totalForProductType;
        }, 0);
        shippingTotal += productShippingTotal;

        singleShippingQtyDescription = (
          (numberOfProductsForType > 1 && !differentUnitCosts && productTypeId != PRODUCT_TYPE_IDS.PHOTO_TILE) ? (
            <span>({numberOfProductsForType} x <Currency amount={unitCost} showFree/>)</span>
          ) : (
            null
          )
        )

        if (numberOfProductTypes > 1){

          return (
            <div className="basket__summary_row">
              <div className="basket__summary_col-left">
                {numberOfProductsForType > 1 ? (
                  <span>{productType.get('name')} {singleShippingQtyDescription}</span>
                ) : (
                  <span>{productType.get('name')}</span>
                )}
              </div>
              <div className="basket__summary_col-right">
                <span>
                  {productShippingTotal === 0 ? (
                    <span>Free</span>
                  ) : (
                    <span>
                      <Currency amount={productShippingTotal} showFree/>                      
                    </span>
                  )
                  }
                </span>
              </div>
            </div>
          );
        } else{
          return null
        }        
      });
    }

    const multipleShipments = containsMultipleShipments(summary.get('items').map((item) => item.get('productId')).toJS())

    return (
      <div className="basket__single-shipment-order-summary">
        {hasShippingDetails && (
          <>
            <div className="basket__summary-container">
              {numberOfProductTypes > 1 ? (
                <span className="basket__block-title">Shipping Details</span>
              ) : (
                <span className="basket__block-title">Shipping Details</span>
              )}
              
              <div className="basket__summary-block">
                {addressComplete && (
                  <>
                    <div className="basket__summary-block-left" style={{position: 'relative'}}>
                      {itemSharedAddress && (
                        <p className="basket__summary-text multiline-address">
                          {generateMultilineAddress(itemSharedAddress.toJS()).join("\n")}
                        </p>
                      )}
                      <div className="basket__summary-edit-btn" style={{position: 'absolute', right: 0, top: 0}} onClick={() => this.props.onClickAddress(useItemForAddress)}>
                        <EditOutlined /> Edit
                      </div>
                      {(true) && (
                        <div style={{paddingTop: '10px'}}>
                          <span style={{fontSize: '12px', fontWeight: '400'}}>
                            {numberOfProductTypes > 1 ? (
                              "Shipping Options"
                            ) : (
                              "Shipping Options"
                            )}
                          </span>
                          {allPostageSchemeOptions}
                        </div>
                      )}

                    </div>
                  </>
                )}
                {!addressComplete && (
                  <>
                    <div className="basket__summary-block-left">
                      <p>No shipping address</p>
                    </div>
                    <div className="basket__summary-edit-btn" onClick={() => this.props.onClickAddress(useItemForAddress)}>
                      <EditOutlined /> Add Address
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        
        
        <div className="basket__summary-container">
          <span className="basket__block-title">
            Order Summary
          </span>
          <div className="basket__summary-block">
            <div className="basket__summary-block-left">
              <div className="basket__summary-top-row">
                <span onClick={this.props.onClickItemCount} style={{fontWeight: 'bold', marginLeft: '10px'}} className="basket__block-title-right">
                  {itemsTotal} {itemsTotal === 1 ? "Item" : "Items"}
                </span>
                <div className="basket__summary-edit-btn" onClick={this.props.onEditBasket}>
                  <EditOutlined /> Delete
                </div>
              </div>
              {numberOfProductTypes > 1 && (
                <div className="basket__summary_row--subheading">
                  <span>Products</span>
                </div>
              )}
              {productsSummary}
              <div className="basket__summary_row border-top">
                <div className="basket__summary_col-left">
                  {numberOfProductTypes > 1 ? (
                    <span>PRODUCTS TOTAL</span>
                  ) : (
                    <span>PRODUCTS</span>
                  )}
                
                </div>
                <div className="basket__summary_col-right">
                  <span><Currency amount={productsTotal} showFree/></span>
                </div>
              </div>
              {true && (
                <>
                  {numberOfProductTypes > 1 && (
                    <div className="basket__summary_row--subheading">
                      <span>Shipping</span>
                    </div>
                  )}
                  
                  {shippingSummary}
                  <div className={`basket__summary_row ${numberOfProductTypes > 1 ? 'border-top' : ''}`}>
                    <div className="basket__summary_col-left">
                      {numberOfProductTypes > 1 ? (
                        <span>SHIPPING TOTAL</span>
                      ) : (
                        <span>SHIPPING {singleShippingQtyDescription}</span>
                      )}
                    </div>
                    <div className="basket__summary_col-right">
                      <span><Currency amount={shippingTotal} showFree/></span>
                    </div>
                  </div>
                  
                </>
              )}

              {multipleShipments && (
                <div className="basket__summary_row">
                  <div className="basket__summary_col-left">
                    <span>2ND SHIPMENT SURCHARGE</span>
                  </div>
                  <div className="basket__summary_col-right">
                    <span>
                      <Currency amount={3.00}/>
                    </span>
                  </div>
                </div>
              )}

              {summary.getIn(["promotionInfo", "data"]) && (
                <div className="basket__summary_row colored">
                  <div className="basket__summary_col-left">
                    <span>DISCOUNT</span>
                  </div>
                  <div className="basket__summary_col-right">
                    <span>
                      <Currency amount={-summary.getIn(["promotionInfo", "totalDiscountForOrder"])}/>
                    </span>
                  </div>
                </div>
              )}
              <div className="basket__summary_row no-border bold">
                <div className="basket__summary_col-left">
                  <span>TOTAL</span>
                </div>
                <div className="basket__summary_col-right">
                  <span><Currency amount={summary.get("total")}/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleShipmentOrderSummaryV2;
