import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { Button, Col, Row, Modal as AntModal } from "antd";
//import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
//import colours from "../../pages/ThemeList/colours.json";
import * as LAYER_TYPES from "../../constants/layer-types";
import useUndoableState from "@jeremyling/react-use-undoable-state";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Icon from "../Icon/Icon";
import "./textEditor.scss";
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  FontSizeOutlined,
  UndoOutlined,
  RedoOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FontFamilySelectionModal from "./FontFamilySelectionModal";
import FontSizeSelectionModal from "./FontSizeSelectionModal";
import FontSizeSliderSelectionModal from "./FontSizeSliderSelectionModal";
import FontColourSelectionModal from "./FontColourSelectionModal";
import TextEditingTools from "./TextEditingTools";
import { FONT_FAMILIES } from "../../constants/text-style-options";
import { render } from "react-dom";

// const FONT_FAMILIES = [
//   "Arial",
//   "Helvetica",
//   "Verdana",
//   "Calibri",
//   "Noto",
//   "Lucida Sans",
//   "Gill Sans",
//   "Segoe UI",
//   "Times New Roman",
//   "Rockwell",
//   "Courier",
//   "Chalkduster",
//   "Brush Script MT",
// ];
/*const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      return false;
    } else {
      // alert("2") // Safari
      return true;
    }
  }
  return false;
};
*/

const FONT_SIZES = [
  { name: "Small", size: 16 }, // 2/3s of cover size
  { name: "Normal", size: 28 },
  { name: "Medium", size: 32 },
  { name: "Large", size: 36 },
  { name: "Extra Large", size: 60 },
];

const COVER_FONT_SIZES = [
  { name: "Small", size: 24 },
  { name: "Normal", size: 34 },
  { name: "Medium", size: 36 },
  { name: "Large", size: 38 },
  { name: "Extra Large", size: 160 },
];

export const DEFAULT_MAX_LENGTH = 32;
const DEFAULT_SUBTEXT_LENGTH = 50;

const TextEditor = ({
  defPlaceholder,
  onSave,
  forCover,
  isEditingCoverPage,
  item,
  renderWidth,
  toUpdate,
  onPage,
  setGlobalTextFont,
  renderEdit,
  setBookColour
}) => {
  const canvasRef = useRef();
  const textRef = useRef();
  const textareaRef = useRef();
  const subTextareaRef = useRef();
  const [isFontModalOpen, setFontModalOpen] = useState(false);
  const [isFontSizeModalOpen, setFontSizeModalOpen] = useState(false);
  const [isFontSizeSliderModalOpen, setFontSizeSliderModalOpen] = useState(false);
  const [isFontColourModalOpen, setFontColourModalOpen] = useState(false);
  const page = onPage;
  // const [placeholder, setPlaceholder] = useState(defPlaceholder);
  const [placeholder, setPlaceholder] = useState(JSON.parse(JSON.stringify(defPlaceholder)));
  const templateData = JSON.parse(JSON.stringify(toUpdate));

  const [selectedFontFamily, setFontFamily] = useState({
    name: "Open Sans",
    displayName: "Seatown",
  });

  const [selectedSubTextFontFamily, setSubTextFontFamily] = useState({
    name: "Open Sans",
    displayName: "Seatown",
  });

  const [selectedFontSize, setFontSize] = useState("35") //useState((forCover || isEditingCoverPage) ? "35" : "24");
  const [selectedSubTextFontSize, setSubTextFontSize] = useState("21") //useState((forCover || isEditingCoverPage) ? "35" : "24");
  const [selectedFontColour, setFontColour] = useState(placeholder?.properties?.color);
  const [selectedSubTextFontColour, setSubTextFontColour] = useState(placeholder?.properties?.subTextColor);
  const [selectedAlignment, setAlignment] = useState("center");
  const [selectedSubTextAlignment, setSubTextAlignment] = useState("center");
  const [selectedVerticalAlignment, setVerticalAlignment] = useState("center");

  const [selectPaddingTop, setPaddingTop] = useState(0);
  const [textLength, setTextLength] = useState(0);
  const [subTextLength, setSubTextLength] = useState(0);
  const [inputFocused, setInputFocused] = useState("heading");
  const [itemForCharCheck, setItemForCharCheck] = useState(item);

  //const [selectedBookColour, setSelectedBookColour] = useState("#fff");

  const [isContentTooBig, setIsContentTooBig] = useState(false);
  const [isContentTooWide, setIsContentTooWide] = useState(false);

  const [previousContent, setPreviousContent] = useState(placeholder?.properties?.previousContent);
  const [isPreviousContentModalVisible, setIsPreviousContentModalVisible] = useState(placeholder?.properties?.previousContent);

  const [isTextIssue, setTextIssue] = useState(null);

  const [activeTextComponent, setActiveTextComponent] = useState("text"); // or subtext

  const init = {
    text: placeholder?.properties?.content //, // || placeholder?.properties?.previousContent,
  };
  const subinit = { text: placeholder?.properties?.subtext || "" };
  const {
    state: doc,
    setState: setDoc,
    //eslint-disable-next-line
    resetState: resetDoc,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    goBack: undoDoc,
    goForward: redoDoc,
  } = useUndoableState(init, 500);

  const {
    state: subdoc,
    setState: setsubDoc,
    //eslint-disable-next-line
    resetState: resetsubDoc,
    index: subdocStateIndex,
    lastIndex: subdocStateLastIndex,
    goBack: undosubDoc,
    goForward: redosubDoc,
  } = useUndoableState(subinit, 500);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  const cansubUndo = subdocStateIndex > 0;
  const cansubRedo = subdocStateIndex < subdocStateLastIndex;

  const withActiveTextComponent = (setActiveTextComponent, setterFunction) => {
    return (component, val) => {
      setActiveTextComponent(component);
      setterFunction(val);
    };
  };


  // const setSubTextFontModalOpen = (val) => {
  //   setActiveTextComponent("subtext")
  //   setFontModalOpen(val)
  // }
  // const setSubTextFontSizeSliderModalOpen = (val) => {
  //   setActiveTextComponent("subtext")
  //   setFontSizeSliderModalOpen(val)
  // }

  // const setSubTextFontColourModalOpen = (val) => {
  //   setActiveTextComponent("subtext")
  //   setFontColourModalOpen(val)
  // }

  // const handleSubTextAlignmentChange = (val) => {
  //   setActiveTextComponent("subtext")
  //   handleTextAlignmentChange(val)
  // }

  // const handleSubTextHorizontalAlignmentChange = (val) => {
  //   setActiveTextComponent("subtext")
  //   handleTextHorizontalAlignmentChange(val)
  // }

  const handleRestorePreviousContent = () =>{
    const temp = placeholder;
    if (temp.properties) {
      temp.properties.content = previousContent;
      temp.properties.previousContent = "";
      setPlaceholder(temp);
      setDoc({ text: previousContent });
      handleUpdateItemForCharCheck(temp)
      canvasRef.current.renderAll();
    }

    setPreviousContent(null)
    setIsPreviousContentModalVisible(false)
  }


  const handleRemovePreviousText = () =>{
    const temp = placeholder;
    if (temp.properties) {
      temp.properties.content = "";
      temp.properties.previousContent = "";
      setPlaceholder(temp);
      setDoc({ text: "" });
      canvasRef.current.renderAll();
    }

    setPreviousContent(null)
    setIsPreviousContentModalVisible(false)
  }

  useEffect(() => {
    const temp = placeholder;
    if (temp.properties) {
      temp.properties.content = doc.text;
      setPlaceholder(temp);
      const numberOfLines = doc.text.split("\n").length;
      const newTextLength = doc.text.length + mapSpaceToWords(numberOfLines - 1);
      // if (newTextLength <= getCharacterLimit()) {
      setTextLength(newTextLength);
    }
  //eslint-disable-next-line
  }, [doc, placeholder]);
  useEffect(() => {
    const temp = placeholder;
    if (temp.properties) {
      temp.properties.subtext = subdoc.text;
      setPlaceholder(temp);
      const numberOfLines = subdoc.text.split("\n").length;
      const newTextLength = subdoc.text.length + mapSpaceToWords(numberOfLines - 1);
      // if (newTextLength <= getCharacterLimit()) {
      setSubTextLength(newTextLength);
    }
  //eslint-disable-next-line
  }, [subdoc, placeholder]);
  const width = useRef(0);
  useEffect(() => {
    width.current = document.getElementById("canvasContainer")?.clientWidth || 200;
    if (textareaRef && textareaRef?.current) {
      //textareaRef.current.focus();
    }
  }, []);
  const height = 100;
  const initCanvas = () =>
    (canvasRef.current = new fabric.Canvas("canvas", {
      height: height,
      width: width.current,
      backgroundColor: "#666",
    }));

  const updateForEdit = (font) => {
    onSave(
      placeholder,
      font
    );
  }

  const onPressSave = () => {
    setPlaceholder(null);
    // if (
    //   placeholder?.properties?.subtext?.trim().length > 0 &&
    //   (placeholder.properties.content === "Aa" ||
    //     placeholder?.properties?.content === "Your text here ... AaBbCc")
    // ) {
    //   placeholder.properties.content = "";
    // }
    onSave(
      placeholder,
      selectedFontFamily !== item.getIn(["product_options", "fontFamily"])
        ? selectedFontFamily
        : null
    );
  };
  const verticalAlignmentToPaddingTop = verticalAlignment => {
    //change relevant button active values if changing these
    if (verticalAlignment === "top") {
      setPaddingTop(0);
    } else if (verticalAlignment === "center") {
      if (window.innerHeight > window.innerWidth) {
        // if (isSafari()) {
        //   return setPaddingTop("30px");
        // }
        setPaddingTop("20%");
      } else {
        setPaddingTop("20%");
      }
    } else if (verticalAlignment === "bottom") {
      if (window.innerHeight > window.innerWidth) {
        // if (isSafari()) {
        //   return setPaddingTop("50px");
        // }
        setPaddingTop("35%");
      } else {
        setPaddingTop("30%");
      }
    } else {
      setPaddingTop(0);
    }
  };
  useEffect(() => {
    initCanvas();
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      const {
        content,
        fontSize,
        subTextFontSize,
        verticalAlignment,
        horizontalAlignment,
        color,
        fontFamily,
        subTextFontFamily,
        subtext,
        textAlignment,
        subTextAlignment
      } = placeholder.properties;
      // const bottomRightCoords = { x: placeholder.position.bottomRight[0] * canvasWidth, y: placeholder.position.bottomRight[1] * canvasSize.height }
      if (fontFamily !== "") setFontFamily(fontFamily);
      if (subTextFontFamily !== "") setSubTextFontFamily(subTextFontFamily);
      setFontSize(fontSize);
      setSubTextFontSize(subTextFontSize || (fontSize * 0.6));
      setAlignment(textAlignment);
      setSubTextAlignment(subTextAlignment || "center"); //  || textAlignment

      // console.log("fontFamily", fontFamily)
      // console.log("subTextFontFamily", subTextFontFamily)
      // console.log("Setting font to", item.getIn(["product_options", "fontFamily"]) || "Arial")

      setFontFamily(item.getIn(["product_options", "fontFamily"]) || "Open Sans");
      if (!subTextFontFamily){
        // TODO, may not want to do this but it seems a sensible default
        setSubTextFontFamily(item.getIn(["product_options", "fontFamily"]) || "Open Sans");
      }
      

      verticalAlignmentToPaddingTop(verticalAlignment);
      setVerticalAlignment(verticalAlignment);
      textareaRef.current.value = content;
      // content === "Aa" || content === "Your text here ... AaBbCc" ? "" : content;
      if (subtext && (forCover || isEditingCoverPage)) {
        subTextareaRef.current.value = subtext;
        setSubTextLength(subtext.length);
      }
      if (forCover || isEditingCoverPage) {
        autoGrow();
      }
      if (content.length > getCharacterLimit(fontSize)) {
        textareaRef.current.value = content.slice(0, getCharacterLimit(fontSize));
      }
      // textareaRef.current.value = content;
      const numberOfLines = content.split("\n").length;
      const defaultWordSubtraction = 0;
      const newTextLength =
        content.length + mapSpaceToWords(numberOfLines - 1, fontSize) - defaultWordSubtraction;
      setTextLength(newTextLength);
      if (newTextLength > getCharacterLimit(fontSize)) {
        //textareaRef.current.style.border = "3px solid #FF5A5F";
        //message.error(`Word limit should not exceed ${getCharacterLimit(fontSize)}`);
      }
      textRef.current = new fabric.Text(content || "Text goes here", {
        height: height,
        width: width.current,
        selectable: false,
        fontSize: fontSize || 12,
        fill: color || "white",
        fontFamily: fontFamily || "Courier",
        borderColor: "red",
        hasBorders: true,
      });
      textRef.current.textAlign = textAlignment || "center";

      // const lineHeight = textRef.current.height || 0;
      // let verticalOffset = 0;
      // if (verticalAlignment?.length === 0 || verticalAlignment === "top") {
      //   verticalOffset = 0;
      // } else if (verticalAlignment === "center") {
      //   verticalOffset = height / 2 - lineHeight;
      // } else if (verticalAlignment === "bottom") {
      //   verticalOffset = height - lineHeight;
      // }

      const textWidth = textRef.current.width || 0;
      let horizontalOffset = 0;
      if (horizontalAlignment?.length === 0 || horizontalAlignment === "left") {
        horizontalOffset = 0;
      } else if (horizontalAlignment === "center") {
        horizontalOffset = width.current / 2 - textWidth / 2;
      } else if (horizontalAlignment === "right") {
        horizontalOffset = width.current - textWidth;
      }

      // textRef.current.top = verticalOffset;
      textRef.current.left = horizontalOffset;
      canvasRef.current.add(textRef.current);
    }
  //eslint-disable-next-line
  }, []);
  const getCharacterLimit = value => {
    if (forCover || isEditingCoverPage) {
      return 40;
    }
    const newFontSize = value || selectedFontSize || 28;
    const multiplier = newFontSize < 24 ? (newFontSize === 12 ? 2 : 1.38) : 1;
    let maxlength = DEFAULT_MAX_LENGTH;

    if (
      placeholder.type === "TEXT_PLACEHOLDER" &&
      placeholder.properties &&
      placeholder.properties.isRect
    ) {
      maxlength = DEFAULT_MAX_LENGTH - 20;
    }
    maxlength = parseInt(maxlength * (28 / newFontSize) * multiplier, 10);
    if (
      placeholder.type === "TEXT_PLACEHOLDER" &&
      placeholder.properties &&
      placeholder.properties.wordScale
    ) {
      return parseInt(maxlength * placeholder.properties.wordScale, 10);
    }
    return maxlength;
  };
  //eslint-disable-next-line
  const mapSpaceToWords = (numberOfLines, newFontSize) => {
    const size = newFontSize || selectedFontSize;
    const wordScale = parseInt(
      placeholder.properties.wordScale / 2 < 1 ? 1 : placeholder.properties.wordScale / 2,
      10
    );
    let characters;
    switch (size) {
      case 32:
        characters = 10;
        break;
      case 28:
        characters = 11;
        break;
      case 24:
        characters = 13;
        break;
      case 20:
        characters = 15;
        break;
      case 16:
        characters = 19;
        break;
      case 12:
        characters = 26;
        break;
      default:
        characters = 15;
        break;
    }
    // console.log("characters ", characters, "wordscale ", wordScale);
    return characters * numberOfLines * wordScale;
  };
  const updateTextDimensionValues = (verticalAlignment, horizontalAlignment) => {
    const lineHeight = textRef.current.height || 0;
    let verticalOffset = 0;

    if (verticalAlignment.length === 0 || verticalAlignment === "top") {
      verticalOffset = 0;
    } else if (verticalAlignment === "center") {
      verticalOffset = height / 2 - lineHeight / 2;
    } else if (verticalAlignment === "bottom") {
      verticalOffset = height - lineHeight;
    }

    const textWidth = textRef.current.width || 0;

    let horizontalOffset = 0;
    if (horizontalAlignment.length === 0 || horizontalAlignment === "left") {
      horizontalOffset = 0;
    } else if (horizontalAlignment === "center") {
      horizontalOffset = width.current / 2 - textWidth / 2;
    } else if (horizontalAlignment === "right") {
      horizontalOffset = width.current - textWidth;
    }

    return { verticalOffset, horizontalOffset };
  };

  const handleSetIsContentTooBig = (isTooBig) => {
    if (isTooBig){
      setTextIssue(`This text does not fit. Shorten your text length or reduce your font size.`)
      textareaRef.current.style.border = "3px solid #FF5A5F";
    } else if (!isContentTooWide){
      setTextIssue(null)
      if ((forCover || isEditingCoverPage) === false) {
        textareaRef.current.style.border = "inherit";
      }
      if ((forCover || isEditingCoverPage) && !isTooBig){
        textareaRef.current.style.border = "inherit";
      }
    }
    setIsContentTooBig(isTooBig)
  }

  const handleSetIsContentTooWide = (isTooWide) => {
    if (isTooWide){
      setTextIssue(`Some words are too long for this template and will wrap at this font size. Shorten your words or reduce your font size`)
      textareaRef.current.style.border = "3px solid #FF5A5F";
    } else if (!isContentTooBig){
      setTextIssue(null)
      if ((forCover || isEditingCoverPage) === false) {
        textareaRef.current.style.border = "inherit";
      }
    }
    setIsContentTooWide(isTooWide)
  }

  const handleUpdateItemForCharCheck = (updatedPlaceholder, font) =>{
    let newItemForCharCheck = itemForCharCheck

    newItemForCharCheck = newItemForCharCheck.withMutations(item => {
      return item.withMutations(item => {
        if (font) {
          item.setIn(["product_options", "fontFamily"], font);
        }
        item.update("layers", layers => {
          return layers.map(layer => {
            if (
              layer.get("type") === LAYER_TYPES.PHOTO &&
              layer.get("page") === page
            ) {
              const template = layer.get("config").toJS().template;
              for (let i = 0; i < template.placeholders.length; i++) {
                if (
                  template.placeholders[i].id === defPlaceholder.id
                ) {
                  template.placeholders[i] = updatedPlaceholder;
                  break;
                }
              }
              return layer.setIn(["config", "template"], template);
            } else {
              return layer;
            }
          });
        });
      });
    })
    //console.log("itemForCharCheck", itemForCharCheck.toJS())
    setItemForCharCheck(newItemForCharCheck)
  }

  const handleTextChange = e => {
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      const { verticalAlignment, horizontalAlignment } = placeholder.properties;
      if (forCover || isEditingCoverPage) {
        let textWithoutSpace = e.target.value
        if (!renderEdit){
          textWithoutSpace = textWithoutSpace.replaceAll("\n", "");
        }
        textareaRef.current.value = textWithoutSpace;
      }
      textRef.current.text = e.target.value;
      const numberOfLines = e.target.value.split("\n").length;
      const newTextLength = e.target.value.length + mapSpaceToWords(numberOfLines - 1);
      // console.log("numberOfLines", numberOfLines)
      // console.log("newTextLength", newTextLength)
      // console.log("getCharacterLimit()", getCharacterLimit())
      // console.log("doc.text.length", doc.text.length)
      // console.log("e.target.value.length", e.target.value.length)

      // console.log("newTextLength <= getCharacterLimit()", newTextLength <= getCharacterLimit())
      // console.log("doc.text.length > e.target.value.length", doc.text.length > e.target.value.length)

      //if (newTextLength <= getCharacterLimit() || doc.text.length > e.target.value.length) {
      if (true){
        if (newTextLength <= getCharacterLimit()) {
          if ((forCover || isEditingCoverPage) === false) {
            textareaRef.current.style.border = "inherit";
          }
        }
        setTextLength(newTextLength);
        const temp = placeholder;
        if (temp.properties) {
          temp.properties.content = e.target.value;
          setPlaceholder(temp);
          canvasRef.current.renderAll();
          const { verticalOffset, horizontalOffset } = updateTextDimensionValues(
            verticalAlignment || "center",
            horizontalAlignment || "center"
          );
          textRef.current.top = verticalOffset;
          textRef.current.left = horizontalOffset;
          canvasRef.current.renderAll();
          handleUpdateItemForCharCheck(temp, selectedFontFamily)
          setDoc({ text: e.target.value });
        }
        // if (isSafari()) {
        //   textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        // }
      }
      if (forCover || isEditingCoverPage) {
        autoGrow();
      }
    }
    if (renderEdit){
      updateForEdit()
    }

  };

  const handleSubTextChange = e => {
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      // const { verticalAlignment, horizontalAlignment } = placeholder.properties;
      textRef.current.text = e.target.value;
      let textWithoutSpace = e.target.value
      if (!renderEdit){
        textWithoutSpace = textWithoutSpace.replaceAll("\n", "");
      }
      subTextareaRef.current.value = textWithoutSpace;
      const numberOfLines = e.target.value.split("\n").length;
      const newTextLength = e.target.value.length + mapSpaceToWords(numberOfLines - 1);
      if (newTextLength <= DEFAULT_SUBTEXT_LENGTH) {
        setSubTextLength(newTextLength);
        const temp = placeholder;
        if (temp.properties) {
          setsubDoc({ text: e.target.value });
          temp.properties.subtext = e.target.value;
          setPlaceholder(temp);
        }
      }
      // else {
      //   setSubTextLength(DEFAULT_SUBTEXT_LENGTH);
      //   textRef.current.text = "Aa";
      //   textareaRef.current.value = placeholder.properties.content;
      // }
      if (renderEdit){
        updateForEdit()
      }
    }
    
  };

  const clearText = () => {
    if (inputFocused === "subtext") {
      setsubDoc({ text: "" });
      const temp = placeholder;
      temp.properties.subtext = "";
      setPlaceholder(temp);
    } else {
      setDoc({ text: "" });
      const temp = placeholder;
      temp.properties.content = "";
      setPlaceholder(temp);
    }
  };

  // const handleSizeChange = e => {
  //   setFontSize(parseInt(e.target.value))
  //   if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
  //     const { verticalAlignment, horizontalAlignment } = placeholder.properties;
  //     textRef.current.fontSize = e.target.value;
  //     const temp = placeholder;
  //     if (temp.properties) {
  //       temp.properties.fontSize = e.target.value;
  //       setPlaceholder(temp);
  //       canvasRef.current.renderAll();

  //       const { verticalOffset, horizontalOffset } = updateTextDimensionValues(
  //         verticalAlignment || "center",
  //         horizontalAlignment || "center"
  //       );
  //       textRef.current.top = verticalOffset;
  //       textRef.current.left = horizontalOffset;
  //       canvasRef.current.renderAll();
  //     }
  //   }
  // };

  const handleFontColourChange = value => {
    if(activeTextComponent === "subtext"){
      setSubTextFontColour(value);
    } else{
      setFontColour(value);
    }
    
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      textRef.current.color = value;
      const temp = placeholder;
      if (temp.properties) {
        if(activeTextComponent === "subtext"){
          temp.properties.subTextColor = value;
        } else{
          temp.properties.color = value;
        }
        setPlaceholder(temp);
        canvasRef.current.renderAll();
      }
    }
    setFontColourModalOpen(false);
    if (renderEdit){
      updateForEdit()
    }
  };

  const handleFontSizeChange = (value) => {
    if(activeTextComponent === "subtext"){
      setSubTextFontSize(parseInt(value));
    } else{
      setFontSize(parseInt(value));
    }
    // const slicedText = textareaRef.current.value.slice(0, getCharacterLimit(value));
    // if (slicedText.length < textareaRef.current.value.length) {
    //   textareaRef.current.value = slicedText;
    //   handleTextChange({ target: { value: slicedText } });
    // }
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      const { verticalAlignment, horizontalAlignment } = placeholder.properties;
      textRef.current.fontSize = value;
      const temp = placeholder;
      if (temp.properties) {

        if(activeTextComponent === "subtext"){
          temp.properties.subTextFontSize = value;  
        } else if (activeTextComponent === "maintext"){
          temp.properties.fontSize = value;  
        } else{
          temp.properties.fontSize = value;
        }
        
        setPlaceholder(temp);
        const numberOfLines = placeholder.properties.content.split("\n").length;
        const newTextLength =
          placeholder.properties.content.length + mapSpaceToWords(numberOfLines - 1, value);
        setTextLength(newTextLength);
        canvasRef.current.renderAll();
        if (newTextLength > getCharacterLimit(value)) {
          //textareaRef.current.style.border = "3px solid #FF5A5F";
          //message.error(`Word limit should not exceed ${getCharacterLimit(value)}`);
        } else if (newTextLength <= getCharacterLimit(value)) {
          textareaRef.current.style.border = "inherit";
        }

        const { verticalOffset, horizontalOffset } = updateTextDimensionValues(
          verticalAlignment || "center",
          horizontalAlignment || "center"
        );
        textRef.current.top = verticalOffset;
        textRef.current.left = horizontalOffset;
        canvasRef.current.renderAll();

        handleUpdateItemForCharCheck(temp)
        if (renderEdit){
          updateForEdit()
        }
      }
    }
    setFontSizeModalOpen(false);
  };

  const handleTextAlignmentChange = (activeComponent, value) => {
    if(activeComponent === "subtext"){
      setSubTextAlignment(value);
    } else{
      setAlignment(value);
    }

    
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      textRef.current.textAlign = value;
      const temp = placeholder;
      if (temp.properties) {
        if(activeComponent === "subtext"){
          temp.properties.subTextAlignment = value;
        }
        else {
          temp.properties.textAlignment = value;
        }
        setPlaceholder(temp);
        canvasRef.current.renderAll();
      }
      if (renderEdit){
        updateForEdit()
      }
    }
  };
  const autoGrow = () => {
    // textareaRef.current.style.height = "28px";
    // const adjustHeightForMobile = window.innerHeight > window.innerWidth ? 50 : 0;
    // textareaRef.current.style.height =
    //   textareaRef.current.scrollHeight + adjustHeightForMobile + "px";
  };
  const handleTextVerticalAlignmentChange = value => {
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      verticalAlignmentToPaddingTop(value);
      setVerticalAlignment(value);
      const temp = placeholder;
      if (temp.properties) {
        temp.properties.verticalAlignment = value;
        setPlaceholder(temp);
      }
    }
  };
  const handleTextHorizontalAlignmentChange = (activeComponent, value) => {
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      const { verticalAlignment } = placeholder.properties;
      const { horizontalOffset } = updateTextDimensionValues(verticalAlignment || "center", value);
      const temp = placeholder;
      if (temp.properties) {
        if(activeComponent === "subtext"){
          temp.properties.subTextHorizontalAlignment = value;
        }
        else {
          temp.properties.horizontalAlignment = value;
        }
        
        setPlaceholder(temp);
        textRef.current.left = horizontalOffset;
        canvasRef.current.renderAll();
      }
    }
  };

  // const onChangeBookColour = (colour)=> {
  //   console.log("colour", colour)
  //   setSelectedBookColour(colour)
  //   setBookColour(colour)
  // }

  const headingInputFocused = () => {
    setInputFocused("heading");
  };
  const subtextInputFocused = () => {
    setInputFocused("subtext");
  };
  /*const fontSizeSelected = fontSize => {
    FONT_SIZES.filter(function(entry) {
      if (entry.size === fontSize);
      return entry.name;
    });
  };*/

  const handleFontFamilyChange = value => {
    if(activeTextComponent === "subtext"){
      setSubTextFontFamily(value);
    } else{
      setFontFamily(value);
    }
    
    if (placeholder.type === "TEXT_PLACEHOLDER" && placeholder.properties) {
      const temp = placeholder;
      if (temp.properties) {
        if(activeTextComponent === "subtext"){
          temp.properties.subTextFontFamily = value;
        }
        else {
          temp.properties.fontFamily = value;
        }
        setPlaceholder(temp);
        canvasRef.current.renderAll();
      }
      // if (renderEdit){
      //   updateForEdit()
      // }
    }

    handleUpdateItemForCharCheck(placeholder, value)
    if (renderEdit){
      updateForEdit(value)
    }
    setFontModalOpen(false);
  };

  const focusInput = () => {
    console.log("clicked")
    if (textareaRef && textareaRef?.current) {
      textareaRef.current.focus();
    }
  }

  const setFontModalOpenForActiveTextComponent = withActiveTextComponent(setActiveTextComponent, setFontModalOpen);
  const setFontSizeSliderModalOpenForActiveTextComponent = withActiveTextComponent(setActiveTextComponent, setFontSizeSliderModalOpen);
  const setFontColourModalOpenForActiveTextComponent = withActiveTextComponent(setActiveTextComponent, setFontColourModalOpen);

  const isSubTextActive = activeTextComponent === "subtext"

  const isFirstTime = (forCover && !renderEdit)

  //console.log("selectedFontSize*0.5", selectedFontSize*0.5)
  //console.log("selectedSubTextFontSize*0.5", selectedSubTextFontSize*0.5)

  let textPlaceholderFontSize = selectedFontSize*0.5
  let subTextPlaceholderFontSize = selectedSubTextFontSize*0.5
  let tooSmall = false

  if (textPlaceholderFontSize < 24 || subTextPlaceholderFontSize < 16){
    tooSmall = true
    textPlaceholderFontSize = selectedFontSize
    subTextPlaceholderFontSize = selectedSubTextFontSize
  }

  return (
    <div className="editorContainer">
      {/* <div id="canvasContainer">
        <h3>Preview:</h3>
        <canvas id="canvas"></canvas>
      </div> */}
      <AntModal
        visible={isPreviousContentModalVisible}
        title="Restore Previous Text?"
        onOk={handleRestorePreviousContent}
        onCancel={() => setIsPreviousContentModalVisible(false)}
        closable={false}
        footer={[
          <Button key="back" onClick={handleRemovePreviousText}>
            Discard
          </Button>,
          <Button key="submit" type="primary" onClick={handleRestorePreviousContent}>
            Restore & Edit
          </Button>
        ]}
      > 
        <p>You previously had text on this page that could no longer fit on this new template layout - would you like to restore and edit this text to fit this new layout or discard and add some new text?</p>
        <p>Here is the text we can restore for you:
          <pre style={{
            fontSize: '10px',
            background: '#eaeaea',
            padding: '10px',
            marginTop: '10px'
          }}
          >{previousContent}</pre>
        </p>
      </AntModal>
      <FontFamilySelectionModal
        isOpen={isFontModalOpen}
        onClose={() => setFontModalOpen(false)}
        fonts={FONT_FAMILIES}
        selectedFontFamily={isSubTextActive ? selectedSubTextFontFamily : selectedFontFamily}
        onChangeFont={handleFontFamilyChange}
      />
      <FontSizeSelectionModal
        isOpen={isFontSizeModalOpen}
        onClose={() => setFontSizeModalOpen(false)}
        fontSize={(forCover || isEditingCoverPage) ? COVER_FONT_SIZES : FONT_SIZES }
        selectedFontSize={isSubTextActive ? selectedSubTextFontSize : selectedFontSize}
        onChangeFont={handleFontSizeChange}
      />
      <FontSizeSliderSelectionModal
        isOpen={isFontSizeSliderModalOpen}
        onClose={() => setFontSizeSliderModalOpen(false)}
        fontSize={(forCover || isEditingCoverPage) ? COVER_FONT_SIZES : FONT_SIZES }
        selectedFontSize={isSubTextActive ? selectedSubTextFontSize : selectedFontSize}
        onChangeFont={handleFontSizeChange}
        tooBig={isContentTooBig || isContentTooWide}
      />
      <FontColourSelectionModal
        isOpen={isFontColourModalOpen}
        onClose={() => setFontColourModalOpen(false)}
        selectedFontColour={isSubTextActive ? selectedSubTextFontColour : selectedFontColour}
        onChangeColour={handleFontColourChange}
      />
      {isTextIssue && (
        <div style={{
          background: '#fc5c63',
          padding: '10px',
          color: 'white',
          marginTop: '10px',
          borderRadius: '4px'
        }}>
          <p style={{
            color: 'white',
            fontWeight: '500',
            textAlign: 'center'
          }}>{isTextIssue}</p>
        </div>
      )}
      {isFirstTime && (
        <div style={{ margin: "15px 0px", display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div
            style={{
              //pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative"
            }}
            onClick={() => focusInput()}
            className={`coverPreviewContainer`}
          >
            <div class="overlay"></div>
            <HtmlRenderer
              width={250}
              isInteractive={false}
              previewMode={false}
              item={item.toJS()}
              page={0}
              realBorder={true}
              photoBookBorders={true}
              lowResScaling={true}
              showBorderCanvas={false}
              key={`cover-text-page-preview`}
              uniquekey="coverEditor"
              highlightText={true}
            />
            <div className="coverArrowContainer">
              <Icon name="hand-arrow" large />
            </div>
            
          </div>
          <div style={{
            background: '#eaeaea',
            padding: '10px',
            fontSize: '12px',
            borderRadius: '5px',
            width: '100%',
            maxWidth: '570px',
            marginTop:'27px'
          }}
          >
            <p>This is a preview of the front cover of your photo book. Add a title and subtitle (or leave blank) and then tap Done. After you have tapped Done you can change your front cover photo and design (We’ve got lots of other cover design templates to choose from!)</p>
          </div>
        </div>
      )}
      <div className="textEditFunctions">
        <div
          className={
            forCover || isEditingCoverPage ? "textAreaContainer" : "textAreaContainerFixed"
          }
        >
          {forCover || isEditingCoverPage ? (
            <>
              <textarea
                ref={textareaRef}
                onChange={handleTextChange}
                onFocus={headingInputFocused}
                placeholder={`Write a title or leave blank`}
                rows={5}
                maxLength={getCharacterLimit()}
                // className="text-area"
                style={{
                  // minHeight:"28 px",
                  fontFamily: selectedFontFamily,
                  // fontSize: 28,
                  //fontSize: 16+(((COVER_FONT_SIZES.findIndex(item => item.size === selectedFontSize))+1)*4),
                  fontSize: textPlaceholderFontSize,
                  textAlign: selectedAlignment,
                  width: "100%",
                  paddingBottom: 0,
                  // paddingTop: selectPaddingTop,
                  boxShadow: "1px 1px 1px 3px #fbfbfb",
                  //border: inputFocused !== "subtext" ? "1px solid black" : "1px solid #eee",
                  borderRadius: "4px",
                  marginBottom: "15px",
                  padding: "5px",
                  //maxWidth: '600px',
                  minHeight: isFirstTime ? '120px' : '160px',
                  color: selectedFontColour,
                  backgroundColor: selectedFontColour === "#FFFFFF" ? "#939393" : "#FFFFFF",
                  height: "160px"
                }}
                value={doc.text}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <span
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginRight: "10px",
                  fontSize: "11px",
                  marginBottom: "8px",
                }}
                >Size: {selectedFontSize}</span>
                <span
                  style={{
                    textAlign: "right",
                    width: "100%",
                    marginRight: "10px",
                    fontSize: "11px",
                    marginBottom: "8px",
                  }}
                >Chars: {`${textLength}/${getCharacterLimit()}`}</span>
              </div>
              
              <TextEditingTools
                forCover={forCover}
                renderEdit={renderEdit}
                isEditingCoverPage={isEditingCoverPage}
                selectedAlignment={selectedAlignment}
                selectedVerticalAlignment={selectedVerticalAlignment}

                setFontModalOpen={(val) => setFontModalOpenForActiveTextComponent("text",val)}
                setFontSizeSliderModalOpen={(val) => setFontSizeSliderModalOpenForActiveTextComponent("text", val)}
                setFontColourModalOpen={(val) => setFontColourModalOpenForActiveTextComponent("text", val)}
                handleTextAlignmentChange={(activeComponent, val)=> handleTextAlignmentChange(activeComponent, val)}
                handleTextHorizontalAlignmentChange={(activeComponent, val) => handleTextHorizontalAlignmentChange(activeComponent, val)}

                handleTextVerticalAlignmentChange={handleTextVerticalAlignmentChange}

                
              />
              <textarea
                // type="text"
                ref={subTextareaRef}
                onChange={handleSubTextChange}
                onFocus={subtextInputFocused}
                placeholder={"Write a subtitle or leave blank"}
                rows={5}
                value={subdoc.text}
                maxLength={DEFAULT_SUBTEXT_LENGTH}
                // className="text-area"
                style={{
                  fontFamily: selectedSubTextFontFamily,
                  //fontSize: 12+(((COVER_FONT_SIZES.findIndex(item => item.size === selectedFontSize))+1)*4),
                  fontSize: subTextPlaceholderFontSize,
                  textAlign: selectedSubTextAlignment,
                  width: "100%",
                  paddingTop: 10,
                  height: "160px",
                  boxShadow: "1px 1px 1px 3px #fbfbfb",
                  border: "none",//inputFocused === "subtext" ? "1px solid black" : "1px solid #eee",
                  borderRadius: "4px",
                  marginBottom: "15px",
                  padding: "5px",
                  maxWidth: '600px',
                  minHeight: isFirstTime ? '170px' : '160px',
                  color: selectedSubTextFontColour,
                  backgroundColor: selectedSubTextFontColour === "#FFFFFF" ? "#939393" : "#FFFFFF"
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <span
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginRight: "10px",
                  fontSize: "11px",
                  marginBottom: "8px",
                }}
                >Size: {selectedSubTextFontSize}</span>
                <span
                  style={{
                    textAlign: "right",
                    width: "100%",
                    marginRight: "10px",
                    fontSize: "11px",
                    marginBottom: "8px",
                  }}
                >Chars: {`${subTextLength}/${DEFAULT_SUBTEXT_LENGTH}`}</span>
              </div>
              <TextEditingTools
                subText={true}
                forCover={forCover}
                renderEdit={renderEdit}
                isEditingCoverPage={isEditingCoverPage}
                selectedAlignment={selectedSubTextAlignment}
                setFontModalOpen={(val) => setFontModalOpenForActiveTextComponent("subtext",val)}
                setFontSizeSliderModalOpen={(val) => setFontSizeSliderModalOpenForActiveTextComponent("subtext", val)}
                setFontColourModalOpen={(val) => setFontColourModalOpenForActiveTextComponent("subtext", val)}

                handleTextAlignmentChange={(activeComponent, val)=> handleTextAlignmentChange(activeComponent, val)}
                handleTextHorizontalAlignmentChange={(activeComponent, val) => handleTextHorizontalAlignmentChange(activeComponent, val)}
              />
            </>
          ) : (
            <textarea
              ref={textareaRef}
              onChange={handleTextChange}
              placeholder={forCover ? "Enter cover page text" : "Enter some text here"}
              rows={5}
              //maxLength={getCharacterLimit()}
              // className={isSafari() ? "text-area-safari" : "text-area"}
              className={"text-area"}
              style={{
                fontFamily: selectedFontFamily,
                fontSize: selectedFontSize,
                textAlign: selectedAlignment,
                paddingTop: selectPaddingTop,
                borderWidth: 0,
              }}
              value={doc.text}
            />
          )}
        </div>

        {!(forCover || isEditingCoverPage) && (
          <TextEditingTools
            renderEdit={renderEdit}
            forCover={forCover}
            isEditingCoverPage={isEditingCoverPage}
            setFontModalOpen={setFontModalOpen}
            setFontSizeSliderModalOpen={setFontSizeSliderModalOpen}
            setFontColourModalOpen={setFontColourModalOpen}                
            handleTextAlignmentChange={handleTextAlignmentChange}
            handleTextHorizontalAlignmentChange={handleTextHorizontalAlignmentChange}
            selectedAlignment={selectedAlignment}
            selectedVerticalAlignment={selectedVerticalAlignment}
            handleTextVerticalAlignmentChange={handleTextVerticalAlignmentChange}
          />
        )}
        
        {/* <div
          className="actions"
          style={{marginRight: 0, width: 'calc(100% - 32px)', maxWidth: '570px', padding: '10px 5px', marginTop:'40px'}}
        >
          <span class="actions__help-title">Book Colour</span>
          <HorizontalScroller key="colour-options" style={{padding: '0px', display: 'flex', maxWidth: '80vw'}}>
            {colours.map((colour, index) => {
                return (
                  <div
                    onClick={() => onChangeBookColour(colour.hex)}
                    className={`each-colour-swatch-container ${selectedBookColour === colour.hex ? 'active' : ''}`}
                    style={{margin : '5px'}}
                    >
                      <div
                        className={`each-colour-swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                        style={{ backgroundColor: colour.hex, color: colour?.textColor, width: '40px', height: '40px' }}
                      ></div>
                  </div>
                )
              })}
          </HorizontalScroller>
        </div> */}
        <Row>
          <Col style={{ marginBottom: 5, display: 'none'}}>
            {inputFocused === "heading" && (
              <>
                <Button
                  onClick={() => undoDoc()}
                  disabled={!canUndo}
                  style={{ marginRight: "8px" }}
                >
                  <UndoOutlined />
                </Button>
                <Button
                  onClick={() => redoDoc()}
                  disabled={!canRedo}
                  style={{ marginRight: "8px" }}
                >
                  <RedoOutlined />
                </Button>
              </>
            )}
            {inputFocused === "subtext" && (
              <>
                <Button
                  onClick={() => undosubDoc()}
                  disabled={!cansubUndo}
                  style={{ marginRight: "8px" }}
                >
                  <UndoOutlined />
                </Button>
                <Button
                  onClick={() => redosubDoc()}
                  disabled={!cansubRedo}
                  style={{ marginRight: "8px" }}
                >
                  <RedoOutlined />
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                clearText();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Col>
          {/* <Col className="word-row">
            <Row>
              {!forCover && !isEditingCoverPage ? (
                <Row span="8">
                  {textLength}/{getCharacterLimit()}
                </Row>
              ) : (
                <></>
              )}
            </Row>
          </Col> */}
        </Row>
      </div>
      {((forCover || isEditingCoverPage) && !renderEdit) && (
        <span style={{ position: "absolute", zIndex: 1000, top: 22, fontSize: '18px' }}>Photo book title</span>
      )}
      {!renderEdit && (
        <Button
        className="ant-custom-btn"
        style={{ position: "absolute", top: 20, right: 20, zIndex: 1000 }}
        onClick={onPressSave}
        disabled={isContentTooBig || isContentTooWide}
        >
          Done
        </Button>
      )}
      
      {/* style={{opacity:0, position: 'absolute', pointerEvents: 'none'}} */}
      <div style={{opacity:0, position: 'absolute', pointerEvents: 'none'}}>
        <HtmlRenderer
          //width={130 * 2.5}
          //width={window.innerWidth > 700 ? 325 : window.innerWidth-60}
          width={renderWidth}
          isInteractive={false}
          previewMode={false} 
          item={itemForCharCheck.toJS()}
          page={templateData.pageNumber}
          screenshotMode={true}
          realBorder={true}
          photoBookBorders={true}
          lowResScaling={true}
          showBorderCanvas={false}
          key={`size-checker`}
          forCharacterChecking={true}
          placeholderIdToCheck={defPlaceholder.id}
          onOverflow={(isOverflow) => handleSetIsContentTooBig(isOverflow)}
          onWrap={(isWrapped) => handleSetIsContentTooWide(isWrapped)}
          placeholderContent={placeholder?.properties?.content?.length}
          reRender={true}
        />
      </div>
    </div>
  );
};

export default TextEditor;
