import React from "react";
import classNames from "classnames";

import Currency from "../Formatters/Currency";

import "./ProductPreview.scss";

const DIMENSIONS_INDICATOR_WIDTH = 16;

const ProductPreview = ({
  product,
  showPricingInfo = false,
  isHighlighted = false,
  onClick = () => {},
}) => {
  const dimensionsIndicatorStyling = {
    width: DIMENSIONS_INDICATOR_WIDTH,
    height: DIMENSIONS_INDICATOR_WIDTH / (product.get("width") / product.get("height")),
  };

  const classes = classNames("product-preview", {
    "product-preview--highlighted": isHighlighted,
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className="product-preview__dimensions-indicator" style={dimensionsIndicatorStyling} />
      <div className="product-preview__info">
        <div className="product-preview__name">{product.get("name")}</div>
        {showPricingInfo && (
          <div className="product-preview__pricing">
            From <Currency amount={product.getIn(["pricingScheme", "cost"])} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPreview;
