import { fromJS, Map } from "immutable";
import findKey from "lodash/findKey";
import postsnapApi from '../lib/apis/postsnap';

const DESIGNS_API_DATA = fromJS(require("../api-data/designs"));
const CACHED_DESIGNS_DATA = fromJS(require("../api-data/cached-designs"));

export const designsById = new Map(
  DESIGNS_API_DATA.get('designs').map(v => [v.get("id"), v])
);

export const designsByProductId = DESIGNS_API_DATA.get("designs").groupBy(design =>
  design.get("product_id")
);

export const cachedDesigns = CACHED_DESIGNS_DATA.mapEntries(([key, value]) => {
  return [+key, value];
});

//const greetingCardProductIds = productsByProductTypeId
//  .get(PRODUCT_TYPE_IDS.GREETING_CARD)
//  .map(item => item.get("id"));

const greetingCardProductIds = fromJS([]);

// export const greetingCardDesignsByCategory = designsByProductId.get(greetingCardProductId)
//   .groupBy(design => design.get('category_id'));

export const greetingCardDesignsByCategory = greetingCardProductIds.flatMap(
  greetingCardProductId => {
    if (designsByProductId.get(greetingCardProductId)) {
      return designsByProductId
        .get(greetingCardProductId)
        .groupBy(design => design.get("category_id"));
    } else {
      return [];
    }
  }
);

export async function getRemoteDesignBySlug(designSlug){
  return await postsnapApi.designs.getDetailsBySlug(designSlug);
}

export const greetingCardDesignsByCategoriesMap = new Map();

export const greetingCardDesignsByCategories = greetingCardProductIds.flatMap(
  greetingCardProductId => {
    if (designsByProductId.get(greetingCardProductId) !== undefined) {
      designsByProductId.get(greetingCardProductId).forEach(design => {
        design.get("category_ids").forEach(category_id => {
          if (!greetingCardDesignsByCategoriesMap[category_id]) {
            greetingCardDesignsByCategoriesMap[category_id] = [design];
          } else {
            greetingCardDesignsByCategoriesMap[category_id].push(design);
          }
        });
      });
    }
  }
);

export function getDesignForDesignId(designId) {
  return DESIGNS_API_DATA.get("designs").find(design => design.get("id") === designId);
}

export function getDesignForDesignUrlSlug(slug) {
  return DESIGNS_API_DATA.get("designs").find(design => design.get("slug") === slug);
}

export function getCachedDesignForDesignUrlSlug(slug) {
  return findKey(cachedDesigns.toJS(), { 'slug': slug });
  //return CACHED_DESIGNS_DATA.get("designs").find(design => design.get("slug") === slug);
}

export const greetingCardCategories = DESIGNS_API_DATA.get("categories");
