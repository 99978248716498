import { fromJS } from "immutable";
import { handle } from "redux-pack";
//import LogRocket from "logrocket";
import KlaviyoTracking from "../../utils/klaviyo";

import * as STORAGE_KEYS from "../../constants/storage-keys";
import postsnapApi from "../../lib/apis/postsnap";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

export const types = {
  SIGN_IN: "POSTSNAP/AUTH/SIGN_IN",
  SIGN_OUT: "POSTSNAP/AUTH/SIGN_OUT",
  SIGN_UP: "POSTSNAP/AUTH/SIGN_UP",
  REQUEST_PASSWORD_RESET: "POSTSNAP/AUTH/REQUEST_PASSWORD_RESET",
  RESET_PASSWORD: "POSTSNAP/AUTH/RESET_PASSWORD",
  CONFIRM_EMAIL: "POSTSNAP/AUTH/CONFIRM_EMAIL",
  CONVERT_GUEST_ACCOUNT: "POSTSNAP/USER/CONVERT_GUEST_ACCOUNT",
  FETCH_USER_DATA_WITH_AUTH_TOKEN: "POSTSNAP/USER/FETCH_USER_DATA_WITH_AUTH_TOKEN",
  UPDATE_USER: "POSTSNAP/USER/UPDATE_USER",
  FETCH_ORDER_HISTORY: "POSTSNAP/USER/FETCH_ORDER_HISTORY",
  FETCH_TOPUP_HISTORY: "POSTSNAP/USER/FETCH_TOPUP_HISTORY",
  CREATE_TOPUP_PURCHASE: "POSTSNAP/AUTH/CREATE_TOPUP_PURCHASE",
  CREATE_TOPUP_PAYMENT_INTENT: "POSTSNAP/AUTH/CREATE_TOPUP_PAYMENT_INTENT",
  CHARGE_STRIPE_PAYMENT_FOR_TOPUP_PURCHASE:
    "POSTSNAP/AUTH/CHARGE_STRIPE_PAYMENT_FOR_TOPUP_PURCHASE",
  CHARGE_STRIPE_CUSTOMER_FOR_TOPUP_PURCHASE:
    "POSTSNAP/AUTH/CHARGE_STRIPE_CUSTOMER_FOR_TOPUP_PURCHASE",
  CONFIRM_PAYPAL_PAYMENT_FOR_TOPUP_PURCHASE:
    "POSTSNAP/AUTH/CONFIRM_PAYPAL_PAYMENT_FOR_TOPUP_PURCHASE",
  REMOVE_SAVED_CARD: "POSTSNAP/AUTH/REMOVE_SAVED_CARD",
  FETCH_ORDER_BY_REFERENCE: "POSTSNAP/AUTH/FETCH_ORDER_BY_REFERENCE",
  SET_GUEST_CHECKOUT_DETAILS: "POSTSNAP/AUTH/SET_GUEST_CHECKOUT_DETAILS",
  UNSET_GUEST_CHECKOUT_DETAILS: "POSTSNAP/AUTH/UNSET_GUEST_CHECKOUT_DETAILS",
  SET_GUEST_TOKEN: "POSTSNAP/AUTH/SET_GUEST_TOKEN",
  UNSET_GUEST_TOKEN: "POSTSNAP/AUTH/UNSET_GUEST_TOKEN",
  SET_CHOSEN_GUEST_ROUTE: "POSTSNAP/AUTH/SET_CHOSEN_GUEST_ROUTE",
};

let INITIAL_STATE = fromJS(
  getStoreDebugStateOrDefaultState("auth", {
    authToken: null,
    guestToken: null,    
    user: null,
    guestCheckoutDetails: {},
    hasSetGuestCheckoutDetails: false,
    hasChosenGuestRoute: false,
    orderHistory: {
      data: [],
      loading: false,
    },
    orderByReference: {
      data: {},
      loading: false,
    },
    topUpHistory: {
      data: [],
      loading: false,
    },
  })
);

try {
  let storedAuthToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  let storedGuestToken = localStorage.getItem(STORAGE_KEYS.GUEST_TOKEN);
  let storedChosenGuestOrCustomer = localStorage.getItem(STORAGE_KEYS.CHOSEN_GUEST_ROUTE);
  let storedUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER));

  if (localStorage.getItem(STORAGE_KEYS.DEBUG_STATE)) {
    console.warn(
      "Using authToken & user from stored postsnap.debugState, not from postsnap.authToken & postsnap.user!"
    );
    const debugState = JSON.parse(localStorage.getItem(STORAGE_KEYS.DEBUG_STATE));
    storedAuthToken = debugState.auth.authToken;
    storedUser = debugState.auth.user;
  }

  if (storedAuthToken && storedUser) {
    INITIAL_STATE = INITIAL_STATE.withMutations(state => {
      state.set("authToken", storedAuthToken);
      state.set("user", fromJS(storedUser));
    });
  }

  let storedGuestDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.GUEST_USER));
  if (storedGuestDetails) {
    INITIAL_STATE = INITIAL_STATE.withMutations(state => {
      state.set("guestCheckoutDetails", fromJS(storedGuestDetails));
      state.set("hasSetGuestCheckoutDetails", true);
    });
  }

  if (storedGuestToken) {
    INITIAL_STATE = INITIAL_STATE.withMutations(state => {
      state.set("guestToken", storedGuestToken);
    });
  }

  if (storedChosenGuestOrCustomer){
    INITIAL_STATE = INITIAL_STATE.withMutations(state => {
      state.set("hasChosenGuestRoute", storedChosenGuestOrCustomer);
    });
  }

} catch (err) {
  process.env.NODE_ENV !== "test" &&
    console.warn("Error while retrieving auth token and user from localStorage:", err);
}

export function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  const setSignedInCookie = () => {
    document.cookie = `isSignedIn=true; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;
  };

  const deleteSignedInCookie = () => {
    document.cookie = `isSignedIn=false; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;
  };

  switch (type) {
    case types.SIGN_IN:
      return handle(state, action, {
        success: prevState => {
          if (payload.data.success) {
            setSignedInCookie();
            KlaviyoTracking.identifyUser(payload.data.data.customer.email, payload.data.data.customer.first_name, payload.data.data.customer.last_name, false)
            // LogRocket.identify(payload.data.data.customer.id, {
            //   name: `${payload.data.data.customer.first_name} ${payload.data.data.customer.last_name}`,
            //   email: payload.data.data.customer.email,
            // });
            return prevState.withMutations(state => {
              state.set("authToken", payload.data.data.auth_token);
              state.set("user", fromJS(payload.data.data.customer));
            });
          } else {
            return prevState;
          }
        },
      });
    case types.SIGN_UP:
      return handle(state, action, {
        success: prevState => {
          if (payload.data.success) {
            if (true){ // payload.data.data.customer.marketing_opt_in === true
              KlaviyoTracking.identifyUser(payload.data.data.customer.email, payload.data.data.customer.first_name, payload.data.data.customer.last_name, false)
            }
            //setSignedInCookie();
            // LogRocket.identify(payload.data.data.customer.id, {
            //   name: `${payload.data.data.customer.first_name} ${payload.data.data.customer.last_name}`,
            //   email: payload.data.data.customer.email,
            // });

            return prevState.withMutations(state => {
              state.set("authToken", payload.data.data.auth_token);
              state.set("user", fromJS(payload.data.data.customer));
            });
          } else {
            return prevState;
          }
        },
      });
    case types.CONVERT_GUEST_ACCOUNT:
      return handle(state, action, {
        success: prevState => {
          if (payload.data.success) {
            return prevState.withMutations(state => {
              state.set("authToken", payload.data.data.auth_token);
              state.set("user", fromJS(payload.data.data.customer));
            });
          } else {
            return prevState;
          }
        },
      });
    case types.FETCH_USER_DATA_WITH_AUTH_TOKEN:
      return handle(state, action, {
        success: prevState => {
          if (payload.data.success) {
            return prevState.withMutations(state => {
              state.set("authToken", payload.data.data.auth_token);
              state.set("user", fromJS(payload.data.data.customer));
            });
          } else {
            return prevState;
          }
        },
        failure: prevState => {
          return prevState;
        },
      });
    case types.FETCH_ORDER_HISTORY:
      return handle(state, action, {
        start: prevState => prevState.setIn(["orderHistory", "loading"], true),
        finish: prevState => prevState.setIn(["orderHistory", "loading"], false),
        success: prevState => {
          if (payload.data.success) {
            return state.withMutations(state => {
              state.setIn(["orderHistory", "data"], fromJS(payload.data.data.created));
            });
          }
          return prevState;
        },
      });
    case types.FETCH_ORDER_BY_REFERENCE:
      return handle(state, action, {
        start: prevState => prevState.setIn(["orderByReference", "loading"], true),
        finish: prevState => prevState.setIn(["orderByReference", "loading"], false),
        success: prevState => {
          if (payload.data.success) {
            return state.withMutations(state => {
              state.setIn(["orderByReference", "data"], fromJS(payload.data.data));
            });
          }
          return prevState;
        },
      });
    case types.FETCH_TOPUP_HISTORY:
      return handle(state, action, {
        start: prevState => prevState.setIn(["topUpHistory", "loading"], true),
        finish: prevState => prevState.setIn(["topUpHistory", "loading"], false),
        success: prevState => {
          if (payload.data.success) {
            return state.withMutations(state => {
              state.setIn(["topUpHistory", "data"], fromJS(payload.data.data.objects));
            });
          }
          return prevState;
        },
      });
    case types.REMOVE_SAVED_CARD:
      return handle(state, action, {
        success: prevState => {
          if (payload.data.success) {
            return state.withMutations(state => {
              state.setIn(["stripe_card_details"], fromJS({}));
            });
          }
          return prevState;
        },
      });
    case types.SIGN_OUT:
      deleteSignedInCookie();
      return state.withMutations(state => {
        state.set("authToken", null);
        state.set("user", null);
      });
    case types.SET_GUEST_CHECKOUT_DETAILS: {
      return state.withMutations(state => {
        state.set("guestCheckoutDetails", fromJS(payload.guestCheckoutDetails));
        state.set("hasSetGuestCheckoutDetails", true);
      });
    }
    case types.UNSET_GUEST_CHECKOUT_DETAILS: {
      return state.withMutations(state => {
        state.set("guestCheckoutDetails", fromJS({}));
        state.set("hasSetGuestCheckoutDetails", false);
        state.set("guestToken", null);
        state.set("authToken", null);
        state.set("user", null);
      });
    }
    case types.SET_GUEST_TOKEN: {
      return state.withMutations(state => {
        state.set("guestToken", payload.guestToken);
      });
    }
    case types.UNSET_GUEST_TOKEN: {
      return state.withMutations(state => {
        state.set("guestToken", null);
      });
    }
    case types.SET_CHOSEN_GUEST_ROUTE: {
      return state.withMutations(state => {
        state.set("hasChosenGuestRoute", payload.hasChosenGuestRoute);
      });
    }
    default:
      return state;
  }
}

export const actions = {
  signIn: ({ email, password, authToken }) => ({
    type: types.SIGN_IN,
    promise: postsnapApi.auth.signIn({ email, password, authToken }),
  }),
  signOut: () => ({
    type: types.SIGN_OUT,
  }),
  signUp: userData => ({
    type: types.SIGN_UP,
    promise: postsnapApi.auth.signUp(userData),
  }),
  requestPasswordReset: email => ({
    type: types.REQUEST_PASSWORD_RESET,
    promise: postsnapApi.auth.requestPasswordResetForEmail(email),
  }),
  resetPassword: ({ password, token }) => ({
    type: types.RESET_PASSWORD,
    promise: postsnapApi.auth.resetPassword(password, token),
  }),
  confirmEmail: ({ confirmationToken, env }) => ({
    type: types.CONFIRM_EMAIL,
    promise: postsnapApi.auth.confirmEmail(confirmationToken, env),
  }),
  convertGuestAccount: (userData) => ({
    type: types.CONVERT_GUEST_ACCOUNT,
    promise: postsnapApi.auth.convertUserFromGuest(userData),
    meta: {
      userData,
    },
  }),
  fetchUserDataWithAuthToken: authToken => ({
    type: types.FETCH_USER_DATA_WITH_AUTH_TOKEN,
    promise: postsnapApi.auth.signInWithAuthToken(authToken),
  }),
  updateUser: userData => ({
    type: types.UPDATE_USER,
    promise: postsnapApi.auth.updateUser(userData),
    meta: {
      userData,
    },
  }),
  fetchOrderHistory: () => ({
    type: types.FETCH_ORDER_HISTORY,
    promise: postsnapApi.auth.getOrderHistory(),
  }),
  fetchOrderByReference: reference => ({
    type: types.FETCH_ORDER_BY_REFERENCE,
    promise: postsnapApi.auth.getOrderByReference(reference),
  }),
  fetchTopUpHistory: () => ({
    type: types.FETCH_TOPUP_HISTORY,
    promise: postsnapApi.auth.getTopUpHistory(),
  }),
  createTopupPurchase: topupId => ({
    type: types.CREATE_TOPUP_PURCHASE,
    promise: postsnapApi.topups.createTopupPurchase(topupId),
  }),
  createTopupPaymentIntent: intentData => ({
    type: types.CREATE_TOPUP_PAYMENT_INTENT,
    promise: postsnapApi.topups.createTopupPaymentIntent(intentData),
  }),
  chargeStripePaymentForTopupPurchase: ({ stripeToken, reference, saveCardDetails }) => ({
    type: types.CHARGE_STRIPE_PAYMENT_FOR_TOPUP_PURCHASE,
    promise: postsnapApi.topups.chargeStripePaymentForTopupPurchase({
      stripeToken,
      reference,
      saveCardDetails,
    }),
  }),
  chargeStripeCustomerForTopupPurchase: reference => ({
    type: types.CHARGE_STRIPE_CUSTOMER_FOR_TOPUP_PURCHASE,
    promise: postsnapApi.topups.chargeStripeCustomerForTopupPurchase(reference),
  }),
  confirmPaypalPaymentForTopupPurchase: ({ token, reference }) => ({
    type: types.CONFIRM_PAYPAL_PAYMENT_FOR_TOPUP_PURCHASE,
    promise: postsnapApi.topups.confirmPaypalPaymentForTopupPurchase({
      token,
      reference,
    }),
  }),
  removeSavedCard: () => ({
    type: types.CONFIRM_PAYPAL_PAYMENT_FOR_TOPUP_PURCHASE,
    promise: postsnapApi.auth.removeSavedCard(),
  }),
  setGuestCheckoutDetails: (guestCheckoutDetails) => {
    return {
      type: types.SET_GUEST_CHECKOUT_DETAILS,
      payload: { guestCheckoutDetails },
    };
  },
  clearGuestDetails: () => {
    return {
      type: types.UNSET_GUEST_CHECKOUT_DETAILS
    };
  },
  setGuestToken: (guestToken) => {
    return {
      type: types.SET_GUEST_TOKEN,
      payload: { guestToken },
    };
  },
  clearGuestToken: () => {
    return {
      type: types.UNSET_GUEST_TOKEN
    };
  },
  setHasChosenGuestRoute: (hasChosenGuestRoute) => {
    return {
      type: types.SET_CHOSEN_GUEST_ROUTE,
      payload: { hasChosenGuestRoute },
    };
  },
};

export const selectors = {
  getUser: state => state.auth.get("user"),
  getAuthToken: state => state.auth.get("authToken"),
  getGuestToken: state => state.auth.get("guestToken"),
  getPrePayBalance: state => {
    if (Boolean(selectors.getUser(state))) {
      return {
        amount: parseFloat(state.auth.getIn(["user", "credit_balance"])),
        currency: state.auth.getIn(["user", "currency"]),
      };
    }
  },
  getOrderHistory: state => state.auth.get("orderHistory"),
  getOrderByReference: state => state.auth.get("orderByReference"),
  getTopUpHistory: state => state.auth.get("topUpHistory"),
  hasGuestDetails: state => state.auth.get("hasSetGuestCheckoutDetails"),
  hasChosenGuestRoute: state => state.auth.get("hasChosenGuestRoute"),
  guestDetails: state => state.auth.get("guestCheckoutDetails"),
};
