import * as LAYER_TYPES from "../constants/layer-types";

const LAYER_TYPES_REQUIRING_RENDER = [LAYER_TYPES.TEXT, LAYER_TYPES.PHOTO, LAYER_TYPES.SIGNATURE];

export default function getUnrenderedItems(items, withFailures = false) {
  return items.filter(item => {
    const unrenderedLayers = item.get("layers").filter(layer => {
      const isRenderableLayer = LAYER_TYPES_REQUIRING_RENDER.includes(layer.get("type"));
      const isExtraTextLayer = layer.get("id") === "EXTRA_TEXT_LAYER";
      const hasRender = Boolean(layer.get("render"));
      const hasRenderError = Boolean(item.get("renderFailed"));
      return (
        isRenderableLayer &&
        !isExtraTextLayer &&
        !hasRender &&
        (withFailures ? hasRenderError : !hasRenderError)
      );
    });

    const hasPreview = Boolean(item.get("preview_s3_key"));
    //console.log("Has rendered preview:", hasPreview);
    return !item.get("isRendering") && (unrenderedLayers.size > 0 || !hasPreview);
  });
}
