import { fromJS } from "immutable";

import { selectors as addressBookSelectors } from "./address-book";
import {
  productsByProductId,
  getNextDecreasedQuantityForProductType,
  getNextIncreasedQuantityForProductType,
} from "../../data/products";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

export const types = {
  SET_EDITOR_ITEM: "POSTSNAP/EDITOR/SET_EDITOR_ITEM",
  CLEAR_EDITOR_ITEM: "POSTSNAP/EDITOR/CLEAR_EDITOR_ITEM",
  SET_PRODUCT_ID: "POSTSNAP/EDITOR/SET_PRODUCT_ID",
  SET_PRODUCT_OPTIONS: "POSTSNAP/EDITOR/SET_PRODUCT_OPTIONS",
  CHANGE_PRODUCT_OPTIONS: "POSTSNAP/EDITOR/CHANGE_PRODUCT_OPTIONS",
  SET_S3_KEY_FOR_GRAPHIC_LAYER: "POSTSNAP/EDITOR/SET_S3_KEY_FOR_GRAPHIC_LAYER",
  SET_COLOR_FOR_TEXT_LAYER: "POSTSNAP/EDITOR/SET_COLOR_FOR_TEXT_LAYER",
  SET_TEXT_CONTENT_FOR_TEXT_LAYER: "POSTSNAP/EDITOR/SET_TEXT_CONTENT_FOR_TEXT_LAYER",
  SET_TEXT_POSITION_FOR_TEXT_LAYER: "POSTSNAP/EDITOR/SET_TEXT_POSITION_FOR_TEXT_LAYER",
  SET_IMAGE_SRC_FOR_PHOTO_REGION: "POSTSNAP/EDITOR/SET_IMAGE_SRC_FOR_PHOTO_REGION",
  SET_IMAGE_CROP_DATA_FOR_PHOTO_REGION: "POSTSNAP/EDITOR/SET_IMAGE_CROP_DATA_FOR_PHOTO_REGION",
  SET_IMAGE_POSITION_FOR_PHOTO_REGION: "POSTSNAP/EDITOR/SET_IMAGE_POSITION_FOR_PHOTO_REGION",
  SET_FILTER_FOR_PHOTO_REGION: "POSTSNAP/EDITOR/SET_FILTER_FOR_PHOTO_REGION",
  SET_BORDER_THICKNESS_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_BORDER_THICKNESS_FOR_PHOTO_LAYER",
  SET_BORDER_PRINT_WIDTH_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_BORDER_PRINT_WIDTH_FOR_PHOTO_LAYER",
  SET_REGION_BORDER_RADIUS_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_REGION_BORDER_RADIUS_FOR_PHOTO_LAYER",
  SET_REGION_BORDER_WIDTH_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_REGION_BORDER_WIDTH_FOR_PHOTO_LAYER",
  SET_BORDER_STYLE_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_BORDER_STYLE_FOR_PHOTO_LAYER",
  SET_LAYOUT_FOR_PHOTO_LAYER: "POSTSNAP/EDITOR/SET_LAYOUT_FOR_PHOTO_LAYER",
  SWAP_REGION_IMAGES: "POSTSNAP/EDITOR/SWAP_REGION_IMAGES",
  UPDATE_TEXT_LAYER_CONFIG: "POSTSNAP/EDITOR/UPDATE_TEXT_LAYER_CONFIG",
  UPDATE_SIGNATURE_FOR_SIGNATURE_LAYER: "POSTSNAP/EDITOR/UPDATE_SIGNATURE_FOR_SIGNATURE_LAYER",
  SET_ADDRESS: "POSTSNAP/EDITOR/SET_ADDRESS",
  SET_ADDRESS_BOOK_ID: "POSTSNAP/EDITOR/SET_ADDRESS_BOOK_ID",
  SET_ADDRESS_BOOK_ENTRY: "POSTSNAP/EDITOR/SET_ADDRESS_BOOK_ENTRY",
  SET_POST_DATE: "POSTSNAP/EDITOR/SET_POST_DATE",
  DECREASE_QUANTITY: "POSTSNAP/EDITOR/DECREASE_QUANTITY",
  CHANGE_DOUBLE_DIRECT: "POSTSNAP/EDITOR/CHANGE_DOUBLE_DIRECT",
  INCREASE_QUANTITY: "POSTSNAP/EDITOR/INCREASE_QUANTITY",
  SET_PACK_QUANTITY: "POSTSNAP/EDITOR/SET_PACK_QUANTITY",
  SET_PACK_SCHEME: "POSTSNAP/EDITOR/SET_PACK_SCHEME",
};

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("editor", {
    item: null,
  })
);

function updateLayer(state, layerId, updater) {
  return state.updateIn(["item", "layers"], layers =>
    layers.map(layer => {
      if (layer.get("id") === layerId) {
        return layer.update(updater);
      }

      return layer;
    })
  );
}

export function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_EDITOR_ITEM:
      return state.set("item", fromJS(payload));
    case types.CLEAR_EDITOR_ITEM:
      return state.set("item", null);
    case types.SET_PRODUCT_ID:
      const product = productsByProductId.get(payload.productId);
      return state.setIn(["item", "productId"], payload.productId).setIn(
        ["item", "productDimensions"],
        fromJS({
          width: product.get("width"),
          height: product.get("height"),
          dpi: product.get("dpi"),
          bleed: {
            top: parseFloat(product.get("bleed_t")),
            bottom: parseFloat(product.get("bleed_b")),
            left: parseFloat(product.get("bleed_l")),
            right: parseFloat(product.get("bleed_r")),
          },
        })
      ).setIn(["item", "weight"], product.get("weight"));
    case types.SET_PRODUCT_OPTIONS:
      return state.setIn(["item", "product_options"], fromJS(payload.productOptions))
    case types.CHANGE_PRODUCT_OPTIONS:
      return state.withMutations(state => {
        const newProductOptions = fromJS({ ...state.getIn(['item','product_options']).toJS(), ...payload.productOptions })
        state.setIn(["item", "product_options"], newProductOptions);
      });
      //return state.setIn(["item", "product_options"], fromJS(payload.productOptions));
    case types.SET_S3_KEY_FOR_GRAPHIC_LAYER:
      return updateLayer(state, payload.layerId, layer =>
        layer.setIn(["config", "s3_key"], payload.s3Key)
      );
    case types.SET_COLOR_FOR_TEXT_LAYER:
      // TODO: We may want to reset the color when the variantColor is changed
      return updateLayer(state, payload.layerId, layer =>
        layer.setIn(["config", "variantColor"], layer.getIn(["config", "colors", payload.index]))
      );
    case types.SET_TEXT_CONTENT_FOR_TEXT_LAYER:
      return updateLayer(state, payload.layerId, layer =>
        layer.setIn(["config", "text"], payload.content)
      );
    case types.SET_TEXT_POSITION_FOR_TEXT_LAYER:
      return updateLayer(state, payload.layerId, layer =>
        layer.mergeIn(["config", "rect"], fromJS(payload.position))
      );
    case types.SET_IMAGE_SRC_FOR_PHOTO_REGION:
      return updateLayer(state, payload.layerId, layer => {
        if (layer.getIn(["config", "layout", payload.regionIndex, "image"])) {
          return layer.withMutations(layerToMutate => {
            layerToMutate.mergeIn(
              ["config", "layout", payload.regionIndex, "image", "src"],
              fromJS(payload.src)
            );
            layerToMutate.mergeIn(
              ["config", "layout", payload.regionIndex, "image", "originalInfo"],
              fromJS({
                ...payload.image.originalImageInfo,
                uuid: payload.image.uuid,
                size: payload.image.size
              })
            );
            layerToMutate.setIn(
              ["config", "layout", payload.regionIndex, "image", "cropData"],
              null
            );
          });
        } else {
          return layer.setIn(
            ["config", "layout", payload.regionIndex, "image"],
            fromJS({
              src: payload.src,
              originalInfo: {
                ...payload.image.originalImageInfo,
                uuid: payload.image.uuid,
                size: payload.image.size
              },
              cropData: null,
            })
          );
        }
      });
    case types.SET_IMAGE_POSITION_FOR_PHOTO_REGION:
      return updateLayer(state, payload.layerId, layer => {
        return layer.updateIn(
          ["config", "layout", payload.regionIndex, "image", "position"],
          position =>
            position ? position.merge(fromJS(payload.position)) : fromJS(payload.position)
        );
      });
    case types.SET_IMAGE_CROP_DATA_FOR_PHOTO_REGION:
      return updateLayer(state, payload.layerId, layer => {
        return layer.updateIn(
          ["config", "layout", payload.regionIndex, "image", "cropData"],
          cropData =>
            cropData ? cropData.merge(fromJS(payload.cropData)) : fromJS(payload.cropData)
        );
      });
    case types.SWAP_REGION_IMAGES:
      const indexOfSourceLayer = state
        .getIn(["item", "layers"])
        .findIndex(layer => layer.get("id") === payload.sourceLayerId);
      const indexOfDestinationLayer = state
        .getIn(["item", "layers"])
        .findIndex(layer => layer.get("id") === payload.destinationLayerId);
      const sourceImageKeyPath = [
        "layers",
        indexOfSourceLayer,
        "config",
        "layout",
        payload.sourceRegionIndex,
        "image",
      ];
      const destinationImageKeyPath = [
        "layers",
        indexOfDestinationLayer,
        "config",
        "layout",
        payload.destinationRegionIndex,
        "image",
      ];

      return state.update("item", item => {
        return item.withMutations(mutatedItem => {
          const sourceRegionImage = mutatedItem.getIn(sourceImageKeyPath);
          const destinationRegionImage = mutatedItem.getIn(destinationImageKeyPath);

          mutatedItem.setIn(destinationImageKeyPath, sourceRegionImage.set("cropData", null));
          if (destinationRegionImage) {
            mutatedItem.setIn(sourceImageKeyPath, destinationRegionImage.set("cropData", null));
          } else {
            mutatedItem.setIn(sourceImageKeyPath, undefined);
          }
        });
      });

      // return updateLayer(state, payload.layerId, layer => {
      //   return layer.withMutations(layer => {
      //     const sourceImageKeyPath = ["config", "layout", payload.sourceRegionIndex, "image"];
      //     const destinationImageKeyPath = [
      //       "config",
      //       "layout",
      //       payload.destinationRegionIndex,
      //       "image",
      //     ];
      //     const sourceRegionImage = layer.getIn(sourceImageKeyPath);
      //     const destinationRegionImage = layer.getIn(destinationImageKeyPath);

      //     layer.setIn(destinationImageKeyPath, sourceRegionImage.set("cropData", null));
      //     if (destinationRegionImage) {
      //       layer.setIn(sourceImageKeyPath, destinationRegionImage.set("cropData", null));
      //     } else {
      //       layer.setIn(sourceImageKeyPath, undefined);
      //     }
      //   });
      // });
    case types.SET_FILTER_FOR_PHOTO_REGION:
      return updateLayer(state, payload.layerId, layer =>
        layer.setIn(["config", "layout", payload.regionIndex, "image", "filter"], payload.filter)
      );
    case types.SET_REGION_BORDER_RADIUS_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer => 
        layer.setIn(["config", "regionBorderRadius"], payload.radius)
      );
    case types.SET_REGION_BORDER_WIDTH_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer => 
        layer.setIn(["config", "regionBorderWidth"], payload.width)
      );
    case types.SET_BORDER_THICKNESS_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer => 
        layer.setIn(["config", "border", "thickness"], payload.thickness)    
      );
    case types.SET_BORDER_PRINT_WIDTH_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer => 
        layer.setIn(["config", "borderPrintWidth", "width"], payload.width)    
      );
    case types.SET_BORDER_STYLE_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer =>
        layer.setIn(["config", "border", "style"], fromJS(payload.style))
      );
    case types.SET_LAYOUT_FOR_PHOTO_LAYER:
      return updateLayer(state, payload.layerId, layer => {
        const newLayout = fromJS(payload.layout);
        return layer.withMutations(layer => {
          layer.updateIn(["config", "layout"], layout => layout.setSize(newLayout.size));
          layer.updateIn(["config", "layout"], layout =>
            layout.map((region, regionIndex) => {
              if (region) {
                const updatedRegion = region.merge(newLayout.get(regionIndex));

                if (updatedRegion.get("image")) {
                  return updatedRegion.setIn(["image", "cropData"], null);
                }

                return updatedRegion;
              } else {
                return fromJS(newLayout.get(regionIndex));
              }
            })
          );
        });
      });
    case types.UPDATE_TEXT_LAYER_CONFIG:
      return updateLayer(state, payload.layerId, layer =>
        layer.mergeIn(["config"], fromJS(payload.config))
      );
    case types.UPDATE_SIGNATURE_FOR_SIGNATURE_LAYER:
      return updateLayer(state, payload.layerId, layer =>
        layer.update("config", config => config.merge(payload.signature))
      );
    case types.SET_ADDRESS:
      return state.withMutations(state => {
        if (!state.get("address")) {
          state.setIn(["item", "address"], fromJS(payload));
        } else {
          state.mergeIn(["item", "address"], fromJS(payload));
        }

        state.setIn(["item", "addressBookId"], null);
        state.setIn(["item", "addressBookEntry"], null);
      });
    case types.SET_ADDRESS_BOOK_ID:
      return state.withMutations(state => {
        state.setIn(["item", "addressBookId"], payload.addressBookId);
        state.setIn(["item", "address"], null);
      });
    case types.SET_ADDRESS_BOOK_ENTRY:
      return state.withMutations(state => {
        state.setIn(["item", "addressBookEntry"], fromJS(payload.addressBookEntry));
        state.setIn(["item", "address"], null);
      });
    case types.SET_POST_DATE:
      return state.setIn(["item", "postDate"], payload.date);
    case types.CHANGE_DOUBLE_DIRECT:
      return state.setIn(["item", "isDoubleDirect"], payload.isDoubleDirect);
    case types.DECREASE_QUANTITY:
      return state.setIn(
        ["item", "quantity"],
        getNextDecreasedQuantityForProductType(
          state.getIn(["item", "productTypeId"]),
          state.getIn(["item", "quantity"])
        )
      );
    case types.INCREASE_QUANTITY:
      return state.setIn(
        ["item", "quantity"],
        getNextIncreasedQuantityForProductType(
          state.getIn(["item", "productTypeId"]),
          state.getIn(["item", "quantity"])
        )
      );
    case types.SET_PACK_QUANTITY:
      return state.withMutations(state => {
        state.setIn(["item", "packQuantity"], payload.packQty);
        state.setIn(["item", "quantity"], payload.packQty/8);
      })
      
    case types.SET_PACK_SCHEME:
      return state.withMutations(state => {
        state.setIn(["item", "packScheme"], fromJS(payload.packScheme));
        state.setIn(["item", "packQuantity"], payload.packScheme.get('pack_quantity'));
        state.setIn(["item", "quantity"], payload.packScheme.get('pack_quantity')/8);
        
        const newProductOptions = fromJS({ ...state.getIn(['item','product_options']).toJS(), pack_quantity: payload.packScheme.get('pack_quantity') })
        console.log("newProductOptions", newProductOptions.toJS())
        state.setIn(["item", "product_options"], newProductOptions);
      });
    default:
      return state;
  }
}

export const actions = {
  setEditorItem: item => ({ type: types.SET_EDITOR_ITEM, payload: item }),
  clearEditorItem: () => ({ type: types.CLEAR_EDITOR_ITEM }),
  setProductId: productId => ({
    type: types.SET_PRODUCT_ID,
    payload: { productId },
  }),
  setProductOptions: productOptions => ({
    type: types.SET_PRODUCT_OPTIONS,
    payload: { productOptions },
  }),
  changeProductOptions: productOptions => ({
    type: types.CHANGE_PRODUCT_OPTIONS,
    payload: { productOptions },
  }),
  setS3KeyForGraphicLayer: (layerId, s3Key) => ({
    type: types.SET_S3_KEY_FOR_GRAPHIC_LAYER,
    payload: { layerId, s3Key },
  }),
  setColorForTextLayer: (layerId, index) => ({
    type: types.SET_COLOR_FOR_TEXT_LAYER,
    payload: { layerId, index },
  }),
  setTextContentForTextLayer: (layerId, content) => ({
    type: types.SET_TEXT_CONTENT_FOR_TEXT_LAYER,
    payload: { layerId, content },
  }),
  setTextPositionForTextLayer: (layerId, position) => ({
    type: types.SET_TEXT_POSITION_FOR_TEXT_LAYER,
    payload: { layerId, position },
  }),
  setImageSrcForPhotoRegion: ({ layerId, regionIndex, src, image }) => ({
    type: types.SET_IMAGE_SRC_FOR_PHOTO_REGION,
    payload: { layerId, regionIndex, src, image },
  }),
  setImageCropDataForPhotoRegion: ({ layerId, regionIndex, cropData }) => ({
    type: types.SET_IMAGE_CROP_DATA_FOR_PHOTO_REGION,
    payload: { layerId, regionIndex, cropData },
  }),
  setImagePositionForPhotoRegion: ({ layerId, regionIndex, position }) => ({
    type: types.SET_IMAGE_POSITION_FOR_PHOTO_REGION,
    payload: { layerId, regionIndex, position },
  }),
  setFilterForPhotoRegion: ({ layerId, regionIndex, filter }) => ({
    type: types.SET_FILTER_FOR_PHOTO_REGION,
    payload: { layerId, regionIndex, filter },
  }),
  setBorderThicknessForPhotoLayer: (layerId, thickness) => ({
    type: types.SET_BORDER_THICKNESS_FOR_PHOTO_LAYER,
    payload: { layerId, thickness },
  }),
  setBorderPrintWidthForPhotoLayer: (layerId, width) => ({
    type: types.SET_BORDER_PRINT_WIDTH_FOR_PHOTO_LAYER,
    payload: { layerId, width },
  }),
  setRegionBorderRadiusForPhotoLayer: (layerId, radius) => ({
    type: types.SET_REGION_BORDER_RADIUS_FOR_PHOTO_LAYER,
    payload: { layerId, radius },
  }),
  setRegionBorderWidthForPhotoLayer: (layerId, width) => ({
    type: types.SET_REGION_BORDER_WIDTH_FOR_PHOTO_LAYER,
    payload: { layerId, width },
  }),
  setBorderStyleForPhotoLayer: (layerId, style) => ({
    type: types.SET_BORDER_STYLE_FOR_PHOTO_LAYER,
    payload: { layerId, style },
  }),
  setLayoutForPhotoLayer: (layerId, layout) => ({
    type: types.SET_LAYOUT_FOR_PHOTO_LAYER,
    payload: { layerId, layout },
  }),
  swapRegionImages: ({
    sourceLayerId,
    sourceRegionIndex,
    destinationLayerId,
    destinationRegionIndex,
  }) => ({
    type: types.SWAP_REGION_IMAGES,
    payload: { sourceLayerId, sourceRegionIndex, destinationLayerId, destinationRegionIndex },
  }),
  updateTextLayerConfig: (layerId, config) => ({
    type: types.UPDATE_TEXT_LAYER_CONFIG,
    payload: { layerId, config },
  }),
  setImageForSignatureLayer: (layerId, signature) => ({
    type: types.UPDATE_SIGNATURE_FOR_SIGNATURE_LAYER,
    payload: { layerId, signature },
  }),
  setAddress: address => ({
    type: types.SET_ADDRESS,
    payload: address,
  }),
  setAddressBookId: addressBookId => ({
    type: types.SET_ADDRESS_BOOK_ID,
    payload: { addressBookId },
  }),
  setAddressBookEntry: addressBookEntry => ({
    type: types.SET_ADDRESS_BOOK_ENTRY,
    payload: { addressBookEntry },
  }),
  setPostDate: date => ({
    type: types.SET_POST_DATE,
    payload: { date },
  }),
  changeItemDoubleDirect: isDoubleDirect => ({
    type: types.CHANGE_DOUBLE_DIRECT,
    payload: { isDoubleDirect },
  }),
  decreaseQuantity: () => ({
    type: types.DECREASE_QUANTITY,
  }),
  increaseQuantity: () => ({
    type: types.INCREASE_QUANTITY,
  }),
  setPackQuantity: packQty => ({
    type: types.SET_PACK_QUANTITY,
    payload: { packQty },
  }),
  setPackScheme: packScheme => ({
    type: types.SET_PACK_SCHEME,
    payload: { packScheme },
  }),
};

export const selectors = {
  getItem: state => {
    let item = state.editor.get("item");

    if (item && item.get("addressBookId")) {
      item = item.set(
        "addressBookEntry",
        addressBookSelectors.getEntry(state, item.get("addressBookId"))
      );
    }

    return item;
  },
};
