import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";

import { routeCreators } from "../../lib/routes";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import {
  productsByProductTypeId,
  PRODUCT_TYPE_IDS,
  PRODUCT_TYPE_IDS_BY_SLUG,
} from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import ProductTypeLandingPage from "./ProductTypeLandingPage";
import GreetingCardEditorContainer from "../GreetingCardEditor/GreetingCardEditorContainer";

class ProductTypeLandingPageContainer extends Component {
  state = {};

  render() {
    const { productType } = this.props.match.params;
    let productTypeId = PRODUCT_TYPE_IDS_BY_SLUG[productType];
    const products = productsByProductTypeId.get(productTypeId);

    if (
      products.size === 1 ||
      [
        PRODUCT_TYPE_IDS.POSTCARD ||
          PRODUCT_TYPE_IDS.ANNOUNCEMENT ||
          PRODUCT_TYPE_IDS.INVITATION ||
          PRODUCT_TYPE_IDS.CANVAS,
      ].includes(productTypeId)
    ) {
      //console.log("here", products.first().get("url_slug"));
      if (productTypeId !== PRODUCT_TYPE_IDS.GREETING_CARD) {  
        return (
          <Redirect
            to={routeCreators.productLandingPage(productType, products.first().get("url_slug"))}
          />
        );
      }
    }

    if (productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD) {
      return <GreetingCardEditorContainer />;
    }

    const productsWithPricingSchemeSortedByRatio = products
      .map(product => {
        const pricingScheme = getPricingSchemeForProductAndQuantity({
          productId: product.get("id"),
          quantity: 1,
          currency: this.props.currency,
        });
        return product.set("pricingScheme", pricingScheme);
      })
      .sort((a, b) => a.get("width") / a.get("height") - b.get("width") / b.get("height"));
    return (
      <ProductTypeLandingPage
        productType={productType}
        products={productsWithPricingSchemeSortedByRatio}
      />
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTypeLandingPageContainer);
