import { fromJS } from "immutable";
import gtmEvent from "../../utils/gtm";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";

export const types = {
  TOGGLE_SIDEBAR: "POSTSNAP/UI/TOGGLE_SIDEBAR",
  SHOW_AUTH_MODAL: "POSTSNAP/UI/SHOW_AUTH_MODAL",
  HIDE_AUTH_MODAL: "POSTSNAP/UI/HIDE_AUTH_MODAL",
  SHOW_GUEST_CAPTURE_OR_AUTH_MODAL: "POSTSNAP/UI/SHOW_GUEST_CAPTURE_OR_AUTH_MODAL",
  HIDE_GUEST_CAPTURE_OR_AUTH_MODAL: "POSTSNAP/UI/HIDE_GUEST_CAPTURE_OR_AUTH_MODAL",
  SET_GLOBAL_PROGRESS_BAR_PERCENTAGE: "POSTSNAP/UI/SET_GLOBAL_PROGRESS_BAR_PERCENTAGE",
};

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("ui", {
    isSidebarOpen: false,
    isAuthModalVisible: false,
    isAuthWithAddress: true,
    globalProgressBarPercentage: 0,
    isGuestCaptureOrAuthModalVisible: false,
    callbackAfterGuestDone: () => {},
    callbackAfterGuestAuth: () => {},
  })
);

export function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case "@@router/LOCATION_CHANGE":
      console.log("Changing location", {page: action.payload.pathname})

      return state;
      // return state.withMutations(state => {
      //   state.set("isSidebarOpen", false);
      // });
    case types.TOGGLE_SIDEBAR:
      return state.set("isSidebarOpen", !state.get("isSidebarOpen"));
    case types.SHOW_AUTH_MODAL:
      //return state.set("isAuthModalVisible", true);
      if (payload && payload.postApproval){
        console.log("GTM Show auth modal post approval");
        gtmEvent({event: "webAppStage", additionalProps: {stage: 'Sign In - Post Approval'}})
      } else{
        console.log("GTM Show auth modal pre approval");
        gtmEvent({event: "webAppStage", additionalProps: {stage: 'Sign In - Pre Approval'}})
      }
      return state.withMutations(state => {
        state.set("isAuthModalVisible", true);
        console.log("Payload", payload);
        state.set("isAuthWithAddress", payload && payload.withAddress);
      });
    case types.HIDE_AUTH_MODAL:
      // TODO might need to unset with address?
      return state.set("isAuthModalVisible", false);
    case types.SHOW_GUEST_CAPTURE_OR_AUTH_MODAL:
      return state.withMutations(state => {
        state.set("isGuestCaptureOrAuthModalVisible", true);
        if (payload && payload.actionAfterGuestInfo){
          state.set("callbackAfterGuestDone", payload.actionAfterGuestInfo);
        }
        if (payload && payload.actionAfterAuth){
          state.set("callbackAfterGuestAuth", payload.actionAfterAuth);
        }
      });
    case types.HIDE_GUEST_CAPTURE_OR_AUTH_MODAL:
      return state.set("isGuestCaptureOrAuthModalVisible", false);
    case types.SET_GLOBAL_PROGRESS_BAR_PERCENTAGE:
      return state.set("globalProgressBarPercentage", payload);
    default:
      return state;
  }
}

export const actions = {
  toggleSidebar: () => ({ type: types.TOGGLE_SIDEBAR }),
  showAuthModal: options => ({
    type: types.SHOW_AUTH_MODAL,
    payload: options,
  }),
  hideAuthModal: () => ({ type: types.HIDE_AUTH_MODAL }),
  showGuestCaptureOrAuthModal: options => ({
    type: types.SHOW_GUEST_CAPTURE_OR_AUTH_MODAL,
    payload: options,
  }),
  hideGuestCaptureOrAuthModal: () => ({ type: types.HIDE_GUEST_CAPTURE_OR_AUTH_MODAL }),
  setGlobalProgressBarPercentage: percentage => ({
    type: types.SET_GLOBAL_PROGRESS_BAR_PERCENTAGE,
    payload: percentage,
  }),
};

export const selectors = {
  getSidebarVisibilityStatus: state => state.ui.get("isSidebarOpen"),
  getAuthModalVisibilityStatus: state => state.ui.get("isAuthModalVisible"),
  getAuthModalIsWithAddress: state => state.ui.get("isAuthWithAddress"),
  getGlobalProgressBarPercentage: state => state.ui.get("globalProgressBarPercentage"),
  getGuestCaptureOrAuthModalVisibilityStatus: state => state.ui.get("isGuestCaptureOrAuthModalVisible"),
  actionAfterGuestDetails: state => state.ui.get("callbackAfterGuestDone"),
  actionAfterGuestAuth: state => state.ui.get("callbackAfterGuestAuth"),
};
