import React from "react";
import { Modal as AntModal } from "antd";
import colours from "../../pages/ThemeList/colours.json";

const FontColourSelectionModal = ({ isOpen, onClose, onChangeColour, selectedFontColour }) => {
  return (
    <AntModal visible={isOpen} onCancel={onClose} footer={[]}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "25px",
        }}
      >
        <React.Fragment>
          <div>
            <div className="colour-swatches-container">
              {colours.map((colour, index) => {
                return (
                  <div
                    onClick={() => onChangeColour(colour.hex)}
                    className={`each-colour-swatch-container ${selectedFontColour === colour.hex ? 'active' : ''}`}>
                      <div
                        className={`each-colour-swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                        style={{ backgroundColor: colour.hex, color: colour?.textColor }}
                      ></div>
                  </div>
                )
              })}
            </div>
          </div>
        </React.Fragment>
      </div>
    </AntModal>
  );
};
export default FontColourSelectionModal;
