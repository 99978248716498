import React from "react";
import Form from "../../components/Form/Form";
import MainContent from "../../components/MainContent/MainContent";

import Button from "../../components/Button/Button";

const resetPasswordSchema = {
  showErrorList: false,
  validate: (formData, errors) => {
    if (formData.newPassword !== formData.newPasswordRepeat) {
      errors.newPasswordRepeat.addError("Passwords don't match");
    }

    ["newPassword", "newPasswordRepeat"].forEach(field => {
      const password = formData[field];
      // Check if it contains a number
      let containsNumber = password.split("").some(char => !isNaN(char));
      let isMinLength = password.length >= 7;

      if (!(containsNumber && isMinLength)) {
        errors[field].addError(
          "Passwords need to be at least 7 characters including at least one number."
        );
      }
    });

    return errors;
  },
  schema: {
    type: "object",
    required: ["newPassword", "newPasswordRepeat"],
    properties: {
      newPassword: { type: "string", title: "New Password" },
      newPasswordRepeat: { type: "string", title: "Confirm New Password" },
    },
  },
  uiSchema: {
    newPassword: { "ui:widget": "password" },
    newPasswordRepeat: { "ui:widget": "password" },
  },
};

class ResetPassword extends React.Component {
  state = {
    formData: {},
    isLoadingRequest: false,
  };

  handleFormSubmit = form => {
    this.setState(
      {
        isLoadingRequest: true,
      },
      () => {
        this.props.onSubmit(form.formData.newPassword);
        this.setState({
          isLoadingRequest: false,
        });
      }
    );
  };

  handleFormChange = form => {
    this.setState({
      formData: form.formData,
    });
  };

  render() {
    return (
      <MainContent centerContent padded>
        <h1 className="text-center">Forgotten your password?</h1>
        <p className="text-muted text-center">
          <small>No problem, reset it here.</small>
        </p>
        <Form
          {...resetPasswordSchema}
          formData={this.state.formData}
          onChange={this.handleFormChange}
          onSubmit={this.handleFormSubmit}
        >
          <Button
            block
            theme="dark-blue"
            type="submit"
            label="Reset password"
            loadingLabel="Resetting..."
            loading={this.state.isLoadingRequest}
            disabled={this.state.isLoadingRequest}
          />
        </Form>
      </MainContent>
    );
  }
}

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
