import React from "react";
import ColoursContainer from "../../pages/ThemeList/ColoursContainer";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

class ThemeModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isThemeModalVisible: props.isVisible,
      selectedTheme: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible) {
      this.setState({
        isThemeModalVisible: this.props.isVisible,
      });
    }
  }

  closeModal = () => {
    this.setState({
      isThemeModalVisible: false,
    });
    if (this.props.closeThemesModal && typeof this.props.closeThemesModal === "function")
      this.props.closeThemesModal();
  };

  applyTheme = () => {
    if (this.state.selectedTheme){
      this.props.setBorderColor(
        this.state.selectedTheme.hex,
        this.props.pageNumberToAddBorder,
        false,
        this.state.selectedTheme.category,
        this.state.selectedTheme?.textColor
      );
    }
    this.setState({
      selectedTheme: null,
    });
    this.closeModal()
  }

  applyThemeToAll = () => {
    if (this.state.selectedTheme){
      this.props.setBorderColorForAll(
        this.state.selectedTheme.hex,
        this.props.pageNumberToAddBorder,
        false,
        this.state.selectedTheme.category,
        this.state.selectedTheme?.textColor
      );
    }
    this.setState({
      selectedTheme: null,
    });
    this.closeModal()
  }

  handleChangeTheme = (theme) => {
    this.setState({
      selectedTheme: theme,
    });
  }

  render() {
    const { isThemeModalVisible } = this.state;

    return (
      <Modal
        key="themes-selection-modal"
        title={this.props.isPrint ? "" : "Select a colour"}
        isOpen={isThemeModalVisible}
        onClose={this.closeModal}
        hasHeader
        rightAction={<span
          style={{
            position: "absolute",
            right: 10,
            top: 2,
            pointerEvents: 'all'
          }}
        >
          {this.props.isPrint && (
            <Button
              theme="dark-blue"
              priority="secondary"
              label="Apply to All"
              style={{ marginTop: "10px" }}
              onClick={this.applyThemeToAll}
            />  
          )}
          <Button theme="dark-blue" priority="primary" label="Apply" style={{ marginTop: "10px" }} onClick={this.applyTheme}/>
        </span>
        }
      >
        <ColoursContainer
          setBorderColor={this.props.setBorderColor}
          closeThemesModal={this.closeModal}
          pageNumberToAddBorder={this.props.pageNumberToAddBorder}
          item={this.props.item}
          setActiveTheme={this.handleChangeTheme}
          selectedTheme={this.state.selectedTheme}
          isPrint={this.props.isPrint}
        />
      </Modal>
    );
  }
}

export default ThemeModalContainer;
