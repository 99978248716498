import stringToPatternOrFill from "../lib/string-to-pattern-or-fill";

function rgbToHsl(c) {
  var r = c[0]/255, g = c[1]/255, b = c[2]/255;
  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;

  if(max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max){
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default:
        return null;
    }
    h /= 6;
  }
  // eslint-disable-next-line
  return new Array(h * 360, s * 100, l * 100);
}

export const CAPTION_TEXT_COLORS = [
  // "rgb(0,0,0)",
  // "rgb(70,70,70)",
  // "rgb(145,145,145)",
  // "rgb(100,209,253)",
  // "rgb(32,195,203)",
  // "rgb(171,187,238)",
  // "rgb(98,101,205)",
  // "rgb(229,123,204)"
  "rgb(255,255,255)",
  "rgb(209,211,212)",
  "rgb(147,149,152)",
  "rgb(98,103,102)",
  "rgb(56,59,60)",
  "rgb(0,0,0)",
  "rgb(62,68,83)",
  "rgb(124,137,153)",
  "rgb(85,128,155)",
  "rgb(138,163,179)",
  "rgb(193,210,217)",
  "rgb(170,204,211)",
  "rgb(143,209,197)",
  "rgb(6,182,194)",
  "rgb(3,196,239)",
  "rgb(1,78,123)",
  "rgb(7,37,84)",
  "rgb(58,66,132)",
  "rgb(54,117,188)",
  "rgb(117,161,203)",
  "rgb(191,218,239)",
  "rgb(220,240,251)",
  "rgb(232,239,248)",
  "rgb(206,219,244)",
  "rgb(171,185,218)",
  "rgb(188,192,205)",
  "rgb(204,211,212)",
  "rgb(214,229,223)",
  "rgb(176,212,199)",
  "rgb(156,200,176)",
  "rgb(182,201,173)",
  "rgb(194,221,143)",
  "rgb(184,228,47)",
  "rgb(110,190,28)",
  "rgb(92,206,165)",
  "rgb(64,164,106)",
  "rgb(90,147,119)",
  "rgb(78,127,79)",
  "rgb(62,89,69)",
  "rgb(51,58,48)",
  "rgb(138,144,129)",
  "rgb(173,177,171)",
  "rgb(235,231,228)",
  "rgb(237,226,214)",
  "rgb(182,162,139)",
  "rgb(124,67,0)",
  "rgb(84,52,14)",
  "rgb(162,125,89)",
  "rgb(231,190,98)",
  "rgb(248,217,0)",
  "rgb(255,236,98)",
  "rgb(252,243,177)",
  "rgb(244,230,164)",
  "rgb(252,213,148)",
  "rgb(253,184,52)",
  "rgb(254,190,153)",
  "rgb(255,162,108)",
  "rgb(246,118,14)",
  "rgb(240,80,41)",
  "rgb(207,85,37)",
  "rgb(178,60,55)",
  "rgb(154,0,62)",
  "rgb(196,6,12)",
  "rgb(229,22,41)",
  "rgb(245,53,103)",
  "rgb(228,54,140)",
  "rgb(252,73,162)",
  "rgb(224,3,170)",
  "rgb(215,88,168)",
  "rgb(225,125,180)",
  "rgb(251,174,179)",
  "rgb(250,206,209)",
  "rgb(250,226,228)",
  "rgb(239,225,225)",
  "rgb(235,229,237)",
  "rgb(232,206,238)",
  "rgb(165,91,181)",
  "rgb(87,31,101)",
  "rgb(96,70,88)",
]

export const REDUCED_FILL_OPTIONS = [
  "rgb(255,255,255)",
  "rgb(209,211,212)",
  "rgb(147,149,152)",
  "rgb(98,103,102)",
  "rgb(56,59,60)",
  "rgb(0,0,0)",
  "rgb(62,68,83)",
  "rgb(124,137,153)",
  "rgb(85,128,155)",
  "rgb(138,163,179)",
  "rgb(193,210,217)",
  "rgb(170,204,211)",
  "rgb(143,209,197)",
  "rgb(6,182,194)",
  "rgb(3,196,239)",
  "rgb(1,78,123)",
  "rgb(7,37,84)",
  "rgb(58,66,132)",
  "rgb(54,117,188)",
  "rgb(117,161,203)",
  "rgb(191,218,239)",
  "rgb(220,240,251)",
  "rgb(232,239,248)",
  "rgb(206,219,244)",
  "rgb(171,185,218)",
  "rgb(188,192,205)",
  "rgb(204,211,212)",
  "rgb(214,229,223)",
  "rgb(176,212,199)",
  "rgb(156,200,176)",
  "rgb(182,201,173)",
  "rgb(194,221,143)",
  "rgb(184,228,47)",
  "rgb(110,190,28)",
  "rgb(92,206,165)",
  "rgb(64,164,106)",
  "rgb(90,147,119)",
  "rgb(78,127,79)",
  "rgb(62,89,69)",
  "rgb(51,58,48)",
  "rgb(138,144,129)",
  "rgb(173,177,171)",
  "rgb(235,231,228)",
  "rgb(237,226,214)",
  "rgb(182,162,139)",
  "rgb(124,67,0)",
  "rgb(84,52,14)",
  "rgb(162,125,89)",
  "rgb(231,190,98)",
  "rgb(248,217,0)",
  "rgb(255,236,98)",
  "rgb(252,243,177)",
  "rgb(244,230,164)",
  "rgb(252,213,148)",
  "rgb(253,184,52)",
  "rgb(254,190,153)",
  "rgb(255,162,108)",
  "rgb(246,118,14)",
  "rgb(240,80,41)",
  "rgb(207,85,37)",
  "rgb(178,60,55)",
  "rgb(154,0,62)",
  "rgb(196,6,12)",
  "rgb(229,22,41)",
  "rgb(245,53,103)",
  "rgb(228,54,140)",
  "rgb(252,73,162)",
  "rgb(224,3,170)",
  "rgb(215,88,168)",
  "rgb(225,125,180)",
  "rgb(251,174,179)",
  "rgb(250,206,209)",
  "rgb(250,226,228)",
  "rgb(239,225,225)",
  "rgb(235,229,237)",
  "rgb(232,206,238)",
  "rgb(165,91,181)",
  "rgb(87,31,101)",
  "rgb(96,70,88)",
].map(stringToPatternOrFill);

const REDUCED_FILL_OPTIONS_AS_ARRAY = REDUCED_FILL_OPTIONS.map(c => c.color.replace("rgb(", "").replace(")", "").split(","));
export const REDUCED_FILL_OPTIONS_SORTED = REDUCED_FILL_OPTIONS_AS_ARRAY.map(function(rgbColor, i) {
  // Convert to HSL and keep track of original indices
  return {color: rgbToHsl(rgbColor), index: i};
}).sort(function(c1, c2) {
  // Sort by hue
  return c1.color[0] - c2.color[0];
}).map(function(data) {
  // Retrieve original RGB color
  return `rgb(${REDUCED_FILL_OPTIONS_AS_ARRAY[data.index].join(",")})`;
});

export const FILL_OPTIONS =  [
  "rgb(255,255,255)",
  "rgb(209,211,212)",
  "rgb(147,149,152)",
  "rgb(98,103,102)",
  "rgb(56,59,60)",
  "rgb(0,0,0)",
  "rgb(62,68,83)",
  "rgb(124,137,153)",
  "rgb(85,128,155)",
  "rgb(138,163,179)",
  "rgb(193,210,217)",
  "rgb(170,204,211)",
  "rgb(143,209,197)",
  "rgb(6,182,194)",
  "rgb(3,196,239)",
  "rgb(1,78,123)",
  "rgb(7,37,84)",
  "rgb(58,66,132)",
  "rgb(54,117,188)",
  "rgb(117,161,203)",
  "rgb(191,218,239)",
  "rgb(220,240,251)",
  "rgb(232,239,248)",
  "rgb(206,219,244)",
  "rgb(171,185,218)",
  "rgb(188,192,205)",
  "rgb(204,211,212)",
  "rgb(214,229,223)",
  "rgb(176,212,199)",
  "rgb(156,200,176)",
  "rgb(182,201,173)",
  "rgb(194,221,143)",
  "rgb(184,228,47)",
  "rgb(110,190,28)",
  "rgb(92,206,165)",
  "rgb(64,164,106)",
  "rgb(90,147,119)",
  "rgb(78,127,79)",
  "rgb(62,89,69)",
  "rgb(51,58,48)",
  "rgb(138,144,129)",
  "rgb(173,177,171)",
  "rgb(235,231,228)",
  "rgb(237,226,214)",
  "rgb(182,162,139)",
  "rgb(124,67,0)",
  "rgb(84,52,14)",
  "rgb(162,125,89)",
  "rgb(231,190,98)",
  "rgb(248,217,0)",
  "rgb(255,236,98)",
  "rgb(252,243,177)",
  "rgb(244,230,164)",
  "rgb(252,213,148)",
  "rgb(253,184,52)",
  "rgb(254,190,153)",
  "rgb(255,162,108)",
  "rgb(246,118,14)",
  "rgb(240,80,41)",
  "rgb(207,85,37)",
  "rgb(178,60,55)",
  "rgb(154,0,62)",
  "rgb(196,6,12)",
  "rgb(229,22,41)",
  "rgb(245,53,103)",
  "rgb(228,54,140)",
  "rgb(252,73,162)",
  "rgb(224,3,170)",
  "rgb(215,88,168)",
  "rgb(225,125,180)",
  "rgb(251,174,179)",
  "rgb(250,206,209)",
  "rgb(250,226,228)",
  "rgb(239,225,225)",
  "rgb(235,229,237)",
  "rgb(232,206,238)",
  "rgb(165,91,181)",
  "rgb(87,31,101)",
  "rgb(96,70,88)",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_purple.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_blue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_aqua.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_silver.jpg",
  "https://eclipse-engine.s3.amazonaws.com/app/borders/textures/border_glitter_pink.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/a2f9aa0c-127f-41bc-825a-317d37149df7/Red glitter.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/9671fdb0-f513-486a-9584-3d11ff7cbbe9/glitter_gold-250x250.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/432694f0-b559-4ae3-953a-8c52649f26c7/ColourEasterEggs.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d4c7d881-1135-4229-8b6e-7a41d9eb865a/ColourEasterEggs.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e68cf3a5-e144-45b8-9bfb-43d7e6c6fc05/Bunnies.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/acda3aad-10e9-46a4-95d6-f45c27a5510f/Eggs.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e6702a47-1242-41ba-a9bc-82e1de829ec9/Easterchicks.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2d882737-3bba-45f5-9891-1893d9c63c8a/XOs.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d9e6dca6-dbdd-4ed5-a69a-ae3df44fe387/StarsRed.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/120fe2c7-3aac-4116-badc-9ed4904df0d1/Stars.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d359ce5d-ef00-4834-bae9-d60b0fcc4310/TransparentHearts.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/63023732-b01e-43f1-a2ae-bb7c525fa8a7/heartborder200.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/8f85f5cd-5570-45d4-9766-baedca02244a/LoveHearts.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/87c2d41b-de30-4ac4-9c8b-e73ab0766a4d/AnchorsWhite.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/c83ae3da-e447-4d9b-8360-5bfd9f2bf842/AnchorsBlue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2a904fcd-4547-4bd6-9436-d53ead746c14/Fish.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/b80ae5d2-2cbc-477a-8c77-443fb40a326d/Sun.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/17251ebc-1be9-4bf9-9e27-7c3814594917/Sun.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/c9f1351e-4488-4448-ba2b-ddb31700a906/Watermelons.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/86af16b5-5df1-47cf-967e-a5df70910e5d/Pineapples.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/c90b5a03-3463-4f0f-96f3-ffc71149ac3e/IceLollies.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/14e3b746-e485-4b91-bf63-a106cc77305f/Daisy.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/a519d8da-c406-4a04-b07a-49664cf40a61/Flimingo.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/bcb22165-7225-471a-aff3-089e43171a78/Sprinkles.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/7f0538bc-811f-4862-a744-b487c7827e5c/TribalDiamond.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/ae1ff1d9-6209-4c22-823e-e5d09179b805/Palm.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e7e07486-eb1d-4f09-8030-eb1a2ac28732/BlackSpots.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/33ff6560-4284-45fc-bcaa-4f5747ed0548/Bones.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/6ed478a1-7b8e-4630-aaaf-0b293b3fcdba/BonesTrans.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/d8dace84-d253-48bd-b611-ac1940305306/Clouds.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e81f83a4-e562-4228-9eac-7a6cc1e902cc/BlueClouds.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/81c4b72a-a02a-4b30-b3ff-50c8c5b2b8bd/raindrops.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/b8734580-05a0-426a-a4fc-32ebef0e411b/ElephantsGrey.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/8f798ec4-0683-4a2a-8d68-a2e2b9f59cd9/StarsPastel.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/459e4835-ccbd-49c2-847e-b22b1a42e30f/StarsBlue.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/4281d94d-8b38-49e7-aee0-d7f73c7bcd1c/starborder.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/ec88bee2-5add-430c-b11d-1cd5f524afe4/PolkaDotneon.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/5c9d3075-540c-43aa-9751-1b708d1c5a2b/Polkadots.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/17e51577-a5d2-4712-89cc-e14773550c9b/Triangles.png",
  "https://eclipse-engine.s3.amazonaws.com/uploads/44eb641e-d192-450f-8cd9-5d1e7ced7961/hexagonpattern.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/02e5c876-980b-46c7-86f2-8d12224ba599/RainbowStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2381d825-a587-47e0-bdc7-92518d5a2a1c/BlueStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/fc3d58be-96e9-4dc4-bf95-c12e98aa98db/bluestripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2de5513c-5673-4862-b3e0-1b10a5fdc3d3/YellowStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/12034132-e008-482d-8fe8-994ee1754b26/PinkStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/e347a1ca-0aaf-45e9-9927-ff546a271d95/Stripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/57c6df3a-a261-4dfe-bfce-149574754c16/Orange-Stripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/5e70acad-3dd2-4e70-aa81-5a72a00d671c/Pinkstripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/cfdc3e40-52cb-472b-8dd0-25028faeaa6b/Yellowstripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/2cf4fb90-7712-49ac-8bb6-01c28236d136/GreenStripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/9460501a-aeb2-42ff-8f42-979c6508ddd2/Teal-Stripes.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/11acf229-c8bf-4618-8d1f-acee98d92b48/greenstripeborder.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/0f0503a9-8a27-41de-a1f9-17942b26b10a/3candystripe.jpg",
  "https://eclipse-engine.s3.amazonaws.com/uploads/fec43bed-c04a-4c2c-b2ce-098af58dfba3/starsandstripes.jpg"
].map(stringToPatternOrFill);

export default FILL_OPTIONS;