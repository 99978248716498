import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererIndiciaLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {

    const isPicCollage = this.props.item.picCollage
    const imageUrl = isPicCollage ? `url('https://piccollage.gifts/_nuxt/img/logo.9415411.png')` : `url('${process.env.PUBLIC_URL}/images/postsnap-postage-paid.png')` 
    return (
      <HtmlRendererRect
        className="html-renderer-indicia-layer"
        rect={this.props.config.rect}
        style={{
          backgroundImage: imageUrl,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      ></HtmlRendererRect>
    );
  }
}

export default HtmlRendererIndiciaLayer;
