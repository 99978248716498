import React from "react";
import PropTypes from "prop-types";
import MainContent from "../../../components/MainContent/MainContent";
import Button from "../../../components/Button/Button";
import './CheckoutGuestOrCustomer.scss';

class CheckoutGuestOrCustomer extends React.Component {
  static propTypes = {
    onSignInOrUp: PropTypes.func,
    onSubmitGuestDetails: PropTypes.func,
    onClearGuestDetails: PropTypes.func,
    onActionAfterGuestDone: PropTypes.func,
    onActionAfterGuestAuth: PropTypes.func,
  };

  defaultState = {
    guestCaptureData: this.props.guestDetails.toJS() || {},
    alert: null,
    isLoading: false,
    edited: false,
    isSavingGuestDetails: false,
    isSigningIn: false,
    signInData: {},
    signInFormVisible: false,
    signUpData: {},
    signUpFormVisible: false,
    isSigningUp: false,
    isLoadingRequest: false,
    chosenGuestOrCustomer: this.props.guestDetails.size > 0 || false,
  };

  state = { ...this.defaultState };

  handleChooseGuestCheckout = () => {
    this.props.chosenGuestRoute()
  }

  handleSignIn = () => {
    this.props.chosenSignInRoute()
  }

  handleSignUp = () => {
    this.props.chosenSignUpRoute()
  }


  render() {
    const signUpOrInFormVisible = this.state.signUpFormVisible || this.state.signInFormVisible

    return (
      <MainContent centerContent padded>
        <div className="restricted-width-modal-content">
          <div className="checkout__guest-or-customer">
            <h2 style={{textAlign: 'center'}}>How would you like to checkout today?</h2>
            {!signUpOrInFormVisible && (
              <div className="checkout_options-container">
                <Button
                  block
                  theme="dark-blue"
                  type="submit"
                  label="Guest Checkout"
                  dataGtmElement="choose-guest-checkout-button"
                  onClick={this.handleChooseGuestCheckout}
                />
                <p className="checkout__option-btn-explainer">Save time – there’s no need to register. Create an account later if required</p>
                <Button
                  block
                  theme="dark-blue"
                  priority="secondary"
                  type="submit"
                  label="Customer Checkout"
                  dataGtmElement="choose-guest-checkout-button"
                  onClick={this.handleSignIn}
                />
                <p className="checkout__option-btn-explainer">Welcome back</p>
                <Button
                  block
                  theme="dark-blue"
                  priority="secondary"
                  type="submit"
                  label="Sign Up & Checkout"
                  dataGtmElement="choose-guest-checkout-button"
                  onClick={this.handleSignUp}
                />
                <p className="checkout__option-btn-explainer">Get added benefits such as order history and an address book</p>
              </div>
            )}
          </div>
        </div>
      </MainContent>
    );
  }
}

CheckoutGuestOrCustomer.propTypes = {};
CheckoutGuestOrCustomer.defaultProps = {};

export default CheckoutGuestOrCustomer;
