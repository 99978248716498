import $ from "jquery";
import React from "react";
import MainContent from "../../../components/MainContent/MainContent";
import Button from "../../../components/Button/Button";
import PasswordField from "../../../components/PasswordField/PasswordField";
import Form from "../../../components/Form/Form";
import SweetAlert from "../../../components/SweetAlert/SweetAlert";

import './CheckoutSignIn.scss';

const SCHEMAS = {
  SIGN_IN : {
    showErrorList: false,
    schema: {
      type: "object",
      properties: {
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7, autoComplete: "password-current" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signIn",
      email: {
        "ui:widget": "email",
        "ui:autocomplete": "username"
      },
      password: {
        "ui:widget": PasswordField,
        "ui:autocomplete": "password-current"
      },
    }
  },
  REQUEST_PASSWORD_RESET: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Email" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "forgotPassword",
      email: {
        "ui:widget": "email",
      },
    },
  }
}


class CheckoutSignIn extends React.Component {
  
  defaultState = {
    alert: null,
    isLoading: false,
    edited: false,
    isSigningIn: false,
    signInData: {},
    signInFormVisible: false,
    isLoadingRequest: false,
    passwordResetVisible: false,
    requestPasswordResetData: {},
  };

  state = { ...this.defaultState };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleChooseSignUp = () => {
    this.props.chosenSignUpRoute()
  }


  handleSignInFormChange = form => {
    let data = form.formData
    // Horrible hack because browsers no longer seem to trigger onChange for password autofill
    // TODO: investigate a better way of doing this...
    if (!form.formData.password && $('#signIn #pw-input').attr('data-ps-val')){
      data = {...data, password: $('#signIn #pw-input').attr('data-ps-val')}
    }
    this.setState({
      signInData: data,
    });
  };

  handleSignInFormSubmit = async form => {
    const { email, password } = form.formData;
    this.setState({
      isLoadingRequest: true,
      isSigningIn: true,
    });
    try {
      const result = await this.props.onSignIn({ email, password });
      if (result && result.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't sign you in",
            text: result.payload.data.error,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        //await this.props.onActionAfterAuth();
        this.props.onSignedIn();
      }
    } catch (err) {
      console.warn("Error while signing in:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningIn: false,
      });
    }
  };

  handleForgottenPassword = () => {
    this.setState({
      passwordResetVisible: true
    })
  }

  handleRequestPasswordResetFormChange = form => {
    this.setState({
      requestPasswordResetData: form.formData,
    });
  };

  handleRequestPasswordResetFormSubmit = async form => {
    const { email } = form.formData;
    this.setState({
      isLoadingRequest: true,
    });

    try {
      const result = await this.props.onRequestPasswordReset(email);
      if (result.payload.data.success) {
        this.props.onClose();
        this.setState({
          alert: {
            type: "success",
            title: "Email sent",
            text: "Please check your email for instructions on how to reset your password.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        const errors = result.payload.data.errors;
        let title;
        let text;

        if (errors.email) {
          if (errors.email[0] === "not found") {
            title = "Email not found";
            text =
              "This email address was not found. Please check the email address for any mistakes and try again.";
          }
        }

        this.setState({
          alert: {
            type: "error",
            title,
            text,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      }
    } catch (err) {
      console.warn("Error while requesting password reset:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };


  render() {

    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <MainContent centerContent padded>
        <div className="restricted-width-modal-content">
          {this.state.passwordResetVisible ? (
            <div className="checkout__forgot-password">
            <React.Fragment>
              <Form
                {...SCHEMAS.REQUEST_PASSWORD_RESET}
                formData={this.state.requestPasswordResetData}
                onChange={this.handleRequestPasswordResetFormChange}
                onSubmit={this.handleRequestPasswordResetFormSubmit}
              >
                <Button
                  dataGtmElement="Reset Password Button"
                  block
                  type="submit"
                  theme="dark-blue"
                  label="Reset Password"
                  loadingLabel="Requesting Reset"
                  loading={this.state.isLoadingRequest}
                />
              </Form>
            </React.Fragment>
          </div>
          )
          : (
            <div className="checkout__sign-in">
              <React.Fragment>
                <Form
                  {...SCHEMAS.SIGN_IN}
                  formData={this.state.signInData}
                  onChange={this.handleSignInFormChange}
                  onSubmit={this.handleSignInFormSubmit}
                  autoComplete={true}
                  disabled={this.state.signInDisabled}
                >
                  <Button
                    dataGtmElement="Sign In Button"
                    block
                    theme="dark-blue"
                    type="submit"
                    label="Sign In"
                    loadingLabel="Signing In ..."
                    priority={this.state.isLoadingRequest ? "primary" : "primary"}
                    loading={this.state.isLoadingRequest}
                  />
                </Form>
                <div style={{textAlign: 'center'}}>
                  <div style={{marginTop:'10px'}} data-gtm-element="Forgot Password Button" onClick={this.handleForgottenPassword}>
                    <p style={{textDecoration: 'underline'}}>Forgotten password?</p>
                  </div>  
                </div>  
              </React.Fragment>
            </div>
          )}
        </div>
      </MainContent>
    ]
  }
}

CheckoutSignIn.propTypes = {};
CheckoutSignIn.defaultProps = {};

export default CheckoutSignIn;
