import React from "react";
import QuantityPicker from "../QuantityPicker/QuantityPicker";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import * as LAYER_TYPES from "../../constants/layer-types";
import Icon from "../Icon/Icon";
import { 
  Popconfirm
} from "antd";

import {
ExclamationCircleOutlined,
WarningFilled
} from '@ant-design/icons';

import "./ColouredRetros.scss";

const ColouredRetros = ({ items,itemPreviewWidth, onClickItem, areBordered, areRealBorders, onIncreaseItemQuantity, onDecreaseItemQuantity, deletePopoverActiveForItem, classes, effects, onDeleteItem, setDeletePopoverActiveForItem, isEditModalOpen  }) => {
  
  let allHasSameBG = false;
  let firstBg;

  firstBg = items[0].get('layers').find(layer => layer.get('type') === LAYER_TYPES.PHOTO)?.getIn(['config', 'background']).toJS();
  if(firstBg?.colour){
    firstBg = firstBg?.colour.hex
  } else {
    firstBg = firstBg?.effect.id
  }
  
  allHasSameBG = items.every(item => {
    let bg = item.get('layers').find(layer => layer.get('type') === LAYER_TYPES.PHOTO)?.getIn(['config', 'background']).toJS();
    if(bg?.colour){
      bg = bg?.colour.hex
    } else {
      bg = bg?.effect.id
    }
    return bg === firstBg;
  });

  return (
    <>
      <div className="coloured-retros-container">
        {/* <h2 className="coloured-retros-heading">Tap a Retro Print to Personalise</h2> */}
        {/* {!allHasSameBG && (
          <div className="warning">
            <h3><WarningFilled  style={{color: '#FF5722', fontSize: '20px'}}/><span style={{marginLeft:'10px'}}>Different Frames</span></h3>
            <p>Your Retro Prints have <strong>different</strong> frames - if you wanted the same frame, tap a print and change the 'Apply to all?' switch  to 'Yes' in the Personalise Frame section.</p>
          </div>
        )} */}

        <div style={{display: 'flex', flexWrap: "wrap"}}>
          {items.map((item, index) => (
            <>
              <div key={index} className="photo-prints-prebag__item" style={
                  {
                    flexBasis: document.documentElement.clientWidth < 550 ? '50%' : '33%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: document.documentElement.clientWidth < 400 ? '0px' : '0px 5px'
                  }
                }>
                  <div className={`${classes} ${item.get('isManuallyRotated') ? 'item-preview--rotated' : ''}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    {!isEditModalOpen && (
                      <HtmlRenderer
                      width={itemPreviewWidth}
                      isInteractive={false}
                      previewMode={true}
                      key={"editor-render"}
                      item={item.toJS()}
                      page={item.getIn(["pages", "front"])}
                      onClick={() => onClickItem(index)}
                      onClickSignatureLayer={() => console.log("Signature clicked")}
                      borderedForPreviews={false}
                      realBorders={false}
                      effects={item.getIn(["product_options", "effects"])}
                      withShadow={areBordered && !areRealBorders}
                      mask={false}
                      showCaptionPlaceholder={false}
                      uniquekey={"prebag-preview"}
                      showBorderCanvas={false}
                      lowResImages={true}
                    />
                    )}
                    <div className="item-options">
                      <div>
                        <QuantityPicker
                          quantity={item.get("quantity")}
                          onIncrease={() => onIncreaseItemQuantity(index)}
                          onDecrease={() => onDecreaseItemQuantity(index)}
                          onDelete={() => onDeleteItem(index)}
                          withDelete={false}
                        />
                      </div>
                      <Popconfirm
                        style={{ marginRight: "10px" }}
                        visible={deletePopoverActiveForItem === item.get('id')}
                        title={<div style={{width: '40vw', maxWidth: '200px'}}>Delete this print?</div>}
                        onConfirm={() => onDeleteItem(index)}
                        onCancel={() => setDeletePopoverActiveForItem(null)}
                        okButtonProps={{style: {height: '32px'}}}
                        cancelButtonProps={{style: {height: '32px'}}}
                        okText="Yes"
                        cancelText="No"
                        icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                        placement="left"
                      >
                        <div
                          className="item-options__delete"
                            onClick={() => setDeletePopoverActiveForItem(item.get('id'))}
                          >
                          <Icon name="bin" size="large"/>
                        </div>
                      </Popconfirm>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
    
    
  );
};

ColouredRetros.defaultProps = {};

export default ColouredRetros;
