import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";

import "./PosterEditor.scss";

import EditorContainer from "../../components/Editor/EditorContainer";
import Modal from "../../components/Modal/Modal";
import { routeCreators } from "../../lib/routes";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import * as LAYER_TYPES from "../../constants/layer-types";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
} from "../../data/products";
import MainContent from "../../components/MainContent/MainContent";
import LAYOUTS from "../../constants/layouts";
import EditorCollageOption from "../../components/Editor/EditorCollageOption";
import { STEPS as EDITOR_STEPS } from "../../components/Editor/Editor";
import { designsByProductId } from "../../data/designs";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import EditorAddressInputModal from "../../components/Editor/EditorAddressInputModal";
import * as STORAGE_KEYS from "../../constants/storage-keys";

class PosterEditorContainer extends Component {
  state = {
    item: null,
    isEditorVisible: false,
    isAddressInputModalVisible: false,
    isDesignSelectionModalVisible: true,
  };

  closeEditor = (closeBehavior = {}) => {
    // TODO: extract this to ENV var
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  setUpEditorItem = async ({ designId, selectedVariationIndex, layout }) => {
    localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    const { productId, productTypeId } = this.props;
    const product = productsByProductId.get(productId);
    const designDetail = await postsnapApi.designs.getDetails(designId);
    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    if (layout) {
      layers = layers
        .sort((a, b) => a.page - b.page)
        .reverse()
        .map(layer => {
          if (
            layer.get("type") === LAYER_TYPES.PHOTO &&
            layer.get("page") === PAGES_BY_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTERS].front
          ) {
            return layer.setIn(["config", "layout"], fromJS(layout));
          }

          return layer;
        });
    }

    if (selectedVariationIndex !== undefined) {
      layers = layers.map(layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          return layer.setIn(
            ["config", "s3_key"],
            layer.getIn(["config", "s3_keys", selectedVariationIndex])
          );
        }

        if (layer.get("type") === LAYER_TYPES.TEXT) {
          return layer.setIn(
            ["config", "color"],
            layer.getIn(["config", "colors", selectedVariationIndex])
          );
        }

        return layer;
      });
    }

    let itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId,
      quantity: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      supports_collages: Boolean(layout),
      layers,
      designOptions: designDetail.data.data.preview_s3_keys || [],
      postDate:
        productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD ? moment().add(1, "days") : moment(),
    });

    if (itemData.getIn(["pages", "envelope"])) {
      itemData = itemData.update("layers", layers =>
        layers.concat(
          fromJS([
            {
              config: {
                rect: {
                  x: 0.1,
                  y: 0.7,
                  width: 0.5,
                  height: 0.6,
                },
                font: "Anaheim Gothic",
                size: 10,
                color: "0,0,0",
              },
              id: "ENVELOPE_ADDRESS",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              zindex: null,
              type: "Layer::Address",
            },
            {
              config: {
                rect: {
                  x: 0.695,
                  y: 0.94,
                  width: 0.26,
                  height: 0.23,
                },
              },
              id: "ENVELOPE_STAMP",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              type: "Layer::Indicia",
            },
          ])
        )
      );
    }

    this.setState({
      isEditorVisible: true,
      item: itemData,
    });
  };

  handleSelectBasicLayout = layout => {
    const basicDesign = designsByProductId
      .get(this.props.productId)
      .find(design => design.get("description") === "NO_DESIGN");

    this.setUpEditorItem({ layout, designId: basicDesign.get("id") });
  };

  handleSelectDesign = design => {
    this.setUpEditorItem({ designId: design.get("id") });
  };

  handleEditorSave = item => {
    this.setState({
      item,
      isAddressInputModalVisible: true,
    });
  };

  handleSaveAddress = address => {
    this.addToItemBasket(
      this.state.item.withMutations(item => {
        item.set("addressBookId", null);
        item.set("address", fromJS(address));
      })
    );
  };

  handleSelectAddressBookEntry = addressBookEntryId => {
    this.addToItemBasket(
      this.state.item.withMutations(item => {
        item.set("addressBookId", addressBookEntryId);
        item.set("address", null);
      })
    );
  };

  addToItemBasket = item => {
    this.props.addBasketItem(item.toJS());
    this.props.goToBasket();
  };

  render() {
    const product = productsByProductId.get(this.props.productId);
    const designs = designsByProductId
      .get(this.props.productId)
      .filter(design => design.get("description") !== "NO_DESIGN");

    const addressInputModal = (
      <EditorAddressInputModal
        isDoubleDirect={false}
        isOpen={this.state.isAddressInputModalVisible}
        mode={"new"}
        initialFormData={
          this.state.item && this.state.item.get("address") ? this.state.item.get("address") : null
        }
        onCancel={() => this.setState({ isAddressInputModalVisible: false })}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
        onSaveOwnAddress={this.handleOwnAddressSave}
      />
    );

    const designSelectionModal = (
      <Modal
        hasHeader={false}
        isOpen={this.state.isDesignSelectionModalVisible}
        onClose={() => this.setState({ isDesignSelectionModalVisible: false })}
      >
        <MainContent padded>
          <h3>Basic layouts</h3>
          <div className="collage-prints-prebag__layouts">
            {LAYOUTS.map((layout, index) => (
              <div>
                <EditorCollageOption
                  key={index}
                  ratio={product.get("width") / product.get("height")}
                  stretchToFit
                  layout={layout}
                  onClick={() => this.handleSelectBasicLayout(layout)}
                />
              </div>
            ))}
          </div>
          <h3>Designed posters</h3>
          <div className="poster-editor__designs-grid">
            {designs.map(design => (
              <div
                key={design.get("id")}
                className="poster-editor__design-preview"
                onClick={() => this.handleSelectDesign(design)}
              >
                <img
                  className="poster-editor__design-preview__image"
                  src={generateS3AssetUrlFromKey(design.get("preview_s3_key"))}
                  alt={design.get("description")}
                />
              </div>
            ))}
          </div>
        </MainContent>
      </Modal>
    );

    const editorModal = (
      <Modal
        isOpen={Boolean(this.state.isEditorVisible)}
        onClose={this.closeEditor}
        hasHeader={false}
        animated={false}
      >
        <EditorContainer
          ref={el => (this.editorContainer = el)}
          item={this.state.item}
          lastStep={EDITOR_STEPS.PRODUCT_FRONT}
          skipAddressInput={true}
          onClose={this.closeEditor}
          onSave={this.handleEditorSave}
          saveButtonLabel="Add to Basket"
        />
      </Modal>
    );
    return (
      <React.Fragment>
        {designSelectionModal}
        {editorModal}
        {addressInputModal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosterEditorContainer);
