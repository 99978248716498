import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS, List } from "immutable";
import moment from "moment";
import groupBy from "lodash/groupBy";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as designsData from "../../data/designs";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  isDefaultFinishMatt,
  productsByProductTypeId,
} from "../../data/products";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";
import { STEPS as EDITOR_STEPS } from "../Editor/Editor";

import Modal from "../Modal/Modal";
import transformDesignLayers from "../../lib/transform-design-layers";
import squareLayoutTemplates from "../../lib/collage-generator/square-layout-templates";
import landscapeLayoutTemplates from "../../lib/collage-generator/landscape-layout-templates";
import portraitLayoutTemplates from "../../lib/collage-generator/portrait-layout-templates";
import generateLayout from "../../lib/collage-generator/generate-layouts";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import { Modal as AntModal } from "antd";
import "./BorderPrintEditor.scss";
import EditorContainer from "../Editor/EditorContainer";
import BorderPrintEditor from "./BorderPrintEditor";

const { confirm } = AntModal;

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;
  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

class BorderPrintEditorContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      items: new List(),
      indexOfItemToEdit: null,
      selectedFinish: isDefaultFinishMatt(this.props.productId) ? "matt" : "gloss",
      isUploadModalVisible: false,
      editingFromPreBag: false,
    };

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag && existingPrebag.data) {
      this.state.items = fromJS(existingPrebag.data.items);
    }
  }

  componentDidUpdate() {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items,
        },
      })
    );
    // if (this.state.items.length > 0) {

    // } else {
    //   const existingPrebag = getExistingPrebagForProduct(
    //     this.props.productTypeId,
    //     this.props.productId
    //   );
    //   debugger
    //   if (existingPrebag) {
    //     localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    //   }
    // }
  }

  componentDidMount() {
    if (this.state.items.size === 0) {
      this.handleSelectProductType(PRODUCT_TYPE_IDS.PHOTO_PRINT, this.props.productId);
    }
  }

  handleSelectProductType = async (productTypeId, productId) => {
    const product = productsByProductTypeId.get(productTypeId).find(p => p.get("id") === productId);
    const firstDesignForProductId = designsData.designsByProductId.get(productId).first();
    console.log(firstDesignForProductId.get("id"));
    this.setUpEditorItem({
      designId: firstDesignForProductId.get("id"),
      productTypeId: productTypeId,
      product: product,
    });
  };

  setUpEditorItem = async ({ product, productTypeId, designId }) => {
    const designDetail = await postsnapApi.designs.getDetails(designId);
    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    let borderPrintDefaultConfig = {
      width: 22, // TODO: extract this to a global CONST
      colour: "#FFFFFF",
    };

    let defaultProductOptions = isDefaultFinishMatt(this.props.productId)
      ? { finish: "matt", borderPrint: borderPrintDefaultConfig }
      : { borderPrint: borderPrintDefaultConfig, finish: "gloss" };

    let itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId,
      quantity: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: defaultProductOptions, // add here the photo tile specific options
      postDate: moment(),
      duplicateAlertShown: false,
      isBorderPrint: true,
    });
    this.setState({
      items: this.state.items.push(itemData),
      indexOfItemToEdit: this.state.items.size,
    });
  };

  handleEditorUpdate = ({ item, images }) => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items.toJS(),
        },
      })
    );
  };

  handleSaveEditor = item => {
    this.setState({
      items: this.state.items.set(this.state.indexOfItemToEdit, item),
      indexOfItemToEdit: null,
      editingFromPreBag: false,
    });
  };

  handleCloseEditor = () => {
    let updatedItemsList = this.state.items;
    if (!this.state.editingFromPreBag) {
      updatedItemsList = this.state.items.delete(this.state.indexOfItemToEdit);
    }

    this.setState({
      indexOfItemToEdit: null,
      items: updatedItemsList,
      editingFromPreBag: false,
      isLayoutSelectionModalVisible: updatedItemsList.size === 0,
    });
  };

  handleIncreaseItemQuantity = itemIndex => {
    this.setState({
      items: this.state.items.updateIn([itemIndex, "quantity"], quantity => quantity + 1),
    });
  };

  handleDecreaseItemQuantity = itemIndex => {
    this.setState({
      items: this.state.items.updateIn([itemIndex, "quantity"], quantity => quantity - 1),
    });
  };

  handleDeleteItem = itemIndex => {
    this.setState({
      items: this.state.items.delete(itemIndex),
    });
  };

  approveCurrentPrints = () => {
    if (this.props.existingPhotoPrintItems.size > 0) {
      const existingPrint = this.props.existingPhotoPrintItems.find(item =>
        item.getIn(["postageScheme", "cost"])
      );
      if (existingPrint) {
        const existingPostageScheme = existingPrint.get("postageScheme");
        const existingAddress = existingPrint.get("address"); // address book entry too...
        this.setState(
          {
            items: this.state.items.map(item => {
              return item.withMutations(item => {
                item
                  .set("postageScheme", fromJS(existingPostageScheme))
                  .set("setPostageScheme", true);
                item.set("address", fromJS(existingAddress));
              });
            }),
          },
          () => {
            this.finalizePreBag();
          }
        );
      } else {
        this.finalizePreBag();
      }
    } else {
      this.finalizePreBag();
    }
  };

  finalizePreBag = () => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items.toJS(),
        },
      })
    );

    //localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    const itemsWithProductOptions = this.state.items.map(item =>
      item.setIn(["product_options", "finish"], this.state.selectedFinish)
    );
    itemsWithProductOptions.forEach(async item => {
      this.props.addPendingApprovalItem(item.toJS());
    });
    //this.props.addItemToBasket(itemsWithProductOptions.toJS());
    this.props.navigateToApproval();
  };

  showCancelConfirmationAlert = () => {
    if (this.state.items.size > 0) {
      confirm({
        title: null,
        icon: null,
        content: "Are you sure you want to cancel? If you cancel you will lose your progress.",
        okText: "Stay",
        cancelText: "Cancel",
        onOk: () => {},
        onCancel: () => {
          this.navigateBack();
        },
      });
    } else {
      this.navigateBack();
    }
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  render() {
    const product = productsByProductId.get(this.props.productId);
    const productName = product.get("name");

    return (
      <Modal
        className="collage-prints-prebag"
        isOpen={true}
        hasHeader={false}
        contentLabel={productName}
        animated={false}
      >
        {this.state.indexOfItemToEdit !== null && (
          <EditorContainer
            ref={el => (this.editorContainer = el)}
            item={this.state.items.get(this.state.indexOfItemToEdit)}
            lastStep={EDITOR_STEPS.PRODUCT_FRONT}
            skipAddressInput={true}
            onClose={this.handleCloseEditor}
            onSave={this.handleSaveEditor}
            saveButtonLabel="Save"
          />
        )}

        {this.state.indexOfItemToEdit === null && (
          <BorderPrintEditor
            product={product}
            selectedProductOption={this.state.selectedFinish}
            items={this.state.items}
            onChangeProductOption={option => this.setState({ selectedFinish: option })}
            onClickItem={index =>
              this.setState({ indexOfItemToEdit: index, editingFromPreBag: true })
            }
            onClickAddPrint={() =>
              this.handleSelectProductType(PRODUCT_TYPE_IDS.PHOTO_PRINT, this.props.productId)
            }
            onIncreaseItemQuantity={itemIndex => this.handleIncreaseItemQuantity(itemIndex)}
            onDecreaseItemQuantity={itemIndex => this.handleDecreaseItemQuantity(itemIndex)}
            onDeleteItem={itemIndex => this.handleDeleteItem(itemIndex)}
            onClickNext={this.approveCurrentPrints}
            onCancel={this.showCancelConfirmationAlert}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  existingPhotoPrintItems: basketSelectors
    .getItems(state)
    .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT),
});

const mapDispatchToProps = dispatch => ({
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), { fromPreBag: true })),
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(BorderPrintEditorContainer);
