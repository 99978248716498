import generateS3AssetUrlFromKey from "../lib/generate-s3-asset-url-from-key"
import {
  productsByProductId,
  hasMinimumQuantityForProduct,
  orderSummaryFormat
} from "../data/products"
import { getPricingSchemeForProductAndQuantity } from "../data/pricing-schemes";

async function trackEvent(...eventDetails) {
  try {
    if (typeof window === 'undefined' || !window.klaviyo) {
      return null;
    }
    // Using the new klaviyo object which returns a Promise
    return window.klaviyo.push([...eventDetails]);
  } catch (error) {
    console.log({ error });
    //return error;
  }
}

//https://developers.klaviyo.com/en/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#added-to-cart
function addedToCart(product) {
  const item = {
    Name: product.title,
    ProductID: product.variant.id,
    Categories: product.tags,
    ImageURL: product.variant.featuredMedia.src,
    URL: `${window.location.origin}/products/${product.handle}`,
    Brand: product.vendor,
    Price: product.variant.price,
    CompareAtPrice: product.variant.compareAtPrice
  };
  return trackEvent('track', 'Added to Cart', item);
}

function trackCheckoutStarted(items, currency = "GBP", orderSummary){
  try {
    let itemNames = []
    let categories = []
    let totalValue = 0;
    let containsPack = false
    const klaviyoItemsGrouped = items.groupBy((item) => item.get('productId')).map((items, productId) => {
      const productItem = items.first()
      let totalForProduct = items.reduce((total, item) => total + item.get("quantity"), 0);
      const imageUrl = generateS3AssetUrlFromKey(productItem.get('preview_s3_key'), false, true)
      const productAppKey = productsByProductId.getIn([productId, "appKey"])
      const productDescription = productsByProductId.getIn([productId, "name"]) //basket_description
      const productTypeName = orderSummaryFormat(productItem.get('productTypeId'))
      const pricingScheme = getPricingSchemeForProductAndQuantity({
        productId: productId,
        quantity: totalForProduct,
        currency: currency,
      });
      const isPackItem = hasMinimumQuantityForProduct(productItem.get('productId'));
      const productPrice = totalForProduct * pricingScheme.get('cost');
      itemNames.push(productDescription)
      categories.push(productTypeName)
      totalValue = totalValue + productPrice
      if(isPackItem){
        containsPack = true;
      }
      return {
        "ProductID": productItem.get('productId'),
        "SKU": productAppKey,
        "ProductName": productDescription,
        "Quantity": totalForProduct,
        "ProductType": productTypeName,
        "ItemPrice": pricingScheme.get('cost'),
        "RowTotal": productPrice,
        "ImageURL": imageUrl,
        "isPack": isPackItem,
        "isRotated": productItem.get('isRotated'),
        "ProductCategories": [productTypeName],
        "Metadata": {"currency": currency}
      }
    })
    const klaviyoItemsPayloadGrouped = Object.entries(klaviyoItemsGrouped.toJS()).map((mappings) => mappings[1])
    console.log("klaviyoItemsPayloadGrouped", klaviyoItemsPayloadGrouped)

    const totalBagValue = orderSummary.get("total")
    let checkoutShippingFirstName = orderSummary.getIn(["checkoutShippingAddress", "first_name"])
    const klaviyoPayload = {
      "$event_id": Date.now(),
      "$value": totalBagValue,
      "BagTotal": totalBagValue,
      "ItemNames": itemNames,
      "CheckoutURL": "https://www.postsnap.com/app/basket",
      "Items": klaviyoItemsPayloadGrouped,
      "ContainsPack": containsPack,
      "Categories": [...new Set(categories)],
      "ShippingFirstName": checkoutShippingFirstName,
      "Metadata": {"currency": currency}
    }
    console.log("Klaviyo Started Checkout", klaviyoPayload)
    trackEvent('track', 'Started Checkout', klaviyoPayload)
  } catch (err) {
    console.warn("Failed to send Klaviyo Started Checkout", err.message);
  }
}



//https://developers.klaviyo.com/en/docs/identify-api-reference
async function identifyUser(email, first_name, last_name = "", isGuest = false) {
  //"$phone_number": phone,
  return trackEvent(
    "identify",
    {
      "$email": email,
      "$first_name": first_name,
      "$last_name": last_name,
      "$consent": ["sms", "email", "web", "mobile"],
      "IsGuest": isGuest,
      "IsCustomer": !isGuest,
    },
  );
}

function setCustomer(customer) {
  if (!customer.email) {
    return null;
  }
  const trackCustomer = {
    $email: customer.email
  };
  return trackEvent('identify', trackCustomer);
}

export default {
  trackEvent,
  addedToCart,
  setCustomer,
  identifyUser,
  trackCheckoutStarted
};