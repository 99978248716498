import React, { Component } from "react";
import { LeftOutlined, MailOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Result, Card, Col, Row } from "antd";
import { isCurrentMajorVersionOutdated } from "../../lib/version-checker";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as Sentry from "@sentry/browser";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    //Sentry.captureException(error, { extra: errorInfo });
    Sentry.withScope(scope => {
      //scope.setExtras(errorInfo);
      const user = this.props.user;
      if (user){
        scope.setUser(
          {
            'id': user.get('id'),
            'email': user.get('email'),
          }
        );
      }
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      scope.setTag("Env", `${process.env.REACT_APP_ENVIRONMENT}`);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    console.log("outdated", isCurrentMajorVersionOutdated())
    if (isCurrentMajorVersionOutdated()) {
      console.log("Major version outdated, resetting");
      localStorage.removeItem(STORAGE_KEYS.BASKET);
      localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
      localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
      localStorage.removeItem(STORAGE_KEYS.APP_VERSION);
    }
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null, eventId: null });
  };

  render() {
    const { children, user } = this.props;
    const { error, errorInfo } = this.state;
    if (error) {
      let userInfo = {};
      if (user) {
        userInfo = {
          email: user.get("email"),
          name: `${user.get("first_name")} ${user.get("last_name")}`,
        };
      }

      console.log("Error Info", errorInfo.componentStack);
      return (
        <div className="error" style={{ marginTop: "2rem" }}>
          <Row type="flex" align="middle" justify="center" className="errorPage">
            <Col xs={{ span: 22 }} md={{ span: 22 }} lg={{ span: 12 }}>
              <Card bordered={true} title={"An error has occurred"}>
                <Row type="flex" justify="center" style={{ marginBottom: "10px" }}>
                  <Col>
                    <Result
                      style={{ fontSize: "15px" }}
                      status="warning"
                      title="Something went wrong"
                      subTitle="We've been notified 👨🏼‍💻 ... please help us by telling us what happened:"
                      extra={[
                        <React.Fragment>
                          {/* <Alert type="error" message={error.toString()} /> */}
                          {/* <br /> */}
                          <Button
                            icon={<MailOutlined />}
                            className="contact-button"
                            href="mailto:team@postsnap.com"
                            onClick={e => {
                              if (Sentry.lastEventId()) {
                                e.preventDefault();
                                Sentry.showReportDialog({
                                  eventId: this.state.eventId,
                                  title: "It looks like we're having issues",
                                  subtitle: "Please help us by telling us what happened",
                                  labelName: "Your Name",
                                  labelSubmit: "Submit Feedback",
                                  successMessage: "Your feedback has been sent. Thank you so much.",
                                  user: userInfo,
                                });
                              }
                            }}
                          >
                            Let us know what happened
                          </Button>
                          <Row type="flex" justify="center" style={{ marginTop: "15px" }}>
                            <Col span={12}>
                              <Button icon={<LeftOutlined />} onClick={() => window.history.back()}>
                                Go Back
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button icon={<ReloadOutlined />} onClick={this.clearError}>
                                Try again
                              </Button>
                            </Col>
                          </Row>
                        </React.Fragment>,
                      ]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return children;
    }
  }
}
export default ErrorBoundary;
