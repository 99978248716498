import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Footer.scss";

const Footer = ({ children, padded, floating, topShadow, background }) => {
  const classes = classNames("footer", {
    "footer--padded": padded,
    "footer--floating": floating,
    "footer--top-shadow": topShadow,
    "footer--bg": background,
  });

  return <footer className={classes}>{children}</footer>;
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
  floating: PropTypes.bool,
  topShadow: PropTypes.bool,
  background: PropTypes.bool,
};

export default Footer;
