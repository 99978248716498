import React from "react";
import MainContent from "../../../components/MainContent/MainContent";
import Form from "../../../components/Form/Form";
import Button from "../../../components/Button/Button";
import Grid from "../../../components/Grid/Grid";

import './CheckoutContactDetails.scss';

import { message } from "antd"

function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === "required") {
      error.message = `This is a required field`
    }
    return error;
  });
}

let contactDetailsSchema = {
  showErrorList: false,
  schema: {
    type: "object",
    required: ["email"],
    properties: {
      email: { type: "string", title: "Email" },
      mobile: { type: "string", title: "Mobile Number" },
      marketing_opt_in: {
        type: "boolean",
        title: "",
        default: true,
      },
    },
  },
  uiSchema: {
    email: {
      "ui:widget": "email",
    },
    marketing_opt_in: {
      "ui:label": false,
      "ui:widget": props => {
        return (
          <Grid.Row>
            <Grid.Column size="0 auto">
              <div className="pretty p-svg" style={{ marginRight: 0 }}>
                <input
                  id="sign-up-marketing-email"
                  type="checkbox"
                  checked={props.value}
                  onChange={() => props.onChange(!props.value)}
                />
                <div className="state">
                  <img
                    className="svg"
                    style={{background: "black"}}
                    src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                    alt=""
                  />
                  <label htmlFor="">&nbsp;</label>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <label htmlFor="sign-up-marketing-email" className="opt-in-label">
                 Yes, I'd like to receive marketing emails with details of regular promotions. Unsubscribe anytime.
              </label>
            </Grid.Column>
          </Grid.Row>
        );
      },
    },
  }
};

const signedInContactDetailsSchema = {
  showErrorList: false,
  schema: {
    type: "object",
    properties: {
      mobile: { type: "string", title: "Mobile Number" },
    },
  },
};

class CheckoutContactDetails extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      formData: this.props.checkoutContactDetails || {}
    };
  }

  handleFormSubmit = form => {
    this.props.onSaveContactDetails(form.formData);
  };

  handleFormChange = form => {
    this.setState({
      formData: form.formData,
    });
  };


  validateForm = (formData, errors) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const phoneRegex = /^(07\d{8,12}|447\d{7,11})$/

    let errorMessage

    if (!emailRegex.test(formData.email) && (formData.email !== "" && formData.email !== undefined)) {
      errors.email.addError("Please enter a valid email address");
      errorMessage = "That doesn't look right. Please check your email address and try again"
    }

    const cleanedMobile = formData.mobile ? formData.mobile.replace(/\s/g, '') : formData.mobile;
    if (!phoneRegex.test(cleanedMobile) && (formData.mobile !== "" && formData.mobile !== undefined) ){
      errors.mobile.addError("Please enter a valid 11 digit UK mobile number starting with 07");
      errorMessage = "That doesn't look right. Please check your mobile number and try again"
    }

    if (errorMessage) {
      message.error(errorMessage)
    }

    return errors;
  }


  render() {
    

    let schema = contactDetailsSchema;

    if (this.props.isSignedIn){
      schema = signedInContactDetailsSchema
      // schema.uiSchema["email"] = {
      //   "ui:disabled": true
      // }
    }

    if (this.props.requiresMobileDetails){
      schema.schema.required = ["email", "mobile"]
    }

    return (
      <MainContent centerContent padded>
        <div className="restricted-width-modal-content">
          <div className="checkout__contact-details">
            {!this.props.isEditingInIsolation && (
              <>
                <h3>Enter Contact Details</h3>
                <p>We'll use this information to send you a receipt and to keep you updated on the status of your order.</p>
              </>
            )}
            <Form
              {...schema}
              formData={this.state.formData}
              onChange={this.handleFormChange}
              onSubmit={this.handleFormSubmit}
              autoComplete={"off"}
              validate={this.validateForm}
              noHtml5Validate={true}
              transformErrors={transformErrors}
            >
              <Button
                block
                theme={this.props.picCollage ? "pc" : "dark-blue"}
                priority={this.props.picCollage ? "secondary" : "primary"}
                type="submit"
                label={this.props.isEditingInIsolation ? "Update" : "Checkout"}
              />
            </Form>
          </div>
        </div>
      </MainContent>
    );
  }
}

CheckoutContactDetails.propTypes = {};
CheckoutContactDetails.defaultProps = {};

export default CheckoutContactDetails;
