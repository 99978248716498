import React, { useMemo } from "react";
import {
  getConfigurationFromParameters,
  scaleConfiguration,
} from "../../pages/PhotoTileEditor/components/TilePreview/tilePreviewCalculator";
import { TilePreview } from "../../pages/PhotoTileEditor/components/TilePreview/TilePreview";
import { useProduct } from "../../utils/useProduct";

export function PhotoTilePreviewSinglePack({ itemToApprove, width }) {
  const product = useProduct(itemToApprove.productId);

  const configuration = useMemo(() => {
    const page0 = itemToApprove.layers.find(layer => layer.type === "Layer::Photo");

    const config = getConfigurationFromParameters({
      borderColor: page0?.config?.layout?.[0]?.borderPrint?.borderColor,
      borderWidth: page0?.config?.layout?.[0]?.borderPrint?.borderWidth,
      withFrame: product.photo_tile_framed,
      frameColor: itemToApprove?.product_options?.frameColor,
      imageFilter: page0?.config?.layout?.[0]?.filter?.bnw ? "grayscale(100%)" : "none",
    });

    return scaleConfiguration(config, width);
  }, [itemToApprove, width]);

  return (
    <TilePreview
      configuration={configuration}
      itemData={itemToApprove}
      width={configuration.width}
    />
  );
}
