import React, { useEffect } from "react";
import { render } from "react-dom";
import HtmlRendererPhotoLayer from "../HtmlRenderer/HtmlRendererPhotoLayer";
import camera from "../../images/edit-image.png";
import "./templateHtml.css";

const TemplateHtml = ({
  templateData,
  scaleBy,
  clickCallback,
  exportable = undefined,
  showImages = true,
  uniqueID,
  genericLayerProps,
  specificDimensions,
  tiled,
  realBorders,
  lowResScaling,
  canDragAndDropAcrossRegions,
  dragAndDropHandlers,
  effect,
  onClickRegion,
  showLocketOverlays,
  onSwapRegionImages,
  reRender,
  layer,
  fontFamily,
  subTextFontFamily,
  previewMode = false,
  longPressSwap,
  active = false,
  page,
  templateSelect,
  swapImageFromTemplate,
  canvasColor: propsCanvasColor,
  highlightText = false,
  forCharacterChecking = false,
  onOverflow,
  onWrap,
  placeholderIdToCheck,
  placeholderContent
}) => {
  const { name, canvasSize, canvasColor, canvasTexture, fontColor } = templateData;
  const { canvasDimensions, screenshotMode, originalWidth } = genericLayerProps;

  const distanceFromLeft = point => {
    if (screenshotMode && canvasDimensions) {
      // console.log(canvasDimensions.width * point[0]);
      return canvasDimensions.width * point[0];
    }
    // const borderThickness = layer.config.border.style.showBorder
    //   ? layer.config.border.thickness * 100
    //   : 0;
    return canvasSize.width * scaleBy * point[0];
  };

  const distanceFromTop = point => {
    if (screenshotMode && canvasDimensions) {
      return canvasDimensions.height * point[1];
    }
    // const borderThickness = layer.config.border.style.showBorder
    //   ? layer.config.border.thickness * 100
    //   : 0;
    return canvasSize.height * scaleBy * point[1];
  };

  function getCursorPosition(event) {
    if (event.type === "touchmove") {
      const touch = event.touches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    var x = event.clientX;
    var y = event.clientY;
    return { x, y };
  }
  const disableScrolling = () => {
    const mainContent = document.getElementById("maincontent");
    mainContent.style.overflowY = "hidden";
    document.documentElement.style.overflow = "hidden";
    document.body.scroll = "no";
  };

  useEffect(() => {
    let idToWatch = null
    const OFFSET = 0;
    const id = `canvas_${name}_${uniqueID}`;
    const main = document.getElementById(id);
    while (main.lastChild) {
      main.removeChild(main.lastChild);
    }
    const container = document.createElement("div");

    const anyBorderedPlaceholders = templateData.placeholders.some(
      placeholder => placeholder.properties.border
    );

    if (canvasColor) {
      container.style.backgroundColor = canvasColor; //'#FFFFFF' //canvasColor; //white
    }

    if (templateSelect && propsCanvasColor) {
      // console.log("bg color", canvasColor);
      container.style.backgroundColor = "#e8e8e8"//propsCanvasColor;
      if (anyBorderedPlaceholders) {
        container.style.backgroundColor = "#e8e8e8";
      }
    }

    // if (
    //   layer.config.border.style.showBorder &&
    //   layer.page !== 0 &&
    //   layer.config?.template?.name === "single-image-default-template"
    // ) {
    //   // console.log(layer.config.border.style.showBorder);
    //   const borderThickness = layer.config.border.thickness * 100;
    //   const borderColor = layer.config.border.style.color;
    //   container.style.border = `${borderThickness}px solid ${borderColor}`;
    //   // container.style.borderWidth = "20px";
    // }
    if (templateSelect && active) {
      container.style.outline = "#4a947799 solid 6px"; //"4px solid #ff5a5f";
    }
    if (canvasTexture) {
      container.style.backgroundImage = `url(${canvasTexture})`;
      container.style.backgroundColor = "transparent";
    }

    // Blank template always white
    if (templateData.name === "blank"){
      container.style.backgroundColor = "#FFFFFF"
    }    


    container.style.height = `${canvasSize.height * scaleBy}px`;
    container.style.width = `${canvasSize.width * scaleBy}px`;
    if (screenshotMode && canvasDimensions) {
      container.style.height = `${canvasDimensions.height * scaleBy}px`;
      container.style.width = `${canvasDimensions.width * scaleBy}px`;
    }

    container.style.position = "relative";
    templateData.placeholders.forEach(placeholder => {
      if (placeholder.type === "IMAGE_PLACEHOLDER") {
        const { bottomLeft, topLeft, topRight, bottomRight } = placeholder.position;
        const { offset, imageSrc, imageData, border: placeholderBorder } = placeholder.properties;
        const imagePlaceholder = document.createElement("div");
        imagePlaceholder.id = `${placeholder.id}_${uniqueID}`;
        imagePlaceholder.setAttribute("data-page", page);
        imagePlaceholder.setAttribute("data-placeholder-id", placeholder.id);
        let up = false;

        if (longPressSwap && imageData) {
          let isTouch = false;
          imagePlaceholder.addEventListener("mouseup", e => {
            if (!isTouch) {
              up = true;
            }
          });
          imagePlaceholder.addEventListener("touchend", e => {
            up = true;
          });
          imagePlaceholder.addEventListener("touchstart", e => {
            isTouch = true;
            if (layer.isDragEnabled) {
              disableScrolling();
            }
            setTimeout(() => {
              if (!up) {
                if (!layer.isDragEnabled) {
                  return;
                }
                up = false;
                const image = document.createElement("div");
                // image.src = imageSrc;
                image.className = "dragPreview";
                image.style.border = "2px solid black";
                image.style.height = "50px";
                image.style.width = "50px";
                const pos = getCursorPosition(e);
                document.body.addEventListener("touchmove", e => {
                  const pos = getCursorPosition(e);
                  image.style.top = `${pos.y}px`;
                  image.style.left = `${pos.x}px`;
                });
                image.style.top = `${pos.y}px`;
                image.style.left = `${pos.x}px`;
                image.style.zIndex = 1000;
                image.style.position = "absolute";
                image.style.backgroundImage = `URL(${imageSrc})`;
                image.style.borderRadius = "100%";
                image.style.backgroundSize = "contain";
                imagePlaceholder.style.opacity = "0.5";
                document.body.appendChild(image);
                swapImageFromTemplate(page, placeholder.id, image, imagePlaceholder, imageData);
              } else {
                up = false;
                if (clickCallback) {
                  clickCallback(placeholder.id);
                }
              }
            }, 50);
          });
          imagePlaceholder.addEventListener("mousedown", e => {
            if (!isTouch) {
              setTimeout(() => {
                if (!up) {
                  if (!layer.isDragEnabled) {
                    return;
                  }
                  up = false;
                  const image = document.createElement("div");
                  // image.src = imageSrc;
                  image.className = "dragPreview";
                  image.style.border = "2px solid black";
                  image.style.height = "50px";
                  image.style.width = "50px";
                  const pos = getCursorPosition(e);
                  document.body.addEventListener("mousemove", e => {
                    const pos = getCursorPosition(e);
                    image.style.top = `${pos.y}px`;
                    image.style.left = `${pos.x}px`;
                  });
                  image.style.top = `${pos.y}px`;
                  image.style.left = `${pos.x}px`;
                  image.style.zIndex = 1000;
                  image.style.position = "absolute";
                  image.style.backgroundImage = `URL(${imageSrc})`;
                  image.style.borderRadius = "100%";
                  image.style.backgroundSize = "contain";
                  imagePlaceholder.style.opacity = "0.5";
                  document.body.appendChild(image);
                  swapImageFromTemplate(page, placeholder.id, image, imagePlaceholder, imageData);
                } else {
                  up = false;
                  if (clickCallback) {
                    clickCallback(placeholder.id, false);
                  }
                }
              }, 200);
            }
          });
          imagePlaceholder.addEventListener("dblclick", function() {
            up = false;
            if (clickCallback) {
              console.log("double click");
              clickCallback(placeholder.id, true);
            }
          });
          let expired;
          let doubleTouch = function(e) {
            if (e.touches.length === 1) {
              if (!expired) {
                expired = e.timeStamp + 400;
              } else if (e.timeStamp <= expired) {
                // remove the default of this event ( Zoom )
                e.preventDefault();
                if (clickCallback) {
                  console.log("double touch");
                  clickCallback(placeholder.id, true);
                }
                // then reset the variable for other "double Touches" event
                expired = null;
              } else {
                // if the second touch was expired, make it as it's the first
                expired = e.timeStamp + 400;
              }
            }
          };
          imagePlaceholder.addEventListener("touchstart", doubleTouch);
        } else {
          imagePlaceholder.addEventListener("click", () => {
            if (clickCallback) {
              clickCallback(placeholder.id, false);
            }
          });
        }
        imagePlaceholder.style.position = "absolute";
        // if (canvasColor) {
        //   // imagePlaceholder.style.backgroundColor = hexToRgbA(canvasColor, 0.7);
        //   imagePlaceholder.style.backgroundColor = "RGB(216, 216, 216)";
        // }
        if (canvasTexture) {
          // imagePlaceholder.style.backgroundImage=`url(${canvasTexture})`;
          // imagePlaceholder.style.backgroundColor = 'transparent';
          imagePlaceholder.style.backgroundColor = "RGB(216, 216, 216)";
        }
        if (canvasTexture) {
          // imagePlaceholder.style.border = `${strokeWidth}px solid rgba(0,0,0,0.2)`;
        }
        const borderAdjustment = 0;
        // layer.config.border.style.showBorder &&
        // layer.page !== 0 &&
        // layer.config?.template?.name === "single-image-default-template"
        //   ? layer.config.border.thickness * 100
        //   : 0;

        const top = `${(distanceFromTop(topLeft, true, placeholder.id) +
          (typeof offset === "number" ? 0 : OFFSET) / 2)}px`;
        
        const left = `${(distanceFromLeft(topLeft) +
          (typeof offset === "number" ? 0 : OFFSET) / 2)}px`;

        const width = `${(distanceFromLeft(topRight) -
          distanceFromLeft(topLeft) -
          (typeof offset === "number" ? 0 : OFFSET) -
          borderAdjustment * 2)}px`
        const height = `${(distanceFromTop(bottomLeft) -
          distanceFromTop(topLeft) -
          (typeof offset === "number" ? 0 : OFFSET) -
          borderAdjustment * 2)}px`;

        imagePlaceholder.style.height = height
        imagePlaceholder.style.width = width
        const isFullHeight =
          screenshotMode && canvasDimensions
            ? parseInt(
                imagePlaceholder.style.height.slice(0, imagePlaceholder.style.height.length - 2)
              ) === canvasDimensions.height
            : parseInt(
                imagePlaceholder.style.height.slice(0, imagePlaceholder.style.height.length - 2)
              ) === canvasSize.height;
        const isFullWidth =
          screenshotMode && canvasDimensions
            ? parseInt(
                imagePlaceholder.style.width.slice(0, imagePlaceholder.style.width.length - 2)
              ) === canvasDimensions.width
            : parseInt(
                imagePlaceholder.style.width.slice(0, imagePlaceholder.style.width.length - 2)
              ) === canvasSize.width;
        imagePlaceholder.style.top = top
        imagePlaceholder.style.left = left
        if (canvasColor) {
          imagePlaceholder.style.backgroundColor = canvasColor;
        }
        if (!imageSrc) {
          imagePlaceholder.style.backgroundColor = "rgba(216, 216, 216,1)";
        }
        imagePlaceholder.style.zIndex = 100;
        if (placeholderBorder) {
          imagePlaceholder.style.borderWidth =
            screenshotMode && canvasDimensions
              ? `${10 * (canvasDimensions.width / 325)}px`
              : `${10 * (originalWidth / 325)}px`;
          Object.keys(placeholderBorder).forEach(property => {
            imagePlaceholder.style[property] = placeholderBorder[property];
          });
        }
        // imagePlaceholder.style.borderBottomStyle = "solid";

        let debug = false;
        if (debug) {
          const debugTL = document.createElement("div");
          debugTL.style.background = "red";
          debugTL.style.position = "absolute";
          debugTL.style.top = 0;
          debugTL.style.left = 0;
          debugTL.style.color = "white";
          debugTL.innerText = `X:${topLeft[0]}\nY:${topLeft[1]}`;
          const debugTR = document.createElement("div");
          debugTR.style.background = "green";
          debugTR.style.position = "absolute";
          debugTR.style.top = 0;
          debugTR.style.right = 0;
          debugTR.style.color = "white";
          debugTR.innerText = `X:${topRight[0]}\nY:${topRight[1]}`;
          const debugBL = document.createElement("div");
          debugBL.style.background = "blue";
          debugBL.style.position = "absolute";
          debugBL.style.bottom = 0;
          debugBL.style.left = 0;
          debugBL.style.color = "white";
          debugBL.innerText = `X:${bottomLeft[0]}\nY:${bottomLeft[1]}`;
          const debugBR = document.createElement("div");
          debugBR.style.background = "yellow";
          debugBR.style.position = "absolute";
          debugBR.style.bottom = 0;
          debugBR.style.right = 0;
          debugBR.style.color = "black";
          debugBR.innerText = `X:${bottomRight[0]}\nY:${bottomRight[1]}`;
          imagePlaceholder.appendChild(debugTL);
          imagePlaceholder.appendChild(debugTR);
          imagePlaceholder.appendChild(debugBL);
          imagePlaceholder.appendChild(debugBR);
        }

        container.appendChild(imagePlaceholder);

        if (imageSrc && !templateSelect) {
          // genericLayerProps.config.border = null;
          const config = {
            border: {
              style: layer.config?.border?.style,
              thickness: 0,
            },
            layout: [
              {
                height: 1,
                width: 1,
                xOffset: 0,
                yOffset: 0,
                image: {
                  ...imageData,
                },
              },
            ],
            rect: {
              x: 0,
              y: 1,
              height: 1,
              width: 1,
            },
          };
          const img = (
            <HtmlRendererPhotoLayer
              dimensions={{
                height:
                  distanceFromTop(bottomLeft) - distanceFromTop(topLeft) - borderAdjustment * 2,
                width:
                  distanceFromLeft(topRight) - distanceFromLeft(topLeft) - borderAdjustment * 2,
              }}
              isTemplate={true}
              isFullHeight={isFullHeight}
              isFullWidth={isFullWidth}
              imageData={imageData}
              {...genericLayerProps}
              specificDimensions={specificDimensions}
              tiled={tiled}
              config={config}
              realBorders={realBorders}
              lowResScaling={lowResScaling}
              canDragAndDropAcrossRegions={canDragAndDropAcrossRegions}
              dragAndDropHandlers={dragAndDropHandlers}
              effect={effect}
              onClickRegion={onClickRegion}
              showLocketOverlays={showLocketOverlays}
              onSwapRegionImages={onSwapRegionImages}
              topCrop={true}
            />
          );

          const interval = setInterval(() => {
            if (document.getElementById(`${placeholder.id}_${uniqueID}`)) {
              render(img, document.getElementById(`${placeholder.id}_${uniqueID}`));
              clearInterval(interval);
            }
          }, 100);
          // setTimeout(() => {
          //   render(img, document.getElementById(`${placeholder.id}_${uniqueID}`));
          // }, 0);
        } else {
          const img = document.createElement("img");
          img.src = camera;
          img.height = scaleBy === 0.3 ? `${80 * scaleBy}` : `${50 * scaleBy}`;
          imagePlaceholder.style.display = "flex";
          img.style.webkitUserDrag = "none";
          imagePlaceholder.style.justifyContent = "center";
          imagePlaceholder.style.alignItems = "center";
          imagePlaceholder.append(img);
        }
      } else if (placeholder.type === "TEXT_PLACEHOLDER") {
        const { bottomLeft, topLeft, topRight } = placeholder.position;
        const {
          offset,
          content,
          content_placeholder,
          fontSize,
          // fontFamily,
          subtext,
          verticalAlignment,
          horizontalAlignment,
          textAlignment,
          color,
          subTextColor,
          subTextHorizontalAlignment,
          subTextAlignment,
          subTextFontSize,
          subTextFontFamily,
          subTextFont,
          subTextMargin
        } = placeholder.properties;
        const showText =
          content === "Aa" || content === "Your text here ... AaBbCc" || content.length === 0
            ? previewMode || page === 0
              ? false
              : true
            : true;
        //console.log("showText", showText)

        const textPlaceholder = document.createElement("div");
        if (clickCallback) {
          textPlaceholder.addEventListener("click", () => {
            clickCallback(placeholder.id);
          });
        }
        const borderAdjustment = 0;
        //   layer.config.border.style.showBorder && layer.page !== 0
        //     ? layer.config.border.thickness * 100
        //     : 0;
        let textPlaceholderHeight, textPlaceholderWidth;
        if (placeholder.position?.direction === "vertical") {
          textPlaceholderHeight = `${distanceFromLeft(topRight) -
            distanceFromLeft(topLeft) -
            (typeof offset === "number" ? offset : OFFSET) -
            borderAdjustment * 2}px`;
          textPlaceholderWidth = `${distanceFromTop(bottomLeft) -
            distanceFromTop(topLeft) -
            (typeof offset === "number" ? offset : OFFSET) -
            borderAdjustment * 2}px`;
        } else {
          textPlaceholderHeight = `${distanceFromTop(bottomLeft) -
            distanceFromTop(topLeft) -
            (typeof offset === "number" ? offset : OFFSET) -
            borderAdjustment * 2}px`;
          textPlaceholderWidth = `${distanceFromLeft(topRight) -
            distanceFromLeft(topLeft) -
            (typeof offset === "number" ? offset : OFFSET) -
            borderAdjustment * 2}px`;
        }

        let innerText = content
        ? `${content.replaceAll('<', '').replaceAll('>', '').replaceAll('\n', '<br/>')}` //.replaceAll(' ', '&nbsp;')
        : showText
        ? subtext
          ? ""
          : content_placeholder.replaceAll('<', '').replaceAll('>', '')
        : "";
        //console.log("innerText", innerText)
        if (innerText === "" && page !== 0 && !previewMode){
          innerText = "Aa"
        }
        textPlaceholder.innerHTML = innerText        
        textPlaceholder.style.color = "black";
        //textPlaceholder.style.border = "1px solid black";
        textPlaceholder.style.transform =
          placeholder.position?.direction === "vertical"
            ? `rotate(-90deg) translate(-${distanceFromTop(bottomLeft) -
                distanceFromTop(topLeft) -
                (typeof offset === "number" ? offset : OFFSET)}px,0px)`
            : "rotate(0)";
        textPlaceholder.style.transformOrigin = "0% 0%";
        //disabling char wrap
        // 
        
        if (forCharacterChecking){
          //textPlaceholder.style.wordBreak = "break-all";
          //textPlaceholder.style.overflow = "auto"; //checkoutHidden
          textPlaceholder.style.overflowWrap = "anywhere";
          textPlaceholder.style.overflow = "hidden"; //hidden
        } else{
          textPlaceholder.style.overflowWrap = "anywhere";
          textPlaceholder.style.overflow = "hidden"; //hidden
        }
        textPlaceholder.style.zIndex = "100";
        // const maxDimension =
        //   parseInt(textPlaceholderWidth.slice(0, textPlaceholderWidth.length - 2)) >
        //   parseInt(textPlaceholderHeight.slice(0, textPlaceholderHeight.length - 2))
        //     ? parseInt(textPlaceholderWidth.slice(0, textPlaceholderWidth.length - 2))
        //     : parseInt(textPlaceholderHeight.slice(0, textPlaceholderHeight.length - 2));
        // let fontScale = (maxDimension / 425) * maxDimension < 300 ? 0.8 : 0.7;
        let fontScale = 0.7;
        if (canvasSize?.width > canvasSize?.height) {
          fontScale = 0.52;
        }
        if (page === 0) {
          fontScale = 0.65; //*=
        }
        //console.log("fontSize", fontSize)
        //console.log("scaleBy", scaleBy)
        //console.log("fontScale", fontScale)
        textPlaceholder.style.fontSize = `${fontSize * scaleBy * fontScale}px` || "20px";
        textPlaceholder.style.lineHeight = page === 0 ? (textPlaceholder.style.fontSize || "20px") : 'normal' //`${fontSize * scaleBy * fontScale * 1.2}px`;
        if (screenshotMode && canvasDimensions) {
          textPlaceholder.style.fontSize =
            `${fontSize * scaleBy * fontScale * (canvasDimensions.width / canvasSize.width)}px` ||
            "20px";
            textPlaceholder.style.lineHeight = page === 0 ? (textPlaceholder.style.fontSize || "20px") : "normal"
          /*textPlaceholder.style.lineHeight = `${fontSize *
            (canvasDimensions.width / canvasSize.width) *
            scaleBy *
            fontScale *
            1.2}px`;
            */
        }
        textPlaceholder.style.display = "flex";
        textPlaceholder.style.flexDirection = "column";
        textPlaceholder.style.textAlign = textAlignment;
        textPlaceholder.style.fontFamily = fontFamily || "Open Sans";
        textPlaceholder.style.alignItems =
          horizontalAlignment === "center"
            ? "center"
            : horizontalAlignment === "left"
            ? "flex-start"
            : "flex-end";
        textPlaceholder.style.justifyContent =
          verticalAlignment === "top"
            ? "flex-start"
            : verticalAlignment === "center"
            ? "center"
            : "flex-end";
        if (forCharacterChecking){
          // For character checking, check with top aligned text otherwise we can't detect scroll overflow.
          textPlaceholder.style.justifyContent = "flex-start"
        }
        textPlaceholder.style.position = "absolute";
        // if (canvasColor) {
        // textPlaceholder.style.backgroundColor = hexToRgbA(canvasColor, 0.2);
        if (content === "" && page && page !== 0 && !screenshotMode) {
          textPlaceholder.style.backgroundColor = "rgba(255,255,255,0.2)";
        }
        if (highlightText && content === ""){
          textPlaceholder.style.backgroundColor = "#cfcfcf6e";
          //textPlaceholder.style.border = "1px solid #979797";
          console.log("placeholder", placeholder)
          textPlaceholder.style.fontSize = placeholder.id === '94' ? "42px" : "33px"//"33px"; // TODO: Better Portrait book identifier
          textPlaceholder.style.color = "black";
          textPlaceholder.innerHTML = `<span>Your Title</span></span><span style='font-size: ${placeholder.id === '94' ? '32px' : '22px'};margin-top:1.5%;'>Your Subtitle</span>`
        }
        // }
        if (canvasTexture) {
          // textPlaceholder.style.backgroundColor = "rgba(255,255,255,0.2)";
        }
        
        if (fontColor) { // Global color
          textPlaceholder.style.color = fontColor;
        }

        if (color){ // color next to property (covers)
          textPlaceholder.style.color = color;
        }
        // textPlaceholder.style.border = strokeWidth
        //   ? `${strokeWidth}px solid ${hexToRgbA(canvasColor, 0.8)}`
        //   : `10px solid ${hexToRgbA(canvasColor, 0.8)}`;
        // textPlaceholder.style.padding = "7.5px 5px";
        const scaledVPadding = 5.5 * (originalWidth / 500);
        const scaledHPadding = 5 * (originalWidth / 500);
        textPlaceholder.style.padding = `${scaledVPadding}px ${scaledHPadding}px`;
        if (screenshotMode && canvasDimensions) {
          const scaledVerticalPadding = 5.5 * (canvasDimensions.width / 500); //canvasDimensions.height
          const scaledHorizontalPadding = 5 * (canvasDimensions.width / 500);
          textPlaceholder.style.padding = `${scaledVerticalPadding}px ${scaledHorizontalPadding}px`;
        }
        // textPlaceholder.style.paddingVertical = "7.5px";
        textPlaceholder.style.height = textPlaceholderHeight;
        textPlaceholder.style.width = textPlaceholderWidth;
        textPlaceholder.style.top = `${distanceFromTop(topLeft) +
          (typeof offset === "number" ? offset : OFFSET) / 2}px`;
        textPlaceholder.style.left = `${distanceFromLeft(topLeft) +
          (typeof offset === "number" ? offset : OFFSET) / 2}px`;

        // if (subtext && content){
        //   const keyline = document.createElement("div");
        //   keyline.style.borderTop = `1px solid ${fontColor}`;
        //   keyline.style.width = "100%";
        //   keyline.style.margin = "5px 0px";
        //   textPlaceholder.appendChild(keyline);
        // }

        if (placeholderIdToCheck === placeholder.id){
          idToWatch = `text-${placeholder.id}_${uniqueID}`;
          textPlaceholder.id = idToWatch;
        }
        
        container.appendChild(textPlaceholder);
        
        if (subtext) {
          const subtextElement = document.createElement("div");
          subtextElement.style.textAlign = subTextAlignment || "center";
          const subTextMarginTopRatio = 0.66 //0.75
          const subTextScale = 1.5 //1.5

          if (fontColor) { // Global color
            subtextElement.style.color = fontColor;
          }
  
          if (subTextColor){ // color next to property (covers)
            subtextElement.style.color = subTextColor;
          }

          subtextElement.style.fontFamily = subTextFontFamily || fontFamily || "Open Sans";
          subtextElement.style.width = "100%"
          subtextElement.style.alignItems =
          subTextHorizontalAlignment === "center"
            ? "center"
            : subTextHorizontalAlignment === "left"
            ? "flex-start"
            : "flex-end";

          subtextElement.innerHTML = subtext //.replaceAll(' ', '&nbsp;');

          subtextElement.style.fontSize = `${subTextFontSize * scaleBy * fontScale}px` || "18px";
          subtextElement.style.lineHeight = 'normal' //subtextElement.style.fontSize
          //subtextElement.style.marginTop = '-3%'
          
          if (subTextMargin){
            subtextElement.style.marginTop = subTextMargin
          } else{
            subtextElement.style.marginTop = `${(subTextFontSize * scaleBy * fontScale) * subTextMarginTopRatio}px` || "5px";
          }
          

          if (screenshotMode && canvasDimensions) {
            subtextElement.style.fontSize = `${subTextFontSize * scaleBy * fontScale * (canvasDimensions.width / canvasSize.width)}px` || "18px";
            subtextElement.style.lineHeight = 'normal' //subtextElement.style.fontSize
            //`${fontSize * scaleBy * fontScale * (canvasDimensions.width / canvasSize.width)}px` ||
            //"20px";
            /*subtextElement.style.fontSize =
              `${18 * scaleBy * fontScale * (canvasDimensions.width / canvasSize.width)}px` ||
              "18px";
            */
            //subtextElement.style.marginTop = '-3%'
            if (subTextMargin){
              subtextElement.style.marginTop = subTextMargin
            }
            else{
              subtextElement.style.marginTop = `${(subTextFontSize * scaleBy * fontScale * (canvasDimensions.width / canvasSize.width)) * subTextMarginTopRatio}px` || "18px";
            }
              
            
          }
          textPlaceholder.appendChild(subtextElement);
        }
      }
    });
    main.appendChild(container);
    if (forCharacterChecking){
      let textPlaceholder = document.getElementById(idToWatch);
      if (textPlaceholder){
        // console.log("textPlaceholder.scrollHeight", textPlaceholder.scrollHeight)
        // console.log("textPlaceholder.clientHeight", textPlaceholder.clientHeight)
        //console.log("textPlaceholder.scrollWidth", textPlaceholder.scrollWidth)
        //console.log("textPlaceholder.clientWidth", textPlaceholder.clientWidth)
        if (textPlaceholder.scrollWidth > textPlaceholder.clientWidth) {
          onWrap(true)
        } else{
          onWrap(false)
        }

        if (textPlaceholder.scrollHeight > textPlaceholder.clientHeight) {
          //console.log('Text inside the div will overflow');
          onOverflow(true)
        } else{
          onOverflow(false)
        }
      } else{
        onOverflow(false)
        onWrap(false)
      }
    }
      // var observer = new MutationObserver(function(mutations) {
      //   debugger;
      //   console.log("listen to", idToWatch)
      //   const textPlaceholder = document.getElementById(idToWatch);
      //   debugger;
      //   if (textPlaceholder){
      //     console.log("textPlaceholder.scrollHeight", textPlaceholder.scrollHeight)
      //     console.log("textPlaceholder.clientHeight", textPlaceholder.clientHeight)
      //     if (textPlaceholder.scrollHeight > textPlaceholder.clientHeight) {
      //       console.log('Text inside the div will overflow');
      //       onOverflow()
      //     }
      //   }
        
      // });
      // var observerConfig = { childList: true };
      // //Start observing the document body for changes
      // observer.observe(document.body, observerConfig);
    //}
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender, canvasDimensions, placeholderContent]);

  // return <div id={`canvas_${name}_${uniqueID}`}></div>;
  return (
    <>
      <div id={`canvas_${name}_${uniqueID}`}></div>
    </>
  );
};

export default React.memo(TemplateHtml, (prevProps, nextProps) => {
  // console.log(prev);
  if (prevProps.genericLayerProps.screenshotMode) {
    return (
      prevProps.reRender === nextProps.reRender &&
      prevProps.genericLayerProps.canvasDimensions === nextProps.genericLayerProps.canvasDimensions
    );
  }
  if (prevProps.forCharacterChecking){
    return false
  }
  return (
    prevProps.reRender === nextProps.reRender &&
    prevProps.layer.isDragEnabled === nextProps.layer.isDragEnabled
  );
});
