import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";

import Button from "../Button/Button";
import Grid from "../Grid/Grid";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import "./AnnouncementPreview.scss";

const Page = React.memo(({ item, pageNumber, debug, width, isLastPage }) => {
  
  const isPortraitProduct =
      item.getIn(["productDimensions", "width"]) <
      item.getIn(["productDimensions", "height"]);
  return (
    <div className="page">
      {debug && <div className="page__number">{pageNumber}</div>}
      <HtmlRenderer
        photoMagazine={true}
        showingPage={true}
        page={pageNumber}
        item={item.toJS()}
        isInteractive={false}
        previewMode={true}
        width={width}
        isPortraitProduct={isPortraitProduct}
      />
    </div>
  );
});

const AnnouncementPreview = forwardRef(({ item, onChangePage }, ref) => {
  const containerRef = useRef(null);
  const renderRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageWidth, setPageWidth] = useState(90);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [hasSeenAllPages, setHasSeenAllPages] = useState(false);
  const [currentApprovalItem, setCurrentApprovalItem] = useState(null);

  useEffect(() => {
    if (currentApprovalItem !== item.get('id')){
      resetPagesForNewItem()
    }
    setCurrentApprovalItem(item.get('id'))

    const aspectRatio = item.getIn(["productDimensions", "width"]) / item.getIn(["productDimensions", "height"]);
    const isPortraitProduct = item.getIn(["productDimensions", "width"]) <
    item.getIn(["productDimensions", "height"]);
    let { width, height } = containerRef.current.getBoundingClientRect();
    let scaleFactor = isLastPage ? 0.9 : 0.9
    let applyWidth = width * scaleFactor;

    if (height > 0 ){
      if (isPortraitProduct && !isLastPage) {
        let proposedHeight = applyWidth / aspectRatio
        if (proposedHeight > height){
          applyWidth = height * aspectRatio;
        }
      }
    }

    if (width) {
      setPageWidth(applyWidth);
    }
  // eslint-disable-next-line
  }, [setPageWidth, item, isLastPage]);

  useEffect(() => {
    if (hasSeenAllPages){
      console.log("Has seen all", hasSeenAllPages)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  // eslint-disable-next-line
  }, [hasSeenAllPages]);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      goToNextPage();
    }
    if (touchStart - touchEnd < -150) {
      goToPreviousPage();
    }
  }

  const resetPagesForNewItem = () => {
    setPageNumber(0)
    setIsLastPage(false)
    setHasSeenAllPages(false)
    onChangePage(false, 0)
  }

  const goToPreviousPage = () => {
    if(pageNumber !== 0){
      setPageNumber(pageNumber-1)
      setIsLastPage(false)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  };

  const goToNextPage = () => {
    if (pageNumber === 0){
      setPageNumber(pageNumber+1)
      setIsLastPage(true)
      setHasSeenAllPages(true)
      console.log("Has seen all pages", hasSeenAllPages)
      onChangePage(hasSeenAllPages, pageNumber)
    }
  };

  useImperativeHandle(ref, () => ({
    goToPreviousPage,
    goToNextPage,
  }));

  return (
    <div className="announcement-preview">
      <div className="announcement-preview__render" style={{flex: 1}} ref={containerRef}>
        <div
          onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
          onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
          onTouchEnd={handleTouchEnd}
          style={{maxHeight: '100%'}}
          ref={renderRef}
        >
          <Page
            item={item}
            pageNumber={pageNumber}
            debug={false}
            width={pageWidth}
            isLastPage={isLastPage}
          />
        </div>
      </div>
      <div className="announcement-preview__navigation" style={{marginTop: 'auto'}}>
        {/* <p className="help-text text-center" style={{marginTop:'5px', marginBottom: '5px'}}>
          {isEvelopePage ? "Envelope" : (pageNumber === 0 ? 'Front' : 'Inside')}
        </p> */}
        <Grid.Row className="preview-nav-buttons" style={{maxWidth: '300px', margin: '0 auto', marginBottom: '0px'}}>
          <Grid.Column>
            <div style={{ paddingRight: "0.25rem" }}>
              <Button
                block
                size="small"
                disabled={pageNumber === 0}
                label="Previous"
                theme="dark-blue"
                onClick={goToPreviousPage}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.25rem" }}>
              <Button
                disabled={isLastPage}
                block
                size="small"
                label="Next"
                theme="dark-blue"
                onClick={goToNextPage}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
});

export default AnnouncementPreview;
