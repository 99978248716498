import {schema as addressSchemas} from "../form-schemas/address";

export default function validateAddress(address) {

  if(Object.keys(address).length === 0){
    return false
  }
  
  let validAddress = false;
  let schema = addressSchemas[address.country];
  let requiredFields;

  if(schema){
    requiredFields = schema.required
    validAddress = requiredFields.map(field => {
      return address.hasOwnProperty(field) && address[field] !== ""
    }).every(Boolean)
  }
  
  return validAddress
}
