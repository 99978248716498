import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";
import classNames from "classnames";

class HtmlRendererSignatureLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    avatarWidth: null,
    onlyWritten: false
  };

  componentDidMount() {
    this.setState({
      avatarWidth: this.avatarContainer.offsetHeight,
      onlyWritten: !this.props.config.avatar && this.props.config.drawing
    });
  }

  componentDidUpdate() {
    const newWidth = this.avatarContainer.offsetHeight;

    if (this.state.avatarWidth !== newWidth) {
      this.setState({
        avatarWidth: newWidth,
      });
    }

    if (this.props.config.avatar && this.state.onlyWritten){
      if (this.props.config.avatar.url){
        this.setState({
          onlyWritten: false,
        });
      }
    } else if (this.state.onlyWritten === false && (!this.props.config.avatar && this.props.config.drawing)){
      this.setState({
        onlyWritten: this.props.config.drawing,
      });
    }
  }

  render() {
    const classes = classNames("html-renderer-signature-layer", {
      "html-renderer-signature-layer__full_width_drawing": this.state.onlyWritten,
    });
  
    const { config } = this.props;
    const avatarStyles = {
      width: this.state.avatarWidth,
    };
    const drawingStyles = {};

    if (config.avatar && config.avatar.url) {
      avatarStyles.backgroundImage = `url(${config.avatar.url})`;
    }

    if (config.drawing && config.drawing.image) {
      drawingStyles.backgroundImage = `url(${config.drawing.image})`;
    }

    return (
      <HtmlRendererRect
        className={classes}
        rect={this.props.config.rect}
        onClick={this.props.onClick}
      >
        <div
          className="html-renderer-signature-layer__avatar"
          ref={el => (this.avatarContainer = el)}
          style={avatarStyles}
        >
          &nbsp;
        </div>
        <div className="html-renderer-signature-layer__drawing" style={drawingStyles} />
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererSignatureLayer;
