import React, { useEffect, useState } from "react";
import {
  addTile,
  deleteTileAtIndex,
  getEmptyTile,
  MAIN_VIEW_MODES,
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../store/ducks/ui-photo-tiles";
import {
  confirmBeforeNavAway,
  findNextTileToEdit,
  getFrameColorFromUrl,
  getItemDataForPack,
  getItemDataSinglePack,
} from "../photoTilesUtils";
import { connect } from "react-redux";
import { getConfigurationFromTile, scaleConfiguration } from "./TilePreview/tilePreviewCalculator";
import { TilePreview } from "./TilePreview/TilePreview";
import Header from "../../../components/Header/Header";
import Button from "../../../components/Button/Button";
import Footer from "../../../components/Footer/Footer";
import Grid from "../../../components/Grid/Grid";
import { actions as basketActions } from "../../../store/ducks/basket";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import QuantityPicker2 from "../../../components/QuantityPicker/QuantityPicker2";

function PhotoTilesPrebag({
  tiles,
  product,
  setTileBeingEditedIndex,
  setTiles,
  setMainViewMode,
  openFilesModal,
  addPendingApprovalItem,
  navigateToApproval,
}) {
  const onEditTileClick = index => {
    setTileBeingEditedIndex(index);
    setMainViewMode(MAIN_VIEW_MODES.EDITOR);
  };

  const onDeleteTile = index => {
    const newList = deleteTileAtIndex(tiles, index);
    setTiles(newList);
  };

  const onAddClick = () => {
    const frameColor = getFrameColorFromUrl();

    const newList = addTile(tiles, getEmptyTile(frameColor));
    setTiles(newList);

    setTileBeingEditedIndex(findNextTileToEdit(newList));
    setMainViewMode(MAIN_VIEW_MODES.EDITOR);
    openFilesModal();
  };

  const shouldAddTileBeDisabled = () => {
    return product.photo_tile_pack_quantity > 1 && tiles.length >= product.photo_tile_pack_quantity;
  };

  const shouldPreviewBeDisabled = () => {
    if (product.photo_tile_pack_quantity > 1 && tiles.length !== product.photo_tile_pack_quantity)
      return true;

    // single quantity
    return tiles.length === 0;
  };

  const onDecreaseClick = (tile, index) => {
    if (tile.quantity > 1) {
      tile.quantity = tile.quantity - 1;
      const updatedList = updateTile(tiles, index, { ...tile });
      setTiles(updatedList);
    }
  };
  const onIncreaseClick = (tile, index) => {
    tile.quantity = tile.quantity + 1;
    const updatedList = updateTile(tiles, index, { ...tile });
    setTiles(updatedList);
  };

  const onPreviewClick = async () => {
    const itemDataList = [];
    if (product.photo_tile_pack_quantity > 1) {
      const packItemData = await getItemDataForPack(tiles, product);
      itemDataList.push(packItemData);
    } else {
      for (const tile of tiles) {
        const itemData = await getItemDataSinglePack(tile, product);
        itemDataList.push(itemData);
      }
    }
    itemDataList.forEach(itemData => {
      addPendingApprovalItem(itemData.toJS());
    });
    navigateToApproval();
    // const itemData = await getRenderData(tiles[0]);
    // console.log(itemData.toJS());
  };

  const [itemData, setItemData] = useState([]);
  useEffect(() => {
    const fetchItemData = async () => {
      const newMap = [];
      for (const tile of tiles) {
        const r = await getItemDataSinglePack(tile, product);
        newMap.push([tile.id, r.toJS()]);
      }
      setItemData(newMap);
    };

    fetchItemData();
  }, [tiles, product]);

  return (
    <div style={{ overflow: "auto", height: "100dvh", display: "flex", flexDirection: "column" }}>
      <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}>
        <Header
          title="Photo Tiles"
          leftAction={
            <Button
              theme="muted"
              priority="tertiary"
              label="Cancel"
              onClick={confirmBeforeNavAway}
            />
          }
        />
      </div>
      <div style={{ flex: 1, overflowY: "scroll" }}>
        <div style={{ marginLeft: 16, marginRight: 16, marginTop: 32 }}>
          {tiles.map((tile, i) => {
            const configuration = getConfigurationFromTile(tile);
            const withFrame = {
              ...configuration,
              withFrame: product?.photo_tile_framed,
              frameColor: getFrameColorFromUrl(),
            };

            const scaledConfiguration = scaleConfiguration(withFrame, 130);

            const itemDataItem = itemData.find(([id]) => id === tile.id);

            return (
              <div
                key={tile.id}
                style={{
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                  borderBottom: "1px solid #eee",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 5,
                    fontSize: 14,
                    width: 130,
                    marginLeft: 16,
                  }}
                >
                  {product.name}
                </div>
                <div key={tile.id} className="collage-print-prebag__item">
                  <TilePreview
                    configuration={scaledConfiguration}
                    imageUrl={tile?.image?.lowResUrl}
                    onClick={() => onEditTileClick(i)}
                    cropData={tile?.crop}
                    itemData={itemDataItem?.[1]}
                    width={scaledConfiguration.width}
                  />
                  <QuantityPicker2
                    quantity={tile.quantity}
                    onIncrease={() => onIncreaseClick(tile, i)}
                    onDecrease={() => onDecreaseClick(tile, i)}
                    onDelete={() => onDeleteTile(i)}
                    delConfirmTitle="Delete this tile?"
                    delSuccessTitle="Tile deleted"
                    onEdit={() => onEditTileClick(i)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer padded topShadow style={{ borderTop: "1px solid #ededed" }}>
        <Grid.Row>
          <Grid.Column>
            <div style={{ paddingRight: "0.5rem" }}>
              <Button
                block
                theme="dark-blue"
                priority="secondary"
                label={"Add Tile"}
                onClick={onAddClick}
                disabled={shouldAddTileBeDisabled()}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.5rem" }}>
              <Button
                label="Preview"
                block
                theme="dark-blue"
                onClick={() => {
                  onPreviewClick({ tiles });
                }}
                disabled={shouldPreviewBeDisabled()}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Footer>
    </div>
  );
}

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  product: photoTilesSelectors.getProduct(state),
});

const mapDispatchToProps = dispatch => ({
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), { fromPreBag: true })),
  setTileBeingEditedIndex: index => dispatch(photoTilesActions.setTileBeingEditedIndex(index)),
  setMainViewMode: mode => dispatch(photoTilesActions.setMainViewMode(mode)),
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
  openFilesModal: () => dispatch(photoTilesActions.setShowFilesModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesPrebag);
