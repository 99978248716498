import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../store/ducks/ui-photo-tiles";
import BottomNavBar from "../../../components/BottomNavbar/BottomNavbar";
import BottomNavbarItem from "../../../components/BottomNavbar/BottomNavbarItem";
import { getCroppedImageAsDataUrl, inMemoryImageCache } from "../photoTilesUtils";

const IMAGE_SIZE = 75;

function PhotoTilesColorEditor({ tiles, tileBeingEditedIndex, setTiles }) {
  const tileEdited = tiles[tileBeingEditedIndex];
  const isOriginalSelected = tileEdited?.bnw === false;

  const updateColor = bnw => {
    const editedTile = tiles[tileBeingEditedIndex];
    editedTile.bnw = bnw;
    const updatedList = updateTile(tiles, tileBeingEditedIndex, { ...editedTile });
    setTiles(updatedList);
  };

  const [menuImage, setMenuImage] = useState(null);
  useEffect(() => {
    const cached = inMemoryImageCache.getItem(tileEdited?.image?.lowResUrl, tileEdited?.crop);
    if (cached) {
      setMenuImage(cached);
      return;
    }

    if (!tileEdited?.crop) {
      setMenuImage(tileEdited?.image?.lowResUrl);
      inMemoryImageCache.addItem(
        tileEdited?.image?.lowResUrl,
        tileEdited?.crop,
        tileEdited?.image?.lowResUrl
      );
      return;
    }

    const updateMenuImage = async () => {
      const image = await getCroppedImageAsDataUrl(
        tileEdited?.image?.lowResUrl,
        tileEdited?.crop,
        tileEdited?.image?.originalImageInfo.width,
        tileEdited?.image?.originalImageInfo.height
      );
      setMenuImage(image);
      inMemoryImageCache.addItem(tileEdited?.image?.lowResUrl, tileEdited?.crop, image);
    };

    updateMenuImage();
  }, [tileEdited?.crop, tileEdited?.image]);

  const hasMenuImage = Boolean(menuImage);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
      <BottomNavbarItem
        active={isOriginalSelected}
        label="Original"
        onClick={() => updateColor(false)}
      >
        {!hasMenuImage && (
          <img
            className="html-renderer-photo-layer__region__placeholder-icon animated fadeIn"
            src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
            alt=""
          />
        )}
        {hasMenuImage && (
          <img
            src={menuImage}
            alt="original"
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              objectFit: "cover",
              // objectPosition: "0% 0%",
            }}
          />
        )}
      </BottomNavbarItem>
      <BottomNavbarItem
        active={!isOriginalSelected}
        label="Black & White"
        onClick={() => updateColor(true)}
      >
        {!hasMenuImage && (
          <img
            className="html-renderer-photo-layer__region__placeholder-icon animated fadeIn"
            src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
            alt=""
          />
        )}
        {hasMenuImage && (
          <img
            src={menuImage}
            alt="original"
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              filter: "grayscale(100%)",
              objectFit: "cover",
              // objectPosition: "0% 0%",
            }}
          />
        )}{" "}
      </BottomNavbarItem>
    </div>
  );
}

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
});

const mapDispatchToProps = dispatch => ({
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesColorEditor);
