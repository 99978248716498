import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";

import * as STORAGE_KEYS from "../../constants/storage-keys";
import EditorContainer from "../../components/Editor/EditorContainer";
import Modal from "../../components/Modal/Modal";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import { routeCreators } from "../../lib/routes";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import * as LAYER_TYPES from "../../constants/layer-types";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import { PRODUCT_TYPE_IDS, productsByProductTypeId } from "../../data/products";
import * as designsData from "../../data/designs";

import SweetAlert from "../../components/SweetAlert/SweetAlert";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
import MODELS from "../../constants/models";

class CanvasEditorContainer extends Component {
  state = {
    itemForEditor: null,
    isItemDuplicationAlertVisible: false,
    toast: null,
    editorItemLoaded: false,
    editorItemLoading: true,
    isApprovalModalVisible: false,
    approvalModalItemId: null,
    approvalModalImage: null,
    renderItemLoading: false,
    renderItemLoaded: false,
    threeDModel: null,
  };

  componentWillMount() {
    this.handleSelectProductType(PRODUCT_TYPE_IDS.CANVAS);
  }

  closeEditor = (closeBehavior = {}) => {
    // TODO: extract this to ENV var
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  setUpEditorItem = async ({ product, productTypeId, designId, selectedVariationIndex }) => {
    const designDetail = await postsnapApi.designs.getDetails(designId);

    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    if (selectedVariationIndex !== undefined) {
      layers = layers.map(layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          return layer.setIn(
            ["config", "s3_key"],
            layer.getIn(["config", "s3_keys", selectedVariationIndex])
          );
        }

        if (layer.get("type") === LAYER_TYPES.TEXT) {
          return layer.setIn(
            ["config", "color"],
            layer.getIn(["config", "colors", selectedVariationIndex])
          );
        }

        return layer;
      });
    }

    let itemData = fromJS({
      imageCrop: true,
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      weight: product.get("weight"),
      productTypeId,
      productId: product.get("id"),
      designId,
      quantity: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      designOptions: designDetail.data.data.preview_s3_keys || [],
      postDate:
        productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD ? moment().add(1, "days") : moment(),
    });

    if (itemData.getIn(["pages", "envelope"])) {
      itemData = itemData.update("layers", layers =>
        layers.concat(
          fromJS([
            {
              config: {
                rect: {
                  x: 0.1,
                  y: 0.7,
                  width: 0.5,
                  height: 0.6,
                },
                font: "Anaheim Gothic",
                size: 10,
                color: "0,0,0",
              },
              id: "ENVELOPE_ADDRESS",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              zindex: null,
              type: "Layer::Address",
            },
            {
              config: {
                rect: {
                  x: 0.695,
                  y: 0.94,
                  width: 0.26,
                  height: 0.23,
                },
              },
              id: "ENVELOPE_STAMP",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              type: "Layer::Indicia",
            },
          ])
        )
      );
    }
    //console.log("Model is", MODELS[product.get("slug")]);
    this.setState({
      itemForEditor: itemData,
      editorItemLoaded: true,
      threeDModel: MODELS[product.get("slug")],
    });
  };

  openApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: true,
    });
  };

  closeApprovalModal = itemId => {
    this.setState({
      isApprovalModalVisible: false,
    });
  };

  closeApprovalModalAndDeleteItem = itemId => {
    this.props.deleteItem(itemId);
    this.closeApprovalModal();
  };

  handleApproveItem = item => {
    this.props.approveItem(item);
    this.closeApprovalModal();
    this.props.goToBasket();
    // this.setState({
    //   isItemDuplicationAlertVisible: true,
    // });
  };

  handleEditItem = itemId => {
    this.props.deleteItem(itemId);
    this.closeApprovalModal();
    const editorInstance = this.editorContainer.getWrappedInstance();
    editorInstance.goToFrontPage();
  };

  handleSelectProductType = async productTypeId => {
    // this.setState({
    //   editorItemLoading: true,
    // });
    // Get the first product ID for this product type ID
    const firstProductForProductType = productsByProductTypeId.get(productTypeId).find(p => {
      return p.get("url_slug") === this.props.productSlug;
    });

    /**
     * Get the first design for that product (the first design is the _only_ design, except for greeting cards which
     * are handled in a separate method)
     */
    const firstDesignForProductId = designsData.designsByProductId
      .get(this.props.productId)
      .first();
    this.setUpEditorItem({
      designId: firstDesignForProductId.get("id"),
      productTypeId: productTypeId,
      product: firstProductForProductType,
    });
  };

  goToApproval = () => {
    this.props.navigateToApproval();
  }

  handleSave = (item) => {
    this.props.addPendingApprovalItem(item.toJS());

    this.goToApproval();
  }

  handleAddToBasket = async item => {
    this.setState({
      renderItemLoading: true,
    });
    const bagItem = await this.props.addBasketItemAsync(item.toJS());
    this.props.renderItem(bagItem.payload.id).then(async res => {
      const item = await this.props.getItem(bagItem.payload.id);
      const s3Key = item.payload.itemData.preview_s3_key;
      this.setState({
        approvalModalItemId: bagItem.payload.id,
        approvalModalImage: s3Key,
        renderItemLoading: false,
      });
      setTimeout(() => {
        this.openApprovalModal();
      }, 300);
    });
  };

  render() {
    const productNameForItem =
      this.state.itemForEditor &&
      (this.state.itemForEditor.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS
        ? "canvas"
        : "card");
    const approvalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isApprovalModalVisible}
        onClose={this.closeApprovalModal}
        title="Approve Your Canvas"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={() => this.closeApprovalModalAndDeleteItem(this.state.approvalModalItemId)}
          />
        }
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Approve"
            onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
          />
        }
      >
        <MainContent scrollable={false} centeredVertically padded key="main-preview">
          <ThreeDimensionalViewer
            model={this.state.threeDModel}
            image={this.state.approvalModalImage}
          />
          <p className="help-text text-center" style={{ zIndex: 1 }}>
            This preview is for illustrative purposes only. The actual product may differ slightly
            from what is shown here.
          </p>
        </MainContent>
        <Footer padded key="footer">
          <div className="footer__split-buttons">
            <Button
              label="Edit"
              priority="secondary"
              onClick={() => this.handleEditItem(this.state.approvalModalItemId)}
            />
            <Button
              label="Approve"
              onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
            />
          </div>
        </Footer>
      </Modal>
    );

    if (this.state.editorItemLoading && !this.state.editorItemLoaded) {
      return (
        <FullScreenLoader
          key="loader"
          message="Loading your canvas..."
          isVisible={this.state.editorItemLoading && !this.state.editorItemLoaded}
        />
      );
    }
    return [
      <Modal
        key="editor-modal"
        isOpen={Boolean(this.state.itemForEditor)}
        onClose={this.closeEditor}
        hasHeader={false}
        animated={false}
      >
        <EditorContainer
          key="editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemForEditor}
          onClose={this.closeEditor}
          onSave={this.handleSave}
          saveButtonLabel="Add to Basket"
        />
      </Modal>,
      approvalModal,
      <SweetAlert
        key="duplication-alert"
        isOpen={this.state.isItemDuplicationAlertVisible}
        title="Checkout or Duplicate?"
        text={`Your ${productNameForItem} has been added to your basket. Would you like to checkout, or duplicate and edit your ${productNameForItem}?`}
        confirmButtonText="Checkout"
        cancelButtonText="Duplicate"
        onConfirm={this.handleDuplicationAlertGoToBasket}
        onCancel={this.handleDuplicationAlertDuplicate}
      />,
      <FullScreenLoader
        key="3d-loader"
        loader="bar"
        message="Please wait while we prepare a preview of your canvas ..."
        isVisible={this.state.renderItemLoading}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), {fromPreBag : true})),
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasEditorContainer);
