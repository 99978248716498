import React from "react";
import PropTypes from "prop-types";

import "./TruncatedText.scss";

const TruncatedText = ({ width, maxWidth, children }) => {
  return (
    <span className="truncated-text" style={{ width, maxWidth }}>
      {children}
    </span>
  );
};

TruncatedText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

TruncatedText.defaultProps = {
  width: "50vw",
  maxWidth: "480px"
};

export default TruncatedText;
