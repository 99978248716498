import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./MainContent.scss";

class MainContent extends Component {
  static propTypes = {
    scrollable: PropTypes.bool.isRequired,
    padded: PropTypes.bool.isRequired,
    centeredVertically: PropTypes.bool,
    alignedTop: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    scrollable: true,
    padded: true,
    alignedTop: false,
    fullWidth: false
  };

  render() {
    const classes = classNames(
      "main-content",
      {
        "main-content--scrollable": this.props.scrollable,
        "main-content--padded": this.props.padded,
        "main-content--centered-vertically": this.props.centeredVertically,
        "main-content--top-aligned": this.props.alignedTop,
        "main-content--full-width": this.props.fullWidth,
      },
      this.props.className
    );

    return (
      <main className={classes} id="maincontent" style={this.props.style}>
        {this.props.children}
      </main>
    );
  }
}

export default MainContent;
