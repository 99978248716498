import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

//import * as CURRENCIES from '../../constants/currencies';
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { routeCreators } from "../../lib/routes";

import "./Sidebar.scss";

const Sidebar = ({
  currency,
  onChangeCurrency,
  isSignedIn,
  onClickSignIn,
  onClickSignOut,
  onToggleIntercom,
  unreadMessageCount,
}) => {
  const storedAppVersion = localStorage.getItem(STORAGE_KEYS.APP_VERSION);

  return (
    <div className="sidebar" key="sidebar">
      <button className="sidebar__item sidebar__item--navlink">
        <a href="https://www.post-snap.com">Shop</a>
      </button>
      <div className="sidebar__item">
        <label htmlFor="currency">Currency:&nbsp;</label>
        <div className="shop__currency-selector"></div>
      </div>
      {isSignedIn && (
        <button className="sidebar__item sidebar__item--navlink">
          <NavLink exact to={routeCreators.prepay()}>
            Buy Prepay Credit
          </NavLink>
        </button>
      )}
      {isSignedIn && (
        <button className="sidebar__item sidebar__item--navlink">
          <NavLink exact to={routeCreators.account()}>
            Account
          </NavLink>
        </button>
      )}
      {isSignedIn && (
        <button className="sidebar__item sidebar__item--navlink">
          <NavLink exact to={routeCreators.orderHistory()}>
            Order History
          </NavLink>
        </button>
      )}
      <button className="sidebar__item" onClick={isSignedIn ? onClickSignOut : onClickSignIn}>
        {isSignedIn ? "Sign Out" : "Sign In"}
      </button>
      {isSignedIn && (
        <button className="sidebar__item" onClick={onToggleIntercom}>
          Message Us
          {unreadMessageCount > 0 && (
            <div className="sidebar__item--message-count">{unreadMessageCount}</div>
          )}
        </button>
      )}
      <button className="sidebar__item sidebar__item--navlink">
        <a href="/blog" title="Postsnap Blog">
          Blog
        </a>
      </button>
      <button className="sidebar__item sidebar__item--navlink">
        <NavLink exact to={routeCreators.pricingInfo()}>
          Pricing Info
        </NavLink>
      </button>
      <button className="sidebar__item sidebar__item--version">
        <span>v{storedAppVersion}</span>
      </button>
    </div>
  );
};

Sidebar.propTypes = {
  currency: PropTypes.string,
  isSignedIn: PropTypes.bool,
  onChangeCurrency: PropTypes.func,
  onClickSignIn: PropTypes.func,
  onClickSignOut: PropTypes.func,
};

Sidebar.defaultProps = {};

export default Sidebar;
