import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { countriesByCurrency } from "../../constants/geo";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import AddressForm from "../Form/AddressForm";
import SweetAlert from "../SweetAlert/SweetAlert";

const DEFAULT_COUNTRY = "United Kingdom";

class PicCollageEditorAddressInputModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    onSaveNewAddress: PropTypes.func.isRequired,
    onSelectAddressBookEntry: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showAuthModal: PropTypes.func.isRequired,
    initialFormData: PropTypes.object,
    isDoubleDirect: PropTypes.bool.isRequired,
    isPackMode: PropTypes.bool,
    titleLabel: PropTypes.string,
    onlyCountries: PropTypes.array,
    onlyNewAddress: PropTypes.bool,
    straightToEdit: PropTypes.bool
  };

  static defaultProps = {
    onlyCountries: [],
    onlyNewAddress: false,
    toShippingSummaryAfterSelection: false,
    straightToEdit: false,
  };

  constructor(props) {
    super(props);

    let defaultCountry = countriesByCurrency[props.currency] || DEFAULT_COUNTRY;

    let isAllowedOwnCountry = true;
    if(this.props.onlyCountries && this.props.onlyCountries.length > 0){
      if(!this.props.onlyCountries.includes(defaultCountry)){
        isAllowedOwnCountry = false;
      }
    }

    this.defaultFormData = {
      country: isAllowedOwnCountry ? defaultCountry : DEFAULT_COUNTRY,
    };
    this.state = {
      newAddressFormData: props.initialFormData || this.defaultFormData,
      ownAddressFormData: this.defaultFormData,
      loadingSendBackToMe: false,
      sendBackToMeIsDoubleDirect: false,
      editingAddress: false,
      addressBookEntryDetailData: null,
      addressBookNewEntryData: {
        country: "United Kingdom",
      },
      onlyNewAddress: props.onlyNewAddress,
      loadingAddresses: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Reset the form when the modal appears, with either the initial form data provided in props, or the default form
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        newAddressFormData: this.props.initialFormData,
      });
      
    }

    if (!prevProps.onlyNewAddress && this.props.onlyNewAddress) {
      this.setState({
        onlyNewAddress: true,
      });
    }
  }

  handleNewAddressFormChange = form => {
    console.log("Form changed", form.formData)
    this.setState({
      newAddressFormData: {
        ...this.state.newAddressFormData,
        ...form.formData,
      },
    });
  };

  handleNewAddressFormSubmit = async () => {
    console.log("New address for submit")
    const address = this.state.newAddressFormData;
    if (address.country === "United States") {
      //const matchingState = militaryStates.concat(provincesByCountry['US']).find(p => p.name === address.county);
      //const stateCode = matchingState && matchingState.short;

      // Skip buggy address verification for now
      this.props.onSaveNewAddress(address);
      // try {
      //   await smartyStreetsApi.verifyAddress({ street: address.line1, city: address.city, state: stateCode });
      //   this.props.onSaveNewAddress(address);
      //   this.setActivePage(MODAL_PAGES.OVERVIEW);
      // } catch (err) {
      //   this.setState({
      //     alert: {
      //       title: 'Verification Unsuccessful',
      //       text: "We can't find that address in the local postal service's database. Would you like to use this address anyway?",
      //       confirmButtonText: 'Yes',
      //       onConfirm: () => {
      //         this.props.onSaveNewAddress(address);
      //         this.setActivePage(MODAL_PAGES.OVERVIEW);
      //         this.setState({ alert: null });
      //       },
      //       cancelButtonText: 'No',
      //       onCancel: () => this.setState({ alert: null })
      //     }
      //   });
      // }
    } else {
      console.log("Saving new address");
      this.props.onSaveNewAddress(address);
      //console.log("Saved new address");
    }
  };

  handleEditingAddress = (isEditing) => {
    this.setState({ editingAddress: isEditing });
  }
  
  handleCancelNewAddress = () => {
    if (this.state.onlyNewAddress){
      this.props.onCancel()
    }
  }

  handleNewAddressReset = form => {
    console.log("this.defaultFormData", this.defaultFormData)
    this.setState({
      newAddressFormData: {
        ...this.defaultFormData
      },
      isEditing: false
    }, () => {
      console.log("this.state.newAddressFormData", this.state.newAddressFormData)
      //this.props.onUpdateCheckoutShippingAddress(this.state.checkoutShippingAddressFormData)
    });
  };

  render() {
    
    let headerProps = {
      title: 'Shipping Address',
      leftAction: (
        <Button
          label="Back"
          priority="tertiary"
          theme="muted"
          onClick={this.handleCancelNewAddress}
        />
      ),
    };

    const onlyNewAddress = this.props.onlyNewAddress === true;
    const showNewAddressOnly = this.props.isOpen && onlyNewAddress;

    const newAddressFormContent = (
      <div className="restricted-width-modal-content">
        <div className="checkout__shipping-container">
          <AddressForm
            key="other-country-address-form"
            ref={el => (this.newAddressForm = el)}
            formData={this.state.newAddressFormData}
            onChange={this.handleNewAddressFormChange}
            onSubmit={this.handleNewAddressFormSubmit}
            showCountry={true}
            onlyCountries={this.props.onlyCountries}
            picCollage={true}
          />
        </div>
      </div>
    )
    
    return [
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="address-alert"
        data-gtm-element="Address Error"
      />,
      <Modal
        key="address-input-modal-only-new"
        isOpen={showNewAddressOnly}
        onClose={this.props.onCancel}
        padded
        {...headerProps}
      >
        {newAddressFormContent}
      </Modal>
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PicCollageEditorAddressInputModal);
