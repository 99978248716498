export default function generateS3AssetUrlFromKey(key, thumbnail = false, cloudfront = false) {
  if (key && key.startsWith("/")) {
    return key;
  }

  if (thumbnail) {
    let regex = /.png/;
    key = key.replace(regex, `-thumbnail.png`);
  }

  if (cloudfront) {
    let newURL = `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${key}`.replace(process.env.REACT_APP_BASE_ASSETS_DOMAIN, "https://d1blw8jjajsijg.cloudfront.net/");
    return newURL
  } else{
    return `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${key}`;
  }
  
}
