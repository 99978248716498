import React from "react";
import PropTypes from "prop-types";

import "./SavedCreditCard.scss";

const SavedCreditCard = ({ last4Digits, brand }) => {
  return (
    <div className="saved-credit-card">
      ●●●● ●●●● ●●●● {last4Digits} {brand && `(${brand})`}
    </div>
  );
};

SavedCreditCard.propTypes = {
  last4Digits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  brand: PropTypes.string,
};

SavedCreditCard.defaultProps = {};

export default SavedCreditCard;
