import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import getTranslatedPixelMeasurement from "../../lib/get-translated-pixel-measurement";
import "./EditorCollageOption.scss";

const COLLAGE_LAYOUT_PREVIEW_HEIGHT = 40;
//const LAYOUT_PADDING = 20

function getRectangleForRegion(region, regionIndex, borderThickness) {
  return (
    <rect
      key={regionIndex}
      x={`${region.xOffset * 100}%`}
      y={`${region.yOffset * 100}%`}
      width={`${region.width * 100}%`}
      height={`${region.height * 100}%`}
      // rx={2}
      // ry={2}
      style={{strokeWidth: `${borderThickness}px`, fill: region.blank ? '#fff' : '#ccc'}}
    />
  );
}

class EditorCollageOption extends React.Component {
  static propTypes = {
    ratio: PropTypes.number.isRequired,
    layout: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
  };

  static defaultProps = {
    ratio: 1,
    active: false,
    large: false,
    gridLayout: false,
    itemsPerRow: 3,
    stretchToFit: false,
    padding: 10
  };

  constructor(props) {
    super(props);

    this.state = {
      width: COLLAGE_LAYOUT_PREVIEW_HEIGHT * props.ratio,
      height: COLLAGE_LAYOUT_PREVIEW_HEIGHT,
    };
  }

  componentDidMount() {
    if (this.props.stretchToFit) {
      const parentWidth = ReactDOM.findDOMNode(this).parentNode.parentNode.offsetWidth;

      const layoutWidth = (parentWidth/this.props.itemsPerRow)-(this.props.padding*2)
      const newDimensions = {
        width: layoutWidth,
        height: layoutWidth / this.props.ratio,
      };

      //if (newDimensions.height > parentHeight) {
      //  newDimensions.height = parentHeight;
      //  newDimensions.width = newDimensions.height * this.props.ratio;
      //}

      this.setState(newDimensions);
    }
  }

  render() {
    const { layout, active, onClick, large } = this.props;
    const classes = classNames("editor__collage-option", {
      "editor__collage-option--active": active,
      "editor__collage-option--large": large,
    });

    let buttonStyles = {
      ...this.state,
    };

    if (this.props.large){
      buttonStyles.boxShadow = "1px 1px 7px 2px #ccc";
    }
    const canvasDimensions = {
      width: this.state.width,
      height: this.state.height
    } 
    const productDimensions = {
      width: this.props.productWidth,
      height: this.props.productHeight
    }
    const collagePrintBorderThickness = getTranslatedPixelMeasurement({
      productDimensions,
      canvasDimensions,
      amount: 3,
    });

    // const borderThicknessVertical = (collagePrintBorderThickness.width*2)/this.props.ratio
    // const borderThicknessHorizontal = (collagePrintBorderThickness.height*2)

    const borderThicknessAsPercent = {
      width: (100-((collagePrintBorderThickness.width*2) * (100/canvasDimensions.width)*2))/100,
      height: (100-((collagePrintBorderThickness.height*2) * (100/canvasDimensions.height)*2))/100
    }

    let styles = {
        transform: `scaleX(${borderThicknessAsPercent.width}) scaleY(${borderThicknessAsPercent.height})`,
        border: 'none',
        padding: 0,
        boxShadow: 'none'
    }
    
    // console.log("collagePrintBorderThickness", collagePrintBorderThickness)
    // console.log("productDimensions", productDimensions)
    // console.log("canvasDimensions", canvasDimensions)
    return (
      <button className={classes} onClick={onClick} style={buttonStyles}>
        <svg
          style={
            styles
          }
          width={this.state.width}
          height={this.state.height}
          viewBox={`0 0 ${this.state.width} ${this.state.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {layout.map((region, regionIndex) => getRectangleForRegion(region, regionIndex, this.props.large ? 0 : 2))}
        </svg>
      </button>
    );
  }
}

export default EditorCollageOption;
