import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import chunk from "lodash/chunk";
import { fromJS } from "immutable";
import moment from "moment";
import shortid from "shortid";
import throttle from "lodash/throttle";
import queryString from "query-string";

import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { pickImages } from "../../lib/file-uploader";
import getMaxResolutionForDimensions from '../../lib/get-max-resolution-for-dimensions';
import * as designsData from "../../data/designs";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  isTextPrintProduct,
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  productsByProductTypeId,
  isDefaultFinishMatt
} from "../../data/products";

import {
  getPricingSchemeForProductAndQuantity,
} from "../../data/pricing-schemes";

import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as authActions, selectors as authSelectors } from "../../store/ducks/auth";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions, selectors as addressSelectors } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";
import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import Button from "../Button/Button";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import AnySizePrintPreBag from "./AnySizePrintPreBag";
import SweetAlert from "../SweetAlert/SweetAlert";

import { console } from "es6-shim";

const DEBUG_UPLOADS = [
  {
    "uuid": "7c23643a-6518-42a1-bb67-12ee37151cba",
    "name": "ios-app.jpg",
    "size": 22285,
    "isStored": true,
    "isImage": true,
    "originalImageInfo": {
      "color_mode": "RGB",
      "orientation": null,
      "format": "JPEG",
      "sequence": false,
      "height": 295,
      "width": 425,
      "geo_location": null,
      "datetime_original": null,
      "dpi": null
    },
    "mimeType": "image/jpeg",
    "originalUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba/",
    "cdnUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba/",
    "cdnUrlModifiers": null,
    "sourceInfo": {
      "source": "local",
      "file": {}
    },
    "highResUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba//-/autorotate/yes/",
    "lowResUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba//-/autorotate/yes//-/resize/400x/"
  }
];

const STEPS = {
  UPLOAD: "UPLOAD",
  CHOOSE_ADDRESS: "CHOOSE_ADDRESS",
};

const DEFAULT_PRODUCT_OPTIONS = {finish: "gloss", border: false };

// 1 = Image size can't be less than Product width/height in MM at Product DPI (300) 
// 0.5 = Image size can't be half of the Product width/height in MM at Product DPI (300) 
// The higher the value, the more strict it is
// TODO: Add a per product config
//const MINIMUM_RESOLUTION_THRESHOLD = 0.4;

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;

  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId
  ) {
    return existingPrebag;
  }
}

class AnySizePrintPrebagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    const defaultProductId = productsByProductTypeId.get(
      this.props.productTypeId
    ).filter(product => product.get('custom_print')).first().get('id');

    this.defaultState = {
      items: [],
      step: STEPS.UPLOAD,
      itemIndexForCropper: null,
      draftRotationState: null,
      isUploadModalVisible: false,
      isAddressInputModalVisible: false,
      isCancelConfirmationAlertVisible: false,
      shouldShowInfoMessage: false,
      isReplacingImage: false,
      replacingImageIndex: null,
      closeURL: 'https://www.postsnap.com/photo-printing/custom-photo-prints',
      asGuest: false,
      isShippingSummaryVisible: false,
      returnToShippingSummaryAfterAddressEdit: false,
      addressToEdit: null,
      isEditingFromSummary: false,
      mappedProductId: defaultProductId, //200, // 200 //202,
      hasMappedtoProduct: false,
      uploadedImages: [],
      productRotatedToFit: false,
      numTiles: 0,
      canTile: false,
      productSpec: "",
      showNoAvailableProducts:false,
      printRightEdgetPercent: 50,
      width: window.innerWidth,
      height: window.innerHeight
    };


    const existingPrebag = getExistingPrebagForProduct(
      "custom-size"
    );

    this.state = { ...this.defaultState };

    if (existingPrebag && existingPrebag.data) {
      this.state.items = existingPrebag.data.items.map(item => fromJS(item));
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillMount() {
    //console.log("document.referrer", document.referrer);
    this.setState({
      closeURL: document.referrer || 'https://www.postsnap.com/photo-printing/custom-photo-prints'
    });
  }

  componentDidUpdate() {
    if (this.state.items.length > 0) {
      // localStorage.setItem(
      //   STORAGE_KEYS.WIP_PREBAG,
      //   JSON.stringify({
      //     productTypeId: "any-size",
      //     productId: this.state.mappedProductId,
      //     productSlug: "any-size",
      //     productTypeSlug: "prints",
      //     data: {
      //       items: this.state.items,
      //     },
      //   })
      // );
    } else {
      const existingPrebag = getExistingPrebagForProduct(
        "custom-size"
      );

      if (existingPrebag) {
        localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
      }
    }
  }


  showUploadModal = (replacing) => {
    this.setState({ isUploadModalVisible: true, isReplacingImage: replacing });
  };
  
  closeUploadModal = ({withPhotos = false}) => {
    this.setState({ isUploadModalVisible: false, isReplacingImage: false });
  };

  handleSetLocketPreview = (val) => {
    this.setState({
      items: this.state.items.map((item, index) => {
        return item.withMutations(item => {
          item.set("locketPreview", val)
          if(item.get('locketShape') === undefined){
            item.set("locketShape", 'heart') // undefined?
          }
          //item.set("locketShape", 'heart') // undefined?
        })
      })   
    });   
  }

  handleSetLocketShape = (val) => {
    this.setState({
      items: this.state.items.map((item, index) => {
        return item.withMutations(item => {
          item.set("locketShape", val)
        })
      })   
    });   
  }

  handleSaveCrop = crop => {
    const itemIndex = 0
    const itemForCropper = this.state.items[itemIndex];
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    const layoutsWithCropData = itemForCropper.getIn(["layers", indexOfPhotoLayer, "config", "layout"])

    this.setState({
      items: this.state.items.map((item, index) => {
        if (index === itemIndex) {

          return item.withMutations(item => {
            layoutsWithCropData.forEach((layout, index) =>{
              item.setIn(
                ["layers", indexOfPhotoLayer, "config", "layout", index, "image", "cropData"],
                fromJS(crop)
              );
            })
            
            //console.log("Setting is rotated to", Boolean(this.state.draftRotationState))
            //console.log("productRotatedToFit", this.state.productRotatedToFit)
            if (!this.state.productRotatedToFit){
              item.set("isRotated", Boolean(this.state.draftRotationState));
            }
            
          });
        }
        return item;
      }),
    });
  };


  generateEditorItemsForImages = async images => {
    const fallbackProductId = productsByProductTypeId.get(
      this.props.productTypeId
    ).filter(product => product.get('custom_print')).first().get('id');
    const productId = this.state.mappedProductId || fallbackProductId //333 //200
    const { productTypeId } = this.props;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    const parsedQueryString = queryString.parse(window.location.search);
    const isProductLandscape = parseFloat(product.get("width")) > parseFloat(product.get("height"));
    const horizontalBleed = parseFloat(product.get("bleed_l")) + parseFloat(product.get("bleed_r"));
    const verticalBleed = parseFloat(product.get("bleed_t")) + parseFloat(product.get("bleed_b"));
    const isSquareProduct = parseFloat(product.get("width")) + horizontalBleed === parseFloat(product.get("height")) + verticalBleed;

    let existingProductOptions = this.state.items.length > 0 ? this.state.items[0].get("product_options").toJS() : false;


    if (this.props.existingPhotoPrintItems.size > 0 ){
      console.log("Has existing prints...")
      if (this.props.existingPhotoPrintItems.some((existingPrint) => existingPrint.get('productId') === productId)) {
        console.log("Has existing prints with same productId");
        const existingPrintsWithSameProduct = this.props.existingPhotoPrintItems.find((existingPrint) => existingPrint.get('productId') === productId)
        existingProductOptions = existingPrintsWithSameProduct.get("product_options").toJS();
      }
    }

    // if (this.state.items.length > 0){
    //   console.log(this.state.items[0].get("product_options").toJS());
    // }
    
    //console.log("Existing product options", existingProductOptions);
    
    let defaultProductOptions = isDefaultFinishMatt(this.props.productId) ? {finish: 'matt' } : {border: true, finish: 'gloss'}

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: 0, //parseFloat(product.get("bleed_t")),
          bottom: 0,  //parseFloat(product.get("bleed_b")),
          left: 0,  //parseFloat(product.get("bleed_l")),
          right: 0, //parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: existingProductOptions || (parsedQueryString.finish ? {finish: parsedQueryString.finish} : false) || defaultProductOptions,
      postDate: moment(),
      duplicateAlertShown: true,
      isCustomPrint: true,
      locketPreview: false
    });

    //const realBorderProduct = hasRealBorders(this.state.mappedProductId);
    //const hasBordersOn = existingProductOptions.border === true;

    const indexOfPhotoLayer = itemData
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    let itemsWithImage;
    let mapImages = new Array(1).fill(images)
    itemsWithImage = mapImages.map((image) => {
      let isImageLandscape = image.originalImageInfo.width >= image.originalImageInfo.height;
      let isImageSquare = image.originalImageInfo.width === image.originalImageInfo.height
      //const dimensions = itemData.get('productDimensions').toJS();
      const portraitExifs = [8,6] //https://www.impulseadventure.com/photo/exif-orientation.html
      if (portraitExifs.includes(image.originalImageInfo.orientation)){
        if (isImageLandscape === true && !isImageSquare){
          console.log("Portrait orientation for landscape image ... turning off auto-rotate...");
          image.highResUrl = image.highResUrl.replace("autorotate/yes/", "autorotate/no/");
          image.lowResUrl = image.lowResUrl.replace("autorotate/yes/", "autorotate/no/");
        } else{
          console.log("Portrait orientation for portrait image, keeping autorotate on");
        }
        console.log("Is portrait")

        if (isImageSquare && isImageLandscape === true){
          console.log("Portrait orientation for square image ... turning off auto-rotate...");
          image.highResUrl = image.highResUrl.replace("autorotate/yes/", "autorotate/no/");
          image.lowResUrl = image.lowResUrl.replace("autorotate/yes/", "autorotate/no/");
        }
        if(!isImageSquare){
          isImageLandscape = false;
        }
      } else if (image.originalImageInfo.orientation !== null){
        if (image.originalImageInfo.orientation === 1){
          console.log("Inferred from width > height: isImageLandscape", isImageLandscape);
        } else{
          isImageLandscape = true;
          console.log("Landscape photo")
        }
      }
      return itemData.withMutations(item => {
        item.set('id', shortid.generate());
        if(isTextPrintProduct(product.get("id"))) { // TODO -> use a product attribute or app key
          item.set("isRotated", false);
        } else {
          console.log("Setting isRotated tooo", !isSquareProduct && isProductLandscape) //&& !isImageLandscape
          item.set("isRotated", !isSquareProduct && isProductLandscape); //&& !isImageLandscape
        }
        
        item.setIn(
          ["layers", indexOfPhotoLayer, "config", "layout", 0, "image"],
          fromJS({
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            originalSize: {
              width: image.originalImageInfo.width,
              height: image.originalImageInfo.height,
            },
            cropData: null,
          })
        );
      });
    });

    return itemsWithImage;
  };



  debugUpload = async () => {
    const debugImages = new Array(1).fill(DEBUG_UPLOADS[0])
    const generatedItems = await this.generateEditorItemsForImages(debugImages);
    this.setState({
      items: [...this.state.items, ...generatedItems],
    });
    this.closeUploadModal({withPhotos: true});
  };


  pickImageFromUploadcare = async source => {
    try {
      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };
    
      const hasMinimumQty = hasMinimumQuantityForProduct(this.state.mappedProductId);
      const suggestedAmount = hasMinimumQty ? minimumQuantityForProduct(this.state.mappedProductId) : 100;

      const currentQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);
      
      let amount = suggestedAmount - currentQuantity;

      if (currentQuantity >= suggestedAmount){
        amount = suggestedAmount-(currentQuantity-suggestedAmount);
        console.log("Amount is", amount);
      }

      const product = productsByProductId.get(this.state.mappedProductId);
      const productWidth =  (product.get('width') + parseInt(product.get('bleed_l')) + parseInt(product.get('bleed_r')));
      const productHeight =  (product.get('height') + parseInt(product.get('bleed_t')) + parseInt(product.get('bleed_b')));
      
      let maxWidthOrHeight = Math.max(productWidth, productHeight);

      if (maxWidthOrHeight <= 54) {
        console.log(`Bumping small print (${maxWidthOrHeight}mm) maxResolution quality by 1.5`)
        maxWidthOrHeight = maxWidthOrHeight * 1.5;
      }

      // TODO - we want productDimensions including bleed ideally
      let maxResolution = getMaxResolutionForDimensions({
        width: maxWidthOrHeight,
        height: maxWidthOrHeight,
        dpi: product.get('dpi'),
      });

      // TODO: check the 100% is needed and not the default
      maxResolution = `${maxResolution} 100%`

      let images = await pickImages({
        amount: 1,
        source,
        maxResolution,
        onTotalProgressUpdate: throttle(progressHandler, 500),
        autoRotate: false,
        onStartUpload: () => {}
      });
    
      if(amount === 1){
        images = new Array(1).fill(images)
      }
      
      const generatedItems = await this.generateEditorItemsForImages(images);
      const isFirstSetOfItems = this.state.items.length === 0;


      //Check if can tile...
      const existingItem = this.state.items[0]
      let newItem;
      if (existingItem && existingItem.get('tiled')){
        const indexOfPhotoLayerForNew = generatedItems[0]
          .get("layers")
          .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        const indexOfPhotoLayerForExisting = existingItem
          .get("layers")
          .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        
        let layoutsForExisting = existingItem.getIn(['layers', indexOfPhotoLayerForExisting, 'config', 'layout'])
        const imageDataForNew = generatedItems[0].getIn(['layers', indexOfPhotoLayerForNew, 'config', 'layout', 0, 'image']).toJS()

        const layoutsForExistingWithNewImage = layoutsForExisting.toJS().map(layout => {
          return {...layout, ...{image :imageDataForNew}}
        })
        const generatedItemWithTiledLayers = generatedItems[0].setIn(['layers', indexOfPhotoLayerForNew, 'config', 'layout'], fromJS(layoutsForExistingWithNewImage))
        //const existingItemWIthNewImage = existingItem.setIn(['layers', indexOfPhotoLayerForExisting, 'config', 'layout'], fromJS(layoutsForExistingWithNewImage))
        newItem = {...existingItem.toJS(), ...generatedItemWithTiledLayers.toJS()}
        //newItem = existingItemWIthNewImage.toJS()
      } else{
        newItem = {...existingItem.toJS(), ...generatedItems[0].toJS()}
      }

      const selectedWidthAsMM = existingItem.get('customWidth')
      const selectedHeightAsMM = existingItem.get('customHeight')

      const mmPadding = 0.5
      const totalTilePadding = mmPadding*2; //1mm
      const minimumOuterBorderMM = 5 // 5mm border around edge as minimum
      const totalMinimumBorderWidth = minimumOuterBorderMM* 2
      const totalPaddingPlusOuterBorder = totalMinimumBorderWidth
      const howManyFitWidth = Math.floor((productWidth-totalPaddingPlusOuterBorder)/(selectedWidthAsMM+totalTilePadding))
      const howManyFitHeight = Math.floor((productHeight-totalPaddingPlusOuterBorder)/(selectedHeightAsMM+totalTilePadding))
      const numTiles = howManyFitWidth * howManyFitHeight
      const canTile = numTiles > 1
      const rightEdgetPercent = 50 + ((selectedWidthAsMM * (100/productWidth))/2)

      if (this.state.isReplacingImage){
        this.setState({
          items: [fromJS(newItem)],
          shouldShowInfoMessage : isFirstSetOfItems,
          noPhoto: false,
          numTiles: numTiles,
          canTile: canTile,
          printRightEdgetPercent: this.state.printRightEdgetPercent,
          isReplacingImage: false,
        });
      } else{
        this.setState({
          items: [fromJS(newItem)],
          shouldShowInfoMessage : isFirstSetOfItems,
          noPhoto: false,
          numTiles: numTiles,
          canTile: canTile,
          printRightEdgetPercent: rightEdgetPercent
        }, () => {
          setTimeout(() => {
            const width = newItem.selectedWidth
            const height = newItem.selectedHeight
            const customWidth = newItem.customWidth
            const customHeight = newItem.customHeight
            const itemTiled = newItem.tiled
            const itemUnits = newItem.units
            this.handleChangeCustomDimensions(width, height, customWidth, customHeight, itemUnits, itemTiled)
          }, 500 );
        });
      }

      this.closeUploadModal({withPhotos: true});
    } catch (err) {
      console.log("Cancelled upload:", err);
    }
  };

  generateItemForProductId = async productId => {
    const productTypeId = this.props.productTypeId;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId)
      .sort((a, b) => a.page - b.page)
      .reverse();

    let defaultProductOptions = isDefaultFinishMatt(productId) ? {finish: 'matt', border: false } : DEFAULT_PRODUCT_OPTIONS

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: 0 ,// parseFloat(product.get("bleed_t")),
          bottom: 0, //parseFloat(product.get("bleed_b")),
          left: 0, //parseFloat(product.get("bleed_l")),
          right: 0, //parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: defaultProductOptions,
      postDate: moment(),
      duplicateAlertShown: false,
      isCustomPrint: true,
      locketPreview: false,
    });

    return itemData;
  };


  clearPreBag = () => {
    this.setState(this.defaultState, () => {
      // const existingPrebag = getExistingPrebagForProduct(
      //   "any-size"
      // );

      // if (existingPrebag) {
        localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
        window.location.reload()
      // }
    })
  }

  finalizePreBag = () => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: "custom-size",
        productId: this.state.mappedProductId,
        productSlug: "custom-size",
        productTypeSlug: "prints",
        data: {
          items: this.state.items,
        },
      })
    );


    const itemChunks = chunk(this.state.items, 10);
    itemChunks.forEach(async (chunkedItems, index) => {
      chunkedItems.forEach(async item => {
        this.props.addPendingApprovalItem(item.toJS());
        //this.props.renderItem(item.get('id'))

        await new Promise(r => setTimeout(r, 150));
        //console.log("Added item");
      });
      await new Promise(r => setTimeout(r, 250));
      console.log(`Adding ${chunkedItems.length} of chunk ${index}`);
    })

    this.props.navigateToApproval();
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    if(this.state.closeURL){
      window.location = this.state.closeURL;
    } else {
      window.history.back();
    }
  };

  handleChangeProductOption = (option, value) => {
    this.setState({
      items: this.state.items.map(item => item.setIn(["product_options", option], value)),
    }, () => {
      //message.success(`Print ${option} changed to ${value}`);
    });
  };

  adjustItemQuantity = (amount) => {
    this.setState({
      items: this.state.items.map(item => item.update("quantity", qty => Math.max(1, qty + amount))),
    });
  };

  handleChangeCustomDimensions = async (selectedWidth, selectedHeight, selectedWidthAsMM, selectedHeightAsMM, chosenUnits, tiled) => {
    if (selectedWidthAsMM === 0 || selectedHeightAsMM === 0 || isNaN(selectedWidthAsMM) || isNaN(selectedHeightAsMM)){
      this.setState({
        noAvailableProducts: true,
        mappedProductId: null,
        productSpec: "",
      })
      return
    }

    const smallestDimension = selectedWidth <= selectedHeight ? "width" : "height"
    const largestDimension = selectedHeight <= selectedWidth ? "width" : "height"
    const availableProducts = productsByProductTypeId.get(
      this.props.productTypeId
    ).filter(product => product.get('custom_print'));  // product.get('custom_print') //!APP_KEYS_EXCLUDED_FROM_ANY_SIZE.includes(product.get('appKey'))
    
    const productsByNaturalSize = availableProducts.filter(product => {
      const productWidth = product.get('width');
      const productHeight = product.get('height')
      const fitsNaturally = productWidth >= selectedWidthAsMM && productHeight >= selectedHeightAsMM
      //const fitsRotated = productWidth >= selectedHeightAsMM && productHeight >= selectedWidthAsMM
      return fitsNaturally //|| fitsRotated
    }).sort((p1, p2) => (p1.get(smallestDimension) - p2.get(smallestDimension)) + (p1.get(largestDimension) - p2.get(largestDimension)));
    //console.log("productsByNaturalSize", productsByNaturalSize.toJS())
    
    const productsByRotatedSize = availableProducts.filter(product => {
      const productWidth = product.get('width');
      const productHeight = product.get('height')
      //const fitsNaturally = productWidth >= selectedWidthAsMM && productHeight >= selectedHeightAsMM
      const fitsRotated = productWidth >= selectedHeightAsMM && productHeight >= selectedWidthAsMM
      return fitsRotated //|| fitsRotated
    }).sort((p1, p2) => (p1.get(largestDimension) - p2.get(largestDimension)) + (p1.get(smallestDimension) - p2.get(smallestDimension)));

    //console.log("productsByRotatedSize", productsByRotatedSize.toJS())

    const productByNaturalSize = productsByNaturalSize.size > 0 ? productsByNaturalSize.first() : null
    const productByRotatedSize = productsByRotatedSize.size > 0 ? productsByRotatedSize.first() : null

    let smallestProduct

    if (productByRotatedSize && productByNaturalSize){
      const smallestNaturalValue = Math.min(productByNaturalSize.get("width"), productByNaturalSize.get("height"))
      const smallestRotatedValue = Math.min(productByRotatedSize.get("width"), productByRotatedSize.get("height"))
      smallestProduct = smallestNaturalValue < smallestRotatedValue ? productByNaturalSize : productByRotatedSize
    } else if (productByNaturalSize){
      smallestProduct = productByNaturalSize
    } else if (productByRotatedSize){
      smallestProduct = productByRotatedSize
    }
  
    if(!smallestProduct){
      this.setState({
        noAvailableProducts: true,
        mappedProductId: null,
        productSpec: "",
        showNoAvailableProducts: true,
      }, () => {
        // message.open({
        //   content: "We can't print this size on photo paper - our largest print size is 12x8″",
        //   duration: 1,
        //   className: 'crop-info',
        //   key: "info-message",
        //   style: {marginTop: '10px'},
        // });
      })
    }
    
    if(smallestProduct){
      console.log(smallestProduct.get('appKey'))
      const chosenProduct = smallestProduct

      let hasRotatedProductToFit = false

      if(selectedWidthAsMM < chosenProduct.get('width') && selectedHeightAsMM > chosenProduct.get('height')){
        hasRotatedProductToFit = true
      }

      let unitIdentifier = "″";
      switch(chosenUnits) {
        case "inches":
          unitIdentifier = "″";
          break;
        case "cm":
          unitIdentifier = "cm";
          break;
        case "mm":
          unitIdentifier = "mm";
          break;
        default:
          unitIdentifier = "";
      }

      const productSpec = `${selectedWidth}×${selectedHeight}${unitIdentifier}`
      // on a ${chosenProduct.get('name').replace('Prints', '').replace(' × ','×')}

      this.setState({
        mappedProductId: chosenProduct.get('id'),
        mappedProductTypeId: chosenProduct.get('productTypeId'),
        productRotatedToFit: hasRotatedProductToFit,
        noAvailableProducts: false,
        productSpec: productSpec,
        showNoAvailableProducts: false,
      })


      //console.log(productsBySize.toJS())

       //productsByNaturalSize.first()
      //this.setState({mappedProductId : chosenProduct.get('id')}, async () => {

        const item = this.state.items[0];

        if(!item){

          let newItemForProduct = await this.generateItemForProductId(chosenProduct.get('id'));
          newItemForProduct = newItemForProduct.withMutations(item => {
            if(hasRotatedProductToFit){
              item.set('isRotated', true)
            }
            item.set("selectedWidth", selectedWidth)
            item.set("selectedHeight", selectedHeight)
            item.set("customWidth", selectedWidthAsMM)
            item.set("customHeight", selectedHeightAsMM)
            item.set("tiled", tiled)
            if (tiled){
              item.set("locketPreview", false)
            }
            item.set("hasSpecificDimensions", true)
            item.set("units", chosenUnits)
            item.set("customPrintDescription", productSpec)
          })
          this.setState({
            items: [fromJS(newItemForProduct)],
            noPhoto: true,
          })
        }

        if (item){
          const existingItem = this.state.items[0];
          const indexOfPhotoLayer = existingItem
            .get("layers")
            .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
          let existingImageData
          if(!isNaN(indexOfPhotoLayer)){
            if (existingItem.hasIn(["layers", indexOfPhotoLayer, "config", "layout", 0, "image"])){
              existingImageData = existingItem.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0, "image"]).toJS();
            }
            
          }
          
          let newItemForProduct = await this.generateItemForProductId(chosenProduct.get('id'));
          const indexOfNewPhotoLayer = newItemForProduct
            .get("layers")
            .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

          newItemForProduct = newItemForProduct.withMutations(item => {
            if(existingImageData){
              item.setIn(
                ["layers", indexOfNewPhotoLayer, "config", "layout", 0, "image"],
                fromJS(existingImageData)
              );
            }
            
            if(hasRotatedProductToFit){
              item.set('isRotated', true)
            }
            item.set("selectedWidth", selectedWidth)
            item.set("selectedHeight", selectedHeight)
            item.set("customWidth", selectedWidthAsMM)
            item.set("customHeight", selectedHeightAsMM)
            item.set("tiled", tiled)
            item.set("locketPreview", existingItem.get('locketPreview'))
            item.set("locketShape", existingItem.get('locketShape'))
            if (tiled){
              item.set("locketPreview", false)
            }
            item.set("hasSpecificDimensions", true)
            item.set("units", chosenUnits)
            item.set("customPrintDescription", productSpec)
            
            item.set("product_options", fromJS(existingItem.get('product_options')))
            item.set("quantity", existingItem.get('quantity'))
            //item.set("tiled", existingItem.get('tiled'))


            if(selectedWidthAsMM > 0 && selectedHeightAsMM > 0){
              const productWidth = chosenProduct.get('width')
              const productHeight = chosenProduct.get('height')

              const mmPadding = 0.5
              const totalTilePadding = mmPadding*2; //1mm

              const minimumOuterBorderMM = 5 // 5mm border around edge as minimum
              const totalMinimumBorderWidth = minimumOuterBorderMM* 2
              const totalPaddingPlusOuterBorder = totalMinimumBorderWidth
              const howManyFitWidth = Math.floor((productWidth-totalPaddingPlusOuterBorder)/(selectedWidthAsMM+totalTilePadding))
              const widthUnused = (productWidth-totalPaddingPlusOuterBorder)-(howManyFitWidth*(selectedWidthAsMM+totalTilePadding))          
              const howManyFitHeight = Math.floor((productHeight-totalPaddingPlusOuterBorder)/(selectedHeightAsMM+totalTilePadding))
              const heightUnused = (productHeight-totalPaddingPlusOuterBorder)-(howManyFitHeight*(selectedHeightAsMM+totalTilePadding))
              const numTiles = howManyFitWidth * howManyFitHeight
              const canTile = numTiles > 1

              this.setState({
                numTiles: numTiles,
                canTile: canTile,
              })

              if (!canTile){
                item.set("tiled", false)
              }

              let regionIndex = 0
              
              let rowIndex = 0
              if (tiled){
                new Array(howManyFitHeight).fill().forEach(widthTile => {
                  let columnIndex = 0
                  new Array(howManyFitWidth).fill().forEach(heightTile => {
                    const firstRowOffset = mmPadding+(heightUnused/2)+(totalPaddingPlusOuterBorder/2)///mmPadding+horizontalSpaceAroundEach
                    const heightofOneTilePlusSpace = firstRowOffset+(totalTilePadding*rowIndex)+(selectedHeightAsMM *rowIndex)
                    const yOffset = rowIndex === 0 ? firstRowOffset : heightofOneTilePlusSpace
                    //mmPadding+(horizontalSpaceAroundEach)+(rowIndex * heightPlusPadding) + (horizontalSpaceAroundEach*rowIndex)

                    const firstColumnOffset = mmPadding+widthUnused/2+(totalPaddingPlusOuterBorder/2)//mmPadding+verticalSpaceAroundEach
                    const widthOfOneTilePlusSpace = firstColumnOffset+(totalTilePadding*columnIndex)+(selectedWidthAsMM *columnIndex) //firstColumnOffset+((verticalSpaceAroundEach*2)*columnIndex)+((mmPadding*2)*columnIndex)+(selectedWidthAsMM *columnIndex)
                    const xOffset = columnIndex === 0 ? firstColumnOffset : (widthOfOneTilePlusSpace)
                    //mmPadding+(verticalSpaceAroundEach)+(columnIndex * widthPlusPadding) + (verticalSpaceAroundEach*columnIndex)

                    const yOffsetAsPercentage = (yOffset*(100/productHeight))/100
                    const xOffsetAsPercentage = (xOffset*(100/productWidth))/100
  
                    let layout = {
                      yOffset: yOffsetAsPercentage,
                      xOffset: xOffsetAsPercentage,
                      width: (selectedWidthAsMM * (100/productWidth))/100,
                      height: (selectedHeightAsMM * (100/productHeight))/100
                    }
                    columnIndex+=1
  
                    let existingLayout = item.getIn(["layers", indexOfNewPhotoLayer, "config", "layout", 0]).toJS()
  
                    let newLayout = {...existingLayout, ...layout}

                    item.setIn(
                      ["layers", indexOfNewPhotoLayer, "config", "layout", regionIndex],
                      fromJS(newLayout)
                    );
                    
                    regionIndex+=1
                    if (columnIndex === howManyFitWidth){

                      this.setState({
                        printRightEdgetPercent: (layout.xOffset + layout.width) * 100.0 //(xOffsetAsPercentage*100)+layout.width
                      })
                    }
                  })
                  rowIndex+=1
                })
              } else{
                let rightEdgetPercent = 50 + ((selectedWidthAsMM * (100/productWidth))/2)

                // if(this.state.productRotatedToFit){
                //    rightEdgetPercent = 50 + ((selectedHeightAsMM * (100/productHeight))/2)
                // }
                this.setState({
                  printRightEdgetPercent: rightEdgetPercent
                })
              }
              
            }
            return item
          });
          
          this.setState({
            items: [fromJS(newItemForProduct)],
            noPhoto: false,
            // mappedProductId:chosenProduct.get('id'),
            // productRotatedToFit: hasRotatedProductToFit,
            // noAvailableProducts: false,
            // productSpec: productSpec,
          }, () => {
            //message.success(`This will be on a ${chosenProduct.get('name')}`);
          });
        }
      //});
    }
  }


  render() {

    const recentlyUploaded = JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;
    const isDebugUser = this.props.user && this.props.user.get("unpublished_designs")

    let uploadModal = (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title="Upload A Photo"
      >
        <MainContent scrollable={true} padded alignedTop>
          <div className="restricted-width-modal-content">
            {(process.env.NODE_ENV === "development" || isDebugUser) && (
              <Button
                theme="dark-blue"
                block
                label="DEBUG UPLOAD"
                icon="phone"
                onClick={this.debugUpload}
              />
            )}
            <Button
              block
              theme="dark-blue"
              label="From Device"
              icon="phone"
              onClick={() => this.pickImageFromUploadcare("file")}
              dataGtmElement="Photo Upload Source"
            />
            {anyRecentlyUploaded && (
              <Button
                block
                className="btn--recent"
                label="Recently Uploaded"
                icon="upload"
                dataGtmElement="Photo Upload Source"
                onClick={() => this.pickImageFromUploadcare("favorites")}
              />
            )}
            <Button
              block
              icon="facebook"
              className="btn--facebook"
              label="Facebook"
              dataGtmElement="Photo Upload Source"
              onClick={() => this.pickImageFromUploadcare("facebook")}
            />
            <Button
              block
              icon="instagram"
              className="btn--instagram"
              dataGtmElement="Photo Upload Source"
              label="Instagram"
              onClick={() => this.pickImageFromUploadcare("instagram")}
            />
            <Button
              block
              icon="gdrive"
              dataGtmElement="Photo Upload Source"
              className="btn--google"
              label="Google Drive"
              onClick={() => this.pickImageFromUploadcare("gdrive")}
            />
          </div>
        </MainContent>
      </Modal>
    );

    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`You have unsaved changes, are you sure you want to go back?`}
        cancelButtonText="Exit & lose changes"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    let totalQuantity = 0
    totalQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);
    
    const item = this.state.items[0]
    let hasPhoto = false

    let defaultFrameOptions = {
      width: 0,
      height: 0,
      units: queryString.parse(window.location.search).locket ? 'mm' : '',
      tiled: true,
      locketPreview: false,
      locketShape: 'heart'
    }
  
    if(item){
      //console.log("locaketPreview", item.get('locketPreview'))
      defaultFrameOptions = {
        width: item.get('selectedWidth'),
        height: item.get('selectedHeight'),
        units: item.get('units'),
        tiled: item.get('tiled') ? true : this.state.canTile ? true : false,
        locketPreview: item.get('tiled') ? false : item.get('locketPreview'),
        locketShape: item.get('locketShape') || 'heart'
      }
    }
    
    
    let pricingScheme = null;
    if (item){
      totalQuantity = item.get("quantity");
      pricingScheme = getPricingSchemeForProductAndQuantity({
        productId: item.get('productId'),
        quantity: totalQuantity,
        currency: this.props.currency,
      });
    }

    if (item){
      if (item.get('layers').some(layer => layer.get("type") === LAYER_TYPES.PHOTO)){
        const indexOfPhotoLayer = item
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        hasPhoto = item.hasIn(["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "src"])
      }
    }

    // console.log("Items", this.state.items)
    // console.log("defaultFrameOptions", defaultFrameOptions)
    // console.log("HasPhoto", hasPhoto);

    return (
      <React.Fragment>
        {uploadModal}
        {cancelConfirmationAlert}
        <AnySizePrintPreBag
          showInfoMessage={this.state.shouldShowInfoMessage}
          onInfoMessageDismissed={this.infoMessageDismissed}
          currency={this.props.currency}
          items={this.state.items}
          uploadedImages={this.state.uploadedImages}
          totalQuantity={totalQuantity}
          onStartUpload={this.showUploadModal}
          onIncreaseItemQuantity={() =>this.adjustItemQuantity(1)}
          onDecreaseItemQuantity={() => this.adjustItemQuantity(-1)}
          onRotateItem={(itemId) => this.rotateItem(itemId)}
          onDeleteItem={this.deleteItem}
          onClickItem={this.startCropping}
          onClickNext={this.finalizePreBag}
          onClickRestart={this.clearPreBag}
          onCancel={this.navigateBack}
          onSaveCrop={this.handleSaveCrop}
          onChangeWidthOrHeight={this.handleChangeCustomDimensions}
          frameOptions={defaultFrameOptions}
          numTiles={this.state.numTiles}
          canTile={this.state.canTile}
          productSpec={this.state.productSpec}
          hasProduct={!this.state.noAvailableProducts}
          showNoAvailableProducts={this.state.showNoAvailableProducts}
          handleChangeProductOption={this.handleChangeProductOption}
          costPerPrint={pricingScheme && pricingScheme.get("cost")}
          noPhoto={!hasPhoto}
          rightEdge={this.state.printRightEdgetPercent}
          isRotated={this.state.productRotatedToFit}
          availableWidth={this.state.width}
          onChangeLocketPreview={this.handleSetLocketPreview}
          onChangeLocketShape={this.handleSetLocketShape}
          isMillimeterOnly={queryString.parse(window.location.search).locket}
          isLocketPrint={queryString.parse(window.location.search).locket}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  user: authSelectors.getUser(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  addressBookEntries: addressSelectors.getAllEntries(state),
  existingPhotoPrintItems: basketSelectors
    .getItems(state)
    .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT),
});

const mapDispatchToProps = dispatch => ({
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  onChangeCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  updateItemPostageScheme: (itemId, postageSchemeId) =>
    dispatch(basketActions.updateItemPostageScheme(itemId, postageSchemeId)),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setGuestCheckoutDetails: details => dispatch(authActions.setGuestCheckoutDetails(details)),
  getAddressBookItem: (addressBookEntryId) => dispatch(addressBookActions.getEntry(addressBookEntryId)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), {fromPreBag : true})),
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AnySizePrintPrebagContainer);
