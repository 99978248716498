import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
//import sortBy from "lodash/sortBy";

import { preloadImage } from "../../lib/image-preloader";
import loadCrossOriginImage from "../../lib/load-cross-origin-image";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import HtmlRendererRect from "./HtmlRendererRect";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import Icon from "../Icon/Icon";
import { message } from "antd";
import { console } from "es6-shim";
import ImageDragDropPreview from "../ImageDragDropPreview/ImageDragDropPreview";
import getTranslatedPixelMeasurement from "../../lib/get-translated-pixel-measurement";

const CANVAS_PIXEL_OVERDRAW = 1;
const AMOUNT_OF_REPEATING_PATTERNS_IN_FULL_WIDTH = 10;

class HtmlRendererPhotoLayer extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    realBorders: PropTypes.bool,
    specificDimensions: PropTypes.object,
    showLocketOverlays: PropTypes.bool,
    canDragAndDropAcrossRegions: PropTypes.bool,
    showBordeCanvas: PropTypes.bool,
    topCrop: PropTypes.bool,
    disableTouchHandling: PropTypes.bool,
  };

  static defaultProps = {
    realBorders: false,
    specificDimensions: null,
    showLocketOverlays: false,
    canDragAndDropAcrossRegions: false,
    showBorderCanvas: true,
    topCrop: false,
    disableTouchHandling: false,
  };

  canvas = null;

  state = {
    preloadedImageUrls: [],
    imageStylesByRegionIndex: {},
    dragSourceRegionIndex: null,
    dragDestinationRegionIndex: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const hasSameConfig = isEqual(nextProps.config, this.props.config);
    const hasSameItemOptions = isEqual(
      nextProps.item.product_options,
      this.props.item.product_options
    );
    const hasSameCanvasDimensions = isEqual(
      nextProps.canvasDimensions,
      this.props.canvasDimensions
    );
    const hasSameState = isEqual(this.state, nextState);
    //console.log("Should update", !hasSameConfig || !hasSameCanvasDimensions || !hasSameState || !hasSameItemOptions);
    //console.log("!hasSameConfig", !hasSameConfig);
    //console.log("!hasSameCanvasDimensions", !hasSameCanvasDimensions);
    //console.log("!hasSameState", !hasSameState);
    //console.log("!hasSameItemOptions", !hasSameItemOptions);
    const willUpdate = !!(
      !hasSameConfig ||
      !hasSameCanvasDimensions ||
      !hasSameState ||
      !hasSameItemOptions ||
      // !hasSameCropData ||
      // isReRenderSame ||
      this.props.previewMode
    );
    return willUpdate;
  }

  componentDidUpdate = async prevProps => {
    // if (
    //   ![PRODUCT_TYPE_IDS.PHOTO_MAGAZINE].includes(
    //     this.props.item.productTypeId
    //   )
    // ) {
    if (!this.canvas && this.props.canvasDimensions.width) {
      this.setUpCanvas();
    }
    // if (this.props.reRender !== prevProps.reRender) {
    //   this.forceUpdate();
    // }

    if (this.props.canvasDimensions.width !== prevProps.canvasDimensions.width) {
      this.setUpCanvas();
    }

    if (!isEqual(prevProps.config.border.style, this.props.config.border.style)) {
      if (this.props.realBorders) {
        await this.styleBorder(this.props.config);
        this.canvas.renderAll();
      } else {
        console.log("Not applying borders as they are fake");
      }
    }
    if (this.canvas) {
      await this.clipBorder(this.props.config.border.thickness);
      this.canvas.renderAll();
    }

    //}

    if (!this.props.screenshotMode) {
      if (!this.props.lowResImages) {
        this.preloadAllImagesForLayout(this.props.config.layout);
      }
    }
    //console.log("setImagesStylesForRegions...");
    this.setImagesStylesForRegions(this.props.config.layout);
  };
  // reRender = () => {
  //   // calling the forceUpdate() method
  //   this.forceUpdate();
  // };
  preloadHighResImg = async imgUrl => {
    try {
      await preloadImage(imgUrl);
      this.setState(prevState => {
        return {
          preloadedImageUrls: [...prevState.preloadedImageUrls, imgUrl],
        };
      });
    } catch (err) {
      console.log("Error while preloading image:", err);
    }
  };

  preloadAllImagesForLayout = debounce(
    layout => {
      layout
        .map(region => region.image && region.image.src.highResUrl)
        .filter(url => url && !this.state.preloadedImageUrls.includes(url))
        .forEach(this.preloadHighResImg);
    },
    200,
    { leading: true }
  );

  getDimensions = () => {
    return this.rect.getDimensions();
  };

  setImagesStylesForRegions = layout => {
    const updatedState = {};
    layout.forEach((region, regionIndex) => {
      if (region.image) {
        const imageStyles = {
          ...(this.props.config?.layout?.[0]?.image?.filter?.bnw
            ? { filter: "grayscale(100%)" }
            : {}),
        };
        const cropData = { ...region.image.cropData };
        const hasPreloadedHighRes = this.state.preloadedImageUrls.includes(
          region.image.src.highResUrl
        );
        if (!region.image.cropData) {
          let imageUrl =
            hasPreloadedHighRes || this.props.screenshotMode
              ? region.image.src.highResUrl
              : region.image.src.lowResUrl;
          if (
            [PRODUCT_TYPE_IDS.PHOTO_MAGAZINE, PRODUCT_TYPE_IDS.POSTCARD].includes(
              this.props.item.productTypeId
            ) &&
            !this.props.screenshotMode
          ) {
            let scaledWidth = Math.floor(window.devicePixelRatio * 500);
            scaledWidth = Math.min(scaledWidth, 3000);
            imageUrl = imageUrl + `/-/resize/${scaledWidth}x/`;
          }
          // console.log("imageUrl", imageUrl)
          imageStyles.backgroundImage = `url(${imageUrl})`;
        } else if (this.rect) {
          //const hasPreloadedHighRes = this.state.preloadedImageUrls.includes(region.image.src.highResUrl);

          const rectDimensions = this.rect.getDimensions();
          let regionDimensions = {
            width: rectDimensions.width * region.width,
            height: rectDimensions.height * region.height,
          };

          if (this.props.specificDimensions) {
            regionDimensions = {
              width: (rectDimensions.width * this.props.specificDimensions.width) / 100,
              height: (rectDimensions.height * this.props.specificDimensions.height) / 100,
            };
            // styles = {
            //   width: `${this.props.specificDimensions.width}%`, // width based on incoming dimensions
            //   height: `${this.props.specificDimensions.height}%`,
            //   top: '50%',
            //   left: '50%',
            //   transform: 'translate(-50%, -50%)'
            // };
          }

          if (!cropData) {
            return;
          }

          // Multiply the translate values by the ratio of the region width & preview width
          const regionVsPreviewRatio = regionDimensions.width / cropData.transform.containerWidth;
          const regionVsPreviewRatioHeight =
            regionDimensions.height / cropData.transform.containerHeight;

          //console.log("Ratio Width", regionVsPreviewRatio);
          //console.log("Ratio Height", regionVsPreviewRatioHeight);

          const multipliedTransform = {
            width: cropData.transform.width * regionVsPreviewRatio,
            height: cropData.transform.height * regionVsPreviewRatioHeight,
            translateX: cropData.transform.translateX * regionVsPreviewRatio,
            translateY: cropData.transform.translateY * regionVsPreviewRatioHeight,
          };

          //// TODO: multiply the translate values by the ratio?
          //if (hasPreloadedHighRes || this.props.screenshotMode) {
          //  //const [lowResDimensions, highResDimensions] = await Promise.all([
          //  //  getImageDimensions(region.image.src.lowResUrl),
          //  //  getImageDimensions(region.image.src.highResUrl),
          //  //]);
          //  //
          //  //const ratio = lowResDimensions.width / highResDimensions.width;
          //}
          //
          imageStyles.width = multipliedTransform.width;
          imageStyles.height = multipliedTransform.height;
          imageStyles.transform = `
            translateX(${multipliedTransform.translateX.toFixed(2)}px)
            translateY(${multipliedTransform.translateY.toFixed(2)}px)
            rotate(${cropData.rotate}deg)
          `;
          imageStyles.WebkitTransform = imageStyles.transform;
        }
        updatedState[regionIndex] = imageStyles;
      }
    });

    if (!isEqual(this.state.imageStylesByRegionIndex, updatedState)) {
      this.setState({
        imageStylesByRegionIndex: updatedState,
      });
    }
  };

  setUpCanvas = async () => {
    fabric.Object.prototype.objectCaching = false;

    const width =
      Math.ceil(this.props.canvasDimensions.width * this.props.config.rect.width) +
      CANVAS_PIXEL_OVERDRAW;
    const height =
      Math.ceil(this.props.canvasDimensions.height * this.props.config.rect.height) +
      CANVAS_PIXEL_OVERDRAW;
    this.canvas = new fabric.Canvas(this.canvasContainer, {
      preserveObjectStacking: false,
      objectCaching: false,
      enableRetinaScaling: false, //this.props.lowResScaling ? false : true,
      width,
      height,
      top: -CANVAS_PIXEL_OVERDRAW,
      left: -CANVAS_PIXEL_OVERDRAW,
    });

    const borderDimensions = {
      width,
      height,
    };

    // Fixes retin image degredation issue thanks to: https://github.com/fabricjs/fabric.js/issues/5967
    fabric.Pattern.prototype.toLive = function(ctx) {
      var source = typeof this.source === "function" ? this.source() : this.source;

      // if the image failed to load, return, and allow rest to continue loading
      if (!source) {
        return "";
      }

      // if an image
      if (typeof source.src !== "undefined") {
        if (!source.complete) {
          return "";
        }
        if (source.naturalWidth === 0 || source.naturalHeight === 0) {
          return "";
        }
      }

      var canvasPattern = ctx.createPattern(source, this.repeat);

      //Check is setTransform exists - not supported in IE
      if (!canvasPattern.setTransform) {
        return canvasPattern;
      }
      // if (!this._svgMatrix){
      //   const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      //   if (svg && svg.createSVGMatrix){
      //     this._svgMatrix = svg.createSVGMatrix();
      //   }
      //   if (!this._svgMatrix || !canvasPattern.setTransform){
      //     this._svgMatrix = false;
      //     return canvasPattern;
      //   }
      // }

      //var canvasPattern = ctx.createPattern(source, this.repeat);
      var matrix = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix();
      canvasPattern.setTransform(matrix.scale(1 / fabric.devicePixelRatio));

      return canvasPattern;
    };

    this.border = new fabric.Rect({
      ...borderDimensions,
      selectable: false,
      evented: false,
      fill: "transparent",
    });

    //console.log("Adding border")
    this.canvas.add(this.border);
    //console.log(this.props.config.border);
    //console.log("Real borders", this.props.realBorders);
    if (this.props.config.border.style && this.props.realBorders) {
      //await this.canvas.add(this.border);
      this.styleBorder(this.props.config);
      //await this.canvas.add(this.border);
      //await this.canvas.add(this.border);
      this.clipBorder(this.props.config.border.thickness);
      this.canvas.renderAll();
    }
  };

  styleBorder = async config => {
    if (!config.border.style) {
      //console.log("No border style, opacity 0");
      this.border.set({ opacity: 0 });
      return;
    } else {
      if (this.props.lowResScaling) {
        console.log("Disabling scaling and setting DPI to 1");
        this.canvas.objectCaching = false;
        this.canvas.enableRetinaScaling = false;
        fabric.devicePixelRatio = 1;
      }
      //console.log("Border style, opacity 1");
      this.border.set({ opacity: 1 });
    }

    switch (config.border.style.type) {
      case "color":
        this.border.set({ fill: config.border.style.color });
        break;
      case "image": {
        const borderImg = await loadCrossOriginImage(`${config.border.style.src}?${+Date.now()}`);
        const img = new fabric.Image(borderImg);
        const devicePixelratio = window.devicePixelRatio || 1;
        const imgWidth =
          Math.round(
            this.props.canvasDimensions.width /
              AMOUNT_OF_REPEATING_PATTERNS_IN_FULL_WIDTH /
              devicePixelratio
          ) * devicePixelratio;
        //console.log(window.devicePixelRatio, imgWidth);

        fabric.Image.prototype.strokeWidth = 0;

        img.scaleToWidth(imgWidth);
        const patternSourceCanvas = new fabric.StaticCanvas(null, {
          enableRetinaScaling: true,
          imageSmoothingEnabled: false,
          objectCaching: false,
        });

        //patternSourceCanvas.enableRetinaScaling = true;
        //patternSourceCanvas.imageSmoothingEnabled = true;
        //patternSourceCanvas.objectCaching = false;

        patternSourceCanvas.add(img);
        patternSourceCanvas.renderAll();

        this.props.debug && console.log("Creating border pattern and setting as fill for border");
        const pattern = new fabric.Pattern({
          source: function() {
            patternSourceCanvas.setDimensions({
              width: imgWidth,
              height: imgWidth,
            });

            return patternSourceCanvas.renderAll().getElement();
          },
          repeat: "repeat",
        });

        this.border.set({ fill: pattern, objectCaching: false, strokeWidth: 0 });
        this.canvas.renderAll();
        break;
      }
      // no default
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showCropAlert = () => {
    const messageContent = (
      <div className="crop-alert" style={{ fontSize: "11px" }}>
        Trying to move or zoom your photo?
        <br />
        Use the Crop & Rotate tool below {<Icon name="crop-rotate" size="small" />}
      </div>
    );
    message.info(messageContent, 3);
  };

  showDragPreview = ({ imageUrl, x, y }) => {
    console.log("Showing drag preview:", { imageUrl, x, y });
    this.imageDndPreview.updateImage(imageUrl);
    this.imageDndPreview.updateCoordinates({ x, y });
    this.imageDndPreview.show();
  };

  handleDragStart = (e, regionIndex) => {
    const sourceRegion = this.props.config.layout[regionIndex];
    if (sourceRegion.image) {
      e.persist();
      this.setState({
        dragSourceRegionIndex: regionIndex,
      });
      this.showDragPreview({
        imageUrl: sourceRegion.image.src.lowResUrl,
        x: e.pageX,
        y: e.pageY,
      });
    }
    //if (image) {
    //  this.setState({
    //    indexOfRegionBeingDragged:
    //  });
    //}
  };

  handleMouseMove = e => {
    if (this.state.dragSourceRegionIndex !== null) {
      this.imageDndPreview.updateCoordinates({ x: e.pageX, y: e.pageY });
    }
  };

  handleDragEnter = (e, regionIndex) => {
    if (this.state.dragSourceRegionIndex !== null) {
      this.setState({
        dragDestinationRegionIndex: regionIndex,
      });
    }
  };

  handleDragLeave = (e, regionIndex) => {
    this.setState({
      dragDestinationRegionIndex: null,
    });
  };

  handleDragEnd = e => {
    if (
      this.state.dragSourceRegionIndex !== null &&
      this.state.dragDestinationRegionIndex !== null
    ) {
      console.log(
        `Drag ended, moving from ${this.state.dragSourceRegionIndex} to ${this.state.dragDestinationRegionIndex}`
      );
      // Only swap if regions changed
      if (this.state.dragSourceRegionIndex !== this.state.dragDestinationRegionIndex) {
        this.props.onSwapRegionImages(
          this.state.dragSourceRegionIndex,
          this.state.dragDestinationRegionIndex
        );
      }
    }

    this.imageDndPreview.hide();
    this.setState({
      dragSourceRegionIndex: null,
      dragDestinationRegionIndex: null,
    });
  };

  handleTouchStart = (e, regionIndex) => {
    console.log("handleTouchStart");
    const sourceRegion = this.props.config.layout[regionIndex];
    if (sourceRegion.image) {
      console.log("Dragging image:", sourceRegion.image);
      this.setState({
        dragSourceRegionIndex: regionIndex,
      });

      const { clientX, clientY } = e.changedTouches[0];
      this.showDragPreview({ imageUrl: sourceRegion.image.src.lowResUrl, x: clientX, y: clientY });
    }
  };

  handleTouchMove = e => {
    if (this.state.dragSourceRegionIndex !== null) {
      const { clientX, clientY } = e.changedTouches[0];
      const dragDestinationRegionIndex = Array.prototype.slice
        .call(this.regionsContainer.children)
        .findIndex(childElement => {
          const rect = childElement.getBoundingClientRect();
          //console.log(rect, clientX, clientY);
          return (
            clientX >= rect.x &&
            clientX <= rect.x + rect.width &&
            clientY >= rect.y &&
            clientY <= rect.y + rect.height
          );
        });

      this.setState({
        dragDestinationRegionIndex:
          dragDestinationRegionIndex === -1 ? null : dragDestinationRegionIndex,
      });

      this.imageDndPreview.updateCoordinates({ x: clientX, y: clientY });
    }
  };

  handleTouchEnd = e => {
    e.persist();
    console.log("handleTouchEnd", e);

    if ([this.state.dragSourceRegionIndex, this.state.dragDestinationRegionIndex].includes(null)) {
      return;
    }

    this.props.onSwapRegionImages(
      this.state.dragSourceRegionIndex,
      this.state.dragDestinationRegionIndex
    );
    this.setState({
      dragSourceRegionIndex: null,
      dragDestinationRegionIndex: null,
    });

    this.imageDndPreview.hide();
  };

  clipBorder = async thickness => {
    if (this.canvas) {
      const layerDimensions = {
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
      };
      const borderThickness = Math.round(layerDimensions.width * thickness);
      this.border.set({
        clipPath: new fabric.Group([
          //top
          new fabric.Rect({
            left: -layerDimensions.width / 2,
            top: -layerDimensions.height / 2 - CANVAS_PIXEL_OVERDRAW,
            width: layerDimensions.width,
            height: borderThickness,
          }),
          //bottom
          new fabric.Rect({
            left: -layerDimensions.width / 2,
            top: layerDimensions.height / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
            width: layerDimensions.width,
            height: borderThickness,
          }),
          //left
          new fabric.Rect({
            left: -layerDimensions.width / 2 - CANVAS_PIXEL_OVERDRAW,
            top: -layerDimensions.height / 2,
            width: borderThickness,
            height: layerDimensions.height,
          }),
          //right
          new fabric.Rect({
            left: layerDimensions.width / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
            top: -layerDimensions.height / 2,
            width: borderThickness,
            height: layerDimensions.height,
          }),
        ]),
        // clipTo: ctx => {
        //   ctx.beginPath();
        //   // Top side
        //   ctx.rect(
        //     -layerDimensions.width / 2,
        //     -layerDimensions.height / 2 - CANVAS_PIXEL_OVERDRAW,
        //     layerDimensions.width,
        //     borderThickness
        //   );
        //   // Bottom side
        //   ctx.rect(
        //     -layerDimensions.width / 2,
        //     layerDimensions.height / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
        //     layerDimensions.width,
        //     borderThickness
        //   );
        //   // Left side
        //   ctx.rect(
        //     -layerDimensions.width / 2 - CANVAS_PIXEL_OVERDRAW,
        //     -layerDimensions.height / 2,
        //     borderThickness,
        //     layerDimensions.height
        //   );
        //   // Right side
        //   ctx.rect(
        //     layerDimensions.width / 2 - borderThickness + CANVAS_PIXEL_OVERDRAW,
        //     -layerDimensions.height / 2,
        //     borderThickness,
        //     layerDimensions.height
        //   );
        //   ctx.closePath();
        // },
      });
    }
  };

  render() {
    const collagePrintBorderThickness = getTranslatedPixelMeasurement({
      productDimensions: this.props.productDimensions,
      canvasDimensions: this.props.canvasDimensions,
      amount: 3,
    });

    // merged in ..
    //const borderThicknessAsPercent =
    //   (100 - collagePrintBorderThickness * 2 * (100 / this.props.canvasDimensions.width) * 2) / 100;
    // let regions = this.props.config.layout.map((region, regionIndex) => {
    //   // const backgroundColor = !this.props.isFullWidth
    //   //   ? this.props.config?.border?.style?.color
    //   //   : "#fff";
    //   // const backgroundColor = "transparent";
    //   const backgroundColor = this.props.config?.border?.style?.color || "transparent";
    //   const backgroundImage = this.props.config?.border?.style?.texture;
    //   let styles;
    //   if (!backgroundImage) {
    //     styles = {
    //       left: `${region.xOffset * 100}%`,
    //       top: `${region.yOffset * 100}%`,
    //       width: `${region.width * 100}%`,
    //       height: `${region.height * 100}%`,
    //       border: "none",
    //       backgroundColor: backgroundColor,
    //     };
    //   } else {
    //     styles = {
    //       left: `${region.xOffset * 100}%`,
    //       top: `${region.yOffset * 100}%`,
    //       width: `${region.width * 100}%`,
    //       height: `${region.height * 100}%`,
    //       border: "none",
    //       backgroundColor: backgroundColor,
    //       backgroundImage: `url(${backgroundImage})`,
    //     };
    //   }
    //   if (this.props.item.isCollagePrint) {
    //     // styles = {
    //     //   ...styles,
    //     //   border: 'none',
    //     //   borderLeft: region.xOffset === 0 ? 'none' : `${collagePrintBorderThickness}px solid #fff`,
    //     //   borderTop: region.yOffset === 0 ? 'none' : `${collagePrintBorderThickness}px solid #fff`,
    //     //   borderBottom: (Math.round((region.yOffset + region.height) * 100) + Number.EPSILON / 100) >= 100 ? 'none' : `${collagePrintBorderThickness}px solid #fff`,
    //     //   borderRight: (Math.round((region.xOffset + region.width) * 100) + Number.EPSILON / 100) >= 100 ? 'none' : `${collagePrintBorderThickness}px solid #fff`,
    //     // }

    const borderThicknessAsPercent = {
      width:
        (100 -
          collagePrintBorderThickness.width * 2 * (100 / this.props.canvasDimensions.width) * 2) /
        100,
      height:
        (100 -
          collagePrintBorderThickness.height * 2 * (100 / this.props.canvasDimensions.height) * 2) /
        100,
    };

    const regionBorderWidthAsPixel =
      (this.props.canvasDimensions.width / 100) * this.props.config.regionBorderWidth;

    let regions = this.props.config.layout.map((region, regionIndex) => {
      let styles = {
        left: `${region.xOffset * 100}%`,
        top: `${region.yOffset * 100}%`,
        width: `${region.width * 100}%`,
        height: `${region.height * 100}%`,
        border: "none",
      };
      if (this.props.item.isCollagePrint) {
        const regionBorderRadiusHorizontal = this.props.config.regionBorderRadius;
        const regionBorderRadiusVertical =
          this.props.config.regionBorderRadius * (region.width / region.height);
        styles = {
          ...styles,
          borderRadius: `${regionBorderRadiusHorizontal}%/${regionBorderRadiusVertical}%`,
          border: `${regionBorderWidthAsPixel}px solid white`,
        };
        // console.log("regionIndex", regionIndex)
        // console.log("region", region)
        // console.log("bottom", (Math.round((region.yOffset + region.height) * 100) + Number.EPSILON / 100))
        // console.log("right", (Math.round((region.xOffset + region.width) * 100) + Number.EPSILON / 100))
      }

      if (this.props.specificDimensions) {
        if (this.props.tiled) {
          // styles = {
          //   width: `${this.props.specificDimensions.width}%`, // width based on incoming dimensions
          //   height: `${this.props.specificDimensions.height}%`,
          //   top: regionIndex === 0 ? '50%' : '0%',
          //   left: regionIndex === 0 ? '50%' : '0%',
          //   transform: 'translate(-50%, -50%)'
          // };
        } else {
          styles = {
            width: `${this.props.specificDimensions.width}%`, // width based on incoming dimensions
            height: `${this.props.specificDimensions.height}%`,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        }
      }

      const classes = classNames("html-renderer-photo-layer__region", {
        "html-renderer-photo-layer__region--has-image": region.image,
      });

      let imageStyles = {
        ...(this.props.config?.layout?.[0]?.image?.filter?.bnw
          ? { filter: "grayscale(100%)" }
          : {}),
      };
      let imageUrl;
      let hasPreloadedHighRes = false;
      if (region.image) {
        hasPreloadedHighRes = this.state.preloadedImageUrls.includes(region.image.src?.highResUrl);
        imageUrl =
          hasPreloadedHighRes || this.props.screenshotMode
            ? region.image.src?.highResUrl
            : region.image.src?.lowResUrl;
        if (this.props.lowResImages) {
          imageUrl = imageUrl.replace("/resize/1200x/", "/resize/1000x/");
        }
        if (!region.image.cropData) {
          if (
            [PRODUCT_TYPE_IDS.PHOTO_MAGAZINE, PRODUCT_TYPE_IDS.POSTCARD].includes(
              this.props.item.productTypeId
            ) &&
            !this.props.screenshotMode
          ) {
            let scaledWidth = Math.floor(window.devicePixelRatio * 500);
            scaledWidth = Math.min(scaledWidth, 3000);
            imageUrl = imageUrl + `/-/resize/${scaledWidth}x/`;
          }
          imageStyles.backgroundImage = `url(${imageUrl})`;
        } else if (this.state.imageStylesByRegionIndex[regionIndex]) {
          //console.log(`Image height for ${regionIndex} ${this.state.imageStylesByRegionIndex[regionIndex].height}`);
          imageStyles = this.state.imageStylesByRegionIndex[regionIndex];
        } else if (this.props.isTemplate && this.props.imageData.cropData) {
          const cropData = this.props.imageData.cropData;
          let regionDimensions = {
            width: this.props.dimensions.width * region.width,
            height: this.props.dimensions.height * region.height,
          };
          // Multiply the translate values by the ratio of the region width & preview width
          const regionVsPreviewRatio = regionDimensions.width / cropData.transform.containerWidth;
          const regionVsPreviewRatioHeight =
            regionDimensions.height / cropData.transform.containerHeight;

          //console.log("Ratio Width", regionVsPreviewRatio);
          //console.log("Ratio Height", regionVsPreviewRatioHeight);

          const multipliedTransform = {
            width: cropData.transform.width * regionVsPreviewRatio,
            height: cropData.transform.height * regionVsPreviewRatioHeight,
            translateX: cropData.transform.translateX * regionVsPreviewRatio,
            translateY: cropData.transform.translateY * regionVsPreviewRatioHeight,
          };

          //// TODO: multiply the translate values by the ratio?
          //if (hasPreloadedHighRes || this.props.screenshotMode) {
          //  //const [lowResDimensions, highResDimensions] = await Promise.all([
          //  //  getImageDimensions(region.image.src.lowResUrl),
          //  //  getImageDimensions(region.image.src.highResUrl),
          //  //]);
          //  //
          //  //const ratio = lowResDimensions.width / highResDimensions.width;
          //}
          //
          imageStyles.width = multipliedTransform.width;
          imageStyles.height = multipliedTransform.height;
          imageStyles.transform = `
            translateX(${multipliedTransform.translateX.toFixed(5)}px)
            translateY(${multipliedTransform.translateY.toFixed(5)}px)
            rotate(${cropData.rotate}deg)
          `;
          imageStyles.WebkitTransform = imageStyles.transform;
        }
      }

      const extraRegionProps = {};

      if (this.props.canDragAndDropAcrossRegions) {
        extraRegionProps.onMouseDown = e => this.handleDragStart(e, regionIndex);
        extraRegionProps.onMouseOver = e => this.handleDragEnter(e, regionIndex);
        extraRegionProps.onMouseOut = e => this.handleDragLeave(e, regionIndex);
        extraRegionProps.onTouchStart = e => this.handleTouchStart(e, regionIndex);
        extraRegionProps.onTouchMove = e => this.handleTouchMove(e, regionIndex);
        extraRegionProps.onTouchEnd = e => this.handleTouchEnd(e, regionIndex);
        extraRegionProps.onMouseUp = this.handleDragEnd;
      }

      // onDragEnd={() => this.handleDrag(region.image)}
      // onTouchMove={() => this.handleDrag(region.image)}

      const regionClasses = classNames("html-renderer-photo-layer__region__dnd-hint", {
        "html-renderer-photo-layer__region__dnd-hint--visible":
          regionIndex === this.state.dragDestinationRegionIndex &&
          regionIndex !== this.state.dragSourceRegionIndex,
      });

      return (
        <div
          className={classes}
          style={styles}
          key={regionIndex}
          onClick={() => this.props.onClickRegion(regionIndex)}
          data-gtm-element="Photo Upload"
          {...extraRegionProps}
        >
          <div className={regionClasses}>
            <div className="html-renderer-photo-layer__region__dnd-hint__inner">
              {region.image ? "Drop to swap photos" : "Drop to move photo"}
            </div>
          </div>
          {region.image && !region.image.cropData && !this.props.topCrop && (
            <div
              className="html-renderer-photo-layer__region__uncropped-image"
              style={imageStyles}
            />
          )}
          {region.image && !region.image.cropData && this.props.topCrop && (
            <div
              className="html-renderer-photo-layer__region__uncropped-image-top-crop"
              style={imageStyles}
            />
          )}
          {region.image && region.image.cropData && (
            <img
              className="html-renderer-photo-layer__region__cropped-image"
              src={imageUrl}
              style={imageStyles}
              alt=""
            />
          )}
          {!region.image && !region.blank && (
            <img
              className="html-renderer-photo-layer__region__placeholder-icon animated fadeIn"
              src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
              alt=""
            />
          )}
          {region.blank && (
            <div className="html-renderer-photo-layer__region__blank" style={imageStyles} />
          )}
        </div>
      );
    });

    if (
      this.props.item.locketPreview &&
      !this.props.screenshotMode &&
      this.props.showLocketOverlays
    ) {
      const shape = `${this.props.item.locketShape}-locket-mask.svg`;

      let maskStyle = {};

      if (this.props.specificDimensions.width <= this.props.specificDimensions.height) {
        const verticalMaskRemaining =
          this.props.specificDimensions.height - this.props.specificDimensions.width;
        const verticalMaskToApply = Math.ceil(
          (verticalMaskRemaining / this.props.specificDimensions.height / 2) * 100
        );
        //console.log("height remaing", verticalMaskRemaining/this.props.specificDimensions.height)
        maskStyle = {
          backgroundColor: "transparent",
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/masks/${shape}), linear-gradient(#686868a6, #686868a6),linear-gradient(#686868a6, #686868a6)`,
          backgroundSize: `contain, 100% ${verticalMaskToApply}%, 100% ${verticalMaskToApply}%`,
          backgroundPosition: "center, top, bottom",
          backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        };
      }
      if (this.props.specificDimensions.width > this.props.specificDimensions.height) {
        const horizontalMaskRemaining =
          this.props.specificDimensions.width - this.props.specificDimensions.height;
        const horizontalMaskToApply = Math.ceil(
          (horizontalMaskRemaining / this.props.specificDimensions.width / 2) * 100
        );
        //console.log("width remaing", horizontalMaskRemaining/this.props.specificDimensions.width)
        //const horizontalMaskToApply = ((horizontalMaskRemaining/this.props.specificDimensions.width)/2)*100
        maskStyle = {
          backgroundColor: "transparent",
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/masks/${shape}), linear-gradient(#686868a6, #686868a6),linear-gradient(#686868a6, #686868a6)`,
          backgroundSize: `contain, ${horizontalMaskToApply}% 100%, ${horizontalMaskToApply}% 100%`,
          backgroundPosition: "center, left, right",
          backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        };
      }

      let lastRegion = Object.assign({}, regions[0]);
      let maskRegion = (
        <div
          className={lastRegion.props.className}
          style={{ ...lastRegion.props.style, ...maskStyle }}
          key={"last-region"}
        />
      );

      regions.push(maskRegion);
    }

    const isCollagePrint = this.props.item.isCollagePrint;
    let rectStyle =
      isCollagePrint && !this.props.screenshotMode
        ? {
            transform: `scaleX(${borderThicknessAsPercent.width}) scaleY(${borderThicknessAsPercent.height})`,
            background: "#fff",
          }
        : { background: "#fff" };

    if ([PRODUCT_TYPE_IDS.PHOTO_MAGAZINE].includes(this.props.item.productTypeId)) {
      rectStyle = { background: "transparent" };
    }
    if (this.props.item.isBorderPrint) {
      rectStyle = { background: "transparent" };
    }

    return (
      <HtmlRendererRect
        className="html-renderer-photo-layer"
        style={rectStyle}
        rect={this.props.config.rect}
        ref={el => (this.rect = el)}
        onMouseMove={this.props.canDragAndDropAcrossRegions ? this.handleMouseMove : () => {}}
        {...(this.props.dragAndDropHandlers || {})}
        disableTouchHandling={this.props.disableTouchHandling}
      >
        {this.props.showBorderCanvas && (
          <canvas
            className="html-renderer-photo-layer__border"
            ref={el => (this.canvasContainer = el)}
          />
        )}
        <div ref={el => (this.regionsContainer = el)}>{regions}</div>
        <ImageDragDropPreview ref={el => (this.imageDndPreview = el)} />
        {this.props.effect && (
          <div
            className={`html-renderer-photo-layer-effect html-renderer-photo-layer-effect--${this.props.effect}`}
          />
        )}
        <SweetAlert
          isOpen={Boolean(this.state.alert)}
          {...(this.state.alert || {})}
          key="crop-alert"
        />
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererPhotoLayer;
