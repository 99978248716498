import React from "react";
import { connect } from "react-redux";

import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";

import OrderHistory from "./OrderHistory";
import convertLineItemToItemObject from "../../lib/convert-line-item-to-item-object";
import { actions as basketActions } from "../../store/ducks/basket";
import { push } from "react-router-redux";
import { routeCreators } from "../../lib/routes";

class OrderHistoryContainer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    props.fetchOrderHistory();
  }

  handleReorder = (order) => {
    //console.log(order.toJS());
    const itemsToAdd = order.get('line_items').map(async (lineItem) => {
      const convertedItem = await convertLineItemToItemObject(lineItem.toJS());
      return convertedItem.withMutations((item) => {
        item.set('isReorderedItem', true);
        item.set('isApproved', true);
      })
    });

    itemsToAdd.forEach((item) => {
      this.props.addBasketItem(item.toJS());
      //this.props.goToBasket();
    })
  };

  render() {
    return (
      <OrderHistory
        reference={this.props.match.params.reference}
        orderHistory={this.props.orderHistory}
        onReorder={this.handleReorder}
      />
    );
  }
}

const mapStateToProps = state => ({
  orderHistory: authSelectors.getOrderHistory(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrderHistory: () => dispatch(authActions.fetchOrderHistory()),
  addBasketItem: item => {
    let action = basketActions.addItem(item);
    return dispatch(action);
  },
  goToBasket: () => dispatch(push(routeCreators.basket())),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryContainer);
