import React from "react";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererBorder extends React.Component {
  static defaultProps = {};

  render() {
    let borderElements = [];
    //console.log(this.props.style);
    const productDimensions = this.props.productDimensions;
    //const thickness = this.props.hasBorder && this.props.style && this.props.style.thickness;
    let color = "#ffffff";
    let texture = "";
    let thickness = 0;
    if (this.props.hasBorder && this.props.style && this.props.style.style !== null) {
      color = this.props.style.style.color;
      texture = this.props.style.style.texture;
      thickness = this.props.style.thickness * 100;
    }

    const height = productDimensions.height;
    const width = productDimensions.width;

    const aspectRatio = width / height;

    //const thickness = 0.045;

    const relativeThickness = {
      top: thickness * aspectRatio,
      bottom: thickness * aspectRatio,
      right: thickness,
      left: thickness,
    };

    borderElements = [
      <div
        key="top"
        className="html-renderer-border__band html-renderer-mask__band--horizontal"
        style={{
          backgroundColor: color,
          top: 0,
          height: `${relativeThickness.top}%`,
          width: `calc(100% - 2px)`,
          backgroundImage: `url(${texture})`,
          backgroundSize: "cover",
          zIndex: 100,
        }}
      />,
      <div
        key="bottom"
        className="html-renderer-border__band html-renderer-mask__band--horizontal"
        style={{
          backgroundColor: color,
          bottom: 0,
          height: `${relativeThickness.bottom}%`,
          width: `calc(100% - 2px)`,
          backgroundImage: `url(${texture})`,
          backgroundSize: "cover",
          zIndex: 100,
        }}
      />,
      <div
        key="left"
        className="html-renderer-border__band html-renderer-mask__band--vertical"
        style={{
          backgroundColor: color,
          left: 0,
          width: `${relativeThickness.left}%`,
          backgroundImage: `url(${texture})`,
          backgroundSize: "cover",
          zIndex: 100,
        }}
      />,
      <div
        key="right"
        className="html-renderer-border__band html-renderer-mask__band--vertical"
        style={{
          backgroundColor: color,
          right: 0,
          width: `${relativeThickness.right}%`,
          backgroundImage: `url(${texture})`,
          backgroundSize: "cover",
          zIndex: 100,
        }}
      />,
    ];

    const rect = {
      x: 0,
      y: 1,
      width: 1,
      height: 1,
    };

    if (!this.props.hasBorder) {
      return null;
    }

    return (
      <HtmlRendererRect className="html-renderer-border" rect={rect}>
        {borderElements}
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererBorder;
