export const MIN_FONT_SIZE = 10;
export const MAX_FONT_SIZE = 60;
export const POSTCARD_DEFAULT_FONT_SIZE = 14;
export const GREETING_CARD_DEFAULT_FONT_SIZE = 16;

export const MIN_FONT_SIZE_IN_EM = 1;
export const MAX_FONT_SIZE_IN_EM = 5;
export const FONT_SIZE_STEP = 0.1;
/**
 * The next value for the canvas/font size ratio is a bit of a magic number, this was tweaked to make the font sizes in
 * the webapp match the ones in the iOS app.
 */
export const STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO = 0.025;

export const PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO = 0.038;

export const CAPTION_FONT_FAMILIES = [
  {
    name: "Open Sans",
    displayName: "Seatown",
  },
  {
    name: "Charm",
    displayName: "Poundbury",
  },
  {
    name: "EBGaramond",
    displayName: "Gillingham",
  },
  {
    name: "Coustard",
    displayName: "Middlemarsh",
  },
  {
    name: "Prata",
    displayName: "Kingston Lacey",
  },
  {
    name: "AnaheimGothic",
    displayName: "Tarrant",
  },
  {
    name: "Helsinki",
    displayName: "Bournemouth",
  },
  {
    name: "Caveat",
    displayName: "Overcombe",
  },
  {
    name: "SpecialElite",
    displayName: "Blue Pool",
  },
  {
    name: "Meridian",
    displayName: "Canford Cliffs",
  },
]

export const FONT_FAMILIES = [
  {
    name: "Open Sans",
    displayName: "Seatown",
    characterLimit: 33,
  },
  {
    name: "Londrina Solid",
    displayName: "Cranbourne",
    defaultFontSize: 45,
    characterLimit: 30,
  },
  {
    name: "Signika",
    displayName: "Charmouth",
    characterLimit: 28,
  },
  {
    name: "Nerko One",
    displayName: "Durley",
    defaultFontSize: 45,
    characterLimit: 27,
  },
  {
    name: "PermanentMarker",
    displayName: "Marshwood",
    characterLimit: 27,
  },
  {
    name: "Alegreya Sans",
    displayName: "Abbotsbury",
    defaultFontSize: 42,
    characterLimit: 30,
  },
  {
    name: "Coustard",
    displayName: "Middlemarsh",
    characterLimit: 24,
  },
  {
    name: "Helsinki",
    displayName: "Bournemouth",
    defaultFontSize: 37,
    characterLimit: 28,
  },
  {
    name: "AnaheimGothic",
    displayName: "Tarrant",
    characterLimit: 26,
  },
  {
    name: "SpecialElite",
    displayName: "Blue Pool",
    characterLimit: 26,
  },
  {
    name: "JosefinSans",
    displayName: "Hazelbury",
    characterLimit: 35,
  },
  {
    name: "Charm",
    displayName: "Poundbury",
    defaultFontSize: 45,
    characterLimit: 35,
  },
  {
    name: "EBGaramond",
    displayName: "Gillingham",
    defaultFontSize: 45,
    characterLimit: 38,
  },
  {
    name: "IndieFlower",
    displayName: "Tuckton",
    defaultFontSize: 45,
    characterLimit: 27,
  },
  {
    name: "Gaegu",
    displayName: "Piddlehinton",
    defaultFontSize: 45,
    characterLimit: 25,
  },
  {
    name: "JasonSharpie",
    displayName: "Tolpuddle",
    defaultFontSize: 35,
    characterLimit: 24,
  },
  {
    name: "Satisfy",
    displayName: "Swanage",
    defaultFontSize: 50,
    characterLimit: 25 ,
  },
  {
    name: "Caveat",
    displayName: "Overcombe",
    defaultFontSize: 55,
    characterLimit: 32,
  },
  {
    name: "Pacifico",
    displayName: "Brownsea Island",
    characterLimit: 35,
  },
  {
    name: "ReenieBeanie",
    displayName: "Bryanston",
    defaultFontSize: 68,
    characterLimit: 22,
  },
  {
    name: "LaBelleAurore",
    displayName: "Chesil Beach",
    defaultFontSize: 47,
    characterLimit: 23,
  },
  {
    name: "Wren",
    displayName: "Lyme Regis",
    defaultFontSize: 50,
    characterLimit: 25,
  },
  {
    name: "Meridian",
    displayName: "Canford Cliffs",
    characterLimit: 26,
  },
  {
    name: "Poppins",
    displayName: "Durdle Door",
    characterLimit: 24,
  },
  {
    name: "Prata",
    displayName: "Kingston Lacey",
    characterLimit: 25,
  },
  {
    name: "Amaranth",
    displayName: "Sandbanks",
    defaultFontSize: 45,
    characterLimit: 26,
  },
  {
    name: "Antonio",
    displayName: "Studland",
    defaultFontSize: 45,
    characterLimit: 29,
  },
  {
    name: "BokkaSolidOT",
    displayName: "Lulworth Cove",
    characterLimit: 24,
    defaultFontSize: 38,
  },
  {
    name: "JustAnotherHand",
    displayName: "Dorchester",
    defaultFontSize: 65,
    characterLimit: 32,
  },
  {
    name: "BokkaHalfShadowOT",
    displayName: "Shell Bay",
    characterLimit: 24,
    defaultFontSize: 38,
  },
];

export const DEFAULT_FONT = FONT_FAMILIES[0].name;

export const DEFAULT_FONT_COLOR = "rgb(0,0,0)";
