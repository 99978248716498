import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { reducer as auth } from "./auth";
import { reducer as editor } from "./editor";
import { reducer as basket } from "./basket";
import { reducer as ui } from "./ui";
import { reducer as addressBook } from "./address-book";
import { reducer as photoTilesEditor } from "./ui-photo-tiles";

import * as STORAGE_KEYS from "../../constants/storage-keys";

export const appReducer = combineReducers({
  auth,
  editor,
  basket,
  ui,
  addressBook,
  photoTilesEditor,
  router: routerReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_ALL') {
    console.log("Resetting app...")
    let keysToRemove = Object.values(STORAGE_KEYS);
    keysToRemove.forEach(k => {
      console.log("Removing", k)
      localStorage.removeItem(k)
    })
    state = undefined
    window.location.reload()
  }

  return appReducer(state, action)
}
