import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { productsByProductId } from "../../data/products";
//import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
//import PhotoTilePreview from "../../components/PhotoTilePreview/PhotoTilePreview";
import {
  getEmptyTiles,
  MAIN_VIEW_MODES,
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../store/ducks/ui-photo-tiles";
import PhotoTileEditor from "./components/PhotoTileEditor/PhotoTileEditor";
import PhotoTilesPrebag from "./components/PhotoTilesPrebag";
import PhotoTilesFilesModal from "./components/PhotoTilesFilesModal";
import PhotoTilesCropEditor from "./components/PhotoTilesCropEditor";
import { getFrameColorFromUrl, getItemDataForPack, getItemDataSinglePack } from "./photoTilesUtils";
import getMaxResolutionForDimensions from "../../lib/get-max-resolution-for-dimensions";
import * as STORAGE_KEYS from "../../constants/storage-keys";

// props
// productTypeId={+PRODUCT_TYPE_IDS.PHOTO_TILE}
// productId={
//   +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.PHOTO_TILE, productSlug).get(
//     "id"
//   )
// }
// productSlug={productSlug}
// productTypeSlug={productType}

function PhotoTileEditorContainer({
  productId,
  productSlug,
  product,
  tiles,
  setProduct,
  setTiles,
  mainViewMode,
  setShowFilesModal,
  tileBeingEditedIndex,
  setShowColorEditor,
  setShowBorderEditor,
  setTileBeingEditedIndex,
  setMainViewMode,
  filesAmount,
}) {
  const { photo_tile_pack_quantity } = product ?? {};
  const disableSaveWipBag = useRef(false);

  useEffect(() => {
    const product = Object.fromEntries(productsByProductId.get(productId));
    setProduct(product);
    const frameColor = getFrameColorFromUrl();

    // load the state from local storage if it exists
    const wipPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG);

    if (wipPrebag) {
      const wipPrebagData = JSON.parse(wipPrebag);

      if (wipPrebagData.productId === productId) {
        // initialize the state with the data from local storage
        const tiles = wipPrebagData.photoTilesState;
        setTileBeingEditedIndex(-1);
        setMainViewMode(MAIN_VIEW_MODES.PRE_BAG);
        setTiles(tiles);
        setShowFilesModal(false);
        return;
      }
    }

    // we initialise the state
    disableSaveWipBag.current = true;
    setTiles(getEmptyTiles(product?.photo_tile_pack_quantity ?? 1, frameColor));
    setTimeout(() => {
      disableSaveWipBag.current = false;
    }, 250);
    setShowFilesModal(true, product?.photo_tile_pack_quantity ?? 1);
    setTileBeingEditedIndex(0);
  }, [productId]);

  useEffect(() => {
    const productGot = Object.fromEntries(productsByProductId.get(productId));

    const savePrebag = async () => {
      const itemDataList = [];

      if (productGot.photo_tile_pack_quantity > 1) {
        const packItemData = await getItemDataForPack(tiles, productGot);
        itemDataList.push(packItemData);
      } else {
        for (const tile of tiles) {
          const itemData = await getItemDataSinglePack(tile, productGot);
          itemDataList.push(itemData);
        }
      }

      const prebag = {
        productTypeId: productGot.product_type_id,
        productId: productGot.id,
        productSlug: productGot.slug,
        productTypeSlug: "photo-tiles",
        data: {
          items: itemDataList,
        },
        photoTilesState: tiles,
      };

      localStorage.setItem(STORAGE_KEYS.WIP_PREBAG, JSON.stringify(prebag));
    };

    if (!disableSaveWipBag.current === true) {
      savePrebag();
    }
  }, [tiles]);

  const getDefaultCropData = image => {
    let heightRatio = image.originalImageInfo.height / image.originalImageInfo.width;

    if (heightRatio < 1) {
      //landscape need no crop
      return null;
    }
    const horizontalBleed = parseFloat(product.bleed_l) + parseFloat(product.bleed_r);
    const verticalBleed = parseFloat(product.bleed_t) + parseFloat(product.bleed_b);

    let dimensionsAsPx = getMaxResolutionForDimensions({
      width: parseFloat(product.width + horizontalBleed),
      height: parseFloat(product.height + verticalBleed),
      dpi: product.dpi,
    });

    const PREVIEW_CROPPER_NODE_WIDTH = 500;
    let transformHeight = PREVIEW_CROPPER_NODE_WIDTH * heightRatio;
    return {
      x: 0,
      y: 0,
      width: dimensionsAsPx.split("x")[0],
      height: dimensionsAsPx.split("x")[1],
      rotate: 0,
      scaleX: 1,
      scaleY: 1,
      transform: {
        containerWidth: PREVIEW_CROPPER_NODE_WIDTH,
        containerHeight: PREVIEW_CROPPER_NODE_WIDTH,
        width: PREVIEW_CROPPER_NODE_WIDTH,
        height: transformHeight,
        translateX: 0,
        translateY: 0,
        xPercent: 0,
        yPercent: 0,
      },
    };
  };

  const addImageToTile = image => {
    if (!Array.isArray(image)) {
      // this means that the file uploader was opened for a specific tile
      const editedTile = tiles[tileBeingEditedIndex];
      editedTile.image = image;
      editedTile.crop = getDefaultCropData(image);
      const updatedList = updateTile(tiles, tileBeingEditedIndex, { ...editedTile });
      setTiles(updatedList);
      setShowFilesModal(false);
      return;
    }

    // Fill tiles that missing images from the first X of the picked images
    // Leave tiles empty if there are not enough images
    let pickImageIndex = 0;
    let updatedList = [...tiles];
    updatedList.forEach((tile, index) => {
      if (!tile.image) {
        // if tile has no image then assign one
        const newTile = { ...tile };
        // assign an image only if there's left one
        if (image[pickImageIndex]) {
          newTile.image = image[pickImageIndex];
          newTile.crop = getDefaultCropData(image[pickImageIndex]);
          updatedList[index] = newTile;
          pickImageIndex++;
        }
      }
    });

    setTiles(updatedList);
    setShowFilesModal(false);
  };

  const imageBeingEdited = tiles[tileBeingEditedIndex]?.image?.lowResUrl;

  // const [cropperView, setCropperView] = useState(false);

  return (
    <div>
      {mainViewMode === MAIN_VIEW_MODES.EDITOR && <PhotoTileEditor />}

      {mainViewMode === MAIN_VIEW_MODES.PRE_BAG && <PhotoTilesPrebag />}

      <PhotoTilesFilesModal onImagePicked={addImageToTile} amount={filesAmount} />
      <PhotoTilesCropEditor />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currency: basketSelectors.getCurrency(state),
    tiles: photoTilesSelectors.getTiles(state),
    product: photoTilesSelectors.getProduct(state),
    tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
    mainViewMode: photoTilesSelectors.getMainViewMode(state),
    showFilesModal: photoTilesSelectors.getShowFilesModal(state),
    showCropEditor: photoTilesSelectors.getShowCropEditor(state),
    showColorEditor: photoTilesSelectors.getShowColorEditor(state),
    showBorderEditor: photoTilesSelectors.getShowBorderEditor(state),
    filesAmount: photoTilesSelectors.getFilesAmount(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setProduct: product => dispatch(photoTilesActions.setProduct(product)),
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
  setTileBeingEditedIndex: tileBeingEditedIndex =>
    dispatch(photoTilesActions.setTileBeingEditedIndex(tileBeingEditedIndex)),
  setShowFilesModal: (showFilesModal, amount = 1) =>
    dispatch(photoTilesActions.setShowFilesModal(showFilesModal, amount)),
  setShowCropEditor: showCropEditor =>
    dispatch(photoTilesActions.setShowCropEditor(showCropEditor)),
  setShowColorEditor: showColorEditor =>
    dispatch(photoTilesActions.setShowColorEditor(showColorEditor)),
  setShowBorderEditor: showBorderEditor =>
    dispatch(photoTilesActions.setShowBorderEditor(showBorderEditor)),
  setMainViewMode: mainViewMode => dispatch(photoTilesActions.setMainViewMode(mainViewMode)),
  // setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  // addBasketItem: item => dispatch(basketActions.addItem(item)),
  // addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  // renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  // goToBasket: () => dispatch(push(routeCreators.basket())),
  // getItem: itemId => dispatch(basketActions.getItem(itemId)),
  // showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  // approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  // deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});
// class PhotoTileEditorContainer extends Component {
//   state = {
//     itemForEditor: null,
//     isItemDuplicationAlertVisible: false,
//     toast: null,
//     editorItemLoaded: false,
//     editorItemLoading: true,
//     isApprovalModalVisible: false,
//     approvalModalItemId: null,
//     approvalModalImage: null,
//     renderItemLoading: false,
//     renderItemLoaded: false,
//     threeDModel: null,
//     approvalModalItem: null
//   };
//
//   componentWillMount() {
//     this.handleSelectProductType(PRODUCT_TYPE_IDS.PHOTO_TILE);
//   }
//
//   closeEditor = (closeBehavior = {}) => {
//     // TODO: extract this to ENV var
//     localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
//     window.history.back();
//   };
//
//   setUpEditorItem = async ({ product, productTypeId, designId, selectedVariationIndex }) => {
//     const designDetail = await postsnapApi.designs.getDetails(designId);
//
//     let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);
//
//     if (selectedVariationIndex !== undefined) {
//       layers = layers.map(layer => {
//         if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
//           return layer.setIn(
//             ["config", "s3_key"],
//             layer.getIn(["config", "s3_keys", selectedVariationIndex])
//           );
//         }
//
//         if (layer.get("type") === LAYER_TYPES.TEXT) {
//           return layer.setIn(
//             ["config", "color"],
//             layer.getIn(["config", "colors", selectedVariationIndex])
//           );
//         }
//
//         return layer;
//       });
//     }
//
//     let itemData = fromJS({
//       productDimensions: {
//         width: product.get("width"),
//         height: product.get("height"),
//         dpi: product.get("dpi"),
//         bleed: {
//           top: parseFloat(product.get("bleed_t")),
//           bottom: parseFloat(product.get("bleed_b")),
//           left: parseFloat(product.get("bleed_l")),
//           right: parseFloat(product.get("bleed_r")),
//         },
//       },
//       weight: product.get("weight"),
//       productTypeId,
//       productId: product.get("id"),
//       designId,
//       quantity: 1,
//       pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
//       layers,
//       designOptions: designDetail.data.data.preview_s3_keys || [],
//       postDate: moment(),
//       product_options: "",
//     });
//
//     if (itemData.getIn(["pages", "envelope"])) {
//       itemData = itemData.update("layers", layers =>
//         layers.concat(
//           fromJS([
//             {
//               config: {
//                 rect: {
//                   x: 0.1,
//                   y: 0.7,
//                   width: 0.5,
//                   height: 0.6,
//                 },
//                 font: "Anaheim Gothic",
//                 size: 10,
//                 color: "0,0,0",
//               },
//               id: "ENVELOPE_ADDRESS",
//               page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
//               zindex: null,
//               type: "Layer::Address",
//             },
//             {
//               config: {
//                 rect: {
//                   x: 0.695,
//                   y: 0.94,
//                   width: 0.26,
//                   height: 0.23,
//                 },
//               },
//               id: "ENVELOPE_STAMP",
//               page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
//               type: "Layer::Indicia",
//             },
//           ])
//         )
//       );
//     }
//     //console.log("Model is", MODELS[product.get("slug")]);
//     this.setState({
//       itemForEditor: itemData,
//       editorItemLoaded: true,
//       threeDModel: MODELS[product.get("slug")],
//     });
//   };
//
//   openApprovalModal = () => {
//     this.setState({
//       isApprovalModalVisible: true,
//     });
//   };
//
//   closeApprovalModal = itemId => {
//     this.setState({
//       isApprovalModalVisible: false,
//     });
//   };
//
//   closeApprovalModalAndDeleteItem = itemId => {
//     this.props.deleteItem(itemId);
//     this.closeApprovalModal();
//   };
//
//   handleApproveItem = item => {
//     this.props.approveItem(item);
//     this.closeApprovalModal();
//     this.props.goToBasket();
//     // this.setState({
//     //   isItemDuplicationAlertVisible: true,
//     // });
//   };
//
//   handleEditItem = itemId => {
//     this.props.deleteItem(itemId);
//     this.closeApprovalModal();
//     const editorInstance = this.editorContainer.getWrappedInstance();
//     editorInstance.goToFrontPage();
//   };
//
//   handleSelectProductType = async productTypeId => {
//     // this.setState({
//     //   editorItemLoading: true,
//     // });
//     // Get the first product ID for this product type ID
//     const firstProductForProductType = productsByProductTypeId.get(productTypeId).find(p => {
//       return p.get("url_slug") === this.props.productSlug;
//     });
//
//     /**
//      * Get the first design for that product (the first design is the _only_ design, except for greeting cards which
//      * are handled in a separate method)
//      */
//     const firstDesignForProductId = designsData.designsByProductId
//       .get(this.props.productId)
//       .first();
//     this.setUpEditorItem({
//       designId: firstDesignForProductId.get("id"),
//       productTypeId: productTypeId,
//       product: firstProductForProductType,
//     });
//   };
//
//   handleAddToBasket = async item => {
//     this.setState({
//       renderItemLoading: true,
//     });
//     const bagItem = await this.props.addBasketItemAsync(item.toJS());
//     this.props.renderItem(bagItem.payload.id).then(async res => {
//       const item = await this.props.getItem(bagItem.payload.id);
//       const s3Key = item.payload.itemData.preview_s3_key;
//       console.log(item);
//       this.setState({
//         approvalModalItemId: bagItem.payload.id,
//         approvalModalImage: s3Key,
//         renderItemLoading: false,
//         approvalModalItem: item.payload.itemData
//       });
//       setTimeout(() => {
//         this.openApprovalModal();
//       }, 300);
//     });
//   };
//
//   render() {
//
//     const productNameForItem = "Photo Tile"
//
//     const bleedMasks = MASKS[this.props.productSlug];
//
//     const approvalModal = (
//       <Modal
//         key="approval-modal"
//         isOpen={this.state.isApprovalModalVisible}
//         onClose={this.closeApprovalModal}
//         title="Tile Preview"
//         leftAction={
//           <Button
//             theme="muted"
//             priority="tertiary"
//             label="Cancel"
//             onClick={() => this.closeApprovalModalAndDeleteItem(this.state.approvalModalItemId)}
//           />
//         }
//         rightAction={
//           <Button
//             theme="default"
//             priority="tertiary"
//             label="Approve"
//             onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
//           />
//         }
//       >
//         <MainContent scrollable={false} centeredVertically padded key="main-preview">
//           {this.state.approvalModalItem && (
//             <div>
//               <HtmlRenderer
//                 item={this.state.approvalModalItem}
//                 page={0}
//                 previewMode={true}
//                 mask={bleedMasks.canvasPreview}
//               />
//               {/* <PhotoTilePreview
//                 previewImage={this.state.approvalModalImage}
//                 item={this.state.approvalModalItem}
//               /> */}
//             </div>
//           )}
//
//
//           {/* <ThreeDimensionalViewer
//             model={this.state.threeDModel}
//             image={this.state.approvalModalImage}
//           /> */}
//           <p className="help-text text-center" style={{ zIndex: 1, marginTop: '30px' }}>
//             This preview is for illustrative purposes only. Your photo tile may differ slightly
//             from what is shown here as a result of the trimming process.
//             Please ensure any critical areas of your photo aren't too close to the edge of the product.
//           </p>
//         </MainContent>
//         <Footer padded key="footer">
//           <div className="footer__split-buttons">
//             <Button
//               label="Edit"
//               priority="secondary"
//               onClick={() => this.handleEditItem(this.state.approvalModalItemId)}
//             />
//             <Button
//               label="Approve"
//               onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
//             />
//           </div>
//         </Footer>
//       </Modal>
//     );
//
//     if (this.state.editorItemLoading && !this.state.editorItemLoaded) {
//       return (
//         <FullScreenLoader
//           key="loader"
//           message="Loading your photo tile..."
//           isVisible={this.state.editorItemLoading && !this.state.editorItemLoaded}
//         />
//       );
//     }
//     return [
//       <Modal
//         key="editor-modal"
//         isOpen={Boolean(this.state.itemForEditor)}
//         onClose={this.closeEditor}
//         hasHeader={false}
//         animated={false}
//       >
//         <EditorContainer
//           key="editor-container"
//           ref={el => (this.editorContainer = el)}
//           item={this.state.itemForEditor}
//           onClose={this.closeEditor}
//           onSave={this.handleAddToBasket}
//           saveButtonLabel="Add to Basket"
//         />
//       </Modal>,
//       approvalModal,
//       <SweetAlert
//         key="duplication-alert"
//         isOpen={this.state.isItemDuplicationAlertVisible}
//         title="Checkout or Duplicate?"
//         text={`Your ${productNameForItem} has been added to your basket. Would you like to checkout, or duplicate and edit your ${productNameForItem}?`}
//         confirmButtonText="Checkout"
//         cancelButtonText="Duplicate"
//         onConfirm={this.handleDuplicationAlertGoToBasket}
//         onCancel={this.handleDuplicationAlertDuplicate}
//       />,
//       <FullScreenLoader
//         key="3d-loader"
//         loader="bar"
//         message="Please wait while we prepare a preview of your photo tile ..."
//         isVisible={this.state.renderItemLoading}
//       />,
//     ];
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTileEditorContainer);

// Landmark: Tile Flow should happen here
