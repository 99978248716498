import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";
import shortid from "shortid";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as designsData from "../../data/designs";
import { productsByProductId } from "../../data/products";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";
import queryString from "query-string";
import PhotoMagazineEditorContainer from "../PhotoMagazineEditor/PhotoMagazineEditorContainer";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import SweetAlert from "../SweetAlert/SweetAlert";

import { Modal as AntModal } from "antd";

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;

  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

function getImagesMappedToItem(item, images) {
  return item.update("layers", layers => {
    return layers.map(layer => {
      const imageForPage = images.get(layer.get("page"));

      if (layer.get("type") === LAYER_TYPES.PHOTO) {
        return layer.setIn(["config", "layout", 0, "image"], imageForPage);
      }

      return layer;
    });
  });
}

class PhotoMagazinePreBagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      item: null,
      pageNumberToCrop: null,
      isUploadModalVisible: false,
      isCoverTextInputModalVisible: true,
      coverTextModalHasBeenAutoShown: false,
      isCancelConfirmationAlertVisible: false,
      isDeleteConfirmationAlertVisible: false,
    };

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag) {
      this.state.item = fromJS(existingPrebag.data.item);
    }
  }

  componentDidMount() {
    if (!this.state.item) {
      this.generateItem();
    }
  }

  generateItem = async () => {
    const { productTypeId, productId } = this.props;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId)
      .sort((a, b) => a.page - b.page)
      .reverse();
    const defaultLastLayer = layers.toJS().filter(layer => layer.page === layers.size - 2);

    const parsedQueryString = queryString.parse(window.location.search);
    const finish = (parsedQueryString.finish ? parsedQueryString.finish || "gloss" : "gloss")

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      defaultLastLayer: fromJS(defaultLastLayer[0]),
      product_options: {
        coverColour: "",
        border: false,
        finish: finish
      },
      postDate: moment(),
      minPages: product.get("min_pages"),
      maxPages: product.get("max_pages"),
      // coverTemplates: product.get("photobook_cover_templates"),
      coverThemes: product.get("photobook_cover_themes"),
      // pagestemplates: product.get("photobook_templates"),
      themes: product.get("photobook_themes"),
      duplicateAlertShown: false,
      id: shortid.generate()
    });

    return this.setState({
      item: itemData,
    });
  };

  handleEditorUpdate = ({ item, images }, delay = 500, callback ) => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          item: getImagesMappedToItem(item, images),
        },
      })
    );
    if(delay === 0){
      callback();
    } else{
      setTimeout(() => {
        callback();
      }, delay);
    }
    
  };

  handleEditorSave = item => {
    this.setState(
      {
        item,
      },
      this.finalizePreBag
    );
  };

  finalizePreBag = () => {
    //localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    //this.props.addItemToBasket(this.state.item.toJS());
    //this.props.navigateToBasket();
    this.props.deletePendingApprovalItem(this.state.item.toJS().id)

    this.props.addPendingApprovalItem(this.state.item.toJS());
    const backTo = window.location.href;
    this.props.navigateToApproval(backTo);
  };

  showCancelConfirmationAlert = () => {
    if (this.photoMagazineEditor.state.images.size > 0) {
      // confirm({
      //   title: "Are you sure?",
      //   content: "If you tap Back you will close the editor and lose your photo book progress",
      //   okText: "Yes - Delete this photo book",
      //   cancelText: "No - I’ll continue to make my photo book",
      //   icon: null,
      //   onOk: () => {
      //     this.navigateBack();
      //   },
      //   onCancel: () => {
      //     this.closeCancelConfirmationAlert();          
      //   },
      // });
      this.setState({ isDeleteConfirmationAlertVisible: true });
    } else {
      this.navigateBack();
    }
  };

  closeCancelConfirmationAlert = () => {
    this.setState({ isCancelConfirmationAlertVisible: false });
  };

  closeDeleteConfirmationAlert = () => {
    this.setState({ isDeleteConfirmationAlertVisible: false });
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  render() {
    const deleteAlert = (
      <AntModal
        visible={this.state.isDeleteConfirmationAlertVisible}
        onCancel={this.closeDeleteConfirmationAlert}
        footer={null}
        title={<span style={{textAlign: 'center', display: 'block'}}>Are you sure? </span>}>
        <div>
          <>
            <p>Do you really want to delete this photo book?</p>
            <Button
              label={"No - Continue my photo book"}
              onClick={this.closeDeleteConfirmationAlert}
              block
              theme="dark-blue"
              priority="secondary"
              style={{border : '1px solid #4b5566'}}
            />
            <Button
              theme="default"
              priority={"primary"}
              label={"Yes - Delete this photo book"}
              onClick={this.navigateBack}
              block
            />
          </>
        </div>
      </AntModal>
    )
    

    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`You have unsaved changes, are you sure you want to go back?`}
        cancelButtonText="Exit & discard changes"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    return (
      <Modal key="photomagazine-prebag-modal" isOpen={true} hasHeader={false} animated={false}>
        <React.Fragment>
          {cancelConfirmationAlert}
          {deleteAlert}
          {this.state.item && (
            <PhotoMagazineEditorContainer
              ref={el => (this.photoMagazineEditor = el)}
              item={this.state.item}
              saveButtonText="Preview"
              onCancel={this.showCancelConfirmationAlert}
              onUpdate={this.handleEditorUpdate}
              onSave={this.handleEditorSave}
              setGlobalProgressBarPercentage={this.props.setGlobalProgressBarPercentage}
              onChangeOwnAddress={this.props.setUserAddress}
            />
          )}
        </React.Fragment>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  deletePendingApprovalItem: itemId => dispatch(basketActions.deletePendingApprovalItem(itemId)),
  navigateToApproval: backTo =>
    dispatch(push(routeCreators.approval(), { fromPreBag: true, backTo: backTo })),
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(PhotoMagazinePreBagContainer);
