import { fromJS } from "immutable";
import postsnapApi from '../lib/apis/postsnap';
import { PRODUCT_TYPE_IDS } from "../data/products";
//const CACHED_POSTAGE_SCHEME_DATA = fromJS(require("../api-data/cached-postage-schemes"));

// export const cachedPostageSchemeData = CACHED_POSTAGE_SCHEME_DATA.mapEntries(([key, value]) => {
//   return [+key, value];
//});

const allPostageSchemes = fromJS(require("../api-data/postage-schemes"));
const postageSchemesMap = allPostageSchemes
  .groupBy(s => s.get("currency"))
  .map(schemesForCurrency => {
    return schemesForCurrency
      .groupBy(s => s.get("destination"))
      .map(schemesForCountry => {
        return schemesForCountry.groupBy(s => s.get("product_type_id"));
      });
  });

//const VALID_UK_POSTCODE = /^(AL|B|B[ABDHLNRS]|C[ABHMORTVW]|D[AEHLNTY]|E|E[CNX]|FY|G[LUY]|H[ADGPUX]|I[GM‌​P]‌​|JE|KT|L|L[AENSU]|M|ME|N|N[EGNRW]|O[LX]|P[ELOR]|R[GHM]|S|S[EGKLMNOPRSTW]|T[AFNQ‌​‌​RSW]|UB|W|W[ACDFNRSV]|YO)\d{1,2}\s?(\d[\w]{2})?/

const NORTHERN_IRELAND = ["BT1",  "BT2",  "BT3",  "BT4",  "BT5",  "BT6",  "BT7",  "BT8",  "BT9",  "BT10", "BT11", "BT12", "BT13", "BT14", "BT15",
  "BT16", "BT17", "BT18", "BT19", "BT20", "BT21", "BT22", "BT23", "BT24", "BT25", "BT26", "BT27", "BT28", "BT29", "BT30",
  "BT31", "BT32", "BT33", "BT34", "BT35", "BT36", "BT37", "BT38", "BT39", "BT40", "BT41", "BT42", "BT43", "BT44", "BT45",
  "BT46", "BT47", "BT48", "BT49", "BT51", "BT52", "BT53", "BT54", "BT55", "BT56", "BT57", "BT58", "BT60", "BT61", "BT62",
  "BT63", "BT64", "BT65", "BT66", "BT67", "BT68", "BT69", "BT70", "BT71", "BT74", "BT75", "BT76", "BT77", "BT78", "BT79",
  "BT80", "BT81", "BT82", "BT92", "BT93", "BT94", "BT99"]

const OFFSHORE = ["HS1",  "HS2",  "HS3",  "HS4",  "HS5",  "HS6",  "HS7",  "HS8",  "HS9","KA27", "KA28",
  "KW1",  "KW2",  "KW3",  "KW4",  "KW5",  "KW6",  "KW7",  "KW8",  "KW9",  "KW10", "KW11", "KW12", "KW13", "KW14", "KW15",
  "KW16", "KW17", "PA41", "PA42", "PA43", "PA44", "PA45", "PA46", "PA47", "PA48", "PA49", "PA60", "PA61", "PA62", "PA63", "PA64", "PA65",
  "PA66", "PA67", "PA68", "PA69", "PA70", "PA71", "PA72", "PA73", "PA74", "PA75", "PA76", "PA77", "PA78",
  "PH42", "PH43", "PH44",
  "ZE1",  "ZE2",  "ZE3",
  "IM1",  "IM2",  "IM3",  "IM4",  "IM5",  "IM6",  "IM7",  "IM8",  "IM9",
  "GY",
  "JE",
  "BF1",
  "KA27", "KA28", "TR21"
]

const SCOTTISH_HIGHLANDS = [
  "AB10", "AB11", "AB12", "AB13", "AB14", "AB15", "AB16", "AB20", "AB21", "AB22", "AB23", "AB24", "AB25", "AB30", "AB31",
  "AB32", "AB33", "AB34", "AB35", "AB36", "AB37", "AB38", "AB39", "AB41", "AB42", "AB43", "AB44", "AB45", "AB51", "AB52",
  "AB53", "AB54", "AB55", "AB56", "AB99",
  "DD8",  "DD9",  "DD10", "DD11", "DD12",        
  "PA20", "PA21", "PA22", "PA23", "PA25", "PA26", "PA27", "PA28", "PA29", "PA30", "PA31", "PA32", "PA34", "PA35", "PA37",
  "PH19", "PH20", "PH21", "PH22", "PH23", "PH24", "PH25", "PH26", "PH30", "PH31", "PH32", "PH33", "PH34", "PH35", "PH36",
  "PH37", "PH38", "PH39", "PH40", "PH41",
  "IV1",  "IV2",  "IV3",  "IV4",  "IV5",  "IV6",  "IV7",  "IV8",  "IV9",  "IV10", "IV11", "IV12", "IV13", "IV14", "IV15",
  "IV16", "IV17", "IV18", "IV19", "IV20", "IV21", "IV22", "IV23", "IV24", "IV25", "IV26", "IV27", "IV28", "IV29", "IV30",
  "IV31", "IV32", "IV36", "IV40", "IV41", "IV42", "IV43", "IV44", "IV45", "IV46", "IV47", "IV48", "IV49", "IV51", "IV52",
  "IV53", "IV54", "IV55", "IV56", "IV63",
  "KW1",  "KW2",  "KW3",  "KW4",  "KW5",  "KW6",  "KW7",  "KW8",  "KW9",  "KW10", "KW11", "KW12", "KW13", "KW14", "KW15",
  "KW16", "KW17",
]

const EU_COUNTRIES = [
  "Andorra",
  "Austria",
  "Belgium",
  "Bosnia and Herzegovina",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Gibraltar",
  "Greece",
  "Holy See (Vatican City State)",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Monaco",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "San Marino",
  "Serbia and Montenegro",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

function getInternalDestinationFromRealCountryName(countryName) {
  if (EU_COUNTRIES.includes(countryName)) {
    return "eu";
  }

  switch (countryName) {
    case "United Kingdom":
      return "uk";
    case "United States":
      return "usa";
    case "Canada":
      return "canada";
    case "Australia":
      return "australia";
    default:
      return "international";
  }
}

export function getPostageSchemeForProductTypeAndDestination({
  productTypeId,
  destinationCountry,
  currency = "GBP",
  weight,
  useFreshPostageSchemes = false,
  freshPostageSchemes = {},
  productIds = []
}) {
  const applicablePostageSchemes = getAllPostageSchemesForProductTypeAndDestination({
    productTypeId,
    destinationCountry,
    currency,
    weight,
    useFreshPostageSchemes,
    freshPostageSchemes,
    productIds
  })
  if(!applicablePostageSchemes){
    console.log("No postage scheme found for:", `pt:${productTypeId} | dest:${destinationCountry} | currency:${currency} | weight:${weight}`)
  }
  const validScheme = applicablePostageSchemes.sortBy(scheme => parseFloat(scheme.get('cost'))).find(scheme => {
    const maxWeight = scheme.get("max_weight") === null ? Infinity : scheme.get("max_weight");
    //const isNotUpgrade = scheme.get("is_an_upgrade") === false
    return weight > scheme.get("min_weight") && weight <= maxWeight;
  });
  return validScheme;
}

export function getPostageSchemeForId(postageSchemeId) {
  return allPostageSchemes.find((ps) => ps.get('id') === postageSchemeId);
}

export async function getFreshPostageSchemes(productTypeId, cacheResponse = true){
  const postageSchemes = await postsnapApi.postageSchemes.getAllForProductType(productTypeId, cacheResponse);
  const postageSchemeData = fromJS(postageSchemes).groupBy(s => s.get("currency"))
  .map(schemesForCurrency => {
    return schemesForCurrency
      .groupBy(s => s.get("destination"))
      .map(schemesForCountry => {
        return schemesForCountry.groupBy(s => s.get("product_type_id"));
    });
  });
  return postageSchemeData
}

export function getAllPostageSchemesForProductTypeAndDestination({
  productTypeId,
  destinationCountry,
  currency = "GBP",
  weight,
  useFreshPostageSchemes = false,
  freshPostageSchemes = {},
  productIds = [],
  postcode
}) {

  let postageSchemeData = postageSchemesMap;
  if(useFreshPostageSchemes){
    postageSchemeData = freshPostageSchemes;
  }

  const internalDestinationCode = getInternalDestinationFromRealCountryName(destinationCountry);
  let applicablePostageSchemes = postageSchemeData.getIn([
    currency,
    internalDestinationCode,
    productTypeId,
  ])
  
  if(applicablePostageSchemes){
    applicablePostageSchemes = applicablePostageSchemes.filter(scheme => {
      const maxWeight = scheme.get("max_weight") === null ? Infinity : scheme.get("max_weight");
      return weight > scheme.get("min_weight") && weight <= maxWeight;
    });


    if(applicablePostageSchemes.some(scheme => scheme.get("is_applicable_to_specific_products")) && productIds.length > 0){
      applicablePostageSchemes = applicablePostageSchemes.filter(scheme => {
        //console.log("scheme", scheme.get('description'))
        const hasSpecificProducts = scheme.get("specific_product_ids").size > 0

        if (hasSpecificProducts){
          //console.log("has specific products")
          const hasCorrectProducts = scheme.get("specific_product_ids").some(specific_product_id => productIds.includes(parseInt(specific_product_id)))
          if(hasCorrectProducts){
            //console.log("has correct products")
            return true
          } else{
            //console.log("doesn't have correct products")
            return false
          }
        } else{
          const otherSchemesForSameWeightAndDeliveryClass = applicablePostageSchemes.filter(checkScheme => {
            const sameDeliveryClass = checkScheme.get('delivery_class') === scheme.get('delivery_class')
            return sameDeliveryClass
          })

          if(otherSchemesForSameWeightAndDeliveryClass.size > 1) {
            //console.log("other schemes for weight")
            const tryOthers = otherSchemesForSameWeightAndDeliveryClass.filter(tryScheme => scheme.get('id') !== tryScheme.get('id'))
            //console.log("tryOthers", tryOthers.toJS())
            const anyWithCorrectProducts = tryOthers.some(scheme => {
              //console.log("trying scheme", scheme.get("description"))
              const hasSpecificProducts = scheme.get("specific_product_ids").size > 0
              //console.log("hasSpecificProducts", hasSpecificProducts)
              if(hasSpecificProducts){
                //console.log("other scheme has specific products")
                const hasCorrectProducts = scheme.get("specific_product_ids").some(specific_product_id => productIds.includes(parseInt(specific_product_id)))
                if(hasCorrectProducts){
                  //console.log("other scheme has correct products, dont show this")
                  return true
                }
                else{
                  //console.log("other scheme doesn't match correct products, show this")
                  return false
                }
              } else{
                //console.log("other scheme doesn't have specific products, show this")
                return false
              }
            })
            //console.log("anyWithCorrectProducts", anyWithCorrectProducts)
            if (anyWithCorrectProducts){
              //console.log("some with correct products, dont show this")
              return false
            } else{
              //console.log("no others with correct products, show this")
              return true
            }

          } else{
            //console.log("no others schemes for same del class, show this")
            return true
          }
        }
      });
    }

    if(postcode && destinationCountry === "United Kingdom" && productTypeId === PRODUCT_TYPE_IDS.PHOTO_PRINT){
      //const isValidUkPostcode = VALID_UK_POSTCODE.test(postcode)
      //console.log("isValidUkPostcode", isValidUkPostcode)
      const isMainlandUk = !NORTHERN_IRELAND.some((p) => postcode.startsWith(p)) && !OFFSHORE.some((p) => postcode.startsWith(p)) && !SCOTTISH_HIGHLANDS.some((p) => postcode.startsWith(p))
      if (!isMainlandUk){
        applicablePostageSchemes= applicablePostageSchemes.filter(scheme => scheme.get('delivery_class') !== "courier")
      }
    }

    applicablePostageSchemes = applicablePostageSchemes.sortBy(scheme => parseFloat(scheme.get('cost')));
  }


  if(applicablePostageSchemes){
    //console.log("Available schemes", applicablePostageSchemes.toJS());
  } else{
    console.log("No postage scheme found for:", `pt:${productTypeId} | dest:${destinationCountry} | currency:${currency} | weight:${weight}`)
  }
  
  return applicablePostageSchemes;
  
  // return applicablePostageSchemes.find(scheme => {
  //   const maxWeight = scheme.get("max_weight") === null ? Infinity : scheme.get("max_weight");
  //   return weight >= scheme.get("min_weight") && weight < maxWeight;
  // });
}
