import React from "react";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import * as LAYER_TYPES from "../../constants/layer-types";

const PhotoBookSpreadPreview = ({
  item,
}) => {

  const coverPage = item && item.get("layers").find((layer) => layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])  && layer.get("page") === 0);
  const layersWithPhotos = item && item
  .get("layers")
  .filter(
    (layer) =>
      layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
  )

  const aspectRatio = item && item.getIn(["productDimensions", "width"]) / item.getIn(["productDimensions", "height"]);

  const coverPreviewWidth = 200
  const pagePreviewWidth = 130
  const pagePreviewHeight = 130 / aspectRatio

  const spreads = layersWithPhotos
    ? layersWithPhotos
        .sortBy((layer) => layer.get("page"))
        .slice(1) // Remove the cover since it will be displayed separately
        .reduce((result, layer, index) => {
          const isFirstPage = index === 0
          if(isFirstPage){
            result.push([null, item.get("layers").get(index)]);
            return result
          } else{
            if (index % 2 !== 0) { // odd (i.e 1,3 which are page 2,4)
              const nextLayer = layersWithPhotos.find(findLayer => findLayer.get('page') === layer.get('page')+1)
              const spread = [layer, nextLayer || null];              
              result.push(spread);
            }
          }
          
          return result;
        }, [])
    : [];
  

  return (
    <div>
      {/* Button at the top to call onDone */}
      {/* <button onClick={onDone}>Done</button> */}

      {coverPage && (
        <div
          style={{
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: 'column',
            marginBottom: '20px'
          }}
        >
          <span style={{
            marginBottom: '10px',
            fontSize: '12px'
          }}>Front Cover</span>
          <HtmlRenderer
            width={coverPreviewWidth}
            isInteractive={false}
            previewMode={false}
            item={item.toJS()}
            page={0}
            realBorder={true}
            photoBookBorders={true}
            lowResScaling={true}
            showBorderCanvas={false}
            key={`info-page-preview`}
            uniquekey="infoScreen"
          />
        </div>
      )}

      {/* Display the page spreads */}
      {spreads.map((spread, index) => (
        <div key={`spread-${index}`} style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
          {spread.map((layer, spreadIndex) => {
            let page
            if (layer){
              const isFirstPage = layer.get("page") === 0
              page = isFirstPage ? 1 : layer.get("page")
            }
            
            return (
              layer ? (
                <div
                  key={`preview-wrapper-${page}`}
                  style={{
                    pointerEvents: "none",
                    display: "flex",
                    justifyContent: "center",
                    margin: 0,
                    alignItems: page % 2 === 0 ? 'flex-start' : 'flex-end',
                    flexDirection: 'column'
                  }}
                  className="pageContainer"
                >
                  <span style={{
                    marginBottom: '10px',
                    fontSize: '12px'
                  }}>Page {page}</span>
                  <HtmlRenderer
                    width={130}
                    isInteractive={false}
                    previewMode={false}
                    item={item.toJS()}
                    page={page}
                    onClick={() => {}}
                    realBorder={true}
                    photoBookBorders={true}
                    lowResScaling={true}
                    key={`preview-${page}`}
                    templateCallback={() => {}}
                    showBorderCanvas={false}
                  />
                </div>
              ) : (
                // If there's no second page in the spread, display a blank page
                <div key={`blank-page-${index}`}
                  style={{
                    pointerEvents: "none",
                    display: "flex",
                    justifyContent: "center",
                    margin: 0,
                    alignItems: index === 0 ? 'flex-start' : 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      marginBottom: '10px',
                      fontSize: '12px'
                    }}
                  >Blank Page</span>
                  <div
                    style={{
                      width: `${pagePreviewWidth}px`,
                      height: `${pagePreviewHeight}px`,
                      border: '1px solid #d7d7d7'
                    }}
                  ></div>
                </div>
              )
            )  
          })}
        </div>
      ))}
    </div>
  );
}

export default PhotoBookSpreadPreview;