import { types as basketTypes, actions as basketActions } from "../ducks/basket";
import { message } from "antd";

message.config({
  duration: 3,
  maxCount: 1,
});

export default store => next => action => {
  const ACTIONS_TO_START_RENDER_FOR = [
    basketTypes.ADD_ITEM,
    basketTypes.UPDATE_ITEM,
    basketTypes.ADD_PENDING_APPROVAL_ITEM,
    basketTypes.UPDATE_PENDING_APPROVAL_ITEM,
  ];

  if (ACTIONS_TO_START_RENDER_FOR.includes(action.type)) {
    /**
     * A timeout is used to ensure that the action we're acting on has been dispatched and the store has been updated,
     * since the `renderItem()` action fetches the item data by itself
     */
    setTimeout(() => {
      const itemId = action.payload.itemId || action.payload.id;
      console.log("itemId Found", itemId);
      //console.log('Rendering item from ACTIONS_TO_START_RENDER_FOR', action);
      if (
        action.type === basketTypes.ADD_PENDING_APPROVAL_ITEM ||
        action.type === basketTypes.UPDATE_PENDING_APPROVAL_ITEM
      ) {
        store.dispatch(basketActions.renderPendingApprovalItem(itemId)).catch(err => {
          message.error(
            "There was a problem preparing your item, please check your connection and try again."
          );
        });
      } else {
        store.dispatch(basketActions.renderItem(itemId)).catch(err => {
          message.error(
            "There was a problem preparing your item, please check your connection and try again."
          );
        });
      }
    }, 0);
  }

  return next(action);
};
