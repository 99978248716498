import React from "react";
import { Modal as AntModal } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const FontFamilySelectionModal = ({ isOpen, onClose, fonts, onChangeFont, selectedFontFamily }) => {
  return (
    <AntModal visible={isOpen} onCancel={onClose} footer={[]} title={<span style={{textAlign: 'center',display: 'block', marginLeft: '-10px'}}>Select a font</span>}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {fonts.map((font,index) => (
          <div key={index} onClick={() => onChangeFont(font.name)} style={{
            padding: '10px 0px',
            paddingLeft: '10px',
            background: selectedFontFamily === font.name ? '#efefef' : 'transparent'
          }}>
            <text style={{ fontFamily: font.name, color: selectedFontFamily === font.name ? '#FC5D62' : 'black' }}>{font.displayName}</text>
            {selectedFontFamily === font.name ? (
              <CheckCircleFilled style={{ marginLeft: 30, color: "#31CF65", fontSize: '22px', float: 'right', marginRight: '10px'}} />
            ) : (
              <CheckCircleFilled style={{ marginLeft: 30, color: "transparent", fontSize: '22px' }} />
            )}
          </div>
        ))}
      </div>
    </AntModal>
  );
};
export default FontFamilySelectionModal;
