export default {
  "12-x-8-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.07142857143, // 1"(wrap)/14"(x+wrap) = 0.07142857143
        y: 0.9, // 1"(wrap)/10"(x+wrap) = 0.1
        width: 0.857, // 12"/14" = 0.857
        height: 0.8, // 8"/10" = 0.8
        // x: 0.0638, //0.9"/14.1" //0.059000000000000004,
        // y: 0.9109, //1-(0.9"/10.1")
        // width: 0.8723, // 12.3"/14.1"
        // height: 0.8218, // 8.3"/10.1"
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.07142857143,
        y: 0.9,
        width: 0.867,
        height: 0.8,
        // x: 0.0638, //0.9"/14.1" //0.059000000000000004,
        // y: 0.9109, //1-(0.9"/10.1")
        // width: 0.8723, // 12.3"/14.1"
        // height: 0.8218, // 8.3"/10.1"
      },
    },
  },
  "16-x-12-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.05555555556, // 1/18
        y: 0.9285714286, // 1-(1/14) 
        width: 0.8888888889, // 16/18
        height: 0.8571428571, // 12/14
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.05555555556,
        y: 0.9285714286,
        width: 0.8888888889,
        height: 0.8571428571,
      },
    },
  },
  "12-x-12-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.07142857143, // 1/14
        y: 0.9285714286, // 1-(1/14)
        width: 0.8571428571, // 12/14
        height: 0.8571428571,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.07142857143,
        y: 0.9285714286,
        width: 0.8571428571,
        height: 0.8571428571,
      },
    },
  },
  "24-x-16-canvas": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0.03846153846, // 1/26
        y: 0.9444444444, // 1-(1/18)
        width: 0.9230769231, //24/26
        height: 0.8888888889, //16/18
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0.03846153846,
        y: 0.9444444444,
        width: 0.9230769231,
        height: 0.8888888889,
      },
    },
  },
  "photo-tiles": {
    canvasEditor: {
      style: "transparent-bleed-masks",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
    canvasPreview: {
      style: "transparent-bleed-masks-with-shadow",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
  },
  "2×2_PRINTS": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    }
  },
  "3×3_PRINTS": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    }
  },
  "2×3_PRINTS": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    }
  },
  "2×2.5_PRINTS": {
    canvasEditor: {
      style: "corners-and-dotted-lines",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    },
    canvasPreview: {
      style: "bleed-bands",
      rect: {
        x: 0,
        y: 1,
        width: 1,
        height: 1,
      },
    }
  },
};
