export default function getTranslatedPixelMeasurement({
  productDimensions,
  canvasDimensions,
  amount,
}) {
  const width = (((amount / productDimensions.width) * canvasDimensions.width)/2)
  const height = (((amount / productDimensions.height) * canvasDimensions.height)/2)
  return {
    width: width,
    height: height,
  };
}
