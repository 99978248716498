import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";
import Button from "../Button/Button";
import Env from "../Env/Env";
import Currency from "../Formatters/Currency";
import SavedCreditCard from "../SavedCreditCard/SavedCreditCard";
import Divider from "../Divider/Divider";
import SweetAlert from "../SweetAlert/SweetAlert";

const DEFAULT_POSTCODE_LABEL = "ZIP";

class StripePaymentForm extends React.Component {
  static propTypes = {
    amount: PropTypes.number,
    onSubmit: PropTypes.func,
    isPaying: PropTypes.bool,
    currency: PropTypes.string,
    enableSavedCard: PropTypes.bool,
    defaultPostcode: PropTypes.string,
    confirmStripePayment: PropTypes.func,
    onPaymentComplete: PropTypes.func,
    onPaymentError: PropTypes.func,
  };

  static defaultProps = {
    enableSavedCard: true
  };

  state = {
    error: null,
    saveCardDetails: false,
    isRequestingToken: false,
    isRemovingSavedCard: false,
    removedSavedCard: false,
    alert: null,
    postcode: this.props.defaultPostcode || ""
  };

  componentDidMount() {

    this.stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
    const elements = this.stripe.elements();

    const style = {
      base: {
        color: "#32325d",
        lineHeight: "24px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    this.card = elements.create("card", {
      hidePostalCode: true,
      style,
    });

    this.card.mount(this.cardElement);

    window.card = this.card;

    this.card.addEventListener("change", event => {
      this.setState({
        error: event.error ? event.error.message : "",
      });
    });

    // Update the card payload with our custom postcode input
    this.postalCodeInput.addEventListener("change", ({ target }) => {
      this.card.update({ value: { postalCode: target.value } });
    });
  }

  componentWillUnmount() {
    this.cardElement.blur();
    document.activeElement.blur();
  }

  handleFormSubmit = e => {
    e.preventDefault();

    this.setState({
      isRequestingToken: true,
    });

    /**
     * Manually trigger the focus and blur the card element immediately afterwards to ensure number keyboard disappears
     * on iOS Safari.
     */
    this.card.focus();
    setTimeout(() => {
      this.card.blur();
    }, 0);

    this.stripe.createPaymentMethod(
      'card',
      this.card
    ).then(async result => {
      if (result.error) {
        //console.error('Stripe token creation error', result);
        this.setState({
          error: result.error.message,
          isRequestingToken: false
        });
      } else {
        // Send paymentMethod.id to server
        //console.log("Stripe PM result:", result)
        const serverResponse = await this.props.confirmStripePayment({payment_method_id: result.paymentMethod.id});
        //console.log("Server Confirmation result:", serverResponse)

        this.handleServerResponse(serverResponse.data);
      }
    }).catch((e) => {
      this.setState({
        isRequestingToken: false,
      });
      console.log('Got error', e);
    });
  };

  handleServerResponse = async (response) => {
    if (response.error) {
      console.log("Error", response.error)
      this.setState({
        error: response.error,
        isRequestingToken: false
      });
    } else if (response.data.action_required) {
      //console.log("Requires Action", response)
      // Use Stripe.js to handle required card action
      this.handleAction(response.data);
    } else {
      //console.log("Success", response)
      this.props.onPaymentComplete({
        response,
        saveCardDetails: this.state.saveCardDetails,
      });
    }
  }

  handleAction = async (response) => {
    await this.stripe.confirmCardPayment(
      response.payment_intent_client_secret
    )
    .then(async (result) => {
      if (result.error) {
        console.log("Error", result.error)
        this.setState({
          error: result.error.message,
          isRequestingToken: false,
        }, () => {
          this.props.onPaymentError(result.error.message)
        });
        // Show error in payment form
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        // TODO: Check if this is needed?
        const serverResponse = await this.props.confirmStripePayment({payment_method_id: result.paymentIntent.payment_method});
        this.handleServerResponse(serverResponse.data);
      }
    });
  }

  handleSaveCardDetailsCheckboxChange = e => {
    this.setState({
      saveCardDetails: e.target.checked,
    });
  };

  handlePostcodeChange = e => {
    this.setState({
      postcode: e.target.value,
    });
  }

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  removeSavedCard = async () => {
    this.setState({
      isRemovingSavedCard: true,
    });

    try {
      await this.props.removeSavedCard();
      this.setState({
        removedSavedCard: true,
        alert: {
          type: "success",
          text: "Your card details have been successfully removed",
          confirmButtonText: "OK",
          onConfirm: this.clearAlert,
          showCancelButton: false,
        },
      });
    } catch (err) {
      console.warn("Error while removing saved card:", err);
    } finally {
      this.setState({
        isRemovingSavedCard: false,
      });
    }
  };

  render() {
    const postcodeLabel = this.props.currency === "GBP" ? "Postcode" : DEFAULT_POSTCODE_LABEL;
    const hasSavedCreditCard = false
      // this.props.user &&
      // this.props.user.getIn(["stripe_card_details", "saved_card"]) &&
      // !this.state.removedSavedCard;

    return (
      <form className="form stripe-payment-form" onSubmit={this.handleFormSubmit}>
        <SweetAlert key="alert" isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />
        <Env env={env => env !== "production"}>
          <p>
            <small>
              US test card (Visa): <code>4242 4242 4242 4242</code> (any exp./CVC) US test card
              (Visa debit): <code>4000 0566 5566 5556</code> (any exp./CVC)
            </small>
          </p>
        </Env>
        {this.props.amount && (
          <table className="muted">
            <tbody>
              <tr>
                <td>Order Total</td>
                <td className="text-right">
                  <Currency amount={this.props.amount} />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <br />
        {hasSavedCreditCard && [
          <div key="saved">
            <SavedCreditCard
              last4Digits={
                hasSavedCreditCard &&
                this.props.user.getIn(["stripe_card_details", "saved_card", "last4"])
              }
              brand={
                hasSavedCreditCard &&
                this.props.user.getIn(["stripe_card_details", "saved_card", "brand"])
              }
            />
            <Button
              block
              label="Pay with saved card"
              loadingLabel="Making Payment..."
              loading={this.props.isPayingWithSavedCard}
              disabled={this.props.isPaying || this.state.isRemovingSavedCard}
              priority="primary"
              theme="dark-blue"
              onClick={this.props.onPayWithSavedCard}
            />
            <Button
              block
              label="Remove saved card"
              loadingLabel="Removing Card..."
              loading={this.state.isRemovingSavedCard}
              disabled={this.props.isPayingWithSavedCard || this.props.isPaying}
              priority="secondary"
              theme="dark-blue"
              onClick={this.removeSavedCard}
            />
          </div>,
          <Divider key="saved-divider" label="or use another card" />,
        ]}
        <br />
        <div className="form-group">
          <label className="control-label" htmlFor="card-element">
            Card Details
          </label>
          <div className="form-control" ref={e => (window.cardElement = this.cardElement = e)} />
          {this.state.error && (
            <p className="text-danger">
              <small>{this.state.error}</small>
            </p>
          )}
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="postcode">
            {postcodeLabel}
          </label>
          <input
            ref={el => (this.postalCodeInput = el)}
            type="text"
            className="form-control"
            style={{ textTransform: "uppercase" }}
            value={this.state.postcode}
            onChange={this.handlePostcodeChange}
          />
        </div>
        {this.props.enableSavedCard && (
          <div className="form-group">
            <div className="pretty p-svg">
              <input
                type="checkbox"
                value={this.state.saveCardDetails}
                onChange={this.handleSaveCardDetailsCheckboxChange}
              />
              <div className="state">
                <img className="svg" src={`${process.env.PUBLIC_URL}/images/checkmark.svg`} alt="" />
                <label>Save these card details for next time</label>
              </div>
            </div>
          </div>
        )}
        <Button
          block
          label="Pay Now"
          loadingLabel="Making Payment..."
          theme="dark-blue"
          loading={this.state.isRequestingToken || this.props.isPaying}
          disabled={
            this.state.isRequestingToken ||
            this.props.isPaying ||
            this.props.disabled ||
            this.props.isPayingWithSavedCard
          }
          type="submit"
        />
      </form>
    );
  }
}

const mapStateToProps = state => ({
  user: authSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
  removeSavedCard: () => dispatch(authActions.removeSavedCard()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripePaymentForm);
