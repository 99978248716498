import React from "react";

import { PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO } from "../../constants/text-style-options";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererAddressLayer extends React.Component {
  static propTypes = {};

  static defaultProps = {};


  render() {

    console.log("Address",this.props.address)
  
    const rectStyles = {
      fontSize:
        (this.props.canvasDimensions.height || 0) *
        PLACEHOLDER_AND_ADDRESS_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO,
    };

    return (
      <HtmlRendererRect
        className="html-renderer-address-layer"
        rect={this.props.config.rect}
        style={rectStyles}
        onClick={this.props.onClick}
      >
        <p style={{ whiteSpace: "pre-line" }}>
          {generateMultilineAddress(this.props.address).join("\n")}
        </p>
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererAddressLayer;
