import React from "react";

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        ...props.formData,
      passwordVisible: false,
      password: null
    };
  }

  togglePasswordVisiblity() {
    this.setState({
      passwordVisible: !this.state.passwordVisible
    }, () => {
      this.passwordInput.focus();
    });
  }

  passwordChange(val) {
    this.setState({
      password: val
    }, () => {
      this.props.onChange(val)
    });
  }

  render() {
      const {passwordVisible} = this.state;
      return (
      <div className="show-hide-password-wrapper">
        <input
          ref={(input) => { this.passwordInput = input; }} 
          type={passwordVisible ? "text" : "password"}
          className='form-control'
          value={this.props.value}
          required={this.props.required}
          onChange={(event) => this.passwordChange(event.target.value)}
          data-ps-val={this.state.password}
          placeholder={this.props.placeholder}
          id="pw-input"
        />
        <span
          className="show-hide-password--btn"
          onClick={() => this.togglePasswordVisiblity()}>
          {passwordVisible ? 'HIDE' : 'SHOW'}
        </span>
      </div>
      );
  }
}
  export default PasswordField;