import React, { Component } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";

import loadCrossOriginImage from "../../lib/load-cross-origin-image";
import * as PHOTO_FILTERS from "../../constants/photo-filters";

import "./FilterPreview.scss";

const PHOTO_FILTER_IDS_TO_FABRIC_FILTERS = {
  [PHOTO_FILTERS.GRAYSCALE]: fabric.Image.filters.Grayscale,
  [PHOTO_FILTERS.BLACK_WHITE]: fabric.Image.filters.BlackWhite,
  [PHOTO_FILTERS.SEPIA]: fabric.Image.filters.Sepia,
  [PHOTO_FILTERS.BROWNIE]: fabric.Image.filters.Brownie,
  [PHOTO_FILTERS.VINTAGE]: fabric.Image.filters.Vintage,
  [PHOTO_FILTERS.TECHNICOLOR]: fabric.Image.filters.Technicolor,
  [PHOTO_FILTERS.POLAROID]: fabric.Image.filters.Polaroid,
  [PHOTO_FILTERS.KODACHROME]: fabric.Image.filters.Kodachrome,
};

class FilterPreview extends Component {
  static propTypes = {
    filterId: PropTypes.oneOf(Object.keys(PHOTO_FILTERS), null).isRequired,
    imageSrc: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {},
  };

  async componentDidMount() {
    this.width = 80;
    this.height = 50;

    this.canvas = new fabric.Canvas(this.canvasElement, {
      preserveObjectStacking: true,
      width: this.width,
      height: this.height,
    });

    this.image = new fabric.Image();
    this.image.selectable = false;

    await this.loadImage(this.props.imageSrc);

    if (this.props.filterId) {
      console.log(PHOTO_FILTERS[this.props.filterId]);
      console.log(PHOTO_FILTER_IDS_TO_FABRIC_FILTERS[
        PHOTO_FILTERS[this.props.filterId]
      ]);
      //debugger;
      const filter = new PHOTO_FILTER_IDS_TO_FABRIC_FILTERS[
        PHOTO_FILTERS[this.props.filterId]
      ]()
      try{
        this.image.filters[0] = filter;
        this.image.applyFilters();
      } catch (e){
        console.log(e);
      }
      
    }

    this.canvas.add(this.image);
  }

  loadImage = async src => {
    const imgElement = await loadCrossOriginImage(src);
    this.image.setElement(imgElement);

    let scaleFactor = this.width / this.image.width;
    if (this.image.height * scaleFactor < this.height) {
      scaleFactor = this.height / this.image.height;
    }

    this.image.set({
      scaleX: scaleFactor,
      scaleY: scaleFactor,
    });

    this.canvas.renderAll();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.imageSrc !== this.props.imageSrc) {
      this.loadImage(this.props.imageSrc);
    }
  }

  render() {
    return (
      <div className="filter-preview" onClick={() => this.props.onClick(this.props.filterId)}>
        <canvas ref={e => (this.canvasElement = e)} />
        <div className="filter-preview__name">{this.props.filterId || "No filter"}</div>
      </div>
    );
  }
}

export default FilterPreview;
