import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";

import "./ShopButton.scss";

class ShopButton extends React.Component {
  componentDidUpdate() {}

  render() {
    return (
      <div className="shop-button" style={{ marginLeft: "8px" }}>
        <Button icon="home" priority="tertiary" theme="muted" onClick={this.props.goToShop} />
      </div>
    );
  }
}

ShopButton.propTypes = {
  goToShop: PropTypes.func,
};

ShopButton.defaultProps = {};

export default ShopButton;
