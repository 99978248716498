import React from "react";
import PropTypes from "prop-types";
import Modal from "../../components/Modal/Modal";
import Button from "../Button/Button";
import Currency from "../../components/Formatters/Currency";
import generateMultilineAddress from "../../lib/generate-multiline-address";
  
import {
  notification,
} from "antd";

import {
  getPostageSchemeForProductTypeAndDestination,
  getAllPostageSchemesForProductTypeAndDestination,
  getFreshPostageSchemes
} from "../../data/postage-schemes";

// import {
//   WarningOutlined
//} from '@ant-design/icons';

import './ShippingSummary.scss';

class ShippingSummary extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onCancel: PropTypes.string,
    isOpen: PropTypes.bool,
    onDone: PropTypes.bool,
    onChangeShippingOption: PropTypes.func,
    currency: PropTypes.bool,
    onEditAddress: PropTypes.func,
    onEditThisAddress: PropTypes.func,
    guestDetails: PropTypes.object,
    existingPostageScheme: PropTypes.number,
  };

  static defaultProps = {
    isOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if(!state.phoneNumber && !state.hasEditedPhone){
      return {
        phoneNumber: props.guestDetails.get('phone') || props.userMobile || '',
      }
    } 
  }

  constructor(props) {
    super(props);
    this.state = {
      activePostageSchemeId: this.props.existingPostageScheme || null,
      shippingAddress: null,
      shippingOptions: null,
      phoneNumber: this.props.guestDetails ? this.props.guestDetails.get('phone') : this.props.userMobile || '',
      hasEditedPhone: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.isOpen && !prevProps.isOpen) || this.state.activePostageSchemeId === null){
      this.populateShippingOptions()
    }
  }

  // componentDidMount(){
  //   if (!!this.state.phoneNumber){
  //     this.setState({
  //       phoneNumber: this.props.guestDetails ? this.props.guestDetails.get('phone') : null
  //     })
  //   }
  // }

  async populateShippingOptions() {
    if(this.props.items.length > 0){
      const item = this.props.items[0]
      const shippingAddress = this.props.useAddress //item.get("address") || item.get("addressBookEntry");
      const fallbackWeight = 1;
      let activePostageSchemeId;
      let quantity = item.get("quantity");
      const totalWeight = quantity * (item.get("weight") === undefined ? fallbackWeight : item.get("weight"));
      if(shippingAddress){
        const freshPostageSchemes = await getFreshPostageSchemes(item.get("productTypeId"), true)

        const uniqProductIds = [...new Set(this.props.items.map(item => item.get('productId')))]

        const shippingOptions = getAllPostageSchemesForProductTypeAndDestination({
          productTypeId: item.get("productTypeId"),
          destinationCountry: shippingAddress.get('country'),
          currency: this.props.currency,
          weight: totalWeight,
          useFreshPostageSchemes: true,
          freshPostageSchemes: freshPostageSchemes,
          productIds: uniqProductIds,
          postcode: shippingAddress.get('postcode')
        });


        if(this.props.existingPostageScheme){
          activePostageSchemeId = this.props.existingPostageScheme
        } else {
          const postageScheme = getPostageSchemeForProductTypeAndDestination({
            productTypeId: item.get("productTypeId"),
            destinationCountry: shippingAddress.get('country'),
            currency: this.props.currency,
            weight: totalWeight,
            useFreshPostageSchemes: true,
            freshPostageSchemes: freshPostageSchemes,
            productIds: uniqProductIds
          });
          activePostageSchemeId = postageScheme.get('id')
        }
        
        this.setState({
          activePostageSchemeId: activePostageSchemeId,
          shippingAddress: shippingAddress,
          shippingOptions: shippingOptions
        })
      }
    }
  }

  handleChangeShippingOption = (optionId) => {
    console.log("Changed shipping option", optionId);
    this.setState({
      activePostageSchemeId: optionId
    }, () => {
      this.props.onChangeShippingOption(optionId)
    })
  }

  handlePhoneChange = (phone) => {
    console.log("Changed phone value", phone);
    this.setState({
      phoneNumber: phone,
      hasEditedPhone: true
    })
  }

  handlePhoneUpdate = () => {
    this.props.onUpdatePhone(this.state.phoneNumber)
  }

  handleAddPhonePrompt = () => {
    notification.close("checkPhone");
    this.phoneInput.focus()
  }

  showNoPhoneNotification = () => {
    const noPhoneDescription = (
      <React.Fragment>
        <p style={{fontSize: '13px'}}>We <strong>strongly</strong> advise you to provide a phone number so we can quickly update you on the status of your order and let you know of any quality issues.</p>
        <Button
          block
          theme="dark-blue"
          label="Add Phone"
          size="small"
          priority="secondary"
          onClick={this.handleAddPhonePrompt}/>
        <Button
          block
          theme="dark-blue"
          label="Continue without Phone"
          size="small"
          priority="primary"
          onClick={() => {
            this.props.onDone(this.state.activePostageSchemeId, this.state.phoneNumber)
            notification.close("checkPhone");
          }}
        />
      </React.Fragment>
    )

    notification.open({
      message: "One second...",
      description: noPhoneDescription,
      duration: 0,
      key: "checkPhone",
      placement: "topRight",
      onClose: () => {},
    });
  }

  handleDone = () => {
    if (!this.state.phoneNumber){
      this.showNoPhoneNotification()
    } else{
      this.props.onDone(this.state.activePostageSchemeId, this.state.phoneNumber)
    }
  }

  render () {
    //console.log("this.props.existingPostageScheme", this.props.existingPostageScheme);
    // if (this.state.activePostageSchemeId === null){
    //   console.log("No active postage scheme - populating shipping options");
    //   this.populateShippingOptions()
    // }
    // console.log("props guest details", this.props.guestDetails.get('phone'))
    // console.log("this.state.phoneNumber", this.props.guestDetails ? this.props.guestDetails.get('phone') : null)
    // console.log("this.state.phoneNumber", this.state.phoneNumber);

    const {
      shippingAddress,
      shippingOptions,
      phoneNumber
    } = this.state

    //console.log("shippingAddress", shippingAddress);

    const hasMoreThanOneShippingOption = !!shippingOptions && shippingOptions.toJS().length > 1

    return (
      <Modal
        key="shipping-summary-modal"
        isOpen={this.props.isOpen}
        onClose={this.props.onCancel}
        title="Shipping Details"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Back"
            onClick={this.props.onCancel}
          />
        }
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Next"
            onClick={this.handleDone}
          />
        }
      >
        <div className="restricted-width-modal-content">
          <div className="shipping-summary__container">
            <div className="shipping-summary__section">
              <h2>Shipping Address</h2>
              {!!shippingAddress && (
                <div className="shipping-summary__shipping-address">
                  <h3>Address:</h3>
                  <p>
                    {generateMultilineAddress(shippingAddress.toJS()).join("\n")}
                  </p>
                  <Button
                    theme="dark-blue"
                    label="Edit address"
                    size="small"
                    priority="secondary"
                    onClick={() => this.props.onEditThisAddress(shippingAddress)}
                  />
                  {/* <Button
                    theme="dark-blue"
                    label="Choose another address"
                    size="small"
                    priority="secondary"
                    onClick={this.props.onEditAddress}
                  /> */}
                  <div className="shipping-summary__shipping-address-phone">
                    <h3>Mobile Number:</h3>
                    <div className="shipping-summary__shipping-address-phone-input">
                      <input
                        ref={(input) => { this.phoneInput = input }}
                        type={"text"}
                        className='form-control'
                        value={phoneNumber}
                        onChange={(e) => this.handlePhoneChange(e.target.value)}
                      />
                      <Button
                        theme="dark-blue"
                        label={phoneNumber === '' ? "Add" : "Update"}
                        size="small"
                        priority="secondary"
                        onClick={this.handlePhoneUpdate}
                      />
                    </div>
                  </div>              
                </div>
              )}
              
            </div>
            {hasMoreThanOneShippingOption && (
              <div className="shipping-summary__section">
                <h2>Shipping Options</h2>
                <div className="shipping-summary__shipping-options">
                  {!!shippingOptions && shippingOptions.toJS().map(shippingOption => (
                    <div className="shipping-summary__shipping-option" onClick={() => this.handleChangeShippingOption(shippingOption.id)}>
                      <div className="shipping-option__bullet">
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            checked={this.state.activePostageSchemeId === shippingOption.id}
                            id={`postage-option-${shippingOption.id}`}
                            readOnly
                          />
                          <div className="state">
                            <label></label>
                          </div>
                        </div>
                      </div>
                      <div className="shipping-option__text">
                        <span className="shipping-option__title">{shippingOption.description}</span>
                        <span className="shipping-option__description">
                          {shippingOption.service_description}
                        </span>
                      </div>
                      <div className="shipping-option__price"><Currency amount={shippingOption.cost} /></div>
                    </div>
                  ))}
                  {/* <div className="shipping-summary__extra-info">
                    <WarningOutlined style={{ color: "#fff", marginRight: '10px', fontSize: "20px", display: 'flex', alignSelf: 'center' }} />
                    <p>
                      We're expecting things to run a little slower with COVID, please allow up to 2 additional working days
                    </p>
                  </div> */}
                  <Button
                    theme="dark-blue"
                    label="Next"
                    block
                    onClick={this.handleDone}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ShippingSummary;
