function convertMillimetresToInches(mm) {
  return mm * 0.0393701;
}

export default function ({ width, height, dpi }) {
  const widthInInches = convertMillimetresToInches(parseFloat(width));
  const heightInInches = convertMillimetresToInches(parseFloat(height));
  const dimensions = `${Math.round(widthInInches * dpi)}x${Math.round(heightInInches * dpi)}`;
  // console.log("width", width);
  // console.log("height", height);
  // console.log("Dimensions", dimensions);
  return dimensions
};
