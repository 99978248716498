import React from "react";
//import themes from "./themes.json";
//import themes1 from "./themes1.json";
import colours from "./colours.json";
import effects from "./effects.json";
import "./themesContainer.scss";
import * as LAYER_TYPES from "../../constants/layer-types";

class BackgroundsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: props.item,
    };
  }

  changeBackground = (background) => {
    this.props.setActiveBackground(background)
  };

  render() {
    const { item } = this.props;
    let selectedItemBackground = item?.toJS().layers.find(layer => layer.type === LAYER_TYPES.PHOTO)?.config.background;
    let selectedBackground;
    let isEffect = false
    if(this.props.selectedBackground){
      selectedBackground = this.props.selectedBackground
    } else{
      selectedBackground = selectedItemBackground
    }

    if (selectedBackground?.effect){
      isEffect = true
    }
    
    const effectCategories = ["Gold", "Silver", "Rose", "Marble"]

    return (
      <React.Fragment>
        <div className="backgrounds-selection-container">
          <div className="background-info">
            <p>Choose an effect or colour for your retro print frame</p>
          </div>
          {/* {selectedColour && (
            <div className={`active-colour-swatch ${selectedColour === '#FFFFFF' ? 'bordered' : ''}`} style={{ backgroundColor: selectedColour }}></div>
          )} */}
          <h2 className="background-type-heading">Effects</h2>
          <div className="background-type-container">
            {effectCategories.map(category => (
              <div key={category} className="background-category">
                <h3 className="background-category-name">{category}</h3>
                <div className="background-swatches">
                  {effects.filter(effect => effect.category === category).map((effect, index) => (
                    <div
                      key={index}
                      onClick={() => this.changeBackground({effect: {name: effect.name, url: effect.link, id: effect.id}})}
                      className={`background-swatch-container ${isEffect ? selectedBackground.effect.url === effect.link ? 'active' : '' : ''}`}
                    >
                      <div
                        className="background-swatch"
                        style={{ background: `url(${effect.link})`, backgroundSize: 'cover' }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div class="background-type-divider"></div>
          <h2 className="background-type-heading">Colours</h2>
          <div className="background-type-container">
          <div className="background-swatches center">
            {colours.map((colour, index) => {
              return (
                <div
                  onClick={() => this.changeBackground({colour: colour})}
                  className={`background-swatch-container ${selectedBackground?.colour?.hex === colour.hex ? 'active' : ''}`}>
                  <div
                    className={`background-swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                    style={{ backgroundColor: colour.hex, color: colour?.textColor }}
                  >
                    {/* <span style={{color:colour.textColor}}>{colour.color}</span> */}
                  </div>
                </div>
                
            )})}
          </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BackgroundsContainer;
