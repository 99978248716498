import React, { Component } from "react";
import PropTypes from "prop-types";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import debounce from "lodash/debounce";
import gtmEvent from "../../utils/gtm";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Footer from "../Footer/Footer";
import MainContent from "../MainContent/MainContent";
import PhotoPrintsTextBand from "../PhotoPrintsTextBand/PhotoPrintsTextBand";
import { message, Progress } from "antd";

import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

import classNames from "classnames";

import "./EditorCropperModal.scss";

const PREVIEW_CROPPER_NODE_WIDTH = 500;

class EditorCropperModal extends Component {
  static propTypes = {
    imgUrl: PropTypes.string,
    highResImgUrl: PropTypes.string,
    ratio: PropTypes.number,
    isOpen: PropTypes.bool,
    disallowRotation: PropTypes.bool,
    hideRotation: PropTypes.bool,
    renderPhotoMagazineCoverBand: PropTypes.bool,
    renderPhotoPrintsTextBand: PropTypes.bool,
    photoMagazineCoverText: PropTypes.shape({
      coverColour: PropTypes.object,
    }),
    photoPrintsText: PropTypes.shape({
      text: PropTypes.string,
      font: PropTypes.string,
      color: PropTypes.string,
    }),
    onSave: PropTypes.func,
    onRotate: PropTypes.func,
    onClose: PropTypes.func,
    renderBleedBands: PropTypes.bool,
    productDimensions: PropTypes.object,
    isManuallyRotated: PropTypes.bool,
    showFaceGuides: PropTypes.bool,
    templateImage: PropTypes.bool,
    isPhotoBook: PropTypes.bool,
    returnImageUrl: PropTypes.bool,
    bleedLabel: PropTypes.string,
  };

  static defaultProps = {
    ratio: 1,
    onRotate: () => {},
    hideRotation: false,
    isManuallyRotated: false,
    disallowRotation: true,
    showFaceGuides: false,
    templateImage: false,
    isPhotoBook: false,
    bleedLabel: "BLEED AREA",
  };

  state = {
    dragMode: "crop",
    shouldShowLowResCropperWarning: false,
    loading: false,
    isThemeModalVisible: false,
    currentRes: null,
    rate: null,
    isSaving: false,
  };

  componentDidUpdate(prevProps) {
    const didOpen = !prevProps.isOpen && this.props.isOpen;
    const didClose = prevProps.isOpen && !this.props.isOpen;

    if (didOpen) {
      this.setState(
        {
          cachedCropData: null,
          loading: true,
          isSaving: false,
        },
        () => {
          gtmEvent({ event: "webAppStage", additionalProps: { stage: "Cropper Modal Shown" } });
        }
      );
      setTimeout(this.initCropper, 300);
    }
    if (prevProps.ratio !== this.props.ratio && this.cropper) {
      this.setAspectRatio(this.props.ratio);
    }

    if (didClose) {
      this.cropper && this.cropper.destroy();
    }
  }

  componentWillUnmount() {
    this.cropper && this.cropper.destroy();
  }

  showCropperMessage = () => {
    message.config({
      top: 58,
      duration: 5,
      maxCount: 3,
      key: "crop-warning",
    });
    message.info("Adjust the box to your preferred cropping", 4);
  };

  handleCropChange = () => {
    if (this.cropper) {
      const MIN_WIDTH_PROPORTION = 0.1;
      let cropData = this.cropper.getData();
      let cropDataTooSmall = false;
      const canvasData = this.cropper.getCanvasData();
      //console.log(cropData.width/canvasData.naturalWidth);

      // If the newly cropped region is too small (or more likely, user inadvertantly taps a 0-sized crop region)
      // We override the cropData adding some padding to prevent a rounding error causing a loop

      if (cropData.width / canvasData.naturalWidth < MIN_WIDTH_PROPORTION) {
        cropData.width = canvasData.naturalWidth * MIN_WIDTH_PROPORTION + 0.01;
        this.cropper.setData(cropData);
      }

      if (
        cropData.width.toFixed(2) ===
        (canvasData.naturalWidth * MIN_WIDTH_PROPORTION + 0.01).toFixed(2)
      ) {
        cropDataTooSmall = true;
      }

      //console.log(cropDataTooSmall);
      this.setState(
        {
          shouldShowLowResCropperWarning: cropDataTooSmall,
          cachedCropData: cropData,
        },
        () => {
          if (!this.state.loading) {
            //gtmEvent({ event: "webAppEvent", additionalProps: { eventName: "Crop Change" } });
          }
        }
      );
    }
  };

  initCropper = () => {
    //let cropperData = { x: 0, y: 0 }; // top-left
    let cropperData = {}; //center
    console.log("init cropper");

    if (this.props.cropData) {
      cropperData = this.props.cropData;
    }

    if (this.state.cachedCropData) {
      cropperData = this.state.cachedCropData;
    }
    const $cropperPreviewNode = document.getElementById("cropper-preview");

    this.cropper = new Cropper(this.cropperImgElement, {
      aspectRatio: this.props.ratio,
      data: cropperData,
      viewMode: 1,
      preview: $cropperPreviewNode,
      minCropBoxWidth: 50,
      background: false,
      strict: true,
      guides: true,
      autoCropArea: 1,
      responsive: true,
      zoomable: false,
      dragMode: "crop",
      ready: () => {
        setTimeout(() => {
          this.setState({ loading: false });
          //window.addEventListener("resize", this.fitAdditionalArtefacts());
        }, 200);

        if (this.props.isManuallyRotated) {
          this.cropper.rotate(90);
          this.fitCanvasToContainer();
          this.fitCropBoxToCanvas(cropperData);
        }

        if (
          this.props.isPhotoBook &&
          (Object.keys(cropperData).length === 0 || cropperData == null)
        ) {
          const cropBoxData = this.cropper.getCropBoxData();
          cropBoxData.top = 0;
          this.cropper.setCropBoxData(cropBoxData);
        }

        if (
          cropperData &&
          (cropperData.rotate === 90 || cropperData.rotate === -90 || cropperData.rotate === 270)
        ) {
          console.log("Rotated ... fitting to container and canvas");
          //this.fitToContainer();
          this.fitCanvasToContainer();
          this.fitCropBoxToCanvas(cropperData);
          //this.fitCropBoxToCanvas();
        }

        if (!cropperData) {
          this.fitCanvasToContainer();
          this.fitCropBoxToCanvas();
        }

        // We have to add a slight timeout before we position the photo band, otherwise the modal animation will mess
        // up the initial positioning.
        setTimeout(() => {
          this.fitAdditionalArtefacts();
        }, 350);
        //this.fitPhotoMagazineCoverBandToCropperBox();

        //this.showCropperMessage();
      },
      crop: debounce(this.handleCropChange, 100),
      cropmove: this.fitAdditionalArtefacts,
      modal: true,
      highlight: false,
    });

    this.setAspectRatio(this.props.ratio);
  };

  handleImageSize = size => {
    const containerData = this.cropper.getContainerData();
    console.log(containerData);
    if (size === "fit") {
      this.cropper.setCropBoxData({
        height: containerData.height,
        width: containerData.width,
      });
      const cropperData = this.cropper.getCropBoxData();
      this.cropper.setCropBoxData({
        left: (containerData.width - cropperData.width) / 2,
      });
      this.cropper.setCanvasData({
        height: containerData.height,
        top: 0,
      });
      const imageData = this.cropper.getImageData();
      this.cropper.setCanvasData({
        left: containerData.width / 2 - imageData.width / 2,
      });
      if (imageData.aspectRatio > 1) {
        this.cropper.setCanvasData({
          width: cropperData.width,
          left: containerData.width / 2 - cropperData.width / 2,
          top: containerData.height / 4,
        });
      }
    } else {
      const imageData = this.cropper.getImageData();
      const canvasData = this.cropper.getCanvasData();
      console.log(imageData.width, canvasData.width, containerData.width, imageData.naturalWidth);
      console.log(imageData, canvasData, containerData);
      const scaledImageWidth =
        (containerData.height / imageData.naturalHeight) * imageData.naturalWidth;
      this.cropper.setCropBoxData({
        // height: imageData.height,
        // width: canvasData.width,
        width: scaledImageWidth,
        top: containerData.height / 4,
      });
      const cropperData = this.cropper.getCropBoxData();
      this.cropper.setCropBoxData({
        left: containerData.width / 2 - cropperData.width / 2,
      });
      // const canvasData = this.cropper.getCanvasData();
      var scaleWidth = canvasData.naturalWidth / containerData.width;
      var scaleHeight = canvasData.naturalHeight / containerData.height;
      var scale = Math.max(scaleWidth, scaleHeight);
      var newWidth = canvasData.naturalWidth / scale;
      var newHeight = canvasData.naturalHeight / scale;
      var newLeft = (containerData.width - newWidth) / 2;
      this.cropper.setCanvasData({
        height: newHeight,
        top: 0,
      });
      // const imageData = this.cropper.getImageData();
      this.cropper.setCanvasData({
        left: newLeft,
      });
      // this.cropper.zoomTo(0.6);
    }
    this.fitAdditionalArtefacts();
  };

  setAspectRatio = ratio => {
    //console.log("setAspectRatio:", ratio);
    const $cropperPreviewNode = document.getElementById("cropper-preview");
    $cropperPreviewNode.style.width = `${PREVIEW_CROPPER_NODE_WIDTH}px`;
    $cropperPreviewNode.style.height = `${PREVIEW_CROPPER_NODE_WIDTH * ratio}px`;
    this.cropper.setAspectRatio(ratio);
  };

  rateOutOfTen = (original, given) => {
    console.log(original, given);
    if (original > given) return 10 - ((original - given) / original) * 10;
    return 10;
  };

  fitAdditionalArtefacts = () => {
    this.fitPhotoPrintsTextBandToCropperBox();
    this.fitBleedBands();
  };

  fitBleedBands = () => {
    if (!this.bleedBandsContainer) {
      return;
    }
    const { left, top, width, height } = this.cropperContainerElement
      .querySelector(".cropper-crop-box")
      .getBoundingClientRect();

    Object.assign(this.bleedBandsContainer.style, {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      opacity: "1",
    });
  };

  fitPhotoMagazineCoverBandToCropperBox = () => {
    if (!this.photoMagazineCoverBandContainer) {
      return;
    }

    const { left, top, width, height } = this.cropperContainerElement
      .querySelector(".cropper-crop-box")
      .getBoundingClientRect();

    Object.assign(this.photoMagazineCoverBandContainer.style, {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      opacity: "1",
    });

    this.photoMagazineCoverBandContainer.querySelector(
      ".photo-magazine-cover-band"
    ).style.fontSize = `${height * 0.03}px`;
  };

  fitPhotoPrintsTextBandToCropperBox = () => {
    if (!this.photoPrintsTextBandContainer) {
      return;
    }

    const { left, top, width, height } = this.cropperContainerElement
      .querySelector(".cropper-crop-box")
      .getBoundingClientRect();

    Object.assign(this.photoPrintsTextBandContainer.style, {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      opacity: "1",
    });

    this.photoPrintsTextBandContainer.querySelector(
      ".photo-prints-text-band"
    ).style.fontSize = `${height * 0.065}px`;
  };

  fitCroppedImageToCropper = () => {
    console.log("Fitting image to cropper");
    // Most of this code is taken from http://stackoverflow.com/a/32938693
    let contData = this.cropper.getContainerData();
    this.cropper.setCropBoxData({
      width: 2,
      height: 2,
      top: contData.height / 2 - 1,
      left: contData.width / 2 - 1,
    });

    let canvData = this.cropper.getCanvasData();
    const newWidth = canvData.width * (contData.height / canvData.height);

    let newCanvData;

    if (newWidth >= contData.width) {
      const newHeight = canvData.height * (contData.width / canvData.width);
      newCanvData = {
        height: newHeight,
        width: contData.width,
        top: (contData.height - newHeight) / 2,
        left: 0,
      };
    } else {
      newCanvData = {
        height: contData.height,
        width: newWidth,
        top: 0,
        left: (contData.width - newWidth) / 2,
      };
    }

    try {
      this.cropper.setCanvasData(newCanvData);
    } catch (err) {
      console.log("Error while setting new canvas data:", err);
    }
    this.cropper.render();
    if (!this.props.cropData) {
      this.cropper.setCropBoxData(newCanvData);
    } else {
      console.log("Adjusting crop using existing crop data");
      // let contData = this.cropper.getContainerData();
      let canvData = this.cropper.getCanvasData();
      let scale;
      //let newCanvData;

      if (canvData.height < contData.height) {
        console.log("Portrait photo rotated to landscape...");

        scale = canvData.height / contData.height;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: this.props.cropData.y * scale + canvData.top,
          left: this.props.cropData.x * scale,
        };
      } else {
        console.log("Landscape photo rotated to portrait...");
        scale = contData.height / canvData.height;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: this.props.cropData.y * scale,
          left: this.props.cropData.x * scale + canvData.left,
        };
      }
      setTimeout(() => {
        this.cropper.setCropBoxData(newCanvData);
      }, 10);
    }
  };

  handleClickSave = () => {
    this.setState(
      {
        isSaving: true,
      },
      () => {
        if (this.props.isManuallyRotated) {
          this.cropper.rotate(0);
          this.cropper.render();
        }

        const $previewContainer = document.getElementById("cropper-preview");
        const $previewImg = $previewContainer.firstChild;
        const imgStyles = window.getComputedStyle($previewImg);
        let transformMatrix = null;

        if (imgStyles.transform !== "none") {
          transformMatrix = /matrix\((.*)\)/g
            .exec(imgStyles.transform)[1]
            .split(", ")
            .map(v => Number(v));
        }
        const transform = {
          containerWidth: $previewContainer.offsetWidth,
          containerHeight: $previewContainer.offsetHeight,
          width: parseFloat(imgStyles.width),
          height: parseFloat(imgStyles.height),
          translateX: transformMatrix ? transformMatrix[4] : 0,
          translateY: transformMatrix ? transformMatrix[5] : 0,
          xPercent: this.cropper.getData().x / this.cropper.getImageData().naturalHeight,
          yPercent: this.cropper.getData().y / this.cropper.getImageData().naturalWidth,
        };
        if (this.props.templateImage) {
          this.props.onSaveTemplatePhoto(
            {
              ...this.cropper.getData(),
              transform,
            },
            this.props.templatePhotoEdit.pageNumber,
            this.props.templatePhotoEdit.placeholderId
          );
        }
        this.props.onSave({
          ...this.cropper.getData(),
          ...(this.props.returnImageUrl
            ? { croppedUrl: this.cropper.getCroppedCanvas().toDataURL() }
            : {}),
          transform,
        });
      }
    );
  };

  fitToContainer = () => {
    let adata = this.cropper.getCanvasData();
    const cdata = this.cropper.getContainerData();
    adata.height = (adata.height * cdata.width) / adata.width;
    adata.width = cdata.width;
    adata.left = 0;
    adata.top = (cdata.height - adata.height) / 2;
    if (adata.height > cdata.height) {
      adata.width = (adata.width * cdata.height) / adata.height;
      adata.height = cdata.height;
      adata.top = 0;
      adata.left = (cdata.width - adata.width) / 2;
    }
    const shrink_by = this.cropper.options.autoCropArea;
    this.cropper.setCanvasData({
      top: adata.top,
      left: adata.left,
      width: adata.width,
      height: adata.height,
    });
    this.cropper.setCropBoxData({
      top: adata.top + (adata.height * (1 - shrink_by)) / 2,
      left: adata.left + (adata.width * (1 - shrink_by)) / 2,
      height: adata.height * shrink_by,
      width: adata.width * shrink_by,
    });
  };

  fitCanvasToContainer = () => {
    var containerData = this.cropper.getContainerData();
    //console.log('containerData: ', containerData);
    var canvasData = this.cropper.getCanvasData();
    //console.log('canvasData: ', canvasData);

    var scaleWidth = canvasData.naturalWidth / containerData.width;
    var scaleHeight = canvasData.naturalHeight / containerData.height;
    var scale = Math.max(scaleWidth, scaleHeight);
    var newWidth = canvasData.naturalWidth / scale;
    var newHeight = canvasData.naturalHeight / scale;
    var newLeft = (containerData.width - newWidth) / 2;
    var newTop = (containerData.height - newHeight) / 2;

    var newCanvasData = {
      width: newWidth,
      height: newHeight,
      left: newLeft,
      top: newTop,
    };
    this.cropper.options.viewMode = 0;
    this.cropper.setCropBoxData({
      width: 4,
      height: 3,
      left: containerData.width / 2,
      top: containerData.height / 2,
    });
    this.cropper.setCanvasData(newCanvasData);
    this.cropper.options.viewMode = 1; //2
  };

  fitCropBoxToCanvas = cropperData => {
    if (!cropperData) {
      this.cropper.crop();

      var cropBoxData = this.cropper.getCropBoxData();
      var canvasData = this.cropper.getCanvasData();
      //console.log('fitCropBoxToCanvas canvasData: ', canvasData);
      //console.log('fitCropBoxToCanvas: cropBoxData', cropBoxData);

      var scaleWidth = cropBoxData.width / canvasData.width;
      var scaleHeight = cropBoxData.height / canvasData.height;
      var scale = Math.max(scaleWidth, scaleHeight) / this.cropper.options.autoCropArea;
      var newWidth = cropBoxData.width / scale;
      var newHeight = cropBoxData.height / scale;
      var newLeft = (canvasData.width - newWidth) / 2 + canvasData.left;
      var newTop = (canvasData.height - newHeight) / 2 + canvasData.top;

      var newCropBox = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop,
      };
      //console.log('fitCropBoxToCanvas: newCropBox', newCropBox);
      this.cropper.setCropBoxData(newCropBox);
    } else {
      let contData = this.cropper.getContainerData();
      let canvData = this.cropper.getCanvasData();

      let newCanvData;

      console.log("Adjusting crop using existing crop data");
      let scale;

      if (canvData.height < contData.height) {
        console.log("Portrait photo rotated to landscape...");

        scale = canvData.height / canvData.naturalHeight;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: this.props.cropData.y * scale + canvData.top,
          left: this.props.cropData.x * scale + canvData.left,
        };
      } else {
        console.log("Landscape photo rotated to portrait...");
        scale = canvData.width / canvData.naturalWidth;
        newCanvData = {
          height: this.props.cropData.height * scale,
          width: this.props.cropData.width * scale,
          top: this.props.cropData.y * scale,
          left: this.props.cropData.x * scale + canvData.left,
        };
      }
      setTimeout(() => {
        this.cropper.setCropBoxData(newCanvData);
      }, 10);
    }
  };

  rotateCroppedImage = rotation => {
    //this.cropper.rotate(rotation); //
    this.fitCanvasToContainer();
    this.fitCropBoxToCanvas();
    console.log("Cropper rotate", this.cropper.getData().rotate);
    this.props.onRotate(rotation); //this.cropper.getData().rotate
    //this.forceUpdate();
    this.fitAdditionalArtefacts();
    setTimeout(() => {
      this.fitBleedBands();
    }, 350);
  };

  rotateOnlyImage = rotation => {
    this.cropper.rotate(rotation);
    this.cropper.render();
    //this.fitCroppedImageToCropper();
    this.fitCanvasToContainer();
    this.fitCropBoxToCanvas();
    this.fitAdditionalArtefacts();
  };

  zoomCroppedImage = zoomLevel => {
    this.cropper.zoom(zoomLevel);
  };

  setDragMode = dragMode => {
    console.log("Setting drag mode:", dragMode);
    this.cropper.setDragMode(dragMode);
    this.setState({
      dragMode,
    });
  };

  closeThemesModal = () => {
    this.setState({
      isThemeModalVisible: false,
    });
  };

  render() {
    const modalCropperInfoClasses = classNames("editor-modal__cropper-info", {
      "animated fadeIn": this.props.isOpen,
    });

    const modalCropperClasses = classNames("editor-modal__cropper", {
      "animated fadeIn": this.props.isOpen,
    });

    const productDimensions = this.props.productDimensions;
    let bleed = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };

    let relativeBleed = bleed;
    const aspectRatio = this.props.ratio;

    if (productDimensions && this.props.isOpen) {
      const height =
        productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom;
      const width =
        productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right;

      bleed = {
        top: productDimensions.bleed.top * (100.0 / height),
        bottom: productDimensions.bleed.bottom * (100.0 / height),
        right: productDimensions.bleed.right * (100.0 / width),
        left: productDimensions.bleed.left * (100.0 / width),
      };

      relativeBleed = bleed;

      if (aspectRatio > 1) {
        // landscape
        relativeBleed = {
          top: relativeBleed.left,
          bottom: relativeBleed.right,
          right: relativeBleed.top,
          left: relativeBleed.bottom,
        };
      }
    }

    return (
      <Modal
        title={this.props.disallowRotation ? "Crop and Zoom" : "Crop, Zoom & Rotate"}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        //rightAction={<Button priority="tertiary" label="Save" onClick={this.handleClickSave} dataGtmElement="Apply Crop Button Header"/>}
      >
        <MainContent
          scrollable={false}
          padded={true}
          style={{ maxHeight: this.props.renderEditor ? "75vh" : "auto" }}
        >
          {this.state.rate && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                fontWeight: "bolder",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "15px",
                flexDirection: "column",
                marginTop: "-20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  fontWeight: "normal",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                  width: "100%",
                }}
              >
                <span>Quality: </span>
                <Progress
                  percent={this.state.rate * 10}
                  showInfo={false}
                  strokeColor={{
                    "0%": "grey",
                    "100%": "grey",
                  }}
                />
                <span>{this.state.rate.toFixed(0)}/10</span>
              </div>
              {/* {this.state.currentRes && (
                <p>
                  Resolution: {this.state.currentRes.height}*{this.state.currentRes.width}
                </p>
              )} */}
            </div>
          )}
          <React.Fragment>
            {this.state.loading && (
              <FullScreenLoader key="loader" isVisible={true} message={"Loading your photo..."} />
            )}

            <div className={modalCropperClasses} style={{ height: "90%", padding: "0px 15px" }}>
              <div className="cropper-wrapper" ref={el => (this.cropperContainerElement = el)}>
                <img src={this.props.imgUrl} ref={el => (this.cropperImgElement = el)} alt="" />
              </div>
              {/* {(this.props.renderBleedBands && this.props.isOpen) && (
                // Inside cropper-crop-box
                <div class="cropper-crop-box__bleed-wrapper">
                  <div class="cropper-crop-box__bleed"
                    style={{
                      left: '3.571428571428571%',
                      top: '3.571428571428571%',
                      width:  '92.85714285714286%',
                      height: '92.85714285714286%'
                    }}
                  ></div>
                </div>
              )} */}
              {this.props.showFaceGuides && (
                <div
                  className="cropper-face-guides"
                  style={{
                    top: "10%",
                    left: "20%",
                    width: "60%",
                    height: "60%",
                    borderStyle: "solid",
                    borderWidth: "5px 0px 5px 0px",
                    borderColor: "#0033CC",
                    position: "absolute",
                    display: "block",
                    opacity: ".5",
                  }}
                  ref={el => (this.faceGuide = el)}
                />
              )}
              {this.props.renderBleedBands && this.props.isOpen && (
                <div
                  className="cropper-bleed-bands-container"
                  style={{
                    position: "fixed",
                    pointerEvents: "none",
                    opacity: 0,
                    transition: "opacity 0.2s",
                  }}
                  ref={el => (this.bleedBandsContainer = el)}
                >
                  <div
                    className="cropper-bleed-band cropper-bleed-band-top"
                    style={{
                      width: `${100 - relativeBleed.left * 2}%`,
                      position: "absolute",
                      top: "0",
                      left: `${relativeBleed.left}%`,
                      height: `${relativeBleed.top}%`,
                    }}
                  >
                    <span className="cropper-bleed-band--text cropper-bleed-band--text-horizontal">
                      {this.props.bleedLabel}
                    </span>
                  </div>
                  <div
                    className="cropper-bleed-band cropper-bleed-band-right"
                    style={{
                      width: `${relativeBleed.right}%`,
                      position: "absolute",
                      right: "0",
                      height: "100%",
                    }}
                  >
                    <span
                      style={{ writingMode: "vertical-rl" }}
                      className="cropper-bleed-band--text cropper-bleed-band--text-vertical"
                    >
                      {this.props.bleedLabel}
                    </span>
                  </div>
                  <div
                    className="cropper-bleed-band cropper-bleed-band-bottom"
                    style={{
                      width: `${100 - relativeBleed.left * 2}%`,
                      position: "absolute",
                      bottom: "0",
                      left: `${relativeBleed.left}%`,
                      height: `${relativeBleed.bottom}%`,
                    }}
                  >
                    <span className="cropper-bleed-band--text cropper-bleed-band--text-horizontal">
                      {this.props.bleedLabel}
                    </span>
                  </div>
                  <div
                    className="cropper-bleed-band cropper-bleed-band-left"
                    style={{
                      width: `${relativeBleed.left}%`,
                      position: "absolute",
                      left: "0",
                      height: "100%",
                    }}
                  >
                    <span
                      style={{ writingMode: "vertical-lr", transform: "rotateZ(180deg)" }}
                      className="cropper-bleed-band--text cropper-bleed-band--text-vertical"
                    >
                      {this.props.bleedLabel}
                    </span>
                  </div>
                </div>
              )}
              {this.props.renderPhotoPrintsTextBand && this.props.photoPrintsText && (
                <div
                  style={{
                    position: "fixed",
                    pointerEvents: "none",
                    opacity: 0,
                    transition: "opacity 0.2s",
                  }}
                  ref={el => (this.photoPrintsTextBandContainer = el)}
                >
                  <PhotoPrintsTextBand
                    text={this.props.photoPrintsText.text}
                    color={this.props.photoPrintsText.color}
                    font={this.props.photoPrintsText.font}
                  />
                </div>
              )}
            </div>
            <p
              className="text-center text-warning"
              style={{
                background: "#ff5a5f",
                color: "white",
                fontSize: "14px",
                fontWeight: "500",
                marginBottom: 0,
                display: this.state.shouldShowLowResCropperWarning ? "block" : "none",
              }}
            >
              <small>The selected area is very small — it may look blurry when printed!</small>
            </p>
            <div className={modalCropperInfoClasses}>
              <p>
                {/* Adjust the box to your preferred cropping */}
                Drag the box to set the area you want to print or drag the grey circle to adjust the
                zoom.
              </p>
            </div>
          </React.Fragment>
        </MainContent>
        <Footer>
          <React.Fragment>
            <div className="cropper-footer-content">
              <div className="cropper-footer-content--rotation-btns">
                {this.props.hideRotation === false &&
                  (this.props.disallowRotation ? (
                    <div className="cropper-buttons-container center">
                      <div className="cropper-buttons-group-container">
                        <p className="cropper-buttons-group-info">Rotate Photos:</p>
                        <div className="cropper-buttons-group">
                          <Button
                            onClick={() => this.rotateOnlyImage(-90)}
                            label="Left"
                            size="small"
                            icon="rotate-ccw"
                            iconPosition="right"
                            dataGtmElement="Rotate Photo Left Button"
                            priority="secondary"
                            theme="muted"
                          />
                          <Button
                            label="Right"
                            onClick={() => this.rotateOnlyImage(90)}
                            size="small"
                            icon="rotate-cw"
                            dataGtmElement="Rotate Photo Right Button"
                            priority="secondary"
                            theme="muted"
                          />
                        </div>
                      </div>
                      {/* <div className="cropper-buttons-group-container">
                        <p className="cropper-buttons-group-info">Image Size:</p>
                        <div className="cropper-buttons-group">
                          <AntButton
                            icon={<FullscreenOutlined />}
                            onClick={() => this.handleImageSize("fit")}
                          />
                          <AntButton
                            icon={<FullscreenExitOutlined />}
                            onClick={() => this.handleImageSize("cover")}
                          />
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="cropper-buttons-container">
                      <div className="cropper-buttons-group-container">
                        <p className="cropper-buttons-group-info">Rotate Crop:</p>
                        <div className="cropper-buttons-group">
                          <Button
                            label="Left"
                            onClick={() => this.rotateCroppedImage(-90)}
                            size="small"
                            icon="rotate-left"
                            iconPosition="right"
                            priority="secondary"
                            theme="muted"
                            dataGtmElement="Rotate Crop Left Button"
                          />
                          <Button
                            label="Right"
                            onClick={() => this.rotateCroppedImage(90)}
                            size="small"
                            icon="rotate-right"
                            priority="secondary"
                            theme="muted"
                            dataGtmElement="Rotate Crop Right Button"
                          />
                        </div>
                      </div>
                      <div className="cropper-buttons-group-container">
                        <p className="cropper-buttons-group-info">Rotate Photo:</p>
                        <div className="cropper-buttons-group">
                          <Button
                            onClick={() => this.rotateOnlyImage(-90)}
                            label="Left"
                            size="small"
                            icon="rotate-ccw"
                            iconPosition="right"
                            priority="secondary"
                            theme="muted"
                            dataGtmElement="Rotate Crop Left Button"
                          />
                          <Button
                            label="Right"
                            onClick={() => this.rotateOnlyImage(90)}
                            size="small"
                            icon="rotate-cw"
                            priority="secondary"
                            theme="muted"
                            dataGtmElement="Rotate Crop Right Button"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="cropper-footer-content--actions">
                <div className="cropper-footer-content--replace-img">
                  {this.props.onHandleReplaceImage && (
                    <Button
                      onClick={
                        this.props.templateImage
                          ? this.props.onHandleReplaceImageInTemplate
                          : this.props.onHandleReplaceImage
                      }
                      label={"Change Photo"}
                      size="small"
                      dataGtmElement="Crop Change Photo Button"
                    />
                  )}
                </div>
                <div className="cropper-footer-content--save-btn">
                  <Button
                    onClick={this.handleClickSave}
                    label={"Apply"}
                    size="small"
                    theme="dark-blue"
                    priority="primary"
                    dataGtmElement="Apply Crop Button Footer"
                    loading={this.state.isSaving}
                    loadingLabel="Applying"
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Footer>
      </Modal>
    );
  }
}

export default EditorCropperModal;
