import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gtmEvent from "../../utils/gtm";
//import { getAppValueByKey, getAppValueByKeyAndLocale } from "../../data/app-values";
import { FONT_FAMILIES, CAPTION_FONT_FAMILIES } from "../../constants/text-style-options";
import { fromJS } from "immutable";
import MASKS from "../../constants/masks";
import { REDUCED_FILL_OPTIONS_SORTED, CAPTION_TEXT_COLORS } from "../../constants/fill-options";
import SignaturePad from "react-signature-canvas";
import { isTextPrintProduct } from "../../data/products";
import {
  CloseCircleOutlined,
  EditOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  UndoOutlined,
  UpOutlined,
  CloseOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import {
  notification,
  Button as AntButton,
  Modal as AntModal,
  Input,
  Row,
  Col,
  Table,
  Tabs,
  Radio,
} from "antd";
import * as LAYER_TYPES from "../../constants/layer-types";
import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";
import Footer from "../Footer/Footer";
import Grid from "../Grid/Grid";
import BackgroundModalContainer from "../BackgroundModal";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import EditorFontOption from "../Editor/EditorFontOption";
import EditorFillOption from "../Editor/EditorFillOption";
import ColouredRetros from "./ColouredRetros";
import NormalPrints from "./NormalPrints";

//import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Currency from "../Formatters/Currency";
//import Modal from "../Modal/Modal";
import Icon from "../Icon/Icon";
import QueueAnim from "rc-queue-anim";
import "./PhotoPrintsPreBag.scss";
import $ from "jquery";

const TEXT_LINE_MAX_LENGTH = 35;
const CAPTION_HEADING_MAX_LENGTH = 40;
const CAPTION_SUBHEADING_MAX_LENGTH = 50;
const { TextArea } = Input;
const { TabPane } = Tabs;

const ButtonGroup = AntButton.Group;

const DEFAULT_TEXT = "Open Sans";
const DEFAULT_COLOUR = "RGB(63, 68, 82)"; //postsnap blue //"rgb(0,0,0)"
const DEFAULT_WEIGHT = "normal";
const DEFAULT_ALIGNMENT = "center";

const PEN_SIZES = {
  small: { minWidth: 0.5, maxWidth: 2, dotSize: 2 },
  medium: { minWidth: 0.5, maxWidth: 3, dotSize: 2 },
  large: { minWidth: 0.5, maxWidth: 5, dotSize: 2 },
};

const PhotoPrintsPreBag = ({
  product,
  items,
  totalQuantity,
  costPerPrint,
  lowestCostPerPrint,
  nextCostPerPrint,
  pricingTable,
  nextPricingSchemeQuantity,
  nextPricingSchemeAvailable,
  showTooltip,
  onCancel,
  onStartUpload,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  onDeleteItem,
  onChangeTextForItem,
  onChangeDrawingForItem,
  onChangeBackgroundForItem,
  onChangeBackgroundForAllItems,
  onClickNext,
  onClickItem,
  onChangeProductOption,
  onChangeBorderApplied,
  multiLineText,
  captionText,
  isCaptionPrint,
  isColouredRetro,
  currency,
  hasMinimumQuantity,
  minimumQuantity,
  realBorders,
  finishOptionsAvailable,
  borderOptionAvailable,
  effectsAvailable,
  onRotateItem,
  showInfoMessage,
  onInfoMessageDismissed,
  showCaptionModal,
  showCaptionModalForItem,
  isEditModalOpen,
  editModalHasClosed,
}) => {
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const [isKeyFeaturesModalVisible, setIsKeyFeaturesModalVisible] = useState(false);
  const [isLowResInfoModalVisible, setIsLowResInfoModalVisible] = useState(false);
  const [isPricingBandVisible, setIsPricingBandVisible] = useState(true);
  const [isLowResPopoverVisible, setLowResPopoverVisible] = useState(false);
  const [isTextInputModalVisible, setIsTextInputModalVisible] = useState(showCaptionModal);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const [isBackgroundModalVisible, setIsBackgroundModalVisible] = useState(false);
  const [backgroundModalActiveForItem, setBackgroundModalActiveForItem] = useState(null);

  const [deletePopoverActiveForItem, setDeletePopoverActiveForItem] = useState(null);

  const [textModalActiveForItem, setTextModalActiveForItem] = useState(showCaptionModalForItem);
  const [previewModalActiveForItem, setPreviewModalActiveForItem] = useState(null);

  const [textDraft, setTextDraft] = useState("");
  const [captionHeading, setCaptionHeading] = useState("");
  const [captionSubheading, setCaptionSubheading] = useState("");

  const [captionHeadingCharCount, setCaptionHeadingCharCount] = useState(0);
  const [captionSubheadingCharCount, setCaptionSubheadingCharCount] = useState(0);

  const [textColor, setTextColor] = useState(DEFAULT_COLOUR);
  const [captionHeadingTextColor, setCaptionHeadingTextColor] = useState(DEFAULT_COLOUR);
  const [captionSubheadingTextColor, setCaptionSubheadingTextColor] = useState(DEFAULT_COLOUR);

  const [hasCustomisedSubheading, setHasCustomisedSubheading] = useState(false);

  const [textFont, setTextFont] = useState(DEFAULT_TEXT);
  const [captionHeadingFont, setCaptionHeadingFont] = useState(DEFAULT_TEXT);
  const [captionSubheadingFont, setCaptionSubheadingFont] = useState(DEFAULT_TEXT);

  const [captionHeadingAlignment, setCaptionHeadingAlignment] = useState(DEFAULT_ALIGNMENT);
  const [captionSubheadingAlignment, setCaptionSubheadingAlignment] = useState(DEFAULT_ALIGNMENT);

  const [captionHeadingWeight, setCaptionHeadingWeight] = useState(DEFAULT_WEIGHT);
  const [captionSubheadingWeight, setCaptionSubheadingWeight] = useState(DEFAULT_WEIGHT);

  const [activeInputTool, setActiveInputTool] = useState("text");

  const [captionHeadingFontToolActive, setCaptionHeadingFontToolActive] = useState(true);
  const [captionHeadingColorToolActive, setCaptionHeadingColorToolActive] = useState(false);

  const [captionSubheadingFontToolActive, setCaptionSubheadingFontToolActive] = useState(true);
  const [captionSubheadingColorToolActive, setCaptionSubheadingColorToolActive] = useState(false);

  // eslint-disable-next-line
  const [drawingURL, setDrawingURL] = useState(null);
  const [drawingData, setDrawingData] = useState(null);
  // eslint-disable-next-line
  const [drawingAspectRatio, setDrawingAspectRatio] = useState([]);
  const [hasResizedCanvas, setHasResizedCanvas] = useState(false);
  const [drawingAreaHeight, setDrawingAreaHeight] = useState(100);
  const [drawingPlaceholderVisible, setDrawingPlaceholderVisible] = useState(true);
  const [penSize, setPenSize] = useState("medium");
  const drawingRef = useRef(false);

  useEffect(() => {
    setIsTextInputModalVisible(showCaptionModal);
  }, [showCaptionModal]);

  useEffect(() => {
    setTextModalActiveForItem(showCaptionModalForItem);
  }, [showCaptionModalForItem]);

  // useEffect(() => {
  //   setTextModalActiveForItem(showCaptionModalForItem);
  // }, [textModalActiveForItem]);

  useEffect(() => {
    const activeItem = items.find(item => item.get("id") === textModalActiveForItem);
    if (activeItem) {
      const indexOfCaptionHeadingLayer = activeItem
        .get("layers")
        .findIndex(
          layer =>
            layer.get("type") === LAYER_TYPES.TEXT &&
            layer.get("id") !== "EXTRA_TEXT_LAYER" &&
            layer.getIn(["config", "captionHeading"]) === true
        );
      const itemCaptionHeadingConfig = activeItem
        .getIn(["layers", indexOfCaptionHeadingLayer, "config"])
        .toJS();

      const indexOfCaptionSubheadingLayer = activeItem
        .get("layers")
        .findIndex(
          layer =>
            layer.get("type") === LAYER_TYPES.TEXT &&
            layer.get("id") !== "EXTRA_TEXT_LAYER" &&
            layer.getIn(["config", "captionSubheading"]) === true
        );
      const itemCaptionSubheadingConfig = activeItem
        .getIn(["layers", indexOfCaptionSubheadingLayer, "config"])
        .toJS();

      /*text: captionHeading,
      color: captionHeadingTextColor,
      font: captionHeadingFont,
      alignment: captionHeadingAlignment,
      weight: captionHeadingWeight,
      size: 15,
      captionText: true,
      captionHeading: true
      */
      if (indexOfCaptionHeadingLayer >= 0) {
        setCaptionHeading(itemCaptionHeadingConfig.text);
        setCaptionHeadingTextColor(itemCaptionHeadingConfig.color);
        setCaptionHeadingFont(itemCaptionHeadingConfig.font);
        setCaptionHeadingWeight(itemCaptionHeadingConfig.weight);
        setCaptionHeadingAlignment(itemCaptionHeadingConfig.alignment);
      } else {
        setCaptionHeading("");
        setCaptionHeadingTextColor(DEFAULT_COLOUR);
        setCaptionHeadingFont(DEFAULT_TEXT);
        setCaptionHeadingWeight(DEFAULT_WEIGHT);
        setCaptionHeadingAlignment(DEFAULT_ALIGNMENT);
        //setIsTextInputModalVisible(true);
      }

      if (indexOfCaptionSubheadingLayer >= 0) {
        setCaptionSubheading(itemCaptionSubheadingConfig.text);
        setCaptionSubheadingTextColor(itemCaptionSubheadingConfig.color);
        setCaptionSubheadingFont(itemCaptionSubheadingConfig.font);
        setCaptionSubheadingWeight(itemCaptionSubheadingConfig.weight);
        setCaptionSubheadingAlignment(itemCaptionSubheadingConfig.alignment);
      } else {
        setCaptionSubheading("");
        setCaptionSubheadingTextColor(DEFAULT_COLOUR);
        setCaptionSubheadingFont(DEFAULT_TEXT);
        setCaptionSubheadingWeight(DEFAULT_WEIGHT);
        setCaptionSubheadingAlignment(DEFAULT_ALIGNMENT);
        //setIsTextInputModalVisible(true);
      }
    }
  }, [textModalActiveForItem, items]);

  const handleChangeProductOption = (el, option, value) => {
    document.activeElement.blur();
    onChangeProductOption(option, value);
  };

  const handleBorderToggle = (checked, event) => {
    //console.log("Border switch is", checked)
    onChangeBorderApplied(checked);
  };

  const handleClickNext = () => {
    const notMinimumQuantity = hasMinimumQuantity ? totalQuantity % minimumQuantity !== 0 : false;

    if (notMinimumQuantity) {
      let remainingNeeded = 0;
      let messageContent = "";
      if (totalQuantity > minimumQuantity) {
        const removeQty = totalQuantity % minimumQuantity;
        const addMoreQty = minimumQuantity - removeQty;
        messageContent = (
          <p style={{ fontSize: "14px" }} data-gtm-element="Minimum Quantity Notification">
            <span>
              {`Mini prints are sold in packs of ${minimumQuantity}.`}
              <br />
              Please add{` `}
              <span style={{ color: "#4b5566", fontWeight: "bold" }}>{addMoreQty}</span> to complete
              your pack, or delete{" "}
              <span style={{ color: "#4b5566", fontWeight: "bold" }}>{removeQty}</span> to proceed.
            </span>
          </p>
        );
      } else {
        remainingNeeded = minimumQuantity - totalQuantity;
        messageContent = (
          <p style={{ fontSize: "14px" }} data-gtm-element="Minimum Quantity Notification">
            <span>
              {`Mini prints are sold in packs of ${minimumQuantity}.`}
              <br />
              Please add{` `}
              <span style={{ color: "#4b5566", fontWeight: "bold" }}>{remainingNeeded}</span> to
              complete your first pack.
            </span>
          </p>
        );
      }

      notification.open({
        message: "Not enough prints...",
        description: messageContent,
        icon: <InfoCircleOutlined style={{ color: "#4b5566" }} />,
        onClick: () => {
          console.log("Notification Clicked!");
        },
        duration: 7,
      });

      gtmEvent({
        event: "webAppEvent",
        additionalProps: { eventName: "Minimum Quantity Alert Shown" },
      });
    } else {
      onClickNext();
    }
  };

  // const currencyToLocaleMappings = {
  //   "GBP" : "en_GB",
  //   "USD" : "en_US",
  //   "CAD" : "en_CA",
  //   "AUD" : "en_AU",
  // }

  const pricingTableColumns = [
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];

  const pricingTableFormatted = pricingTable.map(data => {
    return {
      ...data,
      price: <Currency amount={data.price} suffix={data.priceSuffix || " per print"} />,
    };
  });

  /*const shippingInfo = `Fast turnaround – we print and mail on the <strong style='color:#636363;font-weight:bold;'>same or next working day</strong>.<br/><br/>Our Standard delivery price is
  <strong style='color:#636363;font-weight:bold;'>${currency === "GBP" ? '£3.99' : '$6.99'}</strong> and includes both postage and <span style='color:#ff5a5f; font-weight:normal'>high-quality sturdy & protective packaging</span>.
  <br/><br/>We aim to deliver your prints to ${currency === "GBP" ? 'UK 🇬🇧' : 'USA 🇺🇸'} addresses within 2 — 3 working days of order. Delivery times include the manufacturing time.`;
  */

  const newShippingInfo = (
    <div style={{ fontSize: "12px" }}>
      <p>
        <strong style={{ fontWeight: "bold" }}>Average Production Time:</strong> 1-2 working days.*
      </p>
      <p>
        <strong style={{ fontWeight: "bold" }}>Average Shipping Time:</strong> 2-5 working days.*
      </p>
      <p>
        <strong style={{ fontWeight: "bold" }}>Standard Shipping Charge:</strong> £3.99.
      </p>
      <p>
        <strong style={{ fontWeight: "bold" }}>Express Shipping Charge:</strong> £9.99.
      </p>
      <p>
        <strong style={{ fontWeight: "bold" }}>Second shipment surcharge:</strong> £3.00.
      </p>
      <p>
        Standard shipping charge includes: Royal Mail postage, handling fees and packaging costs.
        This is not a tracked service.*
      </p>
      <p>
        Express shipping is a tracked, courier service using DPD. Please note that Express delivery
        is not available for delivery on a Saturday, Sunday or Bank Holiday.
      </p>
      <p>
        <i>*Order by 3pm weekdays.</i>
      </p>
      <p>
        PostSnap photo prints are shipped in protective, sustainable packaging from our UK based
        photo printing lab.
      </p>
      <p>
        We will send you order confirmation and shipping emails – please check your junk folder if
        you do not receive our emails.
      </p>
      <p>
        Please check your prints carefully before placing your order as photo prints can only be
        returned if they are faulty.
      </p>
      <p>
        Please consider adding your mobile phone number to your account to help us contact you in
        the event of an issue with your order.
      </p>
      <p>
        If you have a problem with your prints, we must be notified within 14 days of the shipping
        date. Faulty photo prints may be returned to us for a refund. Please note that we cannot
        refund you for customer errors such as badly cropped, dark or pixelated photos.‍
      </p>
    </div>
  );
  const pricingModal = (
    <AntModal
      style={{ top: 20, height: "500px" }}
      bodyStyle={{ overflowY: "scroll" }}
      visible={isPricingModalVisible}
      onOk={() => setIsPricingModalVisible(false)}
      onCancel={() => setIsPricingModalVisible(false)}
      footer={[
        <AntButton key="submit" type="default" onClick={() => setIsPricingModalVisible(false)}>
          Done
        </AntButton>,
      ]}
    >
      <div style={{ overfow: "scroll" }}>
        <h3 style={{ textAlign: "center" }}>
          {isCaptionPrint ? "" : product.get("name").replace("Prints", "")} Prices & Shipping
        </h3>
        <Table
          bordered
          pagination={false}
          dataSource={pricingTableFormatted.toJS()}
          columns={pricingTableColumns}
        />
        <br />
        {newShippingInfo}
      </div>
    </AntModal>
  );

  const keyFeaturesModal = (
    <AntModal
      style={{ top: 20, height: "500px" }}
      bodyStyle={{ overflowY: "scroll" }}
      visible={isKeyFeaturesModalVisible}
      onOk={() => setIsKeyFeaturesModalVisible(false)}
      onCancel={() => setIsKeyFeaturesModalVisible(false)}
      footer={[
        <AntButton key="submit" type="default" onClick={() => setIsKeyFeaturesModalVisible(false)}>
          Done
        </AntButton>,
      ]}
    >
      <div style={{ overfow: "scroll" }}>
        <h3 style={{ textAlign: "center" }}>Key Features</h3>
        Lorem ipsum...
      </div>
    </AntModal>
  );

  const noItems = items.length === 0;
  const someItems = items.length > 0;

  const glossyOrMatt = true; //finishOptionsAvailable;
  const isGlossy = someItems ? items[0].getIn(["product_options", "finish"]) === "gloss" : true;
  const areBordered = someItems ? items[0].getIn(["product_options", "border"]) === true : false;
  const effects = someItems ? items[0].getIn(["product_options", "effects"]) : false;

  const lowResInfoModal = (
    <AntModal
      style={{ top: 20, height: "500px" }}
      bodyStyle={{ height: "75vh", overflowY: "scroll" }}
      visible={isLowResInfoModalVisible}
      onOk={() => setIsLowResInfoModalVisible(false)}
      onCancel={() => setIsLowResInfoModalVisible(false)}
      footer={[
        <AntButton key="submit" type="default" onClick={() => setIsLowResInfoModalVisible(false)}>
          OK
        </AntButton>,
      ]}
    >
      <div style={{ overfow: "scroll", fontSize: "12px" }}>
        <h2 style={{ textAlign: "center" }}>Resolution Explained</h2>
        <br />
        <h3>What does a "low resolution" warning mean?</h3>
        <p>
          Photo resolution is a term that refers to the quality of your photo and is measured in
          pixels per inch (PPI). Our print labs print at 300 PPI. The more pixels per inch, the more
          tiny squares of detail your photo includes and the higher the resolution.
        </p>
        <p>
          Generally, higher resolution photos produce better printed images.
          <br />
          If you receive a low resolution warning, we've detected that your photo does not contain
          enough detail to produce a high quality printed image at 300 DPI, and we want to be sure
          you enjoy the products we print for you.
        </p>
        <h3>What should I do about this low resolution warning?</h3>
        <p>
          If you have a higher resolution copy of your photo, upload and use it instead. This is the
          best solution. If you don’t have a copy, here are some suggestions:
        </p>
        <ol style={{ color: "rgba(51, 51, 51, 0.8)", fontWeight: "300", marginLeft: "10px" }}>
          <li>If you’re ordering prints, consider selecting a smaller size print.</li>
          <li>If you've cropped your photo, consider using your original image instead.</li>
          <li>
            If you’re creating photo gifts, consider creating a collage or reducing the size of your
            photos.
          </li>
        </ol>
      </div>
    </AntModal>
  );

  const lowResWarningContent = (
    <div className="low-res-warning-content">
      <p>
        The resolution of this photo is too low to print at this size and may not print clearly. Try
        choosing a smaller print or replacing the photo.
      </p>
    </div>
  );

  const faceCheckWarningContent = (
    <div className="low-res-warning-content">
      <p>
        We think this needs an extra check
        <br />
        just to make sure any faces don't get chopped off...
      </p>
    </div>
  );

  const lowResWarningTitle = (
    <div
      style={{
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ fontWeight: "500", fontSize: "20px" }}>Low Resolution Warning</p>
    </div>
  );

  const areRealBorders = areBordered && realBorders; //product.get("appKey") === "2×2_PRINTS"// false;
  const maskedProduct = realBorders; //product.get("appKey") === "2×2_PRINTS";

  const bleedMasks = MASKS[product.get("appKey")];

  const isTextPrint = isTextPrintProduct(product.get("id")); //["6X4.5_TEXT_PRINTS", "RETRO_PRINTS", "2×2.5_PRINTS"].includes(product.get("appKey")); // Todo

  const textPrintCropPositions = {
    "6X4.5_TEXT_PRINTS": { top: "8px", right: "8px" },
    RETRO_PRINTS: { top: "16px", right: "16px" },
    RETRO_PRINTS_C: { top: "16px", right: "16px" },
    "2×2.5_PRINTS": { top: "16px", right: "16px" },
  };

  let cropButtonPosition = {
    right: isTextPrint
      ? textPrintCropPositions[product.get("appKey")].right
      : maskedProduct
      ? "20px"
      : "9px",
    bottom: isTextPrint ? "unset" : maskedProduct ? "20px" : "7px",
    top: isTextPrint ? textPrintCropPositions[product.get("appKey")].top : "unset",
  };

  // {
  //   position: 'absolute',
  //   bottom: maskedProduct ? '20px' : '7px',
  //   right: maskedProduct ? '55px': '40px',
  //   border: 'none',
  //   zIndex: 1,
  //   "-webkit-transform": "translate3d(0,0,0)",
  // }

  const classes = classNames("photo-prints-prebag__item-preview", {
    "item-preview--is-glossy-or-matt": glossyOrMatt,
    "item-preview--is-glossy": isGlossy,
    "item-preview--padded": isTextPrint,
  });

  const handleTextDraftChange = (event, value, multiLine = false) => {
    let newValue = value;

    if (multiLine) {
      let allowedLines = 2;
      let maxLineLength = 25;
      let lines = newValue.split("\n");

      if (lines.some(line => line.length > maxLineLength)) {
        console.log("line too long");
        return;
      }

      if (lines.length > allowedLines) {
        console.log("too many lines");
        return;
      }

      let input = $(event.target);
      let h = event.target.offsetHeight;

      $(input).css({
        //clear current padding and height so we can use scrollHeight below
        paddingTop: 0,
        height: 0,
      });

      let paddingTop = Math.max(0, h / 2 - event.target.scrollHeight / 2);

      $(input).css({
        paddingTop: paddingTop,
        height: h,
      });
      setTextDraft(newValue.slice(0, TEXT_LINE_MAX_LENGTH * 2));
    } else {
      setTextDraft(newValue.slice(0, TEXT_LINE_MAX_LENGTH));
    }

    // if(newValue.length < maxTextLength
    //     && lines.length <= allowedLines){
    //   setTextDraft(
    //     newValue.slice(0, TEXT_LINE_MAX_LENGTH),
    //   );
    // } else{
    //   console.log("Too many lines")
    // }
  };

  const handleCaptionHeadingChange = (event, value, multiLine = false) => {
    setCaptionHeading(value);
    setCaptionHeadingCharCount(value.length);
    //setTextDraft(newValue.slice(0, TEXT_LINE_MAX_LENGTH*2));
  };

  const handleCaptionSubheadingChange = (event, value, multiLine = false) => {
    setCaptionSubheading(value);
    setCaptionSubheadingCharCount(value.length);
    //setTextDraft(newValue.slice(0, TEXT_LINE_MAX_LENGTH*2));
  };

  const handleTextColorChange = value => {
    setTextColor(value);
  };

  const handleCaptionHeadingTextColorChange = value => {
    setCaptionHeadingTextColor(value);
    if (!hasCustomisedSubheading) {
      setCaptionSubheadingTextColor(value);
    }
  };

  const handleCaptionSubheadingTextColorChange = value => {
    setCaptionSubheadingTextColor(value);
    setHasCustomisedSubheading(true);
  };

  const handleTextFontChange = value => {
    setTextFont(value);
  };

  const handleCaptionHeadingFontChange = value => {
    setCaptionHeadingFont(value);
    if (!hasCustomisedSubheading) {
      setCaptionSubheadingFont(value);
    }
  };

  const handleClickCaptionHeadingFontTool = value => {
    if (captionHeadingColorToolActive) {
      setCaptionHeadingColorToolActive(false);
      setCaptionHeadingFontToolActive(!captionHeadingFontToolActive);
    }
  };

  const handleClickCaptionHeadingColorTool = value => {
    if (captionHeadingFontToolActive) {
      setCaptionHeadingFontToolActive(false);
      setCaptionHeadingColorToolActive(!captionHeadingColorToolActive);
    }
  };

  const handleClickCaptionSubheadingFontTool = value => {
    if (captionSubheadingColorToolActive) {
      setCaptionSubheadingColorToolActive(false);
      setCaptionSubheadingFontToolActive(!captionSubheadingFontToolActive);
    }
  };

  const handleClickCaptionSubheadingColorTool = value => {
    if (captionSubheadingFontToolActive) {
      setCaptionSubheadingFontToolActive(false);
      setCaptionSubheadingColorToolActive(!captionSubheadingColorToolActive);
    }
  };

  const handleCaptionSubheadingFontChange = value => {
    setCaptionSubheadingFont(value);
    setHasCustomisedSubheading(true);
  };

  const handleCaptionHeadingAlignment = value => {
    console.log("handleCaptionHeadingAlignment", value);
    setCaptionHeadingAlignment(value);
    if (!hasCustomisedSubheading) {
      setCaptionSubheadingAlignment(value);
    }
  };

  const handleCaptionHeadingWeight = value => {
    if (captionHeadingWeight === "normal") {
      setCaptionHeadingWeight("bold");
      if (!hasCustomisedSubheading) {
        setCaptionSubheadingWeight("bold");
      }
    } else {
      setCaptionHeadingWeight("normal");
      if (!hasCustomisedSubheading) {
        setCaptionSubheadingWeight("normal");
      }
    }
  };

  const handleCaptionSubheadingWeight = value => {
    // setCaptionSubheadingWeight(value);
    setHasCustomisedSubheading(true);
    if (captionSubheadingWeight === "normal") {
      setCaptionSubheadingWeight("bold");
      if (!hasCustomisedSubheading) {
        setCaptionSubheadingWeight("bold");
      }
    } else {
      setCaptionSubheadingWeight("normal");
      if (!hasCustomisedSubheading) {
        setCaptionSubheadingWeight("normal");
      }
    }
  };

  const handleCaptionSubheadingAlignment = value => {
    console.log("handleCaptionSubheadingAlignment", value);
    setCaptionSubheadingAlignment(value);
    setHasCustomisedSubheading(true);
  };

  const handleTextFormSubmit = async e => {
    if (activeInputTool === "text") {
      e.preventDefault();
      //console.log("Changing txt for item", textModalActiveForItem)
      //const textSize = multiLineText ? (textDraft.split("\n").length > 1 ? 40 : 43) : 18 //24

      const textSize = multiLineText ? (textDraft.split("\n").length > 1 ? 40 : 53) : 18; //24

      let newItem = null;
      if (captionText) {
        newItem = await onChangeTextForItem(textModalActiveForItem, {
          text: captionSubheading,
          color: captionSubheadingTextColor,
          font: captionSubheadingFont,
          alignment: captionSubheadingAlignment,
          weight: captionSubheadingWeight,
          //size: 11,
          captionText: true,
          captionSubheading: true,
        });
        newItem = await onChangeTextForItem(textModalActiveForItem, {
          text: captionHeading,
          color: captionHeadingTextColor,
          font: captionHeadingFont,
          alignment: captionHeadingAlignment,
          weight: captionHeadingWeight,
          //size: 15,
          captionText: true,
          captionHeading: true,
        });
      } else {
        newItem = await onChangeTextForItem(textModalActiveForItem, {
          text: textDraft,
          color: textColor,
          font: textFont,
          size: textSize,
          captionText: false,
        });
      }
      //let ref = previewRef.current
      //ref.forceUpdate()

      if (isPreviewModalVisible) {
        //setIsPreviewModalVisible(false);
        //setIsPreviewModalVisible(true);
        setIsPreviewModalVisible(true);
        setPreviewModalActiveForItem(newItem);
        // setTimeout(() => {
        //   setPreviewModalActiveForItem(newItem);
        // }, 600);
        // let ref = previewRef.current
        // ref.forceUpdate()
      }
      hideTextInputModal();
    } else {
      //const drawingURL = drawingRef.current.getTrimmedCanvas().toDataURL("image/png");
      const drawingURL = drawingRef.current.toDataURL("image/png");
      //const drawingURL = drawingRef.current.toDataURL('image/svg+xml');
      const drawingData = drawingRef.current.toData();

      const newItem = await onChangeDrawingForItem(textModalActiveForItem, {
        drawing: drawingURL,
        data: fromJS(drawingData),
        activeColor: textColor,
      });
      hideTextInputModal();

      if (isPreviewModalVisible) {
        setIsPreviewModalVisible(true);
        setPreviewModalActiveForItem(newItem);
      }

      setDrawingURL(drawingURL);
      setDrawingData(drawingData);
    }
  };

  const hideTextInputModal = () => {
    setIsTextInputModalVisible(false);
  };

  const handleClearDrawing = () => {
    drawingRef.current.clear();
    setDrawingURL(null);
    setDrawingPlaceholderVisible(true);
    setIsTextInputModalVisible(true);
  };

  const handleUndoDrawing = () => {
    const sigPad = drawingRef.current;
    const data = drawingRef.current.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      sigPad.fromData(data);
    }
    setIsTextInputModalVisible(true);
  };

  const hasBeenCustomised = itemId => {
    const activeItem = items.find(item => item.get("id") === itemId);
    if (activeItem) {
      let itemTextLayerConfigCustomised = false;
      let itemSigLayerConfigCustomised = false;

      const indexOfTextLayer = activeItem
        .get("layers")
        .findIndex(
          layer => layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER"
        );
      itemTextLayerConfigCustomised = activeItem.getIn([
        "layers",
        indexOfTextLayer,
        "config",
        "hasBeenCustomised",
      ]);

      const indexOfSignatureLayer = activeItem
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.SIGNATURE);
      if (indexOfSignatureLayer !== null) {
        itemSigLayerConfigCustomised = activeItem.getIn([
          "layers",
          indexOfSignatureLayer,
          "config",
          "hasBeenCustomised",
        ]);
      }
      return itemTextLayerConfigCustomised || itemSigLayerConfigCustomised;
    } else {
      return false;
    }
  };

  const handleClickEditText = (itemId, forceRender = true) => {
    console.log("Handle click");
    setIsTextInputModalVisible(true);
    setTextModalActiveForItem(itemId);

    const activeItem = items.find(item => item.get("id") === itemId);

    const indexOfTextLayer = activeItem
      .get("layers")
      .findIndex(
        layer => layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER"
      );
    const itemConfig = activeItem.getIn(["layers", indexOfTextLayer, "config"]).toJS();

    let textColor = itemConfig.color;
    let drawingColor = textColor;

    const signatureLayerIndex = activeItem
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.SIGNATURE);

    if (signatureLayerIndex != null) {
      const itemDrawingData = activeItem.getIn([
        "layers",
        signatureLayerIndex,
        "config",
        "drawing",
        "data",
      ]);
      drawingColor = activeItem.getIn(["layers", signatureLayerIndex, "config", "color"]);
      console.log("itemDrawingData", itemDrawingData);
      if (itemDrawingData) {
        setDrawingData(itemDrawingData.toJS());
      }

      console.log("Drawing data from item", drawingData);
      console.log("SigPad", drawingRef.current);
      if (drawingRef.current) {
        //console.log("DrawingRef", drawingRef.current);
        if (drawingData) {
          console.log("Applying drawing data");
          drawingRef.current.fromData(drawingData);
        } else {
          console.log("Clearing sig pad");
          drawingRef.current.clear();
        }
        drawingRef.current.on();
      }
    }

    console.log("Customised", hasBeenCustomised(itemId));
    if (hasBeenCustomised(itemId)) {
      let color = activeInputTool === "text" ? textColor : drawingColor;
      setTextDraft(itemConfig.text);
      setTextColor(color);
      setTextFont(itemConfig.font);
    } else {
      setTextColor("rgb(0,0,0)");
      setTextFont("Open Sans");
      setTextDraft("");
    }
  };

  // const handleClickBackgroundItem = (item) => {
  //   setIsBackgroundModalVisible(true)
  //   setBackgroundModalActiveForItem(item)
  // };

  const hideBackgroundModal = () => {
    setIsBackgroundModalVisible(false);
    setBackgroundModalActiveForItem(null);
  };

  const handleBackgroundChange = background => {
    console.log("id", backgroundModalActiveForItem.get("id"));
    console.log("background", background);
    onChangeBackgroundForItem(backgroundModalActiveForItem.get("id"), background);
  };

  const handleBackgroundChangeForAll = background => {
    onChangeBackgroundForAllItems(background);
  };

  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  const handleClickPreviewItem = item => {
    setIsPreviewModalVisible(true);
    setPreviewModalActiveForItem(item);
    console.log("item");
  };

  // const handleClickRotateItem = (itemId) => {
  //   onRotateItem(itemId)
  // };

  const resizeCanvas = aspectRatio => {
    const sigPad = drawingRef.current;

    if (sigPad !== false && sigPad !== null) {
      console.log("Resizing sigPad");
      let canvas = sigPad.getCanvas();
      const sigPadWidth = sigPad ? canvas.clientWidth : 300;
      //console.log(aspectRatio);
      //console.log(sigPadWidth);
      const canvasHeight = sigPadWidth * aspectRatio;
      //console.log(canvasHeight);
      if (canvasHeight > 0) {
        setDrawingAreaHeight(canvasHeight);
        if (drawingData) {
          console.log("Drawing Data", drawingData);
          sigPad.fromData(drawingData);
        } else {
          console.log("No Drawing Data");
          // const activeItem = items.find((item) => item.get('id') === textModalActiveForItem)
          // const signatureLayerIndex = activeItem.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.SIGNATURE));
          // const drawingData = activeItem.getIn(["layers", signatureLayerIndex, "config", "drawing", "data"]);
          // if (drawingData){
          //   sigPad.fromData(drawingData);
          // }
        }
      } else {
        console.log("No Canvas Height, resizing again after delay...");
        setTimeout(() => {
          resizeCanvas(aspectRatio);
          //drawingRef.current.on();
        }, 250);
      }

      let ratio = Math.max(window.devicePixelRatio || 1, 1);
      let newWidth = canvas.offsetWidth * ratio;
      let newHeight = canvas.offsetHeight * ratio;
      if (newWidth > 0 && newHeight > 0) {
        console.log("Resizing", newHeight);

        canvas.width = newWidth;
        canvas.height = newHeight;
        //sigPad.fromData(drawingData);
        canvas.getContext("2d").scale(ratio, ratio);
      } else {
        console.log("No height");
      }
    } else {
      console.log("No canvas", sigPad);
    }
  };

  const handleChangeTextToolTab = activeKey => {
    setActiveInputTool(activeKey);

    if (activeKey === "drawing") {
      const activeItem = items.find(item => item.get("id") === textModalActiveForItem);
      const signatureLayerIndex = activeItem
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.SIGNATURE);
      //console.log(signatureLayerIndex);
      if (signatureLayerIndex != null) {
        const signatureSize = activeItem.getIn(["layers", signatureLayerIndex, "config"]).toJS();
        const width = signatureSize.rect.width * activeItem.getIn(["productDimensions", "width"]);
        const height =
          signatureSize.rect.height * activeItem.getIn(["productDimensions", "height"]);
        let aspectRatio = height / width;
        setDrawingAspectRatio(aspectRatio);

        window.addEventListener("resize", () => {
          console.log("window.resize triggered");
          setTimeout(() => {
            const currentDrawingData =
              drawingRef.current && drawingRef.current !== false ? drawingRef.current.toData() : [];

            setDrawingData(drawingRef.current.toData());

            console.log("drawingData", currentDrawingData);
            console.log("drawingData", drawingData);
            console.log(drawingRef.current.toData());
            resizeCanvas(aspectRatio);

            // if (currentDrawingData.length > 0){
            //   resizeCanvas(aspectRatio)
            // }
          }, 300);
        });

        // window.addEventListener("orientationchange", () => {
        //   console.log("window.orientationchange triggered");
        //   setDrawingData(drawingRef.current.toData());
        //   setTimeout(()=> {

        //     const currentDrawingData = drawingRef.current !== false ? drawingRef.current.toData() : [];

        //     setDrawingData(drawingRef.current.toData());

        //     console.log("drawingData", currentDrawingData);
        //     console.log("drawingData", drawingData);

        //     if (currentDrawingData.length > 0){
        //       resizeCanvas(aspectRatio)
        //     }
        //   }, 200)
        // });

        setTimeout(() => {
          // const sigPadWidth = (drawingRef.current !== false ? drawingRef.current.getCanvas().clientWidth : 300);
          // console.log(sigPadWidth);
          // console.log((height/width));
          // const canvasHeight =  sigPadWidth * (height/width);
          // console.log(canvasHeight);
          // setDrawingAreaHeight(canvasHeight)
          if (!hasResizedCanvas) {
            console.log("first load");
            resizeCanvas(aspectRatio);
            setHasResizedCanvas(true);
          }
          //drawingRef.current.on();
        }, 250);
      }
    }
  };

  const handlePenSizeChange = penSize => {
    setPenSize(penSize);
  };

  const hidePlaceholder = () => {
    setDrawingPlaceholderVisible(false);
  };

  const textInputLines = textDraft.split("\n").length;

  const textModalTitle = (
    <React.Fragment>
      <span style={{ textAlign: "center", display: "block" }}>Add Your Caption</span>
      <div style={{ position: "absolute", right: "10px", top: "10px", zIndex: 20 }}>
        <Button theme="dark-blue" size="small" label="Save" onClick={handleTextFormSubmit} />
      </div>
    </React.Fragment>
  );

  const textModal = (
    <AntModal
      visible={isTextInputModalVisible}
      title={textModalTitle}
      okText="Save"
      footer={null}
      onCancel={hideTextInputModal}
      onOk={handleTextFormSubmit}
      zIndex={1001}
      width={"100%"}
      style={{
        top: document.documentElement.clientWidth < 550 ? 0 : 20,
        margin: document.documentElement.clientWidth < 550 ? 0 : "auto",
        maxWidth: "800px",
      }}
      okButtonProps={{ style: { backgroundColor: "#4b5566", borderColor: "#4b5566" } }}
      bodyStyle={{
        padding: "0px",
        height: document.documentElement.clientWidth < 550 ? "calc(100vh - 108px)" : "auto",
      }}
    >
      <Form onSubmit={e => e.preventDefault()} className="coverText-form">
        <Form.Item>
          {captionText && (
            <>
              <div
                style={{
                  padding: "10px 15px",
                }}
              >
                <Row>
                  <Col span={24}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span style={{ fontSize: "14px" }}>Caption Heading</span>
                      <span
                        style={{
                          fontSize: "12px",
                          color:
                            captionHeadingCharCount >= CAPTION_HEADING_MAX_LENGTH
                              ? "#FC5C63"
                              : "#CCC",
                        }}
                      >{`${captionHeadingCharCount}/${CAPTION_HEADING_MAX_LENGTH}`}</span>
                    </div>
                    <Input
                      placeholder="Add line 1"
                      value={captionHeading}
                      onChange={e => handleCaptionHeadingChange(e, e.target.value)}
                      style={{
                        textAlign: captionHeadingAlignment,
                        color: captionHeadingTextColor,
                        fontFamily: captionHeadingFont,
                        marginBottom: "12px",
                        height: "53px",
                        fontSize: "28px",
                        fontWeight: captionHeadingWeight,
                        backgroundColor: captionHeading === "" ? "#F3F4F5" : "#fff",
                      }}
                      size="large"
                      maxLength={CAPTION_HEADING_MAX_LENGTH}
                    />
                  </Col>
                </Row>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "360px",
                      margin: "0 auto",
                      marginBottom: "10px",
                    }}
                  >
                    <AntButton
                      size="medium"
                      style={{
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionHeadingFontToolActive ? "primary" : ""}
                      onClick={e => handleClickCaptionHeadingFontTool(e)}
                    >
                      Aa
                    </AntButton>
                    <AntButton
                      size="medium"
                      style={{
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionHeadingColorToolActive ? "primary" : ""}
                      onClick={e => handleClickCaptionHeadingColorTool(e)}
                    >
                      Colour
                      <span
                        style={{
                          backgroundColor: "white",
                          width: "24px",
                          height: "22px",
                          borderRadius: "5px",
                          marginLeft: "7px",
                        }}
                      >
                        <CheckCircleFilled style={{ color: captionHeadingTextColor }} />
                      </span>
                    </AntButton>
                    <div style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px 5px",
                            minWidth: "45px",
                          }}
                          type={captionHeadingAlignment === "left" ? "primary" : ""}
                          onClick={e => handleCaptionHeadingAlignment("left")}
                        >
                          <AlignLeftOutlined />
                        </AntButton>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px",
                            minWidth: "45px",
                          }}
                          type={captionHeadingAlignment === "center" ? "primary" : ""}
                          onClick={e => handleCaptionHeadingAlignment("center")}
                        >
                          <AlignCenterOutlined />
                        </AntButton>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px 5px",
                            minWidth: "45px",
                          }}
                          type={captionHeadingAlignment === "right" ? "primary" : ""}
                          onClick={e => handleCaptionHeadingAlignment("right")}
                        >
                          <AlignRightOutlined />
                        </AntButton>
                      </ButtonGroup>
                    </div>
                    <AntButton
                      size="medium"
                      style={{
                        fontWeight: captionHeadingWeight === "bold" ? "bold" : "bold",
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionHeadingWeight === "bold" ? "primary" : ""}
                      onClick={e => handleCaptionHeadingWeight(e.target.value)}
                    >
                      B
                    </AntButton>
                  </div>
                </Row>

                <Row>
                  <Col span={24}>
                    <div>
                      {captionHeadingFontToolActive && (
                        <HorizontalScroller bordered>
                          {CAPTION_FONT_FAMILIES.map((option, index) => (
                            <EditorFontOption
                              key={index}
                              fontName={option.name}
                              label={option.displayName}
                              active={option.name === captionHeadingFont}
                              onClick={e => handleCaptionHeadingFontChange(option.name)}
                            />
                          ))}
                        </HorizontalScroller>
                      )}
                      {captionHeadingColorToolActive && (
                        <HorizontalScroller bordered>
                          {CAPTION_TEXT_COLORS.map((color, index) => (
                            <EditorFillOption
                              key={index}
                              type="color"
                              color={color}
                              active={color === captionHeadingTextColor}
                              onClick={() => handleCaptionHeadingTextColorChange(color)}
                            />
                          ))}
                        </HorizontalScroller>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  padding: "10px 15px",
                }}
              >
                <Row>
                  <Col span={24}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span style={{ fontSize: "14px" }}>Caption Subheading</span>
                      <span
                        style={{
                          fontSize: "12px",
                          color:
                            captionSubheadingCharCount >= CAPTION_SUBHEADING_MAX_LENGTH
                              ? "#FC5C63"
                              : "#ccc",
                        }}
                      >{`${captionSubheadingCharCount}/${CAPTION_SUBHEADING_MAX_LENGTH}`}</span>
                    </div>
                    <Input
                      placeholder="Add your text"
                      value={captionSubheading}
                      onChange={e => handleCaptionSubheadingChange(e, e.target.value)}
                      style={{
                        textAlign: captionSubheadingAlignment,
                        color: captionSubheadingTextColor,
                        fontFamily: captionSubheadingFont,
                        marginBottom: "12px",
                        height: "53px",
                        fontSize: "20px", // 28*(15/11)
                        fontWeight: captionSubheadingWeight,
                        backgroundColor: captionSubheading === "" ? "#F3F4F5" : "#fff",
                      }}
                      size="large"
                      maxLength={CAPTION_SUBHEADING_MAX_LENGTH}
                    />
                  </Col>
                </Row>

                <Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "360px",
                      margin: "0 auto",
                      marginBottom: "10px",
                    }}
                  >
                    <AntButton
                      size="medium"
                      style={{
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionSubheadingFontToolActive ? "primary" : ""}
                      onClick={e => handleClickCaptionSubheadingFontTool(e)}
                    >
                      Aa
                    </AntButton>
                    <AntButton
                      size="medium"
                      style={{
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionSubheadingColorToolActive ? "primary" : ""}
                      onClick={e => handleClickCaptionSubheadingColorTool(e)}
                    >
                      Colour
                      <span
                        style={{
                          backgroundColor: "white",
                          width: "24px",
                          height: "22px",
                          borderRadius: "5px",
                          marginLeft: "7px",
                        }}
                      >
                        <CheckCircleFilled style={{ color: captionSubheadingTextColor }} />
                      </span>
                    </AntButton>
                    <div style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px 5px",
                            minWidth: "45px",
                          }}
                          type={captionSubheadingAlignment === "left" ? "primary" : ""}
                          onClick={e => handleCaptionSubheadingAlignment("left")}
                        >
                          <AlignLeftOutlined />
                        </AntButton>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px",
                            minWidth: "45px",
                          }}
                          type={captionSubheadingAlignment === "center" ? "primary" : ""}
                          onClick={e => handleCaptionSubheadingAlignment("center")}
                        >
                          <AlignCenterOutlined />
                        </AntButton>
                        <AntButton
                          size="medium"
                          style={{
                            padding: "4px 5px",
                            minWidth: "45px",
                          }}
                          type={captionSubheadingAlignment === "right" ? "primary" : ""}
                          onClick={e => handleCaptionSubheadingAlignment("right")}
                        >
                          <AlignRightOutlined />
                        </AntButton>
                      </ButtonGroup>
                    </div>
                    <AntButton
                      size="medium"
                      style={{
                        fontWeight: captionSubheadingWeight === "bold" ? "bold" : "bold",
                        padding: "4px 5px",
                        minWidth: "45px",
                      }}
                      type={captionSubheadingWeight === "bold" ? "primary" : ""}
                      onClick={e => handleCaptionSubheadingWeight(e.target.value)}
                    >
                      B
                    </AntButton>
                  </div>
                </Row>

                <Row>
                  <Col span={24}>
                    <div>
                      {captionSubheadingFontToolActive && (
                        <HorizontalScroller bordered>
                          {CAPTION_FONT_FAMILIES.map((option, index) => (
                            <EditorFontOption
                              key={index}
                              fontName={option.name}
                              label={option.displayName}
                              active={option.name === captionSubheadingFont}
                              onClick={e => handleCaptionSubheadingFontChange(option.name)}
                            />
                          ))}
                        </HorizontalScroller>
                      )}
                      {captionSubheadingColorToolActive && (
                        <HorizontalScroller bordered>
                          {CAPTION_TEXT_COLORS.map((color, index) => (
                            <EditorFillOption
                              key={index}
                              type="color"
                              color={color}
                              active={color === captionSubheadingTextColor}
                              onClick={() => handleCaptionSubheadingTextColorChange(color)}
                            />
                          ))}
                        </HorizontalScroller>
                      )}
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  <Col span={24}>
                    <span style={{fontSize: '12px', textAlign: 'left', display: 'block'}}>Font:</span>
                    <HorizontalScroller bordered>
                      {CAPTION_FONT_FAMILIES.map((option, index) => (
                        <EditorFontOption
                          key={index}
                          fontName={option.name}
                          label={option.displayName}
                          active={option.name === captionSubheadingFont}
                          onClick={() => handleCaptionSubheadingFontChange(option.name)}
                        />
                      ))}
                    </HorizontalScroller>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span style={{fontSize: '12px', textAlign: 'left', display: 'block'}}>Colour:</span>
                    <HorizontalScroller bordered>
                      {CAPTION_TEXT_COLORS.map((color, index) => (
                        <EditorFillOption
                          key={index}
                          type="color"
                          color={color}
                          active={color === captionSubheadingTextColor}
                          onClick={() => handleCaptionSubheadingTextColorChange(color)}
                        />
                      ))}
                    </HorizontalScroller>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <span style={{fontSize: '12px', textAlign: 'center', display: 'block'}}>Alignment:</span>
                    <div style={{textAlign: 'center', marginBottom: '10px'}}>
                      <Radio.Group size="medium" value={captionSubheadingAlignment} onChange={(e) => handleCaptionSubheadingAlignment(e.target.value)}>
                        <Radio.Button buttonStyle="solid" size="medium" value="left"><span><AlignLeftOutlined/></span></Radio.Button>
                        <Radio.Button buttonStyle="solid" size="medium" value="center"><AlignCenterOutlined/></Radio.Button>
                        <Radio.Button buttonStyle="solid" size="medium" value="right"><AlignRightOutlined/></Radio.Button>
                      </Radio.Group>
                    </div>
                    </Col>
                  <Col span={12}>
                    <span style={{fontSize: '12px', textAlign: 'center', display: 'block'}}>Weight:</span>
                    <div style={{textAlign: 'center', marginBottom: '10px'}}>
                      <Radio.Group size="medium" value={captionSubheadingWeight} onChange={(e) => handleCaptionSubheadingWeight(e.target.value)}>
                        <Radio.Button size="medium" value="normal"><span style={{fontSize: '12px'}}>Regular</span></Radio.Button>
                        <Radio.Button size="medium" value="bold"><span style={{fontWeight: 'bold', width: '50px', display: 'inline-block', fontSize: '12px'}}>Bold</span></Radio.Button>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>              */}
              </div>
            </>
          )}
          {multiLineText ? (
            <Tabs
              defaultActiveKey="text"
              onChange={activeKey => handleChangeTextToolTab(activeKey)}
              animated={false}
              size="small"
              centered
            >
              <TabPane
                tab={
                  <span>
                    <FontSizeOutlined />
                    Text
                  </span>
                }
                key="text"
              >
                {multiLineText ? (
                  <TextArea
                    rows={2}
                    cols={10}
                    wrap="hard"
                    maxLength={90}
                    placeholder="Add Your Text"
                    value={textDraft}
                    onChange={e => handleTextDraftChange(e, e.target.value, true)}
                    style={{
                      boxSizing: "border-box",
                      textAlign: "center",
                      color: textColor,
                      fontFamily: textFont,
                      fontSize: textInputLines > 1 ? "1.4em" : "1.8em",
                      paddingTop: "15px",
                    }}
                    size="large"
                  />
                ) : (
                  <Input
                    placeholder="Add Your Text"
                    value={textDraft}
                    onChange={e => handleTextDraftChange(e, e.target.value)}
                    style={{ textAlign: "center", color: textColor, fontFamily: textFont }}
                    size="large"
                    maxLength={TEXT_LINE_MAX_LENGTH}
                  />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <EditOutlined />
                    Drawing
                  </span>
                }
                key="drawing"
              >
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                  <Radio.Group
                    size="small"
                    value={penSize}
                    onChange={e => handlePenSizeChange(e.target.value)}
                  >
                    <Radio.Button size="small" value="small">
                      <span style={{ paddingRight: "16px", fontSize: "0.8em" }}>
                        <span style={{ fontSize: "0.9em", position: "absolute", right: "9px" }}>
                          ●
                        </span>{" "}
                        Small
                      </span>
                    </Radio.Button>
                    <Radio.Button size="small" value="medium">
                      <span style={{ paddingRight: "16px", fontSize: "0.8em" }}>
                        <span style={{ fontSize: "1.4em", position: "absolute", right: "9px" }}>
                          ●
                        </span>{" "}
                        Medium
                      </span>
                    </Radio.Button>
                    <Radio.Button size="small" value="large">
                      <span style={{ paddingRight: "16px", fontSize: "0.8em" }}>
                        <span
                          style={{
                            fontSize: "1.8em",
                            position: "absolute",
                            right: "8px",
                            top: "1px",
                          }}
                        >
                          ●
                        </span>{" "}
                        Large
                      </span>
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: `${drawingAreaHeight}px`,
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                >
                  {drawingPlaceholderVisible && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        Draw your caption here...
                      </p>
                    </div>
                  )}
                  <SignaturePad
                    minWidth={PEN_SIZES[penSize].minWidth}
                    maxWidth={PEN_SIZES[penSize].maxWidth}
                    penColor={textColor}
                    canvasProps={{
                      style: {
                        width: "100%",
                        height: `${drawingAreaHeight}px`,
                        position: "absolute",
                      },
                    }}
                    ref={drawingRef}
                    onBegin={hidePlaceholder}
                  />
                </div>
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <AntButton
                    onClick={() => handleClearDrawing()}
                    type={""}
                    style={{ paddingTop: "2px" }}
                  >
                    Clear <CloseCircleOutlined />
                  </AntButton>
                  <AntButton
                    onClick={() => handleUndoDrawing()}
                    type={""}
                    style={{ paddingTop: "2px", marginLeft: "10px" }}
                  >
                    Undo <UndoOutlined />
                  </AntButton>
                </div>
              </TabPane>
            </Tabs>
          ) : (
            !captionText && (
              <Input
                placeholder="Add Your Text"
                value={textDraft}
                onChange={e => handleTextDraftChange(e, e.target.value, false)}
                style={{ textAlign: "center", color: textColor, fontFamily: textFont }}
                size="large"
                maxLength={TEXT_LINE_MAX_LENGTH}
              />
            )
          )}
        </Form.Item>
      </Form>
      {!captionText && (
        <>
          <HorizontalScroller bordered>
            {REDUCED_FILL_OPTIONS_SORTED.map((color, index) => (
              <EditorFillOption
                key={index}
                type="color"
                color={color}
                active={color === textColor}
                onClick={() => handleTextColorChange(color)}
              />
            ))}
          </HorizontalScroller>
          {activeInputTool === "text" && (
            <HorizontalScroller bordered>
              {FONT_FAMILIES.map((option, index) => (
                <EditorFontOption
                  key={index}
                  fontName={option.name}
                  label={option.displayName}
                  active={option.name === textFont}
                  onClick={() => handleTextFontChange(option.name)}
                />
              ))}
            </HorizontalScroller>
          )}
        </>
      )}
    </AntModal>
  );

  //let previewWidth = document.documentElement.clientWidth < 550 ? document.documentElement.clientWidth * 0.7 : 500

  // const colourModal = (
  //   <ThemeModalContainer
  //     setBorderColor={handleColourChange}
  //     setBorderColorForAll={handleColourChangeForAll}
  //     isVisible={isColourModalVisible}
  //     closeThemesModal={hideColourModal}
  //     isPrint={true}
  //     item={colourModalActiveForItem}
  //   />
  // )

  const backgroundModal = (
    <BackgroundModalContainer
      setBackgroundColour={handleBackgroundChange}
      setBackgroundColourForAll={handleBackgroundChangeForAll}
      isVisible={isBackgroundModalVisible}
      closeBackgroundModal={hideBackgroundModal}
      item={backgroundModalActiveForItem}
    />
  );

  const previewModal = (
    <AntModal
      visible={isPreviewModalVisible}
      title={<span style={{ textAlign: "center", display: "block" }}>Preview</span>}
      okText="Done"
      width={550}
      style={{ top: 30 }}
      onCancel={hidePreviewModal}
      onOk={hidePreviewModal}
      footer={null}
      key="preview-render-modal"
      okButtonProps={{ style: { backgroundColor: "#49bda1", borderColor: "#49bda1" } }}
    >
      {/* {previewModalActiveForItem && (
        <div>
          <HtmlRenderer
            key={`preview-render-${previewModalActiveForItem.get('id')}`}
            ref={previewRef}
            width={previewWidth}
            isInteractive={false}
            previewMode={true}
            borderedForPreviews={areBordered && !areRealBorders}
            realBorders={areBordered && areRealBorders}
            item={previewModalActiveForItem.toJS()}
            page={previewModalActiveForItem.getIn(["pages", "front"])}
            onSelectRegionInPhotoLayer={(layerId, regionIndex) => console.log("Photo clicked")}
            onClickSignatureLayer={() => handleClickEditText(previewModalActiveForItem.get('id'), true)}
            onClickTextLayer={() => handleClickEditText(previewModalActiveForItem.get('id'), true)}
            onClearLayerSelection={() => console.log("cleared")}
          />
        </div>
      )} */}
      {previewModalActiveForItem && (
        <Row type="flex" justify="center" style={{ marginBottom: "5px", marginTop: "10px" }}>
          <Col>
            <AntButton
              onClick={() => handleClickEditText(previewModalActiveForItem.get("id"), true)}
              type={""}
              style={{
                background: isCaptionPrint ? "#ff5a5f" : "#4b5566",
                color: "#ffffff",
                border: "none",
                paddingTop: "2px",
              }}
            >
              {hasBeenCustomised(previewModalActiveForItem.get("id"))
                ? `Edit ${isCaptionPrint ? "Caption" : "Text"}`
                : `Add ${isCaptionPrint ? "Caption" : "Text"}`}
            </AntButton>
            <AntButton onClick={hidePreviewModal} style={{ paddingTop: "2px", marginLeft: "15px" }}>
              Done
            </AntButton>
          </Col>
        </Row>
      )}
    </AntModal>
  );

  // const printBordersInfo = (
  //   <div>
  //     <p style={{fontSize: '12px'}}>We will add a 4mm white border<br/> around all of these prints</p>
  //   </div>
  // );
  const addMoreQuantity = nextPricingSchemeQuantity - totalQuantity;

  const bordersOn = noItems
    ? true
    : someItems && items[0].getIn(["product_options", "border"]) === false
    ? true
    : false;

  const glossOn = noItems
    ? true
    : someItems && items[0].getIn(["product_options", "finish"]) === "gloss"
    ? true
    : false;

  const effectsOn = noItems
    ? false
    : someItems && items[0].getIn(["product_options", "effects"])
    ? true
    : false;

  const optionActiveButtonColor = "#4b5566";
  const optionButtonStyles = {
    fontSize: "12px",
    borderRadius: "2px",
    width: "125px",
    height: "32px",
  };

  let itemPreviewWidth = 130;

  if (product.get("width") !== product.get("height")) {
    //&& product.get("appKey") !== "2×3_PRINTS"
    itemPreviewWidth = 150;
  }

  const checkedOn = (
    <span style={{ position: "absolute", right: "5px" }}>
      <CheckCircleFilled style={{ color: "#00C202" }} />
    </span>
  );

  const printOptions = (
    <div className="print-options-container">
      <Row type="flex" justify="start" style={{ marginBottom: "15px" }}>
        <Col span={24}>Photo Options</Col>
      </Row>
      {finishOptionsAvailable && (
        <Row type="flex" justify="start" style={{ marginTop: "5px" }}>
          <Col span={24}>
            <div style={{ display: "inline-block" }}>
              <AntButton
                size="small"
                style={{
                  ...optionButtonStyles,
                  backgroundColor: glossOn ? optionActiveButtonColor : "",
                  borderColor: glossOn ? optionActiveButtonColor : "",
                }}
                type={glossOn ? "primary" : ""}
                onClick={e => handleChangeProductOption(e, "finish", "gloss")}
                data-gtm-element="Product Options - Finish"
                disabled={noItems}
              >
                Gloss {glossOn && checkedOn}
              </AntButton>
              <AntButton
                size={isTextPrint ? "small" : "small"}
                onClick={e => handleChangeProductOption(e, "finish", "matt")}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: !glossOn ? optionActiveButtonColor : "",
                  borderColor: !glossOn ? optionActiveButtonColor : "",
                  marginLeft: "30px",
                }}
                type={!glossOn ? "primary" : ""}
                data-gtm-element="Product Options - Finish"
                disabled={noItems}
              >
                Matt {!glossOn && checkedOn}
              </AntButton>
            </div>
          </Col>
        </Row>
      )}
      {borderOptionAvailable && (
        <Row type="flex" justify="start" style={{ marginTop: "5px" }}>
          <Col span={24}>
            <div style={{ display: "inline-block" }}>
              <AntButton
                size={"small"}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: bordersOn ? optionActiveButtonColor : "",
                  borderColor: bordersOn ? optionActiveButtonColor : "",
                }}
                type={bordersOn ? "primary" : ""}
                data-gtm-element="Product Options - Borders"
                onClick={e => handleBorderToggle(false, e)}
                disabled={noItems}
              >
                No border {bordersOn && checkedOn}
              </AntButton>
              <AntButton
                size={"small"}
                onClick={e => handleBorderToggle(true, e)}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: !bordersOn ? optionActiveButtonColor : "",
                  borderColor: !bordersOn ? optionActiveButtonColor : "",
                  marginLeft: "30px",
                }}
                type={!bordersOn ? "primary" : ""}
                data-gtm-element="Product Options - Borders"
                disabled={noItems}
              >
                White border
                {!bordersOn && checkedOn}
              </AntButton>
            </div>
          </Col>
        </Row>
      )}
      {effectsAvailable && (
        <Row type="flex" justify="start" style={{ marginTop: "5px" }}>
          <Col span={24}>
            <div style={{ display: "inline-block" }}>
              <AntButton
                size={"small"}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: !effectsOn ? optionActiveButtonColor : "",
                  borderColor: !effectsOn ? optionActiveButtonColor : "",
                }}
                type={!effectsOn ? "primary" : ""}
                onClick={e => handleChangeProductOption(e, "effects", false)}
                data-gtm-element="Product Options - Effects"
                disabled={noItems}
              >
                Colour
                {!effectsOn && checkedOn}
              </AntButton>
              <AntButton
                size={"small"}
                onClick={e => handleChangeProductOption(e, "effects", "black-and-white")}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: effectsOn ? optionActiveButtonColor : "",
                  borderColor: effectsOn ? optionActiveButtonColor : "",
                  marginLeft: "30px",
                }}
                type={effectsOn ? "primary" : ""}
                data-gtm-element="Product Options - Effects"
                disabled={noItems}
              >
                Black & white
                {effectsOn && checkedOn}
              </AntButton>
            </div>
          </Col>
        </Row>
      )}
      {hasMinimumQuantity && (
        <p style={{ fontSize: "12px", marginTop: "10px", color: "rgba(0, 0, 0, 0.65)" }}>
          <span>
            Mini prints are sold in packs of{" "}
            <span style={{ color: "#000", fontWeight: "bold" }}>{minimumQuantity}</span>. The
            minimum order is{" "}
            <span style={{ color: "#000", fontWeight: "bold" }}>{minimumQuantity}</span> prints.
          </span>
        </p>
      )}
    </div>
  );

  let pageTitle = (
    <div
      style={{
        lineHeight: "normal",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "15px",
        marginTop: "20px",
      }}
    >
      <span>{product.get("name").replace(" × ", "×")}</span>
      {product.get("metric_size") && (
        <span style={{ fontSize: "11px" }}>({product.get("metric_size").replace("x", "×")})</span>
      )}
    </div>
  );

  if (product.get("fine_art_print")){
    let title = product.get("name").split("(")[0]
    let paperType = product.get("name").split("(")[1].split(")")[0]
    pageTitle = (
      <div
        style={{
          lineHeight: "normal",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: "15px",
          marginTop: "15px",
        }}
      >
        <span>{title}</span>
        <span style={{ fontSize: "11px" }}>({paperType})</span>
      </div>
    )
  }

  let fixedContent = null;
  if (isColouredRetro) {
    pageTitle = "Retro Prints";
    fixedContent = <h2 className="coloured-retros-heading">Tap Print to Personalise</h2>;
  }

  return (
    <React.Fragment>
      {pricingModal}
      {keyFeaturesModal}
      {textModal}
      {previewModal}
      {backgroundModal}
      {lowResInfoModal}
      <Header
        title={pageTitle}
        leftAction={<Button theme="muted" priority="tertiary" label="Back" onClick={onCancel} />}
        // rightAction={
        //   <Button
        //     theme="muted"
        //     priority="tertiary"
        //     label="Review & Buy"
        //     disabled={noItems}
        //     onClick={() => handleClickNext()}
        //   />
        // }
      />
      {fixedContent}
      <MainContent padded scrollable className="photo-prints-prebag">
        {noItems || isColouredRetro ? <></> : printOptions}
        {!noItems && !isColouredRetro && (
          <div className="edit-options-container">
            <Row type="flex" justify="start">
              <Col span={24}>
                <>
                  <span>
                    Re-crop Photos <Icon name="crop-rotate" size="small" />
                  </span>
                  <br />
                  <span style={{ fontSize: "11px" }}>Tap below to re-crop</span>
                </>
              </Col>
            </Row>
          </div>
        )}
        <QueueAnim
          delay={0}
          duration={100}
          interval={50}
          className="queue-simple"
          type={["right", "left"]}
          leaveReverse
        >
          {isColouredRetro && !noItems ? (
            <ColouredRetros
              items={items}
              itemPreviewWidth={itemPreviewWidth}
              areBordered={areBordered}
              areRealBorders={areRealBorders}
              effects={effects}
              classes={classes}
              maskedProduct={maskedProduct}
              bleedMasks={bleedMasks}
              isCaptionPrint={false}
              deletePopoverActiveForItem={deletePopoverActiveForItem}
              onClickItem={onClickItem}
              onIncreaseItemQuantity={onIncreaseItemQuantity}
              onDecreaseItemQuantity={onDecreaseItemQuantity}
              onDeleteItem={onDeleteItem}
              setDeletePopoverActiveForItem={setDeletePopoverActiveForItem}
              isEditModalOpen={isEditModalOpen}
            />
          ) : (
            <NormalPrints
              items={items}
              itemPreviewWidth={itemPreviewWidth}
              onClickItem={onClickItem}
              areBordered={areBordered}
              areRealBorders={areRealBorders}
              onIncreaseItemQuantity={onIncreaseItemQuantity}
              onDecreaseItemQuantity={onDecreaseItemQuantity}
              classes={classes}
              effects={effects}
              onDeleteItem={onDeleteItem}
              isCaptionPrint={isCaptionPrint}
              isTextPrint={isTextPrint}
              maskedProduct={maskedProduct}
              bleedMasks={bleedMasks}
              hasBeenCustomised={hasBeenCustomised}
              cropButtonPosition={cropButtonPosition}
              glossyOrMatt={glossyOrMatt}
              handleClickEditText={handleClickEditText}
              handleClickPreviewItem={handleClickPreviewItem}
              lowResWarningContent={lowResWarningContent}
              lowResWarningTitle={lowResWarningTitle}
              setLowResPopoverVisible={setLowResPopoverVisible}
              isLowResInfoModalVisible={isLowResInfoModalVisible}
              faceCheckWarningContent={faceCheckWarningContent}
              isLowResPopoverVisible={isLowResPopoverVisible}
            />
          )}
          <div style={{backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '10px', marginTop: '10px'}}>
            <p style={{color: '#4b5566', fontWeight: '500', marginBottom: '5px'}}>Please check each photo print</p>
            <p style={{fontSize: '12px', lineHeight: '22px'}}>
              Some of your photos have been automatically cropped.<br/>
              Please check each print to make sure that the important parts are on the photo print.
            </p>
          </div>
        </QueueAnim>
      </MainContent>
      <Footer padded topShadow style={{ borderTop: "1px solid #ededed" }}>
        {showInfoMessage && (
          <div className="crop-notice">
            <AntButton
              style={{
                marginLeft: "4px",
                fontSize: "12px",
                paddingTop: "2px",
                position: "absolute",
                right: "15px",
              }}
              size="small"
              onClick={onInfoMessageDismissed}
              shape="circle"
              icon={<CloseOutlined />}
            />
            <p style={{ fontSize: "14px", marginTop: "20px" }}>
              PostSnap's Photo Lab Manager Says...
            </p>
            <div className="quote-and-icon">
              <blockquote className="lab-quote">
                <p style={{ fontSize: "12px", display: "inline-block" }}>
                  Please <strong>check the crop</strong> of your photos before ordering.
                  <br />
                  <span>I've seen far too many chopped off heads!</span>
                </p>
              </blockquote>
              <Icon name={"head-crop"} size="full-width" />
            </div>
          </div>
        )}
        {totalQuantity > 0 &&
          (isPricingBandVisible ? (
            <div
              className="photo-prints-prebag__pricing-indicator"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div className="photo-prints-prebag__pricing-breakdown">
                <p>
                  <span style={{ color: "#000", fontWeight: "bold" }}>{totalQuantity}</span>{" "}
                  {totalQuantity > 1 ? "prints" : "print"} @{" "}
                  <strong>
                    <Currency amount={costPerPrint} />
                  </strong>
                  {totalQuantity > 1 ? " each." : "."}
                </p>
                {nextPricingSchemeAvailable ? (
                  <p>
                    Add <span style={{ color: "#000", fontWeight: "bold" }}>{addMoreQuantity}</span>{" "}
                    more to pay{" "}
                    <strong>
                      <Currency amount={nextCostPerPrint} />
                    </strong>{" "}
                    per print.
                    <br />
                    Prices as low as{" "}
                    <strong>
                      <Currency amount={lowestCostPerPrint} />
                    </strong>
                    .
                  </p>
                ) : (
                  <span></span>
                )}
                {hasMinimumQuantity && (
                  <p>
                    <span>
                      Minimum quantity of{" "}
                      <span style={{ color: "#000", fontWeight: "bold" }}>{minimumQuantity}</span>
                    </span>
                  </p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <AntButton
                  data-gtm-element="Prices & Shipping Button"
                  type="default"
                  size="small"
                  onClick={() => setIsPricingModalVisible(true)}
                  style={{
                    background: "#ffffff",
                    padding: "0px 5px",
                    fontSize: "12px",
                    height: "35px",
                    marginBottom: "0px",
                    width: "140px",
                    marginRight: "5px",
                  }}
                >
                  Prices & Shipping
                </AntButton>
                {/* <AntButton
                  data-gtm-element="Key features button"
                  type="default"
                  size="small"
                  onClick={() => setIsKeyFeaturesModalVisible(true)}
                  style={
                    {
                      background: '#ffffff',
                      padding: '0px 5px',
                      fontSize: '12px',
                      height:'32px',
                      width: '125px'
                    }
                  }
                >
                  Key features
                </AntButton> */}
              </div>
            </div>
          ) : (
            <Row>
              <Col span={3} offset={20}>
                <AntButton type="link" ghost onClick={() => setIsPricingBandVisible(true)}>
                  <UpOutlined style={{ fontSize: "20px", color: "#043253" }} />
                </AntButton>
              </Col>
            </Row>
          ))}
        <div style={{ padding: "10px 0px" }}>
          <Grid.Row>
            <Grid.Column>
              <div style={{ paddingRight: "0.5rem" }}>
                {isCaptionPrint ? (
                  <Button
                    dataGtmElement="More Photos"
                    block
                    theme="dark-blue"
                    size="small"
                    label={someItems ? "Upload more photos" : "Add photos"}
                    onClick={onStartUpload}
                    disabled={items.length >= 200}
                  />
                ) : (
                  <Button
                    dataGtmElement="More Photos"
                    block
                    theme="dark-blue"
                    priority="secondary"
                    size="small"
                    label={someItems ? "Upload More Photos" : "Add Photos"}
                    onClick={onStartUpload}
                    disabled={items.length >= 200}
                  />
                )}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={{ paddingLeft: "0.5rem" }}>
                {isCaptionPrint ? (
                  <Button
                    block
                    theme="dark-blue"
                    size="small"
                    priority="secondary"
                    label="Preview all"
                    onClick={handleClickNext}
                    disabled={items.length === 0}
                  />
                ) : isColouredRetro ? (
                  <Button
                    block
                    theme="dark-blue"
                    size="small"
                    label="Review & Buy"
                    onClick={handleClickNext}
                    disabled={items.length === 0 || !editModalHasClosed}
                  />
                ) : (
                  <Button
                    block
                    theme="dark-blue"
                    size="small"
                    label="Review & Buy"
                    onClick={handleClickNext}
                    disabled={items.length === 0}
                  />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </Footer>
    </React.Fragment>
  );
};

PhotoPrintsPreBag.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default PhotoPrintsPreBag;
