import { fromJS } from "immutable";
import moment from "moment";

import { designsById } from "../data/designs";
import { productsByProductId } from "../data/products";
import transformDesignLayers from "../lib/transform-design-layers";
import postsnapApi from "./apis/postsnap";

export default async function convertLineItemToItemObject(lineItem) {
  const design = designsById.get(lineItem.design_id);
  const product = productsByProductId.get(design.get("product_id"));
  //console.log(lineItem);
  const designDetail = await postsnapApi.designs.getDetails(lineItem.design_id);
  const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), product.get('product_type_id')).map(layer => {
    const customisationForLayerInLineItem = lineItem.recipients[0].customisations.find(customisation => customisation.layer_id === layer.get("id"));
    if (customisationForLayerInLineItem && customisationForLayerInLineItem.klass === 'Layer::Photo::Config') {
      return layer.set('render', customisationForLayerInLineItem.s3_key);
    }

    return layer;
  });
  console.log(layers.toJS());
  //console.log(lineItem);
  //console.log("---");
  //console.log(design.toJS());
  //console.log("---");
  //console.log(product.toJS());
  return fromJS({
    duplicateAlertShown: true,
    isApproved: false,
    isRendering: false,
    layers: [], // TODO, populate `render` fields
    productTypeId: product.get('product_type_id'),
    productId: product.get('id'),
    pages: {}, // TODO
    quantity: lineItem.quantity,
    postDate: moment(),
    product_options: lineItem.product_options,
    renderFailed: false,
    address: null, // TODO
    preview_s3_key: lineItem.preview_s3_key,
    weight: product.get('weight'),
    addressBookId: lineItem.recipients[0].original_id || null, // TODO
    designId: lineItem.design_id,
    productDimensions: {
      width: product.get('width'),
      height: product.get('height'),
      dpi: product.get('dpi'),
      bleed: {
        top: product.get('bleed_t'),
        bottom: product.get('bleed_b'),
        left: product.get('bleed_l'),
        right: product.get('bleed_r'),
      },
    }
  });
}
