import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../lib/routes";
import { Redirect } from "react-router-dom";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as authActions } from "../../store/ducks/auth";
import {
  PRODUCT_TYPE_IDS,
  hasMinimumQuantityForProduct,
  productsByProductId,
  productTypeSlugFromProductType,
} from "../../data/products";
import ProductApproval from "./ProductApproval";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import MainContent from "../../components/MainContent/MainContent";
import Footer from "../../components/Footer/Footer";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import EditorCropperModal from "../../components/Editor/EditorCropperModal";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import CropCheckPopup from "../../components/CropCheckPopup/CropCheckPopup";

import { fromJS } from "immutable";

import { Row, Col, Modal as AntModal } from "antd";

const { confirm } = AntModal;

class ApprovalContainer extends Component {
  state = {
    itemToEdit: null,
    canProceedToBag: false,
    isEditorModalVisible: false,
    isCropperModalVisible: false,
    draftRotationState: null,
    canApprove: true,
    straightToBasket: false,
    packItems: [],
    hasSeenCropCheckModal: false,
    itemToProceedAfterCropModal: null,
    isCropCheckModalVisible: false,
    cropCheckConfirmed: false,
    approvedItemIds: [],
  };

  constructor(props) {
    super(props);
    const approvingPendingOrBagItems = this.props.location?.state;
    let unapprovedItems = null;

    if (approvingPendingOrBagItems) {
      unapprovedItems =
        approvingPendingOrBagItems.fromBasket === true
          ? this.props.unapprovedBagItems
          : this.props.unapprovedItems;
    }

    this.state = {
      totalItemsToApprove:
        unapprovedItems &&
        unapprovedItems.map(i => i.get("quantity")).reduce((prev, current) => prev + current),
      productToApprove:
        unapprovedItems && unapprovedItems.first() && unapprovedItems.first().get("productId"),
      approvingPendingItems:
        approvingPendingOrBagItems && approvingPendingOrBagItems.fromPreBag === true,
      initiatedFrom: approvingPendingOrBagItems,
      backTo: approvingPendingOrBagItems && approvingPendingOrBagItems.backTo,
      goingBack: false,
      allowApproveAll: unapprovedItems && unapprovedItems.size > 1,
      canApprove: true,
      packItems: [], //(this.props.approvedItems && this.props.approvedItems.size > 0) ? this.props.approvedItems.toJS() : []
      approvedItemIds: [],
    };
  }

  componentDidMount() {
    this.setState({
      packItems:
        this.props.approvedItems && this.props.approvedItems.size > 0
          ? this.props.approvedItems.toJS()
          : [],
    });
  }

  componentDidUpdate(prevProps) {
    if (this.unapprovedItems().size === 0 && this.state.canProceedToBag) {
      //this.props.goToBasket();
      if (this.state.approvingPendingItems) {
        this.props.clearPendingApprovalItems();
        localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
        if (this.state.straightToBasket) {
          this.props.setHasChosenGuestRoute(true);
          this.props.goToContactDetails();
        } else {
          this.props.goToApprovalComplete({
            quantity: this.state.totalItemsToApprove,
            products: this.state.productToApprove,
            fromPage: "/app/create/prints",
          });
        }
      }
    }
  }

  unapprovedItems = () => {
    if (this.state.approvingPendingItems) {
      return this.props.unapprovedItems;
    } else {
      return this.props.unapprovedBagItems;
    }
  };

  handleGoToBasket = () => {
    if (this.unapprovedItems().length > 0) {
    } else {
      this.props.goToBasket();
    }
  };

  handleGoBack = item => {
    this.setState(
      {
        goingBack: true,
        canProceedToBag: false,
      },
      () => {
        let backLocation;
        if (item) {
          if (this.state.approvingPendingItems) {
            // If item isn't made from EditorContainer which clears WIp editr item on done

            // Set the WIP PREBAG item to the current approval item.
            //console.log("WIP_PREBAG",  JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)))

            const existingWipPrebag = JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG));

            let updateWipPrebag = false;

            if (existingWipPrebag && existingWipPrebag.data.items) {
              const someWithWipId = existingWipPrebag.data.items.some(wipItem => wipItem.id);
              updateWipPrebag =
                someWithWipId &&
                !existingWipPrebag.data.items.some(wipItem => wipItem.id === item.get("id"));
              console.log(
                "None of the wip prebag items contain the current item ID, need to update WIP_PREBAG"
              );
            }

            //console.log("updateWipPrebag", updateWipPrebag)
            if (updateWipPrebag) {
              let data = {};
              if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
                data = {
                  item: item.toJS(),
                };
              } else if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
                data = {
                  items: [item.toJS()],
                };
              }

              const product = productsByProductId.get(item.get("productId"));
              const productSlug = product.get("url_slug");
              const productTypeSlug = productTypeSlugFromProductType(item.get("productTypeId"));
              //console.log("productTypeSlug", productTypeSlug)
              //console.log("productSlug", productSlug)
              // TODO: Need custom slugs for custom and collage prints
              localStorage.setItem(
                STORAGE_KEYS.WIP_PREBAG,
                JSON.stringify({
                  productTypeId: item.get("productTypeId"),
                  productId: item.get("productId"),
                  productSlug: productSlug,
                  productTypeSlug: productTypeSlug,
                  data: data,
                })
              );
              backLocation = `/app/create/${productTypeSlug}/${productSlug}`;
            }

            if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
              this.props.deletePendingApprovalItem(item.get("id"));
            } else if (
              item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
              item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE
            ) {
              this.props.deleteBasketItems(this.state.approvedItemIds);
              // TODO, all relavent items!- need a deletePendingApprovalItems
              this.props.clearPendingApprovalItems();
            }
          }
        }
        if (this.state.backTo) {
          window.location = this.state.backTo;
        } else {
          if (backLocation) {
            window.location = backLocation;
          } else {
            window.history.back();
          }
        }
      }
    );
  };

  handleGoBackAndClear = item => {
    this.setState(
      {
        goingBack: true,
        canProceedToBag: false,
      },
      () => {
        if (item) {
          if (this.state.approvingPendingItems) {
            // If item isn't made from EditorContainer which clears WIp editr item on done
            if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
              this.props.clearPendingApprovalItems();
            }
          }
        }
        if (this.state.backTo) {
          window.location = this.state.backTo;
        } else {
          window.history.back();
        }
      }
    );
  };

  handleShowCropModalOrApprove = item => {
    if (item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
      this.handleApproveItem(item);
    } else {
      if (this.state.hasSeenCropCheckModal && this.state.cropCheckConfirmed) {
        this.handleApproveItem(item);
      } else {
        this.setState({
          hasSeenCropCheckModal: true,
          itemToProceedAfterCropModal: item,
          isCropCheckModalVisible: true,
        });
      }
    }
  };

  closeCropCheckModal = () => {
    this.setState({
      hasSeenCropCheckModal: true,
      itemToProceedAfterCropModal: null,
      isCropCheckModalVisible: false,
    });
  };

  backFromCropCheckModal = () => {
    const proceedOnItem = this.state.itemToProceedAfterCropModal;
    this.setState(
      {
        hasSeenCropCheckModal: true,
        itemToProceedAfterCropModal: null,
        isCropCheckModalVisible: false,
      },
      () => {
        this.handleGoBack(proceedOnItem);
      }
    );
  };

  proceedCropCheckModal = () => {
    const proceedOnItem = this.state.itemToProceedAfterCropModal;
    this.setState(
      {
        hasSeenCropCheckModal: true,
        itemToProceedAfterCropModal: null,
        isCropCheckModalVisible: false,
        cropCheckConfirmed: true,
      },
      () => {
        this.handleApproveItem(proceedOnItem);
      }
    );
  };

  handleApproveItem = item => {
    let approvedIds = this.state.approvedItemIds || [];
    approvedIds.push(item.get("id"));

    this.setState(
      {
        canProceedToBag: true,
        straightToBasket: item.get("picCollage"),
        approvedItemIds: approvedIds,
      },
      () => {
        if (this.state.approvingPendingItems) {
          this.props.approvePendingApprovalItem(item.get("id"));
          // If pack item, add to state until last pack item is added
          // then add all pack items..
          const isPackItem = hasMinimumQuantityForProduct(item.get("productId"));
          if (isPackItem) {
            this.setState(
              {
                packItems: [...this.state.packItems, item.toJS()],
              },
              () => {
                if (this.unapprovedItems().size === 0) {
                  //last item
                  this.state.packItems.forEach(async packItem => {
                    this.props.addPendingApprovalItemToBasket(packItem);
                  });
                }
              }
            );
          } else {
            this.props.addPendingApprovalItemToBasket(item.toJS());
          }

          const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
            ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
            : null;
          // If item to approve is the only item in WIP PREBAG...
          let preBagItemID;
          if (existingPrebag) {
            if (existingPrebag.data.item) {
              preBagItemID = existingPrebag.data.item.id;
            } else if (existingPrebag.data.items) {
              preBagItemID = existingPrebag.data.items[existingPrebag.data.items.length - 1].id;
            }
            if (preBagItemID === item.get("id")) {
              if (this.unapprovedItems().size === 1) {
                //last item
                localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
              }
            }
          }
        } else {
          this.props.approveItem(item.get("id"));
        }
      }
    );
  };

  handleApproveAllItems = () => {
    const multipleToApprove = this.unapprovedItems().size > 1;

    let approvalText = multipleToApprove
      ? `all the remaining ${this.unapprovedItems().size} items without checking each one?`
      : `this?`;

    confirm({
      title: multipleToApprove ? "Approve All?" : "Approve",
      content: `Are you sure you want to approve ${approvalText} We do not check orders before printing & shipping.`,
      okText: multipleToApprove ? "Yes — Approve All" : "Approve",
      okButtonProps: {
        style: {
          backgroundColor: "#4b5566",
          borderColor: "#4b5566",
        },
      },
      cancelText: "No",
      icon: null,
      onOk: () => {
        this.setState(
          {
            canProceedToBag: true,
          },
          () => {
            if (this.state.approvingPendingItems) {
              this.props.approveAllPendingApprovalItems(
                this.unapprovedItems()
                  .map(i => i.get("id"))
                  .toJS()
              );
              this.unapprovedItems().forEach(async item => {
                this.props.addPendingApprovalItemToBasket(item.toJS());
              });
              this.state.packItems.forEach(async packItem => {
                this.props.addPendingApprovalItemToBasket(packItem);
              });
              localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
            } else {
              this.props.approveAllItems(
                this.unapprovedItems()
                  .map(i => i.get("id"))
                  .toJS()
              );
            }
            //message.success("All items approved");
          }
        );
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  openCropperModal = item => {
    this.setState({
      itemToEdit: item,
      draftRotationState: item.get("isRotated"),
      isCropperModalVisible: true,
    });
  };

  handleRotateCrop = () => {
    this.setState({
      draftRotationState: !this.state.draftRotationState,
    });
  };

  closeCropper = () => {
    this.setState({
      itemToEdit: null,
      draftRotationState: null,
      isCropperModalVisible: false,
    });
  };

  handleDeleteItem = item => {
    //if(this.checkCanDeleteOrReduceQtyForItem(item)){
    let message = "Are you sure you want to delete this?";
    let deletingPackItem = false;
    let okText = "Yes";
    if (this.state.approvingPendingItems && hasMinimumQuantityForProduct(item.get("productId"))) {
      deletingPackItem = true;
      message =
        "Are you sure you want to delete this? Doing so will mean you'll have to add additional prints to complete this pack.";
      okText = "Yes";
    }

    confirm({
      title: "Delete?",
      content: message,
      okText: okText,
      cancelText: "No",
      icon: null,
      onOk: () => {
        if (this.state.approvingPendingItems) {
          if (deletingPackItem) {
            //const packQtyRequired = minimumQuantityForProduct(item.get('productId'));
            this.setState({
              canApprove: false,
              packQtyMissing: (this.state.packQtyMissing || 0) + item.get("quantity"),
            });
          }

          this.setState(
            {
              totalItemsToApprove: this.state.totalItemsToApprove - 1,
            },
            () => {
              this.props.deletePendingApprovalItem(item.get("id"));

              if (localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)) {
                const existingWipPrebag = JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG));

                let newPreBagData = [];
                if (existingWipPrebag.data.items) {
                  newPreBagData = existingWipPrebag.data.items.filter(
                    wipItem => wipItem.id !== item.get("id")
                  );
                }

                if (newPreBagData.length === 0) {
                  // Just remove this product Id?
                  localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
                } else {
                  localStorage.setItem(
                    STORAGE_KEYS.WIP_PREBAG,
                    JSON.stringify({
                      ...existingWipPrebag,
                      data: {
                        items: newPreBagData,
                      },
                    })
                  );
                }
              }

              if (deletingPackItem) {
                this.handleGoBackAndClear(item);
              }
            }
          );
        } else {
          this.props.deleteItem(item.get("id"));
        }
        //if (!deletingPackItem){
        //message.success("Item deleted");
        //}
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
    //}
  };

  borderThicknessForItem = item => {
    const dimensions = item.get("productDimensions").toJS();
    const widthPlusBleed = dimensions.width + dimensions.bleed.left + dimensions.bleed.right;
    const heightPlusBleed = dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom;
    const widthOrHeight = item.get("isRotated") ? heightPlusBleed : widthPlusBleed;
    const borderThicknessInMM = 6;
    return borderThicknessInMM / widthOrHeight;
  };

  handleSaveCrop = crop => {
    const itemForCropper = this.state.itemToEdit;
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    const updatedItem = itemForCropper.withMutations(item => {
      item.setIn(
        ["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "cropData"],
        fromJS(crop)
      );
      item.set("isRotated", Boolean(this.state.draftRotationState));

      if (item.getIn(["product_options", "border"])) {
        let borderConfig = {
          style: {
            type: "color",
            color: "rgb(255,255,255)",
          },
          thickness: this.borderThicknessForItem(item), //0.08 //6mm/75mm = 0.08 // 0.111 // 6mm/54mm = 0.111
        };
        // If rotated, need to change border thickness to use shortest length
        if (Boolean(this.state.draftRotationState)) {
          item.setIn(["layers", indexOfPhotoLayer, "config", "border"], fromJS(borderConfig));
        } else {
          borderConfig = {
            style: {
              type: "color",
              color: "rgb(255,255,255)",
            },
            thickness: this.borderThicknessForItem(item), //6mm/75mm = 0.08 // 0.111 // 6mm/54mm = 0.111
          };
          item.setIn(["layers", indexOfPhotoLayer, "config", "border"], fromJS(borderConfig));
        }
      }
    });

    if (this.state.approvingPendingItems) {
      this.props.updatePendingApprovalItem(updatedItem.get("id"), updatedItem.toJS());
    } else {
      this.props.updateItem(updatedItem.get("id"), updatedItem.toJS());
    }
    this.closeCropper();
  };

  closeApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: false,
    });
  };

  handleEditItem = item => {
    this.closeApprovalModal();
    // TODO, might need a different route to return us to the approval modal after saving?
    if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
      if (item.get("isCustomPrint") || item.get("isCollagePrint") || item.get("isColouredRetro")) {
        this.handleGoBack(item);
      } else {
        this.openCropperModal(item);
      }
    } else {
      this.handleGoBack(item);
    }
  };

  render() {
    const cropperModalProps = {};

    const { itemToEdit } = this.state;

    if (itemToEdit) {
      const indexOfPhotoLayer = itemToEdit
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      const regionToCrop = itemToEdit.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0]);

      const photoRect = itemToEdit.getIn(["layers", indexOfPhotoLayer, "config", "rect"]);

      let productDimensions = itemToEdit.get("productDimensions").toJS();

      //We want the photo region dimensions and not the product dimensions
      productDimensions.width = productDimensions.width * photoRect.get("width");
      productDimensions.height = productDimensions.height * photoRect.get("height");

      const imageToCrop = regionToCrop.get("image");
      //const productDimensions = itemToEdit.get("productDimensions").toJS();
      cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"]);
      cropperModalProps.cropData = imageToCrop.get("cropData")
        ? imageToCrop.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right) /
        (productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom);

      const textLayer = itemToEdit
        .get("layers")
        .find(
          layer => layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER"
        );

      if (itemToEdit.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
        cropperModalProps.disallowRotation = false;
        if (textLayer) {
          // cropperModalProps.renderPhotoPrintsTextBand = true;
          // cropperModalProps.photoPrintsText = {
          //   text: textLayer.getIn(['config', 'text']),
          // };
          cropperModalProps.disallowRotation = true;
          // Hack to hide rotation for text prints
        }

        if (cropperModalProps.ratio === 1 || productDimensions.width === productDimensions.height) {
          cropperModalProps.disallowRotation = true;
        }
      }

      if (this.state.draftRotationState) {
        cropperModalProps.ratio =
          (productDimensions.height +
            productDimensions.bleed.top +
            productDimensions.bleed.bottom) /
          (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right);
      }

      cropperModalProps.productDimensions = itemToEdit.get("productDimensions").toJS();

      cropperModalProps.renderBleedBands =
        productDimensions.bleed.left === productDimensions.bleed.right &&
        productDimensions.bleed.left > 0;
    }

    const unapprovedItems = this.unapprovedItems(); //this.props.unapprovedItems;
    const allowApproveAll = this.state.allowApproveAll; //unapprovedItems.size > 1;

    let areAnyItemsRendering = true;
    if (unapprovedItems.size === 0 && this.state.goingBack === false) {
      return <Redirect to={routeCreators.basket()} />;
    }

    if (unapprovedItems.size === 0 && this.state.goingBack) {
      return <FullScreenLoader key="loader" message="Loading..." isVisible={true} />;
    }

    let editLabel = "Edit";
    let deleteLabel = "Delete";
    if (unapprovedItems && unapprovedItems.size > 0) {
      areAnyItemsRendering = unapprovedItems.some(i => i.get("isRendering"));
      if (unapprovedItems.first()) {
        if (unapprovedItems.first().get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
          if (
            unapprovedItems.first().get("isCustomPrint") ||
            unapprovedItems.first().get("isCollagePrint") ||
            unapprovedItems.first().get("isColouredRetro")
          ) {
            editLabel = "Edit";
          } else {
            editLabel = "Edit Crop";
          }
        } else {
          editLabel = `${
            unapprovedItems.first().get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE
              ? "Edit Book"
              : "Edit"
          }`;
        }

        if (unapprovedItems.first().get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
          deleteLabel = "Delete Book";
        } else {
          deleteLabel = "Delete";
        }
      }
    }

    const cropperModal = (
      <EditorCropperModal
        key="cropper-modal"
        isOpen={this.state.isCropperModalVisible}
        {...cropperModalProps}
        onClose={this.closeCropper}
        onRotate={this.handleRotateCrop}
        onSave={this.handleSaveCrop}
      />
    );

    const remainingToApprove = (
      <p
        className="text-center approval-count"
        style={{ visibility: unapprovedItems.size === 1 ? "hidden" : "visible" }}
      >
        <span className="text-primary">{unapprovedItems.size}</span> left to approve
      </p>
    );

    const approvalText = {
      approveSingle: this.state.approvingPendingItems
        ? unapprovedItems.first().get("isColouredRetro")
          ? "Approve This Print"
          : "Approve & Add to Basket"
        : "Approve",
      approveAll: this.state.approvingPendingItems
        ? unapprovedItems.first().get("isColouredRetro")
          ? "Approve All Prints"
          : "Approve All & Add to Basket"
        : "Approve All",
    };

    const canApprove = this.state.canApprove;
    let picCollage = false;
    if (unapprovedItems && unapprovedItems.size > 0) {
      if (unapprovedItems.first()) {
        if (unapprovedItems.first().get("picCollage")) {
          picCollage = true;
        }
      }
    }

    const customPrintText = productsByProductId
      .getIn([unapprovedItems.first().get("productId"), "name"])
      .replace(" Custom Prints", "")
      .replace(" × ", "×");

    let deletionAllowed = true;
    if (unapprovedItems && unapprovedItems.size > 0) {
      if (unapprovedItems.first()) {
        if (hasMinimumQuantityForProduct(unapprovedItems.first().get("productId"))) {
          deletionAllowed = false;
        }
      }
    }

    const cropCheckModal = (
      <CropCheckPopup
        key="crop-check-modal"
        isOpen={this.state.isCropCheckModalVisible}
        onClose={this.closeCropCheckModal}
        onProceed={this.proceedCropCheckModal}
        onGoBack={this.backFromCropCheckModal}
      />
    );

    const isPhotoBook =
      unapprovedItems.first().get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE;

    const isGreetingCard =
      unapprovedItems.first().get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    // In the <ProductApproval/> component, always the first item is shown.
    // So we consider the above condition to show the delete and edit buttons or not.
    const showDelEditButtons =
      unapprovedItems.first().get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_TILE;

    return [
      cropperModal,
      cropCheckModal,
      <Header
        key="approval-header"
        theme="default"
        title="Preview"
        //rightAction={<Button priority="tertiary" label="Approve" onClick={this.handleGoToBasket}/>}
        leftAction={
          <Button
            disabled={areAnyItemsRendering}
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.handleGoBack(unapprovedItems.first())}
          />
        }
        onClickTitleOrImage={() => {}}
      />,
      <MainContent
        scrollable={true}
        centeredVertically
        padded
        key="main-preview"
        style={{ justifyContent: "space-between" }}
      >
        {!areAnyItemsRendering &&
          (this.state.approvingPendingItems ? (
            <div style={{ marginTop: "-18px" }}>
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                You're almost done. Review your order and confirm you're happy with the crop & print
                quality.
              </p>
            </div>
          ) : (
            <div style={{ marginTop: "-18px" }}>
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                Review your changes & confirm you are happy with the crop & print quality.
              </p>
            </div>
          ))}
        <ProductApproval itemsToApprove={unapprovedItems} />
        {!areAnyItemsRendering &&
          unapprovedItems.first().get("isCustomPrint") &&
          !unapprovedItems.first().get("isPassportPrint") && (
            <p style={{ textAlign: "center", color: "#ff5a5f", fontSize: "11px" }}>
              NOTE: This is a {unapprovedItems.first().get("customPrintDescription")}
              {unapprovedItems.first().get("tiled") ? " tiled " : " "}photo printed on a{" "}
              {customPrintText} sheet. You will need to trim to size.
            </p>
          )}
        {!areAnyItemsRendering && (
          <>
            <Row
              type="flex"
              justify="space-between"
              gutter={8}
              style={{ width: "100%", margin: "0 auto" }}
            >
              {deletionAllowed && showDelEditButtons && (
                <Col span={12}>
                  <Button
                    data-gtm-element="Approval - Delete"
                    type="default"
                    theme="dark-blue"
                    block
                    priority="secondary"
                    size="small"
                    label={deleteLabel}
                    style={{ backgroundColor: "white", borderColor: "#ccc" }}
                    onClick={() => this.handleDeleteItem(unapprovedItems.first())}
                  />
                </Col>
              )}
              {showDelEditButtons && (
                <Col span={12} offset={deletionAllowed ? 0 : 6}>
                  <Button
                    data-gtm-element="Approval - Edit"
                    type="default"
                    theme="dark-blue"
                    block
                    priority="secondary"
                    size="small"
                    label={editLabel}
                    icon={editLabel === "Edit Crop" ? "crop-rotate" : null}
                    iconSize="small"
                    iconPosition="right"
                    style={{ backgroundColor: "white", borderColor: "#ccc" }}
                    onClick={() => this.handleEditItem(unapprovedItems.first())}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </MainContent>,
      <Footer padded key="footer">
        {!areAnyItemsRendering && !isPhotoBook && !isGreetingCard && remainingToApprove}
        {!areAnyItemsRendering && canApprove && (
          <>
            <Row type="flex" justify="space-between" gutter={8} style={{ marginTop: "5px" }}>
              <Col span={24}>
                <Button
                  label={approvalText.approveSingle}
                  theme={picCollage ? "pc" : "dark-blue"}
                  priority={picCollage ? "secondary" : "primary"}
                  data-gtm-element="Approval - Approve"
                  block
                  size="large"
                  style={{ backgroundColor: "#4b5566", borderColor: "#4b5566" }}
                  disabled={false}
                  onClick={() => this.handleShowCropModalOrApprove(unapprovedItems.first())}
                />
              </Col>
            </Row>
            {allowApproveAll && (
              <Row
                type="flex"
                justify="space-between"
                gutter={8}
                style={{ marginTop: "10px", marginBottom: "5px" }}
              >
                <Col span={24}>
                  <Button
                    label={approvalText.approveAll}
                    data-gtm-element={"Approval - Approve All"}
                    theme={picCollage ? "pc" : "dark-blue"}
                    priority="secondary"
                    block
                    size="large"
                    disabled={false}
                    onClick={this.handleApproveAllItems}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {!canApprove && (
          <>
            <p style={{ textAlign: "center", color: "#ff5a5f" }}>
              You have less photos than required to complete your pack. Go back and add{" "}
              {this.state.packQtyMissing} more to proceed.
            </p>
            <Button
              label="Add More Prints"
              theme="dark-blue"
              priority="secondary"
              block
              size="large"
              disabled={false}
              onClick={() => this.handleGoBackAndClear(unapprovedItems.first())}
            />
          </>
        )}
      </Footer>,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  unapprovedItems: basketSelectors.getAllUnapprovedPendingApprovalItems(state),
  approvedItems: basketSelectors.getAllApprovedPendingApprovalItems(state),
  unapprovedBagItems: basketSelectors.getAllUnapprovedItems(state),
});

const mapDispatchToProps = dispatch => ({
  updatePendingApprovalItem: (itemId, itemData) =>
    dispatch(basketActions.updatePendingApprovalItem(itemId, itemData)),
  updateItem: (itemId, itemData) => dispatch(basketActions.updateItem(itemId, itemData)),
  deletePendingApprovalItem: itemId => dispatch(basketActions.deletePendingApprovalItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  goToContactDetails: () => dispatch(push(routeCreators.checkoutContactDetails())),
  setHasChosenGuestRoute: hasChosenGuestRoute =>
    dispatch(authActions.setHasChosenGuestRoute(hasChosenGuestRoute)),
  goToApprovalComplete: itemsAdded => dispatch(push(routeCreators.approvalComplete(), itemsAdded)),
  approvePendingApprovalItem: itemId => dispatch(basketActions.approvePendingApprovalItem(itemId)),
  approveAllItems: itemIds => dispatch(basketActions.approveAllItems(itemIds)),
  approveAllPendingApprovalItems: itemIds =>
    dispatch(basketActions.approveAllPendingApprovalItems(itemIds)),
  approveItems: itemIds => {
    if (itemIds.length) {
      dispatch(basketActions.approveItems(itemIds));
    }
  },
  addPendingApprovalItemToBasket: item =>
    dispatch(basketActions.addPendingApprovalItemToBasket(item)),
  clearPendingApprovalItems: () => dispatch(basketActions.clearPendingApprovalItems()),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
  deleteBasketItems: itemIds => dispatch(basketActions.deleteItems(itemIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalContainer);
