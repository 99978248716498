import React from "react";
//import themes from "./themes.json";
//import themes1 from "./themes1.json";
import colours from "./colours.json";
import "./themesContainer.scss";
import * as LAYER_TYPES from "../../constants/layer-types";
const DEFAULT_BORDER_THICKNESS = 0.045; // 0.035


class ColoursContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: props.item,
    };
  }

  borderConfigForColour = colour => {
    return {
      style: {
        type: "color",
        color: colour,
      },
      thickness: DEFAULT_BORDER_THICKNESS,
    };
  };
  
  
  setBorderColor = colour => {
    console.log("this is set border coloor callled");
    const borderConfig = this.borderConfigForColour(colour);

    this.setState({
      item: this.state.item.withMutations(item => {
        return item.withMutations(item => {
          item.update("layers", layers => {
            return layers.map(layer => {
              if (layer.get("type") === LAYER_TYPES.PHOTO) {
                return layer.setIn(["config", "border"], borderConfig);
              } else {
                return layer;
              }
            });
          });
        });
      }),
    });
  };

  changeTheme = (color) => {
    this.props.setActiveTheme(color)
  };

  // addTheme = (color, isTexture = false, category, textColor = null) => {
  //   console.log("this add theme    callled", color);
  //   // this.props.setBorderColor(
  //   //   color,
  //   //   this.props.pageNumberToAddBorder,
  //   //   isTexture,
  //   //   category,
  //   //   textColor
  //   // );
  //   //this.props.closeThemesModal();
  // };

  render() {
    const { item, pageNumberToAddBorder, isPrint } = this.props;
    let selectedBorder = item
      .toJS()
      .layers.filter(layer => layer.page === pageNumberToAddBorder)[0]?.config.border?.style;
    if (pageNumberToAddBorder === 0){ // cover
      selectedBorder = item
      .toJS()
      .layers.filter(layer => layer.page === pageNumberToAddBorder && layer.id !== "EXTRA_TEXT_LAYER")[0]?.config.border?.style
    }
    if (pageNumberToAddBorder === null){ // global
      selectedBorder = item.toJS().product_options?.globalTheme?.style;
    } 
    let selectedColour = "#FFFFFF";
    
    if(this.props.selectedTheme){
      selectedColour = this.props.selectedTheme.hex
    } else{
      selectedColour = selectedBorder?.color;
    }
        
    let contextText = null;

    if(pageNumberToAddBorder === 0){
      if (isPrint){
        contextText = 'Choose a colour for your frame'
      } else {
        contextText = 'Choose a colour for the cover of your photo book'
      }
    } else if (pageNumberToAddBorder === null){
      contextText = 'Choose a colour for your photo book. Note: this will apply to all pages.'
    } else{
      contextText = `Choose a colour for page ${pageNumberToAddBorder}`
    }


    return (
      <React.Fragment>
        <div>
          {contextText && (
            <div className="colour-info">
              <p>{contextText}</p>
            </div>
          )}
          {selectedColour && (
            <div className={`active-colour-swatch ${selectedColour === '#FFFFFF' ? 'bordered' : ''}`} style={{ backgroundColor: selectedColour }}></div>
          )}
          <div className="colour-swatches-container">
            {colours.map((colour, index) => {
              return (
                <div
                  onClick={() => this.changeTheme(colour)}
                  className={`each-colour-swatch-container ${selectedColour === colour.hex ? 'active' : ''}`}>
                  <div
                    className={`each-colour-swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                    style={{ backgroundColor: colour.hex, color: colour?.textColor }}
                  >
                    {/* <span style={{color:colour.textColor}}>{colour.color}</span> */}
                </div>
                </div>
                
            )})}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ColoursContainer;
