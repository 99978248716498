import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./EditorSignatureColorOption.scss";

const EditorSignatureColorOption = ({ color, active, onClick }) => {
  const classes = classNames("editor__signature-color-option", {
    "editor__signature-color-option--active": active,
  });

  return (
    <div className={classes} style={{ backgroundColor: color }} onClick={onClick}>
      <img
        src={`${process.env.PUBLIC_URL}/images/check-shadow.png`}
        alt="Selected"
        className="editor__signature-color-option__checkmark"
      />
    </div>
  );
};

EditorSignatureColorOption.propTypes = {
  color: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

EditorSignatureColorOption.defaultProps = {
  active: false,
};

export default EditorSignatureColorOption;
