import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";
import axios from "axios";
import shortid from "shortid";
import pullAt from "lodash/pullAt";
import chunk from "lodash/chunk";
import throttle from "lodash/throttle";
import queryString from "query-string";
import gtmEvent from "../../utils/gtm";
import {
  getPricingSchemeForProductAndQuantity,
  getNextPricingSchemeForProductAndQuantity,
  getLowestCostPricingSchemeForProduct,
  getPricingTableForProduct
} from "../../data/pricing-schemes";
import {
  getPostageSchemeForId
} from "../../data/postage-schemes";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { pickImages } from "../../lib/file-uploader";
import getMaxResolutionForDimensions from '../../lib/get-max-resolution-for-dimensions';
import * as designsData from "../../data/designs";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  isTextPrintProduct,
  isRetroPrint,
  isDefaultFinishMatt,
  hasRealBorders,
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  getShippingCountriesForProductType,
} from "../../data/products";

import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as authActions, selectors as authSelectors } from "../../store/ducks/auth";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions, selectors as addressSelectors } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";
import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import Button from "../Button/Button";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import {
  FONT_FAMILIES,
} from "../../constants/text-style-options";
import EditorCropperModal from "../Editor/EditorCropperModal";
import EditPrintModal from "../Editor/EditPrintModal";
import EditorAddressInputModal from "../Editor/EditorAddressInputModal";
import PhotoPrintsPreBag from "./PhotoPrintsPreBag";
import ShippingSummary from "../ShippingSummary/ShippingSummary";
import SweetAlert from "../SweetAlert/SweetAlert";
//import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import { message, Modal as AntModal } from "antd";

const { confirm } = AntModal;


const DEBUG_UPLOADS = [
  {
    "uuid": "7c23643a-6518-42a1-bb67-12ee37151cba",
    "name": "ios-app.jpg",
    "size": 22285,
    "isStored": true,
    "isImage": true,
    "originalImageInfo": {
      "color_mode": "RGB",
      "orientation": null,
      "format": "JPEG",
      "sequence": false,
      "height": 295,
      "width": 425,
      "geo_location": null,
      "datetime_original": null,
      "dpi": null
    },
    "mimeType": "image/jpeg",
    "originalUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba/",
    "cdnUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba/",
    "cdnUrlModifiers": null,
    "sourceInfo": {
      "source": "local",
      "file": {}
    },
    "highResUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba//-/autorotate/yes/",
    "lowResUrl": "https://ucarecdn.com/7c23643a-6518-42a1-bb67-12ee37151cba//-/autorotate/yes//-/resize/400x/"
  }
];

const STEPS = {
  UPLOAD: "UPLOAD",
  CHOOSE_ADDRESS: "CHOOSE_ADDRESS",
};

const DEFAULT_PRODUCT_OPTIONS = {finish: "gloss", border: false };

// 1 = Image size can't be less than Product width/height in MM at Product DPI (300)
// 0.5 = Image size can't be half of the Product width/height in MM at Product DPI (300)
// The higher the value, the more strict it is
// TODO: Add a per product config
const MINIMUM_RESOLUTION_THRESHOLD = 0.4;


function mmToInches(mm) {
  return mm * 0.0393701;
}

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;

  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

class PhotoPrintsPreBagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      step: STEPS.UPLOAD,
      itemIndexForCropper: null,
      draftRotationState: null,
      isUploadModalVisible: false,
      isAddressInputModalVisible: false,
      isCancelConfirmationAlertVisible: false,
      shouldShowInfoMessage: false,
      shouldOpenCaptionModal: false,
      hasNotOpenedCaptionModal: true,
      shouldOpenCaptionModalForItem: null,
      isReplacingImage: false,
      replacingImageIndex: null,
      closeURL: null,
      asGuest: false,
      isShippingSummaryVisible: false,
      returnToShippingSummaryAfterAddressEdit: false,
      addressToEdit: null,
      isEditingFromSummary: false,
      selectedBackground: null,
      backgroundSetForAll: false,
      textSetForAll: false,
      textColourSetForAll: false,
      fontSetForAll: false,
      effectSetForAll: false,
      editModalHasClosed: true,
      editModalCloseTimer: null
    };

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag) {
      this.state.items = existingPrebag.data.items.map(item => fromJS(item));
    }
  }

  editPrintModalRef = React.createRef();

  componentWillMount() {
    //console.log("document.referrer", document.referrer);
    this.setState({
      closeURL: document.referrer || "https://www.postsnap.com/photo-printing",
    });
  }

  componentDidMount() {
    //this.debugUpload().then(() => {
    //  //this.startCropping(0);
    //});
    const product = productsByProductId.get(this.props.productId);
    gtmEvent({
      event: "webAppStage",
      additionalProps: {
        stage: 'Prints Pre-Bag',
        'product': product.get('appKey')
      }
    })

    if (this.state.items.length === 0) {
      this.showUploadModal();
    }

    const applyAllSettingsKeys = [
      'backgroundSetForAll',
      'textSetForAll',
      'textColourSetForAll',
      'fontSetForAll',
      'effectSetForAll'
    ];

    applyAllSettingsKeys.forEach(key => this.loadSettingsFromLocalStorage(key));

    const lastApplyAllSetFromItem = localStorage.getItem("lastApplyAllSetFromItem");
    if (lastApplyAllSetFromItem) {
      this.setState({
        lastApplyAllSetFromItem: fromJS(JSON.parse(lastApplyAllSetFromItem))
      });
    }

  }

  componentWillUpdate() {
    // if(this.state.shouldShowInfoMessage){
    //   const textPrints = isTextPrintProduct(this.props.productId);

    //   let messageContent = textPrints ?
    //     "Tap a photo to crop and rotate. Tap 'Add Text' to add your text" :
    //     (
    //       <div className="crop-notice">
    //         {/* <Avatar size={60} src={`${process.env.PUBLIC_URL}/images/lab-manager.jpg`} /> */}

    //         <p style={{fontSize: '14px', marginTop: '20px'}}>PostSnap's Photo Lab Manager Says...</p>
    //         <div className="quote-and-icon">
    //           <Icon name={"head-crop"} size="full-width"/>
    //           <blockquote className="lab-quote">
    //             <p style={{fontSize: '12px'}}>
    //               Please <strong>check the crop</strong> of your photos before ordering.
    //               <br/>
    //               <span>I've seen far too many chopped off heads!</span>
    //               {/* <span>There's no excuse for cut off heads!</span> */}
    //             </p>
    //           </blockquote>
    //         </div>

    //       </div>
    //     );

    //   this.setState({shouldShowInfoMessage: false}, () => {
    //     message.open({
    //       content: messageContent,
    //       duration: 6.5,
    //       className: 'crop-info',
    //       style: {marginTop: '50px'},
    //       key: "info-message"
    //     });

    //   });
    // }
  }

  componentDidUpdate() {
    if (this.state.items.length > 0) {
      localStorage.setItem(
        STORAGE_KEYS.WIP_PREBAG,
        JSON.stringify({
          productTypeId: this.props.productTypeId,
          productId: this.props.productId,
          productSlug: this.props.productSlug,
          productTypeSlug: this.props.productTypeSlug,
          data: {
            items: this.state.items,
          },
        })
      );
    } else {
      const existingPrebag = getExistingPrebagForProduct(
        this.props.productTypeId,
        this.props.productId
      );

      if (existingPrebag) {
        localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
      }

      localStorage.removeItem("lastApplyAllSetFromItem");
      const applyAllSettingsKeys = [
        'backgroundSetForAll',
        'textSetForAll',
        'textColourSetForAll',
        'fontSetForAll',
        'effectSetForAll'
      ];
      applyAllSettingsKeys.forEach(key => localStorage.removeItem(key));
      // this.setState({
      //   lastApplyAllSetFromItem: null
      // });

    }
  }

  checkForFaceChopping = async (images) => {
    await images.forEach(async (image) => {
      // Todo detect what will be autocropped based on central cropping..
      const faceYThreshold = 20
      await axios.get(`https://ucarecdn.com/${image.uuid}/detect_faces/`).then((response) => {
        if (response.data.faces && response.data.faces.length > 0){
          if (response.data.faces.some(face => face[1] <= faceYThreshold)){ // [x,y,x_size,y_size]
            //console.log("Check for face chopping", response.data.faces)
            //console.log("Face Y =", response.data.faces[0][1])
            const updateItem = this.state.items.find(item => {
              const indexOfTextLayer = item.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.PHOTO));
              return item.getIn(["layers", indexOfTextLayer, "config", "layout", 0, "image", "src", "uploadcareUuid"]) === image.uuid;
            })
            if(updateItem){
              this.setState({
                items: this.state.items.map((item, index) => {
                  if (item.get('id') === updateItem.get('id')) {
                    return item.set("potentialFaceChop", true);
                  }
                  return item;
                }),
              });
            }
          }
        }
      });
    });

  }

  infoMessageDismissed = () => {
    this.setState({
      shouldShowInfoMessage: false
    })
  }

  isLowResImage = (productDimensions, imageWidth, imageHeight) => {
    const isLandscapeProduct = productDimensions.width > productDimensions.height;
    const isInPortrait = imageWidth < imageHeight;
    const isRotated = isInPortrait && isLandscapeProduct;
    const productWidth = isRotated ? productDimensions.height : productDimensions.width;
    const productHeight = isRotated ? productDimensions.width : productDimensions.height;

    const productDimensionsInPixels = {
      width: mmToInches(productWidth) * productDimensions.dpi,
      height: mmToInches(productHeight) * productDimensions.dpi,
    };
    //console.log("Min width", productDimensionsInPixels.width * MINIMUM_RESOLUTION_THRESHOLD);
    //console.log("Actual width", imageWidth);
    //console.log("Min height", productDimensionsInPixels.height * MINIMUM_RESOLUTION_THRESHOLD);
    //console.log("Actual height", imageHeight);
    const isLowRes = (
      productDimensionsInPixels.width * MINIMUM_RESOLUTION_THRESHOLD > imageWidth ||
      productDimensionsInPixels.height * MINIMUM_RESOLUTION_THRESHOLD > imageHeight
    );
    return isLowRes
  }

  fontSizeForFont = (fontName) => {
    const font = FONT_FAMILIES.find((font) => font.name === fontName);
    return font?.defaultFontSize ?? 40;
  }

  generateEditorItemsForImages = async images => {
    const { productTypeId, productId } = this.props;
    const product = productsByProductId.get(productId);

    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    const parsedQueryString = queryString.parse(window.location.search);
    let isProductLandscape = parseFloat(product.get("width")) > parseFloat(product.get("height"));
    const horizontalBleed = parseFloat(product.get("bleed_l")) + parseFloat(product.get("bleed_r"));
    const verticalBleed = parseFloat(product.get("bleed_t")) + parseFloat(product.get("bleed_b"));
    const isSquareProduct = parseFloat(product.get("width")) + horizontalBleed === parseFloat(product.get("height")) + verticalBleed;

    let existingProductOptions = this.state.items.length > 0 ? this.state.items[0].get("product_options").toJS() : false;
    if (product.get("appKey") === "RETRO_PRINTS_C") {
      // Ensure new retros dont get existing options
      existingProductOptions = false
    }

    if (this.props.existingPhotoPrintItems.size > 0 ){
      console.log("Has existing prints...")

      if (this.props.existingPhotoPrintItems.some((existingPrint) => existingPrint.get('productId') === productId) && product.get("appKey") !== "RETRO_PRINTS_C") {
        console.log("Has existing prints with same productId");
        const existingPrintsWithSameProduct = this.props.existingPhotoPrintItems.find((existingPrint) => existingPrint.get('productId') === productId)
        existingProductOptions = existingPrintsWithSameProduct.get("product_options").toJS();
      }
    }

    // if (this.state.items.length > 0){
    //   console.log(this.state.items[0].get("product_options").toJS());
    // }

    //console.log("Existing product options", existingProductOptions);

    // For 12"/A4 or greater images, use pure image crop render
    const imageCrop = (product.get("width") >= 297 || product.get("height") >= 297) && product.get('caption_print') !== true; // 400

    let defaultProductOptions = isDefaultFinishMatt(this.props.productId) ? {finish: "matt", border: false } : DEFAULT_PRODUCT_OPTIONS

    const isFineArtPrint = product.get('fine_art_print');

    if(isFineArtPrint){
      defaultProductOptions = { border: false }
    }

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: existingProductOptions || (parsedQueryString.finish ? {finish: parsedQueryString.finish} : false) || defaultProductOptions,
      postDate: moment(),
      duplicateAlertShown: true,
      imageCrop: imageCrop,
      isColouredRetro: product.get("appKey") === "RETRO_PRINTS_C"
    });

    const realBorderProduct = hasRealBorders(this.props.productId);
    const hasBordersOn = existingProductOptions.border === true;

    const indexOfPhotoLayer = itemData
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    let itemsWithImage;
    itemsWithImage = images.map((image) => {
      let isImageLandscape = image.originalImageInfo.width >= image.originalImageInfo.height;
      let isImageSquare = image.originalImageInfo.width === image.originalImageInfo.height

      const dimensions = itemData.get('productDimensions').toJS();
      const isLowRes = this.isLowResImage(dimensions, image.originalImageInfo.width, image.originalImageInfo.height);
      // Rotating image based on Exif...
      //console.log("Image info", image);

      const isCMYK = image.originalImageInfo.color_mode === "CMYK"
      const portraitExifs = [8,6] //https://www.impulseadventure.com/photo/exif-orientation.html

      //console.log("isImageLandscape", isImageLandscape);
      //console.log("orientation", image.originalImageInfo.orientation);
      if (portraitExifs.includes(image.originalImageInfo.orientation)){
        //console.log("Portrait orientation from EXIF");
        if (isImageLandscape === true && !isImageSquare){
          console.log("Portrait orientation for landscape image ... turning off auto-rotate...");
          image.highResUrl = image.highResUrl.replace("autorotate/yes/", "autorotate/no/");
          image.lowResUrl = image.lowResUrl.replace("autorotate/yes/", "autorotate/no/");
        } else{
          console.log("Portrait orientation for portrait image, keeping autorotate on");
        }
        console.log("Is portrait")

        if (isImageSquare && isImageLandscape === true){
          console.log("Portrait orientation for square image ... turning off auto-rotate...");
          image.highResUrl = image.highResUrl.replace("autorotate/yes/", "autorotate/no/");
          image.lowResUrl = image.lowResUrl.replace("autorotate/yes/", "autorotate/no/");
        }


        if(!isImageSquare){
          isImageLandscape = false;
        }





      } else if (image.originalImageInfo.orientation !== null){
        if (image.originalImageInfo.orientation === 1){
          console.log("Inferred from width > height: isImageLandscape", isImageLandscape);
        } else{
          isImageLandscape = true;
          console.log("Landscape photo")
        }
      }
      // console.log("isSquareProduct", isSquareProduct)
      const isPortraitInSquare = !isImageLandscape && isSquareProduct

      let isRotated = !isSquareProduct && isProductLandscape && !isImageLandscape

      const product = productsByProductId.get(this.props.productId);
      if (product.get("fine_art_print")){
        isRotated = false
      }

      //Special case for 5.3×4 prints
      if (productsByProductId.getIn([this.props.productId, "appKey"]) === "5.3×4_PRINTS"){
        if (isImageLandscape){
          isRotated = true
        }
      }
      const isRetroPrint = ["RETRO_PRINTS", "2×2.5_PRINTS", "RETRO_PRINTS_C"].includes(productsByProductId.getIn([this.props.productId, "appKey"]))
      const isWideRetroPrint = "6X4.5_TEXT_PRINTS" === productsByProductId.getIn([this.props.productId, "appKey"])
      const isPortraitInRetroPrint = !isImageLandscape && isRetroPrint
      //const isPortraitImage = !isImageLandscape
      // console.log("isPortraitInSquare", isPortraitInSquare)
      let cropData = null
      if (isPortraitInSquare || isPortraitInRetroPrint){
        let dimensionsAsPx = getMaxResolutionForDimensions({
          width: parseFloat(product.get("width")) + horizontalBleed,
          height: parseFloat(product.get("height")) + verticalBleed,
          dpi: product.get('dpi'),
        });
        const PREVIEW_CROPPER_NODE_WIDTH = 500
        let heightRatio = image.originalImageInfo.height/image.originalImageInfo.width
        let transformHeight = PREVIEW_CROPPER_NODE_WIDTH*heightRatio
        cropData = {
          "x": 0,
          "y": 0,
          "width": dimensionsAsPx.split("x")[0],
          "height": dimensionsAsPx.split("x")[1],
          "rotate": 0,
          "scaleX": 1,
          "scaleY": 1,
          "transform": {
            "containerWidth": PREVIEW_CROPPER_NODE_WIDTH,
            "containerHeight": PREVIEW_CROPPER_NODE_WIDTH,
            "width": PREVIEW_CROPPER_NODE_WIDTH,
            "height": transformHeight,
            "translateX": 0,
            "translateY": 0,
            "xPercent": 0,
            "yPercent": 0
          }
        }
      }

      if (isRotated && !isWideRetroPrint){
        let dimensionsAsPx = getMaxResolutionForDimensions({
          width: parseFloat(product.get("width")) + horizontalBleed,
          height: parseFloat(product.get("height")) + verticalBleed,
          dpi: product.get('dpi'),
        });
        const PREVIEW_CROPPER_NODE_WIDTH = 500
        let heightRatio = image.originalImageInfo.height/image.originalImageInfo.width
        let transformHeight = PREVIEW_CROPPER_NODE_WIDTH*heightRatio
        let productAspectRatio = dimensionsAsPx.split("x")[0]/dimensionsAsPx.split("x")[1]
        if(heightRatio > productAspectRatio){
          cropData = {
            "x": 0,
            "y": 0,
            "width": dimensionsAsPx.split("x")[1],
            "height": dimensionsAsPx.split("x")[0],
            "rotate": 0,
            "scaleX": 1,
            "scaleY": 1,
            "transform": {
              "containerWidth": PREVIEW_CROPPER_NODE_WIDTH,
              "containerHeight": PREVIEW_CROPPER_NODE_WIDTH * productAspectRatio,
              "width": PREVIEW_CROPPER_NODE_WIDTH,
              "height": transformHeight,
              "translateX": 0,
              "translateY": 0,
              "xPercent": 0,
              "yPercent": 0
            }
          }
        }
      }

      return itemData.withMutations(item => {
        if (isLowRes){
          this.triggerIsLowResEvent()
        }
        item.set("isLowRes", isLowRes);
        item.set("isCMYK", isCMYK);
        item.set('id', shortid.generate());
        if(isTextPrintProduct(product.get("id"))) { // TODO -> use a product attribute or app key
          item.set("isRotated", false);
        } else {
          //console.log("isRotated", !isSquareProduct && isProductLandscape && !isImageLandscape);
          item.set("isRotated", isRotated);
        }
        item.setIn(
          ["layers", indexOfPhotoLayer, "config", "layout", 0, "image"],
          fromJS({
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            originalSize: {
              width: image.originalImageInfo.width,
              height: image.originalImageInfo.height,
            },
            originalInfo: {
              ...image.originalImageInfo,
              uuid: image.uuid,
              size: image.size
            },
            cropData: cropData,
          })
        );

        // Set bg to white for retros
        if (product.get("appKey") === "RETRO_PRINTS_C"){
          item.setIn(
            ["layers", indexOfPhotoLayer, "config", "background"],
            fromJS(
              { "colour":
               {
                "category": "PASTEL",
                "color": "White",
                "hex": "#FFFFFF",
                "textColor": "#000000"
              }
            })
          );
          const indexOfTextLayer = item.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
          item.setIn(
            ["layers", indexOfTextLayer, "config", "font"],
            "Coustard"
          );
        }

        // Apply any global personalisation config
        if (this.state.items.length > 0 && this.state.lastApplyAllSetFromItem){
          // if for each global flag...
          let copyItem = this.state.lastApplyAllSetFromItem
          // if applyAllBgon
          if (this.state.backgroundSetForAll){
            let copyItemBackground = copyItem.toJS().layers.find(layer => layer.type === LAYER_TYPES.PHOTO).config.background
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "background"],
              fromJS(copyItemBackground)
            );
          }

          // if applyEffectsOn

          //if applyAllTextConfigOn
          if (this.state.textColourSetForAll){
            const indexOfCopyTextLayer = copyItem.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            let copyItemTextColour = copyItem.getIn(["layers", indexOfCopyTextLayer, "config", "color"])
            const indexOfTextLayer = item.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            item.setIn(
              ["layers", indexOfTextLayer, "config", "color"],
              copyItemTextColour
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "size"],
              this.fontSizeForFont(item.getIn(["layers", indexOfTextLayer, "config", "font"]))
            );
          }

          if (this.state.fontSetForAll){
            const indexOfCopyTextLayer = copyItem.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            let copyItemFont = copyItem.getIn(["layers", indexOfCopyTextLayer, "config", "font"])
            const indexOfTextLayer = item.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            item.setIn(
              ["layers", indexOfTextLayer, "config", "font"],
              copyItemFont
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "size"],
              this.fontSizeForFont(copyItemFont)
            );
          }

          if (this.state.textSetForAll){
            const indexOfCopyTextLayer = copyItem.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            let copyItemText = copyItem.getIn(["layers", indexOfCopyTextLayer, "config", "text"])
            const indexOfTextLayer = item.get("layers").findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
            item.setIn(
              ["layers", indexOfTextLayer, "config", "text"],
              copyItemText
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "size"],
              this.fontSizeForFont(item.getIn(["layers", indexOfTextLayer, "config", "font"]))
            );
          }

          if (this.state.effectSetForAll){
            let copyItemEffect = copyItem.getIn(["product_options", "effects"])
            item.setIn(
              ["product_options", "effects"],
              copyItemEffect
            );
          }
        }

        if (realBorderProduct && hasBordersOn){
          const borderConfig = {
            style: {
              type: "color",
              color: "rgb(255,255,255)",
            },
            thickness: this.borderThicknessForItem(item) // 6mm/54mm = 0.111
          }
          item.setIn(
            ["layers", indexOfPhotoLayer, "config", "border"],
            fromJS(borderConfig)
          );
          item.setIn(
            ["product_options", "border"],
            true
          );
        }
      });
    });

    return itemsWithImage;
  };

  triggerStartUpload = (product, source) => {
    gtmEvent({
      event: "webAppStage",
      additionalProps: {
        stage: 'Prints Photo Upload Started',
        product: product.get('appKey'),
        source: source
      }
    })
  }

  triggerIsLowResEvent = () => {
    gtmEvent({event: "webAppEvent", additionalProps: {eventName: "Low Res Image"}})
  }

  pickImageFromUploadcare = async source => {
    try {
      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };

      const hasMinimumQty = hasMinimumQuantityForProduct(this.props.productId);
      const suggestedAmount = hasMinimumQty ? minimumQuantityForProduct(this.props.productId) : 100;

      const currentQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);

      let amount = suggestedAmount - currentQuantity;

      if (currentQuantity >= suggestedAmount){
        amount = 100 - currentQuantity //suggestedAmount-(currentQuantity-suggestedAmount);
        console.log("Amount is", amount);
      }

      const product = productsByProductId.get(this.props.productId);

      const productWidth =  (product.get('width') + parseInt(product.get('bleed_l')) + parseInt(product.get('bleed_r')));
      const productHeight =  (product.get('height') + parseInt(product.get('bleed_t')) + parseInt(product.get('bleed_b')));

      let maxWidthOrHeight = Math.max(productWidth, productHeight);

      if (maxWidthOrHeight <= 54) {
        console.log(`Bumping small print (${maxWidthOrHeight}mm) maxResolution quality by 1.5`)
        maxWidthOrHeight = maxWidthOrHeight * 1.5;
      }

      // TODO - we want productDimensions including bleed ideally
      let maxResolution = getMaxResolutionForDimensions({
        width: maxWidthOrHeight,
        height: maxWidthOrHeight,
        dpi: product.get('dpi'),
      });

      // TODO: check the 100% is needed and not the default
      maxResolution = `${maxResolution} 100%`

      if (maxWidthOrHeight <= 100) {
        maxResolution = false;
      }
      maxResolution = false;

      let images = await pickImages({
        amount: amount,
        source,
        maxResolution,
        onTotalProgressUpdate: throttle(progressHandler, 500),
        autoRotate: false,
        onStartUpload: () => this.triggerStartUpload(product, source)
      });

      if(amount === 1){
        images = new Array(1).fill(images)
      }

      const generatedItems = await this.generateEditorItemsForImages(images);

      const isFirstSetOfItems = this.state.items.length === 0;

      if (this.state.replacingImageIndex !== null){

        this.setState({
          items: this.state.items.map((item, index) => {
            if (index === this.state.replacingImageIndex) {
              return fromJS(generatedItems[0])
            }
            return item;
          }),
          replacingImageIndex: null,
        });

      } else{
        this.setState({
          items: [...this.state.items, ...generatedItems],
          shouldShowInfoMessage : isFirstSetOfItems
        }, async () => {
          await this.checkForFaceChopping(images);
        });
      }
      this.closeUploadModal({withPhotos: true});

      // setTimeout(() => {
      //   this.openCaptionModal()
      // }, 300);


    } catch (err) {
      console.log("Cancelled upload:", err);
    }
  };

  debugUpload = async () => {
    const debugImages = new Array(1).fill(DEBUG_UPLOADS[0])
    const generatedItems = await this.generateEditorItemsForImages(debugImages);
    this.setState({
      items: [...this.state.items, ...generatedItems],
      shouldShowInfoMessage : true,
    });
    this.closeUploadModal({withPhotos: true});
  };

  showUploadModal = () => {
    this.setState({ isUploadModalVisible: true }, () => {
      const product = productsByProductId.get(this.props.productId);
      gtmEvent({
        event: "webAppStage",
        additionalProps: {
          stage: 'Prints Photo Upload Opened',
          'product': product.get('appKey')
        }
      })
    });
  };

  openCaptionModal = () => {
    const product = productsByProductId.get(this.props.productId);
    this.setState({
      shouldOpenCaptionModal: this.state.hasNotOpenedCaptionModal === true ? (product.get('caption_print') ? true : false) : false,
      shouldOpenCaptionModalForItem: this.state.items.at(-1).get('id')
    }, () => {
      this.setState({
        hasNotOpenedCaptionModal: false
      })
    })
  }

  closeUploadModal = ({withPhotos = false}) => {
    this.setState({ isUploadModalVisible: false, hasNotOpenedCaptionModal: true }, () => {
      if(withPhotos){
        const product = productsByProductId.get(this.props.productId);
        gtmEvent({
          event: "webAppStage",
          additionalProps: {
            stage: 'Prints Photo Upload Complete',
            'product': product.get('appKey')
          }
        })
      }
    });
  };

  approveCurrentPrints = () => {
    if (this.state.isEditModalVisible){
      console.log("Modal still open...")
      return false
    }
    if (this.props.existingPhotoPrintItems.size > 0) {
      const existingPrint = this.props.existingPhotoPrintItems.find(item => item.getIn(["postageScheme", "cost"]));
      if (existingPrint){
        const existingPostageScheme = existingPrint.get('postageScheme')
        const existingAddress = existingPrint.get('address') // address book entry too...
        this.setState({
          items: this.state.items.map((item) => {
            return item.withMutations(item => {
              item.set("postageScheme", fromJS(existingPostageScheme)).set('setPostageScheme', true);
              item.set("address", fromJS(existingAddress))
            });
          }),
        }, () => {
          this.finalizePreBag()
        });
      } else {
        this.finalizePreBag()
      }

    } else {
      this.finalizePreBag()
    }


  }

  checkForEmptyPrintsThenShowAddressInputModal = () => {
    message.destroy();
    if(this.props.isSignedIn){
      this.showAddressInputModalOrUseExisting(false)
    } else{
      //console.log("guest details",this.props.hasGuestDetails);

      if(this.props.hasGuestDetails){
        this.showAddressInputModalAfterGuest()
        // this.props.showGuestCaptureOrAuthModal({
        //   actionAfterGuestInfo: this.showAddressInputModalAfterGuest,
        //   actionAfterAuth: this.showAddressInputModalAfterAuth
        // })
      } else {
        this.props.showGuestCaptureOrAuthModal({
          actionAfterGuestInfo: this.showAddressInputModalAfterGuest,
          actionAfterAuth: this.showAddressInputModalAfterAuth
        })
      }
    }

    /*const textPrints = isTextPrintProduct(this.props.productId);
    const someEmptyTextPrints = this.state.items.some(item => {
      const indexOfTextLayer = item
        .get("layers")
        .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
      const text = item.getIn(["layers", indexOfTextLayer, "config", "text"])
      return text === ""
    })

    if (textPrints && someEmptyTextPrints) {
      confirm({
        title: 'Are you sure? 🤔',
        content: 'Some of your text prints have no text, are you sure you want to continue without any text?',
        okText: 'Continue',
        cancelText: 'Stay & add text',
        onOk: () => {
          this.showAddressInputModal()
        },
        onCancel: () => {
          console.log("Cancel")
        },
      });
    } else {
      this.showAddressInputModal()
    }*/
  }

  showAddressInputModalAfterGuest = () => {
    this.showAddressInputModalOrUseExisting(true)
  }

  showAddressInputModalAfterAuth = () => {
    this.showAddressInputModalOrUseExisting(false)
  }

  showAddressInputModal = (initializeForGuest = false) => {
    console.log("Address Input Visible", initializeForGuest);
    this.setState({ isAddressInputModalVisible: true, asGuest: initializeForGuest }, () => {
      const product = productsByProductId.get(this.props.productId);
      gtmEvent({
        event: "webAppStage",
        additionalProps: {
          stage: 'Shipping Address',
          'product': product.get('appKey')
        }
      })
    });
  }

  showAddressInputModalOrUseExisting = (initializeForGuest = false) => {
    if (this.props.existingPhotoPrintItems.size > 0) {
      const firstPhotoPrint = this.props.existingPhotoPrintItems.first();
      if (firstPhotoPrint.get("addressBookId")) {
        this.handleSelectAddressBookEntry(firstPhotoPrint.get("addressBookId"));
      } else {
        this.handleSaveAddress(firstPhotoPrint.get("address").toJS());
      }
    } else {
      this.showAddressInputModal(initializeForGuest)
    }
  };

  returnToShippingSummaryIfRequired = () => {
    if (this.state.returnToShippingSummaryAfterAddressEdit === true){
      this.setState({
        returnToShippingSummaryAfterAddressEdit: false
      }, () => {
        this.showShippingSummaryIfRequiredOrFinalize()
      })
    }
  }

  closeAddressInputModal = () => {
    this.setState({ isAddressInputModalVisible: false }, () => {
      this.returnToShippingSummaryIfRequired()
    });
  };

  showCancelConfirmationAlert = () => {
    if (this.state.items.length > 0) {
      confirm({
        title: 'Are you sure?',
        content: 'You have unsaved changes, are you sure you want to go back?',
        okText: 'Stay',
        cancelText: 'Exit & lose changes',
        icon: null,
        onOk: () => {
          this.closeCancelConfirmationAlert();
        },
        onCancel: () => {
          this.navigateBack();
        },
      });
    } else {
      this.navigateBack();
    }
  };

  closeCancelConfirmationAlert = () => {
    this.setState({ isCancelConfirmationAlertVisible: false });
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    if(this.state.closeURL){
      window.location = this.state.closeURL;
    } else {
      window.history.back();
    }
  };

  adjustItemQuantity = (itemIndex, amount) => {
    this.setState({
      items: this.state.items.map((item, index) => {
        if (index === itemIndex) {
          return item.update("quantity", qty => Math.max(1, qty + amount));
        }

        return item;
      }),
    });
  };

  resetHeadingHeight = (itemId, layerId, height) => {
    console.log("reset heading height")
    let changedItem;
    this.setState({
      items: this.state.items.map((item) => {
        if (itemId === item.get('id')) {
          const indexOfHeadingCaptionLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("id") === layerId));
          console.log(layerId, height)
          changedItem = item.withMutations(item => {
              item.setIn(
                ["layers", indexOfHeadingCaptionLayer, "config", "rect", "height"],
                height
              );
              item.setIn(
                ["layers", indexOfHeadingCaptionLayer, "config", "hasChangedHeight"],
                false
              );
          });
          return changedItem;
        }
        return item;
      }),
    });
    return changedItem;
  }

  doubleHeadingHeight = (itemId, layerId, height, oldHeight) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item) => {
        if (itemId === item.get('id')) {
          const indexOfHeadingCaptionLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("id") === layerId));
          changedItem = item.withMutations(item => {
              item.setIn(
                ["layers", indexOfHeadingCaptionLayer, "config", "rect", "height"],
                height
              );
              item.setIn(
                ["layers", indexOfHeadingCaptionLayer, "config", "rect", "oldHeight"],
                oldHeight
              );
              item.setIn(
                ["layers", indexOfHeadingCaptionLayer, "config", "hasChangedHeight"],
                true
              );
          });
          return changedItem;
        }
        return item;
      }),
    });
    return changedItem;
  }

  changeTextContentForItem = async (itemId, textConfig) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item)  => {
        if (itemId === item.get('id')) {
          let indexOfTextLayer = item
            .get("layers")
            .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfTextLayer, "config", "text"],
              textConfig.text
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "size"],
              this.fontSizeForFont(textConfig.font)
            );
          });
          return changedItem;
        }
        return item;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      })
    });
    return changedItem;
  };

  changeTextContentForAll = async (itemId) => {
    let changedItem;
    let item = this.state.items.find(item => item.get('id') === itemId)
    let indexOfTextLayer = item.get("layers")
    .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    let text = item.getIn(["layers", indexOfTextLayer, "config", "text"]);

    this.setState({
      items: this.state.items.map((item)  => {
        let indexOfTextLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
        changedItem = item.withMutations(item => {
          item.setIn(
            ["layers", indexOfTextLayer, "config", "text"],
            text
          );
          item.setIn(
            ["layers", indexOfTextLayer, "config", "size"],
            this.fontSizeForFont(item.getIn(["layers", indexOfTextLayer, "config", "font"]))
          );
        });
        return changedItem;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      }, () => {
        this.updateLastApplyAllSetFromItem()
      })
    });
    return changedItem;
  };

  changeTextColourForItem = async (itemId, colour) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item)  => {
        if (itemId === item.get('id')) {
          let indexOfTextLayer = item
            .get("layers")
            .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfTextLayer, "config", "color"],
              colour
            );
          });
          return changedItem;
        }
        return item;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      })
    });
    return changedItem;
  };

  changeTextColourForAll = async (itemId) => {
    let changedItem;
    let item = this.state.items.find(item => item.get('id') === itemId)
    let indexOfTextLayer = item.get("layers")
    .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    let textColour = item.getIn(["layers", indexOfTextLayer, "config", "color"])
    console.log("textColour", textColour)
    this.setState({
      items: this.state.items.map((item)  => {
        let indexOfTextLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
        changedItem = item.withMutations(item => {
          item.setIn(
            ["layers", indexOfTextLayer, "config", "color"],
            textColour
          );
        });
        return changedItem;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      }, () => {
        this.updateLastApplyAllSetFromItem()
      })
    });
    return changedItem;
  };

  changeFontForItem = async (itemId, font) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item)  => {
        if (itemId === item.get('id')) {
          let indexOfTextLayer = item
            .get("layers")
            .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfTextLayer, "config", "font"],
              font
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "size"],
              this.fontSizeForFont(item.getIn(["layers", indexOfTextLayer, "config", "font"]))
            );
          });
          return changedItem;
        }
        return item;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      })
    });
    return changedItem;
  };

  changeEffectForItem = async (itemId, effect) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item, index) => {
        if (itemId === item.get('id')) {
          changedItem = item.withMutations(item => {
            item.setIn(["product_options", "effects"], effect)
          });
          return changedItem;
        }
        return item
      })
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      })
    });
    return changedItem;
  };

  changeEffectForAll = async (itemId, effect) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item)  => {
        changedItem = item.withMutations(item => {
          item.setIn(["product_options", "effects"], effect);
        });
        return changedItem;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      }, () => {
        this.updateLastApplyAllSetFromItem()
      })
    });
    return changedItem;
  };

  changeFontForAll = async (itemId) => {
    let changedItem;
    let item = this.state.items.find(item => item.get('id') === itemId)
    let indexOfTextLayer = item.get("layers")
    .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    let font = item.getIn(["layers", indexOfTextLayer, "config", "font"])
    console.log("font", font)
    this.setState({
      items: this.state.items.map((item)  => {
        let indexOfTextLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
        changedItem = item.withMutations(item => {
          item.setIn(
            ["layers", indexOfTextLayer, "config", "font"],
            font
          );
          item.setIn(
            ["layers", indexOfTextLayer, "config", "size"],
            this.fontSizeForFont(item.getIn(["layers", indexOfTextLayer, "config", "font"]))
          );
        });
        return changedItem;
      }),
    }, () => {
      this.setState({
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      }, () => {
        this.updateLastApplyAllSetFromItem()
      })
    });
    return changedItem;
  };

  changeTextForItem = async (itemId, textConfig) => {
    let changedItem;
    let headingLayerId;
    let headingLayerResetHeight;
    let headingLayerOriginalHeight;
    let resetHeadingHeight = false
    let doubleHeadingHeight = false
    this.setState({
      items: this.state.items.map((item)  => {
        if (itemId === item.get('id')) {
          let indexOfTextLayer = 0
          let subHeadingEmpty = false

          if(textConfig.captionText){
            let textLayers = item.get("layers").filter(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ))
            let sortedTextLayersByY = textLayers.sort((a, b) =>  b.getIn(["config","rect", "y"]) - a.getIn(["config","rect", "y"]))
            let captionHeadingLayer = sortedTextLayersByY.first()
            let captionSubheadingLayer = sortedTextLayersByY.last()

            // if editing heading
              // if subheading empty double heading height
              // else reset heading height to old if needed

            // if editing subheading
              // if subheading going to be empty, double heading height
              // else reset heading height to old if needed


            if (textConfig.captionHeading){
              console.log("Editing heading...")
              // if subheading empty double heading height
              subHeadingEmpty = captionSubheadingLayer.getIn(["config", "text"]) === ""
              console.log(captionSubheadingLayer.getIn(["config", "text"]))
              headingLayerId = captionHeadingLayer.get('id')
              if (subHeadingEmpty){
                console.log("Subheading empty")
                doubleHeadingHeight = true
                headingLayerId = captionHeadingLayer.get('id')
                console.log("hasChangedHeight", captionHeadingLayer.getIn(["config", "hasChangedHeight"]))
                console.log("heading height", captionHeadingLayer.getIn(["config","rect", "height"]))
                console.log("subheading height", captionSubheadingLayer.getIn(["config","rect", "height"]))
                let headingHeight = captionHeadingLayer.getIn(["config", "hasChangedHeight"]) === true ? captionHeadingLayer.getIn(["config", "rect", "oldHeight"]) : captionHeadingLayer.getIn(["config","rect","height"])
                console.log("headingHeight", headingHeight)
                const fullHeadingHeight = headingHeight + captionSubheadingLayer.getIn(["config","rect", "height"])
                headingLayerResetHeight = fullHeadingHeight
                headingLayerOriginalHeight = headingHeight //captionHeadingLayer.getIn(["config","rect", "height"])
                console.log("Set heading height to", headingLayerResetHeight)
                console.log("Set heading height original to", headingLayerOriginalHeight)
              } else if (captionHeadingLayer.getIn(["config", "hasChangedHeight"]) === true){
                console.log("Subheading not empty and height changed..")
                resetHeadingHeight = true
                headingLayerResetHeight = captionHeadingLayer.getIn(["config", "rect", "oldHeight"])
                console.log("Reset heading height to", headingLayerResetHeight)
              }
              let captionHeadingIndex = item.get("layers").findIndex(layer => layer.get('id') === captionHeadingLayer.get('id'))
              indexOfTextLayer = captionHeadingIndex


            } else if (textConfig.captionSubheading){
              console.log("Editing subheading...")
              headingLayerId = captionHeadingLayer.get('id')

              if (textConfig.text === ""){
                console.log("Subheading going to be empty")
                doubleHeadingHeight = true
                console.log(captionHeadingLayer.toJS())
                console.log("hasChangedHeight", captionHeadingLayer.getIn(["config", "hasChangedHeight"]))
                console.log("heading height", captionHeadingLayer.getIn(["config","rect", "height"]))
                console.log("subheading height", captionSubheadingLayer.getIn(["config","rect", "height"]))
                let headingHeight = captionHeadingLayer.getIn(["config", "hasChangedHeight"]) === true ? captionHeadingLayer.getIn(["config","rect", "oldHeight"]) : captionHeadingLayer.getIn(["config","rect","height"])
                console.log("headingHeight", headingHeight)
                const fullHeadingHeight = headingHeight + captionSubheadingLayer.getIn(["config","rect", "height"])
                headingLayerResetHeight = fullHeadingHeight
                headingLayerOriginalHeight = headingHeight
                console.log("Set heading height to", headingLayerResetHeight)
                console.log("Set heading height original to", headingLayerOriginalHeight)
              } else if (captionHeadingLayer.getIn(["config", "hasChangedHeight"]) === true){
                console.log("Subheading not going to be empty and heading height changed.. reset height")
                console.log("textConfig.text", textConfig.text)
                resetHeadingHeight = true
                headingLayerResetHeight = captionHeadingLayer.getIn(["config", "rect", "oldHeight"])
                console.log("Reset heading height to", headingLayerResetHeight)
              }
              let captionSubheadingIndex = item.get("layers").findIndex(layer => layer.get('id') === captionSubheadingLayer.get('id'))
              indexOfTextLayer = captionSubheadingIndex
            }
          } else{
            indexOfTextLayer = item
            .get("layers")
            .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
          }

          //console.log("textConfig.alignment", textConfig.alignment)
          //console.log("textConfig.size", item.getIn(["layers", indexOfTextLayer, "config", "size"]))

          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfTextLayer, "config", "text"],
              textConfig.text
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "color"],
              textConfig.color
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "lock"],
              true
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "font"],
              textConfig.font || "Open Sans"
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "hasBeenCustomised"],
              true
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "weight"],
              textConfig.weight
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "alignment"],
              textConfig.alignment || "center"
            );
            if (!textConfig.captionHeading){
              item.setIn(
                ["layers", indexOfTextLayer, "config", "size"],
                textConfig.size || 22
              );
            }

            item.setIn(
              ["layers", indexOfTextLayer, "config", "captionText"],
              textConfig.captionText || null
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "captionHeading"],
              textConfig.captionHeading || null
            );
            item.setIn(
              ["layers", indexOfTextLayer, "config", "captionSubheading"],
              textConfig.captionSubheading || null
            );

            // if(subHeadingEmpty){
            //   item.setIn(
            //     ["layers", indexOfTextLayer, "config", "rect", "height"],
            //     fullHeadingHeight
            //   );
            //   item.setIn(
            //     ["layers", indexOfTextLayer, "config", "rect", "oldHeight"],
            //     oldHeadingHeight
            //   );
            //   item.setIn(
            //     ["layers", indexOfTextLayer, "config", "hasChangedHeight"],
            //     true
            //   );
            // }
          });
          return changedItem;
        }
        return item;
      }),
    }, () => {
      if (resetHeadingHeight){
        console.log("Resetting heading height to:", headingLayerResetHeight)
        this.resetHeadingHeight(itemId, headingLayerId, headingLayerResetHeight)
      }
      if (doubleHeadingHeight){
        console.log("Setting heading height to:", headingLayerResetHeight)
        console.log("Setting original height to:", headingLayerOriginalHeight)
        this.doubleHeadingHeight(itemId, headingLayerId, headingLayerResetHeight, headingLayerOriginalHeight)
      }
    });
    return changedItem;
  };

  changeDrawingForItem = async (itemId, drawingConfig) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item) => {
        if (itemId === item.get('id')) {
          console.log("Changing", itemId);
          const indexOfSignatureLayer = item
          .get("layers")
          .findIndex(layer => (layer.get("type") === LAYER_TYPES.SIGNATURE));
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfSignatureLayer, "config", "drawing"],
              {image : drawingConfig.drawing, data: drawingConfig.data}
            );
            item.setIn(
              ["layers", indexOfSignatureLayer, "config", "hasBeenCustomised"],
              true
            );
            item.setIn(
              ["layers", indexOfSignatureLayer, "config", "color"],
              drawingConfig.activeColor
            );
          });
          return changedItem;
        }
        return item;
      }),
    });
    return changedItem;
  };

  changeBorderColourForItem = async (itemId, borderConfig) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item, index) => {
        if (itemId === item.get('id')) {
          const indexOfPhotoLayer = item
            .get("layers")
            .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
          const newBorderConfig = {
            style: {
              type: "color",
              color: borderConfig,
            }
          }
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "border"],
              fromJS(newBorderConfig)
            );
          });
          return changedItem;
        }
        return item
      })
    }, () => {
      //message.success(`Changed frame colour`);
    });
  };

  changeBackgroundForItem = (itemId, background) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item, index) => {
        if (itemId === item.get('id')) {
          const indexOfPhotoLayer = item
            .get("layers")
            .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
          changedItem = item.withMutations(item => {
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "background"],
              fromJS(background)
            ).setIn(["product_options", "background"], fromJS(background));;
          });
          return changedItem;
        }
        return item
      })
    }, () => {
      this.setState({
        selectedBackground: background,
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
      })
    });
  };

  changeBackgroundForAllItems = async (itemId, background) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item, index) => {
        const indexOfPhotoLayer = item
          .get("layers")
          .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        changedItem = item.withMutations(item => {
          item.setIn(
            ["layers", indexOfPhotoLayer, "config", "background"],
            fromJS(background)
          ).setIn(["product_options", "background"], fromJS(background));
        });
        return changedItem;
      })
    }, () => {
      this.setState({
        selectedBackground: background,
        itemForEditModal: this.state.items.find(item => item.get('id') === itemId),
        backgroundSetForAll: true,
      }, () => {
        this.updateLastApplyAllSetFromItem()
      })
    });
  };

  changeTextureForAllItems = async (texture) => {
    let changedItem;
    this.setState({
      items: this.state.items.map((item, index) => {
        const indexOfPhotoLayer = item
          .get("layers")
          .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        const newBorderConfig = {
          style: {
            type: "texture",
            texture: texture,
          }
        }
        changedItem = item.withMutations(item => {
          item.setIn(
            ["layers", indexOfPhotoLayer, "config", "border"],
            fromJS(newBorderConfig)
          );
        });
        return changedItem;
      })
    }, () => {
      //message.success(`Changed frame colour`);
    });
  };

  setActiveItemForEditModal = (itemId) => {
    this.setState({
      itemForEditModal: this.state.items.find(item => item.get('id') === itemId)
    })
  }

  handleChangeProductOption = (option, value, itemId) => {
    this.setState({
      items: this.state.items.map(item => item.setIn(["product_options", option], value)),
    }, () => {
      this.setActiveItemForEditModal(itemId)
      //message.success(`Print ${option} changed to ${value}`);
    });
  };

  borderThicknessForItem = (item) => {
    const dimensions = item.get('productDimensions').toJS();
    const widthPlusBleed = dimensions.width + dimensions.bleed.left + dimensions.bleed.right;
    const heightPlusBleed = dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom;
    const widthOrHeight = item.get('isRotated') ? heightPlusBleed : widthPlusBleed
    const borderThicknessInMM = 6
    //console.log("Border thickness = ", borderThicknessInMM/widthOrHeight)
    return borderThicknessInMM/widthOrHeight;
  }

  handleChangeBorderApplied = (isBorderApplied, realBorder = false) => {

    // Some products have borders applied by the prints, some need the border added to the output file

    if (realBorder){
      this.setState({
        items: this.state.items.map((item, index) => {
          return item.withMutations(item => {
            const indexOfPhotoLayer = item
            .get("layers")
            .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

            const borderConfig = isBorderApplied ? {
              style: {
                type: "color",
                color: "rgb(255,255,255)",
              },
              thickness: this.borderThicknessForItem(item) // 6mm/54mm = 0.111
            } : {
              style: null,
              thickness: 0
            }
            //console.log(borderConfig);
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "border"],
              fromJS(borderConfig)
            );
            item.setIn(
              ["product_options", "border"],
              isBorderApplied
            );
          });
        }),
      }, () => {
        message.success(`Borders ${isBorderApplied ? 'added' : 'removed'}`);
      });
    } else{
      this.setState({
        items: this.state.items.map(item => item.setIn(["product_options", "border"], isBorderApplied)),
      }, () => {
        let messageText = isBorderApplied ? "Borders added to all prints" : "Borders removed from all prints";
        message.success(messageText);
      });
    }

    //item.setIn(
      //   ["layers", indexOfPhotoLayer, "config", "border"],
      //   fromJS({
      //     style: {
      //       type: "color",
      //       color: "rgb(255,255,255)",
      //     },
      //     thickness: 0.07
      //   })
      // );

  };

  deleteItem = index => {
    const updatedItems = [...this.state.items];
    pullAt(updatedItems, [index]);
    this.setState({
      items: updatedItems,
    }, () => {
      if (this.state.items.length === 0){
        this.setState({
          lastApplyAllSetFromItem: null,
        })
      }
    });
  };

  handleChangeBackground = (background) => {
    this.changeBackgroundForItem(this.state.itemForEditModal.get('id'), background)
  }

  handleChangeBackgroundForAll = (background) => {
    this.changeBackgroundForAllItems(this.state.itemForEditModal.get('id'), background)
  }

  handleChangeText = (textConfig) => {
    this.changeTextContentForItem(this.state.itemForEditModal.get('id'), textConfig)
  }

  handleChangeTextForAll = () => {
    this.changeTextContentForAll(this.state.itemForEditModal.get('id'))
  }

  handleChangeTextColour = (colour) => {
    this.changeTextColourForItem(this.state.itemForEditModal.get('id'), colour)
  }

  handleChangeTextColourForAll = () => {
    this.changeTextColourForAll(this.state.itemForEditModal.get('id'))
  }

  handleChangeFont = (font) => {
    this.changeFontForItem(this.state.itemForEditModal.get('id'), font)
  }

  handleChangeFontForAll = () => {
    this.changeFontForAll(this.state.itemForEditModal.get('id'))
  }

  handleChangeEffect = (effect) => {
    this.changeEffectForItem(this.state.itemForEditModal.get('id'), effect)
  }

  handleChangeEffectForAll = (effect) => {
    this.changeEffectForAll(this.state.itemForEditModal.get('id'), effect)
  }

  loadSettingsFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      this.setState({
        [key]: JSON.parse(storedValue)
      });
    }
  }

  updateLastApplyAllSetFromItem = () => {
    this.setState({
      lastApplyAllSetFromItem: this.state.itemForEditModal
    }, () => {
      localStorage.setItem("lastApplyAllSetFromItem", JSON.stringify(this.state.itemForEditModal.toJS()));
    })
  }

  handleSetForAll = (key, value) => {
    const newState = {
      [key]: value,
      lastApplyAllSetFromItem: this.state.itemForEditModal
    };

    this.setState(newState, () => {
      localStorage.setItem(key, JSON.stringify(value));
      this.updateLastApplyAllSetFromItem()
    });
  }

  openEditModalForItem = itemIndex => {
    if (this.state.editModalCloseTimer) {
      clearTimeout(this.state.editModalCloseTimer);
    }

    let container = document.querySelector('.photo-prints-prebag');
    let scrollTo
    if (container) {
      scrollTo = container.scrollTop;
    }

    this.setState({
      itemForEditModal: this.state.items[itemIndex],
      isEditModalVisible: true,
      editModalHasClosed: false,
      scrollTo: scrollTo
    });
  }

  scrollTo = async () => {
    let container = document.querySelector('.photo-prints-prebag');
    if (container) {
      container.scrollTop = this.state.scrollTo;
    }
  }

  closeEditModal = () => {
    if (this.state.editModalCloseTimer) {
      clearTimeout(this.state.editModalCloseTimer);
    }

    this.setState({
      isEditModalVisible: false
    }, () => {

      setTimeout( () => {
        this.scrollTo(this.state.scrollTo);
      }, 150);

      setTimeout( () => {
        this.setState({
          itemForEditModal: null
        });
      }, 500);

      const newTimerId = setTimeout(() => {
        this.setState({
          editModalHasClosed: true,
          editModalCloseTimer: null // Reset timer ID after it's executed
        });
      }, 5000);

      this.setState({ editModalCloseTimer: newTimerId });
    });
  }

  handleOnClickItem = (item) => {
    this.openEditModalForItem(item)
  }

  startCropping = itemIndex => {
    this.setState({
      itemIndexForCropper: itemIndex,
      draftRotationState: this.state.items[itemIndex].get("isRotated"),
    });
  };

  closeCropper = () => {
    if (this.state.openEditModalAfterDone){
      this.setState({
        itemIndexForCropper: null,
        draftRotationState: null,
        isEditModalVisible: true,
      }, () => {
        this.setActiveItemForEditModal(this.state.itemForEditModal.get('id'))
      });
    } else{
      this.setState({
        itemIndexForCropper: null,
        draftRotationState: null,
      });
    }

  };

  openCropper = (itemId) => {
    let itemIndex = this.state.items.findIndex((item) => item.get('id') === itemId)
    this.setState({
      itemIndexForCropper: itemIndex,
      draftRotationState: this.state.items[itemIndex].get("isRotated"),
      isEditModalVisible: false,
      openEditModalAfterDone: true
    });
  };

  handleRotateCrop = () => {
    this.setState({
      draftRotationState: !this.state.draftRotationState,
    });
  };

  rotateItem = (itemId) => {
    this.setState({
      //draftRotationState: !this.state.draftRotationState,
      items: this.state.items.map((item, index) => {
        if (item.get('id') === itemId) {
          return item.withMutations(item => {
            item.set("isManuallyRotated", !item.get("isManuallyRotated"));
            //item.set("isRotated", !item.get("isRotated"));
          });
        }
        return item;
      }),
    });
  }


  handleSaveCrop = crop => {
    const itemForCropper = this.state.items[this.state.itemIndexForCropper];
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    const dimensions = itemForCropper.get('productDimensions').toJS();
    const percentageWidthCropped = crop.transform.containerWidth/crop.transform.width;
    const percentageHeightCropped = crop.transform.containerHeight/crop.transform.height;

    const originalWidthPx = itemForCropper.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "originalSize", "width"]);
    const originalHeightPx = itemForCropper.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "originalSize", "height"]);
    const newlyCroppedWidthInPx = originalWidthPx * percentageWidthCropped;
    const newlyCroppedHeightInPx = originalHeightPx * percentageHeightCropped;

    //console.log("Cropped Width", newlyCroppedWidthInPx);
    //console.log("Cropped Height", newlyCroppedWidthInPx);

    const isLowRes = this.isLowResImage(dimensions, newlyCroppedWidthInPx, newlyCroppedHeightInPx);

    if (isLowRes){
      this.triggerIsLowResEvent()
    }

    this.setState({
      items: this.state.items.map((item, index) => {
        if (index === this.state.itemIndexForCropper) {
          return item.withMutations(item => {
            item.setIn(
              ["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "cropData"],
              fromJS(crop)
            );
            item.set("isRotated", Boolean(this.state.draftRotationState));
            item.set("isLowRes", isLowRes);


            if(item.getIn(["product_options", "border"])){

              let borderConfig = {
                style: {
                  type: "color",
                  color: "rgb(255,255,255)",
                },
                thickness: this.borderThicknessForItem(item) //0.08 //6mm/75mm = 0.08 // 0.111 // 6mm/54mm = 0.111
              }
              // If rotated, need to change border thickness to use shortest length
              if (Boolean(this.state.draftRotationState)){
                item.setIn(
                  ["layers", indexOfPhotoLayer, "config", "border"],
                  fromJS(borderConfig)
                );
              } else{
                borderConfig = {
                  style: {
                    type: "color",
                    color: "rgb(255,255,255)",
                  },
                  thickness: this.borderThicknessForItem(item) //6mm/75mm = 0.08 // 0.111 // 6mm/54mm = 0.111
                }
                item.setIn(
                  ["layers", indexOfPhotoLayer, "config", "border"],
                  fromJS(borderConfig)
                );
              }

            }

          });
        }

        return item;
      }),
    });


    this.closeCropper();
  };

  handleSaveAddress = address => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", null);
            item.set("address", fromJS(address));
          });
        }),
      },
      this.showShippingSummaryIfRequiredOrFinalize
    );
    //this.closeAddressInputModal();
  };

  handleOwnAddressSave = address => {
    console.log("Saving own address");
    return this.props.setUserAddress(address);
  };


  handleSelectAddressBookEntry = (addressBookEntryId, entry) => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", addressBookEntryId);
            item.set("addressBookEntry", fromJS(entry))
            item.set("address", null);
          });
        }),
      },
      this.showShippingSummaryIfRequiredOrFinalize
    );
    //this.closeAddressInputModal();
  };

  handleUpdatePhone = (phone) => {
    if (this.props.hasGuestDetails){
      const newDetails = this.props.guestDetails.set('phone', phone)
      this.props.setGuestCheckoutDetails(newDetails)
    }

    this.setState({
      items: this.state.items.map(item => item.set("shippingPhone", phone))
    }, () => {
      message.success('Phone Number Updated')
    });


  }

  handleEditShippingAddress = () => {
    this.closeShippingSummary();
    this.setState({
      returnToShippingSummaryAfterAddressEdit: true,
      addressToEdit: null,
      isEditingFromSummary: false,
    }, () => {
      this.showAddressInputModal(this.props.hasGuestDetails)
    })

    //this.showShippingSummary();
  }

  handleEditCurrentShippingAddress = (addressToEdit) => {
    this.closeShippingSummary();
    this.setState({
      returnToShippingSummaryAfterAddressEdit: true,
      addressToEdit: addressToEdit,
      isEditingFromSummary: true,
    }, () => {
      this.showAddressInputModal(this.props.hasGuestDetails)
    })
    // Show address in edit mode
    // this.closeShippingSummary();
    // this.setState({
    //   returnToShippingSummaryAfterAddressEdit: true
    // }, () => {
    //   this.showAddressInputModal(this.props.hasGuestDetails)
    // })

    //this.showShippingSummary();
  }


  showShippingSummaryIfRequiredOrFinalize = () =>{
    if (this.props.existingPhotoPrintItems.size > 0) {
      const existingPrint = this.props.existingPhotoPrintItems.first();
      // if (firstPhotoPrint.get("addressBookId")) {
      //   this.handleSelectAddressBookEntry(firstPhotoPrint.get("addressBookId"));
      // } else {
      //   this.handleSaveAddress(firstPhotoPrint.get("address").toJS());
      // }
      this.finalizeShippingSummary(existingPrint.getIn(["postageScheme", "id"]), existingPrint.get("shippingPhone"))
    } else {
      this.setState({
        isShippingSummaryVisible: true
      })
    }
  }

  closeShippingSummary = () => {
    this.setState({
      isShippingSummaryVisible: false
    })
  }

  finalizeShippingSummary = (selectedPostageOptionId, shippingPhone) => {
    const selectedPostageScheme = getPostageSchemeForId(selectedPostageOptionId);
    this.setState({
      isShippingSummaryVisible: false,
      items: this.state.items.map(item => {
        return item.set("postageScheme", fromJS(selectedPostageScheme))
            .set('setPostageScheme', true)
            .set("shippingPhone", shippingPhone)
      })
    }, () => {
      this.finalizePreBag()
      //this.goToApproval()
    })
  }

  handleShippingOptionChange = (postageSchemeId) => {
    const selectedPostageScheme = getPostageSchemeForId(postageSchemeId);
    this.setState({
      items: this.state.items.map(item => item.set("postageScheme", fromJS(selectedPostageScheme)).set('setPostageScheme', true))
    }, () => {
      message.success(`Shipping changed to ${selectedPostageScheme.get('description')}`)
    });
  }

  goToApproval = () => {
    this.props.navigateToApproval();
  }

  finalizePreBag = () => {
    // if (this.state.items.length > 1){
    //   message.loading(`Adding prints to your basket... please wait while we prepare your prints for approval`, 5);
    // }

    // if (this.props.existingPhotoPrintItems.size > 0) {
    //   const existingPrint = this.props.existingPhotoPrintItems.first();
    //   const existingPostageScheme = existingPrint.get('postageScheme')
    //   const existingAddress = existingPrint.get('address') // address book entry too...
    //   this.setState({
    //     items: this.state.items.map((item) => {
    //       return item.withMutations(item => {
    //         debugger;
    //         item.set("postageScheme", fromJS(existingPostageScheme)).set('setPostageScheme', true);
    //         item.set("address", fromJS(existingAddress))
    //       });
    //     }),
    //   });
    // }


    //localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);

    localStorage.removeItem("lastApplyAllSetFromItem");
    this.setState({
      lastApplyAllSetFromItem: null,
      itemForEditModal: null,
    }, () => {
      localStorage.removeItem("lastApplyAllSetFromItem");
      const itemChunks = chunk(this.state.items, 10);
      itemChunks.forEach(async (chunkedItems, index) => {
        chunkedItems.forEach(async item => {
          this.props.addPendingApprovalItem(item.toJS());
          //this.props.renderItem(item.get('id'))

          await new Promise(r => setTimeout(r, 150));
          //console.log("Added item");
        });
        await new Promise(r => setTimeout(r, 250));
        console.log(`Adding ${chunkedItems.length} of chunk ${index}`);
      })
      // this.state.items.forEach(item => {
      //   this.props.addItemToBasket(item.toJS());
      // });

      // If UK address and currency is USD, switch to GBP
      const itemAddressCountry = this.state.items[0].getIn(["address", "country"]);
      if (this.props.currency === "USD" && itemAddressCountry === "United Kingdom"){
        console.log("Address country is UK and currency is USD override currency to GBP");
        this.props.onChangeCurrency("GBP")
      }
      // if US address and currency is GBP, switch to USD
      if (this.props.currency === "GBP" && itemAddressCountry === "United States"){
        console.log("Address country is USA and currency is GBP override currency to USD");
        this.props.onChangeCurrency("USD")
      }

      gtmEvent({
        event: "webAppStage",
        additionalProps: {
          stage: 'Product Preparation Started'
        }
      })
      this.props.navigateToApproval();
    })
    //this.props.navigateToBasket();
  };

  handleReplaceImage = (index) => {
    this.setState({
      itemIndexForCropper: null,
      isUploadModalVisible: true,
      isReplacingImage: true,
      replacingImageIndex: index
    });
  }

  handleConfirmInstagram = () => {
    this.setState({
      isInstagramWarningAlert: false,
    }, () => {
      this.pickImageFromUploadcare("instagram");
    });
  };

  handleSelectInstagram = () => {
    this.setState({
      isInstagramWarningAlert: true,
    });
  };

  handleCancelInstagram = () => {
    this.setState({
      isInstagramWarningAlert: false,
    });
  };


  render() {

    const parsedQueryString = queryString.parse(window.location.search);
    const isFromSource = parsedQueryString.source;

    const { productId } = this.props;
    const product = productsByProductId.get(productId);

    const itemForCropper = this.state.items[this.state.itemIndexForCropper];
    const recentlyUploaded = JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;

    const isDebugUser = this.props.user && this.props.user.get("unpublished_designs")

    const userMobile = this.props.user && this.props.user.get("mobile")

    let uploadModal = (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title="Upload Photos"
      >
        <MainContent scrollable={true} padded alignedTop>
          <div className="restricted-width-modal-content">
            {(process.env.NODE_ENV === "development" || isDebugUser) && (
              <Button
                theme="dark-blue"
                block
                label="DEBUG UPLOAD"
                icon="phone"
                onClick={this.debugUpload}
              />
            )}
            <Button
              block
              theme="dark-blue"
              label="From Device"
              icon="phone"
              onClick={() => this.pickImageFromUploadcare("file")}
              dataGtmElement="Photo Upload Source"
            />
            {anyRecentlyUploaded && (
              <Button
                block
                className="btn--recent"
                label="Recently Uploaded"
                icon="upload"
                dataGtmElement="Photo Upload Source"
                onClick={() => this.pickImageFromUploadcare("favorites")}
              />
            )}
            {/* <Button
              block
              icon="facebook"
              className="btn--facebook"
              label="Facebook"
              dataGtmElement="Photo Upload Source"
              onClick={() => this.pickImageFromUploadcare("facebook")}
            />
            <Button
              block
              icon="instagram"
              className="btn--instagram"
              dataGtmElement="Photo Upload Source"
              label="Instagram"
              onClick={() => this.pickImageFromUploadcare("instagram")}
            /> */}
            {/* <div style={{marginTop: '-10px', marginBottom: '10px'}}>
              <p style={{fontSize: '11px', lineHeight: '16px',fontWeight: '400'}}>
                Please note: Instagram and Snap photos may have too low a resolution for quality printing. If available we recommend using the original files for best results.
              </p>
            </div> */}
            <Button
              block
              icon="gdrive"
              dataGtmElement="Photo Upload Source"
              className="btn--google"
              label="Google Drive"
              onClick={() => this.pickImageFromUploadcare("gdrive")}
            />
            {/* <Button
              block
              icon="dropbox"
              className="btn--dropbox"
              label="Dropbox"
              onClick={() => this.pickImageFromUploadcare("dropbox")}
            />
            <Button
              block
              icon="flickr"
              className="btn--flickr"
              label="Flickr"
              onClick={() => this.pickImageFromUploadcare("flickr")}
            /> */}
          </div>
        </MainContent>
      </Modal>
    );

    if (isFromSource) {
      uploadModal = (
        <Modal
          key="uploadcare-selection-modal"
          isOpen={this.state.isUploadModalVisible}
          onClose={this.closeUploadModal}
          title="Upload Photos"
        >
          <MainContent scrollable={true} padded alignedTop>
            <div className="restricted-width-modal-content">
              <Button
                block
                icon="instagram"
                className="btn--instagram"
                label="Instagram"
                onClick={() => this.pickImageFromUploadcare("instagram")}
              />
              <div style={{marginTop: '-10px', marginBottom: '10px'}}>
                <p style={{fontSize: '11px', lineHeight: '16px',fontWeight: '400'}}>
                  Please note: Instagram and Snap photos may have too low a resolution for quality printing. If available we recommend using the original files for best results.
                </p>
              </div>
              <div>
                <p style={{marginBottom:"5px"}}>Or use photos from:</p>
              </div>
              {(process.env.NODE_ENV === "development" || isDebugUser) && (
                <Button
                  theme="dark-blue"
                  block
                  label="DEBUG UPLOAD"
                  icon="phone"
                  onClick={this.debugUpload}
                />
              )}
              <Button
                block
                label="From Device"
                icon="phone"
                onClick={() => this.pickImageFromUploadcare("file")}
              />
              {anyRecentlyUploaded && (
                <Button
                  block
                  className="btn--recent"
                  label="Recently Uploaded"
                  icon="upload"
                  onClick={() => this.pickImageFromUploadcare("favorites")}
                />
              )}
              {/* <Button
                block
                icon="facebook"
                className="btn--facebook"
                label="Facebook"
                onClick={() => this.pickImageFromUploadcare("facebook")}
              /> */}

              <Button
                block
                icon="gdrive"
                className="btn--google"
                label="Google Drive"
                onClick={() => this.pickImageFromUploadcare("gdrive")}
              />
              {/* <Button
                block
                icon="dropbox"
                className="btn--dropbox"
                label="Dropbox"
                onClick={() => this.pickImageFromUploadcare("dropbox")}
              />
              <Button
                block
                icon="flickr"
                className="btn--flickr"
                label="Flickr"
                onClick={() => this.pickImageFromUploadcare("flickr")}
              /> */}
            </div>
          </MainContent>
        </Modal>
      );
    }

    const cropperModalProps = {};

    if (itemForCropper) {
      const indexOfPhotoLayer = itemForCropper
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      const regionToCrop = itemForCropper.getIn([
        "layers",
        indexOfPhotoLayer,
        "config",
        "layout",
        0,
      ]);

      const photoRect = itemForCropper.getIn([
        "layers",
        indexOfPhotoLayer,
        "config",
        "rect",
      ]);

      const imageToCrop = regionToCrop.get("image");
      let productDimensions = itemForCropper.get("productDimensions").toJS();

      //We want the photo region dimensions and not the product dimensions
      productDimensions.width = productDimensions.width * photoRect.get('width');
      productDimensions.height = productDimensions.height * photoRect.get('height');

      //cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"]);
      cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"])//.replace("/resize/1200x/", "/resize/600x/");
      cropperModalProps.highResImgUrl = imageToCrop.getIn(["src", "highResUrl"]);
      cropperModalProps.cropData = imageToCrop.get("cropData")
        ? imageToCrop.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right) /
        (productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom);

      const textLayer = itemForCropper.get("layers").find(layer =>
        (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));

      cropperModalProps.disallowRotation = false;

      if(textLayer){
        //cropperModalProps.renderPhotoPrintsTextBand = true;
        cropperModalProps.disallowRotation = true;
        // cropperModalProps.photoPrintsText = {
        //   text: textLayer.getIn(['config', 'text']),
        //   font: textLayer.getIn(['config', 'font']),
        //   color: textLayer.getIn(['config', 'color']),
        // };

        // Hack to hide rotation for text prints
      }

      if (cropperModalProps.ratio === 1 || productDimensions.width === productDimensions.height){
        cropperModalProps.disallowRotation = true;
      }

      //cropperModalProps.hideRotation = hasRealBorders(this.props.productId);

      if (this.state.draftRotationState) {
        cropperModalProps.ratio =
          (productDimensions.height +
            productDimensions.bleed.top +
            productDimensions.bleed.bottom) /
          (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right);
      }

      // if (itemForCropper.get('isManuallyRotated')){
      //   cropperModalProps.ratio = 1/(
      //     (productDimensions.height +
      //       productDimensions.bleed.top +
      //       productDimensions.bleed.bottom) /
      //     (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right));
      //   cropperModalProps.isManuallyRotated = true;
      // }

      cropperModalProps.productDimensions = itemForCropper.get("productDimensions").toJS();


      cropperModalProps.renderBleedBands = productDimensions.bleed.left === productDimensions.bleed.right && productDimensions.bleed.left > 0;
    }

    const cropperModal = (
      <EditorCropperModal
        isOpen={this.state.itemIndexForCropper !== null}
        {...cropperModalProps}
        onRotate={this.handleRotateCrop}
        onClose={this.closeCropper}
        onSave={this.handleSaveCrop}
        onHandleReplaceImage={() => this.handleReplaceImage(this.state.itemIndexForCropper)}
      />
    );

    const onlyCountriesforProductType = getShippingCountriesForProductType(this.props.productTypeId, this.props.productId);
    const hasExistingAddress = this.state.items.length > 0 && this.state.items[0].get("address")
    const isEditingAnAddress = this.state.addressToEdit !== null && this.state.isEditingFromSummary;
    const addressInputEditMode = hasExistingAddress || isEditingAnAddress
    const initialAddressFormData = isEditingAnAddress ? this.state.addressToEdit.toJS() : hasExistingAddress
      ? this.state.items[0].get("address").toJS()
      : null

    const addressInputModal = (
      <EditorAddressInputModal
        titleLabel={"Shipping Address"}
        isDoubleDirect={false}
        isOpen={this.state.isAddressInputModalVisible}
        onlyNewAddress={this.state.asGuest}
        mode={addressInputEditMode ? "edit" : "new"}
        initialFormData={initialAddressFormData}
        onCancel={this.closeAddressInputModal}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
        onSaveOwnAddress={this.handleOwnAddressSave}
        onlyCountries={onlyCountriesforProductType || []}
        toShippingSummaryAfterSelection={true}
        straightToEdit={isEditingAnAddress}
      />
    );
    let existingPostageScheme = null;
    if (this.props.existingPhotoPrintItems.size > 0) {
      const firstPhotoPrint = this.props.existingPhotoPrintItems.first();
      if (firstPhotoPrint.get("postageScheme")) {
        existingPostageScheme = firstPhotoPrint.getIn(["postageScheme", "id"]);
      }
    }

    const useAddressForItem = this.state.items[0]
    let rawAddress
    if(useAddressForItem){
      rawAddress = useAddressForItem.get("address")
      || useAddressForItem.get("addressBookEntry")
      || this.props.addressBookEntries.find((abe) => abe.get('id') === useAddressForItem.get("addressBookId"))
    }

    const shippingSummary = (
      <ShippingSummary
        items={this.state.items}
        useAddress={rawAddress}
        guestDetails={this.props.guestDetails}
        isOpen={this.state.isShippingSummaryVisible}
        onCancel={this.closeShippingSummary}
        onDone={this.finalizeShippingSummary}
        onChangeShippingOption={this.handleShippingOptionChange}
        currency={this.props.currency}
        onUpdatePhone={this.handleUpdatePhone}
        onEditAddress={this.handleEditShippingAddress}
        onEditThisAddress={this.handleEditCurrentShippingAddress}
        existingPostageScheme={existingPostageScheme}
        userMobile={userMobile}
      />
    )

    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`You have unsaved changes, are you sure you want to go back?`}
        cancelButtonText="Exit & lose changes"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    const totalQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);
    const pricingScheme = getPricingSchemeForProductAndQuantity({
      productId,
      quantity: totalQuantity,
      currency: this.props.currency,
    });

    const nextPricingScheme = getNextPricingSchemeForProductAndQuantity({
      productId,
      quantity: totalQuantity,
      currency: this.props.currency,
    });

    const lowestPricingScheme = getLowestCostPricingSchemeForProduct({
      productId,
      currency: this.props.currency,
    });

    const schemes =  getPricingTableForProduct({
      productId,
      currency: this.props.currency,
    });

    const hasMinimumQty = hasMinimumQuantityForProduct(this.props.productId);
    const minimumQty = hasMinimumQty ? minimumQuantityForProduct(this.props.productId) : 0

    const pricingTable = schemes.map((scheme, index) => {
      let qtyFormat = scheme.get("min_qty") === scheme.get("max_qty") ? (
        scheme.get("min_qty")
        ) : (
        scheme.get("max_qty") ? (
          `${scheme.get("min_qty")} — ${scheme.get("max_qty")}`
        ) : (
          scheme.get("min_qty") === 0 ? '1 — 100' : scheme.get("min_qty")
        )
      )

      let price = scheme.getIn(["cost", this.props.currency]);
      let priceSuffix = " per print"
      if(hasMinimumQty){
        qtyFormat = `Packs of ${minimumQty}`;
        price = price*minimumQty;
        priceSuffix = " per pack";
      }

      const data = {
        key : `${index+1}`,
        qty: qtyFormat,
        price: price,
        priceSuffix: priceSuffix
      }
      return data;
    })

    const realBordersApplied = hasRealBorders(this.props.productId);
    const isFineArtPrint = product.get('fine_art_print');
    const hasFinishOptionsAvailable = !realBordersApplied && !isRetroPrint(this.props.productId) && !isFineArtPrint
    const isTextPrint = isTextPrintProduct(this.props.productId) //["6X4.5_TEXT_PRINTS", "RETRO_PRINTS", "2×2.5_PRINTS"].includes(product.get("appKey"));
    let borderOptionAvailable = (!isTextPrint || product.get('caption_print')) && !isFineArtPrint; 

    const LARGE_PRINT_SIZE_MM = 304.8 // 12 inches
    const isLargeBorderlessPrint = product.get("width") > LARGE_PRINT_SIZE_MM && product.get("width") > LARGE_PRINT_SIZE_MM
    if(isLargeBorderlessPrint){
      borderOptionAvailable = false
    }

    const editModal = (
      <EditPrintModal
        ref={this.editPrintModalRef}
        isOpen={this.state.isEditModalVisible}
        onClose={this.closeEditModal}
        onSave={this.handleSaveEdit}
        item={this.state.itemForEditModal}
        items={this.state.items}
        openCropper={this.openCropper}
        setActiveBackground={this.handleChangeBackground}
        setActiveBackgroundAll={this.handleChangeBackgroundForAll}
        setText={this.handleChangeText}
        setTextForAll={this.handleChangeTextForAll}
        setTextColour={this.handleChangeTextColour}
        setTextColourForAll={this.handleChangeTextColourForAll}
        setFont={this.handleChangeFont}
        setEffect={this.handleChangeEffect}
        setEffectForAll={this.handleChangeEffectForAll}
        setFontForAll={this.handleChangeFontForAll}
        selectedBackground={this.state.selectedBackground}
        backgroundSetForAll={this.state.backgroundSetForAll}
        textSetForAll={this.state.textSetForAll}
        textColourSetForAll={this.state.textColourSetForAll}
        fontSetForAll={this.state.fontSetForAll}
        effectSetForAll={this.state.effectSetForAll}
        setBackgroundSetForAll={(val) => this.handleSetForAll('backgroundSetForAll', val)}
        setTextSetForAll={(val) => this.handleSetForAll('textSetForAll', val)}
        setTextColourSetForAll={(val) => this.handleSetForAll('textColourSetForAll', val)}
        setFontSetForAll={(val) => this.handleSetForAll('fontSetForAll', val)}
        setEffectSetForAll={(val) => this.handleSetForAll('effectSetForAll', val)}
        onChangeProductOption={this.handleChangeProductOption}
      />
    );

    return (
      <React.Fragment>
        {/* <FullScreenLoader
          key="loader"
          isVisible={!this.state.itemForEditModal && !this.state.isEditModalVisible}
          message="Loading..."
        /> */}
        {cropperModal}
        {editModal}
        {uploadModal}
        {addressInputModal}
        {cancelConfirmationAlert}
        {shippingSummary}
        <PhotoPrintsPreBag
          showCaptionModal={false}
          showCaptionModalForItem={null}
          showInfoMessage={false}
          onInfoMessageDismissed={this.infoMessageDismissed}
          currency={this.props.currency}
          product={product}
          items={this.state.items}
          totalQuantity={totalQuantity}
          costPerPrint={pricingScheme && pricingScheme.get("cost")}
          lowestCostPerPrint={lowestPricingScheme && lowestPricingScheme.get("cost")}
          pricingTable={pricingTable}
          nextCostPerPrint={nextPricingScheme && nextPricingScheme.get("cost")}
          nextPricingSchemeQuantity={nextPricingScheme && nextPricingScheme.get("min_qty")}
          nextPricingSchemeAvailable={Boolean(nextPricingScheme)}
          onStartUpload={this.showUploadModal}
          onIncreaseItemQuantity={itemIndex => this.adjustItemQuantity(itemIndex, 1)}
          onDecreaseItemQuantity={itemIndex => this.adjustItemQuantity(itemIndex, -1)}
          onChangeTextForItem={(itemId, textConfig) => this.changeTextForItem(itemId, textConfig)}
          onChangeDrawingForItem={(itemId, drawingConfig) => this.changeDrawingForItem(itemId, drawingConfig)}
          onChangeBorderColourForItem={(itemId, borderConfig) => this.changeBorderColourForItem(itemId, borderConfig)}
          onChangeBorderColourForAllItems={(borderConfig) => this.changeBorderColourForAllItems(borderConfig)}
          onChangeTextureForItem={(itemId, texture) => this.changeTextureForItem(itemId, texture)}
          onChangeTextureForAllItems={(texture) => this.changeTextureForAllItems(texture)}
          onChangeBackgroundForItem={(itemId, background) => this.changeBackgroundForItem(itemId, background)}
          onChangeBackgroundForAllItems={(itemId, background) => this.changeBackgroundForAllItems(itemId, background)}
          onChangeProductOption={this.handleChangeProductOption}
          onChangeBorderApplied={(borderOn) => this.handleChangeBorderApplied(borderOn, realBordersApplied)}
          onRotateItem={(itemId) => this.rotateItem(itemId)}
          onDeleteItem={this.deleteItem}
          onClickItem={product.get("appKey") === "RETRO_PRINTS_C" ? this.handleOnClickItem : this.startCropping}
          onClickNext={this.approveCurrentPrints}
          onCancel={this.showCancelConfirmationAlert}
          multiLineText={product.get("appKey") === "RETRO_PRINTS_C" || product.get("appKey") === "RETRO_PRINTS" || product.get("appKey") === "2×2.5_PRINTS"}
          captionText ={product.get('caption_print')}
          isCaptionPrint={product.get('caption_print')}
          isColouredRetro={product.get("appKey") === "RETRO_PRINTS_C"}
          hasMinimumQuantity={hasMinimumQty}
          minimumQuantity={minimumQty}
          realBorders={realBordersApplied}
          finishOptionsAvailable={hasFinishOptionsAvailable}
          borderOptionAvailable={borderOptionAvailable}
          effectsAvailable={true}
          isEditModalOpen={this.state.isEditModalVisible}
          editModalHasClosed={this.state.editModalHasClosed}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  user: authSelectors.getUser(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  addressBookEntries: addressSelectors.getAllEntries(state),
  checkoutShippingAddress: basketSelectors.getCheckoutShippingAddress(state),
  existingPhotoPrintItems: basketSelectors
    .getItems(state)
    .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT),
});

const mapDispatchToProps = dispatch => ({
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  onChangeCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), {fromPreBag : true})),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  updateItemPostageScheme: (itemId, postageSchemeId) =>
    dispatch(basketActions.updateItemPostageScheme(itemId, postageSchemeId)),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setGuestCheckoutDetails: details => dispatch(authActions.setGuestCheckoutDetails(details)),
  getAddressBookItem: (addressBookEntryId) => dispatch(addressBookActions.getEntry(addressBookEntryId)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(PhotoPrintsPreBagContainer);
