import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  BORDER_THICKNESS,
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../store/ducks/ui-photo-tiles";
import EditorCropperModal from "../../../components/Editor/EditorCropperModal";
import { getItemDataSinglePack, inMemoryImageCache } from "../photoTilesUtils";

function PhotoTilesCropEditor({
  showCropEditor,
  closeModal,
  tiles,
  tileBeingEditedIndex,
  setTiles,
  openFilesModal,
  product,
}) {
  const imageBeingEdited = tiles[tileBeingEditedIndex]?.image?.lowResUrl;

  const [itemData, setItemData] = useState();

  const onSave = cropData => {
    const editedTile = tiles[tileBeingEditedIndex];
    const { croppedUrl, ...rest } = cropData;
    editedTile.crop = { ...rest };

    const updatedList = updateTile(tiles, tileBeingEditedIndex, { ...editedTile });
    setTiles(updatedList);
    closeModal();

    inMemoryImageCache.addItem(editedTile.image.lowResUrl, rest, croppedUrl);
  };

  useEffect(() => {
    const calcItemData = async () => {
      const currentItem = tiles[tileBeingEditedIndex];
      if (currentItem && product) {
        const itemData = await getItemDataSinglePack(tiles[tileBeingEditedIndex], product);

        setItemData(itemData.toJS());
      }
    };

    calcItemData();
  }, [tiles, tileBeingEditedIndex, product]);

  const renderBleedBands =
    tiles[tileBeingEditedIndex]?.border?.thickness === BORDER_THICKNESS.NO_BORDER;

  return (
    <EditorCropperModal
      isOpen={showCropEditor}
      imgUrl={imageBeingEdited}
      ratio={1}
      key="cropper-modal"
      renderBleedBands={renderBleedBands}
      onHandleReplaceImage={() => {
        openFilesModal();
        closeModal();
      }}
      onClose={closeModal}
      onSave={onSave}
      productDimensions={itemData?.productDimensions}
      bleedLabel="BLEED ZONE"
      cropData={tiles[tileBeingEditedIndex]?.crop}
      returnImageUrl
    />
  );
}

const mapStateToProps = state => ({
  showCropEditor: photoTilesSelectors.getShowCropEditor(state),
  showFilesModal: photoTilesSelectors.getShowFilesModal(state),
  tiles: photoTilesSelectors.getTiles(state),
  tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
  product: photoTilesSelectors.getProduct(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(photoTilesActions.setShowCropEditor(false)),
  openFilesModal: () => dispatch(photoTilesActions.setShowFilesModal(true)),
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesCropEditor);
