import React, { Component } from "react";
import { connect } from "react-redux";
import postsnapApi from "../../lib/apis/postsnap";
import Approve from "./Approve";
import queryString from 'query-string'

class ApproveContainer extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  componentDidMount() {
    //const { reference } = this.props.match.params;
    //const key = queryString.parse(this.props.location.search).key;
    // console.log("props",this.props);
    // console.log("reference", reference);
    // console.log("key", key);
    // debugger;
  }

  handleReject = async (approvalKey, reason) => {
    console.log("Rejected")
    const reject = await postsnapApi.approvals.reject(approvalKey, reason)
    console.log("Rejected response", reject)
  }

  handleAccept = async (approvalKey) => {
    console.log("Accepted")
    const accept = await postsnapApi.approvals.approve(approvalKey)
    console.log("Accepted", accept)
  }

  render() {
    const { reference } = this.props.match.params;
    const approvalKey = queryString.parse(this.props.location.search).key;

    const defaultAction = queryString.parse(this.props.location.search).action;

    return (
      <Approve
        reference={reference}
        approvalKey={approvalKey}
        onApprove={this.handleAccept}
        onReject={this.handleReject}
        defaultAction={defaultAction}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveContainer);
