import React from 'react';
import * as LAYER_TYPES from "../../constants/layer-types";
import "./FrameLayoutPicker.scss";
//import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { fromJS } from "immutable";

const FrameLayout = ({
  photoLayers,
  productRatio
}) => {

  const layoutWidth = 100;

  return (
    <div style={{
      width: `${layoutWidth}px`,
      height: `${layoutWidth/productRatio}px`,
      position: 'relative',
      border: 'thin solid #d4d4d4',
    }}>
      {photoLayers.map(photoLayer => {
        const rect = photoLayer.getIn(['config','rect']).toJS();
        return(
          <div style={
            {
              position: 'absolute',
              top: `${(1-rect.y) * 100}%`,
              left: `${rect.x * 100}%`,
              width: `${rect.width * 100}%`,
              height: `${rect.height * 100}%`,
              background: '#e4e4e4',
            }
          }
          />
        );
      })}
    </div>
  );
};

class FrameLayoutPicker extends React.Component {

  state = {
    designLayers: [],
    hasLoadedDesigns: false,
  };

  async componentDidMount() {
    const designsWithLayers = await Promise.all(this.props.frameDesigns.map(async design => {
      const designDetail = await postsnapApi.designs.getDetails(design.get("id"));
      let designLayers = transformDesignLayers(fromJS(designDetail.data.data.layers), this.props.productTypeId);
      console.log(designLayers);
      return ({design: design, layers:designLayers});
    }));
    
    this.setState({
      designLayers: designsWithLayers,
      hasLoadedDesigns: true
    })
  }


  render() {
    return (<>
      <section className='frame-layout-picker'>
        <div className="frame-layout-picker__options">
          {this.state.hasLoadedDesigns && (
            <ul className="frame-layout-picker__list">
            {this.state.designLayers.map(designLayer => {
              const design = designLayer.design;
              const layers =  designLayer.layers;
              return (
                <li
                className={`frame-layout-picker__list-item ${this.props.currentDesignId === design.get('id') ? 'active' : ''}`}
                key={design.get("id")}
                onClick={() => this.props.onSelectFrameDesign(design)}
                >
                  <FrameLayout
                    photoLayers={layers.filter(l => l.get("type") === LAYER_TYPES.PHOTO)}
                    productRatio={this.props.productRatio}
                  />
                  <p>{design.get("description")}</p>
                </li>
              )
            })}
          </ul>
          )}
          {!this.state.hasLoadedDesigns && (
            <p>Loading Frame Layouts ...</p>
          )}
        </div>
      </section>
    </>)
  }
}

export default FrameLayoutPicker;