import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import CheckoutShipping from "./CheckoutShipping";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import { selectors as basketSelectors, actions as basketActions } from "../../../store/ducks/basket";
import { selectors as authSelectors, actions as authActions } from "../../../store/ducks/auth";
import { actions as uiActions } from "../../../store/ducks/ui";
import gtmEvent from "../../../utils/gtm";

import {
  actions as addressBookActions,
  selectors as addressBookSelectors } from "../../../store/ducks/address-book";

class CheckoutShippingContainter extends React.Component {
  state = {
    mode: "new"
  };

  constructor(props) {
    super(props);
    const mode = this.props.location?.state?.mode || "new"

    this.state = {
      mode: mode,
      overridenBackTo: null
    };
  }

  componentDidMount(){
    if(this.props.items.size === 0){
      this.props.goToBasket()
    }

    if (this.props.checkoutShippingAddress && (this.state.mode !== "edit" && this.state.mode !== "edit-from-options" && this.state.mode !== "edit-from-options-back") ){
      // TODO: extract this logic
      const existingDataIsComplete = Object.keys(this.props.checkoutShippingAddress.toJS()).includes("line1")
      if (existingDataIsComplete && (this.props.hasCompletedCheckoutSteps || this.props.isSignedIn)){ //&& this.props.hasCompletedCheckoutSteps
        this.props.goToBasket()
      }
    }

    gtmEvent({event: "webAppStage", additionalProps: {stage: 'Checkout - Shipping'}})

  }

  handleSaveCheckoutShippingAddress = (address) => {
    this.props.onSaveCheckoutShippingAddress(address)
    // if change country
    this.props.unsetItemPostageSchemes()
    this.handleNext()
  }

  handleNext = () => {
    if (this.state.mode === "edit"){
      this.props.goToBasket()
    } else{
      this.props.goToShippingOptions()
    }
  }

  handleBack = () => {
    // console.log(this.state.overridenBackTo)
    // if(this.state.overridenBackTo !== null){
    //   if (this.state.overridenBackTo === "index"){
    //     this.setState({
    //       overridenBackTo: null
    //     })
    //     return
    //   }
    // }
    if(this.props.isSignedIn){
      return this.props.goToBasket()
    }
    if (this.state.mode === "edit"){
      this.props.goToBasket()
    } else{
      if (this.state.mode === "edit-from-options"){ //|| this.state.mode === "edit-from-options-back"
        this.props.goToShippingOptions()
      } else {
        this.props.setHasCompletedCheckoutSteps(false)
        this.props.setHasChosenGuestRoute(false)
        this.props.goToGuestOrCustomer()
      }
      
    }
  }

  handleUpdateBack = (backTo) => {
    this.setState({
      overridenBackTo: backTo
    })
  }


  handleSelectAddressBookEntry = (entry, isDoubleDirect) => {
    this.props.onSaveCheckoutShippingAddressEntry(entry);
    // if country change...
    this.props.unsetItemPostageSchemes()
    //const address = this.props.getAddressBookItem(entryId)
    //this.props.onSaveCheckoutShippingAddress(address)
    this.handleNext()
  };

  render() {
    const hasExistingData = this.props.checkoutShippingAddress.size > 0
    const initialFormData = hasExistingData ? this.props.checkoutShippingAddress.toJS() : false

    // TODO: better way of doing this...
    const existingDataIsComplete = Object.keys(this.props.checkoutShippingAddress.toJS()).includes("line1")

    const isEditing = (this.state.mode === "edit" || this.state.mode === "edit-from-options" || this.state.mode === "edit-from-options-back")

    console.log("initialFormData", initialFormData)    

    const onlyNewAddress = (this.props.hasChosenGuestRoute && !this.props.isSignedIn) || (this.props.isSignedIn && this.state.mode === "edit")

    return [
      <Header
        key="top-header"
        theme="grey"
        title="Shipping Address"
        leftAction={
          <Button theme="muted" priority="tertiary" label="Back" onClick={this.handleBack}/>
        }
        // rightAction={
        //   <Button priority="tertiary" label="Next" onClick={this.handleNext}/>
        // }
      />,
      <CheckoutShipping
        key="checkout-shipping"
        items={this.props.items}
        currency={this.props.currency}
        isSignedIn={this.props.isSignedIn}
        user={this.props.user}
        onlyNewAddress={onlyNewAddress}
        initialFormData={initialFormData}
        mode={initialFormData ? "edit" : "new"}
        straightToEdit={existingDataIsComplete ? true : false}
        onSubmitCheckoutShippingAddress={this.handleSaveCheckoutShippingAddress}
        onUpdateCheckoutShippingAddress={this.props.onSaveCheckoutShippingAddress}
        isEditingInIsolation={isEditing}
        updateBackTo={this.handleUpdateBack}
        overridenBackTo={this.state.overridenBackTo}
        addressBookEntries={this.props.addressBookEntries}
        onCancel={this.handleBack}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        addAddressBookEntry={this.props.addAddressBookEntry}
        onSaveOwnAddress={this.props.setUserAddress}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  guestToken: authSelectors.getGuestToken(state),
  hasGuestToken: Boolean(authSelectors.getGuestToken(state)),
  hasChosenGuestRoute: Boolean(authSelectors.hasChosenGuestRoute(state)),
  checkoutShippingAddress: basketSelectors.getCheckoutShippingAddress(state),
  hasCompletedCheckoutSteps: Boolean(basketSelectors.getHasCompletedCheckoutSteps(state)),
  addressBookEntries: addressBookSelectors.getAllEntries(state),
});

const mapDispatchToProps = dispatch => ({
  setHasChosenGuestRoute: (hasChosenGuestRoute) => dispatch(authActions.setHasChosenGuestRoute(hasChosenGuestRoute)),
  setHasCompletedCheckoutSteps: (completedCheckoutSteps) => dispatch(basketActions.setHasCompletedCheckoutSteps(completedCheckoutSteps)),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  goToGuestOrCustomer: () => dispatch(push(routeCreators.checkoutGuestOrCustomer())),
  onSaveCheckoutShippingAddress: (address) => dispatch(basketActions.setCheckoutShippingAddress(address)),
  onSaveCheckoutShippingAddressEntry: (entry) => dispatch(basketActions.setCheckoutShippingAddressEntry(entry)),
  goToShippingOptions: () => dispatch(push(routeCreators.checkoutShippingOption())),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  unsetItemPostageSchemes: () => dispatch(basketActions.unsetItemPostageSchemes()),
  addAddressBookEntry: address => dispatch(addressBookActions.addNewEntry(address)),
  getAddressBookItem: (addressBookEntryId) => dispatch(addressBookActions.getEntry(addressBookEntryId)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutShippingContainter);
