import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const S3Image = ({ storageKey, alt, className, retryOnError }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(
    `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${storageKey}`
  );

  const retryImageLoad = () => {
    // Append a timestamp to the image URL to force a retry
    console.log("Retrying now..");
    setIsLoading(true);
    setImageSrc(
      `${process.env.REACT_APP_BASE_ASSETS_DOMAIN}${storageKey}?retry=${Date.now()}`
    );
  };

  useEffect(() => {
    if (retryCount > 0 && retryCount < 10) {
      // Retry after 1 second when retryCount is less than 3
      let waitFor = retryCount * 500;
      if (retryCount === 1) {
        waitFor = 100;
      }
      console.log(`Retrying in ${waitFor / 2000} seconds`);
      const retryTimeout = setTimeout(retryImageLoad, waitFor);

      return () => {
        // Clear the retry timeout when the component unmounts or when retryCount changes
        clearTimeout(retryTimeout);
      };
    } else {
      if (retryCount >= 10) {
        console.log("Failed to load after 5 retries", imageSrc);
      }
    }
    // eslint-disable-next-line
  }, [retryCount, storageKey]);

  const handleImageError = () => {
    if (retryOnError && retryCount < 10) {
      setRetryCount(retryCount + 1);
    }
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <img
      className={`${className} ${isLoading ? "loading-img" : ""}`}
      src={imageSrc}
      alt={alt}
      onError={handleImageError}
      onLoad={handleImageLoad}
    />
  );
};

S3Image.propTypes = {
  storageKey: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  retryOnError: PropTypes.bool,
};

S3Image.defaultProps = {
  alt: "",
  className: "",
  retryOnError: false,
};

export default S3Image;
