import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./EditorImageOption.scss";

const EditorImageOption = ({ src, active, onClick }) => {
  const classes = classNames("editor__image-option", {
    "editor__image-option--is-active": active,
  });

  return <img src={src} className={classes} onClick={onClick} alt="" />;
};

EditorImageOption.propTypes = {
  src: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

EditorImageOption.defaultProps = {
  active: false,
  onClick: () => {},
};

export default EditorImageOption;
