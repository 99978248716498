import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import queryString from "query-string";
import { routeCreators } from "../../lib/routes";
import { actions as authActions } from "../../store/ducks/auth";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
//import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import ConvertGuestAccount from "./ConvertGuestAccount";

import Header from "../../components/Header/Header";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import ShopButtonContainer from "../../components/ShopButton/ShopButtonContainer";

class ConvertGuestAccountContainer extends React.Component {
  state = {
    alert: null,
    converting: false,
    convertingFinished: false,
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  clearAlertAndRedirect = () => {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.props.goToAccount()
        //window.location.href = window.location.origin;
        //this.props.goToShop();
      }
    );
  };

  convertAccount = (formData) => {
    this.setState({
      converting: true,
    });

    this.props
      .convertGuestAccount(formData)
      .then(result => {
        if (result.payload.data.success) {
          this.setState({
            alert: {
              type: "success",
              title: "Account Converted",
              text: "Welcome to PostSnap - you're now a PostSnap customer and you have been signed in. You will now be taken to your account.",
              showCancelButton: false,
              onConfirm: this.clearAlertAndRedirect
            },
          });
          // this.props.showAlert({
          //   type: 'success',
          //   title: 'Password reset',
          //   text: 'Your password has been reset, and you can now log in.',
          //   callback: () => {
          //     window.location.href = window.location.origin;
          //   }
          // });
        } else {
          const errors = result.payload.data.errors;
          let message = "There was a problem converting your account"
          if (errors.base && errors.base[0]){
            message = errors.base[0]
          } else if (errors.password) {
            message = errors.password[0]
          }

          this.setState({
            alert: {
              type: "error",
              title: "A problem occurred",
              text: message,
              showCancelButton: false,
              onConfirm: this.clearAlert,
            },
          });
        }
      });
  };

  goToShop = () => {
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  render() {
    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Header
        key="top-header"
        theme="grey"
        image="/images/logo.svg"
        title="PostSnap Postcard App"
        rightAction={
          <div style={{display:'flex'}}>
            <AccountButtonContainer />
            <BasketButtonContainer />
          </div>
        }
        leftAction={<ShopButtonContainer />}
        onClickTitleOrImage={this.goToShop}
      />,
      <ConvertGuestAccount
        onSubmit={this.convertAccount}
        email={queryString.parse(window.location.search).email} // + is %2B
        />,
    ];
  }
}

const mapDispatchToProps = dispatch => ({
  convertGuestAccount: (userData) => dispatch(authActions.convertGuestAccount(userData)),
  goToAccount: () => dispatch(push(routeCreators.account())),
});

export default connect(
  null,
  mapDispatchToProps
)(ConvertGuestAccountContainer);
