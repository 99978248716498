import $ from "jquery";
import React from "react";
import MainContent from "../../../components/MainContent/MainContent";
import Button from "../../../components/Button/Button";
import Divider from "../../../components/Divider/Divider";
import PasswordField from "../../../components/PasswordField/PasswordField";
import Form from "../../../components/Form/Form";
import SweetAlert from "../../../components/SweetAlert/SweetAlert";
import { COUNTRIES } from "../../../constants/geo";
import Grid from "../../../components/Grid/Grid";
import KlaviyoTracking from "../../../utils/klaviyo";
import './CheckoutSignUp.scss';

// const SIGN_UP_SCHEMA =  {
//   showErrorList: false,
//   schema: {
//     type: "object",
//     required: ["first_name", "email", "password"],
//     properties: {
//       first_name: { type: "string", title: "Name" },
//       email: { type: "string", title: "Email" },
//       password: { type: "string", title: "Password", minLength: 7 },
//       mobile: { type: "string", title: "Mobile Phone Number" },
//     },
//   },
//   uiSchema: {
//     "ui:rootFieldId": "signUp",
//     "ui:order": [
//       "first_name",
//       "email",
//       "password",
//       "mobile",
//     ],
//     email: {
//       "ui:widget": "email",
//     },
//     mobile: {
//       "ui:help": "Add your mobile phone number so we can contact you quickly if there is a problem with your order.",
//     },
//     password: {
//       "ui:widget": PasswordField,
//       "ui:help": "(Must be 7 characters inc. at least one number.)",
//     },
//   },
// }

let signUpScheme = {
  showErrorList: false,
  schema: {
    type: "object",
    required: ["first_name", "email", "address", "password"],
    properties: {
      first_name: { type: "string", title: "First Name" },
      last_name: { type: "string", title: "Last Name" },
      email: { type: "string", title: "Email" },
      mobile: { type: "string", title: "Mobile Phone" },
      address: {
        type: "object",
        properties: {
          address_book_entry: {
            type: "object",
            required: ["line1", "city", "country", "postcode"],
            properties: {
              country: {
                type: "string",
                title: "Country",
                enum: COUNTRIES.map(c => c.name),
              },
              company_name: { type: "string", title: "Company Name" },
              line1: { type: "string", title: "Line 1" },
              line2: { type: "string", title: "Line 2" },
              line3: { type: "string", title: "Line 3" },
              city: { type: "string", title: "Town/City" },
              county: { type: "string", title: "County/State" },
              postcode: { type: "string", title: "Postcode/ZIP" },
            },
          },
        },
      },
      password: { type: "string", title: "Password", minLength: 7 },
      marketing_opt_in: {
        type: "boolean",
        title: "Email preference",
        default: true,
      },
    },
  },
  uiSchema: {
    "ui:rootFieldId": "signUp",
    "ui:order": [
      "email",
      "password",
      "first_name",
      "last_name",
      "address",
      "mobile",
      "marketing_opt_in",
    ],
    marketing_opt_in: {
      "ui:label": false,
      "ui:widget": props => {
        return (
          <Grid.Row>
            <Grid.Column size="0 auto">
              <div className="pretty p-svg" style={{ marginRight: 0 }}>
                <input
                  id="sign-up-marketing-email"
                  type="checkbox"
                  checked={props.value}
                  onChange={() => props.onChange(!props.value)}
                />
                <div className="state">
                  <img
                    className="svg"
                    style={{background: "black"}}
                    src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                    alt=""
                  />
                  <label htmlFor="">&nbsp;</label>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <label htmlFor="sign-up-marketing-email" className="opt-in-label">
                Yes, I'd like to receive marketing emails with details of regular promotions. Unsubscribe anytime.
              </label>
            </Grid.Column>
          </Grid.Row>
        );
      },
    },
    email: {
      "ui:widget": "email",
      "ui:autocomplete": "username"
    },
    mobile: {
      "ui:help": "Add your mobile phone number so that we can contact you quickly if there is a problem with your order.",
    },
    password: {
      "ui:widget": PasswordField,
      "ui:help": "(Must be 7 characters inc. at least one number.)",
      "ui:autocomplete": "password-current"
    },
  },
}


class CheckoutSignUp extends React.Component {
  
  defaultState = {
    alert: null,
    isLoading: false,
    edited: false,
    isSigningUp: false,
    signUpData: {},
    signUpFormVisible: false,
    isLoadingRequest: false,
  };

  state = { ...this.defaultState };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleChooseSignIn = () => {
    this.props.chosenSignInRoute()
  }


  handleSignUpFormChange = form => {
    let data = form.formData
    // Horrible hack because browsers no longer seem to trigger onChange for password autofill
    // TODO: investigate a better way of doing this...
    if (!form.formData.password && $('#signUp #pw-input').attr('data-ps-val')){
      data = {...data, password: $('#signUp #pw-input').attr('data-ps-val')}
    }
    this.setState({
      signUpData: data,
    });
  };

  handleSignUpFormSuccess = async () => {
    //await this.props.onActionAfterGuestAuth()
    this.props.onClose()
  }

  handleSignUpFormSubmit = async form => {
    const signUpData = { ...form.formData };
    
    if (signUpData.address){
      signUpData.address.address_book_entry.first_name = signUpData.first_name;
      signUpData.address.address_book_entry.last_name = signUpData.last_name;
    }  

    this.setState({
      isLoadingRequest: true,
      isSigningUp: true
    });

    try {
      console.log("signUpData", signUpData)
      const result = await this.props.onSignUp(signUpData);
      if (result.error) {
        const errors = result.payload.data.errors;
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't create your account",
            text: errors[Object.keys(errors)[0]][0],
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Account Created",
            text: "Your account has been created, and you have been signed in.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
        if (true){ // signUpData.marketing_opt_in === true
          KlaviyoTracking.identifyUser(signUpData.email, signUpData.first_name, "", false)
        }
        
        //this.props.onClose();
        this.handleSignUpFormSuccess()
      }
    } catch (err) {
      let errorMessage = "There was a problem creating your account, please try again."
      if (err.payload){
        const errors = err.payload.data.errors;
        errorMessage = errors[Object.keys(errors)[0]][0];
      }
      this.setState({
        alert: {
          type: "error",
          title: "Couldn't create your account",
          text: errorMessage,
          showCancelButton: false,
        },
      });
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningUp: false,
      });
    }
  };

  handleForgottenPassword = () => {
    console.log("Forgotten pass")
  }


  render() {

    let schema = signUpScheme;

    if (this.props.requiresMobileDetails){
      schema.schema.required = ['first_name', 'email', 'address', 'password', 'mobile']
    }

    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <MainContent centerContent padded>
        <div className="restricted-width-modal-content">
          <div className="checkout__sign-up">
            <React.Fragment>
              <Form
                {...schema}
                formData={this.state.signUpData}
                onChange={this.handleSignUpFormChange}
                onSubmit={this.handleSignUpFormSubmit}
                autoComplete={true}
                disabled={this.state.signUpDisabled}
              >
                <Button
                  dataGtmElement="Sign Up Button"
                  block
                  theme="dark-blue"
                  type="submit"
                  label="Sign Up"
                  loadingLabel="Signing Up ..."
                  priority="primary"
                  loading={this.state.isLoadingRequest}
                />
              </Form>
              <Divider label="or" />
              <br/>
              <p>Already with PostSnap?</p>
              <Button
                dataGtmElement="Choose Sign In Button"
                block
                theme="dark-blue"
                priority="secondary"
                label="Sign In"
                onClick={this.handleChooseSignIn}
              />
              <p className="terms-text" style={{paddingBottom: '20px'}}>
                By signing up or signing in you are agreeing to the terms of our
                {' '}<a href="https://www.postsnap.com/privacy">Privacy Policy</a>
                {` and `}
                <a href="https://www.postsnap.com/terms-and-conditions">Terms of Use.</a>
                <br/>
              </p>
            </React.Fragment>
          </div>
        </div>
      </MainContent>
    ]
  }
}

CheckoutSignUp.propTypes = {};
CheckoutSignUp.defaultProps = {};

export default CheckoutSignUp;
