import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import postsnapApi from "../../lib/apis/postsnap";

import {
  Row,
  Col,
  Checkbox,
  message,
  Input
} from "antd";

const { TextArea } = Input;

class Approve extends Component {
  static propTypes = {
    onApprove: PropTypes.func,
    onReject: PropTypes.func,
  };

  state = {
    loading: true,
    error: false,
    hasSubmittedResponse: false,
    accepted: null,
    alert: null,
    confirmed: false,
    rejectReasonShown:false
  };


  async componentDidMount () {
    console.log("props",this.props);
    try {
      const response = await postsnapApi.approvals.getApproval({
        reference: this.props.reference,
        key: this.props.approvalKey
      })
      console.log("data", response);
      this.setState({
        data: response.data.data,
        loading: false,
        approvalKey: this.props.approvalKey
      }, () => {
        if (this.props.defaultAction){
          const defaultAction = this.props.defaultAction
          if (defaultAction === "accept"){
            this.handleAccept(true)
          }
        }

      })
    } catch (e) {
      console.log(e)
      this.setState({
        alert: {
          type: "error",
          text:"We cannot find this order to approve",
          confirmButtonText: "OK",
          onConfirm: this.clearAlert,
          showCancelButton: false,
        }
      });
    }
    
  }

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleAccept = (skipConfirmation = false) => {
    if(!this.state.confirmed && !skipConfirmation){
      message.error("Please confirm you understand")
      return
    }
    this.props.onApprove(this.state.approvalKey)
    this.setState({
      hasSubmittedResponse: true,
      accepted: true,
      alert: {
        type: "success",
        text:"Thank you for your response, we will proceed to print",
        confirmButtonText: "OK",
        onConfirm: this.clearAlert,
        showCancelButton: false,
      }
    })
  }  

  handleResetResponse = () => {
    this.setState({
      hasSubmittedResponse: false,
      rejectReasonShown: false
    })
  }

  handleRejectChosen = () => {
    this.setState({
      rejectReasonShown: true
    })
  }

  handleRejectCancelled = () => {
    this.setState({
      rejectReasonShown: false
    })
  }

  handleReject = () => {
    if(!this.state.confirmed){
      message.error("Please confirm you understand")
      return
    }
    this.props.onReject(this.state.approvalKey, this.state.rejectReason)
    this.setState({
      hasSubmittedResponse: true,
      accepted: false,
      alert: {
        type: "success",
        text:"Thank you for your response, one of our team will be in touch to discuss",
        confirmButtonText: "OK",
        onConfirm: this.clearAlert,
        showCancelButton: false,
      }
    })
  }  

  onChangeConfirm = (e) => {
    this.setState({
      confirmed: e.target.checked
    });
  }

  changeRejectReason = (e) => {
    this.setState({
      rejectReason: e.target.value
    });
  }

  render() {
    const centerContainerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 100,
    };

    const orderData = this.state.data
    if (this.state.error) {
      return (
        <div style={centerContainerStyle}>
          <h3>Error</h3>
        </div>
      );
    } else {
      return [
        <SweetAlert
          isOpen={Boolean(this.state.alert)}
          {...(this.state.alert || {})}
          key="basket-alert"
        />,
        <MainContent scrollable padded>
          {orderData && (
            <div>
              <div style={{display: 'flex', margin: '10px 0px'}}>
                <img src={`${process.env.PUBLIC_URL}/images/ps-icon-dark.svg`} style={{width: '50px', marginRight: '10px'}} alt={"logo"}/>
                <h2 style={{fontSize: '16px', margin: '15px 5px'}}>Approval required for order {orderData.reference}</h2>
                {/* <img src={`${process.env.PUBLIC_URL}/images/guaranteed.svg`} style={{width: '100px'}} /> */}
              </div>
              
              
              <p style={{fontSize: '12px', color: '#000000d9', fontWeight: '400', lineHeight: '2em'}}>Thank you for your order. As this is a high value order we want to double-check you are happy with the quality before we proceed to print.</p>
              <div>
                {orderData.customer_approval_attachments.map(item => (
                  <div>
                    <h4>Item: {item.product_name}</h4>
                    <img
                      className="item-preview"
                      src={item.original_file}
                      alt={'item'}
                    />
                  </div>
                ))}
                {!this.state.hasSubmittedResponse && (
                  <p style={{fontSize: '12px', color: '#000000d9', fontWeight: '400', lineHeight: '2em', marginTop: '10px'}}>
                    Please respond by approving or rejecting, <strong>if we do not hear from you before {moment(orderData.customer_approval_cut_off_time).format('HH:mm')} we will proceed to print.</strong>
                  </p>              
                )}
              </div>
              {this.state.hasSubmittedResponse && (
                <>
                  <div style={{background: '#5AA7B2', borderRadius: '4px', margin: '20px 0px'}}>
                    <p style={{color: 'white', padding: '15px', fontSize: '12px'}}>Your response has been submitted:
                      <span style={{color: 'black', background: 'white', borderRadius: '2px', padding: '2px 5px', marginLeft: '10px', fontSize: '12px'}}>
                        {this.state.accepted ? "Approved" : "Rejected"}
                      </span>
                    </p>
                  </div>
                  <Row type="flex" justify="space-between" style={{width: '100%', margin: '10px auto'}}>
                    <Col span={24}>
                      <Button block theme="dark-blue" priority="secondary" label="Change Response" onClick={this.handleResetResponse}/>
                    </Col>
                  </Row>
                </>
              )}
              {!this.state.hasSubmittedResponse && (
                <>
                  <Row type="flex" justify="space-between" gutter={8} style={{width: '100%', margin: '10px auto'}}>
                    <Col span={24}>
                      <Checkbox onChange={this.onChangeConfirm}>
                        <span style={{fontSize: '10px', marginLeft: '5px'}}>I understand that by approving, I am instructing PostSnap to proceed to print and that by rejecting there may be delays to the fulfilment.</span>
                      </Checkbox>
                    </Col>
                  </Row>
                  {!this.state.rejectReasonShown && (
                    <Row type="flex" justify="space-between" gutter={8} style={{width: '100%', margin: '0 auto'}}>
                      <Col span={12}>
                        <Button block theme="dark-blue" label="Approve" onClick={this.handleAccept} disabled={!this.state.confirmed}/>
                      </Col>
                      <Col span={12}>
                        <Button block theme="dark-blue" priority="secondary" label="Reject" onClick={this.handleRejectChosen} disabled={!this.state.confirmed}/>
                      </Col>
                    </Row>
                  )}
                  {this.state.rejectReasonShown && (
                    <>
                      <Row type="flex" justify="space-between" gutter={8} style={{width: '100%', margin: '10px auto'}}>
                        <Col span={24}>
                          <p style={{fontSize: '12px', color: '#000000d9', fontWeight: '400', lineHeight: '2em'}}>Please provide more details so we can assist further:</p>
                          <TextArea
                            rows={4}
                            onChange={this.changeRejectReason}
                            placeholder="Please describe why you are not happy to proceed..."
                          />
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" gutter={8} style={{width: '100%', margin: '10px auto'}}>
                        <Col span={12}>
                          <Button block theme="dark-blue" priority="secondary" label="Cancel" onClick={this.handleRejectCancelled} disabled={!this.state.confirmed}/>
                        </Col>
                        <Col span={12}>
                          <Button block theme="dark-blue" label="Confirm Rejection" onClick={this.handleReject} disabled={!this.state.confirmed}/>
                        </Col>
                      </Row>
                    </>
                  )}
                  
                </>
              )}
            </div>
          )}
        </MainContent>,
      ];
    }
  }
}

export default Approve;
