import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import $ from "jquery"
import {
  MIN_FONT_SIZE_IN_EM,
  MAX_FONT_SIZE_IN_EM,
  FONT_SIZE_STEP,
  FONT_FAMILIES,
  STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO,
} from "../../constants/text-style-options";
import FILL_OPTIONS from "../../constants/fill-options";
import { isAndroidOriOS, isiOS } from "../../lib/platform-and-feature-detection";
import calculateLuminanceFromRGB from "../../lib/calculate-luminance-from-rgb";
import containsEmoji from "../../lib/contains-emoji";

import Modal from "../Modal/Modal";
import Slider from "../Slider/Slider";
import Button from "../Button/Button";
import MainContent from "../MainContent/MainContent";
import Footer from "../Footer/Footer";
import BottomNavBar from "../BottomNavbar/BottomNavbar";
import BottomNavbarItem from "../BottomNavbar/BottomNavbarItem";
import Icon from "../Icon/Icon";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import EditorFontOption from "./EditorFontOption";
import EditorFillOption from "./EditorFillOption";
import SweetAlert from "../SweetAlert/SweetAlert";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import roundToNearestHalf from "../../lib/round-to-nearest-half";

const TEXT_CONTENT_LUMINANCE_CUTOFF_FOR_DARK_VARIANT = 0.8;
const TEXT_CONTENT_CONTAINER_HORIZONTAL_PADDING = 3;
const IOS_MAX_TEXTAREA_HEIGHT_PERCENTAGE_OF_VIEWPORT = 0.3;
const ANDROID_MAX_TEXTAREA_HEIGHT_PERCENTAGE_OF_VIEWPORT = 0.5;
const TEXT_COLORS = FILL_OPTIONS.filter(o => o.type === "color").map(o => o.color);
const TOOLS = {
  FONT: "FONT",
  SIZE: "SIZE",
  ALIGNMENT: "ALIGNMENT",
  COLOR: "COLOR",
};

class EditorTextEditorModal extends React.Component {
  static propTypes = {
    scaleToDimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  };

  static defaultProps = {
    config: {},
    canvasDimensions: {},
    isVerticallyCentered: true,
  };

  defaultState = {
    config: {},
    activeTool: null,
    isHeightWarningVisible: false,
    isTextareaFocused: false,
    textChangedFromPlaceholder: false,
    textSelected: false,
    isSettingContentDimensions: false,
    hasSetContentDimensions: false,
    alert: null,
    contentDimensions: {
      width: 0,
      maxHeight: 0,
    },
    clearDisabled: true,
  };

  state = { ...this.defaultState };

  componentDidUpdate(prevProps, prevState) {
    const isOpen = this.props.isOpen;
    const didOpen = !prevProps.isOpen && this.props.isOpen;
    const didClose = prevProps.isOpen && !this.props.isOpen;
    //console.log("Active tool", this.state.activeTool);
    const isPortrait = Boolean(
      this.props.scaleToDimensions &&
        this.props.scaleToDimensions.width < this.props.scaleToDimensions.height
    );

    let scalingDimensions = false;
    if (isOpen && this.props.scaleToDimensions) {
      scalingDimensions = true;
      this.setContentDimensions();
      scalingDimensions = false;
    }

    if (didOpen) {
      this.setState({
        config: {
          ...this.props.config,
        },
      });
      setTimeout(() => {
        this.setState({
          clearDisabled: false,
        });
      }, 500);
    }

    if (didClose) {
      this.setState({
        ...this.defaultState,
        clearDisabled: true,
      });
    }

    //console.log("Scaling dimensions", scalingDimensions);
    if (isOpen && this.state.contentDimensions.maxHeight && !scalingDimensions) {
      const hasExceededVerticalSpace =
        (isPortrait ? this.textarea.scrollHeight : this.textarea.scrollHeight) >
        this.state.contentDimensions.maxHeight;
      //console.log("this.textarea.scrollHeight", this.textarea.scrollHeight)
      //console.log("this.state.contentDimensions.maxHeight", this.state.contentDimensions.maxHeight)
      //console.log("isPortrait", isPortrait);
      // console.log("this.textarea.scrollHeight/2", this.textarea.scrollHeight/2);
      // console.log("this.textarea.scrollHeight", this.textarea.scrollHeight);
      // console.log("this.state.contentDimensions.maxHeight", this.state.contentDimensions.maxHeight);
      //console.log("hasExceededVerticalSpace", hasExceededVerticalSpace);
      if (this.state.isHeightWarningVisible !== hasExceededVerticalSpace) {
        if (hasExceededVerticalSpace) {
          //message.error("This text doesn't fit - reduce the size of the text or shorten your message");
        }
        this.setState({
          isHeightWarningVisible: hasExceededVerticalSpace,
        });
      }
    }
    if (isOpen) {
      if (
        this.state.config.placeholderText &&
        this.state.config.text === this.state.config.placeholderText &&
        !this.state.textSelected
      ) {
        this.selectText();
      }
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const isOpen = this.props.isOpen;
  //   const didOpen = !prevProps.isOpen && this.props.isOpen;
  //   const didClose = prevProps.isOpen && !this.props.isOpen;

  //   const isPortrait = prevProps.scaleToDimensions && (prevProps.scaleToDimensions.width < prevProps.scaleToDimensions.height);

  //   if (didOpen) {
  //     this.setState({
  //       config: {
  //         ...this.props.config,
  //       },
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         clearDisabled: false,
  //       });
  //     }, 500);
  //   }

  //   if (didClose) {
  //     this.setState({
  //       ...this.defaultState,
  //       clearDisabled: true,
  //     });
  //   }

  //   if (isOpen && this.state.contentDimensions.maxHeight) {
  //     // console.log("Scroll height", this.textarea.scrollHeight);
  //     // console.log("Max height", this.state.contentDimensions.maxHeight);
  //     // console.log("Too big", this.textarea.scrollHeight > this.state.contentDimensions.maxHeight);
  //     const hasExceededVerticalSpace = (isPortrait ? this.textarea.scrollHeight/2 : this.textarea.scrollHeight) > this.state.contentDimensions.maxHeight;
  //     if (this.state.isHeightWarningVisible !== hasExceededVerticalSpace) {
  //       this.setState({
  //         isHeightWarningVisible: hasExceededVerticalSpace,
  //       });
  //     }
  //   }
  //   if (isOpen) {
  //     if (
  //       this.state.config.placeholderText &&
  //       this.state.config.text === this.state.config.placeholderText &&
  //       !this.state.textSelected
  //     ) {
  //       this.selectText();
  //     }
  //   }
  // }

  setContentDimensions = () => {
    setTimeout(() => {
      // const isPortrait = Boolean(
      //   this.props.scaleToDimensions &&
      //     this.props.scaleToDimensions.width < this.props.scaleToDimensions.height
      // );
      const $mainContentNode = ReactDOM.findDOMNode(this.mainContent);
      let aspectRatio = this.props.scaleToDimensions.width / this.props.scaleToDimensions.height;
      // let portraitAspectRatio =
      //   this.props.scaleToDimensions.height / this.props.scaleToDimensions.width;

      const width = $mainContentNode.offsetWidth - TEXT_CONTENT_CONTAINER_HORIZONTAL_PADDING * 2;
      //const height = $mainContentNode.offsetHeight - TEXT_CONTENT_CONTAINER_HORIZONTAL_PADDING * 2;

      //const isPortrait = prevProps.scaleToDimensions && (prevProps.scaleToDimensions.width < prevProps.scaleToDimensions.height);
      //const newMaxHeight =this.props.scaleToDimensions.height * (width / this.props.scaleToDimensions.width);
      // isPortrait ? Math.round(height / portraitAspectRatio) : Math.round(width / aspectRatio)
      const contentDimensions = {
        width,
        maxHeight: Math.round(width / aspectRatio),
      };

      //console.log("contentDimensions",contentDimensions);
      //console.log("portrait",isPortrait);
      if (!isEqual(this.state.contentDimensions, contentDimensions)) {
        //console.log("Setting content dimensions");
        this.setState(
          {
            contentDimensions,
          },
          () => {
            setTimeout(() => {
              if (this.state.activeTool == null) {
                //&& this.state.isTextareaFocused
                //console.log("Focusing text area");
                this.focus();
                this.scrollBottom();
                this.centerContent();
              } else {
                //console.log("Skipping textarea focus");
              }
            }, 100);
          }
        );

        if (contentDimensions.maxHeight > $mainContentNode.offsetHeight) {
          console.warn("TODO: Need to recalculate width and height, height exceeds main content!");
        }
      }
    }, 100);
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  selectText = () => {
    this.textarea.focus();
    setTimeout(() => {
      this.textarea.setSelectionRange(0, 9999);
      this.setState({
        textSelected: true,
      });
    }, 1);
  };

  focus = () => {
    this.textarea.focus();
    this.textarea.select();
    this.textarea.setSelectionRange(this.textarea.value.length, this.textarea.value.length);
  };

  scrollBottom = () => {
    this.textarea.scrollTop = this.textarea.scrollHeight;
  };

  centerContent = () => {
    if (!this.props.isVerticallyCentered){
      return
    }
    const height = this.textarea.offsetHeight;
    //clear current padding and height so we can use scrollHeight below
    $(this.textarea).css({
      paddingTop: 0,
      height: 0
    });

    $(this.textarea).css({
      paddingTop: Math.max(0, height/2 - this.textarea.scrollHeight/2),
      height: height
    });
  }

  changeConfigValue = (key, value) => {
    this.setState({
      config: {
        ...this.state.config,
        [key]: value,
      },
    });
  };

  handleClickSave = () => {
    this.props.onSave({
      ...this.state.config,
      savedWithHeightWarningVisible: this.state.isHeightWarningVisible,
    });
  };

  clearText = () => {
    this.clearAlert();
    this.changeConfigValue("text", "");
    this.focus();
  };

  handleClickClear = e => {
    console.log("clear clicked");
    if (this.state.config.text !== "") {
      this.textarea.blur();
      this.setState({
        alert: {
          type: "warning",
          title: "Clear all text?",
          text: "Are you sure you want to remove all your text and start again?",
          showCancelButton: true,
          confirmButtonText: "Clear",
          onConfirm: this.clearText,
          onCancel: () => this.focus(),
        },
      });
    }
  };

  setActiveTool = toolId => {
    setTimeout(() => {
      this.setState({
        activeTool: this.state.activeTool === toolId ? null : toolId,
      });
    });
  };

  handleChangeTextAreaContents = e => {
    const content = e.target.value;
    this.centerContent();

    if (containsEmoji(content)) {
      // this.setState({
      //   alert: {
      //     type: "info",
      //     title: "We're sorry...",
      //     text: "We don't currently support the printing of Emojis 🙈",
      //     showCancelButton: false,
      //     confirmButtonText: "OK",
      //     onConfirm: this.clearAlert,
      //   },
      // });
      this.changeConfigValue("text", content);
    } else {
      let contentWithoutPlaceholder = content;
      if(this.state.config.placeholderText){
        contentWithoutPlaceholder = contentWithoutPlaceholder.replace(this.state.config.placeholderText, "")
      }

      this.changeConfigValue("text", contentWithoutPlaceholder);
      if (!this.state.textChangedFromPlaceholder) {
        this.setState({
          textChangedFromPlaceholder: true,
        });
      }
    }
  };

  handleTextAreaFocus = () => {
    this.setState({
      isTextareaFocused: true,
    });
    this.setActiveTool(null);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  };

  handleTextAreaBlur = () => {
    //console.log("Blurred");
    setTimeout(() => {
      this.setState({
        isTextareaFocused: false,
      });
    });
  };

  render() {
    const modalClasses = classNames("editor__text-editor", {
      "editor__text-editor--open": this.props.isOpen,
    });

    let textAreaStyles = {
      textAlign: this.state.config.alignment,
      fontFamily: this.state.config.font,
      color: this.state.config.color || this.props.config.variantColor || "rgb(0,0,0)",
      boxSizing: 'border-box',
      // resize: 'none'
    };
    // console.log("Is setting content dimensions", this.state.isSettingContentDimensions);
    // console.log("Has set content dimensions", this.state.hasSetContentDimensions);
    let textAreaContainerStyles = {
      fontSize: this.state.config.size ? `${this.state.config.size / 10}em` : 24,
      // opacity: this.state.hasSetContentDimensions ? '1' : '0',
      // transition: 'opacity 1s ease',
    };

    const rgbArray = textAreaStyles.color.match(/([0-9]+)/g);
    const luminance = calculateLuminanceFromRGB.apply(null, rgbArray);
    const shouldUseDarkVersion = luminance >= TEXT_CONTENT_LUMINANCE_CUTOFF_FOR_DARK_VARIANT;

    const contentClasses = classNames("editor__text-editor-content", {
      "editor__text-editor-content--dark": shouldUseDarkVersion,
    });

    if (this.props.scaleToDimensions) {
      textAreaContainerStyles = {
        ...textAreaContainerStyles,
        ...this.state.contentDimensions,
      };
      textAreaContainerStyles.flex = "none";
      const fontSizeRatio = this.props.scaleToDimensions.height / textAreaContainerStyles.maxHeight;
      //console.log("Font size ratio", fontSizeRatio);
      const isPortraitProduct = Boolean(
        this.props.scaleToDimensions &&
        this.props.isPortraitProduct
      );
      textAreaContainerStyles.fontSize = roundToNearestHalf(
        ((this.props.canvasDimensions.height || 0) *
          (STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO / (isPortraitProduct ? 2 : 1))) /
          fontSizeRatio
      );
      //console.log("textAreaContainerStyles font size", textAreaContainerStyles.fontSize);
      textAreaStyles.fontSize = `${this.state.config.size / 10}em`;
    }

    /**
     * On iOS, we make limit the max height of the textarea when the user is typing, so that it is never covered by the
     * keyboard (which floats on top of the content, rather than reducing the viewport and pushing the content up).
     */
    if (isAndroidOriOS && this.state.isTextareaFocused) {
      textAreaContainerStyles.maxHeight = Math.min(
        Math.round(
          document.documentElement.clientHeight *
            (isiOS
              ? IOS_MAX_TEXTAREA_HEIGHT_PERCENTAGE_OF_VIEWPORT
              : ANDROID_MAX_TEXTAREA_HEIGHT_PERCENTAGE_OF_VIEWPORT)
        ),
        textAreaContainerStyles.maxHeight || document.documentElement.clientHeight // textAreaContainerStyles.maxHeight is NaN for movable text which results in full-height textarea
      );
    }

    let activeToolMarkup = null;

    switch (this.state.activeTool) {
      case TOOLS.FONT:
        activeToolMarkup = (
          <div className="editor__text-editor-tool">
            <HorizontalScroller>
              {FONT_FAMILIES.map((option, index) => (
                <EditorFontOption
                  key={index}
                  fontName={option.name}
                  label={option.displayName}
                  active={option.name === this.state.config.font}
                  onClick={() => this.changeConfigValue("font", option.name)}
                />
              ))}
            </HorizontalScroller>
          </div>
        );
        break;
      case TOOLS.SIZE:
        activeToolMarkup = (
          <div className="editor__text-editor-tool editor__text-editor-tool--padded">
            <Slider
              min={MIN_FONT_SIZE_IN_EM * 10}
              max={MAX_FONT_SIZE_IN_EM * 10}
              step={FONT_SIZE_STEP * 10}
              value={this.state.config.size}
              onChange={value => this.changeConfigValue("size", value)}
            />
          </div>
        );
        break;
      case TOOLS.ALIGNMENT:
        activeToolMarkup = (
          <div className="editor__text-editor-tool text-center">
            <Button
              priority="tertiary"
              theme={this.state.config.alignment === "left" ? "default" : "muted"}
              icon="text-align-left"
              onClick={() => this.changeConfigValue("alignment", "left")}
            />
            <Button
              priority="tertiary"
              theme={this.state.config.alignment === "center" ? "default" : "muted"}
              icon="text-align-center"
              onClick={() => this.changeConfigValue("alignment", "center")}
            />
            <Button
              priority="tertiary"
              theme={this.state.config.alignment === "right" ? "default" : "muted"}
              icon="text-align-right"
              onClick={() => this.changeConfigValue("alignment", "right")}
            />
          </div>
        );
        break;
      case TOOLS.COLOR:
        activeToolMarkup = (
          <div className="editor__text-editor-tool">
            <HorizontalScroller>
              {TEXT_COLORS.map((color, index) => (
                <EditorFillOption
                  key={index}
                  type="color"
                  color={color}
                  active={textAreaStyles.color === color}
                  onClick={() => this.changeConfigValue("color", color)}
                />
              ))}
            </HorizontalScroller>
          </div>
        );
        break;
      // no default
    }

    const bottomNavBar = (
      <BottomNavBar>
        <BottomNavbarItem
          label="Font"
          active={this.state.activeTool === TOOLS.FONT}
          onClick={() => this.setActiveTool(TOOLS.FONT)}
        >
          <div
            className="editor__font-family-preview"
            style={{ fontFamily: this.state.config.font }}
          >
            Aa
          </div>
        </BottomNavbarItem>
        <BottomNavbarItem
          label="Size"
          active={this.state.activeTool === TOOLS.SIZE}
          onClick={() => this.setActiveTool(TOOLS.SIZE)}
        >
          <div className="editor__font-size-preview">{Math.round(this.state.config.size || 0)}</div>
        </BottomNavbarItem>
        <BottomNavbarItem
          label="Alignment"
          active={this.state.activeTool === TOOLS.ALIGNMENT}
          onClick={() => this.setActiveTool(TOOLS.ALIGNMENT)}
        >
          <Icon name="text-align-center" />
        </BottomNavbarItem>
        <BottomNavbarItem
          label="Color"
          active={this.state.activeTool === TOOLS.COLOR}
          onClick={() => this.setActiveTool(TOOLS.COLOR)}
        >
          <div
            className="editor__font-color-preview"
            style={{ backgroundColor: this.state.config.color || "rgb(0,0,0)" }}
          />
        </BottomNavbarItem>
      </BottomNavBar>
    );

    return (
      <Modal
        isOpen={true}
        className={modalClasses}
        onClose={this.props.onClose}
        rightAction={
          <Button priority="tertiary" theme="dark-blue" label="Save" onClick={this.handleClickSave} />
        }
      >
        <SweetAlert isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />
        <MainContent scrollable={false} padded={false} ref={el => (this.mainContent = el)}>
          {this.state.isHeightWarningVisible && (
            <div className="editor__text-editor-height-warning">
              <ExclamationCircleOutlined />
              &nbsp; Not enough space for your text.
              <br />
              Reduce the font size or shorten your message.
            </div>
          )}
          <div className="editor__text-editor-content-container" style={textAreaContainerStyles}>
            <textarea
              className={contentClasses}
              ref={el => (this.textarea = el)}
              value={this.state.config.text}
              style={textAreaStyles}
              onChange={this.handleChangeTextAreaContents}
              onFocus={this.handleTextAreaFocus}
              onBlur={this.handleTextAreaBlur}
              key={`layer-${this.props.layerId}`}
            />
          </div>
          {isAndroidOriOS && this.state.isTextareaFocused && bottomNavBar}
          <div className="text-center">
            <Button
              label="Clear"
              priority="tertiary"
              theme="muted"
              onClick={this.handleClickClear}
              disabled={this.state.clearDisabled}
            />
          </div>
        </MainContent>
        <Footer>
          {activeToolMarkup}
          {!(isAndroidOriOS && this.state.isTextareaFocused) && bottomNavBar}
        </Footer>
      </Modal>
    );
  }
}

export default EditorTextEditorModal;
