import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import CheckoutShippingOption from "./CheckoutShippingOption";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import { selectors as basketSelectors, actions as basketActions } from "../../../store/ducks/basket";
import { selectors as authSelectors } from "../../../store/ducks/auth";
import { actions as uiActions } from "../../../store/ducks/ui";
import gtmEvent from "../../../utils/gtm";
import { singleShippingAddressProductTypes } from "../../../data/products"

import {
  actions as addressBookActions,
  selectors as addressBookSelectors } from "../../../store/ducks/address-book";

class CheckoutShippingOptionContainer extends React.Component {
  state = {
    alert: null,
    shippingAddress: null,
    activeProductTypeId: null,
    hasSetActiveProductType: false,
    mode: 'new'
  };


  async componentDidMount(){
    if(this.props.items.size === 0){
      this.props.goToBasket()
    }


    if (this.props.checkoutShippingOption && this.state.mode !== "edit"){
      // TODO: extract this logic
      const existingShippingOptions = this.props.checkoutShippingOption.size > 0

      if (existingShippingOptions && this.props.hasCompletedCheckoutSteps){
        const singleShipmentItems = this.props.items.filter(item => {
          return (
            singleShippingAddressProductTypes.includes(item.get('productTypeId'))
          );
        });

        const productTypesNeedingShippingOptions = Object.keys(singleShipmentItems.groupBy((item) => item.get('productTypeId')).toJS())
        
        //[52, 54]

        const everyProductTypeHasShippingOption = productTypesNeedingShippingOptions.every((productTypeId) => {
          return this.props.checkoutShippingOption.get(productTypeId)
        })

        if (everyProductTypeHasShippingOption){
          // if all single shipment products have a checkout Shippin option
          this.props.goToContactDetails()
        } else{
          const productTypeWithoutShippingOption = productTypesNeedingShippingOptions.find((productTypeId) => {
            return !this.props.checkoutShippingOption.get(productTypeId)
          })
          // set active product type to the first one missing
          this.setState({
            activeProductTypeId: productTypeWithoutShippingOption,
            hasSetActiveProductType: true
          })
        }
      }
    }

    const address = (this.props.checkoutShippingAddress && Object.keys(this.props.checkoutShippingAddress?.toJS()).length > 0) ? this.props.checkoutShippingAddress : await this.props.checkoutShippingAddressFromEntryId(this.props.checkoutShippingAddressEntryId)


    if (this.state.shippingAddress === null && address){
      this.setState({
        shippingAddress: address
      })
    }
    gtmEvent({event: "webAppStage", additionalProps: {stage: 'Checkout - Shipping Option'}})
  }

  async componentDidUpdate(){

    //const address = (this.props.checkoutShippingAddress && Object.keys(this.props.checkoutShippingAddress.toJS()).length > 0) ? this.props.checkoutShippingAddress : await this.props.getAddressBookItem(this.props.checkoutShippingAddressEntryId)
    
    const address = (this.props.checkoutShippingAddress && Object.keys(this.props.checkoutShippingAddress?.toJS()).length > 0) ? this.props.checkoutShippingAddress : await this.props.checkoutShippingAddressFromEntryId(this.props.checkoutShippingAddressEntryId)

    if (this.state.shippingAddress === null && address){
      this.setState({
        shippingAddress: address
      })
    }
  }

  handleSaveCheckoutShippingOption = (shippingOption, productTypeId) => {
    this.props.onSaveCheckoutShippingOption(shippingOption, productTypeId)
    this.props.goToContactDetails()
  }

  handleBack = () => {
    this.props.goToShipping("edit-from-options-back")// "edit-from-options"
  }

  handleEdit = () => {
    this.props.goToShipping("edit-from-options")
  }


  render() {
    
    const items = this.props.items.filter(item => {
      return (
        singleShippingAddressProductTypes.includes(item.get('productTypeId'))
      );
    });

    const showAllProductTypeOptions = items.size > 1 //!this.state.mode === "edit"

    
    let activeProductType;
    if (items){
      activeProductType = items.sortBy((i) => i.addedAt).first().get('productTypeId');
    }

    if (this.state.hasSetActiveProductType){
      activeProductType = parseInt(this.state.activeProductTypeId)
    }
    

    return [
      <Header
        key="top-header"
        theme="grey"
        title="Shipping"
        leftAction={
          <Button theme="muted" priority="tertiary" label="Back" onClick={this.handleBack}/>
        }
      />,
      <CheckoutShippingOption
        key="checkout-shipping-option"
        items={items}
        currency={this.props.currency}
        isSignedIn={this.props.isSignedIn}
        onSaveShippingOption={this.handleSaveCheckoutShippingOption}
        checkoutShippingAddress={this.state.shippingAddress}
        checkoutShippingOption={this.props.checkoutShippingOption}
        onChangeShippingOption={this.props.onSaveCheckoutShippingOption}
        onEditThisAddress={this.handleEdit}
        activeProductTypeId={showAllProductTypeOptions ? null : activeProductType}
        showAllProductTypeOptions={showAllProductTypeOptions}
      />
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  guestToken: authSelectors.getGuestToken(state),
  hasGuestToken: Boolean(authSelectors.getGuestToken(state)),
  hasChosenGuestRoute: Boolean(authSelectors.hasChosenGuestRoute(state)),
  checkoutShippingAddress: basketSelectors.getCheckoutShippingAddress(state),
  checkoutShippingAddressEntryId: basketSelectors.getCheckoutShippingAddressEntryId(state),
  checkoutShippingOption: basketSelectors.getCheckoutShippingOption(state),
  checkoutShippingAddressFromEntryId: (addressBookId) => addressBookSelectors.getEntry(state, addressBookId),
  hasCompletedCheckoutSteps: Boolean(basketSelectors.getHasCompletedCheckoutSteps(state)),
});

const mapDispatchToProps = dispatch => ({
  goToBasket: () => dispatch(push(routeCreators.basket())),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  goToGuestOrCustomer: () => dispatch(push(routeCreators.checkoutGuestOrCustomer())),
  onSaveCheckoutShippingOption: (shippingOption, productTypeId) => dispatch(basketActions.setCheckoutShippingOption(shippingOption, productTypeId)),
  goToContactDetails: () => dispatch(push(routeCreators.checkoutContactDetails())),
  goToShipping: (mode) => dispatch(push(routeCreators.checkoutShipping(), {mode : mode})),
  getAddressBookItem: (addressBookEntryId) => dispatch(addressBookActions.getEntry(addressBookEntryId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutShippingOptionContainer);
