import React, { useMemo } from "react";
import {
  getConfigurationFromParameters,
  scaleConfiguration,
} from "../../pages/PhotoTileEditor/components/TilePreview/tilePreviewCalculator";
import { TilePreview } from "../../pages/PhotoTileEditor/components/TilePreview/TilePreview";
import { productsByProductId } from "../../data/products";

export function PhotoTilePreviewRenderer({ itemData, width }) {
  const getAdditionalStack = () => {
    return (productsByProductId.get(itemData.productId)?.toJS()?.pages ?? 1) - 1;
  };

  const configuration = useMemo(() => {
    const page0 = itemData.allLayers.find(layer => layer.page === 0);

    const config = getConfigurationFromParameters({
      borderColor: page0?.config?.layout?.[0]?.borderPrint?.borderColor,
      borderWidth: page0?.config?.layout?.[0]?.borderPrint?.borderWidth,
      withFrame: Boolean(itemData?.product_options?.frameColor),
      frameColor: itemData?.product_options?.frameColor,
      imageFilter: page0?.config?.layout?.[0]?.filter?.bnw ? "grayscale(100%)" : "none",
    });

    // We need to apply the 'Width' not in Tile Content but the whole preview image
    // (wisth stacked tiles etc).
    // So 'scaled' is the based on the configuration with width applied on tile content
    const scaled = scaleConfiguration(config, width);
    // W is the width of the whole preview image
    const W = getTilePreviewDisplaySize(scaled, getAdditionalStack());

    // so we scale down the W = width
    return scaleConfiguration(config, width * (width / W));
  }, [itemData, width]);

  if (!itemData) return null;

  const shadowFactor = width/200;

  return (
    <TilePreview
      configuration={configuration}
      itemData={itemData}
      additionalStack={getAdditionalStack()}
      frameInnerShadow={`rgba(137, 137, 137, 0.25) ${2*shadowFactor}px ${2*shadowFactor}px ${7*shadowFactor}px ${shadowFactor}px inset`}
    />
  );
}

// This is the same as it is calculated inside the TilePreview component
const getTilePreviewDisplaySize = (configuration, additionalStack) => {
  const tileWidth = configuration.width + 2 * configuration.bleedArea;
  const frameLength = tileWidth + 2 * (configuration.frameWidth - configuration.frameBleedArea);
  const containerWidth = configuration.withFrame ? frameLength : tileWidth;

  return containerWidth + configuration.depth + additionalStack * configuration.depth;
};
