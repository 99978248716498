import React, { useMemo } from "react";
import { useProduct } from "../../utils/useProduct";
import {
  getConfigurationFromParameters,
  scaleConfiguration,
} from "../../pages/PhotoTileEditor/components/TilePreview/tilePreviewCalculator";
import { TilePreview } from "../../pages/PhotoTileEditor/components/TilePreview/TilePreview";

export function PhotoTilePreviewMultiPack({ itemToApprove, width = 250 }) {
  const product = useProduct(itemToApprove.productId);

  const configurations = useMemo(() => {
    const layers = itemToApprove.layers
      .filter(layer => layer.type === "Layer::Photo")
      .toSorted((a, b) => a.page - b.page);

    return layers.map(layer => {
      const config = getConfigurationFromParameters({
        borderColor: layer?.config?.layout?.[0]?.borderPrint?.borderColor,
        borderWidth: layer?.config?.layout?.[0]?.borderPrint?.borderWidth,
        withFrame: product.photo_tile_framed,
        frameColor: itemToApprove?.product_options?.frameColor,
        imageFilter: layer?.config?.layout?.[0]?.filter?.bnw ? "grayscale(100%)" : "none",
      });
      return scaleConfiguration(config, width);
    });
  }, [itemToApprove, width]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        whiteSpace: "nowrap",
        overflowX: "auto",
        paddingLeft: 20,
        paddingRight: 20,
        position: "absolute",
      }}
    >
      {configurations.map((config, index) => (
        <div style={{ margin: 10 }} key={index}>
          <TilePreview
            configuration={config}
            itemData={itemToApprove}
            width={config.width}
            page={index}
          />
        </div>
      ))}
    </div>
  );
}
