import React from "react";
import QuantityPicker from "../QuantityPicker/QuantityPicker";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Icon from "../Icon/Icon";

import {
  Button as AntButton,
  Popover
} from "antd";

import {
  WarningTwoTone,
  WarningFilled,
} from '@ant-design/icons';

import "./NormalPrints.scss";

const NormalPrints = ({
  items,
  itemPreviewWidth,
  onClickItem,
  areBordered,
  areRealBorders,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  classes,
  effects,
  onDeleteItem,
  isCaptionPrint,
  isTextPrint,
  maskedProduct,
  bleedMasks,
  hasBeenCustomised,
  cropButtonPosition,
  glossyOrMatt,
  handleClickEditText,
  handleClickPreviewItem,
  lowResWarningContent,
  lowResWarningTitle,
  setLowResPopoverVisible,
  isLowResInfoModalVisible,
  faceCheckWarningContent,
  isLowResPopoverVisible 
  }) => {
 
  return (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {items.map((item, index) => (
        <div key={index} className="photo-prints-prebag__item" style={
          {
            flexBasis: isCaptionPrint ? '100%' : (document.documentElement.clientWidth < 550 ? '50%' : '33%'),
            flexDirection: isCaptionPrint ? 'row' : 'column',
            alignItems: document.documentElement.clientWidth < 550 ? 'center' : 'flex-start',
          }
        }>
          <div className={`${classes} ${item.get('isManuallyRotated') ? 'item-preview--rotated' : ''}`}>
            <HtmlRenderer
              width={itemPreviewWidth}
              isInteractive={false}
              previewMode={true}
              key={"editor-render"}
              item={item.toJS()}
              page={item.getIn(["pages", "front"])}
              onClick={() => onClickItem(index)}
              onClickSignatureLayer={() => console.log("Signature clicked")}
              borderedForPreviews={areBordered && !areRealBorders}
              realBorders={areBordered && areRealBorders}
              effects={effects}
              withShadow={areBordered && !areRealBorders}
              mask={maskedProduct && bleedMasks.canvasPreview}
              showCaptionPlaceholder={isCaptionPrint && !hasBeenCustomised(item.get('id'))}
            />
            {!isCaptionPrint && (
              <AntButton
                onClick={() => onClickItem(index)}
                data-gtm-element="Photo Edit - Crop Zoom"
                size="small"
                type="circle"
                style={
                  {
                    position: 'absolute',
                    bottom: cropButtonPosition.bottom,
                    right: cropButtonPosition.right,
                    top: cropButtonPosition.top,
                    border: 'none',
                    zIndex: 1,
                    "-webkit-transform": "translate3d(0,0,0)",
                  }
                }>
                <span style={{position: 'relative', top: '3px', left: '0px'}}>
                  <Icon size="small" name="crop-rotate"/>
                </span>
              </AntButton>
            )}
            {glossyOrMatt && (
              <div className="glossy-or-matt" />
            )}
            {(isTextPrint && !isCaptionPrint) && (
              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                <AntButton
                  size="small"
                  onClick={() => handleClickEditText(item.get('id'))}
                  type={""}
                  style={{fontSize: '12px', background: '#4b5566', color: '#ffffff', border:'none', paddingTop: '2px'}}
                >
                  {hasBeenCustomised(item.get('id')) ? 'Edit Text' : 'Add Text'}
                </AntButton>
              </div>
            )}
            {isCaptionPrint && (
              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                <AntButton
                  size="small"
                  onClick={() => handleClickEditText(item.get('id'))}
                  type={""}
                  style={{
                    fontSize: '12px',
                    background: '#ff5a5f',
                    color: '#ffffff',
                    border:'none',
                    paddingTop: '2px',
                    width: '100%',
                    height: '32px',
                    marginTop: '10px'
                  }}
                >
                  {hasBeenCustomised(item.get('id')) ? 'Edit caption' : 'Write a caption'}
                </AntButton>
              </div>
            )}
          </div>
          <div style={{
            marginLeft: isCaptionPrint ? '40px' : document.documentElement.clientWidth < 550 ? '0' : '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
            }}>
            <QuantityPicker
              quantity={item.get("quantity")}
              onIncrease={() => onIncreaseItemQuantity(index)}
              onDecrease={() => onDecreaseItemQuantity(index)}
              onDelete={() => onDeleteItem(index)}
              withDelete={false}
            />
            {isCaptionPrint && (
              <AntButton
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  paddingTop: '2px',
                  height: '33px',
                  width: '130px',
                  marginTop: '20px',
                  marginBottom: '40px'
                }}
                size="small"
                onClick={() => handleClickPreviewItem(item)}
              >
                Preview
              </AntButton>
            )}
            {item.get('isLowRes') && (
              <div className="low-res-warning">
                <Popover
                  className="low-res-warning-popover"
                  trigger="click"
                  content={lowResWarningContent}
                  title={lowResWarningTitle}
                  visible={isLowResPopoverVisible && isLowResPopoverVisible === item.get('id') && !isLowResInfoModalVisible}
                  onVisibleChange={(visible) => setLowResPopoverVisible( visible ? item.get('id') : false)}
                >
                  <WarningTwoTone style={{ color: "#ff5a5f", fontSize: "40px" }} twoToneColor="#ff5a5f" />
                </Popover>
                <span style={{
                  fontSize:'12px',
                  display: 'block',
                  fontWeight: 500,
                  marginTop: '4px',
                  color: '#ff5a5f'
                }}>LOW RESOLUTION WARNING</span>
              </div>
            )}
            {(item.get('potentialFaceChop') && !item.get('isLowRes')) && (
              <div className="low-res-warning">
                <Popover
                  className="low-res-warning-popover"
                  trigger="click"
                  content={faceCheckWarningContent}
                  title={'Check Your Faces...'}
                  visible={isLowResPopoverVisible && isLowResPopoverVisible === item.get('id') && !isLowResInfoModalVisible}
                  onVisibleChange={(visible) => setLowResPopoverVisible( visible ? item.get('id') : false)}
                >
                  <WarningFilled style={{ color: "#009688", fontSize: "20px" }} twoToneColor="#4C5666" />
                </Popover>
                <span style={{fontSize:'10px', display: 'block', marginTop:'-3px'}}>Check Crop</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

NormalPrints.defaultProps = {};

export default NormalPrints;
