// import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";

// import "./EditorFontOption.scss";

// const EditorFontOption = ({ label, fontName, active, onClick }) => {
//   const style = {
//     fontFamily: fontName,
//   };

//   const classes = classNames("editor__font-option animated fadeIn", {
//     "editor__font-option--active": active,
//   });

//   return (
//     <button className={classes} style={style} onClick={onClick}>
//       {label}
//     </button>
//   );
// };

// EditorFontOption.propTypes = {
//   label: PropTypes.string.isRequired,
//   fontName: PropTypes.string.isRequired,
//   active: PropTypes.bool.isRequired,
//   onClick: PropTypes.func,
// };

// EditorFontOption.defaultProps = {};

// export default EditorFontOption;


import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import isElementInViewport from "../../lib/is-element-in-viewport";
import "./EditorFontOption.scss";

const EditorFontOption = ({ label, fontName, active, onClick }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (active) {
      scrollToActiveChild();
    }
  }, [active]);

  const style = {
    fontFamily: fontName,
  };

  const classes = classNames("editor__font-option animated fadeIn", {
    "editor__font-option--active": active,
  });

  const scrollToActiveChild = () => {
    setTimeout(() => {
      try {
        const $node = buttonRef.current.parentNode; // Get the parent node of the button
        const $activeChild = buttonRef.current;

        if ($activeChild && !isElementInViewport($activeChild)) {
          const styles = window.getComputedStyle($node);
          const scrollerWidth = parseInt(styles.width, 10)
          const activeChildWidth = parseInt(window.getComputedStyle($activeChild).width, 10)
          //console.log("scrollerWidth", scrollerWidth)
          //console.log("activeChildWidth", activeChildWidth)
          const centerScroll = parseInt((scrollerWidth/2) + (activeChildWidth/2), 10)
          //console.log("centerScroll", centerScroll)
          const paddingLeft = parseInt(styles.paddingLeft, 10);
          //console.log("paddingLeft", paddingLeft)
          //console.log("styles.paddingLeft", styles.paddingLeft)
          $node.scrollLeft = $activeChild.offsetLeft - paddingLeft - 20;
          //console.log("$activeChild.offsetLeft - paddingLeft", $activeChild.offsetLeft - paddingLeft)
        }
      } catch (err) {}
    }, 0);
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    scrollToActiveChild();
  };

  return (
    <button ref={buttonRef} className={classes} style={style} onClick={handleClick}>
      {label}
    </button>
  );
};

EditorFontOption.propTypes = {
  label: PropTypes.string.isRequired,
  fontName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default EditorFontOption;
