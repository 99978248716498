import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PrePay from "./PrePay";
import { selectors as authSelectors, actions as authActions } from "../../store/ducks/auth";
import { selectors as basketSelectors, actions as basketActions } from "../../store/ducks/basket";
import { topups } from "../../data/topups";

import { message } from "antd";

class PrePayContainer extends Component {
  static propTypes = {
    balance: PropTypes.object,
    currency: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    canMakeStripePaymentRequest: false,
    selectedTopupOption: null,
    paymentRequest: null,
    isPayingForTopup: false,
    isPayingForTopupWithSavedCard: false,
  };

  constructor(props) {
    super(props);

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: props.currency.toLowerCase(),
      total: {
        label: "Order total",
        amount: 0,
      },
    });

    // paymentRequest.on("token", ({ complete, token, ...data }) => {
    //   complete("success");
    //   this.handleStripePaymentRequestSuccess(token, data);
    // });

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      console.log("Payment Method", ev)
      
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      console.log("Topup reference is", topupPurchaseResponse.payload.data.data.reference);
      const clientSecretResponse = await this.props.createTopupPaymentIntent({
        amount: Math.round(this.state.selectedTopupOption.get("cost") * 100),
        currency: props.currency.toLowerCase(),
        reference: topupPurchaseResponse.payload.data.data.reference
      });
      const paymentIntentClientSecret = clientSecretResponse.payload.data.data.client_secret

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        console.log(confirmError.message)
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        console.log("paymentIntent.status", paymentIntent.status)
        if (paymentIntent.status === "requires_action" || paymentIntent.status === "requires_source_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret);
          if (error) {
            console.log("Error", error)
            this.handleStripePaymentRequestFailed(error.message)
            // The payment failed -- ask your customer for a new payment method.
          } else {
            this.handleStripePaymentRequestComplete()
            // The payment has succeeded.
          }
        } else {
          this.handleStripePaymentRequestComplete()
          // The payment has succeeded.
          //handleStripePaymentRequestSuccess()
        }
      }
    });


    paymentRequest.canMakePayment().then(result => {
      this.setState({
        canMakeStripePaymentRequest: Boolean(result),
        paymentRequest,
      });
    });
  }

  handleSelectTopupOption = topup => {
    this.setState({
      selectedTopupOption: topup,
    });

    if (this.state.paymentRequest) {
      //console.log('Updating payment request');
      this.state.paymentRequest.update({
        currency: this.props.currency.toLowerCase(),
        total: {
          label: "Order total",
          amount: Math.round(topup.get("cost") * 100),
        },
      });
    }
  };

  handleStripePaymentRequestSuccess = async (token, data) => {
    this.handleStripePaymentFormSubmit({ result: { token } });
  };

  handleStripePaymentRequestFailed = async (errorMessage) => {
    message.error(errorMessage);
    this.setState({
      isPayingForTopup: false,
    });
  }

  handleStripePaymentRequestComplete = async () => {
    message.success("Payment Complete");
    this.setState({
      isPayingForTopup: false,
      selectedTopupOption: null,
    });
  }

  handleStripePaymentComplete = async (result) => {
    message.success("Payment Complete");
    this.setState({
      isPayingForTopup: false,
      selectedTopupOption: null,
    });
  }

  handleStripePaymentError = async (errorMessage) => {
    message.error(errorMessage);
    this.setState({
      isPayingForTopup: false,
    });
  }

  handleConfirmStripePayment = async (intent) => {
    this.setState({
      isPayingForTopup: true,
    });
    try {
      console.log("Creating topup purchase for", this.state.selectedTopupOption.get("id"));
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      console.log("Topup reference is", topupPurchaseResponse.payload.data.data.reference);
      const reference = topupPurchaseResponse.payload.data.data.reference;
      const response = await this.props.confirmStripeTopupPayment({
        intent: intent,
        reference,
      });

      if (response.error){
        console.log("Error", response.error)
      } else {
        console.log(response.payload);
        return response.payload
      }
      console.log("confirmation response", response);
    } catch (err) {
      console.warn("Error while paying for topup with Stripe:", err);
    } finally {
      this.setState({
        isPayingForTopup: false,
      });
    }
  }

  handlePaypalAuthorization = async response => {
    this.setState({
      isPayingForTopup: true,
    });

    try {
      console.log("Creating topup purchase for", this.state.selectedTopupOption.get("id"));
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      console.log("Topup reference is", topupPurchaseResponse.payload.data.data.reference);
      const reference = topupPurchaseResponse.payload.data.data.reference;
      const payPalResponse = await this.props.confirmPaypalPaymentForTopupPurchase({
        token: response.id,
        reference,
      });
      console.log("payPalResponse", payPalResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Paypal:", err);
    } finally {
      this.setState({
        isPayingForTopup: false,
        selectedTopupOption: null,
      });
    }
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    this.setState({
      isPayingForTopup: true,
    });

    try {
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );

      const reference = topupPurchaseResponse.payload.data.data.reference;
      await this.props.chargeStripePaymentForTopupPurchase({
        stripeToken: result.token.id,
        saveCardDetails,
        reference,
      });
      //console.log(topupPaymentResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Stripe:", err);
    } finally {
      this.setState({
        isPayingForTopup: false,
        selectedTopupOption: null,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    this.setState({
      isPayingForTopupWithSavedCard: true,
    });

    try {
      const topupPurchaseResponse = await this.props.createTopupPurchase(
        this.state.selectedTopupOption.get("id")
      );
      const reference = topupPurchaseResponse.payload.data.data.reference;
      await this.props.chargeStripeCustomerForTopupPurchase(reference);
      //console.log(topupPaymentResponse);
    } catch (err) {
      console.warn("Error while paying for topup with Stripe:", err);
    } finally {
      this.setState({
        isPayingForTopupWithSavedCard: false,
        selectedTopupOption: null,
      });
    }
  };

  clearSelectedTopupOption = () => {
    this.setState({
      selectedTopupOption: null,
    });
  };

  render() {
    const availableTopups = topups.filter(topup => topup.get("currency") === this.props.currency);
    return (
      <PrePay
        {...this.props}
        currency={this.props.currency}
        topupOptions={availableTopups}
        selectedTopupOption={this.state.selectedTopupOption}
        onSelectTopupOption={this.handleSelectTopupOption}
        onClearSelectedTopupOption={this.clearSelectedTopupOption}
        onStripePaymentFormSubmit={this.handleStripePaymentFormSubmit}
        onPayWithSavedCard={this.handlePayWithSavedCard}
        onPayPalAuthorization={this.handlePaypalAuthorization}
        onSwitchToCurrency={this.props.switchToCurrency}
        canMakeStripePaymentRequest={this.state.canMakeStripePaymentRequest}
        paymentRequest={this.state.paymentRequest}
        isPayingForTopup={this.state.isPayingForTopup}
        isPayingForTopupWithSavedCard={this.state.isPayingForTopupWithSavedCard}
        handleConfirmStripePayment={this.handleConfirmStripePayment}
        onStripePaymentComplete={this.handleStripePaymentComplete}
        onStripePaymentError={this.handleStripePaymentError}
      />
    );
  }
}

const mapStateToProps = state => ({
  balance: authSelectors.getPrePayBalance(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  switchToCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  confirmStripeTopupPayment: ({ reference, intent }) => dispatch(basketActions.confirmStripeTopupPayment({ intent, reference })),
  createTopupPurchase: topupId => dispatch(authActions.createTopupPurchase(topupId)),
  chargeStripePaymentForTopupPurchase: ({ stripeToken, reference, saveCardDetails }) => {
    return dispatch(
      authActions.chargeStripePaymentForTopupPurchase({
        stripeToken,
        reference,
        saveCardDetails,
      })
    );
  },
  chargeStripeCustomerForTopupPurchase: reference => {
    return dispatch(authActions.chargeStripeCustomerForTopupPurchase(reference));
  },
  confirmPaypalPaymentForTopupPurchase: ({ token, reference }) => {
    return dispatch(authActions.confirmPaypalPaymentForTopupPurchase({ token, reference }));
  },
  createTopupPaymentIntent: (intentData) =>
    dispatch(authActions.createTopupPaymentIntent(intentData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrePayContainer);
