import React, { Component } from "react";
import PropTypes from "prop-types";
//import ReactJSONSchemaForm from "react-jsonschema-form";
import Form from "@rjsf/core";
import Button from "../Button/Button";

import "./Form.scss";

class PostSnapForm extends Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object,
    formData: PropTypes.any,
    submitButtonLabel: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    submitButtonLabel: "Submit",
  };

  transformErrors = (errors) => {
    //console.log(errors);
    return errors;
  }

  render() {
    const defaultSubmitButton = (
      <Button block label={this.props.submitButtonLabel} type="submit" priority="primary" />
    );

    return (
      <Form
        className="form"
        schema={this.props.schema}
        uiSchema={this.props.uiSchema}
        formData={this.props.formData}
        onChange={this.props.onChange}
        onSubmit={this.props.onSubmit}
        transformErrors={this.transformErrors}
        noHtml5Validate={false}
        {...this.props}
      >
        {this.props.children || defaultSubmitButton}
      </Form>
    );
  }
}

export default PostSnapForm;
