export default {
  "20x16-framed-print": [ // must match app key
    {
      sku: 'black',
      name: 'Black',
      colour: 'black',
      frameBorderImageSrc: '/app/images/frames/frame_OVF25.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF25.jpg',
      frameConfig: {
        borderWidthPercent:5.81, //what percentage of border width in frameBorderImageSrc is the frame (20/300)
        borderSlicePx:32, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 33, //mm
      }
    },
    {
      sku: 'white',
      name: 'White',
      colour: 'white',
      frameBorderImageSrc: '/app/images/frames/frame_OVF24.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF24.jpg',
      frameConfig: {
        borderWidthPercent:5.81, //what percentage of border width is the frame (32/550)*100
        borderSlicePx:32, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 33,
      }
    },
    {
      sku: 'wood',
      name: 'Wood',
      colour: 'wood',
      frameBorderImageSrc: '/app/images/frames/frame_OVF16.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF16.jpg',
      frameConfig: {
        borderWidthPercent:6.54, //what percentage of border width is the frame (36/550)*100
        borderSlicePx:36, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 38 // mm
      }
    },
  ],
  "16x16-framed-print": [
    {
      sku: 'black',
      name: 'Black',
      colour: 'black',
      frameBorderImageSrc: '/app/images/frames/frame_OVF25_square.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF25.jpg',
      frameConfig: {
        borderWidthPercent:7.09, //what percentage of border width in frameBorderImageSrc is the frame (20/300)
        borderSlicePx:39, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 33, //mm
      }
    },
    {
      sku: 'white',
      name: 'White',
      colour: 'white',
      frameBorderImageSrc: '/app/images/frames/frame_OVF24_square.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF24.jpg',
      frameConfig: {
        borderWidthPercent:7.09, //what percentage of border width is the frame (32/550)*100
        borderSlicePx:39, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 33,
      }
    },
    {
      sku: 'wood',
      name: 'Wood',
      colour: 'wood',
      frameBorderImageSrc: '/app/images/frames/frame_OVF16_square.jpeg',
      framePreviewImageSrc: '/app/images/frames/frame_preview_OVF16.jpg',
      frameConfig: {
        borderWidthPercent:8.0, //what percentage of border width is the frame (36/550)*100
        borderSlicePx:44, //how many pixels does the frame on the border image take up
        hasMount: false,
        realWidth: 38 // mm
      }
    },
  ]
};
  