import React, { useEffect, useMemo, useState } from "react";
import HtmlRenderer from "../../../../components/HtmlRenderer/HtmlRenderer";
import { productsByProductId } from "../../../../data/products";
import shortid from "shortid";
import { useProduct } from "../../../../utils/useProduct";

const BLEED_BORDER = 1; //px;

export function TilePreview({
  configuration,
  onClick,
  itemData,
  additionalStack = 0,
  page = 0,
  bleedDisabled = true,
  frameInnerShadow = "rgba(137, 137, 137, 0.25) 2px 2px 7px 1px inset",
  showBorderCanvas = true,
}) {
  const product = useProduct(itemData?.productId);
  const layer = itemData?.layers?.find(
    layer => layer.type === "Layer::Photo" && layer.page === page
  );

  const ADDITIONAL_BLEED_AREA = 2; // mm;
  const bleedArea =
    ((Number(product?.bleed_l) + ADDITIONAL_BLEED_AREA) / 200) * configuration.width;
  const tileWidth = configuration.width + 2 * configuration.bleedArea;
  const tileHeight = configuration.height + 2 * configuration.bleedArea;
  const frameLength = tileWidth + 2 * (configuration.frameWidth - configuration.frameBleedArea);
  const boxShadow = configuration.withFrame
    ? `3px 3px ${(25 / 200) * configuration.width}px rgba(0, 0, 0, 0.5)`
    : `3px 3px ${(15 / 200) * configuration.width}px rgba(0, 0, 0, 0.2)`;

  const containerWidth = configuration.withFrame ? frameLength : tileWidth;
  const containerHeight = configuration.withFrame ? frameLength : tileHeight;

  const shadowContainerTop = configuration.withFrame
    ? configuration.frameWidth - configuration.frameBleedArea
    : 0;
  const shadowContainerLeft = configuration.withFrame
    ? configuration.frameWidth - configuration.frameBleedArea
    : 0;

  const onClickResponse = event => {
    event.stopPropagation();
    onClick && onClick();
  };

  const stacks = useMemo(() => new Array(additionalStack).fill().map(() => shortid.generate()), [
    additionalStack,
  ]);

  const stackBorder = 0.005 * configuration.width; // 1px on 200 width
  const stackBorderColor = configuration.frameColor ?? "white";

  const showBleed =
    !bleedDisabled &&
    !configuration.withFrame &&
    layer?.config?.layout?.[0]?.image?.borderPrint?.width === 0;

  if (!itemData) return null;

  const showNonShadowInner = false; // this is left here in case we need to revert back to non-shadow shadow

  const hasImage = layer?.config.layout?.[0]?.image?.src?.lowResUrl !== undefined;

  return (
    <>
      <div
        style={{
          display: "flex",
          width: containerWidth + configuration.depth + additionalStack * configuration.depth,
          height: containerHeight + configuration.depth + additionalStack * configuration.depth,
          position: "relative",
        }}
      >
        {/*// container div to apply shadow*/}
        <div
          style={{
            boxShadow,
            width: tileWidth,
            height: tileHeight,
            position: "absolute",
            top: shadowContainerTop,
            left: shadowContainerLeft,
          }}
          onClickCapture={onClickResponse}
        >
          <div style={{ position: "relative" }}>
            {!hasImage && (
              <div
                style={{
                  width: tileWidth,
                  height: tileWidth,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: configuration.borderColor,
                  padding: configuration.borderWidth,
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="html-renderer-photo-layer__region__placeholder-icon animated fadeIn"
                    src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
                    alt=""
                  />
                </div>
              </div>
            )}
            {hasImage && (
              <HtmlRenderer
                width={tileWidth}
                page={page}
                item={itemData}
                screenshotMode
                disableTouchHandling
                showBorderCanvas={showBorderCanvas}
              />
            )}
            {showBleed && (
              <>
                {/*bleed top*/}
                <div
                  style={{
                    width: tileWidth - 2 * (bleedArea - BLEED_BORDER),
                    height: bleedArea,
                    position: "absolute",
                    top: 0,
                    left: bleedArea,
                    borderBottom: `${BLEED_BORDER}px dashed transparent`,
                    backgroundImage: "linear-gradient(to right, #49fb35 50%, red 50%)",
                    backgroundSize: `10px ${BLEED_BORDER}px`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "0 100%",
                    borderImage:
                      "linear-gradient(to right, #49fb35 50%, red 50%) 1 / 0 / 0 stretch",
                  }}
                />
                {/*bleed bottom*/}
                <div
                  style={{
                    width: tileWidth - 2 * (bleedArea - BLEED_BORDER),
                    height: bleedArea - BLEED_BORDER,
                    position: "absolute",
                    bottom: 0,
                    left: bleedArea - BLEED_BORDER,
                    borderTop: `${BLEED_BORDER}px dashed transparent`,
                    backgroundImage: "linear-gradient(to right, #49fb35 50%, red 50%)",
                    backgroundSize: `10px ${BLEED_BORDER}px`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "0 0",
                    borderImage:
                      "linear-gradient(to right, #49fb35 50%, red 50%) 1 / 0 / 0 stretch",
                  }}
                />
                {/*bleed left*/}
                <div
                  style={{
                    width: bleedArea - BLEED_BORDER,
                    height: tileWidth - 2 * (bleedArea - BLEED_BORDER),
                    position: "absolute",
                    left: 0,
                    top: bleedArea - BLEED_BORDER,
                    borderRight: `${BLEED_BORDER}px dashed transparent`,
                    backgroundImage: "linear-gradient(to bottom, #49fb35 50%, red 50%)",
                    backgroundSize: `${BLEED_BORDER}px 10px`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "100% 0",
                    borderImage:
                      "linear-gradient(to bottom, #49fb35 50%, red 50%) 1 / 0 / 0 stretch",
                  }}
                />
                {/*bleed right*/}
                <div
                  style={{
                    width: bleedArea - BLEED_BORDER,
                    height: tileWidth - 2 * (bleedArea - BLEED_BORDER) - BLEED_BORDER,
                    position: "absolute",
                    right: 0,
                    top: bleedArea - BLEED_BORDER,
                    borderLeft: `${BLEED_BORDER}px dashed transparent`,
                    backgroundImage: "linear-gradient(to bottom, #49fb35 50%, red 50%)",
                    backgroundSize: `${BLEED_BORDER}px 10px`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "0 0",
                    borderImage:
                      "linear-gradient(to bottom, #49fb35 50%, red 50%) 1 / 0 / 0 stretch",
                  }}
                />
              </>
            )}
          </div>
          {/* right depth side */}
          <div
            style={{
              height: tileHeight,
              width: configuration.depth,
              backgroundColor: "lightgray",
              position: "absolute",
              top: configuration.depth / 2,
              right: -configuration.depth,
              transform: "skewY(45deg)",
            }}
          />
          {stacks.map((id, index) => (
            <div
              style={{
                height: tileHeight,
                width: configuration.depth,
                backgroundColor: "lightgray",
                position: "absolute",
                top: configuration.depth / 2 + configuration.depth * (index + 1),
                right: -configuration.depth - configuration.depth * (index + 1),
                borderLeft: "2px solid white",
                transform: "skewY(45deg)",
              }}
            />
          ))}
          {/* bottom depth side */}
          <div
            style={{
              width: tileWidth,
              height: configuration.depth,
              backgroundColor: "gray",
              position: "absolute",
              bottom: -configuration.depth,
              left: configuration.depth / 2,
              transform: "skewX(45deg)",
            }}
          />
          {stacks.map((id, index) => (
            <div
              style={{
                width: tileWidth,
                height: configuration.depth,
                backgroundColor: "gray",
                position: "absolute",
                bottom: -configuration.depth - configuration.depth * (index + 1),
                left: configuration.depth / 2 + configuration.depth * (index + 1),
                borderTop: `${stackBorder}px solid white`,
                transform: "skewX(45deg)",
              }}
            />
          ))}

          {/* This is the frame */}
          {configuration.withFrame && (
            <>
              {/* Inner shadow box*/}
              <div
                style={{
                  width: tileWidth - 2 * bleedArea,
                  height: tileHeight - 2 * bleedArea,
                  // backgroundColor: "red",
                  // opacity: "0.5",
                  position: "absolute",
                  top: bleedArea,
                  left: bleedArea,
                  boxShadow: frameInnerShadow,
                }}
              />

              {showNonShadowInner && (
                <>
                  {/*Frame inner shadow - top*/}
                  <div
                    style={{
                      width: tileWidth - configuration.frameBleedArea,
                      height: configuration.frameInnerDepth,
                      position: "absolute",
                      backgroundColor: "gray",
                      top: configuration.frameBleedArea,
                      left: configuration.frameBleedArea,
                      transform: "skewX(45deg)",
                    }}
                  />
                  {/* Frame inner shadow left */}
                  <div
                    style={{
                      width: configuration.frameInnerDepth,
                      height: tileHeight - configuration.frameBleedArea,
                      position: "absolute",
                      backgroundColor: "lightgray",
                      top: configuration.frameBleedArea + configuration.frameInnerDepth / 2,
                      left: configuration.frameBleedArea,
                      transform: "skewY(45deg)",
                    }}
                  />
                  {/*Frame inner shadow bottom */}
                  <div
                    style={{
                      width: tileWidth,
                      height: 1,
                      backgroundColor: "lightgray",
                      bottom: configuration.frameBleedArea,
                      left: 0,
                      position: "absolute",
                    }}
                  />
                  {/* Frame inner shadow right*/}
                  <div
                    style={{
                      width: 1,
                      height: tileHeight,
                      backgroundColor: "lightgray",
                      top: 0,
                      right: configuration.frameBleedArea,
                      position: "absolute",
                    }}
                  />
                </>
              )}

              {/* Top part */}
              <div
                style={{
                  width: frameLength,
                  height: configuration.frameWidth,
                  position: "absolute",
                  backgroundColor: configuration.frameColor,
                  top: -(configuration.frameWidth - configuration.frameBleedArea),
                  left: -(configuration.frameWidth - configuration.frameBleedArea),
                }}
              />
              {/* Bottom part */}
              <div
                style={{
                  width: frameLength,
                  height: configuration.frameWidth,
                  backgroundColor: configuration.frameColor,
                  position: "absolute",
                  bottom: -(configuration.frameWidth - configuration.frameBleedArea),
                  left: -(configuration.frameWidth - configuration.frameBleedArea),
                }}
              />
              {/* Left part */}
              <div
                style={{
                  width: configuration.frameWidth,
                  height: frameLength,
                  backgroundColor: configuration.frameColor,
                  position: "absolute",
                  top: -(configuration.frameWidth - configuration.frameBleedArea),
                  left: -(configuration.frameWidth - configuration.frameBleedArea),
                }}
              />
              {/* Right Part */}
              <div
                style={{
                  width: configuration.frameWidth,
                  height: frameLength,
                  backgroundColor: configuration.frameColor,
                  position: "absolute",
                  bottom: -(configuration.frameWidth - configuration.frameBleedArea),
                  right: -(configuration.frameWidth - configuration.frameBleedArea),
                }}
              />
              {/* Right Depth */}
              <div
                style={{
                  width: configuration.depth,
                  height: frameLength,
                  backgroundColor: "lightgray",
                  position: "absolute",
                  top:
                    -configuration.frameWidth +
                    configuration.frameBleedArea +
                    configuration.depth / 2,
                  right: -(
                    configuration.frameWidth -
                    configuration.frameBleedArea +
                    configuration.depth
                  ),
                  transform: "skewY(45deg)",
                }}
              />
              {stacks.map((id, index) => (
                <div
                  key={id}
                  style={{
                    width: configuration.depth,
                    height: frameLength,
                    backgroundColor: "lightgray",
                    position: "absolute",
                    top:
                      -configuration.frameWidth +
                      configuration.frameBleedArea +
                      configuration.depth / 2 +
                      configuration.depth * (index + 1),
                    right:
                      -(
                        configuration.frameWidth -
                        configuration.frameBleedArea +
                        configuration.depth
                      ) -
                      configuration.depth * (index + 1),
                    transform: "skewY(45deg)",
                    borderLeft: `${stackBorder}px solid ${stackBorderColor}`,
                  }}
                />
              ))}

              {/* Bottom Depth */}
              <div
                style={{
                  width: frameLength,
                  height: configuration.depth,
                  backgroundColor: "gray",
                  position: "absolute",
                  bottom:
                    -configuration.frameWidth + configuration.frameBleedArea - configuration.depth,
                  left:
                    -configuration.frameWidth +
                    configuration.frameBleedArea +
                    configuration.depth / 2,
                  transform: "skewX(45deg)",
                }}
              />
              {stacks.map((id, index) => (
                <div
                  style={{
                    width: frameLength,
                    height: configuration.depth,
                    backgroundColor: "gray",
                    position: "absolute",
                    bottom:
                      -configuration.frameWidth +
                      configuration.frameBleedArea -
                      configuration.depth +
                      -configuration.depth * (index + 1),
                    left:
                      -configuration.frameWidth +
                      configuration.frameBleedArea +
                      configuration.depth / 2 +
                      configuration.depth * (index + 1),
                    transform: "skewX(45deg)",
                    borderTop: `${stackBorder}px solid ${stackBorderColor}`,
                  }}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}
