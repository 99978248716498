import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import TruncatedText from "../Formatters/TruncatedText";

import "./Header.scss";

const Header = ({ theme, title, image, leftAction, rightAction, onClickTitleOrImage, style }) => (
  <header style={style} className={classNames("header", `header--theme-${theme}`)}>
    <div className="header__content">
      {leftAction && <div className="header__action header__action--left">{leftAction}</div>}
      {title && !image && (
        <div className="header__center">
          <h1
            className={`header__title ${onClickTitleOrImage ? "" : "no-click"}`}
            onClick={onClickTitleOrImage}
          >
            <TruncatedText width="52vw">{title}</TruncatedText>
          </h1>
        </div>
      )}
      {image && (
        <div className="header__center">
          <h1>
            <img
              src={`${process.env.PUBLIC_URL}${image}`}
              alt={title}
              title={title}
              className="header__image"
              onClick={onClickTitleOrImage}
            />
          </h1>
        </div>
      )}
      {rightAction && <div className="header__action header__action--right">{rightAction}</div>}
    </div>
  </header>
);

Header.propTypes = {
  /** Determines the look of the Header. */
  theme: PropTypes.oneOf(["default", "grey", "mini"]),
  /** Shown in the center of the Header. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Shown in the center of the Header. */
  image: PropTypes.string,
  /** Node rendered on the left side of the Header. Usually a `<Button />`. */
  leftAction: PropTypes.node,
  /** Node rendered on the right side of the Header. Usually a `<Button />`. */
  rightAction: PropTypes.node,
  /** Fired when the title or image in the center is clicked. */
  onClickTitleOrImage: PropTypes.func,
};

Header.defaultProps = {};

export default Header;
