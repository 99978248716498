import $ from "jquery";
import React, { Component } from "react";
import PropTypes from "prop-types";
import SweetAlert from "../SweetAlert/SweetAlert";
import Modal from "../Modal/Modal";
import Form from "../Form/Form";
import Button from "../Button/Button";
import Divider from "../Divider/Divider";
import Grid from "../Grid/Grid";
import gtmEvent from "../../utils/gtm";
import { COUNTRIES } from "../../constants/geo";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import ModalPage from "../Modal/ModalPage";
import PasswordField from "../PasswordField/PasswordField";
//import queryString from "query-string";
import { countriesByCurrency } from "../../constants/geo";

import { connect } from "react-redux";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { selectors as uiSelectors } from "../../store/ducks/ui";

const DEFAULT_COUNTRY = "United Kingdom";

const MODAL_PAGES = {
  SIGN_UP_OR_IN: "SIGN_UP_OR_IN",
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

const SCHEMAS = {
  SIGN_IN: {
    showErrorList: false,
    schema: {
      type: "object",
      properties: {
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7, autoComplete: "password-current" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signIn",
      email: {
        "ui:widget": "email",
        "ui:autocomplete": "username"
      },
      password: {
        "ui:widget": PasswordField,
        "ui:autocomplete": "password-current"
      },
    },
  },
  SIGN_UP: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["first_name", "email", "address", "password"],
      properties: {
        first_name: { type: "string", title: "First Name" },
        last_name: { type: "string", title: "Last Name" },
        email: { type: "string", title: "Email" },
        mobile: { type: "string", title: "Mobile Phone" },
        use_shipping_address: {
          type: "boolean",
          title: "",
        },
        address: {
          type: "object",
          properties: {
            address_book_entry: {
              type: "object",
              required: ["line1", "city", "country", "postcode"],
              properties: {
                country: {
                  type: "string",
                  title: "Country",
                  enum: COUNTRIES.map(c => c.name),
                },
                company_name: { type: "string", title: "Company Name" },
                line1: { type: "string", title: "Line 1" },
                line2: { type: "string", title: "Line 2" },
                line3: { type: "string", title: "Line 3" },
                city: { type: "string", title: "Town/City" },
                county: { type: "string", title: "County/State" },
                postcode: { type: "string", title: "Postcode/ZIP" },
              },
            },
          },
        },
        password: { type: "string", title: "Password", minLength: 7 },
        marketing_opt_in: {
          type: "boolean",
          title: "Email preference",
        },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signUp",
      "ui:order": [
        "email",
        "password",
        "use_shipping_address",
        "first_name",
        "last_name",
        "address",
        "mobile",
        "marketing_opt_in",
      ],
      use_shipping_address: {
        classNames: "hidden-label",
        "ui:widget": props => {
          return (
            <div
              style={{
                width: "280px",
                padding: "7px 10px",
                borderRadius: "6px",
                backgroundColor: "white",
                border: "1px solid #4b5566",
                color: "#4b5566",
              }}
            >
              <Grid.Row>
                <Grid.Column size="0 auto">
                  <div className="pretty p-svg" style={{ marginRight: 0 }}>
                    <input
                      id="sign-up-marketing-email"
                      type="checkbox"
                      checked={props.value}
                      onChange={() => props.onChange(!props.value)}
                    />
                    <div className="state">
                      <img
                        className="svg"
                        style={{background: "black"}}
                        src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                        alt=""
                      />
                      <label htmlFor="">&nbsp;</label>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <label htmlFor="sign-up-marketing-email">Use Shipping Details For Sign up</label>
                </Grid.Column>
              </Grid.Row>
            </div>
          );
        },
      },
      marketing_opt_in: {
        "ui:widget": props => {
          return (
            <Grid.Row>
              <Grid.Column size="0 auto">
                <div className="pretty p-svg" style={{ marginRight: 0 }}>
                  <input
                    id="sign-up-marketing-email"
                    type="checkbox"
                    checked={props.value}
                    onChange={() => props.onChange(!props.value)}
                  />
                  <div className="state">
                    <img
                      className="svg"
                      style={{background: "black"}}
                      src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                      alt=""
                    />
                    <label htmlFor="">&nbsp;</label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="sign-up-marketing-email">
                  Send me emails with the latest news and offers
                </label>
              </Grid.Column>
            </Grid.Row>
          );
        },
      },
      email: {
        "ui:widget": "email",
        "ui:autocomplete": "username"
      },
      mobile: {
        "ui:help": "Add your mobile phone number so that we can contact you quickly if there is a problem with your order.",
      },
      password: {
        "ui:widget": PasswordField,
        "ui:help": "(Must be 7 characters inc. at least one number.)",
        "ui:autocomplete": "password-current"
      },
    },
  },
  SIGN_UP_WITHOUT_ADDRESS: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["first_name", "email", "password"],
      properties: {
        first_name: { type: "string", title: "Name" },
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7 },
        mobile: { type: "string", title: "Mobile Phone Number" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signUpNoAddress",
      "ui:order": [
        "first_name",
        "email",
        "password",
        "mobile",
      ],
      email: {
        "ui:widget": "email",
      },
      mobile: {
        "ui:help": "This helps us to contact you if we have issues with your order. We will never display your number publicly or use it for any other reason.",
      },
      password: {
        "ui:widget": PasswordField,
        "ui:help": "(Must be 7 characters inc. at least one number.)",
      },
    },
  },
  REQUEST_PASSWORD_RESET: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Email" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "forgotPassword",
      email: {
        "ui:widget": "email",
      },
    },
  },
};

class AuthModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSignIn: PropTypes.func,
    onSignUp: PropTypes.func,
    onRequestPasswordReset: PropTypes.func,
    withAddress: PropTypes.bool, 
    onActionAfterGuestAuth: PropTypes.func,
  };

  static defaultProps = {
    onActionAfterGuestAuth: () => {}
  }

  defaultState = {
    activePage: MODAL_PAGES.SIGN_UP_OR_IN,
    signInData: {},
    requestPasswordResetData: {},
    signUpData: {
      marketing_opt_in: true,
      address: {
        address_book_entry: {
          country: countriesByCurrency[this.props.currency] || DEFAULT_COUNTRY,
        },
      },
    },
    signUpNoAddressData: {
      marketing_opt_in: true,
    },
    alert: null,
    isLoadingRequest: false,
    isSigningIn: false,
    isSigningUp: false,
    shippingAddressAvailable: false,
    shippingAddress: {},
    hasUsedShippingAddress: false,
    hasUndoneShippingAddress: false,
    signInDisabled: true,
  };

  state = { ...this.defaultState };

  componentDidMount() {  
    //const parsedQueryString = queryString.parse(window.location.search);
    if (window.location.search.includes("?sign-in")){
      this.setActivePage(MODAL_PAGES.SIGN_IN);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen){
      //log opening the Auth modal
      gtmEvent({event: "webAppStage", additionalProps: {stage: 'Sign Up or Sign In'}})
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(this.defaultState);

      const anyPrints = this.props.basketItems.filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
      if(anyPrints.size > 0){
        const firstAddress = anyPrints.first()

        if (firstAddress && firstAddress.get("address")){
          const printShippingAddress = firstAddress.get("address").toJS();

          if (this.state.shippingAddressAvailable === false) {
            this.setState({
              shippingAddressAvailable: true,
              shippingAddress: printShippingAddress,
            });
          }
        }
      }
    } else{

      const anyPrints = this.props.basketItems.filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
      if(anyPrints.size > 0){
        const firstAddress = anyPrints.first()
        //console.log("Use shipping Address is", firstAddress);
        if (firstAddress && firstAddress.get("address")){
          const printShippingAddress = firstAddress.get("address").toJS();

          if (this.state.shippingAddressAvailable === false) {
            this.setState({
              shippingAddressAvailable: true,
              shippingAddress: printShippingAddress,
            });
          }
        }
      }
    }
  }

  handleSignUpFormSuccess = async () => {
    await this.props.onActionAfterGuestAuth()
    this.props.onClose()
  }

  handleSignInFormChange = form => {
    let data = form.formData
    // Horrible hack because browsers no longer seem to trigger onChange for password autofill
    // TODO: investigate a better way of doing this...
    if (!form.formData.password && $('#signIn #pw-input').attr('data-ps-val')){
      data = {...data, password: $('#signIn #pw-input').attr('data-ps-val')}
    }
    this.setState({
      signInData: data,
    });
  };

  handleSignInFormSubmit = async form => {
    const { email, password } = form.formData;
    this.setState({
      isLoadingRequest: true,
      isSigningIn: true,
    });
    try {
      const result = await this.props.onSignIn({ email, password });
      if (result && result.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't sign you in",
            text: result.payload.data.error,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.props.onClose();
      }
    } catch (err) {
      console.warn("Error while signing in:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningIn: false,
      });
    }
  };

  handleRequestPasswordResetFormChange = form => {
    this.setState({
      requestPasswordResetData: form.formData,
    });
  };

  handleRequestPasswordResetFormSubmit = async form => {
    const { email } = form.formData;
    this.setState({
      isLoadingRequest: true,
    });

    try {
      const result = await this.props.onRequestPasswordReset(email);
      if (result.payload.data.success) {
        this.props.onClose();
        this.setState({
          alert: {
            type: "success",
            title: "Email sent",
            text: "Please check your email for instructions on how to reset your password.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        const errors = result.payload.data.errors;
        let title;
        let text;

        if (errors.email) {
          if (errors.email[0] === "not found") {
            title = "Email not found";
            text =
              "This email address was not found. Please check the email address for any mistakes and try again.";
          }
        }

        this.setState({
          alert: {
            type: "error",
            title,
            text,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      }
    } catch (err) {
      console.warn("Error while requesting password reset:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  handleSignUpWithoutAddressFormChange = form => {
    const signUpNoAddressData = { ...form.formData };
    this.setState({
      signUpNoAddressData: signUpNoAddressData,
    });
  }


  handleSignUpFormChange = form => {
    const signUpData = { ...form.formData };

    const noSignUpDataButUsingShipping =
      !this.state.signUpData && signUpData.use_shipping_address === true;
    const signUpDataAndUseShipping =
      this.state.signUpData && signUpData.use_shipping_address === true;

    const dontUseShipping = this.state.signUpData && signUpData.use_shipping_address === false;

    if (noSignUpDataButUsingShipping || signUpDataAndUseShipping) {
      if (this.state.hasUsedShippingAddress === false) {
        signUpData.address = {
          address_book_entry: {
            country: "United Kingdom",
            postcode: this.state.shippingAddress.postcode,
            company_name: this.state.shippingAddress.company_name,
            line1: this.state.shippingAddress.line1,
            line2: this.state.shippingAddress.line2,
            line3: this.state.shippingAddress.line3,
            city: this.state.shippingAddress.city,
            county: this.state.shippingAddress.county,
          },
        };

        const shippingName = this.state.shippingAddress.first_name;

        if (shippingName){
          const nameParts = shippingName.split(" ");
          if (nameParts.length > 1){
            signUpData.first_name = nameParts[0];
            signUpData.last_name = nameParts[1];
          } else{
            signUpData.first_name = this.state.shippingAddress.first_name;
          }
        }

        this.setState({
          hasUsedShippingAddress: true,
          hasUndoneShippingAddress: false,
        });
      }
    }

    if (dontUseShipping && this.state.hasUndoneShippingAddress === false) {
      signUpData.address = {
        address_book_entry: {
          country: signUpData.address.address_book_entry.country,
          postcode: "",
          company_name: "",
          line1: "",
          line2: "",
          line3: "",
          city: "",
          county: "",
        },
      };
      signUpData.first_name = "";
      signUpData.last_name = "";

      this.setState({
        hasUndoneShippingAddress: true,
        hasUsedShippingAddress: false,
      });
    }

    this.setState({
      signUpData: signUpData,
    });
  };

  handleSignUpFormSubmit = async form => {
    const signUpData = { ...form.formData };
    
    if (signUpData.address){
      signUpData.address.address_book_entry.first_name = signUpData.first_name;
      signUpData.address.address_book_entry.last_name = signUpData.last_name;
    }  
    //signUpData.address.address_book_entry.salutation = signUpData.salutation;

    this.setState({
      isLoadingRequest: true,
      isSigningUp: true
    });

    try {
      const result = await this.props.onSignUp(signUpData);
      if (result.error) {
        const errors = result.payload.data.errors;
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't create your account",
            text: errors[Object.keys(errors)[0]][0],
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Account Created",
            text: "Your account has been created, and you have been signed in.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        }, () => {
          gtmEvent({
            event: "webAppStage",
            additionalProps: {
              stage: 'Account Created'
            }
          })
        });
        //this.props.onClose();
        this.handleSignUpFormSuccess()
      }
    } catch (err) {
      let errorMessage = "There was a problem creating your account, please try again."
      if (err.payload){
        const errors = err.payload.data.errors;
        errorMessage = errors[Object.keys(errors)[0]][0];
      }
      
      this.setState({
        alert: {
          type: "error",
          title: "Couldn't create your account",
          text: errorMessage,
          showCancelButton: false,
        },
      });
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningUp: false,
      });
    }
  };

  setActivePage = activePage => {
    if(activePage === MODAL_PAGES.SIGN_IN){
      this.setState({
        signInDisabled: false
      }, () => {
        this.setState({ activePage }, () => {
          gtmEvent({event: "webAppStage", additionalProps: {stage: "Sign In"}})
        });
      })
    } else{
      this.setState({ activePage }, () => {
        let pageTitle = "Sign Up or Sign In";
        switch (this.state.activePage) {
          case MODAL_PAGES.SIGN_UP_OR_IN: {
            pageTitle = "Sign Up or Sign In";
            break;
          }
          case MODAL_PAGES.SIGN_IN: {
            pageTitle = "Sign In";
            break;
          }
          case MODAL_PAGES.SIGN_UP: {
            pageTitle = "Sign Up or Sign In";
            break;
          }
          case MODAL_PAGES.FORGOT_PASSWORD: {
            pageTitle = "Reset Password";
            break;
          }
          default:
            return pageTitle;
        }
        gtmEvent({event: "webAppStage", additionalProps: {stage: pageTitle}})
      });
    }

    

  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    //console.log("withAddress", this.props.withAddress);
    let headerTitle;
    let headerLeftAction = (
      <Button priority="tertiary" iconSize="small" theme="muted" icon="clear" onClick={this.props.onClose} />
    );

    let headerRightAction;

    switch (this.state.activePage) {
      case MODAL_PAGES.SIGN_UP_OR_IN: {
        headerTitle = "Sign Up or Sign In";
        break;
      }
      case MODAL_PAGES.SIGN_IN: {
        headerTitle = "Sign In";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_UP_OR_IN)}
          />
        );
        break;
      }
      case MODAL_PAGES.SIGN_UP: {
        headerTitle = "Sign Up or Sign In";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_UP_OR_IN)}
          />
        );
        // headerRightAction = (
        //   <Button
        //     label="Sign Up"
        //     priority="tertiary"
        //     onClick={this.handleSignUpFormSubmit}
        //   />
        // )
        break;
      }
      case MODAL_PAGES.FORGOT_PASSWORD: {
        headerTitle = "Reset Password";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.SIGN_IN)}
          />
        );
        break;
      }
      // no default
    }

    let signUpFormSchema = { ...SCHEMAS.SIGN_UP };
    if (!this.props.withAddress){
      signUpFormSchema = { ...SCHEMAS.SIGN_UP_WITHOUT_ADDRESS };
    }    

    if (signUpFormSchema.uiSchema.use_shipping_address){
      if (!this.state.shippingAddressAvailable) {
        signUpFormSchema.uiSchema.use_shipping_address.classNames = ["hidden-all"];
      } else {
        signUpFormSchema.uiSchema.use_shipping_address.classNames = ["hidden-label"];
        signUpFormSchema.uiSchema.use_shipping_address = {
          ...signUpFormSchema.uiSchema.use_shipping_address,
          "ui:options": {
            shippingAddress: this.state.shippingAddress,
          },
        };
      }
    }
    

    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Modal
        key="auth-modal"
        padded
        title={headerTitle}
        leftAction={headerLeftAction}
        rightAction={headerRightAction}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        className="auth-modal"
        paged
        animated={false}
        activePage={this.state.activePage}
      >
        <ModalPage pageId={MODAL_PAGES.SIGN_UP_OR_IN} depth={1} animated={false}>
          <div className="restricted-width-modal-content">
            <Form
              {...signUpFormSchema}
              formData={!this.props.withAddress ? this.state.signUpNoAddressData : this.state.signUpData}
              onChange={!this.props.withAddress ? this.handleSignUpWithoutAddressFormChange : this.handleSignUpFormChange}
              onSubmit={this.handleSignUpFormSubmit}
              autocomplete={false}
            >
              <Button
                dataGtmElement="Sign Up Button"
                block
                type="submit"
                theme="dark-blue"
                label="Sign Up"
                loadingLabel="Creating Account"
                loading={this.state.isLoadingRequest && this.state.isSigningUp}
              />
            </Form>
            {/* <Button block label="Sign Up" onClick={() => this.setActivePage(MODAL_PAGES.SIGN_UP)} /> */}
            <Divider label="or" />
            <p>Already with PostSnap?</p>
            <Button
              dataGtmElement="Choose Sign In Button"
              block
              theme="dark-blue"
              priority="secondary"
              label="Sign In"
              onClick={() => this.setActivePage(MODAL_PAGES.SIGN_IN)}
            />
            <p className="terms-text" style={{paddingBottom: '20px'}}>
              By signing up or signing in you are agreeing to the terms of our
              {' '}<a href="https://www.postsnap.com/privacy">Privacy Policy</a>
              {` and `}
              <a href="https://www.postsnap.com/terms-and-conditions">Terms of Use.</a>
              <br/>
            </p>
            {/* <Form
              {...SCHEMAS.SIGN_IN}
              formData={this.state.signInData}
              onChange={this.handleSignInFormChange}
              onSubmit={this.handleSignInFormSubmit}
            >
              <Button
                block
                type="submit"
                label="Sign In"
                loadingLabel="Signing In"
                priority={this.state.isLoadingRequest ? "primary" : "secondary"}
                loading={this.state.isLoadingRequest && this.state.isSigningIn}
              />
            </Form>
            <p className="terms-text">
              By signing up or signing in you are agreeing to the terms of our
              {' '}<a href="https://www.postsnap.com/privacy">Privacy Policy</a>
              {` and `}
              <a href="https://www.postsnap.com/terms-and-conditions">Terms of Use.</a>
              <br/>
            </p> */}
          </div>
        </ModalPage>
        {/* <ModalPage pageId={MODAL_PAGES.SIGN_IN} depth={2} animated={false}>
          <div className="restricted-width-modal-content">
            <Form
              {...SCHEMAS.SIGN_IN}
              formData={this.state.signInData}
              onChange={this.handleSignInFormChange}
              onSubmit={this.handleSignInFormSubmit}
            >
              <Button
                block
                type="submit"
                label="Sign In"
                loadingLabel="Signing In"
                priority={this.state.isLoadingRequest ? "primary" : "secondary"}
                loading={this.state.isLoadingRequest}
              />
            </Form>
            <br />
            <Button
              block
              label="Forgotten Password?"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.FORGOT_PASSWORD)}
            />
          </div>
        </ModalPage> */}
        <ModalPage pageId={MODAL_PAGES.SIGN_IN} depth={2} animated={false}>
          <div className="restricted-width-modal-content">
            <Form
              {...SCHEMAS.SIGN_IN}
              formData={this.state.signInData}
              onChange={this.handleSignInFormChange}
              onSubmit={this.handleSignInFormSubmit}
              autoComplete={true}
              disabled={this.state.signInDisabled}
            >
              <Button
                dataGtmElement="Sign In Button"
                block
                theme="dark-blue"
                type="submit"
                label="Sign In"
                loadingLabel="Signing In"
                priority={this.state.isLoadingRequest ? "primary" : "secondary"}
                loading={this.state.isLoadingRequest}
              />
            </Form>
            <br />
            <Button
              dataGtmElement="Forgot Password Button"
              block
              label="Forgotten Password?"
              priority="tertiary"
              theme="dark-blue"
              onClick={() => this.setActivePage(MODAL_PAGES.FORGOT_PASSWORD)}
            />
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.FORGOT_PASSWORD} depth={2} animated={false}>
          <div className="restricted-width-modal-content">
            <Form
              {...SCHEMAS.REQUEST_PASSWORD_RESET}
              formData={this.state.requestPasswordResetData}
              onChange={this.handleRequestPasswordResetFormChange}
              onSubmit={this.handleRequestPasswordResetFormSubmit}
            >
              <Button
                dataGtmElement="Reset Password Button"
                block
                type="submit"
                label="Reset Password"
                loadingLabel="Requesting Reset"
                loading={this.state.isLoadingRequest}
              />
            </Form>
          </div>
        </ModalPage>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  basketItems: basketSelectors.getItems(state),
  withAddress: uiSelectors.getAuthModalIsWithAddress(state)
});

export default connect(mapStateToProps)(AuthModal);
