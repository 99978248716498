import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class StripePaymentRequestButton extends Component {
  static propTypes = {
    paymentRequest: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    padded: PropTypes.bool,
    preventPropagation: PropTypes.bool,
  };

  static defaultProps = {
    padded: false,
    preventPropagation: false,
  };

  state = {
    canMakePayment: false,
  };

  componentDidMount() {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
    const elements = stripe.elements();
    const $node = ReactDOM.findDOMNode(this);
    const prButton = elements.create("paymentRequestButton", {
      paymentRequest: this.props.paymentRequest,
      style: {
        paymentRequestButton: {
          type: "default",
        },
      },
    });
    prButton.mount($node);
    prButton.on("click", e => {
      console.log("Stripe button tapped");
      if (this.props.preventPropagation) {
        //e.preventDefault();
        return this.props.onClick(e);
      } else {
        return this.props.onClick;
      }
    });
  }

  render() {
    return (
      <div
        data-gtm-element="Guest Checkout Button"
        style={
          this.props.disabled
            ? { opacity: 0.7, pointerEvents: "none" }
            : this.props.padded
            ? { marginBottom: "20px" }
            : null
        }
      />
    );
  }
}

export default StripePaymentRequestButton;
