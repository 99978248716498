// Size configuration for the tile preview in mm
import { BORDER_THICKNESS, FRAME_COLORS } from "../../../../store/ducks/ui-photo-tiles";

export const BORDER_WIDTHS = {
  [BORDER_THICKNESS.NO_BORDER]: 0,
  [BORDER_THICKNESS.THICK1]: 10,
  [BORDER_THICKNESS.THICK2]: 20,
  [BORDER_THICKNESS.THICK3]: 30,
  [BORDER_THICKNESS.THICK4]: 40,
};

export const DEFAULT_TILE_CONFIGURATION = {
  width: 200,
  height: 200,
  bleedArea: 3,
  depth: 8,
  frameWidth: 17,
  frameBleedArea: 5, // the amount of frame that covers the image
  borderWidth: BORDER_WIDTHS[BORDER_THICKNESS.THICK1],
  borderColor: "#FFFFFF", // MUST MATCH retro-colours.json
  frameColor: FRAME_COLORS.WHITE,
  withFrame: false,
  frameInnerDepth: 1,
  imageFilter: "none",
};

// Allows to override the default configuration with the parameters
export const getConfigurationFromParameters = ({
  borderColor,
  borderWidth,
  withFrame,
  frameColor,
  imageFilter,
} = {}) => {
  return {
    ...DEFAULT_TILE_CONFIGURATION,
    borderColor: borderColor ?? DEFAULT_TILE_CONFIGURATION.borderColor,
    borderWidth: borderWidth ?? DEFAULT_TILE_CONFIGURATION.borderWidth,
    withFrame: withFrame ?? DEFAULT_TILE_CONFIGURATION.withFrame,
    frameColor: frameColor ?? DEFAULT_TILE_CONFIGURATION.frameColor,
    imageFilter: imageFilter ?? DEFAULT_TILE_CONFIGURATION.imageFilter,
  };
};

export const getConfigurationFromTile = tile => {
  return {
    ...DEFAULT_TILE_CONFIGURATION,
    borderColor: tile.border.color,
    borderWidth: BORDER_WIDTHS[tile.border.thickness],
    imageFilter: tile.bnw ? "grayscale(100%)" : "none",
  };
};

export const scaleConfiguration = (configuration, newWidth) => {
  const ratio = newWidth / configuration.width;
  return {
    ...configuration,
    width: newWidth,
    height: configuration.height * ratio,
    bleedArea: configuration.bleedArea * ratio,
    depth: configuration.depth * ratio,
    frameWidth: configuration.frameWidth * ratio,
    frameBleedArea: configuration.frameBleedArea * ratio,
    borderWidth: configuration.borderWidth * ratio,
    frameInnerDepth: configuration.frameInnerDepth * ratio,
  };
};
