import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import "./CropCheckPopup.scss";

const CropCheckPopup = ({ isOpen, onClose, onProceed, onGoBack }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} padded className="crop-check-popup">
      <h3>Have you cropped all your photos?</h3>
      <img
        className="crop-img"
        alt="crop"
        src={`${process.env.PUBLIC_URL}/images/crop-check.png`}
      />
      <p>Please make sure you have cropped your photos accurately to ensure nothing gets cropped off</p>
      <div className="button">
        <Button
          label="No try again"
          priority="secondary"
          theme="muted"
          onClick={onGoBack}
          style={{maxWidth: '180px', width: '40vw'}}
        />
        <Button
          label="Yes I'm happy"
          theme="dark-blue"
          priority="primary"
          onClick={onProceed}
          style={{maxWidth: '180px', width: '40vw'}}
        />
      </div>
      
    </Modal>
  );
};

CropCheckPopup.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

CropCheckPopup.defaultProps = {};

export default CropCheckPopup;
