import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";

import "./RatingStars.scss";

function roundToHalf(num) {
  return Math.round(num * 2) / 2;
}

const MAX_RATING = 5;

const RatingStars = ({ rating, color }) => {
  const classes = classNames(
    "rating-stars",
    { "rating-stars--no-rating": rating === undefined },
    { "rating-stars--yellow": color && color === "yellow" }
  );

  let stars = new Array(MAX_RATING)
    .fill("")
    .map((_, index) => <Icon size="small" key={`"star-empty-${index}`} name="star-empty" />);

  if (rating !== undefined) {
    const rounded = roundToHalf(rating);
    const amountOfFullStars = Math.trunc(rounded);
    const amountOfHalfStars = Math.round(rounded % 1);
    const amountOfEmptyStars = MAX_RATING - amountOfFullStars - amountOfHalfStars;

    stars = [
      ...new Array(amountOfFullStars)
        .fill("")
        .map((_, index) => <Icon size="small" key={`star-${index}`} name="star" />),
      ...new Array(amountOfHalfStars)
        .fill("")
        .map((_, index) => <Icon size="small" key={`star-half-${index}`} name="star-half" />),
      ...new Array(amountOfEmptyStars)
        .fill("")
        .map((_, index) => <Icon size="small" key={`star-empty-${index}`} name="star-empty" />),
    ];
  }

  return <div className={classes}>{stars}</div>;
};

RatingStars.propTypes = {
  rating: PropTypes.number,
};

RatingStars.defaultProps = {};

export default RatingStars;
