import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import $ from "jquery";
import { BarLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../constants/colors";
import * as LAYER_TYPES from "../../constants/layer-types";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import {
  hasRealBorders,
  isCaptionPrintProduct
} from "../../data/products";
import './PhotoPrintPreview.scss';

//import { WarningOutlined } from '@ant-design/icons';

const PhotoPrintPreview = ({ item }) => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const lastItemId = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const [imageStyles, setImageStyles] = useState(null);
  const [bleedMaskStyles, setBleedMaskStyles] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  useEffect(() => {
    if(imageRef.current){
      imageRef.current.onload = () => {
        setIsLoadingImage(false);
      };
      imageRef.current.onerror = () => {
        setIsLoadingImage(false);
      };
    }
    
  }, []);

  useEffect(() => {
    const parentElement = containerRef.current.parentElement;
    const width = $(parentElement).width()*0.80;
    const height = $(parentElement).height()*0.80;

    const isRotated = item.get("isRotated");
    const isLowRes = item.get("isLowRes");

    let minHeightOrWidth = width >= height ? height : width;

    const productDimensions = item.get("productDimensions").toJS();

    const productHeight = productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom;
    const productWidth = productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right;
    //console.log(productDimensions);
    const isSquareProduct = productHeight === productWidth;

    let ratio = productDimensions.width / productDimensions.height;
    
    if (isRotated){
      //ratio = productDimensions.height / productDimensions.width;
      //translation = (containerDimensions.width - containerDimensions.height) / 2;
      //scale = containerDimensions.width / containerDimensions.height;
    }


    // const containerDimensions = isRotated
    //   ? {
    //       width: minHeightOrWidth / ratio,
    //       height: minHeightOrWidth,
    //     }
    //   : {
    //       width: minHeightOrWidth,
    //       height: minHeightOrWidth / ratio,
    //     };
    const isCollagePrint = item.get('isCollagePrint');

    const containerDimensions = {
      width: isCollagePrint ? '100%' : 'auto', //minHeightOrWidth 100%,
      height: minHeightOrWidth / ratio,
    };


    //let translation = (containerDimensions.height - minHeightOrWidth) / 2
    let scale = containerDimensions.height / minHeightOrWidth

    if (isRotated){
      //ratio = productDimensions.height / productDimensions.width;
      //translation = (containerDimensions.width - containerDimensions.height) / 2;
      scale = 1;
    }

    
    if (lastItemId.current !== item.get("id")) {
      setIsLoadingImage(true);
    }

    if (imageRef.current){

      const isCaptionPrint = isCaptionPrintProduct(item.get("productId"));
      let previewImage
      if (isCaptionPrint) {
        console.log("is caption print")
        const layer = item.get("layers").filter(layer => {
          return layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === 0
        })
        if(layer.size === 1){
          let pageLayer = layer.first()
          previewImage = pageLayer.get("renderLowRes")
          //previewImage = item.get("preview_s3_key")
          console.log("preview image is", previewImage)
        }
      } else{
        console.log("not caption print", item.get("preview_s3_key"))
        previewImage = item.get("preview_s3_key")
      }
      console.log("previewImage", previewImage)
      console.log("src", generateS3AssetUrlFromKey(previewImage))

      imageRef.current.src = generateS3AssetUrlFromKey(previewImage);
    }
    

    setDimensions(containerDimensions);

    
    const areBordered = Boolean(item.getIn(["product_options", "border"]));
    //const product = productsByProductId.get(item.get("productId"))
    const realBorders = hasRealBorders(item.get("productId"));
    const areRealBorders = areBordered && realBorders
    const hasFakeBorder = (areBordered && !areRealBorders) || isCollagePrint;

    let borderStyle = null;
    if(hasFakeBorder){
      //const borderWidthInPx = 5*3.779528; // 5mm * mmInPx;
      // 362 -> 294
      //let actualHeight = ((minHeightOrWidth-20)/100) * 95; // (containerHeight-(padding*2)/100) at 95% [max-height]
      let actualHeight = ((minHeightOrWidth-20)/100) * 95; // (containerHeight-(padding*2)/100) at 95% [max-height]
      let borderWidth = isCollagePrint ? 3 : 5;
      const borderWidthAsPercent = (borderWidth/productDimensions.width)*100;
      let borderWidthAsPx = (Math.ceil(actualHeight)/100)* borderWidthAsPercent;
      //console.log("fakeBorderWidth%", borderWidthAsPercent);
      //console.log("fakeBorderWidthPx", borderWidthAsPx);
      // Some sub-pixel border widths prevent image from showing?
      //borderWidthAsPx = Math.ceil(borderWidthAsPx)
      //alert(borderWidthAsPx)
      borderStyle = {
        border: `${borderWidthAsPx}px solid white`
      }
    }

    let transform = ''

    if (!isSquareProduct && isRotated) {
      transform = `scale(${scale}) rotate(-90deg) translateX(0px)`; //${translation}px
      setTimeout(() => {
        setImageStyles({
          ...borderStyle,
          transform: transform,
        });
      }, 900);
    } else {
      setTimeout(() => {
        setImageStyles(borderStyle);
      }, 900);
    }

    const bleedPercent = (productDimensions.bleed.left/(productDimensions.width+productDimensions.bleed.left+productDimensions.bleed.right))*100.0;
    //console.log(bleedPercent);
    //console.log(minHeightOrWidth);
    const bleedAsPixels = (minHeightOrWidth/100)* bleedPercent; // ~12px for 2mm

    let bleedStyles = {
      width: `calc(90% - ${bleedAsPixels*2}px`,
      height: `calc(90% - ${bleedAsPixels*2}px`,
      top: `calc(2.5% + ${bleedAsPixels}px)`,
      left: `calc(5% + ${bleedAsPixels}px)`,
    }

    // bleedStyles = {
    //   width: `100%`,
    //   height: `100%`,
    //   top: `0`,
    //   left: `0`,
    // }
    

    if (!areBordered){
      // bleedStyles = {
      //   width: `100%`,
      //   height: `calc(90% + 1px)`,
      //   top: `calc(2.5% - 0px)`,
      //   left: `calc(5% - 1px)`,
      //   border: `${bleedAsPixels}px solid #ffffff69`,
      //   boxShadow: 'none',
      //   transform: 'scale(1.0)' // TODO 1.03?
      // }

      // bleedStyles = {
      //   width: `100%`,
      //   height: `100%`,
      //   top: `0`,
      //   left: `0`,
      //   border: `${bleedAsPixels}px solid rgba(255, 255, 255, 0.71)`,
      //   boxShadow: 'none',
      //   transform: 'scale(1.0)' // TODO 1.03?
      // }

      // bleedStyles = {
      //   width: `100%`,
      //   height: `100%`,
      //   top: `0`,
      //   left: `0`,
      //   border: `${bleedAsPixels}px solid white`,
      //   boxShadow: 'none',
      //   transform: 'scale(1.0)' // TODO 1.03?
      // }
      
      bleedStyles = {
        width: `calc(100%)`,
        height: `calc(100% )`,
        top: `0`,
        left: `0`,
        border: `${bleedAsPixels}px solid white`,
        transform: 'scale(1.01)', // TODO 1.03?
        boxShadow: 'inset 0 0px 2px 0px rgba(0, 0, 0, 0.3)'
      }

    }

    if (areBordered && hasRealBorders){
      bleedStyles = {
        width: `calc(100% - ${bleedAsPixels*2}px)`,
        height: `calc(100% - ${bleedAsPixels*2}px)`,
        top: `${bleedAsPixels}px`,
        left: `${bleedAsPixels}px`,
        border: `${bleedAsPixels}px solid white`,
        transform: transform // TODO 1.03?
      }
    }

    if (!isSquareProduct && isRotated) {
      bleedStyles.transform = `scale(1.01) rotate(-90deg) translateX(0px)`; //${translation}
    }

    setBleedMaskStyles(bleedStyles);

    lastItemId.current = item.get("id");
  }, [item]);

  const isCollagePrint = item.get('isCollagePrint');
  const areBordered = Boolean(item.getIn(["product_options", "border"]));
  const realBorders = hasRealBorders(item.get("productId"));
  const areRealBorders = areBordered && realBorders
  const isLowRes = item.get('isLowRes');
  const shouldShowBleedBask = realBorders;

  const hasFakeBorder = (Boolean(item.getIn(["product_options", "border"])) && !areRealBorders) || isCollagePrint;

  //const potentialHeadIsssue = Boolean(item.get("potentialFaceChop"));

  const isCaptionPrint = isCaptionPrintProduct(item.get("productId"));
  let previewImage
  if (isCaptionPrint) {
    console.log("is caption print")
    const layer = item.get("layers").filter(layer => {
      return layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === 0
    })
    if(layer.size === 1){
      let pageLayer = layer.first()
      previewImage = pageLayer.get("renderLowRes")
      console.log("preview image is", previewImage)
    }
  } else{
    console.log("not caption print", item.get("preview_s3_key"))
    previewImage = item.get("preview_s3_key")
  }
  console.log("previewImage", previewImage)
  console.log("src", generateS3AssetUrlFromKey(previewImage))
  return (
    <div
      className={classNames("photo-print-preview", {
        "photo-print-preview--loading": isLoadingImage,
        "photo-print-preview--fake-border": hasFakeBorder,
        "photo-print-preview--with-bleed-mask": shouldShowBleedBask,
      })}
      ref={containerRef}
      style={{ ...(dimensions || {}) }}
    >
      {isLoadingImage && (
        <div className="photo-print-preview__loading-indicator">
          {/* <p style={{textAlign: 'center'}}>Loading preview . . .</p> */}
          <div style={{marginTop: '10px', marginLeft: '10px'}}>
            <BarLoader color={PRIMARY_COLOR} />
          </div>        
        </div>
      )}
      {/* {potentialHeadIsssue && (
        <div className="photo-print-preview__head-check">
          <p><WarningOutlined />&nbsp;Check Heads</p>
        </div>
      )} */}
      {previewImage && (
        <img
          ref={imageRef}
          src={generateS3AssetUrlFromKey(previewImage)}
          style={{ ...(imageStyles || {}) }}
          alt=""
        />
      )}
      {shouldShowBleedBask && (
        <div className="photo-print-preview__bleed-mask" style={bleedMaskStyles}></div>
      )}
    </div>
  );
};

export default PhotoPrintPreview;
