import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HtmlRendererRect from "./HtmlRendererRect";

class HtmlRendererMask extends React.Component {
  static propTypes = {
    style: PropTypes.oneOf(["corners-and-dotted-lines", "bleed-bands", "transparent-bleed-masks"]),
    canvasDimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    addedPhoto: PropTypes.bool,
    productDimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      bleed: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }),
    }).isRequired,
    isRotated: PropTypes.bool,
    isCanvas: PropTypes.bool,
  };

  static defaultProps = {
    isRotated: false,
    isCanvas: false,
  };


  render() {
    const CANVAS_WRAP_DEPTH = 22.86+3.81; // 0.9 inches + 0.15 as mm
    let maskElements = [];
    const productDimensions = this.props.productDimensions;
    let bleed = productDimensions.bleed;

    if (this.props.isCanvas){
      bleed = {
        top: CANVAS_WRAP_DEPTH,
        bottom: CANVAS_WRAP_DEPTH,
        right: CANVAS_WRAP_DEPTH,
        left: CANVAS_WRAP_DEPTH,
      }
    }

    const canvasHeight = this.props.canvasDimensions.height || 0;
    const canvasWidth  = this.props.canvasDimensions.width || 0;
    
    let height = (productDimensions.height + bleed.top + bleed.bottom);
    let width = (productDimensions.width + bleed.left + bleed.right);

    let relativeBleed = {
      top: bleed.top * (100.0/height),
      bottom: bleed.bottom * (100.0/height),
      right: bleed.right * (100.0/width),
      left: bleed.left * (100.0/width),
    }
    
    if (this.props.isCanvas){
      relativeBleed = {
        top: bleed.top * (100/(productDimensions.height)),
        bottom: bleed.bottom * (100/(productDimensions.height)),
        right: bleed.right * (100/(productDimensions.width)),
        left: bleed.left * (100/(productDimensions.width)),
      }
    }

    if(this.props.isRotated){
      relativeBleed = {
        top: bleed.top * (100.0/width),
        bottom: bleed.bottom * (100.0/width),
        right: bleed.right * (100.0/height),
        left: bleed.left * (100.0/height),
      }
    }

    let transparentBleedMasks = maskElements = [
      <div
        key="top"
        className="html-renderer-transparent__bleed__mask__band html-renderer-mask__band--horizontal"
        style={{
          top: -2,
          //width: `calc(100% - ${((bleed.top / productDimensions.height) * canvasHeight)*2}px)`,
          height:
            (bleed.top / productDimensions.height) * canvasHeight + 2,
          //left: (bleed.top / productDimensions.height) * canvasHeight,
        }}
      />,
      <div
        key="bottom"
        className="html-renderer-transparent__bleed__mask__band html-renderer-mask__band--horizontal"
        style={{
          bottom: -3,
          //width: `calc(100% - ${((bleed.top / productDimensions.height) * canvasHeight)*2}px)`,
          height:
            (bleed.bottom / productDimensions.height) * canvasHeight + 3,
          //left: (bleed.bottom / productDimensions.height) * canvasHeight,
        }}
      />,
      <div
        key="left"
        className="html-renderer-transparent__bleed__mask__band html-renderer-mask__band--vertical"
        style={{
          left: -2,
          width: (bleed.left / productDimensions.width) * canvasWidth + 2,
        }}
      />,
      <div
        key="right"
        className="html-renderer-transparent__bleed__mask__band html-renderer-mask__band--vertical"
        style={{
          right: -2,
          width:
            (bleed.right / productDimensions.width) * canvasWidth + 2,
        }}
      />,
    ];
    //TODO: top/left/right/bottom should also use the offsets to ensure the mask doesn't get overlaid on the flaps
    let leftOffset = (CANVAS_WRAP_DEPTH / productDimensions.width) * (canvasWidth * this.props.rect.width)
    let rightOffset = (CANVAS_WRAP_DEPTH / productDimensions.width) * (canvasWidth * this.props.rect.width)
    let topOffset = (CANVAS_WRAP_DEPTH / productDimensions.height) * (canvasHeight * this.props.rect.height)
    let bottomOffset = (CANVAS_WRAP_DEPTH / productDimensions.height) * (canvasHeight * this.props.rect.height)
    switch (this.props.style) {
      case "corners-and-dotted-lines": {
        maskElements = [
          <div
            key="top"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--horizontal", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              top: topOffset,
            }}
          />,
          <div
            key="bottom"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--horizontal", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              bottom:
                bottomOffset,
            }}
          />,
          <div
            key="left"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--vertical", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              left: leftOffset,
            }}
          />,
          <div
            key="right"
            className={classNames("html-renderer-mask__line html-renderer-mask__line--vertical", {
              placeholder: !this.props.addedPhoto,
            })}
            style={{
              right: rightOffset,
            }}
          />,
          <div
            key="tl"
            className="html-renderer-mask__corner"
            style={{
              height: topOffset,
              width: leftOffset,
              top: 0,
              left: 0,
            }}
          />,
          <div
            key="tr"
            className="html-renderer-mask__corner"
            style={{
              height: topOffset,
              width: rightOffset,
              top: 0,
              right: 0,
            }}
          />,
          <div
            key="bl"
            className="html-renderer-mask__corner"
            style={{
              height: bottomOffset,
              width: leftOffset,
              bottom: 0,
              left: 0,
            }}
          />,
          <div
            key="br"
            className="html-renderer-mask__corner"
            style={{
              height: bottomOffset,
              width: rightOffset,
              bottom: 0,
              right: 0,
            }}
          />,
        ];
        break;
      }
      case "bleed-bands": {
        maskElements = [
          <div
            key="top"
            className="html-renderer-mask__band html-renderer-mask__band--horizontal"
            style={{
              top: -2,
              height:
                `calc(${relativeBleed.top}% + 2px)`,
            }}
          />,
          <div
            key="bottom"
            className="html-renderer-mask__band html-renderer-mask__band--horizontal"
            style={{
              bottom: -3,
              height: `calc(${relativeBleed.bottom}% + 3px)`,
            }}
          />,
          <div
            key="left"
            className="html-renderer-mask__band html-renderer-mask__band--vertical"
            style={{
              left: -2,
              width: `calc(${relativeBleed.left}% + 2px)`,
            }}
          />,
          <div
            key="right"
            className="html-renderer-mask__band html-renderer-mask__band--vertical"
            style={{
              right: -2,
              width: `calc(${relativeBleed.right}% + 2px)`,
            }}
          />,
        ];
        if(true){
          let bleedMaskBorders = [
            <div
              key="top-bleed-border"
              className="html-renderer-mask__band html-renderer-mask__band--border html-renderer-mask__band--horizontal"
              style={{
                top: -2,
                width: `calc(100% - ${relativeBleed.left*2}% + 4px)`,
                height: `calc(${relativeBleed.top}% + 2px)`,
                left: `calc(${relativeBleed.left}% - 2px)`,
                borderBottomWidth: '2px',
              }}
            />,
            <div
              key="bottom-bleed-border"
              className="html-renderer-mask__band html-renderer-mask__band--border html-renderer-mask__band--horizontal"
              style={{
                bottom: -2,
                width: `calc(100% - ${relativeBleed.left*2}% + 4px)`,
                height: `calc(${relativeBleed.top}% + 2px)`,
                left: `calc(${relativeBleed.left}% - 2px)`,
                borderTopWidth: '2px',
              }}
            />,
            <div
              key="left-bleed-border"
              className="html-renderer-mask__band html-renderer-mask__band--border html-renderer-mask__band--vertical"
              style={{
                left: -2,
                width: `calc(${relativeBleed.left}% + 2px)`,
                height:`calc(100% - ${relativeBleed.top*2}%)`,
                borderRightWidth: '2px',
                top:`${relativeBleed.top}%`,
              }}
            />,
            <div
              key="right-bleed-border"
              className="html-renderer-mask__band html-renderer-mask__band--border html-renderer-mask__band--vertical"
              style={{
                right: -2,
                width: `calc(${relativeBleed.left}% + 2px)`,
                height:`calc(100% - ${relativeBleed.top*2}%)`,
                borderLeftWidth: '2px',
                top:`${relativeBleed.top}%`,
              }}
            />,
          ]
          maskElements.push(bleedMaskBorders);
        }
        break;
      }
      case "transparent-bleed-masks": {
        let faintShadowOverlay = [
          <div
            key="overlay"
            className="html-renderer-transparent__bleed__mask__shadow__faint"
            style={{
              top: `${relativeBleed.top}%`,
              left:  `${relativeBleed.left}%`,
              width: `calc(100% - ${relativeBleed.left*2}%)`,
              height: `calc(100% - ${relativeBleed.top*2}%)`,
            }}
          />
        ];
        maskElements = transparentBleedMasks;
        maskElements.push(faintShadowOverlay);
        break;
      }
      case "transparent-bleed-masks-with-shadow": {
        let shadowOverlay = [
          <div
            key="overlay"
            className="html-renderer-transparent__bleed__mask__shadow"
            style={{
              top: `${relativeBleed.top}%`,
              left:  `${relativeBleed.left}%`,
              width: `calc(100% - ${relativeBleed.left*2}%)`,
              height: `calc(100% - ${relativeBleed.top*2}%)`,
            }}
          />
        ];
        maskElements = transparentBleedMasks;
        maskElements.push(shadowOverlay);
        break;
      }
      // no default
    }

    return (
      <HtmlRendererRect className="html-renderer-mask" rect={this.props.rect}>
        {maskElements}
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererMask;
