import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  BORDER_THICKNESS,
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../store/ducks/ui-photo-tiles";
import { BORDER_WIDTHS, DEFAULT_TILE_CONFIGURATION } from "./TilePreview/tilePreviewCalculator";
import retroColours from "../../ThemeList/retro-colours.json";
import { getCroppedImageAsDataUrl, inMemoryImageCache } from "../photoTilesUtils";

const divSize = 50;
const borderSize = 2;
const configurationWidth = DEFAULT_TILE_CONFIGURATION.width;

const borderMenuItems = Object.entries(BORDER_THICKNESS).map(([key, value]) => {
  const padding = (BORDER_WIDTHS[value] / configurationWidth) * divSize;
  const imgSize = divSize - padding * 2 - 2 * borderSize;

  return { padding, imgSize, value, key };
});

function PhotoTilesBorderEditor({ setTiles, tiles, tileBeingEditedIndex }) {
  const tileEdited = tiles[tileBeingEditedIndex];
  const activeThickness = tileEdited?.border?.thickness;
  const activeColour = tileEdited?.border?.color;

  const handleThicknessChange = thickness => {
    onUpdateBorder({ thickness, color: tileEdited?.border?.color });
  };

  const onUpdateBorder = border => {
    const editedTile = tiles[tileBeingEditedIndex];
    editedTile.border = border;
    const updatedList = updateTile(tiles, tileBeingEditedIndex, { ...editedTile });
    setTiles(updatedList);
  };

  // const menuImage = tileEdited?.crop?.croppedUrl ?? tileEdited?.image?.lowResUrl;

  const filter = tileEdited?.bnw ? "grayscale(100%)" : "none";

  const handleColorChange = color => {
    onUpdateBorder({ thickness: tileEdited?.border?.thickness, color });
  };

  const [menuImage, setMenuImage] = useState(null);
  useEffect(() => {
    const cached = inMemoryImageCache.getItem(tileEdited?.image?.lowResUrl, tileEdited?.crop);
    if (cached) {
      setMenuImage(cached);
      return;
    }

    if (!tileEdited?.crop) {
      setMenuImage(tileEdited?.image?.lowResUrl);
      inMemoryImageCache.addItem(
        tileEdited?.image?.lowResUrl,
        tileEdited?.crop,
        tileEdited?.image?.lowResUrl
      );
      return;
    }

    const updateMenuImage = async () => {
      const image = await getCroppedImageAsDataUrl(
        tileEdited?.image?.lowResUrl,
        tileEdited?.crop,
        tileEdited?.image?.originalImageInfo.width,
        tileEdited?.image?.originalImageInfo.height
      );
      setMenuImage(image);
      inMemoryImageCache.addItem(tileEdited?.image?.lowResUrl, tileEdited?.crop, image);
    };

    updateMenuImage();
  }, [tileEdited?.crop, tileEdited?.image]);

  const hasMenuImage = Boolean(menuImage);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: 10,
          paddingBottom: 5,
          marginBottom: 5,
          paddingRight: 10,
          width: "100%",
          overflowX: "scroll",
        }}
      >
        {retroColours.map((colour, index) => {
          return (
            <div
              onClick={() => {
                handleColorChange(colour.hex);
              }}
              className={`swatch-container ${
                activeColour.toLowerCase() === colour.hex.toLowerCase() ? "active" : ""
              }`}
              key={colour.hex}
            >
              <div
                className={`swatch ${colour.color === "White" ? "bordered" : ""}`}
                style={{
                  backgroundColor: colour.hex,
                  color: colour?.textColor,
                  width: "38px",
                  height: "38px",
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          paddingTop: 5,
        }}
      >
        {borderMenuItems.map(({ padding, imgSize, value, key }) => {
          const border =
            activeThickness === value ? `${borderSize}px solid red` : `${borderSize}px solid gray`;
          return (
            <div
              key={value}
              style={{
                width: divSize,
                height: divSize,
                padding,
                backgroundColor: "white",
                border,
              }}
              onClick={() => handleThicknessChange(value)}
            >
              {!hasMenuImage && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/add-photo.svg`}
                  style={{
                    width: imgSize,
                    height: imgSize,
                    border: padding === 0 ? "none" : "px solid lightgray",
                  }}
                />
              )}
              {hasMenuImage && (
                <img
                  src={menuImage}
                  style={{
                    width: imgSize,
                    height: imgSize,
                    filter,
                    objectFit: "cover",
                    // objectPosition: "0% 0%",
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
});

const mapDispatchToProps = dispatch => ({
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesBorderEditor);
