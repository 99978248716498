import $ from "jquery";
// eslint-disable-next-line
/* global $ */

import uploadcare from "uploadcare-widget";
import { message } from "antd";
// import React from "react";
// import ReactDOMServer from "react-dom/server";
//import { Icon } from "antd";

const LOW_RES_WIDTH = 1200;

// https://github.com/uploadcare/uploadcare-widget/blob/master/app/assets/javascripts/uploadcare/locale/en.js.coffee
window.UPLOADCARE_LOCALE = "en";
window.UPLOADCARE_LIVE = false;
window.UPLOADCARE_LOCALE_TRANSLATIONS = {
  file: {
    one: "%1 photo",
    other: "%1 photos",
  },
  errors: { fileType: "GIF files are not allowed." },
  dialog: {
    showFiles: "Show Photos",
    done: "Use these photos",
    tabs: {
      names: {
        file: "From your device",
      },
      file: {
        button: "Add Photos From Your Device",
        cloudsTip: "Tap here to choose photos from <br>Facebook, Instagram or Google Drive",
      },
      preview: {
        multiple: {
          title: "You’ve selected %files%, tap Done below to personalise",
          question: "Do you want to add all of these photos?",
          tooManyFiles: "You’ve chosen too many photos. %max% is maximum.",
          tooFewFiles: "You’ve chosen %files%. At least %min% required.",
        },
        image: {
          title: "Add this photo?",
        },
        done: "Add Photo",
        error: {
          fileType: {
            title: "Error.",
            text: "GIF files are not allowed.",
            back: "Back",
          },
          default: {
            title: "Oops!",
            text: "Something went wrong during the upload - only jpeg/pngs allowed",
            back: "Please try again",
          },
        },
      },
    },
  },
  footer: {
    text: "PostSnap",
    link: "Photo uploads",
  },
};

window.UPLOADCARE_LOCALE = "en";
window.UPLOADCARE_LOCALE_TRANSLATIONS = {
  uploading: "Uploading... Please wait.",
  loadingInfo: "Loading info...",
  draghere: "Choose Photos",
  file: {
    one: "%1 photo",
    other: "%1 photos",
  },
  buttons: {
    cancel: "Cancel",
    remove: "Remove",
    choose: {
      files: {
        one: "Choose a file",
        other: "Choose files",
      },
      images: {
        one: "Choose an image",
        other: "Choose images",
      },
    },
  },
  dialog: {
    tabs: {
      file: {
        button: "Choose Photos",
        drag: "",
        or: "",
      },
      preview: {
        error: {
          fileType: {
            title: "Error",
            text: "Unfortunately we don't support GIF files",
            back: "Back",
          },
          default: {
            title: "Oops!",
            text: "Something went wrong during the upload - only jpeg/pngs allowed",
            back: "Please try again",
          },
        },
        unknownName: "unknown",
        change: "Cancel",
        back: "Back",
        done: "Add Photo",
        unknown: {
          title: "Uploading... Please wait for a preview.",
          done: "Skip preview and accept",
        },
        regular: {
          title: "Add this file?",
          line1: "You are about to add the file above.",
          line2: "Please confirm.",
        },
        image: {
          title: "Add this photo?",
          change: "Cancel",
        },
        crop: {
          title: "Crop and add this image",
          done: "Done",
          free: "free",
        },
        video: {
          title: "Add this video?",
          change: "Cancel",
        },
        multiple: {
          title: "You’ve chosen %files%.",
          question:
            "Uploading your photos ... Please wait until all photos have finished uploading",
          tooManyFiles: "You’ve chosen too many files. %max% is maximum.",
          tooFewFiles: "You’ve chosen %files%. At least %min% required.",
          clear: "Remove all",
          done: "Next",
          file: {
            preview: "Preview %file%",
            remove: "Remove %file%",
          },
        },
      },
    },
  },
};

// const enableNoSleep = () => {
//   (new NoSleep()).enable();
//   document.addEventListener('touchstart', enableNoSleep, false);
// };
// document.addEventListener('touchstart', enableNoSleep, false);

var style = `
  .source-facebook .big-button {border-radius: 3px}
  .list-icons-item._selected, .list-tiles-item._selected {
    border: 3px solid rgb(104, 104, 104);
    margin: 1px;
    border-radius: 0px;
    opacity:1;
  }
  .list-icons-item._selected:before {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' fill='rgb(255, 90, 95)' viewBox='64 64 896 896' focusable='false' class='' data-icon='check-circle' width='1em' height='1em' aria-hidden='true'><path d='M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z'></path><path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z'></path></svg>");
    display:block;
    position: absolute;
    bottom: 31%;
    right: 41%;
    transform: scale(1.8);
  }
  .list-icons-item { margin: 4px;}
  a {
      text-decoration: none;
      color: rgb(255, 90, 95);
  }
  .source-gdrive > .welcome > h2 {
    visibility: hidden;
  }
  .source-gdrive > .welcome > h2:after{
    content: 'Tapping below will prompt you to login to Google Drive so we can access your photos 👍';
    visibility: visible;
    display: block;
  }
  .source-gdrive .big-button, .source-gphotos .big-button {
    background-color: #00b09f;
    color: #fff;
  }
  .side-bar{
    padding-top: 50px !important;
  }
`;
uploadcare.tabsCss.addStyle(style);

// function disableUploadcareNextButton() {
//   console.log('Disabling next button')
//   $('.uploadcare--preview__done').attr('disabled', true);
// }
//
// function enableUploadcareNextButton() {
//   console.log('Enabling next button')
//   $('.uploadcare--preview__done').attr('disabled', false);
// }

uploadcare.registerTab("favorites", function(container, button, dialogApi, settings) {
  container.append(
    '<div id="psFavoritesTab"><p class="heading">Select recently used photos...</p></div>'
  );
  let psTab = $("#psFavoritesTab");

  const usedUUIDs = JSON.parse(localStorage.getItem("usedUUIDs")) || [];

  //console.log("usedUUIDs", usedUUIDs)

  $.each(settings.favoriteFiles, function(i, uuid) {
    const $imageContainer = $("<div>", {
      class: `psFavorite-image-container ${usedUUIDs.includes(uuid) ? "used" : ""}`,
    });
    let added = false;
    $imageContainer.append(
      $("<img>", {
        class: `psFavorite-files-image ${usedUUIDs.includes(uuid) ? "used" : ""}`,
        src: settings.cdnBase + "/" + uuid + "/-/scale_crop/280x280/center/-/autorotate/yes/",
      }).on("click", function(e) {
        if ($(this).hasClass("added")) {
          console.log("Removing file", uuid);

          dialogApi.fileColl.get();
          const existingFiles = dialogApi.fileColl.__items;
          //console.log("Files count", existingFiles);
          //let fileToRemove = uploadcare.fileFrom('uploaded', uuid, settings);
          const removingFileIndex = $(".added").index($(this));
          const newFiles = existingFiles.filter((f, index) => {
            return removingFileIndex !== index;
          });

          dialogApi.fileColl.clear();
          dialogApi.addFiles(newFiles);

          //let nowFilesSize = dialogApi.fileColl.length();
          //console.log("Files count", nowFilesSize);
          $(this).removeClass("added");
          added = false;
        } else {
          dialogApi.addFiles([uploadcare.fileFrom("uploaded", uuid, settings)]);
          $(this).addClass("added");
          added = true;
        }
        const selected = $(".psFavorite-files-image.added").length;
        if (selected && selected > 0) {
          const btnText = selected === 1 ? "Done (1 Photo)" : `Done (${selected} Photos)`;
          $(".uploadcare--panel__done").text(btnText);
        } else {
          $(".uploadcare--panel__done").text("Done");
        }

        if (added) {
          $imageContainer.append("<div class='ribbon green'>Selected</div>");
        } else {
          const $ribbon = $imageContainer.find(".ribbon.green");
          if ($ribbon.length) {
            $ribbon.remove();
          }
        }
      })
    );

    if (usedUUIDs.includes(uuid)) {
      $imageContainer.append("<div class='ribbon'>Added</div>");
    }
    psTab.append($imageContainer);
  });
});

export function pickImages({
  amount = 1,
  source = "file",
  maxResolution = "1800x1800 100%",
  onTotalProgressUpdate = () => {},
  autoRotate = true,
  onStartUpload = () => {},
}) {
  console.log("Max Resolution", maxResolution);
  return new Promise((resolve, reject) => {
    // If we're dealing with local file uploads only, trigger the file input instead of showing the first empty screen

    // if (source === "file") {
    //   setTimeout(function() {
    //     $(".uploadcare--button_primary").trigger("click");
    //   }, 600);
    // }

    // Until upload care support localisation of the dialog file button, change the button text with JS depending on amount
    if (source === "file") {
      if (amount > 1) {
        setTimeout(function() {
          let btn = document.querySelector(".uploadcare--tab__action-button");
          let dropZone = document.querySelector(".uploadcare--dragging__show");
          if (btn) {
            btn.innerHTML = `Choose Photos`;
          }

          //dropZone.innerHTML = `Upload up to ${amount} photos`;
          // if (dropZone) {
          //   if (amount === 100) {
          //     dropZone.innerHTML = `For best results please upload up to ${amount} photos`;
          //   } else if (amount === 20){
          //     dropZone.innerHTML = `Upload photos`;
          //   } else {
          //     dropZone.innerHTML = `Upload up to ${amount} photos`;
          //   }
          // }

          dropZone.innerHTML = "";

          //let isMac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)?true:false;
          const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
          //let isWindows = navigator.appVersion.indexOf("Win")!=-1;
          const isWindows = navigator.platform.indexOf("Win") > -1;

          const isiPad =
            navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

          if ((isMac && !isiPad) || isWindows) {
            let key = isMac ? "CMD ⌘" : "CTRL";
            let multiPhotoTip = `
            <div class='uploadcare--desktop-multiphoto-tip animated fadeIn'>
              <p><span style="color:#49bda1;font-weight:600;margin-left:2px;">TIP</span><br/>
              To upload multiple photos, hold the <span class="cmd-key">${key}</span> key and click on each photo</p>
            </div>`;
            btn.insertAdjacentHTML("afterend", multiPhotoTip);
          }
        });
      } else {
        setTimeout(function() {
          let btn = document.querySelector(".uploadcare--tab__action-button");
          btn.innerHTML = "Upload Photo";
        });
      }
    }

    if (amount === 1) {
      setTimeout(() => {
        $(".uploadcare--dialog").addClass("uploadcare--single-file");
      }, 0);
    }

    console.log("Required image resolution", maxResolution);

    const gifLimit = fileInfo => {
      if (fileInfo && fileInfo.mimeType) {
        if (fileInfo.mimeType.indexOf("gif") > -1) {
          throw new Error("fileType");
        }
      }
    };

    const autoRotateText = autoRotate ? "yes" : "yes";
    console.log("Will autorotate", autoRotateText);
    const dialog = uploadcare
      .openDialog(null, {
        validators: [gifLimit],
        publicKey: process.env.REACT_APP_UPLOADCARE_API_KEY,
        imageShrink: maxResolution,
        multiple: amount > 1,
        multipleMax: amount,
        imagesOnly: true,
        previewStep: amount === 1,
        tabs: source,
        favoriteFiles: JSON.parse(localStorage.getItem("recentlyUploadedFiles")) || [],
        debugUploads: true,
      })
      .fail(function(errorType, fileInfo, error) {
        console.log("errorType", errorType);
        console.log("fileInfo", fileInfo);
        console.log("error", error);
        //reject();
      })
      .done(result => {
        if (amount === 1) {
          result.done(info => {
            //TODO:Check this doesn't break things... perhaps no autorotation is
            // only suitable for prints...
            console.log("orientation", info.originalImageInfo.orientation);
            info.highResUrl = info.cdnUrl + `/-/autorotate/${autoRotateText}/`;
            info.lowResUrl = `${info.highResUrl}/-/resize/${LOW_RES_WIDTH}x/`;
            resolve(info);
          });
        } else {
          Promise.all(
            result.files().map(filePromise => {
              return new Promise((resolve, reject) =>
                filePromise.done(info => {
                  info.highResUrl = info.cdnUrl + `/-/autorotate/${autoRotateText}/`;
                  info.lowResUrl = `${info.highResUrl}/-/resize/${LOW_RES_WIDTH}x/`;
                  resolve(info);
                })
              );
            })
          ).then(fileUrls => {
            return resolve(fileUrls);
          });
        }
      });

    let total = 0;
    let total_done = 0;
    const progressForAllFiles = [];

    dialog.fileColl.onAdd.add(file => {
      if (total === 0) {
        onStartUpload();
      }

      if (amount === 1) {
        $(".uploadcare--preview__title").hide();
      }

      function disablePreviewButton() {
        $(".uploadcare--preview__done").attr("disabled", true);
      }

      setTimeout(disablePreviewButton, 0);
      let progressIndex = total;
      progressForAllFiles[progressIndex] = 0;

      if (amount === 1) {
        if (source !== "favorites") {
          message.open({
            content: "Please wait while we upload your photo...",
            duration: 2,
            type: "loading",
          });
        }
      } else {
        message.open({
          content: "Please wait while we upload your photos...",
          duration: 2,
          type: "loading",
        });
      }

      file.progress(function(uploadInfo) {
        $(".uploadcare--preview__done").attr("disabled", true);

        if (amount === 1) {
          if ($("#upload-wait").length === 0 && source !== "favorites") {
            $(
              "<p id='upload-wait' style='margin:0 auto;'>Please wait while we upload your photo</p><span class='meter'><span></span></span>"
            ).insertBefore(".uploadcare--preview__done");
          }
          let progress = $(".meter > span");
          progress.width((uploadInfo.progress * 100).toFixed(2) + "%");
        }

        progressForAllFiles[progressIndex] = uploadInfo.progress;
        const totalProgress = progressForAllFiles.reduce(
          (total, fileProgress) => total + fileProgress,
          0
        );

        if (source !== "favorites") {
          // Don't show progress for recently uploaded.
          onTotalProgressUpdate(totalProgress / progressForAllFiles.length);
        }
      });

      file.done(theFile => {
        if (source !== "favorites") {
          let uuids = JSON.parse(localStorage.getItem("recentlyUploadedFiles")) || [];
          // If less than x add to front
          if (uuids.length < 50) {
            uuids.unshift(theFile.uuid);
          } else {
            // otherwise add to front but remove
            uuids.pop();
            uuids.unshift(theFile.uuid);
          }
          localStorage.setItem("recentlyUploadedFiles", JSON.stringify(uuids));
        }

        // if (source === "favorites") {
        //   const selected = $('.psFavorite-files-image.added').length;
        //   if(selected && selected > 0){
        //     const btnText = selected === 1 ? "Done (1 Photo)" : `Done (${selected} Photos)`;
        //     $('.uploadcare--panel__done').text(btnText);
        //   }

        // }

        total_done++;
        if (amount === 1) {
          //console.log('File done');
          $("#upload-wait").remove();
          $(".meter").hide();
          $(".uploadcare--preview__done").attr("disabled", false);
          $(".uploadcare--preview__done").css("opacity", 1);
          $(".uploadcare--preview__done").css("pointer-events", "all");
        }
        if (source === "file") {
          if (total_done === total && amount > 1) {
            $(".uploadcare--dialog").addClass("animated fadeOutDown");
            setTimeout(function() {
              dialog.resolve(); // close dialog after all files uploaded
            }, 500);
          }
        }
      });
      total++;
    });
  });
}
