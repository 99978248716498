import React from "react";
import { Modal as AntModal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const FontSizeSelectionModal = ({ isOpen, onClose, fontSize, onChangeFont, selectedFontSize }) => {
  return (
    <AntModal visible={isOpen} onCancel={onClose} footer={[]}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "25px",
        }}
      >
        {fontSize.map(({ size, name }, index) => (
          <div onClick={() => onChangeFont(size)}>
            <text style={{ fontSize: 16+((index+1)*4) }}>{name} <span style={{backgroundColor: 'yellow'}}>({size})</span></text>
            {selectedFontSize === size ? (
              <CheckOutlined style={{ marginLeft: 20 }} />
            ) : (
              <CloseOutlined style={{ marginLeft: 20, color: "transparent" }} />
            )}
          </div>
        ))}
      </div>
    </AntModal>
  );
};
export default FontSizeSelectionModal;
