import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import gtmEvent from "../../utils/gtm";
import { countriesByCurrency } from "../../constants/geo";

import { routeCreators } from "../../lib/routes";
import filterAddressFieldsFromObject from "../../lib/filter-address-fields-from-object";
import { actions as authActions, selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import {
  actions as addressBookActions,
  selectors as addressBookSelectors,
} from "../../store/ducks/address-book";

import Account from "./Account";
import Modal from "../../components/Modal/Modal";
import ModalPage from "../../components/Modal/ModalPage";
import AddressForm from "../../components/Form/AddressForm";
import Button from "../../components/Button/Button";
import Form from "../../components/Form/Form";
import AddressBook from "../../components/AddressBook/AddressBook";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

import Header from "../../components/Header/Header";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import ShopButtonContainer from "../../components/ShopButton/ShopButtonContainer";

import Grid from "../../components/Grid/Grid";

const FORM_SCHEMAS = {
  editAccount: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["first_name", "current_password"],
      properties: {
        first_name: { type: "string", title: "First Name" },
        last_name: { type: "string", title: "Last Name" },
        email: { type: "string", title: "Email" },
        mobile: { type: "string", title: "Mobile" },
        current_password: {
          type: "string",
          title: "Current Password",
          minLength: 7,
        },
        marketing_opt_in: {
          type: "boolean",
          title: "Email preference",
        },
      },
    },
    uiSchema: {
      email: {
        "ui:widget": "email",
      },
      current_password: {
        "ui:widget": "password",
      },
      marketing_opt_in: {
        "ui:widget": props => {
          return (
            <Grid.Row>
              <Grid.Column size="0 auto">
                <div className="pretty p-svg" style={{ marginRight: 0 }}>
                  <input
                    id="sign-up-marketing-email"
                    type="checkbox"
                    checked={props.value}
                    onChange={() => props.onChange(!props.value)}
                  />
                  <div className="state">
                    <img
                      className="svg"
                      style={{background: "black"}}
                      src={`${process.env.PUBLIC_URL}/images/checkmark.svg`}
                      alt=""
                    />
                    <label htmlFor="">&nbsp;</label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="sign-up-marketing-email">
                  {"I'd like to receive emails with the latest news and offers from PostSnap"}
                </label>
              </Grid.Column>
            </Grid.Row>
          );
        },
      },
    },
  },
  changePassword: {
    showErrorList: false,
    validate: (formData, errors) => {
      if (formData.newPassword !== formData.newPasswordRepeat) {
        errors.newPasswordRepeat.addError("Passwords don't match.");
      }

      ["newPassword", "newPasswordRepeat"].forEach(field => {
        const password = formData[field];
        // Check if it contains a number
        let containsNumber = password.split("").some(char => !isNaN(char));
        let isMinLength = password.length >= 7;

        if (!(containsNumber || isMinLength)) {
          errors[field].addError(
            "Passwords need to be at least 7 characters including at least one number."
          );
        }
      });

      return errors;
    },
    schema: {
      type: "object",
      required: ["currentPassword", "newPassword", "newPasswordRepeat"],
      properties: {
        currentPassword: {
          type: "string",
          title: "Current Password",
          minLength: 7,
        },
        newPassword: { type: "string", title: "New Password", minLength: 7 },
        newPasswordRepeat: {
          type: "string",
          title: "Confirm New Password",
          minLength: 7,
        },
      },
    },
    uiSchema: {
      currentPassword: { "ui:widget": "password" },
      newPassword: { "ui:widget": "password" },
      newPasswordRepeat: { "ui:widget": "password" },
    },
  },
};

const ADDRESS_BOOK_MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  DETAIL: "DETAIL",
  ADD_NEW: "ADD_NEW",
};

const DEFAULT_COUNTRY = "United Kingdom";

class AccountContainer extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    editAddressFormData: null,
    editAccountFormData: null,
    isSavingAccount: false,
    isChangePasswordModalVisible: false,
    changePasswordFormData: null,
    isAddressBookModalVisible: false,
    isEditAddressBookModalVisible: false,
    addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
    addressBookEntryDetailData: null,
    addressBookNewEntryData: {
      country: "United Kingdom",
    },
    alert: null,
    isAddingCustomerAddress: false
  };

  componentDidMount() {
    gtmEvent({event: "webAppStage", additionalProps: {stage: 'Account'}})
  }

  showEditAddressModal = () => {
    if (this.props.user.get("address")) {
      this.setState({
        editAddressFormData: this.props.user.get("address").toJS(),
        isEditAddressBookModalVisible: true,
      });
    } else { // No user address, need to add one

      const newAccountAddressData = {
        country: countriesByCurrency[this.props.currency] || DEFAULT_COUNTRY,
        main: true
      }

      this.setState({
        editAddressFormData: newAccountAddressData,
        isAddingCustomerAddress: true,
        isEditAddressBookModalVisible: true,
      });
    }
  };

  closeEditAddressModal = () => {
    this.setState({
      editAddressFormData: null,
      isEditAddressBookModalVisible: false,
    });
  };

  showChangePasswordModal = () => {
    this.setState({
      isChangePasswordModalVisible: true,
    });
  };

  closeChangePasswordModal = () => {
    this.setState({
      isChangePasswordModalVisible: false,
    });
  };

  showAddressBookModal = () => {
    this.setState({
      isAddressBookModalVisible: true,
    });
  };

  closeAddressBookModal = () => {
    this.setState({
      isAddressBookModalVisible: false,
    });
  };

  showEditAccountModal = () => {
    this.setState({
      editAccountFormData: this.props.user.toJS(),
      isEditAccountModalVisible: true,
    });
  };

  closeEditAccountModal = () => {
    this.setState({
      isEditAccountModalVisible: false,
    });
  };

  handleEditAddressFormChange = form => {
    this.setState({
      editAddressFormData: {
        ...this.state.editAddressFormData,
        ...form.formData,
      },
    });
  };

  handleEditAddressFormSubmit = async form => {

    const hasAddress = this.props.user.getIn(["address", "id"]);

    let data = {
      id: hasAddress,
      address: {
        main: true,
        ...filterAddressFieldsFromObject(form.formData),
      },
    };
    
    if (this.state.isAddingCustomerAddress && !hasAddress){
      console.log("Adding customer address");
      data = {
        ...data.address,
        first_name: this.props.user.get("first_name")
      }
      await this.props.addAddressBookEntry(data);
    } else{
      await this.props.updateAddressBookEntry(data);
    }
    
    this.closeEditAddressModal();
  };

  handleChangePasswordFormChange = form => {
    this.setState({
      changePasswordFormData: {
        ...this.state.changePasswordFormData,
        ...form.formData,
      },
    });
  };

  handleChangePasswordFormSubmit = form => {
    this.props.updateUser({
      current_password: form.formData.currentPassword,
      password: form.formData.newPassword,
    });
    this.closeChangePasswordModal();
  };

  handleEditAccountFormChange = form => {
    this.setState({
      editAccountFormData: {
        ...this.state.editAccountFormData,
        ...form.formData,
      },
    });
  };

  handleEditAccountFormSubmit = async form => {
    this.setState({
      isSavingAccount: true,
    });

    const result = await this.props.updateUser(form.formData);

    this.setState({
      isSavingAccount: false,
    });

    if (!result.payload.data.success) {
      if (result.payload.data.errors.current_password) {
        this.setState({
          alert: {
            type: "error",
            text:
              "Your current password does not match the one we have on file. Please check its spelling and try again.",
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
            showCancelButton: false,
          },
        });
      }
    } else {
      this.closeEditAccountModal();
    }
  };

  showAddressBookEntryDetails = entry => {
    const data = entry.toJS();
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (value === null) {
        data[key] = undefined;
      }
    });
    this.setState({
      addressBookEntryDetailData: data,
      addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.DETAIL,
    });
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleClickDeleteEntry = () => {
    this.setState({
      alert: {
        type: "warning",
        text: "Are you sure you want to delete this address?",
        confirmButtonText: "Yes",
        onConfirm: () => {
          this.props.deleteAddressBookEntry(this.state.addressBookEntryDetailData.id);
          this.setState({
            alert: null,
            addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
          });
        },
        cancelButtonText: "No",
        onCancel: this.clearAlert,
      },
    });
  };

  handleEditAddressBookEntryFormChange = form => {
    this.setState({
      addressBookEntryDetailData: {
        ...this.state.addressBookEntryDetailData,
        ...form.formData,
      },
    });
  };

  handleEditAddressBookEntryFormSubmit = async form => {
    const data = {
      id: form.formData.id,
      address: {
        ...filterAddressFieldsFromObject(form.formData),
      },
    };
    await this.props.updateAddressBookEntry(data);
    this.setState({
      addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
    });
  };

  handleNewAddressBookEntryFormChange = form => {
    this.setState({
      addressBookNewEntryData: {
        ...this.state.addressBookNewEntryData,
        ...form.formData,
      },
    });
  };

  handleNewAddressBookEntryFormSubmit = form => {
    this.props.addAddressBookEntry(form.formData);
    this.setState({
      addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
      addressBookNewEntryData: {
        country: "United Kingdom",
      },
    });
  };

  goToShop = () => {
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  render() {
    const editAddressModal = (
      <Modal
        key="edit-address"
        isOpen={this.state.isEditAddressBookModalVisible}
        onClose={this.closeEditAddressModal}
        title={this.state.isAddingCustomerAddress ? "Add your address" : "Edit address"}
        padded
        rightAction={
          <Button
            priority="tertiary"
            theme="muted"
            label={this.state.isAddingCustomerAddress ? "Apply" : "Apply"}
            onClick={this.addressForm && this.addressForm.submitForm}
          />
        }
      >
        <div className="restricted-width-modal-content">
          {this.state.editAddressFormData && (
            <AddressForm
              ref={el => (this.addressForm = el)}
              formData={this.state.editAddressFormData}
              onChange={this.handleEditAddressFormChange}
              onSubmit={this.handleEditAddressFormSubmit}
              excludeName={true}
            />
          )}
        </div>
      </Modal>
    );

    const editAccountModal = (
      <Modal
        key="edit-account"
        isOpen={this.state.isEditAccountModalVisible}
        onClose={this.closeEditAccountModal}
        title="Edit account"
        padded
      >
        {this.state.editAccountFormData && (
          <Form
            {...FORM_SCHEMAS.editAccount}
            formData={this.state.editAccountFormData}
            onChange={this.handleEditAccountFormChange}
            onSubmit={this.handleEditAccountFormSubmit}
          >
            <Button
              block
              type="submit"
              label="Save"
              loadingLabel="Saving..."
              loading={this.state.isSavingAccount}
              kind="primary"
              theme="dark-blue"
            />
          </Form>
        )}
      </Modal>
    );

    const changePasswordModal = (
      <Modal
        key="change-password"
        isOpen={this.state.isChangePasswordModalVisible}
        onClose={this.closeChangePasswordModal}
        title="Change password"
        padded
        rightAction={
          <Button
            priority="tertiary"
            theme="muted"
            label="Save"
            onClick={() => this.handleChangePasswordFormSubmit()}
          />
        }
      >
        <Form {...FORM_SCHEMAS.changePassword}
          formData={this.state.changePasswordFormData}
          onSubmit={this.handleChangePasswordFormSubmit}
          onChange={this.handleChangePasswordFormChange}
          >
          <Button block type="submit" label="Save" kind="primary" theme="dark-blue"/>
        </Form>
      </Modal>
    );

    let addressBookExtraModalProps = {};

    switch (this.state.addressBookModalPage) {
      case ADDRESS_BOOK_MODAL_PAGES.OVERVIEW:
        addressBookExtraModalProps = {
          ...addressBookExtraModalProps,
          title: "Address Book",
          rightAction: (
            <Button
              priority="tertiary"
              theme="muted"
              label="Add"
              onClick={() =>
                this.setState({
                  addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.ADD_NEW,
                })
              }
            />
          ),
        };
        break;
      case ADDRESS_BOOK_MODAL_PAGES.DETAIL:
        addressBookExtraModalProps = {
          ...addressBookExtraModalProps,
          title: "Edit Address",
          leftAction: (
            <Button
              priority="tertiary"
              theme="muted"
              label="Back"
              onClick={() =>
                this.setState({
                  addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
                })
              }
            />
          ),
          rightAction: (
            <Button
              priority="tertiary"
              theme="muted"
              label="Save"
              onClick={
                this.addressBookEntryDetailForm && this.addressBookEntryDetailForm.submitForm
              }
            />
          ),
        };
        break;
      case ADDRESS_BOOK_MODAL_PAGES.ADD_NEW:
        addressBookExtraModalProps = {
          ...addressBookExtraModalProps,
          title: "Add Address",
          leftAction: (
            <Button
              priority="tertiary"
              theme="muted"
              label="Back"
              onClick={() =>
                this.setState({
                  addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.OVERVIEW,
                })
              }
            />
          ),
          rightAction: (
            <Button
              priority="tertiary"
              theme="muted"
              label="Save"
              disabled={
                this.state.addressBookEntryDetailData &&
                !this.state.addressBookEntryDetailData.system
              }
              onClick={this.addressBookNewEntryForm && this.addressBookNewEntryForm.submitForm}
            />
          ),
        };
        break;
      // no default
    }

    const addressBookModal = (
      <Modal
        key="address-book"
        isOpen={this.state.isAddressBookModalVisible}
        onClose={this.closeAddressBookModal}
        padded={this.state.addressBookModalPage !== ADDRESS_BOOK_MODAL_PAGES.OVERVIEW}
        paged
        activePage={this.state.addressBookModalPage}
        {...addressBookExtraModalProps}
      >
        <ModalPage pageId={ADDRESS_BOOK_MODAL_PAGES.OVERVIEW} depth={1}>
          <AddressBook
            entries={this.props.addressBookEntries}
            onClickEntry={entry => this.showAddressBookEntryDetails(entry)}
            onClickAddAddress={ () => {
              this.setState({
                addressBookModalPage: ADDRESS_BOOK_MODAL_PAGES.ADD_NEW,
              })
            }}
          />
        </ModalPage>
        <ModalPage pageId={ADDRESS_BOOK_MODAL_PAGES.DETAIL} depth={2}>
          <div className="restricted-width-modal-content">
            {this.state.addressBookEntryDetailData && (
              <AddressForm
                ref={el => (this.addressBookEntryDetailForm = el)}
                formData={this.state.addressBookEntryDetailData}
                onChange={this.handleEditAddressBookEntryFormChange}
                onSubmit={this.handleEditAddressBookEntryFormSubmit}
              />
            )}
            {this.state.addressBookEntryDetailData &&
              !this.state.addressBookEntryDetailData.system && (
                <div style={{marginTop:'10px'}}>
                  <Button
                    block
                    priority="secondary"
                    theme="dark-blue"
                    label="Delete"
                    onClick={this.handleClickDeleteEntry}
                  />
                </div>
              )}
          </div>
        </ModalPage>
        <ModalPage pageId={ADDRESS_BOOK_MODAL_PAGES.ADD_NEW} depth={2}>
          <div className="restricted-width-modal-content">
            {this.state.addressBookNewEntryData && (
              <AddressForm
                ref={el => (this.addressBookNewEntryForm = el)}
                formData={this.state.addressBookNewEntryData}
                onChange={this.handleNewAddressBookEntryFormChange}
                onSubmit={this.handleNewAddressBookEntryFormSubmit}
              />
            )}
          </div>
        </ModalPage>
      </Modal>
    );

    return [
      editAddressModal,
      changePasswordModal,
      addressBookModal,
      editAccountModal,
      <SweetAlert key="alert" isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />,
      <Header
        key="top-header"
        theme="grey"
        image="/images/logo.svg"
        title="PostSnap Postcard App"
        rightAction={
          <div style={{display:'flex'}}>
            <AccountButtonContainer />
            <BasketButtonContainer />
          </div>
        }
        leftAction={<ShopButtonContainer />}
        onClickTitleOrImage={this.goToShop}
      />,
      <Account
        key="account"
        user={this.props.user}
        onClickEditAccount={this.showEditAccountModal}
        onClickAddress={this.showEditAddressModal}
        onClickChangePassword={this.showChangePasswordModal}
        onClickAddressBook={this.showAddressBookModal}
        onClickSignOut={this.props.signOut}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  user: authSelectors.getUser(state),
  addressBookEntries: addressBookSelectors.getAllEntries(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  updateAddressBookEntry: ({ id, address }) =>
    dispatch(addressBookActions.updateEntry({ id, address })),
  addAddressBookEntry: address => dispatch(addressBookActions.addNewEntry(address)),
  deleteAddressBookEntry: id => dispatch(addressBookActions.deleteEntry(id)),
  updateUser: userData => dispatch(authActions.updateUser(userData)),
  signOut: () => {
    dispatch(push(routeCreators.basket()));
    dispatch(authActions.signOut());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountContainer);
