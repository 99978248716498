import React from "react";
import { fromJS } from "immutable";
import Button from "../../../components/Button/Button";
import AddressForm from "../../../components/Form/AddressForm";
import UKPostcodeLookUpAddressForm from "../../../components/Form/UKPostcodeLookUpAddressForm";
import Modal from "../../../components/Modal/Modal";
import ModalPage from "../../../components/Modal/ModalPage";
import AddressBook from "../../../components/AddressBook/AddressBook";
import SweetAlert from "../../../components/SweetAlert/SweetAlert";
import { countriesByCurrency } from "../../../constants/geo";
import { preloadImage } from "../../../lib/image-preloader";

import { singleShippingAddressProductTypes, getShippingCountriesForProductType } from "../../../data/products";

import './CheckoutShipping.scss';
//import { ConsoleSqlOutlined } from "@ant-design/icons";


const DEFAULT_COUNTRY = "United Kingdom";

const MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  ADDRESS_FORM: "ADDRESS_FORM",
  ADDRESS_BOOK: "ADDRESS_BOOK",
  ADD_OWN_ADDRESS: "ADD_OWN_ADDRESS",
  ADD_TO_ADDRESS_BOOK: "ADD_TO_ADDRESS_BOOK",
};

class CheckoutShipping extends React.Component {
  state = {
    
  };

  constructor(props) {
    super(props);
    let defaultCountry = countriesByCurrency[props.currency] || DEFAULT_COUNTRY;
    let isAllowedOwnCountry = true;
    if(this.props.onlyCountries && this.props.onlyCountries.length > 0){
      if(!this.props.onlyCountries.includes(defaultCountry)){
        isAllowedOwnCountry = false;
      }
    }
    this.defaultFormData = {
      country: isAllowedOwnCountry ? defaultCountry : DEFAULT_COUNTRY,
    };
    this.state = {
      checkoutShippingAddressFormData: props.initialFormData || this.defaultFormData,
      loadingSendBackToMe: false,
      sendBackToMeIsDoubleDirect: false,
      editingAddress: false,
      addressBookEntryDetailData: null,
      addressBookNewEntryData: {
        country: "United Kingdom",
      },
      ownAddressFormData: this.defaultFormData,
      onlyNewAddress: props.onlyNewAddress,
      newAddressVisible: false,
      signedInAddressOptionsVisible: false,
      straightToEdit: null,
      activePage: props.onlyNewAddress ? MODAL_PAGES.ADDRESS_FORM : MODAL_PAGES.OVERVIEW,
    };
  }

  componentDidMount(){
    if (this.props.isSignedIn && !this.props.isEditingInIsolation){
      this.setState({
        signedInAddressOptionsVisible: true
      })
    }

    if (this.props.isSignedIn && this.props.isEditingInIsolation){
      this.setActivePage(MODAL_PAGES.ADDRESS_FORM)  
    }

    preloadImage(`${process.env.PUBLIC_URL}/images/uk_flag.png`)
    preloadImage(`${process.env.PUBLIC_URL}/images/us_flag.png`)
  }

  componentDidUpdate(prevProps){
    if (prevProps.overridenBackTo === "index" && this.props.overridenBackTo === null){
      this.setState({
        signedInAddressOptionsVisible: true,
        newAddressVisible: false
      })
    }
  }

  handleCheckoutShippingAddressChanged = form => {
    this.setState({
      checkoutShippingAddressFormData: {
        ...this.state.checkoutShippingAddressFormData,
        ...form.formData,
      },
    });
  };

  handleCheckoutShippingAddressReset = form => {
    this.setState({
      checkoutShippingAddressFormData: {
        ...this.defaultFormData
      },
      isEditing: false
    }, () => {
      this.props.onUpdateCheckoutShippingAddress(this.state.checkoutShippingAddressFormData)
    });
  };

  handleCheckoutShippingAddressEditing = (isEditing) => {
    this.setState({ editingAddress: isEditing });
  }

  handleCheckoutShippingAddressSubmitted = async () => {
    const address = this.state.checkoutShippingAddressFormData;
    this.props.onSubmitCheckoutShippingAddress(address)
  };

  handleClickWorldTab = () => {
    switch (this.state.checkoutShippingAddressFormData.country) {
      case "United States":
        this.setState({
          checkoutShippingAddressFormData: {
            ...this.state.checkoutShippingAddressFormData,
            country: "United Kingdom",
          },
        });
        break;
      default:
        this.setState({
          checkoutShippingAddressFormData: {
            ...this.state.checkoutShippingAddressFormData,
            country: "United States",
          },
        });
        break;
    }
  };

  handleClickOwnCountryTab = () => {
    this.setState({
      checkoutShippingAddressFormData: {
        ...this.state.checkoutShippingAddressFormData,
        country: this.defaultFormData.country,
      },
    });
  };

  handleSelectAddressBookEntry = (entry, isDoubleDirect) => {
    if (this.props.onlyCountries && this.props.onlyCountries.length > 0){
      if (!this.props.onlyCountries.includes(entry.get('country'))){
        this.setState({
          alert: {
            title: 'Sorry',
            text: `We're currently unable to ship this product to ${entry.get('country')}`,
            confirmButtonText: 'OK',
            onConfirm: () => {
              this.setState({ alert: null });
            },
          },
          loadingSendBackToMe: false,
        });

        //return true;
      } else{
        this.props.onSelectAddressBookEntry(entry, isDoubleDirect);
        this.setActivePage(MODAL_PAGES.OVERVIEW);
        this.setState({ loadingSendBackToMe: false });
      }
    } else{
      this.props.onSelectAddressBookEntry(entry, isDoubleDirect);
      this.setActivePage(MODAL_PAGES.OVERVIEW);
      this.setState({ loadingSendBackToMe: false });
    }
  };


  handleClickSendBackToMe = ownAddressResponse => {
    if (this.props.user.get("address") || ownAddressResponse) {
      this.setState({ loadingSendBackToMe: true }, () => {
        this.handleSelectAddressBookEntry(
          this.props.user.get("address") || fromJS(ownAddressResponse.payload.data.data),
          this.props.isDoubleDirect
        );
      });
    } else {
      this.setState({
        activePage: MODAL_PAGES.ADD_OWN_ADDRESS,
      });
    }
  };

  handleClickNewAddress = () => {
    this.setState({
      checkoutShippingAddressFormData: {
        ...this.defaultFormData
      },
      isEditing: false,
      straightToEdit: false
    }, () => {
      this.props.onUpdateCheckoutShippingAddress(this.state.checkoutShippingAddressFormData)
      this.setActivePage(MODAL_PAGES.ADDRESS_FORM)  
    });
    // this.setState({
    //   newAddressVisible: true,
    //   signedInAddressOptionsVisible: false
    // }, () => {
    //   this.props.updateBackTo("index")
    // })
  }

  setActivePage = pageId => {
    this.setState({ activePage: pageId })
  };

  handleClickAddressBook = () => {
    if (this.props.isSignedIn) {
      this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
    }
  };

  handleCancelNewAddress = () => {
    if (this.state.onlyNewAddress){
      this.props.onCancel()
    } else {
      this.setActivePage(MODAL_PAGES.OVERVIEW)
    }
  }

  handleOwnAddressFormChange = form => {
    this.setState({
      ownAddressFormData: {
        ...this.state.ownAddressFormData,
        ...form.formData,
      },
    });
  };

  handleOwnAddressFormSubmit = async () => {
    const address = this.state.ownAddressFormData;
    this.props.onSaveOwnAddress(address).then(this.handleClickSendBackToMe);
  };

  handleNewAddressBookEntryFormChange = form => {
    this.setState({
      addressBookNewEntryData: {
        ...this.state.addressBookNewEntryData,
        ...form.formData,
      },
    });
  };

  handleNewAddressBookEntryFormSubmit = async form => {
    const address = this.state.addressBookNewEntryData;
    const result = await this.props.addAddressBookEntry(address);
    if (!result.payload.data.success) {
      this.setState({
        alert: {
          type: "error",
          text:
            "There was a problem adding your address",
          confirmButtonText: "OK",
          onConfirm: () => this.setState({ alert: null }),
          showCancelButton: false,
        },
      });
    } else {
      this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
      this.setState({
        addressBookNewEntryData: {
          country: "United Kingdom",
        },
      });
    }
  };


  render() {


    let headerProps = {};

    switch (this.state.activePage) {
      case MODAL_PAGES.OVERVIEW:
        headerProps = {
          title: this.props.titleLabel || "Shipping Address",
          theme: 'grey',
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={this.props.onCancel}
            />
          ),
        };
        break;
      case MODAL_PAGES.ADDRESS_BOOK:
        headerProps = {
          title: "Choose Address",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      case MODAL_PAGES.ADDRESS_FORM:
        headerProps = {
          title: this.props.onlyNewAddress ? "Shipping Address" : "New Address",
          theme: 'grey',
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={this.handleCancelNewAddress}
            />
          ),
          // rightAction: (
          //   <Button
          //     label={this.state.editingAddress ? "Done" : "Apply"}
          //     priority="tertiary"
          //     theme="default"
          //     onClick={() => this.state.editingAddress ? this.newAddressForm.doneEditingAddress() : this.newAddressForm && this.newAddressForm.submitForm()}
          //     disabled={
          //       this.state.newAddressFormData.country === "United Kingdom" &&
          //       !this.state.newAddressFormData.line1
          //     }
          //   />
          // ),
        };
        break;
      case MODAL_PAGES.ADD_OWN_ADDRESS:
        headerProps = {
          title: "Add Your Address",
          theme: 'grey',
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            <Button
              label={this.state.ownAddressFormData.country === "United States" ? "Save" : "Save"}
              priority="tertiary"
              theme="default"
              onClick={() => this.ownAddressForm && this.ownAddressForm.submitForm()}
              disabled={
                this.state.ownAddressFormData.country === "United Kingdom" &&
                !this.state.ownAddressFormData.line1
              }
            />
          ),
        };
        break;
        case MODAL_PAGES.ADD_TO_ADDRESS_BOOK:
          headerProps = {
            title: "Add Address",
            theme: 'grey',
            leftAction: (
              <Button
                label="Cancel"
                priority="tertiary"
                theme="muted"
                onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
              />
            ),
            rightAction: (
              <Button
                priority="tertiary"
                theme="muted"
                label="Save"
                disabled={
                  this.state.addressBookEntryDetailData &&
                  !this.state.addressBookEntryDetailData.system
                }
                onClick={this.addressBookNewEntryForm && this.addressBookNewEntryForm.submitForm}
              />
            ),
          };
        break;
      // no default
    }


    let showCountryToggle = !this.props.isEditingInIsolation || (this.props.isEditingInIsolation && !this.state.isEditing)
    const straightToEdit = (this.state.straightToEdit !== false && this.state.straightToEdit !== null) || this.props.straightToEdit //Object.keys(this.state.checkoutShippingAddressFormData).length > 1
    const isOnOwnCountry =
      this.state.checkoutShippingAddressFormData.country === countriesByCurrency[this.props.currency];
    let ownCountryLabel = this.defaultFormData.country;

    //let otherCountriesLabel = "USA"

    switch (this.defaultFormData.country) {
      case "United Kingdom":
        ownCountryLabel = "UK";
        break;
      case "United States":
        ownCountryLabel = "USA";
        break;
      // no default
    }

    const shouldShowUKPostcodeLookupForm =
      isOnOwnCountry && this.state.checkoutShippingAddressFormData.country === "United Kingdom";
    

    const clarifySingleShipmentInfo = this.props.items.some((item) => !singleShippingAddressProductTypes.includes(item.get('productTypeId')))

    const countries = this.props.items.flatMap(item => {
      return getShippingCountriesForProductType(item.get('productTypeId'), item.get('productId'));
    })

    if (countries.size === 1){
      showCountryToggle = false
    }

    let addressBookEntries = this.props.addressBookEntries;
    addressBookEntries = addressBookEntries.filter(entry => countries.toJS().includes(entry.get('country')));

    const newAddressFormContent = (
      <div className="restricted-width-modal-content">
        <div className="checkout__shipping-container">
          {showCountryToggle && (
            <div className="checkout__shipping-country-select-container">
              <div className="checkout__shipping-country-select-label">
                Select country:
              </div>
              <div className="checkout__shipping-country-select">
                <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                  <img className="checkout__shipping-country-option-flag" src={`${process.env.PUBLIC_URL}/images/uk_flag.png`} alt='UK'/>
                </div>
                <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                  <img className="checkout__shipping-country-option-flag" src={`${process.env.PUBLIC_URL}/images/us_flag.png`} alt='US'/>
                </div>
              </div>
            </div>
          )}
          {shouldShowUKPostcodeLookupForm ? (
            <UKPostcodeLookUpAddressForm
              ref={el => (this.checkoutShippingAddressForm = el)}
              formData={this.state.checkoutShippingAddressFormData}
              onChange={this.handleCheckoutShippingAddressChanged}
              onReset={this.handleCheckoutShippingAddressReset}
              onSubmit={this.handleCheckoutShippingAddressSubmitted}
              onIsEditing={this.handleCheckoutShippingAddressEditing}
              straightToEdit={straightToEdit}
              submitOnSelection={true}
              isEditingInIsolation={this.props.isEditingInIsolation}
            />
          ) : (
            <AddressForm
              formData={this.state.checkoutShippingAddressFormData}
              onChange={this.handleCheckoutShippingAddressChanged}
              onSubmit={this.handleCheckoutShippingAddressSubmitted}
              onlyCountries={countries.toJS()}
              isEditingInIsolation={this.props.isEditingInIsolation}
            />
          )}
        </div>
        {clarifySingleShipmentInfo && (
          <p className="red-text">You have cards in your basket which will be sent direct to your recipient at the address you’ve previously entered</p>
        )}
      </div>
    )

    return [
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="address-alert"
        data-gtm-element="Address Error"
      />,
      <Modal
        key="address-input-modal-guest"
        isOpen={!this.props.isSignedIn}
        onClose={this.props.onCancel}
        padded
        {...headerProps}
      >
        {newAddressFormContent}
      </Modal>,
      <Modal
        key="address-input-modal-signed-in"
        isOpen={this.props.isSignedIn}
        onClose={this.props.onCancel}
        padded
        paged
        activePage={this.state.activePage}
        {...headerProps}
      >
      <ModalPage pageId={MODAL_PAGES.OVERVIEW} depth={1}>
        <div className="restricted-width-modal-content">
          <div className="address-selection-container">
            <div className="address-selection-block">
              <div className="address-selection-block--btns">
                <Button
                  block
                  theme="dark-blue"
                  priority="secondary"
                  label="Send to Me"
                  onClick={() => this.handleClickSendBackToMe(false)}
                  dataGtmElement="Address Option Button"
                />
                <Button
                  block
                  theme="dark-blue"
                  priority="secondary"
                  label="New Name & Address"
                  onClick={this.handleClickNewAddress}
                  dataGtmElement="Address Option Button"
                />
                <Button
                  block
                  theme="dark-blue"
                  priority="secondary"
                  label="PostSnap Address Book"
                  onClick={this.handleClickAddressBook}
                  dataGtmElement="Address Option Button"
                />
              </div>
          </div>
          </div>
        </div>
      </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_BOOK} depth={2}>
          <div style={{ margin: "0 -16px" }}>
            {this.state.activePage === MODAL_PAGES.ADDRESS_BOOK && (
              <AddressBook
                entries={addressBookEntries}
                onClickEntry={this.handleSelectAddressBookEntry}
                onClickAddAddress={() => this.setActivePage(MODAL_PAGES.ADD_TO_ADDRESS_BOOK)}
                onClickAddMainAddress={() => this.setActivePage(MODAL_PAGES.ADD_OWN_ADDRESS)}
              />
            )}
            
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_FORM} depth={2}>
          {newAddressFormContent}
        </ModalPage>

        <ModalPage pageId={MODAL_PAGES.ADD_OWN_ADDRESS} depth={2}>
          <div className="restricted-width-modal-content">
            <div className="checkout__shipping-container">
              {showCountryToggle && (
                <div className="checkout__shipping-country-select-container">
                  <div className="checkout__shipping-country-select-label">
                    Select country:
                  </div>
                  <div className="checkout__shipping-country-select">
                    <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                      <span style={{color: isOnOwnCountry ? 'white' : 'black'}}>{ownCountryLabel}</span>
                    </div>
                    <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                      <span style={{color: isOnOwnCountry ? 'black' : 'white'}}>World</span>
                    </div>
                  </div>
                </div>
              )}
              {this.state.activePage === MODAL_PAGES.ADD_OWN_ADDRESS && (
                shouldShowUKPostcodeLookupForm ? (
                  <UKPostcodeLookUpAddressForm
                    key="own-uk-address"
                    ref={el => (this.ownAddressForm = el)}
                    formData={this.state.ownAddressFormData}
                    onChange={this.handleOwnAddressFormChange}
                    onSubmit={this.handleOwnAddressFormSubmit}
                    onIsEditing={this.handleEditingAddress}
                    straightToEdit={straightToEdit}
                    submitOnSelection={true}
                  />
                ) : (
                  <AddressForm
                    formData={this.state.ownAddressFormData}
                    onChange={this.handleOwnAddressFormChange}
                    onSubmit={this.handleOwnAddressFormSubmit}
                    onlyCountries={countries.toJS()}
                  />
                )
              )}
            </div>
          </div>
        </ModalPage>

        <ModalPage pageId={MODAL_PAGES.ADD_TO_ADDRESS_BOOK} depth={2}>
          <div className="restricted-width-modal-content">
            <div className="checkout__shipping-container">
              {showCountryToggle && (
                <div className="checkout__shipping-country-select-container">
                  <div className="checkout__shipping-country-select-label">
                    Select country:
                  </div>
                  <div className="checkout__shipping-country-select">
                    <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                      <span style={{color: isOnOwnCountry ? 'white' : 'black'}}>{ownCountryLabel}</span>
                    </div>
                    <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                      <span style={{color: isOnOwnCountry ? 'black' : 'white'}}>World</span>
                    </div>
                  </div>
                </div>
              )}
              {this.state.activePage === MODAL_PAGES.ADD_TO_ADDRESS_BOOK && (
                shouldShowUKPostcodeLookupForm ? (
                  <UKPostcodeLookUpAddressForm
                    key="address-book-entry-uk"
                    ref={el => (this.addressBookNewEntryForm = el)}
                    formData={this.state.addressBookNewEntryData}
                    onChange={this.handleNewAddressBookEntryFormChange}
                    onSubmit={this.handleNewAddressBookEntryFormSubmit}
                    submitOnSelection={true}
                    onIsEditing={this.handleEditingAddress}
                  />
                ) : (
                  <AddressForm
                    formData={this.state.addressBookNewEntryData}
                    onChange={this.handleNewAddressBookEntryFormChange}
                    onSubmit={this.handleNewAddressBookEntryFormSubmit}
                    onlyCountries={countries.toJS()}
                  />
                )
              )}
              {clarifySingleShipmentInfo && (
                <p className="red-text">You have a card in your basket which will be sent direct to your recipient at the address you’ve previously entered</p>
              )}
            </div>
          </div>
        </ModalPage>
      </Modal>,
    ];

    // return (
    //   <MainContent centerContent padded>
    //     <div className="restricted-width-modal-content">
    //       {this.state.signedInAddressOptionsVisible && (
    //         <div className="address-selection-block--btns">
    //           <Button
    //             block
    //             theme="dark-blue"
    //             priority="secondary"
    //             label="Send to Me"
    //             onClick={this.handleClickSendBackToMe}
    //             dataGtmElement="Address Option Button"
    //           />
    //           <Button
    //             block
    //             theme="dark-blue"
    //             priority="secondary"
    //             label="New Name & Address"
    //             onClick={this.handleClickNewAddress}
    //             dataGtmElement="Address Option Button"
    //           />
    //           <Button
    //             block
    //             theme="dark-blue"
    //             priority="secondary"
    //             label="PostSnap Address Book"
    //             onClick={this.handleClickAddressBook}
    //             dataGtmElement="Address Option Button"
    //           />
    //         </div>
    //       )}

    //       {this.state.newAddressVisible && (
    //         <div className="checkout__shipping-container">
    //           {showCountryToggle && (
    //             <div className="checkout__shipping-country-select-container">
    //               <div className="checkout__shipping-country-select-label">
    //                 Select country:
    //               </div>
    //               <div className="checkout__shipping-country-select">
    //                 <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
    //                   <img className="checkout__shipping-country-option-flag" src={`${process.env.PUBLIC_URL}/images/uk_flag.png`} alt='UK'/>
    //                 </div>
    //                 <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
    //                   <img className="checkout__shipping-country-option-flag" src={`${process.env.PUBLIC_URL}/images/us_flag.png`} alt='UK'/>
    //                 </div>
    //               </div>
    //             </div>
    //           )}
    //           {shouldShowUKPostcodeLookupForm ? (
    //             <UKPostcodeLookUpAddressForm
    //               ref={el => (this.checkoutShippingAddressForm = el)}
    //               formData={this.state.checkoutShippingAddressFormData}
    //               onChange={this.handleCheckoutShippingAddressChanged}
    //               onReset={this.handleCheckoutShippingAddressReset}
    //               onSubmit={this.handleCheckoutShippingAddressSubmitted}
    //               onIsEditing={this.handleCheckoutShippingAddressEditing}
    //               straightToEdit={straightToEdit}
    //               submitOnSelection={true}
    //               isEditingInIsolation={this.props.isEditingInIsolation}
    //             />
    //           ) : (
    //             <AddressForm
    //               ref={el => (this.checkoutShippingAddressForm = el)}
    //               formData={this.state.checkoutShippingAddressFormData}
    //               onChange={this.handleCheckoutShippingAddressChanged}
    //               onSubmit={this.handleCheckoutShippingAddressSubmitted}
    //               onlyCountries={this.props.onlyCountries}
    //               isEditingInIsolation={this.props.isEditingInIsolation}
    //             />
    //           )}
    //         </div>
    //       )}
    //     </div>
          
    //   </MainContent>
    // );
  }
}

CheckoutShipping.propTypes = {};
CheckoutShipping.defaultProps = {};

export default CheckoutShipping;
