import * as STORAGE_KEYS from "../constants/storage-keys";

const currentAppVersion = process.env.REACT_APP_VERSION;
const storedAppVersion = localStorage.getItem(STORAGE_KEYS.APP_VERSION);

export function isCurrentMajorVersionOutdated() {
  if (!storedAppVersion) {
    return false;
  }

  const storedAppVersionMajor = +storedAppVersion.split(".")[0];
  const currentAppVersionMajor = +currentAppVersion.split(".")[0];
  return currentAppVersionMajor > storedAppVersionMajor;
}
