import React from "react";
import PropTypes from "prop-types";

import "./BottomNavbar.scss";

const BottomNavBar = ({ children }) => <nav className="bottom-navbar">{children}</nav>;

BottomNavBar.propTypes = {
  children: PropTypes.node,
};

BottomNavBar.defaultProps = {};

export default BottomNavBar;
