import React from "react";
import { Modal as AntModal, Button } from "antd";
//import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Slider from "../Slider/Slider";

const FontSizeSliderSelectionModal = ({ isOpen, onClose, fontSize, onChangeFont, selectedFontSize, tooBig }) => {
  return (
    <AntModal visible={isOpen} onCancel={onClose} footer={[]}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "25px",
        }}
      >
        <p style={{fontSize:'18px'}}>Select a font size:</p>
        <Slider
          min={fontSize[0].size}
          max={fontSize[fontSize.length-1].size}
          step={1}
          value={selectedFontSize}
          onChange={val => onChangeFont(val)}
        />
        {tooBig ? (
          <>
            <span style={{
              fontSize: '20px',
              fontWeight: 'bold',
              border: '1px solid rgb(172 93 87)',
              padding: '3px 20px',
              borderRadius: '10px',
              background: 'rgb(255 221 221)',
              color: '#F44336'
            }}>{selectedFontSize}</span>
            <span style={{
              color: '#F44336',
              textAlign: 'center',
              fontSize: '12px'
            }}>This is too big - reduce the font size or shorten your text</span>
          </>
        ) : (
          <span style={{
            fontSize: '20px',
            fontWeight: 'bold',
            border: '1px solid rgb(236, 236, 236)',
            padding: '3px 20px',
            borderRadius: '10px',
            background: '#ededed'
          }}>{selectedFontSize}</span>
        )}
        
        {/* {fontSize.map(({ size, name }, index) => (
          <div onClick={() => onChangeFont(size)}>
            <text style={{ fontSize: 16+((index+1)*4) }}>{name} <span style={{backgroundColor: 'yellow'}}>({size})</span></text>
            {selectedFontSize === size ? (
              <CheckOutlined style={{ marginLeft: 20 }} />
            ) : (
              <CloseOutlined style={{ marginLeft: 20, color: "transparent" }} />
            )}
          </div>
        ))} */}
        <Button
          className="ant-custom-btn"
          //style={{ position: "absolute", top: 20, right: 20, zIndex: 1000 }}
          onClick={onClose}
          disabled={tooBig}
        >
          Done
        </Button>
      </div>
    </AntModal>
  );
};
export default FontSizeSliderSelectionModal;
