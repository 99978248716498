const urlsAlreadyPreloaded = [];

export function preloadImage(url) {
  if (urlsAlreadyPreloaded.includes(url)) {
    //console.log("Image already preloaded, resolving right away");
    return Promise.resolve();
  }

  return new Promise(function(resolve, reject) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      urlsAlreadyPreloaded.push(url);
      resolve();
    };
    //img.onerror = reject;
    img.onerror = function(e) {
      console.log(`Preloading image error for: ${this.src}`)
      resolve();
      //reject(`Preloading image error for ${this.src}`);
    };
    // May want to resolve here ^
  });
}
