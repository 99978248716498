import React, { Component } from "react";
import { fromJS, List } from "immutable";

import throttle from "lodash/throttle";
import * as LAYER_TYPES from "../../constants/layer-types";
import { pickImages } from "../../lib/file-uploader";
import getMaxResolutionForDimensions from "../../lib/get-max-resolution-for-dimensions";
import { productsByProductId } from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import Button from "../Button/Button";
import EditorCropperModal from "../Editor/EditorCropperModal";
import PhotoMagazineEditor from "./PhotoMagazineEditor";
import SweetAlert from "../SweetAlert/SweetAlert";
import { message, Modal as AntModal } from "antd";
import TextEditor from "../Editor/TextEditor";
import * as STORAGE_KEYS from "../../constants/storage-keys";

import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

// import coverTemplates from "../../constants/templateCover.json";
// import coverTemplatesLandscape from "../../constants/templateLandscapeCover.json";
// import regularTemplates from "../../constants/templateFile.json";
// import landscapeTemplates from "../../constants/templateFileLandScape.json";

import pageTemplatesSquare from "../../constants/pageTemplatesSquare.json";
import pageTemplatesPortrait from "../../constants/pageTemplatesPortrait.json";
import pageTemplatesLandscape from "../../constants/pageTemplatesLandscape.json";

import coverTemplatesSquare from "../../constants/coverTemplatesSquare.json";
import coverTemplatesPortrait from "../../constants/coverTemplatesPortrait.json";
import coverTemplatesLandscape from "../../constants/coverTemplatesLandscape.json";

import colours from "../../pages/ThemeList/colours.json";

const DEBUG_UPLOAD = {
  uuid: "4e04c552-5b9d-4e33-980a-535d310a3d8d",
  name: "debug-grid.jpg",
  size: 2374050,
  isStored: true,
  isImage: true,
  originalImageInfo: {
    dpi: [72, 72],
    width: 2125,
    format: "JPEG",
    height: 1524,
    sequence: false,
    color_mode: "RGB",
    orientation: 1,
    geo_location: null,
    datetime_original: null,
  },
  mimeType: "image/jpeg",
  originalUrl: "https://ucarecdn.com/4e04c552-5b9d-4e33-980a-535d310a3d8d/",
  cdnUrl: "https://ucarecdn.com/4e04c552-5b9d-4e33-980a-535d310a3d8d/",
  cdnUrlModifiers: null,
  sourceInfo: {
    source: "local",
    file: {},
  },
  highResUrl: "https://ucarecdn.com/4e04c552-5b9d-4e33-980a-535d310a3d8d//-/autorotate/yes/",
  lowResUrl:
    "https://ucarecdn.com/4e04c552-5b9d-4e33-980a-535d310a3d8d//-/autorotate/yes//-/resize/1200x/",
};

const DEFAULT_SQUARE_BORDER_THICKNESS = 0.05 //0.085;
const DEFAULT_LANDSCAPE_BORDER_THICKNESS = 0.06
const NO_BORDER_CONFIG = {
  style: { showBorder: false },
  thickness: 0,
};

function getImagesMappedToItem(item, images, showOnlyPopulated = true) {
  return item.update("layers", layers => {
    const updateLayers = layers
      .map(layer => {
        const imageForPage = images.get(layer.get("page"));
        if (layer.get("type") === LAYER_TYPES.PHOTO) {
          return layer.setIn(["config", "layout", 0, "image"], imageForPage);
        }
        return layer;
      })
      .sortBy(layer => layer.get("page"));
    const onlyPopulated = updateLayers.filter(layer => layer.getIn(["config", "layout", 0, "image"]))
    if(showOnlyPopulated === true){
      return onlyPopulated;
    } else{
      return updateLayers
    }
  });
}

class PhotoMagazineEditorContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.productType =
      props.item.getIn(["productDimensions", "width"]) /
        props.item.getIn(["productDimensions", "height"]) ===
      1
        ? "square"
        : "landscape";
    this.state = {
      item: props.item,
      images: props.item
        .get("layers")
        .filter(layer => layer.get("type") === LAYER_TYPES.PHOTO)
        .sortBy(layer => layer.get("page"))
        .map(layer => layer.getIn(["config", "layout", 0, "image"]))
        .filter(image => image),
      alert: null,
      pageNumberToCrop: null,
      isUploadModalVisible: false,
      isReplacingImage: false,
      replacingImageIndex: null,
      replacingImageTemplateIndex: null,
      isCoverTextInputModalVisible: true,
      coverTextModalHasBeenAutoShown: false,
      isCancelConfirmationAlertVisible: false,
      addTemplateToPageNumber: null,
      template: null,
      isTemplateModalOpen: false,
      isUploadTemplateImageModalVisible: false,
      textEditorModalVisible: false,
      templateToUpdateData: null,
      templatePhotoEdit: null,
      reRender: null,
      imagesToUploadLimit: null,
      showWelcomeModal: false,
      hasSeenWelcomeModal: false,
      reRenderPhotos: true,
      savingCrop: false,
      scrollToPageValue: null
    };
  }
  openCoverTextEditor = () => {
    const coverLayer = this.state.item
      .toJS()
      .layers.filter(layer => layer.type === LAYER_TYPES.PHOTO && layer.page === 0)[0];
    const placeholder = coverLayer.config.template.placeholders.filter(placeholder => {
      return placeholder.type === "TEXT_PLACEHOLDER";
    })[0];
    this.setState(
      {
        uploadingFirstTime: false,
        textEditorModalVisible: true,
        templateToUpdateData: {
          placeholderId: placeholder.id,
          pageNumber: 0,
          placeholder: placeholder,
          forCover: true,
        },
      },
      this.emitUpdate
    );
  };
  coverTextWork = async () => {
    // let defaultCoverTemplate = this.state.item
    //   .toJS()
    //   ?.coverTemplates.filter(template => template.name === "default")[0];
    let coverTemplatesAll;

    const width = this.state.item.toJS().productDimensions.width
    const height = this.state.item.toJS().productDimensions.height

    if (width === height){
      coverTemplatesAll = coverTemplatesSquare
    } else if (width > height) {
      coverTemplatesAll = coverTemplatesLandscape
    } else if (width < height){
      coverTemplatesAll = coverTemplatesPortrait
    }

    let coverTemplate = coverTemplatesAll.filter(template => template.name === "default")[0];
    let defaultCoverTemplate = JSON.parse(JSON.stringify(coverTemplate));

    const defaultThemes = colours; //this.state.item.toJS().themes[2].themeData;
    //const randomTheme = defaultThemes[Math.floor(Math.random() * (defaultThemes.length - 2))];
    const defaultTheme = defaultThemes.find(theme => theme.color === "#FFFFFF");
    console.log("defaultTheme", defaultThemes)
    defaultCoverTemplate.canvasColor = defaultTheme?.hex;
    defaultCoverTemplate.fontColor = defaultTheme?.textColor;

    this.setBorderColor(
      defaultTheme?.hex,
      null,
      false,
      defaultTheme?.category,
      defaultTheme?.textColor,
      false
    );
    this.handleSaveTemplate(defaultCoverTemplate, 0);

    if (this.state.uploadingFirstTime)
      this.setState(
        {
          uploadingFirstTime: false,
          // textEditorModalVisible: true,
          // templateToUpdateData: {
          //   placeholderId: 2,
          //   pageNumber: 0,
          //   placeholder: placeholder,
          //   forCover: true,
          // },
          item: this.state.item.withMutations(item => {
            return item.withMutations(item => {
              item.update("layers", layers => {
                return layers.map(layer => {
                  if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === 0) {
                    return layer.setIn(["config", "template"], defaultCoverTemplate);
                  } else {
                    return layer;
                  }
                });
              });
            });
          }),
        },
        () => {
          this.emitUpdate();
          this.openCoverTextEditor();
        }
      );
  };

  componentDidMount() {
    if (this.state.images.size === 0) {
      this.setState(
        {
          uploadingFirstTime: true,
        },
        () => {
          const recentlyUploaded = JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
          const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;
          this.showUploadModal(25, anyRecentlyUploaded);
          if (!this.state.hasSeenWelcomeModal) {
            this.setState({ showWelcomeModal: true });
          }
        }
      );
    } else if (this.state.uploadingFirstTime) {
      this.coverTextWork();
    }
    //this.debugUpload().then(() => {
    //  //this.startCropping(0);
    //});
  }

  emitUpdate = () => {
    //console.log("emitUpdate", this.props.onUpdate)
    this.props.onUpdate &&
      this.props.onUpdate(
        {
          item: this.state.item,
          images: this.state.images,
        },
        1,
        () => {
          if (this.state.uploadingFirstTime) {
            this.coverTextWork();
          }
        }
      );
  };

  pickImageForTemplate = async source => {
    await this.saveUsedImages()

    try {

      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };

      const product = productsByProductId.get(this.state.item.get("productId"));

      let maxResolution = getMaxResolutionForDimensions({
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
      });

      // TODOD: check the 100% is needed and not the default
      maxResolution = `${maxResolution} 100%`;

      const images = await pickImages({
        amount: 1,
        source,
        maxResolution,
        onTotalProgressUpdate: throttle(progressHandler, 500),
      });
      const image = images;

      let cropData;
      if (image.originalImageInfo){
        const isPortraitImage =
          image.originalImageInfo.height > image.originalImageInfo.width;
        if (isPortraitImage) {
          let dimensionsAsPx = getMaxResolutionForDimensions({
            width: parseFloat(product.get("width")),
            height: parseFloat(product.get("height")),
            dpi: product.get("dpi"),
          });

          console.log("this.state.templateToUpdateData", this.state.templateToUpdateData)
          let imagePlaceholder = this.state.templateToUpdateData.template.placeholders.find((placeholder) => placeholder.id === this.state.templateToUpdateData.placeholderId)
          console.log("imagePlaceholder", imagePlaceholder)

          let width = dimensionsAsPx.split("x")[0]
          let height = dimensionsAsPx.split("x")[1]

          console.log("width", width)
          console.log("height", height)

          let aspectRatio = image.originalImageInfo.height / image.originalImageInfo.width;
          let regionWidth
          let regionHeight

          if (imagePlaceholder){
            regionWidth = imagePlaceholder.position.topRight[0] - imagePlaceholder.position.topLeft[0]
            regionHeight = imagePlaceholder.position.bottomRight[1] - imagePlaceholder.position.topRight[1]
            console.log("regionWidth", regionWidth)
            console.log("regionHeight", regionHeight)
            //width = width * regionWidth
            //height = height * regionHeight
            console.log("width", width)
            console.log("height", height)
            aspectRatio = regionHeight / regionWidth
            console.log("aspectRatio", aspectRatio)
          }

          const PREVIEW_CROPPER_NODE_WIDTH = 500;
          let heightRatio =
            image.originalImageInfo.height / image.originalImageInfo.width;
          //let transformHeight = PREVIEW_CROPPER_NODE_WIDTH * aspectRatio;

          let translateX = 0
          console.log("heightRatio", heightRatio)

          let isRegionLandscape = regionWidth > regionHeight
          let photoIsLandscape = image.originalImageInfo.width > image.originalImageInfo.height
          let imageAspectRatio = photoIsLandscape ? (image.originalImageInfo.width/image.originalImageInfo.height) : (image.originalImageInfo.height/image.originalImageInfo.width)
          let regionAspectRatio = isRegionLandscape ? (regionWidth/regionHeight) : (regionHeight/regionWidth)

          console.log("regionWidth", regionWidth)
          console.log("regionHeight", regionHeight)
          console.log("imageWidth", image.originalImageInfo.width)
          console.log("imageHeight", image.originalImageInfo.height)
          console.log("isRegionLandscape", isRegionLandscape)
          console.log("photoIsLandscape", photoIsLandscape)
          console.log("imageAspectRatio", imageAspectRatio)
          console.log("regionAspectRatio", regionAspectRatio)

          let containerWidth = PREVIEW_CROPPER_NODE_WIDTH
          let containerHeight = isRegionLandscape ? PREVIEW_CROPPER_NODE_WIDTH / regionAspectRatio : PREVIEW_CROPPER_NODE_WIDTH * regionAspectRatio
          let cropDataWidth = image.originalImageInfo.width
          let cropDataHeight = isRegionLandscape ? image.originalImageInfo.width / regionAspectRatio : image.originalImageInfo.width * regionAspectRatio// image.originalImageInfo.width / (regionWidth/regionHeight) 640 / (0.41/0.85)
          let transformWidth = PREVIEW_CROPPER_NODE_WIDTH
          let transformHeight = PREVIEW_CROPPER_NODE_WIDTH * imageAspectRatio

          console.log("containerWidth", containerWidth)
          console.log("containerHeight", containerHeight)
          console.log("cropDataWidth", cropDataWidth)
          console.log("cropDataHeight", cropDataHeight)
          console.log("transformWidth", transformWidth)
          console.log("transformHeight", transformHeight)


          cropData = {
            x: 0,
            y: 0,
            width: cropDataWidth,
            height: cropDataHeight,
            rotate: 0,
            scaleX: 1,
            scaleY: 1,
            transform: {
              containerWidth: containerWidth,
              containerHeight: containerHeight,
              width: transformWidth,
              height: transformHeight,
              translateX: translateX,
              translateY: 0,
              xPercent: 0,
              yPercent: 0,
            },
          };
        }
      }

      console.log("cropData", cropData)

      let newImage = {
        src: {
          lowResUrl: image.lowResUrl,
          highResUrl: image.highResUrl,
          uploadcareUuid: image.uuid,
        },
        cropData: null // cropData
      };
      const template = this.state.templateToUpdateData.template;
      template.placeholders.forEach(placeholder => {
        if (placeholder.id === this.state.templateToUpdateData.placeholderId) {
          placeholder.properties.imageSrc = image.highResUrl;
          placeholder.properties.imageData = newImage;
        }
      });
      this.setState(
        {
          item: this.state.item.withMutations(item => {
            return item.withMutations(item => {
              return item.update("layers", layers => {
                return layers.map(layer => {
                  if (
                    layer.get("page") === this.state.templateToUpdateData.pageNumber &&
                    layer.get("type") === LAYER_TYPES.PHOTO
                  ) {
                    return layer.setIn(["config", "template"], template);
                  } else {
                    return layer;
                  }
                });
              });
            });
          }),
          reRender: !this.state.reRender,
          isUploadTemplateImageModalVisible: false,
          templateToUpdateData: null,
        },
        this.emitUpdate
      );

      this.closeUploadModal();
    } catch (err) {
      console.log("Cancelling upload:", err);
    }
  };
  selectImageUploadCount = number => {
    this.setState({
      imagesToUploadLimit: number,
    });
  };

  saveUsedImages = async () => {
    const stateUuidsArray = this.state.images.toJS().map(i => i.src.uploadcareUuid)
    const layerImages = this.state.item && this.state.item.get("layers").filter(layer => layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"]))

    const layerImagesArray = layerImages.toJS();
    // Extract the UUIDs and flatten the array
    const uuidsArray = layerImagesArray.flatMap((i) =>
      (i.config.template?.placeholders || []).map((p) => p.properties.imageData?.src?.uploadcareUuid)
    );

    // Remove undefined values
    const filteredUUIDs = uuidsArray.filter((uuid) => uuid);

    // Remove duplicates (using a Set to automatically eliminate duplicates)
    const combinedUuids = [
      ...new Set([
        ...filteredUUIDs,
        ...stateUuidsArray
      ])
    ];
    
    // console.log("filteredUUIDs", filteredUUIDs)
    // console.log("stateUuidsArray", stateUuidsArray)
    // console.log("combinedUuids", combinedUuids)
    localStorage.setItem("usedUUIDs", JSON.stringify(combinedUuids));
  }

  pickImageFromUploadcare = async (source, amount) => {

    await this.saveUsedImages()

    try {
      const progressHandler = totalProgress => {
        this.props.setGlobalProgressBarPercentage(totalProgress * 100);
      };

      const product = productsByProductId.get(this.state.item.get("productId"));
      const maxPages = this.state.item.get("maxPages") + 1;
      let maxResolution = getMaxResolutionForDimensions({
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
      });

      const layersWithPhotos =
        this.state.item &&
        this.state.item
          .get("layers")
          .filter(
            layer =>
              layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
          )
          .sortBy(layer => layer.get("page"));
      console.log("pages added", layersWithPhotos.size && layersWithPhotos.size)

      // if (this.state.item.toJS().layers.length - 1 + this.state.imagesToUploadLimit > maxPages) {
      //   const newAmount =
      //     this.state.imagesToUploadLimit -
      //     (this.state.item.toJS().layers.length - 1 + this.state.imagesToUploadLimit - maxPages);
      //   this.insertNewLayer(newAmount);
      // } else if (amount <= 0) {
      //   this.insertNewLayer(this.state.imagesToUploadLimit);
      // } else if (amount - this.state.imagesToUploadLimit < 0) {
      //   this.insertNewLayer(this.state.imagesToUploadLimit - amount);
      // }
      // TODOD: check the 100% is needed and not the default
      maxResolution = `${maxResolution} 100%`;


      maxResolution = false;

      let moreImagesThanPages = false

      let uploadedImages = await pickImages({
        amount: this.state.imagesToUploadLimit ? this.state.imagesToUploadLimit : amount,
        source,
        maxResolution,
        onTotalProgressUpdate: throttle(progressHandler, 500),
      });
      let addImages = uploadedImages

      if (this.state.imagesToUploadLimit && this.state.imagesToUploadLimit < uploadedImages.length) {
        console.log("More images than this.state.imagesToUploadLimit ...")
        console.log("images.length", uploadedImages.length)
        console.log("this.state.imagesToUploadLimit", this.state.imagesToUploadLimit)
        addImages = uploadedImages.slice(0, this.state.imagesToUploadLimit);
        moreImagesThanPages = true
      } else if (!this.state.imagesToUploadLimit && amount < uploadedImages.length) {
        console.log("More images slicing B")
        moreImagesThanPages = true
        addImages = uploadedImages.slice(0, amount);
      }
      // if (this.state.item.toJS().layers.length - 1 + images.length > maxPages) {
      //   console.log("sliced");
      //   images = images.slice(
      //     0,
      //     this.state.item.toJS().layers.length - 1 + images.length - maxPages
      //   );
      // }
      if (
        this.state.item.toJS().layers.length - 1 + this.state.imagesToUploadLimit > maxPages &&
        this.state.images.toJS().length + this.state.imagesToUploadLimit > maxPages
      ) {
        console.log("Inserting new layers...")
        const newAmount =
          this.state.imagesToUploadLimit -
          (this.state.item.toJS().layers.length - 1 + this.state.imagesToUploadLimit - maxPages);
        console.log("newAmount", newAmount)
        const newLayersToAdd = newAmount >= addImages.length ? addImages.length : newAmount
        console.log("Inserting new layers amount", newLayersToAdd)

        //this.insertNewLayer(this.state.imagesToUploadLimit);
        this.insertNewLayer(newLayersToAdd);

        console.log("Pages selected", this.state.imagesToUploadLimit)
        console.log("Image count", uploadedImages.length)

        if (uploadedImages.length > this.state.imagesToUploadLimit){
          console.log("Add multiple images to pages...")
          moreImagesThanPages = true
        }



      } else if (
        uploadedImages.length + this.state.images.toJS().length >
        this.state.item.toJS().layers.length - 1
      ) {
        console.log("Here B")
        const layersToBeInserted =
        uploadedImages.length +
          this.state.images.toJS().length -
          (this.state.item.toJS().layers.length - 1);
        console.log("layersToBeInserted", layersToBeInserted)

        console.log("Add multiple images to pages... B")
        moreImagesThanPages = true

        if (!moreImagesThanPages){
          //this.insertNewLayer(layersToBeInserted);
        } else{
          console.log("Not inserting new layers")
        }
        
      }
      // else if (amount <= 0) {
      //   this.insertNewLayer(
      //     this.state.imagesToUploadLimit = images.length
      //       ? this.state.imagesToUploadLimit
      //       : images.length
      //   ); }
      // else if (amount - this.state.imagesToUploadLimit < 0) {
      //   this.insertNewLayer(this.state.imagesToUploadLimit - amount);
      // }
      if (amount === 1) {
        if (
          this.state.replacingImageTemplateIndex !== null &&
          this.state.replacingImageIndex !== null
        ) {
          let image = uploadedImages;
          /*let cropData = null;
          if (image.originalImageInfo){
            const isPortraitImage =
              image.originalImageInfo.height > image.originalImageInfo.width;
            if (isPortraitImage) {
              let dimensionsAsPx = getMaxResolutionForDimensions({
                width: parseFloat(product.get("width")),
                height: parseFloat(product.get("height")),
                dpi: product.get("dpi"),
              });
              const PREVIEW_CROPPER_NODE_WIDTH = 500;
              let heightRatio =
                image.originalImageInfo.height / image.originalImageInfo.width;
              let transformHeight = PREVIEW_CROPPER_NODE_WIDTH * heightRatio;

              cropData = {
                x: 0,
                y: 0,
                width: dimensionsAsPx.split("x")[0],
                height: dimensionsAsPx.split("x")[1],
                rotate: 0,
                scaleX: 1,
                scaleY: 1,
                transform: {
                  containerWidth: PREVIEW_CROPPER_NODE_WIDTH,
                  containerHeight: PREVIEW_CROPPER_NODE_WIDTH,
                  width: PREVIEW_CROPPER_NODE_WIDTH,
                  height: transformHeight,
                  translateX: 0,
                  translateY: 0,
                  xPercent: 0,
                  yPercent: 0,
                },
              };
            }
          }*/
          let newImage = {
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            cropData: null, //null, cropData
          };
          this.setState(
            {
              item: this.state.item.withMutations(item => {
                return item.withMutations(item => {
                  item.update("layers", layers => {
                    return layers.map(layer => {
                      if (
                        layer.get("type") === LAYER_TYPES.PHOTO &&
                        layer.get("page") === this.state.replacingImageIndex
                      ) {
                        const template = layer.get("config").toJS().template;
                        template.placeholders.forEach(placeholder => {
                          if (placeholder.id === this.state.replacingImageTemplateIndex) {
                            placeholder.properties.imageSrc = image.highResUrl || image.lowResUrl;
                            placeholder.properties.imageData = newImage;
                          }
                        });
                        return layer.setIn(["config", "template"], template);
                      } else {
                        return layer;
                      }
                    });
                  });
                });
              }),
              reRender: !this.state.reRender,
            },
            () => {
              this.setState({
                replacingImageTemplateIndex: null,
                replacingImageIndex: null,
              });
              this.closeCropper();
              this.emitUpdate();
            }
          );
        } else if (this.state.replacingImageIndex !== null) {
          let image = uploadedImages;

          let cropData = null;
          if (image.originalImageInfo){
            const isPortraitImage =
              image.originalImageInfo.height > image.originalImageInfo.width;
            if (isPortraitImage) {
              let dimensionsAsPx = getMaxResolutionForDimensions({
                width: parseFloat(product.get("width")),
                height: parseFloat(product.get("height")),
                dpi: product.get("dpi"),
              });
              const PREVIEW_CROPPER_NODE_WIDTH = 500;
              let heightRatio =
                image.originalImageInfo.height / image.originalImageInfo.width;
              let transformHeight = PREVIEW_CROPPER_NODE_WIDTH * heightRatio;

              cropData = {
                x: 0,
                y: 0,
                width: dimensionsAsPx.split("x")[0],
                height: dimensionsAsPx.split("x")[1],
                rotate: 0,
                scaleX: 1,
                scaleY: 1,
                transform: {
                  containerWidth: PREVIEW_CROPPER_NODE_WIDTH,
                  containerHeight: PREVIEW_CROPPER_NODE_WIDTH,
                  width: PREVIEW_CROPPER_NODE_WIDTH,
                  height: transformHeight,
                  translateX: 0,
                  translateY: 0,
                  xPercent: 0,
                  yPercent: 0,
                },
              };
              console.log("cd", cropData)
            }
          }


          let newImage = {
            src: {
              lowResUrl: image.lowResUrl,
              highResUrl: image.highResUrl,
              uploadcareUuid: image.uuid,
            },
            cropData: null, //cropData,null
          };          
          this.setState(
            {
              item: getImagesMappedToItem(
                this.state.item,
                this.state.images.withMutations(images => {
                  images.set(this.state.replacingImageIndex, fromJS(newImage));
                }),
                false // need all updatedLayers!
              ),
              images: this.state.images.withMutations(images => {
                images.set(this.state.replacingImageIndex, fromJS(newImage));
              }),
            },
            () => {
              this.setState({
                replacingImageIndex: null,
              });
              // this.closeCropper();
              this.emitUpdate();
            }
          );
        } else {
          this.addImages(addImages); // TODO: Fix this hack
        }
      } else {
        if (this.state.images.size + 1 === this.state.item.toJS().layers.length){
          console.log("Here C")
          this.insertNewLayer(1);
        }
        console.log("moreImagesThanPages", moreImagesThanPages)
        if (moreImagesThanPages){
          console.log("Adding Extra Images to multi-photo templates")
          console.log("this.state.imagesToUploadLimit", this.state.imagesToUploadLimit)
          console.log("this.state.item.toJS().layers.length - 1", this.state.item.toJS().layers.length - 1)
          const pageCount = this.state.imagesToUploadLimit ? this.state.imagesToUploadLimit : this.state.item.toJS().layers.length - 1
          console.log("pageCount", pageCount)
          this.addExtraImages(uploadedImages, pageCount);          
        } else{
          console.log("Adding Images", addImages)
          this.addImages(addImages);
        }
      }
      // if (this.state.imagesToUploadLimit && images.length < this.state.imagesToUploadLimit) {
      //   this.setState({ moreOptionPageNumber: this.state.images.size });
      //   for (let i = 0; i < this.state.imagesToUploadLimit - images.length; i++) {
      //     this.handleInsertPage(null);
      //   }
      // }
      if (this.state.imagesToUploadLimit) {
        //const generatedItems = await this.generateItem(images);
        this.setState({
          imagesToUploadLimit: null,
        });
      }

      // this.setState({
      //   items: [...this.state.items, ...generatedItems],
      // });
      this.closeUploadModal();
    } catch (err) {
      console.log("Cancelling upload:", err);
    }
  };

  insertNewLayer = numberOfLayers => {
    const itemJS = this.state.item.toJS();
    const newLayers = [];
    for (let i = 0; i < numberOfLayers; i++) {
      const newDefaultLastLayer = this.state.item.toJS().defaultLastLayer;
      newDefaultLastLayer.config.border = this.state.item.toJS()?.product_options?.globalTheme;
      newDefaultLastLayer.id = newDefaultLastLayer.id + i + 1;
      newDefaultLastLayer.page = newDefaultLastLayer.page + i + 1;
      newLayers.push(newDefaultLastLayer);
    }

    const saveNewLayers = [...itemJS.layers, ...newLayers]
    console.log("newLayers", newLayers)
    console.log("saveNewLayers", saveNewLayers)
    const defaultLastLayer =  newLayers[newLayers.length - 1]
    this.setState(
      {
        item: fromJS({
          ...itemJS,
          layers: saveNewLayers,
          defaultLastLayer: defaultLastLayer,
        }),
      },
      this.emitUpdate
    );
  };

  updatePages = () => {
    //const existingPageCount = this.state.item.getIn(["product_options", "pages"]);
    const product = productsByProductId.get(this.state.item.get("productId"));
    const layersWithPhotos = this.state.images;
    const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
    const minimumPagesRequired = product.get("min_pages");
    const additionalPages = pagesAdded > minimumPagesRequired ? (pagesAdded - minimumPagesRequired) : 0
    const pageCountConfig = {
      pages: pagesAdded,
      additionalPages: additionalPages 
    }
    const existingConfig = this.state.item.getIn(["product_options"]);
    const newConfig = { ...existingConfig.toJS(), ...pageCountConfig };

    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.set("product_options", fromJS(newConfig));
          })
        }),
      }, () => {
        console.log("Pages", this.state.item.getIn(["product_options", "pages"]))
        console.log("Additional Pages", this.state.item.getIn(["product_options", "additionalPages"]))
      }
    )
  }

  distributeImages = (images, pageCount, startPage = 1) => {
    let result = {};

    if (pageCount <= 0) {
      return result; // Invalid pageCount
    }

    const imagesPerPage = Math.floor(images.length / pageCount);
    let extraImages = images.length % pageCount;

    let startIndex = 0;
    for (let i = startPage; i < startPage + pageCount; i++) {
      if (extraImages > 0 && i > 0) {
        result[i] = images.slice(startIndex, startIndex + imagesPerPage + 1);
        extraImages--;
        startIndex += imagesPerPage + 1;
      } else {
        result[i] = images.slice(startIndex, startIndex + imagesPerPage);
        startIndex += imagesPerPage;
      }
    }

    return result;
  }

  // distributeImages = (images, pageCount, startPage = 1) => {
  //   let result = {};
  
  //   if (pageCount <= 0) {
  //     console.log("Invalid page count")
  //     return result; // Invalid pageCount
  //   }
    
  //   const imagesPerPage = Math.floor(images.length / pageCount);
  //   let extraImages = images.length % pageCount;

  //   let startIndex = 0;
  //   for (let i = startPage; i < startPage + pageCount; i++) {
  //     let endIndex = startIndex + imagesPerPage + (extraImages > 0 ? 1 : 0);
  //     result[i] = images.slice(startIndex, endIndex);
  //     startIndex = endIndex;
  //     if (extraImages > 0) {
  //       extraImages--;
  //     }
  //   }
  
  //   return result;
  // }

  addExtraImages = async (images, limit, includesCover = false) => {
    console.log("Adding Extra images", images.length)
    //const totalPagesRequired = product.get("pages");
    if (!Array.isArray(images)) {
      images = [images];
    }
    console.log("limit = ", limit)

    const product = productsByProductId.get(this.state.item.get("productId"));
    const totalPagesRequired = product.get("min_pages") || 23;
    
    const pagesAdded = this.state.images.toJS().length
    console.log("pagesAdded", pagesAdded)
    const pagesRemaining = totalPagesRequired - pagesAdded;
    console.log("pagesRemaining", pagesRemaining)
    const lastPage = totalPagesRequired - pagesRemaining
    console.log("lastPage", lastPage)
    console.log("images", images)
    console.log("limit", limit)
    
    const maxImagesPerPage = 4

    if ((images.length*maxImagesPerPage) > limit){
      console.log("Images before",images)
      console.log(`More than ${maxImagesPerPage} images per page needed, slice images`)
      let max = limit*maxImagesPerPage
      images = images.slice(0, max);
      console.log("Images before",images)
    }

    const imageDistribution = this.distributeImages(images, limit, lastPage)
    console.log("imageDistribution", imageDistribution)
    const firstImagesForEachPage = Object.values(imageDistribution).map((a) => a[0])


    let newImages = this.state.images.concat(
      new List(
        fromJS(
          firstImagesForEachPage.map(image => {
            let mappedImage = {
              src: {
                lowResUrl: image.lowResUrl,
                highResUrl: image.highResUrl,
                uploadcareUuid: image.uuid,
              },
              cropData: null
            };
            return mappedImage;
          })
        )
      )
    )

    console.log("newImages", newImages)
    
    this.setState(
      {
        images: newImages
      },
      () => {
        this.emitUpdate()
        this.updatePages()
      }
    );
    
    console.log("pickTemplateWithPhotoCountForPage", images)

    const tooManyImagesCount = images.length - limit

    console.log("tooManyImagesCount", tooManyImagesCount)

    
    if(tooManyImagesCount >= limit){
      console.log("Enough for each page to use a template")
    } else{
      console.log("Only some pages need a template")
    }


    console.log("imageDistribution", imageDistribution)

    for (const page in imageDistribution) {
      if (imageDistribution.hasOwnProperty(page)) {
        const imagesCount = imageDistribution[page].length;
        //await this.pickTemplateWithPhotoCountForPage(2, 1, [images[0], images[1]])
        console.log("imagesCount", imagesCount)
        console.log("pageNumber", page)
        console.log("imageDistribution[page]", imageDistribution[page])
        await this.pickTemplateWithPhotoCountForPage(imagesCount, parseFloat(page), imageDistribution[page]);
      }
    }
  

    const res = await JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG));
    if (res && res.data && res.data.item) {
      this.setState({ item: fromJS(res.data.item) });
    }
  };


  addImages = async (images, makeBlank = false) => {
    //const totalPagesRequired = product.get("pages");
    if (!Array.isArray(images)) {
      images = [images];
    }

    let newImages = this.state.images.concat(
      new List(
        fromJS(
          images.map(image => {
            let mappedImage = {
              src: {
                lowResUrl: image.lowResUrl,
                highResUrl: image.highResUrl,
                uploadcareUuid: image.uuid,
              },
              cropData: null
            };
            return mappedImage;
          })
        )
      )
    )
  
    this.setState(
      {
        images: newImages.slice(0, this.state.item.toJS().layers.length)
      },
      () => {
        this.emitUpdate()
        this.updatePages()
      }
    );
    
    const res = await JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG));
    if (res && res.data && res.data.item) {
      this.setState({ item: fromJS(res.data.item) });
    }
  };

  debugUpload = async () => {
    const debugImages = Array(31).fill(DEBUG_UPLOAD);
    await this.addImages(debugImages);
    this.closeUploadModal();
  };

  showUploadModal = (pagesRemaining = 1, anyRecentlyUploaded = false) => {
    this.setState({ isUploadModalVisible: true }, () => {
      if (!anyRecentlyUploaded) {
        this.pickImageFromUploadcare("file", pagesRemaining);
      }
    });
  };

  closeUploadModal = () => {
    this.setState({
      isUploadModalVisible: false,
      imagesToUploadLimit: null,
    });
    if (this.state.images.size === 0) {
      this.props.onCancel();
    }
  };

  closeUploadTemplateImageModal = () => {
    this.setState({ isUploadTemplateImageModalVisible: false });
  };

  startCropping = pageNumber => {
    let targetLayer;
    this.state.item.toJS().layers.forEach(layer => {
      if (layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber) {
        targetLayer = layer;
        return;
      }
    });
    if (targetLayer && targetLayer.config.template) {
      let targetPlaceholder = targetLayer.config.template.placeholders.filter(
        placeholder => placeholder.type === "IMAGE_PLACEHOLDER"
      )[0];
      this.templateCallback(targetPlaceholder.id, pageNumber, targetLayer.config.template, true);
    } else {
      const newLayers = this.state.item.toJS().layers.map(layer => {
        if (layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber) {
          layer.isDragEnabled = false;
          return layer;
        }
        return layer;
      });
      
      let container = document.querySelector('.photo-magazine-prebag');
      let scrollToPageValue
      if (container) {
        scrollToPageValue = container.scrollTop;
      }

      this.setState(
        {
          item: this.state.item.withMutations(item => {
            return item.withMutations(item => {
              item.set("layers", fromJS(newLayers));
            });
          }),
          pageNumberToCrop: pageNumber,
          scrollToPageValue: scrollToPageValue
        },
        this.emitUpdate
      );
    }
  };

  scrollToPageNumber = async (pageNumberToCropWas, scrollToPageValueWas) => {
    //console.log("scroll to page", pageNumberToCropWas)
    const page = document.getElementById(`page-${pageNumberToCropWas}`);
    if (page) {
      //console.log("Scrolling to page...", page)
      //console.log("Scrolling to point...", scrollToPageValueWas)
      setTimeout(() => {
        let container = document.querySelector('.photo-magazine-prebag');
        if (container) {
          container.scrollTop = scrollToPageValueWas;
        }
        //page.scrollIntoView();
      }, 200);
    }
  }

  closeCropper = () => {
    const pageNumberToCropWas = this.state.pageNumberToCrop
    const scrollToPageValueWas = this.state.scrollToPageValue
    this.setState({
      pageNumberToCrop: null,
      templatePhotoEdit: null,
      scrollToPageValue: null,
    }, async () => {
      await this.scrollToPageNumber(pageNumberToCropWas, scrollToPageValueWas);
      setTimeout(() => {
        this.setState({
          savingCrop: false
        })
      }, 500);
      
    });
  };

  onSaveTemplatePhoto = (crop, pageNumber, placeholderId) => {
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.update("layers", layers => {
              return layers.map(layer => {
                if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === pageNumber) {
                  const template = layer.get("config").toJS().template;
                  template.placeholders.forEach(placeholder => {
                    if (placeholder.id === placeholderId) {
                      placeholder.properties.imageData.cropData = crop;
                    }
                  });
                  return layer.setIn(["config", "template"], template);
                } else {
                  return layer;
                }
              });
            });
          });
        }),
        reRender: !this.state.reRender,
      },
      this.emitUpdate
    );
  };

  handleSaveCrop = crop => {
    this.setState(
      {
        images: this.state.images.setIn([this.state.pageNumberToCrop, "cropData"], fromJS(crop)),
        savingCrop: true
      },
      this.emitUpdate
    );
    this.closeCropper();
  };

  handleSwapImages = (sourcePageNumber, destinationPageNumber) => {
    if (sourcePageNumber === 0 || destinationPageNumber === 0) {
      return message.error(`Cannot Swap Cover Image`);
    }
    // const source = this.state.item
    //   .get("layers")
    //   .filter(
    //     layer => layer.get("page") === sourcePageNumber && layer.get("type") === LAYER_TYPES.PHOTO
    //   )
    //   .toJS()[0];
    // const dest = this.state.item
    //   .get("layers")
    //   .filter(
    //     layer =>
    //       layer.get("page") === destinationPageNumber && layer.get("type") === LAYER_TYPES.PHOTO
    //   )
    //   .toJS()[0];
    // return;
    const sourceImage = this.state.images.get(sourcePageNumber);
    const destinationImage = this.state.images.get(destinationPageNumber);

    // if (source.config.template && dest.config.template) {
    //   this.setState(
    //     {
    //       item: this.state.item.withMutations(item => {
    //         return item.withMutations(item => {
    //           item.update("layers", layers => {
    //             return layers.map(layer => {
    //               if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === destinationPageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], source.config.template);
    //               } else if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === sourcePageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], dest.config.template);
    //               } else {
    //                 return layer;
    //               }
    //             });
    //           });
    //         });
    //       }),
    //     },
    //     this.emitUpdate
    //   );
    // } else if (source.config.template && dest.type === LAYER_TYPES.PHOTO) {
    //   this.setState(
    //     {
    //       item: this.state.item.withMutations(item => {
    //         return item.withMutations(item => {
    //           item.update("layers", layers => {
    //             return layers.map(layer => {
    //               if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === destinationPageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], source.config.template);
    //               } else if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === sourcePageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], null);
    //               } else {
    //                 return layer;
    //               }
    //             });
    //           });
    //         });
    //       }),
    //     },
    //     this.emitUpdate
    //   );
    // } else if (dest.config.template && source.type === LAYER_TYPES.PHOTO) {
    //   this.setState(
    //     {
    //       item: this.state.item.withMutations(item => {
    //         return item.withMutations(item => {
    //           item.update("layers", layers => {
    //             return layers.map(layer => {
    //               if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === sourcePageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], dest.config.template);
    //               } else if (
    //                 layer.get("type") === LAYER_TYPES.PHOTO &&
    //                 layer.get("page") === destinationPageNumber
    //               ) {
    //                 return layer.setIn(["config", "template"], null);
    //               } else {
    //                 return layer;
    //               }
    //             });
    //           });
    //         });
    //       }),
    //     },
    //     this.emitUpdate
    //   );
    // }

    this.setState(
      {
        item: getImagesMappedToItem(
          this.state.item,
          this.state.images.withMutations(images => {
            images.set(sourcePageNumber, destinationImage);
            images.set(destinationPageNumber, sourceImage);
          })
        ),
        images: this.state.images.withMutations(images => {
          images.set(sourcePageNumber, destinationImage);
          images.set(destinationPageNumber, sourceImage);
        }),
        //commented this to prevent changing of border and other config and only images
        // item: this.state.item.withMutations(item => {
        //   return item.withMutations(item => {
        //     item.update("layers", layers => {
        //       return layers.map(layer => {
        //         if (
        //           layer.get("type") === LAYER_TYPES.PHOTO &&
        //           layer.get("page") === destinationPageNumber
        //         ) {
        //           return layer.setIn(["config"], source.config);
        //         } else if (
        //           layer.get("type") === LAYER_TYPES.PHOTO &&
        //           layer.get("page") === sourcePageNumber
        //         ) {
        //           return layer.setIn(["config"], dest.config);
        //         } else {
        //           return layer;
        //         }
        //       });
        //     });
        //   });
        // }),
      },
      this.emitUpdate
    );
  };
  // handlePageReorder = (originalIndex, destinationIndex) => {
  //   const source = this.state.item
  //     .get("layers")
  //     .filter(
  //       layer => layer.get("page") == originalIndex && layer.get("type") === LAYER_TYPES.PHOTO
  //     )
  //     .toJS()[0];
  //   if (originalIndex < destinationIndex) {
  //     let newLayers = this.state.item.toJS().layers;
  //     let newImages = this.state.images.toJS();
  //     const originalIndexImage = newImages[originalIndex];
  //     let startingIndex = originalIndex + 1;
  //     while (startingIndex <= destinationIndex) {
  //       console.log(startingIndex);
  //       const startIndexLayer = newLayers.filter(
  //         layer => layer && layer.type === LAYER_TYPES.PHOTO && layer.page == startingIndex
  //       )[0];
  //       newLayers = newLayers.map(layer => {
  //         if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page == startingIndex - 1) {
  //           let newLayer = layer;
  //           newLayer.config = startIndexLayer.config;
  //           console.log(layer.config.border, startIndexLayer.config.border);
  //           return newLayer;
  //         } else {
  //           return layer;
  //         }
  //       });
  //       const startingIndexImage = newImages[startingIndex];
  //       newImages[startingIndex - 1] = startingIndexImage;
  //       startingIndex++;
  //     }
  //     newLayers = newLayers.map(layer => {
  //       if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page == destinationIndex) {
  //         let newLayer = layer;
  //         newLayer.config = source.config;
  //         console.log(layer.config.border, source.config.border);
  //         return newLayer;
  //       } else {
  //         return layer;
  //       }
  //     });
  //     console.log(newLayers);
  //     newImages[destinationIndex] = originalIndexImage;
  //     let updatedItem = this.state.item.toJS();
  //     updatedItem.layers = newLayers;

  //     this.setState({ images: fromJS(newImages), item: fromJS(updatedItem) }, this.emitUpdate);
  //   } else if (originalIndex > destinationIndex) {
  //     let newLayers = this.state.item.toJS().layers;
  //     let newImages = this.state.images.toJS();
  //     const originalIndexImage = newImages[originalIndex];
  //     let startingIndex = originalIndex - 1;
  //     while (startingIndex >= destinationIndex) {
  //       console.log(startingIndex);
  //       const startIndexLayer = newLayers.filter(
  //         layer => layer && layer.type === LAYER_TYPES.PHOTO && layer.page == startingIndex
  //       )[0];
  //       newLayers = newLayers.map(layer => {
  //         if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page == startingIndex + 1) {
  //           let newLayer = layer;
  //           newLayer.config = startIndexLayer.config;
  //           console.log(layer.config.border, startIndexLayer.config.border);
  //           return newLayer;
  //         } else {
  //           return layer;
  //         }
  //       });
  //       const startingIndexImage = newImages[startingIndex];
  //       newImages[startingIndex + 1] = startingIndexImage;
  //       startingIndex--;
  //     }
  //     newLayers = newLayers.map(layer => {
  //       if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page == destinationIndex) {
  //         let newLayer = layer;
  //         newLayer.config = source.config;
  //         console.log(layer.config.border, source.config.border);
  //         return newLayer;
  //       } else {
  //         return layer;
  //       }
  //     });
  //     console.log(newLayers);
  //     newImages[destinationIndex] = originalIndexImage;
  //     let updatedItem = this.state.item.toJS();
  //     updatedItem.layers = newLayers;

  //     this.setState({ images: fromJS(newImages), item: fromJS(updatedItem) }, this.emitUpdate);
  //   }
  // };
  handlePageReorder = (item, images) => {

    const newItemMapped = this.state.item.withMutations(itemMutate => {
      return itemMutate.withMutations(changeItem => {
        changeItem.update("layers", layers => {
          return layers.map(itemLayer => {
            const changedPosition = item.toJS().layers.find((layer) => layer.page === itemLayer.get('page'))
            if (
              changedPosition && itemLayer.get('type') === LAYER_TYPES.PHOTO
            ) {
              return itemLayer.set("config", fromJS(changedPosition.config));
            } else {
              return itemLayer;
            }
          });
        });
      });
    })
    
    this.setState(
      {
        images: images,
        item: newItemMapped,
        reRender: !this.state.reRender,
      },
      () => {
        this.emitUpdate()
      }
      
    );
  };
  handleDeleteImage = pageNumber => {
    this.deleteImage(pageNumber);
    // this.setState({
    //   alert: {
    //     type: "warning",
    //     text: "Are you sure you want to delete this page?",
    //     confirmButtonText: "Delete",
    //     onConfirm: () => {
    //       this.setState({ alert: null });
    //       this.deleteImage(pageNumber);
    //     },
    //     cancelButtonText: "Cancel",
    //     onCancel: () => {
    //       this.setState({ alert: null });
    //     },
    //   },
    // });
  };

  deleteImage = pageNumber => {
    this.setState(prev => {
      const item = prev.item.toJS();
      const layersCopy = prev.item.toJS().layers;

      for (let i = 0; i < item.layers.length; i++) {
        if (item.layers[i].page >= pageNumber) {
          // if (item.layers[i].page === pageNumber) {
          //   delete item.layers[i].config?.template;
          // }
          for (let j = 0; j < item.layers.length; j++) {
            if (layersCopy[j])
              if (item.layers[i].page === layersCopy[j].page - 1) {
                item.layers[i].config = layersCopy[j].config;
                break;
              }
          }
          if (item.layers[i].page === item.layers.length - 2) {
            console.log(item.layers[i].page);
            console.log("deleteing page template");
            if (item.layers[i].config?.template) {
              item.layers[i].config.template = null;
            }
            if (item.layers[i].config?.border) {
              item.layers[i].config.border = item.product_options.globalTheme
                ? {
                    ...item.product_options.globalTheme,
                    style: {
                      ...item.product_options.globalTheme.style,
                      showBorder: item.product_options.border,
                    },
                  }
                : {
                    style: null,
                  };
            }
            // delete item.layers[i].config?.template;
          }
        }
      }
      //deleting layers logic
      const minPages = item.minPages;
      console.log(minPages, item.layers.length - 2);

      if (item.layers.length - 2 >= minPages) {
        console.log("deleting layer", minPages, item.layers.length - 2);
        if (item.defaultLastLayer){
          item.defaultLastLayer.id = item.defaultLastLayer.id - 1;
          item.defaultLastLayer.page = item.defaultLastLayer.page - 1;
        }      
        item.layers = item.layers.filter(layer => layer.page !== item.layers.length - 2);
      }
      return {
        images: this.state.images.delete(pageNumber),
        item: fromJS(item),
        reRender: !this.state.reRender,
      };
    }, () => {
      this.emitUpdate()
      this.updatePages()
    });
  };

  handleCoverTextModalAutoShown = () => {
    this.setState({
      coverTextModalHasBeenAutoShown: true,
    });
  };

  borderConfigForColour = (colour, category = "PASTEL") => {
    const item = this.state.item.toJS()
    const isLandscape = item.productDimensions.width > item.productDimensions.height
    console.log("isLandscape", isLandscape)
    return {
      style: {
        type: "color",
        color: colour,
        showBorder: true,
        category: category,
      },
      thickness: isLandscape ? DEFAULT_LANDSCAPE_BORDER_THICKNESS : DEFAULT_SQUARE_BORDER_THICKNESS,
    };
  };
  borderConfigForTexture = (texture, category = "TEXTURES") => {
    const item = this.state.item.toJS()
    const isLandscape = item.productDimensions.width > item.productDimensions.height
    return {
      style: {
        type: "texture",
        texture: texture,
        showBorder: true,
        category: category,
      },
      thickness: isLandscape ? DEFAULT_LANDSCAPE_BORDER_THICKNESS : DEFAULT_SQUARE_BORDER_THICKNESS,
    };
  };

  setBorderColor = (
    colour,
    pageNumber,
    isTexture = false,
    category,
    textColor = null,
    setBorderToPages = false
  ) => {
    const item = this.state.item.toJS();
    let borderConfig = {};
    if (!isTexture) {
      borderConfig = this.borderConfigForColour(colour, category);
    } else {
      borderConfig = this.borderConfigForTexture(colour, category);
    }

    if (typeof pageNumber === "number") {
      
      item.layers.map(layer => {
        if (layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber) {
          // const ly = layer.toJS();
          const isBorderApplied = layer.config?.border?.style?.showBorder;
          // if (!isBorderApplied && !layer.config.template) {
          //   item.product_options.border = true;
          // }
          layer.config.border = borderConfig;
          // layer.config.borderPriority = true;
          //dont show border if template is applied
          if (!isBorderApplied && layer.config.template) {
            layer.config.border.style.showBorder = false;
          }
          if (!isBorderApplied) {
            layer.config.border.style.showBorder = isBorderApplied;
          }
          if (layer.config.template && !isTexture) {
            layer.config.template.canvasColor = colour;
            layer.config.template.fontColor = textColor;
            layer.config.template.canvasTexture = null;
          }
          if (layer.config.template && isTexture) {
            layer.config.template.canvasTexture = colour;
            layer.config.template.canvasColor = null;
            layer.config.template.fontColor = textColor;
          }
          return layer;
        } else {
          return layer;
        } 
      });

      if (pageNumber === 0){
        item.product_options.coverColour = colour;
      }

      this.setState(
        {
          item: fromJS(item),
          reRender: !this.state.reRender,
        },
        this.emitUpdate
      );
    } else {
      const item = this.state.item.toJS();
      if (setBorderToPages) {
        item.product_options.border = true;
      }
      item.product_options.globalTheme = borderConfig;
      item.product_options.coverColour = colour;
      item.layers.map(layer => {
        if (
          layer.type === LAYER_TYPES.PHOTO &&
          !layer.config.borderPriority &&
          !layer.config.border.style?.priority
        ) {
          // const ly = layer.toJS();
          let showBorder = layer.config?.border?.style?.showBorder;
          // item.product_options.border = true;
          if (setBorderToPages) {
            showBorder = setBorderToPages;
          }
          layer.config.border = {
            ...borderConfig,
            style: { ...borderConfig.style, showBorder: showBorder },
          };
          if (layer.config.template && !isTexture) {
            layer.config.template.canvasColor = colour;
            layer.config.template.fontColor = textColor;
            layer.config.template.canvasTexture = null;
          }
          if (layer.config.template && isTexture) {
            layer.config.template.canvasTexture = colour;
            layer.config.template.canvasColor = null;
            layer.config.template.fontColor = textColor;
          }
          return layer;
        } else {
          return layer;
        }
      });
      this.setState(
        {
          item: fromJS(item),
          reRender: !this.state.reRender,
        },
        this.emitUpdate
      );
    }
  };

  setThemeToTemplate = color => {
    const borderConfig = this.borderConfigForColour(color);
    const tempTemplate = this.state.template;
    tempTemplate.canvasColor = color;
    alert("hey");
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.update("layers", layers => {
              return layers.map(layer => {
                if (
                  layer.get("type") === LAYER_TYPES.PHOTO &&
                  layer.get("page") === this.state.addTemplateToPageNumber
                ) {
                  return layer.setIn(["config", "border"], borderConfig);
                } else {
                  return layer;
                }
              });
            });
          });
        }),
        template: tempTemplate,
        reRender: !this.state.reRender,
      },
      this.emitUpdate
    );
  };

  handleChangeCoverText = coverConfig => {
    const existingConfig = this.state.item.getIn(["product_options"]);
    const newConfig = { ...existingConfig.toJS(), ...coverConfig };

    const hasBorder = this.state.item.getIn(["product_options", "border"]);
    const borderConfig = hasBorder
      ? this.borderConfigForColour(coverConfig.coverColour.backgroundColour)
      : NO_BORDER_CONFIG;

    this.setState({
      item: this.state.item.withMutations(item => {
        return item.withMutations(item => {
          item.set("product_options", fromJS(newConfig));
          item.update("layers", layers => {
            return layers.map(layer => {
              if (layer.get("type") === LAYER_TYPES.PHOTO) {
                return layer.setIn(["config", "border"], fromJS(borderConfig));
              } else {
                return layer;
              }
            });
          });
        });
      }),
    });
  };

  distanceFromLeft = point => {
    const canvasSize = this.productType === "square" ? { width: 500 } : { width: 500 };
    return canvasSize.width * point[0];
  };

  distanceFromTop = point => {
    const canvasSize = this.productType === "square" ? { height: 500 } : { height: 369 };

    return canvasSize.height * point[1];
  };

  handleSaveTemplate = (template, pageNumber) => {
    const newTemplate = {};
    const copyTemplate = JSON.parse(JSON.stringify(template));
    if (this.state.uploadingFirstTime) {
      Object.keys(template).forEach(key => (newTemplate[key] = template[key]));
    } else {
      Object.keys(template).forEach(key => (newTemplate[key] = copyTemplate[key]));
    }
    const item = this.state.item.toJS();
    // let newImages = this.state.images.toJS();
    item.layers.forEach(layer => {
      if (layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber) {
        const oldTemplate = layer.config.template;
        if (oldTemplate) {
          let oldText = oldTemplate.placeholders
            .map(placeholder =>
              placeholder.type === "TEXT_PLACEHOLDER"
                ? {
                    properties: placeholder.properties,
                  }
                : null
            )
            .filter(text => text !== null);
          if (oldText.length === 0) {
            oldText = layer.config?.textPlaceholders || [];
          }

          const allImages = oldTemplate.placeholders
            .map(placeholder => ({
              imageSrc: placeholder.properties.imageSrc,
              imageData: placeholder.properties.imageData,
            }))
            .filter(image => image.imageData);
          let counter = 0;
          //this is retaining old images
          // const arrayToBeUsed =
          //   layer.config.allImages?.length > allImages.length ? layer.config.allImages : allImages;
          const arrayToBeUsed = allImages;
          layer.config.allImages = allImages;
          for (let i = 0; i < newTemplate.placeholders.length; i++) {
            if (
              newTemplate.placeholders[i].type === "IMAGE_PLACEHOLDER" &&
              arrayToBeUsed[counter]
            ) {
              newTemplate.placeholders[i].properties.imageSrc =
                arrayToBeUsed[counter].imageData.src.highResUrl ||
                arrayToBeUsed[counter].imageData.src.lowResUrl;
              newTemplate.placeholders[i].properties.imageData = arrayToBeUsed[counter].imageData;
              if (newTemplate.placeholders[i].properties.imageData?.cropData) {
                newTemplate.placeholders[i].properties.imageData.cropData = null;
              }
              counter++;
            }
          }

          let counter1 = 0;
          for (let i = 0; i < newTemplate.placeholders.length; i++) {
            if (
              newTemplate.placeholders[i].type === "TEXT_PLACEHOLDER" &&
              oldText[counter1]?.properties
            ) {
              //we need to change wordScale and retain other properties
              const wordScale = newTemplate.placeholders[i].properties.wordScale;
              let fontSize;
              if (pageNumber === 0) {
                fontSize = newTemplate.placeholders[i].properties.fontSize;
              }

              const newPlaceholderProperties = newTemplate.placeholders[i].properties

              newTemplate.placeholders[i].properties = oldText[counter1].properties;
              if (pageNumber !== 0) {
                // const newFontSize = oldText[counter1].properties.fontSize || 28;
                // const multiplier = newFontSize < 24 ? (newFontSize == 12 ? 2 : 1.38) : 1;
                // let maxlength = DEFAULT_MAX_LENGTH;

                // maxlength = parseInt(maxlength * (28 / newFontSize) * multiplier, 10);
                // if (wordScale) {
                //   maxlength = parseInt(maxlength * wordScale, 10);
                // }

                let removeContent = false

                const newTemplateProperties = newTemplate.placeholders[i]
                const oldTemplateProperties = oldTemplate.placeholders.find(placeholder => placeholder.type === "TEXT_PLACEHOLDER") //oldTemplate.placeholders[i]
                if (oldTemplateProperties && newTemplateProperties){
                  const newTemplateWidth = newTemplateProperties.position.topRight[0] - newTemplateProperties.position.topLeft[0]
                  const newTemplateHeight = newTemplateProperties.position.bottomRight[1] - newTemplateProperties.position.topRight[1]
                  const isNewTemplatePortrait = newTemplateHeight > newTemplateWidth
                  //const isNewTemplateLandscape = !isNewTemplatePortrait
                  //console.log("isNewTemplatePortrait", isNewTemplatePortrait)

                  const oldTemplateWidth = oldTemplateProperties.position.topRight[0] - oldTemplateProperties.position.topLeft[0]
                  const oldTemplateHeight = oldTemplateProperties.position.bottomRight[1] - oldTemplateProperties.position.topRight[1]
                  const isOldTemplatePortrait = oldTemplateHeight > oldTemplateWidth
                  const isOldTemplateLandscape = !isOldTemplatePortrait
                  console.log("isOldTemplatePortrait", isOldTemplatePortrait)

                  const isHeightReduced = newTemplateHeight < oldTemplateHeight
                  const isWidthReduced = newTemplateWidth < oldTemplateWidth

                  

                  // If going from landscape to portrait ... lose text
                  if (isOldTemplateLandscape && isNewTemplatePortrait){
                    console.log("Going from a landscape to portrait - removing text")
                    removeContent = true
                  }

                  if (isHeightReduced || isWidthReduced){
                    console.log("Height or text reduced - removeing content")
                    removeContent = true
                  }
                
                }

                let content = oldText[counter1].properties.content;
                if (removeContent) {
                  newTemplate.placeholders[i].properties.previousContent = content
                  content = newPlaceholderProperties.content_placeholder; // or ""?
                }

                // if (content.length > maxlength) {
                //   content = content.slice(0, maxlength);
                // }

                //TODO: Ensure text fitsin new template, if not shrink to fit.

                newTemplate.placeholders[i].properties.content = content;
                newTemplate.placeholders[i].properties.content_placeholder = newPlaceholderProperties.content_placeholder;
              }
              newTemplate.placeholders[i].properties.wordScale = wordScale;
              newTemplate.placeholders[i].properties.subTextMargin = newPlaceholderProperties.subTextMargin
              
              if (fontSize) {
                newTemplate.placeholders[i].properties.fontSize = fontSize;
                newTemplate.placeholders[i].properties.subTextFontSize = fontSize * 0.6;
                console.log("fontSize",fontSize)
                console.log("fontSize * 0.6",fontSize * 0.6)
              }
              counter1++;
            }
          }
          if (counter1 === 0) {
            layer.config.textPlaceholders = oldText;
          }
        } else {
          for (let i = 0; i < newTemplate.placeholders.length; i++) {
            if (
              newTemplate.placeholders[i].type === "IMAGE_PLACEHOLDER" &&
              layer.config.layout[0].image
            ) {
              newTemplate.placeholders[i].properties.imageSrc =
                layer.config.layout[0].image.src.highResUrl ||
                layer.config.layout[0].image.src.lowResUrl;
              newTemplate.placeholders[i].properties.imageData = layer.config.layout[0].image;
              newTemplate.placeholders[i].properties.imageData = layer.config.layout[0].image;
              //changed this to prevent photo distortion
              if (newTemplate.placeholders[i].properties.imageData?.cropData) {
                // TODO, may need to do this only for the cover and for the first time?
                newTemplate.placeholders[i].properties.imageData.cropData = null;
              }
              break;
            }
          }
        }

        let color = null;
        let texture = null;
        let fontColor = null;

        if (layer.config.template && layer.config.template.canvasColor) {
          color = layer.config.template.canvasColor;
          const selectedTheme = colours.filter(theme => theme.hex === color);
          if (selectedTheme[0] && selectedTheme[0].textColor) {
            if (color === "#FFFFFF") {
              fontColor = "#000000";
            } else {
              fontColor = selectedTheme[0].textColor;
            }
          }
        } else if (
          layer.config.border.style &&
          layer.config.border.style.color &&
          layer.config.border.style.type === "color"
        ) {
          color = layer.config.border.style.color;
          //setting font Color from pastel border color
          const selectedTheme = colours.filter(theme => theme.hex === color);
          if (selectedTheme[0] && selectedTheme[0].textColor) {
            fontColor = selectedTheme[0].textColor;
          }
        }
        if (layer.config.template && layer.config.template.canvasTexture) {
          texture = layer.config.template.canvasTexture;
        } else if (
          layer.config.border.style &&
          layer.config.border.style.texture &&
          layer.config.border.style.type === "texture"
        ) {
          texture = layer.config.border.style.texture;
        }
        layer.config.template = newTemplate;
        if (template.name !== "single-image-default-template") {
          layer.config.border = {
            ...layer?.config?.border,
            style: { ...layer.config?.border?.style, showBorder: false },
          }; //no borders if template applied
        }
        if (texture) {
          layer.config.template.canvasTexture = texture;
        } else if (color) {
          layer.config.template.canvasColor = color;
        }
        if (fontColor) {
          layer.config.template.fontColor = fontColor;
        }
        // const doesPhotoExistOnLayout =
        //   layer.config?.layout[0]?.image?.src?.highResUrl ||
        //   layer.config?.layout[0]?.image?.src?.lowResUrl;
        // if (!doesPhotoExistOnLayout) {
        //   const newImage = layer?.config?.allImages[0]?.imageData;
        //   layer.config.layout[0].image = newImage;
        //   newImages[layer.page] = newImage;
        // }
      }
    });

    this.setState(
      {
        // item: getImagesMappedToItem(fromJS(item), fromJS(newImages)),
        item: fromJS(item),
        isTemplateModalOpen: false,
        reRender: !this.state.reRender,
        // images: fromJS(newImages),
        // addTemplateToPageNumber: null,
      },
      this.emitUpdate
    );
  };

  handleTemplateSelect = (template, pageNumber) => {
    const newTemplate = {};
    Object.keys(template).forEach(key => (newTemplate[key] = template[key]));

    const layer = this.state.item
      .get("layers")
      .filter(layer => layer.get("page") === pageNumber && layer.get("type") === LAYER_TYPES.PHOTO)
      .toJS()[0];
    const placeholders = newTemplate.placeholders;
    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i].type === "IMAGE_PLACEHOLDER") {
        placeholders[i].properties.imageSrc =
          layer.config.layout[0].image.src.highResUrl || layer.config.layout[0].image.src.lowResUrl;
        break;
      }
    }

    this.setState(
      {
        addTemplateToPageNumber: pageNumber,
        template: newTemplate,
      },
      () => {
        setTimeout(() => {
          this.setState({ isTemplateModalOpen: true });
        }, 400);
      }
    );
  };

  setPageNumberToSelectTemplate = pageNumber => {
    this.setState({ addTemplateToPageNumber: pageNumber });
  };

  handleSaveAddress = address => {
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          item.set("addressBookId", null);
          item.set("addressBookEntry", null);
          item.set("address", fromJS(address));
        }),
      },
      this.saveItem
    );
  };
  setGlobalTextFont = font => {
    this.setState(
      {
        item: this.state.item.setIn(["product_options", "fontFamily"], font),
        reRender: !this.state.reRender,
      },
      this.emitUpdate
    );
  };
  enableScrolling = () => {
    const mainContent = document.getElementById("maincontent");
    mainContent.style.overflowY = "scroll";
    document.documentElement.style.overflow = "scroll";
    document.body.scroll = "scroll";
  };
  swapImageFromTemplate = (page, placeholderId, popup, target, imageData) => {
    if (typeof page === "number") {
      var targetTouches;
      const mouseUpEvent = e => {
        let targetElement = e.target;
        if (e.type === "touchend") {
          console.log(targetTouches);
          if (targetTouches) {
            targetElement = document.elementFromPoint(
              targetTouches[0].clientX,
              targetTouches[0].clientY
            );
          }
          // console.log(element);
          console.log(targetElement);
          // return;
        }
        if (targetElement && targetElement.tagName === "IMG") {
          targetElement = targetElement.parentNode;
        }
        if (e.type === "touchmove") {
          const origin = e.srcElement;
          if (!origin.getAttribute("data-placeholder-id")) {
            if (document.body.contains(popup)) {
              document.body.removeChild(popup);
            }
          }
          targetTouches = e.targetTouches;
          return;
        }
        this.enableScrolling();
        if (Number(targetElement?.getAttribute("data-placeholder-id"))) {
          const targetImage = this.state.item
            .toJS()
            .layers.filter(
              layer =>
                layer.type === LAYER_TYPES.PHOTO &&
                layer.page === Number(targetElement?.getAttribute("data-page"))
            )[0]
            .config.template.placeholders.filter(
              placeholder => placeholder.id === targetElement?.getAttribute("data-placeholder-id")
            )[0].properties.imageData;
          if (page === targetElement.getAttribute("data-page")) {
            this.setState(
              {
                item: this.state.item.withMutations(item => {
                  return item.withMutations(item => {
                    item.update("layers", layers => {
                      return layers.map(layer => {
                        if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === page) {
                          const template = layer.toJS().config.template;
                          template.placeholders.forEach(placeholder => {
                            if (placeholder.id === placeholderId) {
                              if (targetImage) {
                                placeholder.properties.imageData = targetImage;
                                placeholder.properties.imageData.cropData = null;
                                placeholder.properties.imageSrc = targetImage.src.highResUrl;
                              } else {
                                placeholder.properties.imageData = null;
                                placeholder.properties.imageSrc = null;
                              }
                            } else if (
                              placeholder.id === targetElement?.getAttribute("data-placeholder-id")
                            ) {
                              placeholder.properties.imageData = imageData;
                              placeholder.properties.imageData.cropData = null;
                              // placeholder.properties.imageData = imageData;
                              placeholder.properties.imageSrc = imageData.src.highResUrl;
                            }
                          });
                          return layer.setIn(["config", "template"], fromJS(template));
                        } else {
                          return layer;
                        }
                      });
                    });
                  });
                }),
                reRender: !this.state.reRender,
              },
              this.emitUpdate
            );
          } else {
            this.setState(
              {
                item: this.state.item.withMutations(item => {
                  return item.withMutations(item => {
                    item.update("layers", layers => {
                      return layers.map(layer => {
                        if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === page) {
                          const template = layer.toJS().config.template;
                          template.placeholders.forEach(placeholder => {
                            if (placeholder.id === placeholderId) {
                              if (targetImage) {
                                placeholder.properties.imageData = targetImage;
                                placeholder.properties.imageData.cropData = null;
                                placeholder.properties.imageSrc = targetImage.src.highResUrl;
                              } else {
                                placeholder.properties.imageData = null;
                                placeholder.properties.imageSrc = null;
                              }
                            } else if (
                              placeholder.id === targetElement?.getAttribute("data-placeholder-id")
                            ) {
                              placeholder.properties.imageData = imageData;
                              placeholder.properties.imageData.cropData = null;
                              // placeholder.properties.imageData = imageData;
                              placeholder.properties.imageSrc = imageData.src.highResUrl;
                            }
                          });
                          return layer.setIn(["config", "template"], fromJS(template));
                        } else if (
                          layer.get("type") === LAYER_TYPES.PHOTO &&
                          layer.get("page") === Number(targetElement?.getAttribute("data-page"))
                        ) {
                          const template = layer.toJS().config.template;
                          template.placeholders.forEach(placeholder => {
                            if (
                              placeholder.id === targetElement?.getAttribute("data-placeholder-id")
                            ) {
                              // placeholder.properties.imageData = imageData;
                              placeholder.properties.imageData = imageData;
                              placeholder.properties.imageData.cropData = null;
                              placeholder.properties.imageSrc = imageData.src.highResUrl;
                            }
                          });
                          return layer.setIn(["config", "template"], fromJS(template));
                        } else {
                          return layer;
                        }
                      });
                    });
                  });
                }),
                reRender: !this.state.reRender,
              },
              this.emitUpdate
            );
          }
        } else if (Number(targetElement?.getAttribute("data-page"))) {
          console.log("dragging image across pages")
          /*const images = this.state.images.toJS();
          const savedImage = images[Number(targetElement.getAttribute("data-page"))];
          images[Number(targetElement?.getAttribute("data-page"))] = imageData;
          this.setState(
            {
              images: fromJS(images),
              item: getImagesMappedToItem(
                this.state.item.withMutations(item => {
                  return item.withMutations(item => {
                    item.update("layers", layers => {
                      return layers.map(layer => {
                        if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === page) {
                          const template = layer.toJS().config.template;
                          template.placeholders.forEach(placeholder => {
                            if (placeholder.id === placeholderId) {
                              if (savedImage) {
                                placeholder.properties.imageData = savedImage;
                                placeholder.properties.imageData.cropData = null;
                                placeholder.properties.imageSrc = savedImage.src.highResUrl;
                              } else {
                                placeholder.properties.imageData = null;
                                placeholder.properties.imageSrc = null;
                              }
                            }
                          });
                          return layer.setIn(["config", "template"], fromJS(template));
                        } else {
                          return layer;
                        }
                      });
                    });
                  });
                }),
                fromJS(images)
              ),
              reRender: !this.state.reRender,
            },
            this.emitUpdate
          );*/
        } else {
          this.setState({
            reRender: !this.state.reRender,
          });
        }
        target.style.opacity = "1";
        if (document.body.contains(popup)) {
          document.body.removeChild(popup);
        }
        document.body.removeEventListener("mouseup", mouseUpEvent);
        document.body.removeEventListener("touchend", mouseUpEvent);
        document.body.removeEventListener("touchmove", mouseUpEvent);
      };
      document.body.addEventListener("mouseup", mouseUpEvent);
      document.body.addEventListener("touchend", mouseUpEvent);
      document.body.addEventListener("touchmove", mouseUpEvent);
    }
  };

  handleImageSwapStart = (page, target, popup) => {
    let targetTouches;
    popup.style.backgroundImage = `URL(${this.state.images.toJS()[page].src.highResUrl})`;
    if (page) {
      const mouseUpEvent = e => {
        // console.log(e);
        let targetElement = e.target;
        this.enableScrolling();

        if (e.type === "touchend") {
          // console.log(targetElement);
          console.log(targetTouches);
          targetElement = document.elementFromPoint(
            targetTouches[0].clientX,
            targetTouches[0].clientY
          );
          // console.log(element);
          console.log(targetElement);
        }
        console.log(targetElement);
        if (targetElement && targetElement.tagName === "IMG") {
          targetElement = targetElement.parentNode;
        }

        if (e.type === "touchmove") {
          targetTouches = e.targetTouches;
          // console.log(targetTouches);
          return;
        }
        // const targetElement = e.target;
        if (Number(targetElement?.getAttribute("data-placeholder-id"))) {
          const images = this.state.images.toJS();
          const templateImage = this.state.item
            .toJS()
            .layers.filter(
              layer =>
                layer.type === LAYER_TYPES.PHOTO &&
                layer.page === Number(targetElement?.getAttribute("data-page"))
            )[0]
            .config.template.placeholders.filter(
              placeholder => placeholder.id === targetElement?.getAttribute("data-placeholder-id")
            )[0].properties.imageData;
          const savedImage = images[page];
          if (templateImage) {
            images[page] = templateImage;
          } else {
            const defaultTemplate = this.getPageTemplatesForProduct().filter(
              template => template.name === "single-image-default-template"
            )[0]
              // this.state.item.toJS().productDimensions.width >
              // this.state.item.toJS().productDimensions.height
              //   ? landscapeTemplates.filter(
              //       template => template.name === "single-image-default-template"
              //     )[0]
              //   : regularTemplates.filter(
              //       template => template.name === "single-image-default-template"
              //     )[0];

            this.setState(
              {
                images: fromJS(images),
                item: this.state.item.withMutations(item => {
                  return item.withMutations(item => {
                    item.update("layers", layers => {
                      return layers.map(layer => {
                        if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === page) {
                          defaultTemplate.canvasColor = layer.toJS().config.border?.style?.color;
                          return layer.setIn(["config", "template"], defaultTemplate);
                        } else if (
                          layer.get("type") === LAYER_TYPES.PHOTO &&
                          layer.get("page") === Number(targetElement?.getAttribute("data-page"))
                        ) {
                          const template = layer.toJS().config.template;
                          template.placeholders.forEach(placeholder => {
                            if (
                              placeholder.id === targetElement?.getAttribute("data-placeholder-id")
                            ) {
                              placeholder.properties.imageData = savedImage;
                              placeholder.properties.imageData.cropData = null;
                              placeholder.properties.imageSrc = savedImage.src.highResUrl;
                            }
                          });
                          return layer.setIn(["config", "template"], template);
                        } else {
                          return layer;
                        }
                      });
                    });
                  });
                }),
                reRender: !this.state.reRender,
              },
              this.emitUpdate
            );
            target.style.opacity = "1";
            document.body.removeChild(popup);
            document.body.removeEventListener("mouseup", mouseUpEvent);
            document.body.removeEventListener("touchend", mouseUpEvent);
            document.body.removeEventListener("touchmove", mouseUpEvent);
            return;
          }
          this.setState(
            {
              images: fromJS(images),
              item: getImagesMappedToItem(
                this.state.item.withMutations(item => {
                  return item.withMutations(item => {
                    item.update("layers", layers => {
                      return layers.map(layer => {
                        if (
                          layer.get("type") === LAYER_TYPES.PHOTO &&
                          layer.get("page") === Number(targetElement?.getAttribute("data-page"))
                        ) {
                          const template = layer.get("config").toJS().template;
                          template.placeholders.forEach(placeholder => {
                            if (
                              placeholder.id === targetElement?.getAttribute("data-placeholder-id")
                            ) {
                              placeholder.properties.imageData = savedImage;
                              placeholder.properties.imageData.cropData = null;
                              placeholder.properties.imageSrc = savedImage?.src?.highResUrl;
                            }
                          });
                          return layer.setIn(["config", "template"], template);
                        } else {
                          return layer;
                        }
                      });
                    });
                  });
                }),
                fromJS(images)
              ),
              reRender: !this.state.reRender,
            },
            this.emitUpdate
          );
        } else {
          if (
            targetElement?.getAttribute("data-page") &&
            page &&
            Number(targetElement?.getAttribute("data-page")) !== page
          ) {
            console.log("handle swap images");
            this.handleSwapImages(page, Number(targetElement?.getAttribute("data-page")));
          }
        }
        target.style.opacity = "1";
        document.body.removeChild(popup);
        document.body.removeEventListener("mouseup", mouseUpEvent);
        document.body.removeEventListener("touchend", mouseUpEvent);
        document.body.removeEventListener("touchmove", mouseUpEvent);
      };
      // document.body.addEventListener("pointerup", mouseUpEvent);
      document.body.addEventListener("touchend", mouseUpEvent);
      document.body.addEventListener("touchmove", mouseUpEvent);
      document.body.addEventListener("mouseup", mouseUpEvent);
    }
  };

  handleTemplateImageSize = (pageNumber, placeholderId, imageSize) => {
    this.setState({
      item: this.state.item.withMutations(item => {
        return item.withMutations(item => {
          item.update("layers", layers => {
            return layers.map(layer => {
              if (layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === pageNumber) {
                const template = layer.get("config").toJS().template;
                template.placeholders.forEach(placeholder => {
                  if (placeholder.id === placeholderId) {
                    placeholder.properties.imageSize = imageSize;
                  }
                });
                return layer.setIn(["config", "template"], template);
              } else {
                return layer;
              }
            });
          });
        });
      }),
    });
  };

  handleOwnAddressSave = address => {
    return this.props.onChangeOwnAddress(address);
  };

  handleSelectAddressBookEntry = (addressBookEntryId, entry) => {
    //console.log("Saving address book entry ID:", addressBookEntryId);
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          item.set("addressBookId", addressBookEntryId);
          item.set("addressBookEntry", fromJS(entry));
          item.set("address", null);
        }),
      },
      this.saveItem
    );
  };

  handleClickSave = () => {
    if (this.allTemplatesHavePhoto()){
      this.props.onSave(getImagesMappedToItem(this.state.item, this.state.images));
    } else {
      message.error("You need to add photos to each of your page layouts");
    }
  };

  handleReplaceImage = index => {
    this.setState({
      pageNumberToCrop: null,
      isUploadModalVisible: true,
      isReplacingImage: true,
      replacingImageIndex: index,
    });
  };

  onHandleReplaceImageInTemplate = () => {
    this.setState({
      pageNumberToCrop: null,
      isUploadModalVisible: true,
      isReplacingImage: true,
      replacingImageIndex: this.state.templatePhotoEdit.pageNumber,
      replacingImageTemplateIndex: this.state.templatePhotoEdit.placeholderId,
    });
  };

  allTemplatesHavePhoto = () => {
    const allTemplatesUsed = this.state.item
      .toJS()
      .layers.filter(layer => layer.config.template)
      .map(layer => layer.config.template.placeholders);
    for (let i = 0; i < allTemplatesUsed.length; i++) {
      for (let j = 0; j < allTemplatesUsed[i].length; j++) {
        if (allTemplatesUsed[i][j].type === "IMAGE_PLACEHOLDER") {
          if (!allTemplatesUsed[i][j].properties.imageSrc) {
            return false;
          }
        }
      }
    }
    return true;
  };

  handleMakeBlank = () => {
    console.log("moreOptionPageNumber", this.state.moreOptionPageNumber);

    const blankTemplate = this.getPageTemplatesForProduct().filter(template => template.name === "blank")[0]
      // this.state.item.toJS().productDimensions.width >
      // this.state.item.toJS().productDimensions.height
      //   ? landscapeTemplates.filter(template => template.name === "blank")[0]
      //   : regularTemplates.filter(template => template.name === "blank")[0];

    let defaultBlankTemplate = JSON.parse(JSON.stringify(blankTemplate));
    this.handleSaveTemplate(defaultBlankTemplate, this.state.moreOptionPageNumber);
  };


  // handleFillRestBlank = (pagesRemaining) => {
  //   const product = productsByProductId.get(this.state.item.get("productId"));
  //   const totalPagesRequired = product.get("min_pages") || 23;
  //   const layersWithPhotos =
  //     this.state.item &&
  //     this.state.item
  //       .get("layers")
  //       .filter(
  //         layer =>
  //           layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
  //       )
  //       .sortBy(layer => layer.get("page"));
    
  //   const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
  //   const myPagesRemaining = totalPagesRequired - pagesAdded;
  //   console.log("pages added", pagesAdded)
  //   console.log("pagesRemaing", myPagesRemaining)
  //   console.log("pagesRemaing", pagesRemaining)
    
  //   if (
  //     pagesRemaining > 0
  //   ) {
  //     //this.handleSaveTemplate(defaultBlankTemplate, pageToMakeBlank);
  //     const lastPage = totalPagesRequired-pagesRemaining
  //     // console.log("pagesNeededToFill", pagesRemaining)
  //     // console.log("lastPage", totalPagesRequired-pagesRemaining)
    
  //     const blankTemplate =
  //     this.state.item.toJS().productDimensions.width >
  //     this.state.item.toJS().productDimensions.height
  //       ? landscapeTemplates.filter(template => template.name === "blank")[0]
  //       : regularTemplates.filter(template => template.name === "blank")[0];

  //     let defaultBlankTemplate = JSON.parse(JSON.stringify(blankTemplate));
      
  //     for (let i = 0; i < pagesRemaining; i++) {

  //       console.log("lastPage", lastPage)
  //       const pageToMakeBlank = lastPage+i
  //       console.log("Making page blank", pageToMakeBlank)
  //       debugger;
  //       // this.setState({moreOptionPageNumber: pageToMakeBlank-1}, () => {
  //       //   console.log("moreOptionPageNumber", this.state.moreOptionPageNumber)
  //       //   debugger;
  //       this.handleInsertPage(null, pageToMakeBlank-1);
  //       this.handleSaveTemplate(defaultBlankTemplate, pageToMakeBlank);
  //       //})
        
  //     }

  //   }
  // };


  getPageTemplatesForProduct = () => {
    const width = this.state.item.toJS().productDimensions.width
    const height = this.state.item.toJS().productDimensions.height
    
    let pageTemplates;

    if (width === height){
      pageTemplates = pageTemplatesSquare
    } else if (width > height) {
      pageTemplates = pageTemplatesLandscape
    } else if (width < height){
      pageTemplates = pageTemplatesPortrait
    }
    return pageTemplates
  }

  pickTemplateWithPhotoCountForPage = async (photoCount = 2, pageNumber, images = []) => {
       
    console.log("photoCount", photoCount)
    console.log("pageNumber", pageNumber)

    const chosenTemplates = this.getPageTemplatesForProduct().filter(template => {
      let imagePlaceholderCount = template.placeholders.filter((p) => p.type === "IMAGE_PLACEHOLDER").length
      if (imagePlaceholderCount === photoCount) {
        return template;
      }
      // eslint-disable-next-line
      return;
    })

    console.log("chosenTemplates", chosenTemplates)

    const chosenTemplate = chosenTemplates[0]
    if(chosenTemplate){
      console.log("images", images)

      const newTemplate = {};
      const copyTemplate = JSON.parse(JSON.stringify(chosenTemplate));
      Object.keys(chosenTemplate).forEach(key => (newTemplate[key] = copyTemplate[key]));

      const placeholders = newTemplate.placeholders;
      for (let i = 0; i < placeholders.length; i++) {
        if (placeholders[i].type === "IMAGE_PLACEHOLDER") {

          const imageData = {
            src: {
              highResUrl: images[i].highResUrl,
              lowResUrl: images[i].lowResUrl,
              uuid: images[i].uuid
            },
            cropData: null
          }

          placeholders[i].properties.imageSrc = images[i].highResUrl
          placeholders[i].properties.imageData = imageData
          //placeholders[i].properties.imageData = stateImages.find(image => image.src.uploadcareUuid == images[i].uuid)
        }
      }
      console.log("newTemplate", newTemplate)
      console.log("chosenTemplate", chosenTemplate)
      await this.handleSaveTemplate(newTemplate, pageNumber);

    } else{
      console.log("No template found")
    }
    
  }


  handleFillRestBlank = async (pagesRemaining) => {
    const product = productsByProductId.get(this.state.item.get("productId"));
    const totalPagesRequired = product.get("min_pages") || 23;
    const lastPage = totalPagesRequired-pagesRemaining

    const debugImages = Array(pagesRemaining).fill(DEBUG_UPLOAD);
    //this.insertNewLayer(pagesRemaining);
    await this.addImages(debugImages, true);

    const blankTemplate = this.getPageTemplatesForProduct().filter(template => template.name === "blank")[0]

    let defaultBlankTemplate = JSON.parse(JSON.stringify(blankTemplate));

    for (let i = 0; i < pagesRemaining; i++) {
      await this.handleSaveTemplate(defaultBlankTemplate, lastPage+i);
    }
  };

  

  handleInsertPage = (config = null, pageNumber = null) => {
    let pageNumberToActionOn = pageNumber || this.state.moreOptionPageNumber
    console.log("pageNumberToActionOn", pageNumberToActionOn)
    console.log("handle insert pageee called", config);
    let newDefaultLastLayer;
    //24,22
    if (
      this.state.item.toJS().defaultLastLayer.page < this.state.images.toJS().length &&
      this.state.item.toJS().defaultLastLayer.page <= this.state.item.toJS().maxPages
    ) {
      console.log("A")
      // this.insertNewLayer(1);
      newDefaultLastLayer = this.state.item.toJS().defaultLastLayer;
      newDefaultLastLayer.id = newDefaultLastLayer.id + 1;
      newDefaultLastLayer.page = newDefaultLastLayer.page + 1;
    }

    let newItem = this.state.item.toJS();
    /*if (newItem.layers.length - 2 >= newItem.maxPages) {
      return;
    }*/
    if (newDefaultLastLayer) {
      newItem = {
        ...newItem,
        layers: [...newItem.layers, newDefaultLastLayer],
        defaultLastLayer: newDefaultLastLayer,
      };
    }
    let prevLayers = newItem.layers;
    const newLayers = newItem.layers.map(layer => {
      if (layer.type === LAYER_TYPES.PHOTO) {
        if (layer.page >= pageNumberToActionOn + 1) {
          if (layer.page === pageNumberToActionOn + 1) {
            if (config) {
              return { ...layer, config: config };
            } else {
              // const defaultTemplate = this.state.item
              //   .toJS()
              //   .pagestemplates.filter(
              //     template => template.name === "single-image-default-template"
              //   )[0];
              const defaultTemplate = this.getPageTemplatesForProduct().filter(
                template => template.name === "single-image-default-template"
              )[0];
                // this.state.item.toJS().productDimensions.width >
                // this.state.item.toJS().productDimensions.height
                //   ? landscapeTemplates.filter(
                //       template => template.name === "single-image-default-template"
                //     )[0]
                //   : regularTemplates.filter(
                //       template => template.name === "single-image-default-template"
                //     )[0];
              if (newItem.product_options.globalTheme) {
                defaultTemplate.canvasColor = newItem.product_options.globalTheme.style.color;
              }
              const con = {
                border: newItem.product_options.globalTheme
                  ? {
                      ...newItem.product_options.globalTheme,
                      style: {
                        ...newItem.product_options.globalTheme.style,
                        showBorder: newItem.product_options.border,
                      },
                    }
                  : {
                      style: null,
                    },
                layout: [
                  {
                    height: 1,
                    width: 1,
                    xoffset: 0,
                    yoffset: 0,
                    image: {
                      src: {},
                    },
                  },
                ],
                template: defaultTemplate,
                rect: {
                  height: 1,
                  width: 1,
                  x: 0,
                  y: 1,
                },
              };
              return { ...layer, config: con };
            }
          } else {
            for (let j = 0; j <= prevLayers.length - 1; j++) {
              // console.log(layer.page);
              if (
                layer.page - 1 === prevLayers[j].page &&
                layer.page > pageNumberToActionOn + 1
              ) {
                return { ...layer, config: prevLayers[j].config };
              }
            }
            return layer;
          }
        } else {
          return layer;
        }
      } else {
        return layer;
      }
    });
    newItem = { ...newItem, layers: newLayers };
    this.setState(
      {
        item: fromJS(newItem),
        reRender: !this.state.reRender,
      },
      () => {
        this.emitUpdate();
        this.updatePages();
      }
    );
    //keept this to revert back
    // this.setState(
    //   {
    //     item: this.state.item.withMutations(item => {
    //       console.log(this.state.item.toJS().defaultLastLayer);
    //       console.log(this.state.item.toJS().layers.length);
    //       return item.withMutations(item => {
    //         item.update("layers", layers => {
    //           let prevLayers = layers.toJS();
    //           // if (newDefaultLastLayer) {
    //           //   prevLayers = [...prevLayers, newDefaultLastLayer];
    //           // }
    //           return layers.map(layer => {
    //             if (layer.get("type") === LAYER_TYPES.PHOTO) {
    //               if (layer.get("page") >= this.state.moreOptionPageNumber + 1) {
    //                 if (layer.get("page") === this.state.moreOptionPageNumber + 1) {
    //                   if (config) {
    //                     return layer.setIn(["config"], fromJS(config));
    //                   } else {
    //                     const defaultTemplate =
    //                       this.state.item.toJS().productDimensions.width >
    //                       this.state.item.toJS().productDimensions.height
    //                         ? landscapeTemplates.filter(
    //                             template => template.name === "single-image-default-template"
    //                           )[0]
    //                         : regularTemplates.filter(
    //                             template => template.name === "single-image-default-template"
    //                           )[0];
    //                     if (item.toJS().product_options.globalTheme) {
    //                       defaultTemplate.canvasColor = item.toJS().product_options.globalTheme.style.color;
    //                     }
    //                     const con = {
    //                       border: item.toJS().product_options.globalTheme
    //                         ? item.toJS().product_options.globalTheme
    //                         : {
    //                             style: null,
    //                             thickness: 0.1,
    //                           },
    //                       layout: [
    //                         {
    //                           height: 1,
    //                           width: 1,
    //                           xoffset: 0,
    //                           yoffset: 0,
    //                         },
    //                       ],
    //                       template: defaultTemplate,
    //                       rect: {
    //                         height: 1,
    //                         width: 1,
    //                         x: 0,
    //                         y: 1,
    //                       },
    //                     };
    //                     console.log(con);
    //                     return layer.setIn(["config"], fromJS(con));

    //                     // this.state.item.toJS().productDimensions.width >
    //                     // this.state.item.toJS().productDimensions.height
    //                     //   ? templatesLandscape.filter(template => template.name === "")
    //                     //   : templates;
    //                   }
    //                 } else {
    //                   for (let j = 0; j < prevLayers.length - 1; j++) {
    //                     if (
    //                       layer.get("page") - 1 === prevLayers[j].page &&
    //                       layer.get("page") > this.state.moreOptionPageNumber + 1
    //                     ) {
    //                       return layer.setIn(["config"], prevLayers[j].config);
    //                     } else {
    //                       return layer;
    //                     }
    //                   }
    //                 }
    //               } else {
    //                 return layer;
    //               }
    //             } else {
    //               return layer;
    //             }
    //           });
    //         });
    //       });
    //     }),
    //   },
    //   this.emitUpdate
    // );

    const currentImages = this.state.images.toJS();
    const firstHalf = currentImages.slice(0, pageNumberToActionOn + 1);
    const secondHalf = currentImages.slice(
      pageNumberToActionOn + 1,
      currentImages.length
    );
    if (config) {
      this.setState(
        {
          images: fromJS([...firstHalf, config.layout[0].image, ...secondHalf]),
        },
        this.emitUpdate
      );
    } else {
      const con = {
        border: {
          style: null,
          thickness: 0.14,
        },
        layout: [
          {
            height: 1,
            width: 1,
            xoffset: 0,
            yoffset: 0,
            image: {
              src: {
                lowResUrl: "",
                highResUrl: "",
                uploadcareUuid: "",
              },
              cropData: null,
            },
          },
        ],
        rect: {
          height: 1,
          width: 1,
          x: 0,
          y: 1,
        },
      };
      this.setState(
        {
          images: fromJS([...firstHalf, con.layout[0].image, ...secondHalf]),
        },
        this.emitUpdate
      );
    }
    this.handleMoreModalClose();
  };

  handleMoreModalOpen = pageNumber => {
    this.setState({ moreOptionPageNumber: pageNumber });
  };

  handleBorderPopupOpen = pageNumber => {
    this.setState({ borderOverridePageNumber: pageNumber });
  };

  borderOverridePageNumber = pageNumber => {
    this.setState({ borderOverridePageNumber: null });
  };

  handleMoreModalClose = () => {
    this.setState({ moreOptionPageNumber: null });
  };

  handleToggleBorder = (setColorToo = false, pageNumber) => {
    this.state.item.setIn(["product_options", "backgroundColor"], "red");
    const borderColour =
      this.state.item.getIn(["product_options", "coverColour", "backgroundColour"]) ||
      "rgb(255,255,255)";

    // this is old method to check border
    let isBorderApplied = !this.state.item.getIn(["product_options", "border"]);
    // const borderConfig = isBorderApplied
    //   ? this.borderConfigForColour(borderColour)
    //   : NO_BORDER_CONFIG;

    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            if (!pageNumber) {
              item.setIn(["product_options", "border"], isBorderApplied);
            }
            item.update("layers", layers => {
              if (pageNumber) {
                return layers.map(layer => {
                  const shouldApplyToPage = pageNumber ? pageNumber === layer.get("page") : false;
                  if (layer.get("type") === LAYER_TYPES.PHOTO && shouldApplyToPage) {
                    let oldBorderConfig;
                    try {
                      oldBorderConfig = layer.getIn(["config", "border"]).toJS();
                    } catch (err) {
                      oldBorderConfig = layer.getIn(["config", "border"]);
                    }

                    let fromBlank = false;
                    if (oldBorderConfig.thickness === 0) {
                      oldBorderConfig = this.borderConfigForColour(borderColour);
                      // fromBlank = true;
                      console.log("from blankkk");
                    }
                    const isBorderAppliedOnPhoto =
                      oldBorderConfig &&
                      oldBorderConfig.style &&
                      oldBorderConfig.style.showBorder &&
                      !fromBlank;
                    isBorderApplied = !isBorderAppliedOnPhoto;
                    if (isBorderAppliedOnPhoto) {
                      console.log("border applied already", oldBorderConfig);
                      const newBorderConfig = {
                        ...oldBorderConfig,
                        style: { ...oldBorderConfig.style, showBorder: false },
                      };
                      if (
                        layer.toJS()?.config?.template?.name === "single-image-default-template"
                      ) {
                        return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                      } else if (
                        layer.toJS().config?.template &&
                        layer.toJS()?.config?.template?.name !== "single-image-default-template"
                      ) {
                        return layer.setIn(
                          ["config", "border"],
                          fromJS({
                            ...oldBorderConfig,
                            style: { ...oldBorderConfig.style, showBorder: true },
                          })
                        );
                      } else {
                        return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                        // .setIn(["config", "template"], null)
                        // .setIn(["config", "allImages"], null);
                      }
                    } else {
                      if (oldBorderConfig.style) {
                        const newBorderConfig = {
                          ...oldBorderConfig,
                          style: { ...oldBorderConfig.style, showBorder: true },
                        };
                        if (
                          layer.toJS()?.config?.template?.name === "single-image-default-template"
                        ) {
                          return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                        } else {
                          return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                          // .setIn(["config", "template"], null);
                        }
                      }
                      // return layer
                      //   .setIn(
                      //     ["config", "border"],
                      //     fromJS(this.borderConfigForColour(borderColour))
                      //   )
                      //   .setIn(["config", "template"], null);
                      if (
                        layer.toJS()?.config?.template?.name === "single-image-default-template"
                      ) {
                        return layer.setIn(
                          ["config", "border"],
                          this.borderConfigForColour(borderColour)
                        );
                      } else {
                        return layer.setIn(
                          ["config", "border"],
                          this.borderConfigForColour(borderColour)
                        );
                        // .setIn(["config", "template"], null);
                      }
                    }
                  } else {
                    return layer;
                  }
                });
              } else {
                return layers.map(layer => {
                  // const shouldApplyToPage = pageNumber ? pageNumber === layer.get("page") : false;
                  if (
                    layer.get("type") === LAYER_TYPES.PHOTO &&
                    !layer.getIn(["config", "border"]).toJS()?.style?.priority
                  ) {
                    const oldBorderConfig = layer.getIn(["config", "border"]).toJS();
                    const isBorderAppliedOnPhoto =
                      oldBorderConfig && oldBorderConfig.style && oldBorderConfig.style.showBorder;
                    if (!isBorderAppliedOnPhoto === isBorderApplied) {
                      if (isBorderAppliedOnPhoto) {
                        const newBorderConfig = {
                          ...oldBorderConfig,
                          style: { ...oldBorderConfig.style, showBorder: false },
                        };

                        return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                      } else {
                        if (oldBorderConfig.style) {
                          const newBorderConfig = {
                            ...oldBorderConfig,
                            style: { ...oldBorderConfig.style, showBorder: true },
                          };

                          return layer.setIn(["config", "border"], fromJS(newBorderConfig));
                        }
                        return layer.setIn(
                          ["config", "border"],
                          fromJS(this.borderConfigForColour(borderColour))
                        );
                      }
                    } else {
                      return layer;
                    }
                  } else {
                    return layer;
                  }
                });
              }
            });
          });
        }),
        // reRender: !this.state.reRender,
      },
      () => {
        this.emitUpdate();
        if (pageNumber) {
          const selectedLayer = this.state.item.toJS().layers.filter(layer => {
            return (
              layer.type === LAYER_TYPES.PHOTO &&
              layer.page === pageNumber &&
              layer.config.template &&
              layer.config.template.name !== "single-image-default-template"
            );
          });
          if (selectedLayer?.length !== 0) {
            const defaultTemplate = this.getPageTemplatesForProduct().filter(
              template => template.name === "single-image-default-template"
            )[0]
              // this.state.item.toJS().productDimensions.width >
              // this.state.item.toJS().productDimensions.height
              //   ? landscapeTemplates.filter(
              //       template => template.name === "single-image-default-template"
              //     )[0]
              //   : regularTemplates.filter(
              //       template => template.name === "single-image-default-template"
              //     )[0];
            this.handleSaveTemplate(defaultTemplate, pageNumber);
          }
        }
        if (setColorToo) {
          this.setBorderColor("#fff");
        }
        message.success(
          `${pageNumber ? "Border" : "Borders"} ${isBorderApplied ? `added ${pageNumber ? '' : ' to all pages'}` : `removed ${pageNumber ? '' : ' from all pages'}`}`
        );
      }
    );

  };
  toggleCropForPage = page => {
    const newLayers = this.state.item.toJS().layers.map(layer => {
      if (layer.type === LAYER_TYPES.PHOTO && layer.page === page) {
        const isCropEnabled = layer?.isCropEnabled;
        layer.isCropEnabled = !isCropEnabled;
        layer.isDragEnabled = false;
        if (layer.isCropEnabled) {
          message.success(`Tap a photo to change, crop & rotate`);
        }
        return layer;
      }
      if (layer.type === LAYER_TYPES.PHOTO && layer.page !== page) {
        layer.isCropEnabled = false;
        layer.isDragEnabled = false;
        return layer;
      }
      return layer;
    });

    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.set("layers", fromJS(newLayers));
          });
        }),
      },
      this.emitUpdate
    );
  };

  turnOffAllDragTools = () => {
    const noDragToolLayers = this.state.item.toJS().layers.map(layer => {
      if (layer.type === LAYER_TYPES.PHOTO) {
        layer.isCropEnabled = false;
        layer.isDragEnabled = false;
        return layer;
      }
      return layer;
    });
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.set("layers", fromJS(noDragToolLayers));
          });
        }),
      },
      this.emitUpdate
    );
  }

  toggleDragForPage = page => {
    const newLayers = this.state.item.toJS().layers.map(layer => {
      if (layer.type === LAYER_TYPES.PHOTO && layer.page === page) {
        if (layer.config.template){
          if (layer.config.template.placeholders.filter(p => p.type === "IMAGE_PLACEHOLDER").length > 1){
            const isDragEnabled = layer?.isDragEnabled;
            if (isDragEnabled) {
              this.enableScrolling();
            }
            layer.isDragEnabled = !isDragEnabled;
            layer.isCropEnabled = false;
            message.success(`Drag & drop photos to rearrange on a page`);
            return layer;
          } else {
            return layer;
          }
        } else{
          message.info(`Drag & drop to rearrange is only available for multi-photo layouts`);
          return layer;
        }
      }
      if (layer.type === LAYER_TYPES.PHOTO && layer.page !== page) {
        layer.isCropEnabled = false;
        layer.isDragEnabled = false;
        return layer;
      }
      return layer;
    });

    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            item.set("layers", fromJS(newLayers));
          });
        }),
      },
      this.emitUpdate
    );
  };

  templateCallback = (
    placeholderId,
    pageNumber,
    template,
    isCoverCropperClicked = false,
    doubleClick = false
  ) => {
    //console.log({ placeholderId, pageNumber, template });
    let isCropEnabled = this.state.item
      .toJS()
      .layers.filter(layer => layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber)[0]
      ?.isCropEnabled;
    if (isCoverCropperClicked) {
      isCropEnabled = true;
    }

    if (doubleClick) {
      isCropEnabled = true;
    }

    template.placeholders.forEach(placeholder => {
      if (placeholder.id === placeholderId) {
        if (placeholder.type === "TEXT_PLACEHOLDER") {
          this.setState({
            textEditorModalVisible: true,
            templateToUpdateData: {
              placeholderId,
              pageNumber,
              placeholder,
            },
          });
        } else {
          if (
            template.placeholders.filter(placeholder => placeholder.id === placeholderId)[0]
              .properties.imageSrc
          ) {
            if (isCropEnabled)
              this.setState({
                templatePhotoEdit: {
                  placeholderId,
                  pageNumber,
                  template,
                },
              }, () => {
                //Hide all drag previews...
                setTimeout(() => {
                  document.querySelectorAll(".dragPreview").forEach(el => el.remove());
                },200);
              });
          } else {
            this.setState({
              isUploadTemplateImageModalVisible: true,
              templateToUpdateData: {
                placeholderId,
                pageNumber,
                template,
              },
            });
          }
        }
      }
    });
    
  };

  saveItem = () => {
    this.props.onSave(getImagesMappedToItem(this.state.item, this.state.images));
  };

  onTextEditSave = (updatedPlaceholder, font = null, keepOpen = false) => {
    this.setState(
      {
        item: this.state.item.withMutations(item => {
          return item.withMutations(item => {
            if (font) {
              item.setIn(["product_options", "fontFamily"], font);
            }
            item.update("layers", layers => {
              return layers.map(layer => {
                if (
                  layer.get("type") === LAYER_TYPES.PHOTO &&
                  layer.get("page") === this.state.templateToUpdateData.pageNumber
                ) {
                  const template = layer.get("config").toJS().template;
                  for (let i = 0; i < template.placeholders.length; i++) {
                    if (
                      template.placeholders[i].id === this.state.templateToUpdateData.placeholderId
                    ) {
                      template.placeholders[i] = updatedPlaceholder;
                      break;
                    }
                  }
                  return layer.setIn(["config", "template"], template);
                } else {
                  return layer;
                }
              });
            });
          });
        }),
        reRender: !this.state.reRender,
      },
      this.emitUpdate
    );
    if (!keepOpen){
      this.handleHideTextEditorModal();
    }
  };

  handleHideTextEditorModal = () => {
    this.setState({ textEditorModalVisible: false, templateToUpdateData: null });
  };

  hideWelcomeModal = () => {
    this.setState({ showWelcomeModal: false, hasSeenWelcomeModal: true });
  };

  render() {
    const product = productsByProductId.get(this.state.item.get("productId"));
    const layersWithPhotos = this.state.images;

    const totalPagesRequired = product.get("pages");
    const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
    const pagesRemaining = this.state.isReplacingImage ? 1 : totalPagesRequired - pagesAdded;
    const minimumPagesRequired = product.get("min_pages");

    const alert = <SweetAlert isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />;

    const recentlyUploaded = JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;

    const welcomeModal = (
      <AntModal
        visible={this.state.showWelcomeModal && !this.state.hasSeenWelcomeModal}
        onCancel={this.hideWelcomeModal}
        onOk={this.hideWelcomeModal}
        style={{ top: 20, height: "500px" }}
        bodyStyle={{ overflowY: "scroll", backgroundColor: '#FAF6F0' }}
        maskClosable={true}
        footer={null}
        closable={false}
      >
        <div style={{ overfow: "scroll" }}>
          <h3 style={{ textAlign: "center", marginTop: "0px" }}>Your New Photo Book</h3>
          <p style={{ fontSize: "14px" }}>
            A photo book comprises of a front cover and 24 pages, if you want less than 24 pages we will print blank pages in their place.
          </p>
          <p style={{ fontSize: "14px" }}>
            Our top tips:
            <br/>
            <br/>
            <ul>
              <li>You will need at least 25 photos to complete your photo book.</li>
              <li>Our photo book creator has been optimised for use on a mobile phone.</li>
              <li>We recommend using the latest Chrome or Safari web browser.</li>
              <li>Please use high-resolution photos (around 2MB).</li>
              <li>Please do not use WhatsApp photos as these will not print crisply.</li>
            </ul>
          </p>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
              onClick={this.hideWelcomeModal}
              label="Get Started"
              theme="dark-blue"
              priority="primary"
              block
            />
          </div>
        </div>
      </AntModal>
    );

    const uploadModal = this.state.item && (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title={"Upload Photos"}
        leftAction={
          this.state.images.size === 0 ? (
            <Button
              priority="tertiary"
              theme="muted"
              label="Cancel"
              onClick={() => this.closeUploadModal()}
            />
          ) : null
        }
      >
        <MainContent scrollable={false} padded centeredVertically>
          <div className="restricted-width-modal-content" style={{ marginTop: "-50px" }}>
            <p style={{ textAlign: "center" }}>
              {this.state.imagesToUploadLimit
                ? `Upload ${
                    this.state.imagesToUploadLimit === 1
                      ? "a Photo"
                      : `${this.state.imagesToUploadLimit} Photos`
                  }`
                : `Upload ${minimumPagesRequired} photos to start, the first photo selected will be your cover - you can change this later`}
            </p>
            {process.env.NODE_ENV === "development" && (
              <Button
                theme="dark-blue"
                block
                label="DEBUG UPLOAD"
                icon="phone"
                onClick={this.debugUpload}
              />
            )}
            <Button
              block
              label="Upload From Device"
              icon="phone"
              theme="dark-blue"
              onClick={() => this.pickImageFromUploadcare("file", pagesRemaining)}
            />
            {anyRecentlyUploaded && (
              <Button
                block
                className="btn--recent"
                label="Recently Uploaded"
                icon="upload"
                onClick={() => this.pickImageFromUploadcare("favorites", this.state.isReplacingImage ? 1 : 100)} //pagesRemaining
              />
            )}
          </div>
        </MainContent>
      </Modal>
    );

    const uploadTemplateImageModal = this.state.item && (
      <Modal
        key="uploadcare-template-selection-modal"
        isOpen={this.state.isUploadTemplateImageModalVisible}
        onClose={this.closeUploadTemplateImageModal}
        title={`Upload 1 Photos`}
      >
        <MainContent scrollable={false} padded centeredVertically>
          <div className="restricted-width-modal-content">
            {process.env.NODE_ENV === "development" && (
              <Button
                theme="dark-blue"
                block
                label="DEBUG UPLOAD"
                icon="phone"
                onClick={this.debugUpload}
              />
            )}
            <Button
              block
              label="Upload From Device"
              icon="phone"
              theme="dark-blue"
              onClick={() => this.pickImageForTemplate("file")}
            />
            {anyRecentlyUploaded && (
              <Button
                block
                className="btn--recent"
                label="Recently Uploaded"
                icon="upload"
                onClick={() => this.pickImageForTemplate("favorites")}
              />
            )}
          </div>
        </MainContent>
      </Modal>
    );

    const imageForCropper = this.state.images.get(this.state.pageNumberToCrop);
    const cropperModalProps = {};

    const distanceFromLeft = (point, scaleBy, canvasSize) => {
      return canvasSize.width * scaleBy * point[0];
    };

    const distanceFromTop = (point, scaleBy, canvasSize) => {
      return canvasSize.height * scaleBy * point[1];
    };

    if (this.state.templatePhotoEdit) {
      cropperModalProps.templateImage = true;
      cropperModalProps.imageSize = this.state.templatePhotoEdit.template.placeholders.filter(
        placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId
      )[0].properties.imageSize;
      cropperModalProps.imgUrl = this.state.templatePhotoEdit.template.placeholders.filter(
        placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId
      )[0].properties.imageSrc;
      cropperModalProps.cropData = this.state.templatePhotoEdit.template.placeholders.filter(
        placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId
      )[0].properties.imageData.cropData;
      const placeholder = (cropperModalProps.ratio = this.state.templatePhotoEdit.template.placeholders.filter(
        placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId
      )[0]);
      const image = new Image();
      image.src = cropperModalProps.imgUrl;
      cropperModalProps.originalHeight = image.height;
      const photoLayers = this.state.item.get("layers").filter(
        layer =>
          layer.get("type") === LAYER_TYPES.PHOTO &&
          // layer.get("page") !== 0 &&
          layer.get("page") === this.state.templatePhotoEdit.pageNumber
      );
      const borderConfig = photoLayers.first().toJS().config.border;
      cropperModalProps.borderConfig = borderConfig;
      cropperModalProps.originalWidth = image.width;

      cropperModalProps.ratio =
        (distanceFromLeft(
          placeholder.position.topRight,
          1,
          this.state.templatePhotoEdit.template.canvasSize
        ) -
          distanceFromLeft(
            placeholder.position.topLeft,
            1,
            this.state.templatePhotoEdit.template.canvasSize
          )) /
        (distanceFromTop(
          placeholder.position.bottomRight,
          1,
          this.state.templatePhotoEdit.template.canvasSize
        ) -
          distanceFromTop(
            placeholder.position.topRight,
            1,
            this.state.templatePhotoEdit.template.canvasSize
          ));
    }
    if (imageForCropper) {
      cropperModalProps.imgUrl = imageForCropper.getIn(["src", "highResUrl"])+`/-/resize/800x/-/quality/smart_retina/`;
      cropperModalProps.cropData = imageForCropper.get("cropData")
        ? imageForCropper.get("cropData").toJS()
        : null;
      const image = new Image();
      image.src = cropperModalProps.imgUrl;
      cropperModalProps.originalHeight = image.height;
      cropperModalProps.originalWidth = image.width;
      cropperModalProps.ratio =
        this.state.item.getIn(["productDimensions", "width"]) /
        this.state.item.getIn(["productDimensions", "height"]);
      this.state.pageNumberToCrop !== 0 && this.state.item.getIn(["product_options", "border"]);
      cropperModalProps.pageNumber = this.state.pageNumberToCrop;
    }

    // const handleTemplateModalClose = () => {
    //   this.setState({
    //     isTemplateModalOpen: false,
    //     // addTemplateToPageNumber: null,
    //   });
    // };

    /*
    const handleSaveTemplate = async template => {
      this.setState(
        {
          item: this.state.item.withMutations(item => {
            return item.withMutations(item => {
              item.update("layers", layers => {
                return layers.map(layer => {
                  if (
                    layer.get("type") === LAYER_TYPES.PHOTO &&
                    layer.get("page") === this.state.addTemplateToPageNumber
                  ) {
                    return layer.setIn(["config", "template"], template);
                  } else {
                    return layer;
                  }
                });
              });
            });
          }),
          isTemplateModalOpen: false,
        },
        this.emitUpdate
      );
    };
    */

    const isCropperActive = this.state.pageNumberToCrop !== null || this.state.templatePhotoEdit !== null

    const cropperModal = (
      <EditorCropperModal
        isOpen={isCropperActive}
        {...cropperModalProps}
        onClose={this.closeCropper}
        onSave={this.handleSaveCrop}
        onHandleReplaceImage={() => this.handleReplaceImage(this.state.pageNumberToCrop)}
        onHandleReplaceImageInTemplate={this.onHandleReplaceImageInTemplate}
        templatePhotoEdit={this.state.templatePhotoEdit}
        handleTemplateImageSize={this.handleTemplateImageSize}
        onSaveTemplatePhoto={this.onSaveTemplatePhoto}
        isPhotoBook={true}
      />
    );

    /*const handleTemplateImageUpload = newImage => {
      // this.setState(
      //   {
      //     images: this.state.images.setIn([this.state.addTemplateToPageNumber], fromJS(newImage)),
      //   },
      //   this.emitUpdate
      // );
    };*/
    /*
    const textEditorModal = this.state.templateToUpdateData?.placeholder ? (
      <AntModal
        transitionName=""
        mask={false}
        visible={this.state.textEditorModalVisible}
        onCancel={
          !this.state.templateToUpdateData.forCover ? this.handleHideTextEditorModal : () => {}
        }
        footer={null}
        // width="40vmax"
        className="ant-custom-modal"
        bodyStyle={{ width: "100vw", minHeight: "100vh" }}
        closable={!this.state.templateToUpdateData.forCover}
        maskClosable={!this.state.templateToUpdateData.forCover}
        // wrapClassName="ant-modal-wrap-text-editor"
      >
        <TextEditor
          defPlaceholder={this.state.templateToUpdateData.placeholder}
          toUpdate={this.state.templateToUpdateData}
          onPage={this.state.templateToUpdateData.pageNumber}
          item={this.state.item}
          setGlobalTextFont={this.setGlobalTextFont}
          forCover={this.state.templateToUpdateData.forCover}
          isEditingCoverPage={
            this.state.templateToUpdateData.pageNumber === 0 &&
            !this.state.templateToUpdateData.forCover
          }
          onSave={this.onTextEditSave}
        />
      </AntModal>
    ) : null;
    */

    let dimensionsAsPx = getMaxResolutionForDimensions({
      width: parseFloat(product.get("width")) + parseFloat(product.get("bleed_l")) + parseFloat(product.get("bleed_r")),
      height: parseFloat(product.get("height"))+ parseFloat(product.get("bleed_t")) + parseFloat(product.get("bleed_b")),
      dpi: product.get("dpi"),
    });
    let renderWidth = dimensionsAsPx.split("x")[0]

    const textEditorModal = this.state.templateToUpdateData?.placeholder ? (
      <Modal
        hasHeader={false}
        key="texteditor-modal"
        isOpen={this.state.textEditorModalVisible}
        onClose={!this.state.templateToUpdateData.forCover ? this.handleHideTextEditorModal : () => {}}
        title={"Photo book title"}
        leftAction={
          !this.state.templateToUpdateData.forCover ? (
            <Button
              priority="tertiary"
              theme="muted"
              label="Cancel"
              onClick={() => this.handleHideTextEditorModal}
            />
          ) : null
        }
        rightAction={
          <Button
            priority="tertiary"
            theme="muted"
            label="Done"
            onClick={() => this.handleHideTextEditorModal}
          />
        }
      >
        <MainContent>
          <TextEditor
            defPlaceholder={this.state.templateToUpdateData.placeholder}
            toUpdate={this.state.templateToUpdateData}
            onPage={this.state.templateToUpdateData.pageNumber}
            item={this.state.item}
            renderWidth={renderWidth || 2000}
            setGlobalTextFont={this.setGlobalTextFont}
            forCover={this.state.templateToUpdateData.forCover}
            isEditingCoverPage={
              this.state.templateToUpdateData.pageNumber === 0 &&
              !this.state.templateToUpdateData.forCover
            }
            onSave={this.onTextEditSave}
            setBookColour={this.setBorderColor}
          />
        </MainContent>
      </Modal>
       ) : null;

    // const templateModal = (
    //   <TemplateModal
    //     isOpen={this.state.isTemplateModalOpen}
    //     template={this.state.template}
    //     pageNumber={this.state.addTemplateToPageNumber}
    //     onClose={handleTemplateModalClose}
    //     saveTemplate={handleSaveTemplate}
    //     item={this.state.item}
    //     handleTemplateImageUpload={handleTemplateImageUpload}
    //     setGlobalProgressBarPercentage={this.props.setGlobalProgressBarPercentage}
    //     setThemeToTemplate={this.setThemeToTemplate}
    //   />
    // );
    const productId = this.state.item.get("productId");
    const pricingScheme = getPricingSchemeForProductAndQuantity({
      productId,
      quantity: 1,
      currency: this.props.currency,
      additionalPages: 4,
      additionalPagesCost: 2.00
    });
    // const pricingScheme = getPricingSchemeForProductAndQuantity({
    //   productId,
    //   quantity: 1,
    //   currency: this.props.currency,
    // });

    const itemWithMappedImages = getImagesMappedToItem(this.state.item, this.state.images);
    return (
      <React.Fragment>
        {this.state.savingCrop && (
          <FullScreenLoader key="loader" isVisible={true} message={"Saving..."}/>
        )}
        {alert}
        {cropperModal}
        {uploadModal}
        {uploadTemplateImageModal}
        {textEditorModal}
        {welcomeModal}
        {/* {this.state.isTemplateModalOpen ? templateModal : null} */}
        <PhotoMagazineEditor
          cropperOpen={isCropperActive}
          pricingScheme={pricingScheme}
          coverTextModalOpen={false}
          onCoverTextModalAutoShown={this.handleCoverTextModalAutoShown}
          product={product}
          item={itemWithMappedImages}
          images={this.state.images}
          saveButtonText="Preview"
          setBorderColor={this.setBorderColor}
          uploadingFirstTime={this.state.uploadingFirstTime}
          onStartUpload={() => this.showUploadModal(pagesRemaining, anyRecentlyUploaded)}
          onClickPage={this.startCropping}
          onSwapImages={this.handleSwapImages}
          onDeleteImage={this.handleDeleteImage}
          onChangeCoverText={this.handleChangeCoverText}
          onClickSave={this.handleClickSave}
          onCancel={this.props.onCancel}
          handleTemplateSelect={this.handleTemplateSelect}
          handleSaveTemplate={this.handleSaveTemplate}
          templateCallback={this.templateCallback}
          handleToggleBorder={this.handleToggleBorder}
          handleMoreModalOpen={this.handleMoreModalOpen}
          handleBorderPopupOpen={this.handleBorderPopupOpen}
          handleInsertPage={this.handleInsertPage}
          handleMakeBlank={this.handleMakeBlank}
          handleFillRestBlank={this.handleFillRestBlank}
          openCoverTextEditor={this.openCoverTextEditor}
          setGlobalTextFont={this.setGlobalTextFont}
          selectImageUploadCount={this.selectImageUploadCount}
          reRender={this.state.reRender}
          moreOptionPageNumber={this.state.moreOptionPageNumber}
          borderOverridePageNumber={this.state.borderOverridePageNumber}
          handleImageSwapStart={this.handleImageSwapStart}
          swapImageFromTemplate={this.swapImageFromTemplate}
          handlePageReorder={this.handlePageReorder}
          toggleCropForPage={this.toggleCropForPage}
          toggleDragForPage={this.toggleDragForPage}
          turnOffAllDragTools={this.turnOffAllDragTools}
        />
      </React.Fragment>
    );
  }
}

export default PhotoMagazineEditorContainer;
