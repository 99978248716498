import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { is } from "immutable";
import queryString from "query-string";

import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import { productsByProductTypeId, getProductforProductId } from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import {
  actions as editorActions,
  selectors as editorSelectors,
} from "../../store/ducks/editor";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors, actions as basketActions } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import Editor from "./Editor";
import SweetAlert from "../SweetAlert/SweetAlert";

import { Modal as AntModal } from "antd";

const { confirm } = AntModal;

class EditorContainer extends Component {
  static propTypes = {
    item: PropTypes.object,
    isSignedIn: PropTypes.bool,
    user: PropTypes.object,
    editorItem: PropTypes.object,
    showAuthModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
    setEditorItem: PropTypes.func.isRequired,
    clearEditorItem: PropTypes.func.isRequired,
    setS3KeyForGraphicLayer: PropTypes.func.isRequired,
    setTextContentForTextLayer: PropTypes.func.isRequired,
    setTextPositionForTextLayer: PropTypes.func.isRequired,
    setImageSrcForPhotoRegion: PropTypes.func.isRequired,
    setFilterForPhotoRegion: PropTypes.func.isRequired,
    setProductId: PropTypes.func.isRequired,
    lastStep: PropTypes.string,
    skipAddressInput: PropTypes.bool,
    disableScaling: PropTypes.bool,
    onChangeStep: PropTypes.func,
  };

  static defaultProps = {
    saveButtonLabel: "Save",
    skipAddressInput: false,
    disableScaling: false,
  };

  static DRAG_DROP_MODES = {
    NONE: "NONE",
    ACROSS_REGIONS: "ACROSS_REGIONS",
    ACROSS_LAYERS: "ACROSS_LAYERS",
  };

  state = {
    isDirtyWarningVisible: false,
  };

  componentDidMount() {
    if (this.props.item) {
      this.setItemAsEditorItem(this.props.item);
      //console.log(this.props.editorItem);
    }

    if (this.props.isDuplicate) {
      console.log("This is a duplicate");
      this.resetForDuplicate();
    }

    const wipEditorItem = localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM)
      ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM))
      : null;
    if (wipEditorItem && `/app${wipEditorItem.source}` === window.location.pathname) {
      console.log("Restoring wip item");
      this.props.setEditorItem(wipEditorItem.item);
    } else{
      if (this.props.getAllUnapprovedPendingApprovalItems && this.props.getAllUnapprovedPendingApprovalItems.toJS().length > 0 ){
        const restoreItem = this.props.getAllUnapprovedPendingApprovalItems.first().toJS()
        this.props.clearPendingApprovalItems() // TODO should only be for this product type
        this.props.setEditorItem(restoreItem);
      }

      console.log("Not restoring wip item, different paths");
      if (wipEditorItem){
        console.log(`/app${wipEditorItem.source}`);
        console.log(window.location.pathname);
      }    
    }
    //
    //setTimeout(() => {
    //  this.props.setTextContentForTextLayer('EXTRA_TEXT_LAYER', '<p>I am the only one</p><br><p>Where are the others</p>');
    //}, 1000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.item || !this.props.item) {
      return this.props.clearEditorItem();
    }

    if (prevProps.item.get("id") !== this.props.item.get("id")) {
      this.setItemAsEditorItem(this.props.item);
    }

    if (prevProps.item.get("designId") !== this.props.item.get("designId")) {
      this.setItemAsEditorItem(this.props.item);
    }
  }

  componentWillUnmount() {
    console.log("Clearing editor item")
    // TODO - don't clear when restoring by moving to a different editor source
    // e.g from GC to postcards
    const parsedQueryString = queryString.parse(window.location.search);
    //console.log("query string", parsedQueryString);
    if(!parsedQueryString.restoring){
      this.props.clearEditorItem();
    } else{
      console.log("Restoring item, don't clear...")
    }
    
  }

  setItemAsEditorItem = item => {
    this.props.setEditorItem(item);
    this.originalEditorItem = item;
  };

  reset = () => {
    const editorItem = this.props.editorItem;
    this.props.clearEditorItem();
    this.setItemAsEditorItem(editorItem);
  };

  resetForDuplicate = () => {
    const newItemWithoutAddress = {
      ...this.props.item.toJS(),
      addressBookEntry: null,
      addressBookId: null,
      address: null,
    };
    this.props.clearEditorItem();
    this.props.setEditorItem(newItemWithoutAddress);
  };

  goToFrontPage = () => {
    this.editor.goToProductFront();
  };

  handleSave = () => {
    //this.props.onSave(this.props.editorItem.delete("addressBookEntry"));
    // TODO: Why the above?
    this.props.onSave(this.props.editorItem);
  };
  
  handlePackChange = (scheme) => {
    this.props.setPackScheme(scheme)
  }
  
  handleChangeProduct = (productId) => {
    this.props.setProductId(productId)
    this.props.onSetProduct(this.props.editorItem.get("productTypeId"), productId)
  }

  handleClose = productName => {
    const isDirty = !is(this.originalEditorItem, this.props.editorItem);
    if (isDirty) {
      confirm({
        title: "",
        content: `Are you sure you want to cancel? If you cancel you will lose your progress.`,
        okText: `Stay`,
        cancelText: "Cancel",
        icon: null,
        onOk: () => {
          this.dismissDirtyWarning();
        },
        onCancel: () => {
          this.discardChangesAndClose();
        },
      });
    } else {
      this.props.onClose();
    }
  };

  dismissDirtyWarning = () => {
    this.setState({ isDirtyWarningVisible: false });
  };

  discardChangesAndClose = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    this.dismissDirtyWarning();
    this.props.onClose();
  };

  setAddressBookEntryAndId = (entryId, entry) =>{
    this.props.setAddressBookId(entryId)
    this.props.setAddressBookEntry(entry)
  }

  render() {
    if (!this.props.editorItem) {
      return null;
    }

    let productName = getProductforProductId(this.props.editorItem.get("productId"));

    let products = productsByProductTypeId
      .get(this.props.editorItem.get("productTypeId"))
      .map(product =>
        product.set(
          "pricingScheme",
          getPricingSchemeForProductAndQuantity({
            productId: product.get("id"),
            quantity: this.props.editorItem.get("quantity"),
            currency: this.props.currency,
          })
        )
      );
    // if Greeting card, we dont treat portrait and landscape as the same type

    if (this.props.editorItem.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD) {
      products = [
        products.find(product => product.get("id") === this.props.editorItem.get("productId")),
      ];

      // because getProductforProductId returns undefined for Portrait/Landscape cards...
      productName = "greeting card";
    }

    let dragDropMode = EditorContainer.DRAG_DROP_MODES.NONE;

    if (
      this.props.editorItem.get("productTypeId") === PRODUCT_TYPE_IDS.COLLAGE_PRINTS ||
      (this.props.editorItem.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT &&
      this.props.editorItem.get("supports_collages"))
    ) {
      dragDropMode = EditorContainer.DRAG_DROP_MODES.ACROSS_REGIONS;
    }

    if (this.props.editorItem.get("productTypeId") === PRODUCT_TYPE_IDS.POSTERS) {
      const photoLayers = this.props.editorItem
        .get("layers")
        .filter(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      dragDropMode =
        photoLayers.size > 1
          ? EditorContainer.DRAG_DROP_MODES.ACROSS_LAYERS
          : EditorContainer.DRAG_DROP_MODES.ACROSS_REGIONS;
    }

    return [
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isDirtyWarningVisible}
        text={`You have unsaved changes, are you sure you don't want this ${productName}?`}
        cancelButtonText="Exit & discard changes"
        confirmButtonText={`Continue my ${productName}`}
        onConfirm={this.dismissDirtyWarning}
        onCancel={this.discardChangesAndClose}
      />,
      <Editor
        key="editor"
        onRef={ref => (this.editor = ref)}
        ref={ref => (this.editor = ref)}
        lastStep={this.props.lastStep}
        skipAddressInput={this.props.skipAddressInput}
        item={this.props.editorItem}
        dragDropMode={dragDropMode}
        products={products}
        isSignedIn={this.props.isSignedIn}
        user={this.props.user}
        showAuthModal={this.props.showAuthModal}
        onClose={() => this.handleClose(productName)}
        onSave={this.handleSave}
        saveButtonLabel={this.props.saveButtonLabel}
        onTextContentChange={this.props.setTextContentForTextLayer}
        onTextPositionChange={this.props.setTextPositionForTextLayer}
        onChangeSrcForPhotoRegion={this.props.setImageSrcForPhotoRegion}
        onSwapRegionImages={this.props.onSwapRegionImages}
        onMoveImageInRegionInPhotoLayer={this.props.setImagePositionForPhotoRegion}
        onCropImageInRegionInPhotoLayer={this.props.setImageCropDataForPhotoRegion}
        onChangeFilterForPhotoRegion={this.props.setFilterForPhotoRegion}
        onChangeSelectedGraphic={this.props.setS3KeyForGraphicLayer}
        onChangeTextColor={this.props.setColorForTextLayer}
        onChangeBorderThickness={this.props.setBorderThicknessForPhotoLayer}
        onChangeBorderPrintWidth={this.props.setBorderPrintWidthForPhotoLayer}
        onChangeRegionBorderRadius={this.props.setRegionBorderRadiusForPhotoLayer}
        onChangeRegionBorderWidth={this.props.setRegionBorderWidthForPhotoLayer}
        onChangeBorderStyle={this.props.setBorderStyleForPhotoLayer}
        onChangeLayout={this.props.setLayoutForPhotoLayer}
        onChangeTextConfig={this.props.updateTextLayerConfig}
        onChangeSignature={this.props.setImageForSignatureLayer}
        onChangeAddress={this.props.setAddress}
        onChangeOwnAddress={this.props.setUserAddress}
        onChangeAddressBookId={this.setAddressBookEntryAndId}
        onChangePostDate={this.props.setPostDate}
        onChangeItemDoubleDirect={this.props.changeItemDoubleDirect}
        onChangeProductId={this.handleChangeProduct}
        onDecreaseQuantity={this.props.decreaseQuantity}
        onIncreaseQuantity={this.props.increaseQuantity}
        onChangePackScheme={this.handlePackChange}
        onChangeProductOptions={this.props.changeProductOptons}
        setEditorItem={this.props.setEditorItem}
        disableScaling={this.props.disableScaling}
        onChangeStep={this.props.onChangeStep}
        currency={this.props.currency}
        selectVariantIndex={this.props.defaultSelectedVariantIndex}
      />,
    ];
  }
}

const mapStateToProps = state => {
  const editorItem = editorSelectors.getItem(state);
  const user = authSelectors.getUser(state);
  const getAllUnapprovedPendingApprovalItems = basketSelectors.getAllUnapprovedPendingApprovalItems(state);
  return {
    editorItem,
    isSignedIn: Boolean(user),
    user: user,
    currency: basketSelectors.getCurrency(state),
    getAllUnapprovedPendingApprovalItems: getAllUnapprovedPendingApprovalItems
  };
};

const mapDispatchToProps = dispatch => ({
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
  setEditorItem: item => dispatch(editorActions.setEditorItem(item)),
  clearEditorItem: () => dispatch(editorActions.clearEditorItem()),
  setProductId: productId => dispatch(editorActions.setProductId(productId)),
  changeProductOptons: (productOptions) => dispatch(editorActions.changeProductOptions(productOptions)),
  setS3KeyForGraphicLayer: (layerId, s3Key) =>
    dispatch(editorActions.setS3KeyForGraphicLayer(layerId, s3Key)),
  setColorForTextLayer: (layerId, index) =>
    dispatch(editorActions.setColorForTextLayer(layerId, index)),
  setTextContentForTextLayer: (layerId, content) =>
    dispatch(editorActions.setTextContentForTextLayer(layerId, content)),
  setTextPositionForTextLayer: (layerId, position) =>
    dispatch(editorActions.setTextPositionForTextLayer(layerId, position)),
  setImageSrcForPhotoRegion: ({ layerId, regionIndex, src, image }) =>
    dispatch(
      editorActions.setImageSrcForPhotoRegion({
        layerId,
        regionIndex,
        src,
        image,
      })
    ),
  onSwapRegionImages: ({
    sourceLayerId,
    sourceRegionIndex,
    destinationLayerId,
    destinationRegionIndex,
  }) =>
    dispatch(
      editorActions.swapRegionImages({
        sourceLayerId,
        sourceRegionIndex,
        destinationLayerId,
        destinationRegionIndex,
      })
    ),
  setImageCropDataForPhotoRegion: ({ layerId, regionIndex, cropData }) =>
    dispatch(
      editorActions.setImageCropDataForPhotoRegion({
        layerId,
        regionIndex,
        cropData,
      })
    ),
  setImagePositionForPhotoRegion: ({ layerId, regionIndex, position }) =>
    dispatch(
      editorActions.setImagePositionForPhotoRegion({
        layerId,
        regionIndex,
        position,
      })
    ),
  setFilterForPhotoRegion: ({ layerId, regionIndex, filter }) =>
    dispatch(
      editorActions.setFilterForPhotoRegion({
        layerId,
        regionIndex,
        filter,
      })
    ),
  setBorderThicknessForPhotoLayer: (layerId, thickness) =>
    dispatch(editorActions.setBorderThicknessForPhotoLayer(layerId, thickness)),
  setBorderPrintWidthForPhotoLayer: (layerId, width) =>
    dispatch(editorActions.setBorderPrintWidthForPhotoLayer(layerId, width)),
  setRegionBorderRadiusForPhotoLayer: (layerId, radius) =>
    dispatch(editorActions.setRegionBorderRadiusForPhotoLayer(layerId, radius)),
  setRegionBorderWidthForPhotoLayer: (layerId, width) =>
    dispatch(editorActions.setRegionBorderWidthForPhotoLayer(layerId, width)),
  setBorderStyleForPhotoLayer: (layerId, style) =>
    dispatch(editorActions.setBorderStyleForPhotoLayer(layerId, style)),
  setLayoutForPhotoLayer: (layerId, layout) =>
    dispatch(editorActions.setLayoutForPhotoLayer(layerId, layout)),
  updateTextLayerConfig: (layerId, style) =>
    dispatch(editorActions.updateTextLayerConfig(layerId, style)),
  setImageForSignatureLayer: (layerId, src) =>
    dispatch(editorActions.setImageForSignatureLayer(layerId, src)),
  setAddress: address => dispatch(editorActions.setAddress(address)),
  setAddressBookId: addressBookId => dispatch(editorActions.setAddressBookId(addressBookId)),
  setAddressBookEntry: addressBookEntry => dispatch(editorActions.setAddressBookEntry(addressBookEntry)),
  setPostDate: date => dispatch(editorActions.setPostDate(date)),
  changeItemDoubleDirect: (isDoubleDirect) => dispatch(editorActions.changeItemDoubleDirect(isDoubleDirect)),
  decreaseQuantity: () => dispatch(editorActions.decreaseQuantity()),
  increaseQuantity: () => dispatch(editorActions.increaseQuantity()),
  setPackQuantity: packQty => dispatch(editorActions.setPackQuantity(packQty)),
  setPackScheme: packScheme => dispatch(editorActions.setPackScheme(packScheme)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  clearPendingApprovalItems: () => dispatch(basketActions.clearPendingApprovalItems()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EditorContainer);
