import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Drawer from "react-bottom-drawer";
import Currency from "../Formatters/Currency"
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import Header from "../Header/Header";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Footer from "../Footer/Footer";
import MainContent from "../MainContent/MainContent";
import * as LAYER_TYPES from "../../constants/layer-types";
import Icon from "../Icon/Icon";
import { BarLoader } from "react-spinners";
import {
  Popconfirm,
  Modal as AntModal,
  Button as AntButton,
  Tabs,
  Col,
  Row,
  Popover,
  Progress,
  message,
} from "antd";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
  FontSizeOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  PictureOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import "./PhotoMagazineEditor.scss";

import pageTemplatesSquare from "../../constants/pageTemplatesSquare.json";
import pageTemplatesPortrait from "../../constants/pageTemplatesPortrait.json";
import pageTemplatesLandscape from "../../constants/pageTemplatesLandscape.json";

import coverTemplatesSquare from "../../constants/coverTemplatesSquare.json";
import coverTemplatesPortrait from "../../constants/coverTemplatesPortrait.json";
import coverTemplatesLandscape from "../../constants/coverTemplatesLandscape.json";

import ThemeModalContainer from "../ThemeModal";
import TemplateHtml from "../TemplateSelect/TemplateHtml";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import PagesModal from "./PagesModal";
import FontFamilySelectionModal from "../Editor/FontFamilySelectionModal";
import { FONT_FAMILIES } from "../../constants/text-style-options";

const TEMPLATE_CATEGORIES = ["all", "text", "minimal", "collage", "2", "3", "4"]; // "overlap"
const { TabPane } = Tabs;


// const FONT_FAMILIES = [
//   "Arial",
//   "Helvetica",
//   "Verdana",
//   "Calibri",
//   "Noto",
//   "Lucida Sans",
//   "Gill Sans",
//   "Segoe UI",
//   "Times New Roman",
//   "Rockwell",
//   "Courier",
//   "Chalkduster",
//   "Brush Script MT",
// ];


const MAGAZINE_TOTAL_PAGES = 23;
// const TEMPLATES = templates;

const TemplateChooseModal = ({
  showCoverTemplates,
  isTemplateChooseModalVisible,
  hideTemplateChooseModal,
  handleTemplateChoosed,
  currentTemplateName,
  selectedCategory,
  category,
  item,
  templateForPageNumber,
}) => {
  const width = item?.toJS().productDimensions.width
  const height = item?.toJS().productDimensions.height
  const isSquare = width === height
  const isLandscape = width > height
  const isPortrait = width < height

  let allCoverTemplates;
  let allPageTemplates;
  
  if (isSquare){
    allCoverTemplates = coverTemplatesSquare
    allPageTemplates = pageTemplatesSquare
  } else if (isLandscape) {
    allCoverTemplates = coverTemplatesLandscape
    allPageTemplates = pageTemplatesLandscape
  } else if (isPortrait){
    allCoverTemplates = coverTemplatesPortrait
    allPageTemplates = pageTemplatesPortrait
  }

  const templatesRef = useRef(allPageTemplates);

  const templates = templatesRef?.current;
  // const templates = item.toJS().pagestemplates;
  const coverTemplatesRef = useRef(allCoverTemplates);

  const coverTemplates = coverTemplatesRef?.current;
  // const coverTemplates = item.toJS().coverTemplates;
  const getActiveTemplate = (templateName, category) => {
    if (currentTemplateName) {
      return templateName === currentTemplateName;
    } else {
      //add minimal template name condition as well
      return category === "minimal" && templateName === "single-image-default-template";
    }
  };
  // let templateCanvasColor =
  //   item
  //     .toJS()
  //     .layers.filter(
  //       layer => layer.page === templateForPageNumber && layer.id !== "EXTRA_TEXT_LAYER"
  //     )[0]?.config.border?.style?.color ||
  //   item.toJS().product_options.globalTheme?.style?.color ||
  //   "#FFF";
  // if (templateCanvasColor === "#FFFFFF") {
  //   templateCanvasColor = "#CCCCCC";
  // }

  const TemplateComponent = (newTemplate, template, index) => {
    return (
      <div
        key={`${template}-${index}`}
        onClick={() => handleTemplateChoosed(template)}
        className="templateContainer"
      >
        <div
          style={{
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* {template.name} */}
          <TemplateHtml
            templateSelect={true}
            genericLayerProps={{ canvasDimensions: null, screenshotMode: false }}
            layer={{
              page: 1,
              config: {
                border: {
                  style: {
                    showBorder: true,
                    color: "rgb(0,0,0)",
                  },
                  thickness: 0.15,
                },
              },
            }}
            active={getActiveTemplate(template.name, template.category)}
            templateData={newTemplate}
            canvasColor={"#FFFFFF"}
            scaleBy={0.3}
            showImages={false}
            uniqueID="present"
            cover={false}
          />
        </div>
      </div>
    );
  };

  const customTabName = category => {
    if (category === "2" || category === "3" || category === "4" || category === "5+")
      return (
        <>
          <PictureOutlined style={{ marginRight: 6 }} />
          {category}
        </>
      );
    else return category;
  };

  // templates.forEach(function(item, i) {
  //   if (item.name === currentTemplateName) {
  //     templates.splice(i, 1);
  //     templates.unshift(item);
  //   }
  // });

  // coverTemplates.forEach(function(item, i) {
  //   if (item.name === currentTemplateName) {
  //     coverTemplates.splice(i, 1);
  //     coverTemplates.unshift(item);
  //   }
  // });

  let coverTemplateCanvasColor =
    item.toJS().layers.filter(layer => layer.page === 0 && layer.id !== "EXTRA_TEXT_LAYER")[0]
      ?.config.border?.style?.color ||
    item.toJS().product_options.globalTheme?.style?.color ||
    "#cccccc";
  if (coverTemplateCanvasColor === "#FFFFFF") {
    coverTemplateCanvasColor = "#CCCCCC";
  }
  return (
    <Modal
      // visible={isTemplateChooseModalVisible}
      isOpen={isTemplateChooseModalVisible}
      onClose={hideTemplateChooseModal}
      title={<span style={{ textAlign: "center", display: "block" }}>Choose a Template</span>}
      style={{ alignItems: "center", backgroundColor: "#FAF6F0" }}
      // width={650}
      // footer={[]}

      onCancel={hideTemplateChooseModal}
    >
      {!showCoverTemplates && (
        <Tabs
          style={{
            textTransform: "capitalize",
            gap: "0px", // 30px
            margin: "20px 0",
            justifyContent: "center",
            marginTop: "0px",
          }}
          defaultActiveKey={category}
        >
          {TEMPLATE_CATEGORIES.map((category, index) => (
            <TabPane
              tab={customTabName(category)}
              key={index}
              style={{ overflowY: "scroll", backgroundColor: "#FAF6F0", paddingTop: "20px" }}
            >
              <div className="templateSelectModal" key={index}>
                {(() => {
                  const currentTemplate = templates.filter(
                    template => template.name === currentTemplateName && template.name !== "blank"
                    // (category === "all" || template.category === category) &&
                  );
                  let imageCount = 0;
                  if (
                    category === "all" ||
                    currentTemplate[0]?.category === category ||
                    category === imageCount
                  ) {
                    return currentTemplate.map((template, index) => {
                      // const newTemplate = {
                      //   ...template,
                      //   name: template.name + category,
                      // };
                      return null //TemplateComponent(newTemplate, template, index);
                    });
                  }
                })()}
                {/* {templates
                  .filter(
                    template =>
                      template.name === currentTemplateName &&
                      (category === "all" || template.category === category) &&
                      template.name === currentTemplateName
                  )
                  .map((template, index) => {
                    const newTemplate = {
                      ...template,
                      name: template.name + category,
                    };
                    return TemplateComponent(newTemplate, template, index);
                  })} */}

                {
                  <>
                    {(category === "text" ||
                      category === "minimal" ||
                      category === "overlap" ||
                      category === "collage") &&
                      templates
                        .filter(
                          template =>
                            template.category === category
                        )
                        .map((template, index) => TemplateComponent(template, template, index))}
                    {category === "all" &&
                      templates
                        .filter(
                          template =>
                            template.name !== "blank"
                        )
                        .map((template, index) => {
                          const newTemplate = {
                            ...template,
                            name: template.name + "-all",
                          };
                          return TemplateComponent(newTemplate, template, index);
                        })}
                    {category === "2" &&
                      templates
                        .filter(template => {
                          let imagePlaceholderCount = 0;
                          imagePlaceholderCount = template.placeholders.filter((p) => p.type === "IMAGE_PLACEHOLDER").length
                          if (imagePlaceholderCount === 2 && template.name !== currentTemplateName) {
                            return template;
                          }
                          // eslint-disable-next-line
                          return;
                        })
                        .map((template, index) => {
                          const newTemplate = {
                            ...template,
                            name: template.name + category,
                          };
                          return TemplateComponent(newTemplate, template, index);
                        })}
                    {category === "3" &&
                      templates
                        .filter(template => {
                          let imagePlaceholderCount = 0;
                          imagePlaceholderCount = template.placeholders.filter((p) => p.type === "IMAGE_PLACEHOLDER").length
                          if (imagePlaceholderCount === 3 && template.name !== currentTemplateName)
                            return template;
                          // eslint-disable-next-line
                          return;
                        })
                        .map((template, index) => {
                          const newTemplate = {
                            ...template,
                            name: template.name + category,
                          };
                          return TemplateComponent(newTemplate, template, index);
                        })}
                    {category === "4" &&
                      templates
                        .filter(template => {
                          let imagePlaceholderCount = 0;
                          imagePlaceholderCount = template.placeholders.filter((p) => p.type === "IMAGE_PLACEHOLDER").length
                          if (imagePlaceholderCount === 4 && template.name !== currentTemplateName)
                            return template;
                          // eslint-disable-next-line
                          return;
                        })
                        .map((template, index) => {
                          const newTemplate = {
                            ...template,
                            name: template.name + category,
                          };
                          return TemplateComponent(newTemplate, template, index);
                        })}
                    {category === "5+" &&
                      templates
                        .filter(template => {
                          let imageCount = 0;
                          if (imageCount >= 5) return template;
                          // eslint-disable-next-line
                          return;
                        })
                        .map((template, index) => {
                          const newTemplate = {
                            ...template,
                            name: template.name + category,
                          };
                          return TemplateComponent(newTemplate, template, index);
                        })}
                  </>
                }
              </div>
            </TabPane>
          ))}
        </Tabs>
      )}
      {showCoverTemplates && (
        <div
          style={{
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            gap: "30px",
            margin: "20px 0",
            justifyContent: "center",
            backgroundColor: "#FAF6F0",
            marginTop: "0px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div className="templateSelectModal">
            {coverTemplates
              .filter(template => template.name === currentTemplateName)
              .map(template => (
                <div onClick={() => handleTemplateChoosed(template)} className="templateContainer">
                  <div
                    style={{
                      pointerEvents: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* {template.name} */}
                    <br />
                    {/* <Template
                    active={getActiveTemplate(template.name, template.category)}
                    templateData={template}
                    scaleBy={0.3}
                    showImages={false}
                    uniqueID="present"
                    canvasColor={coverTemplateCanvasColor}
                    cover={true}
                  /> */}
                    <TemplateHtml
                      templateSelect={true}
                      genericLayerProps={{ canvasDimensions: null, screenshotMode: false }}
                      layer={{
                        page: 1,
                        config: {
                          border: {
                            style: {
                              showBorder: false,
                              color: "rgb(0,0,0)",
                            },
                            thickness: 0.035,
                          },
                        },
                      }}
                      active={getActiveTemplate(template.name, template.category)}
                      templateData={template}
                      canvasColor={"#FFFFFF"}
                      scaleBy={0.3}
                      showImages={false}
                      uniqueID="present"
                      cover={false}
                    />
                  </div>
                </div>
              ))}
            {coverTemplates
              .filter(template => template.name !== currentTemplateName)
              .map((template, index) => (
                <div key={index} onClick={() => handleTemplateChoosed(template)} className="templateContainer">
                  <div
                    style={{
                      pointerEvents: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* {template.name} */}
                    <br />
                    {/* <Template
                    active={getActiveTemplate(template.name, template.category)}
                    templateData={template}
                    scaleBy={0.3}
                    showImages={false}
                    uniqueID="present"
                    canvasColor={coverTemplateCanvasColor}
                    cover={true}
                  /> */}
                    <TemplateHtml
                      templateSelect={true}
                      genericLayerProps={{ canvasDimensions: null, screenshotMode: false }}
                      layer={{
                        page: 1,
                        config: {
                          border: {
                            style: {
                              showBorder: false,
                              color: "rgb(0,0,0)",
                            },
                            thickness: 0.035,
                          },
                        },
                      }}
                      active={getActiveTemplate(template.name, template.category)}
                      templateData={template}
                      canvasColor={"#FFFFFF"}
                      scaleBy={0.3}
                      showImages={false}
                      uniqueID="present"
                      cover={false}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

const PhotoMagazineEditor = ({
  product,
  item,
  saveButtonText,
  coverTextModalOpen,
  onCoverTextModalAutoShown,
  onCancel,
  onStartUpload,
  onClickSave,
  onClickPage,
  onChangeCoverText,
  onSwapImages,
  onDeleteImage,
  setBorderColor,
  handleTemplateSelect,
  handleSaveTemplate,
  templateCallback,
  handleToggleBorder,
  handleInsertPage,
  handleMakeBlank,
  handleFillRestBlank,
  handleMoreModalOpen,
  handleBorderPopupOpen,
  borderOverridePageNumber,
  setGlobalTextFont,
  openCoverTextEditor,
  moreOptionPageNumber,
  selectImageUploadCount,
  reRender,
  handleImageSwapStart,
  swapImageFromTemplate,
  handlePageReorder,
  images,
  toggleCropForPage,
  toggleDragForPage,
  pricingScheme,
  turnOffAllDragTools,
  uploadingFirstTime,
  cropperOpen
}) => {
  const [isPagesModalOpen, setPagesModal] = useState(false);
  const [templateForPageNumber, setTemplateForPageNumber] = useState(null);
  const [currentTemplateName, setCurrentTemplateName] = useState(null);
  const [currentTemplateCategory, setCurrentTemplateCategory] = useState(null);
  const [isTemplateChooseModalVisible, setIsTemplateChooseModalVisible] = useState(false);
  const [isThemeModalVisible, setThemeModalVisibility] = useState(false);
  const [isDeleteConfirmationAlertVisible, setDeleteConfirmationAlertVisible] = useState(false);
  const [showCoverTemplates, setShowCoverTempate] = useState([]);
  const [pageNumberToAddBorder, setPageNumberToAddBorder] = useState(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [currentMoreOptionsConfig, setCurrentMoreOptionsConfig] = useState(null);
  const [showBorderOverridePopup, setShowBorderOverridePopup] = useState(false);
  //const [currentBorderOverrideConfig, setBorderOverrideConfig] = useState(null);
  const [isGlobalFontModalOpen, setGlobalFontOpen] = useState(false);
  const [isPageInputOpen, setPageInputOpen] = useState(false);
  const [pagesInputValue, setPagesInputValue] = useState(0);

  const [isFillingBlank, setIsFillingBlank] = useState(false);

  
  const [isFillBlankModalVisible, setShowFillBlankModalVisible] = useState(false);

  const [footerMinimised, setFooterMinimised] = useState(false);

  const [isThemesConfirmOpen, setThemesConfirmOpen] = useState(false);
  const [isBordersConfirmOpen, setBordersConfirmOpen] = useState(false);
  const [isFontConfirmOpen, setFontConfirmOpen] = useState(false);

  const [
    deleteConfirmationAlertVisibleForIndex,
    setDeleteConfirmationAlertVisibleForIndex,
  ] = useState(null);

  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = React.useState(false);
  const openInfoDrawer = React.useCallback(() => setIsInfoDrawerVisible(true), []);
  const closeInfoDrawer = React.useCallback(() => setIsInfoDrawerVisible(false), []);

  const totalPagesRequired = product.get("min_pages") || MAGAZINE_TOTAL_PAGES;
  const maxPages = totalPagesRequired // item.get("maxPages")

  const showThemesModal = pageNumber => {
    setPageNumberToAddBorder(pageNumber);
    setThemeModalVisibility(true);
  };

  const closeThemesModal = () => {
    setThemeModalVisibility(false);
  };

  const handleThemesConfirmClose = () => {
    setThemesConfirmOpen(false);
  };

  const handleThemesConfirmOK = () => {
    setThemesConfirmOpen(false);
    showThemesModal(null)
  };

  const handleThemesConfirmOpen = () => {
    setThemesConfirmOpen(true);
  };

  const handleBordersConfirmClose = () => {
    setBordersConfirmOpen(false);
  };

  const handleBordersConfirmOK = () => {
    setBordersConfirmOpen(false);
    handleToggleBorderSelected(false)
  };

  const handleBordersConfirmOpen = () => {
    setBordersConfirmOpen(true);
  };

  const handleOpenPageModal = () => {
    turnOffAllDragTools()
    setPagesModal(true)
  }

  const handleFontConfirmClose = () => {
    setFontConfirmOpen(false);
  };

  const handleFontConfirmOK = () => {
    setFontConfirmOpen(false);
    setGlobalFontOpen(true)
  };

  const handleFontConfirmOpen = () => {
    setFontConfirmOpen(true);
  };


  const layersWithPhotos =
    item &&
    item
      .get("layers")
      .filter(
        layer =>
          layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
      )
      .sortBy(layer => layer.get("page"));
  
  const bookFull = layersWithPhotos.size === maxPages

  const pagesAdded = (layersWithPhotos && layersWithPhotos.size) || 0;
  const pagesRemaing = totalPagesRequired - pagesAdded;
  const pageCompletionPercent = Math.round((pagesAdded / totalPagesRequired) * 100) || 0;

  const doAllPagesHaveAPhoto = layersWithPhotos && pagesAdded >= totalPagesRequired;
  const minimumPagesMet = pagesAdded >= totalPagesRequired

  
  //const doMinimumPageCountHavePhotos = layersWithPhotos && pagesAdded >= totalPagesRequired;
  const additionalPages = pagesAdded < totalPagesRequired ? 0 : pagesAdded - product.get("min_pages")
  const costPerAdditionalPages = product.get("price_per_additional_page")
  const totalCostForAdditionalPages = additionalPages * costPerAdditionalPages

  let pageCountIsFullBook = false
  if (totalPagesRequired === pagesAdded){
    pageCountIsFullBook = true
  } else if (pagesAdded > totalPagesRequired){
    if (pagesAdded % 2 !== 0){ // odd page count
      pageCountIsFullBook = true
    }
  }

  const allowProceeding = doAllPagesHaveAPhoto && minimumPagesMet && pageCountIsFullBook

  let additionalPagesRoundedDown = additionalPages % 2 === 0 ? additionalPages : additionalPages-1
  
  const dynamicPricingScheme = getPricingSchemeForProductAndQuantity({
    productId: product.get('id'),
    quantity: 1,
    currency: "GBP",
    additionalPages: additionalPagesRoundedDown,
    additionalPagesCost: costPerAdditionalPages
  });

  const productName = product.get("name")
  const productSecondaryName = product.get("secondary_name")


  const handleToggleBorderSelected = (setColorToo = false, pageNumber) => {
    if(pagesAdded < 2){
      message.open({ content: "Borders can only be applied once you have inner pages", duration: 4 });
    } else{
      handleToggleBorder(setColorToo, pageNumber)
    }
  }

  const handleDragEnd = result => {
    if (result.destination) {
      onSwapImages(result.source.index, result.destination.index);
    }
  };

  const handleClickDelete = index => {
    setDeleteConfirmationAlertVisible(!isDeleteConfirmationAlertVisible);
    setDeleteConfirmationAlertVisibleForIndex(index);
  };

  const handleClickDeleteCancel = (index, value = false) => {
    setDeleteConfirmationAlertVisible(value);
    if (!value) {
      setDeleteConfirmationAlertVisibleForIndex(null);
    }
  };

  const handleClickDeleteConfirm = page => {
    setDeleteConfirmationAlertVisible(false);
    setDeleteConfirmationAlertVisibleForIndex(null);
    onDeleteImage(page);
  };

  const handleTemplateChoosed = template => {
    handleSaveTemplate(template, templateForPageNumber);
    // handleTemplateSelect(template, templateForPageNumber);
    // setTemplateForPageNumber(null);
    hideTemplateChooseModal();
  };


  const hideTemplateChooseModal = () => {
    setCurrentTemplateName(null);
    setCurrentTemplateCategory(null);
    setIsTemplateChooseModalVisible(false);
  };
  const handleInsertMorePages = () => {
    if (pagesInputValue > 0) {
      setPageInputOpen(false);
      selectImageUploadCount(pagesInputValue);
      setPagesInputValue(0);
      onStartUpload();
    }
  };
  const handlePageInput = val => {
    const maxPagesToBeAdded = maxPages - layersWithPhotos.size;
    if (val < 0 || val > maxPagesToBeAdded) {
      return;
    }
    setPagesInputValue(val);
  };
  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",

  //   // style page row if dragging
  //   border: isDragging ? "1px solid #f8f8f8" : "none",
  //   boxShadow: isDragging ? "1px 1px 11px 0px #ccc" : "none",

  //   // styles we need to apply on draggables
  //   ...draggableStyle,
  // });

  const EnterPageNumber = () => {
    return (
      <Col span={24} align="center" justify="center" style={{ padding: "10px" }}>
        {pagesRemaing > 0 && (
          <React.Fragment>
            <span style={{ fontSize: "11px", display: "block", marginBottom: "15px" }}>
              (<strong>{pagesRemaing}</strong> required to complete book)
            </span>
          </React.Fragment>
        )}
        <Col span={24}>
          <Row justify="center" align="space-around">
            <MinusCircleOutlined
              style={{ fontSize: 30 }}
              onClick={() => handlePageInput(pagesInputValue - 1)}
            />
            <div style={{ fontSize: "22px" }}>{pagesInputValue}</div>
            <PlusCircleOutlined
              style={{ fontSize: 30 }}
              onClick={() => handlePageInput(pagesInputValue + 1)}
            />
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: "15px" }}>
          <Button
            theme="dark-blue"
            priority={"primary"}
            label={`Insert ${pagesInputValue > 0 ? `${pagesInputValue}` : "a"} ${
              pagesInputValue > 1 ? "Pages" : "Page"
            }`}
            onClick={handleInsertMorePages}
            disabled={pagesInputValue ? false : true}
            size="small"
          />
        </Col>
      </Col>
    );
  };
  const MoreOptions = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* <AntButton
          type="default"
          style={{ height: "40px", margin: "10px" }}
          onClick={() => {
            handleMakeBlank();
            setShowMoreModal(false);
          }}
        >
          Make Blank
        </AntButton> */}
        {bookFull && (
          <p style={{fontSize: '12px'}}>You cannot add any additional pages because you are at the maximum page count</p>
        )}
        <AntButton
          type="default"
          disabled={bookFull}
          style={{ height: "40px", margin: "10px" }}
          onClick={() => {
            handleInsertPage();
            setShowMoreModal(false);
          }}
        >
          Insert a Page
        </AntButton>
        <AntButton
          type="default"
          disabled={bookFull}
          style={{ height: "40px", margin: "10px" }}
          onClick={() => {
            handleInsertPage(currentMoreOptionsConfig);
            setShowMoreModal(false);
          }}
        >
          Duplicate Page
        </AntButton>
      </div>
    );
  };

  const BorderOverridePopup = opts => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>
          You already have a template applied.
          <br />
          Applying a border will replace your existing template.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AntButton
            type="default"
            style={{ margin: "1%" }}
            onClick={() => {
              setShowBorderOverridePopup(false);
            }}
          >
            Cancel
          </AntButton>
          <AntButton
            type="primary"
            style={{ margin: "1%" }}
            onClick={() => {
              if (opts.layer) {
                handleToggleBorderSelected(false, opts.layer.get("page"));
              }
              //handleInsertPage(currentBorderOverrideConfig);
              setShowBorderOverridePopup(false);
            }}
          >
            Apply Border
          </AntButton>
        </div>
      </div>
    );
  };
  const closePagesModal = () => {
    setPagesModal(false);
  };
  const onChangeFont = font => {
    setGlobalFontOpen(false);
    setGlobalTextFont(font);
  };
  const handleTemplateSelectVisible = (pageNumber, shouldShowCoverTemplate) => {
    setTemplateForPageNumber(pageNumber);
    const currentTemplateName = item.toJS().layers.filter(layer => layer.page === pageNumber)[
      shouldShowCoverTemplate ? 0 : 0
    ].config.template?.name;
    setCurrentTemplateName(currentTemplateName);
    setCurrentTemplateCategory(
      item.toJS().layers.filter(layer => layer.page === pageNumber)[shouldShowCoverTemplate ? 0 : 0]
        .config.template?.category
    );
    setShowCoverTempate(shouldShowCoverTemplate);
    setIsTemplateChooseModalVisible(true);
  };
  const handleCropForPage = cropLayer => {
    const currentTemplate = item
      .toJS()
      .layers.filter(layer => layer.type === LAYER_TYPES.PHOTO && layer.page === cropLayer.page)[0]
      .config.template;
    
    let imagePlaceholderCount = 0

    if (currentTemplate){
      const placeholders = currentTemplate.placeholders.filter(
        placeholder => placeholder.type === "IMAGE_PLACEHOLDER"
      );
      imagePlaceholderCount = placeholders.length
    }
    
    if (cropLayer.page === 0 && imagePlaceholderCount === 1) {
      toggleCropForPage(cropLayer.page);
      //return onClickPage(cropLayer.page);
    }
    // const numberOfPlaceholders=()
    toggleCropForPage(cropLayer.page);
  };

  const handleTextSelect = pageNumber => {
    const currentTemplate = item
      .toJS()
      .layers.filter(layer => layer.type === LAYER_TYPES.PHOTO && layer.page === pageNumber)[0]
      .config.template;
    const textLayerId = currentTemplate.placeholders.find(
      placeholder => placeholder.type === "TEXT_PLACEHOLDER"
    );
    if (textLayerId) {
      templateCallback(textLayerId.id, pageNumber, currentTemplate);
    }
  };

  // const handleDoubleTap = (page, layer, isDragEnabled) => {
  //   if (!layer.toJS().config.template) {
  //     if (isDragEnabled){
  //       toggleDragForPage(page)
  //     }
      
  //     onClickPage(page);
  //   }
  // };

  // const handleTap = () => {
  //   if (!hasSeenCropTip) {
  //     setHasSeenCropTip(true);
  //     //message.open({ content: "Double-tap or tap & hold to edit a photo", duration: 4 });
  //   }
  // };

  // const pressOption = {
  //   recognizers: {
  //     press: {
  //       time: 500,
  //       threshold: 50,
  //     },
  //   },
  // };

  // const handlePress = (page, layer, isDragEnabled) => {
  //   if (!isDragEnabled && !layer.toJS().config.template) {
  //     onClickPage(page);
  //   }
  // };


  const handleOnClickPreview = () => {
    if (allowProceeding){
      onClickSave()
    } else{
      //onClickSave()
      setIsFillingBlank(false)
      setShowFillBlankModalVisible(true)
    }
  }

  const handleOnClickFillBlank = () => {
    setIsFillingBlank(true)

    setTimeout(() => {
      handleFillRestBlank(pagesRemaing).then(() => {
        setShowFillBlankModalVisible(false)
        setIsFillingBlank(false); // Update state again after handleFillRestBlank is completed
        onClickSave()
      });
    }, 200)
  }

  function getCursorPosition(event) {
    if (event.type === "touchmove") {
      const touch = event.touches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    var x = event.clientX;
    var y = event.clientY;
    return { x, y };
  }
  return (
    <React.Fragment>
      <TemplateChooseModal
        showCoverTemplates={showCoverTemplates}
        isTemplateChooseModalVisible={isTemplateChooseModalVisible}
        hideTemplateChooseModal={hideTemplateChooseModal}
        handleTemplateChoosed={handleTemplateChoosed}
        currentTemplateName={currentTemplateName}
        item={item}
        category={currentTemplateCategory}
        templateForPageNumber={templateForPageNumber}
      />
      {isPagesModalOpen && (
        <PagesModal
          isPagesModalOpen={isPagesModalOpen}
          item={item}
          images={images}
          closePagesModal={closePagesModal}
          layersWithPhotos={layersWithPhotos}
          templateCallback={templateCallback}
          reRender={reRender}
          handleDragEnd={handleDragEnd}
          onClickPage={onClickPage}
          handlePageReorder={handlePageReorder}
          bookFull={bookFull}
        />
      )}
      <ThemeModalContainer
        setBorderColor={setBorderColor}
        isVisible={isThemeModalVisible}
        closeThemesModal={closeThemesModal}
        pageNumberToAddBorder={pageNumberToAddBorder}
        item={item}
      />
      <FontFamilySelectionModal
        isOpen={isGlobalFontModalOpen}
        onClose={() => setGlobalFontOpen(false)}
        onChangeFont={onChangeFont}
        selectedFontFamily={item.getIn(["product_options", "fontFamily"] || "Arial")}
        fonts={FONT_FAMILIES}
      />

      <AntModal
        visible={isThemesConfirmOpen}
        onCancel={() => handleThemesConfirmClose()}
        title={<span style={{textAlign: 'center', display: 'block'}}>Entire Book Colour</span>}
        footer={null}
        >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
            <>
              <p>Note: You're about to choose a background colour for your <strong>entire</strong> book and this will change <u>all</u> of your pages. <br/><br/>You can still change individual pages using the single page editing tools above each page.</p>
              <Button
                theme="dark-blue"
                priority={"primary"}
                label={"Choose Book Colour"}
                onClick={() => handleThemesConfirmOK()}
                block
              />
              <Button
                label={"Cancel"}
                onClick={() => handleThemesConfirmClose()}
                block
                theme="dark-blue"
                priority="secondary"
                style={{border : '1px solid #4b5566'}}
              />
            </>
        </div>
      </AntModal>

      <AntModal
        visible={isBordersConfirmOpen}
        onCancel={() => handleBordersConfirmClose()}
        title={<span style={{textAlign: 'center', display: 'block'}}>Entire Book Border</span>}
        footer={null}
        >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
            <>
              {item.getIn(["product_options", "border"]) ? (
                <p>Note: You're about to remove borders <u>all</u> of your pages. <br/><br/>You can still change individual pages using the single page editing tools above each page.</p>
              ) : (
                <p>Note: You're about to apply borders to <u>all</u> of your pages. This will also replace and override any templates you currently have on any of your pages.<br/><br/>You can still change individual pages using the single page editing tools above each page.</p>
              )}
              
              <Button
                theme="dark-blue"
                priority={"primary"}
                label={item.getIn(["product_options", "border"]) ? "Remove Borders From All Pages" : "Add Borders To All Pages"}
                onClick={() => handleBordersConfirmOK()}
                block
              />
              <Button
                label={"Cancel"}
                onClick={() => handleBordersConfirmClose()}
                block
                theme="dark-blue"
                priority="secondary"
                style={{border : '1px solid #4b5566'}}
              />
            </>
        </div>
      </AntModal>

      <AntModal
        visible={isFontConfirmOpen}
        onCancel={() => handleFontConfirmClose()}
        title={<span style={{textAlign: 'center', display: 'block'}}>Entire Book Font</span>}
        footer={null}
        >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
            <>
              <p>Note: You're about to choose a font for your <strong>entire</strong> book and this will apply to all of the text on <u>all</u> of your pages, including the cover.</p>              
              <Button
                theme="dark-blue"
                priority={"primary"}
                label={"Choose Book Font"}
                onClick={() => handleFontConfirmOK()}
                block
              />
              <Button
                label={"Cancel"}
                onClick={() => handleFontConfirmClose()}
                block
                theme="dark-blue"
                priority="secondary"
                style={{border : '1px solid #4b5566'}}
              />
            </>
        </div>
      </AntModal>


      <AntModal
        visible={isFillBlankModalVisible}
        onCancel={() => setShowFillBlankModalVisible(false)}
        footer={null}
        title={<span style={{textAlign: 'center', display: 'block'}}>Fill your book?</span>}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {isFillingBlank && (
            <>
              <BarLoader color='#4b5566' height={5} width={125}/>
              <p style={{textAlign: 'center', fontSize: '12px'}}>Please wait while we fill your book with blank pages and prepare your book for preview</p>
            </>
          )}
          {!isFillingBlank && (
            <>
              <p>You haven't added all pages of your book - would you like us to fill the remainder of your book with blank pages?</p>
              <Button
                theme="dark-blue"
                priority={"primary"}
                label={"Yes - add blank pages"}
                onClick={handleOnClickFillBlank}
                block
                loading={isFillingBlank}
              />
              <Button
                label={"No - continue to add pages"}
                onClick={() => setShowFillBlankModalVisible(false)}
                block
                theme="dark-blue"
                priority="secondary"
                style={{border : '1px solid #4b5566'}}
              />
            </>
          )}
          
        </div>
      </AntModal>
      <Header
        title={
          <>
            {/* <span>{product.get("name")}</span> */}
            <span>Photo Book</span>
            <div className="photo-magazine-prebag__header-icon" onClick={openInfoDrawer} style={{position: 'absolute', top: 4, marginLeft: '5px'}}>
              <InfoCircleOutlined style={{ fontSize: 28, color: "#FFC107" }} />
            </div>
          </>
        }
        onClickTitleOrImage={openInfoDrawer}
        leftAction={<span style={{marginLeft: '5px'}}><Button theme="default" priority="tertiary" icon="bin" size="large" onClick={onCancel} /></span>}
        rightAction={
          <Button
            theme="dark-blue"
            priority={allowProceeding ? "primary" : "tertiary"}
            label={saveButtonText}
            onClick={handleOnClickPreview}
            disabled={false}
            size="small"
          />
        }
      />
      {/* <div style={{ height: 1, backgroundColor: "gray", marginLeft: 15, marginRight: 15 }} /> */}
      {/* <p className="photo-magazine-prebag__help-text">
        Tap a photo to crop, rotate & apply a border
        <br />
        Press and drag <Icon name="drag-handle" size="small" />
        to reorder your pages
      </p> */}
      {/* <br /> */}
      {(layersWithPhotos.size > 0 && !uploadingFirstTime) && (
        <div className="photo-magazine-prebag__help-text">
          <span className="photo-magazinge-prebag__help-title">Entire Book Editing Tools</span>
          {/* <AntButton onClick={() => showThemesModal(null)} type="primary">
          Global Theme
        </AntButton> */}
          <Row span={24} justify="space-around" style={{ lineHeight: "14px" }}>          
            <Col span={4} className="global-btn" onClick={handleOpenPageModal}>
              <Icon name="rearrange-pages" size="large" />
              <div style={{ marginTop: "2px" }}>Rearrange Pages</div>
            </Col>

            <Col
            span={4}
            className="global-btn"
            onClick={() => handleThemesConfirmOpen()}
            style={{ position: "relative" }}
            >
              {/* <BgColorsOutlined style={{ fontSize: 24 }} /> */}
              <img
                className="colour-icon"
                alt="colours"
                src={`${process.env.PUBLIC_URL}/images/colours.png`}
                style={{width: '30px'}}
              />
              <div style={{ marginTop: "5px" }}>Book Colour</div>
            </Col>
            
            {item.getIn(["product_options", "border"]) ? (
              <Col span={4} className="global-btn" onClick={() => handleBordersConfirmOpen()}>
                <Icon name="border-on" size="large" />
                <div>Remove Borders</div>
              </Col>
            ) : (
              <Col span={4} className="global-btn" onClick={() => handleBordersConfirmOpen()}>
                <Icon name="border-off" size="large" />
                <div>Add Borders</div>
              </Col>
            )}
            <Col span={4} className="global-btn" onClick={() => handleFontConfirmOpen()}>
              <FontSizeOutlined style={{ fontSize: 24 }} />
              <div style={{ marginTop: "10px" }}>Book Font</div>
            </Col>
            <Popover
              destroyTooltipOnHide={true}
              content={<EnterPageNumber />}
              trigger="click"
              disabled={layersWithPhotos.size >= maxPages}
              placement="left"
              visible={isPageInputOpen}
              onVisibleChange={val => {
                if (layersWithPhotos.size < maxPages) {
                  setPageInputOpen(val);
                }
                setPagesInputValue(pagesRemaing <= 0 ? 1 : pagesRemaing);
              }}
            >
              <Col span={4} className="global-btn">
                <PlusOutlined
                  style={{
                    fontSize: 30,
                    color: layersWithPhotos.size >= maxPages ? "#D3D3D3" : "black",
                  }}
                />
                <div>Add Pages</div>
              </Col>
            </Popover>
          </Row>
        </div>
      )}
      <MainContent scrollable className="photo-magazine-prebag">
        <div className="photo-magazine-prebag__pages-list">
          <div>
            {layersWithPhotos &&
              layersWithPhotos.map((layer, index) => {
                const isCoverPage = layer.get("page") === 0;
                const isEven = layer.get("page") % 2 === 0;
                const isLastPage = layer.get("page") === pagesAdded - 1;
                const lastPopulatedPage = layersWithPhotos.size === index + 1;
                const lastPageEven = lastPopulatedPage && isEven;
                const showSpreadBorder = layer.get("page") > 1 && (!isLastPage || (isLastPage && (pagesAdded !== totalPagesRequired ))) && !lastPageEven;
                const isOddSpread = !isEven && showSpreadBorder;
               
                return (
                  <div
                    id={`page-${layer.get("page")}`}
                    key={`page-${layer.get("page")}`}
                    className={`photo-magazine-prebag__page ${
                      showSpreadBorder
                        ? isEven
                          ? "photo-magazine-prebag__page_even"
                          : "photo-magazine-prebag__page_odd"
                        : ""
                    } ${isCoverPage ? 'cover' : ''}`}
                  >
                    {isOddSpread && (
                      <div className="spread-info">
                        <span>These 2 pages will be next to each other in a spread</span>
                      </div>
                    )}
                    {isOddSpread && (
                      <div className="page-divider"></div>
                    )}
                    {/* {isCoverPage && (
                      <>
                        <span style={{backgroundColor: item.toJS().product_options.coverColour}}>{`CoverColour:${item.toJS().product_options.coverColour}`}</span>
                      </>
                    )} */}
                    {!isCoverPage && (
                      <div className="photo-magazine-prebag__top-action">
                        <Popconfirm
                          style={{ marginRight: "10px" }}
                          visible={
                            isDeleteConfirmationAlertVisible &&
                            deleteConfirmationAlertVisibleForIndex === index
                          }
                          title="Are you sure you want to delete this page?"
                          onConfirm={() => handleClickDeleteConfirm(layer.get("page"))}
                          onCancel={() => handleClickDeleteCancel(index)}
                          onVisibleChange={value => handleClickDeleteCancel(index, value)}
                          okText="Delete"
                          cancelText="Cancel"
                          icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                          placement="left"
                        >
                          <div
                            className="photo-magazine-prebag__drag-handle"
                            onClick={() => handleClickDelete(index)}
                          >
                            <Icon name="bin" size="large"/>
                            {/* <MinusCircleOutlined style={{ fontSize: 25, color: "#F75E67", marginTop: '7px' }} /> */}
                          </div>
                        </Popconfirm>
                      </div>
                    )}
                    <div className="photo-magazine-prebag__page-number">
                      {isCoverPage ? "Front Cover" : `Page ${layer.get("page")}`}
                    </div>
                    {/* <Hammer
                      onTap={handleTap}
                      onDoubleTap={() => handleDoubleTap(layer.get("page"), layer, layer.get("isDragEnabled"))}
                      onPress={() =>
                        handlePress(layer.get("page"), layer, layer.get("isDragEnabled"))
                      }
                      options={pressOption}
                    > */}
                      <div
                        className="photo-magazine-prebag__preview"
                        onTouchStart={function(e) {
                          if (!layer.get("isDragEnabled")) {
                            return;
                          }
                          if (!layer.toJS().config.template) {
                            let isUp = false;
                            e.target.addEventListener("touchend", () => {
                              isUp = true;
                            });
                            //disableScrolling();
                            const target = e.target;
                            setTimeout(() => {
                              if (!isUp) {
                                e.preventDefault();
                                const image = document.createElement("div");
                                image.className = 'dragPreview';
                                image.style.border = "2px solid black";
                                image.style.height = "50px";
                                image.style.width = "50px";
                                const pos = getCursorPosition(e);
                                document.body.addEventListener("touchmove", e => {
                                  e.preventDefault();
                                  const pos = getCursorPosition(e);
                                  image.style.top = `${pos.y - 50}px`;
                                  image.style.left = `${pos.x - 25}px`;
                                });
                                image.style.top = `${pos.y}px`;
                                image.style.left = `${pos.x}px`;
                                image.style.zIndex = 1000;
                                image.style.position = "absolute";
                                image.style.borderRadius = "100%";
                                image.style.backgroundSize = "cover";
                                image.style.backgroundRepeat = "no-repeat";
                                image.style.backgroundPosition = "center";
                                document.body.appendChild(image);
                                target.style.opacity = "0.5";
                                handleImageSwapStart(layer.get("page"), target, image);
                              }
                            }, 200);
                          }
                        }}
                        onMouseDown={function(e) {
                          if (!layer.get("isDragEnabled")) {
                            return;
                          }
                          if (!layer.toJS().config.template) {
                            let isUp = false;
                            e.target.addEventListener("mouseup", () => {
                              isUp = true;
                            });
                            const target = e.target;
                            setTimeout(() => {
                              if (!isUp) {
                                const image = document.createElement("div");
                                image.className = 'dragPreview';
                                image.style.border = "2px solid black";
                                image.style.height = "50px";
                                image.style.width = "50px";
                                const pos = getCursorPosition(e);
                                document.body.addEventListener("mousemove", e => {
                                  const pos = getCursorPosition(e);
                                  image.style.top = `${pos.y - 50}px`;
                                  image.style.left = `${pos.x - 25}px`;
                                });
                                image.style.top = `${pos.y}px`;
                                image.style.left = `${pos.x}px`;
                                image.style.zIndex = 1000;
                                image.style.position = "absolute";
                                image.style.borderRadius = "100%";
                                image.style.backgroundSize = "cover";
                                image.style.backgroundRepeat = "no-repeat";
                                image.style.backgroundPosition = "center";
                                document.body.appendChild(image);
                                target.style.opacity = "0.5";
                                handleImageSwapStart(layer.get("page"), target, image);
                              }
                            }, 200);
                          }
                        }}
                      >
                        {/* <Ripples color="#FFFFFF" className="page-shadow"> */}
                          <HtmlRenderer
                            swapImageFromTemplate={swapImageFromTemplate}
                            longPressSwap={true}
                            // width={MOBILESCREEN ? 130 * 2.5 : 130}
                            width={window.innerWidth > 700 ? 325 : window.innerWidth-60}
                            isInteractive={false}
                            previewMode={false}
                            item={item.toJS()}
                            page={layer.get("page")}
                            // onClick={() =>
                            //   isCropModeOn &&
                            //   !layer.toJS().config.template &&
                            //   onClickPage(layer.get("page"))
                            // }
                            realBorder={true}
                            photoBookBorders={true}
                            lowResScaling={true}
                            key={`page-preview-${layer.get("page")}`}
                            templateCallback={templateCallback}
                            reRender={reRender}
                            uniquekey="mainScreen"
                            showBorderCanvas={false}
                          />
                        {/* </Ripples> */}
                      </div>
                    {/* </Hammer> */}
                    <div className="photo-magazine-prebag__actions">
                      <div className="photo-magazine-prebag__actions-container">
                        <span className="photo-magazine-prebag__actions__help-title">
                          {isCoverPage ? "Cover Editing Tools" : "Single Page Editing Tools"}
                        </span>
                        <div
                          className="photo-magazine-prebag__drag-handle"
                          onClick={() =>
                            handleTemplateSelectVisible(index, isCoverPage ? true : false)
                          }
                        >
                          <Icon name="select-layout" size="large"/>
                        </div>
                        <div className="photo-magazine-prebag__drag-handle less-opacity">
                          <div
                            onClick={
                              isCoverPage && !layer.toJS().config.template
                                ? null
                                : () => showThemesModal(layer.get("page"))
                            }
                            style={
                              isCoverPage && !layer.toJS().config.template ? { opacity: 0.3 } : {}
                            }
                          >
                            {/* <BgColorsOutlined style={{ fontSize: 25 }} /> */}
                            <img
                              className="colour-icon"
                              alt="colours"
                              src={`${process.env.PUBLIC_URL}/images/colours.png`}
                              style={{width: '28px'}}
                            />
                          </div>
                        </div>
                        {!layer.toJS().config.template ||
                        layer.toJS().config?.template?.name === "single-image-default-template" ? (
                          <React.Fragment>
                            {layer.get("page") !== 0 && (
                              <div
                                className="photo-magazine-prebag__drag-handle"
                                onClick={() => handleToggleBorderSelected(false, layer.get("page"))}
                              >
                                {layer.toJS().config.border.style &&
                                layer.toJS().config.border.style.showBorder ? (
                                  <Icon name="border-on" size="large" />
                                ) : (
                                  <Icon name="border-off" size="large" />
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {layer.get("page") !== 0 && (
                              <Popover
                                title="Apply Border"
                                content={<BorderOverridePopup layer={layer} />}
                                trigger="click"
                                visible={
                                  showBorderOverridePopup &&
                                  layer.get("page") === borderOverridePageNumber
                                }
                                onVisibleChange={value => setShowBorderOverridePopup(value)}
                              >
                                <div className="photo-magazine-prebag__drag-handle">
                                  <div
                                    onClick={() => {
                                      setShowBorderOverridePopup(true);
                                      handleBorderPopupOpen(layer.get("page"));
                                    }}
                                  >
                                    {/* <div className="photo-magazine-prebag__drag-handle"> */}
                                      {layer.toJS().config.border.style &&
                                      layer.toJS().config.border.style.showBorder ? (
                                        <Icon name="border-on" size="large" />
                                      ) : (
                                        <Icon name="border-off" size="large" />
                                      )}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </Popover>
                            )}
                          </React.Fragment>
                        )}
                        <div
                          className="photo-magazine-prebag__drag-handle"
                          onClick={() =>
                            layer.toJS().config?.template
                              ? handleCropForPage(layer.toJS())
                              : onClickPage(layer.get("page"))
                          }
                        >
                          <span
                            className={`${
                              layer.toJS().config.template
                                ? layer.toJS()?.isCropEnabled
                                  ? "icon-active"
                                  : ""
                                : ""
                            }`}
                          >
                            <Icon name="edit-image" size="large" />
                          </span>

                          {/* <ScissorOutlined
                              style={{
                                fontSize: 20,
                                color: layer.toJS().config.template
                                  ? layer.toJS()?.isCropEnabled
                                    ? "red"
                                    : "black"
                                  : "black",
                              }}
                            /> */}
                        </div>
                        {isCoverPage && (
                          <div
                            className="photo-magazine-prebag__drag-handle"
                            onClick={() => handleTextSelect(layer.get("page"))}
                          >
                            <FontSizeOutlined
                              style={{
                                fontSize: 27,
                                color: "black",
                              }}
                            />
                          </div>
                        )}
                        <div
                          className="photo-magazine-prebag__drag-handle"
                          onClick={() => toggleDragForPage(layer.get("page"))}
                        >
                          <span
                            className={`${
                              layer.toJS().config.template
                                ? layer.toJS()?.isDragEnabled
                                  ? "icon-active"
                                  : ""
                                : ""
                            }`}
                          >
                            <Icon name="swap-image" size="large"/>
                          </span>
                        </div>
                        {!isCoverPage && (
                          <Popover
                            title="Page Options"
                            content={<MoreOptions />}
                            placement="left"
                            trigger="click"
                            visible={showMoreModal && layer.get("page") === moreOptionPageNumber}
                            onVisibleChange={value => setShowMoreModal(value)}
                          >
                            <div className="photo-magazine-prebag__drag-handle">
                              <div
                                onClick={() => {
                                  setShowMoreModal(true);
                                  handleMoreModalOpen(layer.get("page"));
                                  setCurrentMoreOptionsConfig(layer.get("config").toJS());
                                }}
                              >
                                <Icon name="more-dots" size="large"/>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </div>
                      
                      {/* <div
                                  className="photo-magazine-prebag__drag-handle"
                                  {...provided.dragHandleProps}
                                >
                                  <Icon name="drag-handle" size="large" />
                                </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </MainContent>
      <Drawer
        duration={300}
        hideScrollbars={false}
        onClose={closeInfoDrawer}
        isVisible={isInfoDrawerVisible}
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <h2>About This Photo Book</h2>
            <Button
              priority="tertiary"
              theme="muted"
              icon="clear"
              onClick={closeInfoDrawer}
              iconSize="small"
            />
          </div>
          <div className="drawer-body">
            <div
              className={`drawer-body__info ${
                item.toJS().product_options?.globalTheme?.style?.color === "#FFFFFF" ? "white" : ""
              }`}
              style={{
                backgroundColor: `${item.toJS().product_options?.globalTheme?.style?.color}d9`,
              }}
            >
              <h4>{productName}</h4>
              <p>
                {productSecondaryName}
                {/* <strong>Size:</strong> 21cm x 21cm (8" x 8") */}
              </p>
              <p>
                <strong>Price:</strong> <Currency amount={dynamicPricingScheme.get("cost")}/>
              </p>
              <div style={{ margin: "15px 0px" }}>
                <div
                  style={{
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className={`coverPreviewContainer`}
                >
                  <div className="overlay"></div>
                  <HtmlRenderer
                    width={200}
                    isInteractive={false}
                    previewMode={false}
                    item={item.toJS()}
                    page={0}
                    realBorder={true}
                    photoBookBorders={true}
                    lowResScaling={true}
                    showBorderCanvas={false}
                    key={`info-page-preview`}
                    uniquekey="infoScreen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Footer padded background>
        {item && pagesAdded < totalPagesRequired ? (
          <div className="photo-magazine-prebag__page-indicator">
            <span style={{
                position: 'absolute',
                right: 5,
                top: 5,
                height: '35px',
                width: '35px',
                display: 'flex',
                alignItems: 'center',
                background: '#e0e0e073',
                justifyContent: 'center',
                borderRadius: '2px'
              }}>
              {footerMinimised ? (
                <UpOutlined onClick={() => setFooterMinimised(!footerMinimised)} />
              ) : (
                <DownOutlined onClick={() => setFooterMinimised(!footerMinimised)} />
              )}
            </span>
            {!footerMinimised && (
              <div>
                {pricingScheme && (
                  <div>
                    {additionalPagesRoundedDown > 0 && (
                      <p>(Includes {additionalPages} additional pages @ <Currency amount={costPerAdditionalPages}/> per 2 pages = <Currency amount={totalCostForAdditionalPages}/>)</p>
                    )}               
                  </div>
                )}
                {pagesAdded === 0 ? (
                  <p>Add {pagesRemaing} pages to your photo book.</p>
                ) : (
                  <React.Fragment>
                    {pagesAdded === 1 ? (
                      <p>
                        You've added a <strong style={{ color: "black" }}>cover</strong>.<br/>Add{" "}
                        <strong style={{ color: "black" }}>{pagesRemaing}</strong> {pagesRemaing > 1 ? 'pages' : 'page'} to complete
                        your photo book.
                      </p>
                    ) : (
                      <p>
                        You've added a  <strong style={{ color: "black" }}>cover</strong> and{" "}
                        <strong style={{ color: "black" }}>{pagesAdded - 1}</strong> {pagesAdded - 1 > 1 ? 'pages' : 'page'}.<br/>Add{" "}
                        <strong style={{ color: "black" }}>{pagesRemaing}</strong> {pagesRemaing > 1 ? 'pages' : 'page'} to complete
                        your photo book.
                      </p>
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
            <div style={{
              width: '70%',
              display: 'flex',
              fontSize: '10px',
              alignItems: 'center'
            }}>
              <Progress
                percent={pageCompletionPercent}
                showInfo={false}
                strokeColor={"#4C5666"}
                size="small"
              />
              <React.Fragment>
                <span style={{width:'33%', marginLeft: '5px'}}>
                  {pagesAdded - 1}/{totalPagesRequired-1} pages
                </span>
              </React.Fragment>
            </div>
          </div>
        ) : (
          <div className="photo-magazine-prebag__page-indicator complete">
            <span style={{
                position: 'absolute',
                right: 5,
                top: 5,
                height: '35px',
                width: '35px',
                display: 'flex',
                alignItems: 'center',
                background: '#e0e0e073',
                justifyContent: 'center',
                borderRadius: '2px'
              }}>
              {footerMinimised ? (
                <UpOutlined onClick={() => setFooterMinimised(!footerMinimised)} />
              ) : (
                <DownOutlined onClick={() => setFooterMinimised(!footerMinimised)} />
              )}
            </span>
            {!footerMinimised && (
              <div>
                {pricingScheme && (
                  <div>
                    {additionalPagesRoundedDown > 0 && (
                      <p>(Includes {additionalPages} additional pages @ <Currency amount={costPerAdditionalPages}/> per 2 pages = <Currency amount={totalCostForAdditionalPages}/>)</p>
                    )}
                  </div>
                )}
                  <p>You've added a <strong style={{ color: "black" }}>cover</strong> and all <strong style={{ color: "black" }}>{pagesAdded - 1}</strong> pages.<br/>Tap Preview to continue</p>
              </div>
            )}          
            <div style={{
              width: '70%',
              display: 'flex',
              fontSize: '10px',
              alignItems: 'center'
            }}>
              <Progress
                percent={pageCompletionPercent}
                showInfo={false}
                strokeColor={"#4C5666"}
                size="small"
              />
              <React.Fragment>
                <span style={{width:'33%', marginLeft: '5px'}}>
                  {pagesAdded - 1}/{totalPagesRequired-1} pages
                </span>
              </React.Fragment>
            </div>
          </div> 
        )}
        {item && pagesAdded === 0 ? (
          <Button
            block
            theme="dark-blue"
            label="Add Photos"
            onClick={onStartUpload}
            disabled={doAllPagesHaveAPhoto}
          />
        ) : null}
      </Footer>
    </React.Fragment>
  );
};

PhotoMagazineEditor.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default PhotoMagazineEditor;
