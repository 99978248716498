import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS } from "immutable";
import moment from "moment";

import * as STORAGE_KEYS from "../../constants/storage-keys";
import EditorContainer from "../../components/Editor/EditorContainer";
import Modal from "../../components/Modal/Modal";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import { routeCreators } from "../../lib/routes";
import transformDesignLayers from "../../lib/transform-design-layers";
import postsnapApi from "../../lib/apis/postsnap";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as editorActions, selectors as editorSelectors } from "../../store/ducks/editor";
import * as LAYER_TYPES from "../../constants/layer-types";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import { PRODUCT_TYPE_IDS, productsByProductId, productsByProductTypeId } from "../../data/products";
import * as designsData from "../../data/designs";

import SweetAlert from "../../components/SweetAlert/SweetAlert";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FramePicker from "../../components/FramePicker/FramePicker";
import FrameLayoutPicker from "../../components/FrameLayoutPicker/FrameLayoutPicker";
//import MASKS from "../../constants/masks";
import FRAMES from "../../constants/frame-options";
import { Tabs } from 'antd';
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";

const { TabPane } = Tabs;

class FramedPrintsEditorContainer extends Component {
  state = {
    itemForEditor: null,
    isItemDuplicationAlertVisible: false,
    toast: null,
    editorItemLoaded: false,
    editorItemLoading: true,
    isApprovalModalVisible: false,
    approvalModalItemId: null,
    approvalModalImage: null,
    renderItemLoading: false,
    renderItemLoaded: false,
    approvalModalItem: null,
    frameOptionsVisible: true
  };

  componentWillMount() {
    this.handleSelectProductType(PRODUCT_TYPE_IDS.FRAMED_PRINTS);
  }

  componentDidUpdate() {
    // if (this.editorContainer){
    //   const editorInstance = this.editorContainer.getWrappedInstance();
    //   console.log("Current step", editorInstance.editor.state.currentStep);
    // }
  }

  closeEditor = (closeBehavior = {}) => {
    // TODO: extract this to ENV var
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  setUpEditorItem = async ({ product, productTypeId, designId, replacingDesign }) => {
    localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM)
    //localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
  
    const productId = this.props.productId;
    productTypeId = productTypeId || this.props.productTypeId;
    product = product || productsByProductId.get(productId);

    const designDetail = await postsnapApi.designs.getDetails(designId);

    let layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

    if (replacingDesign){
      console.log(replacingDesign);
      const customisedPhotoLayers = replacingDesign.toJS().layers.filter(layer => {
        return layer.type === LAYER_TYPES.PHOTO && layer.config.layout[0].image !== undefined;
      });
      console.log(customisedPhotoLayers);
      const newDesignPhotoLayers = layers.filter(layer => {
        return layer.get('type') === LAYER_TYPES.PHOTO;
      });
      const newDesignNonPhotoLayers = layers.filter(layer => {
        return layer.get('type') !== LAYER_TYPES.PHOTO;
      });

      const mappedPhotoLayers = newDesignPhotoLayers.map( (photoLayer, index) => {
        if (customisedPhotoLayers[index]){
          return photoLayer.setIn(['config', 'layout', 0, 'image'], fromJS(customisedPhotoLayers[index].config.layout[0].image));
        } else{
          return photoLayer;
        }
        
      })

      //debugger;
      layers = newDesignNonPhotoLayers.concat(mappedPhotoLayers) //{...newDesignNonPhotoLayers, ...mappedPhotoLayers}
      //debugger;
    }

    let itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      weight: product.get("weight"),
      productTypeId,
      productId: product.get("id"),
      designId,
      quantity: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      designOptions: designDetail.data.data.preview_s3_keys || [],
      postDate: moment(),
      product_options: {
        frameSku: this.state.itemForEditor ? this.state.itemForEditor.getIn(['product_options', 'frameSku']) : 'black'
      },
    });


    if (itemData.getIn(["pages", "envelope"])) {
      itemData = itemData.update("layers", layers =>
        layers.concat(
          fromJS([
            {
              config: {
                rect: {
                  x: 0.1,
                  y: 0.7,
                  width: 0.5,
                  height: 0.6,
                },
                font: "Anaheim Gothic",
                size: 10,
                color: "0,0,0",
              },
              id: "ENVELOPE_ADDRESS",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              zindex: null,
              type: "Layer::Address",
            },
            {
              config: {
                rect: {
                  x: 0.695,
                  y: 0.94,
                  width: 0.26,
                  height: 0.23,
                },
              },
              id: "ENVELOPE_STAMP",
              page: PAGES_BY_PRODUCT_TYPE[productTypeId].envelope,
              type: "Layer::Indicia",
            },
          ])
        )
      );
    }
    //console.log("Model is", MODELS[product.get("slug")]);
    this.setState({
      itemForEditor: itemData,
      editorItemLoaded: true,
    });
  };

  openApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: true,
    });
  };

  closeApprovalModal = itemId => {
    this.setState({
      isApprovalModalVisible: false,
    });
  };

  closeApprovalModalAndDeleteItem = itemId => {
    this.props.deleteItem(itemId);
    this.closeApprovalModal();
  };

  handleApproveItem = item => {
    this.props.approveItem(item);
    this.closeApprovalModal();
    this.props.goToBasket();
    // this.setState({
    //   isItemDuplicationAlertVisible: true,
    // });
  };

  handleEditItem = itemId => {
    this.props.deleteItem(itemId);
    this.closeApprovalModal();
    const editorInstance = this.editorContainer.getWrappedInstance();
    editorInstance.goToFrontPage();
  };

  handleSelectProductType = async productTypeId => {
    // this.setState({
    //   editorItemLoading: true,
    // });
    // Get the first product ID for this product type ID
    const firstProductForProductType = productsByProductTypeId.get(productTypeId).find(p => {
      return p.get("url_slug") === this.props.productSlug;
    });

    /**
     * Get the first design for that product (the first design is the _only_ design, except for greeting cards which
     * are handled in a separate method)
     */
    const firstDesignForProductId = designsData.designsByProductId
      .get(this.props.productId)
      .first();

    
    this.setUpEditorItem({
      designId: firstDesignForProductId.get("id"), //firstDesignForProductId.get("id"),
      productTypeId: productTypeId,
      product: firstProductForProductType,
    });
  };

  handleChangeFrameOption = (newFrameSKU) => {
    console.log("Changed Frame to", newFrameSKU)
    const currentItem = this.state.itemForEditor;
    const changedItem = currentItem.set("product_options", fromJS({frameSku: newFrameSKU}));

    //this.props.setProductOptions({frameSku: newFrameSKU})

    this.setState({
      itemForEditor: changedItem,
    }, () => {
      //this.props.setEditorItem(this.state.itemForEditor);
      this.props.setProductOptions({frameSku: newFrameSKU})
      console.log(this.state.itemForEditor.toJS());
      // message
    });
  };

  handleChangeFrameDesign = (newDesign) => {

    const oldItemData = this.props.editorItem;
    console.log(oldItemData.toJS());
    this.setUpEditorItem({ designId: newDesign.get("id"), replacingDesign: oldItemData });



    // TODO: we then need to re-apply the photo layers
  };

  handleChangedEditorStep = (newStep) => {
    if(newStep !== 'PRODUCT_FRONT'){
      this.setState({
        frameOptionsVisible: false
      })
    } else {
      if(this.state.frameOptionsVisible === false){
        this.setState({
          frameOptionsVisible: true
        })
      }
    }
  }

  handleAddToBasket = async item => {
    this.setState({
      renderItemLoading: true,
    });
    const bagItem = await this.props.addBasketItemAsync(item.toJS());
    debugger;
    this.props.renderItem(bagItem.payload.id).then(async res => {
      const item = await this.props.getItem(bagItem.payload.id);
      const s3Key = item.payload.itemData.preview_s3_key;
      this.setState({
        approvalModalItemId: bagItem.payload.id,
        approvalModalImage: s3Key,
        renderItemLoading: false,
        approvalModalItem: item.payload.itemData
      });
      setTimeout(() => {
        this.openApprovalModal();
      }, 300);
    });
  };

  render() {
    
    const productNameForItem = "Framed Print"

    //const bleedMasks = MASKS[this.props.productSlug];

    const approvalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isApprovalModalVisible}
        onClose={this.closeApprovalModal}
        title="Your Framed Print"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={() => this.closeApprovalModalAndDeleteItem(this.state.approvalModalItemId)}
          />
        }
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Approve"
            onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
          />
        }
      >
        <MainContent scrollable={false} centeredVertically padded key="main-preview">
          {this.state.approvalModalItem && (
            <div>
              <HtmlRenderer
                item={this.state.approvalModalItem}
                page={0}
                previewMode={true}
                width={300}
              />
              {/* <PhotoTilePreview
                previewImage={this.state.approvalModalImage}
                item={this.state.approvalModalItem}
              /> */}
            </div>
          )}
        
          <p className="help-text text-center" style={{ zIndex: 1, marginTop: '30px' }}>
            This preview is for illustrative purposes only. Your framed print may differ slightly
            from what is shown here as a result of the trimming process.
            Please ensure any critical areas of your photo aren't too close to the edge of the frame.
          </p>
        </MainContent>
        <Footer padded key="footer">
          <div className="footer__split-buttons">
            <Button
              label="Edit"
              priority="secondary"
              onClick={() => this.handleEditItem(this.state.approvalModalItemId)}
            />
            <Button
              label="Approve"
              onClick={() => this.handleApproveItem(this.state.approvalModalItemId)}
            />
          </div>
        </Footer>
      </Modal>
    );

    if (this.state.editorItemLoading && !this.state.editorItemLoaded) {
      return (
        <FullScreenLoader
          key="loader"
          message="Loading your frame..."
          isVisible={this.state.editorItemLoading && !this.state.editorItemLoaded}
        />
      );
    }

    const frameOptions = FRAMES["20x16-framed-print"]; //TODO extract to productId

    const designs = designsData.designsByProductId.get(this.props.productId)
    const product = productsByProductId.get(this.props.productId)
    const productRatio = product.get('width')/product.get('height');

    return [
      <Modal
        key="editor-modal"
        isOpen={Boolean(this.state.itemForEditor)}
        onClose={this.closeEditor}
        hasHeader={false}
        animated={false}
      >
        <EditorContainer
          key="editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemForEditor}
          onClose={this.closeEditor}
          onSave={this.handleAddToBasket}
          onChangeStep={this.handleChangedEditorStep}
          saveButtonLabel="Add to Basket"
        />
        {this.state.frameOptionsVisible && (
          <Tabs defaultActiveKey="1" style={{
            padding: '10px',
            borderTop: '1px solid #e8e8e8'
          }}>
            <TabPane tab="Frame Options" key="frame-type">
              <FramePicker
                frameOptions={frameOptions}
                currentFrame={this.state.itemForEditor.getIn(['product_options', 'frameSku'])}
                onSelectFrameOption={this.handleChangeFrameOption}
              />
            </TabPane>
            <TabPane tab="Photos & Layout" key="layout">
              <FrameLayoutPicker 
                frameDesigns={designs}
                productTypeId={this.props.productTypeId}
                onSelectFrameDesign={this.handleChangeFrameDesign}
                productRatio={productRatio}
                currentDesignId={this.state.itemForEditor.get('designId')}
              />
            </TabPane>
          </Tabs>
        )}
      </Modal>,
      approvalModal,
      <SweetAlert
        key="duplication-alert"
        isOpen={this.state.isItemDuplicationAlertVisible}
        title="Checkout or Duplicate?"
        text={`Your ${productNameForItem} has been added to your basket. Would you like to checkout, or duplicate and edit your ${productNameForItem}?`}
        confirmButtonText="Checkout"
        cancelButtonText="Duplicate"
        onConfirm={this.handleDuplicationAlertGoToBasket}
        onCancel={this.handleDuplicationAlertDuplicate}
      />,
      <FullScreenLoader
        key="3d-loader"
        loader="bar"
        message="Please wait while we prepare a preview of your framed print ..."
        isVisible={this.state.renderItemLoading}
      />,
    ];
  }
}

const mapStateToProps = state => {
  const editorItem = editorSelectors.getItem(state);
  return {
    editorItem,
    currency: basketSelectors.getCurrency(state),
  }
};

const mapDispatchToProps = dispatch => ({
  setEditorItem: item => dispatch(editorActions.setEditorItem(item)),
  setProductOptions: productOptions => dispatch(editorActions.setProductOptions(productOptions)),
  setCurrency: currency => dispatch(basketActions.setCurrency(currency)),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  showDuplicateAlertForItem: itemId => dispatch(basketActions.showDuplicateAlertForItem(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FramedPrintsEditorContainer);
