import validateAddress from "./validate-address";
import { PRODUCT_TYPE_IDS, sharedShippingAddress } from '../data/products';

export default function canCheckout(bagItems, checkoutDetails, checkoutAddress, isSignedIn) {

  const checkoutShippingNeeded = bagItems.some(item => sharedShippingAddress(item.get('productTypeId')))

  const existingContactDataIsComplete = Object.keys(checkoutDetails).includes("email")

  const anyPrints = bagItems.some(item => item.get('productTypeId') === PRODUCT_TYPE_IDS.PHOTO_PRINT)

  const printsHavePostageScheme = (!anyPrints || (anyPrints && bagItems.some(item => item.get('productTypeId') === PRODUCT_TYPE_IDS.PHOTO_PRINT && item.getIn(["postageScheme", "cost"]))))

  const productTypeIdsForItemsNeedingAddressAndScheme = Object.keys(bagItems.filter(item => sharedShippingAddress(item.get('productTypeId'))).groupBy((item => item.get('productTypeId'))).toJS())
  

  const allProducTypeItemsHaveWhatsNeeded = productTypeIdsForItemsNeedingAddressAndScheme.every(productTypeId => {
    const itemwithProductTypeAndScheme = bagItems.find((item) => {
      return item.get('productTypeId') === parseInt(productTypeId) && item.getIn(["postageScheme", "id"])
    })
    return itemwithProductTypeAndScheme
  })

  const contactDetailsPresent = isSignedIn ? true : existingContactDataIsComplete
  const validAddress = validateAddress(checkoutAddress) === true

  const canCheckout = contactDetailsPresent && printsHavePostageScheme &&
    ((checkoutShippingNeeded && validAddress && allProducTypeItemsHaveWhatsNeeded)
    || !checkoutShippingNeeded)

  return canCheckout
}
