import PropTypes from "prop-types";
import { connect } from "react-redux";
import currencyFormatter from "currency-formatter";

import { selectors as basketSelectors } from "../../store/ducks/basket";

const Currency = ({ amount, currency, suffix, showFree }) => {
  if (showFree && amount === 0.0  ){
    return "Free"
  } else {
    return `${currencyFormatter.format(amount, { code: currency })}${suffix ? suffix :''}`;
  }
  
};

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Currency.defaultProps = {
  amount: 0,
  suffix: null,
  showFree: false,
};

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
});

export default connect(mapStateToProps)(Currency);
