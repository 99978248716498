import React, { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { Button } from "antd";
import camera from "../../images/edit-image.png";

const Template = ({
  templateData,
  scaleBy,
  clickCallback,
  exportable = undefined,
  showImages = true,
  uniqueID,
  active = false,
  canvasColor,
  cover = false,
}) => {
  const { placeholders, name, canvasSize, category } = templateData;
  const [canvasHeight] = useState(canvasSize.height * scaleBy);
  const [canvasWidth] = useState(canvasSize.width * scaleBy);
  const canvasRef = useRef();
  const initCanvas = useCallback(
    () =>
      new fabric.Canvas(`canvas_${name}_${uniqueID}`, {
        height: canvasHeight,
        width: canvasWidth,
        backgroundColor: canvasColor || "#cccccc", //hexToRgbA(canvasColor, 0.6),
      }),
    [canvasColor, canvasHeight, canvasWidth, name, uniqueID]
  );

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")";
    }
    throw new Error("Bad Hex");
  }

  useEffect(() => {
    const OFFSET = 15;
    const canvas = initCanvas();
    if (clickCallback) {
      let time;
      canvas.on("mouse:down", e => {
        time = new Date();
      });
      canvas.on("mouse:up", e => {
        if (!(new Date() - time > 150)) {
          if (e.target) {
            clickCallback(e.target.data);
          }
        }
      });
    }
    placeholders.forEach(placeholder => {
      if (placeholder.type === "IMAGE_PLACEHOLDER") {
        const { backgroundColor, rotation, scale, offset } = placeholder.properties;
        const topLeftCoords = {
          x: placeholder.position.topLeft[0] * canvasWidth,
          y: placeholder.position.topLeft[1] * canvasHeight,
        };
        const topRightCoords = {
          x: placeholder.position.topRight[0] * canvasWidth,
          y: placeholder.position.topRight[1] * canvasHeight,
        };
        const bottomLeftCoords = {
          x: placeholder.position.bottomLeft[0] * canvasWidth,
          y: placeholder.position.bottomLeft[1] * canvasHeight,
        };
        // const bottomRightCoords = { x: placeholder.position.bottomRight[0] * canvasWidth, y: placeholder.position.bottomRight[1] * canvasSize.height }

        const height = new fabric.Point(topLeftCoords.x, topLeftCoords.y).distanceFrom(
          bottomLeftCoords
        );

        const width = new fabric.Point(topLeftCoords.x, topLeftCoords.y).distanceFrom(
          topRightCoords
        );

        if (placeholder.properties.imageSrc && showImages) {
          fabric.Image.fromURL(
            placeholder.properties.imageSrc,
            image => {
              image.rotate(rotation);
              image.scaleToWidth(width - (typeof offset === "number" ? offset : OFFSET));
              if (image.getScaledHeight() < height) {
                image.scaleToHeight(height - (typeof offset === "number" ? offset : OFFSET));
                image.width =
                  width * (image.width / image.getScaledWidth()) -
                  (typeof offset === "number" ? offset : OFFSET);
              } else {
                image.height = height * (image.height / image.getScaledHeight());
              }
              image.top = topLeftCoords.y + (typeof offset === "number" ? offset : OFFSET) / 2;
              image.left = topLeftCoords.x + (typeof offset === "number" ? offset : OFFSET) / 2;
              image.selectable = false;
              image.data = placeholder.id;

              canvas.add(image);
            },
            { crossOrigin: "anonymous" }
          );
          const rect = new fabric.Rect({
            height: height - (typeof offset === "number" ? offset : OFFSET),
            width: width - (typeof offset === "number" ? offset : OFFSET),
            top: topLeftCoords.y + (typeof offset === "number" ? offset : OFFSET) / 2,
            left: topLeftCoords.x + (typeof offset === "number" ? offset : OFFSET) / 2,
            selectable: false,
            fill: backgroundColor.length > 0 ? "red" : "red", //hexToRgbA(canvasColor, 0.5)
            scaleX: scale.x,
            scaleY: scale.y,
            data: placeholder.id,
          });
          rect.rotate(rotation);
          canvas.add(rect);
        } else {
          fabric.Image.fromURL(
            camera,
            image => {
              image.rotate(rotation);
              image.scaleY = height / image.height / 4;
              image.scaleX = height / image.height / 4;
              const topOffset = height - image.getScaledHeight();
              const leftOffset = width - image.getScaledWidth();
              image.top = topLeftCoords.y + topOffset / 2;
              image.left = topLeftCoords.x + leftOffset / 2;
              image.selectable = false;
              image.data = placeholder.id;
              canvas.add(image);
            },
            { crossOrigin: "anonymous" }
          );
          console.log(category, "category");
          const rect = new fabric.Rect({
            height: height - (typeof offset === "number" ? offset : OFFSET),
            width: width - (typeof offset === "number" ? offset : OFFSET),
            top: topLeftCoords.y + (typeof offset === "number" ? offset : OFFSET) / 2,
            left: topLeftCoords.x + (typeof offset === "number" ? offset : OFFSET) / 2,
            selectable: false,
            // fill: backgroundColor.length > 0 ? hexToRgbA(canvasColor, 0.7) : "red",
            fill:
              category === "minimal" || category === "overlap_text"
                ? "transparent"
                : backgroundColor.length > 0
                ? "#FFF"
                : "red",
            stroke:
              category === "overlap" && placeholder.id === "2"
                ? hexToRgbA(canvasColor, 0.6)
                : "transparent",
            // strokeDasharray: "0,150,150",
            //strokeWidth: category === "overlap" && placeholder.id === "2" ? 10 : 0,
            scaleX: scale.x,
            scaleY: scale.y,
            data: placeholder.id,
          });
          rect.rotate(rotation);
          canvas.add(rect);
        }
      } else if (placeholder.type === "TEXT_PLACEHOLDER") {
        const {
          fontSize,
          verticalAlignment,
          horizontalAlignment,
          color,
          fontFamily,
          textAlignment,
          offset,
        } = placeholder.properties;

        const topLeftCoords = {
          x: placeholder.position.topLeft[0] * canvasWidth,
          y: placeholder.position.topLeft[1] * canvasHeight,
        };
        const topRightCoords = {
          x: placeholder.position.topRight[0] * canvasWidth,
          y: placeholder.position.topRight[1] * canvasHeight,
        };
        const bottomLeftCoords = {
          x: placeholder.position.bottomLeft[0] * canvasWidth,
          y: placeholder.position.bottomLeft[1] * canvasHeight,
        };
        // const bottomRightCoords = { x: placeholder.position.bottomRight[0] * canvasWidth, y: placeholder.position.bottomRight[1] * canvasSize.height }

        const height = new fabric.Point(topLeftCoords.x, topLeftCoords.y).distanceFrom(
          bottomLeftCoords
        );

        const width = new fabric.Point(topLeftCoords.x, topLeftCoords.y).distanceFrom(
          topRightCoords
        );

        const rect = new fabric.Rect({
          height: height - (typeof offset === "number" ? offset : OFFSET),
          width: width - (typeof offset === "number" ? offset : OFFSET),
          top: topLeftCoords.y + (typeof offset === "number" ? offset : OFFSET) / 2,
          left: topLeftCoords.x + (typeof offset === "number" ? offset : OFFSET) / 2,
          selectable: false,
          //fill: hexToRgbA(canvasColor, 0.2),
          fill: "#ccc",
          data: placeholder.id,
        });
        canvas.add(rect);

        let textToShow = "Lorem ipsum dolor sit amet\nconsectetur adipiscing elit,\n\nsed do eiusmod tempor incididunt\nut labore et dolore magna aliqua. Ut enim ad minim veniam\n\nquis nostrud exercitation ullamco laboris\nnisi ut aliquip ex ea commodo consequat."

        if (cover){
          textToShow = "Lorem ipsum\ndolor sit amet"
        }

        const text = new fabric.Text(textToShow, {
          height: height - (typeof offset === "number" ? offset : OFFSET),
          width: width - (typeof offset === "number" ? offset : OFFSET),
          top: topLeftCoords.y + (typeof offset === "number" ? offset : OFFSET) / 2,
          left: topLeftCoords.x + (typeof offset === "number" ? offset : OFFSET) / 2,
          textAlign: "center",
          selectable: false,
          fontSize: fontSize * scaleBy || 12,
          fill: color || "white",
          fontFamily: fontFamily || "Arial",
          data: placeholder.id,
          angle: placeholder.position?.direction === "vertical" ? -90 : 0,
        });
        text.textAlign = textAlignment || "center";
        const lineHeight = text.height || 0;
        let verticalOffset = 0;
        if (verticalAlignment.length === 0 || verticalAlignment === "top") {
          verticalOffset = 0;
        } else if (verticalAlignment === "center") {
          verticalOffset = height / 2 - lineHeight;
        } else if (verticalAlignment === "bottom") {
          verticalOffset = height - lineHeight;
        }

        const textWidth = text.width || 0;
        let horizontalOffset = 0;
        if (horizontalAlignment.length === 0 || horizontalAlignment === "left") {
          horizontalOffset = 0;
        } else if (horizontalAlignment === "center") {
          horizontalOffset = width / 2 - textWidth / 2;
        } else if (horizontalAlignment === "right") {
          horizontalOffset = textWidth;
        }
        text.top = placeholder.position.topLeft[1] * canvasHeight + verticalOffset;
        text.left = placeholder.position.topLeft[0] * canvasWidth + horizontalOffset;
        canvas.add(text);
      }
      canvasRef.current = canvas;
    });
  }, [canvasColor, canvasHeight, canvasWidth, initCanvas, placeholders, clickCallback, category, cover, scaleBy, showImages]);

  // eslint-disable-next-line react/no-array-index-key
  return (
    <div style={{ position: "relative", border: active ? "4px solid #ff5a5f" : "none", boxShadow: '3px 3px 13px 2px #ccc' }}>
      <div
        style={{
          backgroundColor: active ? "none" : "none", //hexToRgbA("#ff5a5f", 1)
          height: "100%",
          width: "100%",
          position: "absolute",
          // zIndex: 1000,
        }}
      ></div>
      <canvas id={`canvas_${name}_${uniqueID}`} />
      {exportable && (
        <Button
          onClick={() => {
            // console.log(canvasRef.current.toDataURL());
            exportable(canvasRef.current.toDataURL());
          }}
        >
          Export
        </Button>
      )}
    </div>
  );
};

export default Template;
