import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PRODUCT_TYPE_IDS,
  PRODUCT_TYPE_URL_SLUGS,
  productsByProductTypeId,
} from "../../data/products";
import PhotoPrintsPreBagContainer from "../../components/PhotoPrintsPreBag/PhotoPrintsPreBagContainer";
import PhotoMagazinePreBagContainer from "../../components/PhotoMagazinePreBag/PhotoMagazinePreBagContainer";
import PostcardEditorContainer from "../PostcardEditor/PostcardEditorContainer";
import AnnouncementCardEditorContainer from "../AnnouncementCardEditor/AnnouncementCardEditorContainer";
import GreetingCardEditorContainer from "../GreetingCardEditor/GreetingCardEditorContainer";
import CanvasEditorContainer from "../CanvasEditor/CanvasEditorContainer";
import PhotoTileEditorContainer from "../PhotoTileEditor/PhotoTileEditorContainer";
import FramedPrintsEditorContainer from "../FramedPrintsEditor/FramedPrintsEditorContainer";
import CollagePrintPrebagContainer from "../../components/CollagePrintPrebag/CollagePrintPreBagContainer";
import AnySizePrintPrebagContainer from "../../components/AnySizePrintPreBag/AnySizePrintPrebagContainer";
import PosterEditorContainer from "../PosterEditor/PosterEditorContainer";
import PassportPrintPrebagContainer from "../../components/PassportPrintPreBag/PassportPrintPrebagContainer";
import BorderPrintEditorContainer from "../../components/BorderPrintEditor/BorderPrintEditorContainer";

function getProductByProductTypeIdAndProductSlug(productTypeId, productSlug) {
  return productsByProductTypeId.get(+productTypeId).find(p => {
    return p.get("url_slug") === productSlug;
  });
}

class ProductLandingPageContainer extends Component {
  state = {};

  render() {
    const { productType, productSlug } = this.props.match.params;

    switch (productType) {
      case PRODUCT_TYPE_URL_SLUGS.POSTCARD:
        return (
          <PostcardEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.POSTCARD}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.POSTCARD, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.POSTERS:
        return (
          <PosterEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.POSTERS}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.POSTERS, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.ANNOUNCEMENT:
        return (
          <AnnouncementCardEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.ANNOUNCEMENT}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.ANNOUNCEMENT,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.GREETING_CARD:
        return <GreetingCardEditorContainer designSlug={productSlug} />;
      case PRODUCT_TYPE_URL_SLUGS.CANVAS:
        return (
          <CanvasEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.CANVAS}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.CANVAS, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.PHOTO_TILE:
        return (
          <PhotoTileEditorContainer
            productTypeId={+PRODUCT_TYPE_IDS.PHOTO_TILE}
            productId={
              +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.PHOTO_TILE, productSlug).get(
                "id"
              )
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
        case PRODUCT_TYPE_URL_SLUGS.FRAMED_PRINTS:
          return (
            <FramedPrintsEditorContainer
              productTypeId={+PRODUCT_TYPE_IDS.FRAMED_PRINTS}
              productId={
                +getProductByProductTypeIdAndProductSlug(+PRODUCT_TYPE_IDS.FRAMED_PRINTS, productSlug).get(
                  "id"
                )
              }
              productSlug={productSlug}
              productTypeSlug={productType}
            />
          );
      case PRODUCT_TYPE_URL_SLUGS.PHOTO_PRINT:

        console.log('productSlug:', productSlug);
        if(productSlug === "custom-size"){
          return (
            <AnySizePrintPrebagContainer
              productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
            />
          );
        }

        if(productSlug === "passport-photos"){
          return (
            <PassportPrintPrebagContainer
              productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
            />
          );
        }

        const product = getProductByProductTypeIdAndProductSlug(
          +PRODUCT_TYPE_IDS.PHOTO_PRINT,
          productSlug
        );
        const doesPrintSupportCollages = product.get("supports_collages");
        //console.log("doesPrintSupportCollages:", doesPrintSupportCollages);
        if (doesPrintSupportCollages) {
        console.log('will render CollagePrintPrebagContainer');
          return (
            <CollagePrintPrebagContainer
              productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
              productId={+product.get("id")}
              productSlug={productSlug}
              productTypeSlug={productType}
            />
          );
        }

        if(product.get("border_print")){
          return (
            <BorderPrintEditorContainer
              productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
              productId={+product.get("id")}
              productSlug={productSlug}
              productTypeSlug={productType}
            />
          );
        }

        console.log('will render PhotoPrintsPreBagContainer');
        return (
          <PhotoPrintsPreBagContainer
            productTypeId={+PRODUCT_TYPE_IDS.PHOTO_PRINT}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.PHOTO_PRINT,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.PHOTO_MAGAZINE:
        return (
          <PhotoMagazinePreBagContainer
            productTypeId={+PRODUCT_TYPE_IDS.PHOTO_MAGAZINE}
            productId={
              +getProductByProductTypeIdAndProductSlug(
                +PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
                productSlug
              ).get("id")
            }
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      case PRODUCT_TYPE_URL_SLUGS.COLLAGE_PRINTS: {
        const product = getProductByProductTypeIdAndProductSlug(
          +PRODUCT_TYPE_IDS.COLLAGE_PRINTS,
          productSlug
        );
        return (
          <CollagePrintPrebagContainer
            productTypeId={+PRODUCT_TYPE_IDS.COLLAGE_PRINTS}
            productId={+product.get("id")}
            productSlug={productSlug}
            productTypeSlug={productType}
          />
        );
      }
      default:
        return null;
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductLandingPageContainer);
