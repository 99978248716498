import { Button } from "antd";
import React, { useEffect, useState } from "react";
import Template from "./Template";
import templates from "../../constants/pageTemplatesSquare.json";

const TEMPLATE_CATEGORIES = ["text", "minimal", "collage"];

const TEMPLATES_ARRAY = templates;
const TemplateHomeContainer = () => {
  const [selectedCategory, setSelectedCategory] = useState(TEMPLATE_CATEGORIES[0]);
  const [templatesToShow, setTemplatesToShow] = useState([]);

  useEffect(() => {
    setTemplatesToShow([]);
    setTemplatesToShow(TEMPLATES_ARRAY.filter(template => template.category === selectedCategory));
  }, [selectedCategory]);

  const handleSelectedCategoryChange = category => {
    setSelectedCategory(category);
  };

  const handleTemplateSelect = template => {
    //selected template
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h1>Choose a Template</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "30px",
          margin: "20px 0",
        }}
      >
        {TEMPLATE_CATEGORIES.map(category => (
          <Button
            onClick={() => handleSelectedCategoryChange(category)}
            type="dashed"
            style={
              selectedCategory === category
                ? { borderColor: "#ff8282", color: "#ff8282", textTransform: "capitalize" }
                : { textTransform: "capitalize" }
            }
            key={category}
          >
            {category}
          </Button>
        ))}
      </div>
      {templatesToShow.length ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "250px",
            gap: "20px",
            overflow: "scroll",
            padding: "20px 30px",
            height: "80vh",
            border: "1px solid black",
          }}
        >
          {templatesToShow.map(template => (
            <div key={template.templateName} onClick={() => handleTemplateSelect(template)}>
              <div style={{ pointerEvents: "none" }}>
                <Template templateData={template} scaleBy={1} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ textAlign: "center", margin: "40px 0" }}>
          <h3>No template to show in current category</h3>
        </div>
      )}
    </div>
  );
};

export default TemplateHomeContainer;
