import React, { Component } from "react";
import PropTypes from "prop-types";
import gtmEvent from "../../utils/gtm";
import SweetAlert from "../SweetAlert/SweetAlert";
import Modal from "../Modal/Modal";
import Form from "../Form/Form";
import Button from "../Button/Button";
import Divider from "../Divider/Divider";
import ModalPage from "../Modal/ModalPage";
import PasswordField from "../PasswordField/PasswordField";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import { connect } from "react-redux";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { selectors as authSelectors } from "../../store/ducks/auth";
import './GuestCaptureOrAuthModal.scss';

const MODAL_PAGES = {
  GUEST_CAPTURE: "GUEST_CAPTURE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

const SCHEMAS = {
  GUEST_CAPTURE: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Email Address", autoComplete: "email" },
        phone: { type: "string", title: "Phone Number", autoComplete: "tel" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "guestCapture",
      email: {
        "ui:widget": "email",
        "ui:autocomplete": "email",
        "ui:placeholder": "Your email",
      },
      phone: {
        "ui:placeholder": "Your phone number",
        "ui:autocomplete": "tel",
        "ui:help": ("Add your mobile phone number so we can contact you quickly if there is a problem with your order."),
        "ui:options": {
          inputType: 'tel'
        }
      }
    },
  },
  SIGN_IN: {
    showErrorList: false,
    schema: {
      type: "object",
      properties: {
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7, autoComplete: "password-current" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signIn",
      email: {
        "ui:widget": "email",
        "ui:autocomplete": "username"
      },
      password: {
        "ui:widget": PasswordField,
        "ui:autocomplete": "password-current"
      },
    },
  },
  SIGN_UP_WITHOUT_ADDRESS: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["first_name", "email", "password"],
      properties: {
        first_name: { type: "string", title: "Name" },
        email: { type: "string", title: "Email" },
        password: { type: "string", title: "Password", minLength: 7 },
        mobile: { type: "string", title: "Mobile Phone Number" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "signUpNoAddress",
      "ui:order": [
        "first_name",
        "email",
        "password",
        "mobile",
      ],
      email: {
        "ui:widget": "email",
      },
      mobile: {
        "ui:help": "Add your mobile phone number so we can contact you quickly if there is a problem with your order.",
      },
      password: {
        "ui:widget": PasswordField,
        "ui:help": "(Must be 7 characters inc. at least one number.)",
      },
    },
  },
  REQUEST_PASSWORD_RESET: {
    showErrorList: false,
    schema: {
      type: "object",
      required: ["email"],
      properties: {
        email: { type: "string", title: "Email" },
      },
    },
    uiSchema: {
      "ui:rootFieldId": "forgotPassword",
      email: {
        "ui:widget": "email",
      },
    },
  }
};

class GuestCaptureOrAuthModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSignInOrUp: PropTypes.func,
    onSubmitGuestDetails: PropTypes.func,
    onClearGuestDetails: PropTypes.func,
    onActionAfterGuestDone: PropTypes.func,
    onActionAfterGuestAuth: PropTypes.func,
  };

  defaultState = {
    activePage: MODAL_PAGES.GUEST_CAPTURE,
    guestCaptureData: this.props.guestDetails.toJS() || {},
    alert: null,
    isLoading: false,
    edited: false,
    isSavingGuestDetails: false,
    isSigningIn: false,
    signInData: {},
    signInFormVisible: false,
    signUpData: {},
    signUpFormVisible: false,
    isSigningUp: false,
    isLoadingRequest: false,
    chosenGuestOrCustomer: this.props.guestDetails.size > 0 || false,
  };

  state = { ...this.defaultState };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen && !this.state.edited) {
      console.log("Setting to default state");
      this.setState(this.defaultState);
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      if(this.props.guestDetails.toJS() !== this.state.guestCaptureData){
        console.log("Setting to guest detauls", this.props.guestDetails.toJS());
        console.log("this.props.guestDetails.size", this.props.guestDetails.size)
        this.setState({
          guestCaptureData: this.props.guestDetails.toJS(),
          chosenGuestOrCustomer: this.props.guestDetails.size > 0
        });
      }
    }
  }

  handleGuestCaptureFormChange = form => {
    this.setState({
      guestCaptureData: form.formData,
      edited: true,
    });
  };

  handleSignInFormChange = form => {
    let data = form.formData
    // Horrible hack because browsers no longer seem to trigger onChange for password autofill
    // TODO: investigate a better way of doing this...
    // if (!form.formData.password && $('#signIn #pw-input').attr('data-ps-val')){
    //   data = {...data, password: $('#signIn #pw-input').attr('data-ps-val')}
    // }
    this.setState({
      signInData: data,
    });
  };

  handleGuestFormSubmit = async form => {
    const details = form.formData;
    //message.loading("Updating guest details...");
    this.setState({
      isSavingGuestDetails: true,
    }, async () => {
      try {
        //
        //await this.props.onActionAfterGuestDone();
        
        await this.props.onSubmitGuestDetails(details);

        //setTimeout(async () => {
          await this.props.onActionAfterGuestDone();
        //}, 200);
        await this.props.onClose();
        //message.success("Guest details saved");
      } catch (err) {
        //error
      } finally {
        this.setState({
          isSavingGuestDetails: false,
        });
      }
    }); 
  }

  handleSignInFormSubmit = async form => {
    const { email, password } = form.formData;
    this.setState({
      isLoadingRequest: true,
      isSigningIn: true,
    });
    try {
      const result = await this.props.onSignIn({ email, password });
      if (result && result.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't sign you in",
            text: result.payload.data.error,
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        await this.props.onActionAfterGuestAuth();
        this.props.onClose();
      }
    } catch (err) {
      console.warn("Error while signing in:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningIn: false,
      });
    }
  };

  handleSignUpFormSuccess = async () => {
    await this.props.onActionAfterGuestAuth()
    this.props.onClose()
  }

  handleSignUpFormSubmit = async form => {
    const signUpData = { ...form.formData };
    
    this.setState({
      isLoadingRequest: true,
      isSigningUp: true
    });

    try {
      console.log("signUpData", signUpData)
      const result = await this.props.onSignUp(signUpData);
      if (result.error) {
        const errors = result.payload.data.errors;
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't create your account",
            text: errors[Object.keys(errors)[0]][0],
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Account Created",
            text: "Your account has been created, and you have been signed in.",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        }, () => {
          gtmEvent({
            event: "webAppStage",
            additionalProps: {
              stage: 'Account Created'
            }
          })
        });
        //this.props.onClose();
        this.handleSignUpFormSuccess()
      }
    } catch (err) {
      let errorMessage = "There was a problem creating your account, please try again."
      if (err.payload){
        const errors = err.payload.data.errors;
        errorMessage = errors[Object.keys(errors)[0]][0];
      }
      this.setState({
        alert: {
          type: "error",
          title: "Couldn't create your account",
          text: errorMessage,
          showCancelButton: false,
        },
      });
    } finally {
      this.setState({
        isLoadingRequest: false,
        isSigningUp: false,
      });
    }
  };

  setActivePage = activePage => {
    this.setState({ activePage }, () => {
      gtmEvent({event: "webAppStage", additionalProps: {stage: `Guest or Sign In - ${activePage}`}})
    });  
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleClearGuestDetails = () => {
    this.props.onClearGuestDetails();
    this.setState({
      guestCaptureData: {},
    }, () => {
      //this.props.onSignUp()
    });
  }

  handleSignUpOrIn = () => {
    this.showSignUpForm()
    // console.log("On Sign Up")
    // this.props.onSignUp(this.props.onActionAfterGuestAuth)
  }

  showSignUpForm = () => {
    this.setState({
      signUpFormVisible: true,
      signInFormVisible: false,
      chosenGuestOrCustomer: true
    })
  }

  hideSignUpForm = () => {
    this.setState({
      signUpFormVisible: false,
      chosenGuestOrCustomer: true
    })
  }

  handleSignUpWithoutAddressFormChange = form => {
    const signUpData = { ...form.formData };
    this.setState({
      signUpData: signUpData,
    });
  }

  showSignInForm = () => {
    this.setState({
      signInFormVisible: true,
      signUpFormVisible: false,
      chosenGuestOrCustomer: true
    })
  }

  hideSignInForm = () => {
    this.setState({
      signInFormVisible: false,
      chosenGuestOrCustomer: true
    })
  }

  handleChooseGuestCheckout = () => {
    this.setState({
      chosenGuestOrCustomer: true
    })
  }

  render() {

    const { hasGuestDetails } = this.props;

    let headerTitle;
    let headerLeftAction = (
      <Button priority="tertiary" theme="muted" icon="clear" onClick={this.props.onClose} />
    );

    let headerRightAction;

    switch (this.state.activePage) {
      case MODAL_PAGES.GUEST_CAPTURE: {
        headerTitle = hasGuestDetails ? "Guest Details" : "Guest or Customer?";
        break;
      }
      case MODAL_PAGES.FORGOT_PASSWORD: {
        headerTitle = "Reset Password";
        headerLeftAction = (
          <Button
            priority="tertiary"
            theme="muted"
            label="Back"
            onClick={() => this.setActivePage(MODAL_PAGES.GUEST_CAPTURE)}
          />
        );
        break;
      }
      // no default
    }

    const guestCaptureFormSchema = { ...SCHEMAS.GUEST_CAPTURE };
    const showExtraInfo = !hasGuestDetails;
    const signUpOrInFormVisible = this.state.signUpFormVisible || this.state.signInFormVisible

    // console.log("isLoading", this.state.isLoading)
    // console.log("showExtraInfo", showExtraInfo)

    if (this.state.isSavingGuestDetails === true){
      return (
        <FullScreenLoader
        key="loader"
        message="Updating your guest details"
        isVisible={true}
        />
      )
    }

    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Modal
        key="guest-modal"
        padded
        title={headerTitle}
        leftAction={headerLeftAction}
        rightAction={headerRightAction}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        className="auth-modal"
        animated={true}
        paged
        activePage={this.state.activePage}
      >
        <ModalPage pageId={MODAL_PAGES.GUEST_CAPTURE} animated={true} depth={1}>
          <div className="restricted-width-modal-content">
            <div className="guest-checkout-capture">
              {(showExtraInfo && !this.state.isSavingGuestDetails && !signUpOrInFormVisible) && (
                <p>Don't have an account? No need to sign up - just use your email to continue as a guest. You can create an account later if you like.</p>
              )}
              {!this.state.chosenGuestOrCustomer && (
                <>
                  <Button
                    block
                    theme="dark-blue"
                    type="submit"
                    label="Guest Checkout"
                    dataGtmElement="choose-guest-checkout-button"
                    onClick={this.handleChooseGuestCheckout}
                    style={{marginBottom: '0px'}}
                  />
                  <br/>
                </>
              )}
              {(!signUpOrInFormVisible && this.state.chosenGuestOrCustomer) && (      
                <React.Fragment>        
                  <h2>Guest Checkout</h2>
                  {/* <p>Please add your email for receipts and order information. You can always create an account later.</p> */}
                  <p>Continue securely as a guest</p>
                  <Form
                    {...guestCaptureFormSchema}
                    formData={this.state.guestCaptureData}
                    onChange={this.handleGuestCaptureFormChange}
                    onSubmit={this.handleGuestFormSubmit}
                    autoComplete={"autocomplete"}
                  >
                    <Button
                      block
                      theme="dark-blue"
                      type="submit"
                      label={hasGuestDetails ? "Continue with Guest Details" : "Continue as Guest"}
                      loadingLabel="Continuing as a guest"
                      loading={this.state.isSavingGuestDetails}
                      dataGtmElement="guest-checkout-button"
                    />
                    {(hasGuestDetails && !this.state.isSavingGuestDetails) && (
                      <Button
                        block
                        theme="dark-blue"
                        priority="secondary"
                        label="Clear Guest Details"
                        dataGtmElement="guest-sign-in-button"
                        onClick={this.handleClearGuestDetails}
                      />
                    )}
                  </Form>
                </React.Fragment>
              )}
              {showExtraInfo && (
                <React.Fragment>
                  {!signUpOrInFormVisible && (
                    <React.Fragment>
                      <Divider label="or" />
                      <div style={{paddingTop:'30px'}}>
                        <Button
                          dataGtmElement="Sign Up Button"
                          block
                          theme="dark-blue"
                          type="submit"
                          label="Sign Up"
                          loadingLabel="Signing Up ..."
                          priority={this.state.isLoadingRequest ? "primary" : "secondary"}
                          loading={this.state.isLoadingRequest}
                          onClick={this.handleSignUpOrIn}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.signInFormVisible && (
                    <React.Fragment>
                      <h2>Sign In</h2>
                      {/* <p>Sign up or Sign In to a customer account to save money with PrePay credit, see your order history and create a dedicated address book.</p> */}
                      {/* <p>Checkout faster, see past orders and save shipping addresses</p> */}
                      <Form
                        {...SCHEMAS.SIGN_IN}
                        formData={this.state.signInData}
                        onChange={this.handleSignInFormChange}
                        onSubmit={this.handleSignInFormSubmit}
                        autoComplete={true}
                        disabled={this.state.signInDisabled}
                      >
                        <Button
                          dataGtmElement="Sign In Button"
                          block
                          theme="dark-blue"
                          type="submit"
                          label="Sign In"
                          loadingLabel="Signing In ..."
                          priority={this.state.isLoadingRequest ? "primary" : "primary"}
                          loading={this.state.isLoadingRequest}
                        />
                      </Form>
                      <div style={{textAlign: 'center'}}>
                        <div style={{marginTop:'10px'}} data-gtm-element="Forgot Password Button" onClick={() => this.setActivePage(MODAL_PAGES.FORGOT_PASSWORD)}>
                          <p style={{textDecoration: 'underline'}}>Forgotten password?</p>
                        </div>  
                      </div>  
                    </React.Fragment>
                  )}
                  {this.state.signInFormVisible ? (
                    <div style={{textAlign: 'center'}}>
                      <div style={{marginTop:'10px'}} data-gtm-element="Sign Up" onClick={this.handleSignUpOrIn}>
                        <p>No account? <span style={{textDecoration: 'underline'}} onClick={this.handleSignUpOrIn}>Sign up here</span></p>
                      </div>                
                    </div>
                  ) : (
                    !this.state.signUpFormVisible && (
                      <div style={{textAlign: 'center'}}>
                        <div style={{marginTop:'10px'}} data-gtm-element="Sign Up" onClick={this.showSignInForm}>
                          <p>Already have an account? <span style={{textDecoration: 'underline'}} onClick={this.showSignInForm}>Sign in here</span></p>
                        </div>                
                      </div>
                    )
                  )}
                  {this.state.signInFormVisible && (
                    <React.Fragment>
                      <Divider label="or" />
                      <br/>  
                      {(showExtraInfo && !this.state.isSavingGuestDetails && this.state.signInFormVisible) && (
                        <p>Don't have an account? There's no need to sign up, just enter your email address to continue with your purchase. You can create an account later if you change your mind!</p>
                      )}       
                      <Button
                        dataGtmElement="Continue as Guest Button"
                        block
                        theme="dark-blue"
                        type="submit"
                        label="Continue as Guest"
                        priority="secondary"
                        onClick={this.hideSignInForm}
                      />
                    </React.Fragment>
                  )}
                  {this.state.signUpFormVisible && (
                    <React.Fragment>
                      <h2>Sign Up</h2>
                      {/* <p>Sign up or Sign In to a customer account to save money with PrePay credit, see your order history and create a dedicated address book.</p> */}
                      {/* <p>Checkout faster, see past orders and save shipping addresses</p> */}
                      <Form
                        {...SCHEMAS.SIGN_UP_WITHOUT_ADDRESS}
                        formData={this.state.signUpData}
                        onChange={this.handleSignUpFormChange}
                        onSubmit={this.handleSignUpFormSubmit}
                        autoComplete={false}
                      >
                        <Button
                          dataGtmElement="Sign Up Button"
                          block
                          theme="dark-blue"
                          type="submit"
                          label="Sign Up"
                          loadingLabel="Creating Account..."
                          priority={this.state.isLoadingRequest ? "primary" : "primary"}
                          loading={this.state.isLoadingRequest && this.state.isSigningUp}
                        />
                      </Form>
                      <div style={{textAlign: 'center'}}>
                        <div style={{marginTop:'10px'}} data-gtm-element="Sign Up" onClick={this.showSignInForm}>
                          <p>Already have an account? <span style={{textDecoration: 'underline'}} onClick={this.showSignInForm}>Sign in here</span></p>
                        </div>                
                      </div>
                      <Divider label="or" />
                      <br/>      
                      <Button
                        dataGtmElement="Continue as Guest Button"
                        block
                        theme="dark-blue"
                        type="submit"
                        label="Continue as Guest"
                        priority="secondary"
                        onClick={this.hideSignUpForm}
                      />
                    </React.Fragment>
                  )}             
                </React.Fragment>
              )}
            </div>
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.FORGOT_PASSWORD} depth={2} animated={false}>
          <div className="restricted-width-modal-content">
            <Form
              {...SCHEMAS.REQUEST_PASSWORD_RESET}
              formData={this.state.requestPasswordResetData}
              onChange={this.handleRequestPasswordResetFormChange}
              onSubmit={this.handleRequestPasswordResetFormSubmit}
            >
              <Button
                dataGtmElement="Reset Password Button"
                block
                type="submit"
                theme="dark-blue"
                label="Reset Password"
                loadingLabel="Requesting Reset"
                loading={this.state.isLoadingRequest}
              />
            </Form>
          </div>
        </ModalPage>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  basketItems: basketSelectors.getItems(state),
  guestDetails: authSelectors.guestDetails(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
});

export default connect(mapStateToProps)(GuestCaptureOrAuthModal);
