import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import isEqual from "lodash/isEqual";

import getElementDimensions from "../../lib/get-element-dimensions";

import "./CardOpener.scss";

class CardOpener extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    ratio: PropTypes.number,
  };

  static defaultProps = {
    ratio: 1,
  };

  state = {
    width: 0,
    height: 0,
    scale: 1,
  };

  componentDidMount() {
    this.setDimensions();
  }

  componentDidUpdate(prevProps, prevState) {
    // TODO: Doesn't work when going back
    if (this.props.ratio !== prevProps.ratio || prevProps.isOpen !== this.props.isOpen) {
      console.log("Setting dimensions");
      setTimeout(this.setDimensions, 300);
    }
  }

  setDimensions = () => {
    try {
      const parentContainer = ReactDOM.findDOMNode(this).parentElement;
      const parentDimensions = getElementDimensions(parentContainer);
      const updatedDimensions = {
        width: parentDimensions.width,
        height: Math.ceil(parentDimensions.width / this.props.ratio),
      };

      if (updatedDimensions.height > parentDimensions.height) {
        updatedDimensions.height = parentDimensions.height;
        updatedDimensions.width = parentDimensions.height * this.props.ratio;
      }

      if (!isEqual({ width: this.state.width, height: this.state.height }, updatedDimensions)) {
        console.log("Updating card opener dimensions", updatedDimensions.height);
        this.setState(updatedDimensions);
        this.props.onUpdateDimensions(updatedDimensions);
      }
    } catch (err) {}
  };

  render() {
    const containerStyles = {
      width: this.state.width,
      height: this.state.height,
    };

    if (!containerStyles.width || !containerStyles.height) {
      return <div />;
    }

    const clonedProps = {
      containerWidth: this.state.width,
      containerHeight: this.state.height,
    };

    const containerClasses = classNames("card-opener-container", {
      "card-opener-container--is-open": this.props.isOpen,
    });
    const insideClasses = classNames("card-opener", {
      "card-opener--is-open": this.props.isOpen,
    });

    const dimensions = this.props.dimensions;
    let backBleedStyles = {}

    if (dimensions && (dimensions.bleed.left !== dimensions.bleed.right)){
      const bleedTop = dimensions.bleed.top * (100.0/dimensions.height);
      backBleedStyles = {top: `${bleedTop}%`, height: `${(100-(bleedTop*2))}%`}
    }

    return (
      <div className={containerClasses} style={containerStyles}>
        <div className={insideClasses}>
          <figure className="card-opener__front">
            {React.cloneElement(this.props.front, clonedProps)}
          </figure>
          <figure className="card-opener__back" style={backBleedStyles}></figure>
        </div>
        <div className="card-opener__inside">
          {React.cloneElement(this.props.inside, clonedProps)}
        </div>
      </div>
    );
  }
}

export default CardOpener;
