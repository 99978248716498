import React from "react";
import { connect } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import { photoTilesActions, photoTilesSelectors } from "../../../store/ducks/ui-photo-tiles";
import MainContent from "../../../components/MainContent/MainContent";
import Button from "../../../components/Button/Button";
import { getRecentlyUploaded } from "../photoTilesUtils";
import { pickImages } from "../../../lib/file-uploader";

function PhotoTilesFilesModal({
  showFilesModal,
  closeModal,
  onImagePicked = () => {},
  amount = 1,
}) {
  const anyRecentlyUploaded = getRecentlyUploaded()?.length > 0;
  const pickImageFromUploadcare = async source => {
    const result = await pickImages({ source, amount });
    onImagePicked(result);
  };

  return (
    <Modal isOpen={showFilesModal} onClose={closeModal}>
      <Modal
        key="uploadcare-selection-modal"
        isOpen={showFilesModal}
        onClose={closeModal}
        title={"Upload Photo"}
      >
        <MainContent scrollable={false} padded alignedTop>
          <div className="restricted-width-modal-content">
            {/*{this.state.customUploadDescription && <p>{this.state.customUploadDescription}</p>}*/}
            <Button
              block
              label="From Device"
              icon="phone"
              theme="dark-blue"
              onClick={() => pickImageFromUploadcare("file")}
              dataGtmElement="Photo Upload Source"
            />
            {anyRecentlyUploaded && (
              <Button
                block
                className="btn--recent"
                label="Recently Uploaded"
                icon="upload"
                onClick={() => pickImageFromUploadcare("favorites")}
                dataGtmElement="Photo Upload Source"
              />
            )}
            {/* <Button
              block
              icon="facebook"
              className="btn--facebook"
              label="Facebook"
              onClick={() => pickImageFromUploadcare("facebook")}
              dataGtmElement="Photo Upload Source"
            />
            <Button
              block
              icon="instagram"
              className="btn--instagram"
              label="Instagram"
              onClick={() => pickImageFromUploadcare("instagram")}
              dataGtmElement="Photo Upload Source"
            /> */}
          </div>
        </MainContent>
      </Modal>
    </Modal>
  );
}

const mapStateToProps = state => ({
  showFilesModal: photoTilesSelectors.getShowFilesModal(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(photoTilesActions.setShowFilesModal(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesFilesModal);
