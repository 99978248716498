import axios from "axios";
import sortBy from "lodash/sortBy";

import capitalizeWordsInString from "../capitalize-words-in-string";

export function addressLookupByPostcode(postcode) {
  // Use AA11AA to make free lookups which contain all 3 lines.
  return new Promise(function(resolve, reject) {
    return axios
      .get("https://pcls1.craftyclicks.co.uk/json/rapidaddress", {
        params: {
          key: process.env.REACT_APP_CRAFTYCLICKS_API_KEY,
          postcode,
          response: "data_formatted",
          lines: 3,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(result => {
        if (result.data.error_msg) {
          return reject(result.data);
        }
        //console.log(result.data);
        result.data.delivery_points = result.data.delivery_points.map(deliveryPoint => {
          const hasOrgName = deliveryPoint.organisation_name !== "";
          const hasNoLine1 = deliveryPoint.line_1 === "";

          let line_1 = deliveryPoint.line_1;
          let line_2 = deliveryPoint.line_2;
          let line_3 = deliveryPoint.line_3;
          let company_name = deliveryPoint.organisation_name;
          
          if (hasOrgName && hasNoLine1){
            console.log("Has no line 1 but does have company name")
            line_1 = company_name;
            company_name = "";
          }

          // console.log("Delivery point L2", deliveryPoint);
          // console.log("Delivery point L2", deliveryPoint.line_2);
          // console.log("Delivery point L3", deliveryPoint.line_3);
          
          // TODO: Add company address and or line 3
          // if (hasOrgName){
          //   if (hasNoLine2){
          //     line_1 = deliveryPoint.organisation_name;
          //     line_2 = deliveryPoint.line_1;
          //   } else{
          //     line_1 = `${deliveryPoint.organisation_name}, ${deliveryPoint.line_1}`;
          //   }
          // }
          
          return {
            ...deliveryPoint,
            company_name: capitalizeWordsInString(company_name),
            line_1: capitalizeWordsInString(line_1),
            line_2: capitalizeWordsInString(line_2),
            line_3: capitalizeWordsInString(line_3)
          }
        });

        result.data.delivery_points = sortBy(result.data.delivery_points, item => item.line_1);
        result.data.postal_county = capitalizeWordsInString(result.data.postal_county);
        result.data.town = capitalizeWordsInString(result.data.town);

        resolve(result.data);
      });
  });
}
