import { fromJS, Map } from "immutable";

const PRODUCTS_API_DATA = fromJS(require("../api-data/products"));
const PRODUCT_TYPE_AND_PRODUCT_IDS = require("../api-data/product-type-and-product-ids");

export const PACK_STEPS = [8, 16, 24, 32, 40, 80];
export const GC_PACK_STEPS = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export const productsByProductTypeId = PRODUCTS_API_DATA.flatMap(productType =>
  productType.get("products")
).groupBy(p => p.get("product_type_id"));

export const productsByProductId = new Map(
  PRODUCTS_API_DATA.flatMap(productType => productType.get("products")).map(v => [v.get("id"), v])
);

export const productTypesByProductTypeId = new Map(
  PRODUCTS_API_DATA.map(v => [v.get("id"), v])
);

// values here must match the product type names!
export const productTypeAppKeys = {
  POSTCARD: "Postcards",
  ANNOUNCEMENT: "Announcement Cards",
  GREETING_CARD: "Greeting Cards",
  INVITATION: "Invitations",
  CANVAS: "Canvas",
  PHOTO_PRINT: "Photo Prints",
  PHOTO_MAGAZINE: "Photo Books",
  COLLAGE_PRINTS: "Collage Prints",
  PHOTO_TILE: "Photo Tiles",
  FRAMED_PRINTS: "Framed Prints",
  POSTERS: "Posters",
};

export const PRODUCT_TYPE_URL_SLUGS = {
  POSTCARD: "postcards",
  ANNOUNCEMENT: "announcement-cards",
  GREETING_CARD: "greeting-cards",
  CANVAS: "canvas-prints",
  PHOTO_PRINT: "prints",
  PHOTO_MAGAZINE: "photo-books",
  COLLAGE_PRINTS: "collages",
  PHOTO_TILE: "photo-tiles",
  FRAMED_PRINTS: "framed-prints",
  POSTERS: "posters",
};

export const PRODUCT_TYPE_IDS = Object.keys(productTypeAppKeys).reduce((map, key) => {
  const appKey = productTypeAppKeys[key];
  map[key] = PRODUCT_TYPE_AND_PRODUCT_IDS.productTypes[appKey];
  return map;
}, {});

export const PRODUCT_TYPE_IDS_BY_SLUG = {
  [PRODUCT_TYPE_URL_SLUGS.POSTCARD]: PRODUCT_TYPE_IDS.POSTCARD,
  [PRODUCT_TYPE_URL_SLUGS.ANNOUNCEMENT]: PRODUCT_TYPE_IDS.ANNOUNCEMENT,
  [PRODUCT_TYPE_URL_SLUGS.GREETING_CARD]: PRODUCT_TYPE_IDS.GREETING_CARD,
  [PRODUCT_TYPE_URL_SLUGS.INVITATION]: PRODUCT_TYPE_IDS.INVITATION,
  [PRODUCT_TYPE_URL_SLUGS.CANVAS]: PRODUCT_TYPE_IDS.CANVAS,
  [PRODUCT_TYPE_URL_SLUGS.PHOTO_PRINT]: PRODUCT_TYPE_IDS.PHOTO_PRINT,
  [PRODUCT_TYPE_URL_SLUGS.PHOTO_MAGAZINE]: PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
  [PRODUCT_TYPE_URL_SLUGS.COLLAGE_PRINTS]: PRODUCT_TYPE_IDS.COLLAGE_PRINTS,
  [PRODUCT_TYPE_URL_SLUGS.PHOTO_TILE]: PRODUCT_TYPE_IDS.PHOTO_TILE,
  [PRODUCT_TYPE_URL_SLUGS.FRAMED_PRINTS]: PRODUCT_TYPE_IDS.FRAMED_PRINTS,
  [PRODUCT_TYPE_URL_SLUGS.POSTERS]: PRODUCT_TYPE_IDS.POSTERS,
};

export const singleShippingAddressProductTypes = [
  PRODUCT_TYPE_IDS.CANVAS,
  PRODUCT_TYPE_IDS.PHOTO_PRINT,
  PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
  PRODUCT_TYPE_IDS.PHOTO_TILE
]

export function getProductforProductId(productId) {
  return Object.keys(PRODUCT_TYPE_AND_PRODUCT_IDS.products).find(
    key => PRODUCT_TYPE_AND_PRODUCT_IDS.products[key] === productId
  );
}

export function getProductTypeforProductTypeId(productTypeId) {
  return productTypesByProductTypeId.get(productTypeId);
}

export function isPortraitProduct(productId) {
  return productsByProductId.getIn([productId, "isPortrait"]);
}

export function isTextPrintProduct(productId) {
  return ["6X4.5_TEXT_PRINTS", "RETRO_PRINTS", "2×2.5_PRINTS", "RETRO_PRINTS_C"].includes(productsByProductId.getIn([productId, "appKey"]));
}

export function supportsBackground(productId) {
  return ["RETRO_PRINTS_C"].includes(productsByProductId.getIn([productId, "appKey"]));
}

export function isRetroPrint(productId) {
  return ["6X4.5_TEXT_PRINTS", "RETRO_PRINTS", "RETRO_PRINTS_C"].includes(productsByProductId.getIn([productId, "appKey"]));
}

export function isDefaultFinishMatt(productId) {
  return [
    "6X4.5_TEXT_PRINTS",
    "RETRO_PRINTS",
    "RETRO_PRINTS_C",
    "10×8_PRINTS",
    "12×8_PRINTS",
    "A4_PRINTS",
    "16×12_PRINTS",
    "20×16_PRINTS",
    "36×12_PRINTS",
    "20×10_PRINTS",
    "8×8_PRINTS",
    "10×10_PRINTS",
    "12×12_PRINTS",
    "16×16_PRINTS",
    "18×18_PRINTS",
    "20×20_PRINTS",
    "12×12_COLLAGE",
    "12×12_CUSTOM_PRINTS",
    "15×10_PRINTS",
    "18×12_PRINTS",
    "14×10_PRINTS",
    "12×9_PRINTS",
    "10×9_PRINTS",
  ].includes(productsByProductId.getIn([productId, "appKey"]));
}

export function isCaptionPrintProduct(productId) {
  return productsByProductId.getIn([productId, "caption_print"]);
}

export function hasRealBorders(productId) {
  const productTypeId = productsByProductId.getIn([productId, "product_type_id"]);
  //console.log("productTypeId", productTypeId);
  //console.log("PRODUCT_TYPE_IDS.PHOTO_PRINT", PRODUCT_TYPE_IDS.PHOTO_PRINT);
  if (productTypeId === PRODUCT_TYPE_IDS.PHOTO_PRINT){
    const appKey = productsByProductId.getIn([productId, "appKey"]);
    switch (appKey) {
      case "2×2_PRINTS":
      case "3×3_PRINTS":
      case "2×3_PRINTS":
      case "2×2.5_PRINTS": {
        return true;
      }
      default:
        return false;
    }
  } else if (productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE){
    return false;
  } else{
    return true;
  }
  
}

export function hasMinimumQuantityForProduct(productId) {
  return ["2×2_PRINTS", "3×3_PRINTS", "2×3_PRINTS", "2×2.5_PRINTS"].includes(productsByProductId.getIn([productId, "appKey"]));
}

export function minimumQuantityForProduct(productId) {
  const appKey = productsByProductId.getIn([productId, "appKey"]);
  switch (appKey) {
    case "2×2_PRINTS":
    case "3×3_PRINTS":
    case "2×3_PRINTS":
    case "2×2.5_PRINTS": {
      return 20;
    }
    default:
      return 0;
  }
}

export function containsMultipleShipments(productIds = []) { 
  if (!Array.isArray(productIds)) {
    return false
  }
  const anyMiniPrints = productIds.some(productId => {
    return ["2×2_PRINTS", "3×3_PRINTS", "2×3_PRINTS", "2×2.5_PRINTS"].includes(productsByProductId.getIn([productId, "appKey"]));
  })
  const anyNonMiniPrints = productIds.some(productId => {
    const productTypeId = productsByProductId.getIn([productId, "product_type_id"]);
    const isPrint = productTypeId === PRODUCT_TYPE_IDS.PHOTO_PRINT
    return isPrint && !["2×2_PRINTS", "3×3_PRINTS", "2×3_PRINTS", "2×2.5_PRINTS"].includes(productsByProductId.getIn([productId, "appKey"]));
  })
  return anyNonMiniPrints && anyMiniPrints
}

export function getNextIncreasedQuantityForProductType(productTypeId, quantity) {
  //console.log(productTypeId, quantity);

  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return Math.min(6, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.PHOTO_PRINT: {
      return Math.min(100, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE: {
      return Math.min(100, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return Math.min(10, quantity + 1);
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
    case PRODUCT_TYPE_IDS.INVITATION: {
      if (quantity === PACK_STEPS[PACK_STEPS.length-1]) {
        return PACK_STEPS[PACK_STEPS.length-1];
      }
      return PACK_STEPS[PACK_STEPS.indexOf(quantity) + 1];
    }
    // no default
  }
}

export function getNextIncreasedPackQuantityForProductType(productTypeId, quantity) {
  const PACK_STEPS = [8,16,24,32,40,48,56,64,72,80,88,96,104,112,120];
  return PACK_STEPS[PACK_STEPS.indexOf(quantity) + 1];
}

export function getNextDecreasedQuantityForProductType(productTypeId, quantity) {
  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.PHOTO_PRINT: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE: {
      return Math.max(1, quantity - 1);
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
    case PRODUCT_TYPE_IDS.INVITATION: {
      if(quantity === PACK_STEPS[0]){
        return PACK_STEPS[0];
      }
      return PACK_STEPS[PACK_STEPS.indexOf(quantity) - 1];
    }
    // no default
  }
}

export function getNextDecreasedPackQuantityForProductType(productTypeId, quantity) {
  const PACK_STEPS = [8,16,24,32,40,48,56,64,72,80,88,96,104,112,120];
  return PACK_STEPS[PACK_STEPS.indexOf(quantity) - 1];
}

export function getShippingCountriesForProductType(productTypeId, productId) {
  if (productId){
    if (isRetroPrint(productId)){
      return ["United Kingdom"]
    }
  }
  

  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.PHOTO_PRINT: {
      return ["United Kingdom", "United States", "Canada"];
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return ["United Kingdom"];
    }
    case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE: {
      return ["United Kingdom"];
    }
    default:
      return [];
    // no default
  }
}

export function productTypeSlugFromProductType(productTypeId) {
  switch (productTypeId) {
    case PRODUCT_TYPE_IDS.PHOTO_PRINT: {
      return "prints"; //collage and custom?
    }
    case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE: {
      return "photo-books";
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return "canvas";
    }
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return "greeting-cards";
    }
    case PRODUCT_TYPE_IDS.POSTCARDS: {
      return "postcards";
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT: {
      return "announcements"
    }
    case PRODUCT_TYPE_IDS.INVITATION: {
      return "invitations"
    }
    default:
      return [];
    // no default
  }
}

export const APP_KEYS_EXCLUDED_FROM_ANY_SIZE = [
  'RETRO_PRINTS',
  '2×2_PRINTS',
  '3×3_PRINTS',
  '2×3_PRINTS',
  '2×2.5_PRINTS',
  'PHOTO_STRIPS',
  '4×4_COLLAGE'// '4×4_PRINTS',
];

export function sharedShippingAddress(productTypeId) {
  if (singleShippingAddressProductTypes.includes(productTypeId)) {
    return true;
  } else {
    return false;
  }
}

export function requiresMobile(productTypeId) {
  return PRODUCT_TYPE_IDS.PHOTO_MAGAZINE === productTypeId
}

export function orderSummaryFormat(productTypeId) {
  switch (parseInt(productTypeId)) {
    case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE: {
      return "Photo Book"
    }
    case PRODUCT_TYPE_IDS.PHOTO_PRINT: {
      return "Photo Prints"
    }
    case PRODUCT_TYPE_IDS.CANVAS: {
      return "Canvas"
    }
    case PRODUCT_TYPE_IDS.ANNOUNCEMENT: {
      return "Announcements"
    }
    case PRODUCT_TYPE_IDS.INVITATION: {
      return "Invitations"
    }
    case PRODUCT_TYPE_IDS.POSTCARD: {
      return "Postcards"
    }
    case PRODUCT_TYPE_IDS.GREETING_CARD: {
      return "Greeting Cards"
    }
    default:
      return getProductTypeforProductTypeId(parseInt(productTypeId)).get('name')
    // no default
  }
}


export function relatedProductsForItem(productId) {
  const product = productsByProductId.find((product) => product.get('id') === productId)
  const productAppKey = product.get('appKey');

  let relatedProductsForProductAppKey = []
  switch (productAppKey) {
    case "2×2_PRINTS": {
      relatedProductsForProductAppKey = ["2×3_PRINTS", "3×3_PRINTS", "2×2.5_PRINTS"];
      break;
    }
    case "6×4_PRINTS": {
      relatedProductsForProductAppKey = ["7×5_PRINTS", "8×6_PRINTS", "10×8_PRINTS"];
      break;
    }
    case "7×5_PRINTS": {
      relatedProductsForProductAppKey = ["8×6_PRINTS", "10×8_PRINTS", "12×8_PRINTS"];
      break;
    }
    case "8×6_PRINTS": {
      relatedProductsForProductAppKey = ["10×8_PRINTS", "12×8_PRINTS", "10×10_PRINTS"];
      break;
    }
    case "10×8_PRINTS": {
      relatedProductsForProductAppKey = ["12×8_PRINTS", "10×10_PRINTS", "8×8_PRINTS"];
      break;
    }
    case "12×8_PRINTS": {
      relatedProductsForProductAppKey = ["6×4_PRINTS", "7×5_PRINTS", "8×6_PRINTS"];
      break;
    }
    case "A4_PRINTS": {
      relatedProductsForProductAppKey = ["8×6_PRINTS", "10×8_PRINTS", "12×8_PRINTS"];
      break;
    }
    case "4×4_PRINTS": {
      relatedProductsForProductAppKey = ["3×3_PRINTS", "5×5_PRINTS", "6×6_PRINTS"];
      break;
    }
    case "5×5_PRINTS": {
      relatedProductsForProductAppKey = ["3×3_PRINTS", "4×4_PRINTS", "6×6_PRINTS"];
      break;
    }
    case "6×6_PRINTS": {
      relatedProductsForProductAppKey = ["4×4_PRINTS", "8×8_PRINTS", "10×10_PRINTS"];
      break;
    }
    case "8×8_PRINTS": {
      relatedProductsForProductAppKey = ["10×10_PRINTS", "7×5_PRINTS", "10×8_PRINTS"];
      break;
    }
    case "10×10_PRINTS": {
      relatedProductsForProductAppKey = ["10×8_PRINTS", "12×8_PRINTS", "4×4_PRINTS"];
      break;
    }
    case "2×3_PRINTS": {
      relatedProductsForProductAppKey = ["2×2_PRINTS", "3×3_PRINTS", "2×2.5_PRINTS"];
      break;
    }
    case "3×3_PRINTS": {
      relatedProductsForProductAppKey = ["2×2_PRINTS", "4×4_PRINTS", "6×6_PRINTS"];
      break;
    }
    case "2×2.5_PRINTS": {
      relatedProductsForProductAppKey = ["2×3_PRINTS", "2×2_PRINTS", "3×3_PRINTS"];
      break;
    }
    case "6×4.5_PRINTS": {
      relatedProductsForProductAppKey = ["RETRO_PRINTS", "2×2.5_PRINTS", "6×4_PRINTS"];
      break;
    }
    default:
      relatedProductsForProductAppKey = [];
    // no default
  }
  return relatedProductsForProductAppKey.map((appKey) => {
    const relatedProduct = productsByProductId.find((product) => product.get('appKey') === appKey)
    return relatedProduct;
  })
}
