import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";

import TruncatedText from "../Formatters/TruncatedText";
import Block from "../Block/Block";
import Grid from "../Grid/Grid";
import Button from "../Button/Button";

import "./AddressBook.scss";

const AddressBook = ({ entries, onClickEntry, onClickAddAddress, onClickAddMainAddress, loadingAddresses }) => {
  //const mainAddress = entries.find((entry) => entry.get('main') === true)
  //console.log(mainAddress.toJS());
  const sortedEntriesByFirstCharacter = entries
    .filter(entry => !entry.get("main"))
    .sortBy(entry =>
      entry
        .get("first_name")
        .charAt(0)
        .toUpperCase()
    )
    .groupBy(entry =>
      entry
        .get("first_name")
        .charAt(0)
        .toUpperCase()
    )
    .map(entries => entries.sortBy(entry => entry.get("first_name")))
    .toJS();

  const entrySections = Object.keys(sortedEntriesByFirstCharacter).map(character => {
    const entriesForCharacter = sortedEntriesByFirstCharacter[character];
    return [
      <div key="ab-char" className="address-book__character-container">
        <span className="address-book__character">{character}</span>
      </div>,
      <div key="ab-entries">
        {entriesForCharacter.map(entry => (
          <Block key={entry.id} hasRightArrow hoverEffect onClick={() => onClickEntry(fromJS(entry))}>
            <Grid.Row>
              <Grid.Column>
                <TruncatedText width="35vw" maxWidth="300px">
                  {entry.first_name} {entry.last_name}
                </TruncatedText>
              </Grid.Column>
              <Grid.Column>
                <TruncatedText width="40vw" maxWidth="370px">
                  {entry.line1} {entry.line2} {entry.line3} {entry.city} {entry.postcode}
                </TruncatedText>
              </Grid.Column>
            </Grid.Row>
          </Block>
        ))}
      </div>,
    ];
  });

  const myAddressEntry = entries.find(entry => entry.get("main"));
  const ownAddress = myAddressEntry && myAddressEntry.toJS();

  if(entries && entries.size === 0){
    return (
      <div className="restricted-width-modal-content" style={{margin: '0 auto'}}>
        <div className="text-center">
          <p>You currently have no Addresses in your PostSnap Address Book</p>
          {onClickAddAddress && (
            <>
            <Button
              block
              theme="dark-blue"
              priority="secondary"
              label="Add Your Own Address"
              onClick={onClickAddMainAddress}
            />
            <Button
              block
              theme="dark-blue"
              priority="secondary"
              label="Add A Recipient Address"
              onClick={onClickAddAddress}
            />
            </>
          )}
        </div>
      </div>
    )
  }

  console.log("loadingAddresses", loadingAddresses)

  return (
    <div>
      {ownAddress && (
        <div className="my-address-entry">
          <h2 className="address-book__subheading">My Address</h2>
          <Block key={ownAddress.id} hasRightArrow hoverEffect onClick={() => onClickEntry(fromJS(ownAddress))}>
            <Grid.Row>
              <Grid.Column>
                <TruncatedText width="35vw" maxWidth="300px">
                  {ownAddress.first_name} {ownAddress.last_name}
                </TruncatedText>
              </Grid.Column>
              <Grid.Column>
                <TruncatedText width="40vw" maxWidth="370px">
                  {ownAddress.line1} {ownAddress.line2} {ownAddress.line3} {ownAddress.city} {ownAddress.postcode}
                </TruncatedText>
              </Grid.Column>
            </Grid.Row>
          </Block>
        </div>
      )}
      <div className="address-book">
        {ownAddress && (
          <h2 className="address-book__subheading">Recipient Addresses</h2>
        )}
        {entrySections.length > 0 ? (
          entrySections
        ) : (
          loadingAddresses ? (
            <div className="restricted-width-modal-content" style={{margin: '0 auto'}}>
              <p>Loading addresses...</p>
            </div>
          ) : (
            <div className="restricted-width-modal-content" style={{margin: '0 auto'}}>
              <div className="text-center">
                {/* <p>You currently have no Addresses in your PostSnap Address Book</p> */}
                {onClickAddAddress && (
                  <Button
                  theme="dark-blue"
                  priority="secondary"
                  label="Add A Recipient Address"
                  onClick={onClickAddAddress}
                  />
                )}
              </div>
          </div>
          )
        )}
      </div>
    </div>
    
  );
};

AddressBook.propTypes = {
  // entries: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number,
  //   first_name: PropTypes.string,
  //   last_name: PropTypes.string,
  //   line1: PropTypes.string,
  //   line2: PropTypes.string,
  // })),
  entries: PropTypes.object,
  onClickEntry: PropTypes.func,
  onClickAddAddress: PropTypes.func,
  onClickAddMainAddress: PropTypes.func,
};

AddressBook.defaultProps = {
  onClickEntry: () => {},
};

export default AddressBook;
