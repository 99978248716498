import React, { Component } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import debounce from "lodash/debounce";
import cryptojs from 'crypto-js'
import ReactPixel from "react-facebook-pixel";
import queryString from "query-string";
import { PRODUCT_TYPE_IDS, productsByProductId, getShippingCountriesForProductType } from "../../data/products";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as basketActions } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as authActions, selectors as authSelectors } from "../../store/ducks/auth";
import { routeCreators, routeDefinitions } from "../../lib/routes";
import canCheckout from "../../lib/checkout"
import getUnrenderedItems from "../../lib/get-unrendered-items";
import gtmEvent from "../../utils/gtm";
import Basket from "./Basket";
import Modal from "../../components/Modal/Modal";
import EditorContainer from "../../components/Editor/EditorContainer";
import Button from "../../components/Button/Button";
import MainContent from "../../components/MainContent/MainContent";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import WithWipEditorItemRestore from "../../hoc-components/WithWipEditorItemRestore";
import EditorCropperModal from "../../components/Editor/EditorCropperModal";
import * as LAYER_TYPES from "../../constants/layer-types";
import { fromJS } from "immutable";
import PhotoBookSpreadPreview from "../../components/PhotoMagazineEditor/PhotoBookSpreadPreview";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import ShopButtonContainer from "../../components/ShopButton/ShopButtonContainer";
import Header from "../../components/Header/Header";
import EditorAddressInputModal from "../../components/Editor/EditorAddressInputModal";
import Footer from "../../components/Footer/Footer";
import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
import MODELS from "../../constants/models";
import TEMPLATES from "../../constants/templates"
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";
//import TagManager from 'react-gtm-module'
import { Modal as AntModal, message } from "antd";


// eslint-disable-next-line
function getAllAutoApprovedItems(items) {
  return items.filter(
    item =>
      ![
        PRODUCT_TYPE_IDS.CANVAS,
        PRODUCT_TYPE_IDS.PHOTO_PRINT,
        PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
        PRODUCT_TYPE_IDS.GREETING_CARD,
        PRODUCT_TYPE_IDS.POSTCARD,
        PRODUCT_TYPE_IDS.ANNOUNCEMENT,
      ].includes(item.get("productTypeId"))
  );
}

// eslint-disable-next-line
function createAndAppendScriptWithSourceCode(id, sourceCode) {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.id = id;
  s.innerHTML = sourceCode;
  document.body.appendChild(s);
  return id;
}
// eslint-disable-next-line
function createAndAppendScriptWithSrc(id, src) {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.id = id;
  s.src = src;
  document.body.appendChild(s);
  return id;
}

function removeElementFromBodyWithId(id) {
  const el = document.querySelector(`#${id}`);
  if (el) {
    document.body.removeChild(el);
  }
}

class BasketContainer extends Component {
  static propTypes = {
    items: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    currency: PropTypes.string,
    isSignedIn: PropTypes.bool,
    updateItem: PropTypes.func,
    updateItemWithNoRender: PropTypes.func,
    deleteItem: PropTypes.func,
    createOrderFromItems: PropTypes.func,
    createGuestOrderFromItems: PropTypes.func,
    createEmailOnlyGuestOrderFromItems: PropTypes.func,
    chargeStripePayment: PropTypes.func,
    confirmPayPalPayment: PropTypes.func,
  };

  static defaultProps = {};

  state = {
    itemToEdit: null,
    itemToEditAddress: null,
    isEditorModalVisible: false,
    isCropperModalVisible: false,
    draftRotationState: null,
    orderData: null,
    isCreatingOrder: false,
    isCreatingGuestOrder: false,
    isPayingForOrder: false,
    isPayingForOrderWithSavedCard: false,
    isOrderCompletionVisible: false,
    canMakeStripePayment: false,
    paymentRequest: null,
    alert: null,
    isCanvasApprovalModalVisible: false,
    renderItemLoading: false,
    itemToEnableEditModeFor: null,
    orderIsFree: false,
    stripePaymentDisabled: false,
    guestConversionPassword: null,
    isConvertingAccount: false,
    isPreviewModalVisible: false,
    previewModalActiveForItem: null,
    paymentIntentClientSecret: null,
    hasRemovedPromo: false,
    appliedAutomaticPromo: false,
  };

  constructor(props) {
    super(props);

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);

    let total = Math.round(props.orderSummary.get("total") * 100) || 0.0

    //console.log("Order summary", props.orderSummary.toJS())

    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: props.currency.toLowerCase(),
      total: {
        label: "Order total",
        amount: total, //Math.round(props.orderSummary.get("total") * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // paymentRequest.on("token", async ({ complete, token, ...data }) => {
    //   //console.log("Received Stripe token: ", token);
    //   //console.log("Received customer information: ", data);
    //   try {
    //     await complete("success");
    //     this.handleStripePaymentRequestSuccess(token, data);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // });

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      console.log("Payment Method", ev)

      this.setState({
        isPayingForOrder: true,
      });
      const email = this.props.hasGuestDetails ? this.props.guestDetails.get('email') : this.state.orderData.customer_email // or signed In email/customer //ev.payerEmail; // checkoutDetails?
      const total = this.state.orderData.total_pennies
      const clientSecretResponse = await this.props.createPaymentIntent({
        amount: total,
        currency: props.currency.toLowerCase(),
        email,
        reference: this.state.orderData.reference
      });

      const paymentIntentClientSecret = clientSecretResponse.payload.data.data.client_secret
      
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        message.warning(confirmError.message)
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        console.log("paymentIntent.status", paymentIntent.status)
        if (paymentIntent.status === "requires_action" || paymentIntent.status === "requires_source_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret);
          if (error) {
            console.log("Error", error)
            message.warning(error.message)
            this.handleStripePaymentRequestFailed(error.message)
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
            this.handleStripePaymentRequestComplete()
          }
        } else {
          // The payment has succeeded.
          this.handleStripePaymentRequestComplete()
        }
      }
    });

    paymentRequest.canMakePayment().then(result => {
      if (this.state.orderIsFree || this.props.orderSummary.get("total") === 0.0){
        console.log("Can't make card payment as order total is 0.00")
        this.setState({
          stripePaymentDisabled: true
        });
      }
      //console.log("Payment request", result)
      this.setState({
        canMakeStripePayment: Boolean(result),
        paymentRequest,
      });
    });

    const allAutoApprovedItems = getAllAutoApprovedItems(this.props.unapprovedItems);
    this.props.approveItems(allAutoApprovedItems.map(i => i.get("id")).toJS());

    // this.unloadListener = function (event) {
    //   if (this.props.items.size > 0) {
    //     event.returnValue = "Are you sure you want to leave your bag?";
    //   }
    // };
    // window.addEventListener("beforeunload", this.unloadListener)
  }

  async componentDidUpdate(prevProps, prevState) {

    const anyTemplateItems = this.props.items.some((item) => item.get("isTemplate"));
    if(anyTemplateItems){
      const templatePromos = this.props.items.filter((item) => item.get("isTemplate")).map((item) => {
        const templateConfig = TEMPLATES[item.get('templateId')];
        return templateConfig.promoCode
      });
      const existingPromo = this.props.orderSummary.getIn(["promotionInfo", "data", "code"]);

      templatePromos.forEach((promo) => {
        if (existingPromo !== promo){
          this.props.applyPromoCode(promo)
        } else{
          //console.log("Already has promo", existingPromo);
        }
      });
      
    }

    const urlPromo = queryString.parse(window.location.search).discount
    const justRemovedPromo = prevState.hasRemovedPromo === false && this.state.hasRemovedPromo === true
    const hasRemovedPromo = this.state.hasRemovedPromo === false
    const hasntAppliedAutomaticPromo = this.state.appliedAutomaticPromo === false
    if(hasntAppliedAutomaticPromo && urlPromo && hasRemovedPromo && !justRemovedPromo){
      this.setState({
        appliedAutomaticPromo : true
      }, () => {
        this.props.applyPromoCode(urlPromo.toUpperCase())
      })
    }

    this.checkForUnrenderedItems();

    const nonCanvasItems = getAllAutoApprovedItems(this.props.unapprovedItems);
    this.props.approveItems(nonCanvasItems.map(i => i.get("id")).toJS());

    if (this.state.paymentRequest) {
      if (
        prevProps.currency !== this.props.currency ||
        prevProps.orderSummary.get("total") !== this.props.orderSummary.get("total")
      ) {
        console.log("Updating payment request");
        //console.log(`Total from ${prevProps.orderSummary.get("total")} to ${this.props.orderSummary.get("total")}`);
        try{
          this.state.paymentRequest.update({
            currency: this.props.currency.toLowerCase(),
            total: {
              label: "Order total",
              amount: Math.round(this.props.orderSummary.get("total") * 100),
            },
          });
        } catch (err) {
          console.log("couldn't update payment request", err)
        }
        
        if (this.props.orderSummary.get("total") === 0.0){
          this.setState({
            orderIsFree: true,
            stripePaymentDisabled: true
          }, () => {
            console.log("Can't make card payment as order total is 0.00");
          });
        } else{
          this.setState({
            orderIsFree: false,
            stripePaymentDisabled: false
          });
        }
      } else {
        //console.log("Not updating payment request");
      }
    }

    if (this.state.isOrderCompletionVisible) {
      this.loadConversionScripts();
    }
  }

  componentDidMount() {
    const basketProducts = this.props.items.map(item => {
      const productData = {
        name: item.get('description'),
        qty: item.get('quantity'),
        productId: item.get('productId'),
        productAppKey: productsByProductId.getIn([item.get('productId'), "app_key"]),
        addedAt: item.get('addedAt')
      }
      return productData;
    }).sortBy((i) => i.addedAt).toJS();

    let lastProduct = null;
    if(basketProducts.length > 0) {
      lastProduct = basketProducts.slice(-1)[0].productAppKey;
      // console.log("First product", basketProducts[0].name);
      // console.log("Last product", basketProducts.slice(-1)[0].name);
    }
    gtmEvent({
      event: "webAppStage",
      additionalProps: {
        stage: 'Basket',
        products: basketProducts,
        product: lastProduct // needed to capture product funnels
      }})
  }

  componentWillUnmount() {
    this.removeConversionScripts();
    //window.removeEventListener('beforeunload', this.unloadListener);
  }

  loadConversionScripts = () => {
    if (this.sentConversion) {
      console.log("Already sent conversion");
      return true;
    }
    if (!this.state.orderData){
      console.log("No orderData");
      return true;
    }

    const total = parseFloat(this.state.orderData.total);
    const currency = this.props.currency;

    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: true, // enable logs
    };
    ReactPixel.init("667949721737656", advancedMatching, options);
    //ReactPixel.pageView();

    const data = {
      value: total,
      currency: currency,
      transaction_id: this.state.orderData.reference,
    };

    ReactPixel.track("Purchase", data);

    this.sentConversion = true;
  };

  removeConversionScripts = () => {
    [this.facebookEventsScriptId].forEach(removeElementFromBodyWithId);
  };

  trackAdwordsConversion = () => {
    console.log("Tracking AdWords/E-commerce conversion");
    try {
      //if (window.gtag) {
        let adConversion = {
          send_to: "AW-974777519/24rBCPujrrcBEK_Z59AD",
          value: parseFloat(this.state.orderData.total),
          currency: this.props.currency,
          transaction_id: this.state.orderData.reference,
        };
        console.log("Tracking ad conversion", adConversion);
        //window.gtag("event", "conversion", adConversion);

        //console.log("Order data", this.state.orderData);
        // E-commerce purchase
        let itemsList = this.state.orderData.line_items.map(function(line_item, index) {
          return {
            brand: "PostSnap",
            category: line_item.item_description, // product type
            id: line_item.product.app_key, // SKU
            name: line_item.item_description, // desciption
            price: `${line_item.sub_total/line_item.quantity}`, // '3.0'
            quantity: line_item.quantity, // 1
            list_positon: index + 1,
          };
        });

        let shippingTotal = 0;
        let totalShipping = this.state.orderData.line_items.reduce(
          (sum, line_item) => sum + parseFloat(line_item.delivery_cost),
          shippingTotal
        );

        let promotionCode = "None";
        if (this.state.orderData.promotions.length > 0) {
          promotionCode = this.state.orderData.promotions[0].code;
        }

        let transaction = {
          transaction_id: this.state.orderData.reference,
          affiliation: "PostSnap Website",
          value: parseFloat(this.state.orderData.total),
          currency: this.props.currency,
          tax: 0.0, // TODO
          shipping: totalShipping, //parseFloat(totalShipping), // TODO
          items: itemsList,
          coupon: promotionCode || "None",
        };

        console.log("Tracking e-commerce purchase", transaction);
        //window.gtag("event", "purchase", transaction);

        let products = this.state.orderData.line_items.map(function(line_item, index) {
          return {
            'name': line_item.item_description, // Name or ID is required.
            'id': line_item.product.app_key,
            'price': `${line_item.sub_total/line_item.quantity}`, // unit price
            'brand': 'PostSnap',
            'category': line_item.item_description, //product type
            'quantity': line_item.quantity,
          }
        });

        //transactionID
        //transactionTotal
        //currency
        //https://developers.google.com/tag-manager/enhanced-ecommerce
        window.dataLayer = window.dataLayer || [];
      
        let ecommercePurchaseData = {
          'event': 'eec.purchase',
          'ecommerce': {
            'currencyCode': this.props.currency,
            'purchase': {
              'actionField': {
                'id': this.state.orderData.reference,
                'affiliation': 'PostSnap Website',
                'revenue': `${this.state.orderData.total}`,
                'tax': '0.00',
                'shipping': `${totalShipping}`,
              },
              'products': products
            }
          },
          'enhanced_conversion_data': {
            'email': this.state.orderData.customer_email
          }
        }

        let googleAdsConversionData = {
          'event': 'ga.purchase',
          'ecommerce': {
            'currencyCode': this.props.currency,
            'purchase': {
              'actionField': {
                'id': this.state.orderData.reference,
                'affiliation': 'PostSnap Website',
                'revenue': parseFloat(this.state.orderData.total),
                'tax': '0.00',
                'shipping': totalShipping,
              },
              'products': products
            },
            'enhanced_conversion_data': {
              'email': this.state.orderData.customer_email
            }
          }
        }

        window.dataLayer.push(ecommercePurchaseData);
        window.dataLayer.push(googleAdsConversionData);
        console.log("Ecommerce purchase data", ecommercePurchaseData)
        console.log("Google Ads conversion data", googleAdsConversionData)

        const gtmProducts = this.state.orderData.line_items.map(function(line_item, index) {
          return {
            'name': line_item.product.app_key,
            'designId': `GC${line_item.design_id}`
          }
        });
        const gtmProductKeys = gtmProducts.map(product => product.name);
        const gtmUniqProductKeys = [...new Set(gtmProductKeys)].join(" ");

        gtmEvent({
          event: "webAppStage",
          additionalProps: {
            stage: 'Transaction Complete',
            products: gtmProducts,
            product: gtmUniqProductKeys
          }
        })

        const customShippingAmounts = [
          {
            appKey: 'STANDARD_POSTCARD',
            shipping: 0.70
          },
          {
            appKey: 'LARGE_POSTCARD',
            shipping: 0.70
          }
        ]

        let customShippingTotal = 0;
        let customTotalShipping = this.state.orderData.line_items.reduce(
          (sum, line_item) => {
            let itemShippingCost = line_item.delivery_cost;
            if(customShippingAmounts.find(c => c.appKey === line_item.product.app_key)){
              itemShippingCost = customShippingAmounts.find(c => c.appKey === line_item.product.app_key).shipping
              console.log("Using custom shipping amount", itemShippingCost)
            }
            return sum + parseFloat(itemShippingCost)
          },
          customShippingTotal
        );

        const orderTotal = parseFloat(this.state.orderData.total)
        // If order total (inc discount) < shipping then use ordertotal, otherwise deduct shipping from total
        const totalToUse = orderTotal <= customTotalShipping ? orderTotal : Math.max(0, orderTotal-customTotalShipping)
        const orderTotalLessShipping = parseFloat(totalToUse.toFixed(2))
        const totalLessVat = (orderTotalLessShipping/1.20).toFixed(2);
        const vatApplied = parseFloat((orderTotalLessShipping - totalLessVat).toFixed(2));
        console.log("Net VAT", vatApplied);
        console.log("Total less VAT", totalLessVat);
        const userEmail = this.state.orderData.customer_email; //this.props.user.get('email');
        console.log("User email", userEmail);
        const md5Email = cryptojs.MD5(userEmail).toString();
        
        let promoCode = ""

        if(this.state.orderData.promotions.length > 0){
          promoCode = this.state.orderData.promotions[0].code
        }

        const adTractionConversionData = {
          'transactionId': this.state.orderData.reference,
          'transactionTotal': totalLessVat,
          'md5': md5Email,
          'transactionPromoCode': promoCode,
          'currency': this.props.currency
        }
        console.log("Adtraction data", adTractionConversionData);
        window.dataLayer.push(adTractionConversionData);
        gtmEvent({
          event: "adtractionConversion"
        })

        const pinterestProducts = gtmProducts.map(product => product.designId);
        const pinterestConversionData = {
          pinterestValue: parseFloat(this.state.orderData.total),
          pinterestOrderQty: 1,
          pinterestCurrency: this.props.currency,
          pinterestOrderId: this.state.orderData.reference,
          pinterestProductID: pinterestProducts[0],
          pinterestProductCategory: "",
        }
        
        console.log("Pinterest data", pinterestConversionData);
        window.dataLayer.push(pinterestConversionData);
        gtmEvent({
          event: "pinterestConversion",
          additionalProps: pinterestConversionData
        })

        return false;
      //}
    } catch (err) {
      console.log("Failed to track Adwords conversion", err);
    }
  };

  checkForUnrenderedItems = debounce(() => {
    const unrenderedItems = getUnrenderedItems(this.props.orderSummary.get("items"));
    unrenderedItems.forEach(item => {
      console.log("Rendering item from unchecked");
      this.props.renderItem(item.get("id"));
    });
  }, 1000);

  retryRenders = () => {
    const unrenderedItems = getUnrenderedItems(this.props.orderSummary.get("items"), true);
    unrenderedItems.forEach(item => {
      console.log("Rendering item retry");
      this.props.renderItem(item.get("id")).catch(err => {
        message.error("There was still a problem. Please contact us if the issue persists.");
      });
    });
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  closeEditor = (closeBehavior = {}) => {
    if (closeBehavior.closedWithEscKey || closeBehavior.closedByClickingOverlay) {
      return;
    }

    this.setState({
      itemToEdit: null,
      isEditorModalVisible: false,
    });
  };

  handleEditorSave = item => {
    this.props.updateItem(item.get("id"), item.toJS());
    // TODO: Re-show 3d approval modal here
    this.closeEditor();
    this.props.navigateToApproval({fromBasket : true});
  };

  openFullSizePreview = item => {
    this.setState({
      isPreviewModalVisible: true,
      previewModalActiveForItem: item
    })
  }

  closeFullSizePreview = () => {
    this.setState({
      isPreviewModalVisible: false,
      previewModalActiveForItem: null
    })
  }


  handleClickPreview = item => {
    if (item.get('isReorderedItem')) {
      this.setState({
        itemToEditAddress: item,
      });
      return;
    }

    switch (item.get("productTypeId")) {
      case PRODUCT_TYPE_IDS.PHOTO_PRINT:
        if (item.get('isCustomPrint') || item.get('isCollagePrint')){
          this.openFullSizePreview(item);
          break;
        } else {
          this.openCropperModal(item);
          break;
        }    
      case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
        this.openPhotoBookSpreadPreview(item);
        return;
      default:
        this.openEditor(item);
    }
  };

  handleSubmitGuestPasswordConversion = async e => {
    e.preventDefault();
    this.setState({
      isConvertingAccount: true,
    });
    try {
      const data = {
        email: this.props.guestDetails.get('email'),
        new_password: this.state.guestConversionPassword,
        guest_token: this.props.guestToken,
      }

      const response = await this.props.convertGuestAccount(data);
      if (!response.payload.data.success) {
        let errorMessage = "We were unable to convert your account, please check your password contains 7 characters and at least 1 number."
        if (response.payload.data.errors && response.payload.data.errors.base){
          errorMessage = response.payload.data.errors.base[0]
        }
        this.setState({
          alert: {
            type: "error",
            title: "Error",
            text: errorMessage,
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
          isConvertingAccount: false,
        });
      } else {
        this.setState(
          {
            isConvertingAccount: false,
          },
          () => {
            message.success("Your account has been created and you havce been signed in");
            // if (response.payload.data.data.message) {
            //   message.success(response.payload.data.data.message);
            // }
          }
        );
      }
    } catch (err) {
      this.setState({
        isConvertingAccount: false,
      });
    }
  };

  handleClickEditorModeForItem = item => {
    if (this.state.itemToEnableEditModeFor === item.get("id")) {
      this.setState({
        itemToEnableEditModeFor: null,
      });
    } else {
      this.setState({
        itemToEnableEditModeFor: item.get("id"),
      });
    }
  };

  handleChangeProductOptionForItem = (item, option) => {
    let needsRender = false;

    const updatedItem = item.withMutations(item => {
      const newOption = {...item.get("product_options").toJS(), ...option};

      item.set("product_options", newOption)
      //console.log("Option is", option);
      //console.log("Option is", !!option.border);
      if (option.hasOwnProperty("effects")){
        needsRender = true;
      }

      if (option.hasOwnProperty("border")){
        console.log("Border toggled to", option.border);
        const indexOfPhotoLayer = item
          .get("layers")
          .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
        const borderConfig = option.border ? {
          style: {
            type: "color",
            color: "rgb(255,255,255)",
          },
          thickness: 0.111 // 6mm/54mm = 0.111
        } : {
          style: null,
          thickness: 0
        }
        item.setIn(
          ["layers", indexOfPhotoLayer, "config", "border"],
          fromJS(borderConfig)
        );
        item.setIn(
          ["product_options", "border"],
          option.border
        );
        needsRender = true;
      }

    });

    if (needsRender) {
      //console.log("Needs render");
      this.props.updateItem(updatedItem.get("id"), updatedItem.toJS());
    } else{
      this.props.updateItemWithNoRender(updatedItem.get("id"), updatedItem.toJS());      
    }
  };

  handleClickItemAddress = item => {
    this.setState({
      itemToEditAddress: item,
    });
  };

  handleClickCheckoutAddress = item => {
    this.props.goToShipping("edit")
    // this.setState({
    //   itemToEditAddress: item,
    // });
  };

  handleSelectAddressBookEntry = (addressBookEntry, isDoubleDirect) => {
    if (this.state.itemToEditAddress){
      this.props.updateItemAddress(this.state.itemToEditAddress.get("id"), {
        addressBookId: addressBookEntry.get('id'),
        addressBookEntry: addressBookEntry.toJS(),
        address: null,
      });
    }
    
    this.closeAddressInputModal();
  };

  handleChangePostageScheme = (item, postageSchemeId) => {
    //console.log("Updating postageScheme to", postageSchemeId);
    //this.props.updateItemPostageScheme(item, postageSchemeId);
    //console.log("Saving checkout shipping option", postageSchemeId)
    //console.log("For Product Type ID", item.get('productTypeId'))
    this.props.onSaveCheckoutShippingOption(postageSchemeId, item.get('productTypeId'))
  };

  handleSaveAddress = address => {
    console.log("Saving address:", address);
    if (this.state.itemToEditAddress){
      this.props.updateItemAddress(this.state.itemToEditAddress.get("id"), {
        addressBookId: null,
        addressBookEntry: null,
        address: address,
      });
    }
    this.closeAddressInputModal();
  };

  closeAddressInputModal = () => {
    this.setState({ itemToEditAddress: null });
  };

  openCropperModal = item => {
    this.setState({
      itemToEdit: item,
      draftRotationState: item.get("isRotated"),
      isCropperModalVisible: true,
    });
  };

  handleRotateCrop = () => {
    this.setState({
      draftRotationState: !this.state.draftRotationState,
    });
  };

  closeCropper = () => {
    this.setState({
      itemToEdit: null,
      draftRotationState: null,
      isCropperModalVisible: false,
    });
  };

  handleSaveCrop = crop => {
    const itemForCropper = this.state.itemToEdit;
    const indexOfPhotoLayer = itemForCropper
      .get("layers")
      .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);

    const updatedItem = itemForCropper.withMutations(item => {
      item.setIn(
        ["layers", indexOfPhotoLayer, "config", "layout", 0, "image", "cropData"],
        fromJS(crop)
      );
      item.set("isRotated", Boolean(this.state.draftRotationState));
    });

    this.props.updateItem(updatedItem.get("id"), updatedItem.toJS());
    this.closeCropper();
    this.props.navigateToApproval({fromBasket : true});
  };

  openPhotoBookSpreadPreview = item => {
    this.setState({
      itemToEdit: item,
      isPhotoBookSpreadPreviewVisible: true,
    });
  };

  closePhotoBookSpreadPreview = () => {
    this.setState({
      itemToEdit: null,
      isPhotoBookSpreadPreviewVisible: false,
    });
  };

  handleSavePhotoMagazine = item => {
    if (this.state.isDuplicate) {
      this.props.addBasketItem(item.toJS());
      this.setState({
        isDuplicate: false,
      });
    } else {
      this.props.updateItem(item.get("id"), item.toJS());
    }
    this.closePhotoMagazineEditor();
    this.props.navigateToApproval({fromBasket : true});
  };

  openEditor = item => {
    this.setState({
      itemToEdit: item,
      isEditorModalVisible: true,
      isDuplicate: false,
    });
  };

  openEditorForDuplicate = item => {
    switch (item.get("productTypeId")) {
      case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
        this.setState({
          itemToEdit: item,
          isPhotoBookSpreadPreviewVisible: true,
          isDuplicate: true,
        });
        break;
      default:
        this.setState({
          itemToEdit: item,
          isEditorModalVisible: true,
          isDuplicate: true,
        });
        break;
    }
  };

  handleCreateOrder = async (asGuest = false) => {
    //console.log("Creating order as guest", asGuest)
    this.setState({
      isCreatingOrder: true,
    });

    try {
      let orderResponse 
      if (asGuest && this.props.hasGuestDetails){
        orderResponse = await this.props.createEmailOnlyGuestFromItems(this.props.guestDetails);
        //console.log("Order data", orderResponse.data.data);
        await this.props.setGuestToken(orderResponse.data.data.guest_token)
      } else {
        orderResponse = await this.props.createOrderFromItems();
      }
      this.setState({
        orderData: orderResponse.data.data,
        isCreatingOrder: false,
      });
      return orderResponse;
    } catch (err) {
      console.warn("Error while creating order:", err);
      if (err && err.data) {
        console.log(JSON.stringify(err.data.errors));
      }
      this.setState({
        isCreatingOrder: false,
      });
      return err;
    }
  };

  cleanUpOrder = () => {
    if(this.props.hasGuestToken){
      this.props.clearGuestDetails();
      this.props.clearGuestToken();
    }
  }

  handleStripePaymentRequestComplete = async () => {
    message.success('Payment completed');
    this.setState({
      //isOrderCompletionVisible: true,
      isCreatingGuestOrder: false,
      isPayingForOrder: false,
    }, async () => {
      await this.props.setPaymentSuccessful();
      this.trackAdwordsConversion();
      this.cleanUpOrder();
    });
  }

  handleStripePaymentRequestFailed = async (errorMessage) => {
    message.error(`Payment failed - ${errorMessage}`);
    this.setState({
      isOrderCompletionVisible: false,
      isCreatingGuestOrder: false,
      isPayingForOrder: false,
    });
  }

  handleStripePaymentRequestSuccess = async (token, data) => {
    // const makingPaymentMessage = message.loading({
    //   content: "Making payment, please wait...",
    //   key,
    //   duration: 0,
    // });
    if (this.props.isSignedIn || this.props.hasGuestToken) {
      try {

        if (this.state.orderData !== null && this.props.hasGuestToken){
          //console.log("Is email guest checkout and has order data...", this.state.orderData);
        } else{
          const orderResponse = await this.props.createOrderFromItems();

          if(!orderResponse || orderResponse.data === "" || !orderResponse.data){
            //console.log("No order Response:", orderResponse);
            throw new Error('No response');
          }

          this.setState({
            orderData: orderResponse.data.data,
          });
        }

        const response = await this.props.chargeStripePayment({
          reference: this.state.orderData.reference,
          stripeToken: token.id,
        });
        //console.log(response);
        if (response.error) {
          console.warn(
            "Error while creating paymentRequest with Stripe payment:",
            response.payload.data.errors
          );
          const errorMessage =
            `${response.payload.data.errors.base[0].message} Please try again or use another card.` ||
            "Something went wrong with your payment, Please try again later.";
          message.error("There was an error making your payment");
          this.setState({
            alert: {
              type: "error",
              title: "Payment Error",
              text: errorMessage,
              showCancelButton: false,
              confirmButtonText: "OK",
              onConfirm: this.clearAlert,
            },
          });
        } else {
          message.success("Payment completed");
          
          //todo move to a finalize ordercomplete
          this.setState({
            isOrderCompletionVisible: true,
          }, () => {
            this.trackAdwordsConversion();
            this.cleanUpOrder()
          });
        }
      } catch (err) {
        console.warn("Error while creating order:", err);

        let errorMessage = "There was a problem creating your order — this may have been due to loss of connectivity. Please check your email for an order confirmation before trying again. Contact us at team@postsnap.com if the issue persists";

        if (err && err.data){
          if (err.data.errors && err.data.errors.base) {
            errorMessage = err.data.errors.base[0].message || err.data.errors.base[0];
          }
        }

        //console.log(errorMessage);
        message.error("There was an error making your payment");
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: errorMessage || "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
          isOrderCompletionVisible: false,
        });

        return err;
      } finally {
      }
    } else {
      this.setState({
        isCreatingGuestOrder: true,
      });

      try {
        const orderResponse = await this.props.createGuestOrderFromItems(token.id, data.payerEmail);
        
        //console.log("Order Response:", orderResponse);
        
        if(!orderResponse || orderResponse.data === ""){
          console.log("No order Response:", orderResponse);
          throw new Error('No response');
        }

        this.setState({
          orderData: orderResponse.data.data,
        });
        
        if (orderResponse.data.success === false){
          let errorMessage;
          if (orderResponse.data.errors.promotion) {
            errorMessage = orderResponse.data.errors.promotion[0];
          } else if (orderResponse.data.errors.base){
            const error = orderResponse.data.errors.base[0].message || orderResponse.data.errors.base[0];
            errorMessage = `There was a problem creating your order - ${error}`;
          } else {
            errorMessage = "There was a problem creating your order";
          }

          message.error(errorMessage);
          this.setState({
            alert: {
              type: "error",
              title: "Payment Error",
              text: errorMessage || "Something went wrong with your payment, Please try again later.",
              showCancelButton: false,
              confirmButtonText: "OK",
              onConfirm: this.clearAlert,
            },
            orderData: orderResponse.data.data,
            isCreatingGuestOrder: false,
            isOrderCompletionVisible: false,
          });
          // TODO: might want to throw an error
        } else{
          message.success('Payment completed');
          this.setState({
            orderData: orderResponse.data.data,
          });
          this.setState({
            isOrderCompletionVisible: true,
            isCreatingGuestOrder: false,
          }, () => {
            this.trackAdwordsConversion();
            this.cleanUpOrder();
          });
        }
      } catch (err) {
        console.warn("Error while creating guest order with Stripe payment:", err);

        let errorMessage = "There was a problem creating your order — this may have been due to loss of connectivity. Please check your email for an order confirmation before trying again. Contact us at team@postsnap.com if the issue persists";

        if (err && err.data){
          if (err.data.errors && err.data.errors.base) {
            errorMessage = err.data.errors.base[0].message || err.data.errors.base[0];
          }
        }

        //console.log(errorMessage);
        message.error("There was an error making your payment");
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: errorMessage || "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
          isOrderCompletionVisible: false,
        });
        return err;
      } finally {
        this.setState({
          isCreatingGuestOrder: false,
        });
      }
    }
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    this.setState({
      isPayingForOrder: true,
    });

    try {
      const response = await this.props.chargeStripePayment({
        reference: this.state.orderData.reference,
        stripeToken: result.token.id,
        saveCardDetails,
      });

      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        
        this.setState({
          isOrderCompletionVisible: true,
        }, () => {
          this.trackAdwordsConversion();
          this.cleanUpOrder();
        });
      }
    } catch (err) {
      console.warn("Error while paying for order with Stripe:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handleStripePaymentFormComplete = async (result) => {
    console.log("Payment complete", result)
    this.setState({
      isPayingForOrder: true,
    });
    try {
      message.success("Payment completed");
    
      this.setState({
        isOrderCompletionVisible: true,
      }, async () => {
        await this.props.setPaymentSuccessful();
        this.trackAdwordsConversion();
        this.cleanUpOrder();
      });
      
    } catch (err) {
      console.warn("Error while completing order:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  }

  handleStripePaymentFormError = async (errorMessage) => {
    console.log("Payment errored", errorMessage)
    message.error(`Payment failed - ${errorMessage}`);
    this.setState({
      isPayingForOrder: false,
    });
  }

  handleStripePaymentFormConfirm = async (result) => {
    this.setState({
      isPayingForOrder: true,
    });

    console.log("Result", result);

    try {
      const response = await this.props.confirmStripePayment({
        reference: this.state.orderData.reference,
        intent: result
      });

      if (response.error){
        this.setState({
          alert: {
            type: 'error',
            title: 'Payment Error',
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: 'OK',
            onConfirm: this.clearAlert
          }
        });
      } else {
        console.log(response.payload);
        return response.payload
      }
    } catch (err) {
      console.warn('Error while confirming with Stripe:', err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    this.setState({
      isPayingForOrderWithSavedCard: true,
    });

    try {
      const response = await this.props.chargeStripeCustomerForOrder(
        this.state.orderData.reference
      );
      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.cleanUpOrder();
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while paying for order with Stripe:", err);
      this.setState({
        isPayingForOrderWithSavedCard: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrderWithSavedCard: false,
      });
    }
  };

  handlePayPalAuthorization = async response => {
    message.loading("Please wait while we confirm your payment");
    this.setState({
      isPayingForOrder: true,
    });
    try {
      //console.log("Confirming with token", response.id);
      const payPalResponse = await this.props.confirmPayPalPayment({
        reference: this.state.orderData.reference,
        token: response.id,
      });
      //console.log("Paypal confirmation response", payPalResponse);
      if (payPalResponse.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        message.success("Payment confirmed");
        this.cleanUpOrder();
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while confirming PayPal payment:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handlePayForOrderWithPrepayBalance = async () => {
    console.log("Paying for order with prepay balance");
    this.setState({
      isPayingForOrder: true,
    });

    try {
      const response = await this.props.processPrepayPayment({
        amount: this.props.orderSummary.get("total"),
        reference: this.state.orderData.reference,
      });
      if (response.error) {
        this.setState({
          alert: {
            type: "error",
            title: "Payment Error",
            text: "Something went wrong with your payment, Please try again later.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.cleanUpOrder()
        this.trackAdwordsConversion();
        this.setState({
          isOrderCompletionVisible: true,
        });
      }
    } catch (err) {
      console.warn("Error while processing prepay payment:", err);
      this.setState({
        isPayingForOrder: false,
      });
      return err;
    } finally {
      this.setState({
        isPayingForOrder: false,
      });
    }
  };

  handleAddToBasket = async item => {
    if (item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS) {
      this.setState({
        renderItemLoading: true,
      });

      const bagItem = await this.props.addBasketItemAsync(item.toJS());
      console.log("Rendering item from handleAddToBasket");
      this.props.renderItem(bagItem.payload.id).then(async res => {
        const item = await this.props.getItem(bagItem.payload.id);
        const s3Key = item.payload.itemData.preview_s3_key;
        const productSlug = productsByProductId
          .get(item.payload.itemData.productId)
          .get("url_slug");

        this.setState({
          canvasApprovalModalItemId: bagItem.payload.id,
          canvasApprovalModalItem: bagItem.payload,
          canvasApprovalModalImage: s3Key,
          threeDModel: MODELS[productSlug],
        });

        if (this.state.isDuplicate) {
          this.setState({
            isDuplicate: false,
          });
        }
        this.closeEditor();
        this.setState({
          renderItemLoading: false,
        });
        setTimeout(() => {
          this.openCanvasApprovalModal();
        }, 200);
      });
    } else {
      this.props.addBasketItem(item.toJS());
      this.closeEditor();
    }
  };

  handleRemovePromo = () => {
    this.setState({
      hasRemovedPromo: true,
      appliedAutomaticPromo: false
    }, () => {
      this.props.removePromoCode()
    }) 
  }

  handleShowAuthModal = () => {
    if(this.props.unapprovedItems.size > 0){
      //console.log("Show auth modal pre approval");
      this.props.showAuthModal({postApproval: false});
    } else{
      //console.log("Show auth modal post approval");
      this.props.showAuthModal({postApproval: true});
    }
  };

  openCanvasApprovalModal = () => {
    this.setState({
      isCanvasApprovalModalVisible: true,
    });
  };

  closeCanvasApprovalModal = itemId => {
    this.setState({
      isCanvasApprovalModalVisible: false,
    });
  };

  closeCanvasApprovalModalAndDeleteItem = itemId => {
    //this.props.deleteItem(itemId);
    this.closeCanvasApprovalModal();
  };

  handleApproveCanvasItem = item => {
    this.props.approveItem(item);
    this.closeCanvasApprovalModal();
  };

  handleEditCanvasItem = item => {
    this.props.deleteItem(item.id);
    this.closeCanvasApprovalModal();

    if (this.state.isDuplicate) {
      this.setState({
        isDuplicate: false,
      });
      this.openEditorForDuplicate(fromJS(item));
    } else {
      this.openEditor(fromJS(item));
    }

    // const editorInstance = this.editorContainer.getWrappedInstance();
    // editorInstance.goToFrontPage();
  };

  goToShop = () => {
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  handleChangeGuestConversionPassword = val => {
    this.setState({
      guestConversionPassword: val,
    });
  };

  render() {
    const cropperModalProps = {};
    const { itemToEdit } = this.state;

    if (itemToEdit) {
      const indexOfPhotoLayer = itemToEdit
        .get("layers")
        .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
      if (indexOfPhotoLayer === -1) {
        return;
      }
      const regionToCrop = itemToEdit.getIn(["layers", indexOfPhotoLayer, "config", "layout", 0]);

      const photoRect = itemToEdit.getIn([
        "layers",
        indexOfPhotoLayer,
        "config",
        "rect",
      ]);

      let productDimensions = itemToEdit.get("productDimensions").toJS();
      
      //We want the photo region dimensions and not the product dimensions
      productDimensions.width = productDimensions.width * photoRect.get('width');
      productDimensions.height = productDimensions.height * photoRect.get('height');

      const imageToCrop = regionToCrop.get("image");
      //const productDimensions = itemToEdit.get("productDimensions").toJS();
      cropperModalProps.imgUrl = imageToCrop.getIn(["src", "lowResUrl"]);
      cropperModalProps.cropData = imageToCrop.get("cropData")
        ? imageToCrop.get("cropData").toJS()
        : null;
      cropperModalProps.ratio =
        (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right) /
        (productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom);

      const textLayer = itemToEdit.get("layers").find(layer =>
        (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
      


      if (itemToEdit.get('productTypeId') === PRODUCT_TYPE_IDS.PHOTO_PRINT){
        cropperModalProps.disallowRotation = false;
        if(textLayer){
          // cropperModalProps.renderPhotoPrintsTextBand = true;
          // cropperModalProps.photoPrintsText = {
          //   text: textLayer.getIn(['config', 'text']),
          // };
          cropperModalProps.disallowRotation = true;
          // Hack to hide rotation for text prints
        }

        if (cropperModalProps.ratio === 1 || productDimensions.width === productDimensions.height){
          cropperModalProps.disallowRotation = true;
        }
      }

      if (this.state.draftRotationState) {
        cropperModalProps.ratio =
          (productDimensions.height +
            productDimensions.bleed.top +
            productDimensions.bleed.bottom) /
          (productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right);
      }

      cropperModalProps.productDimensions = itemToEdit.get("productDimensions").toJS();


      cropperModalProps.renderBleedBands = productDimensions.bleed.left === productDimensions.bleed.right && productDimensions.bleed.left > 0;
    }

    const onlyCountriesforProductType = this.state.itemToEditAddress && getShippingCountriesForProductType(this.state.itemToEditAddress.get("productTypeId"), this.state.itemToEditAddress.get("productId"));
    //console.log("onlyCountriesforProductType", onlyCountriesforProductType);

    const hasExistingAddress = this.state.itemToEditAddress && this.state.itemToEditAddress.get("address");

    const canMakeStripePayment = !this.state.stripePaymentDisabled && this.state.canMakeStripePayment

    //const checkoutShippingNeeded = this.props.items.some(item => sharedShippingAddress(item.get('productTypeId')))
    // Can we checkout...
    // if not signed in, do we have guest/contact details
    // if some of the items need a shared shipping address, is it populated and valid

    // const existingContactDataIsComplete = Object.keys(this.props.checkoutContactDetails.toJS()).includes("email")
    // const contactDetailsPresent = this.props.isSignedIn ? true : existingContactDataIsComplete

    // checkout details present && either checkout shipping is needed and valid or its not needed
    //const canCheckout = contactDetailsPresent && ((checkoutShippingNeeded && validateAddress(this.props.checkoutShippingAddress.toJS()) === true) || !checkoutShippingNeeded)
    const ableToCheckout = canCheckout(
      this.props.items,
      this.props.checkoutContactDetails.toJS(),
      this.props.checkoutShippingAddress.toJS(),
      this.props.isSignedIn
    )

    const mobile = {mobile: (this.props.checkoutContactDetails.get('mobile') || (this.props.user && this.props.user.get('mobile'))) }
    const checkoutDetails = this.props.isSignedIn ? fromJS({...this.props.user.toJS(),...mobile}) : this.props.checkoutContactDetails
    
    const addressInputModal = (
      <EditorAddressInputModal
        key="address-input-modal"
        isDoubleDirect={Boolean(
          this.state.itemToEditAddress &&
            ![
              PRODUCT_TYPE_IDS.POSTCARD,
              PRODUCT_TYPE_IDS.CANVAS,
              PRODUCT_TYPE_IDS.PHOTO_PRINT,
              PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
            ].includes(this.state.itemToEditAddress.get("productTypeId"))
        )}
        isOpen={Boolean(this.state.itemToEditAddress)}
        mode={hasExistingAddress ? "edit" : "new"}
        initialFormData={
          hasExistingAddress
            ? this.state.itemToEditAddress.get("address").toJS()
            : null
        }
        onCancel={this.closeAddressInputModal}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
        onlyCountries={onlyCountriesforProductType || []}
        onlyNewAddress={this.props.hasGuestDetails}
      />
    );

    const cropperModal = (
      <EditorCropperModal
        key="cropper-modal"
        isOpen={this.state.isCropperModalVisible}
        {...cropperModalProps}
        onClose={this.closeCropper}
        onRotate={this.handleRotateCrop}
        onSave={this.handleSaveCrop}
      />
    );

    const photoMagazineEditorModal = (
      <Modal
        key="photo-book-preview"
        isOpen={this.state.isPhotoBookSpreadPreviewVisible}
        onClose={this.closePhotoBookSpreadPreview}
        title={
          <>
            <span
              style={{
                textAlign: "center",
                display: "block",
                paddingBottom: 20,
                fontSize: 16,
              }}
            >
              Photo Book Preview
            </span>
            {/* <span
              style={{
                position: "absolute",
                right: 20,
                top: 0,
                pointerEvents: 'all'
              }}
            >
              <Button
              label="Close"
              theme="dark-blue"
              type="primary"
              style={{ marginTop: "10px" }}
              onClick={this.closePhotoBookSpreadPreview}
            />
            </span> */}
          </>
        }
      >
        <PhotoBookSpreadPreview
          item={this.state.itemToEdit}
          onDone={this.closePhotoBookSpreadPreview}
        />
      </Modal>
    );

    const canvasApprovalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isCanvasApprovalModalVisible}
        onClose={this.closeCanvasApprovalModal}
        title="Approve Your Canvas"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={() =>
              this.closeCanvasApprovalModalAndDeleteItem(this.state.canvasApprovalModalItemId)
            }
          />
        }
        rightAction={
          <Button
            theme="default"
            priority="tertiary"
            label="Approve"
            onClick={() => this.handleApproveCanvasItem(this.state.canvasApprovalModalItemId)}
          />
        }
      >
        <MainContent scrollable={false} centeredVertically padded key="main-preview">
          <ThreeDimensionalViewer
            model={this.state.threeDModel}
            image={this.state.canvasApprovalModalImage}
          />
          <p className="help-text text-center" style={{ zIndex: 1 }}>
            This preview is for illustrative purposes only. The actual product may differ slightly
            from what is shown here.
          </p>
        </MainContent>
        <Footer padded key="footer">
          <div className="footer__split-buttons">
            <Button
              label="Edit"
              priority="secondary"
              onClick={() => this.handleEditCanvasItem(this.state.canvasApprovalModalItem)}
            />
            <Button
              label="Approve"
              onClick={() => this.handleApproveCanvasItem(this.state.canvasApprovalModalItemId)}
            />
          </div>
        </Footer>
      </Modal>
    );

    let previewWidth = document.documentElement.clientWidth < 550 ? document.documentElement.clientWidth * 0.7 : 500 
  
    const previewModal = (
      <AntModal
        visible={this.state.isPreviewModalVisible}
        title={<span style={{textAlign:'center', display:'block'}}>Preview</span>}
        okText="Done"
        width={550}
        style={{ top: 30 }}
        onCancel={this.closeFullSizePreview}
        onOk={this.closeFullSizePreview}
        footer={null}
        key="preview-render-modal"
        okButtonProps={{style: {backgroundColor: '#49bda1', borderColor: '#49bda1'}}}
      > 
        
        {this.state.previewModalActiveForItem && (
          <div>
            <HtmlRenderer
              key={`preview-render`}
              width={previewWidth}
              previewMode={true}
              isInteractive={false}
              item={this.state.previewModalActiveForItem.toJS()}
              page={this.state.previewModalActiveForItem.getIn(["pages", "front"])}
              onSelectRegionInPhotoLayer={(layerId, regionIndex) => console.log("Photo clicked")}
            />
          </div>
        )}
      </AntModal>
    )


    const content = [
      cropperModal,
      photoMagazineEditorModal,
      canvasApprovalModal,
      addressInputModal,
      previewModal,
      this.state.isOrderCompletionVisible && (
        <React.Fragment>
          <Header
            theme="grey"
            image="/images/logo.svg"
            title="PostSnap Postcard App"
            rightAction={
              <div style={{display:'flex'}}>
                <AccountButtonContainer />
                <BasketButtonContainer />
              </div>
            }
            leftAction={<ShopButtonContainer />}
            onClickTitleOrImage={this.goToShop}
          />
          <MainContent scrollable={true} padded={true}>
            {this.state.orderData && (
              <div
                key="order-completion"
                id="order-complete"
                className="animated fadeIn"
                dangerouslySetInnerHTML={{
                  __html: this.state.orderData.completion_html,
                }}
              />
            )}
            <br />
            <div style={{
              width: '80%',
              margin: '0 auto',
              display: 'block',
              maxWidth: '350px'
            }}>
              <Button
                block
                theme="dark-blue"
                label="SHOP FOR ANOTHER PRODUCT"
                link={process.env.REACT_APP_BASE_URL || "https://www.postsnap.com"}
                key="go-to-shop"
              />
            </div>
            
            {/* {(this.props.hasGuestToken && !this.props.isSignedIn) && (
              <div style={{marginTop: 'auto'}}>
                <Footer padded key="footer">
                  <div className="footer-convert_guest" style={{ background: 'white', padding: '10px', maxWidth: '400px', margin: '0 auto', boxShadow: "1px 1px 4px 2px #e0e0e0", borderRadius: '4px'}}>
                    <p style={{fontSize: '12px'}}>
                      <UserAddOutlined style={{ fontSize: '18px' }}/>&nbsp;
                      Save your details for a quicker checkout experience next time by providing a password to create an account with PostSnap
                    </p>
                    <form
                      className="form convert-guest-account"
                      onSubmit={this.handleSubmitGuestPasswordConversion}
                    >
                      <PasswordField
                        onChange={this.handleChangeGuestConversionPassword}
                        value={this.state.guestConversionPassword}
                        placeholder="Enter a password"
                      />
                      <p style={{padding: '4px 7px', background: '#fff7df', fontSize: '11px', marginTop: '5px', marginBottom: '0px'}}>Your account password should be at least 7 characters long and include at least one number</p>
                      <div style={{paddingTop: '10px'}}>
                        <Button
                          block
                          label="Create Customer Account"
                          priority={this.state.isConvertingAccount ? "secondary" : "secondary"}
                          type="submit"
                          theme="dark-blue"
                          disabled={!this.state.guestConversionPassword}
                          loadingLabel="Creating account..."
                          loading={this.state.isConvertingAccount}
                        />
                      </div>
                      
                    </form>
                  </div>
                </Footer>
              </div>
            )} */}
          </MainContent>
        </React.Fragment>
      ),
      !this.state.isOrderCompletionVisible && (
        <Basket
          key="basket"
          items={this.props.items}
          orderSummary={this.props.orderSummary}
          orderData={this.state.orderData}
          user={this.props.user}
          currency={this.props.currency}
          unapprovedItems={this.props.unapprovedItems}
          showDuplicateAlertForItem={this.props.showDuplicateAlertForItem}
          prepayBalance={this.props.prepayBalance}
          canMakeStripePayment={canMakeStripePayment}
          paymentRequest={this.state.paymentRequest}
          isSignedIn={this.props.isSignedIn}
          isCreatingOrder={this.state.isCreatingOrder}
          isPayingForOrder={this.state.isPayingForOrder}
          isPayingForOrderWithSavedCard={this.state.isPayingForOrderWithSavedCard}
          isCreatingGuestOrder={this.state.isCreatingGuestOrder}
          onClickPreview={this.handleClickPreview}
          onClickDuplicate={this.openEditorForDuplicate}
          onDeleteItem={this.props.deleteItem}
          onDeleteItems={this.props.deleteItems}
          onDeleteAllItems={this.props.deleteAllItems}
          onClickItemAddress={this.handleClickItemAddress}
          onClickCheckoutAddress={this.handleClickCheckoutAddress}
          onClickEditModeForItem={this.handleClickEditorModeForItem}
          onChangeProductOptionForItem={this.handleChangeProductOptionForItem}
          onCreateOrder={this.handleCreateOrder}
          onStripePaymentFormSubmit={this.handleStripePaymentFormSubmit}
          onStripePaymentFormConfirm={this.handleStripePaymentFormConfirm}
          onStripePaymentFormComplete={this.handleStripePaymentFormComplete}
          onStripePaymentFormError={this.handleStripePaymentFormError}
          onPayPalAuthorization={this.handlePayPalAuthorization}
          onPayForOrderWithPrepayBalance={this.handlePayForOrderWithPrepayBalance}
          onPayWithSavedCard={this.handlePayWithSavedCard}
          onShowAuthModal={this.handleShowAuthModal}
          onShowGuestCaptureOrAuthModal={this.props.showGuestCaptureOrAuthModal}
          onDismissDuplicateAlertItem={this.props.setDuplicateAlertShown}
          onApproveItem={this.props.approveItem}
          onApproveAllItems={this.props.approveAllItems}
          onApplyPromoCode={this.props.applyPromoCode}
          onRemovePromoCode={this.handleRemovePromo}
          onRetryRenders={this.retryRenders}
          goToRoute={this.props.goToRoute}
          hasSeenCrossSellModal={this.props.hasSeenCrossSellModal}
          onSetSeenCrossSellModal={this.props.setSeenCrossSellModal}
          onDecreaseQuantityForItem={this.props.decreaseQuantityForItem}
          onDecreasePackQuantityForItem={this.props.decreasePackQuantityForItem}
          onIncreaseQuantityForItem={this.props.increaseQuantityForItem}
          onIncreasePackQuantityForItem={this.props.increasePackQuantityForItem}
          editModeEnabledFor={this.state.itemToEnableEditModeFor}
          guestToken={this.props.guestToken}
          guestDetails={this.props.guestDetails}
          onEditGuestDetails={this.props.showGuestDetails}
          unApproveAllItems={this.props.unApproveAllItems}
          onChangePostageSchemeForItem={this.handleChangePostageScheme}
          resetAll={this.props.resetAll}
          onGoToPrints={this.props.goToPrints}
          onGoToShipping={this.props.goToShipping}
          onGoToGuestOrCustomer={this.props.goToGuestOrCustomer}
          onGoToContactDetails={this.props.goToContactDetails}
          checkoutContactDetails={checkoutDetails}
          checkoutShippingAddress={this.props.checkoutShippingAddress}
          checkoutShippingOption={this.props.checkoutShippingOption}
          hasCompletedCheckoutSteps={ableToCheckout}
          clearCheckoutDetails={this.props.clearCheckoutDetails}
          navigateToApproval={this.props.navigateToApproval}
        />
      ),
      <Modal
        key="basket-editor-modal"
        isOpen={this.state.isEditorModalVisible}
        onClose={this.closeEditor}
        hasHeader={false}
      >
        <EditorContainer
          key="basket-editor-container"
          ref={el => (this.editorContainer = el)}
          item={this.state.itemToEdit}
          isDuplicate={this.state.isDuplicate}
          onClose={this.closeEditor}
          onSave={this.state.isDuplicate ? this.handleAddToBasket : this.handleEditorSave}
          saveButtonLabel={this.state.isDuplicate ? "Add to Basket" : "Save"}
          disableScaling={true}
        />
      </Modal>,
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="basket-alert"
      />,
      <FullScreenLoader
        key="3d-loader"
        loader="bar"
        message="Please wait while we prepare a preview of your canvas ..."
        isVisible={this.state.renderItemLoading}
      />,
    ];

    return (
      <WithWipEditorItemRestore
        key="basket-wip"
        source={routeDefinitions.basket}
        onRestore={this.openEditor}
      >
        {content}
      </WithWipEditorItemRestore>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  hasSeenCrossSellModal: basketSelectors.getHasSeenCrossSellModal(state),
  unapprovedItems: basketSelectors.getAllUnapprovedItems(state),
  showDuplicateAlertForItem: basketSelectors.getDuplicateAlertItem(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  prepayBalance: authSelectors.getPrePayBalance(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  guestToken: authSelectors.getGuestToken(state),
  hasGuestToken: Boolean(authSelectors.getGuestToken(state)),
  checkoutContactDetails: basketSelectors.getCheckoutContactDetails(state),
  checkoutShippingAddress: basketSelectors.getCheckoutShippingAddress(state),
  checkoutShippingOption: basketSelectors.getCheckoutShippingOption(state),
  hasCompletedCheckoutSteps: Boolean(basketSelectors.getHasCompletedCheckoutSteps(state)),
});

const mapDispatchToProps = dispatch => ({
  resetAll: () => dispatch({ type: 'RESET_ALL' }),
  addBasketItem: item => dispatch(basketActions.addItem(item)),
  addBasketItemAsync: item => dispatch(basketActions.addItemAsync(item)),
  getItem: itemId => dispatch(basketActions.getItem(itemId)),
  goToBasket: () => dispatch(push(routeCreators.basket())),
  goToPrints: () => dispatch(push(routeCreators.productTypeLandingPage("prints"))),
  goToShipping: (mode) => dispatch(push(routeCreators.checkoutShipping(), {mode : mode})),
  goToGuestOrCustomer: () => dispatch(push(routeCreators.checkoutGuestOrCustomer())),
  goToContactDetails: () => dispatch(push(routeCreators.checkoutContactDetails(), {mode : 'edit'})),
  goToRoute: route => dispatch(push(route)),
  setSeenCrossSellModal: seenCrossSellModal =>
    dispatch(basketActions.setSeenCrossSellModal(seenCrossSellModal)),
  updateItem: (itemId, itemData) => dispatch(basketActions.updateItem(itemId, itemData)),
  updateItemWithNoRender: (itemId, itemData) =>
    dispatch(basketActions.updateItemWithNoRender(itemId, itemData)),
  updateItemAddress: (itemId, addressData) =>
    dispatch(basketActions.updateItemAddress(itemId, addressData)),
  updateItemPostageScheme: (itemId, postageSchemeId) =>
    dispatch(basketActions.updateItemPostageScheme(itemId, postageSchemeId)),
  onSaveCheckoutShippingOption: (shippingOption, productTypeId) => dispatch(basketActions.setCheckoutShippingOption(shippingOption, productTypeId)),
  deleteItem: itemId => dispatch(basketActions.deleteItem(itemId)),
  deleteItems: itemIds => dispatch(basketActions.deleteItems(itemIds)),
  deleteAllItems: () => dispatch(basketActions.deleteAllItems()),
  renderItem: itemId => dispatch(basketActions.renderItem(itemId)),
  setDuplicateAlertShown: itemId => dispatch(basketActions.setDuplicateAlertShown(itemId)),
  approveItem: itemId => dispatch(basketActions.approveItem(itemId)),
  approveAllItems: itemId => dispatch(basketActions.approveAllItems()),
  approveItems: itemIds => {
    if (itemIds.length) {
      dispatch(basketActions.approveItems(itemIds));
    }
  },
  unApproveAllItems: itemId => dispatch(basketActions.unApproveAllItems()),
  createOrderFromItems: () => dispatch(basketActions.createOrderFromItems()),
  createEmailOnlyGuestFromItems: (guestDetails) => dispatch(basketActions.createEmailOnlyGuestFromItems(guestDetails)),
  createGuestOrderFromItems: (stripeToken, customerEmail) =>
    dispatch(basketActions.createGuestOrderFromItems(stripeToken, customerEmail)),
  createPaymentIntent: (intentData) =>
    dispatch(basketActions.createPaymentIntent(intentData)),
  chargeStripePayment: ({ reference, stripeToken, saveCardDetails }) =>
    dispatch(
      basketActions.chargeStripePayment({
        reference,
        stripeToken,
        saveCardDetails,
      })
    ),
  chargeStripeCustomerForOrder: reference =>
    dispatch(basketActions.chargeStripeCustomerForOrder(reference)),
  confirmPayPalPayment: ({ reference, token }) =>
    dispatch(basketActions.confirmPayPalPayment({ token, reference })),
  confirmStripePayment: ({ reference, intent }) => dispatch(basketActions.confirmStripePayment({ intent, reference })),
  setPaymentSuccessful: () => dispatch(basketActions.setPaymentSuccessful()),
  processPrepayPayment: ({ amount, reference }) =>
    dispatch(basketActions.processPrepayPayment({ amount, reference })),
  applyPromoCode: code => dispatch(basketActions.applyPromoCode(code)),
  removePromoCode: code => dispatch(basketActions.removePromoCode(code)),
  showAuthModal: postApproval => dispatch(uiActions.showAuthModal(postApproval)),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  decreaseQuantityForItem: itemId => dispatch(basketActions.decreaseQuantityForItem(itemId)),
  decreasePackQuantityForItem: (itemId, currency = "GBP") => dispatch(basketActions.decreasePackQuantityForItem(itemId, currency)),
  increaseQuantityForItem: itemId => dispatch(basketActions.increaseQuantityForItem(itemId)),
  increasePackQuantityForItem: (itemId, currency = "GBP") => dispatch(basketActions.increasePackQuantityForItem(itemId, currency)),
  showGuestDetails: () => dispatch(uiActions.showGuestCaptureOrAuthModal()),
  onCloseGuestCaptureOrAuthModal: () => dispatch(uiActions.hideGuestCaptureOrAuthModal()),
  setGuestCheckoutDetails: details => dispatch(authActions.setGuestCheckoutDetails(details)),
  clearCheckoutDetails: () => dispatch(basketActions.clearCheckoutDetails()),
  setGuestToken: guestToken => dispatch(authActions.setGuestToken(guestToken)),
  clearGuestDetails: () => dispatch(authActions.clearGuestDetails()),
  clearGuestToken: () => dispatch(authActions.clearGuestToken()),
  convertGuestAccount: (userData) => dispatch(authActions.convertGuestAccount(userData)),
  navigateToApproval: (fromWhere) => dispatch(push(routeCreators.approval(), fromWhere)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketContainer);
