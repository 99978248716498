import React from "react";
import {
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../store/ducks/ui-photo-tiles";
import { connect } from "react-redux";
import retroColours from "../../ThemeList/retro-colours.json";

function PhotoTilesBorderColourEditor({ setTiles, tiles, tileBeingEditedIndex }) {
  const tileEdited = tiles[tileBeingEditedIndex];
  const activeColour = tileEdited?.border?.color;

  const handleColorChange = color => {
    onUpdateBorder({ thickness: tileEdited?.border?.thickness, color });
  };

  const onUpdateBorder = border => {
    const editedTile = tiles[tileBeingEditedIndex];
    editedTile.border = border;
    const updatedList = updateTile(tiles, tileBeingEditedIndex, { ...editedTile });
    setTiles(updatedList);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        paddingLeft: 10,
        paddingRight: 10,
        width: "100%",
        overflowX: "scroll",
      }}
    >
      {retroColours.map((colour, index) => {
        return (
          <div
            onClick={() => {
              handleColorChange(colour.hex);
            }}
            className={`swatch-container ${
              activeColour.toLowerCase() === colour.hex.toLowerCase() ? "active" : ""
            }`}
            key={colour.hex}
          >
            <div
              className={`swatch ${colour.color === "White" ? "bordered" : ""}`}
              style={{
                backgroundColor: colour.hex,
                color: colour?.textColor,
                width: "38px",
                height: "38px",
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
});

const mapDispatchToProps = dispatch => ({
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTilesBorderColourEditor);
