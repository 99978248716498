import Button from "../../../../../components/Button/Button";
import React from "react";

export function HeaderNextButton({ onClick, label = "Next", disabled = false }) {
  return (
    <Button
      theme={"muted"}
      priority={"tertiary"}
      label={label}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export function HeaderCancelButton(props) {
  return <Button theme="muted" priority="tertiary" label="Cancel" onClick={props.onClick} />;
}
