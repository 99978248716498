import React from "react";
import PropTypes from "prop-types";

import "./Divider.scss";

const Divider = ({ label }) => {
  return (
    <div className="divider">
      <div className="divider__line" />
      {label && <div className="divider__label">{label}</div>}
    </div>
  );
};

Divider.propTypes = {
  label: PropTypes.string,
};

Divider.defaultProps = {};

export default Divider;
