import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../lib/routes";
import canCheckout from "../../lib/checkout";
import KlaviyoTracking from "../../utils/klaviyo";
import MainContent from "../../components/MainContent/MainContent";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Header from "../../components/Header/Header";
import S3Image from "../../components/S3Image/S3Image";
import Currency from "../../components/Formatters/Currency";
import {
  productsByProductId,
  relatedProductsForItem,
  getProductforProductId,
  hasMinimumQuantityForProduct,
} from "../../data/products";
import { getPricingSchemeForProductAndQuantity } from "../../data/pricing-schemes";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors, actions as basketActions } from "../../store/ducks/basket";
import gtmEvent from "../../utils/gtm";

import "./ApprovalComplete.scss";

import { Divider } from "antd";

import { Link } from "react-router-dom";

const RelatedProduct = ({ product, pricingScheme }) => {
  if (product) {
    const isPack = hasMinimumQuantityForProduct(product.get("id"));

    return (
      <Link
        to={routeCreators.productLandingPage("prints", product.get("url_slug"))}
        key={product.get("url_slug")}
        className="related-product__link"
      >
        <div className="related-product">
          <div className="related-product__img">
            <S3Image storageKey={product.get("preview_s3_key")} className="item-preview" />
          </div>
          <div className="related-product__content">
            <h2 className="related-product__title">{product.get("name")}</h2>
            {isPack ? (
              <p className="related-product__price" style={{ marginBottom: "0px" }}>
                Pack of 20
                <br />
                <Currency amount={pricingScheme.get("cost") * 20} />
              </p>
            ) : (
              <p className="related-product__price">
                From <Currency amount={pricingScheme.get("cost")} />
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  } else {
    return "";
  }
};

class ApprovalCompleteContainer extends Component {
  constructor(props) {
    super(props);

    const itemsAdded = this.props.location?.state;
    this.state = {
      itemsAdded: (itemsAdded && itemsAdded.quantity) || null,
      productsAdded: (itemsAdded && itemsAdded.products) || null,
      productsToShow: [],
      shouldRedirect: !!itemsAdded,
      redirectTo: (itemsAdded && itemsAdded.fromPage) || null,
    };
  }

  componentDidMount() {
    if (this.state.itemsAdded === null) {
      if (this.state.redirectTo) {
        window.location = this.state.redirectTo;
      } else {
        this.props.goToBasket(); // or prints prebag?
      }
    }

    if (this.state.itemsAdded) {
      const productsToShow = relatedProductsForItem(this.state.productsAdded);
      this.setState({
        productsToShow: productsToShow,
      });
    }
    if (this.state.productsAdded) {
      const product = productsByProductId.get(this.state.productsAdded).get("appKey");
      gtmEvent({
        event: "webAppStage",
        additionalProps: { stage: "Approval - Complete", product: product },
      });
    }

    const { history } = this.props;
    //use the state via location.state
    //and replace the state via
    history.replace();
  }

  goToShop = () => {
    this.clearStateThen(() => {
      window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
    });
  };

  onClose = () => {
    this.goToBasket();
  };

  goToBasket = () => {
    this.clearStateThen(() => this.props.goToBasket());
  };

  clearStateThen = callback => {
    this.setState(
      {
        itemsAdded: null,
        productsAdded: null,
        productsToShow: [],
        shouldRedirect: true,
      },
      () => {
        callback();
      }
    );
  };

  trackCheckoutStarted = () => {
    try {
      KlaviyoTracking.trackCheckoutStarted(
        this.props.items,
        this.props.currency,
        this.props.orderSummary
      );
    } catch (err) {
      console.warn("Failed to send Klaviyo Started Checkout", err.message);
    }
  };

  handleCheckoutNow = () => {
    //this.props.setHasCompletedCheckoutSteps(false)
    if (this.props.hasChosenGuestRoute) {
      const ableToCheckout = canCheckout(
        this.props.items,
        this.props.checkoutContactDetails.toJS(),
        this.props.checkoutShippingAddress.toJS(),
        this.props.isSignedIn
      );
      if (ableToCheckout) {
        console.log("Able to checkout");
        this.trackCheckoutStarted(this.props.items, this.props.currency, this.props.orderSummary);
        this.goToBasket();
      } else {
        console.log("Not able to checkout, going to guest or customer");
        this.clearStateThen(() => {
          //this.trackCheckoutStarted()
          this.props.goToCheckoutGuestOrCustomer();
        });
      }
    } else {
      this.clearStateThen(() => {
        console.log("Not chosen guest route, going to guest or customer");
        //this.trackCheckoutStarted()
        this.props.goToCheckoutGuestOrCustomer();
      });
    }
  };

  render() {
    let productName = "item";
    let overrideInfo = false;
    let overrideAddedInfo = "";
    if (this.state.productsAdded) {
      productName = getProductforProductId(this.state.productsAdded)?.toLowerCase();
      console.log("this.state.itemsAdded", this.state.itemsAdded);
      if (!productName) {
        productName = "item";
      }

      if (productName?.includes("print")) {
        productName = "print";
      }
      if (productName?.includes("retros")) {
        productName = "retro";
      }
      if (productName?.includes("passport")) {
        productName = "passport print";
      }

      if (productName?.includes("greeting card pack")) {
        overrideInfo = true;
        overrideAddedInfo = "Cards added to your basket";
      }
    }

    let addedInfo = `${this.state.itemsAdded} × ${
      this.state.itemsAdded === 1
        ? productName?.replace(" × ", "×")
        : `${productName?.replace(" × ", "×")}s`
    } added to your basket`;

    if (overrideInfo) {
      addedInfo = overrideAddedInfo;
    }

    return [
      <Header
        leftAction={
          <Button
            priority="tertiary"
            theme="muted"
            icon="clear"
            iconSize="small"
            onClick={this.onClose}
          />
        }
      />,
      <MainContent scrollable={false} padded key="main-preview">
        <div className="restricted-width-modal-content" style={{ height: "100%" }}>
          <div className="approval-complete">
            {this.state.itemsAdded && (
              <div className="approval-complete__info">
                <Icon name="check-circle" />
                <p>{addedInfo}</p>
              </div>
            )}
            <Divider />
            <div></div>
            <Button
              theme="dark-blue"
              priority="secondary"
              label="Continue Shopping"
              block
              onClick={this.goToShop}
            />
            <Button theme="dark-blue" label="Checkout Now" block onClick={this.handleCheckoutNow} />
          </div>
          <br />
          {this.state.productsToShow.length > 0 && (
            <div style={{ marginTop: "auto" }}>
              <p>Order another print size:</p>
              <div className="related-products-container">
                {this.state.productsToShow &&
                  this.state.productsToShow
                    .filter(product => product)
                    .map((relatedProduct, index) => {
                      const pricingScheme = getPricingSchemeForProductAndQuantity({
                        productId: relatedProduct.get("id"),
                        quantity: 1,
                        currency: this.props.currency,
                      });
                      return (
                        <RelatedProduct
                          key={`product-${index}`}
                          product={relatedProduct}
                          pricingScheme={pricingScheme}
                        />
                      );
                    })}
              </div>
            </div>
          )}
        </div>
      </MainContent>,
    ];
  }
}
const mapStateToProps = state => ({
  hasChosenGuestRoute: Boolean(authSelectors.hasChosenGuestRoute(state)),
  checkoutContactDetails: basketSelectors.getCheckoutContactDetails(state),
  checkoutShippingAddress: basketSelectors.getCheckoutShippingAddress(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  goToBasket: () => dispatch(push(routeCreators.basket())),
  goToCheckoutGuestOrCustomer: () => dispatch(push(routeCreators.checkoutGuestOrCustomer())),
  setHasCompletedCheckoutSteps: completedCheckoutSteps =>
    dispatch(basketActions.setHasCompletedCheckoutSteps(completedCheckoutSteps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalCompleteContainer);
