import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { fromJS, List } from "immutable";
import moment from "moment";
import groupBy from "lodash/groupBy"
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as designsData from "../../data/designs";
import { PRODUCT_TYPE_IDS, productsByProductId, isDefaultFinishMatt } from "../../data/products";
import { actions as basketActions, selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";
import { actions as addressBookActions } from "../../store/ducks/address-book";
import postsnapApi from "../../lib/apis/postsnap";
import { routeCreators } from "../../lib/routes";
import { STEPS as EDITOR_STEPS } from "../Editor/Editor";

import Modal from "../Modal/Modal";
import transformDesignLayers from "../../lib/transform-design-layers";
import squareLayoutTemplates from "../../lib/collage-generator/square-layout-templates";
import landscapeLayoutTemplates from "../../lib/collage-generator/landscape-layout-templates";
import portraitLayoutTemplates from "../../lib/collage-generator/portrait-layout-templates";
import generateLayout from "../../lib/collage-generator/generate-layouts";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import SweetAlert from "../SweetAlert/SweetAlert";

import { Modal as AntModal } from "antd";
import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";

import "./CollagePrintPrebag.scss";
import EditorCollageOption from "../Editor/EditorCollageOption";
import EditorContainer from "../Editor/EditorContainer";
import CollagePrintPreBag from "./CollagePrintPreBag";
import EditorAddressInputModal from "../Editor/EditorAddressInputModal";
//import PhotoPrintsPreBag from "../PhotoPrintsPreBag/PhotoPrintsPreBag";
// import {
//   getNextPricingSchemeForProductAndQuantity,
//   getPricingSchemeForProductAndQuantity,
// } from "../../data/pricing-schemes";

const { confirm } = AntModal;

function getExistingPrebagForProduct(productTypeId, productId) {
  const existingPrebag = localStorage.getItem(STORAGE_KEYS.WIP_PREBAG)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_PREBAG))
    : null;
  if (
    existingPrebag &&
    existingPrebag.productTypeId === productTypeId &&
    existingPrebag.productId === productId
  ) {
    return existingPrebag;
  }
}

// function getImagesMappedToItem(item, images) {
//   return item.update("layers", layers => {
//     return layers.map(layer => {
//       const imageForPage = images.get(layer.get("page"));

//       if (layer.get("type") === LAYER_TYPES.PHOTO) {
//         return layer.setIn(["config", "layout", 0, "image"], imageForPage);
//       }

//       return layer;
//     });
//   });
// }

class CollagePrintPrebagContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      items: new List(),
      indexOfItemToEdit: null,
      selectedFinish: isDefaultFinishMatt(this.props.productId) ? "matt" : "gloss",
      isAddressInputModalVisible: false,
      isLayoutSelectionModalVisible: true,
      isUploadModalVisible: false,
      isCancelConfirmationAlertVisible: false,
      editingFromPreBag: false,
    };

    const product = productsByProductId.get(props.productId);

    let layoutTemplates = squareLayoutTemplates
    if(product.get('width') > product.get('height')){
      layoutTemplates = landscapeLayoutTemplates
    } else if (product.get('width') < product.get('height')){
      layoutTemplates = portraitLayoutTemplates
    }

    // this.layoutTemplates = Object.values(layoutTemplates).map(template => generateLayout({
    //   layout: template,
    //   gutter: 3,
    //   product: product.toJS(),
    // }));
    this.layoutTemplates = Object.entries(layoutTemplates).map(([key, template]) => generateLayout({
      layout: template,
      gutter: 3,
      product: product.toJS(),
      id: key,
    }));

    const existingPrebag = getExistingPrebagForProduct(
      this.props.productTypeId,
      this.props.productId
    );

    if (existingPrebag && existingPrebag.data) { //this.state.items.size > 0
      this.state.items = fromJS(existingPrebag.data.items);
      if (this.state.items.size > 0){
        this.state.isLayoutSelectionModalVisible = false;
      }
    }

  }

  componentDidUpdate() {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items,
        },
      })
    );
    // if (this.state.items.length > 0) {

    // } else {
    //   const existingPrebag = getExistingPrebagForProduct(
    //     this.props.productTypeId,
    //     this.props.productId
    //   );
    //   debugger
    //   if (existingPrebag) {
    //     localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    //   }
    // }
  }


  generateItemWithLayout = async layout => {
    const { productTypeId, productId } = this.props;
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId)
      .sort((a, b) => a.page - b.page)
      .reverse()
      .map(layer => {
        if (
          layer.get("type") === LAYER_TYPES.PHOTO &&
          layer.get("page") === PAGES_BY_PRODUCT_TYPE[PRODUCT_TYPE_IDS.COLLAGE_PRINTS].front
        ) {
          return layer.setIn(["config", "layout"], fromJS(layout)).setIn(["config","regionBorderRadius"], 0);
        }

        return layer;
      });

    let defaultProductOptions = isDefaultFinishMatt(this.props.productId) ? {finish: 'matt', border: true } : {border: true, finish: 'gloss'}

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      supports_collages: true,
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: defaultProductOptions,
      postDate: moment(),
      duplicateAlertShown: false,
      isCollagePrint: true,
    });

    return itemData;
  };

  handleSelectLayout = async layout => {
    const item = await this.generateItemWithLayout(layout);
    this.setState({
      items: this.state.items.push(item),
      isLayoutSelectionModalVisible: false,
      indexOfItemToEdit: this.state.items.size,
    });
  };

  handleEditorUpdate = ({ item, images }) => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items.toJS(),
        },
      })
    );
  };

  handleSaveEditor = item => {
    this.setState({
      items: this.state.items.set(this.state.indexOfItemToEdit, item),
      indexOfItemToEdit: null,
      editingFromPreBag: false,
    });
  };

  handleCloseEditor = () => {
    let updatedItemsList = this.state.items;
    if (!this.state.editingFromPreBag){
      updatedItemsList = this.state.items.delete(this.state.indexOfItemToEdit)
    }


    this.setState({
      indexOfItemToEdit: null,
      items: updatedItemsList,
      editingFromPreBag: false,
      isLayoutSelectionModalVisible: updatedItemsList.size === 0,
    });
  };

  handleIncreaseItemQuantity = itemIndex => {
    this.setState({
      items: this.state.items.updateIn([itemIndex, "quantity"], quantity => quantity + 1),
    });
  };

  handleDecreaseItemQuantity = itemIndex => {
    this.setState({
      items: this.state.items.updateIn([itemIndex, "quantity"], quantity => quantity - 1),
    });
  };

  handleDeleteItem = itemIndex => {
    this.setState({
      items: this.state.items.delete(itemIndex),
    });
  };

  handleSaveAddress = address => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", null);
            item.set("address", fromJS(address));
          });
        }),
      },
      this.finalizePreBag
    );
  };

  handleSelectAddressBookEntry = (addressBookEntryId, entry) => {
    this.setState(
      {
        items: this.state.items.map(item => {
          return item.withMutations(item => {
            item.set("addressBookId", addressBookEntryId);
            item.set("addressBookEntry", fromJS(entry))
            item.set("address", null);
          });
        }),
      },
      this.finalizePreBag
    );
  };

  approveCurrentPrints = () => {

    if (this.props.existingPhotoPrintItems.size > 0) {
      const existingPrint = this.props.existingPhotoPrintItems.find(item => item.getIn(["postageScheme", "cost"]));
      if (existingPrint){
        const existingPostageScheme = existingPrint.get('postageScheme')
        const existingAddress = existingPrint.get('address') // address book entry too...
        this.setState({
          items: this.state.items.map((item) => {
            return item.withMutations(item => {
              item.set("postageScheme", fromJS(existingPostageScheme)).set('setPostageScheme', true);
              item.set("address", fromJS(existingAddress))
            });
          }),
        }, () => {
          this.finalizePreBag()
        });
      } else {
        this.finalizePreBag()
      }

    } else {
      this.finalizePreBag()
    }


  }

  finalizePreBag = () => {
    localStorage.setItem(
      STORAGE_KEYS.WIP_PREBAG,
      JSON.stringify({
        productTypeId: this.props.productTypeId,
        productId: this.props.productId,
        productSlug: this.props.productSlug,
        productTypeSlug: this.props.productTypeSlug,
        data: {
          items: this.state.items.toJS(),
        },
      })
    );

    //localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    const itemsWithProductOptions = this.state.items.map(item =>
      item.setIn(["product_options", "finish"], this.state.selectedFinish)
    );
    itemsWithProductOptions.forEach(async item => {
      this.props.addPendingApprovalItem(item.toJS());
    });
    //this.props.addItemToBasket(itemsWithProductOptions.toJS());
    this.props.navigateToApproval();
  };

  showCancelConfirmationAlert = () => {
    if (this.state.items.size > 0) {
      confirm({
        title: null,
        icon: null,
        content: "Are you sure you want to cancel? If you cancel you will lose your progress.",
        okText: "Stay",
        cancelText: "Cancel",
        onOk: () => {
          this.closeCancelConfirmationAlert();
        },
        onCancel: () => {
          this.navigateBack();
        },
      });
      //this.setState({ isCancelConfirmationAlertVisible: true });
    } else {
      this.navigateBack();
    }
  };

  closeCancelConfirmationAlert = () => {
    this.setState({ isCancelConfirmationAlertVisible: false });
  };

  navigateBack = () => {
    localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    window.history.back();
  };

  render() {
    const product = productsByProductId.get(this.props.productId);
    const cancelConfirmationAlert = (
      <SweetAlert
        key="unsaved-alert"
        isOpen={this.state.isCancelConfirmationAlertVisible}
        text={`Are you sure you want to cancel? If you cancel you will lose your progress.`}
        cancelButtonText="Cancel"
        confirmButtonText={`Stay`}
        onConfirm={this.closeCancelConfirmationAlert}
        onCancel={this.navigateBack}
      />
    );

    const addressInputModal = (
      <EditorAddressInputModal
        isDoubleDirect={false}
        isOpen={this.state.isAddressInputModalVisible}
        mode={"new"}
        initialFormData={
          this.state.items.length > 0 && this.state.items[0].get("address")
            ? this.state.items[0].get("address").toJS()
            : null
        }
        onCancel={() => this.setState({ isAddressInputModalVisible: false })}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onSaveNewAddress={this.handleSaveAddress}
        onSaveOwnAddress={this.handleOwnAddressSave}
      />
    );

    //const totalQuantity = this.state.items.reduce((total, item) => total + item.get("quantity"), 0);
    // const pricingScheme = getPricingSchemeForProductAndQuantity({
    //   productId: this.props.productId,
    //   quantity: totalQuantity,
    //   currency: this.props.currency,
    // });

    // const nextPricingScheme = getNextPricingSchemeForProductAndQuantity({
    //   productId: this.props.productId,
    //   quantity: totalQuantity,
    //   currency: this.props.currency,
    // });
    const productName = productsByProductId.get(this.props.productId).get('name');

    const layoutsGroupedByNumPhotos = groupBy(this.layoutTemplates,"length")

    return (
      <Modal className="collage-prints-prebag" isOpen={true} hasHeader={false} contentLabel={productName} animated={false}>
        {addressInputModal}
        {cancelConfirmationAlert}
        <Modal
          hasHeader={this.state.items.size > 0}
          isOpen={this.state.isLayoutSelectionModalVisible}
          onClose={() => this.setState({ isLayoutSelectionModalVisible: false })}
        >
          <React.Fragment>
            {this.state.items.size === 0 && (
              <Header
                title="Collage Layouts"
                leftAction={<Button theme="muted" priority="tertiary" label="Back" onClick={this.navigateBack} />}
              />
            )}

            <MainContent padded>
              <div className="collage-prints-prebag__layouts">
                {Object.entries(layoutsGroupedByNumPhotos).map(([key, value])=>{
                  return (
                    <>
                      <p className="num-photos">{key} Photos</p>
                      {value.map((layout, index) => (
                        <div>
                          {/* <span>ID:{layout[0].id}</span> */}
                          <EditorCollageOption
                            key={index}
                            ratio={product.get("width") / product.get("height")}
                            stretchToFit
                            layout={layout}
                            onClick={() => this.handleSelectLayout(layout)}
                            large
                            productWidth={product.get("width")}
                            productHeight={product.get("height")}
                            gridLayout={true}
                            itemsPerRow={4}
                            padding={14}
                          />
                        </div>
                      ))}
                    </>
                  )
                })}
              </div>
            </MainContent>
          </React.Fragment>
        </Modal>
        {this.state.indexOfItemToEdit !== null && (
          <EditorContainer
            ref={el => (this.editorContainer = el)}
            item={this.state.items.get(this.state.indexOfItemToEdit)}
            lastStep={EDITOR_STEPS.PRODUCT_FRONT}
            skipAddressInput={true}
            onClose={this.handleCloseEditor}
            onSave={this.handleSaveEditor}
            saveButtonLabel="Save"
          />
        )}

        {this.state.indexOfItemToEdit === null && (
          <CollagePrintPreBag
            product={product}
            selectedProductOption={this.state.selectedFinish}
            items={this.state.items}
            onChangeProductOption={option => this.setState({ selectedFinish: option })}
            onClickItem={index => this.setState({ indexOfItemToEdit: index, editingFromPreBag: true })}
            onClickAddPrint={() => this.setState({ isLayoutSelectionModalVisible: true })}
            onIncreaseItemQuantity={itemIndex => this.handleIncreaseItemQuantity(itemIndex)}
            onDecreaseItemQuantity={itemIndex => this.handleDecreaseItemQuantity(itemIndex)}
            onDeleteItem={itemIndex => this.handleDeleteItem(itemIndex)}
            onClickNext={this.approveCurrentPrints}
            onCancel={this.showCancelConfirmationAlert}
          />
        )}

        {/*{this.state.indexOfItemToEdit === null && (*/}
        {/*  <PhotoPrintsPreBag*/}
        {/*    product={product}*/}
        {/*    selectedProductOption={this.state.selectedProductOption}*/}
        {/*    items={this.state.items}*/}
        {/*    currency={this.props.currency}*/}
        {/*    totalQuantity={totalQuantity}*/}
        {/*    costPerPrint={pricingScheme && pricingScheme.get("cost")}*/}
        {/*    nextCostPerPrint={nextPricingScheme && nextPricingScheme.get("cost")}*/}
        {/*    nextPricingSchemeQuantity={nextPricingScheme && nextPricingScheme.get("min_qty")}*/}
        {/*    nextPricingSchemeAvailable={Boolean(nextPricingScheme)}*/}
        {/*    onStartUpload={this.showUploadModal}*/}
        {/*    onChangeProductOption={option => this.setState({ selectedProductOption: option })}*/}
        {/*    onClickItem={index => this.setState({ indexOfItemToEdit: index })}*/}
        {/*    onClickAddPrint={() => this.setState({ isLayoutSelectionModalVisible: true })}*/}
        {/*    onIncreaseItemQuantity={itemIndex => this.handleIncreaseItemQuantity(itemIndex)}*/}
        {/*    onDecreaseItemQuantity={itemIndex => this.handleDecreaseItemQuantity(itemIndex)}*/}
        {/*    onDeleteItem={itemIndex => this.handleDeleteItem(itemIndex)}*/}
        {/*    onClickNext={() => this.setState({ isAddressInputModalVisible: true })}*/}
        {/*    onCancel={this.showCancelConfirmationAlert}*/}
        {/*  />*/}
        {/*)}*/}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  existingPhotoPrintItems: basketSelectors
    .getItems(state)
    .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT),
});

const mapDispatchToProps = dispatch => ({
  addPendingApprovalItem: item => dispatch(basketActions.addPendingApprovalItem(item)),
  navigateToApproval: () => dispatch(push(routeCreators.approval(), {fromPreBag : true})),
  addItemToBasket: item => dispatch(basketActions.addItem(item)),
  navigateToBasket: () => dispatch(push(routeCreators.basket())),
  setGlobalProgressBarPercentage: percentage =>
    dispatch(uiActions.setGlobalProgressBarPercentage(percentage)),
  setUserAddress: address =>
    dispatch(
      addressBookActions.addNewEntry({
        ...address,
        main: true,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(CollagePrintPrebagContainer);

// LandMark: Collage flow happens here
