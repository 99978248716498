import React, { useEffect } from "react";
import { photoTilesSelectors } from "../../../store/ducks/ui-photo-tiles";
import { connect } from "react-redux";
import PhotoTilesPrebagSingle from "./PhotoTilesPrebagSingle";
import PhotoTilesPrebagMulti from "./PhotoTilesPrebagMulti";

function PhotoTilesPrebag({ product }) {
  if (product?.photo_tile_pack) {
    return <PhotoTilesPrebagMulti />;
  }

  return <PhotoTilesPrebagSingle />;
}

const mapStateToProps = state => ({
  product: photoTilesSelectors.getProduct(state),
});

export default connect(mapStateToProps)(PhotoTilesPrebag);
