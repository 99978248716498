import React from "react";
import PropTypes from "prop-types";

import MainContent from "../../components/MainContent/MainContent";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import Block from "../../components/Block/Block";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import CurrencySwitcherContainer from "../../components/CurrencySwitcher/CurrencySwitcherContainer";
import * as STORAGE_KEYS from "../../constants/storage-keys";

import { routeCreators } from "../../lib/routes";
import { NavLink } from "react-router-dom";

const Account = ({
  user,
  onClickEditAccount,
  onClickAddress,
  onClickChangePassword,
  onClickAddressBook,
  onClickSignOut,
}) => {
  const storedAppVersion = localStorage.getItem(STORAGE_KEYS.APP_VERSION);
  return (
    <MainContent scrollable padded={false}>
      <Header
        title="My Account"
        rightAction={
          <Button theme="dark-blue" priority="tertiary" label="Edit" onClick={onClickEditAccount} />
        }
        onClickTitleOrImage={onClickEditAccount}
        leftAction={<CurrencySwitcherContainer />}
      />
      <div className="faint-background-content">
        <div>
          <Block hoverEffect hasRightArrow>
            <NavLink exact to={routeCreators.prepay()}>
              Buy Postcard Credit
            </NavLink>
          </Block>
        </div>
        <div>
          <Block hoverEffect hasRightArrow>
            <NavLink exact to={routeCreators.orderHistory()}>
              Order History
            </NavLink>
          </Block>
        </div>
        <div>
          <Block>
            <strong style={{fontWeight: '500'}}>Name</strong>
            <br />
            {user.get("first_name")} {user.get("last_name")}
          </Block>
          <Block>
            <strong style={{fontWeight: '500'}}>Email</strong>
            <br />
            {user.get("email")}
          </Block>
        </div>
        <div>
          <Block hoverEffect hasRightArrow onClick={onClickAddress}>
            <strong style={{fontWeight: '500'}}>Address</strong>
            <br />
            {user.get("address") ? (
              <p style={{ whiteSpace: "pre" }}>
                {generateMultilineAddress(user.get("address").toJS()).join("\n")}
              </p>
            ) : (
              <p>Add Your Address</p>
            )}
          </Block>
        </div>
        <div>
          <Block hoverEffect hasRightArrow onClick={onClickChangePassword}>
            <p>Change Password</p>
          </Block>
        </div>
        <div>
          <Block hoverEffect hasRightArrow onClick={onClickAddressBook}>
            <p>Address Book</p>
          </Block>
        </div>
        <div>
          <div style={{display: 'flex',flexDirection: 'column', marginBottom: '5px', alignItems: 'center'}}>
            <Button
              label="Sign Out"
              theme="dark-blue"
              onClick={onClickSignOut}
            />
          </div>
          {/* <Block hoverEffect hasRightArrow onClick={onClickSignOut}>
            <p>Sign Out</p>
          </Block> */}
        </div>
        <div>
          <span style={{ textAlign: "right", width: "100%", display: "block", paddingRight: "10px", maxWidth: '350px', margin: '0 auto' }}>
            v{storedAppVersion}
            {/* {` - ${process.env.REACT_APP_SENTRY_RELEASE}`} */}
          </span>
        </div>
      </div>
    </MainContent>
  );
};

Account.propTypes = {
  onClickEditAccount: PropTypes.func,
  onClickAddress: PropTypes.func,
  onClickChangePassword: PropTypes.func,
  onClickAddressBook: PropTypes.func,
  onClickSignOut: PropTypes.func,
};

Account.defaultProps = {};

export default Account;
