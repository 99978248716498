import { fromJS } from "immutable";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";
import shortid from "shortid";

export const MAIN_VIEW_MODES = {
  EDITOR: "editor",
  PRE_BAG: "pre-bag",
};

export const BORDER_THICKNESS = {
  NO_BORDER: "no-border",
  THICK1: "thick1",
  THICK2: "thick2",
  THICK3: "thick3",
  THICK4: "thick4",
};

export const FRAME_COLORS = {
  WHITE: "white",
  BLACK: "black",
};

const types = {
  SET_PRODUCT: "photoTilesEditor/SET_PRODUCT",
  SET_TILES: "photoTilesEditor/SET_TILES",
  SET_TILE_BEING_EDITED_INDEX: "photoTilesEditor/SET_TILE_BEING_EDITED_INDEX",
  SET_MAIN_VIEW_MODE: "photoTilesEditor/SET_MAIN_VIEW_MODE",
  SET_SHOW_FILES_MODAL: "photoTilesEditor/SET_SHOW_FILES_MODAL",
  SET_SHOW_CROP_EDITOR: "photoTilesEditor/SET_SHOW_CROP_EDITOR",
  SET_SHOW_COLOR_EDITOR: "photoTilesEditor/SET_SHOW_COLOR_EDITOR",
  SET_SHOW_BORDER_EDITOR: "photoTilesEditor/SET_SHOW_BORDER_EDITOR",
  UPDATE_TILE: "photoTilesEditor/UPDATE_TILE",
  DELETE_TILE_AT_INDEX: "photoTilesEditor/DELETE_TILE_AT_INDEX",
  ADD_TILE: "photoTilesEditor/ADD_TILE",
  SET_FILES_MODAL_AMOUNT: "photoTilesEditor/SET_FILES_MODAL_AMOUNT",
};

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("photoTilesEditor", {
    product: null,
    tiles: [],
    tileBeingEditedIndex: 0,
    mainViewMode: MAIN_VIEW_MODES.EDITOR,
    showFilesModal: false,
    showCropEditor: false,
    showColorEditor: false,
    showBorderEditor: false,
    filesModalAmount: 1,
  })
);

export function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PRODUCT:
      return state.set("product", payload.product);
    case types.SET_TILES:
      return state.set("tiles", payload.tiles);
    case types.SET_TILE_BEING_EDITED_INDEX:
      return state.set("tileBeingEditedIndex", payload.tileBeingEditedIndex);
    case types.SET_MAIN_VIEW_MODE:
      return state.set("mainViewMode", payload.mainViewMode);
    case types.SET_SHOW_FILES_MODAL:
      return state
        .set("showFilesModal", payload.showFilesModal)
        .set("filesModalAmount", payload.amount);
    case types.SET_SHOW_CROP_EDITOR:
      return state.set("showCropEditor", payload.showCropEditor);
    case types.SET_SHOW_COLOR_EDITOR:
      return state.set("showColorEditor", payload.showColorEditor);
    case types.SET_SHOW_BORDER_EDITOR:
      return state.set("showBorderEditor", payload.showBorderEditor);
    case types.UPDATE_TILE:
      return state.setIn(["tiles", payload.tileIndex], payload.tile);
    case types.DELETE_TILE_AT_INDEX:
      return state.deleteIn(["tiles", payload.tileIndex]);
    case types.ADD_TILE:
      return state.update("tiles", tiles => tiles.push(payload.tile));
    default:
      return state;
  }
}

export const photoTilesActions = {
  setProduct: product => ({ type: types.SET_PRODUCT, payload: { product } }),
  setTiles: tiles => ({ type: types.SET_TILES, payload: { tiles } }),
  setTileBeingEditedIndex: tileBeingEditedIndex => ({
    type: types.SET_TILE_BEING_EDITED_INDEX,
    payload: { tileBeingEditedIndex },
  }),
  setMainViewMode: mainViewMode => ({ type: types.SET_MAIN_VIEW_MODE, payload: { mainViewMode } }),
  setShowFilesModal: (showFilesModal, amount = 1) => ({
    type: types.SET_SHOW_FILES_MODAL,
    payload: { showFilesModal, amount },
  }),
  setShowCropEditor: showCropEditor => ({
    type: types.SET_SHOW_CROP_EDITOR,
    payload: { showCropEditor },
  }),
  setShowColorEditor: showColorEditor => ({
    type: types.SET_SHOW_COLOR_EDITOR,
    payload: { showColorEditor },
  }),
  setShowBorderEditor: showBorderEditor => ({
    type: types.SET_SHOW_BORDER_EDITOR,
    payload: { showBorderEditor },
  }),
};

export const photoTilesSelectors = {
  getProduct: state => state.photoTilesEditor.get("product"),
  getTiles: state => state.photoTilesEditor.get("tiles"),
  getTileBeingEditedIndex: state => state.photoTilesEditor.get("tileBeingEditedIndex"),
  getMainViewMode: state => state.photoTilesEditor.get("mainViewMode"),
  getShowFilesModal: state => state.photoTilesEditor.get("showFilesModal"),
  getShowCropEditor: state => state.photoTilesEditor.get("showCropEditor"),
  getShowColorEditor: state => state.photoTilesEditor.get("showColorEditor"),
  getShowBorderEditor: state => state.photoTilesEditor.get("showBorderEditor"),
  getFilesAmount: state => state.photoTilesEditor.get("filesModalAmount"),
};
export const getRandomId = () => shortid.generate();
export const getEmptyTile = (frameColor = null) => ({
  id: getRandomId(),
  done: false,
  image: null,
  bnw: false,
  border: { thickness: BORDER_THICKNESS.NO_BORDER, color: "#FFFFFF" }, // MUST MATCH retro-colours.json
  crop: null,
  frameColor,
  quantity: 1,
});

export const getEmptyTiles = (noOfItems = 1, frameColor) =>
  Array.from({ length: noOfItems }, () => getEmptyTile(frameColor));

export const updateTile = (tiles, tileIndex, tile) => {
  return tiles.map((t, index) => (index === tileIndex ? tile : t));
};
export const deleteTileAtIndex = (tiles, tileIndex) => {
  return tiles.filter((t, index) => index !== tileIndex);
};
export const addTile = (tiles, tile) => {
  return [...tiles, tile];
};
