import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import {Button as AntButton } from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import Currency from "../Formatters/Currency";

import '@ant-design/compatible/assets/index.css';
import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";
import './PassportPrintPrebagContainer.scss';
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import * as LAYER_TYPES from "../../constants/layer-types";
import { console } from "es6-shim";

const INCHES_TO_MM = 25.4 // 1 inch = 25.4mm
const PREVIEW_CROPPER_NODE_WIDTH = 500;

const PassportPrintPrebag = ({
  items,
  onStartUpload,
  onCancel,
  onClickNext,
  onSaveCrop,
  onChangePreset,
  frameOptions,
  hasProduct,
  showNoAvailableProducts,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  costPerPrint,
  noPhoto,
  availableWidth,
}) => {
  
  //const presetSelectInput = useRef(null);
  //console.log("customWidth", frameOptions.width)
  //console.log("frameOptions", frameOptions)

  const customWidth = frameOptions.width
  const customHeight = frameOptions.height
  const units = frameOptions.units
  // eslint-disable-next-line
  const [preset, setPreset] = useState(frameOptions.preset || "uk");
  const [el, setEl] = useState()

  const ref = useCallback((node) => {
    if (node !== null) {
      setEl(node)
    }
  }, [])

  const cropper = useRef()

  const setCropperAspectRatio = (cropper, ratio) => {
    console.log("setAspectRatio:", ratio);
    if (!cropper) {
      return
    }
    const $cropperPreviewNode = document.getElementById("cropper-preview");
    $cropperPreviewNode.style.width = `${PREVIEW_CROPPER_NODE_WIDTH}px`;
    $cropperPreviewNode.style.height = `${PREVIEW_CROPPER_NODE_WIDTH * ratio}px`;
    $cropperPreviewNode.style.position = 'fixed'; // prevents select/inputs from scrolling page on iOS safari
    cropper.setAspectRatio(ratio);
  };

  const item = items[0]
  const indexForPhoto = item && item
    .get("layers")
    .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
  const imageForItem = item ? item.getIn(["layers", indexForPhoto, "config", "layout", 0, "image", "src", "highResUrl"]) : null;

  const saveCrop = useCallback(() => {
    if (!cropper.current) {
      return
    }
    const $previewNode = document.getElementById("cropper-preview");
    const $previewImg = $previewNode.firstChild;
    const imgStyles = window.getComputedStyle($previewImg);
    let transformMatrix = null;

    if (imgStyles.transform !== "none") {
      transformMatrix = /matrix\((.*)\)/g
        .exec(imgStyles.transform)[1]
        .split(", ")
        .map(v => Number(v));
    }
    let transform = {
      containerWidth: $previewNode.offsetWidth,
      containerHeight: $previewNode.offsetHeight,
      width: parseFloat(imgStyles.width),
      height: parseFloat(imgStyles.height),
      translateX: transformMatrix ? transformMatrix[4] : 0,
      translateY: transformMatrix ? transformMatrix[5] : 0,
    };
    onSaveCrop({
      ...cropper.current.getData(true),
      transform,
    })
  }, [cropper, onSaveCrop])

  const handleChangePreset = (preset) => {
    onChangePreset(preset)
  }

  const renderFaceGuides = () => {
    const cropViews = document.getElementsByClassName('cropper-crop-box');
    const cropView = cropViews[0];
    //cropView.insertAdjacentHTML('beforeend',"<span class='cropper-face-guides'></span>")

    cropView.insertAdjacentHTML('beforeend',`<span class='cropper-face-guide' style='background-image: url("${process.env.PUBLIC_URL}/images/passport-guides.png")'></span>`)
    console.log("Render face guides")
  }

  useEffect(() => {
    if (el && imageForItem) {
      const $cropperPreviewNode = document.getElementById("cropper-preview");
      cropper.current = new Cropper(document.getElementById("image"), {
        aspectRatio: 1,
        zoomable: false,
        scalable: false,
        strict: true,
        guides: false,
        autoCropArea: 1,
        responsive: true,
        viewMode: 1,
        preview: $cropperPreviewNode,
        crop: () => {
          saveCrop()
        },
        ready: () => {
          renderFaceGuides()
        },
      });
      // console.log("Set Aspect ratio...", customWidthAsMM())
      // console.log("Set Aspect ratio...", customHeightAsMM())
      setCropperAspectRatio(cropper.current, frameOptions.width/frameOptions.height);
      return () => cropper.current.destroy()
    }
    // eslint-disable-next-line
  }, [imageForItem, el, preset, saveCrop]);

  useEffect(() => {
    if (cropper.current){
      console.log("setting cropper aspect ratio")
      console.log("Set Aspect ratio", customWidthAsMM())
      console.log("Set Aspect ratio", customHeightAsMM())
      setCropperAspectRatio(cropper.current, frameOptions.width/frameOptions.height);
    }
    handleChangePreset(preset)
    // setProductBasedOnDimenions(customWidth, customHeight)
  // eslint-disable-next-line
  }, [preset]);

  const noItems = items.length === 0;
  const itemWithPhoto = item && !noPhoto
  const hasSetFrameSize = customWidth !== 0 && customHeight !== 0 && units !== ""
  const rendererWidth = availableWidth > 750 ? 600 : availableWidth*0.8
  

  const customWidthAsMM = () => {
    const customWidth = frameOptions.width
    switch (frameOptions.units) {
      case "inches":
        return (customWidth * INCHES_TO_MM)
      case "cm":
        return (customWidth * 10)
      case "mm":
        return customWidth
      default:
        return customWidth
    }
  }

  const customHeightAsMM = () => {
    const customHeight = frameOptions.height

    switch (frameOptions.units) {
      case "inches":
        return (customHeight * INCHES_TO_MM)
      case "cm":
        return (customHeight * 10)
      case "mm":
        return customHeight
      default:
        return customHeight
    }
  }

  const fitCanvasToContainer = () => {
    if (cropper.current) {
      let containerData = cropper.current.getContainerData();
      let canvasData = cropper.current.getCanvasData();
      let scaleWidth = canvasData.naturalWidth / containerData.width;
      let scaleHeight = canvasData.naturalHeight / containerData.height;
      let scale = Math.max(scaleWidth, scaleHeight);
      let newWidth = canvasData.naturalWidth / scale;
      let newHeight = canvasData.naturalHeight / scale;
      let newLeft = (containerData.width - newWidth) / 2;
      let newTop = (containerData.height - newHeight) / 2;

      let newCanvasData = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop
      };
      cropper.current.options.viewMode = 0
      cropper.current.setCropBoxData({width: 4, height: 3, left: containerData.width / 2, top: containerData.height / 2 });
      cropper.current.setCanvasData(newCanvasData);
      cropper.current.options.viewMode = 1 //2 
    }
  }

  const fitCropBoxToCanvas = () => {
    if (cropper.current) {
      cropper.current.crop();
      let cropBoxData = cropper.current.getCropBoxData();
      let canvasData = cropper.current.getCanvasData();

      let scaleWidth = cropBoxData.width / canvasData.width;
      let scaleHeight = cropBoxData.height / canvasData.height;
      let scale = Math.max(scaleWidth, scaleHeight) / cropper.current.options.autoCropArea;
      let newWidth = cropBoxData.width / scale;
      let newHeight = cropBoxData.height / scale;
      let newLeft = (canvasData.width - newWidth) / 2 + canvasData.left;
      let newTop = (canvasData.height - newHeight) / 2 + canvasData.top;

      let newCropBox = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop
      };
      cropper.current.setCropBoxData(newCropBox);
    }
  }
   

  const rotateOnlyImage = (angle) => {
    if (cropper.current){
      cropper.current.rotate(angle);
      cropper.current.render();
      fitCanvasToContainer()
      fitCropBoxToCanvas()
    }
  }

  const totalQuantity = item && item.get('quantity')
  const disableApprove = noItems || !(itemWithPhoto && hasSetFrameSize && hasProduct)
  
  const pageTitle = (
    <div style={{
      lineHeight: 'normal',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '15px',
      marginTop: '20px',
    }}
    >
      <span>Passport Prints</span>
      <span style={{fontSize: '11px'}}>(35mm x 45mm)</span>
    </div>
  );

  return (
    <React.Fragment>
      <Header
        title={pageTitle}
        leftAction={<Button theme="muted" priority="tertiary" label="Back" onClick={onCancel} />}
        theme="grey"
        rightAction={
          <Button
            theme="dark-blue"
            priority="tertiary"
            label="Next"
            disabled={disableApprove}
            onClick={() => onClickNext()}
          />
        }
      />
      <MainContent padded scrollable className="any-size-prints-prebag">
        <div className="any-size-prints-container">
          <div className="print-options">
            {/* <div className="print-options__heading">
              <div className="print-options__left">
                <span className="instructions">Size:</span>
              </div>
            </div> */}
            
            {/* <div className="frame-options">
              <select
                className="passport-size-select"
                onChange={(e) => setPreset(e.target.value)}
                value={preset}
                ref={presetSelectInput}
              >
                <option value="uk">UK/Ireland (35mm x 45mm)</option>
                <option value="usa">USA (2" x 2")</option>
              </select>
            </div> */}

            <div className="other-options">
              <div className="other-options__finish-options">
                <p className="option-title">Quantity:</p>
                <div className="editor__quantity-picker" style={{display: 'flex', alignItems: 'center'}}>
                  <div className="editor__quantity-picker-controls">
                    <AntButton.Group>
                      <AntButton
                        size="default"
                        type={"primary"}
                        ghost
                        active={"true"}
                        onClick={onDecreaseItemQuantity}
                        style={{borderColor: '#4b5566', color: '#4b5566'}}
                      >
                        <MinusOutlined />
                      </AntButton>
                      <AntButton
                        size="default"
                        onClick={onIncreaseItemQuantity}
                        type={"primary"}
                        ghost
                        style={{borderColor: '#4b5566', color: '#4b5566'}}
                      >
                        <PlusOutlined />
                      </AntButton>
                    </AntButton.Group>
                  </div>
                  {costPerPrint && (
                    <div className="editor__quantity-picker__info" style={{ marginLeft: '10px'}}>
                      <p style={{fontSize: '12px', color: 'black'}}>{`${totalQuantity} ${totalQuantity === 1 ? 'sheet of 8' : 'sheets of 8'} @`} <Currency amount={costPerPrint} /> {`${totalQuantity > 1 ? 'each' : ''}`}</p>
                    </div>
                  )}
                  
                </div>
              </div>
            </div>

            {showNoAvailableProducts && (
              <div className="no-size-available">
                <p>We can't print this size on photo paper - our largest photo paper size is 12x8″, reduce your photo size or change the units to fit.</p>
              </div>
            )}
          </div>
          <hr/>
          <div className="print-preview">
            {itemWithPhoto && (
              <div>
                <div className="photo-edit-options">
                  <div className="photo-edit-options__left">
                    <span className="instructions">Edit Photo:</span>
                  </div>
                  <div className="photo-edit-options__right">
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Change Photo"
                      onClick={() => onStartUpload(true)}
                    />
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Rotate Left"
                      onClick={() => rotateOnlyImage(-90)}
                    />
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Rotate Right"
                      onClick={() => rotateOnlyImage(90)}
                    />
                  </div>                
                </div>
                <div className="wraper" style={{width: '80vw', minHeight: '300px', maxWidth: `${rendererWidth}px`, paddingBottom: '20px', margin: '0 auto'}}>
                  <div className="croper-img">
                    <img className="croper" alt="custom" id="image" ref={ref} src={imageForItem} />
                  </div>
                  <p className="crop-intructions">Adjust the red box above to place your head within the guide.<br/> Drag the
                    &nbsp;<img className="crop-icon" src={`${process.env.PUBLIC_URL}/images/text-resize.png`} alt="trim print"/>&nbsp;
                    icon to adjust the print area.</p>
                </div>
              </div>
            )}
            {(noItems || (item && noPhoto)) && (
              <div className="cta-btn-container">
                <Button
                  block
                  theme="dark-blue"
                  label="Upload Passport Photo"
                  onClick={() => onStartUpload(false)}
                  dataGtmElement="Photo Upload Source"
                  disabled={!hasSetFrameSize || !hasProduct}
                />
              </div>
            )}

            {(itemWithPhoto && hasSetFrameSize && hasProduct) && (
              <div style={{marginTop: '10px'}}>
                <p className="instructions">Preview:</p>
                <div className={`custom-print-wrapper`}>
                  <React.Fragment>
                    <div className="custom-print_wrapper__inner">
                      <HtmlRenderer
                        key={`render${item.get('id')}`}
                        width={rendererWidth}
                        previewMode={true}
                        item={item.toJS()}
                        page={item.getIn(["pages", "front"])}
                        onClick={() => {}}
                        specificDimensions={{
                          width: customWidthAsMM(),
                          height: customHeightAsMM()
                        }}
                      />
                    </div>
                  </React.Fragment>
                </div>
              </div>
            )}
          </div>
          {(itemWithPhoto && hasSetFrameSize && hasProduct) && (
            <div className="cta-btn-container">
              <Button
                block
                theme="dark-blue"
                label="Next"
                disabled={disableApprove}
                onClick={() => onClickNext()}
                dataGtmElement="Approve"
              />
            </div>
            
          )}
        </div>
      </MainContent>

    </React.Fragment>
  );
};

PassportPrintPrebag.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default PassportPrintPrebag;
