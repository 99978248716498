import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import gtmEvent from "../../utils/gtm";
//import * as smartyStreetsApi from '../../lib/apis/smartystreets';
import { countriesByCurrency } from "../../constants/geo";
//import { provincesByCountry, countriesByCurrency, militaryStates } from '../../constants/geo';
import {
  actions as addressBookActions,
  selectors as addressBookSelectors } from "../../store/ducks/address-book";
import { selectors as authSelectors } from "../../store/ducks/auth";
import { selectors as basketSelectors } from "../../store/ducks/basket";
import { actions as uiActions } from "../../store/ducks/ui";


import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import AddressForm from "../Form/AddressForm";
import ModalPage from "../Modal/ModalPage";
import AddressBook from "../AddressBook/AddressBook";
//import SplitButton from "../Button/SplitButton";
import UKPostcodeLookUpAddressForm from "../Form/UKPostcodeLookUpAddressForm";
import SweetAlert from "../SweetAlert/SweetAlert";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

//import { Button as AntButton } from 'antd';

const DEFAULT_COUNTRY = "United Kingdom";

const MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  ADDRESS_FORM: "ADDRESS_FORM",
  ADDRESS_BOOK: "ADDRESS_BOOK",
  ADD_OWN_ADDRESS: "ADD_OWN_ADDRESS",
  ADD_TO_ADDRESS_BOOK: "ADD_TO_ADDRESS_BOOK",
};

const ACTIONS_AFTER_SIGNING_IN = {
  SEND_BACK_TO_ME: "SEND_BACK_TO_ME",
  OPEN_ADDRESS_BOOK: "OPEN_ADDRESS_BOOK",
};

class EditorAddressInputModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    onSaveNewAddress: PropTypes.func.isRequired,
    onSelectAddressBookEntry: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showAuthModal: PropTypes.func.isRequired,
    initialFormData: PropTypes.object,
    isDoubleDirect: PropTypes.bool.isRequired,
    isPackMode: PropTypes.bool,
    titleLabel: PropTypes.string,
    onlyCountries: PropTypes.array,
    onlyNewAddress: PropTypes.bool,
    straightToEdit: PropTypes.bool
  };

  static defaultProps = {
    onlyCountries: [],
    onlyNewAddress: false,
    toShippingSummaryAfterSelection: false,
    straightToEdit: false,
  };

  constructor(props) {
    super(props);

    let defaultCountry = countriesByCurrency[props.currency] || DEFAULT_COUNTRY;

    let isAllowedOwnCountry = true;
    if(this.props.onlyCountries && this.props.onlyCountries.length > 0){
      if(!this.props.onlyCountries.includes(defaultCountry)){
        isAllowedOwnCountry = false;
      }
    }

    this.defaultFormData = {
      country: isAllowedOwnCountry ? defaultCountry : DEFAULT_COUNTRY,
    };
    this.state = {
      newAddressFormData: props.initialFormData || this.defaultFormData,
      ownAddressFormData: this.defaultFormData,
      activePage: props.onlyNewAddress ? MODAL_PAGES.ADDRESS_FORM : MODAL_PAGES.OVERVIEW,
      loadingSendBackToMe: false,
      sendBackToMeIsDoubleDirect: false,
      editingAddress: false,
      addressBookEntryDetailData: null,
      addressBookNewEntryData: {
        country: "United Kingdom",
      },
      onlyNewAddress: props.onlyNewAddress,
      loadingAddresses: false
    };
  }

  // componentWillUpdate() {
  //   if(this.props.onlyNewAddress){
  //     this.setState({
  //       onlyNewAddress: true,
  //       activePage: MODAL_PAGES.ADDRESS_FORM
  //     });
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    // Reset the form when the modal appears, with either the initial form data provided in props, or the default form
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        newAddressFormData:
          this.props.mode === "edit" ? this.props.initialFormData : this.defaultFormData,
        activePage: this.props.mode === "edit" ? MODAL_PAGES.ADDRESS_FORM : MODAL_PAGES.OVERVIEW,
      });
      
    }

    if (!prevProps.onlyNewAddress && this.props.onlyNewAddress) {
      this.setState({
        onlyNewAddress: true,
        activePage: MODAL_PAGES.ADDRESS_FORM
      });
    }

    // if (this.props.straightToEdit && !prevProps.straightToEdit){
    //   this.setState({
    //     editingAddress: true,
    //   });
    // }

    if (!prevProps.isSignedIn && this.props.isSignedIn && this.actionAfterSigningIn) {
      switch (this.actionAfterSigningIn) {
        case ACTIONS_AFTER_SIGNING_IN.SEND_BACK_TO_ME:
          if (this.props.user.get("address")) {
            this.handleSelectAddressBookEntry(this.props.user.get("address"));
          } else {
            this.setState({
              activePage: MODAL_PAGES.ADD_OWN_ADDRESS,
            });
          }
          break;
        // no default
        case ACTIONS_AFTER_SIGNING_IN.OPEN_ADDRESS_BOOK:
          this.setState({
            loadingAddresses: true
          }, () => {
            setTimeout(() => {
              this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
            }, 500);
          })
          
          break;
        // no default
      }

      this.actionAfterSigningIn = null;

    }

    if (this.props.isSignedIn && this.state.loadingAddresses){    
      //If we've loaded the address book entries, change loading state
      console.log("this.props.addressBookEntries.size", this.props.addressBookEntries.size)
      console.log("prevProps.addressBookEntries.size", prevProps.addressBookEntries.size)
      console.log("Loading addresses ", this.state.loadingAddresses)
      if (prevProps.addressBookEntries.size === 0 && this.props.addressBookEntries.size !== 0){
        this.setState({
          loadingAddresses: false,
          hasAddresses: true
        })
      }
      if (prevProps.addressBookEntries.size === 0 && this.props.addressBookEntries.size === 0){
        this.setState({
          loadingAddresses: false,
          hasAddresses: false
        })
      }
    }

  }

  handleNewAddressFormChange = form => {
    console.log("Form changed", form.formData)
    this.setState({
      newAddressFormData: {
        ...this.state.newAddressFormData,
        ...form.formData,
      },
    });
  };

  handleNewAddressFormSubmit = async () => {
    console.log("New address for submit")
    const address = this.state.newAddressFormData;
    const existingAddressBookEntryForAddress = this.props.addressBookEntries.find(entry => {
      const PROPERTIES_TO_MATCH = [
        "county",
        "city",
        "postcode",
        "country",
        "first_name",
        "company_name",
        "line1",
        "line2",
        "line3",
      ];

      return PROPERTIES_TO_MATCH.every(property => entry.get(property) === address[property]);
    });

    if (existingAddressBookEntryForAddress) {
      console.log("Existing abe")
      return this.handleSelectAddressBookEntry(existingAddressBookEntryForAddress);
    }

    if (address.country === "United States") {
      //const matchingState = militaryStates.concat(provincesByCountry['US']).find(p => p.name === address.county);

      //const stateCode = matchingState && matchingState.short;

      // Skip buggy address verification for now
      this.props.onSaveNewAddress(address);
      this.setActivePage(MODAL_PAGES.OVERVIEW);
      // try {
      //   await smartyStreetsApi.verifyAddress({ street: address.line1, city: address.city, state: stateCode });
      //   this.props.onSaveNewAddress(address);
      //   this.setActivePage(MODAL_PAGES.OVERVIEW);
      // } catch (err) {
      //   this.setState({
      //     alert: {
      //       title: 'Verification Unsuccessful',
      //       text: "We can't find that address in the local postal service's database. Would you like to use this address anyway?",
      //       confirmButtonText: 'Yes',
      //       onConfirm: () => {
      //         this.props.onSaveNewAddress(address);
      //         this.setActivePage(MODAL_PAGES.OVERVIEW);
      //         this.setState({ alert: null });
      //       },
      //       cancelButtonText: 'No',
      //       onCancel: () => this.setState({ alert: null })
      //     }
      //   });
      // }
    } else {
      console.log("Saving new address");
      this.props.onSaveNewAddress(address);
      //console.log("Saved new address");
      this.setActivePage(MODAL_PAGES.OVERVIEW);
    }
  };

  handleOwnAddressFormChange = form => {
    this.setState({
      ownAddressFormData: {
        ...this.state.ownAddressFormData,
        ...form.formData,
      },
    });
  };

  handleOwnAddressFormSubmit = async () => {
    const address = this.state.ownAddressFormData;
    this.props.onSaveOwnAddress(address).then(this.handleClickSendBackToMe);
  };

  setActivePage = pageId => {
    this.setState({ activePage: pageId }, () => {
      gtmEvent({event: "webAppStage", additionalProps: {stage: `Address - ${pageId}`}})
    });
  };

  handleSelectAddressBookEntry = (entry, isDoubleDirect) => {

    if (this.props.onlyCountries && this.props.onlyCountries.length > 0){
      if (!this.props.onlyCountries.includes(entry.get('country'))){
        console.log("Address country not allowed", entry.get('country'));

        this.setState({
          alert: {
            title: 'Sorry',
            text: `We're currently unable to ship this product to ${entry.get('country')}`,
            confirmButtonText: 'OK',
            onConfirm: () => {
              this.setState({ alert: null });
            },
          },
          loadingSendBackToMe: false,
        });

        //return true;
      } else{
        this.props.onSelectAddressBookEntry(entry, isDoubleDirect);
        this.setActivePage(MODAL_PAGES.OVERVIEW);
        this.setState({ loadingSendBackToMe: false });
      }
    } else{
      this.props.onSelectAddressBookEntry(entry, isDoubleDirect);
      this.setActivePage(MODAL_PAGES.OVERVIEW);
      this.setState({ loadingSendBackToMe: false });
    }
  };

  handleEditingAddress = (isEditing) => {
    this.setState({ editingAddress: isEditing });
  }

  handleClickSendBackToMe = ownAddressResponse => {
    if (this.props.isSignedIn) {
      if (this.props.user.get("address") || ownAddressResponse) {
        this.setState({ loadingSendBackToMe: true }, () => {
          this.handleSelectAddressBookEntry(
            this.props.user.get("address") || fromJS(ownAddressResponse.payload.data.data),
            this.props.isDoubleDirect
          );
        });
      } else {
        this.setState({
          activePage: MODAL_PAGES.ADD_OWN_ADDRESS,
        });
      }
    } else {
      this.props.showAuthModal({withAddress: true});
      this.actionAfterSigningIn = ACTIONS_AFTER_SIGNING_IN.SEND_BACK_TO_ME;
    }
  };

  handleClickAddressBook = () => {
    if (this.props.isSignedIn) {
      this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
    } else {
      this.props.showAuthModal({withAddress: false});
      this.actionAfterSigningIn = ACTIONS_AFTER_SIGNING_IN.OPEN_ADDRESS_BOOK;
    }
  };

  handleClickOwnCountryTab = () => {
    this.setState({
      newAddressFormData: {
        ...this.state.newAddressFormData,
        country: this.defaultFormData.country,
      },
    });
  };

  handleClickWorldTab = () => {
    switch (this.state.newAddressFormData.country) {
      case "United States":
        this.setState({
          newAddressFormData: {
            ...this.state.newAddressFormData,
            country: "United Kingdom",
          },
        });
        break;
      default:
        this.setState({
          newAddressFormData: {
            ...this.state.newAddressFormData,
            country: "United States",
          },
        });
        break;
    }
  };

  handleNewAddressBookEntryFormChange = form => {
    this.setState({
      addressBookNewEntryData: {
        ...this.state.addressBookNewEntryData,
        ...form.formData,
      },
    });
  };

  handleNewAddressBookEntryFormSubmit = async form => {
    console.log("handleNewAddressBookEntryFormSubmit")
    const address = this.state.addressBookNewEntryData;
    const result = await this.props.addAddressBookEntry(address);
    if (!result.payload.data.success) {
      this.setState({
        alert: {
          type: "error",
          text:
            "There was a problem adding your address",
          confirmButtonText: "OK",
          onConfirm: () => this.setState({ alert: null }),
          showCancelButton: false,
        },
      }, () => {
        gtmEvent({event: "webAppStage", additionalProps: {stage: `Address Error`}})
      });
    } else {


      //this.setActivePage(MODAL_PAGES.ADDRESS_BOOK);
      this.setState({
        addressBookNewEntryData: {
          country: "United Kingdom",
        },
      }, () => {
        this.handleSelectAddressBookEntry(fromJS(result.payload.data.data))
      });
    }
  };

  handleCancelNewAddress = () => {
    if (this.state.onlyNewAddress){
      this.props.onCancel()
    } else {
      this.setActivePage(MODAL_PAGES.OVERVIEW)
    }
    
  }

  handleNewAddressReset = form => {
    console.log("this.defaultFormData", this.defaultFormData)
    this.setState({
      newAddressFormData: {
        ...this.defaultFormData
      },
      isEditing: false
    }, () => {
      console.log("this.state.newAddressFormData", this.state.newAddressFormData)
      //this.props.onUpdateCheckoutShippingAddress(this.state.checkoutShippingAddressFormData)
    });
  };

  render() {

    if (this.state.loadingAddresses) {
      return (
        <FullScreenLoader
          key="loader"
          message="Loading addresses..."
          isVisible={this.state.loadingAddresses}
        />
      );
    }

    let headerProps = {};

    switch (this.state.activePage) {
      case MODAL_PAGES.OVERVIEW:
        headerProps = {
          title: this.props.titleLabel || "Shipping Address",
        };
        break;
      case MODAL_PAGES.ADDRESS_BOOK:
        headerProps = {
          title: "Choose Address",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            this.state.activePage === MODAL_PAGES.ADDRESS_BOOK && (
              this.props.addressBookEntries.size > 0 && (
                <Button
                  label={"Add New"}
                  priority="tertiary"
                  theme="dark-blue"
                  onClick={() => this.setActivePage(MODAL_PAGES.ADD_TO_ADDRESS_BOOK)}
                />
              )
              
            )
          ),
        };
        break;
      case MODAL_PAGES.ADDRESS_FORM:
        headerProps = {
          title: this.props.onlyNewAddress ? "Shipping Address" : "New Address",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={this.handleCancelNewAddress}
            />
          ),
          // rightAction: (
          //   <Button
          //     label={this.state.editingAddress ? "Done" : "Apply"}
          //     priority="tertiary"
          //     theme="default"
          //     onClick={() => this.state.editingAddress ? this.newAddressForm.doneEditingAddress() : this.newAddressForm && this.newAddressForm.submitForm()}
          //     disabled={
          //       this.state.newAddressFormData.country === "United Kingdom" &&
          //       !this.state.newAddressFormData.line1
          //     }
          //   />
          // ),
        };
        break;
      case MODAL_PAGES.ADD_OWN_ADDRESS:
        headerProps = {
          title: "Add Your Address",
          leftAction: (
            <Button
              label="Cancel"
              priority="tertiary"
              theme="muted"
              onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
            />
          ),
          rightAction: (
            <Button
              label={this.state.ownAddressFormData.country === "United States" ? "Save" : "Save"}
              priority="tertiary"
              theme="default"
              onClick={() => this.ownAddressForm && this.ownAddressForm.submitForm()}
              disabled={
                this.state.ownAddressFormData.country === "United Kingdom" &&
                !this.state.ownAddressFormData.line1
              }
            />
          ),
        };
        break;
        case MODAL_PAGES.ADD_TO_ADDRESS_BOOK:
          headerProps = {
            title: "Add Address",
            leftAction: (
              <Button
                label="Cancel"
                priority="tertiary"
                theme="muted"
                onClick={() => this.setActivePage(MODAL_PAGES.OVERVIEW)}
              />
            ),
            rightAction: (
              <Button
                priority="tertiary"
                theme="muted"
                label="Save"
                disabled={
                  this.state.addressBookEntryDetailData &&
                  !this.state.addressBookEntryDetailData.system
                }
                onClick={this.addressBookNewEntryForm && this.addressBookNewEntryForm.submitForm}
              />
            ),
          };
        break;
      // no default
    }

    let showCountryToggle = true;
    if(this.defaultFormData.country && (this.props.onlyCountries && this.props.onlyCountries.length > 0)){
      if(!this.props.onlyCountries.includes(countriesByCurrency[this.props.currency])){
        showCountryToggle = false;
      }
    }

    const isOnOwnCountry =
      this.state.newAddressFormData.country === countriesByCurrency[this.props.currency];
    let ownCountryLabel = this.defaultFormData.country;


    const shouldShowUKPostcodeLookupForm =
      isOnOwnCountry && this.state.newAddressFormData.country === "United Kingdom";

    switch (this.defaultFormData.country) {
      case "United Kingdom":
        ownCountryLabel = "UK";
        break;
      case "United States":
        ownCountryLabel = "USA";
        break;
      // no default
    }

    let addressBookEntries = this.props.addressBookEntries;
    if (this.props.onlyCountries && this.props.onlyCountries.length > 0){
      addressBookEntries = addressBookEntries.filter(entry => this.props.onlyCountries.includes(entry.get('country')));
    }

    const onlyNewAddress = this.props.onlyNewAddress === true;
    const showNewAddressOnly = this.props.isOpen && onlyNewAddress;
    const showAllAddressOptions = this.props.isOpen && !onlyNewAddress;

    const newAddressFormContent = (
      <div className="restricted-width-modal-content">
        
        <div className="checkout__shipping-container">
          {showCountryToggle && (
            <div className="checkout__shipping-country-select-container">
              <div className="checkout__shipping-country-select-label">
                Select country:
              </div>
              <div className="checkout__shipping-country-select">
                <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                  <span style={{color: isOnOwnCountry ? 'white' : 'black'}}>{ownCountryLabel}</span>
                </div>
                <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                  <span style={{color: isOnOwnCountry ? 'black' : 'white'}}>World</span>
                </div>
              </div>
            </div>
          )}
          {/* {showCountryToggle && (
            <div className="text-center">
            <SplitButton>
              <Button
                priority={isOnOwnCountry ? "primary" : "secondary"}
                label={ownCountryLabel}
                theme="dark-blue"
                onClick={this.handleClickOwnCountryTab}
              />
              <Button
                priority={!isOnOwnCountry ? "primary" : "secondary"}
                label="World"
                theme="dark-blue"
                onClick={this.handleClickWorldTab}
              />
            </SplitButton>
          </div>
          )} */}
          {(this.state.activePage === MODAL_PAGES.ADDRESS_FORM || showNewAddressOnly) && (
            shouldShowUKPostcodeLookupForm ? (
              <UKPostcodeLookUpAddressForm
                key="new-uk-address"
                ref={el => (this.newAddressForm = el)}
                formData={this.state.newAddressFormData}
                onChange={this.handleNewAddressFormChange}
                onSubmit={this.handleNewAddressFormSubmit}
                onIsEditing={this.handleEditingAddress}
                straightToEdit={this.props.straightToEdit || this.props.mode === "edit"}
                submitOnSelection={true} //this.props.toShippingSummaryAfterSelection}
                onReset={this.handleNewAddressReset}
              />
            ) : (
              <AddressForm
                key="other-country-address-form"
                ref={el => (this.newAddressForm = el)}
                formData={this.state.newAddressFormData}
                onChange={this.handleNewAddressFormChange}
                onSubmit={this.handleNewAddressFormSubmit}
                showCountry={true}
                onlyCountries={this.props.onlyCountries}
              />
            )
          )}
        </div>
      </div>
    )
    
    
    return [
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="address-alert"
        data-gtm-element="Address Error"
      />,
      <Modal
        key="address-input-modal-only-new"
        isOpen={showNewAddressOnly}
        onClose={this.props.onCancel}
        padded
        {...headerProps}
      >
        {newAddressFormContent}
      </Modal>,
      <Modal
        key="address-input-modal"
        isOpen={showAllAddressOptions}
        onClose={this.props.onCancel}
        padded
        paged
        activePage={this.state.activePage}
        {...headerProps}
      >
        <ModalPage pageId={MODAL_PAGES.OVERVIEW} depth={1}>
          <div className="restricted-width-modal-content">
            <div className="address-selection-container">
              {(!this.props.isSignedIn && !onlyNewAddress) && (
                <div className="address-selection-block">
                {/* <div className="address-selection-block--heading">
                  <h2 className="address-selection-block--heading__title">Guest Checkout</h2>
                  <p className="address-selection-block--heading__info">
                    Apple Pay or Google Pay must be installed on your device in order to use guest checkout.
                  </p>
                </div> */}
                <div className="address-selection-block--btns">
                  <Button
                    block
                    theme="dark-blue"
                    label="New Name & Address"
                    onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
                    dataGtmElement="Address Option Button"
                  />
                </div>
              </div>
              )}
              <div className="address-selection-block">
                {/* {(!this.props.isSignedIn && !onlyNewAddress) && (
                  <div className="address-selection-block--heading">
                    <h2 className="address-selection-block--heading__title">Customer Checkout</h2>
                    <p className="address-selection-block--heading__info">
                      Checkout faster and see your order history.
                    </p>
                  </div>
                )} */}
                <div className="address-selection-block--btns">
                  {/* {onlyNewAddress && (
                    <Button
                      block
                      theme="dark-blue"
                      priority="secondary"
                      label="New Name & Address"
                      onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
                      dataGtmElement="Address Option Button"
                    />
                  )}
                  {!onlyNewAddress && (
                    !this.props.isPackMode ? (
                      <Button
                        block
                        theme="dark-blue"
                        label={
                          this.props.isDoubleDirect
                            ? "Send Back To Me With An Extra Envelope"
                            : "Send To Me"
                        }
                        priority="secondary"
                        loadingLabel="Fetching Your Address..."
                        loading={this.state.loadingSendBackToMe}
                        onClick={() => this.handleClickSendBackToMe()}
                        dataGtmElement="Address Option Button"
                      />
                    ) : (
                      <Button
                        block
                        theme="dark-blue"
                        priority="secondary"
                        label="Send back to me with additional envelopes"
                        loadingLabel="Fetching Your Address..."
                        loading={this.state.loadingSendBackToMe}
                        onClick={() => this.handleClickSendBackToMe()}
                        dataGtmElement="Address Option Button"
                      />
                  ))} */}
                  {this.props.isSignedIn && (
                    <Button
                    block
                    theme="dark-blue"
                    label="New Name & Address"
                    onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
                    dataGtmElement="Address Option Button"
                  />
                  )}
                  
                  {!onlyNewAddress && (
                    <>
                      {/* <Button
                        block
                        theme="dark-blue"
                        priority="secondary"
                        label="New Name & Address"
                        onClick={() => this.setActivePage(MODAL_PAGES.ADDRESS_FORM)}
                        dataGtmElement="Address Option Button"
                      /> */}
                      <Button
                        block
                        theme="dark-blue"
                        priority="secondary"
                        label="PostSnap Address Book"
                        onClick={this.handleClickAddressBook}
                        dataGtmElement="Address Option Button"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_BOOK} depth={2}>
          <div style={{ margin: "0 -16px" }}>
            {this.state.activePage === MODAL_PAGES.ADDRESS_BOOK && (
              <AddressBook
                loadingAddresses={this.state.loadingAddresses}
                entries={addressBookEntries}
                onClickEntry={this.handleSelectAddressBookEntry}
                onClickAddAddress={() => this.setActivePage(MODAL_PAGES.ADD_TO_ADDRESS_BOOK)}
                onClickAddMainAddress={() => this.setActivePage(MODAL_PAGES.ADD_OWN_ADDRESS)}
              />
            )}
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADDRESS_FORM} depth={2}>
          {newAddressFormContent}
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADD_OWN_ADDRESS} depth={2}>
          <div className="restricted-width-modal-content">
            <div className="checkout__shipping-container">
              {showCountryToggle && (
                <div className="checkout__shipping-country-select-container">
                  <div className="checkout__shipping-country-select-label">
                    Select country:
                  </div>
                  <div className="checkout__shipping-country-select">
                    <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                      <span style={{color: isOnOwnCountry ? 'white' : 'black'}}>{ownCountryLabel}</span>
                    </div>
                    <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                      <span style={{color: isOnOwnCountry ? 'black' : 'white'}}>World</span>
                    </div>
                  </div>
                </div>
              )} 
              {this.state.activePage === MODAL_PAGES.ADD_OWN_ADDRESS && (
                shouldShowUKPostcodeLookupForm ? (
                  // <AddressForm
                  // key="own-country-address-form"
                  // ref={el => (this.ownAddressForm = el)}
                  // formData={this.state.ownAddressFormData}
                  // onChange={this.handleOwnAddressFormChange}
                  // onSubmit={this.handleOwnAddressFormSubmit}
                  // showCountry={false}
                  // />
                  <UKPostcodeLookUpAddressForm
                    key="own-uk-address"
                    ref={el => (this.ownAddressForm = el)}
                    formData={this.state.ownAddressFormData}
                    onChange={this.handleOwnAddressFormChange}
                    onSubmit={this.handleOwnAddressFormSubmit}
                    onIsEditing={this.handleEditingAddress}
                    straightToEdit={this.props.straightToEdit}
                    submitOnSelection={true}
                  />
                ) : (
                  <AddressForm
                    ref={el => (this.ownAddressForm = el)}
                    formData={this.state.ownAddressFormData}
                    onChange={this.handleOwnAddressFormChange}
                    onSubmit={this.handleOwnAddressFormSubmit}
                    onlyCountries={this.props.onlyCountries}
                  />
                )
              )}
            </div>
          </div>
        </ModalPage>
        <ModalPage pageId={MODAL_PAGES.ADD_TO_ADDRESS_BOOK} depth={2}>
          <div className="restricted-width-modal-content">
            <div className="checkout__shipping-container">
              {showCountryToggle && (
                <div className="checkout__shipping-country-select-container">
                  <div className="checkout__shipping-country-select-label">
                    Select country:
                  </div>
                  <div className="checkout__shipping-country-select">
                    <div className={`checkout__shipping-country-option ${isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickOwnCountryTab}>
                      <span style={{color: isOnOwnCountry ? 'white' : 'black'}}>{ownCountryLabel}</span>
                    </div>
                    <div className={`checkout__shipping-country-option ${!isOnOwnCountry ? 'active' : ''}`} onClick={this.handleClickWorldTab}>
                      <span style={{color: isOnOwnCountry ? 'black' : 'white'}}>World</span>
                    </div>
                  </div>
                </div>
              )} 
              {/* <AddressForm
                ref={el => (this.addressBookNewEntryForm = el)}
                formData={this.state.addressBookNewEntryData}
                onChange={this.handleNewAddressBookEntryFormChange}
                onSubmit={this.handleNewAddressBookEntryFormSubmit}
                onlyCountries={this.props.onlyCountries}
              /> */}
              {this.state.activePage === MODAL_PAGES.ADD_TO_ADDRESS_BOOK && (
                shouldShowUKPostcodeLookupForm ? (
                  // <AddressForm
                  // key="own-country-address-form"
                  // ref={el => (this.ownAddressForm = el)}
                  // formData={this.state.ownAddressFormData}
                  // onChange={this.handleOwnAddressFormChange}
                  // onSubmit={this.handleOwnAddressFormSubmit}
                  // showCountry={false}
                  // />
                  <UKPostcodeLookUpAddressForm
                    key="address-book-entry-uk"
                    ref={el => (this.addressBookNewEntryForm = el)}
                    formData={this.state.addressBookNewEntryData}
                    onChange={this.handleNewAddressBookEntryFormChange}
                    onSubmit={this.handleNewAddressBookEntryFormSubmit}
                    onIsEditing={this.handleEditingAddress}
                    submitOnSelection={true}
                  />
                ) : (
                  <AddressForm
                    ref={el => (this.addressBookNewEntryForm = el)}
                    formData={this.state.addressBookNewEntryData}
                    onChange={this.handleNewAddressBookEntryFormChange}
                    onSubmit={this.handleNewAddressBookEntryFormSubmit}
                    onlyCountries={this.props.onlyCountries}
                  />
                )
              )}
            </div>
          </div>
        </ModalPage>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  addressBookEntries: addressBookSelectors.getAllEntries(state),
  currency: basketSelectors.getCurrency(state),
});

const mapDispatchToProps = dispatch => ({
  showAuthModal: (withAddress) => dispatch(uiActions.showAuthModal(withAddress)),
  addAddressBookEntry: address => dispatch(addressBookActions.addNewEntry(address)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorAddressInputModal);
