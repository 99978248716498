export const BASKET = "postsnap.basket";
export const AUTH_TOKEN = "postsnap.authToken";
export const USER = "postsnap.user";
export const GUEST_USER = "postsnap.guestUser";
export const CHOSEN_GUEST_ROUTE = "postsnap.hasChosenGuestRoute";
export const GUEST_TOKEN = "postsnap.guestToken";
export const APP_VERSION = "postsnap.appVersion";
export const IS_COOKIE_WARNING_DISMISSED = "postsnap.isCookieWarningDismissed";
export const IS_EMAIL_CAPTURE_POPUP_DISMISSED = "postsnap.isEmailCapturePopupDismissed";
export const WIP_EDITOR_ITEM = "postsnap.wipEditorItem";
export const WIP_PREBAG = "postsnap.wipPrebag";
export const DEBUG_STATE = "postsnap.debugState";
export const IS_CROP_MODAL_SHOWN = "postsnap.isCropModalShown"
