import React, { useEffect, useRef, useState, forwardRef } from "react";
import Button from "../Button/Button";
import Grid from "../Grid/Grid";
import S3Image from "../../components/S3Image/S3Image"
import * as LAYER_TYPES from "../../constants/layer-types";
import HTMLFlipBook from 'react-pageflip';
import "./PhotoMagazinePreview.scss";
require("../../vendor/turn");


const RenderPage = React.memo(({ item, pageNumber, debug, width, currentPage, inner }) => {
  let leftPage = Math.abs(pageNumber % 2) === 0

  // let showingPage = false;
  // console.log("pageNumber",pageNumber)
  // console.log("currentPage",currentPage)

  // if (currentPage) {
  //   console.log("foo")
  //   const oddPage = Math.abs(currentPage % 2) === 1;
  //   if (oddPage && pageNumber === currentPage) {
  //     console.log("here")
  //     showingPage = true;
  //   }
  //   if (oddPage) {
  //     console.log("odd")
  //     if (pageNumber === currentPage - 1) {
  //       console.log("odd 1")
  //       showingPage = true;
  //     }
  //     if (pageNumber === currentPage + 1) {
  //       console.log("odd 1")
  //       showingPage = true;
  //     }
  //     if (pageNumber === currentPage + 2) {
  //       console.log("odd 1")
  //       showingPage = true;
  //     }
  //   }
  // } else {
  //   if (pageNumber === 0 || pageNumber === 1) {
  //     showingPage = true;
  //   }
  // }

  //console.log(`showingPage is ${showingPage} for ${pageNumber} (c${currentPage})`);
  // if (pageNumber === 0) {
  //   showingPage = true;
  // } else{
  //   const oddPage = Math.abs(pageObject.page % 2) === 1;
  //   if (oddPage && pageNumber === page) {
  //     showingPage = true;
  //   }
  //   if (oddPage) {
  //     if (pageNumber === page - 1) {
  //       showingPage = true;
  //     }
  //   }
  // }
  // console.log("showingPage", showingPage);

  let pageLayer
  let render
  const layer = item.get("layers").filter(layer => {
    return layer.get("type") === LAYER_TYPES.PHOTO && layer.get("page") === pageNumber
  })
  
  if(layer.size === 1){
    pageLayer = layer.first()
    render = pageLayer.get("renderLowRes")
  }

  return (
    <>
      {render && (
        <div className={`page ${inner ? '' : 'not-inner'} ${leftPage ? 'left' : 'right'}`} style={{width: '100%', height: '100%'}}>
        {/* {debug && <div className="page__number">{pageNumber}</div>} */}
        <S3Image storageKey={render} className="item-preview" width={width/2} retryOnError={true}/>
        {/* <HtmlRenderer
          photoMagazine={true}
          showingPage={showingPage}
          page={pageNumber}
          item={item.toJS()}
          isInteractive={false}
          previewMode={true}
          width={width}
          uniquekey={`preview-${pageNumber}`}
        /> */}
        {/* {pageNumber === 0  ? (
          <HtmlRenderer
          photoMagazine={true}
          showingPage={showingPage}
          page={pageNumber}
          item={item.toJS()}
          isInteractive={false}
          previewMode={true}
          width={width/2}
        />
        ) : (
          <S3Image storageKey={item.get("preview_s3_key")} className="item-preview" width={width/2}/>
        )}       */}
      </div>
      )}
    </>
    
  );
});

const BlankPage = ({ label, item }) => {
  //const globalTheme = item?.toJS()?.product_options?.globalTheme?.style?.color || "#fff";
  return (
    <div className="page not-inner page--blank" style={{width: '100%', height: '100%'}}>
      <div className="page__blank-label">{label}</div>
    </div>
  );
};

const PhotoMagazinePreview = forwardRef(({ item, onChangePage, pageCount }, ref) => {
  const containerRef = useRef(null);
  // eslint-disable-next-line
  const [fontSizeForCoverBand, setFontSizeForCoverBand] = useState(3);
  const [pageWidth, setPageWidth] = useState(100);
  const [pageHeight, setPageHeight] = useState(100);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  // useEffect(() => {
  //   const { width } = containerRef.current.getBoundingClientRect();
  //   const productDimensions = item.get("productDimensions").toJS();
  //   const aspectRatio = productDimensions.width / productDimensions.height;
  // }, [onChangePage, setFontSizeForCoverBand, item, pageCount]);

  useEffect(() => {
    const { width } = containerRef.current.getBoundingClientRect();
    const productDimensions = item.get("productDimensions").toJS();
    const aspectRatio = productDimensions.width / productDimensions.height;
    if (width) {
      setPageWidth(width/2);
      setPageHeight((width/2)/aspectRatio);
    }
  }, [setPageWidth, setPageHeight, item]);

  useEffect(() => {
    setTimeout(() => {
      if (book.current){
        setTotal(book.current.pageFlip().getPageCount());
      }
    }, 1000);

    console.log("preloading images");
    // item.get("layers").filter(layer => {
    //   return layer.get("type") === LAYER_TYPES.PHOTO
    // }).forEach(layer => {
    //   console.log("layer", layer.get("renderLowRes"))
    //   preloadImage(generateS3AssetUrlFromKey(layer.get("renderLowRes")));
    // })
  }, []);


  const book = useRef();
  const globalTheme = item?.toJS()?.product_options?.globalTheme?.style?.color || "#fff";

  let pageNumber = page;
  if(pageNumber === 0){
    pageNumber = "Cover"
  } else if (pageNumber === (total-3)){
    pageNumber = `Page ${total-4}`
  } else if (pageNumber === (total-1)){
    pageNumber = `Back Cover`
  } else{
    if (pageNumber === 1){
      pageNumber = "Page 1"
    } else{
      pageNumber = `Page ${pageNumber-1} - ${pageNumber} of ${total-4}`
    }
  }

  const coverTheme = item?.toJS().layers.find(layer => layer.page === 0 )?.config?.template?.canvasColor || globalTheme

  const updatePage = ({ data }) => setPage(data);

  return (
    <div className="photo-magazine-preview">
      <div className="photo-magazine-preview__render">
        <div ref={containerRef}>
          <HTMLFlipBook
            style={{height:'250px'}}
            onFlip={updatePage}
            ref={book}
            width={pageWidth}
            height={pageHeight}
            className="photo-magazine-flip-container"
            showCover={true}
            drawShadow={true}
            maxShadowOpacity={0.5}
            //usePortrait={true}
            useMouseEvents={false} // Disable flipping book on page touch
          >
            <div className="pageFlipPageContainer" key={`front-cover`}>
              <RenderPage
                key={`cover-page`}
                item={item}
                debug={true}
                pageNumber={0}
                width={pageWidth}
                currentPage={page}
              />
              </div>
            <div className="pageFlipPageContainer" key={`front-cover-inside`} style={{width: pageWidth}}>
              <BlankPage item={item} label="Blank inside front cover" />
            </div>
            {new Array(pageCount - 1).fill("").map((el, index) => (
              <div className="pageFlipPageContainer" key={`page-${index+1}`}>
                <RenderPage
                  key={index}
                  item={item}
                  debug={true}
                  pageNumber={index + 1}
                  width={pageWidth}
                  currentPage={page}
                  inner={true}
                />
              </div>
            ))}
            <div className="pageFlipPageContainer" key={`back-cover-inside`} style={{width: pageWidth}}>
              <BlankPage item={item} label="Blank inside back cover" />
            </div>
            <div className="pageFlipPageContainer" key={`back-cover`} style={{width: pageWidth}}>
              <div
                style={{
                  backgroundColor: coverTheme,
                  width: '100%',
                  height: '100%'
                }}
                className="shadow"
              >
                <img
                  src="https://s3.eu-west-1.amazonaws.com/assets.postsnap.com/logos/postsnap-grey.png"
                  style={{
                    position:'absolute',
                    bottom: '10%',
                    left: '10%',
                    width: '20%'
                  }}
                  alt={"postsnap-logo"}
                />
              </div>
            </div>
          </HTMLFlipBook>          
        </div>
      </div>
      <br />
      <p className="help-text text-center">{pageNumber}</p>
      <div className="photo-magazine-preview__navigation">
        <Grid.Row>
          <Grid.Column>
            <div
              style={{
                paddingRight: "0.25rem",
              }}
            >
              <Button
                block
                size="small"
                disabled={page === 0}
                label="Previous Page"
                theme="dark-blue"
                onClick={() =>
                  book.current.pageFlip().flipPrev()
                }
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div
              style={{
                paddingLeft: "0.25rem",
              }}
            >
              <Button
                disabled={page === (total-1)}
                block
                size="small"
                label="Next Page"
                theme="dark-blue"
                onClick={() =>
                  book.current.pageFlip().flipNext()
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
});

export default PhotoMagazinePreview;