import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import Pluralize from "pluralize";
import { fromJS } from "immutable";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import squareLayoutTemplates from "../../lib/collage-generator/square-layout-templates";
import landscapeLayoutTemplates from "../../lib/collage-generator/landscape-layout-templates";
import portraitLayoutTemplates from "../../lib/collage-generator/portrait-layout-templates";
import generateLayout from "../../lib/collage-generator/generate-layouts";
import retroColours from "../../pages/ThemeList/retro-colours.json";
import gtmEvent from "../../utils/gtm";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as PHOTO_FILTERS from "../../constants/photo-filters";
import TEMPLATES from "../../constants/templates";
import FILL_OPTIONS from "../../constants/fill-options";
import LAYOUTS from "../../constants/layouts";
import PAGES_PER_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import MASKS from "../../constants/masks";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import getMaxResolutionForDimensions from "../../lib/get-max-resolution-for-dimensions";
import { pickImages } from "../../lib/file-uploader";
import {
  getEarliestPostingDateForCurrentTime,
  getArrivalDatesForDestination,
} from "../../lib/postage-date-calculator";
import {
  getPackPricingSchemesForProduct,
  getPricingSchemeForSchemeId,
} from "../../data/pricing-schemes";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  productsByProductTypeId,
} from "../../data/products";
import { preloadImage } from "../../lib/image-preloader";

import MainContent from "../MainContent/MainContent";
import Footer from "../Footer/Footer";
import BottomNavBar from "../BottomNavbar/BottomNavbar";
import BottomNavbarItem from "../BottomNavbar/BottomNavbarItem";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import FilterPreview from "../FilterPreview/FilterPreview";
import Flippable from "../Flippable/Flippable";
import EditorImageOption from "./EditorImageOption";
import Slider from "../Slider/Slider";
import EditorFillOption from "./EditorFillOption";
import Header from "../Header/Header";
import Grid from "../Grid/Grid";
import Button from "../Button/Button";
import EditorCollageOption from "./EditorCollageOption";
import EditorSignatureInputModal from "./EditorSignatureInputModal";
import EditorAddressInputModal from "./EditorAddressInputModal";
import PicCollageEditorAddressInputModal from "./PicCollageEditorAddressInputModal";
import EditorCropperModal from "./EditorCropperModal";
import SweetAlert from "../SweetAlert/SweetAlert";
import EditorTextEditorModal from "./EditorTextEditorModal";
import Currency from "../Formatters/Currency";
import LocalUploadModal from "../Debug/LocalUploadModal";
import Modal from "../Modal/Modal";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Icon from "../Icon/Icon";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import CardOpener from "../CardOpener/CardOpener";
import EditorCanvasPackage from "./EditorCanvasPackage";

import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

import {
  Select,
  message,
  Button as AntButton,
  notification,
  Modal as AntModal,
  Row,
  Col,
  Checkbox,
} from "antd";
import { getAppValueByKey } from "../../data/app-values";
import "./Editor.scss";
import * as designsData from "../../data/designs";
import postsnapApi from "../../lib/apis/postsnap";
import transformDesignLayers from "../../lib/transform-design-layers";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import ProductPreview from "../ProductPreview/ProductPreview";

const Option = Select.Option;
const PLACEHOLDER_IMAGES = [
  "/app/images/placeholder-photo-900x400.jpeg",
  "/app/images/placeholder-photo-900x1600.jpeg",
  "/app/images/placeholder-photo-1000x500.jpeg",
  "/app/images/placeholder-photo-1200x800.jpeg",
  "/app/images/placeholder-photo-1800x1000.jpeg",
  "/app/images/debug-grid.jpg",
];

const TOOLS = {
  STYLE: {
    id: "STYLE",
    label: "Style",
    icon: "style",
  },
  FILTERS: {
    id: "FILTERS",
    label: "Effects",
    icon: "effects",
  },
  COLLAGE: {
    id: "COLLAGE",
    label: "Layouts",
    icon: "collage",
    altIcon: "collage-square",
  },
  BORDER_PRINT_WIDTHS: {
    id: "BORDER_PRINT_WIDTHS",
    label: "Border",
    icon: "border",
  },
  BORDER_PRINT_COLOURS: {
    id: "BORDER_PRINT_COLOURS",
    label: "Colour",
    icon: "colour-circles", // not suitable
    imageIcon: "colours"
  },
  CROP: {
    id: "CROP",
    label: "Crop & Rotate",
    icon: "crop-rotate",
  },
  TEXT: {
    id: "TEXT",
    label: "Text",
    icon: "text",
  },
  BORDER: {
    id: "BORDER",
    label: "Borders",
    icon: "border",
  },
  SIZE_SELECTOR: {
    id: "SIZE_SELECTOR",
    label: "Size",
    icon: "resize",
  },
  GRID_OPTIONS: {
    id: "GRID_OPTIONS",
    label: "Grid Style",
    icon: "grid-options",
  },
  DEBUG: {
    id: "DEBUG",
    label: "Debug",
    icon: "bug",
  },
};

const EDITOR_CONFIG_PER_PRODUCT_TYPE = {
  [PRODUCT_TYPE_IDS.POSTCARD]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.GREETING_CARD]: {
    tools: [TOOLS.STYLE, TOOLS.CROP, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].rear]: "Card Inside",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.GREETING_CARD].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.INVITATION]: {
    tools: [TOOLS.STYLE, TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.INVITATION].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.ANNOUNCEMENT]: {
    tools: [TOOLS.STYLE, TOOLS.COLLAGE, TOOLS.CROP, TOOLS.BORDER, TOOLS.TEXT],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].front]: "Card Front",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].rear]: "Card Back",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.ANNOUNCEMENT].envelope]: "Recipient Details",
    },
  },
  [PRODUCT_TYPE_IDS.CANVAS]: {
    tools: [TOOLS.CROP],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].front]: "Canvas",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].rear]: "Canvas",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.CANVAS].envelope]: "Recipient",
    },
  },
  [PRODUCT_TYPE_IDS.PHOTO_TILE]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.PHOTO_TILE].front]: "Photo Tile",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.PHOTO_TILE].rear]: "Photo Tile",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.PHOTO_TILE].envelope]: "Shipping",
    },
  },
  [PRODUCT_TYPE_IDS.FRAMED_PRINTS]: {
    tools: [],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.FRAMED_PRINTS].front]: "Framed Print",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.FRAMED_PRINTS].rear]: "Framed Print",
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.FRAMED_PRINTS].envelope]: "Shipping",
    },
  },
  [PRODUCT_TYPE_IDS.PHOTO_PRINT]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP, TOOLS.GRID_OPTIONS],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.PHOTO_PRINT].front]: "",
    },
  },
  [PRODUCT_TYPE_IDS.COLLAGE_PRINTS]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.COLLAGE_PRINTS].front]: "Collage",
    },
  },
  [PRODUCT_TYPE_IDS.POSTERS]: {
    tools: [TOOLS.COLLAGE, TOOLS.CROP],
    pageTitles: {
      [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTERS].front]: "Front",
    },
  },
};

export const STEPS = {
  PRODUCT_FRONT: "PRODUCT_FRONT",
  PRODUCT_REAR: "PRODUCT_REAR",
  RECIPIENT_DETAILS: "RECIPIENT_DETAILS",
  SELECT_POST_DATE: "SELECT_POST_DATE",
};

const UPLOAD_MODES = {
  DEBUG: "DEBUG",
  UPLOADCARE: "UPLOADCARE",
};

class CustomDatepickerField extends Component {
  render() {
    let dateLabel;
    if (moment(this.props.value).isSame(moment(), "day")) {
      dateLabel = "Today";
    } else if (moment(this.props.value).isSame(moment(new Date()).add(1, "days"), "day")) {
      dateLabel = "Tomorrow";
    } else {
      dateLabel = moment(this.props.value).format("D MMM YYYY");
    }

    return (
      <Button
        theme="muted"
        priority="secondary"
        label={dateLabel}
        icon="arrow-dropdown"
        iconPosition="right"
        onClick={this.props.onClick}
      />
    );
  }
}

class Editor extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    saveButtonLabel: PropTypes.string,
    onTextContentChange: PropTypes.func,
    onTextPositionChange: PropTypes.func,
    onChangeSrcForPhotoRegion: PropTypes.func,
    onCropImageInRegionInPhotoLayer: PropTypes.func,
    onMoveImageInRegionInPhotoLayer: PropTypes.func,
    onChangeFilterForPhotoRegion: PropTypes.func,
    onChangeSelectedGraphic: PropTypes.func,
    onChangeBorderThickness: PropTypes.func,
    onChangeBorderPrintWidth: PropTypes.func,
    onChangeBorderStyle: PropTypes.func,
    onChangeTextConfig: PropTypes.func,
    onChangeLayout: PropTypes.func,
    onChangeSignature: PropTypes.func,
    onChangeAddress: PropTypes.func,
    onChangeAddressBookId: PropTypes.func,
    onChangePostDate: PropTypes.func,
    onChangeProductId: PropTypes.func,
    lastStep: PropTypes.oneOf(Object.values(STEPS)),
    disableScaling: PropTypes.bool,
    onChangeStep: PropTypes.func,
    currency: PropTypes.string,
    onChangeRegionBorderRadius: PropTypes.func,
    onChangeRegionBorderWidth: PropTypes.func,
  };

  static defaultProps = {
    disableScaling: false,
  };

  constructor(props) {
    super(props);

    const availableTools = Editor.getAvailableToolsForPage(
      props.item,
      props.item.getIn(["pages", "front"])
    );
    this.defaultState = {
      alert: null,
      uploadMode:
        process.env.NODE_ENV === "development" ? UPLOAD_MODES.UPLOADCARE : UPLOAD_MODES.UPLOADCARE, //DEBUG
      isLoading: false,
      isUploadModalVisible: false,
      isReplacingImage: false,
      isTextEditorVisible: false,
      textEditorScalingDimensions: null,
      isSignatureEditorVisible: false,
      isAddressModalVisible: false,
      isInfoModalVisible: false,
      isShippingInfoModalVisible: false,
      isOptionsModalVisible: false,
      isPacksModalOpen: false,
      isSizeSelectionModalVisible: false,
      addressInputMode: "new",
      isCropperModalVisible: false,
      isPhotoMagazineEditorVisible: false,
      currentStep: STEPS.PRODUCT_FRONT,
      selectedLayerId: null,
      selectedRegionIndex: null,
      availableTools: availableTools,
      activeTool: availableTools.length > 0 ? availableTools[0].id : null,
      areTextOptionsVisible: true,
      isAdditionalEnveleopeRequired: false,
      cardOpenerDimension: null,
      templateModalOpen: false,
      templateModalMessage: "",
      emptyInsideModalOpen: false,
      chosenInsideBlank: null,
      customUploadTitle: null,
    };

    const photoLayersForPage = this.props.item
      .get("layers")
      .filter(
        l =>
          l.get("type") === LAYER_TYPES.PHOTO &&
          l.get("page") === this.props.item.getIn(["pages", "front"])
      );

    if (photoLayersForPage && photoLayersForPage.size === 1) {
      if (photoLayersForPage.first().getIn(["config", "layout"]).size === 1) {
        this.defaultState.selectedLayerId = photoLayersForPage.get("id") //|| photoLayersForPage.first().get("id");
        this.defaultState.selectedRegionIndex = 0;
      }
    }

    this.state = { ...this.defaultState };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.moveUpPostingDateIfNecessary(this.props.item.get("postDate"));

    message.config({
      top: 20,
    });

    if (this.props.item.get("templateId")) {
      const templateConfig = TEMPLATES[this.props.item.get("templateId")];
      if (templateConfig.message !== "") {
        this.setState({
          templateModalOpen: true,
          templateModalMessage: templateConfig.message,
        });
      }
    }

    if (this.props.selectVariantIndex) {
      this.handleChangeSelectedVariant(this.props.selectVariantIndex);
    }

    if (this.props.item && this.props.item.get("packMode")) {
      //message.info(`This card is being made as a pack of ${this.props.item.get('quantity')} — you can change your pack size below`, 4);
      //message.info(`This card is being made as a pack of ${this.props.item.get('quantity')} — you can change your pack size below`, 4);

      message.open({
        content: `This card is being made as a pack of ${this.props.item.get(
          "packQuantity"
        )} — you can change your pack size below`,
        duration: 4,
        icon: <InfoCircleOutlined />,
      });
    }
    this.props.onRef(undefined);

    if (
      [
        PRODUCT_TYPE_IDS.CANVAS,
        PRODUCT_TYPE_IDS.PHOTO_TILE,
        PRODUCT_TYPE_IDS.FRAMED_PRINTS,
      ].includes(this.props.item.get("productTypeId"))
    ) {
      preloadImage(`${process.env.PUBLIC_URL}/images/cardboard.jpg`);
    }

    if (this.props.item && (this.props.item.get("isCollagePrint"))){
      const currentPhotoLayer = this.props.item.get("layers").find(l => l.get("type") === LAYER_TYPES.PHOTO);
      const numPopulatedPhotosInCurrentLayout = currentPhotoLayer.getIn(["config", "layout"]).count(region => region.get("image"));
      // Only show upload if coming for first time
      if (numPopulatedPhotosInCurrentLayout === 0) {
        this.setRemainingPhotoCount(this.showUploadModal);
      }
      this.setState({
        activeTool:TOOLS.CROP.id
      })

    }

    if (this.props.item && this.props.item.get("isBorderPrint")){
      const currentPhotoLayer = this.props.item.get("layers").find(l => l.get("type") === LAYER_TYPES.PHOTO);
      const numPopulatedPhotosInCurrentLayout = currentPhotoLayer.getIn(["config", "layout"]).count(region => region.get("image"));
      if (numPopulatedPhotosInCurrentLayout === 0 ){
        setTimeout(() => {
          this.setState({
            activeTool:TOOLS.BORDER_PRINT_WIDTHS.id 
          }, () => {
            this.handleSelectRegionInPhotoLayer(currentPhotoLayer.get("id"), 0);
          })
        }, 10 ); // without this things seem to break... TBD
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.moveUpPostingDateIfNecessary(this.props.item.get("postDate"));

    try {
      const currentPhotoLayer = this.props.item
        .get("layers")
        .find(l => l.get("type") === LAYER_TYPES.PHOTO);
      const numPhotosInCurrentLayout = currentPhotoLayer.getIn(["config", "layout"]).count();

      const previousPhotoLayer = prevProps.item
        .get("layers")
        .find(l => l.get("type") === LAYER_TYPES.PHOTO);
      const numPhotosInPreviousLayout = previousPhotoLayer.getIn(["config", "layout"]).count();

      if (numPhotosInCurrentLayout !== numPhotosInPreviousLayout) {
        this.setRemainingPhotoCount();
      }
    } catch (e) {}
  }

  static getDerivedStateFromProps(props, state) {
    switch (state.currentStep) {
      case STEPS.PRODUCT_FRONT:
        return {
          availableTools: Editor.getAvailableToolsForPage(
            props.item,
            props.item.getIn(["pages", "front"])
          ),
        };
      case STEPS.PRODUCT_REAR:
        //const isGreetingCardPack = props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD && props.item.get("packMode")
        return {
          availableTools: Editor.getAvailableToolsForPage(
            props.item,
            props.item.getIn(["pages", "rear"])
          ),
          emptyInsideModalOpen: false, //isGreetingCardPack && state.chosenInsideBlank === null
        };
      default:
        return null;
    }
  }

  goToProductFront = () => {
    this.setCurrentStep(STEPS.PRODUCT_FRONT);
  };

  moveUpPostingDateIfNecessary = date => {
    if (this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD) {
      const earliestPostingDate = getEarliestPostingDateForCurrentTime();
      const selectedDate = moment(date);
      const isSelectedDateBeforeEarliestPostingDate =
        selectedDate.diff(earliestPostingDate, "days") < 0;
      if (isSelectedDateBeforeEarliestPostingDate) {
        this.props.onChangePostDate(earliestPostingDate.format());
      }
    }
  };

  handleSelectRegionInPhotoLayer = (layerId, regionIndex) => {
    this.setState(
      {
        selectedLayerId: layerId,
        selectedRegionIndex: regionIndex,
      },
      () => {
        const product = productsByProductId.get(this.props.item.get("productId"));
        gtmEvent({
          event: "webAppEvent",
          additionalProps: {
            eventName: "Photo Upload Clicked",
            stage: `${product.get("appKey")} Editor`,
            product: product.get("appKey"),
          },
        });
      }
    );

    const photoLayer = this.props.item.get("layers").find(l => l.get("id") === layerId);
    const regionInPhoto = photoLayer.getIn(["config", "layout", regionIndex]);
    if (!regionInPhoto.get("image")) {
      this.showUploadModal();
    } else {
      switch (this.state.activeTool) {
        case TOOLS.STYLE.id:
        case TOOLS.COLLAGE.id: {
          this.showUploadModal();
          break;
        }
        case TOOLS.CROP.id:
          this.setState({
            isCropperModalVisible: true,
          });
          break;
        // no default
      }
    }
  };

  setRemainingPhotoCount = (callback = () => {}) => {
    let photoLayerForPage = this.props.item
      .get("layers")
      .find(l => l.get("type") === LAYER_TYPES.PHOTO);
    let selectedLayerId = this.state.selectedLayerId;

    if (this.props.item.get('isCollagePrint') || this.props.item.get('isBorderPrint')){
      selectedLayerId = photoLayerForPage.get("id")
    }

    const missingPhotoCount =
      photoLayerForPage.getIn(["config", "layout"]).count() -
      photoLayerForPage.getIn(["config", "layout"]).count(region => region.get("image"));

    let uploadDescription = "";
    let uploadTitle = "Upload Photo";
    if (missingPhotoCount > 1) {
      uploadTitle = `Upload ${missingPhotoCount} Photos`;
      //uploadDescription = `Upload ${missingPhotoCount} photos - you will be able to drag and drop into position after uploading`
    }

    if (this.state.isReplacingImage === true) {
      uploadTitle = "Upload Photo";
      uploadDescription = "Choose a replacement photo";
    }

    this.setState(
      {
        customUploadTitle: uploadTitle,
        customUploadDescription: uploadDescription,
        selectedLayerId: selectedLayerId,
      },
      () => {
        callback();
      }
    );
  };

  showUploadModal = () => {
    this.setState({
      isUploadModalVisible: true,
      isReplacingImage: false,
    });
  };

  closeUploadModal = ({ withPhotos = false }) => {
    this.setState({ isUploadModalVisible: false, isReplacingImage: false }, () => {
      if (withPhotos) {
        this.setRemainingPhotoCount();
        const product = productsByProductId.get(this.props.item.get("productId"));
        gtmEvent({
          event: "webAppStage",
          additionalProps: {
            stage: "Photo Upload Complete",
            product: product.get("appKey"),
          },
        });
      }
    });
  };

  triggerStartUpload = (product, source) => {
    gtmEvent({
      event: "webAppStage",
      additionalProps: {
        stage: "Photo Upload Started",
        product: product.get("appKey"),
        source: source,
      },
    });
  };

  pickImagesFromUploadcare = async source => {
    try {
      const photoLayerForPage = this.props.item
        .get("layers")
        .find(l => l.get("type") === LAYER_TYPES.PHOTO);
      const missingPhotoCount =
        photoLayerForPage.getIn(["config", "layout"]).count() -
        photoLayerForPage.getIn(["config", "layout"]).count(region => region.get("image"));
      let amount = missingPhotoCount;
      let freePhotoRegionIndexes = photoLayerForPage
        .getIn(["config", "layout"])
        .filter(region => !region.get("image"))
        .map(region => {
          return photoLayerForPage.getIn(["config", "layout"]).indexOf(region);
        })
        .toJS();

      // const product = productsByProductId.get(this.props.item.get("productId"));
      // const productWidth =  (product.get('width') + parseInt(product.get('bleed_l')) + parseInt(product.get('bleed_r')));
      // const productHeight =  (product.get('height') + parseInt(product.get('bleed_t')) + parseInt(product.get('bleed_b')));
      let maxResolution = false;

      let images = await pickImages({
        amount: amount,
        source,
        maxResolution,
        autoRotate: false,
      });

      if (amount === 1) {
        images = new Array(1).fill(images);
      }

      images.forEach((image, index) => {
        const layerId = this.state.selectedLayerId; //photoLayerForPage[index].get('id')
        const freePhotoRegionIndex = freePhotoRegionIndexes[index];
        const regionIndex = amount === 1 ? this.state.selectedRegionIndex : freePhotoRegionIndex;

        this.props.onChangeSrcForPhotoRegion({
          layerId: layerId,
          regionIndex: regionIndex,
          src: {
            lowResUrl: image.lowResUrl,
            highResUrl: image.highResUrl,
            uploadcareUuid: image.uuid,
          },
          image
        });
      });

      //console.log("Missing remaining", missingRemaining)

      //this.setRemainingPhotoCount(() => {
      this.closeUploadModal({ withPhotos: true });
      if (!localStorage.getItem(STORAGE_KEYS.IS_CROP_MODAL_SHOWN)) {
        AntModal.info({
          title: "",
          content: (
            <div>
              <p>Tap a photo to crop, drag and drop to change its position</p>
            </div>
          ),
          //okButtonProps: {style : {background: 'red'}},
          okType: "secondary",
          icon: null,
          onOk() {
            localStorage.setItem(STORAGE_KEYS.IS_CROP_MODAL_SHOWN, "true");
          },
        });
      }

      //})
    } catch (err) {
      console.log("Cancelled upload:", err);
    }
  };

  pickImageFromUploadcare = async source => {
    try {
      if (this.props.item.get("isCollagePrint")) {
        const photoLayerForPage = this.props.item
          .get("layers")
          .find(l => l.get("type") === LAYER_TYPES.PHOTO);
        const missingPhotoCount =
          photoLayerForPage.getIn(["config", "layout"]).count() -
          photoLayerForPage.getIn(["config", "layout"]).count(region => region.get("image"));
        if (missingPhotoCount > 1 && this.state.isReplacingImage === false) {
          return this.pickImagesFromUploadcare(source);
        }
      }

      const product = productsByProductId.get(this.props.item.get("productId"));

      //TODO - this won't be ideal as standard postcard is selected by default
      // Pick the biggest product...

      let maxResolution = getMaxResolutionForDimensions({
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
      });

      // TODO: check the 100% is needed and not the default
      maxResolution = false; //`${maxResolution} 100%`

      const image = await pickImages({
        source,
        maxResolution,
        onStartUpload: () => this.triggerStartUpload(product, source),
      });
      this.props.onChangeSrcForPhotoRegion({
        layerId: this.state.selectedLayerId,
        regionIndex: this.state.selectedRegionIndex,
        src: {
          lowResUrl: image.lowResUrl,
          highResUrl: image.highResUrl,
          uploadcareUuid: image.uuid,
        },
        image
      });
      this.closeUploadModal({ withPhotos: true });
    } catch (err) {
      console.log("Error while uploading:", err);
    }
  };

  handleSelectDebugUploadImage = imgUrl => {
    const layer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);

    switch (layer.get("type")) {
      case LAYER_TYPES.PHOTO:
        this.props.onChangeSrcForPhotoRegion({
          layerId: this.state.selectedLayerId,
          regionIndex: this.state.selectedRegionIndex,
          src: {
            lowResUrl: imgUrl,
            highResUrl: imgUrl,
          },
        });
        break;
      // no default
    }

    this.closeUploadModal({ withPhotos: true });
  };

  handleClickTextLayer = (layerId, dimensionsInPixels) => {
    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      const layer = this.props.item.get("layers").find(l => l.get("id") === layerId);

      this.setState(
        {
          selectedLayerId: layerId,
          selectedRegionIndex: null,
          activeTool: !this.state.activeTool ? TOOLS.TEXT.id : this.state.activeTool,
          textEditorScalingDimensions: null,
        },
        () => {
          if (layer.getIn(["config", "text"]) === layer.getIn(["config", "originalText"])) {
            //this.openTextEditorForLayerId(layerId);
          }
        }
      );
    } else {
      this.setState({
        textEditorScalingDimensions: dimensionsInPixels,
      });
      this.openTextEditorForLayerId(layerId);
    }
  };

  handleDeselectTextLayer = () => {
    this.setState({
      selectedLayerId: null,
      selectedRegionIndex: null,
      activeTool: this.state.activeTool === TOOLS.TEXT.id ? null : this.state.activeTool,
    });
  };

  openTextEditorForLayerId = layerId => {
    this.setState({
      activeTool: TOOLS.TEXT.id,
      selectedLayerId: layerId,
      selectedRegionIndex: null,
      isTextEditorVisible: true,
    });

    this.textEditor.focus();
  };

  toggleTextOptions = () => {
    this.setState({
      areTextOptionsVisible: !this.state.areTextOptionsVisible,
    });
  };

  handleClickSignatureLayer = layerId => {
    this.setState({
      selectedLayerId: layerId,
      selectedRegionIndex: null,
      isSignatureEditorVisible: true,
    });
  };

  handleClearLayerSelection = () => {
    this.setState({
      selectedLayerId: null,
      selectedRegionIndex: null,
    });
  };

  setFilterForPhotoRegion = filter => {
    this.props.onChangeFilterForPhotoRegion({
      layerId: this.state.selectedLayerId,
      regionIndex: this.state.selectedRegionIndex,
      filter,
    });
  };

  activateTool = toolId => {
    const currentTool = this.state.activeTool;
    this.setState(
      {
        activeTool: toolId,
      },
      () => {
        switch (toolId) {
          case TOOLS.TEXT.id: {
            const activePageIndex =
              this.state.currentStep === STEPS.PRODUCT_FRONT
                ? this.props.item.getIn(["pages", "front"])
                : this.props.item.getIn(["pages", "rear"]);

            const photoLayerForPage = this.props.item
              .get("layers")
              .find(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.PHOTO);
            const textLayersForPage = this.props.item
              .get("layers")
              .filter(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.TEXT);
            const extraTextLayer = this.props.item
              .get("layers")
              .find(l => l.get("id") === "EXTRA_TEXT_LAYER");
            const extraTextLayerIsEmpty = !extraTextLayer.getIn(["config", "text"]);
            const notEveryRegionHasPhoto = !photoLayerForPage
              .getIn(["config", "layout"])
              .every(region => region.get("image"));
            const missingPhotoCount =
              photoLayerForPage.getIn(["config", "layout"]).count() -
              photoLayerForPage.getIn(["config", "layout"]).count(region => region.get("image"));
            const extraTextLayerIsOnlyTextLayer = extraTextLayer && textLayersForPage.size === 1;

            const isGreetingCard =
              this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;
            const isFront = this.state.currentStep === STEPS.PRODUCT_FRONT;
            if (isGreetingCard && isFront && extraTextLayerIsOnlyTextLayer) {
              return this.setState({
                activeTool: currentTool,
                alert: {
                  type: "info",
                  text:
                    "Sorry. This card doesn't support text on the front, go to the inside to add your message.",
                  showCancelButton: false,
                  onConfirm: this.clearAlert,
                },
              });
            }

            if (extraTextLayerIsOnlyTextLayer && extraTextLayerIsEmpty && notEveryRegionHasPhoto) {
              return this.setState({
                activeTool: currentTool,
                alert: {
                  type: "info",
                  text:
                    missingPhotoCount === 1
                      ? "Please add your photo before adding text"
                      : `Please add the remaining ${missingPhotoCount} photos before adding text.`,
                  showCancelButton: false,
                  onConfirm: this.clearAlert,
                },
              });
            }
            if (
              Editor.doesPageContainLayerType(this.props.item, activePageIndex, LAYER_TYPES.TEXT)
            ) {
              const firstTextLayer = this.props.item
                .get("layers")
                .find(l => l.get("page") === activePageIndex && l.get("type") === LAYER_TYPES.TEXT);
              this.openTextEditorForLayerId(firstTextLayer.get("id"));
            }
            break;
          }
          case TOOLS.BORDER.id: {
            const activePageIndex =
              this.state.currentStep === STEPS.PRODUCT_FRONT
                ? this.props.item.getIn(["pages", "front"])
                : this.props.item.getIn(["pages", "rear"]);
            const firstPhotoLayer = this.props.item
              .get("layers")
              .find(l => l.get("page") === activePageIndex);

            if (firstPhotoLayer && !firstPhotoLayer.getIn(["config", "border", "style"])) {
              this.setState({
                selectedLayerId: firstPhotoLayer.get("id"),
              });
              this.props.onChangeBorderStyle(firstPhotoLayer.get("id"), {
                type: "color",
                color: "rgb(255,255,255)",
              });
            }
            break;
          }
          case TOOLS.COLLAGE.id: {
            this.setState({
              selectedLayerId: null,
              selectedRegionIndex: null,
            });
            break;
          }
          case TOOLS.CROP.id: {
            const allPhotoLayersForPage = this.props.item
              .get("layers")
              .filter(
                l =>
                  l.get("type") === LAYER_TYPES.PHOTO &&
                  l.get("page") === this.props.item.getIn(["pages", "front"])
              );

            // If there's only one photo layer with one image region, select that region to automatically start the cropper
            if (allPhotoLayersForPage.size === 1) {
              if (allPhotoLayersForPage.first().getIn(["config", "layout"]).size === 1) {
                this.handleSelectRegionInPhotoLayer(allPhotoLayersForPage.first().get("id"), 0);
              }
            }
            break;
          }
          case TOOLS.SIZE_SELECTOR.id: {
            this.setState({
              isSizeSelectionModalVisible: true,
            });
          }
          // no default
        }
      }
    );
  };

  static doesPageContainLayerType = (item, pageIndex, layerType) => {
    return Boolean(
      item.get("layers").find(layer => {
        const isLayerForCurrentPage = layer.get("page") === pageIndex;
        const isMatchingLayerType = layer.get("type") === layerType;
        return isLayerForCurrentPage && isMatchingLayerType;
      })
    );
  };

  static getAvailableToolsForPage = (item, pageIndex) => {
    let configEntry = EDITOR_CONFIG_PER_PRODUCT_TYPE[item.get("productTypeId")];

    if (!configEntry) {
      return [];
    }

    if (item.get("templateId")) {
      const templateConfig = TEMPLATES[item.get("templateId")];
      if (templateConfig.allowEditing === false) {
        return [];
      }
    }

    if (item.get("picCollage")) {
      return [];
    }

    if (item.get('isBorderPrint')){
      configEntry = {
        tools: [TOOLS.BORDER_PRINT_WIDTHS, TOOLS.BORDER_PRINT_COLOURS, TOOLS.CROP],
        pageTitles: {
          [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].front]: "Border Print",
          [PAGES_PER_PRODUCT_TYPE[PRODUCT_TYPE_IDS.POSTCARD].envelope]: "Recipient Details",
        },
      }
    }

    const allToolsForProductType = configEntry.tools;

    return allToolsForProductType.filter(tool => {
      switch (tool.id) {
        case TOOLS.STYLE.id:
          return (
            Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.GRAPHIC) &&
            item.get("designOptions").size > 1
          );
        case TOOLS.FILTERS.id:
        case TOOLS.BORDER.id:
        case TOOLS.BORDER_PRINT_WIDTHS.id:
        case TOOLS.BORDER_PRINT_COLOURS.id:
        case TOOLS.CROP.id:
          return Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.PHOTO);
        case TOOLS.COLLAGE.id: {
          const containsLayerType = Editor.doesPageContainLayerType(
            item,
            pageIndex,
            LAYER_TYPES.PHOTO
          );

          if (
            [PRODUCT_TYPE_IDS.COLLAGE_PRINTS, PRODUCT_TYPE_IDS.POSTERS].includes(
              item.get("productTypeId")
            )
          ) {
            return containsLayerType && item.get("supports_collages");
          }

          return containsLayerType;
        }
        case TOOLS.TEXT.id:
          return (
            Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.TEXT) ||
            (Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.PHOTO) &&
              !Editor.doesPageContainLayerType(item, pageIndex, LAYER_TYPES.TEXT))
          );
        case TOOLS.GRID_OPTIONS.id:
        case TOOLS.SIZE_SELECTOR.id:
        case TOOLS.DEBUG.id:
          return true;
        default:
          return false;
      }
    });
  };

  handleSave = () => {
    const isSinglePageWallArt = [
      PRODUCT_TYPE_IDS.CANVAS,
      PRODUCT_TYPE_IDS.PHOTO_TILE,
      PRODUCT_TYPE_IDS.FRAMED_PRINTS,
    ].includes(this.props.item.get("productTypeId"));
    if (
      this.props.skipAddressInput ||
      this.props.item.get("address") || this.props.item.get("addressBookId")
    ) {
      this.props.onSave();
    } else {
      //this.props.onSave();
      if (
        (this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD &&
          this.props.item.get("picCollage")) ||
        this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.POSTCARD
      ) {
        this.setState(
          {
            alert: {
              type: "info",
              showCancelButton: false,
              confirmButtonText: "OK",
              title: "Recipient Details",
              text: "Please add an address",
              onConfirm: this.clearAlert,
            },
          },
          () => {
            gtmEvent({ event: "webAppEvent", additionalProps: { eventName: "Address Error" } });
          }
        );
      } else if (isSinglePageWallArt) {
        const allPhotoLayersForPage = this.props.item
          .get("layers")
          .filter(
            l =>
              l.get("type") === LAYER_TYPES.PHOTO &&
              l.get("page") === this.props.item.getIn(["pages", "front"])
          );
        const allRegionsInPhotoLayers = allPhotoLayersForPage.flatMap(photoLayer =>
          photoLayer.getIn(["config", "layout"])
        );
        const missingPhotoCount =
          allRegionsInPhotoLayers.count() -
          allRegionsInPhotoLayers.count(region => region.get("image"));
        if (!allRegionsInPhotoLayers.every(region => region.get("image"))) {
          message.warning(
            missingPhotoCount === 1
              ? "Please add a photo before proceeding"
              : ` Please add all ${Pluralize("photo", missingPhotoCount)} before proceeding`
          );
          return null;
        } else {
          this.props.onSave();
        }
      } else {
        this.props.onSave();
      }
    }
  };

  saveTextEditorConfig = config => {
    this.props.onChangeTextConfig(this.state.selectedLayerId, config);
    this.closeTextEditor();
  };

  closeTextEditor = () => {
    this.setState({
      isTextEditorVisible: false,
    });
  };

  saveSignature = signature => {
    this.props.onChangeSignature(this.state.selectedLayerId, signature);
    this.closeSignatureEditor();
  };

  closeSignatureEditor = () => {
    this.setState({
      selectedLayerId: null,
      isSignatureEditorVisible: false,
    });
  };

  clearSelection = () => {
    this.frontRenderer.clearSelection();
    this.backRenderer.clearSelection();
  };

  handleCropperSave = cropData => {
    this.props.onCropImageInRegionInPhotoLayer({
      layerId: this.state.selectedLayerId,
      regionIndex: this.state.selectedRegionIndex,
      cropData,
    });
    this.setState({
      isCropperModalVisible: false,
    });
  };

  handleReplaceImage = () => {
    this.setState(
      {
        isCropperModalVisible: false,
        isUploadModalVisible: true,
        isReplacingImage: true,
      },
      () => {
        this.setRemainingPhotoCount();
      }
    );
  };

  handleChangeLayout = (layerId, layout) => {
    this.setState(
      {
        selectedLayerId: null,
        selectedRegionIndex: null,
      },
      () => {
        this.props.onChangeLayout(layerId, layout);
      }
    );
  };

  generateItemForProductId = async productId => {
    const productTypeId = this.props.item.get("productTypeId");
    const product = productsByProductId.get(productId);
    const firstDesign = designsData.designsByProductId.get(productId).first();
    const designDetail = await postsnapApi.designs.getDetails(firstDesign.get("id"));

    const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId)
      .sort((a, b) => a.page - b.page)
      .reverse();

    const itemData = fromJS({
      productDimensions: {
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
        bleed: {
          top: parseFloat(product.get("bleed_t")),
          bottom: parseFloat(product.get("bleed_b")),
          left: parseFloat(product.get("bleed_l")),
          right: parseFloat(product.get("bleed_r")),
        },
      },
      productTypeId,
      productId: product.get("id"),
      designId: firstDesign.get("id"),
      quantity: 1,
      weight: 1,
      pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
      layers,
      product_options: { card_finish: "gloss" },
      postDate: moment(),
      duplicateAlertShown: false,
    });

    return itemData;
  };

  changeEditorItemProduct = async product => {
    if (product.get("id") !== this.props.item.get("productId")) {
      const newItem = await this.generateItemForProductId(product.get("id"));
      this.props.setEditorItem(newItem);
    }

    this.setState({
      isSizeSelectionModalVisible: false,
    });
  };

  handleClickAddressLayer = () => {
    console.log(this.props.item.get("addressFixed"));
    if (this.props.item.get("addressFixed")) {
      console.log("Can't edit address");
      //TODO:message why...
      message.info("Sorry, this postcard template has a fixed shipping address", 4);
      return;
    }

    if (this.props.item.get("address")) {
      this.setState({
        alert: {
          text: "Do you wish to edit this address or add a new one?",
          confirmButtonText: "Edit Existing",
          confirmButtonColor: "#4b5566",
          onConfirm: () =>
            this.setState({ addressInputMode: "edit", alert: null }, this.showAddressModal),
          cancelButtonText: "Add New",
          onCancel: () =>
            this.setState({ addressInputMode: "new", alert: null }, this.showAddressModal),
        },
      });
    } else {
      this.showAddressModal();
    }
  };

  handleAddressSave = address => {
    this.props.onChangeAddress(address);
    this.closeAddressModal();
  };

  handleOwnAddressSave = address => {
    return this.props.onChangeOwnAddress(address);
  };

  handleSelectAddressBookEntry = (entry, isDoubleDirect) => {
    this.props.onChangeAddressBookId(entry.get("id"), entry.toJS());
    this.setState({
      isAdditionalEnvelopeRequired: isDoubleDirect,
    });
    this.closeAddressModal();
  };

  showAddressModal = () => {
    this.setState(
      {
        isAddressModalVisible: true,
      },
      () => {
        const product = productsByProductId.get(this.props.item.get("productId")).get("appKey");
        gtmEvent({
          event: "webAppStage",
          additionalProps: {
            stage: "Shipping Address",
            product: product,
          },
        });
      }
    );
  };

  closeAddressModal = () => {
    this.setState(
      {
        isAddressModalVisible: false,
      },
      () => {
        if (this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.POSTCARD) {
          const destination =
            (this.props.item.get("address") && this.props.item.getIn(["address", "country"])) ||
            (this.props.item.get("addressBookEntry") &&
              this.props.item.getIn(["addressBookEntry", "country"]));
          console.log("destination", destination);
          if (destination === "United States") {
            this.showShippingInfoModal();
          }
        }
      }
    );
  };

  showInfoModal = () => {
    this.setState({
      isInfoModalVisible: true,
    });
  };

  showShippingInfoModal = () => {
    this.setState({
      isShippingInfoModalVisible: true,
    });
  };

  closeShippingInfoModal = () => {
    this.setState({
      isShippingInfoModalVisible: false,
    });
  };

  closeInfoModal = () => {
    this.setState({
      isInfoModalVisible: false,
    });
  };

  showOptionsModal = () => {
    this.setState({
      isOptionsModalVisible: true,
    });
  };

  closeOptionsModal = () => {
    this.setState({
      isOptionsModalVisible: false,
    });
  };

  closeTemplateModal = () => {
    this.setState({
      templateModalOpen: false,
    });
  };

  closeEmptyInsideModal = () => {
    this.setState({
      emptyInsideModalOpen: false,
      chosenInsideBlank: false,
    });
  };

  leaveInsideBlank = () => {
    this.setState(
      {
        emptyInsideModalOpen: false,
        chosenInsideBlank: true,
      },
      () => {
        this.setCurrentStep(STEPS.RECIPIENT_DETAILS);
      }
    );
  };

  showPacksModal = () => {
    this.setState({
      isPacksModalOpen: true,
    });
  };

  closePacksModal = () => {
    this.setState({
      isPacksModalOpen: false,
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDimensionChange = dimensions => {
    this.setState({ cardOpenerDimension: dimensions });
  };

  setCurrentStep = newStep => {
    const currentStep = this.state.currentStep;
    if (
      currentStep === STEPS.PRODUCT_FRONT &&
      [STEPS.RECIPIENT_DETAILS, STEPS.PRODUCT_REAR].includes(newStep)
    ) {
      const allPhotoLayersForPage = this.props.item
        .get("layers")
        .filter(
          l =>
            l.get("type") === LAYER_TYPES.PHOTO &&
            l.get("page") === this.props.item.getIn(["pages", "front"])
        );
      const allRegionsInPhotoLayers = allPhotoLayersForPage.flatMap(photoLayer =>
        photoLayer.getIn(["config", "layout"])
      );
      const missingPhotoCount =
        allRegionsInPhotoLayers.count() -
        allRegionsInPhotoLayers.count(region => region.get("image"));
      if (!allRegionsInPhotoLayers.every(region => region.get("image"))) {
        message.warning(
          missingPhotoCount === 1
            ? "Please add a photo before proceeding"
            : ` Please add all ${Pluralize("photo", missingPhotoCount)} before proceeding`
        );
        return null;
      }
    }
    switch (newStep) {
      case STEPS.RECIPIENT_DETAILS: {
        if (currentStep === STEPS.PRODUCT_REAR) {
          const textLayers = this.props.item.get("layers").filter(l => {
            return l.get("type") === LAYER_TYPES.TEXT;
          });

          const everyTextLayerBlank = textLayers.every(layer => {
            return (
              layer.getIn(["config", "text"]) === "" ||
              layer.getIn(["config", "text"]) === layer.getIn(["config", "placeholderText"])
            );
          });

          if (
            everyTextLayerBlank &&
            !this.state.chosenInsideBlank &&
            !this.props.item.get("picCollage")
          ) {
            const btn = (
              <Button
                theme="dark-blue"
                label="Yes - Leave Blank"
                size="small"
                onClick={() =>
                  this.setState({ currentStep: newStep }, () => {
                    notification.close("check");
                  })
                }
              />
            );
            notification.open({
              message: "Leave Blank?",
              description: "Are you sure you want to leave this card blank on the inside?",
              duration: 0,
              key: "check",
              btn,
              placement: "topRight",
              icon: <InfoCircleOutlined style={{ color: "#4D5463" }} />,
              onClose: () => {
                console.log("Close");
              },
            });
            return null;
          }
        }
        break;
      }

      case STEPS.SELECT_POST_DATE: {
        if (currentStep === STEPS.RECIPIENT_DETAILS) {
          const address = this.props.item.get("address") || this.props.item.get("addressBookId");
          if (!address) {
            return this.setState({
              alert: {
                type: "info",
                showCancelButton: false,
                confirmButtonText: "OK",
                title: "Recipient Details",
                text: "Please add an address",
                onConfirm: this.clearAlert,
              },
            });
          }
        }
        break;
      }
      // no default
    }

    const updatedState = {
      currentStep: newStep,
      selectedLayerId: null,
      selectedRegionIndex: null,
    };

    // Make sure to clear canvas selection when transitioning between front/back
    if (
      this.state.currentStep === STEPS.PRODUCT_FRONT ||
      this.state.currentStep === STEPS.PRODUCT_REAR
    ) {
      this.clearSelection();
    }

    if (newStep === STEPS.PRODUCT_FRONT || newStep === STEPS.PRODUCT_REAR) {
      const activePageIndex =
        newStep === STEPS.PRODUCT_FRONT
          ? this.props.item.getIn(["pages", "front"])
          : this.props.item.getIn(["pages", "rear"]);
      updatedState.availableTools = Editor.getAvailableToolsForPage(
        this.props.item,
        activePageIndex
      );
    }
    this.setState(updatedState, () => {
      this.props.onChangeStep && this.props.onChangeStep(newStep);
      const product = productsByProductId.get(this.props.item.get("productId")).get("appKey");
      gtmEvent({ event: "webAppStage", additionalProps: { stage: newStep, product: product } });
    });
  };

  getHeaderPropsForStep = step => {
    const headerProps = {
      title: "",
      leftAction: null,
      rightAction: null,
    };

    const configEntry = EDITOR_CONFIG_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")];

    const isSinglePageWallArt = [
      PRODUCT_TYPE_IDS.CANVAS,
      PRODUCT_TYPE_IDS.PHOTO_TILE,
      PRODUCT_TYPE_IDS.FRAMED_PRINTS,
    ].includes(this.props.item.get("productTypeId"));

    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    const isPicCollage = Boolean(this.props.item.get("picCollage"));

    switch (step) {
      case STEPS.PRODUCT_FRONT: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].front;
        const frontPhotoLayer = this.props.item
          .get("layers")
          .find(
            layer =>
              layer.get("type") === LAYER_TYPES.PHOTO &&
              layer.get("page") === this.props.item.getIn(["pages", "front"])
          );
        const isNextButtonEnabled =
          !this.props.item.get("supports_collages") ||
          (this.props.item.get("supports_collages") &&
            frontPhotoLayer.getIn(["config", "layout"]).every(region => region.get("image")));
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? configEntry.pageTitles[pageNumber]
            : "";
        headerProps.leftAction = (
          <Button theme="muted" priority="tertiary" label="Cancel" onClick={this.props.onClose} />
        );

        let rightActionLabel = isSinglePageWallArt ? "Preview" : "Next";

        let rightActionOnClickHandler = () =>
          this.setCurrentStep(isSinglePageWallArt ? STEPS.RECIPIENT_DETAILS : STEPS.PRODUCT_REAR);

        if (
          (this.props.lastStep && this.props.lastStep === STEPS.PRODUCT_FRONT) ||
          isSinglePageWallArt
        ) {
          rightActionOnClickHandler = this.handleSave;
        }

        headerProps.rightAction = (
          <Button
            theme={isPicCollage ? "pc" : "muted"}
            priority={isPicCollage ? "primary" : "tertiary"}
            label={rightActionLabel || "Next"}
            disabled={!isNextButtonEnabled}
            onClick={rightActionOnClickHandler}
          />
        );
        break;
      }
      case STEPS.PRODUCT_REAR: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].rear;
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? configEntry.pageTitles[pageNumber]
            : "Card Back";
        headerProps.leftAction = (
          <Button
            theme={"muted"}
            priority={"tertiary"}
            label="Front"
            onClick={() => this.setCurrentStep(STEPS.PRODUCT_FRONT)}
          />
        );

        switch (this.props.item.get("productTypeId")) {
          case PRODUCT_TYPE_IDS.POSTCARD: {
            headerProps.rightAction = (
              <Button
                theme="dark-blue"
                priority="tertiary"
                label={this.props.saveButtonLabel}
                onClick={this.handleSave}
              />
            );
            break;
          }
          // For any non-postcard product, the next step after the product rear step is the envelope step
          default: {
            headerProps.rightAction = (
              <Button
                theme={isPicCollage ? "pc" : "dark-blue"}
                priority={isPicCollage ? "primary" : "tertiary"}
                label={isPicCollage ? "Next" : "Recipient"}
                onClick={() => this.setCurrentStep(STEPS.RECIPIENT_DETAILS)}
              />
            );
            break;
          }
        }
        break;
      }
      case STEPS.RECIPIENT_DETAILS: {
        const pageNumber = PAGES_PER_PRODUCT_TYPE[this.props.item.get("productTypeId")].envelope;
        const isPackMode = this.props.item.get("packMode");
        headerProps.title =
          configEntry && configEntry.pageTitles && configEntry.pageTitles[pageNumber]
            ? isPackMode
              ? "Shipping Address"
              : configEntry.pageTitles[pageNumber]
            : "Recipient Details";
        headerProps.leftAction = (
          <Button
            theme="muted"
            priority="tertiary"
            label="Back"
            onClick={() =>
              this.setCurrentStep(isSinglePageWallArt ? STEPS.PRODUCT_FRONT : STEPS.PRODUCT_REAR)
            }
          />
        );

        if (isGreetingCard) {
          headerProps.rightAction = (
            <Button
              theme={isPicCollage ? "pc" : "dark-blue"}
              priority={isPicCollage ? "primary" : "tertiary"}
              label={isPicCollage ? "Next" : "Post Date"}
              onClick={() =>
                isPicCollage ? this.handleSave() : this.setCurrentStep(STEPS.SELECT_POST_DATE)
              }
            />
          );
        } else {
          headerProps.rightAction = (
            <Button
              theme="dark-blue"
              priority="tertiary"
              label={this.props.saveButtonLabel}
              onClick={this.handleSave}
            />
          );
        }
        break;
      }
      case STEPS.SELECT_POST_DATE: {
        headerProps.title = "Postage Date";
        headerProps.leftAction = (
          <Button
            theme="muted"
            priority="tertiary"
            label="Back"
            onClick={() => this.setCurrentStep(STEPS.RECIPIENT_DETAILS)}
          />
        );
        headerProps.rightAction = (
          <Button
            theme="dark-blue"
            priority="tertiary"
            label={this.props.saveButtonLabel}
            onClick={this.handleSave}
          />
        );
        break;
      }
      // no default
    }

    return headerProps;
  };

  filterDate = date => {
    date = moment(date);
    const isWeekDay = date.isoWeekday() < 6;

    return isWeekDay;
  };

  handleDatePickerChange = date => {
    const FUTURE_DATE_WARNING_DAYS = 60;
    // If selected date is more than x days in the future, show a courtesy warning
    if (
      Math.abs(
        moment()
          .startOf("day")
          .diff(moment(date).startOf("day"), "days")
      ) > FUTURE_DATE_WARNING_DAYS
    ) {
      this.setState({
        alert: {
          type: "warning",
          showCancelButton: false,
          confirmButtonText: "Yes",
          title: "Future Date",
          text: `${moment(date).format(
            "D MMM YYYY"
          )} is quite far in the future, are you sure you want this card to be posted on this date?`,
          onConfirm: this.clearAlert,
        },
      });
    }
    this.props.onChangePostDate(date.format());
  };

  handleChangeSelectedVariant = async variantIndex => {
    this.setState({
      isLoading: true,
    });

    const promises = this.props.item
      .get("layers")
      .map(async layer => {
        if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
          const imgUrl = generateS3AssetUrlFromKey(
            layer.getIn(["config", "s3_keys", variantIndex])
          );
          try {
            const image = await preloadImage(imgUrl);
            return image;
          } catch (err) {
            console.log("Error while preloading image:", err);
          }
        }

        return null;
      })
      .filter(p => p);

    await Promise.all(promises);

    this.props.item.get("layers").forEach(layer => {
      if (layer.get("type") === LAYER_TYPES.TEXT && layer.getIn(["config", "colors"])) {
        this.props.onChangeTextConfig(layer.get("id"), {
          color: layer.getIn(["config", "colors", variantIndex]),
        });
      }

      if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
        this.props.onChangeSelectedGraphic(
          layer.get("id"),
          layer.getIn(["config", "s3_keys", variantIndex])
        );
      }
    });

    this.setState({
      isLoading: false,
    });
  };

  renderFrontOrRearStep = () => {
    const activePageIndex =
      this.state.currentStep === STEPS.PRODUCT_FRONT
        ? this.props.item.getIn(["pages", "front"])
        : this.props.item.getIn(["pages", "rear"]);
    const activeLayer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);
    let helpText;
    let cropText;
    let footerContent;
    let bottomCTAContent;

    const width = this.props.item.getIn(["productDimensions", "width"])
    const height = this.props.item.getIn(["productDimensions", "height"])

    const isSquare = width === height
    const isPortrait = width < height
    const isLandscape = width > height

    let orientation = "square"
    if (isPortrait) {
      orientation = "portrait"
    } else if (isLandscape) {
      orientation = "landscape"
    }

    const toolNavbarItems = this.state.availableTools.map(tool => {
      const isCollageAndSquare = isSquare && tool.id === "COLLAGE";
      return (
        <BottomNavbarItem
          key={tool.id}
          active={this.state.activeTool === tool.id}
          label={tool.label}
          onClick={() => this.activateTool(tool.id)}
        >
          {tool.imageIcon ? (
            <img
              alt={tool.imageIcon}
              src={`${process.env.PUBLIC_URL}/images/${tool.imageIcon}.png`}
              style={{width: '28px'}}
            />
          ) : (
            tool.id === "BORDER_PRINT_WIDTHS" ? (
              <Icon name={`${tool.icon}-${orientation}`} />
            ) : (
              <Icon name={isCollageAndSquare ? tool.altIcon : tool.icon} />
            )
          )}

        </BottomNavbarItem>
      );
    });

    let activeToolMarkup;

    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      switch (this.state.activeTool) {
        case TOOLS.STYLE.id: {
          const graphicLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.GRAPHIC && l.get("page") === activePageIndex);

          // const textLayerForPage = this.props.item
          //   .get('layers')
          //   .find(l => l.get('type') === LAYER_TYPES.TEXT && l.get('page') === activePageIndex);

          const graphicOptions = this.props.item.get("designOptions").map((key, index) => {
            return (
              <EditorImageOption
                key={key}
                src={generateS3AssetUrlFromKey(key)}
                active={
                  graphicLayerForPage.getIn(["config", "s3_key"]) ===
                  graphicLayerForPage.getIn(["config", "s3_keys", index])
                }
                onClick={() => this.handleChangeSelectedVariant(index)}
              />
            );
          });

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="graphic-options" center={true}>
                {graphicOptions}
              </HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.BORDER_PRINT_WIDTHS.id: {
          const borderWidthOptions = [
            { "small": 12 },
            { "medium": 22 },
            { "large": 32 },
            { "x-large": 42 }
          ];
          const product = productsByProductId.get(this.props.item.get("productId"));
          const currentProductOptions = this.props.item.getIn(["product_options"]);
          const currentBorderPrintWidth = this.props.item.getIn(["product_options", "borderPrint", "width"])
          let orientation = "square"
          let height = 45
          let width = 45
          if(product.get("width") > product.get("height")){
            //height = height/1.5
            orientation = "landscape"
          } else if (product.get("width") < product.get("height")){
            //width = width/1.5
            orientation = "portrait"
          }
          const borderOptions = borderWidthOptions.map(borderWidth => {
            const [key, value] = Object.entries(borderWidth)[0];
            const iconName = `${orientation}-border-print-${key}`
            //const borderWidthAsPercentage = ["square", "landscape"].includes(orientation) ? (value/product.get('width'))*width : (value/product.get('height'))*height
            // console.log("value", value)
            // console.log("product.get('width')", product.get('width'))
            // console.log("width", width)
            // console.log("borderWidthAsPercentage", borderWidthAsPercentage)
            return (
              <div
                key={key}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  border: `2px solid transparent`,
                  borderColor: currentBorderPrintWidth === value ? '#ff5a5f' : 'transparent',
                  //boxSizing: 'border-box',
                  //margin: '5px',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => this.props.onChangeProductOptions({
                  ...currentProductOptions.toJS(),
                  borderPrint: { ...currentProductOptions.get("borderPrint").toJS(), width: value }
                })}
              >
                <Icon name={iconName} size="x-large"/>
              </div>

            )
            // return (
            //   <button
            //     style={{
            //       width : '20%',
            //       border: `${value/5}px solid grey`,
            //       borderColor: currentBorderPrintWidth === value ? 'red' : 'grey',
            //       color: currentBorderPrintWidth === value ? 'red' : 'black',
            //       margin: '5px',
            //       padding: '5px 10px',
            //       fontSize: '11px',
            //       background: 'white'
            //     }}
            //     key={key}
            //     onClick={() => this.props.onChangeProductOptions({
            //       ...currentProductOptions.toJS(),
            //       borderPrint: { ...currentProductOptions.get("borderPrint").toJS(), width: value }
            //     })}
            //   >
            //     {key}<br/>

            //     <span style={{fontSize: '9px'}}>({value})</span>
            //   </button>
            // );
          });

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="border-width-options" center={true} spaced={true}>
                {borderOptions}
              </HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.BORDER_PRINT_COLOURS.id: {
          const currentProductOptions = this.props.item.getIn(["product_options"]);
          const currentBorderPrintColour = this.props.item.getIn(["product_options", "borderPrint", "colour"])
          const borderColourOptions = retroColours.map((colour, index) => {
            return (
              <div
                onClick={() => this.props.onChangeProductOptions({
                  ...currentProductOptions.toJS(),
                  borderPrint: { ...currentProductOptions.get("borderPrint").toJS(), colour: colour.hex }
                })}
                className={`swatch-container ${currentBorderPrintColour === colour.hex ? 'active' : ''}`}>
                <div
                  className={`swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                  style={{ backgroundColor: colour.hex, color: colour?.textColor,width: '38px', height: '38px' }}
                >
                </div>
              </div>
          )})

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="border-colour-options">
                {borderColourOptions}
              </HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.FILTERS.id: {
          const imageForFilterOptions =
            activeLayer &&
            activeLayer.get("type") === LAYER_TYPES.PHOTO &&
            activeLayer.getIn(["config", "layout", this.state.selectedRegionIndex, "image"]);

          if (!imageForFilterOptions) {
            helpText = "Please select an image to apply effects.";
            break;
          }

          const filterOptions = Object.keys(PHOTO_FILTERS).map(filter => (
            <FilterPreview
              key={filter}
              filterId={filter}
              imageSrc={imageForFilterOptions && imageForFilterOptions.getIn(["src", "lowResUrl"])}
              onClick={this.setFilterForPhotoRegion}
            />
          ));

          activeToolMarkup = (
            <div className="editor__tool" key="active-tool">
              <HorizontalScroller key="effect-options">
                <FilterPreview
                  key="no-filter"
                  filterId={null}
                  imageSrc={
                    imageForFilterOptions && imageForFilterOptions.getIn(["src", "lowResUrl"])
                  }
                  onClick={this.setFilterForPhotoRegion}
                />
                {filterOptions}
              </HorizontalScroller>
            </div>
          );
          break;
        }
        case TOOLS.BORDER.id: {
          const photoLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.PHOTO && l.get("page") === activePageIndex);

          const borderConfig = photoLayerForPage.getIn(["config", "border"]);

          activeToolMarkup = [
            photoLayerForPage.getIn(["config", "border", "style"]) && (
              <div
                className="editor__tool editor__tool--padded-for-slider"
                key="border-thickness-slider"
              >
                <Slider
                  min={0.05}
                  max={0.2}
                  step={0.005}
                  value={photoLayerForPage.getIn(["config", "border", "thickness"])}
                  onChange={val =>
                    this.props.onChangeBorderThickness(photoLayerForPage.get("id"), val)
                  }
                />
              </div>
            ),
            <div className="editor__tool" key="border-options">
              <HorizontalScroller>
                <EditorFillOption
                  type="image"
                  src={`${process.env.PUBLIC_URL}/images/no-option.svg`}
                  onClick={() => this.props.onChangeBorderStyle(photoLayerForPage.get("id"), null)}
                  active={borderConfig.get("style") === null}
                />
                {FILL_OPTIONS.map((option, index) => (
                  <EditorFillOption
                    key={index}
                    type={option.type}
                    color={option.color}
                    src={option.src}
                    active={
                      borderConfig &&
                      borderConfig.get("style") &&
                      (isEqual(borderConfig.get("style").toJS(), {
                        type: "color",
                        color: option.color,
                      }) ||
                        isEqual(borderConfig.get("style").toJS(), {
                          type: "image",
                          src: option.src,
                        }))
                    }
                    onClick={() =>
                      this.props.onChangeBorderStyle(photoLayerForPage.get("id"), option)
                    }
                  />
                ))}
              </HorizontalScroller>
            </div>,
          ];
          break;
        }
        case TOOLS.COLLAGE.id: {
          const photoLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.PHOTO && l.get("page") === activePageIndex);

          const photoCount = photoLayerForPage
            .getIn(["config", "layout"])
            .count(region => region.get("image"));
          const currentLayout = photoLayerForPage.getIn(["config", "layout"]);
          const onlyLayoutProperties = currentLayout.map(region => ({
            xOffset: region.get("xOffset"),
            yOffset: region.get("yOffset"),
            width: region.get("width"),
            height: region.get("height"),
          }));

          let layoutTemplates = squareLayoutTemplates;
          if (
            this.props.item.getIn(["productDimensions", "width"]) >
            this.props.item.getIn(["productDimensions", "height"])
          ) {
            layoutTemplates = landscapeLayoutTemplates;
          } else if (
            this.props.item.getIn(["productDimensions", "width"]) <
            this.props.item.getIn(["productDimensions", "height"])
          ) {
            layoutTemplates = portraitLayoutTemplates;
          }

          const isCollagePrint = this.props.item.get("isCollagePrint");
          let layouts = isCollagePrint
            ? Object.entries(layoutTemplates).map(([key, template]) =>
                generateLayout({
                  layout: template,
                  gutter: 3,
                  product: {
                    width: this.props.item.getIn(["productDimensions", "width"]),
                    height: this.props.item.getIn(["productDimensions", "height"]),
                  },
                  id: key,
                })
              )
            : LAYOUTS;

          activeToolMarkup = (
            <div className="editor__tool" key="border-options">
              <HorizontalScroller>
                {layouts.map((layout, index) => (
                  <EditorCollageOption
                    key={index}
                    ratio={
                      this.props.item.getIn(["productDimensions", "width"]) /
                      this.props.item.getIn(["productDimensions", "height"])
                    }
                    layout={layout}
                    active={isEqual(layout, onlyLayoutProperties.toJS())}
                    onClick={() => this.handleChangeLayout(photoLayerForPage.get("id"), layout)}
                  />
                ))}
              </HorizontalScroller>
            </div>
          );

          helpText =
            photoCount > 0
              ? photoCount === 1
                ? "Tap your photo to replace it"
                : isCollagePrint
                ? "Tap a photo to replace it"
                : "Tap a photo to replace it"
              : "Tap the camera icon to add a photo";
          break;
        }
        case TOOLS.TEXT.id: {
          const isActiveLayerTextLayer =
            activeLayer && activeLayer.get("type") === LAYER_TYPES.TEXT;
          if (isActiveLayerTextLayer) {
            activeToolMarkup = null;
          }
          break;
        }
        case TOOLS.CROP.id: {
          const allPhotoLayersForPage = this.props.item
            .get("layers")
            .filter(
              l =>
                l.get("type") === LAYER_TYPES.PHOTO &&
                l.get("page") === this.props.item.getIn(["pages", "front"])
            );
          const allRegionsInPhotoLayers = allPhotoLayersForPage.flatMap(photoLayer =>
            photoLayer.getIn(["config", "layout"])
          );
          const populatedPhotoCount = allRegionsInPhotoLayers.count(region => region.get("image"));

          if (populatedPhotoCount === 0) {
            cropText = "Tap the camera icon to add a photo";
          } else {
            cropText =
              populatedPhotoCount === 1
                ? "Tap your photo to crop and zoom to fit"
                : "Tap a photo to crop and zoom to fit";
          }

          activeToolMarkup = (
            <div className="editor__tool editor__tool--padded" key="active-tool">
              <small>{cropText}</small>
            </div>
          );
          break;
        }
        case TOOLS.DEBUG.id: {
          activeToolMarkup = (
            <div className="editor__tool editor__tool--padded" key="active-tool">
              <small>Nothing here yet.</small>
            </div>
          );
          break;
        }
        case TOOLS.GRID_OPTIONS.id: {
          // const activeRenderer = this.state.currentStep === STEPS.PRODUCT_FRONT ? this.frontRenderer : this.backRenderer;
          // const canvasDimensions = activeRenderer.getDimensions()

          const photoLayerForPage = this.props.item
            .get("layers")
            .find(l => l.get("type") === LAYER_TYPES.PHOTO && l.get("page") === activePageIndex);

          const regionBorderRadiusConfig = photoLayerForPage.getIn([
            "config",
            "regionBorderRadius",
          ]);
          const regionBorderWidthConfig = photoLayerForPage.getIn(["config", "regionBorderWidth"]);

          // const largestWidthRegionInPhoto = photoLayerForPage.getIn(["config","layout"]).sortBy((layout) => layout.get('width')).last().get('width');
          // const largestHeightRegionInPhoto = photoLayerForPage.getIn(["config","layout"]).sortBy((layout) => layout.get('height')).last().get('height');

          // let largestRegionDimension = largestWidthRegionInPhoto
          // let canvasDimension = canvasDimensions.width

          // if (largestWidthRegionInPhoto < largestHeightRegionInPhoto){
          //   largestRegionDimension = largestHeightRegionInPhoto
          //   canvasDimension = canvasDimensions.height
          // }

          const maxRadiusValue = 50; //Math.ceil((largestRegionDimension * canvasDimension)/2);
          const maxBorderWidth = 10;

          activeToolMarkup = (
            <div
              className="editor__tool editor__tool--padded-for-slider"
              key="border-radius-thickness-slider"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <span
                  style={{
                    width: "40%",
                    marginRight: "20px",
                    textAlign: "left",
                    maxWidth: "80px",
                  }}
                >
                  Radius:
                </span>
                <Slider
                  min={0}
                  max={maxRadiusValue}
                  step={1}
                  value={regionBorderRadiusConfig}
                  onChange={val => this.handleBorderRadiusChange(photoLayerForPage.get("id"), val)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "40%",
                    marginRight: "20px",
                    textAlign: "left",
                    maxWidth: "80px",
                  }}
                >
                  Spacing:
                </span>
                <Slider
                  min={0}
                  max={maxBorderWidth}
                  step={0.1}
                  value={regionBorderWidthConfig}
                  onChange={val => this.handleBorderWidthChange(photoLayerForPage.get("id"), val)}
                />
              </div>
            </div>
          );
        }
        // no default
      }
    }

    const rendererProps = {
      debug: false,
      item: this.props.item.toJS(),
      dragDropMode: this.props.dragDropMode,
      currentEditorStep: this.state.currentStep,
      activeTool: this.state.activeTool,
      selectedLayerId: this.state.selectedLayerId,
      selectedRegionIndex: this.state.selectedRegionIndex,
      onTextContentChange: this.props.onTextContentChange,
      onTextPositionChange: this.props.onTextPositionChange,
      onSelectRegionInPhotoLayer: this.handleSelectRegionInPhotoLayer,
      onMoveImageInRegionInPhotoLayer: this.props.onMoveImageInRegionInPhotoLayer,
      onSwapRegionImages: this.props.onSwapRegionImages,
      onClearLayerSelection: this.handleClearLayerSelection,
      onClickTextLayer: this.handleClickTextLayer,
      onDeselectTextLayer: this.handleDeselectTextLayer,
      onDeleteTextLayerContents: id => this.props.onChangeTextConfig(id, { text: "" }),
      onStartEditingTextLayer: this.openTextEditorForLayerId,
      onClickSignatureLayer: this.handleClickSignatureLayer,
      onClickAddressLayer: this.handleClickAddressLayer,
    };

    const isSinglePageWallArt = [
      PRODUCT_TYPE_IDS.CANVAS,
      PRODUCT_TYPE_IDS.PHOTO_TILE,
      PRODUCT_TYPE_IDS.FRAMED_PRINTS,
    ].includes(this.props.item.get("productTypeId"));
    const isCanvas = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
    const isPostcard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.POSTCARD;
    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;
    //const isSquareGreetingCard = productsByProductId.get(this.props.item.get("productId")).get("appKey") === "GREETING_SQUARE"

    const dimensions = this.props.item.get("productDimensions").toJS();

    const productRatio =
      (dimensions.width + dimensions.bleed.left + dimensions.bleed.right) /
      (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom);

    const productSlug =
      isSinglePageWallArt &&
      productsByProductId.get(this.props.item.get("productId")).get("url_slug");

    const isPackMode = this.props.item.get("packMode");
    const isPicCollage = Boolean(this.props.item.get('picCollage'))
    //const PACKS = isGreetingCard ? GC_PACK_STEPS : PACK_STEPS;

    // const packOptions = PACKS.slice(1).map(packSize => {
    //   const price = getPricingSchemeForProductAndQuantity({
    //     productId: this.props.item.get("productId"),
    //     quantity: packSize,
    //     currency: "GBP",
    //   });
    //   return { packSize: packSize, price: price.get("cost") * packSize };
    // });

    const packOptions = getPackPricingSchemesForProduct({
      productId: this.props.item.get("productId"),
      currency: this.props.currency,
    })
      .map(packPrice => {
        return {
          packSize: packPrice.get("pack_quantity"),
          price: packPrice.get("cost"),
          schemeId: packPrice.get("id"),
          perCard: packPrice.get("cost") / packPrice.get("pack_quantity"),
          quantity: packPrice.get("min_qty"),
        };
      })
      .toJS();

    const frontRenderer = (
      <HtmlRenderer
        page={this.props.item.getIn(["pages", "front"])}
        ref={e => (this.frontRenderer = e)}
        onSelectTextPlaceholderLayer={this.openTextEditorForLayerId}
        mask={isSinglePageWallArt && MASKS[productSlug].canvasEditor}
        showBleed={isGreetingCard}
        {...rendererProps}
        showBorderCanvas={this.props.item && !this.props.item.get("isBorderPrint")}
      />
    );

    const backRenderer = ( //should showBleed here
      <HtmlRenderer
        page={this.props.item.getIn(["pages", "rear"])}
        ref={e => (this.backRenderer = e)}
        showBleed={isGreetingCard}
        {...rendererProps}
      />
    );

    if (this.state.currentStep === STEPS.PRODUCT_FRONT) {
      footerContent = <BottomNavBar>{toolNavbarItems}</BottomNavBar>;
    } else {
      footerContent = (
        <div>
          {isPostcard && this.state.currentStep === STEPS.PRODUCT_REAR && (
            <div key="info-button-container" className="editor__info-button-container">
              <Button
                size="default"
                theme="dark-blue"
                priority="secondary"
                label="Learn More"
                onClick={this.showInfoModal}
                dataGtmElement={"Learn More Button"}
              />
            </div>
          )}
          <table>
            <tbody>
              {this.props.products.map(product => (
                <tr
                  key={product.get("id")}
                  className={classNames("editor__product-size-option", {
                    "editor__product-size-option--selected":
                      this.props.item.get("productId") === product.get("id"),
                  })}
                  htmlFor={`product-option-${product.get("id")}`}
                  onClick={() => this.props.onChangeProductId(product.get("id"))}
                >
                  {true && (
                    <td className="editor__product-size-option__preview">
                      <HtmlRenderer
                        page={this.props.item.getIn(["pages", "front"])}
                        item={this.props.item.toJS()}
                        showBleed={isGreetingCard}
                        width={
                          [5, 28, 76, 199].includes(product.get("id"))
                            ? 85
                            : [201, 199].includes(product.get("id"))
                            ? 45
                            : 120
                        }
                        isInteractive={false}
                        onClick={() => this.props.onChangeProductId(product.get("id"))}
                      />
                    </td>
                  )}
                  <td className="editor__product-size-option__info">
                    <div className="editor__product-size-option-title">
                      {product.get("name")}
                      {/* {isPackMode ? " Pack" : ""} */}
                    </div>
                    <div className="editor__product-size-option-subtitle">
                      {product.get("width")}mm × {product.get("height")}mm
                    </div>
                  </td>
                  <td className="text-right">
                    {!isPackMode && (
                      <div className="editor__product-size-option__price">
                        {[PRODUCT_TYPE_IDS.ANNOUNCEMENT, PRODUCT_TYPE_IDS.INVITATION].includes(
                          this.props.item.get("productTypeId")
                        ) ? (
                          <div>
                            <Currency
                              amount={
                                product.getIn(["pricingScheme", "cost"]) *
                                this.props.item.get("quantity")
                              }
                            />
                            {/* <br/>
                            (<Currency amount={product.getIn(["pricingScheme", "cost"])} /> per card) */}
                          </div>
                        ) : (
                          <Currency amount={product.getIn(["pricingScheme", "cost"])} />
                        )}
                      </div>
                    )}
                    {this.props.products.size > 1 && (
                      <div className="pretty p-default p-round">
                        <input
                          type="radio"
                          checked={this.props.item.get("productId") === product.get("id")}
                          id={`product-option-${product.get("id")}`}
                          readOnly
                        />
                        <div className="state">
                          <label></label>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {this.props.item.get("productTypeId") !== PRODUCT_TYPE_IDS.POSTCARD && !isPackMode && (
                <tr>
                  <td colSpan={3}>
                    <div className="editor__quantity-picker">
                      <Grid.Row alignVertically>
                        <Grid.Column className="text-right pr-default">
                          <div className="editor__quantity-picker-amount">
                            {[PRODUCT_TYPE_IDS.ANNOUNCEMENT, PRODUCT_TYPE_IDS.INVITATION].includes(
                              this.props.item.get("productTypeId")
                            )
                              ? `${this.props.item.get("quantity") / 8} Pack${
                                  this.props.item.get("quantity") === 8 ? "" : "s"
                                } (${this.props.item.get("quantity")} cards)`
                              : `Quantity: ${this.props.item.get("quantity")}`}
                          </div>
                        </Grid.Column>
                        <Grid.Column className="text-right" size="0 auto">
                          <div className="editor__quantity-picker-controls">
                            <AntButton.Group>
                              <AntButton
                                size="default"
                                type={"primary"}
                                ghost
                                active={"true"}
                                onClick={this.props.onDecreaseQuantity}
                                style={{ borderColor: "#4b5566", color: "#4b5566" }}
                              >
                                <MinusOutlined />
                              </AntButton>
                              <AntButton
                                size="default"
                                onClick={this.props.onIncreaseQuantity}
                                type={"primary"}
                                ghost
                                style={{ borderColor: "#4b5566", color: "#4b5566" }}
                              >
                                <PlusOutlined />
                              </AntButton>
                            </AntButton.Group>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
      if (this.props.item.get("templateId")) {
        const templateConfig = TEMPLATES[this.props.item.get("templateId")];
        if (templateConfig.onlyProductIds && templateConfig.onlyProductIds.includes(5)) {
          footerContent = "";
        }
      }
      if (isPicCollage) {
        footerContent = "";
      }
    }

    let cropperRatio = 1;
    if (activeLayer && this.state.selectedLayerId && this.state.selectedRegionIndex !== null) {
      if (activeLayer.get("type") === LAYER_TYPES.PHOTO) {
        const activeRenderer =
          this.state.currentStep === STEPS.PRODUCT_FRONT ? this.frontRenderer : this.backRenderer;
        if (!activeRenderer) {
          return;
        }
        if (activeRenderer.layerRefs[activeLayer.get("id")]) {
          const realDimensions = activeRenderer.layerRefs[activeLayer.get("id")].getDimensions();

          const photoLayer = this.props.item
            .get("layers")
            .find(l => l.get("id") === this.state.selectedLayerId);
          const regionInPhoto = photoLayer.getIn([
            "config",
            "layout",
            this.state.selectedRegionIndex,
          ]);

          cropperRatio =
            (realDimensions.width * regionInPhoto.get("width")) /
            (realDimensions.height * regionInPhoto.get("height"));
        } else {
          console.log("No active renderer for layer", activeLayer.get("id"));
        }
      }
    }

    const productDimensions = this.props.item.get("productDimensions");
    const imageForCropper =
      activeLayer &&
      activeLayer.getIn(["config", "layout", this.state.selectedRegionIndex, "image"]);
    if (!imageForCropper) {
      console.log("No image for cropper");
    }
    let signatureRatio = null;

    if (this.state.isSignatureEditorVisible && activeLayer) {
      // We subtract a square (dimensions: rectHeight x rectHeight) from the rect dimensions to get the signature ratio
      const signatureDimensions = {
        width: productDimensions.get("width") * activeLayer.getIn(["config", "rect", "width"]),
        height: productDimensions.get("height") * activeLayer.getIn(["config", "rect", "height"]),
      };

      signatureRatio = this.state.isSignatureEditorVisible
        ? (signatureDimensions.width - signatureDimensions.height) / signatureDimensions.height
        : null;
    }

    let uploadModal = null;

    const recentlyUploaded = JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
    const anyRecentlyUploaded = recentlyUploaded && recentlyUploaded.length > 0;

    switch (this.state.uploadMode) {
      case UPLOAD_MODES.DEBUG:
        uploadModal = (
          <LocalUploadModal
            key="debug-upload-modal"
            isOpen={this.state.isUploadModalVisible}
            onClose={this.closeUploadModal}
            images={PLACEHOLDER_IMAGES}
            onSelectImage={this.handleSelectDebugUploadImage}
          />
        );
        break;
      case UPLOAD_MODES.UPLOADCARE:
        uploadModal = (
          <Modal
            key="uploadcare-selection-modal"
            isOpen={this.state.isUploadModalVisible}
            onClose={this.closeUploadModal}
            title={this.state.customUploadTitle ? this.state.customUploadTitle : "Upload Photo"}
          >
            <MainContent scrollable={false} padded alignedTop>
              <div className="restricted-width-modal-content">
                {this.state.customUploadDescription && <p>{this.state.customUploadDescription}</p>}
                <Button
                  block
                  label="From Device"
                  icon="phone"
                  theme="dark-blue"
                  onClick={() => this.pickImageFromUploadcare("file")}
                  dataGtmElement="Photo Upload Source"
                />
                {anyRecentlyUploaded && (
                  <Button
                    block
                    className="btn--recent"
                    label="Recently Uploaded"
                    icon="upload"
                    onClick={() => this.pickImageFromUploadcare("favorites")}
                    dataGtmElement="Photo Upload Source"
                  />
                )}
                {/* <Button
                  block
                  icon="facebook"
                  className="btn--facebook"
                  label="Facebook"
                  onClick={() => this.pickImageFromUploadcare("facebook")}
                  dataGtmElement="Photo Upload Source"
                />
                <Button
                  block
                  icon="instagram"
                  className="btn--instagram"
                  label="Instagram"
                  onClick={() => this.pickImageFromUploadcare("instagram")}
                  dataGtmElement="Photo Upload Source"
                /> */}
                {/* <Button
                  block
                  icon="gdrive"
                  className="btn--google"
                  label="Google Drive"
                  onClick={() => this.pickImageFromUploadcare("gdrive")}
                /> */}
                {/* <Button
                  block
                  icon="dropbox"
                  className="btn--dropbox"
                  label="Dropbox"
                  onClick={() => this.pickImageFromUploadcare("dropbox")}
                />
                <Button
                  block
                  icon="flickr"
                  className="btn--flickr"
                  label="Flickr"
                  onClick={() => this.pickImageFromUploadcare("flickr")}
                /> */}
              </div>
            </MainContent>
          </Modal>
        );
        break;
      // no default
    }

    let infoContent = isCanvas
      ? getAppValueByKey("CANVAS_INFO")
        ? getAppValueByKey("CANVAS_INFO").get("value")
        : ""
      : getAppValueByKey("PRICES")
      ? getAppValueByKey("PRICES").get("value")
      : "";
    const canvasImage = imageForCropper
      ? imageForCropper.getIn(["src", "lowResUrl"])
      : "https://unsplash.it/560/280?image=1040";
    const infoModal = (
      <Modal
        key="info-modal"
        isOpen={this.state.isInfoModalVisible}
        onClose={this.closeInfoModal}
        title="Information"
        animated
      >
        <MainContent scrollable padded>
          {isCanvas && (
            <div
              className="canvas-preview black"
              style={{ display: "none", backgroundImage: `url(${canvasImage})` }}
            >
              <div className="top" style={{ backgroundImage: `url(${canvasImage})` }}></div>
              <div className="right" style={{ backgroundImage: `url(${canvasImage})` }}></div>
            </div>
          )}
          <div style={{ display: "block", width: "100%", height: "100%" }} key="help-text">
            <iframe
              title={"Information"}
              srcDoc={infoContent}
              frameBorder="0"
              style={{ border: "0", width: "100%", height: "100%" }}
            />
          </div>
        </MainContent>
      </Modal>
    );

    const shippingModal = (
      <AntModal
        style={{ top: 20, height: "500px" }}
        bodyStyle={{ overflowY: "scroll" }}
        visible={this.state.isShippingInfoModalVisible}
        footer={[
          <AntButton key="submit" type="default" onClick={this.closeShippingInfoModal}>
            OK
          </AntButton>,
        ]}
      >
        <div style={{ overfow: "scroll" }}>
          <h3 style={{ textAlign: "center" }}>US Postcard Shipping</h3>
          <p>
            Please be aware, we generally print & ship postcards to the US within one working day,
            sometimes two depending upon the time of your order. <br />
            <br />
            Our postcards are then mailed first class and at this point we have no control over the
            speed of delivery by USPS. <br />
            <br />
            Just like postcards you buy at the local mall and put a stamp on, USPS treat postcard
            mail as low priority mail - please considor this if your require a postcard for
            time-sensitive occasions.
          </p>
        </div>
      </AntModal>
    );

    // const allProductsForProductType = productsByProductTypeId.get(
    //   this.props.item.get("productTypeId")
    // );
    const allProductsForProductType = productsByProductTypeId
      .get(this.props.item.get("productTypeId"))
      .filter(
        product => product.get("supports_collages") === this.props.item.get("supports_collages")
      );
    const sizeSelectionModal = (
      <Modal
        isOpen={this.state.isSizeSelectionModalVisible}
        onClose={() => this.setState({ isSizeSelectionModalVisible: false })}
      >
        <MainContent scrollable padded>
          <p>Please select a size option for your product below.</p>
          <div className="editor__size-options">
            {allProductsForProductType.map(product => {
              const isSelectedProduct = this.props.item.get("productId") === product.get("id");
              return (
                <ProductPreview
                  key={product.get("id")}
                  product={product}
                  isHighlighted={isSelectedProduct}
                  onClick={() => this.changeEditorItemProduct(product)}
                />
              );
            })}
          </div>
        </MainContent>
      </Modal>
    );

    //const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;
    const isOnFront = this.state.currentStep && this.state.currentStep === STEPS.PRODUCT_FRONT;
    const areOptionsAvailable = isGreetingCard; //&& isGBP; // TODO only square?

    const glossOn = this.props.item.getIn(["product_options", "card_finish"]) === "gloss";
    //const currentOptions = this.props.item.get("product_options") && this.props.item.get("product_options").toJS().card_finish;

    const isPhotoTile = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE;

    const photoTileInfoModal = (
      <AntModal
        style={{ top: 20, height: "500px" }}
        bodyStyle={{ overflowY: "scroll" }}
        visible={isPhotoTile && this.state.isInfoModalVisible}
        onOk={this.closeInfoModal}
        onCancel={this.closeInfoModal}
        footer={[
          <AntButton key="submit" type="default" onClick={this.closeInfoModal}>
            Done
          </AntButton>,
        ]}
      >
        <div style={{ overfow: "scroll" }}>
          <h3 style={{ textAlign: "center" }}>Photo Tile Information</h3>
          <h4 style={{ textAlign: "left" }}>Description</h4>
          <p>TODO...</p>
          <h4 style={{ textAlign: "left" }}>Pricing</h4>
          <p>TODO...</p>
          <h4 style={{ textAlign: "left" }}>Shipping</h4>
          <p>TODO...</p>
        </div>
      </AntModal>
    );

    /*const emptyInsideModal = (
      <AntModal
        style={{ top: 20, width: '80vw' }}
        bodyStyle={{ overflowY: 'scroll'}}
        visible={this.state.emptyInsideModalOpen}
        onOk={this.closeEmptyInsideModal}
        onCancel={this.closeEmptyInsideModal}
        width={600}
        footer={[]}
        >
          <div style={{overfow: 'scroll'}}>
            <h3 style={{textAlign: 'center', fontSize: '16px'}}>Inside Options</h3>
            <div style={{marginTop: '20px'}}>
              <div style={{display: 'flex', marginBottom: '20px', alignItems: 'center'}}>
                <div style={{width: '50%'}}>
                  <img
                    alt="Leave Blank"
                    src={`${process.env.PUBLIC_URL}/images/leave-blank.png`}
                    />
                </div>
                <div style={{width: '50%', marginLeft: '20px'}}>
                  <h3 style={{fontSize:'13px', marginBottom: '5px'}}>Leave Blank</h3>
                  <p style={{fontSize:'12px'}}>Blank inside with a blank envelopes included. Perfect for handwritten messages</p>
                </div>
              </div>
              <Button
                block
                size="small"
                label="Leave Blank"
                priority="secondary"
                theme="dark-blue"
                onClick={this.leaveInsideBlank}
              />
              <hr style={{margin : '20px 0px 40px 0px'}}/>
              <div style={{display: 'flex', marginBottom: '20px', alignItems: 'center'}}>
                <div style={{width: '50%'}}>
                  <img
                    alt="Add Message"
                    src={`${process.env.PUBLIC_URL}/images/add-message.png`}
                  />
                </div>
                <div style={{width: '50%', marginLeft: '20px'}}>
                  <h3 style={{fontSize:'13px', marginBottom: '5px'}}>Add A Message</h3>
                  <p style={{fontSize:'12px'}}>Add a mesage to the inside of your cards. Note, every card in your pack will have the same message</p>
                </div>
              </div>
              <Button
                block
                size="small"
                label="Add A Message"
                priority="secondary"
                theme="dark-blue"
                onClick={()=> this.closeEmptyInsideModal()}
              />
            </div>
          </div>
      </AntModal>
    ) */

    const templateModal = (
      <AntModal
        style={{ top: 20, width: "80vw" }}
        bodyStyle={{ overflowY: "scroll" }}
        visible={this.state.templateModalOpen}
        onOk={this.closeTemplateModal}
        onCancel={this.closeTemplateModal}
        width={600}
        footer={
          [
            // <AntButton key="submit" type="default" onClick={this.closeTemplateModal}>
            //   Close
            // </AntButton>,
          ]
        }
      >
        <div style={{ overfow: "scroll" }}>
          {/* <h3 style={{textAlign: 'center', fontSize: '14px'}}>Send a little love this Feb with a #CloseMoment PostSnap!</h3> */}
          <div
            style={{ fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: this.state.templateModalMessage }}
          />
          <br />
          <img
            style={{
              boxShadow: "4px 4px 12px 3px #e6e6e6",
              border: "1px solid #fbfbfb",
            }}
            alt={"Mockup 1"}
            src={`https://eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_front.jpg`}
          />
          <br />
          <img
            style={{
              padding: "10px",
              boxShadow: "4px 4px 12px 3px #e6e6e6",
              marginTop: "20px",
              border: "1px solid #fbfbfb",
            }}
            alt={"Mockup 2"}
            src={`https://eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_back.jpg`}
          />
        </div>
      </AntModal>
    );

    const packsModal = (
      <AntModal
        style={{ top: 20, height: "500px" }}
        bodyStyle={{ overflowY: "scroll" }}
        visible={this.state.isPacksModalOpen}
        onOk={this.closePacksModal}
        onCancel={this.closePacksModal}
        title={"What are packs?"}
        footer={[
          <AntButton key="submit" type="default" onClick={this.closePacksModal}>
            OK
          </AntButton>,
        ]}
      >
        <div style={{ overfow: "scroll" }}>
          <div>
            <img
              style={{ width: "100px", margin: "20px auto", display: "flex" }}
              src={`${process.env.PUBLIC_URL}/images/pack-of-cards.png`}
              alt="Pack of cards"
            />
            <p>
              Greeting Card Packs are multiple copies of the same card (the one you're making now)
              sent to a single address, usually back to yourself. Our packs are ideal for sending
              the same card out to multiple recipients but we will only ship your pack to one
              address for you to send on yourself.
            </p>
            <p>
              For each pack quantity (8, 16, 120 etc), you'll receive the same number of additional
              blank envelopes for you to personalise each card and send on.
            </p>
          </div>
        </div>
      </AntModal>
    );

    const cardFinishInfoModal = (
      <AntModal
        style={{ top: 20, height: "500px" }}
        bodyStyle={{ overflowY: "scroll" }}
        visible={this.state.isOptionsModalVisible}
        onOk={this.closeOptionsModal}
        onCancel={this.closeOptionsModal}
        footer={[
          <AntButton key="submit" type="default" onClick={this.closeOptionsModal}>
            OK
          </AntButton>,
        ]}
      >
        <div>
          <p>Choose between two card finishes:</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className={`card-finish-option ${glossOn ? "active" : ""}`}
              onClick={e => this.props.onChangeProductOptions({ card_finish: "gloss" })}
            >
              <h2 style={{ fontSize: "16px" }}>Gloss</h2>
              <p style={{ fontSize: "12px", textAlign: "center" }}>Gloss UV Laminated Cardstock</p>
              {/* <img alt="UV GLoss" src={`${process.env.PUBLIC_URL}/images/uv-gloss-finish.png`}/> */}
            </div>
            <div
              className={`card-finish-option ${!glossOn ? "active" : ""}`}
              onClick={e => this.props.onChangeProductOptions({ card_finish: "matt" })}
            >
              <h2 style={{ fontSize: "16px" }}>Matt</h2>
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                Uncoated Matt Finish Cardstock
              </p>
              {/* <img alt="Matt finish" src={`${process.env.PUBLIC_URL}/images/matt-finish.png`}/> */}
            </div>
          </div>
        </div>
      </AntModal>
    );

    if (isPicCollage) {
      //bottomCTAContent = ''
      bottomCTAContent = (
        <div
          class="pic-collage-btn"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <Button
            theme="pc"
            priority="secondary"
            label={"Card Information"}
            block
            onClick={() => console.log("Next")}
          />
        </div>
      );
    }

    const preventScaling = this.state.isTextEditorVisible || this.props.disableScaling;
    return [
      <EditorSignatureInputModal
        key="signature-input-modal"
        ratio={signatureRatio}
        signatureData={
          this.state.isSignatureEditorVisible && activeLayer.getIn(["config", "data"])
            ? activeLayer.getIn(["config", "data"]).toJS()
            : []
        }
        config={activeLayer && activeLayer.get("config")}
        isOpen={this.state.isSignatureEditorVisible}
        onSave={this.saveSignature}
        onCancel={this.closeSignatureEditor}
      />,
      <EditorCropperModal
        key="cropper-modal"
        onHandleReplaceImage={this.handleReplaceImage}
        isOpen={this.state.isCropperModalVisible}
        imgUrl={imageForCropper && imageForCropper.getIn(["src", "lowResUrl"])}
        cropData={
          imageForCropper && imageForCropper.get("cropData")
            ? imageForCropper.get("cropData").toJS()
            : null
        }
        ratio={cropperRatio}
        onClose={() => this.setState({ isCropperModalVisible: false })}
        onSave={this.handleCropperSave}
      />,
      uploadModal,
      infoModal,
      shippingModal,
      photoTileInfoModal,
      templateModal,
      cardFinishInfoModal,
      sizeSelectionModal,
      packsModal,
      <MainContent scrollable={false} key="main">
        {isGreetingCard ? (
          <CardOpener
            isOpen={this.state.currentStep && this.state.currentStep === STEPS.PRODUCT_REAR}
            ratio={productRatio}
            front={frontRenderer}
            inside={backRenderer}
            onUpdateDimensions={this.handleDimensionChange}
            dimensions={dimensions}
          />
        ) : (
          <Flippable
            isFlipped={this.state.currentStep === STEPS.PRODUCT_REAR}
            ratio={productRatio}
            front={frontRenderer}
            back={backRenderer}
            preventScaling={preventScaling}
          />
        )}
        {/* {isCanvas && (
          <div key="info-button-container" className="editor__info-button-container">
            <Button theme="aqua" priority="secondary" label="Info" onClick={this.showInfoModal} />
          </div>
        )} */}
      </MainContent>,
      helpText && (
        <div
          key="help-text"
          className="editor__help-text"
          dangerouslySetInnerHTML={{ __html: helpText }}
        />
      ),
      isPhotoTile && (
        <div key="info-button-container" className="editor__tile-info-button-container">
          <AntButton type="primary" onClick={this.showInfoModal} ghost>
            Info
          </AntButton>
        </div>
      ),
      areOptionsAvailable && (isOnFront || isPicCollage) && (
        <div
          key="info-button-container"
          className="editor__info-button-container"
          style={{ justifyContent: "space-around" }}
        >
          {isPackMode && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginRight: "10px" }}>
                Quantity:
                {/* {`${this.props.item.get("quantity")} - ${this.props.item.get("packQuantity")}`} */}
              </div>
              <div class="editor__pack-mode-container" style={{ marginLeft: "0px" }}>
                <Select
                  label="Pack Size"
                  autoFocus={true}
                  dropdownStyle={{
                    fontSize: "13px",
                    textAlign: "left",
                    margin: "0px 3px",
                    minWidth: "300px",
                  }}
                  value={{
                    value: this.props.item.get("quantity"),
                    label: `${this.props.item.get("quantity")} Pack (${this.props.item.get(
                      "packQuantity"
                    )} cards)`,
                  }}
                  labelInValue
                  defaultValue={{
                    value: this.props.item.get("quantity"),
                    label: `${this.props.item.get("quantity")} Pack (${this.props.item.get(
                      "packQuantity"
                    )} cards)`,
                  }}
                  onChange={value =>
                    this.props.onChangePackScheme(
                      getPricingSchemeForSchemeId({
                        schemeId: value.value,
                        currency: this.props.currency,
                      })
                    )
                  }
                >
                  {packOptions.map(({ packSize, price, schemeId, perCard, quantity }, i) => (
                    <Option value={schemeId}>
                      {`${quantity} ${quantity > 1 ? "Packs" : "Pack"} (${packSize} cards)`}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
      ),
      activeToolMarkup,
      bottomCTAContent,
      <Footer key="footer">{footerContent}</Footer>,
    ];
  };

  handleToggleDD = () => {
    this.setState(
      {
        isAdditionalEnvelopeRequired: !this.props.item.get("isDoubleDirect"),
      },
      () => {
        this.props.onChangeItemDoubleDirect(!this.props.item.get("isDoubleDirect"));
      }
    );
  };

  handleBorderRadiusChange = (photoLayerId, newValue) => {
    //debounce(() => this.props.onChangeRegionBorderRadius(photoLayerForPage.get("id"), val), 200)
    // const debouncedFunc = () => this.props.onChangeRegionBorderRadius(photoLayerId, newValue);
    // debounce(debouncedFunc, 300);
    this.props.onChangeRegionBorderRadius(photoLayerId, newValue);
  };

  handleBorderWidthChange = (photoLayerId, newValue) => {
    this.props.onChangeRegionBorderWidth(photoLayerId, newValue);
  };

  renderRecipientDetailsStep = () => {
    const rendererProps = {
      debug: false,
      item: this.props.item.toJS(),
      activeTool: this.state.activeTool,
      selectedLayerId: this.state.selectedLayerId,
      selectedRegionIndex: this.state.selectedRegionIndex,
      onClearLayerSelection: this.handleClearLayerSelection,
      onSelectTextPlaceholderLayer: this.openAdvancedTextEditorForLayerId,
      onClickAddressLayer: this.showAddressModal,
    };

    //const image = preloadImage("/images/cardboard.jpg");
    const isSinglePageWallArt = [
      PRODUCT_TYPE_IDS.CANVAS,
      PRODUCT_TYPE_IDS.PHOTO_TILE,
      PRODUCT_TYPE_IDS.FRAMED_PRINTS,
    ].includes(this.props.item.get("productTypeId"));

    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    const isPortraitProduct =
      this.props.item.getIn(["productDimensions", "width"]) <
      this.props.item.getIn(["productDimensions", "height"]);

    const address = this.props.item.get("address") || this.props.item.get("addressBookEntry");

    return [
      <MainContent scrollable={false} key="main">
        {isSinglePageWallArt ? (
          <EditorCanvasPackage
            address={address && address.toJS()}
            onClickAddress={this.showAddressModal}
            aspectRatio={
              this.props.item.getIn(["productDimensions", "height"]) /
              this.props.item.getIn(["productDimensions", "width"])
            }
          />
        ) : (
          <React.Fragment>
            <div style={{ position: "relative", width: "100%" }}>
              {this.state.isAdditionalEnvelopeRequired && (
                <div className="editor__extra-envelope" style={{ display: "none" }} />
              )}
              <HtmlRenderer
                page="ENVELOPE"
                width={this.state.cardOpenerDimension && this.state.cardOpenerDimension.width}
                isPortraitProduct={isPortraitProduct}
                {...rendererProps}
              />
            </div>
            {isGreetingCard && (
              <div
                className="double-direct-option"
                style={{
                  background: "#ebebeb",
                  padding: "5px",
                  marginTop: "20px",
                  borderRadius: "10px",
                  maxWidth: "780px",
                  margin: "20px auto",
                }}
              >
                <Row
                  type="flex"
                  justify="center"
                  gutter={8}
                  style={{ width: "100%", margin: "20px auto" }}
                >
                  <Col span={24} style={{ textAlign: "center", scale: "1.3" }}>
                    <Checkbox
                      checked={this.props.item.get("isDoubleDirect")}
                      onChange={this.handleToggleDD}
                    >
                      <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                        Add an additional blank envelope
                      </span>
                    </Checkbox>
                  </Col>
                </Row>
              </div>
            )}
            {/* {isPackMode && (
              <div className="editor__pack-details">
                <p>This is where we will send all cards in your greeting card pack. <br/><br/>Your shipment will include <strong>{packQty}</strong> additional blank envelopes.</p>
              </div>
            )} */}
          </React.Fragment>
        )}
      </MainContent>,
    ];
  };

  renderSelectPostDateStep = () => {
    const selectedDate = moment(this.props.item.get("postDate"));
    const destination =
      (this.props.item.get("address") && this.props.item.getIn(["address", "country"])) ||
      (this.props.item.get("addressBookEntry") &&
        this.props.item.getIn(["addressBookEntry", "country"]));
    const arrivalEstimates = getArrivalDatesForDestination(
      this.props.item.get("postDate"),
      destination
    );

    const isPackMode = this.props.item.get("packMode");

    return [
      <MainContent scrollable={false} key="main">
        <div style={{ maxWidth: "80%", margin: "0 auto", textAlign: "center" }}>
          <p className="muted">
            {isPackMode ? `When should we post your cards?` : `When should we post your card?`}
          </p>
          <DatePicker
            onChange={this.handleDatePickerChange}
            withPortal
            minDate={getEarliestPostingDateForCurrentTime()}
            filterDate={this.filterDate}
            selected={selectedDate}
            locale="en-custom"
            customInput={<CustomDatepickerField />}
          />
          <br />
          <p className="muted">Estimated arrival between:</p>
          <div className="editor__arrival-estimation">
            <div className="date">
              <div className="date__month">
                {arrivalEstimates.earliestArrivalDate.format("MMM")}
              </div>
              <div className="date__day">{arrivalEstimates.earliestArrivalDate.format("D")}</div>
              <div className="date__day-of-week">
                {arrivalEstimates.earliestArrivalDate.format("dddd")}
              </div>
            </div>
            <div className="editor__arrival-estimation-divider">—</div>
            <div className="date">
              <div className="date__month">{arrivalEstimates.latestArrivalDate.format("MMM")}</div>
              <div className="date__day">{arrivalEstimates.latestArrivalDate.format("D")}</div>
              <div className="date__day-of-week">
                {arrivalEstimates.latestArrivalDate.format("dddd")}
              </div>
            </div>
          </div>
        </div>
      </MainContent>,
    ];
  };

  render() {
    let contentAfterHeader = [];
    console.log(this.props);
    let headerProps = this.getHeaderPropsForStep(this.state.currentStep);

    switch (this.state.currentStep) {
      case STEPS.PRODUCT_FRONT:
      case STEPS.PRODUCT_REAR:
        contentAfterHeader = this.renderFrontOrRearStep();
        break;
      case STEPS.RECIPIENT_DETAILS:
        contentAfterHeader = this.renderRecipientDetailsStep();
        break;
      case STEPS.SELECT_POST_DATE:
        contentAfterHeader = this.renderSelectPostDateStep();
        break;
      // no default
    }

    const activeLayer = this.props.item
      .get("layers")
      .find(l => l.get("id") === this.state.selectedLayerId);
    const activeRenderer =
      this.state.currentStep === STEPS.PRODUCT_FRONT ? this.frontRenderer : this.backRenderer;

    const onlyNewAddresses =
      !!this.props.item.get("templateId") || !!this.props.item.get("picCollage");
    const isPicCollage = this.props.item.get("picCollage");
    const defaultAddressCountry = isPicCollage ? { country: "United States" } : null;
    const initialFormData = this.props.item.get("address")
      ? this.props.item.get("address").toJS()
      : defaultAddressCountry;
    const isGreetingCard = this.props.item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

    const isPortraitProduct =
      this.props.item.getIn(["productDimensions", "width"]) <
      this.props.item.getIn(["productDimensions", "height"]);

    contentAfterHeader = contentAfterHeader || []

    return [
      <SweetAlert key="alert" isOpen={Boolean(this.state.alert)} {...(this.state.alert || {})} />,
      <Header key="header" {...headerProps} />,
      ...contentAfterHeader,
      <FullScreenLoader
        key="loader"
        isVisible={this.state.isLoading}
        message="Loading design..."
      />,
      <EditorTextEditorModal
        key="text-editor-modal"
        ref={el => (this.textEditor = el)}
        isOpen={this.state.isTextEditorVisible}
        scaleToDimensions={this.state.textEditorScalingDimensions}
        canvasDimensions={(activeRenderer && activeRenderer.getDimensions()) || {}}
        config={activeLayer && activeLayer.get("config").toJS()}
        layerId={this.state.selectedLayerId}
        onClose={this.closeTextEditor}
        onSave={this.saveTextEditorConfig}
        isVerticallyCentered={activeLayer && activeLayer.get("page") !== 0 && isGreetingCard}
        isPortraitProduct={isPortraitProduct}
      />,
      <EditorAddressInputModal
        key="address-input-modal"
        isDoubleDirect={
          ![
            PRODUCT_TYPE_IDS.POSTCARD,
            PRODUCT_TYPE_IDS.CANVAS,
            PRODUCT_TYPE_IDS.PHOTO_TILE,
            PRODUCT_TYPE_IDS.FRAMED_PRINTS,
          ].includes(this.props.item.get("productTypeId"))
        }
        isPackMode={this.props.item.get("packMode")}
        isOpen={this.state.isAddressModalVisible && !isPicCollage}
        mode={this.state.addressInputMode}
        initialFormData={initialFormData}
        onCancel={this.closeAddressModal}
        onSaveNewAddress={this.handleAddressSave}
        onSaveOwnAddress={this.handleOwnAddressSave}
        onSelectAddressBookEntry={this.handleSelectAddressBookEntry}
        onlyNewAddress={onlyNewAddresses}
        picCollage={this.props.item.get("picCollage")}
      />,
      <PicCollageEditorAddressInputModal
        key="address-input-modal-pc"
        isOpen={this.state.isAddressModalVisible && isPicCollage}
        initialFormData={initialFormData}
        onCancel={this.closeAddressModal}
        onSaveNewAddress={this.handleAddressSave}
        onlyNewAddress={true}
        onlyCountries={["United States", "United Kingdom"]}
      />,
    ];
  }
}
export default Editor;
