import { fromJS, List } from "immutable";
import shortid from "shortid";
import moment from "moment";
import { handle } from "redux-pack";
import uniq from "lodash/uniq";
import omit from "lodash/omit";

import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import KlaviyoTracking from "../../utils/klaviyo";
import ReactPixel from "react-facebook-pixel";
import * as LAYER_TYPES from "../../constants/layer-types";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import * as CURRENCIES from "../../constants/currencies";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  getNextDecreasedQuantityForProductType,
  getNextIncreasedQuantityForProductType,
  getNextDecreasedPackQuantityForProductType,
  getNextIncreasedPackQuantityForProductType,
  singleShippingAddressProductTypes,
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  containsMultipleShipments,
} from "../../data/products";
import {
  getPricingSchemeForProductAndQuantity,
  getPackPricingSchemeForProductAndPackQuantity,
} from "../../data/pricing-schemes";
import {
  getPostageSchemeForProductTypeAndDestination,
  getPostageSchemeForId,
  getAllPostageSchemesForProductTypeAndDestination,
} from "../../data/postage-schemes";
import { selectors as addressBookSelectors } from "./address-book";
import { types as userTypes } from "./auth";
import postsnapApi from "../../lib/apis/postsnap";
import { isCurrentMajorVersionOutdated } from "../../lib/version-checker";
import getStoreDebugStateOrDefaultState from "../../lib/get-store-debug-state-or-default-state";
import { console } from "es6-shim";

function roundToTwoDecimals(number, down = false) {
  //console.log(parseFloat(RoundHalfDown(number).toFixed(2)));
  return parseFloat(RoundHalfDown(number, down).toFixed(2));
}

function RoundHalfDown(num, down = false) {
  //return -Math.round(-num * 100) / 100;
  if (down) {
    return Math.floor(num * 100) / 100;
  } else {
    return -Math.round(-num * 100) / 100;
  }
}

const LAYER_TYPES_REQUIRING_RENDER = [LAYER_TYPES.TEXT, LAYER_TYPES.PHOTO, LAYER_TYPES.SIGNATURE];

export const types = {
  SET_CURRENCY: "POSTSNAP/BASKET/SET_CURRENCY",
  SET_SEEN_CROSSSELL_MODAL: "POSTSNAP/BASKET/SET_SEEN_CROSSSELL_MODAL",
  ADD_ITEM: "POSTSNAP/BASKET/ADD_ITEM",
  ADD_ITEM_NO_RENDER: "POSTSNAP/BASKET/ADD_ITEM_NO_RENDER",
  ADD_ITEM_WITHOUT_RENDER: "POSTSNAP/BASKET/ADD_ITEM_WITHOUT_RENDER",
  ADD_PENDING_APPROVAL_ITEM: "POSTSNAP/BASKET/ADD_PENDING_APPROVAL_ITEM",
  UPDATE_ITEM: "POSTSNAP/BASKET/UPDATE_ITEM",
  UPDATE_PENDING_APPROVAL_ITEM: "POSTSNAP/BASKET/UPDATE_PENDING_APPROVAL_ITEM",
  UPDATE_ITEM_WITH_NO_RENDER: "POSTSNAP/BASKET/UPDATE_ITEM_WITH_NO_RENDER",
  UPDATE_ITEM_ADDRESS: "POSTSNAP/BASKET/UPDATE_ITEM_ADDRESS",
  UPDATE_ITEM_POSTAGE_SCHEME: "POSTSNAP/BASKET/UPDATE_ITEM_POSTAGE_SCHEME",
  RENDER_ITEM_START: "POSTSNAP/BASKET/RENDER_ITEM_START",
  RENDER_ITEM_END: "POSTSNAP/BASKET/RENDER_ITEM_END",
  RENDER_ITEM_FAILED: "POSTSNAP/BASKET/RENDER_ITEM_FAILED",
  RENDER_LAYER: "POSTSNAP/BASKET/RENDER_LAYER",
  RENDER_PENDING_APPROVAL_ITEM_START: "POSTSNAP/BASKET/RENDER_PENDING_APPROVAL_ITEM_START",
  RENDER_PENDING_APPROVAL_ITEM_END: "POSTSNAP/BASKET/RENDER_PENDING_APPROVAL_ITEM_END",
  RENDER_PENDING_APPROVAL_ITEM_FAILED: "POSTSNAP/BASKET/RENDER_PENDING_APPROVAL_ITEM_FAILED",
  RENDER_PENDING_APPROVAL_LAYER: "POSTSNAP/BASKET/RENDER_PENDING_APPROVAL_LAYER",
  CLEAR_PENDING_APPROVAL_ITEMS: "POSTSNAP/BASKET/CLEAR_PENDING_APPROVAL_ITEMS",
  DELETE_ITEM: "POSTSNAP/BASKET/DELETE_ITEM",
  DELETE_PENDING_APPROVAL_ITEM: "POSTSNAP/BASKET/DELETE_PENDING_APPROVAL_ITEM",
  DELETE_ITEMS: "POSTSNAP/BASKET/DELETE_ITEMS",
  DELETE_ALL_ITEMS: "POSTSNAP/BASKET/DELETE_ALL_ITEMS",
  SET_POST_DATE_FOR_ITEM: "POSTSNAP/BASKET/SET_POST_DATE_FOR_ITEM",
  DUPLICATE_ALERT_DIMISSED: "POSTSNAP/BASKET/DUPLICATE_ALERT_DIMISSED",
  APPROVE_ITEM: "POSTSNAP/BASKET/APPROVE_ITEM",
  APPROVE_ITEMS: "POSTSNAP/BASKET/APPROVE_ITEMS",
  APPROVE_PENDING_APPROVAL_ITEM: "POSTSNAP/BASKET/APPROVE_PENDING_APPROVAL_ITEM",
  APPROVE_PENDING_APPROVAL_ITEMS: "POSTSNAP/BASKET/APPROVE_PENDING_APPROVAL_ITEMS",
  APPROVE_ALL_ITEMS: "POSTSNAP/BASKET/APPROVE_ALL_ITEMS",
  UNAPPROVE_ALL_ITEMS: "POSTSNAP/BASKET/UNAPPROVE_ALL_ITEMS",
  APPLY_PROMOTION_CODE: "POSTSNAP/BASKET/APPLY_PROMOTION_CODE",
  REMOVE_PROMOTION_CODE: "POSTSNAP/BASKET/REMOVE_PROMOTION_CODE",
  CREATE_ORDER: "POSTSNAP/BASKET/CREATE_ORDER",
  CREATE_GUEST_ORDER: "POSTSNAP/BASKET/CREATE_GUEST_ORDER",
  CREATE_EMAIL_ONLY_GUEST_ORDER: "POSTSNAP/BASKET/CREATE_EMAIL_ONLY_GUEST_ORDER",
  SEND_THANKS_FOR_ORDER: "POSTSNAP/BASKET/SEND_THANKS_FOR_ORDER",
  CHARGE_STRIPE_PAYMENT: "POSTSNAP/BASKET/CHARGE_STRIPE_PAYMENT",
  CHARGE_STRIPE_CUSTOMER: "POSTSNAP/BASKET/CHARGE_STRIPE_CUSTOMER",
  CONFIRM_PAYPAL_PAYMENT: "POSTSNAP/BASKET/CONFIRM_PAYPAL_PAYMENT",
  CONFIRM_STRIPE_PAYMENT: "POSTSNAP/BASKET/CONFIRM_STRIPE_PAYMENT",
  CONFIRM_STRIPE_TOPUP_PAYMENT: "POSTSNAP/BASKET/CONFIRM_STRIPE_TOPUP_PAYMENT",
  SET_PAYMENT_SUCCESSFUL: "POSTSNAP/BASKET/SET_PAYMENT_SUCCESSFUL",
  CREATE_PAYMENT_INTENT: "POSTSNAP/BASKET/CREATE_PAYMENT_INTENT",
  PROCESS_PREPAY_PAYMENT: "POSTSNAP/BASKET/PROCESS_PREPAY_PAYMENT",
  DECREASE_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/DECREASE_QUANTITY_FOR_ITEM",
  INCREASE_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/INCREASE_QUANTITY_FOR_ITEM",
  DECREASE_PACK_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/DECREASE_PACK_QUANTITY_FOR_ITEM",
  INCREASE_PACK_QUANTITY_FOR_ITEM: "POSTSNAP/BASKET/INCREASE_PACK_QUANTITY_FOR_ITEM",
  SET_CHECKOUT_SHIPPING_ADDRESS: "POSTSNAP/BASKET/SET_CHECKOUT_SHIPPING_ADDRESS",
  SET_CHECKOUT_SHIPPING_ADDRESS_ENTRY: "POSTSNAP/BASKET/SET_CHECKOUT_SHIPPING_ADDRESS_ENTRY",
  SET_CHECKOUT_SHIPPING_OPTION: "POSTSNAP/BASKET/SET_CHECKOUT_SHIPPING_OPTION",
  SET_CHECKOUT_CONTACT_DETAILS: "POSTSNAP/BASKET/SET_CHECKOUT_CONTACT_DETAILS",
  SET_CHECKOUT_ACTIVE_STEP: "POSTSNAP/BASKET/SET_CHECKOUT_ACTIVE_STEP",
  SET_HAS_COMPLETED_CHECKOUT_STEPS: "POSTSNAP/BASKET/SET_HAS_COMPLETED_CHECKOUT_STEPS",
  CLEAR_CHECKOUT_DETAILS: "POSTSNAP/BASKET/CLEAR_CHECKOUT_DETAILS",
  UNSET_ITEM_POSTAGE_SCHEMES: "POSTSNAP/BASKET/UNSET_ITEM_POSTAGE_SCHEMES",
};

let storedBasketState;

if (isCurrentMajorVersionOutdated()) {
  console.log("Major version outdated, removing basket");
  localStorage.removeItem(STORAGE_KEYS.BASKET);
} else {
  try {
    console.log("Restoring basket state");
    storedBasketState = JSON.parse(localStorage.getItem(STORAGE_KEYS.BASKET));
    //console.log("storedBasketState:", storedBasketState);
    storedBasketState.items = storedBasketState.items.map(item => {
      item.isRendering = false;
      item.renderFailed = false;
      return item;
    });
  } catch (err) {
    console.warn("Error retrieving or parsing basket items from localStorage:", err.message);
  }
}

let defaultCurrency = CURRENCIES.GBP; //USD

switch (window.navigator.languages && window.navigator.languages[0]) {
  case "en-GB":
    defaultCurrency = CURRENCIES.GBP;
    break;
  case "en-US":
    defaultCurrency = CURRENCIES.USD;
    break;
  case "en-AU":
    defaultCurrency = CURRENCIES.AUD;
    break;
  case "en-CA":
  case "fr-CA":
    defaultCurrency = CURRENCIES.CAD;
    break;
  default:
    defaultCurrency = CURRENCIES.GBP;
    break;
}

const DEFAULT_STATE = fromJS(
  getStoreDebugStateOrDefaultState("basket", {
    currency: defaultCurrency,
    items: [],
    pendingApprovalItems: [],
    promotionCode: null,
    order: null,
    seenCrossSellModal: false,
    checkoutShippingAddress: {},
    checkoutShippingAddressEntryId: null,
    checkoutShippingOption: {},
    checkoutContactDetails: {},
    activeCheckoutStep: null,
    completedCheckoutSteps: false,
  })
);

export function reducer(
  state = (storedBasketState && fromJS(storedBasketState)) || DEFAULT_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case userTypes.SIGN_OUT:
      console.log("Signing out");
      return fromJS(DEFAULT_STATE);
    case types.SET_CURRENCY:
      return state.set("currency", payload.currency);
    case types.SET_SEEN_CROSSSELL_MODAL:
      console.log("Set seen", payload.seenCrossSellModal);
      return state.set("seenCrossSellModal", payload.seenCrossSellModal);
    case types.ADD_ITEM:
      let newItem = fromJS(payload);
      if (!newItem.get("quantity")) {
        newItem = newItem.set("quantity", 1);
      }
      return state.update("items", items => items.push(newItem));
    case types.ADD_ITEM_NO_RENDER:
      let newAsyncItem = fromJS(payload);
      if (!newAsyncItem.get("quantity")) {
        newAsyncItem = newAsyncItem.set("quantity", 1);
      }
      return state.update("items", items => items.push(newAsyncItem));
    case types.ADD_PENDING_APPROVAL_ITEM:
      let pendingApprovalItem = fromJS(payload);
      if (!pendingApprovalItem.get("quantity")) {
        pendingApprovalItem = pendingApprovalItem.set("quantity", 1);
      }
      return state.update("pendingApprovalItems", pendingApprovalItems =>
        pendingApprovalItems.push(pendingApprovalItem)
      );
    case types.CLEAR_PENDING_APPROVAL_ITEMS:
      return state.set("pendingApprovalItems", fromJS([]));
    case types.ADD_ITEM_WITHOUT_RENDER:
      let newItemWithoutRender = fromJS(payload);
      if (!newItemWithoutRender.get("quantity")) {
        newItemWithoutRender = newItemWithoutRender.set("quantity", 1);
      }
      return state.update("items", items => items.push(newItemWithoutRender));
    case types.UPDATE_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.merge(fromJS({ ...payload.itemData, isApproved: false }));
          } else {
            return item;
          }
        })
      );
    case types.UPDATE_PENDING_APPROVAL_ITEM:
      return state.update("pendingApprovalItems", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.merge(fromJS({ ...payload.itemData, isApproved: false }));
          } else {
            return item;
          }
        })
      );
    case types.UPDATE_ITEM_WITH_NO_RENDER:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.merge(fromJS({ ...payload.itemData, isApproved: false }));
          } else {
            return item;
          }
        })
      );
    case types.DECREASE_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const decresedQty = getNextDecreasedQuantityForProductType(
                item.get("productTypeId"),
                item.get("quantity")
              );
              item.set("quantity", decresedQty);
            });
          } else {
            return item;
          }
        })
      );
    case types.DECREASE_PACK_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const decresedQty = getNextDecreasedPackQuantityForProductType(
                item.get("productTypeId"),
                item.get("packQuantity")
              );
              const currency = payload.currency || "GBP";
              const nextPackScheme = getPackPricingSchemeForProductAndPackQuantity({
                productId: item.get("productId"),
                currency: currency,
                packQuantity: decresedQty,
              });
              if (nextPackScheme) {
                item.set("packQuantity", decresedQty);
                item.set("packScheme", fromJS(nextPackScheme));
                item.set("quantity", decresedQty / 8);
              }
            });
          } else {
            return item;
          }
        })
      );
    case types.INCREASE_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const increasedQty = getNextIncreasedQuantityForProductType(
                item.get("productTypeId"),
                item.get("quantity")
              );
              item.set("quantity", increasedQty);
            });
          } else {
            return item;
          }
        })
      );
    case types.INCREASE_PACK_QUANTITY_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              const increasedQty = getNextIncreasedPackQuantityForProductType(
                item.get("productTypeId"),
                item.get("packQuantity")
              );
              const currency = payload.currency || "GBP";
              const nextPackScheme = getPackPricingSchemeForProductAndPackQuantity({
                productId: item.get("productId"),
                currency: currency,
                packQuantity: increasedQty,
              });
              if (nextPackScheme) {
                item.set("packQuantity", increasedQty);
                item.set("packScheme", fromJS(nextPackScheme));
                item.set("quantity", increasedQty / 8);
              }
            });
          } else {
            return item;
          }
        })
      );
    case types.UPDATE_ITEM_ADDRESS: {
      const itemToUpdate = state.get("items").find(item => item.get("id") === payload.itemId);
      const shouldUpdateAllPhotoPrintAddresses =
        itemToUpdate.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT;
      return state.update("items", items =>
        items.map(item => {
          if (
            item.get("id") === payload.itemId ||
            (shouldUpdateAllPhotoPrintAddresses &&
              item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
          ) {
            return item.merge(fromJS({ ...payload.addressData }));
          } else {
            return item;
          }
        })
      );
    }
    case types.UPDATE_ITEM_POSTAGE_SCHEME: {
      const itemToUpdate = state.get("items").find(item => item.get("id") === payload.itemId);
      const shouldUpdateAllPhotoPrintPostageSchemes =
        itemToUpdate.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
        itemToUpdate.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE;

      return state.update("items", items =>
        items.map(item => {
          if (
            item.get("id") === payload.itemId ||
            (shouldUpdateAllPhotoPrintPostageSchemes &&
              (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
                item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE))
          ) {
            const postageScheme = getPostageSchemeForId(payload.postageSchemeId);
            //console.log("Changing to postage scheme:", postageScheme.toJS().id);
            return item.withMutations(item => {
              //console.log("original", item.getIn(["originalPostageScheme", "id"]));
              //console.log("originalPostageSchemeId", item.getIn(["originalPostageScheme", "id"]));

              //const changedPostageSchemeFromDefault = !!item.get("originalPostageScheme") && (payload.postageSchemeId !== item.getIn(["originalPostageScheme", "id"]))
              //item.set("changedPostageScheme", changedPostageSchemeFromDefault);
              // upgradedPostage scheme:
              //console.log("Setting postageScheme to", fromJS(postageScheme));
              //console.log("Setting changedPostageScheme", changedPostageSchemeFromDefault);
              console.log("Setting postage shcme to true");
              item.set("postageScheme", fromJS(postageScheme));
              item.set("setPostageScheme", true);
              //console.log("Updated postage scheme Id", item.getIn(['postageScheme', 'description']));
            });
          } else {
            return item;
          }
        })
      );
    }
    case types.RENDER_ITEM_START:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", true);
              // item.set("simulateError", true); // Used to simulate render error
              item.set("renderFailed", false);
              item.update("layers", layers => layers.map(l => l.set("render", null)));
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_ITEM_END:
      console.log("Render item end");
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            //return item.set("isRendering", false);
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", false);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_ITEM_FAILED:
      console.log("Render item failed");
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", true);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_LAYER:
      return handle(state, action, {
        success: prevState => {
          return prevState.update("items", items =>
            items.map(item => {
              if (item.get("id") === action.meta.itemId) {
                return item.withMutations(mutatedItem => {
                  const isFrontPreviewRender = action.meta.layerId === "FRONT_PREVIEW";

                  if (isFrontPreviewRender) {
                    console.log("Saving Preview S3 key", payload.data.s3_key);
                    mutatedItem.set("preview_s3_key", payload.data.s3_key);
                  } else {
                    mutatedItem.update("layers", layers =>
                      layers.map(layer => {
                        if (layer.get("id") === payload.data.layerId) {
                          //console.log("Render return", payload.data);
                          // TODO: If no s3_key set renderFailed?
                          console.log("render", payload.data.s3_key || payload.data.await_s3_key);
                          return layer
                            .set("render", payload.data.s3_key || payload.data.await_s3_key)
                            .set("renderUrl", payload.data.renderURL)
                            .set("awaitS3Key", payload.data.await_s3_key)
                            .set(
                              "renderLowRes",
                              payload.data.s3_key_low_res || payload.data.await_low_res_s3_key
                            )
                            .set("renderJson", payload.data.jsonURL);
                        } else {
                          return layer;
                        }
                      })
                    );
                  }
                });
              } else {
                return item;
              }
            })
          );
        },
        failure: prevState => {
          //console.log("Render failure:", action);
          //dispatch({type: ADD_ERROR, error: err});
          return prevState;
        },
      });
    case types.RENDER_PENDING_APPROVAL_ITEM_START:
      return state.update("pendingApprovalItems", items => {
        console.log("items => ", items);
        return items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", true);
              // item.set("simulateError", true); // Used to simulate render error
              item.set("renderFailed", false);
              item.update("layers", layers => layers.map(l => l.set("render", null)));
            });
          } else {
            return item;
          }
        });
      });
    case types.RENDER_PENDING_APPROVAL_ITEM_END:
      console.log("Render item end");
      return state.update("pendingApprovalItems", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            //return item.set("isRendering", false);
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", false);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_PENDING_APPROVAL_ITEM_FAILED:
      console.log("Render item failed");
      return state.update("pendingApprovalItems", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.withMutations(item => {
              item.set("isRendering", false);
              item.set("renderFailed", true);
            });
          } else {
            return item;
          }
        })
      );
    case types.RENDER_PENDING_APPROVAL_LAYER:
      return handle(state, action, {
        success: prevState => {
          return prevState.update("pendingApprovalItems", items =>
            items.map(item => {
              if (item.get("id") === action.meta.itemId) {
                return item.withMutations(mutatedItem => {
                  const isFrontPreviewRender = action.meta.layerId === "FRONT_PREVIEW";

                  if (isFrontPreviewRender) {
                    if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE) {
                      // For some reason the S3_key for photo tiles is high-res, so we have have to store low_res for the preview
                      console.log("Is Photo Tile");
                      console.log("Saving Preview S3 key (low res)", payload.data.s3_key_low_res);
                      mutatedItem.set("preview_s3_key", payload.data.s3_key_low_res);
                    } else {
                      console.log("Saving Preview S3 key", payload.data.s3_key);
                      mutatedItem.set("preview_s3_key", payload.data.s3_key);
                    }
                  } else {
                    mutatedItem.update("layers", layers =>
                      layers.map(layer => {
                        if (layer.get("id") === payload.data.layerId) {
                          //console.log("Render return", payload.data);
                          // TODO: If no s3_key set renderFailed?
                          console.log("render", payload.data.s3_key || payload.data.await_s3_key);
                          return layer
                            .set("render", payload.data.s3_key || payload.data.await_s3_key)
                            .set("renderUrl", payload.data.renderURL)
                            .set("awaitS3Key", payload.data.await_s3_key)
                            .set(
                              "renderLowRes",
                              payload.data.s3_key_low_res || payload.data.await_low_res_s3_key
                            )
                            .set("renderJson", payload.data.jsonURL);
                        } else {
                          return layer;
                        }
                      })
                    );
                  }
                });
              } else {
                return item;
              }
            })
          );
        },
        failure: prevState => {
          //console.log("Render failure:", action);
          //dispatch({type: ADD_ERROR, error: err});
          return prevState;
        },
      });
    case types.DELETE_ITEM:
      return state.update("items", items =>
        items.filter(item => item.get("id") !== payload.itemId)
      );
    case types.DELETE_PENDING_APPROVAL_ITEM:
      return state.update("pendingApprovalItems", items =>
        items.filter(item => item.get("id") !== payload.itemId)
      );
    case types.DELETE_ITEMS:
      return state.update("items", items =>
        items.filter(item => !payload.itemIds.includes(item.get("id")))
      );
    case types.DELETE_ALL_ITEMS:
      console.log("Deleting all items");
      return fromJS(DEFAULT_STATE);
    case types.SET_POST_DATE_FOR_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("postDate", payload.postDate);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ITEM:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ITEMS:
      return state.update("items", items =>
        items.map(item => {
          if (payload.itemIds.includes(item.get("id"))) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_PENDING_APPROVAL_ITEM:
      return state.update("pendingApprovalItems", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_PENDING_APPROVAL_ITEMS:
      return state.update("pendingApprovalItems", items =>
        items.map(item => {
          if (payload.itemIds.includes(item.get("id"))) {
            return item.set("isApproved", true);
          } else {
            return item;
          }
        })
      );
    case types.APPROVE_ALL_ITEMS:
      return state.update("items", items =>
        items.map(item => {
          return item.set("isApproved", true);
        })
      );
    case types.UNAPPROVE_ALL_ITEMS:
      return state.update("items", items =>
        items.map(item => {
          return item.set("isApproved", false);
        })
      );
    case types.DUPLICATE_ALERT_DIMISSED:
      return state.update("items", items =>
        items.map(item => {
          if (item.get("id") === payload.itemId) {
            return item.set("duplicateAlertShown", true);
          } else {
            return item;
          }
        })
      );
    case types.APPLY_PROMOTION_CODE:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            if (payload.data.success) {
              return state.set("promotionCode", fromJS(payload.data.data));
            }
          });
        },
      });
    case types.REMOVE_PROMOTION_CODE:
      return state.set("promotionCode", null);
    case types.CREATE_ORDER:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            state.setIn(["order"], fromJS(payload.data.data));
          });
        },
      });
    case types.CREATE_EMAIL_ONLY_GUEST_ORDER:
      return handle(state, action, {
        success: prevState => {
          return prevState.withMutations(state => {
            state.setIn(["order"], fromJS(payload.data.data));
          });
        },
      });
    case types.SET_CHECKOUT_SHIPPING_ADDRESS:
      //return state.set("checkoutShippingAddress", fromJS(payload.shippingAddress));
      const address = fromJS({ address: fromJS(payload.shippingAddress) });
      //console.log("singleShippingAddressProductTypes", singleShippingAddressProductTypes)
      const itemsToUpdateAddress = state
        .get("items")
        .filter(item => singleShippingAddressProductTypes.includes(item.get("productTypeId")))
        .map(i => i.get("id"));
      return state.withMutations(state => {
        state.set("checkoutShippingAddress", fromJS(payload.shippingAddress));
        state.update("items", items =>
          items.map(item => {
            if (itemsToUpdateAddress.includes(item.get("id"))) {
              //console.log("fromJS({ ...address })", address)
              return item.withMutations(item => {
                item.merge(address);
                item.set("addressBookId", null);
              });
            } else {
              return item;
            }
          })
        );
      });
    case types.SET_CHECKOUT_SHIPPING_ADDRESS_ENTRY:
      const addressBookId = fromJS({
        addressBookId: fromJS(payload.shippingAddressEntry.get("id")),
      });
      const shippingAddress = fromJS({ address: fromJS(payload.shippingAddressEntry) });
      const itemsToUpdateAddressEntry = state
        .get("items")
        .filter(item => singleShippingAddressProductTypes.includes(item.get("productTypeId")))
        .map(i => i.get("id"));

      return state.withMutations(state => {
        state.set("checkoutShippingAddressEntryId", fromJS(payload.shippingAddressEntry.get("id")));
        state.set("checkoutShippingAddress", fromJS(payload.shippingAddressEntry));
        state.update("items", items =>
          items.map(item => {
            if (itemsToUpdateAddressEntry.includes(item.get("id"))) {
              return item.withMutations(item => {
                item.merge(addressBookId);
                item.merge(shippingAddress);
              });
            } else {
              return item;
            }
          })
        );
      });

    case types.SET_CHECKOUT_SHIPPING_OPTION:
      //return state.set("checkoutShippingOption", fromJS(payload.shippingOption));
      const postageSchemeId = payload.shippingOption;
      // TODO: Extract so it doesn't hinge on products
      let shippingOptionProductTypeId = payload.productTypeId;
      const itemsToUpdate = state
        .get("items")
        .filter(item => item.get("productTypeId") === shippingOptionProductTypeId)
        .map(i => i.get("id"));

      const setCheckoutShippingOption = { [shippingOptionProductTypeId]: payload.shippingOption };

      //const newCheckoutShippingOption = {...state.get("checkoutShippingOption"), ...setCheckoutShippingOption}
      // console.log("newCheckoutShippingOption", newCheckoutShippingOption)
      return state.withMutations(state => {
        state.update("checkoutShippingOption", checkoutShippingOption => {
          const update = fromJS({ ...checkoutShippingOption.toJS(), ...setCheckoutShippingOption });
          return update;
        });

        state.update("items", items =>
          items.map(item => {
            if (itemsToUpdate.includes(item.get("id"))) {
              const postageScheme = getPostageSchemeForId(postageSchemeId);
              return item.withMutations(item => {
                item.set("postageScheme", fromJS(postageScheme));
                console.log("Setting postage scheme to true");
                item.set("setPostageScheme", true);
              });
            } else {
              return item;
            }
          })
        );
      });
    case types.SET_HAS_COMPLETED_CHECKOUT_STEPS:
      console.log("completedCheckoutSteps", payload.completedCheckoutSteps);
      return state.set("completedCheckoutSteps", payload.completedCheckoutSteps);
    case types.UNSET_ITEM_POSTAGE_SCHEMES:
      return state.withMutations(state => {
        state.set("checkoutShippingOption", fromJS({}));
        state.update("items", items =>
          items.map(item => {
            return item.withMutations(item => {
              item.set("postageScheme", fromJS(null));
              item.set("setPostageScheme", false);
            });
          })
        );
      });
    case types.SET_CHECKOUT_CONTACT_DETAILS:
      return state.set("checkoutContactDetails", fromJS(payload.contactDetails));
    case types.SET_CHECKOUT_ACTIVE_STEP:
      return state.set("checkoutActiveStep", payload.activeStep);
    case types.CLEAR_CHECKOUT_DETAILS:
      return state.withMutations(state => {
        state.set("checkoutShippingOption", fromJS({}));
        state.set("completedCheckoutSteps", false);
        state.set("checkoutShippingAddress", fromJS({}));
        state.set("checkoutShippingAddressEntryId", null);
        state.set("checkoutContactDetails", fromJS({}));
      });
    case types.CHARGE_STRIPE_PAYMENT:
    case types.CHARGE_STRIPE_CUSTOMER:
    case types.CONFIRM_PAYPAL_PAYMENT:
    case types.SET_PAYMENT_SUCCESSFUL:
    case types.PROCESS_PREPAY_PAYMENT:
    case types.CREATE_GUEST_ORDER:
      return handle(state, action, {
        success: prevState => {
          //console.log("Payment success:", action);
          //const response = payload.data;
          //eslint-disable-next-line
          const isSuccessful =
            (payload && payload.data.success) ||
            action.type === "POSTSNAP/BASKET/SET_PAYMENT_SUCCESSFUL";
          if (isSuccessful) {
            return prevState.withMutations(state => {
              state.update("items", items => items.clear());
              state.set("promotionCode", null);
              state.set("order", null);

              state.set("checkoutShippingOption", fromJS({}));
              state.set("completedCheckoutSteps", false);
              state.set("checkoutShippingAddress", fromJS({}));
              state.set("checkoutShippingAddressEntryId", null);
              state.set("checkoutContactDetails", fromJS({}));

              localStorage.removeItem(STORAGE_KEYS.CHOSEN_GUEST_ROUTE);
            });
          } else {
            console.log("200: Payment failure:", payload.data);
            return prevState;
          }
        },
        failure: prevState => {
          console.log("FAILED: Payment failure:", action);
          //dispatch({type: ADD_ERROR, error: err});
          return prevState;
        },
      });
    default:
      return state;
  }
}

export const actions = {
  setCurrency: currency => async (dispatch, getState) => {
    dispatch({
      type: types.SET_CURRENCY,
      payload: { currency },
    });
    document.cookie = `currency=${currency}; domain=${window.location.hostname.replace(
      /^(app\.)/,
      ""
    )}; path=/`;
    document.cookie = `currency=${currency}; domain=${window.location.hostname
      .replace(/^(app\.)/, "")
      .replace(/^(www\.)/, "")}; path=/`;
  },
  setSeenCrossSellModal: seenCrossSellModal => async (dispatch, getState) => {
    dispatch({
      type: types.SET_SEEN_CROSSSELL_MODAL,
      payload: { seenCrossSellModal },
    });
  },
  addItem: item => (dispatch, getState) => {
    const id = shortid.generate();
    const addedAt = moment().unix();
    const itemData = {
      ...item,
      id,
      addedAt,
      isApproved: false,
      duplicateAlertShown: item.duplicateAlertShown || false,
    };

    if (window.dataLayer) {
      try {
        const state = getState();
        const currency = selectors.getCurrency(state);
        const productId = item.productId;
        const productQty = item.quantity;
        const pricingScheme = getPricingSchemeForProductAndQuantity({
          productId: productId,
          quantity: productQty,
          currency: currency,
        });
        const productPrice = item.quantity * pricingScheme.get("cost");
        const productDescription =
          productsByProductId.getIn([productId, "basket_description"])?.replace("Portrait", "") ||
          productsByProductId.getIn([productId, "name"]).replace("Portrait", "");

        const enhancedEcommerceAddToCartDataLayer = {
          event: "addToCart",
          ecommerce: {
            currencyCode: currency,
            add: {
              products: [
                {
                  id: `${productId}`,
                  name: productDescription,
                  price: `${productPrice}`,
                  quantity: productQty,
                },
              ],
            },
          },
        };
        console.log("enhancedEcommerceAddToCartDataLayer", enhancedEcommerceAddToCartDataLayer);
        window.dataLayer.push(enhancedEcommerceAddToCartDataLayer);

        const pinterestConversionData = {
          event: "pinterestAddToCart",
          pinterestValue: productPrice,
          pinterestOrderQty: productQty,
          pinterestCurrency: currency,
          pinterestOrderId: "",
          pinterestProductID: `GC${item.designId}`,
          pinterestProductCategory: "",
        };

        console.log("Pinterest atc data", pinterestConversionData);
        window.dataLayer.push(pinterestConversionData);

        if (true) {
          //item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_PRINT
          const imageUrl = generateS3AssetUrlFromKey(item.preview_s3_key);
          const productAppKey = productsByProductId.getIn([productId, "appKey"]);
          const klaviyoItem = {
            Name: productDescription,
            SKU: productAppKey,
            ImageURL: imageUrl,
            URL: `https://www.postsnap.com/app/basket`,
            Quantity: productQty,
            Price: productPrice,
          };

          console.log("klaviyoItem", klaviyoItem);
          KlaviyoTracking.trackEvent("track", "Added to Cart", klaviyoItem);
        }

        ReactPixel.init(
          "667949721737656",
          {},
          {
            autoConfig: true, // set pixel's autoConfig
            debug: true, // enable logs
          }
        );

        const addToCartData = {
          content_ids: [`${item.designId}`],
          content_name: productDescription,
          content_type: "product",
          contents: [
            {
              id: `${item.designId}`,
              item_price: `${productPrice}`,
              quantity: productQty,
            },
          ],
          value: productPrice,
          currency: currency,
        };
        console.log("addToCartData", addToCartData);
        ReactPixel.track("AddToCart", addToCartData);
        console.log("Sent Pixel add to cart data");
      } catch (err) {
        console.warn("Failed to send ecommerce addToCart Event", err.message);
      }
    }

    dispatch({
      type: types.ADD_ITEM,
      payload: itemData,
    });
    // return {
    //   type: types.ADD_ITEM,
    //   payload: itemData,
    // };
  },
  addPendingApprovalItem: item => (dispatch, getState) => {
    console.log("addPendingApprovalItem", item);
    //const id = shortid.generate();
    const addedAt = moment().unix();
    let itemData = {};
    if (item.id) {
      itemData = {
        ...item,
        addedAt,
        isApproved: false,
        pendingApproval: true,
        duplicateAlertShown: item.duplicateAlertShown || false,
      };
    } else {
      const id = shortid.generate();
      itemData = {
        ...item,
        id,
        addedAt,
        isApproved: false,
        pendingApproval: true,
        duplicateAlertShown: item.duplicateAlertShown || false,
      };
    }
    // const itemData = {
    //   ...item,
    //   addedAt,
    //   isApproved: false,
    //   pendingApproval: true,
    //   duplicateAlertShown: item.duplicateAlertShown || false,
    // };
    dispatch({
      type: types.ADD_PENDING_APPROVAL_ITEM,
      payload: itemData,
    });
  },
  addPendingApprovalItemToBasket: item => (dispatch, getState) => {
    const itemData = {
      ...item,
      isApproved: true,
      pendingApproval: false,
    };

    if (window.dataLayer) {
      try {
        const state = getState();
        const currency = selectors.getCurrency(state);
        const productId = item.productId;
        const productQty = item.quantity;
        const pricingScheme = getPricingSchemeForProductAndQuantity({
          productId: productId,
          quantity: productQty,
          currency: currency,
        });
        const productPrice = item.quantity * pricingScheme.get("cost");
        const productDescription =
          productsByProductId.getIn([productId, "basket_description"])?.replace("Portrait", "") ||
          productsByProductId.getIn([productId, "name"]).replace("Portrait", "");

        const enhancedEcommerceAddToCartDataLayer = {
          event: "addToCart",
          ecommerce: {
            currencyCode: currency,
            add: {
              products: [
                {
                  id: `${productId}`,
                  name: productDescription,
                  price: `${productPrice}`,
                  quantity: productQty,
                },
              ],
            },
          },
        };
        console.log("enhancedEcommerceAddToCartDataLayer", enhancedEcommerceAddToCartDataLayer);
        window.dataLayer.push(enhancedEcommerceAddToCartDataLayer);

        const pinterestConversionData = {
          event: "pinterestAddToCart",
          pinterestValue: productPrice,
          pinterestOrderQty: productQty,
          pinterestCurrency: currency,
          pinterestOrderId: "",
          pinterestProductID: `GC${item.designId}`,
          pinterestProductCategory: "",
        };

        console.log("Pinterest atc data", pinterestConversionData);
        window.dataLayer.push(pinterestConversionData);

        ReactPixel.init(
          "667949721737656",
          {},
          {
            autoConfig: true, // set pixel's autoConfig
            debug: true, // enable logs
          }
        );

        const addToCartData = {
          content_ids: [`${item.designId}`],
          content_name: productDescription,
          content_type: "product",
          contents: [
            {
              id: `${item.designId}`,
              item_price: `${productPrice}`,
              quantity: productQty,
            },
          ],
          value: productPrice,
          currency: currency,
        };
        //console.log("addToCartData", addToCartData)
        ReactPixel.track("AddToCart", addToCartData);
        console.log("Sent Pixel add to cart data");
      } catch (err) {
        console.warn("Failed to send ecommerce addToCart Event", err.message);
      }
    }

    dispatch({
      type: types.ADD_ITEM_WITHOUT_RENDER,
      payload: itemData,
    });
  },
  clearPendingApprovalItems: () => ({
    type: types.CLEAR_PENDING_APPROVAL_ITEMS,
  }),
  addItemAsync: item => {
    const id = shortid.generate();
    const itemData = {
      ...item,
      id,
      isApproved: false,
      duplicateAlertShown: false,
    };
    return {
      type: types.ADD_ITEM_NO_RENDER,
      payload: itemData,
    };
  },
  updateItem: (itemId, itemData) => ({
    type: types.UPDATE_ITEM,
    payload: { itemId, itemData },
  }),
  updatePendingApprovalItem: (itemId, itemData) => ({
    type: types.UPDATE_PENDING_APPROVAL_ITEM,
    payload: { itemId, itemData },
  }),
  updateItemWithNoRender: (itemId, itemData) => ({
    // render-starter listens to UPDATE_ITEM
    type: types.UPDATE_ITEM_WITH_NO_RENDER,
    payload: { itemId, itemData },
  }),
  updateItemAddress: (itemId, addressData) => {
    return {
      type: types.UPDATE_ITEM_ADDRESS,
      payload: { itemId, addressData },
    };
  },
  updateItemPostageScheme: (itemId, postageSchemeId) => {
    return {
      type: types.UPDATE_ITEM_POSTAGE_SCHEME,
      payload: { itemId, postageSchemeId },
    };
  },
  getItem: itemId => async (dispatch, getState) => {
    const itemData = selectors.getItem(getState(), itemId).toJS();
    return {
      type: types.GET_ITEM,
      payload: { itemData },
    };
  },

  renderPendingApprovalItem: itemId => async (dispatch, getState) => {
    const item = selectors.getPendingApprovalItem(getState(), itemId).toJS();
    console.log("Rendering item...");
    dispatch({
      type: types.RENDER_PENDING_APPROVAL_ITEM_START,
      payload: { itemId },
    });
    const layerRenders = item.layers
      .filter(
        layer =>
          LAYER_TYPES_REQUIRING_RENDER.includes(layer.type) && layer.id !== "EXTRA_TEXT_LAYER"
      )
      .reduce((renders, layer) => {
        const itemData = {
          ...item,
          layers: [layer],
        };

        const postcardOrAnnouncement =
          item.productTypeId === PRODUCT_TYPE_IDS.POSTCARD ||
          item.productTypeId === PRODUCT_TYPE_IDS.ANNOUNCEMENT;
        // Announcements also have the EXTRA_TEXT_LAYER
        if (postcardOrAnnouncement && layer.page === 0 && layer.type === LAYER_TYPES.PHOTO) {
          itemData.layers = [
            ...itemData.layers,
            item.layers.find(layer => layer.id === "EXTRA_TEXT_LAYER"),
          ];
        }

        itemData.rendererReferenceLayerId = itemData.layers[0].id;
        // Used to simulate a render error
        //itemData.simulateError = true;
        const isCollagePrint = item.isCollagePrint;
        const largeCutOff = 152; // mm - 6 inches
        const isLargeCollage =
          isCollagePrint &&
          (item.productDimensions.width > largeCutOff ||
            item.productDimensions.width > largeCutOff);

        const isPhotoBook = item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE;
        const isCanvas = item.productTypeId === PRODUCT_TYPE_IDS.CANVAS;
        const useRenderAsync = isPhotoBook || isLargeCollage || isCanvas;
        console.log("useRenderAsync", useRenderAsync);

        return [
          ...renders,
          {
            layerId: layer.id,
            promise: useRenderAsync
              ? postsnapApi.renderService.renderItemAsync(itemData)
              : postsnapApi.renderService.renderItem(itemData),
          },
        ];
      }, []);

    const isCollagePrint = item.isCollagePrint;
    const largeCutOff = 152; // mm - 6 inches
    const isLargeCollage =
      isCollagePrint &&
      (item.productDimensions.width > largeCutOff || item.productDimensions.width > largeCutOff);
    let itemWithDPI = {
      ...item,
      productDimensions: {
        dpi: isLargeCollage ? 72 : 300,
        width: item.productDimensions.width,
        height: item.productDimensions.height,
        bleed: item.productDimensions.bleed,
      },
    };
    console.log("itemWithDPI", itemWithDPI);
    const itemForPreviewRender = {
      ...itemWithDPI,
      layers: item.layers.filter(layer => layer.page === item.pages.front),
      rendererReferenceLayerId: "FRONT_PREVIEW",
      allLayers: item.layers,
    };
    const previewRender = {
      layerId: "FRONT_PREVIEW",
      promise: postsnapApi.renderService.renderItem(itemForPreviewRender),
    };
    layerRenders.push(previewRender);

    layerRenders.forEach(layerRender => {
      dispatch({
        type: types.RENDER_PENDING_APPROVAL_LAYER,
        promise: layerRender.promise,
        meta: {
          itemId,
          layerId: layerRender.layerId,
          onFailure: (payload, getState) => {
            console.log("Render Failure Result", payload);
            dispatch({
              type: types.RENDER_PENDING_APPROVAL_ITEM_FAILED,
              payload: { itemId },
            });
            //console.log("Render Failure State", getState());
          },
        },
      });
    });

    await Promise.all(layerRenders.map(r => r.promise));
    dispatch({
      type: types.RENDER_PENDING_APPROVAL_ITEM_END,
      payload: { itemId },
    });
  },

  renderItem: itemId => async (dispatch, getState) => {
    const item = selectors.getItem(getState(), itemId).toJS();

    console.log("Rendering item...");

    dispatch({
      type: types.RENDER_ITEM_START,
      payload: { itemId },
    });

    const layerRenders = item.layers
      .filter(
        layer =>
          LAYER_TYPES_REQUIRING_RENDER.includes(layer.type) && layer.id !== "EXTRA_TEXT_LAYER"
      )
      .reduce((renders, layer) => {
        const itemData = {
          ...item,
          layers: [layer],
        };

        const postcardOrAnnouncement =
          item.productTypeId === PRODUCT_TYPE_IDS.POSTCARD ||
          item.productTypeId === PRODUCT_TYPE_IDS.ANNOUNCEMENT;
        // Announcements also have the EXTRA_TEXT_LAYER
        if (postcardOrAnnouncement && layer.page === 0 && layer.type === LAYER_TYPES.PHOTO) {
          itemData.layers = [
            ...itemData.layers,
            item.layers.find(layer => layer.id === "EXTRA_TEXT_LAYER"),
          ];
        }

        itemData.rendererReferenceLayerId = itemData.layers[0].id;
        // Used to simulate a render error
        //itemData.simulateError = true;
        const isCollagePrint = item.isCollagePrint;
        const largeCutOff = 152; // mm - 6 inches
        const isLargeCollage =
          isCollagePrint &&
          (item.productDimensions.width > largeCutOff ||
            item.productDimensions.width > largeCutOff);
        const isPhotoBook = item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE;
        const isCanvas = item.productTypeId === PRODUCT_TYPE_IDS.CANVAS;
        const useRenderAsync = isPhotoBook || isLargeCollage || isCanvas;
        console.log("useRenderAsync", useRenderAsync);

        return [
          ...renders,
          {
            layerId: layer.id,
            promise: useRenderAsync
              ? postsnapApi.renderService.renderItemAsync(itemData)
              : postsnapApi.renderService.renderItem(itemData),
          },
        ];
      }, []);

    const isCollagePrint = item.isCollagePrint;
    const largeCutOff = 152; // mm - 6 inches
    const isLargeCollage =
      isCollagePrint &&
      (item.productDimensions.width > largeCutOff || item.productDimensions.width > largeCutOff);

    let itemWithDPI = {
      ...item,
      productDimensions: {
        dpi: isLargeCollage ? 72 : 300,
        width: item.productDimensions.width,
        height: item.productDimensions.height,
        bleed: item.productDimensions.bleed,
      },
    };
    console.log("itemWithDPI", itemWithDPI);
    const itemForPreviewRender = {
      ...itemWithDPI,
      layers: item.layers.filter(layer => layer.page === item.pages.front),
      rendererReferenceLayerId: "FRONT_PREVIEW",
      allLayers: item.layers,
    };
    const previewRender = {
      layerId: "FRONT_PREVIEW",
      promise: postsnapApi.renderService.renderItem(itemForPreviewRender),
    };
    layerRenders.push(previewRender);

    layerRenders.forEach(layerRender => {
      dispatch({
        type: types.RENDER_LAYER,
        promise: layerRender.promise,
        meta: {
          itemId,
          layerId: layerRender.layerId,
          onFailure: (payload, getState) => {
            console.log("Render Failure Result", payload);
            dispatch({
              type: types.RENDER_ITEM_FAILED,
              payload: { itemId },
            });
            //console.log("Render Failure State", getState());
          },
        },
      });
    });

    await Promise.all(layerRenders.map(r => r.promise));
    dispatch({
      type: types.RENDER_ITEM_END,
      payload: { itemId },
    });
  },
  decreaseQuantityForItem: itemId => ({
    type: types.DECREASE_QUANTITY_FOR_ITEM,
    payload: { itemId },
  }),
  decreasePackQuantityForItem: (itemId, currency = "GBP") => ({
    type: types.DECREASE_PACK_QUANTITY_FOR_ITEM,
    payload: { itemId, currency },
  }),
  increaseQuantityForItem: itemId => ({
    type: types.INCREASE_QUANTITY_FOR_ITEM,
    payload: { itemId },
  }),
  increasePackQuantityForItem: (itemId, currency = "GBP") => ({
    type: types.INCREASE_PACK_QUANTITY_FOR_ITEM,
    payload: { itemId, currency },
  }),
  deleteItem: itemId => ({
    type: types.DELETE_ITEM,
    payload: { itemId },
  }),
  deletePendingApprovalItem: itemId => ({
    type: types.DELETE_PENDING_APPROVAL_ITEM,
    payload: { itemId },
  }),
  deleteItems: itemIds => ({
    type: types.DELETE_ITEMS,
    payload: { itemIds },
  }),
  deleteAllItems: () => ({
    type: types.DELETE_ALL_ITEMS,
  }),
  setPostDateForItem: (itemId, postDate) => ({
    type: types.SET_POST_DATE_FOR_ITEM,
    payload: { itemId, postDate },
  }),
  setDuplicateAlertShown: itemId => ({
    type: types.DUPLICATE_ALERT_DIMISSED,
    payload: { itemId },
  }),
  approveItem: itemId => ({
    type: types.APPROVE_ITEM,
    payload: { itemId },
  }),
  approveItems: itemIds => ({
    type: types.APPROVE_ITEMS,
    payload: {
      itemIds,
    },
  }),
  approvePendingApprovalItem: itemId => ({
    type: types.APPROVE_PENDING_APPROVAL_ITEM,
    payload: { itemId },
  }),
  approveAllPendingApprovalItems: itemIds => ({
    type: types.APPROVE_PENDING_APPROVAL_ITEMS,
    payload: {
      itemIds,
    },
  }),
  approveAllItems: itemIds => ({
    type: types.APPROVE_ALL_ITEMS,
  }),
  unApproveAllItems: itemIds => ({
    type: types.UNAPPROVE_ALL_ITEMS,
  }),
  applyPromoCode: code => ({
    type: types.APPLY_PROMOTION_CODE,
    promise: postsnapApi.orders.getPromoCodeDetails(code),
  }),
  removePromoCode: () => ({
    type: types.REMOVE_PROMOTION_CODE,
  }),
  createOrderFromItems: () => (dispatch, getState) => {
    const state = getState();
    const orderSummary = selectors.getOrderSummary(state);
    //console.log("Delivery", orderSummary.get("upgrdadedDelivery"));
    const lineItems = selectors.getLineItemsForOrderPayload(state);
    const payload = {
      order: {
        line_items: lineItems.toJS(),
        discount: orderSummary.get("discount"),
        total: lineItems.reduce((total, item) => total + item.get("total"), 0),
        sub_total: lineItems.reduce((subTotal, item) => subTotal + item.get("sub_total"), 0),
        previous_order_reference: selectors.getOrderReference(state),
        upgraded_delivery: orderSummary.get("upgradedDelivery"),
        postage_scheme_id: orderSummary.get("upgradedDeliverySchemeId"),
        checkout_contact_details: orderSummary.get("checkoutContactDetails"),
        checkout_shipping_address: orderSummary.get("checkoutShippingAddress"),
        checkout_shipping_address_entry_id: orderSummary.get("checkoutShippingAddressEntryId"),
        checkout_shipping_option: orderSummary.get("checkoutShippingOption"),
        multiple_shipments: orderSummary.get("multipleShipments"),
        shipping_surcharge: orderSummary.get("shippingSurcharge"),
      },
      previous_order_reference: selectors.getOrderReference(state),
    };

    const promotionCode = selectors.getPromotionCode(state);

    if (promotionCode) {
      payload.order.promotion_codes = [promotionCode.get("code")];
    }
    try {
      const products = lineItems.toJS().map(lineItem => {
        return {
          name: lineItem.item_description,
          id: `${lineItem.product_id}`,
          price: `${lineItem.sub_total}`,
          brand: "PostSnap",
          quantity: lineItem.quantity,
        };
      });
      if (window.dataLayer) {
        try {
          const currency = selectors.getCurrency(state);
          const enhancedEcommerceCheckout = {
            event: "checkout",
            ecommerce: {
              currencyCode: currency,
              checkout: {
                actionField: {
                  step: 1,
                  option: "Checkout",
                },
                products: products,
              },
            },
          };
          console.log("enhancedEcommerceCheckout", enhancedEcommerceCheckout);
          window.dataLayer.push(enhancedEcommerceCheckout);
        } catch (err) {
          console.warn("Failed to send ecommerce checkout Event", err.message);
        }
      }
    } catch (err) {
      console.warn("Failed to send ecommerce checkout Event", err.message);
    }

    const promise = postsnapApi.orders.createOrder(payload);

    dispatch({
      type: types.CREATE_ORDER,
      promise,
    });

    return promise;
  },
  createGuestOrderFromItems: (stripeToken, customerEmail) => (dispatch, getState) => {
    const state = getState();
    const orderSummary = selectors.getOrderSummary(state);
    const lineItems = selectors.getLineItemsForOrderPayload(state);
    const payload = {
      order: {
        line_items: lineItems.toJS(),
        discount: 0, // TODO
        total: lineItems.reduce((total, item) => total + item.get("total"), 0),
        sub_total: lineItems.reduce((subTotal, item) => subTotal + item.get("sub_total"), 0),
        upgraded_delivery: orderSummary.get("upgradedDelivery"),
        postage_scheme_id: orderSummary.get("upgradedDeliverySchemeId"),
        checkout_contact_details: orderSummary.get("checkoutContactDetails"),
        checkout_shipping_address: orderSummary.get("checkoutShippingAddress"),
        checkout_shipping_address_entry_id: orderSummary.get("checkoutShippingAddressEntryId"),
        checkout_shipping_option: orderSummary.get("checkoutShippingOption"),
        previous_order_reference: selectors.getOrderReference(state),
        multiple_shipments: orderSummary.get("multipleShipments"),
        shipping_surcharge: orderSummary.get("shippingSurcharge"),
      },
      payment: {
        method: "stripe",
        stripeToken,
      },
      customer: {
        email: customerEmail,
      },
    };

    const promotionCode = selectors.getPromotionCode(state);

    if (promotionCode) {
      payload.order.promotion_codes = [promotionCode.get("code")];
    }
    // TODO send ecommerce detauils for guest checkouts too
    // try {
    //   const products = lineItems.toJS().map(lineItem => {
    //     return {
    //       'name': lineItem.item_description,
    //       'id': `${lineItem.product_id}`,
    //       'price': `${lineItem.sub_total}`,
    //       'brand': 'PostSnap',
    //       'quantity': lineItem.quantity
    //     }
    //   })
    //   if(window.dataLayer) {
    //     try {
    //       const currency = selectors.getCurrency(state);
    //       const enhancedEcommerceCheckout = {
    //         "event": "checkout",
    //         "ecommerce": {
    //             "currencyCode": currency,
    //             "checkout": {
    //               'actionField': {
    //                 'step': 1,
    //                 'option': 'Checkout'
    //               },
    //               "products": products
    //             }
    //         }
    //       }
    //       console.log("enhancedEcommerceCheckout",enhancedEcommerceCheckout)
    //       window.dataLayer.push(enhancedEcommerceCheckout);
    //     } catch (err) {
    //       console.warn("Failed to send ecommerce checkout Event", err.message);
    //     }
    //   }
    // } catch (err) {
    //   console.warn("Failed to send ecommerce checkout Event", err.message);
    // }

    const promise = postsnapApi.orders.createGuestOrder(payload);

    dispatch({
      type: types.CREATE_GUEST_ORDER,
      promise,
    });

    return promise;
  },

  createEmailOnlyGuestFromItems: guestDetails => (dispatch, getState) => {
    const state = getState();
    const orderSummary = selectors.getOrderSummary(state);
    const lineItems = selectors.getLineItemsForOrderPayload(getState());
    const payload = {
      order: {
        line_items: lineItems.toJS(),
        discount: 0, // TODO
        total: lineItems.reduce((total, item) => total + item.get("total"), 0),
        sub_total: lineItems.reduce((subTotal, item) => subTotal + item.get("sub_total"), 0),
        checkout_contact_details: orderSummary.get("checkoutContactDetails"),
        checkout_shipping_address: orderSummary.get("checkoutShippingAddress"),
        checkout_shipping_address_entry_id: orderSummary.get("checkoutShippingAddressEntryId"),
        checkout_shipping_option: orderSummary.get("checkoutShippingOption"),
        previous_order_reference: selectors.getOrderReference(state),
        multiple_shipments: orderSummary.get("multipleShipments"),
        shipping_surcharge: orderSummary.get("shippingSurcharge"),
      },
      customer: guestDetails,
    };
    const promotionCode = selectors.getPromotionCode(state);
    if (promotionCode) {
      payload.order.promotion_codes = [promotionCode.get("code")];
    }

    const promise = postsnapApi.orders.createEmailOnlyGuestOrder(payload);
    dispatch({
      type: types.CREATE_EMAIL_ONLY_GUEST_ORDER,
      promise,
    });
    return promise;
  },
  createPaymentIntent: intentData => ({
    type: types.CREATE_PAYMENT_INTENT,
    promise: postsnapApi.orders.createPaymentIntent(intentData),
  }),
  sendThanksForOrder: thankData => ({
    type: types.SEND_THANKS_FOR_ORDER,
    promise: postsnapApi.orders.sendThanks(thankData),
  }),
  chargeStripePayment: ({ reference, stripeToken, saveCardDetails = false }) => ({
    type: types.CHARGE_STRIPE_PAYMENT,
    promise: postsnapApi.orders.chargeStripePayment({
      reference,
      stripeToken,
      saveCardDetails,
    }),
    meta: { saveCardDetails },
  }),
  chargeStripeCustomerForOrder: reference => ({
    type: types.CHARGE_STRIPE_CUSTOMER,
    promise: postsnapApi.orders.chargeStripeCustomerForOrder(reference),
  }),
  confirmPayPalPayment: ({ reference, token }) => ({
    type: types.CONFIRM_PAYPAL_PAYMENT,
    promise: postsnapApi.orders.confirmPayPalPayment({ reference, token }),
  }),
  confirmStripePayment: ({ reference, intent }) => ({
    type: types.CONFIRM_STRIPE_PAYMENT,
    promise: postsnapApi.orders.confirmStripePayment({ reference, intent }),
  }),
  confirmStripeTopupPayment: ({ reference, intent }) => ({
    type: types.CONFIRM_STRIPE_TOPUP_PAYMENT,
    promise: postsnapApi.orders.confirmStripeTopupPayment({ reference, intent }),
  }),
  processPrepayPayment: ({ amount, reference }) => ({
    type: types.PROCESS_PREPAY_PAYMENT,
    promise: postsnapApi.orders.processPrepayPayment({ amount, reference }),
  }),
  setCheckoutShippingAddress: shippingAddress => ({
    //todo set on all print items
    type: types.SET_CHECKOUT_SHIPPING_ADDRESS,
    payload: { shippingAddress },
  }),
  setCheckoutShippingAddressEntry: shippingAddressEntry => ({
    //todo set on all print items
    type: types.SET_CHECKOUT_SHIPPING_ADDRESS_ENTRY,
    payload: { shippingAddressEntry },
  }),
  setCheckoutShippingOption: (shippingOption, productTypeId) => ({
    //todo set on all print items
    type: types.SET_CHECKOUT_SHIPPING_OPTION,
    payload: { shippingOption, productTypeId },
  }),
  setCheckoutContactDetails: contactDetails => ({
    //todo set on all print items
    type: types.SET_CHECKOUT_CONTACT_DETAILS,
    payload: { contactDetails },
  }),
  setCheckoutActiveStep: activeStep => ({
    //todo set on all print items
    type: types.SET_CHECKOUT_ACTIVE_STEP,
    payload: { activeStep },
  }),
  setHasCompletedCheckoutSteps: completedCheckoutSteps => ({
    //todo set on all print items
    type: types.SET_HAS_COMPLETED_CHECKOUT_STEPS,
    payload: { completedCheckoutSteps },
  }),
  unsetItemPostageSchemes: () => ({
    type: types.UNSET_ITEM_POSTAGE_SCHEMES,
  }),
  clearCheckoutDetails: () => ({
    type: types.CLEAR_CHECKOUT_DETAILS,
  }),
  setPaymentSuccessful: () => ({
    type: types.SET_PAYMENT_SUCCESSFUL,
    promise: Promise.resolve(),
  }),
};

export const selectors = {
  getCurrency: state => state.basket.get("currency"),
  getHasSeenCrossSellModal: state => state.basket.get("seenCrossSellModal"),
  getPromotionCode: state => state.basket.get("promotionCode"),
  getItem: (state, id) => state.basket.get("items").find(i => i.get("id") === id),
  getPendingApprovalItem: (state, id) =>
    state.basket.get("pendingApprovalItems").find(i => i.get("id") === id),
  getPendingApprovalItems: state => state.basket.get("pendingApprovalItems"),
  getAllUnapprovedPendingApprovalItems: state => {
    if (selectors.getPendingApprovalItems(state)) {
      return selectors.getPendingApprovalItems(state).filter(i => !i.get("isApproved"));
    }
  },
  getAllApprovedPendingApprovalItems: state => {
    if (selectors.getPendingApprovalItems(state)) {
      return selectors.getPendingApprovalItems(state).filter(i => i.get("isApproved"));
    }
  },
  getItems: state =>
    state.basket
      .get("items")
      .map(item => {
        return item.withMutations(async item => {
          let quantity = item.get("quantity");
          // For photo prints, we need the quantity of _all_ photo prints of that size (product ID)
          if (
            item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
            item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE
          ) {
            let printProductId = item.get("productId");
            quantity = state.basket.get("items").reduce((totalPrintQty, basketItem) => {
              if (basketItem.get("productId") === printProductId) {
                return totalPrintQty + basketItem.get("quantity");
              }
              return totalPrintQty;
            }, 0);
          }
          let pricingScheme = getPricingSchemeForProductAndQuantity({
            productId: item.get("productId"),
            quantity: quantity,
            currency: selectors.getCurrency(state),
          });

          if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
            const pagesAdded = item
              .get("layers")
              .filter(
                layer =>
                  layer.get("type") === LAYER_TYPES.PHOTO &&
                  layer.getIn(["config", "layout", 0, "image"])
              ).size;
            const productPages = productsByProductId.get(item.get("productId")).get("min_pages");
            const costPerAdditionalPages = productsByProductId
              .get(item.get("productId"))
              .get("price_per_additional_page");
            const additionalPages = pagesAdded - productPages;

            pricingScheme = getPricingSchemeForProductAndQuantity({
              productId: item.get("productId"),
              quantity: quantity || 1,
              currency: selectors.getCurrency(state),
              additionalPages: additionalPages,
              additionalPagesCost: costPerAdditionalPages,
            });
          }

          if (item.get("packMode") && item.get("packScheme")) {
            pricingScheme = item.get("packScheme");
            quantity = 1; //item.get("quantity")
          }

          item.set("pricingScheme", pricingScheme);
          item.set(
            "description",
            productsByProductId
              .getIn([item.get("productId"), "basket_description"])
              ?.replace("Portrait", "") ||
              productsByProductId.getIn([item.get("productId"), "name"]).replace("Portrait", "")
          );

          if (item.get("isCustomPrint") && !item.get("isPassportPrint")) {
            item.set("description", item.get("customPrintDescription") || "");
          }

          if (!(item.get("address") || item.get("addressBookId"))) {
            //console.log("Item has no address", item.get('id'))
            //&& item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT
            if (singleShippingAddressProductTypes.includes(item.get("productTypeId"))) {
              const existingCheckoutShippingAddress = state.basket
                .get("checkoutShippingAddress")
                .toJS();
              const existingCheckoutShippingAddressEntryId = state.basket.get(
                "checkoutShippingAddressEntryId"
              );
              const existingShippingOption = state.basket.getIn([
                "checkoutShippingOption",
                item.get("productTypeId").toString(),
              ]);
              if (existingShippingOption) {
                const postageScheme = getPostageSchemeForId(existingShippingOption);
                item.set("postageScheme", fromJS(postageScheme));
                //console.log("Setting setPostageScheme to true");
                item.set("setPostageScheme", true);
                item.set("address", fromJS(existingCheckoutShippingAddress));
                if (existingCheckoutShippingAddressEntryId) {
                  //console.log("Setting abe to ", existingCheckoutShippingAddressEntryId)
                  item.set("addressBookId", existingCheckoutShippingAddressEntryId);
                }
              } else {
                //console.log("existingCheckoutShippingAddress", existingCheckoutShippingAddress);
                item.set("address", fromJS(existingCheckoutShippingAddress));
                if (existingCheckoutShippingAddressEntryId) {
                  //console.log("Setting abe to ", existingCheckoutShippingAddressEntryId)
                  item.set("addressBookId", existingCheckoutShippingAddressEntryId);
                }
              }
            }
          }

          if (item.get("address") || item.get("addressBookId")) {
            let country;

            if (item.get("address")) {
              country = item.getIn(["address", "country"]);
            }

            if (item.get("addressBookId")) {
              const addressBookEntry = addressBookSelectors.getEntry(
                state,
                item.get("addressBookId")
              );
              country = addressBookEntry && addressBookEntry.get("country");

              // if (!country){
              //   console.log("No country, re-fetching entries");
              //   const entries = await addressBookSelectors.getAllEntries(state);
              //   const addressBookEntry = addressBookSelectors.getEntry(
              //     state,
              //     item.get("addressBookId")
              //   );
              //   country = addressBookEntry && addressBookEntry.get("country");
              // }
            }

            if (country) {
              //console.log("Country is", country);
              let quantity = item.get("quantity");

              // For photo prints, we need the quantity of _all_ photo prints, since we only apply the postage scheme
              // to one product
              if (
                item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
                item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE
              ) {
                quantity = state.basket.get("items").reduce((totalPrintQty, basketItem) => {
                  if (
                    basketItem.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT ||
                    basketItem.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE
                  ) {
                    return totalPrintQty + basketItem.get("quantity");
                  }

                  return totalPrintQty;
                }, 0);
              }

              const isGreetingCard = item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;
              const fallbackWeight = isGreetingCard ? 10 : 1;
              const totalWeight =
                quantity * (item.get("weight") === undefined ? fallbackWeight : item.get("weight"));
              // const uniqProductIdsForProductType = state.basket.get("items").filter((basketItem) => {
              //   return basketItem.get('')
              // })
              const postageScheme = getPostageSchemeForProductTypeAndDestination({
                productTypeId: item.get("productTypeId"),
                destinationCountry: country,
                currency: selectors.getCurrency(state),
                weight: totalWeight,
                productIds: [item.get("productId")],
              });
              //console.log("Item weight", item.get("weight"));
              //console.log(postageScheme.toJS());
              //console.log("Setting originalPostageScheme to", fromJS(postageScheme));
              //item.set("originalPostageScheme", fromJS(postageScheme));

              const hasChosenPostageScheme = item.get("setPostageScheme") === true;
              //console.log("changedPostageScheme", hasChosenPostageScheme);
              //console.log("originalPostageSchemeId is now", item.getIn(["originalPostageScheme", "id"]));
              //console.log("hasChosenPostageScheme", hasChosenPostageScheme)
              //console.log("item.has('setPostageScheme')", item.get("setPostageScheme"))
              //console.log("postageScheme", item.get("postageScheme").toJS())
              if (!hasChosenPostageScheme) {
                item.set("postageScheme", fromJS(postageScheme));
              }

              //.set("originalPostageScheme", fromJS(postageScheme));

              // if (!!item.get("changedPostageScheme")){
              //   console.log("Skipping postageScheme setting as its manually set")
              // } else{
              //   console.log("Hasn't changed postage scheme - setting to default")
              //   item.set("postageScheme", fromJS(postageScheme)).set("originalPostageScheme", fromJS(postageScheme));
              // }
            }
          }

          if (item.get("addressBookId")) {
            item.set(
              "addressBookEntry",
              addressBookSelectors.getEntry(state, item.get("addressBookId"))
            );
          }
        });
      })
      .map((item, index, items) => {
        // Really poor way to doing this, should be moved to whern item added to bag
        if (
          singleShippingAddressProductTypes.includes(item.get("productTypeId")) &&
          item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT &&
          item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_TILE
        ) {
          const existingCheckoutShippingAddress = state.basket
            .get("checkoutShippingAddress")
            .toJS();
          const existingShippingOption = state.basket.getIn([
            "checkoutShippingOption",
            item.get("productTypeId").toString(),
          ]);
          if (existingShippingOption) {
            const postageScheme = getPostageSchemeForId(existingShippingOption);
            return item.withMutations(item => {
              //console.log("Updated postage scheme Id", item.getIn(['postageScheme', 'description']));
              //console.log("existingCheckoutShippingAddress", fromJS(existingCheckoutShippingAddress));
              item.set("postageScheme", fromJS(postageScheme));
              //console.log("Setting setPostageScheme to true");
              item.set("setPostageScheme", true);
              item.set("address", fromJS(existingCheckoutShippingAddress));
            });
          } else {
            //console.log("existingCheckoutShippingAddress", existingCheckoutShippingAddress);
            return item.withMutations(item => {
              item.set("address", fromJS(existingCheckoutShippingAddress));
            });
          }
        }

        // if(singleShippingAddressProductTypes.includes(item.get("productTypeId")) && state.basket.get("checkoutShippingOption").size === 0) {
        //   actions.setCheckoutShippingOption(item.getIn(["postageScheme", "id"]), item.get("productTypeId"))
        // }
        if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
          const photoPrintItems = items.filter(
            item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT
          );
          const isLastPhotoPrintItem = item.get("id") === photoPrintItems.last().get("id");
          const photoPrintItemWithPostageScheme = photoPrintItems.find(item =>
            item.getIn(["postageScheme", "cost"])
          );

          let photoPrintItemAddress;

          // photoPrintItemAddress = photoPrintItemWithPostageScheme
          // ? photoPrintItemWithPostageScheme.get("address") ||
          // photoPrintItemWithPostageScheme.get("addressBookEntry")
          // : null;

          if (photoPrintItemWithPostageScheme) {
            if (photoPrintItemWithPostageScheme.get("addressBookId")) {
              const addressBookEntry = addressBookSelectors.getEntry(
                state,
                photoPrintItemWithPostageScheme.get("addressBookId")
              );
              photoPrintItemAddress = addressBookEntry;
            } else {
              photoPrintItemAddress = photoPrintItemWithPostageScheme
                ? photoPrintItemWithPostageScheme.get("address") ||
                  photoPrintItemWithPostageScheme.get("addressBookEntry")
                : null;
            }
          }

          let totalWeightForAllPrints = 0;

          if (photoPrintItemWithPostageScheme && photoPrintItemAddress) {
            totalWeightForAllPrints = fromJS(photoPrintItems).reduce(
              (totalWeight, itemForProduct) => {
                const itemQuantity = itemForProduct.get("quantity");
                const itemWeight =
                  itemForProduct.get("weight") === undefined ? 1 : itemForProduct.get("weight");
                return totalWeight + itemQuantity * itemWeight;
              },
              0
            );

            const uniqProductIds = [
              ...new Set(fromJS(photoPrintItems).map(item => item.get("productId"))),
            ];
            //console.log("photoPrintItems", uniqProductIds)
            const availablePostageSchemes = getAllPostageSchemesForProductTypeAndDestination({
              productTypeId: parseInt(item.get("productTypeId")),
              destinationCountry: photoPrintItemAddress.get("country"),
              currency: selectors.getCurrency(state),
              weight: totalWeightForAllPrints,
              useFreshPostageSchemes: false,
              productIds: uniqProductIds,
              postcode: photoPrintItemAddress.get("postcode"),
            });

            const isSelectedAvailable = availablePostageSchemes.find(available => {
              return (
                photoPrintItemWithPostageScheme.getIn(["postageScheme", "id"]) ===
                available.get("id")
              );
            });
            //console.log("isSelectedAvailable", isSelectedAvailable)
            if (!isSelectedAvailable) {
              console.log("Selected not available, reselect...");
              const postageScheme = getPostageSchemeForProductTypeAndDestination({
                productTypeId: parseInt(item.get("productTypeId")),
                destinationCountry: photoPrintItemAddress.get("country"),
                currency: selectors.getCurrency(state),
                weight: totalWeightForAllPrints,
                productIds: uniqProductIds,
              });

              //TODO: This isn't actually changing the state, needs to be sone on an action when adding/removing items
              const setCheckoutShippingOption = {
                [item.get("productTypeId")]: postageScheme.get("id"),
              };
              const newShippingOption = fromJS({
                ...state.basket.get("checkoutShippingOption").toJS(),
                ...setCheckoutShippingOption,
              });
              state.basket.set("checkoutShippingOption", newShippingOption);

              return item.withMutations(item => {
                item.set("postageScheme", fromJS(postageScheme));
                //console.log("Setting setPostageScheme to true")
                item.set("setPostageScheme", true);
                if (!isLastPhotoPrintItem) {
                  if (item.get("postageScheme")) {
                    item.setIn(["postageScheme", "cost"], 0);
                  }
                }
              });
            }
          } else {
            const existingCheckoutShippingAddress = state.basket.get("checkoutShippingAddress");
            return item.withMutations(item => {
              item.set("address", fromJS(existingCheckoutShippingAddress));
              if (!isLastPhotoPrintItem) {
                if (item.get("postageScheme")) {
                  item.setIn(["postageScheme", "cost"], 0);
                }
              }
            });
          }

          /**
           * If we're not on the last photo print item, we set the shipping cost to 0 since we only charge for it once
           */
          if (!isLastPhotoPrintItem) {
            if (item.get("postageScheme")) {
              return item.setIn(["postageScheme", "cost"], 0);
            }
          }
        }

        if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE) {
          const photoTileItems = items.filter(
            item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE
          );
          const isLastPhotoTileItem = item.get("id") === photoTileItems.last().get("id");
          const photoTileItemWithPostageScheme = photoTileItems.find(item =>
            item.getIn(["postageScheme", "cost"])
          );

          let photoTileItemAddress;

          // photoPrintItemAddress = photoPrintItemWithPostageScheme
          // ? photoPrintItemWithPostageScheme.get("address") ||
          // photoPrintItemWithPostageScheme.get("addressBookEntry")
          // : null;

          if (photoTileItemWithPostageScheme) {
            if (photoTileItemWithPostageScheme.get("addressBookId")) {
              const addressBookEntry = addressBookSelectors.getEntry(
                state,
                photoTileItemWithPostageScheme.get("addressBookId")
              );
              photoTileItemAddress = addressBookEntry;
            } else {
              photoTileItemAddress = photoTileItemWithPostageScheme
                ? photoTileItemWithPostageScheme.get("address") ||
                  photoTileItemWithPostageScheme.get("addressBookEntry")
                : null;
            }
          }

          let totalWeightForAllTiles = 0;

          if (photoTileItemWithPostageScheme && photoTileItemAddress) {
            totalWeightForAllTiles = fromJS(photoTileItems).reduce(
              (totalWeight, itemForProduct) => {
                const itemQuantity = itemForProduct.get("quantity");
                const itemWeight =
                  itemForProduct.get("weight") === undefined ? 1 : itemForProduct.get("weight");
                return totalWeight + itemQuantity * itemWeight;
              },
              0
            );

            const uniqProductIds = [
              ...new Set(fromJS(photoTileItems).map(item => item.get("productId"))),
            ];
            //console.log("photoPrintItems", uniqProductIds)
            const availablePostageSchemes = getAllPostageSchemesForProductTypeAndDestination({
              productTypeId: parseInt(item.get("productTypeId")),
              destinationCountry: photoTileItemAddress.get("country"),
              currency: selectors.getCurrency(state),
              weight: totalWeightForAllTiles,
              useFreshPostageSchemes: false,
              productIds: uniqProductIds,
              postcode: photoTileItemAddress.get("postcode"),
            });

            const isSelectedAvailable = availablePostageSchemes.find(available => {
              return (
                photoTileItemWithPostageScheme.getIn(["postageScheme", "id"]) ===
                available.get("id")
              );
            });
            //console.log("isSelectedAvailable", isSelectedAvailable)
            if (!isSelectedAvailable) {
              const postageScheme = getPostageSchemeForProductTypeAndDestination({
                productTypeId: parseInt(item.get("productTypeId")),
                destinationCountry: photoTileItemAddress.get("country"),
                currency: selectors.getCurrency(state),
                weight: totalWeightForAllTiles,
                productIds: uniqProductIds,
              });
              //TODO: This isn't actually changing the state, needs to be sone on an action when adding/removing items
              const setCheckoutShippingOption = {
                [item.get("productTypeId")]: postageScheme.get("id"),
              };
              const newShippingOption = fromJS({
                ...state.basket.get("checkoutShippingOption").toJS(),
                ...setCheckoutShippingOption,
              });
              state.basket.set("checkoutShippingOption", newShippingOption);

              return item.withMutations(item => {
                item.set("postageScheme", fromJS(postageScheme));
                //console.log("Setting setPostageScheme to true")
                item.set("setPostageScheme", true);
                if (!isLastPhotoTileItem) {
                  if (item.get("postageScheme")) {
                    item.setIn(["postageScheme", "cost"], 0);
                  }
                }
              });
            }
          } else {
            const existingCheckoutShippingAddress = state.basket.get("checkoutShippingAddress");
            return item.withMutations(item => {
              item.set("address", fromJS(existingCheckoutShippingAddress));
              if (!isLastPhotoTileItem) {
                if (item.get("postageScheme")) {
                  item.setIn(["postageScheme", "cost"], 0);
                }
              }
            });
          }

          /**
           * If we're not on the last photo print item, we set the shipping cost to 0 since we only charge for it once
           */
          if (!isLastPhotoTileItem) {
            if (item.get("postageScheme")) {
              return item.setIn(["postageScheme", "cost"], 0);
            }
          }
        }

        return item;
      })
      .sort((a, b) => a.get("productTypeId") - b.get("productTypeId")),
  getOrderReference: state =>
    state.basket.get("order") && state.basket.getIn(["order", "reference"]),
  getOrderSummary: state => {
    let items = selectors.getItems(state);
    const promotion = selectors.getPromotionCode(state);
    let promotionInfo = {
      data: selectors.getPromotionCode(state),
    };

    let subTotal = items.reduce((total, item) => {
      let quantity = item.get("quantity");
      if (item.get("packMode") && item.get("packScheme")) {
        quantity = 1; //item.get("quantity")
      }
      const itemCost = quantity * item.getIn(["pricingScheme", "cost"]);
      return total + itemCost;
    }, 0);
    let totalShippingCost = items.reduce((total, item) => {
      const shippingCost = item.get("postageScheme") && item.getIn(["postageScheme", "cost"]);
      return total + (shippingCost || 0);
    }, 0);
    let shippingSurcharge = 0.0;
    const multipleShipments = containsMultipleShipments(
      items.map(item => item.get("productId")).toJS()
    );
    if (multipleShipments) {
      shippingSurcharge = 3.0;
    }
    console.log("=====> SUB TOTAL", subTotal);
    console.log("=====> SHIPPING", totalShippingCost);
    console.log("=====> SURCHARGE", shippingSurcharge);
    let total = subTotal + totalShippingCost + shippingSurcharge || 0.0;
    let globalDiscount = 0;

    if (promotion) {
      switch (promotion.get("promo_type")) {
        case "order": {
          let globalDiscount = roundToTwoDecimals(
            parseFloat(promotion.get("amount")) ||
              parseFloat(total) * (parseFloat(promotion.get("percent")) / 100),
            true
          );

          if (parseFloat(promotion.get("percent")) === 100) {
            globalDiscount = roundToTwoDecimals(total);
          }

          // clamp to order total
          if (globalDiscount > roundToTwoDecimals(total)) {
            globalDiscount = roundToTwoDecimals(total);
          }

          promotionInfo.totalDiscountForOrder = globalDiscount;
          total = roundToTwoDecimals(total) - globalDiscount;
          break;
        }
        case "delivery": {
          let allApplicableItems = [];
          let onlyApplicableItems = false;
          let onlyMinimumSpend = false;
          let minimumSpend = null;
          let hasMinimumSpend = false;

          if (promotion.get("limited_to_products")) {
            //console.log("Limited to products", promotion.get("product_ids").toJS())
            onlyApplicableItems = true;
            allApplicableItems = items.filter(item =>
              promotion.get("product_ids").includes(item.get("productId"))
            );
          }

          if (promotion.get("only_with_minimum_spend")) {
            onlyMinimumSpend = true;
            minimumSpend = parseFloat(promotion.get("minimum_spend"));

            if (onlyApplicableItems) {
              // check minimum spend for items >=
              const spendForApplicable = allApplicableItems.reduce((totalCost, nextItem) => {
                const lineItemCost = parseFloat(
                  nextItem.get("quantity") * parseFloat(nextItem.getIn(["pricingScheme", "cost"]))
                );
                return totalCost + lineItemCost;
              }, 0);
              //console.log("spendForApplicable", spendForApplicable);
              //console.log("minimumSpend", minimumSpend);
              hasMinimumSpend = spendForApplicable >= minimumSpend;
            } else {
              // Check all items spend
              const spendForAll = items.reduce((totalCost, nextItem) => {
                const lineItemCost = parseFloat(
                  nextItem.get("quantity") * parseFloat(nextItem.getIn(["pricingScheme", "cost"]))
                );
                return totalCost + lineItemCost;
              }, 0);
              //console.log("spendForAll", spendForAll);
              //console.log("minimumSpend", minimumSpend);
              hasMinimumSpend = spendForAll >= minimumSpend;
            }
          }

          if (onlyMinimumSpend && !hasMinimumSpend) {
            //console.log("Not minimum spend")
            promotionInfo.invalid = true;
            promotionInfo.invalidReason = "minimum_spend";
            promotionInfo.minimumSpend = minimumSpend;
            break;
          }

          //console.log("onlyApplicableItems", onlyApplicableItems)
          //console.log("allApplicableItems.length", allApplicableItems.size)

          if (onlyApplicableItems && allApplicableItems.size === 0) {
            //console.log("No applicable products")
            promotionInfo.invalid = true;
            promotionInfo.invalidReason = "no_products";
            break;
          }

          if (onlyApplicableItems && allApplicableItems.size > 0) {
            totalShippingCost = allApplicableItems.reduce((total, item) => {
              const shippingCost =
                item.get("postageScheme") && item.getIn(["postageScheme", "cost"]);
              return total + (shippingCost || 0);
            }, 0);
            //console.log("Only getting shipping cost for applicable items");
            //console.log("New total shipping cost", totalShippingCost)
          }

          globalDiscount =
            +promotion.get("amount") ||
            parseFloat(totalShippingCost) * (parseFloat(promotion.get("percent")) / 100);

          promotionInfo.totalDiscountForOrder = globalDiscount;
          total = total - globalDiscount;
          break;
        }
        case "product": {
          const cheapestItem = items.reduce((cheapestSoFar, item) => {
            const cheapestCost = parseFloat(
              cheapestSoFar.get("quantity") *
                parseFloat(cheapestSoFar.getIn(["pricingScheme", "cost"]))
            );
            const lineItemCost = parseFloat(
              item.get("quantity") * parseFloat(item.getIn(["pricingScheme", "cost"]))
            );
            return lineItemCost < cheapestCost ? item : cheapestSoFar;
          }, items.first());
          const costForCheapestItem = parseFloat(
            cheapestItem.get("quantity") * parseFloat(cheapestItem.getIn(["pricingScheme", "cost"]))
          );
          const discountForCheapestItem = roundToTwoDecimals(
            +promotion.get("amount") ||
              parseFloat(costForCheapestItem) * (parseFloat(promotion.get("percent")) / 100),
            true
          );

          items = items.map(item => {
            if (item === cheapestItem) {
              return item.set("discount", discountForCheapestItem);
            } else {
              return item;
            }
          });

          promotionInfo.totalDiscountForOrder = discountForCheapestItem;

          total = total - discountForCheapestItem;
          break;
        }
        case "multi_product": {
          const allApplicableItems = items.filter(item =>
            promotion.get("product_ids").includes(item.get("productId"))
          );
          let discountAcrossAllItems = 0;

          items = items.map(item => {
            if (allApplicableItems.includes(item)) {
              let itemCost = parseFloat(
                item.get("quantity") * parseFloat(item.getIn(["pricingScheme", "cost"]))
              );

              if (item.get("packMode")) {
                itemCost = item.getIn(["pricingScheme", "cost"]);
              }
              // const itemCost = parseFloat(
              //   item.get("quantity") * parseFloat(item.getIn(["pricingScheme", "cost"]))
              // );
              const discountForItem = roundToTwoDecimals(
                +promotion.get("amount") ||
                  parseFloat(itemCost) * (parseFloat(promotion.get("percent")) / 100),
                true
              );
              discountAcrossAllItems += discountForItem;
              return item.set("discount", discountForItem);
            } else {
              return item;
            }
          });

          promotionInfo.totalDiscountForOrder = discountAcrossAllItems;

          total = total - discountAcrossAllItems;
          break;
        }
        // no default
      }
    }

    const anyUpgradedDelivery = items.some(item => item.get("setPostageScheme"));
    let upgradedDeliverySchemeId;
    if (anyUpgradedDelivery) {
      upgradedDeliverySchemeId = items
        .find(item => item.get("setPostageScheme"))
        .getIn(["postageScheme", "id"]);
    }

    let checkoutShippingAddress = selectors.getCheckoutShippingAddress(state);
    let checkoutShippingOption = selectors.getCheckoutShippingOption(state);
    let checkoutContactDetails = selectors.getCheckoutContactDetails(state);

    //console.log("anyUpgradedDelivery", anyUpgradedDelivery);
    //console.log("upgradedDeliverySchemeId", upgradedDeliverySchemeId);
    //console.log("Items", items);
    return fromJS({
      items,
      subTotal: roundToTwoDecimals(subTotal),
      totalShippingCost: roundToTwoDecimals(totalShippingCost),
      total: roundToTwoDecimals(total),
      discount: roundToTwoDecimals(globalDiscount),
      promotionInfo,
      upgradedDelivery: anyUpgradedDelivery,
      upgradedDeliverySchemeId: upgradedDeliverySchemeId,
      checkoutShippingAddress,
      checkoutShippingOption,
      checkoutContactDetails,
      multipleShipments: multipleShipments,
      shippingSurcharge: roundToTwoDecimals(shippingSurcharge),
    });
  },
  getAllUnrenderedItemIds: state =>
    selectors
      .getItems(state)
      .filter(item => {
        const pageNumbers = Object.values(PAGES_BY_PRODUCT_TYPE[item.get("productTypeId")]);
        const isItemMissingRendersForOneOrMoreLayers = item
          .get("layers")
          .filter(layer => layer.get("id") !== "EXTRA_TEXT_LAYER")
          .filter(layer => pageNumbers.includes(layer.get("page")))
          .filter(layer =>
            [LAYER_TYPES.SIGNATURE, LAYER_TYPES.PHOTO, LAYER_TYPES.TEXT].includes(layer.get("type"))
          )
          .some(layer => {
            const isRenderableLayerType = [
              LAYER_TYPES.SIGNATURE,
              LAYER_TYPES.PHOTO,
              LAYER_TYPES.TEXT,
            ].includes(layer.get("type"));
            return isRenderableLayerType && !layer.get("render");
          });

        return !item.get("isRendering") && isItemMissingRendersForOneOrMoreLayers;
      })
      .map(item => item.get("id")),
  getAllUnapprovedItems: state => selectors.getItems(state).filter(i => !i.get("isApproved")),
  getDuplicateAlertItem: state =>
    selectors.getItems(state).find(i => !i.get("addressFixed") && !i.get("duplicateAlertShown")),
  getLineItemsForOrderPayload: state => {
    const orderSummary = selectors.getOrderSummary(state);
    const items = orderSummary.get("items");
    return items.map(item => {
      const customisations = item
        .get("layers")
        .filter(layer => layer.get("id") !== "EXTRA_TEXT_LAYER")
        .filter(layer =>
          [
            LAYER_TYPES.PHOTO,
            LAYER_TYPES.TEXT,
            LAYER_TYPES.SIGNATURE,
            LAYER_TYPES.GRAPHIC,
          ].includes(layer.get("type"))
        )
        .map(layer => {
          const customisation = {
            klass: `${layer.get("type")}::Config`,
            layer_id: layer.get("id"),
          };

          if (layer.get("type") === LAYER_TYPES.TEXT) {
            const rect = layer.getIn(["config", "rect"]).toJS();
            if (rect.transformedRect) {
              customisation.stringRect = `{{${rect.transformedRect.x}, ${rect.transformedRect.y}}, {${rect.transformedRect.width}, ${rect.transformedRect.height}}}`;
            } else {
              customisation.stringRect = `{{${rect.x}, ${rect.y}}, {${rect.width}, ${rect.height}}}`;
            }
            customisation.text = layer.getIn(["config", "text"]);
          }

          if (layer.get("type") === LAYER_TYPES.SIGNATURE) {
            const drawing = layer.getIn(["config", "drawing"]);
            const avatar = layer.getIn(["config", "avatar"]);
            customisation.base64_image = drawing && drawing.get("image");
            customisation.avatar_url = avatar && avatar.get("url");
          } else {
            customisation.s3_key = layer.get("render");

            if (layer.get("type") === LAYER_TYPES.PHOTO || layer.get("type") === LAYER_TYPES.TEXT) {
              customisation.render_url = layer.get("renderUrl");
              customisation.render_json = layer.get("renderJson");
              customisation.await_s3_key = layer.get("awaitS3Key");
              customisation.render_low_res = layer.get("renderLowRes");
            }
            if (layer.get("type") === LAYER_TYPES.PHOTO) {
              try {
                customisation.original_info = layer.getIn([
                  "config",
                  "layout",
                  0,
                  "image",
                  "originalInfo",
                ]);
              } catch (e) {
                console.warn("Couldnt get original image info:", customisation, layer, e);
              }
            }
          }

          if (layer.get("type") === LAYER_TYPES.GRAPHIC) {
            customisation.s3_key = layer.getIn(["config", "s3_key"]);
          }

          if (!customisation.s3_key) {
            console.warn(
              "Created a line item with a customisation that has no render:",
              customisation,
              layer
            );
          }

          return customisation;
        });

      const lineItem = {
        quantity: item.get("quantity"),
        item_cost: 0, // TODO
        discount: item.get("discount") || 0,
        preview_s3_key: item.get("preview_s3_key"),
        product_id: item.get("productId"),
        product_options: item.get("product_options"),
        custom_print: item.get("isCustomPrint"),
        custom_print_width: item.get("selectedWidth"),
        custom_print_height: item.get("selectedHeight"),
        custom_print_units: item.get("units"),
        custom_print_tiled: item.get("tiled"),
        design_id: item.get("designId"),
        item_description: item.get("description"),
        postage_scheme_id: item.getIn(["postageScheme", "id"]),
        is_pack: item.get("packMode"),
        is_rotated: item.get("isRotated"),
        shown_resolution_warning: item.get("isLowRes"),
        pack_quantity: item.get("packQuantity"),
        pack_pricing_scheme_id: item.getIn(["packScheme", "id"]),
        uploadcare_uuids: uniq(
          item
            .get("layers")
            .reduce((allUuids, layer) => {
              if (layer.get("type") === LAYER_TYPES.PHOTO) {
                const uuids = layer
                  .getIn(["config", "layout"])
                  .reduce((layerUuids, region) => {
                    if (region.get("image")) {
                      return layerUuids.concat(region.getIn(["image", "src", "uploadcareUuid"]));
                    }

                    return layerUuids;
                  }, new List())
                  .toJS();

                return allUuids.concat(uuids);
              }

              return allUuids;
            }, new List())
            .flatten(1)
            .toJS()
        ),
        recipients: [
          {
            quantity: item.get("quantity"),
            is_double_direct: item.get("isDoubleDirect"),
            customisations,
          },
        ],
        sub_total: item.get("quantity") * item.getIn(["pricingScheme", "cost"]),
        delivery_cost: item.getIn(["postageScheme", "cost"]),
        total:
          item.get("quantity") * item.getIn(["pricingScheme", "cost"]) +
          item.getIn(["postageScheme", "cost"]),
      };

      if (item.get("addressBookId")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          address_book_id: item.get("addressBookId"),
        }));
      }

      if (item.get("address")) {
        const removeKeys = [
          "original_id",
          "main",
          "id",
          "system",
          "created_at",
          "updated_at",
          "line_item_id",
          "customer_id",
          "double_direct",
          "quantity",
        ];
        const address = omit(item.get("address").toJS(), removeKeys);

        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          address_book_entry: address,
        }));
      }

      if (item.get("shippingPhone")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          shipping_phone: item.get("shippingPhone"),
        }));
      }

      if (item.get("postDate")) {
        lineItem.recipients = lineItem.recipients.map(r => ({
          ...r,
          posting_date: moment(item.get("postDate")).format("YYYY-MM-DD"),
        }));
      }

      return fromJS(lineItem);
    });
  },
  getTotalQuantity: state => {
    let packTotal = 0;
    let nonPackTotal = 0;

    const packProducts = selectors
      .getItems(state)
      .filter(item => {
        return (
          item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT &&
          hasMinimumQuantityForProduct(item.get("productId"))
        );
      })
      .groupBy(item => item.get("productId"));

    Object.keys(packProducts.toJS()).map((productId, index) => {
      const productsForProductId = fromJS(packProducts.toJS()[productId]);
      const productQty = productsForProductId.reduce(
        (total, product) => total + product.get("quantity"),
        0
      );
      const packQty = minimumQuantityForProduct(parseInt(productId));
      const packCount =
        productQty % packQty === 0 ? productQty / packQty : Math.floor(productQty / packQty);
      return (packTotal += packCount);
    });

    const nonPackProducts = selectors.getItems(state).filter(item => {
      return !hasMinimumQuantityForProduct(item.get("productId"));
    });

    nonPackTotal = nonPackProducts.reduce((totalQty, item) => {
      return totalQty + item.get("quantity");
    }, 0);
    return packTotal + nonPackTotal;
    // return selectors
    //   .getItems(state)
    //   .reduce((totalQty, item) => {
    //     if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
    //       if(hasMinimumQuantityForProduct(item.get("productId"))){
    //         const items

    //       } else{
    //         totalQty + item.get("quantity")
    //       }
    //     } else {
    //       totalQty + item.get("quantity")
    //     }

    //     //, 0);
    //   }, 0);
    // const totalQuantityWithoutPrints = selectors.getItems(state).reduce((totalQty, item) => {
    //   if (item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
    //     return totalQty;
    //   }

    //   return totalQty + item.get("quantity");
    // }, 0);

    // const bagHasOneOrMorePrints =
    //   selectors
    //     .getItems(state)
    //     .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT).size > 0;

    // return totalQuantityWithoutPrints + (bagHasOneOrMorePrints ? 1 : 0);
  },
  getCheckoutShippingAddress: state => state.basket.get("checkoutShippingAddress"),
  getCheckoutShippingAddressEntryId: state => state.basket.get("checkoutShippingAddressEntryId"),
  getCheckoutShippingOption: state => state.basket.get("checkoutShippingOption"),
  getCheckoutContactDetails: state => state.basket.get("checkoutContactDetails"),
  getCheckoutActiveStep: state => state.basket.get("checkoutActiveStep"),
  getHasCompletedCheckoutSteps: state => state.basket.get("completedCheckoutSteps"),
};
