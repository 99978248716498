import React from "react";

import Button from "../Button/Button";
import Header from "../Header/Header";
import QueueAnim from "rc-queue-anim";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import QuantityPicker from "../QuantityPicker/QuantityPicker";
import MainContent from "../MainContent/MainContent";

import "./BorderPrintEditor.scss";
import Grid from "../Grid/Grid";
import Footer from "../Footer/Footer";
import { Button as AntButton, Col, Row } from "antd";

const BorderPrintEditor = ({
  selectedProductOption,
  items,
  onClickItem,
  onCancel,
  onClickNext,
  onClickAddPrint,
  onChangeProductOption,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  onDeleteItem,
}) => {
  const glossOn = selectedProductOption === "gloss";
  const optionActiveButtonColor = "#3b3938";
  const optionButtonStyles = {
    fontSize: "12px",
    borderRadius: "2px",
    width: "95px",
    height: "32px",
  };

  return (
    <React.Fragment>
      <Header
        title="Border Photo Prints"
        leftAction={<Button theme="muted" priority="tertiary" label="Cancel" onClick={onCancel} />}
      />
      <MainContent padded scrollable className="photo-prints-prebag">
        <Row type="flex" justify="center" style={{ marginBottom: "15px", marginTop: "10px" }}>
          <Col>
            <div style={{ display: "inline-block" }}>
              <AntButton
                size="small"
                type={glossOn ? "primary" : ""}
                onClick={() => onChangeProductOption("gloss")}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: glossOn ? optionActiveButtonColor : "",
                  borderColor: glossOn ? optionActiveButtonColor : "",
                  marginLeft: "5px",
                }}
              >
                Gloss
              </AntButton>
              <AntButton
                size="small"
                type={!glossOn ? "primary" : ""}
                onClick={() => onChangeProductOption("matt")}
                style={{
                  ...optionButtonStyles,
                  backgroundColor: !glossOn ? optionActiveButtonColor : "",
                  borderColor: !glossOn ? optionActiveButtonColor : "",
                  marginLeft: "10px",
                }}
              >
                Matt
              </AntButton>
            </div>
          </Col>
        </Row>

        {items.map((item, index) => (
          <div key={index} className="collage-print-prebag__item">
            <div>
              <HtmlRenderer
                width={130}
                isInteractive={false}
                previewMode={true}
                item={item.toJS()}
                page={item.getIn(["pages", "front"])}
                onClick={() => onClickItem(index)}
              />
            </div>
            <QuantityPicker
              quantity={item.get("quantity")}
              onIncrease={() => onIncreaseItemQuantity(index)}
              onDecrease={() => onDecreaseItemQuantity(index)}
              onDelete={() => onDeleteItem(index)}
            />
          </div>
        ))}
      </MainContent>
      <Footer padded topShadow style={{ borderTop: "1px solid #ededed" }}>
        <Grid.Row>
          <Grid.Column>
            <div style={{ paddingRight: "0.5rem" }}>
              <Button
                block
                theme="dark-blue"
                priority="secondary"
                label="Add Print"
                onClick={onClickAddPrint}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ paddingLeft: "0.5rem" }}>
              <Button
                label="Preview"
                block
                theme="dark-blue"
                onClick={onClickNext}
                disabled={items.size === 0}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Footer>
    </React.Fragment>
  );
};

export default BorderPrintEditor;
