import React from "react";
import PropTypes from "prop-types";
import RcSlider from "rc-slider";

import "rc-slider/assets/index.css";
import "./Slider.scss";

const Slider = ({ value, min, max, step, disabled, onChange }) => (
  <RcSlider {...{ value, min, max, step, disabled, onChange }} />
);

Slider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

Slider.defaultProps = {
  step: 1,
};

export default Slider;
