import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import {
  BORDER_THICKNESS,
  FRAME_COLORS,
  MAIN_VIEW_MODES,
  photoTilesActions,
  photoTilesSelectors,
  updateTile,
} from "../../../../store/ducks/ui-photo-tiles";
import { connect } from "react-redux";
import {
  confirmBeforeNavAway,
  getFrameColorFromUrl,
  getItemDataSinglePack,
  getTileWidthFromContainerWidth,
  TILE_EDITOR_MENUS,
} from "../../photoTilesUtils";
import Header from "../../../../components/Header/Header";
import { HeaderCancelButton, HeaderNextButton } from "./components/HeaderComponents";
import Footer from "../../../../components/Footer/Footer";
import { TilePreview } from "../TilePreview/TilePreview";
import {
  BORDER_WIDTHS,
  getConfigurationFromParameters,
  scaleConfiguration,
} from "../TilePreview/tilePreviewCalculator";
import "./PhotoTileEditor.scss";
import { PhotoTilesFooterEditors } from "../PhotoTIlesFooterEditors/PhotoTilesFooterEditors";
import { useElementSize } from "../../../../utils/useElementSize";
import BottomNavbarItem from "../../../../components/BottomNavbar/BottomNavbarItem";
import Icon from "../../../../components/Icon/Icon";
import Slider from "react-slick";

const SHOW_SEPARATE_BORDER_COLOR_EDITOR = false; // FEATURE FLAG / MIGHT BE NEEDED IN THE FUTURE

HeaderNextButton.propTypes = { onClick: PropTypes.func };

HeaderCancelButton.propTypes = { onClick: PropTypes.func };

function PhotoTileEditor({
  product,
  tiles,
  tileBeingEditedIndex,
  setTiles,
  setMainViewMode,
  setTileBeingEditedIndex,
  openFilesModal,
  openCropEditor,
}) {
  const sliderRef = useRef(null);
  const frameColor = getFrameColorFromUrl();

  useEffect(() => {
    sliderRef.current && sliderRef.current.slickGoTo(tileBeingEditedIndex);
  }, [tileBeingEditedIndex]);

  const onNextPress = () => {
    const newList = updateTile(tiles, tileBeingEditedIndex, {
      ...tiles[tileBeingEditedIndex],
      done: true,
    });
    setTiles(newList);

    setMainViewMode(MAIN_VIEW_MODES.PRE_BAG);
  };

  const [activeMenu, setActiveMenu] = useState(TILE_EDITOR_MENUS.CROP);
  const tileContainerRef = useRef(null);

  const nextDisabled = tiles.some(tile => !tile.image);

  const tileEdited = tiles[tileBeingEditedIndex];
  const NextButton = (
    <HeaderNextButton onClick={onNextPress} label="Done" disabled={nextDisabled} />
  );

  const { width: wrapperWidth } = useElementSize(tileContainerRef);

  const CancelButton = <HeaderCancelButton onClick={confirmBeforeNavAway} />;

  const configuration = tile =>
    scaleConfiguration(
      getConfigurationFromParameters({
        borderColor: tile?.border?.color,
        borderWidth: BORDER_WIDTHS[tile?.border?.thickness],
        withFrame: product?.photo_tile_framed,
        frameColor: FRAME_COLORS[frameColor?.toUpperCase() ?? "WHITE"],
        imageFilter: tile?.bnw ? "grayscale(100%)" : "none",
      }),
      // this here defines the width of the tile based on the its wrapper element
      getTileWidthFromContainerWidth(wrapperWidth)
    );

  const tileConfigs = useMemo(() => tiles.map(configuration), [tiles, wrapperWidth]);

  const onImageClick = index => {
    if (dragging.current === true) {
      return;
    }
    if (!tiles[index]?.image) {
      return openFilesModal();
    }

    if (activeMenu === TILE_EDITOR_MENUS.CROP) {
      return openCropEditor();
    }
  };

  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    const fetchItemData = async () => {
      const itemData = await Promise.all(tiles.map(tile => getItemDataSinglePack(tile, product)));
      setItemData(itemData.map(data => data.toJS()));
    };

    tileEdited && fetchItemData();
  }, [tileEdited, product]);

  const onCropClick = () => {
    if (!tileEdited.image) {
      return openFilesModal();
    }
    setActiveMenu(TILE_EDITOR_MENUS.CROP);
    openCropEditor();
  };

  useEffect(() => {
    sliderRef.current && sliderRef.current.slickGoTo(tileBeingEditedIndex);
  }, []);

  // https://github.com/akiran/react-slick/issues/848#issuecomment-597185462
  const dragging = useRef(null); // we use null to indicate that it was not initiated yet

  const handleBeforeChange = useCallback(() => {
    if (dragging.current === null) {
      // ignore the first call because it is called on react-slick initialization
      dragging.current = false;
      return;
    }

    dragging.current = true;
  }, []);

  const handleAfterChange = useCallback(
    index => {
      dragging.current = false;
      setTileBeingEditedIndex(index);
    },
    [setTileBeingEditedIndex]
  );

  const showBleedWarning =
    tileEdited?.border?.thickness === BORDER_THICKNESS.NO_BORDER && !product?.photo_tile_framed;

  const showSliderButtons = product?.is_pack_product || tiles.length > 1;

  return (
    <div className="tile-preview-page-wrapper">
      <div className="header-wrapper" style={{ zIndex: 10 }}>
        <Header title="Photo Tiles" leftAction={CancelButton} rightAction={NextButton} />
      </div>
      <div
        ref={tileContainerRef}
        style={{ backgroundColor: "#fcf8f9", flex: 1, position: "relative" }}
      >
        <Slider
          adaptiveHeight
          afterChange={handleAfterChange}
          beforeChange={handleBeforeChange}
          ref={sliderRef}
          arrows={false}
        >
          {tiles.map((tile, index) => {
            const tileIndicator = product?.photo_tile_pack
              ? `Photo Tile ${index + 1} of ${product.photo_tile_pack_quantity}`
              : "";
            return (
              <div key={tile.id}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "5%",
                    paddingBottom: "5%",
                    fontSize: 16,
                  }}
                >
                  {tileIndicator}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  {showSliderButtons && (
                    <div
                      style={{ transform: "scale(-1, 1)", cursor: "pointer", marginRight: "1%" }}
                      onClick={() => sliderRef.current?.slickPrev()}
                    >
                      <Icon size="default" name="arrow-right" />
                    </div>
                  )}

                  <TilePreview
                    imageUrl={tile?.image?.lowResUrl}
                    configuration={tileConfigs[index]}
                    onClick={() => onImageClick(index)}
                    cropData={tile?.crop}
                    itemData={itemData[index]}
                    width={configuration.width}
                    bleedDisabled={false}
                    showBorderCanvas={false} // this solves a flickering issues that happens with react-slick, htmlRendererPhotoLayer:Canvas in Safari
                  />
                  {showSliderButtons && (
                    <div
                      onClick={() => sliderRef.current?.slickNext()}
                      style={{ cursor: "pointer", marginLeft: "1%" }}
                    >
                      <Icon size="default" name="arrow-right" />
                    </div>
                  )}
                </div>
                {/*{tiles.length > 1 && (*/}
                {/*  <div style={{ textAlign: "center", padding: "10%", fontSize: 16 }}>*/}
                {/*    {index + 1}/{tiles.length}*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            );
          })}
        </Slider>
      </div>
      {showBleedWarning && (
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#FDC02F",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <small>Content outside the red and green line may not be printed</small>
        </div>
      )}
      <Footer>
        <PhotoTilesFooterEditors activeMenu={activeMenu} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "white",
            borderTop: "1px solid #eeeeee",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <BottomNavbarItem
            active={activeMenu === TILE_EDITOR_MENUS.CROP}
            label="Crop & Rotate"
            onClick={onCropClick}
          >
            <Icon name="crop" size="large" />
          </BottomNavbarItem>
          <BottomNavbarItem
            active={activeMenu === TILE_EDITOR_MENUS.BORDER}
            label="Border"
            onClick={() => setActiveMenu(TILE_EDITOR_MENUS.BORDER)}
          >
            <Icon name="border-double" size="large" />
          </BottomNavbarItem>
          {SHOW_SEPARATE_BORDER_COLOR_EDITOR && (
            <BottomNavbarItem
              active={activeMenu === TILE_EDITOR_MENUS.COLOURS}
              label="Colours"
              onClick={() => setActiveMenu(TILE_EDITOR_MENUS.COLOURS)}
            >
              <img
                alt="colours icon"
                src={`${process.env.PUBLIC_URL}/images/colours.png`}
                style={{ width: "28px" }}
              />
            </BottomNavbarItem>
          )}
          <BottomNavbarItem
            active={activeMenu === TILE_EDITOR_MENUS.EFFECTS}
            label="Effects"
            onClick={() => setActiveMenu(TILE_EDITOR_MENUS.EFFECTS)}
          >
            <Icon name="effects2" size="large" />
          </BottomNavbarItem>
        </div>
      </Footer>
    </div>
  );
}

PhotoTileEditor.propTypes = {
  tileBeingEditedIndex: PropTypes.number,
  tiles: PropTypes.array,
  showFilesModal: PropTypes.func,
  showCropEditor: PropTypes.func,
  showBorderEditor: PropTypes.func,
  showColorEditor: PropTypes.func,
  setTiles: PropTypes.func,
  setMainViewMode: PropTypes.func,
  setTileBeingEditedIndex: PropTypes.func,
};

const mapStateToProps = state => ({
  tiles: photoTilesSelectors.getTiles(state),
  tileBeingEditedIndex: photoTilesSelectors.getTileBeingEditedIndex(state),
  product: photoTilesSelectors.getProduct(state),
});

const mapDispatchToProps = dispatch => ({
  setTiles: tiles => dispatch(photoTilesActions.setTiles(tiles)),
  setMainViewMode: mode => dispatch(photoTilesActions.setMainViewMode(mode)),
  setTileBeingEditedIndex: index => dispatch(photoTilesActions.setTileBeingEditedIndex(index)),
  openFilesModal: () => dispatch(photoTilesActions.setShowFilesModal(true)),
  openCropEditor: () => dispatch(photoTilesActions.setShowCropEditor(true)),
  openBorderEditor: () => dispatch(photoTilesActions.setShowBorderEditor(true)),
  openColorEditor: () => dispatch(photoTilesActions.setShowColorEditor(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoTileEditor);
