import React, { useEffect, useRef, useState, useCallback } from "react";
import { Sortable, Plugins } from "@shopify/draggable";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import * as LAYER_TYPES from "../../constants/layer-types";
import "./PhotoMagazineEditor.scss";
import "./pages-modal.scss";
import styles from "./styles.module.scss";
import { fromJS } from "immutable";
const PagesModal = ({
  isPagesModalOpen,
  closePagesModal,
  // layersWithPhotos,
  item,
  templateCallback,
  reRender,
  handleDragEnd,
  handlePageReorder,
  images,
  bookFull
}) => {
  
  const productWidth = item.getIn(["productDimensions", "width"])
  const productHeight = item.getIn(["productDimensions", "height"])
  // const productType =
  //   item.getIn(["productDimensions", "width"]) / item.getIn(["productDimensions", "height"]) === 1
  //     ? "square"
  //     : "landscape";
  
  const [pageSize, setpageSize] = useState(0);

  let pageHeight = pageSize

  const squarePageSizeAddition = 60
  const portraitPageSizeAddition = 180 //200

  if(productWidth === productHeight){ 
    pageHeight = pageSize + squarePageSizeAddition
  }
  else if(productWidth > productHeight){
    pageHeight = pageSize
  } else if (productHeight > productWidth){
    pageHeight = pageSize + portraitPageSizeAddition
  }

  //const $container = useRef();
  const [sortable, setSortable] = useState(null);
  // const [imagesState, setImagesState] = useState({ images: images, item: item });
  const imagesState = useRef({ images: images, item: item });
  const onSave = () => {
    handlePageReorder(imagesState.current.item, imagesState.current.images);
    closePagesModal();
  };
  const handlePageReordering = (originalIndex, destinationIndex) => {
    const source = imagesState.current.item
      .get("layers")
      .filter(
        layer => layer.get("page") === originalIndex && layer.get("type") === LAYER_TYPES.PHOTO
      )
      .toJS()[0];
    if (originalIndex < destinationIndex) {
      let newLayers = imagesState.current.item.toJS().layers;
      let newImages = imagesState.current.images.toJS();
      const originalIndexImage = newImages[originalIndex];
      let startingIndex = originalIndex + 1;
      while (startingIndex <= destinationIndex) {
        //eslint-disable-next-line
        const startIndexLayer = newLayers.filter(
          //eslint-disable-next-line
          layer => layer && layer.type === LAYER_TYPES.PHOTO && layer.page === startingIndex
        )[0];
        //eslint-disable-next-line
        newLayers = newLayers.map(layer => {
          //eslint-disable-next-line
          if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page === startingIndex - 1) {
            let newLayer = layer;
            //eslint-disable-next-line
            newLayer.config = startIndexLayer.config;
            return newLayer;
          } else {
            return layer;
          }
        });
        const startingIndexImage = newImages[startingIndex];
        newImages[startingIndex - 1] = startingIndexImage;
        startingIndex++;
      }
      newLayers = newLayers.map(layer => {
        if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page === destinationIndex) {
          let newLayer = layer;
          newLayer.config = source.config;
          return newLayer;
        } else {
          return layer;
        }
      });
      newImages[destinationIndex] = originalIndexImage;
      let updatedItem = imagesState.current.item.toJS();
      updatedItem.layers = newLayers;
      // this.setState({ images: fromJS(newImages), item: fromJS(updatedItem) }, this.emitUpdate);
      // setImagesState({ images: newImages, item: updatedItem });
      imagesState.current.images = fromJS(newImages);
      imagesState.current.item = fromJS(updatedItem);
    } else if (originalIndex > destinationIndex) {
      let newLayers = imagesState.current.item.toJS().layers;
      let newImages = imagesState.current.images.toJS();
      const originalIndexImage = newImages[originalIndex];
      let startingIndex = originalIndex - 1;
      //eslint-disable-next-line
      while (startingIndex >= destinationIndex) {
        const startIndexLayer = newLayers.filter(
          //eslint-disable-next-line
          layer => layer && layer.type === LAYER_TYPES.PHOTO && layer.page === startingIndex
        )[0];
        //eslint-disable-next-line
        newLayers = newLayers.map(layer => {
          //eslint-disable-next-line
          if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page === startingIndex + 1) {
            let newLayer = layer;
            //eslint-disable-next-line
            newLayer.config = startIndexLayer.config;
            return newLayer;
          } else {
            return layer;
          }
        });
        const startingIndexImage = newImages[startingIndex];
        newImages[startingIndex + 1] = startingIndexImage;
        startingIndex--;
      }
      newLayers = newLayers.map(layer => {
        if (layer && layer.type === LAYER_TYPES.PHOTO && layer.page === destinationIndex) {
          let newLayer = layer;
          newLayer.config = source.config;
          return newLayer;
        } else {
          return layer;
        }
      });
      newImages[destinationIndex] = originalIndexImage;
      let updatedItem = imagesState.current.item.toJS();
      updatedItem.layers = newLayers;
      imagesState.current.images = fromJS(newImages);
      imagesState.current.item = fromJS(updatedItem);
      // setImagesState({ images: newImages, item: updatedItem });
      // this.setState({ images: fromJS(newImages), item: fromJS(updatedItem) }, this.emitUpdate);
    }
  };

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const sort = new Sortable(node, {
        draggable: `.${styles.containerItem}`,
        sortAnimation: {
          duration: 200,
          easingFunction: "ease-in-out",
        },
        plugins: [Plugins.SortAnimation],
        mirror: {
          appendTo: `.${styles.container}`,
        },
      });
      //console.log(sort, "ccxcsdfds");
      setTimeout(() => {
        setSortable(sort);
      }, 700);
      // sort.on("sortable:start", (...args) => console.log("sortable:start", args));
      // sort.on("sortable:sort", (...args) => console.log("sortable:sort", args));
      // sort.on("sortable:sorted", (...args) =>
      //   console.log("sortable:sorted", args)
      // );
      sort.on("sortable:stop", (...args) => {
        handlePageReordering(args[0].data.oldIndex + 1, args[0].data.newIndex + 1);
      });
      // sort.on("swappable:start", () => console.log("swappable:start"));
      // sort.on("swappable:swapped", () => console.log("swappable:swapped"));
      sort.on("swappable:stop", () => console.log("swappable:stop"));
      return () => {
        // did unmount
        sort.destroy();
      };
    }
  }, []);
  const handleResize = () => {
    if (window.innerWidth > 400 && window.innerWidth < 800) {
      setpageSize(250);
    } else if (window.innerWidth < 400) {
      setpageSize(150);
    } else {
      setpageSize(350);
    }
  };
  useEffect(() => {
    if (window.innerWidth > 400 && window.innerWidth < 800) {
      setpageSize(200);
    } else if (window.innerWidth < 400) {
      setpageSize(150);
    } else {
      setpageSize(350);
    }

    window.addEventListener("resize", handleResize, false);
  }, []);

  const layersWithPhotos =
    imagesState?.current?.item &&
    imagesState?.current.item
      .get("layers")
      .filter(
        layer =>
          layer.get("type") === LAYER_TYPES.PHOTO && layer.getIn(["config", "layout", 0, "image"])
      )
      .sortBy(layer => layer.get("page"));

  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",

  //   // style page row if dragging
  //   border: isDragging ? "1px solid #f8f8f8" : "none",
  //   boxShadow: isDragging ? "1px 1px 11px 0px #ccc" : "none",

  //   // styles we need to apply on draggables
  //   ...draggableStyle,
  // });

  return (
    <Modal
      // visible={isTemplateChooseModalVisible}
      isOpen={isPagesModalOpen}
      onClose={closePagesModal}
      title={
        <>
          <span
            style={{
              textAlign: "center",
              display: "block",
              paddingBottom: 20,
              fontSize: 16,
            }}
          >
            Rearrange Pages
          </span>
          <span
            style={{
              position: "absolute",
              right: 20,
              top: 0,
              pointerEvents: 'all'
            }}
          >
            <Button
            label="Apply"
            theme="dark-blue"
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={() => onSave()}
          />
          </span>
        </>
        

      }
      style={{ paddingLeft: 10, paddingRight: 10 }}
    >
      <div
        style={{
          textAlign: "center",
          display: "block",
          margin: 8,
        }}
      >
        <div>
          <p>To change the layout of your book, drag the pages to your preferred order.</p>
          <p>You can’t drag a page photo to the cover.</p>
        </div>
      </div>
      <div style={{ marginTop: 30, alignSelf: "center" }}>
        <div
          className={styles.container}
          style={{
            position: "absolute",
            pointerEvents: "none",
            top: pageSize >= 350 ? 70 : pageSize < 350 && pageSize >= 250 ? 70 : 90,
            width: pageSize * 2,
            zIndex: 1000000000,
          }}
        >
          {layersWithPhotos &&
            sortable &&
            layersWithPhotos.map((layer, index) => {
              //const isCoverPage = layer.get("page") === 0;
              return (
                <div
                  className={styles.containerItem}
                  style={{
                    width: pageSize,
                    height: pageHeight,
                  }}
                >
                  <div
                    className={`page-number ${
                      layer.get("page") % 2 !== 0 ? "odd-page" : "even-page"
                    }`}
                    style={{ width: pageSize, paddingLeft: 0, paddingRight: 0 }}
                  >
                    {layer.get("page") === 0 ? "Blank Page" : `Page ${layer.get("page")}`}
                  </div>
                </div>
              );
            })}
          {sortable && (
            <div
              className={styles.containerItem}
              style={{
                width: pageSize,
                height: pageHeight,
              }}
            >
              <div
                className={`page-number ${
                  layersWithPhotos.toJS().length % 2 !== 0 ? "odd-page" : "even-page"
                }`}
                style={{
                  width: pageSize,
                  paddingLeft: 0,
                  paddingRight: 0,
                  position: "relative",
                  visibility: bookFull ? 'visible' : 'hidden'
                }}
              >
              Blank Page
            </div>
          </div>
        )}
        </div>
        <div className={styles.container} style={{ width: pageSize * 2 }} ref={measuredRef}>
          {layersWithPhotos &&
            sortable &&
            layersWithPhotos.map((layer, index) => {
              const isCoverPage = layer.get("page") === 0;
              return (
                <div
                  className={isCoverPage ? {} : styles.containerItem}
                  style={{
                    width: pageSize,
                    height: pageHeight,
                  }}
                  key={`page-swap-container-${layer.get("page")}`}
                >
                  <div
                    style={{
                      pointerEvents: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="pageContainer"
                  >
                    <HtmlRenderer
                      width={pageSize}
                      isInteractive={false}
                      previewMode={false}
                      item={item.toJS()}
                      page={isCoverPage ? null : layer.get("page")}
                      onClick={() => {}}
                      realBorder={true}
                      photoBookBorders={true}
                      lowResScaling={true}
                      key={`page-swap-${layer.get("page")}`}
                      templateCallback={() => {}}
                      reRender={reRender}
                      uniquekey="pageScreen"
                      showBorderCanvas={false}
                    />
                  </div>
                  <div className="pageInner"></div>
                </div>
              );
            })}
          {sortable && (
            <div>
              <div style={{ width: pageSize, height: pageSize }}>
                <div
                  style={{
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    visibility: bookFull ? 'visible' : 'hidden'
                  }}
                  className="pageContainer"
                >
                  <HtmlRenderer
                    // isCoverPage={true}
                    width={pageSize}
                    isInteractive={false}
                    previewMode={false}
                    item={item.toJS()}
                    // page={layer.get("page")}
                    onClick={() => {}}
                    realBorder={true}
                    photoBookBorders={true}
                    lowResScaling={true}
                    key={`page-swap-last-blank`}
                    templateCallback={() => {}}
                    reRender={reRender}
                    uniquekey="pageScreen"
                    showBorderCanvas={false}
                  />
                  <div className="pageInner"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PagesModal;
