import React from 'react';

import "./FramePicker.scss";

class FramePicker extends React.Component {

  state = {
    
  }

  render() {

    return (<>
      <section className='frame-picker'>
        <div className="frame-picker__options">
          <ul className="frame-picker__list">
            {this.props.frameOptions.map(option => {
              return(
                <li className={`frame-picker__list-item ${this.props.currentFrame === option.sku ? 'active' : ''}`} onClick={() => this.props.onSelectFrameOption(option.sku)}>
                  <img src={option.framePreviewImageSrc} alt="frame"/>
                  <br/>
                  {option.name}<br/>
                  <span style={{fontSize: '10px'}}>{option.frameConfig.realWidth}mm</span>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </>)
  }
}

export default FramePicker;