import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import isEqual from "lodash/isEqual";

import * as LAYER_TYPES from "../../constants/layer-types";
import getElementDimensions from "../../lib/get-element-dimensions";
import EditorContainer from "../Editor/EditorContainer";

import HtmlRendererPhotoLayer from "./HtmlRendererPhotoLayer";
import HtmlRendererPlaceholderLayer from "./HtmlRendererPlaceholderLayer";
import HtmlRendererTextLayer from "./HtmlRendererTextLayer";
import HtmlRendererGraphicLayer from "./HtmlRendererGraphicLayer";
import HtmlRendererIndiciaLayer from "./HtmlRendererIndiciaLayer";
import HtmlRendererAddressLayer from "./HtmlRendererAddressLayer";
import HtmlRendererSignatureLayer from "./HtmlRendererSignatureLayer";
import HtmlRendererRect from "./HtmlRendererRect";
import HtmlRendererMask from "./HtmlRendererMask";
import HtmlRendererBleed from "./HtmlRendererBleed";
import HtmlRendererBorder from "./HtmlRendererBorder";
import {
  PRODUCT_TYPE_IDS,
  isTextPrintProduct,
  hasRealBorders,
  supportsBackground,
} from "../../data/products";

import FRAMES from "../../constants/frame-options";

import "./HtmlRenderer.scss";
import ImageDragDropPreview from "../ImageDragDropPreview/ImageDragDropPreview";
import TemplateHtml from "../TemplateSelect/TemplateHtml";

const MIN_RENDERER_WIDTH = 500;

const COLORS_PER_LAYER_TYPE = {
  [LAYER_TYPES.PHOTO]: "rgba(255,0,255,0.2)",
  [LAYER_TYPES.ADDRESS]: "rgba(255,0,0,0.2)",
  [LAYER_TYPES.TEXT]: "rgba(0,255,0,0.2)",
  TRANSFORMED_TEXT: "rgba(0,180,0,120.2)",
  [LAYER_TYPES.SIGNATURE]: "rgba(255,255,0,0.2)",
  [LAYER_TYPES.GRAPHIC]: "rgba(60,60,60,0.2)",
  [LAYER_TYPES.INDICIA]: "rgba(255,0,255,0.2)",
};

class HtmlRenderer extends React.Component {
  static propTypes = {
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    item: PropTypes.object.isRequired,
    width: PropTypes.number,
    isInteractive: PropTypes.bool,
    screenshotMode: PropTypes.bool,
    previewMode: PropTypes.bool,
    debug: PropTypes.bool,
    selectedLayerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedRegionIndex: PropTypes.number,
    onClick: PropTypes.func,
    onTextPositionChange: PropTypes.func,
    onTextContentChange: PropTypes.func,
    onSelectRegionInPhotoLayer: PropTypes.func,
    onMoveImageInRegionInPhotoLayer: PropTypes.func,
    onClearLayerSelection: PropTypes.func,
    onDeselectTextLayer: PropTypes.func,
    onSelectTextLayer: PropTypes.func,
    onClickTextLayer: PropTypes.func,
    onStartEditingTextLayer: PropTypes.func,
    onSelectTextPlaceholderLayer: PropTypes.func,
    onClickSignatureLayer: PropTypes.func,
    onClickAddressLayer: PropTypes.func,
    isPortraitProduct: PropTypes.bool,
    borderedForPreviews: PropTypes.bool,
    withShadow: PropTypes.bool,
    showCaptionPlaceholder: PropTypes.bool,
    realBorders: PropTypes.bool,
    specificDimensions: PropTypes.object,
    showLocketOverlays: PropTypes.bool,
    longPressSwap: PropTypes.bool,
    showBorderCanvas: PropTypes.bool,
    highlightText: PropTypes.bool,
    forCharacterChecking: PropTypes.bool,
    placeholderIdToCheck: PropTypes.string,
    onOverflow: PropTypes.func,
    onWrap: PropTypes.func,
    placeholderContent: PropTypes.string,
    lowResImages: PropTypes.bool,
    // disabling this, will resolve the swipe conflict between HTMLRenderer and react-slick
    disableTouchHandling: PropTypes.bool,
  };

  static defaultProps = {
    isInteractive: true,
    borderedForPreviews: false,
    withShadow: false,
    showCaptionPlaceholder: false,
    realBorders: false,
    lowResScaling: false,
    showLocketOverlays: false,
    onTextPositionChange: () => {},
    onClick: () => {},
    longPressSwap: false,
    showBorderCanvas: true,
    highlightText: false,
    forCharacterChecking: false,
    placeholderIdToCheck: null,
    onOverflow: () => {},
    onWrap: () => {},
    placeholderContent: null,
    lowResImages: false,
    disableTouchHandling: false,
  };

  state = {
    backgroundColor: "transparent",
    background: "white",
    dragSourceLayerId: null,
    dragDestinationLayerId: null,
    placeholderLength: 0,
  };

  layerRefs = {};
  photoLayerRef = React.createRef();

  componentDidMount() {
    this.setDimensions();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.photoLayerRef.current) {
      this.photoLayerRef.current.forceUpdate();
    }
    this.setDimensions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const layersForCurrentPage = this.props.item.layers.filter(
      layer => layer.page === this.props.page && layer.type === LAYER_TYPES.PHOTO
    )[0];
    const nextLayers = nextProps.item.layers.filter(layer => layer.page === this.props.page)[0];
    if (
      layersForCurrentPage &&
      nextLayers &&
      layersForCurrentPage?.config.layout &&
      nextLayers.config.layout
    ) {
      const hasSameCropData = isEqual(
        layersForCurrentPage?.config?.layout[0]?.image?.cropData,
        nextLayers?.config?.layout[0]?.image?.cropData
      );
      if (!hasSameCropData) {
        return true;
      }
    }
    if (this.props.photoMagazine) {
      //console.log("Photo magazine")
      //return true;
      if (nextProps.showingPage) {
        //console.log("Showing page, will update:", nextProps.showingPage)
        return nextProps.showingPage;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setDimensions = () => {
    const dimensions = this.props.item.productDimensions;
    if (!dimensions.bleed) {
      dimensions.bleed = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
    }

    let ratio =
      (dimensions.width + dimensions.bleed.left + dimensions.bleed.right) /
      (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom);

    if (this.props.item.isRotated) {
      ratio =
        (dimensions.height + dimensions.bleed.top + dimensions.bleed.bottom) /
        (dimensions.width + dimensions.bleed.left + dimensions.bleed.right);
    }

    const elementDimensions = getElementDimensions(ReactDOM.findDOMNode(this).parentElement);
    const parentContainerWidth = this.props.containerWidth || elementDimensions.width;
    const parentContainerHeight =
      this.props.containerHeight ||
      elementDimensions.height ||
      getElementDimensions(ReactDOM.findDOMNode(this).parentElement.parentElement).height;
    let width;
    let height;

    if (this.props.width) {
      /**
       * If the given width is smaller than the minimum for us to render the product correctly (due to positioning
       * differences when the dimensions are small enough, related to rounding), we set the renderer to the min width
       * and then scale it down to the right dimensions in the render() method (by setting the given dimensions on the
       * container, and scaling down the renderer itself).
       */
      if (this.props.width < MIN_RENDERER_WIDTH) {
        width = MIN_RENDERER_WIDTH;
      } else {
        width = this.props.width;
      }
    } else {
      width = parentContainerWidth;
    }

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      height = width;
      width = Math.round(width * ratio);
    } else {
      height = Math.round(width / ratio);
    }

    if (this.props.page === "ENVELOPE" && this.props.isPortraitProduct) {
      height = Math.round(width * ratio);
    }

    if (height > parentContainerHeight && !this.props.width) {
      height = parentContainerHeight;
      width = Math.round(parentContainerHeight * ratio);
    }

    const hasSpecificDimensions =
      this.props.specificDimensions || this.props.item.hasSpecificDimensions;

    let background = hasSpecificDimensions ? "#fff" : "transparent";
    let backgroundUrl = false;
    const doesSupportBackground = supportsBackground(this.props.item.productId);

    if (doesSupportBackground) {
      const photoLayer = this.props.item.layers.find(layer => layer.type === LAYER_TYPES.PHOTO);
      if (photoLayer?.config?.background) {
        if (photoLayer?.config?.background?.colour) {
          background = photoLayer?.config?.background?.colour?.hex;
        } else {
          backgroundUrl = true;
          background = `url(${photoLayer?.config?.background?.effect?.url})`;
        }
      }
    }

    if (this.props.item.isBorderPrint) {
      if (this.props?.item?.product_options?.borderPrint) {
        background = this.props?.item?.product_options?.borderPrint?.colour;
      }
    }

    // This applies for PHOTO_TILES
    const activeLayer = this.props.item.layers.find(l => l.page === this.props.page);
    if (activeLayer?.config?.layout?.[0]?.image?.borderPrint) {
      background = activeLayer?.config?.layout?.[0]?.image?.borderPrint.colour;
    }

    let currentState = {
      width: this.state.width,
      height: this.state.height,
      parentContainerHeight: this.state.parentContainerHeight,
      parentContainerWidth: this.state.parentContainerWidth,
      background: background, //this.props.screenshotMode ? backgroundColor : "#fff"
    };

    if (backgroundUrl) {
      currentState = { ...currentState, ...{ backgroundSize: "cover" } };
    }

    let updatedState = {
      width: width,
      height: height,
      parentContainerHeight,
      parentContainerWidth,
      background: background,
    };

    if (backgroundUrl) {
      updatedState = { ...updatedState, ...{ backgroundSize: "cover" } };
    }

    if (!isEqual(currentState, updatedState)) {
      this.setState(updatedState);
    }
  };

  getDimensions = () => {
    return {
      width: this.state.width,
      height: this.state.height,
    };
  };

  clearSelection = () => {
    // TODO: IMPLEMENT?
  };

  handleClick = e => {
    if (e.target === this.$renderer && this.props.isInteractive) {
      this.props.onClearLayerSelection();
    } else {
      this.props.onClick(e);
    }
  };

  handlePhotoLayerMouseDown = (e, layerId) => {
    const layer = this.props.item.layers.find(layer => layer.id === layerId);
    const image = layer.config.layout[0].image;
    if (image) {
      this.setState({
        dragSourceLayerId: layerId,
      });

      this.imageDndPreview.updateImage(image.src.lowResUrl);
      this.imageDndPreview.updateCoordinates({ x: e.pageX, y: e.pageY });
      this.imageDndPreview.show();
    }
  };

  handlePhotoLayerMouseEnter = (e, layerId) => {
    if (this.state.dragSourceLayerId !== null) {
      this.setState({
        dragDestinationLayerId: layerId,
      });
    }
  };

  handlePhotoLayerMouseLeave = (e, layerId) => {
    if (this.state.dragSourceLayerId !== null) {
      this.setState({
        dragDestinationLayerId: null,
      });
    }
  };

  handlePhotoLayerMouseMove = (e, layerId) => {
    if (this.state.dragSourceLayerId !== null) {
      this.imageDndPreview.updateCoordinates({ x: e.pageX, y: e.pageY });
    }
  };

  handlePhotoLayerMouseUp = (e, layerId) => {
    if (this.state.dragSourceLayerId !== null && this.state.dragDestinationLayerId !== null) {
      this.props.onSwapRegionImages({
        sourceLayerId: this.state.dragSourceLayerId,
        sourceRegionIndex: 0,
        destinationLayerId: this.state.dragDestinationLayerId,
        destinationRegionIndex: 0,
      });

      this.imageDndPreview.hide();
    }

    this.setState({
      dragSourceLayerId: null,
      dragDestinationLayerId: null,
    });
  };

  render() {
    const activeLayout = this.props.item.layers.find(l => l.page === this.props.page);

    const effectsApplied = this.props.item.product_options
      ? this.props.item.product_options.effects
      : false;

    const isCanvas = this.props.item.productTypeId === PRODUCT_TYPE_IDS.CANVAS;
    let scale = (this.props.width || this.state.width) / this.state.width;

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      //console.log("Width", this.props.width);
      //console.log("height", this.state.height);
      scale = this.props.width / this.state.height;
    }

    const isCanvasPreviewMode = !this.props.screenshotMode && this.props.previewMode && isCanvas;

    const productDimensions = this.props.item.productDimensions;

    const productHeight =
      productDimensions.height + productDimensions.bleed.top + productDimensions.bleed.bottom;
    const productWidth =
      productDimensions.width + productDimensions.bleed.left + productDimensions.bleed.right;

    const isSquareProduct = productHeight === productWidth;
    const isPortraitProduct = productHeight > productWidth;

    let background = this.state.background;
    let backgroundUrl = false;
    const doesSupportBackground = supportsBackground(this.props.item.productId);

    if (doesSupportBackground) {
      const photoLayer = this.props.item.layers.find(layer => layer.type === LAYER_TYPES.PHOTO);
      if (photoLayer?.config?.background?.colour) {
        background = photoLayer?.config?.background?.colour?.hex;
      } else {
        background = `url(${photoLayer?.config?.background?.effect?.url})`;
        backgroundUrl = true;
      }
    }

    if (this.props.item.isBorderPrint) {
      if (this.props?.item?.product_options?.borderPrint) {
        background = this.props?.item?.product_options?.borderPrint?.colour;
      }
    }

    // This applies for PHOTO_TILES
    if (activeLayout?.config?.layout?.[0]?.image?.borderPrint) {
      background = activeLayout?.config?.layout?.[0]?.image?.borderPrint.colour;
    }

    let rendererStyles = {
      width: this.state.width,
      height: this.state.height,
      backgroundColor: this.state.backgroundColor,
      background: background,
      transform: `scale(${scale})`, //`translateX(-3%) scale(${scale * 1.2})` : `scale(${scale})` isCanvasPreviewMode ? `translateX(-3%) scale(${scale * 1.2})`
      transformOrigin: "left top",
    };

    if (backgroundUrl) {
      rendererStyles = { ...rendererStyles, ...{ backgroundSize: "cover" } };
    }

    const containerStyles = {
      width: this.props.width || this.state.width,
      height: (this.props.width || this.state.width) / (this.state.width / this.state.height) || "",
    };

    if (this.props.item.isRotated && (this.props.previewMode || this.props.screenshotMode)) {
      containerStyles.height = this.props.width;
      containerStyles.width = this.props.width * (this.state.width / this.state.height);
    } else {
      //console.log("No rotation")
    }

    if (this.props.item.isRotated && !isSquareProduct && this.props.screenshotMode) {
      //console.log("Rotated and screenshot mode");

      //console.log("width", this.state.width);
      //console.log("height", this.state.height);
      let transform = "";
      let translation = "";
      // if(this.state.height < this.state.width){
      //   translation = ((this.state.width - this.state.height) / 2);
      //   transform = `rotate(90deg) translateX(${translation}px)`;
      // } else{
      translation = (this.state.height - this.state.width) / 2;
      if (isPortraitProduct) {
        //console.log("Portrait product rotated")

        containerStyles.width = this.state.height;
        containerStyles.height = this.state.width;
        translation = (this.state.width - this.state.height) / 2;
        transform = `rotate(90deg) translateX(-${translation}px) translateY(${translation}px)`;
      } else {
        transform = `rotate(90deg) translateX(-${translation}px)`;
      }
      //console.log("Transform:", transform)
      containerStyles.transform = containerStyles.WebkitTransform = transform;

      //containerStyles.width = this.state.height;
      //containerStyles.height = this.state.width;
    }

    const isFramedPrint = this.props.item.productTypeId === PRODUCT_TYPE_IDS.FRAMED_PRINTS;
    const isPhotoBook = this.props.item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE;
    // const shouldShowFrame =
    //   isFramedPrint &&
    //   (!this.props.screenshotMode ||
    //     (this.props.screenshotMode &&
    //       this.props.item.rendererReferenceLayerId === "FRONT_PREVIEW"));

    if (isFramedPrint) {
      const selectedFrame = this.props.item.product_options.frameSku;
      //const selectedFrame = '2';
      const frame = FRAMES["20x16-framed-print"].find(frameOption => {
        return frameOption.sku === selectedFrame;
      });
      let borderWidthPercent = frame.frameConfig.borderWidthPercent; // 20/300
      let borderImageSlice = frame.frameConfig.borderSlicePx - 1;
      let borderWidthPixels = Math.ceil((rendererStyles.width / 100) * borderWidthPercent);
      let rendererBorderStyles = {
        borderImageSource: `url(${frame.frameBorderImageSrc})`,
        borderImageSlice: borderImageSlice,
        borderImageRepeat: "stretch",
        borderWidth: `${borderWidthPixels}px`,
        borderStyle: "solid",
        transition: `border 0.3s ease-in-out 0s`,
      };

      rendererStyles = { ...rendererStyles, ...rendererBorderStyles };
    }

    //   //let transform = `rotate(90deg) translateX(-${(this.state.height - this.props.width) / 2}px)`;
    //   console.log("transform", transform);
    //   containerStyles.transform = containerStyles.WebkitTransform = transform;
    // }

    //support for item rotation for portrait photos
    // if (this.props.item.isRotated && !isSquareProduct && this.props.screenshotMode) {
    //   console.log("Rotated, not square and screenshot mode");
    //   console.log("here", this.state.height);
    //   console.log("here", this.state.width);

    //   containerStyles.transform = containerStyles.WebkitTransform = `rotate(90deg) translateX(-${(this
    //     .state.height -
    //     this.state.width) /
    //     2}px)`;
    // } else if (this.props.item.isRotated && isSquareProduct && this.props.screenshotMode){
    //   console.log("Rotated, square and screenshot mode");
    //   let transform = `rotate(90deg) translateX(-${(this
    //     .state.height -
    //     this.state.width) /
    //     2}px)`;
    //   console.log("transform", transform);
    //   console.log("width", this.state.width);
    //   console.log("width", this.state.height);

    //   containerStyles.transform = containerStyles.WebkitTransform = transform;
    // }

    const layersForCurrentPage = this.props.item.layers
      .filter(layer => layer.page === this.props.page)
      .map(layer => ({
        ...layer,
        config: {
          ...layer.config,
          max_length: parseInt(layer.config.max_length, 10),
          lock: Boolean(layer.config.lock),
        },
      }))
      .sort((a, b) => a.zindex - b.zindex);

    let layers = layersForCurrentPage.map(layer => {
      const genericLayerProps = {
        key: layer.id,
        id: layer.id,
        item: this.props.item,
        currentEditorStep: this.props.currentEditorStep,
        ref: e => (this.layerRefs[layer.id] = e),
        config: layer.config,
        debug: this.props.debug,
        isSelected: this.props.page === 0 && layer.id === this.props.selectedLayerId,
        isRendererContainerFlipped: this.props.isContainingElementFlipped,
        rendererDOMNode: this.$renderer,
        screenshotMode: this.props.screenshotMode,
        previewMode: this.props.previewMode,
        productDimensions: this.props.item.productDimensions,
        canvasDimensions: {
          width: this.state.width,
          height: this.state.height,
        },
      };

      let specificDimensions = null;
      let tiled = false;
      const hasSpecificDimensions =
        this.props.specificDimensions || this.props.item.hasSpecificDimensions;

      if (hasSpecificDimensions) {
        const height = this.props.item.customHeight || this.props.specificDimensions.height;
        const width = this.props.item.customWidth || this.props.specificDimensions.width;

        const widthInMM = this.props.item.isRotated ? height : width;
        const heightInMM = this.props.item.isRotated ? width : height;
        //console.log("widthInMM", widthInMM);
        //console.log("heightInMM", heightInMM);

        const widthAsPercentage = widthInMM * (100 / productWidth);
        const heightAsPercentage = heightInMM * (100 / productHeight);

        //console.log("widthAsPercentage", widthAsPercentage);
        //console.log("heightAsPercentage", heightAsPercentage);

        specificDimensions = {
          width: this.props.item.isRotated ? heightAsPercentage : widthAsPercentage,
          height: this.props.item.isRotated ? widthAsPercentage : heightAsPercentage,
        };
        tiled = this.props.item.tiled;
      }

      if (this.props.item.isBorderPrint) {
        if (this.props?.item?.product_options?.borderPrint) {
          const widthInMM = this.props?.item?.product_options?.borderPrint?.width;
          const borderWidthAsPercentage = widthInMM * (100 / productWidth);
          const heightWidthAsPercentage = widthInMM * (100 / productHeight);
          specificDimensions = {
            width: 100 - borderWidthAsPercentage * 2,
            height: 100 - heightWidthAsPercentage * 2,
          };
        }
      }

      // This applies for PHOTO_TILES
      // if (this.props.item.layers[0]?.config?.layout?.[0]?.image?.borderPrint) {

      if (layer?.config?.layout?.[0]?.image?.borderPrint) {
        const widthInMM = activeLayout?.config?.layout?.[0]?.image?.borderPrint.width;
        const borderWidthAsPercentage = widthInMM * (100 / productWidth);
        const heightWidthAsPercentage = widthInMM * (100 / productHeight);
        specificDimensions = {
          width: 100 - borderWidthAsPercentage * 2,
          height: 100 - heightWidthAsPercentage * 2,
        };
      }

      const realBordersApplied = hasRealBorders(this.props.item.productId);
      //console.log(`Real borders applied for page ${this.props.page}`, realBordersApplied);
      switch (layer.type) {
        case LAYER_TYPES.PHOTO:
          const dragAndDropHandlers = {};
          if (this.props.dragDropMode === EditorContainer.DRAG_DROP_MODES.ACROSS_LAYERS) {
            dragAndDropHandlers.onMouseDown = e => this.handlePhotoLayerMouseDown(e, layer.id);
            dragAndDropHandlers.onMouseEnter = e => this.handlePhotoLayerMouseEnter(e, layer.id);
            dragAndDropHandlers.onMouseLeave = e => this.handlePhotoLayerMouseLeave(e);
            dragAndDropHandlers.onMouseMove = e => this.handlePhotoLayerMouseMove(e);
            dragAndDropHandlers.onMouseUp = e => this.handlePhotoLayerMouseUp(e);
          }

          if (layer.config.template) {
            const genericTemplateLayerProps = {
              key: layer.id,
              id: layer.id,
              item: this.props.item,
              currentEditorStep: this.props.currentEditorStep,
              ref: e => (this.layerRefs[layer.id] = this.photoLayerRef), // ref={this.photoLayerRef}
              debug: this.props.debug,
              isSelected: this.props.page === 0 && layer.id === this.props.selectedLayerId,
              isRendererContainerFlipped: this.props.isContainingElementFlipped,
              rendererDOMNode: this.$renderer,
              screenshotMode: this.props.screenshotMode,
              previewMode: this.props.previewMode,
              productDimensions: this.props.item.productDimensions,
              originalWidth: this.props.width,
              canvasDimensions: {
                width: this.state.width,
                height: this.state.height,
              },
            };

            return (
              <div key={layer.id}>
                {/* <Template
                  templateData={layer.config.template}
                  scaleBy={3.84}
                  showImages={true}
                  uniqueID={`mainScreen_${this.props.page}Page`}
                  clickCallback={placeholderId =>
                    this.props.templateCallback(
                      placeholderId,
                      this.props.page,
                      layer.config.template
                    )
                  }
                /> */}
                <TemplateHtml
                  swapImageFromTemplate={this.props.swapImageFromTemplate}
                  longPressSwap={this.props.longPressSwap}
                  previewMode={this.props.previewMode}
                  reRender={this.props.reRender}
                  layer={layer}
                  fontFamily={this.props?.item?.product_options?.fontFamily || "Arial"}
                  genericLayerProps={genericTemplateLayerProps}
                  specificDimensions={specificDimensions}
                  tiled={tiled}
                  realBorders={realBordersApplied}
                  lowResScaling={this.props.lowResScaling}
                  canDragAndDropAcrossRegions={
                    this.props.dragDropMode === EditorContainer.DRAG_DROP_MODES.ACROSS_REGIONS
                  }
                  dragAndDropHandlers={dragAndDropHandlers}
                  effect={isCanvas && !this.props.screenshotMode ? "canvas" : null}
                  onClickRegion={regionIndex =>
                    this.props.onSelectRegionInPhotoLayer(layer.id, regionIndex)
                  }
                  showLocketOverlays={this.props.showLocketOverlays}
                  onSwapRegionImages={(sourceRegionIndex, destinationRegionIndex) =>
                    this.props.onSwapRegionImages({
                      sourceLayerId: layer.id,
                      sourceRegionIndex,
                      destinationLayerId: layer.id,
                      destinationRegionIndex,
                    })
                  }
                  templateData={layer.config.template}
                  scaleBy={1}
                  page={this.props.page}
                  showImages={true}
                  uniqueID={`${this.props.uniquekey}_${this.props.page}Page`}
                  clickCallback={(placeholderId, doubleClick) => {
                    if (this.props.templateCallback) {
                      this.props.templateCallback(
                        placeholderId,
                        this.props.page,
                        layer.config.template,
                        doubleClick
                      );
                    }
                  }}
                  highlightText={this.props.highlightText}
                  forCharacterChecking={this.props.forCharacterChecking}
                  onOverflow={this.props.onOverflow}
                  onWrap={this.props.onWrap}
                  placeholderContent={this.props.placeholderContent}
                  placeholderIdToCheck={this.props.placeholderIdToCheck}
                />
              </div>
            );
          }

          return (
            <HtmlRendererPhotoLayer
              {...genericLayerProps}
              //ref={this.photoLayerRef}
              key={`${this.props.page}-photolayer-${this.props.uniquekey}-${layer?.id}`}
              uniqueID={`${this.props.page}-photolayer-${this.props.uniquekey}-${layer?.id}`}
              specificDimensions={specificDimensions}
              tiled={tiled}
              realBorders={realBordersApplied}
              lowResScaling={this.props.lowResScaling}
              canDragAndDropAcrossRegions={
                this.props.dragDropMode === EditorContainer.DRAG_DROP_MODES.ACROSS_REGIONS
              }
              // page={this.props.page}
              // reRender={this.props.reRender}
              dragAndDropHandlers={dragAndDropHandlers}
              effect={isCanvas && !this.props.screenshotMode ? "canvas" : null}
              onClickRegion={regionIndex =>
                this.props.onSelectRegionInPhotoLayer(layer.id, regionIndex)
              }
              showLocketOverlays={this.props.showLocketOverlays}
              onSwapRegionImages={(sourceRegionIndex, destinationRegionIndex) =>
                this.props.onSwapRegionImages({
                  sourceLayerId: layer.id,
                  sourceRegionIndex,
                  destinationLayerId: layer.id,
                  destinationRegionIndex,
                })
              }
              showBorderCanvas={this.props.showBorderCanvas}
              topCrop={isPhotoBook}
              lowResImages={this.props.lowResImages}
              disableTouchHandling={this.props.disableTouchHandling}
            />
          );
        case LAYER_TYPES.TEXT:
          if (
            !layer.config.text &&
            layer.id !== "EXTRA_TEXT_LAYER" &&
            this.props.page !== 0 &&
            !this.props.screenshotMode &&
            this.props.isInteractive
          ) {
            return (
              <HtmlRendererPlaceholderLayer
                {...genericLayerProps}
                message="Add message or leave blank"
                onClick={dimensions => this.props.onClickTextLayer(layer.id, dimensions)}
              />
            );
          } else {
            const shouldShowTextLayer =
              (layer.config.text || (layer.id === "EXTRA_TEXT_LAYER" && layer.config.text)) &&
              (this.props.screenshotMode
                ? layer.config.text !== layer.config.placeholderText
                : true) &&
              (!this.props.isInteractive
                ? layer.config.text !== layer.config.placeholderText
                : true);

            // TODO extract this
            const isATextPrintProduct = isTextPrintProduct(this.props.item.productId);
            let locked = isATextPrintProduct ? layer.config.lock : this.props.page !== 0;
            const isGreetingCard = this.props.item.productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD;
            if (shouldShowTextLayer) {
              return (
                <HtmlRendererTextLayer
                  {...genericLayerProps}
                  lock={locked}
                  isVerticallyCentered={isATextPrintProduct || isGreetingCard}
                  onChangePosition={position => this.props.onTextPositionChange(layer.id, position)}
                  onClick={dimensions => this.props.onClickTextLayer(layer.id, dimensions)}
                  onClickDelete={() => this.props.onDeleteTextLayerContents(layer.id)}
                  onClickEdit={() => this.props.onStartEditingTextLayer(layer.id)}
                />
              );
            }
          }
          break;
        case LAYER_TYPES.ADDRESS:
          if (!(this.props.item.address || this.props.item.addressBookEntry)) {
            if (!this.props.screenshotMode && !this.props.previewMode && this.props.isInteractive) {
              return (
                <HtmlRendererPlaceholderLayer
                  {...genericLayerProps}
                  message="Add name & address"
                  onClick={this.props.onClickAddressLayer}
                />
              );
            }
          } else {
            return (
              <HtmlRendererAddressLayer
                {...genericLayerProps}
                productDimensions={this.props.item.productDimensions}
                address={this.props.item.address || this.props.item.addressBookEntry}
                onClick={this.props.onClickAddressLayer}
              />
            );
          }
          break;
        case LAYER_TYPES.SIGNATURE:
          if (this.props.item.productId === 5) {
            return null;
          } else {
            if (layer.config.drawing || layer.config.avatar) {
              return (
                <HtmlRendererSignatureLayer
                  {...genericLayerProps}
                  onClick={() => this.props.onClickSignatureLayer(layer.id)}
                />
              );
            } else {
              if (
                !this.props.screenshotMode &&
                !this.props.previewMode &&
                this.props.isInteractive
              ) {
                return (
                  <HtmlRendererPlaceholderLayer
                    {...genericLayerProps}
                    message="Add signature"
                    onClick={() => this.props.onClickSignatureLayer(layer.id)}
                  />
                );
              }
              break;
            }
          }
        case LAYER_TYPES.GRAPHIC:
          //if (!this.props.screenshotMode && !this.props.previewMode) {
          return (
            <HtmlRendererGraphicLayer
              {...genericLayerProps}
              onClick={() => this.props.onDeselectTextLayer()}
            />
          );
        case LAYER_TYPES.INDICIA:
          return <HtmlRendererIndiciaLayer {...genericLayerProps} />;

        // return <Template templateData={this.props.item.}/>;
        default:
          console.warn("Renderer has no layer component for type:", layer.type);
          return null;
      }

      return null;
    });

    if (this.props.debug) {
      layers = [
        ...layers,
        layersForCurrentPage.map(layer => {
          if (layer.type === LAYER_TYPES.TEXT) {
            return [
              layer.config.rect.transformedRect && (
                <HtmlRendererRect
                  className="html-renderer-debug-layer"
                  rect={layer.config.rect.transformedRect}
                  style={{
                    backgroundColor: COLORS_PER_LAYER_TYPE.TRANSFORMED_TEXT,
                  }}
                >
                  <div className="html-renderer-debug-layer__layer-id">{layer.id}-TRANSF.RECT</div>
                </HtmlRendererRect>
              ),
              <HtmlRendererRect
                className="html-renderer-debug-layer"
                rect={layer.config.rect}
                style={{ backgroundColor: COLORS_PER_LAYER_TYPE[layer.type] }}
              >
                <div className="html-renderer-debug-layer__layer-id">{layer.id}</div>
              </HtmlRendererRect>,
            ];
          } else {
            return (
              <HtmlRendererRect
                className="html-renderer-debug-layer"
                rect={layer.config.rect}
                style={{ backgroundColor: COLORS_PER_LAYER_TYPE[layer.type] }}
              >
                <div className="html-renderer-debug-layer__layer-id">{layer.id}</div>
              </HtmlRendererRect>
            );
          }
        }),
      ];
    }

    if (this.props.item.productDimensions && this.props.showBleed) {
      const canvasDimensions = {
        width: this.state.width,
        height: this.state.height,
      };

      layers = [
        ...layers,
        <HtmlRendererBleed
          key="bleed"
          rect={{
            x: 0,
            y: 1,
            width: 1,
            height: 1,
          }}
          canvasDimensions={canvasDimensions}
          heavyBorders={this.props.width <= 250}
          productDimensions={this.props.item.productDimensions}
        />,
      ];
    }

    if (this.props.mask) {
      const canvasDimensions = {
        width: this.state.width,
        height: this.state.height,
      };

      const photosAdded = this.props.item.layers.filter(layer => {
        return layer.type === LAYER_TYPES.PHOTO && layer.config.layout[0].image !== undefined;
      });

      layers = [
        ...layers,
        <HtmlRendererMask
          addedPhoto={photosAdded.length > 0}
          key="mask"
          style={this.props.mask.style}
          rect={this.props.mask.rect}
          canvasDimensions={canvasDimensions}
          productDimensions={this.props.item.productDimensions}
          isRotated={this.props.item.isRotated}
          isCanvas={isCanvas}
        />,
      ];
    }

    const renderHtmlBorders = this.props.photoBookBorders || isPhotoBook;
    const hasBorder = isPhotoBook
      ? true
      : this.props.item.product_options && this.props.item.product_options.border;
    const shouldIncludeHtmlBorders = renderHtmlBorders && hasBorder && this.props.page !== 0;
    // console.log("renderHtmlBorders", renderHtmlBorders)
    // console.log("hasBorder", hasBorder)
    // console.log("this.props.page", this.props.page)
    // console.log("this.props.item.product_options.border", this.props.item.product_options.border)
    //console.log("Should include HTML borders", shouldIncludeHtmlBorders);
    if (shouldIncludeHtmlBorders) {
      const dimensions = {
        width: this.state.width,
        height: this.state.height,
      };

      const currentPhotoLayers = this.props.item.layers.filter(layer => {
        return (
          layer.type === LAYER_TYPES.PHOTO &&
          layer.config.layout[0].image !== undefined &&
          layer.page !== 0
        );
      });

      if (currentPhotoLayers.length > 0) {
        if (this.props.page) {
          let photoLayer = currentPhotoLayers[this.props.page - 1];
          if (currentPhotoLayers.length === 1) {
            photoLayer = currentPhotoLayers[0];
          } else {
            photoLayer = currentPhotoLayers[this.props.page - 1];
          }
          layers = [
            ...layers,
            <HtmlRendererBorder
              key="border"
              hasBorder={
                this.props.page &&
                photoLayer.config?.border?.style?.showBorder &&
                this.props.page !== 0 &&
                (!photoLayer.config?.template ||
                  photoLayer.config?.template?.name === "single-image-default-template")
              }
              style={photoLayer.config.border}
              canvasDimensions={dimensions}
              productDimensions={this.props.item.productDimensions}
            />,
          ];
        } else {
          const photoLayer = currentPhotoLayers[0];
          layers = [
            ...layers,
            <HtmlRendererBorder
              key="border"
              hasBorder={
                this.props.page &&
                photoLayer.config?.border?.style?.showBorder &&
                this.props.page !== 0
              }
              style={photoLayer.config.border}
              canvasDimensions={dimensions}
              productDimensions={this.props.item.productDimensions}
            />,
          ];
        }
      }
    }

    const classes = classNames("html-renderer", {
      "html-renderer--non-interactive": !this.props.isInteractive,
      "html-renderer--effects-black-and-white": this.props.effects || effectsApplied,
      "html-renderer--fake-border": this.props.borderedForPreviews && this.props.previewMode,
      "html-renderer--with-shadow":
        this.props.borderedForPreviews && this.props.previewMode && this.props.withShadow,
    });

    const containerClasses = classNames("html-renderer-container", {
      "html-renderer-container--no-border": this.props.mask || this.props.showBleed,
      "html-renderer-container--extra-envelope": this.props.page === "EXTRA_ENVELOPE",
      "html-renderer-container--relative": this.props.showCaptionPlaceholder,
    });

    const globalTheme = this.props.item?.product_options?.globalTheme?.style?.color;

    return (
      <div className={containerClasses} style={containerStyles}>
        {this.props.showCaptionPlaceholder && (
          <span
            style={{
              position: "absolute",
              bottom: "3%",
              zIndex: 1,
              "-webkit-transform": "translate3d(0,0,0)",
              fontSize: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Your caption here
          </span>
        )}
        {this.props.dragDropMode === EditorContainer.DRAG_DROP_MODES.ACROSS_LAYERS && (
          <ImageDragDropPreview ref={el => (this.imageDndPreview = el)} />
        )}
        {!this.props.isCoverPage && (
          <div
            data-page={this.props.page}
            className={classes}
            style={rendererStyles}
            ref={el => (this.$renderer = el)}
            onClick={this.handleClick}
          >
            {layers}
          </div>
        )}
        {this.props.isCoverPage && (
          <div style={{ height: "100%", width: "100%", backgroundColor: globalTheme }} />
        )}
      </div>
    );
  }
}

export default HtmlRenderer;
