import React from "react";
import BackgroundsContainer from "../../pages/ThemeList/BackgroundsContainer";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

class BackgroundModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBackgroundModalVisible: props.isVisible,
      selectedBackground: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible) {
      this.setState({
        isBackgroundModalVisible: this.props.isVisible,
      });
    }
  }

  closeModal = () => {
    this.setState({
      isBackgroundModalVisible: false,
    });
    if (this.props.closeBackgroundModal && typeof this.props.closeBackgroundModal === "function")
      this.props.closeBackgroundModal();
  };

  applyBackground = () => {
    if (this.state.selectedBackground){
      // this.props.setBackgroundColour(
      //   this.state.selectedBackground,
      //   this.props.pageNumberToAddBorder,
      //   false,
      //   this.state.selectedBackground.category,
      //   this.state.selectedBackground?.textColor
      // );
      this.props.setBackgroundColour(this.state.selectedBackground);
    }
    this.setState({
      selectedBackground: null,
    });
    this.closeModal()
  }

  applyBackgroundToAll = () => {
    if (this.state.selectedBackground){
      this.props.setBackgroundColourForAll(this.state.selectedBackground);
    }
    this.setState({
      selectedBackground: null,
    });
    this.closeModal()
  }

  handleChangeBackground = (background) => {
    this.setState({
      selectedBackground: background,
    });
  }

  render() {
    const { isBackgroundModalVisible } = this.state;

    return (
      <Modal
        key="backgrounds-selection-modal"
        title={""}
        isOpen={isBackgroundModalVisible}
        onClose={this.closeModal}
        hasHeader
        rightAction={<span
          style={{
            position: "absolute",
            right: 10,
            top: 2,
            pointerEvents: 'all'
          }}
        >
          <Button
            theme="dark-blue"
            priority="secondary"
            label="Apply to All"
            style={{ marginTop: "10px" }}
            onClick={this.applyBackgroundToAll}
          />  
          <Button theme="dark-blue" priority="primary" label="Apply" style={{ marginTop: "10px" }} onClick={this.applyBackground}/>
        </span>
        }
      >
        <BackgroundsContainer
          setBackgroundColour={this.props.setBackgroundColour}
          closeBackgroundsModal={this.closeModal}
          item={this.props.item}
          setActiveBackground={this.handleChangeBackground}
          selectedBackground={this.state.selectedBackground}
        />
      </Modal>
    );
  }
}

export default BackgroundModalContainer;
