import { fromJS } from "immutable";

const appValues = fromJS(require("../api-data/app-values"));
const appValuesByKey = appValues.groupBy(v => v.get("key"));

export function getAppValueByKey(key) {
  if (appValuesByKey.get(key)) {
    return appValuesByKey.get(key).first();
  } else {
    return false;
  }
}
export function getAppValueByKeyAndLocale(key, locale) {
  if (appValuesByKey.get(key)) {
    if (appValuesByKey.get(key).find(i => i.get("locale") === locale)) {
      return appValuesByKey.get(key).find(i => i.get("locale") === locale);
    } else {
      return appValuesByKey.get(key).find(i => i.get("locale") === "en_GB");
    }
  } else {
    return false;
  }
}
