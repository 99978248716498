import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Flippable.css";

function getElementContentWidth(element) {
  const styles = window.getComputedStyle(element);
  const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);

  return element.clientWidth - padding;
}

function getElementContentHeight(element) {
  const styles = window.getComputedStyle(element);
  const padding = parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);

  return element.clientHeight - padding;
}

class Flippable extends Component {
  static propTypes = {
    isFlipped: PropTypes.bool.isRequired,
    ratio: PropTypes.number.isRequired,
    front: PropTypes.node.isRequired,
    back: PropTypes.node.isRequired,
    preventScaling: PropTypes.bool,
  };

  static defaultProps = {
    isFlipped: false,
    preventScaling: false,
  };

  state = {
    width: 0,
    height: 0,
    scale: 1,
  };

  componentDidMount() {
    const parentContainer = ReactDOM.findDOMNode(this).parentElement;
    const parentContainerWidth = getElementContentWidth(parentContainer);
    this.setState({
      width: parentContainerWidth,
      height: Math.ceil(parentContainerWidth / this.props.ratio),
    });
  }

  componentDidUpdate() {
    // TODO: This hack is a horrible workaround to stop android shrinking the product
    // when the text modal is shown, find a better way of doing this
    if (this.props.preventScaling === false){
      setTimeout(() => {
        const parentContainer = ReactDOM.findDOMNode(this).parentElement;
        const parentContainerHeight = getElementContentHeight(parentContainer);
        const newScale = Math.min(1, parentContainerHeight / this.state.height);
        // console.log("preventScaling", this.props.preventScaling);
        // console.log("scale", this.state.scale);
        // console.log("newScale", newScale);
        if (this.state.scale !== newScale) { //&& newScale > 0.6
          //console.log("Setting scale to", newScale);
          this.setState({
            scale: newScale,
          });
        }
      }, 250) // Time for the text editor modal to close
    }
    
  }

  render() {
    const dimensionStyles = {
      width: this.state.width,
      height: this.state.height,
    };

    const containerStyles = {
      ...dimensionStyles,
      transform: `scale(${this.state.scale})`,
    };

    if (!dimensionStyles.width || !dimensionStyles.height) {
      return <div></div>;
    }

    const containerClasses = classNames("flip-container", {
      "flip-container--flipped": this.props.isFlipped,
    });

    const clonedProps = {
      containerWidth: this.state.width,
      containerHeight: this.state.height,
      isContainingElementFlipped: this.props.isFlipped,
    };

    return (
      <div className={containerClasses} style={containerStyles}>
        <div className="flipper">
          <div className="front" style={dimensionStyles}>
            {/* <img
              src={`${process.env.PUBLIC_URL}/images/frames/black-frame.png`}
              style={
                {
                  width: '100%',
                  zIndex: 1,
                  position: 'absolute',
                  height: 'auto',
                  opacity: 0.5,
                }
              } 
            /> */}
            {React.cloneElement(this.props.front, clonedProps)}
          </div>
          <div className="back" style={dimensionStyles}>
            {React.cloneElement(this.props.back, clonedProps)}
          </div>
        </div>
      </div>
    );
  }
}

export default Flippable;
