/* global google */

const autocompleteService = new google.maps.places.AutocompleteService();
const placesService = new google.maps.places.PlacesService(document.createElement("div"));

export function getAutocompleteSuggestions(query, countryCode = "ca") {
  return new Promise(function(resolve, reject) {
    const options = {
      input: query,
      types: ["geocode", "establishment"],
      placeIdOnly: true,
      componentRestrictions: { country: countryCode },
    };
    if (query.length) {
      autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return reject(status);
        }

        resolve(predictions);
      });
    } else {
      console.log("No query, skipping getPlacePredictions");
      resolve([]);
    }
  });
}

export function getPlaceDetails(placeId) {
  return new Promise(function(resolve, reject) {
    placesService.getDetails({ placeId }, (place, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return reject(status);
      }

      resolve(place);
    });
  });
}
