import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./BottomNavbarItem.scss";

const BottomNavbarItem = ({ label, icon, children, active, disabled, onClick }) => (
  <button
    className={classNames("bottom-navbar__item", {
      "bottom-navbar__item--active": active,
    })}
    onClick={onClick}
    disabled={disabled}
    data-gtm-element="Bottom Nav Element"
  >
    {children}
    {label && <div className="bottom-navbar__item__label">{label}</div>}
  </button>
);

BottomNavbarItem.propTypes = {
  /** The text label. */
  label: PropTypes.string,
  /** The icon that is shown above the label. */
  icon: PropTypes.string,
  /** Toggles the active highlight styling for the button. */
  active: PropTypes.bool,
  /** Makes the button disabled if set to true. */
  disabled: PropTypes.bool,
  /** Called when the `BottomNavbarItem` is clicked anywhere. */
  onClick: PropTypes.func,
};

BottomNavbarItem.defaultProps = {
  active: false,
  disabled: false,
  onClick: () => {},
};

export default BottomNavbarItem;
