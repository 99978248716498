import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { PRODUCT_TYPE_IDS, productsByProductId } from "../../data/products";
import MASKS from "../../constants/masks";
//import { PRODUCT_TYPE_IDS } from '../../data/products';
//import PAGES_BY_PRODUCT_TYPE_ID from '../../constants/pages-by-product-type';
//import * as LAYER_TYPES from '../../constants/layer-types';

import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import { PhotoTilePreviewRenderer } from "../PhotoTilePreviewRenderer/PhotoTilePreviewRenderer";

class ScreenshotRenderer extends Component {
  static propTypes = {
    itemDataUrl: PropTypes.string.isRequired,
    width: PropTypes.number,
    debug: PropTypes.bool,
  };

  static defaultProps = {
    width: 900,
    debug: false,
  };

  state = {
    itemData: null,
  };

  async componentWillMount() {
    const itemDataResponse = await axios.get(this.props.itemDataUrl);
    this.setState({
      itemData: itemDataResponse.data,
    });
  }

  render() {
    if (!this.state.itemData) {
      return null;
    }

    if (
      this.state.itemData?.productTypeId === PRODUCT_TYPE_IDS.PHOTO_TILE &&
      this.state.itemData?.rendererReferenceLayerId === "FRONT_PREVIEW"
    ) {
      return <PhotoTilePreviewRenderer itemData={this.state.itemData} width={this.props.width} />;
    }

    const item = this.state.itemData;
    const page = item.layers[0].page;
    let width = this.props.width;

    const isSinglePageWallArt = [PRODUCT_TYPE_IDS.CANVAS, PRODUCT_TYPE_IDS.PHOTO_TILE].includes(
      item.productTypeId
    );
    const product = productsByProductId.get(item.productId);
    const productSlug = isSinglePageWallArt && product.get("url_slug");
    const maskedProduct = isSinglePageWallArt;
    const bleedMasks = MASKS[isSinglePageWallArt ? productSlug : product.get("appKey")];
    const frontPreview = item.rendererReferenceLayerId === "FRONT_PREVIEW";

    return (
      <HtmlRenderer
        debug={this.props.debug}
        width={width}
        page={page}
        item={item}
        screenshotMode
        previewMode
        mask={frontPreview && maskedProduct && bleedMasks && bleedMasks.canvasPreview}
        showBorderCanvas={item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE ? false : true}
      />
    );
  }
}

export default ScreenshotRenderer;
