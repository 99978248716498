import React, { Component } from "react";
import PropTypes from "prop-types";

import * as STORAGE_KEYS from "../constants/storage-keys";

import Toast from "../components/Toast/Toast";
import { fromJS } from "immutable";

import { getProductforProductId } from "../data/products";

import { SmileOutlined } from '@ant-design/icons';

import { notification } from "antd";

class WithWipEditorItemRestore extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    source: PropTypes.string.isRequired,
    onRestore: PropTypes.func,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      isWipItemToastVisible: false,
    };

    try {
      const storedWipEditorItem = JSON.parse(localStorage.getItem(STORAGE_KEYS.WIP_EDITOR_ITEM));

      const shouldBeVisible =
        storedWipEditorItem && storedWipEditorItem.source === this.props.source;

      if (shouldBeVisible) {
        notification.open({
          message: "Continue where you left off?",
          description: `You have an unsaved ${storedWipEditorItem &&
            getProductforProductId(storedWipEditorItem.item.productId)}, click here to finish it.`,
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          onClick: () => this.restoreWipItem,
          onHidden: () => this.discardWipItem,
        });
      }

      this.state = {
        ...this.state,
        storedWipEditorItem: storedWipEditorItem && fromJS(storedWipEditorItem.item),
        itemName: storedWipEditorItem && getProductforProductId(storedWipEditorItem.item.productId),
        isWipItemToastVisible: shouldBeVisible,
      };
    } catch (err) {}
  }

  restoreWipItem = () => {
    this.importedWipItem = true;
    this.setState({
      isWipItemToastVisible: false,
    });
    this.props.onRestore(this.state.storedWipEditorItem);
  };

  discardWipItem = () => {
    if (!this.importedWipItem) {
      localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    }
    this.setState({ isWipItemToastVisible: false });
  };

  render() {
    return [
      process.env.NODE_ENV !== "development" && this.state.isWipItemToastVisible && (
        <Toast
          type="info"
          key="unsaved-editor-item"
          message={`You have an unsaved ${this.state.itemName}, click here to finish it.`}
          onClick={this.restoreWipItem}
          onHidden={this.discardWipItem}
        />
      ),
      this.props.children,
    ];
  }
}

export default WithWipEditorItemRestore;
