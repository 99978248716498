
export default {
  "Marinship": {
    frontImage: `https://eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/marinship-template.jpg`,
    allowEditing: false,
    promoCode: "MARINSHIP",
    message: "Welcome to all Marinship customers!",
    fixedAddress: {
        country: 'United States',
        county: 'CA',
        city: 'Santa Monica',
        first_name: 'Test Name',
        postcode: '90401-1106',
        line1: '101 Wilshire Boulevard',
        line2: 'Street 2'
    },
    rearMessage: "Marinship Working Waterfront's light industry, marine services and art provide vital services and keep Sausalito salty.",
    rearGraphicLayer: {
      config: {
        s3_keys: ['//eclipse-engine.s3.amazonaws.com/partner_logos/cf11493c-b3e2-4002-879a-a39ec334b81f/marinship-rear.png'],
        thumbnails: ['//eclipse-engine.s3.amazonaws.com//partner_logos/cf11493c-b3e2-4002-879a-a39ec334b81f/marinship-rear.png'],
        rect: {
          x: 0.035,
          y: 0.23,
          width: 0.42,
          height: 0.15,
        },
      },
      created_at: "2013-07-16T21:34:20+01:00",
      design_id: 2,
      id: 117,
      page: 1,
      updated_at: "2016-10-04T11:58:09+01:00",
      zindex: 0,
      type: "Layer::Graphic",
    }
  },
  "linkedin": {
    frontImage: null,
    allowEditing: true,
    promoCode: "LINKEDIN",
    message: "Welcome to LinkedIn!",
    fixedAddress: null,
    rearMessage: "",
    rearGraphicLayer: {
      config: {
        s3_keys: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/app/postsnap/linked-in-icon.png'],
        thumbnails: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/app/postsnap/linked-in-icon.png'],
        rect: {
          x: 0.51,
          y: 0.16,
          width: 0.062,
          height: 0.087,
        },
      },
      created_at: "2013-07-16T21:34:20+01:00",
      design_id: 2,
      id: 117,
      page: 1,
      updated_at: "2016-10-04T11:58:09+01:00",
      zindex: 0,
      type: "Layer::Graphic",
    }
  },
  "ids": {
    frontImage: `https://eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/ids_template.png`,
    allowEditing: false,
    onlyProductIds: [5],
    promoCode: "IDS",
    message: "Welcome to IDS!",
    fixedAddress: {
        country: 'United Kingdom',
        county: 'East Sussex',
        city: 'Brighton',
        first_name: 'C/O Alumni Relations Office',
        postcode: 'BN1 9RE',
        line1: 'Institute of Development Studies',
        line2: 'Library Road'
    },
    rearMessage: "",
    rearGraphicLayer: {
      config: {
        s3_keys: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/ids_rear_graphic.png'],
        thumbnails: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/ids_rear_graphic.png'],
        rect: {
          x: 0.045,
          y: 0.23,
          width: 0.44,
          height: 0.14,
        },
      },
      created_at: "2013-07-16T21:34:20+01:00",
      design_id: 2,
      id: 117,
      page: 1,
      updated_at: "2016-10-04T11:58:09+01:00",
      zindex: 0,
      type: "Layer::Graphic",
    }
  },
  "closeparent": {
    frontImage: ``,
    allowEditing: true,
    onlyProductIds: [5],
    promoCode: "SENDLOVEFEB",
    message: "<h3 style=\"font-size: 14px; text-align:center;font-weight:normal;margin-top:8px;\">Send a little love this Feb with Close Parent and send a FREE real personalised PostSnap postcard!</h3><br/>Thank you for taking part in our Valentines Challenge! This Feb, Close have collaborated with PostSnap to give the first 120 people the opportunity to send your #CloseMoment to a beloved friend or relative on us. Simply upload your photo to the blank template and we will send your image with our Close border as shown below! Remember to leave a little room around the edges.",
    fixedAddress: null,
    rearMessage: "",
    rearGraphicLayer: {
      config: {
        s3_keys: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_logo.png'],
        thumbnails: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_logo.png'],
        rect: {
          x: 0.,
          y: 0.20,
          width: 0.54,
          height: 0.19,
        },
      },
      created_at: "2013-07-16T21:34:20+01:00",
      design_id: 2,
      id: 117,
      page: 1,
      updated_at: "2016-10-04T11:58:09+01:00",
      zindex: 0,
      type: "Layer::Graphic",
    }
  },
  "cp": {
    frontImage: ``,
    allowEditing: true,
    onlyProductIds: [5],
    promoCode: "SENDLOVE50",
    message: "<h3 style=\"font-size: 14px; text-align:center; font-weight:normal; margin-top:8px;\">Send a little love this Feb with Close Parent and send a real personalised PostSnap postcard at <strong>50% OFF!</strong></h3><br/>Thank you for taking part in our Valentines Challenge! This Feb, Close have collaborated with PostSnap to give you the opportunity to send your #CloseMoment to a beloved friend or relative on us. Simply upload your photo to the blank template and we will send your image with our Close border as shown below! Remember to leave a little room around the edges.",
    fixedAddress: null,
    rearMessage: "",
    rearGraphicLayer: {
      config: {
        s3_keys: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_logo.png'],
        thumbnails: ['//eclipse-engine.s3-eu-west-1.amazonaws.com/partner_logos/close_parent_logo.png'],
        rect: {
          x: 0.,
          y: 0.20,
          width: 0.54,
          height: 0.19,
        },
      },
      created_at: "2013-07-16T21:34:20+01:00",
      design_id: 2,
      id: 117,
      page: 1,
      updated_at: "2016-10-04T11:58:09+01:00",
      zindex: 0,
      type: "Layer::Graphic",
    }
  },
  "hsc": {
    allowEditing: true,
    onlyProductIds: [5],
    promoCode: "HSCUTAH",
    fixedAddress: {
        country: 'United States',
        county: 'UT',
        city: 'Salt Lake City',
        first_name: 'Gordon Crabtree',
        last_name: 'Chief Executive Officer',
        company_name: 'Hospital Administration',
        postcode: '84132-0001',
        line1: 'University of Utah Hospitals and Clinics',
        line2: '50 N Medical Dr',
    },
    rearMessage: "",
    authAs: "cpfqwnZuuyWS9zotZBAF"
  },
};
//s3_keys: [`${process.env.PUBLIC_URL}/images/marinship-template.jpg`],
//https://eclipse-engine.s3.amazonaws.com/partner_logos/cf11493c-b3e2-4002-879a-a39ec334b81f/marinship-rear.png
//`${process.env.PUBLIC_URL}/images/marinship-template.jpg`