import React from "react";

import generateMultilineAddress from "../../lib/generate-multiline-address";

import "./EditorCanvasPackage.scss";

const EditorCanvasPackage = ({ address, onClickAddress, aspectRatio }) => {
  const width = `85vw`;
  const height = `calc(${width} * ${aspectRatio})`;
  return (
    <div
      className="editor-canvas-package animated fadeInLeft"
      style={{width: width, height: height}}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/cardboard.jpg`}
        alt=""
        className="editor-canvas-package__background"
      />
      <div className="editor-canvas-package__label animated rotateInDownLeft">
        <img
          src={`${process.env.PUBLIC_URL}/images/indicia.png`}
          alt=""
          className="editor-canvas-package__stamp"
        />
        <div className="editor-canvas-package__address" onClick={onClickAddress}>
          <p
            style={{
              lineHeight: 1.3,
              fontSize: "0.7em",
              whiteSpace: "pre-line",
            }}
          >
            {address ? generateMultilineAddress(address).join("\n") : "Tap to add name & address"}
          </p>
        </div>
      </div>
    </div>
  );
};

EditorCanvasPackage.propTypes = {};

EditorCanvasPackage.defaultProps = {};

export default EditorCanvasPackage;
