import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import "./ImageDragDropPreview.scss";

class ImageDragDropPreview extends React.Component {
  static propTypes = {
    ref: PropTypes.func,
  };

  updateImage = imageUrl => {
    this.dragPreviewContainer.style.backgroundImage = `url(${imageUrl})`;
  };

  updateCoordinates = ({ x, y }) => {
    this.dragPreviewContainer.style.left = `${x}px`;
    this.dragPreviewContainer.style.top = `${y}px`;
  };

  show = () => {
    this.dragPreviewContainer.classList.add("image-dnd-preview--visible");
  };

  hide = () => {
    this.dragPreviewContainer.classList.remove("image-dnd-preview--visible");
  };

  render() {
    const node = (
      <div ref={el => (this.dragPreviewContainer = el)} className="image-dnd-preview"></div>
    );
    return ReactDOM.createPortal(node, document.getElementById("root"));
  }
}

export default ImageDragDropPreview;
