import { fromJS } from "immutable";

const allPricingSchemes = fromJS(require("../api-data/pricing-schemes"));
const pricingSchemesByProductId = allPricingSchemes.groupBy(s => s.get("product_id"));

export function getPricingSchemeForSchemeId({schemeId, currency}) {
  return allPricingSchemes.find(scheme => scheme.get('id') === schemeId).withMutations(scheme => {
    scheme.set("cost", scheme.getIn(["cost", currency]));
  });
}

export function getPricingSchemeForProductAndQuantity({ productId, quantity = 1, currency = "GBP", additionalPages, additionalPagesCost }) {
  const applicablePricingScheme = pricingSchemesByProductId.get(productId).find(scheme => {
    return (
      quantity >= (scheme.get("min_qty") || 0) && quantity <= (scheme.get("max_qty") || Infinity)
    );
  });

  if (applicablePricingScheme) {
    return applicablePricingScheme.withMutations(scheme => {
      let totalCost = scheme.getIn(["cost", currency])
      if (additionalPages){
        totalCost = scheme.getIn(["cost", currency]) + (additionalPages * additionalPagesCost)
        scheme.set("additionalCost", (additionalPages * additionalPagesCost))
        scheme.set("additionalPages", additionalPages)
      }
      scheme.set("cost", totalCost);
    });
  }
}

export function getNextPricingSchemeForProductAndQuantity({
  productId,
  quantity,
  currency = "GBP",
}) {
  const currentPricingScheme = pricingSchemesByProductId.get(productId).find(scheme => {
    return (
      quantity >= (scheme.get("min_qty") || 0) && quantity <= (scheme.get("max_qty") || Infinity)
    );
  });
  if (currentPricingScheme && currentPricingScheme.get("max_qty")) {
    const nextPricingSchemes = pricingSchemesByProductId
      .get(productId)
      .sortBy(scheme => scheme.get("min_qty"))
      .find(scheme => {
        return scheme.get("min_qty") > currentPricingScheme.get("max_qty");
      });
    if (nextPricingSchemes) {
      return nextPricingSchemes.withMutations(scheme => {
        scheme.set("cost", scheme.getIn(["cost", currency]));
      });
    }
  }
}

export function getLowestCostPricingSchemeForProduct({ productId, currency = "GBP"}) {
  const lowestScheme = pricingSchemesByProductId.get(productId).sortBy((scheme) => scheme.getIn(["cost", currency]));
  return lowestScheme.first().withMutations(scheme => {
    scheme.set("cost", scheme.getIn(["cost", currency]));
  });
}

export function getPricingTableForProduct({ productId, currency = "GBP"}) {
  return pricingSchemesByProductId.get(productId).sortBy((scheme) => scheme.get("min_qty"));
}

export function getPackPricingSchemesForProduct({productId, currency = "GBP"}) {
  return pricingSchemesByProductId.get(productId).filter((scheme) => scheme.get('pack_pricing')).map((packPricing) => {
    return packPricing.withMutations(scheme => {
      scheme.set("cost", scheme.getIn(["cost", currency]));
    });
  }).sortBy(packPrice => packPrice.get('pack_quantity'))
}

export function getPackPricingSchemeForProductAndPackQuantity({productId, packQuantity, currency = "GBP"}) {
  const applicablePricingScheme = getPackPricingSchemesForProduct({productId: productId, currency: currency}).find(scheme => {
    return (
      packQuantity === scheme.get("pack_quantity")
    );
  });
  if (applicablePricingScheme) {
    return applicablePricingScheme
  }
}