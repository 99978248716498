import React from "react";
import MainContent from "../../../components/MainContent/MainContent";
import Button from "../../../components/Button/Button";
import Currency from "../../../components/Formatters/Currency";
import generateMultilineAddress from "../../../lib/generate-multiline-address";

import {
  getPostageSchemeForProductTypeAndDestination,
  getAllPostageSchemesForProductTypeAndDestination,
} from "../../../data/postage-schemes";

import { InfoOutlined } from '@ant-design/icons';

import './CheckoutShippingOption.scss';
import { getProductTypeforProductTypeId, singleShippingAddressProductTypes } from "../../../data/products";


import { Modal } from "antd"


class CheckoutShippingOption extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeShippingOption: this.props.checkoutShippingOption || null,
      shippingAddress: this.props.checkoutShippingAddress || null,
      shippingOptions: null,
      activeProductTypeId: this.props.activeProductTypeId || null,
      hasPopulatedOptions: false,
      multipleProductTypesToShow: false,
      isShippingInfoModalOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    this.populateShippingOptionsIfRequired()
  }

  componentDidMount() {
    this.populateShippingOptionsIfRequired()
  }


  getBasketItemForProductType = (productTypeId) => {
    return this.getBasketItemsForProductType(productTypeId).first()
  }

  getBasketItemsForProductType = (productTypeId) => {
    return this.props.items.filter(item => item.get("productTypeId") === parseInt(productTypeId));
  }

  getTotalForWeightForProductType = (productTypeId) => {
    const itemForProductType = this.getBasketItemForProductType(productTypeId)
    let fallbackWeight = 1;
    let quantity = itemForProductType.get("quantity");
    // TODO get total weight of all items
    let totalWeight = quantity * (itemForProductType.get("weight") === undefined ? fallbackWeight : itemForProductType.get("weight"));
    return totalWeight
  }

  getShippingOptionsFor = (productTypeId) => {
    const totalWeightForItemsWithProductType = this.getTotalForWeightForProductType(productTypeId)

    const productIdsForProductTypeId = this.getBasketItemsForProductType(productTypeId).map(item => item.get('productId'));
    const uniqProductIds = [...new Set(productIdsForProductTypeId)]

    let shippingOptions = getAllPostageSchemesForProductTypeAndDestination({
      productTypeId: productTypeId,
      destinationCountry: this.props.checkoutShippingAddress.get('country'),
      currency: this.props.currency,
      weight: totalWeightForItemsWithProductType,
      productIds: uniqProductIds,
      postcode: this.props.checkoutShippingAddress.get('postcode')
    });

    return shippingOptions
  }

  getActivePostageSchemeIdFor = (productTypeId) => {
    let activePostageSchemeId
    if(this.props.checkoutShippingOption && this.props.checkoutShippingOption.has(productTypeId.toString())){
      activePostageSchemeId = this.props.checkoutShippingOption.get(productTypeId.toString())
    } else {
      const totalWeightForItemsWithProductType = this.getTotalForWeightForProductType(productTypeId)
      const productIdsForProductTypeId = this.getBasketItemsForProductType(productTypeId).map(item => item.get('productId'));
      const uniqProductIds = [...new Set(productIdsForProductTypeId)]
      console.log("uniqProductIds", uniqProductIds)
      const postageScheme = getPostageSchemeForProductTypeAndDestination({
        productTypeId: productTypeId,
        destinationCountry: this.props.checkoutShippingAddress.get('country'),
        currency: this.props.currency,
        weight: totalWeightForItemsWithProductType,
        productIds: uniqProductIds
      });
      activePostageSchemeId = postageScheme.get('id')
    }

    return activePostageSchemeId

  }

  showInfoModal = () => {
    this.setState({
      isShippingInfoModalOpen: true
    })
  }

  hideInfoModal = () => {
    this.setState({
      isShippingInfoModalOpen: false
    })
  }


  populateShippingOptionsIfRequired() {
    if(this.state.hasPopulatedOptions === true){
      console.log("NOT Populating shipping options... already populated")
      return false
    }

    if (this.props.checkoutShippingAddress === null){
      console.log("NOT Populating shipping options... dont have shipping adress yet")
      return false
    }

      
    console.log("Populating shipping options...")

    const activeProductType = this.props.activeProductTypeId || this.state.activeProductTypeId

    if (activeProductType){
      const allShippingOptions = this.getShippingOptionsFor(activeProductType)
      let activePostageSchemeId = this.getActivePostageSchemeIdFor(activeProductType)
      if (!activePostageSchemeId){
        console.log("No postage scheme set, set to the default")
      }

      let shippingOptionForProductType = {}

      shippingOptionForProductType[activeProductType] = activePostageSchemeId
      this.setState({
        activeProductTypeId: activeProductType,
        hasPopulatedOptions: true,
        shippingOptions: allShippingOptions,
        activeShippingOption: shippingOptionForProductType
      })

    } else{
      console.log("No active product, populate all options for all bag product types eligible")
      const singleShipmentItems = this.props.items.filter(item => {
        return (
          singleShippingAddressProductTypes.includes(item.get('productTypeId'))
        );
      });

      const productTypesNeedingShippingOptions = Object.keys(singleShipmentItems.groupBy((item) => item.get('productTypeId')).toJS())
  
      let shippingOptionsForProductTypes = {}
      let activeShippingOptionForProductTypes = {}

      productTypesNeedingShippingOptions.forEach((productTypeId) => {
        let allShippingOptions = this.getShippingOptionsFor(parseInt(productTypeId))
        let activePostageSchemeId = this.getActivePostageSchemeIdFor(parseInt(productTypeId))

        activeShippingOptionForProductTypes[productTypeId] = activePostageSchemeId
        shippingOptionsForProductTypes[productTypeId] = allShippingOptions
      })

      if(productTypesNeedingShippingOptions.length > 1){
        this.setState({
          hasPopulatedOptions: true,
          multipleProductTypesToShow: true,
          allShippingOptions: shippingOptionsForProductTypes,
          allActiveShippingOptions: activeShippingOptionForProductTypes,
        })
      } else{
        let activeProductTypeForMultiple = parseInt(productTypesNeedingShippingOptions[0])
        this.setState({
          activeProductTypeId: activeProductTypeForMultiple,
          hasPopulatedOptions: true,
          multipleProductTypesToShow: false,
          shippingOptions: shippingOptionsForProductTypes[activeProductTypeForMultiple],
          activeShippingOption: activeShippingOptionForProductTypes
        })
      }
      
    }

  }


  handleChangeShippingOption = (optionId) => {
    const newActiveShippingOption = {...this.state.activeShippingOption, ...{[this.state.activeProductTypeId] : optionId}}
    this.setState({
      activeShippingOption: newActiveShippingOption
    }, () => {
      this.props.onChangeShippingOption(optionId, this.state.activeProductTypeId)
    })
  }

  handleChangeShippingOptionForProductTypeId = (optionId, productTypeId) => {
    const newActiveShippingOption = {...this.state.allActiveShippingOptions, ...{[productTypeId] : optionId}}
    this.setState({
      allActiveShippingOptions: newActiveShippingOption
    }, () => {
      this.props.onChangeShippingOption(optionId, productTypeId)
    })
  }

  handleDone = () => {
    if(this.state.multipleProductTypesToShow){
      Object.entries(this.state.allActiveShippingOptions).forEach(entry => {
        const [productTypeId, shippingOptionId] = entry;
        this.props.onSaveShippingOption(shippingOptionId, parseInt(productTypeId))
      })
    } else {
      this.props.onSaveShippingOption(this.state.activeShippingOption[this.state.activeProductTypeId], this.state.activeProductTypeId)
    }
    
  }

  render() {
    const shippingAddress = this.props.checkoutShippingAddress
    const hasPopulatedOptions = this.state.hasPopulatedOptions
    const multipleProductTypesToShow = this.state.multipleProductTypesToShow

    let hasMoreThanOneShippingOption = false
    let hasOneShippingOption = false
    let activePostageSchemeId = null
    let onlyShippingOption = false
    let shippingOptions = false

    let productTypeName = ""

    if (hasPopulatedOptions){

      if(!multipleProductTypesToShow){
        shippingOptions = this.state.shippingOptions
        activePostageSchemeId = this.state.activeShippingOption[this.state.activeProductTypeId]
        hasMoreThanOneShippingOption = this.state.shippingOptions && this.state.shippingOptions.toJS().length > 1
        hasOneShippingOption = this.state.shippingOptions && this.state.shippingOptions.toJS().length === 1
        onlyShippingOption = hasOneShippingOption && this.state.shippingOptions.toJS()[0];
        productTypeName = getProductTypeforProductTypeId(this.state.activeProductTypeId).get('name')
      } else{
        shippingOptions = this.state.allShippingOptions
      }
    }


    const clarifySingleShipmentInfo = this.props.items.some((item) => !singleShippingAddressProductTypes.includes(item.get('productTypeId')))

    const infoIcon = (
      <span className="info-icon">
        <span onClick={this.showInfoModal}>
          <InfoOutlined style={{fontSize: '18px'}}/>
        </span>
      </span>
    )

    return (
      <MainContent centerContent padded>
        <div className="restricted-width-modal-content">
          <Modal footer={null} title="Shipping" visible={this.state.isShippingInfoModalOpen} onOk={this.hideInfoModal} onCancel={this.hideInfoModal}>
            <p>We ship with Royal Mail, USPS, DPD, UPS and FEDEX depending upon your order and address.</p>
            <p>Shipping costs depend upon size, weight, quantity and shipping type and are calculated before checkout.</p>
            <p>Items may arrive separately.</p>
            <p>Standard shipping is available for all products and we offer the option of a faster express service in the UK for some products.</p>
            <p>All delivery times are best estimates counted in working days (excluding weekends and national holidays) from order <strong>and take into account production times.</strong> Production times vary from product to product as each order is unique and made just for you.</p>
            <p>Orders placed after <strong>1PM</strong> typically enter production on the next working day.</p>          
          </Modal>
          <div className="shipping-summary__container">
              <div className="shipping-summary__section">
                <h2>Ship To</h2>
                {(!!shippingAddress && shippingAddress !== null) && (
                  <div className="shipping-summary__shipping-address">
                    <p>
                      {generateMultilineAddress(shippingAddress.toJS()).join("\n")}
                    </p>
                    <Button
                      theme="dark-blue"
                      label="Edit"
                      size="small"
                      priority="secondary"
                      onClick={() => this.props.onEditThisAddress(shippingAddress)}
                    />       
                  </div>
                )}
                {clarifySingleShipmentInfo && (
                  <p className="red-text">You have cards in your basket which will be sent direct to your recipient at the address you’ve previously entered</p>
                )}
              </div>
              {(hasMoreThanOneShippingOption && !multipleProductTypesToShow) && (
                <div className="shipping-summary__section">
                  <h2>
                    Shipping Method - {productTypeName}
                    {infoIcon}
                  </h2>
                  <div className="shipping-summary__shipping-options">
                    {!!shippingOptions && shippingOptions.toJS().map(shippingOption => {
                      const optionCount = shippingOptions.toJS().size
                      return (
                        <div className="shipping-summary__shipping-option" onClick={() => this.handleChangeShippingOption(shippingOption.id)}>
                          <div className="shipping-option__bullet">
                            <div className={`pretty p-default p-round ${optionCount === 1 ? 'grey' : ''}`}>
                              <input
                                type="radio"
                                checked={activePostageSchemeId === shippingOption.id}
                                id={`postage-option-${shippingOption.id}`}
                                readOnly
                              />
                              <div className="state">
                                <label></label>
                              </div>
                            </div>
                          </div>
                          <div className="shipping-option__text">
                            <span className="shipping-option__title">{shippingOption.description}</span>
                            <span className="shipping-option__description">
                              {shippingOption.service_description}
                            </span>
                          </div>
                          <div className="shipping-option__price"><Currency amount={shippingOption.cost} showFree/></div>
                          <div className="shipping-option__info">   
                          </div>
                        </div>
                      )
                    })}
                    <Button
                      theme="dark-blue"
                      label="Next"
                      block
                      onClick={this.handleDone}
                    />
                  </div>
                </div>
              )}
              {multipleProductTypesToShow && (
                <div className="shipping-summary__section">
                  <h2>
                    Shipping Methods
                    {infoIcon}
                  </h2>
                  <div className="shipping-summary__shipping-options">
                    {!!shippingOptions && Object.entries(shippingOptions).map(shippingOptionForProductTypes => {
                      const [productTypeId, shippingOptionForProductType] = shippingOptionForProductTypes;
                      const activePostageSchemeId = this.state.allActiveShippingOptions[productTypeId]
                      const optionCount = shippingOptionForProductType.size
                      let options =  shippingOptionForProductType.toJS().map(shippingOption => {
                        return (
                          <div>
                            <div className="shipping-summary__shipping-option multiple" onClick={() => this.handleChangeShippingOptionForProductTypeId(shippingOption.id, productTypeId)}>
                              <div className="shipping-option__bullet">
                                <div className={`pretty p-default p-round ${optionCount === 1 ? 'grey' : ''}`}>
                                  <input
                                    type="radio"
                                    checked={activePostageSchemeId === shippingOption.id}
                                    id={`postage-option-${shippingOption.id}`}
                                    readOnly
                                  />
                                  <div className="state">
                                    <label></label>
                                  </div>
                                </div>
                              </div>
                              <div className="shipping-option__text">
                                <span className="shipping-option__title">{shippingOption.description}</span>
                                <span className="shipping-option__description">
                                  {shippingOption.service_description}
                                </span>
                              </div>
                              <div className="shipping-option__price"><Currency amount={shippingOption.cost} showFree/></div>
                            </div>
                          </div>
                        )
                      })
                      return (
                        <>
                          <h2>{getProductTypeforProductTypeId(parseInt(productTypeId)).get('name')}</h2>
                          <div className="shipping-summary__shipping-option-group multiple">
                            {options}
                          </div>
                        </>
                      )
                    })}
                    <Button
                      theme="dark-blue"
                      label="Next"
                      block
                      onClick={this.handleDone}
                    />
                  </div>
                </div>
              )}
              {hasOneShippingOption && (
                <div className="shipping-summary__section">
                  <h2>
                    Shipping Method
                    {infoIcon}
                  </h2>
                  <div className="shipping-summary__shipping-options">
                    {onlyShippingOption && (
                      <div className="shipping-summary__shipping-option multiple">
                        <div className="shipping-option__bullet">
                          <div className={`pretty p-default p-round grey`}>
                            <input
                              type="radio"
                              checked={true}
                              id={`postage-option-${onlyShippingOption.id}`}
                              readOnly
                            />
                            <div className="state">
                              <label></label>
                            </div>
                          </div>
                        </div>
                        <div className="shipping-option__text">
                          <span className="shipping-option__title">{onlyShippingOption.description}</span>
                          <span className="shipping-option__description">
                            {onlyShippingOption.service_description}
                          </span>
                        </div>
                        <div className="shipping-option__price"><Currency amount={onlyShippingOption.cost} showFree/></div>
                      </div>
                    )}
                    <Button
                      theme="dark-blue"
                      label="Next"
                      block
                      onClick={this.handleDone}
                    />
                  </div>
                </div>
              )}
            </div>
        </div>
      </MainContent>
    );
  }
}

CheckoutShippingOption.propTypes = {};
CheckoutShippingOption.defaultProps = {};

export default CheckoutShippingOption;
