import React from "react";
import { Link } from "react-router-dom";
import { routeCreators } from "../../lib/routes";

import MainContent from "../../components/MainContent/MainContent";
import Currency from "../../components/Formatters/Currency";
import S3Image from "../../components/S3Image/S3Image";

import "./_ProductTypeLandingPage.scss";

const DIMENSIONS_INDICATOR_WIDTH = 16;

function createProductEntry(product, productType) {
  const dimensionsIndicatorStyling = {
    width: DIMENSIONS_INDICATOR_WIDTH,
    height: DIMENSIONS_INDICATOR_WIDTH / (product.get("width") / product.get("height")),
  };
  return (
    <Link
      to={routeCreators.productLandingPage(productType, product.get("url_slug"))}
      key={product.get("id")}
      className="product-type-landing-page__product"
    >
      {product.get('preview_s3_key') ? (
        <div className="product-type-landing-page__product-preview-img">
          <S3Image storageKey={product.get("preview_s3_key")} className="item-preview" />
        </div>
      ) : (
        <div
          className="product-type-landing-page__product-dimensions-indicator"
          style={dimensionsIndicatorStyling}
        />
      )}
      
      <div className="product-type-landing-page__product-info">
        <div className="product-type-landing-page__product-name">{product.get("name")}</div>
        <div className="product-type-landing-page__product-pricing">
          From <Currency amount={product.getIn(["pricingScheme", "cost"])} />
        </div>
      </div>
      {/* <ProductPreview product={product} showPricingInfo /> */}
    </Link>
  );
}

const ProductTypeLandingPage = ({ products, productType }) => {
  const productsWithoutCustoms = products.filter(product => !product.get("custom_print"));
  const nonCollageProducts = productsWithoutCustoms.sortBy((product) => product.get('display_order')).filter(product => !product.get("supports_collages"));
  const collageProducts = productsWithoutCustoms.filter(product => product.get("supports_collages"));
  return (
    <MainContent padded scrollable className="product-type-landing-page">
      <h2 style={{color: '#4C5666', fontWeight: '400',textAlign: 'center'}}>Choose a Product</h2>
      {collageProducts.size > 0 && (
        <React.Fragment>
          <br />
          <h3 className="product-type-landing-page__category-title">Collage products</h3>
          <div className="product-type-landing-page__grid">
            {collageProducts.map(product => createProductEntry(product, productType))}
          </div>
        </React.Fragment>
      )}

      {collageProducts.size > 0 && (
        <h3 className="product-type-landing-page__category-title">Regular products</h3>
      )}
      <div className="product-type-landing-page__grid">
        {nonCollageProducts.map(product => createProductEntry(product, productType))}
      </div>
    </MainContent>
  );
};

export default ProductTypeLandingPage;
