import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import interact from "interactjs";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import {
  PRODUCT_TYPE_IDS,
  isCaptionPrintProduct
} from "../../data/products";

import { STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO } from "../../constants/text-style-options";
import HtmlRendererRect from "./HtmlRendererRect";
import { isTouchEnabled } from "../../lib/platform-and-feature-detection";
import roundToNearestHalf from "../../lib/round-to-nearest-half";

function calculateDegrees(coordinates) {
  const radians = Math.atan2(coordinates.mousex, coordinates.mousey);
  return radians * (180 / Math.PI) * -1 + 90;
}

const MIN_LAYER_WIDTH_SCREEN_PERCENTAGE_ON_RESIZE = 0.15;

class HtmlRendererTextLayer extends React.Component {
  static propTypes = {
    onTextPositionChange: PropTypes.func,
    onClickEdit: PropTypes.func,
    isVerticallyCentered: PropTypes.bool,
    debug: PropTypes.bool,
    false: PropTypes.bool,
  };

  static defaultProps = {
    onTextPositionChange: () => {},
    isVerticallyCentered: false,
    debug: false,
    highlightText: false
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      isRotating: false,
      isResizing: false,
      isMoving: false,
      rotation: props.config.rect.rotation || 0,
      contentFontSizeOverride: null,
      //movedUpFromBottom: false
    };
  }

  prevX = null;

  componentDidMount() {
    this._isMounted = true;
    const $node = ReactDOM.findDOMNode(this);
    if (!this.props.lock) {
      interact($node).draggable({
        onstart: this.handleClick,
        // call this function on every dragmove event
        onmove: event => {
          if (this.state.isRotating || this.state.isResizing) {
            return;
          }

          const target = event.target;
          const originalX = parseFloat(target.getAttribute("data-x")) || 0;
          const originalY = parseFloat(target.getAttribute("data-y")) || 0;
          let x = originalX + event.dx;
          let y = originalY + event.dy;

          const rendererBoundingRect = this.props.rendererDOMNode.getBoundingClientRect();
          const textContainerBoundingRect = this.textContainer.getBoundingClientRect();
    
          let rendererBoundingRectBottom = rendererBoundingRect.bottom * 0.97

          const isOutOfLeftBounds = textContainerBoundingRect.left < rendererBoundingRect.left;
          const isOutOfRightBounds =
            textContainerBoundingRect.left >
            rendererBoundingRect.left +
              rendererBoundingRect.width -
              textContainerBoundingRect.width;
          const isOutOfTopBounds = textContainerBoundingRect.top < rendererBoundingRect.top;
          const isOutOfBottomBounds =
            textContainerBoundingRect.top >
            rendererBoundingRect.top +
              rendererBoundingRect.height -
              textContainerBoundingRect.height;

          if ((isOutOfLeftBounds && event.dx < 0) || (isOutOfRightBounds && event.dx > 0)) {
            x = originalX;
          }

          if ((isOutOfTopBounds && event.dy < 0) || (isOutOfBottomBounds && event.dy > 0)) {
            y = originalY;
          }

          if (isOutOfBottomBounds) {
            const amountOfOutOfBoundPixelsOnBottom = Math.max(
              0,
              textContainerBoundingRect.bottom - rendererBoundingRectBottom
            );
            if (amountOfOutOfBoundPixelsOnBottom > 1) {
              y = originalY - amountOfOutOfBoundPixelsOnBottom;
            }
          }

          if (isOutOfTopBounds) {
            const amountOfOutOfBoundPixelsOnTop = Math.max(
              0,
              textContainerBoundingRect.top - rendererBoundingRect.top
            );
            if (amountOfOutOfBoundPixelsOnTop > 1) {
              y = originalY + amountOfOutOfBoundPixelsOnTop;
            }
          }

          if (isOutOfLeftBounds) {
            const amountOfOutOfBoundPixelsOnLeft = Math.max(
              0,
              textContainerBoundingRect.left - rendererBoundingRect.left
            );
            if (amountOfOutOfBoundPixelsOnLeft > 1) {
              x = originalX + amountOfOutOfBoundPixelsOnLeft;
            }
          }

          if (isOutOfRightBounds) {
            const amountOfOutOfBoundPixelsOnRight = Math.max(
              0,
              textContainerBoundingRect.right - rendererBoundingRect.right
            );
            if (amountOfOutOfBoundPixelsOnRight > 1) {
              x = originalX - amountOfOutOfBoundPixelsOnRight;
            }
          }

          let rotationDegrees = 0;

          const transformValue = target.style.transform || target.style.WebkitTransform;
          if (transformValue && transformValue.includes("rotate")) {
            rotationDegrees = /rotate\((.*)deg\)/g.exec(transformValue)[1];
          }

          // translate the element
          target.style.WebkitTransform = target.style.transform = `translate(${x.toFixed(
            2
          )}px, ${y.toFixed(2)}px) rotate(${rotationDegrees}deg)`;

          // update the position attributes
          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
        // call this function on every dragend event
        onend: event => {
          if (this.state.isRotating || this.state.isResizing) {
            return;
          }

          const x = (parseFloat(event.target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(event.target.getAttribute("data-y")) || 0) + event.dy;
          const xDeltaPercentage = x / this.props.canvasDimensions.width;
          const yDeltaPercentage = y / this.props.canvasDimensions.height;
          const newPosition = {
            x: this.props.config.rect.x + xDeltaPercentage,
            y: this.props.config.rect.y - yDeltaPercentage,
          };

          event.target.removeAttribute("data-x");
          event.target.removeAttribute("data-y");
          let rotationDegrees = 0;

          const transformValue = event.target.style.transform || event.target.style.WebkitTransform;
          if (transformValue && transformValue.includes("rotate")) {
            rotationDegrees = /rotate\((.*)deg\)/g.exec(transformValue)[1];
          }

          event.target.style.WebkitTransform = event.target.style.transform = `rotate(${rotationDegrees}deg)`;

          this.props.onChangePosition(newPosition);
        },
      });

      if (
        !this.props.lock &&
        this.props.currentEditorStep &&
        this.props.currentEditorStep === "PRODUCT_FRONT"
      ) {
        setTimeout(() => {
          const updatedDimensions = this.getUpdatedTextRect();
          this.props.onChangePosition(updatedDimensions);
        }, 300);
      }

      if (isTouchEnabled) {
        window.addEventListener("touchmove", this.handleMouseOrTouchMove);
        window.addEventListener("touchend", () => {
          this.stopRotating();
          this.stopResizing();
          this.stopMoving();
        });
      } else {
        window.addEventListener("mousemove", this.handleMouseOrTouchMove);
        window.addEventListener("mouseup", () => {
          this.stopRotating();
          this.stopResizing();
          this.stopMoving();
        });
      }
    }

    this.overrideFontSizeIfNecessary();
  }

  componentDidUpdate(prevProps) {
    const propsToUpdateFor = ["text", "size", "font"];

    const shouldUpdatePosition =
      !isEqual(
        pick(prevProps.config, propsToUpdateFor),
        pick(this.props.config, propsToUpdateFor)
      ) && !this.props.lock;

    if (shouldUpdatePosition) {
      const updatedDimensions = this.getUpdatedTextRect();
      this.props.onChangePosition(updatedDimensions);
    }

    /**
     * If we're changing back from the back of the product to the front, we wait until the animation is finished and
     * then get the updated rect again. If we don't wait until the animation finishes, the updated rect will be off
     * because one of the parent elements will still be transformed because of the card opening animation.
     */
    if (
      !this.props.lock &&
      this.props.currentEditorStep &&
      this.props.currentEditorStep === "PRODUCT_FRONT" &&
      this.props.currentEditorStep !== prevProps.currentEditorStep
    ) {
      setTimeout(() => {
        const updatedDimensions = this.getUpdatedTextRect();
        this.props.onChangePosition(updatedDimensions);
      }, 1500);
    }

    this.overrideFontSizeIfNecessary();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("touchmove", this.handleMouseOrTouchMove);
    window.removeEventListener("touchend", this.stopRotating);
    window.removeEventListener("touchend", this.stopMoving);
    window.removeEventListener("touchend", this.stopResizing);

    window.removeEventListener("mousemove", this.handleMouseOrTouchMove);
    window.removeEventListener("mouseup", this.stopRotating);
    window.removeEventListener("mouseup", this.stopResizing);
    window.removeEventListener("mouseup", this.stopMoving);
  }

  overrideFontSizeIfNecessary = () => {
    if (!this.props.screenshotMode) {
      return;
    }

    setTimeout(() => {
      // console.log("this.textContent.scrollHeight", this.textContent.scrollHeight);
      // console.log("this.textContent.offsetHeight", this.textContent.offsetHeight);
      // console.log("this.textContent.clientHeight", this.textContent.clientHeight);
      if (this.textContent.scrollHeight > this.textContent.offsetHeight) {
        const updatedFontSize =
          (this.state.contentFontSizeOverride || this.props.config.size) - 0.1;

        if (this.state.contentFontSizeOverride !== updatedFontSize) {
          this.setState({
            contentFontSizeOverride: updatedFontSize,
          });
        }
      }
    }, 100);
  };

  handleMouseOrTouchMove = e => {
    if (this.state.isRotating) {
      const coordinates = this.getRelativeCoordinates(e);
      const degrees = calculateDegrees(coordinates);
      this.setState({
        rotation: degrees,
      });
    }

    if (this.state.isResizing) {
      let movementX = 0;

      if (isTouchEnabled) {
        movementX = this.prevX ? e.targetTouches[0].screenX - this.prevX : 0;
        this.prevX = e.targetTouches[0].screenX;
      } else {
        movementX = this.prevX ? e.screenX - this.prevX : 0;
        this.prevX = e.screenX;
      }

      const $node = ReactDOM.findDOMNode(this);
      const boundingRect = $node.getBoundingClientRect();

      const productScale = this.props.rendererDOMNode.getBoundingClientRect().width/this.props.canvasDimensions.width;
      
      //console.log("Width", boundingRect.width);
      //console.log("X", movementX);

      let updatedWidth = ((boundingRect.width / productScale) + movementX);
      
      //console.log("updatedWidth", updatedWidth);
      //console.log("New width", this.props.rendererDOMNode.getBoundingClientRect().width * MIN_LAYER_WIDTH_SCREEN_PERCENTAGE_ON_RESIZE);
      

      const minTextWidth = this.props.canvasDimensions.width * MIN_LAYER_WIDTH_SCREEN_PERCENTAGE_ON_RESIZE;
      //console.log(minTextWidth);
      const minWidth = Math.max(
        updatedWidth,
        minTextWidth
      );
      const maxWidth =
        this.props.canvasDimensions.width -
        (boundingRect.left - this.props.rendererDOMNode.getBoundingClientRect().left);
      updatedWidth = Math.min(minWidth, maxWidth);
      // console.log("updatedWidth", updatedWidth);
      // console.log("minWidth", minWidth);
      // console.log("maxWidth", maxWidth);
      
      $node.style.width = `${updatedWidth}px`;
    }
  };

  handleClick = () => {
    this.props.onClick(this.rect.getDimensions());
    if (!this.props.lock) {
      this.startMoving();
    }
  };

  handleTouchEnd = e => {
    if (e.target === this.textContainer) {
      if (!this.props.lock) {
        if (!this.state.isMoving) {
          this.props.onClickEdit();
        }
      } else {
        this.props.onClick(this.rect.getDimensions());
      }
    }
  };

  getRelativeCoordinates = event => {
    const clientRect = ReactDOM.findDOMNode(this.rect).getBoundingClientRect();

    if (isTouchEnabled) {
      return {
        mousex: event.targetTouches[0].pageX - clientRect.left - clientRect.width / 2,
        mousey: event.targetTouches[0].pageY - clientRect.top - clientRect.height / 2,
      };
    } else {
      return {
        mousex: event.pageX - clientRect.left - clientRect.width / 2,
        mousey: event.pageY - clientRect.top - clientRect.height / 2,
      };
    }
  };

  startRotating = e => {
    e.stopPropagation();
    this.setState({
      isRotating: true,
    });
  };

  stopRotating = () => {
    if (this.state.isRotating) {
      this.setState({
        isRotating: false,
      });

      // Grab the updated rect
      if (!this.props.lock) {
        const updatedRect = this.getUpdatedTextRect();
        this.props.onChangePosition({
          ...updatedRect,
          rotation: this.state.rotation,
        });
      }
    }
  };

  startResizing = e => {
    e.stopPropagation();
    this.setState({
      isResizing: true,
    });
  };

  startMoving = () => {
    this.setState({
      isMoving: true,
    });
  };

  stopMoving = () => {
    if (this.state.isMoving) {
      this.setState({
        isMoving: false,
      });

      if (!this.props.lock) {
        setTimeout(() => {
          const updatedRect = this.getUpdatedTextRect();
          this.props.onChangePosition(updatedRect);
        }, 50);
      }
    }
  };

  stopResizing = () => {
    if (this._isMounted){ // TODO: Fix this antipattern
      if (this.state.isResizing) {
        setTimeout(() => {
          const updatedDimensions = this.getUpdatedTextRect();
          this.props.onChangePosition(updatedDimensions);
          this.prevX = null;
        }, 100);
      }
  
      this.setState({
        isResizing: false,
      });
    }
  };

  getUpdatedTextRect = () => {
    
    let $node;
    try {
      $node = ReactDOM.findDOMNode(this);
    } catch(error) {
      return false;
    }

    
    const cachedStyles = {
      height: $node.style.height,
    };
    $node.style.height = "auto";

    /**
     * If the container is flipped, we need to 're-flip' the renderer DOM node temporarily, otherwise the coordinates
     * returned from `getBoundingClientRect()` will be incorrect
     */
    if (this.props.isRendererContainerFlipped) {
      this.props.rendererDOMNode.style.transform = "rotateY(180deg)";
    }

    const nodeBoundingRect = $node.getBoundingClientRect();
    const rendererBoundingRect = this.props.rendererDOMNode.getBoundingClientRect();

    /**
     * We use the next value to determine whether or not to nudge the text upwards if it's out of bounds (i.e.
     * overflowing over the edge of the bottom of the product). This value is clamped to 0 to make sure we don't move
     * the text layer if we don't have to.
     */
    const amountOfOutOfBoundPixelsOnBottom = Math.max(
      0,
      nodeBoundingRect.bottom - rendererBoundingRect.bottom
    );

    

    let newDimensions = {
      width: $node.offsetWidth / this.props.canvasDimensions.width,
      height: $node.offsetHeight / this.props.canvasDimensions.height,
      y: Math.max(
        0,
        this.props.config.rect.y +
          amountOfOutOfBoundPixelsOnBottom / this.props.canvasDimensions.height
      ),
      transformedRect: {
        width: nodeBoundingRect.width / this.props.canvasDimensions.width,
        height: nodeBoundingRect.height / this.props.canvasDimensions.height,
        x: (nodeBoundingRect.left - rendererBoundingRect.left) / this.props.canvasDimensions.width,
        y:
          1 -
          (nodeBoundingRect.top - rendererBoundingRect.top - amountOfOutOfBoundPixelsOnBottom) /
            this.props.canvasDimensions.height,
      },
    };

    // if (amountOfOutOfBoundPixelsOnBottom){
    //   this.setState({
    //     movedUpFromBottom: true
    //   });
    // }

    //console.log(newDimensions);

    // Restore the 're-flip' if necessary
    if (this.props.isRendererContainerFlipped) {
      this.props.rendererDOMNode.style.transform = "";
    }

    $node.style.height = cachedStyles.height;

    return newDimensions;
  };

  render() {
    const InchesInMM = 0.0393700787
    const PointsInInch = 72
    const scalingFactor = (this.props.canvasDimensions.height/(this.props.productDimensions.height*InchesInMM*PointsInInch))
    const newSize = this.props.config.size * scalingFactor

    const isPortraitProduct =
      this.props.productDimensions.width < this.props.productDimensions.height;
    //let fontSize = `${(this.state.contentFontSizeOverride || this.props.config.size) / 10}em`;
    let fontSize = `${(this.state.contentFontSizeOverride || this.props.config.size) / 10}em`;
    if (this.props.item.productTypeId === PRODUCT_TYPE_IDS.GREETING_CARD && !this.props.lock){
      fontSize = `${newSize}px`;
    }

    let justifyRect = 'center';
    switch (this.props.config.alignment) {
      case "center":
        justifyRect = "center";
        break;
      case "left":
        justifyRect = "flex-start";
        break;
      case "right":
        justifyRect = "flex-end";
        break;
      default:
        justifyRect = "center";
        break;
    }

    const captionPrint = isCaptionPrintProduct(this.props.item.productId)
    //console.log("isCaptionPrint", captionPrint)
    let rectFontSize = roundToNearestHalf(
      (this.props.canvasDimensions.height || 0) *
        (STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO / (captionPrint ? 1.3 : (isPortraitProduct ? 2 : 1)))
    )
    //console.log("this.props.canvasDimensions.height", this.props.canvasDimensions.height)
    //console.log("STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO", STANDARD_FONT_SIZE_TO_CANVAS_HEIGHT_RATIO)
    //console.log("isPortraitProduct", isPortraitProduct)
    //console.log("rectFontSize", rectFontSize)

    const rectStyles = {
      fontSize: rectFontSize,
      transform: `rotate(${this.state.rotation}deg)`,
      display: 'flex', // If mini retro...
      justifyContent: justifyRect,
      alignItems: 'center',
    };

    rectStyles.WebkitTransform = rectStyles.transform;
    //console.log("this.state.contentFontSizeOverride",this.state.contentFontSizeOverride);
    //console.log("this.props.config.size",this.props.config.size);
    let justify = 'center';
    switch (this.props.config.alignment) {
      case "center":
        justify = "center";
        break;
      case "left":
        justify = "start";
        break;
      case "right":
        justify = "end";
        break;
      default:
        justify = "end";
        break;
    }

    let textContentStyles = {
      fontSize: fontSize,
      textAlign: this.props.config.alignment,
      fontFamily: this.props.config.font,
      color:
        this.props.config.color ||
        this.props.config.variantColor ||
        this.props.config.colors[0] ||
        "rgb(0,0,0)",
      justifyContent: justify,
      fontWeight: this.props.config.weight || "normal"
    };

    //console.log("fontsize:", fontSize);
    // if (this.state.movedUpFromBottom){
    //   textContentStyles.lineHeight = '1em';
    // }

    const classes = classNames(
      "html-renderer-text-layer",
      { "html-renderer-text-layer--is-selected": this.props.isSelected },
      { "html-renderer-text-layer--is-moving": this.state.isMoving },
      { "html-renderer-text-layer--is-locked": this.props.lock },
      { "html-renderer-text-layer--is-centered": this.props.isVerticallyCentered },
    );

    const containerClasses = classNames(
      "html-renderer-text-layer__container",
      { "html-renderer-text-layer--debug": this.props.debug },
      { "html-renderer-text-layer--screenshot": this.props.screenshotMode },
      { "html-renderer-text-layer--highlighted": this.props.highlightText },
    );


    return (
      <HtmlRendererRect
        ref={el => (this.rect = el)}
        className={classes}
        rect={this.props.config.rect}
        style={rectStyles}
      >
        <div
          className={containerClasses}
          ref={el => (this.textContainer = el)}
          onMouseUp={this.handleTouchEnd}
          onTouchEnd={this.handleTouchEnd}
        >
          {!this.props.lock && [
            // <img
            //   key="rotate"
            //   src={`${process.env.PUBLIC_URL}/images/text-rotate.svg`}
            //   className="html-renderer-text-layer__control html-renderer-text-layer__control--rotate"
            //   onMouseDown={this.startRotating}
            //   onTouchStart={this.startRotating}
            //   alt=""
            // />,
            // <img
            //   key="resize"
            //   src={`${process.env.PUBLIC_URL}/images/text-resize.svg`}
            //   className="html-renderer-text-layer__control html-renderer-text-layer__control--resize"
            //   onMouseDown={this.startResizing}
            //   onTouchStart={this.startResizing}
            //   alt=""
            // />,
            <img
              key="delete"
              src={`${process.env.PUBLIC_URL}/images/text-delete.svg`}
              className="html-renderer-text-layer__control html-renderer-text-layer__control--delete"
              onClick={this.props.onClickDelete}
              onTouchEnd={this.props.onClickDelete}
              alt=""
            />,
          ]}
          <div
            className="html-renderer-text-layer__content"
            style={textContentStyles}
            ref={el => (this.textContent = el)}
          >
            {this.props.config.text}
          </div>
        </div>
      </HtmlRendererRect>
    );
  }
}

export default HtmlRendererTextLayer;
