import React from "react";
import PropTypes from "prop-types";

import Button from "../Button/Button";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import gtmEvent from "../../utils/gtm";
//import SweetAlert from "../SweetAlert/SweetAlert";

import { Popconfirm, message } from "antd";

import "./QuantityPicker.scss";
import Icon from "../Icon/Icon";

class QuantityPicker2 extends React.Component {
  static propTypes = {
    quantity: PropTypes.number.isRequired,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    onDelete: PropTypes.func,
    withDelete: PropTypes.bool,
    delConfirmTitle: PropTypes.string,
    delSuccessTitle: PropTypes.string,
    onEdit: PropTypes.func,
  };

  static defaultProps = {
    onIncrease: () => {},
    onDecrease: () => {},
    onDelete: () => {},
    onEdit: () => {},
    withDelete: true,
    delConfirmTitle: "Delete this print?",
    delSuccessTitle: "Print deleted",
  };

  state = {
    isDeleteConfirmationAlertVisible: false,
  };

  handleDecrease = () => {
    if (this.props.quantity === 1) {
      //if (this.props.withDelete){
      this.showDeleteConfirmationAlert();
      //}
    } else {
      this.props.onDecrease();
    }
  };

  handleDelete = () => {
    this.props.onDelete();
    message.success(this.props.delSuccessTitle);
    this.hideDeleteConfirmationAlert();
    gtmEvent({ event: "webAppEvent", additionalProps: { eventName: "Deleted Item" } });
  };

  showDeleteConfirmationAlert = () => {
    this.setState({
      isDeleteConfirmationAlertVisible: true,
    });
  };

  hideDeleteConfirmationAlert = () => {
    this.setState({
      isDeleteConfirmationAlertVisible: false,
    });
  };

  render() {
    const { onIncrease, quantity, withDelete } = this.props;

    return (
      <div className="quantity-picker">
        <div className="quantity-picker__main">
          <Button
            dataGtmElement="Quantity Decrease"
            icon="subtract"
            kind="primary-outline"
            size="small"
            theme="dark-blue"
            priority="secondary"
            onClick={this.handleDecrease}
            disabled={!withDelete ? false : this.props.quantity === 1}
          />
          <div className="quantity-picker__value">{quantity}</div>
          <Button
            icon="add"
            kind="primary-outline"
            size="small"
            theme="dark-blue"
            priority="secondary"
            onClick={onIncrease}
            dataGtmElement="Quantity Increase"
          />
        </div>
        <Popconfirm
          style={{ marginRight: "10px" }}
          visible={this.state.isDeleteConfirmationAlertVisible}
          title={
            <div style={{ width: "40vw", maxWidth: "200px" }}>{this.props.delConfirmTitle}</div>
          }
          onConfirm={this.handleDelete}
          onCancel={this.hideDeleteConfirmationAlert}
          okButtonProps={{ style: { height: "32px" } }}
          cancelButtonProps={{ style: { height: "32px" } }}
          okText="Yes"
          cancelText="No"
          icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
          placement="left"
        >
          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {withDelete && (
              <DeleteOutlined
                style={{ fontSize: 28, color: "red", opacity: "0.5", display: "block" }}
                onClick={this.showDeleteConfirmationAlert}
              />
            )}
            <div style={{ marginLeft: 20, marginTop: 3, opacity: 0.8 }} onClick={this.props.onEdit}>
              <Icon name="edit" size="large" />
            </div>
          </div>
        </Popconfirm>
      </div>
    );
  }
}

export default QuantityPicker2;
