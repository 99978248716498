import React, { Component } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { List } from "immutable";
import Pluralize from "pluralize";
import TransitionGroup from "react-transition-group/TransitionGroup";
import CSSTransition from "react-transition-group/CSSTransition";
import gtmEvent from "../../utils/gtm";
import MASKS from "../../constants/masks";
import MODELS from "../../constants/models";
import { routeCreators } from "../../lib/routes";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import S3Image from "../../components/S3Image/S3Image";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
  isRetroPrint,
  hasRealBorders,
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  singleShippingAddressProductTypes,
} from "../../data/products";
import { militaryStates } from "../../constants/geo";
import MainContent from "../../components/MainContent/MainContent";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Currency from "../../components/Formatters/Currency";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
//import PayPalButton from "../../components/PayPalButton/PayPalButton";
import { PayPalButton } from "react-paypal-button-v2";
import StripePaymentRequestButton from "../../components/StripePaymentRequestButton/StripePaymentRequestButton";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
import ModalPage from "../../components/Modal/ModalPage";
import Icon from "../../components/Icon/Icon";
import HtmlRenderer from "../../components/HtmlRenderer/HtmlRenderer";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import ThreeDimensionalViewer from "../../components/ThreeDimensionalViewer/ThreeDimensionalViewer";
import BasketButtonContainer from "../../components/BasketButton/BasketButtonContainer";
import AccountButtonContainer from "../../components/AccountButton/AccountButtonContainer";
import ShopButtonContainer from "../../components/ShopButton/ShopButtonContainer";
import PhotoMagazinePreview from "../../components/PhotoMagazinePreview/PhotoMagazinePreview";
import GreetingCardPreview from "../../components/GreetingCardPreview/GreetingCardPreview";
import PostcardPreview from "../../components/PostcardPreview/PostcardPreview";
import AnnouncementPreview from "../../components/AnnouncementPreview/AnnouncementPreview";
import PhotoPrintPreview from "../../components/PhotoPrintPreview/PhotoPrintPreview";

import OrderSummary from "../../components/OrderSummary/OrderSummary";
//import SingleShipmentOrderSummary from "../../components/OrderSummary/SingleShipmentOrderSummary";
import SingleShipmentOrderSummaryV2 from "../../components/OrderSummary/SingleShipmentOrderSummaryV2";

import {
  CloseOutlined,
  ExclamationCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Button as AntButton,
  Modal as AntModal,
  message,
  Progress,
  Popconfirm,
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import "./Basket.scss";

const { confirm } = AntModal;

const PAYMENT_METHODS = {
  CREDIT_DEBIT_CARD: "CREDIT_DEBIT_CARD",
  PREPAY: "PREPAY",
};

const PAYMENT_MODAL_PAGES = {
  OVERVIEW: "OVERVIEW",
  SAVED_CARD: "SAVED_CARD",
  STRIPE_CREDIT_CARD_FORM: "STRIPE_CREDIT_CARD_FORM",
  PREPAY_CREDIT_PAYMENT: "PREPAY_CREDIT_PAYMENT",
};

const BasketItem = ({
  item,
  showAddress,
  showShipping,
  canDelete,
  onClickPreview,
  onClickDeleteButton,
  onClickCancelDeleteButton,
  onClickConfirmDeleteButton,
  onClickEditModeForItem,
  onClickEditModeActiveForItem,
  handleOpenBookPreview,
  editModeEnabled,
  deletePopupEnabled,
  onClickAddress,
  onClickIncreaseQty,
  onClickDecreaseQty,
  onChangeProductOption,
  editModeActiveForItem,
  isEditModeActiveGlobally,
  isPack,
  packItems,
  onClickPackForItem,
  onClickHidePackForItem,
  onClickShowPackForItem,
  packNum,
  isEditingPackForProduct,
  showPackHideForItem,
  isPackExpanded,
}) => {
  const address = item.get("address") || item.get("addressBookEntry");

  const isSinglePageWallArt = [PRODUCT_TYPE_IDS.CANVAS, PRODUCT_TYPE_IDS.PHOTO_TILE].includes(
    item.get("productTypeId")
  );

  const isPhotoBook = [PRODUCT_TYPE_IDS.PHOTO_MAGAZINE].includes(item.get("productTypeId"));

  const product = productsByProductId.get(item.get("productId"));
  const realBorderedProduct = hasRealBorders(item.get("productId")); // mini prints or postcards...

  const printProduct = [PRODUCT_TYPE_IDS.PHOTO_PRINT].includes(item.get("productTypeId"));

  const productSlug = isSinglePageWallArt && product.get("url_slug");

  const maskedProduct = isSinglePageWallArt || (realBorderedProduct && printProduct);
  //console.log("Masked product", maskedProduct)
  const bleedMasks = MASKS[isSinglePageWallArt ? productSlug : product.get("appKey")];
  //console.log("bleedMasks", bleedMasks)
  const isPackMode = item.get("packMode");

  const isCollagePrint = item.get("isCollagePrint");

  const areBordered = Boolean(item.getIn(["product_options", "border"]));
  const glossOn = item.getIn(["product_options", "finish"]) === "gloss";
  const effects = Boolean(item.getIn(["product_options", "effects"]));
  const areRealBorders =
    (printProduct && areBordered && realBorderedProduct) || (!printProduct && realBorderedProduct); // false;

  const fakeBorders = areBordered && !areRealBorders && !isPhotoBook && !isCollagePrint;

  const isTextPrint = [
    "6X4.5_TEXT_PRINTS",
    "RETRO_PRINTS",
    "2×2.5_PRINTS",
    "RETRO_PRINTS_C",
  ].includes(product.get("appKey"));
  const isCustomPrint = item.get("isCustomPrint");
  const isFineArtPrint = product.get('fine_art_print');
  const isPassportPrint = item.get("isPassportPrint");
  const isColouredRetro = item.get("isColouredRetro");
  const LARGE_PRINT_SIZE_MM = 304.8; // 12 inches
  const isLargeBorderlessPrint =
    product.get("width") > LARGE_PRINT_SIZE_MM && product.get("width") > LARGE_PRINT_SIZE_MM;

  const finishOptionsAvailable =
    !realBorderedProduct && !isPassportPrint && !isRetroPrint(item.get("productId")) && !isFineArtPrint;
  const borderOptionAvailable =
    !isTextPrint &&
    !isCustomPrint &&
    !isPassportPrint &&
    !isCollagePrint &&
    !isLargeBorderlessPrint;
  const effectsAvailable = !isPassportPrint && !isCollagePrint && !isColouredRetro;
  const optionActiveButtonColor = "#3b3938";
  const optionButtonStyles = {
    fontSize: "12px",
    borderRadius: "2px",
    width: "55px",
  };

  const ProductOptions = ({ item, showFinish, showBorder }) => {
    const itemHasFinishOption = showFinish && item.getIn(["product_options", "finish"]);
    const itemHasBorderOption =
      showBorder && item.getIn(["product_options", "border"]) ? "Border" : "";
    const itemHasEffectOption = item.getIn(["product_options", "effects"]) ? "B & W" : "";

    const productOptions = [itemHasFinishOption, itemHasBorderOption, itemHasEffectOption];

    const filteredOptions = productOptions.filter(option => option);

    return (
      <span style={{ fontSize: "0.8em", color: "#aaa", textTransform: "capitalize" }}>
        {filteredOptions.join(" | ")}
      </span>
    );
  };

  const photoBookOptions = (
    <div className="print-options-container--basket">
      <Row type="flex" justify="start" style={{ marginTop: "8px" }}>
        <Col span={7} style={{ marginTop: "10px", paddiongRight: "4px" }}>
          Qty:
        </Col>
        <Col span={17}>
          <Row type="flex" justify="start">
            <Col span={24}>
              <div className="qtyPicker" style={{ marginTop: "-4px" }}>
                <AntButton.Group style={{ background: "white" }}>
                  <Popconfirm
                    visible={deletePopupEnabled}
                    title="Delete this photo book?"
                    onConfirm={onClickConfirmDeleteButton}
                    onCancel={onClickCancelDeleteButton}
                    okText="Yes"
                    cancelText="No"
                    icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                    placement="left"
                  >
                    <AntButton
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        padding: "0px 10px",
                        borderColor: "#c5c5c5",
                        color: "rgba(0, 0, 0, 0.48)",
                        backgroundColor: "white !important",
                        borderRadiusLeft: "2px",
                        height: "36px",
                      }}
                      type={"primary"}
                      ghost
                      active={"true"}
                      onClick={() => onClickDecreaseQty(item.get("id"))}
                    >
                      <MinusOutlined />
                    </AntButton>
                  </Popconfirm>
                  <AntButton
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                      padding: "0px 10px",
                      borderColor: "#c5c5c5",
                      color: "rgba(0, 0, 0, 0.48)",
                      backgroundColor: "white !important",
                      borderRadiusRight: "2px",
                      height: "36px",
                    }}
                    onClick={() => onClickIncreaseQty(item.get("id"))}
                    type={"primary"}
                    ghost
                  >
                    <PlusOutlined />
                  </AntButton>
                </AntButton.Group>
                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                  <span style={{ color: "#ff5a5f", fontWeight: "bold" }}>
                    {item.get("quantity")}
                  </span>{" "}
                  × <Currency amount={item.getIn(["pricingScheme", "cost"])} />
                  <br />
                  <Currency amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  const printEditOptions = (
    <div className="print-options-container--basket">
      {finishOptionsAvailable && (
        <Row type="flex" justify="start">
          <Col span={7} style={{ marginTop: "4px" }}>
            Finish:
          </Col>
          <Col span={17}>
            <Row type="flex" justify="start">
              <Col span={24}>
                <AntButton
                  onClick={e => onChangeProductOption({ finish: "gloss" })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: glossOn ? optionActiveButtonColor : "",
                    borderColor: glossOn ? optionActiveButtonColor : "",
                  }}
                  type={glossOn ? "primary" : ""}
                >
                  Gloss
                </AntButton>
                <AntButton
                  onClick={e => onChangeProductOption({ finish: "matt" })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: !glossOn ? optionActiveButtonColor : "",
                    borderColor: !glossOn ? optionActiveButtonColor : "",
                    marginLeft: "3px",
                  }}
                  type={!glossOn ? "primary" : ""}
                >
                  Matt
                </AntButton>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {borderOptionAvailable && (
        <Row type="flex" justify="start" style={{ marginTop: "8px" }}>
          <Col span={7} style={{ marginTop: "4px" }}>
            Border:
          </Col>
          <Col span={17}>
            <Row type="flex" justify="start">
              <Col span={24}>
                <AntButton
                  onClick={e => onChangeProductOption({ border: false })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: !areBordered ? optionActiveButtonColor : "",
                    borderColor: !areBordered ? optionActiveButtonColor : "",
                  }}
                  type={!areBordered ? "primary" : ""}
                >
                  No
                </AntButton>
                <AntButton
                  onClick={e => onChangeProductOption({ border: true })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: areBordered ? optionActiveButtonColor : "",
                    borderColor: areBordered ? optionActiveButtonColor : "",
                    marginLeft: "3px",
                  }}
                  type={areBordered ? "primary" : ""}
                >
                  Yes
                </AntButton>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {effectsAvailable && (
        <Row type="flex" justify="start" style={{ marginTop: "8px" }}>
          <Col span={7} style={{ marginTop: "4px" }}>
            Effect:
          </Col>
          <Col span={17}>
            <Row type="flex" justify="start">
              <Col span={24}>
                <AntButton
                  onClick={e => onChangeProductOption({ effects: false })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: !effects ? optionActiveButtonColor : "",
                    borderColor: !effects ? optionActiveButtonColor : "",
                  }}
                  type={!effects ? "primary" : ""}
                >
                  None
                </AntButton>
                <AntButton
                  onClick={e => onChangeProductOption({ effects: "black-and-white" })}
                  size="small"
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: effects ? optionActiveButtonColor : "",
                    borderColor: effects ? optionActiveButtonColor : "",
                    marginLeft: "3px",
                  }}
                  type={effects ? "primary" : ""}
                >
                  B & W
                </AntButton>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!isEditingPackForProduct && (
        <Row type="flex" justify="start" style={{ marginTop: "8px" }}>
          <Col span={7} style={{ marginTop: "4px" }}>
            Qty:
          </Col>
          <Col span={17}>
            <Row type="flex" justify="start">
              <Col span={24}>
                <div className="qtyPicker" style={{ marginTop: "-4px" }}>
                  <AntButton.Group style={{ background: "white" }}>
                    <Popconfirm
                      visible={deletePopupEnabled}
                      title="Delete this print?"
                      onConfirm={onClickConfirmDeleteButton}
                      onCancel={onClickCancelDeleteButton}
                      okText="Yes"
                      cancelText="No"
                      icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                      placement="left"
                    >
                      <AntButton
                        style={{
                          fontSize: "12px",
                          marginTop: "4px",
                          padding: "0px 6px",
                          borderColor: "#c5c5c5",
                          color: "rgba(0, 0, 0, 0.48)",
                          backgroundColor: "white !important",
                          borderRadiusLeft: "2px",
                        }}
                        size="small"
                        type={"primary"}
                        ghost
                        active={"true"}
                        onClick={() => onClickDecreaseQty(item.get("id"))}
                      >
                        <MinusOutlined />
                      </AntButton>
                    </Popconfirm>
                    <AntButton
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        padding: "0px 6px",
                        borderColor: "#c5c5c5",
                        color: "rgba(0, 0, 0, 0.48)",
                        backgroundColor: "white !important",
                        borderRadiusRight: "2px",
                      }}
                      size="small"
                      onClick={() => onClickIncreaseQty(item.get("id"))}
                      type={"primary"}
                      ghost
                    >
                      <PlusOutlined />
                    </AntButton>
                  </AntButton.Group>
                  <div style={{ marginTop: "5px", fontSize: "12px" }}>
                    <span style={{ color: "#ff5a5f", fontWeight: "bold" }}>
                      {item.get("quantity")}
                    </span>{" "}
                    × <Currency amount={item.getIn(["pricingScheme", "cost"])} />
                    <br />
                    <Currency
                      amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="start" style={{ marginTop: "8px" }}>
        <Col span={7} style={{ marginTop: "4px" }}>
          &nbsp;
        </Col>
        <Col span={17}>
          <Row type="flex" justify="start">
            <Col span={24}>
              <AntButton
                style={{
                  fontSize: "12px",
                  padding: "0px 11px",
                  background: "#4b5566",
                  color: "white",
                  border: "none",
                  fontWeight: "normal",
                  borderRadius: "2px",
                }}
                size="small"
                onClick={onClickEditModeForItem}
              >
                Done
              </AntButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  const openPreviewOnEdit = item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT;
  const isGreetingCard = item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;

  console.log("isPackExpanded", isPackExpanded);

  const isMasked =
    maskedProduct &&
    bleedMasks &&
    bleedMasks.canvasPreview &&
    ((item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT && !isEditingPackForProduct) ||
      item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS);

  const mask = isMasked ? bleedMasks.canvasPreview : false;

  //const isCanvas = item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;

  /*let itemPreview = null;

  if (item.get('isReorderedItem')) {
    itemPreview = (
      <div onClick={onClickPreview}>
        <S3Image storageKey={item.get("preview_s3_key")} className="item-preview" />
      </div>
    );
  } else {
    itemPreview = <HtmlRenderer
      width={100}
      isInteractive={false}
      previewMode={true}
      onClick={onClickPreview}
      item={item.toJS()}
      page={item.getIn(["pages", "front"])}
      mask={isCanvas && MASKS[productSlug].canvasPreview}
    />
  }*/

  let itemDescription = (
    <span>{`${item.get("quantity")} × ${item.get("description").replace(" × ", "×")}`}</span>
  );

  //itemDescription

  if (
    product.get("secondary_name") &&
    item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE
  ) {
    const finish = item.getIn(["product_options", "finish"]);
    const finishCapitalized = finish.charAt(0).toUpperCase() + finish.slice(1);
    const itemDescriptionHTML = `
      <span style='font-size:12px'>${product.get("name")}<br/>
      <span style='font-size:10px'>${product.get("secondary_name")}</span></span><br/>
      <span style='font-size:10px'>${finishCapitalized} | 24 pages</span>
    `;
    itemDescription = <span dangerouslySetInnerHTML={{ __html: itemDescriptionHTML }} />;
  }

  return (
    <tr className={editModeEnabled ? "bag-item-editing" : "bag-item"}>
      {canDelete && (
        <td className="pr-default">
          <AntButton
            ghost
            type="primary"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={onClickDeleteButton}
          />
          {/* <Button icon="clear" circle size="small" priority="secondary" onClick={onClickDeleteButton} /> */}
        </td>
      )}
      <td
        width="40%"
        className="item-preview"
        style={item.get("isRendering") ? { opacity: 0.4 } : null}
      >
        {isPack ? (
          <div style={{ height: "140px" }}>
            {[...Array(3).keys()].reverse().map(index => {
              const offset = {
                top: 5 * index,
                left: 5 * index,
              };
              return (
                <div
                  style={{
                    position: "absolute",
                    top: `calc(50% - ${offset.top}px)`,
                    left: `calc(50% + ${offset.left}px)`,
                    transform: "translateX(-50%) translateY(-50%)",
                    webkitTransform: "translateX(-50%) translateY(-50%)",
                    width: "100px",
                  }}
                  onClick={() => onClickPackForItem(item)}
                >
                  <S3Image
                    storageKey={packItems[index].preview_s3_key}
                    className={`pack-preview ${index === 2 ? "shadow" : ""}`}
                  />
                  {/* <HtmlRenderer
                      width={100}
                      isInteractive={false}
                      previewMode={true}
                      onClick={() => onClickPackForItem(item)}
                      item={packItems[index]}
                      page={item.getIn(["pages", "front"])}
                      borderedForPreviews={fakeBorders}
                      withShadow={fakeBorders}
                      realBorders={areRealBorders}
                      effects={effects}
                      mask={false}
                      showBleed={isGreetingCard}
                    /> */}
                </div>
              );
            })}
          </div>
        ) : isPackExpanded ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
            onClick={() => onClickPreview(item)}
          >
            <S3Image storageKey={item.get("preview_s3_key")} className="pack-preview single" />
          </div>
        ) : isPackMode ? (
          <div style={{ height: "140px" }}>
            {[...Array(3).keys()].reverse().map(index => {
              const offset = {
                top: 5 * index,
                left: 5 * index,
              };
              return (
                <div
                  style={{
                    position: "absolute",
                    top: `calc(50% - ${offset.top}px)`,
                    left: `calc(50% + ${offset.left}px)`,
                    transform: "translateX(-50%) translateY(-50%)",
                    webkitTransform: "translateX(-50%) translateY(-50%)",
                    width: "100px",
                  }}
                  onClick={onClickPreview}
                >
                  <HtmlRenderer
                    width={100}
                    isInteractive={false}
                    previewMode={true}
                    onClick={onClickPreview}
                    item={item.toJS()}
                    page={item.getIn(["pages", "front"])}
                    borderedForPreviews={fakeBorders}
                    withShadow={fakeBorders}
                    realBorders={areRealBorders}
                    effects={effects}
                    mask={false}
                    showBleed={isGreetingCard}
                  />
                </div>
              );
            })}
          </div>
        ) : item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_TILE ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <S3Image storageKey={item.get("preview_s3_key")} className="photo-tile-preview" />
          </div>
        ) : (
          <HtmlRenderer
            width={100}
            isInteractive={false}
            previewMode={true}
            onClick={onClickPreview}
            item={item.toJS()}
            page={item.getIn(["pages", "front"])}
            borderedForPreviews={fakeBorders}
            withShadow={fakeBorders}
            realBorders={areRealBorders}
            effects={effects}
            mask={mask}
            showBleed={isGreetingCard}
            uniquekey={item.get("id")}
          />
        )}

        {item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT &&
          !isCustomPrint &&
          !isPassportPrint &&
          !isCollagePrint &&
          !isColouredRetro &&
          editModeEnabled && (
            //style={{position: 'absolute', bottom: maskedProduct ? '27%' : '7px', right: maskedProduct ? '19%': '40px', border: 'none' }}
            <AntButton onClick={onClickPreview} size="small" className="crop-button">
              Crop
            </AntButton>
          )}
        {/* {item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS && (
        {itemPreview}
        {item.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS && (
          <div
            className="qtyPicker"
            style={{ margin: "7px auto", textAlign: "center", marginLeft: "-6px" }}
          >
            <AntButton.Group>
              <AntButton
                size="small"
                type={"primary"}
                ghost
                active={"true"}
                onClick={() => onClickDecreaseQty(item.get("id"))}
              >
                <AntIcon type="minus" />
              </AntButton>
              <AntButton
                size="small"
                onClick={() => onClickIncreaseQty(item.get("id"))}
                type={"primary"}
                ghost
              >
                <AntIcon type="plus" />
              </AntButton>
            </AntButton.Group>
          </div>
        )} */}
        <br />
      </td>
      <td style={{ paddingLeft: "5px" }}>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  width: "70%",
                  fontWeight: "400",
                  fontSize: "14px",
                  //verticalAlign: "top",
                }}
              >
                {isEditingPackForProduct && showPackHideForItem === item.get("id") && (
                  <div>
                    <AntButton
                      style={{
                        fontSize: "12px",
                        marginTop: "0px",
                        padding: "0px 7px",
                        borderRadius: "2px",
                        background: "transparent",
                        color: "inherit",
                        border: "1px solid #ccc",
                      }}
                      size="small"
                      onClick={() => onClickHidePackForItem(item)}
                    >
                      Hide Pack Contents
                    </AntButton>
                  </div>
                )}
                {isPack && (
                  <div>
                    <AntButton
                      style={{
                        fontSize: "12px",
                        marginTop: "0px",
                        padding: "0px 7px",
                        borderRadius: "2px",
                        background: "transparent",
                        color: "inherit",
                        border: "1px solid #ccc",
                      }}
                      size="small"
                      onClick={() => onClickShowPackForItem(item)}
                    >
                      Show Pack Contents
                    </AntButton>
                  </div>
                )}
                {!editModeEnabled &&
                  (isPackMode ? (
                    <div style={{ fontSize: "0.8em" }}>
                      <span>
                        {item.get("quantity")} {item.get("quantity") > 1 ? "Packs" : "Pack"} (
                        {item.get("packQuantity")} cards)
                      </span>
                      <br />
                      {/* <span>+ Envelopes x {item.get("packQuantity")} (Free)</span> */}
                    </div>
                  ) : isPack ? (
                    `${packNum} × ${packNum === 1 ? "Pack" : "Packs"} of ${item
                      .get("description")
                      .replace(" × ", "×")}`
                  ) : (
                    itemDescription
                  ))}

                {/* {(item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD && item.getIn(["product_options", "card_finish"])) && (
                  <div>
                    <span style={{ fontSize: "0.8em", textTransform: 'capitalize' }}>
                      ({item.getIn(["product_options", "card_finish"])} Finish)
                    </span>
                  </div>
                )} */}
                {item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD &&
                  item.get("isDoubleDirect") && (
                    <div>
                      <span style={{ fontSize: "0.8em" }}>
                        {item.get("quantity") > 1
                          ? `With additional envelopes`
                          : `With an additional envelope`}
                      </span>
                    </div>
                  )}
                {item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE && (
                  <React.Fragment>
                    {editModeEnabled ? <React.Fragment>{photoBookOptions}</React.Fragment> : ``}
                  </React.Fragment>
                )}
                {item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT && (
                  <React.Fragment>
                    {editModeEnabled ? (
                      <React.Fragment>{printEditOptions}</React.Fragment>
                    ) : (
                      <div style={{ display: "block" }}>
                        {(finishOptionsAvailable || borderOptionAvailable) && (
                          <ProductOptions
                            item={item}
                            showFinish={finishOptionsAvailable}
                            showBorder={borderOptionAvailable}
                          />
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </td>
              <td className={`qty-and-price text-right ${editModeEnabled ? "editing" : ""}`}>
                {item.get("productTypeId") !== PRODUCT_TYPE_IDS.POSTCARD ? (
                  <div style={{ fontSize: "12px" }}>
                    {isPackMode ? (
                      <React.Fragment>
                        {/* <Currency
                          amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])}
                        /> */}
                        <div
                          className="qtyPicker"
                          style={{ margin: "7px auto", textAlign: "center", marginLeft: "-6px" }}
                        >
                          <Currency amount={item.getIn(["pricingScheme", "cost"])} />
                          <br />
                          <AntButton.Group>
                            <Popconfirm
                              visible={deletePopupEnabled}
                              title="Delete?"
                              onConfirm={onClickConfirmDeleteButton}
                              onCancel={onClickCancelDeleteButton}
                              okText="Yes"
                              cancelText="No"
                              icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                              placement="left"
                            >
                              <AntButton
                                style={{
                                  fontSize: "12px",
                                  marginTop: "0px",
                                  padding: "0px 7px",
                                  borderRadius: "2px",
                                  background: editModeActiveForItem ? "#4b5566" : "transparent",
                                  color: editModeActiveForItem ? "white" : "inherit",
                                  border: editModeActiveForItem ? "none" : "1px solid #ccc",
                                }}
                                size="small"
                                ghost
                                active={"true"}
                                onClick={() => onClickDecreaseQty(item.get("id"))}
                              >
                                <MinusOutlined />
                              </AntButton>
                            </Popconfirm>
                            <AntButton
                              style={{
                                fontSize: "12px",
                                marginTop: "0px",
                                padding: "0px 7px",
                                borderRadius: "2px",
                                background: editModeActiveForItem ? "#4b5566" : "transparent",
                                color: editModeActiveForItem ? "white" : "inherit",
                                border: editModeActiveForItem ? "none" : "1px solid #ccc",
                              }}
                              size="small"
                              onClick={() => onClickIncreaseQty(item.get("id"))}
                              ghost
                            >
                              <PlusOutlined />
                            </AntButton>
                          </AntButton.Group>
                        </div>
                      </React.Fragment>
                    ) : editModeEnabled ? (
                      <div
                        className="qtyPicker"
                        style={{ margin: "7px auto", textAlign: "center", marginLeft: "-6px" }}
                      >
                        <span style={{ color: "#ff5a5f", fontWeight: "bold" }}>
                          {item.get("quantity")}
                        </span>{" "}
                        × <Currency amount={item.getIn(["pricingScheme", "cost"])} />
                        <br />
                        <AntButton.Group>
                          <Popconfirm
                            visible={deletePopupEnabled && canDelete}
                            title="Delete this print?"
                            onConfirm={onClickConfirmDeleteButton}
                            onCancel={onClickCancelDeleteButton}
                            okText="Yes"
                            cancelText="No"
                            icon={<ExclamationCircleOutlined style={{ color: "#F75E67" }} />}
                            placement="left"
                          >
                            <AntButton
                              style={{
                                fontSize: "12px",
                                marginTop: "4px",
                                padding: "0px 6px",
                                backgroundColor: "white !important",
                              }}
                              size="small"
                              type={"primary"}
                              ghost
                              active={"true"}
                              onClick={() => onClickDecreaseQty(item.get("id"))}
                            >
                              <MinusOutlined />
                            </AntButton>
                          </Popconfirm>
                          <AntButton
                            style={{
                              fontSize: "12px",
                              marginTop: "4px",
                              padding: "0px 6px",
                              backgroundColor: "white !important",
                            }}
                            size="small"
                            onClick={() => onClickIncreaseQty(item.get("id"))}
                            type={"primary"}
                            ghost
                          >
                            <PlusOutlined />
                          </AntButton>
                        </AntButton.Group>
                      </div>
                    ) : (
                      <React.Fragment>
                        {isEditModeActiveGlobally ? (
                          ((isPack && isEditingPackForProduct) || !isPack) &&
                          item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_TILE &&
                          (!isPhotoBook ? (
                            <AntButton
                              style={{
                                fontSize: "12px",
                                marginTop: "0px",
                                padding: "0px 7px",
                                borderRadius: "2px",
                                background: editModeActiveForItem ? "#4b5566" : "transparent",
                                color: editModeActiveForItem ? "white" : "inherit",
                                border: editModeActiveForItem ? "none" : "1px solid #ccc",
                              }}
                              size="small"
                              onClick={() => onClickEditModeActiveForItem(item, openPreviewOnEdit)}
                            >
                              {editModeActiveForItem ? "Done" : "Edit"}
                            </AntButton>
                          ) : (
                            <>
                              <AntButton
                                style={{
                                  fontSize: "12px",
                                  marginTop: "0px",
                                  padding: "0px 12px",
                                  borderRadius: "2px",
                                  background: "#4b5566",
                                  color: "white",
                                  border: "none",
                                  height: "36px",
                                }}
                                onClick={() => handleOpenBookPreview(item)}
                              >
                                Preview Book
                              </AntButton>
                              {photoBookOptions}
                            </>
                          ))
                        ) : (
                          <div>
                            {item.get("quantity")} ×{" "}
                            <Currency amount={item.getIn(["pricingScheme", "cost"])} />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <React.Fragment>
                    {isEditModeActiveGlobally ? (
                      <AntButton
                        style={{
                          fontSize: "12px",
                          marginTop: "0px",
                          padding: "0px 7px",
                          borderRadius: "2px",
                          background: editModeActiveForItem ? "#4b5566" : "transparent",
                          color: editModeActiveForItem ? "white" : "inherit",
                          border: editModeActiveForItem ? "none" : "1px solid #ccc",
                        }}
                        size="small"
                        onClick={() => onClickEditModeActiveForItem(item, openPreviewOnEdit)}
                      >
                        {editModeActiveForItem ? "DONE" : "EDIT"}
                      </AntButton>
                    ) : (
                      <Currency
                        amount={item.get("quantity") * item.getIn(["pricingScheme", "cost"])}
                      />
                    )}
                  </React.Fragment>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {showAddress && (item.get("address") || item.get("addressBookEntry")) && (
                  <p style={{ fontSize: 11, whiteSpace: "pre-line" }} onClick={onClickAddress}>
                    {generateMultilineAddress(address.toJS()).join("\n")}
                  </p>
                )}
              </td>
            </tr>
            {showShipping &&
              item.get("postageScheme") &&
              item.getIn(["postageScheme", "cost"]) !== 0 &&
              !isEditModeActiveGlobally && (
                <tr>
                  <td style={{ width: "70%", fontWeight: "400", fontSize: "12px" }}>Shipping</td>
                  <td
                    className="text-right"
                    style={{ width: "70%", fontWeight: "400", fontSize: "12px" }}
                  >
                    {item.getIn(["postageScheme", "cost"]) === 0 ? (
                      "Free"
                    ) : (
                      <Currency amount={item.getIn(["postageScheme", "cost"])} />
                    )}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        <br />
      </td>
    </tr>
  );
};

class Basket extends Component {
  static propTypes = {
    items: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    unapprovedItems: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
    prepayBalance: PropTypes.object,
    isCreatingOrder: PropTypes.bool,
    isPayingForOrder: PropTypes.bool,
    canMakeStripePayment: PropTypes.bool,
    paymentRequest: PropTypes.any,
    onClickPreview: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onDeleteItems: PropTypes.func,
    onCreateOrder: PropTypes.func,
    onCreateGuestOrder: PropTypes.func,
    onStripePaymentFormSubmit: PropTypes.func,
    onStripePaymentFormConfirm: PropTypes.func,
    onPayPalAuthorization: PropTypes.func,
    onShowAuthModal: PropTypes.func,
    onApplyPromoCode: PropTypes.func,
    onRemovePromoCode: PropTypes.func,
    onRetryRenders: PropTypes.func,
    showDuplicateAlertForItem: PropTypes.object,
    editModeEnabledFor: PropTypes.string,
    onShowGuestCaptureOrAuthModal: PropTypes.func,
    navigateToApproval: PropTypes.func,
  };

  static defaultProps = {};

  state = {
    isDeletionModeActive: false,
    isEditModeActive: false,
    isPaymentModalVisible: false,
    paymentModalPage: PAYMENT_MODAL_PAGES.OVERVIEW,
    isApprovalModalVisible: false,
    approvalMetaData: {
      canCompleteApproval: true,
    },
    isItemDuplicationAlertVisible: false,
    productNameForDuplicationItem: "",
    isApprovalModalProductFlipped: false,
    isCrossSellModalVisible: true,
    hasSeenCrossSellModal: false,
    promoCode: "",
    alert: null,
    modal: null,
    modalVisible: false,
    isLookingUpPromoCode: false,
    isAddingPromoCode: false,
    deletePopupEnabledForItem: null,
    editModeActiveForItem: null,
    isPayPalScriptLoaded: false,
    hasSuccessfulPayPalPayment: false,
    successfulPayPalPaymentId: null,
    showBasketContent: false,
  };

  componentDidMount() {
    this.checkMilitaryAddress();

    this.loadPayPalScript();
  }

  componentDidUpdate(prevProps) {
    if (this.props.items.size === 0 && this.state.isDeletionModeActive) {
      this.setState({
        isDeletionModeActive: false,
      });
    }

    if (this.props.items.size === 0) {
      this.props.clearCheckoutDetails();
    }

    if (this.props.unapprovedItems.size === 0 && this.state.isApprovalModalVisible) {
      this.setState(
        {
          isApprovalModalVisible: false,
        },
        () => {
          gtmEvent({
            event: "webAppStage",
            additionalProps: {
              stage: `Items Approved`,
            },
          });
        }
      );
    }

    if (this.props.unapprovedItems.size > 0) {
      const itemToApprove = this.props.unapprovedItems.first();

      if (itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE) {
        if (
          this.state.approvalMetaData.canCompleteApproval &&
          !this.state.approvalMetaData.pageObject
        ) {
          this.setState({
            approvalMetaData: {
              canCompleteApproval: false,
              pageObject: null,
            },
          });
        }
      } else if (
        [
          PRODUCT_TYPE_IDS.GREETING_CARD,
          PRODUCT_TYPE_IDS.POSTCARD,
          PRODUCT_TYPE_IDS.ANNOUNCEMENT,
        ].includes(itemToApprove.get("productTypeId"))
      ) {
        //console.log("canCompleteApproval", this.state.approvalMetaData.canCompleteApproval);
        //console.log("hasSeenAll", this.state.approvalMetaData.hasSeenAllPages);
        if (
          this.state.approvalMetaData.canCompleteApproval &&
          !this.state.approvalMetaData.hasSeenAllPages
        ) {
          console.log("Can't complete approval");
          this.setState({
            approvalMetaData: {
              canCompleteApproval: false,
            },
          });
        }
      } else if (!this.state.approvalMetaData.canCompleteApproval) {
        console.log("Can complete approval");
        this.setState({
          approvalMetaData: {
            canCompleteApproval: true,
          },
        });
      }
    }

    // if (this.props.showDuplicateAlertForItem) {
    //   let singularProductNameForItem = "this product";
    //   let multipleProductNameForItems = "these products";

    //   //console.log(this.props.showDuplicateAlertForItem.get("quantity"));
    //   switch (this.props.showDuplicateAlertForItem.get("productTypeId")) {
    //     case PRODUCT_TYPE_IDS.POSTCARD:
    //     case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
    //     case PRODUCT_TYPE_IDS.GREETING_CARD:
    //       singularProductNameForItem = "this card";
    //       multipleProductNameForItems = "these cards";
    //       break;
    //     case PRODUCT_TYPE_IDS.CANVAS:
    //       singularProductNameForItem = "this canvas";
    //       multipleProductNameForItems = "these canvases";
    //       break;
    //     case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
    //       singularProductNameForItem = "this book";
    //       multipleProductNameForItems = "these books";
    //       break;
    //     case PRODUCT_TYPE_IDS.PHOTO_PRINT:
    //       singularProductNameForItem = "this print";
    //       multipleProductNameForItems = "these prints";
    //       break;
    //     default:
    //       break;
    //   }

    //   const anyTemplatedItems = this.props.items.some((item) => item.get("isTemplate"))

    //   if (!this.state.isItemDuplicationAlertVisible && !anyTemplatedItems) {
    //     this.setState({
    //       isItemDuplicationAlertVisible: true,
    //       productNameForDuplicationItem:
    //         this.props.showDuplicateAlertForItem.get("quantity") === 1
    //           ? singularProductNameForItem
    //           : multipleProductNameForItems,
    //     });
    //   }
    // }
  }

  loadPayPalScript = () => {
    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${payPalClientID}&currency=${this.props.currency}&disable-funding=credit,card,sofort`;
    //script.async = true;
    script.addEventListener("load", () => {
      console.log("PayPal script loaded");
      this.setState({ isPayPalScriptLoaded: true });
    });
    document.body.appendChild(script);
  };

  handleClickStripePaymentRequestButton = e => {
    if (this.props.unapprovedItems.size > 0) {
      e.preventDefault();
      this.openApprovalModal();
    }
  };

  createOrderAndShowPaymentOptions = async asGuest => {
    if (Object.keys(this.props.guestDetails.toJS()).length > 0) {
      //console.log("this.props.guestDetails", this.props.guestDetails.toJS())
    }
    //console.log("this.props.guestToken", this.props.guestToken)
    let orderCreationResponse;
    if (asGuest) {
      //|| this.props.guestToken === null
      if (Object.keys(this.props.guestDetails.toJS()).length === 0) {
        message.info("We have no guest details, please sign in or add guest details");
        this.props.onShowGuestCaptureOrAuthModal({
          actionAfterGuestInfo: () => this.createOrderAndShowPaymentOptions(true),
          actionAfterAuth: () => this.createOrderAndShowPaymentOptions(false),
        });
        return false;
      } else {
        orderCreationResponse = await this.props.onCreateOrder(asGuest);
      }
    } else {
      orderCreationResponse = await this.props.onCreateOrder(false);
      //console.log("Order Response Raw", orderCreationResponse);
    }

    //console.log("Order Response Raw", orderCreationResponse);
    // console.log("Order Response", orderCreationResponse.statusText);
    if (orderCreationResponse && orderCreationResponse.status !== 200) {
      //console.log("Order Response Data", orderCreationResponse.data);
      let errorMessage;
      if (orderCreationResponse?.data?.errors?.errors) {
        if (orderCreationResponse.data.errors.errors.base) {
          errorMessage =
            orderCreationResponse.data.errors.base[0].message ||
            orderCreationResponse.data.errors.base[0];
        } else {
          errorMessage = null;
        }
      } else {
        errorMessage = null;
      }
      
      if (this.props.orderSummary.toJS()?.promotionInfo?.data?.code){
        errorMessage = "There was a problem creating your order - promotion code is invalid.";
      }

      this.setState({
        alert: {
          type: "error",
          title: "Error",
          text: errorMessage || "There was a problem creating your order.",
          showCancelButton: false,
          confirmButtonText: "OK",
          onConfirm: this.clearAlert,
        },
      });
    } else if (orderCreationResponse && orderCreationResponse.status === 200) {
      const response = orderCreationResponse.data;
      //console.log(response);
      if (response.success) {
        this.setState(
          {
            isPaymentModalVisible: true,
          },
          () => {
            gtmEvent({
              event: "webAppStage",
              additionalProps: {
                stage: `Payment Method`,
              },
            });
          }
        );
      } else {
        let errorMessage;
        if (response.errors) {
          if (response.errors.promotion) {
            errorMessage = response.errors.promotion[0];
          } else {
            if (response.errors.base) {
              errorMessage = response.errors.base[0].message || response.errors.base[0];
            } else if (response.errors.line_items) {
              errorMessage = response.errors.line_items.pop();
            }
          }
        }
        this.setState({
          alert: {
            type: "error",
            title: "Error",
            text: errorMessage || "There was a problem creating your order.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
        });
      }
    } else {
      this.setState(
        {
          isPaymentModalVisible: true,
        },
        () => {
          gtmEvent({
            event: "webAppStage",
            additionalProps: {
              stage: `Payment Method`,
            },
          });
        }
      );
    }
  };

  checkMilitaryAddress = () => {
    let containsPFOAddress = this.props.items.some(item => {
      const address =
        item.get("address") && Object.keys(item.get("address")).length > 0
          ? item.get("address")
          : item.get("addressBookEntry");
      if (address) {
        let anyMilitaryStates = militaryStates.some(ms => ms.name === address.get("county"));
        return anyMilitaryStates;
      } else {
        return false;
      }
    });

    if (containsPFOAddress) {
      this.setState({
        alert: {
          type: "success",
          title: "",
          text:
            "<p>🇺🇸 We're so grateful for US service personnel worldwide.</p> <br/><p>Use code <strong style='color: #ffffff;margin: 3px;padding: 5px 10px;background: #7b7878;'><code>MILITARY50</code></strong> for <strong>50%</strong> off all postcards to US military personnel.</p>",
          showCancelButton: false,
          confirmButtonText: "OK 👌",
          onConfirm: this.clearAlert,
        },
      });
    }
  };

  toggleDeletionMode = () => {
    this.setState({
      isDeletionModeActive: !this.state.isDeletionModeActive,
    });
  };

  toggleEditMode = () => {
    this.setState({
      isEditModeActive: !this.state.isEditModeActive,
      editModeActiveForItem: null,
    });
  };

  openApprovalModal = () => {
    const anyPrintsNeedApproving = this.props.unapprovedItems.some(
      item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT
    );
    if (anyPrintsNeedApproving) {
      this.props.navigateToApproval({ fromBasket: true });
    } else {
      this.props.navigateToApproval({ fromBasket: true });
      // this.setState({
      //   isApprovalModalVisible: true,
      // }, () => {
      //   const itemToApprove = this.props.unapprovedItems.first();
      //   const product = productsByProductId.get(itemToApprove.get("productId"));
      //   gtmEvent({
      //     event: "webAppStage",
      //     additionalProps: {
      //       stage: 'Product Approval',
      //       product: product.get('appKey')
      //     }
      //   })
      // });
    }
  };

  retryRenders = () => {
    console.log("Retrying renders");
    this.props.onRetryRenders();
  };

  basketItems = React.createRef();

  scrollToBasketItems = e => {
    setTimeout(() => {
      if (this.basketItems.current) {
        this.basketItems.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 200);
  };

  toggleBasketContent = () => {
    this.setState(
      {
        showBasketContent: !this.state.showBasketContent,
        isEditModeActive: !this.state.isEditModeActive,
        isDeletionModeActive: !this.state.isEditModeActive,
      },
      () => {
        this.scrollToBasketItems();
      }
    );
  };

  closeApprovalModal = () => {
    this.setState({
      isApprovalModalVisible: false,
    });
  };

  closePaymentModal = () => {
    this.setState({
      isPaymentModalVisible: false,
      paymentModalPage: PAYMENT_MODAL_PAGES.OVERVIEW,
    });
  };

  selectPaymentMethod = paymentMethod => {
    switch (paymentMethod) {
      case PAYMENT_METHODS.CREDIT_DEBIT_CARD: {
        if (
          this.props.user &&
          this.props.user.get("stripe_token") &&
          this.props.user.getIn(["stripe_card_details", "saved_card"])
        ) {
          this.showPaymentModalPage(PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM);
        } else {
          this.showPaymentModalPage(PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM);
        }
        break;
      }
      case PAYMENT_METHODS.PREPAY:
        this.showPaymentModalPage(PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT);
        break;
      // no default
    }
  };

  printsPanelRef = React.createRef();

  scrollToPrintsPanel = e => {
    // setTimeout(() => {
    //   if(this.printsPanelRef.current){
    //     this.printsPanelRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
    //   }
    // }, 450)
  };

  handleChangePromotionCodeInput = e => {
    this.setState({
      promoCode: e.target.value.toUpperCase(),
    });
  };

  handleClearPromoCode = () => {
    this.setState(
      {
        promoCode: "",
      },
      () => {
        this.props.onRemovePromoCode();
      }
    );
  };

  handleShowPromoCode = () => {
    this.setState(
      {
        isAddingPromoCode: true,
      },
      () => {
        setTimeout(() => {
          this.promoCodeInput.focus();
        }, 500);
      }
    );
  };

  handleHidePromoCode = () => {
    this.setState({
      isAddingPromoCode: false,
    });
  };

  handleSubmitPromoCodeForm = async e => {
    e.preventDefault();
    this.setState({
      isLookingUpPromoCode: true,
    });
    try {
      const response = await this.props.onApplyPromoCode(this.state.promoCode);
      if (!response.payload.data.success) {
        this.setState({
          alert: {
            type: "error",
            title: "Invalid Code",
            text: "It looks like this code doesn't exist, or has expired.",
            showCancelButton: false,
            confirmButtonText: "OK",
            onConfirm: this.clearAlert,
          },
          isLookingUpPromoCode: false,
        });
      } else {
        this.setState(
          {
            isLookingUpPromoCode: false,
            isAddingPromoCode: false,
          },
          () => {
            if (response.payload.data.data.dismiss_message && response.payload.data.data.dismiss_message_text) {
              this.setState({
                alert: {
                  type: "info",
                  title: response.payload.data.data.description || "",
                  text: response.payload.data.data.dismiss_message_text,
                  showCancelButton: false,
                  confirmButtonText: "OK",
                  onConfirm: this.clearAlert,
                }
              });
            } else {
              message.success(response.payload.data.data.message);
            }
          }
        );
      }
    } catch (err) {
      this.setState({
        isLookingUpPromoCode: false,
      });
    }
  };

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  toggleFlippedForApprovalModal = () => {
    this.setState({
      isApprovalModalProductFlipped: !this.state.isApprovalModalProductFlipped,
    });
  };

  handleApproveItem = item => {
    this.props.onApproveItem(item);
    this.setState({
      isApprovalModalProductFlipped: false,
      approvalMetaData: {
        canCompleteApproval: this.props.unapprovedItems.size > 0 ? false : true,
      },
    });
  };

  handleApproveAllItems = () => {
    const multipleToApprove = this.props.unapprovedItems.size > 1;

    let approvalText = multipleToApprove
      ? `all the remaining ${this.props.unapprovedItems.size} items without checking each one?`
      : `this?`;

    confirm({
      title: multipleToApprove ? "Approve all?" : "Approve",
      content: `Are you sure you want to approve ${approvalText} We do not check orders before printing and shipping.`,
      okText: multipleToApprove ? "Yes — Approve All" : "Approve",
      cancelText: "No",
      icon: null,
      onOk: () => {
        this.props.onApproveAllItems();
        message.success("All items approved");
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  handleEditItem = item => {
    this.closeApprovalModal();
    // TODO, might need a different route to return us to the approval modal after saving?
    this.props.onClickPreview(item);
  };

  handleOnClickEditModeForItem = item => {
    if (this.state.editModeActiveForItem === item.get("id")) {
      this.setState({
        editModeActiveForItem: null,
      });
    }
    //this.props.onClickEditModeForItem(item);
  };

  handleOpenBookPreview = item => {
    const itemId = item.get("id");
    this.setState(
      {
        editModeActiveForItem: itemId,
      },
      () => {
        this.handleEditItem(item);
      }
    );
  };

  handleClickEditModeActiveForItem = (item, openPreview = true) => {
    const itemId = item.get("id");
    if (this.state.editModeActiveForItem === itemId) {
      this.setState({
        editModeActiveForItem: null,
      });
    } else {
      this.setState(
        {
          editModeActiveForItem: itemId,
        },
        () => {
          if (openPreview) {
            this.handleEditItem(item);
          }
        }
      );
    }
  };

  checkCanIncreaseQtyForItem = item => {
    const productID = item.get("productId");
    const hasMinimumQty = hasMinimumQuantityForProduct(productID);

    if (hasMinimumQty) {
      const minimumQty = hasMinimumQty ? minimumQuantityForProduct(productID) : 0;
      //const allItemsForMinimumQuantityProduct = this.props.orderSummary.get("items").filter(i => i.get("productId") === productID);
      const messageContent = `This product is sold in packs of ${minimumQty} (${minimumQty *
        2}, ${minimumQty * 3} etc).
        You'll need to adjust the quantities to make packs of 20. Would you like to round up to the next pack quantity or adjust the print quantities manually?`;
      confirm({
        title: "Pack quantities",
        content: messageContent,
        okText: "Increase Quantity",
        cancelText: "Cancel",
        onOk: () => {
          this.props.onIncreaseQuantityForItem(item.get("id"));
          //this.props.onDeleteItems(toDeleteItemIds);
          //message.success(`All ${productDescription}s removed`);
          console.log("Round Up");
        },
        onCancel: () => {
          //this.props.onDecreaseQuantityForItem(item.get("id"));
          console.log("Round Down");
        },
      });
      return false;
    } else {
      return true;
    }
  };

  checkCanDeleteOrReduceQtyForItem = item => {
    const productID = item.get("productId");
    const hasMinimumQty = hasMinimumQuantityForProduct(productID);
    if (hasMinimumQty) {
      const allItemsForMinimumQuantityProduct = this.props.orderSummary
        .get("items")
        .filter(i => i.get("productId") === productID);
      const toDeleteItemIds = allItemsForMinimumQuantityProduct.map(item => item.get("id"));
      let messageContent = `This product is sold in packs. Deleting this print will remove the entire pack. Are you sure you want to delete this pack?`;
      confirm({
        title: "Delete Pack?",
        content: messageContent,
        okText: "Delete",
        cancelText: "Cancel",
        onOk: () => {
          this.props.onDeleteItems(toDeleteItemIds.toJS());
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
      return false;
    } else {
      return true;
    }
  };

  handleDecreaseQuantityForItem = item => {
    const greetingCardPack =
      item.get("packMode") && item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD;
    if (this.checkCanDeleteOrReduceQtyForItem(item)) {
      if (
        (item.get("quantity") === 1 && !greetingCardPack) ||
        (greetingCardPack && item.get("packQuantity") === 8)
      ) {
        this.setState({
          deletePopupEnabledForItem: item.get("id"),
        });
      } else {
        if (greetingCardPack) {
          this.props.onDecreasePackQuantityForItem(item.get("id"), this.props.currency);
        } else {
          this.props.onDecreaseQuantityForItem(item.get("id"));
        }
      }
    }
  };

  handleIncreaseQuantityForItem = item => {
    //if(this.checkCanIncreaseQtyForItem(item)){
    if (item.get("packMode") && item.get("productTypeId") === PRODUCT_TYPE_IDS.GREETING_CARD) {
      this.props.onIncreasePackQuantityForItem(item.get("id"), this.props.currency);
    } else {
      this.props.onIncreaseQuantityForItem(item.get("id"));
    }

    //}
  };

  handleDismissDeletePopupFor = item => {
    this.setState({
      deletePopupEnabledForItem: null,
    });
  };

  handleConfirmDeletePopupFor = item => {
    this.setState(
      {
        deletePopupEnabledForItem: null,
      },
      () => {
        this.props.onDeleteItem(item.get("id"));
      }
    );
  };

  handleDeleteItem = item => {
    if (this.checkCanDeleteOrReduceQtyForItem(item)) {
      confirm({
        title: "Delete?",
        content: "Are you sure you want to delete this item from your order?",
        okText: "Yes",
        cancelText: "No",
        icon: null,
        onOk: () => {
          this.props.onDeleteItem(item.get("id"));
          message.success("Item deleted");
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    }
  };

  handleClickPackItem = item => {
    this.setState({
      isEditingPackForProduct: item.get("productId"),
      showPackHideForItem: item.get("id"),
    });
  };

  handleClickHidePackItem = item => {
    this.setState({
      isEditingPackForProduct: null,
      showPackHideForItem: null,
      editModeActiveForItem: null,
    });
  };

  showPaymentModalPage = pageId => {
    this.setState(
      {
        paymentModalPage: pageId,
      },
      () => {
        gtmEvent({
          event: "webAppStage",
          additionalProps: {
            stage: `Payment Page - ${pageId}`,
          },
        });
      }
    );
  };

  handleDuplicationAlertGoToBasket = () => {
    if (this.props.showDuplicateAlertForItem) {
      this.props.onDismissDuplicateAlertItem(this.props.showDuplicateAlertForItem.get("id"));
    }
    this.setState({
      isItemDuplicationAlertVisible: false,
    });
  };

  handleDuplicationAlertDuplicate = () => {
    if (this.props.showDuplicateAlertForItem) {
      this.props.onClickDuplicate(this.props.showDuplicateAlertForItem);
      this.props.onDismissDuplicateAlertItem(this.props.showDuplicateAlertForItem.get("id"));
    }

    this.setState({
      isItemDuplicationAlertVisible: false,
    });
  };

  handleCrossSellOk = () => {
    this.setState({
      isCrossSellModalVisible: false,
    });
    this.props.onSetSeenCrossSellModal(true);
    this.props.goToRoute(routeCreators.magazineEditor());
  };

  handleCrossSellCancel = () => {
    this.setState({
      isCrossSellModalVisible: false,
    });
    this.props.onSetSeenCrossSellModal(true);
  };

  handleChangeMagazineApprovalPage = pageObject => {
    //console.log("pageObject", pageObject);
    this.setState({
      approvalMetaData: {
        ...this.state.approvalMetaData,
        canCompleteApproval:
          this.state.canCompleteApproval === true ? true : pageObject.lastPage === true,
        pageObject: pageObject,
      },
    });
  };

  handleChangeProductApprovalPage = (hasSeenAllPages, pageNumber) => {
    // console.log("hasSeenAllPages", hasSeenAllPages);
    // console.log("pageNumber", pageNumber);
    // console.log("state.canCompleteApproval", this.state.canCompleteApproval);
    // console.log("set canCompleteApproval to", this.state.canCompleteApproval === true ? true : hasSeenAllPages === true)
    this.setState({
      approvalMetaData: {
        ...this.state.approvalMetaData,
        canCompleteApproval:
          this.state.canCompleteApproval === true ? true : hasSeenAllPages === true,
        hasSeenAllPages: hasSeenAllPages,
      },
    });
  };

  handleDeleteAll = () => {
    this.props.onDeleteAllItems();
    this.clearAlert();
  };

  handleShowDeleteAllAlert = () => {
    this.setState({
      alert: {
        type: "info",
        title: "Clear your basket?",
        text: "Are you sure you wish to remove all your items from your basket?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        onConfirm: this.handleDeleteAll,
        onCancel: this.clearAlert,
        animation: false,
        customClass: "animated fadeIn",
      },
    });
  };

  handlePaypalAuthorization = async response => {
    console.log("Handling PayPal authorization");
    this.setState({
      successfulPayPalPaymentId: response.id,
      hasSuccessfulPayPalPayment: response.status === "COMPLETED",
    });

    try {
      await this.props.onPayPalAuthorization(response);
      //console.log("Done onPayPalAuthorization");
      //this.showSuccessConfirmation();
    } catch (err) {
      console.log("Error handling Paypal auth", err);
      //this.showErrorAlert(err);
    } finally {
      console.log("Done");
    }
  };

  goToShop = () => {
    // TODO: extract this to ENV var
    window.location = process.env.REACT_APP_BASE_URL || "https://www.postsnap.com";
  };

  goToPrints = () => {
    // TODO: extract this to ENV var
    window.location = `${process.env.REACT_APP_BASE_URL ||
      "https://www.postsnap.com"}/photo-printing`;
  };

  goToPrintsInApp = () => {
    this.props.onGoToPrints();
  };

  editGuestDetails = () => {
    this.props.onEditGuestDetails();
  };

  editContactDetails = () => {
    this.props.onGoToContactDetails();
  };

  gotToshippingDetails = () => {
    if (this.props.isSignedIn) {
      this.props.onGoToShipping("new");
    } else {
      this.props.onGoToGuestOrCustomer();
    }
  };

  render() {
    if (this.props.orderSummary.get("items").size === 0) {
      return (
        <React.Fragment>
          <Header
            key="top-header"
            theme="grey"
            image="/images/logo.svg"
            title="PostSnap Postcard App"
            rightAction={
              <div style={{ display: "flex" }}>
                <AccountButtonContainer />
                <BasketButtonContainer />
              </div>
            }
            leftAction={<ShopButtonContainer />}
            onClickTitleOrImage={this.goToShop}
          />
          <MainContent scrollable padded centeredVertically>
            {process.env.NODE_ENV === "development" && (
              <Button
                label="Reset App"
                block
                size="small"
                priority="tertiary"
                theme="dark-blue"
                onClick={this.props.resetAll}
              />
            )}
            <Icon name="cart" size="full-width" style={{ opacity: 0.2 }} />
            <p className="text-center muted">You have no items in your basket.</p>
            <Button block label="Start Shopping" theme="dark-blue" onClick={this.goToShop} />
          </MainContent>
        </React.Fragment>
      );
    }

    let areAnyMinimQuantitiesNotMet = false;
    const bagHasMinimumQtyProduct = this.props.orderSummary
      .get("items")
      .filter(i => hasMinimumQuantityForProduct(i.get("productId")));
    if (bagHasMinimumQtyProduct.count() > 0) {
      const minQtyProductsGroupedByProducts = bagHasMinimumQtyProduct.groupBy(product =>
        product.get("productId")
      );
      Object.entries(minQtyProductsGroupedByProducts.toJS()).forEach(entry => {
        const [productId] = entry;
        const minimumQtyProductId = parseInt(productId);
        const allItemsForMinimumQuantityProduct = this.props.orderSummary
          .get("items")
          .filter(i => i.get("productId") === minimumQtyProductId);
        const minimumQty = minimumQuantityForProduct(minimumQtyProductId);
        const totalQuantityForMinimumQuantityProduct = allItemsForMinimumQuantityProduct.reduce(
          (total, item) => total + item.get("quantity"),
          0
        );
        const isMinimumQty = totalQuantityForMinimumQuantityProduct === minimumQty;
        const isPackValidPackSize =
          totalQuantityForMinimumQuantityProduct > minimumQty &&
          totalQuantityForMinimumQuantityProduct % minimumQty === 0;
        if ((isMinimumQty || isPackValidPackSize) !== true) {
          areAnyMinimQuantitiesNotMet = false; //true;
        }
      });
    }

    let invalidProductShipping = false;
    //const restrictedProductAppKeys = ['2×3_PRINTS', '2×2.5_PRINTS']
    const restrictedProductTypeAppKeys = [PRODUCT_TYPE_IDS.PHOTO_PRINT];
    const validPrintDestinationCountries = ["United Kingdom", "United States"];

    const anyRestricted = this.props.orderSummary.get("items").some(item => {
      const restrictedProductType = restrictedProductTypeAppKeys.includes(
        item.get("productTypeId")
      );
      const address = item.get("address") || item.get("addressBookEntry");
      const invalidAddressCountry =
        address &&
        address.size > 0 &&
        !validPrintDestinationCountries.includes(address.get("country"));
      //console.log("invalidAddressCountry", invalidAddressCountry)
      const restrictedProductToUnsupportedAddress =
        restrictedProductType && address && invalidAddressCountry;
      //console.log("restrictedProductToUnsupportedAddress", restrictedProductToUnsupportedAddress)
      return restrictedProductToUnsupportedAddress;
    });

    if (anyRestricted) {
      invalidProductShipping = true;
    }

    const checkoutHidden = invalidProductShipping; //areAnyMinimQuantitiesNotMet || invalidProductShipping;

    const areAnyItemsRendering = this.props.orderSummary
      .get("items")
      .some(i => i.get("isRendering"));

    const areAnyItemsRenderFailed = this.props.orderSummary
      .get("items")
      .some(i => i.get("renderFailed"));

    const rendersInProgress = this.props.orderSummary
      .get("items")
      .filter(i => i.get("isRendering"))
      .count();
    //console.log("Renders is progress", rendersInProgress)
    //const rendersCompleted  = this.props.orderSummary.get("items").filter(i => i.get("render")).count();
    //console.log("rendersCompleted", rendersCompleted);
    const renderProgress = 1 - rendersInProgress / this.props.orderSummary.get("items").count();
    const progressPercent = Math.ceil(renderProgress * 100);
    //console.log("Render progress", renderProgress);

    //const anyUnapprovedItem = this.props.unapprovedItems.size > 0;

    let renderingLabel = "";

    if (areAnyItemsRendering) {
      const itemsRendering = this.props.orderSummary.get("items").filter(i => i.get("isRendering"));
      const renderingProducts = itemsRendering.map(item => {
        return item.get("description");
      });
      const uniqueRenderingProducts = [...new Set(renderingProducts)];
      const multipleProducts = uniqueRenderingProducts.length > 1;
      const isCanvasRendering = itemsRendering.some(
        rendering => rendering.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS
      );
      const preparingSingleLabel = isCanvasRendering
        ? "Preparing your canvas"
        : `Preparing your ${Pluralize(uniqueRenderingProducts[0], renderingProducts.size)}...`;
      renderingLabel = multipleProducts ? "Preparing your cards" : preparingSingleLabel;
    }

    const isInWrongRegionForPrepay =
      this.props.isSignedIn && this.props.prepayBalance.currency !== this.props.currency;
    const hasEnoughPrepayBalanceToPayForOrder =
      this.props.isSignedIn &&
      this.props.prepayBalance.amount >= this.props.orderSummary.get("total");

    const disabledPrepayPurchasesForProductsIds = [
      PRODUCT_TYPE_IDS.PHOTO_PRINT,
      PRODUCT_TYPE_IDS.PHOTO_MAGAZINE,
      PRODUCT_TYPE_IDS.CANVAS,
    ];
    const canPayWithPrepayCredit = !this.props.orderSummary
      .get("items")
      .some(item => disabledPrepayPurchasesForProductsIds.includes(item.get("productTypeId")));

    let paymentModalProps = {};

    switch (this.state.paymentModalPage) {
      case PAYMENT_MODAL_PAGES.OVERVIEW:
        paymentModalProps = {
          title: "Payment Method",
        };
        break;
      case PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM:
        paymentModalProps = {
          title: "Card Payment",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.showPaymentModalPage(PAYMENT_MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      case PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT:
        paymentModalProps = {
          title: "Prepay Payment",
          leftAction: (
            <Button
              label="Back"
              priority="tertiary"
              theme="muted"
              onClick={() => this.showPaymentModalPage(PAYMENT_MODAL_PAGES.OVERVIEW)}
            />
          ),
        };
        break;
      // no default
    }

    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    const isFreeOrder = this.props.orderSummary.get("total") === 0;
    const picCollage = this.props.orderSummary.get("items").some(item => item.get("picCollage"));
    //console.log("paymentRequest", this.props.paymentRequest);
    //console.log("canMakeStripePayment", this.props.canMakeStripePayment);

    const paymentModal = (
      <Modal
        key="payment-modal"
        padded
        isOpen={this.state.isPaymentModalVisible}
        onClose={this.closePaymentModal}
        paged
        activePage={this.state.paymentModalPage}
        {...paymentModalProps}
      >
        <ModalPage pageId={PAYMENT_MODAL_PAGES.OVERVIEW} depth={1}>
          <MainContent scrollable={false} padded={false}>
            <div className="text-center">
              <p style={{ fontSize: "22px" }}>
                Order total: <Currency amount={this.props.orderSummary.get("total")} />
              </p>

              {this.props.isSignedIn &&
                !isInWrongRegionForPrepay &&
                !isFreeOrder &&
                canPayWithPrepayCredit && (
                  <p>
                    Your Postcard Credit balance:{" "}
                    <Currency amount={this.props.prepayBalance.amount} />
                  </p>
                )}

              <br />
            </div>
            <div style={{ width: "100%", maxWidth: 500, margin: "0 auto" }}>
              {isFreeOrder ? (
                <React.Fragment>
                  <p style={{ textAlign: "center" }}>
                    Your order is free, tap below to complete this order:
                  </p>
                  <Button
                    block
                    theme={picCollage ? "pc" : "dark-blue"}
                    priority={picCollage ? "secondary" : "primary"}
                    label="Complete Order"
                    loadingLabel="Completing Order..."
                    loading={this.props.isPayingForOrder}
                    onClick={this.props.onPayForOrderWithPrepayBalance}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.props.canMakeStripePayment && (
                    <div style={{ marginBottom: "20px" }}>
                      <StripePaymentRequestButton
                        onClick={this.handleClickStripePaymentRequestButton}
                        paymentRequest={this.props.paymentRequest}
                      />
                    </div>
                  )}
                  <Button
                    block
                    label="Pay with Credit/Debit Card"
                    onClick={() => this.selectPaymentMethod(PAYMENT_METHODS.CREDIT_DEBIT_CARD)}
                    dataGtmElement="Pay with Card"
                    theme="dark-blue"
                  />
                  {canPayWithPrepayCredit && hasEnoughPrepayBalanceToPayForOrder && (
                    <Button
                      block
                      label="Pay with PrePay Credit"
                      priority="secondary"
                      onClick={() => this.selectPaymentMethod(PAYMENT_METHODS.PREPAY)}
                      disabled={isInWrongRegionForPrepay}
                      dataGtmElement="Pay with PrePay"
                      theme="dark-blue"
                    />
                  )}
                  {canPayWithPrepayCredit && !hasEnoughPrepayBalanceToPayForOrder && (
                    <Button
                      block
                      priority="secondary"
                      theme="dark-blue"
                      label="Add PrePay Credit"
                      link={routeCreators.prepay()}
                      dataGtmElement="Add PrePay"
                    />
                  )}
                  {canPayWithPrepayCredit && isInWrongRegionForPrepay && (
                    <p className="help-text text-center" style={{ marginTop: -16 }}>
                      You can only use Postcard Credit credit in the region
                      <br />
                      your account was created in ({this.props.prepayBalance.currency})
                    </p>
                  )}
                  {/* <PayPalButton
                    amount={this.props.orderSummary.get("total")}
                    currency={this.props.currency}
                    onAuthorize={this.props.onPayPalAuthorization}
                    disabled={this.props.isPayingForOrder}
                  /> */}
                  {!this.props.isPayingForOrder &&
                    this.state.hasSuccessfulPayPalPayment &&
                    this.state.successfulPayPalPaymentId && (
                      <Button
                        block
                        priority="secondary"
                        label="Re-confirm PayPal payment"
                        onClick={() =>
                          this.props.onPayPalAuthorization({
                            id: this.state.successfulPayPalPaymentId,
                          })
                        }
                      />
                    )}

                  {!this.props.isPayingForOrder &&
                    this.state.isPayPalScriptLoaded &&
                    !this.state.hasSuccessfulPayPalPayment && (
                      <PayPalButton
                        data-gtm-element="Pay With PayPal"
                        amount={this.props.orderSummary.get("total").toString()}
                        shippingPreference="NO_SHIPPING"
                        currency={this.props.currency}
                        options={{
                          debug: isSandbox,
                          clientId: payPalClientID,
                          currency: this.props.currency,
                          disableFunding: "card,credit,sofort",
                        }}
                        style={{
                          size: "responsive",
                          color: "blue",
                          label: "pay",
                          tagline: false,
                          height: 44,
                          maxHeight: 44,
                        }}
                        onSuccess={(details, data) => {
                          console.log("Transaction completed by", details.payer.name.given_name);
                          //console.log("PayPal data", data);
                          //console.log("PayPal details", details);
                          // TODO check if details.status === "COMPLETED"
                          this.handlePaypalAuthorization(details);
                        }}
                        createOrder={(data, actions) => {
                          console.log("PayPal Clicked");
                          gtmEvent({
                            event: "webAppEvent",
                            additionalProps: { eventName: "PayPal Button Clicked" },
                          });
                          return actions.order.create({
                            purchase_units: [
                              {
                                description: `PostSnap Order #${this.props.orderData.reference}`, //127 chars
                                custom_id: this.props.orderData.reference,
                                reference_id: this.props.orderData.reference,
                                invoice_id: this.props.orderData.reference,
                                payment_descriptor: "PostSnap", // 22 chars
                                soft_descriptor: this.props.orderData.reference,
                                amount: {
                                  currency_code: this.props.currency,
                                  value: this.props.orderSummary.get("total").toString(),
                                },
                              },
                            ],
                            application_context: {
                              shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
                              brand_name: "PostSnap",
                              user_action: "PAY_NOW", // default is COMPLETE
                              // payment_method: {
                              //   payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED' // Accepts only immediate payment from the customer. For example, credit card, PayPal balance, or instant ACH. Ensures that at the time of capture, the payment does not have the `pending` status.
                              // }
                            },
                          });
                        }}
                        onError={error => {
                          console.log("PayPal Error", error);
                          message.info(
                            "There was a problem with your payment, please try again or contact us"
                          );
                        }}
                        catchError={error => {
                          console.log("Caught PayPal Error", error);
                          message.info(
                            "There was a problem with your payment, please try again or contact us"
                          );
                        }}
                        onApprove={(data, actions) => {
                          console.log("onApprove Data", data);
                          return actions.order.capture().then(details => {
                            message.loading("Payment authorised");
                            // TODO: Show confirmation message....
                            //console.log('data',data);
                            //console.log('details',details);
                          });
                        }}
                        onCancel={() => message.info("Payment cancelled")}
                      />
                    )}
                </React.Fragment>
              )}
            </div>
          </MainContent>
        </ModalPage>
        <ModalPage pageId={PAYMENT_MODAL_PAGES.STRIPE_CREDIT_CARD_FORM} depth={2}>
          <StripePaymentForm
            amount={this.props.orderSummary.get("total")}
            onSubmit={this.props.onStripePaymentFormSubmit}
            confirmStripePayment={this.props.onStripePaymentFormConfirm}
            onPaymentComplete={this.props.onStripePaymentFormComplete}
            onPaymentError={this.props.onStripePaymentFormError}
            onPayWithSavedCard={this.props.onPayWithSavedCard}
            isPaying={this.props.isPayingForOrder}
            isPayingWithSavedCard={this.props.isPayingForOrderWithSavedCard}
            disabled={this.props.isPayingForOrderWithSavedCard}
            currency={this.props.currency}
            enableSavedCard={false}
            defaultPostcode={this.props.checkoutShippingAddress.get("postcode")}
          />
        </ModalPage>
        <ModalPage pageId={PAYMENT_MODAL_PAGES.PREPAY_CREDIT_PAYMENT} depth={2}>
          {this.props.isSignedIn && (
            <p>
              Credit remaining: <Currency amount={this.props.prepayBalance.amount} />
            </p>
          )}
          <p>
            Order total: <Currency amount={this.props.orderSummary.get("total")} />
          </p>
          <Button
            label="Pay with Prepay Credit"
            loadingLabel="Making Payment..."
            loading={this.props.isPayingForOrder}
            disabled={!hasEnoughPrepayBalanceToPayForOrder}
            onClick={this.props.onPayForOrderWithPrepayBalance}
          />
        </ModalPage>
      </Modal>
    );

    let approvalModalContent = null;

    let allowApproveAll = false;

    let approvalModalTitle = "Approve your items";

    if (this.props.unapprovedItems.size > 0) {
      const itemToApprove = this.props.unapprovedItems.first();

      const isCanvas = itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.CANVAS;
      const productSlug =
        isCanvas && productsByProductId.get(itemToApprove.get("productId")).get("url_slug");
      if (itemToApprove.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
        allowApproveAll = true;
      }

      switch (itemToApprove.get("productTypeId")) {
        case PRODUCT_TYPE_IDS.CANVAS:
          approvalModalContent = (
            <React.Fragment>
              <ThreeDimensionalViewer
                model={MODELS[productSlug] || MODELS.canvas}
                image={itemToApprove.get("preview_s3_key")}
              />
              <p className="help-text text-center" style={{ zIndex: 1 }}>
                This preview is for illustrative purposes only. The actual product may differ
                slightly from what is shown here.
              </p>
              <p className="help-text text-center" style={{ zIndex: 1 }}>
                Please tap below to confirm that you have triple-checked your product. All sales are
                final. Please note that we do not check orders before printing and shipping.
              </p>
            </React.Fragment>
          );
          approvalModalTitle = "Approve your Canvas";
          break;
        case PRODUCT_TYPE_IDS.PHOTO_PRINT:
          approvalModalContent = (
            <React.Fragment>
              <div className="photo-print-previews-container" style={{ height: "80%" }}>
                <PhotoPrintPreview item={itemToApprove} />
              </div>
              <br />
              <p className="help-text text-center">
                Please tap below to confirm that you've checked this print. We do not check orders
                before printing & shipping.
              </p>
            </React.Fragment>
          );
          approvalModalTitle =
            this.props.unapprovedItems.size > 1 ? "Approve your Prints" : "Approve your Print";
          break;
        case PRODUCT_TYPE_IDS.PHOTO_MAGAZINE:
          approvalModalContent = (
            <React.Fragment>
              <PhotoMagazinePreview
                item={itemToApprove}
                pageCount={productsByProductId.get(itemToApprove.get("productId")).get("pages")}
                onChangePage={this.handleChangeMagazineApprovalPage}
              />
              <br />
              <p className="help-text text-center">
                Please swipe through the preview information until you reach the end of the book,
                after which you'll be able to approve your photo book. All sales are final. Please
                note that we do not check orders before printing and shipping.
              </p>
            </React.Fragment>
          );
          approvalModalTitle = "Approve your Book";
          break;
        case PRODUCT_TYPE_IDS.GREETING_CARD:
          approvalModalContent = (
            <React.Fragment>
              <GreetingCardPreview
                item={itemToApprove}
                pageCount={productsByProductId.get(itemToApprove.get("productId")).get("pages")}
                onChangePage={(hasSeenAllPages, pageNumber) =>
                  this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
                }
              />
            </React.Fragment>
          );
          approvalModalTitle = "Approve your Card";
          break;
        case PRODUCT_TYPE_IDS.POSTCARD:
          approvalModalContent = (
            <React.Fragment>
              <PostcardPreview
                item={itemToApprove}
                pageCount={productsByProductId.get(itemToApprove.get("productId")).get("pages")}
                onChangePage={(hasSeenAllPages, pageNumber) =>
                  this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
                }
              />
            </React.Fragment>
          );
          approvalModalTitle = "Approval";
          break;
        case PRODUCT_TYPE_IDS.ANNOUNCEMENT:
          approvalModalContent = (
            <React.Fragment>
              <AnnouncementPreview
                item={itemToApprove}
                pageCount={productsByProductId.get(itemToApprove.get("productId")).get("pages")}
                onChangePage={(hasSeenAllPages, pageNumber) =>
                  this.handleChangeProductApprovalPage(hasSeenAllPages, pageNumber)
                }
              />
            </React.Fragment>
          );
          approvalModalTitle = "Approval";
          break;
        default:
          break;
      }
    }

    const approvalModal = (
      <Modal
        key="approval-modal"
        isOpen={this.state.isApprovalModalVisible}
        onClose={this.closeApprovalModal}
        title={approvalModalTitle}
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={this.closeApprovalModal}
          />
        }
      >
        {this.props.unapprovedItems.size > 0 && [
          <MainContent scrollable={false} centeredVertically padded key="main-preview">
            <p
              className="text-center"
              style={{ visibility: this.props.unapprovedItems.size === 1 ? "hidden" : "visible" }}
            >
              <span className="text-primary">{this.props.unapprovedItems.size}</span> items left to
              approve
            </p>
            {approvalModalContent}
          </MainContent>,
          <Footer padded key="footer">
            <Row type="flex" justify="space-between" gutter={8}>
              <Col span={allowApproveAll ? 8 : 12}>
                <AntButton
                  data-gtm-element="Approval - Delete"
                  type="default"
                  block
                  size="large"
                  style={{ backgroundColor: "#333333", borderColor: "#333333", color: "white" }}
                  onClick={() => this.handleDeleteItem(this.props.unapprovedItems.first())}
                >
                  Delete
                </AntButton>
              </Col>
              <Col span={allowApproveAll ? 8 : 12}>
                <AntButton
                  data-gtm-element="Approval - Edit"
                  type="default"
                  block
                  size="large"
                  onClick={() => this.handleEditItem(this.props.unapprovedItems.first())}
                >
                  Edit
                </AntButton>
              </Col>
              {allowApproveAll && (
                <Col span={8}>
                  <AntButton
                    data-gtm-element="Approval - Approve"
                    type="primary"
                    block
                    size="large"
                    style={{ backgroundColor: "#4b5566", borderColor: "#4b5566" }}
                    disabled={!this.state.approvalMetaData.canCompleteApproval}
                    onClick={() =>
                      this.handleApproveItem(this.props.unapprovedItems.first().get("id"))
                    }
                  >
                    Approve
                  </AntButton>
                </Col>
              )}
            </Row>
            <Row type="flex" justify="space-around" style={{ marginTop: "5px" }}>
              <Col span={allowApproveAll ? 24 : 24}>
                <AntButton
                  data-gtm-element={
                    allowApproveAll ? "Approval - Approve All" : "Approval - Approve"
                  }
                  type="primary"
                  block
                  size="large"
                  disabled={!this.state.approvalMetaData.canCompleteApproval}
                  onClick={() =>
                    allowApproveAll
                      ? this.handleApproveAllItems()
                      : this.handleApproveItem(this.props.unapprovedItems.first().get("id"))
                  }
                >
                  {allowApproveAll ? "Approve All" : "Approve"}
                </AntButton>
              </Col>
            </Row>
          </Footer>,
        ]}
      </Modal>
    );

    const promotionCodeText = (
      <table>
        <tbody>
          <tr>
            <td>
              <p onClick={this.handleShowPromoCode} className="promotion-code-text">
                Tap to add a promo code
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const promotionCodeInput = (
      <form
        className="form promotion-code-input animated fadeIn"
        onSubmit={this.handleSubmitPromoCodeForm}
      >
        <div className="form-group">
          <p onClick={this.handleHidePromoCode} className="promotion-code-dismiss-text">
            No promo code
          </p>
          <label htmlFor="promocode" className="control-label">
            Promotion code
          </label>
          <div className="promotion-code-input__input-wrapper">
            <input
              type="text"
              value={this.state.promoCode}
              className="form-control"
              id="promocode"
              ref={input => (this.promoCodeInput = input)}
              onChange={this.handleChangePromotionCodeInput}
              placeholder="Enter your code..."
            />
            <Button
              label="Apply"
              priority={this.state.isLookingUpPromoCode ? "primary" : "secondary"}
              type="submit"
              theme="success"
              disabled={!this.state.promoCode}
              loadingLabel="Looking up code..."
              loading={this.state.isLookingUpPromoCode}
            />
          </div>
        </div>
      </form>
    );

    const promotionCodeInfo = this.props.orderSummary.getIn(["promotionInfo", "data"]) && (
      <table>
        <tbody>
          <tr>
            <td style={{ width: "20px" }}>
              <Button icon="clear" priority="tertiary" onClick={this.handleClearPromoCode} />
            </td>
            <td style={{ fontSize: "12px" }}>
              {this.props.orderSummary.getIn(["promotionInfo", "data", "description"])}
              &nbsp; (
              <span>{this.props.orderSummary.getIn(["promotionInfo", "data", "code"])}</span>)
            </td>
            <td className="text-right">
              <span style={{ fontSize: "12px", marginRight: "17px" }}>
                <Currency
                  amount={
                    -this.props.orderSummary.getIn(["promotionInfo", "totalDiscountForOrder"])
                  }
                />
              </span>
            </td>
          </tr>
          {this.props.orderSummary.getIn(["promotionInfo", "invalid"]) && (
            <tr>
              <td colspan="3">
                {this.props.orderSummary.getIn(["promotionInfo", "invalidReason"]) ===
                  "minimum_spend" && (
                  <span className="promo-invalid">
                    Minimum spend not met (
                    <Currency
                      amount={this.props.orderSummary.getIn(["promotionInfo", "minimumSpend"])}
                    />
                    )
                  </span>
                )}
                {this.props.orderSummary.getIn(["promotionInfo", "invalidReason"]) ===
                  "no_products" && (
                  <span className="promo-invalid">No valid products for this promotion</span>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
    // eslint-disable-next-line
    const debugPricingTable = (
      <table>
        <tbody>
          <tr>
            <td className="text-right">
              <code>subTotal</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("subTotal")} />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>totalShippingCost</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("totalShippingCost")} />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>discount</code>
            </td>
            <td>
              <code>
                <Currency
                  amount={this.props.orderSummary.getIn(["promotionInfo", "totalDiscountForOrder"])}
                />
              </code>
            </td>
          </tr>
          <tr>
            <td className="text-right">
              <code>total</code>
            </td>
            <td>
              <code>
                <Currency amount={this.props.orderSummary.get("total")} />
              </code>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const allPhotoPrintItemIds = this.props.orderSummary
      .get("items")
      .filter(item => item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT)
      .map(item => item.get("id"));

    const anyPrints = allPhotoPrintItemIds.size > 0;
    // const photoPrintItemWithRealPostageScheme = this.props.orderSummary
    //   .get("items")
    //   .find(item => item.getIn(["postageScheme", "cost"]));

    // const photoPrintItemAddress = photoPrintItemWithRealPostageScheme
    //   ? photoPrintItemWithRealPostageScheme.get("address") ||
    //     photoPrintItemWithRealPostageScheme.get("addressBookEntry")
    //   : null;

    // const payButtonLabel = areAnyItemsRenderFailed
    //   ? "Try again"
    //   : this.props.canMakeStripePayment
    //   ? "Other Payment Options"
    //   : (
    //       this.props.isSignedIn ? "Pay Now" : "Checkout"
    //     );

    // const payButton = (
    //   <Button
    //     block
    //     disabled={areAnyItemsRendering}
    //     loadingProgress={renderProgress}
    //     label={payButtonLabel}
    //     loadingLabel={areAnyItemsRendering ? renderingLabel : "Creating Order..."}
    //     loading={areAnyItemsRendering || this.props.isCreatingOrder}
    //     dataGtmElement="Pay Now Button"
    //     theme="dark-blue"
    //     onClick={
    //       areAnyItemsRenderFailed
    //         ? this.retryRenders
    //         : this.props.isSignedIn
    //         ? () => this.createOrderAndShowPaymentOptions(false)
    //         : () => this.createOrderAndShowPaymentOptions(true)
    //     }
    //   />
    // );

    const checkoutButton = (
      <Button
        block
        disabled={areAnyItemsRendering}
        loadingProgress={renderProgress}
        loadingLabel={"Creating Order..."}
        label={this.props.hasCompletedCheckoutSteps ? "Pay Now" : "Checkout"}
        theme={picCollage ? "pc" : "dark-blue"}
        priority={picCollage ? "secondary" : "primary"}
        dataGtmElement="Checkout Button"
        loading={this.props.isCreatingOrder}
        onClick={
          this.props.hasCompletedCheckoutSteps
            ? areAnyItemsRenderFailed
              ? this.retryRenders
              : this.props.isSignedIn
              ? () => this.createOrderAndShowPaymentOptions(false)
              : () => this.createOrderAndShowPaymentOptions(true)
            : this.gotToshippingDetails
        }
      />
    );

    let keepShoppingButton = (
      <Button
        block
        label={anyPrints ? "Continue Shopping" : "Continue Shopping"}
        theme="dark-blue"
        priority="secondary"
        onClick={() => (anyPrints ? this.goToPrints() : this.goToShop())}
        dataGtmElement="Continue Shopping Button"
      />
    );

    if(picCollage){
      keepShoppingButton = null;
    }

    let termsText = (
      <div style={{ padding: '5px 0px'}}>
        <p style={{fontSize: '10px', lineHeight: '20px', color: 'black'}}>
          By continuing, you agree to our <a style={{color: '#4b5566', fontWeight: '400', textDecoration: 'underline'}} href="https://www.postsnap.com/terms-and-conditions" target="_blank"><strong>terms and conditions</strong></a> including <a style={{color: '#4b5566', fontWeight: '400', textDecoration: 'underline'}} href="https://www.postsnap.com/terms-and-conditions#returns" target="_blank"><strong>returns policy</strong></a> and have also read, and agree to our <a style={{color: '#4b5566', fontWeight: '400', textDecoration: 'underline'}} href="https://www.postsnap.com/privacy" target="_blank"><strong>privacy policy</strong></a>. Your order contains personalised products and so we cannot refund you once your personalised order is printed.
        </p>
      </div>
    );
   
    // const printsShippingPanelStyle = {
    //   background: "rgb(255, 255, 255)",
    //   borderRadius: 4,
    //   marginBottom: 24,
    //   border: "1px solid #ebebeb",
    //   overflow: "hidden",
    //   boxShadow: "3px 3px 6px 2px #cccccc21",
    // };

    // const printsShippingCost = photoPrintItemWithRealPostageScheme && (
    //   <div style={{fontSize: '14px', marginLeft: '-3px'}}>
    //     <span style={{fontSize: '14px'}}>Prints Shipping: </span><br/>
    //     <Currency
    //       amount={photoPrintItemWithRealPostageScheme.getIn([
    //         "postageScheme",
    //         "cost",
    //       ])}
    //     />
    //     <br/>
    //     <span style={{color: "#636363", fontSize: '12px'}}>Printed & mailed on the same or next working day. </span><br/><span style={{color: "#ff5a5f", fontSize: '12px'}}>Sent in high-quality, sturdy packaging.</span>
    //   </div>
    // );

    const anyItemsForCrossSell = this.props.orderSummary.get("items").filter(item => {
      return (
        item.get("isRendering") === false &&
        item.get("preview_s3_key") != null &&
        item.get("productTypeId") !== PRODUCT_TYPE_IDS.GREETING_CARD
      );
    });
    // const noMagazines = this.props.orderSummary
    //   .get("items")
    //   .every(item => item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_MAGAZINE);
    const itemForCrossSell = anyItemsForCrossSell.first();
    const shouldShouldShowCrossSellModal = false; //((this.state.isCrossSellModalVisible && !this.state.isItemDuplicationAlertVisible && this.props.hasSeenCrossSellModal === false && noMagazines) && itemForCrossSell);

    // const singleShipmentPrintsBasket = this.props.orderSummary.get("items").every(item => {
    //   return (
    //     item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT
    //   );
    // });

    const singleShipmentPrintsBasket = true;

    const canShowBagTotal = this.props.orderSummary.get("items").every(item => {
      return item.get("postageScheme");
    });

    let hasShownPackForProductIds = [];
    let packItems = new List();

    return [
      <AntModal
        key="basket-modal"
        visible={Boolean(this.state.modalVisible)}
        onOk={this.state.modal && this.state.modal.onOk}
        onCancel={this.state.modal && this.state.modal.onCancel}
        {...(this.state.modal || {})}
      >
        {this.state.modal && this.state.modal.content && <p>{this.state.modal.content}</p>}
      </AntModal>,
      <SweetAlert
        isOpen={Boolean(this.state.alert)}
        {...(this.state.alert || {})}
        key="basket-alert"
      />,
      <AntModal
        key="cross-sell-modal"
        visible={shouldShouldShowCrossSellModal} //&& this.props.hasSeenCrossSellModal === false
        title="Make this into a magazine?"
        onOk={this.handleCrossSellOk}
        onCancel={this.handleCrossSellCancel}
        centered
        footer={[
          <AntButton key="back" onClick={this.handleCrossSellCancel}>
            No thanks
          </AntButton>,
          <AntButton key="submit" type="primary" onClick={this.handleCrossSellOk}>
            Make Now
          </AntButton>,
        ]}
      >
        <div className="upsell--magazine-container">
          <p className="upsell--intro-text">
            This would look great in our of our glossy photo magazines:
          </p>
          <div
            className="upsell--magazine-cover"
            style={{
              backgroundImage: `url(${
                itemForCrossSell
                  ? generateS3AssetUrlFromKey(itemForCrossSell.get("preview_s3_key"))
                  : ""
              })`,
            }}
          >
            <div className="upsell--magazine-band">
              <p>
                Family Memories
                <br />
                2019
              </p>
            </div>
            {/* <img src={`${process.env.PUBLIC_URL}/images/pagecurl.png`}/> */}
            <div
              className="pagecurl-overlay"
              style={{ backgroundImage: `${process.env.PUBLIC_URL}/images/pagecurl.png` }}
            ></div>
            {/* <div className="pagecurl"></div> */}
          </div>
          <p className="upsell--footer-text">
            Start making today and get 10% off with code:
            <br />
            <br />
            <span className="upsell--promo-code">PHOTOMAG10</span>
          </p>
        </div>
      </AntModal>,
      <AntModal
        key="duplication-alert-modal"
        visible={this.state.isItemDuplicationAlertVisible}
        onOk={this.handleDuplicationAlertGoToBasket}
        onCancel={this.handleDuplicationAlertDuplicate}
        cancelText="Yes"
        okText="No"
        title="Send to someone else?"
      >
        <p>{`Would you like to edit & send ${this.state.productNameForDuplicationItem} to someone else?`}</p>
      </AntModal>,
      // <SweetAlert
      //   key="duplication-alert"
      //   isOpen={this.state.isItemDuplicationAlertVisible}
      //   title="Send to someone else?"
      //   text={`Would you like to edit & send ${this.state.productNameForDuplicationItem} to someone else?`}
      //   confirmButtonText="No"
      //   cancelButtonText="Yes"
      //   onConfirm={this.handleDuplicationAlertGoToBasket}
      //   onCancel={this.handleDuplicationAlertDuplicate}
      //   animation={false}
      //   customClass="animated fadeIn"
      // />,
      approvalModal,
      paymentModal,
      <Header
        key="top-header"
        theme="grey"
        title={this.props.hasCompletedCheckoutSteps ? "Checkout" : "Basket"}
        rightAction={
          <div style={{ display: "flex" }}>
            <AccountButtonContainer />
            <BasketButtonContainer />
          </div>
        }
        leftAction={<ShopButtonContainer />}
        onClickTitleOrImage={this.goToShop}
      />,
      <MainContent key="content" padded>
        {/* {checkoutSteps} */}
        {/* {this.props.guestDetails && this.props.guestDetails.get('email') && (
          <div className="basket__summary-container">
            <span className="basket__block-title">Contact Details</span>
            <div className="basket__summary-block">
              <div className="basket__summary-block-left">
                <p className="basket__summary-text">Email: {this.props.guestDetails.get('email')}</p>
                <p className="basket__summary-text">
                {!this.props.guestDetails.get('phone') && (
                  <p
                    style={{
                      fontSize: '10px',
                      background: '#ffc107',
                      padding: '4px',
                      margin: '10px 0px',
                      borderRadius: '2px',
                      color: '#000'
                    }}
                  >
                    We strongly advise you add a phone number so we can contact you about important updates with your order.</p>
                )}
                {this.props.guestDetails.get('phone') && (
                  <p className="basket__summary-text">
                    Phone: {this.props.guestDetails.get('phone')}
                  </p>
                )}
                </p>
              </div>
              <div className="basket__summary-block-right" onClick={this.editGuestDetails}>
                <div className="basket__summary-edit-btn">
                  <EditOutlined /> Edit
                </div>
              </div>
            </div>
          </div>
        )} */}

        {this.props.checkoutContactDetails && this.props.checkoutContactDetails.get("email") && (
          <div className="basket__summary-container">
            <span className="basket__block-title">Contact Details</span>
            <div className="basket__summary-block">
              <div className="basket__summary-block-left">
                <p className="basket__summary-text">
                  Email: {this.props.checkoutContactDetails.get("email")}
                </p>
                <p className="basket__summary-text">
                  {!this.props.checkoutContactDetails.get("mobile") ? (
                    <span className="basket__summary-text" onClick={this.editContactDetails}>
                      Mobile:{" "}
                      <span
                        style={{
                          borderBottom: "1px dashed red",
                          background: "#ff00000d",
                          fontSize: "11px",
                        }}
                      >
                        Not provided
                      </span>
                    </span>
                  ) : (
                    <span className="basket__summary-text">
                      Mobile: {this.props.checkoutContactDetails.get("mobile")}
                    </span>
                  )}
                </p>
              </div>
              <div className="basket__summary-block-right" onClick={this.editContactDetails}>
                <div className="basket__summary-edit-btn">
                  <EditOutlined /> Edit
                </div>
              </div>
            </div>
          </div>
        )}
        {singleShipmentPrintsBasket && (
          <>
            {/* <SingleShipmentOrderSummary
              summary={this.props.orderSummary}
              onClickAddress={(item) => this.props.onClickCheckoutAddress(item)}
              onClickItemCount={this.toggleBasketContent}
              currency={this.props.currency}
              onAddMore={this.goToPrintsInApp}
              onEditBasket={this.toggleBasketContent}
              onChangePostageSchemeForItem={(item, postageSchemeId) => this.props.onChangePostageSchemeForItem(item, postageSchemeId)}
            /> */}
            <SingleShipmentOrderSummaryV2
              summary={this.props.orderSummary}
              onClickAddress={item => this.props.onClickCheckoutAddress(item)}
              onClickItemCount={this.toggleBasketContent}
              currency={this.props.currency}
              onAddMore={this.goToPrintsInApp}
              onEditBasket={this.toggleBasketContent}
              checkoutShippingOption={this.props.checkoutShippingOption}
              onChangePostageSchemeForItem={(item, postageSchemeId) =>
                this.props.onChangePostageSchemeForItem(item, postageSchemeId)
              }
            />
            <Button
              onClick={this.toggleBasketContent}
              label={this.state.showBasketContent ? "Hide Basket Items" : "Show Basket Items"}
              block
              theme="dark-blue"
              priority="secondary"
              size="small"
            />
            <br />
          </>
        )}
        <Header
          key="header"
          style={{
            display: singleShipmentPrintsBasket && !this.state.showBasketContent ? "none" : "flex",
          }}
          theme="mini"
          leftAction={
            this.props.orderSummary.get("items").size > 0 && (
              <>
                <Button
                  label={this.state.isDeletionModeActive ? "Done" : "Delete"}
                  priority="tertiary"
                  theme="muted"
                  onClick={this.toggleDeletionMode}
                />
                {process.env.NODE_ENV === "development" && (
                  <Button
                    label="Reset App"
                    size="small"
                    priority="tertiary"
                    theme="dark-blue"
                    onClick={this.props.resetAll}
                  />
                )}
              </>
            )
          }
          rightAction={[
            this.state.isDeletionModeActive && (
              <Button
                label="Delete All"
                priority="secondary"
                size="small"
                onClick={this.handleShowDeleteAllAlert}
              />
            ),
            //  (
            //   !areAnyItemsRendering && (
            //     <Button
            //       label={this.state.isEditModeActive ? "Done Editing" : "Edit"}
            //       priority={(singleShipmentPrintsBasket && this.state.showBasketContent) ? "primary" : "tertiary"}
            //       size={(singleShipmentPrintsBasket && this.state.showBasketContent) ? "small" : "default"}
            //       theme={this.state.showBasketContent ? "dark-blue" : "muted" }
            //       onClick={this.toggleEditMode}
            //     />
            //   )
            // ),
            areAnyItemsRendering && (
              <div style={{ paddingRight: "10px" }}>
                <Progress
                  strokeColor={{
                    from: "#63C0AC",
                    to: "#64C2AB",
                  }}
                  percent={progressPercent}
                  width={40}
                />
              </div>
            ),
          ]}
        />
        {(!singleShipmentPrintsBasket || this.state.showBasketContent) && (
          <table style={{ marginTop: "0px" }} className="bag-items" ref={this.basketItems}>
            <TransitionGroup component="tbody">
              {this.props.orderSummary
                .get("items")
                .groupBy(item => item.get("productId"))
                .map((items, productId) => {
                  let basketItems = items;
                  const isPack = hasMinimumQuantityForProduct(productId);
                  const packQty = minimumQuantityForProduct(productId);
                  const packNum =
                    basketItems.reduce((total, item) => total + item.get("quantity"), 0) / packQty;
                  const isEditingPackForProduct = this.state.isEditingPackForProduct === productId;

                  if (isPack && !isEditingPackForProduct) {
                    console.log("Is a pack item, only show one");
                    if (hasShownPackForProductIds.includes(productId)) {
                      // skip
                      return false;
                    } else {
                      basketItems = new List([items.first()]);
                      if (items.size >= 3) {
                        packItems = items.slice(0, 3);
                      } else {
                        packItems = new List([items.first(), items.first(), items.first()]);
                      }
                      hasShownPackForProductIds.push(productId);
                    }
                  }

                  console.log("isEditingPackForProduct", isEditingPackForProduct);

                  return basketItems.map((item, index) => {
                    const editModeEnabledForItem =
                      item.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT &&
                      ((this.props.editModeEnabledFor &&
                        this.props.editModeEnabledFor === item.get("id")) ||
                        this.state.editModeActiveForItem === item.get("id"));

                    const isDeletePopupEnabled =
                      this.state.deletePopupEnabledForItem !== null &&
                      this.state.deletePopupEnabledForItem === item.get("id");

                    return (
                      <CSSTransition
                        key={`trans-${index}`}
                        mountOnEnter={true}
                        unmountOnExit={
                          this.state.isDeletionModeActive &&
                          this.props.orderSummary.get("items").size > 1
                        }
                        enter={true}
                        appear={true}
                        exit={
                          this.state.isDeletionModeActive &&
                          this.props.orderSummary.get("items").size > 1
                        }
                        classNames={{
                          exit: "animated fadeOutRight",
                          enter: "animated fadeInLeft",
                          appear: "animated fadeIn",
                        }}
                        timeout={500}
                      >
                        <React.Fragment key={item.get("id")}>
                          <React.Fragment>
                            {editModeEnabledForItem && <tr className="spacer"></tr>}
                          </React.Fragment>
                          <BasketItem
                            key={item.get("id")}
                            item={item}
                            editModeEnabled={editModeEnabledForItem}
                            showAddress={
                              !singleShippingAddressProductTypes.includes(item.get("productTypeId"))
                            }
                            showShipping={
                              item.get("productTypeId") !== PRODUCT_TYPE_IDS.PHOTO_PRINT
                            }
                            onClickPreview={() => this.props.onClickPreview(item)}
                            onClickDeleteButton={() => this.handleDeleteItem(item)}
                            onClickAddress={() => this.props.onClickItemAddress(item)}
                            onClickEditModeForItem={() => this.handleOnClickEditModeForItem(item)}
                            onClickEditModeActiveForItem={(item, openPreview) => {
                              isPack && !isEditingPackForProduct
                                ? this.handleClickPackItem(item)
                                : this.handleClickEditModeActiveForItem(item, openPreview);
                            }}
                            handleOpenBookPreview={item => {
                              this.handleOpenBookPreview(item);
                            }}
                            onClickIncreaseQty={() => this.handleIncreaseQuantityForItem(item)}
                            onClickDecreaseQty={() => this.handleDecreaseQuantityForItem(item)}
                            onClickCancelDeleteButton={() => this.handleDismissDeletePopupFor(item)}
                            onClickConfirmDeleteButton={() =>
                              this.handleConfirmDeletePopupFor(item)
                            }
                            canDelete={this.state.isDeletionModeActive}
                            deletePopupEnabled={isDeletePopupEnabled}
                            onChangeProductOption={option =>
                              this.props.onChangeProductOptionForItem(item, option)
                            }
                            editModeActiveForItem={
                              this.state.editModeActiveForItem === item.get("id")
                            }
                            isEditModeActiveGlobally={this.state.isEditModeActive}
                            isPack={isPack && !isEditingPackForProduct}
                            packItems={packItems.toJS()}
                            packNum={packNum}
                            onClickPackForItem={() => this.handleClickPackItem(item)}
                            onClickHidePackForItem={() => this.handleClickHidePackItem(item)}
                            onClickShowPackForItem={() => this.handleClickPackItem(item)}
                            isEditingPackForProduct={isEditingPackForProduct}
                            showPackHideForItem={this.state.showPackHideForItem}
                            isPackExpanded={isEditingPackForProduct}
                          />
                          <React.Fragment>
                            {editModeEnabledForItem && <tr className="spacer"></tr>}
                          </React.Fragment>
                        </React.Fragment>
                      </CSSTransition>
                    );
                  });
                })
                .toList()}
            </TransitionGroup>
          </table>
        )}
        {!singleShipmentPrintsBasket && (
          <OrderSummary
            summary={this.props.orderSummary}
            onClickAddress={item => this.props.onClickItemAddress(item)}
            currency={this.props.currency}
            onChangePostageSchemeForItem={(item, postageSchemeId) =>
              this.props.onChangePostageSchemeForItem(item, postageSchemeId)
            }
          />
        )}
      </MainContent>,
      <FullScreenLoader
        key="loader"
        message={
          this.props.isCreatingGuestOrder
            ? "Checking out. Please wait..."
            : "Making payment, please wait"
        }
        isVisible={this.props.isCreatingGuestOrder || this.props.isPayingForOrder}
      />,
      <Footer padded key="promo-footer" topShadow style={{ zIndex: 1 }}>
        {this.state.isAddingPromoCode
          ? promotionCodeInfo || promotionCodeInput
          : promotionCodeInfo || null}
      </Footer>,
      <Footer padded key="total">
        <div className="text-center">
          {canShowBagTotal && (
            <div
              style={{
                fontWeight: "bold",
                textAlign: "right",
                fontSize: "12px",
                marginRight: "17px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{!this.state.isAddingPromoCode ? promotionCodeText : ""}</span>
              <span>
                TOTAL: <Currency amount={this.props.orderSummary.get("total")} />
              </span>
              {/* <br/>
              Upgraded delivery: {this.props.orderSummary.get('upgradedDelivery') ? 'YES' : 'NO'}
              <br/>
              upgradedDeliverySchemeId: {this.props.orderSummary.get('upgradedDeliverySchemeId')} */}
            </div>
          )}
          {!canShowBagTotal && (
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              Total will be recalculated after entering shipping details
            </p>
          )}
        </div>
      </Footer>,
      <Footer padded key="footer">
        {/* {process.env.NODE_ENV === "development" && (
          <Button
            block
            theme="aqua"
            label="Unapprove all"
            onClick={this.props.unApproveAllItems}
          />
        )} */}
        {this.props.unapprovedItems.size > 0 && (
          <React.Fragment>
            {keepShoppingButton}
            {termsText}
            {!checkoutHidden && (
              <Button
                block
                label={areAnyItemsRenderFailed ? "Try again" : "Approve Changes"}
                loadingLabel={renderingLabel}
                loading={areAnyItemsRendering}
                loadingProgress={renderProgress}
                disabled={areAnyItemsRendering}
                dataGtmElement="Checkout Button"
                theme="dark-blue"
                onClick={areAnyItemsRenderFailed ? this.retryRenders : this.openApprovalModal}
              />
            )}
          </React.Fragment>
        )}
        {this.props.unapprovedItems.size === 0 && (
          <React.Fragment>
            {/* {(this.props.canMakeStripePayment && !areAnyItemsRendering && !checkoutHidden) && (
              <StripePaymentRequestButton
                onClick={this.handleClickStripePaymentRequestButton}
                paymentRequest={this.props.paymentRequest}
                disabled={areAnyItemsRendering}
                preventPropagation={anyUnapprovedItem}
              />
            )}
            {this.props.canMakeStripePayment && (
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingRight: "0.25rem" }}>{keepShoppingButton}</div>
                </Grid.Column>
                {!checkoutHidden && (
                  <Grid.Column>
                    <div style={{ paddingLeft: "0.25rem" }}>{payButton}</div>
                  </Grid.Column>
                )}
              </Grid.Row>
            )} */}
            {this.props.canMakeStripePayment && (
              <React.Fragment>
                {keepShoppingButton}
                {termsText}
                {!checkoutHidden && (
                  checkoutButton
                )}
              </React.Fragment>
            )}
            {!this.props.canMakeStripePayment && (
              <React.Fragment>
                {keepShoppingButton}
                {termsText}
                {!checkoutHidden && (
                  checkoutButton
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {areAnyMinimQuantitiesNotMet && (
          <React.Fragment>
            <div
              style={{
                height: "44px",
                background: "#ff9800",
                textAlign: "center",
                padding: "10px",
              }}
            >
              <p style={{ color: "white", fontSize: "14px", fontWeight: "500" }}>
                Minimum pack quantities not met
              </p>
            </div>
          </React.Fragment>
        )}
        {invalidProductShipping && (
          <React.Fragment>
            <div
              style={{
                height: "auto",
                background: "#ff5a5f",
                textAlign: "center",
                padding: "10px",
              }}
            >
              <p style={{ color: "white", fontSize: "14px", fontWeight: "500" }}>
                Unfortunately we're currently unable to ship prints to your shipping address. We
                only deliver to UK and USA addresses at this time. Apologies for any inconvenience.
              </p>
            </div>
          </React.Fragment>
        )}
      </Footer>,
    ];
  }
}

export default Basket;
