const _ = require("lodash");

//const GUTTER_WIDTH_IN_MM = 3
// const collageProducts = [
//   {
//     appKey: '4×4_COLLAGE',
//     width: 101.6,
//     height: 101.6,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '6×6_COLLAGE',
//     width: 152.4,
//     height: 152.4,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '8×8_COLLAGE',
//     width: 203.2,
//     height: 203.2,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '12×12_COLLAGE',
//     width: 304.8,
//     height: 304.8,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '6×4_COLLAGE',
//     width: 152.4,
//     height: 101.6,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '7×5_COLLAGE',
//     width: 177.8,
//     height: 127.0,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '8×6_COLLAGE',
//     width: 203.2,
//     height: 152.4,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '12×8_COLLAGE',
//     width: 304.8,
//     height: 203.2,
//     gutter: GUTTER_WIDTH_IN_MM,
//   },
//   {
//     appKey: '20×20_COLLAGE',
//     width: 304.8,
//     height: 203.2,
//     gutter: GUTTER_WIDTH_IN_MM,
//   }
// ]

const roundToDecimals = (number, decimals = 5) => {
  return +((number).toFixed(decimals));
};

const convertLayoutTemplateToArrayFormat = (layoutTemplate) => {
  return layoutTemplate.trim().split("\n").map(line => line.trim().split(" "));
};

const validateLayout = (layout) => {
  const doAllRowsHaveEqualElements = layout.every(row => row.length === layout[0].length);

  if (!doAllRowsHaveEqualElements) {
    throw new Error("This layout template does not have an equal amount of elements on every row.");
  }
};

const getGridDimensionsForLayout = (layout) => {
  return {
    rows: layout.length,
    columns: layout[0].length,
  };
};

const generateLayoutInPixelDimensions = ({ layout, product, gutter }) => {
  validateLayout(layout);

  const { rows, columns } = getGridDimensionsForLayout(layout);
  const gridCellWidth = (product.width - (columns - 1) * gutter) / columns;
  const gridCellHeight = (product.height - (rows - 1) * gutter) / rows;

  const transformedLayout = layout
    .map((row, rowIndex) => {
        const gridCellCountById = _.countBy(row, cell => cell);

        return Object.entries(gridCellCountById).reduce(
          (result, [cellId, count], index) => {
            const prevCell = result[index - 1];

            return [
              ...result,
              {
                id: cellId,
                width: (gridCellWidth * count) + (gutter * (count - 1)),
                height: gridCellHeight,
                xOffset: !prevCell ? 0 : prevCell.xOffset + prevCell.width + gutter,
                yOffset: (gridCellHeight * rowIndex) + (gutter * rowIndex),
              }
            ];
          },
          []
        );
      },
    )
    .reduce((result, row, rowIndex) => {
    if (rowIndex === 0) {
      return [
        ...result,
        row,
      ];
    }

    const prevRow = result[rowIndex - 1];

    return [
      ...result,
      row
        .map(cell => {
          const prevRowMatchingCellIndex = (prevRow || []).findIndex(prevRowCell => prevRowCell.id === cell.id);
          const prevRowMatchingCell = (prevRow || [])[prevRowMatchingCellIndex];

          if (prevRowMatchingCell) {
            cell.yOffset = prevRowMatchingCell.yOffset;
            cell.height = cell.height + prevRowMatchingCell.height + gutter;
            // Mark previous cell as 'to delete'
            prevRowMatchingCell.delete = true;
          }
          // Special case to remove cells with "."
          if (cell.id === "."){
            cell.delete = true;
          }

          return cell;
        })
    ];
  }, []);

  return _.flatten(transformedLayout).filter(region => !region.delete);
};

const generateLayout = ({ layout, gutter, product, id }) => {
  const convertedLayout = convertLayoutTemplateToArrayFormat(layout);
  validateLayout(convertedLayout);
  const pixelLayout = generateLayoutInPixelDimensions({
    layout: convertedLayout,
    gutter,
    product,
  });

  return pixelLayout.map(region => ({
    width: roundToDecimals(region.width / product.width),
    height: roundToDecimals(region.height / product.height),
    xOffset: roundToDecimals(region.xOffset / product.width),
    yOffset: roundToDecimals(region.yOffset / product.height),
    id: id,
  }))
};

export default generateLayout;
