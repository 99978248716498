/* global $ */

import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import classNames from "classnames";
import sortBy from "lodash/sortBy";

import Header from "../Header/Header";
import Button from "../Button/Button";

import "./Modal.scss";

ReactModal.setAppElement("#root");

const ANIMATIONS_BY_ENTRANCE_DIRECTION = {
  up: {
    enter: "fadeInUp",
    exit: "fadeOutDown",
  },
};
const ANIMATION_DURATION_IN_MS = 400;

class Modal extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    contentLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isOpen: PropTypes.bool,
    animated: PropTypes.bool,
    minimal: PropTypes.bool,
    disableClose: PropTypes.bool,
    padded: PropTypes.bool,
    onClose: PropTypes.func,
    entranceDirection: PropTypes.oneOf(["up"]),
    hasHeader: PropTypes.bool,
    paged: PropTypes.bool,
    fullWidth: PropTypes.bool,
    activePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    entranceDirection: "up",
    disableClose: false,
    padded: false,
    minimal: false,
    hasHeader: true,
    paged: false,
    animated: true,
    fullWidth: false,
  };

  constructor(props) {
    super(props);

    if (props.paged) {
      this.state = {
        childrenWithStyles: this.getChildrenWithStyles({
          children: props.children,
          activePage: props.activePage,
          animated: false,
        }),
      };
    }
  }

  getChildrenWithStyles = ({ children, activePage, animated }) => {
    const childrenSortedByDepth = sortBy([...children], "depth");
    const activeChildIndex = childrenSortedByDepth.findIndex(c => c.props.pageId === activePage);
    const childrenWithStyles = React.Children.map(children, (element, index) => {
      let transformValue = 0;

      if (index < activeChildIndex) {
        transformValue = -100;
      } else if (index > activeChildIndex) {
        transformValue = 100;
      }

      return React.cloneElement(element, {
        style: {
          transform: `translate3d(${transformValue}%, 0%, 0)`,
          WebkitTransform: `translate3d(${transformValue}%, 0%, 0)`,
          opacity: index === activeChildIndex ? 1 : 0,
        },
        animated,
      });
    });

    return childrenWithStyles;
  };

  componentDidUpdate(prevProps) {
    /**
     * We handle the children styles update in two phases:
     *
     * - Phase 1 is where we reposition all of the CURRENT children *instantly*, meaning the animation is turned off
     * - Phase 2 is where we actually make the transition with the new children and the animation
     */
    if (
      this.props.paged &&
      (prevProps.activePage !== this.props.activePage || prevProps.children !== this.props.children)
    ) {
      // Dirty fix to trigger a click on modal content before navigating to fix iOS 11.3 Safari issue
      if (typeof $ !== "undefined") {
        try{
          $(".modal__content").click();
        } catch (e){
          
        }
      }

      setTimeout(() => {
        // First turn OFF the animation and reposition the CURRENT children
        this.setState(
          {
            childrenWithStyles: this.getChildrenWithStyles({
              children: prevProps.children,
              activePage: prevProps.activePage,
              animated: false,
            }),
          },
          () => {
            // Then turn ON the animation and reposition the NEW children
            this.setState({
              childrenWithStyles: this.getChildrenWithStyles({
                children: this.props.children,
                activePage: this.props.activePage,
                animated: true,
              }),
            });
          }
        );
      }, 0);
    }
  }

  handleClose = e => {
    const closeBehavior = {
      closedWithEscKey: e.keyCode === 27,
      closedByClickingOverlay: [...e.target.classList].includes("modal-overlay"),
    };
    this.props.onClose(closeBehavior);
  };

  render() {
    const {
      children,
      className,
      title,
      contentLabel,
      leftAction,
      rightAction,
      isOpen,
      onClose,
      disableClose,
      padded,
      hasHeader,
      theme,
      fullWidth,
    } = this.props;

    const overlayBaseClasses = classNames("modal-overlay", className, {
      animated: this.props.animated,
      "modal-overlay--minimal": this.props.minimal,
    });

    const contentClasses = classNames("modal__content", {
      "modal__content--padded": padded,
      "modal__content--full-width": fullWidth,
    });

    let mainContent;

    if (!this.props.paged) {
      mainContent = <div className={contentClasses}>{children}</div>;
    } else {
      mainContent = <div className={contentClasses}>{this.state.childrenWithStyles}</div>;
    }

    return (
      <ReactModal
        isOpen={Boolean(isOpen)}
        onRequestClose={this.handleClose}
        contentLabel={title || contentLabel}
        closeTimeoutMS={ANIMATION_DURATION_IN_MS}
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={!disableClose}
        className={{
          base: "modal",
          afterOpen: "modal--after-open",
          beforeClose: "modal--before-close",
        }}
        overlayClassName={{
          base: overlayBaseClasses,
          afterOpen: `modal-overlay--after-open ${ANIMATIONS_BY_ENTRANCE_DIRECTION[this.props.entranceDirection].enter}`,
          beforeClose: `modal-overlay--before-close ${ANIMATIONS_BY_ENTRANCE_DIRECTION[this.props.entranceDirection].exit}`,
        }}
      >
        {hasHeader && (
          <div className="modal__header">
            <Header
              theme={theme ? theme : "default"}
              title={title || contentLabel}
              leftAction={
                leftAction || (
                  <Button
                    priority="tertiary"
                    theme="muted"
                    icon="clear"
                    onClick={onClose}
                    disabled={disableClose}
                    iconSize="small"
                  />
                )
              }
              rightAction={rightAction}
            />
          </div>
        )}
        {mainContent}
      </ReactModal>
    );
  }
}

export default Modal;
