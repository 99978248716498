import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions as basketActions } from "../../store/ducks/basket";

// import { PRIMARY_COLOR } from '../../constants/colors';

// import Block from '../../components/Block/Block';
// import Currency from '../../components/Formatters/Currency';
// import Date from '../../components/Formatters/Date';

import Button from "../../components/Button/Button";
import Form from "../../components/Form/Form";
import S3Image from "../../components/S3Image/S3Image";
import MainContent from "../../components/MainContent/MainContent";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import SweetAlert from "../../components/SweetAlert/SweetAlert";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SCHEMA = {
  showErrorList: false,
  schema: {
    type: "object",
    required: ["name", "message"],
    properties: {
      name: { type: "string", title: "Your name" },
      message: { type: "string", title: "Your message to sender" },
    },
  },
  uiSchema: {
    "ui:rootFieldId": "thanks",
    name: {
      "ui:placeholder": "Type your name here",
    },
    message: {
      "ui:widget": "textarea",
      "ui:options": { rows: 10 },
      "ui:placeholder": "Type your message here",
      classNames: "textarea",
    },
  },
};

class OrderByReference extends Component {
  static propTypes = {
    reference: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    sentModalOpen: false,
    thanksData: {},
    alert: null,
    isLoadingRequest: false,
    messageSent: false,
  };

  handleThanksFormChange = form => {
    this.setState({
      thanksData: form.formData,
    });
  };

  handleThanksFormSubmit = async form => {
    let payload = { thank: { ...form.formData, type: "EmailCommunication" } };
    this.setState({
      isLoadingRequest: true,
    });
    try {
      const result = await this.props.sendThanksForOrder(payload);
      if (!result.payload.data.success) {
        this.setState({
          alert: {
            type: "error",
            title: "Couldn't send message",
            text: "There was a problem sending your message",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
        });
      } else {
        this.setState({
          alert: {
            type: "success",
            title: "Message sent",
            text: "Your message will be sent shortly",
            showCancelButton: false,
            onConfirm: this.clearAlert,
          },
          messageSent: true,
        });
      }
    } catch (err) {
      console.warn("Error:", err);
    } finally {
      this.setState({
        isLoadingRequest: false,
      });
    }
  };

  renderDetail = props => {
    const order = props.orderByReference.get("data");
    const recipientCode = order.get("code");
    const previews = order.get('preview_pages')

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const isGreetingCard = order.getIn(['line_item', 'item_description']).includes('Greeting')

    let title = isGreetingCard ? `SAY THANK YOU FOR YOUR CARD` : `SAY THANK YOU FOR YOUR POSTCARD`
    let instruction = isGreetingCard ? 'Thank you for scanning your card. Please send a short message to the sender of your card to let them know you have received it and to thank them.' : 'Thank you for scanning your postcard. Please send a short message to the sender of your card to let them know you have received it and to thank them.'

    return [
      <FullScreenLoader
        key="thanks-loader"
        message="Sending message..."
        isVisible={this.state.isLoadingRequest}
      />,
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <MainContent key="main" scrollable padded>
        <h1 style={{fontSize: '20px'}}>{title}</h1>
        <p style={{ fontSize: '14px'}}>{instruction}</p>
        <div style={{margin: '10px 5px', padding:'0px 10px'}}>
          {previews && (
            <Slider {...sliderSettings}>
              {previews.map((previewKey, index) => (
                <div
                  key={index}
                  style={{maxWidth: '100px', margin : '20px auto'}}
                >
                    <S3Image
                    key={index}
                    storageKey={previewKey}
                    className="shadowed animated fadeIn scan-preview"
                  />
                </div>
                
              ))}
            </Slider>
          )}
        </div>
        
        {this.state.messageSent ? (
          <div
            className="animated flipOutX"
            style={{
              backgroundColor: "#29bf9d",
              padding: "10px",
              margin: "10px",
              textAlign: "center",
              animationDelay: "5s",
            }}
          >
            <p style={{ color: "white" }}> Your message has been sent</p>
          </div>
        ) : (
          <div>
            <br />
            {/* <p padded className="text-center">
              Send a message to {order.getIn(["line_item", "order", "customer", "first_name"])}
            </p> */}
            <Form
              {...SCHEMA}
              formData={{ ...this.state.thanksData, code: recipientCode }}
              onChange={this.handleThanksFormChange}
              onSubmit={this.handleThanksFormSubmit}
            >
              <Button
                block
                type="submit"
                label="Send message"
                loadingLabel="Sending message.."
                loading={this.state.isLoadingRequest}
              />
            </Form>
            <br/>
            {isGreetingCard ? (
              <>
                <p style={{fontSize: '11px'}}>*We will not share your name or message with anyone other than the sender of your card. Your message will be sent to the sender of your card by email. Your details will not be used for marketing purposes.</p>
                <hr/>
                <br/>
                <p style={{fontSize: '14px'}}>We're PostSnap the UK's leading online photo card retailer. Want to send a personalised photo card yourself?<br/><br/>Search and download PostSnap in the App Store or Google Play or just tap the link below to start making a card online.<br/><br/><a rel="noopener noreferrer" href="https://www.postsnap.com/personalised-cards" target="_blank">Send a card</a><br/><br/>New to PostSnap? Use code <strong>CARD50</strong> at checkout to get <strong>50% off**</strong> your first order of personalised photo cards!</p>
                <p style={{fontSize: '11px'}}>**New PostSnap customers only. Single use. Discount off Greeting Cards (excluding packs) mailed to UK addresses. Maximum of 5 Greeting Cards per first discounted order. Promo may be withdrawn at any time.</p>
                <img src={`${process.env.PUBLIC_URL}/images/trustpilot-5stars.png`} style={{width: '180px', display: 'block', margin: '0 auto'}} alt={"stars"}/>
              </>
            ) : (
              <>
                <p style={{fontSize: '11px'}}>*We will not share your name or message with anyone other than the sender of your postcard. Your message will be sent to the sender of your card by email. Your details will not be used for marketing purposes.</p>
                <hr/>
                <br/>
                <p style={{fontSize: '14px'}}>Want to send a personalised postcard yourself?<br/><br/>Search and download PostSnap in the App Store or Google Play or just tap the link below to start making a postcard online.<br/><br/><a rel="noopener noreferrer" href="https://www.postsnap.com/postcards" target="_blank">Send a postcard</a><br/><br/>New to PostSnap? Use code <strong>KIND50</strong> at checkout to get <strong>50% off**</strong> your first order of standard postcards!</p>
                <p style={{fontSize: '11px'}}>**New PostSnap customers only. Single use. Discount off standard postcards mailed to UK addresses. Maximum of 5 standard postcards per first discounted order. Promo may be withdrawn at any time.</p>
                <img src={`${process.env.PUBLIC_URL}/images/trustpilot-5stars.png`} style={{width: '180px', display: 'block', margin: '0 auto'}} alt={"stars"}/>
              </>
            )}
          </div>
        )}
      </MainContent>,
      <FullScreenLoader
        key="loader"
        message="Loading card"
        isVisible={this.props.orderByReference.get("loading")}
      />,
    ];
  };

  render() {
    if (this.props.reference) {
      return this.renderDetail(this.props);
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  sendThanksForOrder: thankData => dispatch(basketActions.sendThanksForOrder(thankData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderByReference);
