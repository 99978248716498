import React, { useEffect } from "react";

import loadFont from "../../lib/load-font";

import './PhotoPrintsTextBand.scss';

//const COVER_TEXT_FONT = "Open Sans";
// eslint-disable-next-line
const PhotoPrintsTextBand = ({ fontSize = 3, text, font, color }) => {
  useEffect(() => {
    loadFont(font);
    // eslint-disable-next-line
  }, []);


  return (
    <div className="photo-prints-text-band" style={{ fontFamily: font, fontSize, color }}>
      <div className="photo-prints-text-band__text">
        <div>{text}</div>
      </div>
    </div>
  );
};

export default PhotoPrintsTextBand;
