import { types as userTypes } from "../ducks/auth";
import { types as editorTypes } from "../ducks/editor";

import * as STORAGE_KEYS from "../../constants/storage-keys";

export default store => next => action => {
  try {
    // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
    // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
    // to avoid the entire page breaking, without having to do a check at each usage of Storage.
    if (typeof localStorage === "object") {
      // Safari will throw a fit if we try to use localStorage.setItem in private browsing mode.
      try {
        localStorage.setItem("localStorage", 1);
        localStorage.removeItem("localStorage");
      } catch (e) {
        Storage.prototype._setItem = Storage.prototype.setItem;
        Storage.prototype.setItem = function() {};
        alert(
          'Your web browser does not appear to support storing data and settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some data may not save or some features may not work properly for you. Try again using "Normal Browsing Mode" if you experience any problems.'
        );
      }
    }

    const result = next(action);

    const { type, payload } = action;

    switch (type) {
      case userTypes.SIGN_IN:
      case userTypes.SIGN_UP:
      case userTypes.CONVERT_GUEST_ACCOUNT:
      case userTypes.FETCH_USER_DATA_WITH_AUTH_TOKEN:
        if (payload && payload.data && payload.data.success) {
          localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, payload.data.data.auth_token);
          localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(payload.data.data.customer));
          localStorage.removeItem(STORAGE_KEYS.GUEST_USER);
          localStorage.setItem(STORAGE_KEYS.CHOSEN_GUEST_ROUTE, false);
        }
        break;
      case userTypes.SIGN_OUT:
        localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
        localStorage.removeItem(STORAGE_KEYS.USER);
        localStorage.removeItem(STORAGE_KEYS.BASKET);
        break;
      // no default
    }

    if (action.type === userTypes.SET_GUEST_CHECKOUT_DETAILS) {
      const guestDetailsToSave = store.getState().auth.get('guestCheckoutDetails');
      localStorage.setItem(STORAGE_KEYS.GUEST_USER, JSON.stringify(guestDetailsToSave));
    }

    if (action.type === userTypes.UNSET_GUEST_CHECKOUT_DETAILS) {
      localStorage.removeItem(STORAGE_KEYS.GUEST_USER);
    }

    if (action.type === userTypes.SET_GUEST_TOKEN) {
      const guestTokenToSave = store.getState().auth.get('guestToken');
      localStorage.setItem(STORAGE_KEYS.GUEST_TOKEN, guestTokenToSave);
    }

    if (action.type === userTypes.UNSET_GUEST_TOKEN || action.type === userTypes.UNSET_GUEST_CHECKOUT_DETAILS) {
      localStorage.removeItem(STORAGE_KEYS.GUEST_TOKEN);
    }

    if (action.type === userTypes.SET_CHOSEN_GUEST_ROUTE) {
      const chosenGuestRoute = store.getState().auth.get('hasChosenGuestRoute');
      localStorage.setItem(STORAGE_KEYS.CHOSEN_GUEST_ROUTE, chosenGuestRoute);
    }

    // Save basket items to localStorage
    if (action.type.includes("POSTSNAP/BASKET/")) {
      const basketStateToSave = store.getState().basket;
      basketStateToSave.update("items", items => items.map(item => item.set("rendering", false)));
      localStorage.setItem(STORAGE_KEYS.BASKET, JSON.stringify(basketStateToSave.toJS()));
    }

    //console.log("action", action.type);
    // Save WIP editor item to localStorage
    // We ignore the SET_TEXT_POSITION_FOR_TEXT_LAYER because GCs move this on load and that means it
    // saves the empty gc editor item as the WIP item and loses other products
    const shouldSave =
      action.type.includes("POSTSNAP/EDITOR/") && action.type !== "POSTSNAP/EDITOR/SET_EDITOR_ITEM" && action.type !== "POSTSNAP/EDITOR/SET_TEXT_POSITION_FOR_TEXT_LAYER";
    const shouldDelete = action.type === editorTypes.CLEAR_EDITOR_ITEM;
    if (shouldSave && !shouldDelete) {
      const state = store.getState();
      const editorSourcePage = state.router.location.pathname;
      const itemToSave = store
        .getState()
        .editor.get("item")
        .toJS();
      console.log("Saving WIP editor item")
      localStorage.setItem(
        STORAGE_KEYS.WIP_EDITOR_ITEM,
        JSON.stringify({ source: editorSourcePage, item: itemToSave })
      );
    }

    if (shouldDelete) {
      localStorage.removeItem(STORAGE_KEYS.WIP_EDITOR_ITEM);
    }

    return result;
  } catch (err) {
    console.error(err);
  }
};
