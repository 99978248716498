import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { Switch, Popover, Button as AntButton } from 'antd';
import {
  InfoOutlined,
  MinusOutlined,
  PlusOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CheckCircleFilled
} from '@ant-design/icons';

import Currency from "../Formatters/Currency";

import '@ant-design/compatible/assets/index.css';
import MainContent from "../MainContent/MainContent";
import Header from "../Header/Header";
import Button from "../Button/Button";
import './AnySizePrintPrebagContainer.scss';
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import * as LAYER_TYPES from "../../constants/layer-types";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { console } from "es6-shim";


const INCHES_TO_MM = 25.4 // 1 inch = 25.4mm
const PREVIEW_CROPPER_NODE_WIDTH = 500;

const MAX_MM_WIDTH = 203.2 //8 inches
const MAX_MM_HEIGHT = 203.2 //8 inches

const AnySizePrintPrebag = ({
  items,
  onStartUpload,
  onCancel,
  onClickNext,
  uploadedImages,
  onSaveCrop,
  onChangeWidthOrHeight,
  frameOptions,
  canTile,
  numTiles,
  productSpec,
  hasProduct,
  showNoAvailableProducts,
  handleChangeProductOption,
  onIncreaseItemQuantity,
  onDecreaseItemQuantity,
  costPerPrint,
  noPhoto,
  onClickRestart,
  rightEdge,
  isRotated,
  availableWidth,
  onChangeLocketPreview,
  onChangeLocketShape,
  isMillimeterOnly,
  isLocketPrint
}) => {
  const unitSelectInput = useRef(null);
  const [customWidth, setWidth] = useState(frameOptions.width || null);
  const [locketPreview, setLocketPreview] = useState(frameOptions.locketPreview || false);
  const [locketShape, setLocketShape] = useState(frameOptions.locketShape || 'heart');
  const [customHeight, setHeight] = useState(frameOptions.height || null);
  const [units, setUnits] = useState(frameOptions.units || "");
  const [tiled, setTiled] = useState(frameOptions.tiled || false);
  const [el, setEl] = useState()
  const [zoomed, setZoomChanged] = useState(false)
  const [tooBig, setTooBig] = useState(false)
  const millimeterOnly = isMillimeterOnly
  const locketPrint = isLocketPrint === "true"

  const ref = useCallback((node) => {
    if (node !== null) {
      setEl(node)
    }
  }, [])

  // console.log("frameOptions", frameOptions)
  // console.log("frameOptions.locketPreview", frameOptions.locketPreview)
  // console.log("frameOptions.locketPreview || false", frameOptions.locketPreview || false)
  // console.log("locketPreview", locketPreview)

  const cropper = useRef()

  // const saveCrop = () => {
  //   if (!cropper.current) {
  //     return
  //   }
  //   const $previewNode = document.getElementById("cropper-preview");
  //   const $previewImg = $previewNode.firstChild;
  //   const imgStyles = window.getComputedStyle($previewImg);
  //   let transformMatrix = null;

  //   if (imgStyles.transform !== "none") {
  //     transformMatrix = /matrix\((.*)\)/g
  //       .exec(imgStyles.transform)[1]
  //       .split(", ")
  //       .map(v => Number(v));
  //   }
  //   let transform = {
  //     containerWidth: $previewNode.offsetWidth,
  //     containerHeight: $previewNode.offsetHeight,
  //     width: parseFloat(imgStyles.width),
  //     height: parseFloat(imgStyles.height),
  //     translateX: transformMatrix ? transformMatrix[4] : 0,
  //     translateY: transformMatrix ? transformMatrix[5] : 0,
  //   };
  //   onSaveCrop({
  //     ...cropper.current.getData(true),
  //     transform,
  //   })
  // } 


  const setCropperAspectRatio = (cropper, ratio) => {
    console.log("setAspectRatio:", ratio);
    if (!cropper) {
      return
    }
    const $cropperPreviewNode = document.getElementById("cropper-preview");
    $cropperPreviewNode.style.width = `${PREVIEW_CROPPER_NODE_WIDTH}px`;
    $cropperPreviewNode.style.height = `${PREVIEW_CROPPER_NODE_WIDTH * ratio}px`;
    $cropperPreviewNode.style.position = 'fixed'; // prevents select/inputs from scrolling page on iOS safari
    cropper.setAspectRatio(ratio);
  };

  const item = items[0]
  const indexForPhoto = item && item
    .get("layers")
    .findIndex(layer => layer.get("type") === LAYER_TYPES.PHOTO);
  const imageForItem = item ? item.getIn(["layers", indexForPhoto, "config", "layout", 0, "image", "src", "highResUrl"]) : null;
  const cropForItem = item ? item.getIn(["layers", indexForPhoto, "config", "layout", 0, "image", "cropData"]) : null;

  const saveCrop = useCallback(() => {
    if (!cropper.current) {
      return
    }
    const $previewNode = document.getElementById("cropper-preview");
    const $previewImg = $previewNode.firstChild;
    const imgStyles = window.getComputedStyle($previewImg);
    let transformMatrix = null;

    if (imgStyles.transform !== "none") {
      transformMatrix = /matrix\((.*)\)/g
        .exec(imgStyles.transform)[1]
        .split(", ")
        .map(v => Number(v));
    }
    let transform = {
      containerWidth: $previewNode.offsetWidth,
      containerHeight: $previewNode.offsetHeight,
      width: parseFloat(imgStyles.width),
      height: parseFloat(imgStyles.height),
      translateX: transformMatrix ? transformMatrix[4] : 0,
      translateY: transformMatrix ? transformMatrix[5] : 0,
    };
    onSaveCrop({
      ...cropper.current.getData(true),
      transform,
    })
  }, [cropper, onSaveCrop])

  useEffect(() => {
    if (el && imageForItem) {
      const $cropperPreviewNode = document.getElementById("cropper-preview");
      cropper.current = new Cropper(document.getElementById("image"), {
        aspectRatio: 1,
        zoomable: false,
        scalable: false,
        strict: true,
        guides: false,
        autoCropArea: 1,
        responsive: true,
        viewMode: 1,
        preview: $cropperPreviewNode,
        crop: () => {
          saveCrop()
        },
      });
      setCropperAspectRatio(cropper.current, customWidth/customHeight);
      return () => cropper.current.destroy()
    }
  }, [imageForItem, el, customHeight, customWidth, saveCrop]);

  useEffect(() => {
    if (cropper.current){
      console.log("setting cropper aspect ratio")
      setCropperAspectRatio(cropper.current, customWidth/customHeight);
    }
    onChangeWidthOrHeight(
      customWidth,
      customHeight,
      customWidthAsMM(),
      customHeightAsMM(),
      units,
      tiled
    )
    // setProductBasedOnDimenions(customWidth, customHeight)
  // eslint-disable-next-line
  }, [customWidth, customHeight, units, tiled]);


  useEffect(() => {
    if (cropper.current && cropForItem){
      const cropData = cropForItem.toJS()
      let canvData = cropper.current.getCanvasData();
      let contData = cropper.current.getContainerData();
      let scale;
      let newCanvData;

      if (canvData.height < contData.height) {
        console.log("Portrait photo rotated to landscape...");

        scale = canvData.height/canvData.naturalHeight;
        newCanvData = {
          height: cropData.height * scale,
          width: cropData.width * scale,
          top: cropData.y * scale + canvData.top,
          left: cropData.x * scale + canvData.left,
        };
      } else {
        console.log("Landscape photo rotated to portrait...");
        scale = canvData.width/canvData.naturalWidth;
        newCanvData = {
          height: cropData.height * scale,
          width: cropData.width * scale,
          top: cropData.y * scale,
          left: cropData.x * scale + canvData.left,
        };
      }
      setTimeout(() => {
        cropper.current.setCropBoxData(newCanvData)
      }, 10 );
    }
    // setProductBasedOnDimenions(customWidth, customHeight)
  // eslint-disable-next-line
  }, [tiled]);

  const handleSetWidth = (width) => {
    if (!isNaN(width) && !isNaN(parseFloat(width)) ){
      const desiredWidthAsMM = toMM(width)
      //alert(`desiredWidthAsMM is ${desiredWidthAsMM}`)
      const maxWidthAsMM = MAX_MM_WIDTH
      if (desiredWidthAsMM <= maxWidthAsMM){
        //alert(`parseFloat is ${parseFloat(width)}`)
        setWidth(parseFloat(width))
        setTooBig(false)
      } else{
        setTooBig(true)
      }
    } else {
      setWidth(width.replace(/[^0-9.]/g, ''))
    }
  }

  const handleSetHeight = (height) => {
    if (!isNaN(height) && !isNaN(parseFloat(height)) ){
      const desiredHeightAsMM = toMM(height)
      const maxHeightAsMM = MAX_MM_HEIGHT
      if (desiredHeightAsMM <= maxHeightAsMM){
        setHeight(parseFloat(height))
        setTooBig(false)
      } else{
        setTooBig(true)
      }
    } else {
      setHeight(height.replace(/[^0-9.]/g, ''))
    }
  }

  const handleSetUnits = (units) => {
    // console.log("width", customWidthAsMM(units))
    // console.log("height", customHeightAsMM(units))

    if ((customWidthAsMM(units) > MAX_MM_WIDTH) || (customHeightAsMM(units) > MAX_MM_HEIGHT)){
      setTooBig(true)
      return false
    }
    // let canSetUnits = false
    // let isTooBig = false
    // if (customHeightAsMM() > MAX_MM_WIDTH && customHeightAsMM() < MAX_MM_HEIGHT){
    //   if (customWidthAsMM() <= MAX_MM_WIDTH){
    //     // fine
    //     canSetUnits = true
    //   } else {
    //     // too big
    //     isTooBig = true
    //   }
    // } else if (customHeightAsMM() < MAX_MM_WIDTH){
    //   if (customWidthAsMM() <= MAX_MM_HEIGHT){
    //     //fine
    //     canSetUnits = true
    //   } else{
    //     //too big
    //     isTooBig = true
    //   }
    // } else {
    //   // too big
    //   isTooBig = true
    // }

    // if (customWidthAsMM() > MAX_MM_WIDTH && customWidthAsMM() < MAX_MM_HEIGHT){
    //   if (customHeightAsMM() <= MAX_MM_WIDTH){
    //     // fine
    //     canSetUnits = true
    //   } else {
    //     // too big
    //     isTooBig = true
    //   }
    // } else if (customWidthAsMM() < MAX_MM_WIDTH){
    //   if (customHeightAsMM() <= MAX_MM_HEIGHT){
    //     //fine
    //     canSetUnits = true
    //   } else{
    //     //too big
    //     isTooBig = true
    //   }
    // } else {
    //   // too big
    //   isTooBig = true
    // }

    // if height > 8 inches, if width > 8 && height < 12, otherwise true
    //const lessThanMaxLandscape = customHeightAsMM() > MAX_MM_WIDTH ? (customWidthAsMM() > MAX_MM_WIDTH && customHeightAsMM() < MAX_MM_HEIGHT) : true
    // if width > 8 inches, if height > 8 && width < 12, otherwise true
    //const lessThanMaxPortrait = customWidthAsMM() > MAX_MM_WIDTH ? (customHeightAsMM() > MAX_MM_WIDTH && customWidthAsMM() < MAX_MM_HEIGHT) : true
    setUnits(units)
      
  }


  //const handleFocus = (event) => event.target.select();

  const noItems = items.length === 0;
  const itemWithPhoto = item && !noPhoto
  const hasSetFrameSize = customWidth !== 0 && customHeight !== 0 && units !== ""
  const rendererWidth = availableWidth > 750 ? 600 : availableWidth*0.8
  

  const handleChangeLocketPreview = (val) => {
    setLocketPreview(val)
    onChangeLocketPreview(val)
  }

  const handleChangeLocketShape = (val) => {
    setLocketShape(val)
    onChangeLocketShape(val)
  }

  const handleChangeTiled = (val) => {
    setTiled(val)
    if(val === false){
      handleChangeLocketPreview(false)
    }
  }

  const toMM = (size) => {
    switch (units) {
      case "inches":
        return (size * INCHES_TO_MM)
      case "cm":
        return (size * 10)
      case "mm":
        return size
      default:
        return size
    }
  }

  const customWidthAsMM = (forUnits) => {
    let useUnits = forUnits || units
    switch (useUnits) {
      case "inches":
        return (customWidth * INCHES_TO_MM)
      case "cm":
        return (customWidth * 10)
      case "mm":
        return customWidth
      default:
        return customWidth
    }
  }

  const customHeightAsMM = (forUnits) => {
    let useUnits = forUnits || units
    switch (useUnits) {
      case "inches":
        return (customHeight * INCHES_TO_MM)
      case "cm":
        return (customHeight * 10)
      case "mm":
        return customHeight
      default:
        return customHeight
    }
  }

  // const minForUnit = () => {
  //   switch (units) {
  //     case "inches":
  //       return 0.5
  //     case "cm":
  //       return 0.5
  //     case "mm":
  //       return 5
  //     default:
  //       return 0
  //   }
  // }

  // const maxForUnit = (forUnit) => {
  //   switch (units) {
  //     case "inches":
  //       return 8
  //     case "cm":
  //       return 20
  //     case "mm":
  //       return 200
  //     default:
  //       return 8
  //   }
  // }


  const fitCanvasToContainer = () => {
    if (cropper.current) {
      let containerData = cropper.current.getContainerData();
      let canvasData = cropper.current.getCanvasData();
      let scaleWidth = canvasData.naturalWidth / containerData.width;
      let scaleHeight = canvasData.naturalHeight / containerData.height;
      let scale = Math.max(scaleWidth, scaleHeight);
      let newWidth = canvasData.naturalWidth / scale;
      let newHeight = canvasData.naturalHeight / scale;
      let newLeft = (containerData.width - newWidth) / 2;
      let newTop = (containerData.height - newHeight) / 2;

      let newCanvasData = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop
      };
      cropper.current.options.viewMode = 0
      cropper.current.setCropBoxData({width: 4, height: 3, left: containerData.width / 2, top: containerData.height / 2 });
      cropper.current.setCanvasData(newCanvasData);
      cropper.current.options.viewMode = 1 //2 
    }
  }

  const fitCropBoxToCanvas = () => {
    if (cropper.current) {
      cropper.current.crop();
      let cropBoxData = cropper.current.getCropBoxData();
      let canvasData = cropper.current.getCanvasData();

      let scaleWidth = cropBoxData.width / canvasData.width;
      let scaleHeight = cropBoxData.height / canvasData.height;
      let scale = Math.max(scaleWidth, scaleHeight) / cropper.current.options.autoCropArea;
      let newWidth = cropBoxData.width / scale;
      let newHeight = cropBoxData.height / scale;
      let newLeft = (canvasData.width - newWidth) / 2 + canvasData.left;
      let newTop = (canvasData.height - newHeight) / 2 + canvasData.top;

      let newCropBox = {
        width: newWidth,
        height: newHeight,
        left: newLeft,
        top: newTop
      };
      cropper.current.setCropBoxData(newCropBox);
    }
  }
   

  const rotateOnlyImage = (angle) => {
    if (cropper.current){
      cropper.current.rotate(angle);
      cropper.current.render();
      fitCanvasToContainer()
      fitCropBoxToCanvas()
    }
  }

  const tilingContent = (
    <p style={{fontSize: '12px', maxWidth: '50vw'}}>
      If you choose the tiling option, we'll fit as many copies of your photo on the print as we can.<br/>
      We'll add a 1mm space around each photo so you can cut them out without losing any of your photo.
    </p>
  )

  const locketContent = (
    <p style={{fontSize: '12px', maxWidth: '50vw'}}>
      See how your photo might look in a locket. <strong>Note:</strong> Our locket overlays are not be printed and are shown purely as a guide to help you position your photo. You will need to trim your photo.
    </p>
  )

  const effectsOn = !item ? false
    : item && item.getIn(["product_options", "effects"])
    ? true
    : false

  const glossOn = !item ? true
    : item && item.getIn(["product_options", "finish"]) === "gloss"
    ? true
    : false
  
  const totalQuantity = item && item.get('quantity')

  // const optionActiveButtonColor = '#3b3938';
  // const optionButtonStyles = {
  //   fontSize: '10px',
  //   borderRadius: '2px',
  //   width: '90px',
  //   height: '32px',
  //   color: "#fff",
  //   backgroundColor: 'white',
  //   display: 'inline',
  //   marginRight: '5px',
  //   marginBottom: '10px',
  // };

  // console.log("itemWithPhoto",itemWithPhoto)
  // console.log("hasSetFrameSize",hasSetFrameSize)
  // console.log("hasProduct",hasProduct)
  // console.log("items.length === 0", items.length === 0)
  // console.log("noItems", noItems)
  if (item){
    // console.log("item", item.toJS())
    // console.log("item ID", item.get('id'))
    // console.log("Dimensions width", customWidthAsMM())
    // console.log("Dimensions height", customHeightAsMM())
    // console.log("rendererWidth", rendererWidth)
  }
    
  //console.log("noPhoto", noPhoto)
  
  const disableApprove = noItems || !(itemWithPhoto && hasSetFrameSize && hasProduct)

  //const nearlySquare = customWidth >= customHeight ? customWidth/customHeight < 1.5 : customHeight/customWidth < 1.5
  //console.log("nearlySquare", nearlySquare)
  const customMMWidth = customWidthAsMM()
  const customMMHeight = customHeightAsMM()
  const minLocketSize = 10 // 1cm
  const maxLocketSize = 38.1  // 1.5inch
  const locketSize = customMMWidth <= maxLocketSize && customMMWidth >= minLocketSize  && customMMHeight <= maxLocketSize && customMMHeight >= minLocketSize
  //console.log("locketSize", locketSize)

  let currentScale = null
  const getStats = (stats) => {
    currentScale = stats.scale
    if(currentScale > 1.1){
      setTimeout(() => {
        setZoomChanged(true)
      }, 200);
    } else if (currentScale === 1){
      if (zoomed){
        setTimeout(() => {
          setZoomChanged(false)
        }, 200);
      }
    }
  }

  const canUpload = hasSetFrameSize && units !== "" && hasProduct
  const uploadDisabled = locketPrint ? !(customHeight && customWidth) : !canUpload
 
  let title = productSpec !== "" ? ( millimeterOnly ? `Locket Prints: ${productSpec}` : `Custom Prints: ${productSpec}`) : millimeterOnly ? 'Locket Prints' : "Custom Prints" 

  if (uploadDisabled){
    title = locketPrint ? 'Locket Prints' : 'Custom Prints'
  }

  const optionActiveButtonColor = '#4b5566';
  const optionButtonStyles = {
    fontSize: '12px',
    borderRadius: '2px',
    width: '125px',
    height: '32px',
  };

  const checkedOn = (
    <span style={{position: 'absolute', right: '5px'}}>
      <CheckCircleFilled style={{ color: '#00C202' }}/>
    </span>
  )

  return (
    <React.Fragment>
      <Header
        title={title}
        leftAction={<Button theme="muted" priority="tertiary" label="Back" onClick={onCancel} />}
        theme="grey"
        rightAction={
          <Button
            theme="dark-blue"
            priority="tertiary"
            label="Next"
            disabled={disableApprove}
            onClick={() => onClickNext()}
          />
        }
      />
      <MainContent padded scrollable className="any-size-prints-prebag">
        <div className="any-size-prints-container">
          <div className="print-options">
            <div className="print-options__heading">
              <div className="print-options__left">
                <span className="instructions">Size:</span>
              </div>
              {/* <div className="print-options__right">
                <span className="print-options__spec">{productSpec}</span>
              </div> */}
            </div>
            <div className="frame-options">
              <div className="frame-input">
                <span className="frame-input__label">
                  Width
                  {millimeterOnly && (
                    <span> (mm)</span>
                  )}
                </span
                >
                {millimeterOnly ? (
                  <input
                    type="text"
                    name="width"
                    data-role="none"
                    placeholder=""
                    value={customWidth}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Validate input to allow only integers
                      if (/^\d*$/.test(inputValue)) {
                        handleSetWidth(inputValue);
                      }
                    }}
                    className="frame-size-input"
                    style={{ width: '80px' }}
                  />
                ) : (
                  <input
                    type="number"
                    name="width"
                    data-role="none"
                    placeholder=""
                    value={customWidth}
                    onChange={(e) => handleSetWidth(e.target.value)}
                    step="0.1"
                    min="0.1"
                    className="frame-size-input"
                    pattern="^\d*(\.\d{0,2})?$"
                    // onFocus={handleFocus}
                    // onClick={handleFocus}
                    style={{ width:'80px' }}
                  />
                )}
              </div>
              <div className="frame-input">
              <span className="frame-input__label">&nbsp;</span>
                <span style={{fontSize: '20px'}}>×</span>
              </div>
              <div className="frame-input">
                <span className="frame-input__label">
                  Height
                  {millimeterOnly && (
                    <span> (mm)</span>
                  )}
                </span>
                {millimeterOnly ? (
                  <input
                    type="text"
                    name="height"
                    data-role="none"
                    placeholder=""
                    value={customHeight}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Validate input to allow only integers
                      if (/^\d*$/.test(inputValue)) {
                        handleSetHeight(inputValue);
                      }
                    }}
                    className="frame-size-input"
                    style={{ width: '80px' }}
                  />
                ) : (
                  <input
                    type="number"
                    name="height"
                    data-role="none"
                    placeholder=""
                    value={customHeight}
                    onChange={(e) => handleSetHeight(e.target.value)}
                    step="0.1"
                    min="0.1"
                    className="frame-size-input"
                    pattern="^\d*(\.\d{0,2})?$"
                    // onFocus={handleFocus}
                    // onClick={handleFocus}
                    style={{ width : '80px' }}
                  />
                )}
                
                {/* <input
                  name="height"
                  value={customHeight}
                  onChange={(e) => handleSetHeight(e.target.value)}
                  type="number"
                  max={`${maxForUnit('height')}`}
                  min={`${minForUnit()}`}
                  step="0.1"
                  inputmode="decimal"
                  pattern="^\d*(\.\d{0,2})?$"
                  className="frame-size-input"
                  onFocus={handleFocus}
                  onClick={handleFocus}
                  style={{ width : millimeterOnly ? '80px' : 'auto' }}
                /> */}
              </div>
              {millimeterOnly && (
                <div className="frame-input">
                  <p style={{ marginTop: '15px', fontSize: '12px'}}>
                    Enter Your Measurements in Millimetres
                  </p>
                </div>
              )}
              {!millimeterOnly && (
                <div className="frame-input">
                  <span className="frame-input__label">Units</span>
                  
                    <select
                      className="frame-size-select"
                      onChange={(e) => handleSetUnits(e.target.value)}
                      value={units}
                      ref={unitSelectInput}
                      style={{width : '135px', textAlign: 'left'}}
                    >
                      <option value="">Select a unit...</option>
                      <option value="cm">Centimeters</option>
                      <option value="inches">Inches</option>
                    </select>
                </div>
              )}
            </div>
            {tooBig && (
              <p style={{fontSize:'11px', color: 'red'}}>
                The selected size is too big, our largest custom print size is a 8x8" (20x20cm)
              </p>
            )}
            <div className="other-options">
              <div className="other-options__finish-options">
                <p className="instructions">Options:</p>
                <p className="option-title">Quantity:</p>
                <div className="editor__quantity-picker" style={{display: 'flex', alignItems: 'center'}}>
                  <div className="editor__quantity-picker-controls">
                    <AntButton.Group>
                      <AntButton
                        size="default"
                        type={"primary"}
                        ghost
                        active={"true"}
                        onClick={onDecreaseItemQuantity}
                        style={{borderColor: '#4b5566', color: '#4b5566'}}
                      >
                        <MinusOutlined />
                      </AntButton>
                      <AntButton
                        size="default"
                        onClick={onIncreaseItemQuantity}
                        type={"primary"}
                        ghost
                        style={{borderColor: '#4b5566', color: '#4b5566'}}
                      >
                        <PlusOutlined />
                      </AntButton>
                    </AntButton.Group>
                  </div>
                  {costPerPrint && (
                    <div className="editor__quantity-picker__info" style={{ marginLeft: '10px'}}>
                      <p style={{fontSize: '12px', color: 'black'}}>{`${totalQuantity} ${totalQuantity === 1 ? 'copy' : 'copies'} @`} <Currency amount={costPerPrint} /> {`${totalQuantity > 1 ? 'each' : ''}`}</p>
                    </div>
                  )}
                  
                </div>
                <p className="option-title">Finish:</p>
                <div style={{display: 'inline-block', marginTop: '5px', marginBottom: '10px'}}>
                  <AntButton
                    size="small"
                    style={{
                      ...optionButtonStyles,
                      backgroundColor: glossOn ? optionActiveButtonColor : '',
                      borderColor: glossOn ? optionActiveButtonColor: '',
                    }}
                    type={glossOn ? "primary" : ""}
                    onClick={(e) => handleChangeProductOption("finish", "gloss")}
                    data-gtm-element="Product Options - Finish"
                    disabled={noItems}
                  >
                  Gloss {glossOn && (
                    checkedOn
                  )}
                  </AntButton>
                  <AntButton
                    size="small"
                    onClick={(e) => handleChangeProductOption("finish", "matt")}
                    style={{
                      ...optionButtonStyles,
                      backgroundColor: !glossOn ? optionActiveButtonColor : '',
                      borderColor: !glossOn ? optionActiveButtonColor: '',
                      marginLeft: '30px'
                    }}
                    type={!glossOn ? "primary" : ""}
                    data-gtm-element="Product Options - Finish"
                    disabled={noItems}
                  >
                    Matt {!glossOn && (
                      checkedOn
                    )}
                  </AntButton>
                </div>  
                <p className="option-title">Effect:</p>
                <div style={{ display: 'inline-block', marginTop: '5px'}}>
                <AntButton
                  size={"small"}
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: !effectsOn ? optionActiveButtonColor : '',
                    borderColor: !effectsOn ? optionActiveButtonColor: '',
                  }}
                  type={!effectsOn ? "primary" : ""}
                  onClick={(e) => handleChangeProductOption("effects", false)}
                  data-gtm-element="Product Options - Effects"
                  disabled={noItems}
                >
                  Colour
                  {!effectsOn && (
                    checkedOn
                  )}
                </AntButton>
                <AntButton
                  size={"small"}
                  onClick={(e) => handleChangeProductOption("effects", "black-and-white")}
                  style={{
                    ...optionButtonStyles,
                    backgroundColor: effectsOn ? optionActiveButtonColor : '',
                    borderColor: effectsOn ? optionActiveButtonColor: '',
                    marginLeft: '30px'
                  }}
                  type={effectsOn ? "primary" : ""}
                  data-gtm-element="Product Options - Effects"
                  disabled={noItems}
                >
                  Black & white
                  {effectsOn && (
                    checkedOn
                  )}
                </AntButton>
              </div>
              </div>
            </div>

            {showNoAvailableProducts && (
              <div className="no-size-available">
                <p>We can't print this size on photo paper - our largest photo paper size is 8x8″, reduce your photo size or change the units to fit.</p>
              </div>
            )}
          </div>
          <hr/>
          <div className="print-preview">
            {itemWithPhoto && (
              <div>
                <div className="photo-edit-options">
                  <div className="photo-edit-options__left">
                    <span className="instructions">Edit Photo:</span>
                  </div>
                  <div className="photo-edit-options__right">
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Change Photo"
                      onClick={() => onStartUpload(true)}
                    />
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Rotate Left"
                      onClick={() => rotateOnlyImage(-90)}
                    />
                    <Button
                      size="small"
                      priority="secondary"
                      theme="dark-blue"
                      label="Rotate Right"
                      onClick={() => rotateOnlyImage(90)}
                    />
                  </div>                
                </div>
                <div className="wraper" style={{width: '80vw', minHeight: '300px', maxWidth: `${rendererWidth}px`, paddingBottom: '20px', margin: '0 auto'}}>
                  <div className="croper-img">
                    <img className="croper" alt="custom" id="image" ref={ref} src={imageForItem} />
                  </div>
                  <p className="crop-intructions">Move the box above to crop your photo.<br/> Drag the
                    &nbsp;<img className="crop-icon" src={`${process.env.PUBLIC_URL}/images/text-resize.png`} alt="trim print"/>&nbsp;
                    icon to adjust the print area.</p>
                </div>
              </div>
            )}
            {(noItems || (item && noPhoto)) && (
              <div className="cta-btn-container">
                <Button
                  block
                  theme="dark-blue"
                  label="Upload Photo"
                  onClick={() => onStartUpload(false)}
                  dataGtmElement="Photo Upload Source"
                  disabled={uploadDisabled}
                />
              </div>
              // <div className="photo-prints-prebag__no-items-container">
              //   <div className="photo-prints-prebag__no-items" onClick={onStartUpload}>
              //     {/* <PlusOutlined />
              //     <div style={{ marginTop: 8 }}>Add a Photo</div> */}
              //   </div>
              // </div>
            )}

            {(itemWithPhoto && hasSetFrameSize && hasProduct) && (
              <div style={{marginTop: '10px'}}>
                <p className="instructions">Preview:</p>
                
                  {/* <p style={{textAlign: "center", marginBottom: '5px', fontSize: '12px'}}>(Printed on {product.get('name').replace('Prints', '').replace(' × ','×')})</p> */}
                  {/* {customWidthAsMM()}
                  {customHeightAsMM()} */}
                  {/* {units} */}
                <div className={`custom-print-wrapper ${zoomed ? 'zoomed' : ''}`}>
                  {zoomed && (
                    <div className="zoom-warning">
                      <p>Zoomed</p>
                    </div>
                  )}
                  <TransformWrapper
                    options={{ centerContent: true }}
                    defaultScale={1}
                    onZoomChange={getStats}
                    zoomIn={{ step: 20 }}
                    zoomOut={{ step: 17 }}
                    pan={{disabled: (!tiled || (!zoomed && tiled))}}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <div className="tools">
                          <AntButton.Group>
                            <AntButton
                              size="large"
                              type={"primary"}
                              ghost
                              active={"true"}
                              onClick={zoomIn}
                              style={{borderColor: '#4b5566', color: '#4b5566'}}
                            >
                              <ZoomInOutlined style={{ fontSize: '18px'}}/>
                            </AntButton>
                            <AntButton
                              size="large"
                              onClick={zoomOut}
                              type={"primary"}
                              ghost
                              style={{borderColor: '#4b5566', color: '#4b5566'}}
                            >
                              <ZoomOutOutlined style={{ fontSize: '18px'}}/>
                            </AntButton>
                          </AntButton.Group>
                          <div style={{position: 'absolute', right: '5vw', top: '0'}}>
                            <AntButton
                              size="large"
                              type={"primary"}
                              ghost
                              active={"true"}
                              onClick={resetTransform}
                              style={{borderColor: '#4b5566', color: '#4b5566'}}
                            >
                              Reset
                            </AntButton>
                          </div>
                        </div>
                        <TransformComponent>
                          <div className="custom-print_wrapper__inner">
                            <HtmlRenderer
                              key={`render${item.get('id')}`}
                              width={rendererWidth}
                              showLocketOverlays={true}
                              previewMode={true}
                              item={item.toJS()}
                              page={item.getIn(["pages", "front"])}
                              onClick={() => {}}
                              specificDimensions={{
                                width: customWidthAsMM(),
                                height: customHeightAsMM()
                              }}
                            />
                            {!isRotated && (
                              <div className="trim-prints__containter" style={{width: `${rendererWidth}px`}}>
                                <img
                                  style={{left: `calc(${rightEdge}% - (50px - 5px))`}}
                                  src={`${process.env.PUBLIC_URL}/images/trim-prints.png`}
                                  alt="trim-prints"
                                  className="trim-prints"/>
                              </div>
                            )}
                          </div>
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            )}
            {(item && !hasSetFrameSize) && (
              <div className="custom-print-wrapper" style={{padding: '20px'}}>
                <p style={{textAlign: "center"}}>Select your print size above to see a preview of your print</p>
              </div>
            )}
            {(item && hasSetFrameSize && !tiled && itemWithPhoto && locketSize) && (
              <div>
                <div className="tile-options" style={{marginBottom: '10px'}}>
                  <p className="option-title">Locket Preview:
                    <Popover content={locketContent} title="Locket Preview" placement="top">
                      <span className="info-icon">
                        <InfoOutlined style={{fontSize: '18px'}}/>
                      </span>
                    </Popover>
                  </p>
                  <div className="tiling-container">
                    <Switch size="large" checked={locketPreview} onChange={handleChangeLocketPreview} />
                    <div className="tiling-info">
                      <p>See how this might look in a traditional locket.</p>
                    </div>
                  </div>
                </div>
                {locketPreview && (
                  <div className="locket-options" style={{marginBottom: '10px'}}>
                    <p className="option-title">Locket Shape:</p>
                    <div className="locket-shapes">
                      <ul className="locket-shapes__list">
                        <li className={`locket-shapes__list-item ${locketShape === 'heart' ? 'active' : ''}`} onClick={() => handleChangeLocketShape("heart")}>
                          <img className="locket-shapes__img" alt="heart" src={`${process.env.PUBLIC_URL}/images/masks/heart-locket-preview.svg`}/>
                        </li>
                        <li className={`locket-shapes__list-item ${locketShape === 'oval' ? 'active' : ''}`} onClick={() => handleChangeLocketShape("oval")}>
                          <img className="locket-shapes__img"alt="oval" src={`${process.env.PUBLIC_URL}/images/masks/oval-locket-preview.svg`}/>
                        </li>
                        <li className={`locket-shapes__list-item ${locketShape === 'circle' ? 'active' : ''}`} onClick={() => handleChangeLocketShape("circle")}>
                          <img className="locket-shapes__img" alt="circle" src={`${process.env.PUBLIC_URL}/images/masks/circle-locket-preview.svg`}/>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
            {(item && hasSetFrameSize && canTile && itemWithPhoto) && (
              <div className="tile-options">
                <p className="option-title">Tiling:
                  <Popover content={tilingContent} title="Tiling" placement="right">
                    <span className="info-icon">
                      <InfoOutlined style={{fontSize: '18px'}}/>
                    </span>
                  </Popover>
                  
                </p>
                <div className="tiling-container">
                  <Switch size="large" checked={tiled} onChange={handleChangeTiled} disabled={!canTile}/>
                  {canTile && (
                    <div className="tiling-info">
                      <p>Turn on to tile your image</p>
                    </div>            
                  )}
                  {!canTile && (
                    <div className="tiling-info">
                      <p>Unable to tile with the existing print size - make your print size smaller to enable tiling</p>
                    </div>            
                  )}  
                </div>
              </div>
            )}
          </div>
          {(itemWithPhoto && hasSetFrameSize && hasProduct) && (
            <div className="cta-btn-container">
              <Button
                block
                theme="dark-blue"
                label="Next"
                disabled={disableApprove}
                onClick={() => onClickNext()}
                dataGtmElement="Approve"
              />
            </div>
            
          )}
          {/* {!noItems && (
            <div className="cta-btn-container">
              <Button
                block
                theme="dark-blue"
                priority="secondary"
                label="Start Again"
                onClick={() => onClickRestart()}
                dataGtmElement="Preview"
              />
            </div>
          )} */}
            
        </div>
      </MainContent>

    </React.Fragment>
  );
};

AnySizePrintPrebag.propTypes = {
  onClose: PropTypes.func,
  onStartUpload: PropTypes.func,
};

export default AnySizePrintPrebag;
