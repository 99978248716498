import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import {
  getProductTypeforProductTypeId,
  hasMinimumQuantityForProduct,
  minimumQuantityForProduct,
  PRODUCT_TYPE_IDS
 } from "../../data/products";
import { getAllPostageSchemesForProductTypeAndDestination } from "../../data/postage-schemes";
import Currency from "../../components/Formatters/Currency";
import generateMultilineAddress from "../../lib/generate-multiline-address";
import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button as AntButton,
  Collapse,
  Radio,
} from "antd";

import "./OrderSummary.scss";

const { Panel } = Collapse;

// const OrderSummaryItem = ({productTypeId, items}) => {
//   return (
//     <p>{productTypeId} | {items.length}</p>
//   );
// };

class OrderSummary extends React.Component {
  static propTypes = {
    summary: PropTypes.object
  };

  static defaultProps = {
  };

  state = {
    selectedPostageSchemeId: this.props.activePostageSchemeId
  };

  printsPanelRef = React.createRef();


  onChangePostageScheme = (item, event) => {
    const postageSchemeId = event.target.value;
    this.setState({
      selectedPostageSchemeId: postageSchemeId,
    }, () => {

      // const postageSchemes = getAllPostageSchemesForProductTypeAndDestination({
      //   productTypeId: parseInt(productTypeId),
      //   destinationCountry: photoPrintItemAddress.get("country"),
      //   currency: currency,
      //   weight: 1
      // }); 

      this.props.onChangePostageSchemeForItem(item.get('id'), postageSchemeId);
    });
  };

  render() {
    const { summary, currency } = this.props;

    //const total = summary && summary.get('total');

    const itemsGroupedByProductType = summary && summary.get("items").groupBy((item) => item.get('productTypeId')).toJS();

    const groupings = itemsGroupedByProductType && Object.keys(itemsGroupedByProductType)


    let productSections = [];
    let shippingSections = [];
    //let productTotal = 0;
    //let shippingTotal = 0;


    if (groupings){

      productSections = groupings.map((productTypeId, index) => {
        const productsForProductType = itemsGroupedByProductType[productTypeId];
        const productType = getProductTypeforProductTypeId(parseInt(productTypeId));
        const productsGroupedByProduct = fromJS(productsForProductType).groupBy((productTypeProduct) => productTypeProduct.get('productId')).toJS();

        const productGroupings = Object.keys(productsGroupedByProduct).map(productId => {
          const productsForProductId = fromJS(productsGroupedByProduct[productId]);
          let productQty = productsForProductId.reduce((total, product) => total + product.get("quantity"), 0);
          const product = productsForProductId.first();
          const productID = product.get("productId");
          const totalForProduct = productsForProductId.reduce((total, product) => total + (product.get("quantity") * product.getIn(["pricingScheme", "cost"])), 0);

          const hasMinimumQty = hasMinimumQuantityForProduct(productID);
          
          if (hasMinimumQty){
            //let incompletePack = false;
            const packQty = minimumQuantityForProduct(productID)
            const packCount = productQty % packQty === 0 ? (productQty/packQty) : Math.floor(productQty/packQty);
            // TODO when pack qtys not met...

            // if (productQty % packQty !== 0){
            //   incompletePack = true;
            // }

            if(packCount === 1){
              productQty = `${packCount} pack of ${packQty}`
            } else{
              productQty = `${packCount} packs of ${packQty}`
            }            
          }

          //productTotal += totalForProduct;

          return (
            <p style={{display: 'flex', justifyContent: 'space-between'}}><span>{product.get('description')} × {productQty}</span> <span style={{textAlign: 'right', fontSize: '12px'}}>{<Currency amount={totalForProduct}/>}</span></p>
          );
        });

        // const productGroupings = {productsForProductType.map(item => (
        //   <p>{item.description}</p>
        // ))}

        //console.log(itemsForProductType);
        return (
          <div className="order-summary--product-type-section">
            <div key={`productType-${productTypeId}`} className="order-summary--product-type-header-section">
              <span className="order-summary--product-type-heading">{productType.get('name')}</span>
            </div>
            <div className="order-summary--product-groupings">
              {productGroupings}
            </div>
          </div>
        );
      });

      shippingSections = groupings.map((productTypeId, index) => {
        const productsForProductType = itemsGroupedByProductType[productTypeId];
        const productType = getProductTypeforProductTypeId(parseInt(productTypeId));
        const productsGroupedByProduct = fromJS(productsForProductType).groupBy((productTypeProduct) => productTypeProduct.get('productId')).toJS();

        const productShippingTotal = Object.keys(productsGroupedByProduct).reduce((total, productId) => {
          const productsForProductId = fromJS(productsGroupedByProduct[productId]);
          const totalForProductType = productsForProductId.reduce((total, product) => total + product.getIn(["postageScheme", "cost"]), 0);
          return total + totalForProductType;
        }, 0);

        //shippingTotal += productShippingTotal;

        const photoPrintItemWithRealPostageScheme = fromJS(productsForProductType).find(item => item.getIn(["postageScheme", "cost"]));
        //productsForProductId
        const photoPrintItemAddress = photoPrintItemWithRealPostageScheme
          ? photoPrintItemWithRealPostageScheme.get("address") ||
            photoPrintItemWithRealPostageScheme.get("addressBookEntry")
          : null;

        const printsShippingPanelStyle = {
          background: "rgb(255, 255, 255)",
          borderRadius: 4,
          marginBottom: 0,
          //border: "1px solid #ebebeb",
          overflow: "hidden",
          //boxShadow: "3px 3px 6px 2px #cccccc21",
          //marginLeft: '0px',
          border: 'none',
          marginTop: '-5px',
          marginLeft: '-15px',
        };

        const printsShippingCost = (
          <div className="shipping-cost-header">
            <span>
              <Currency amount={productShippingTotal} />
            </span>
          </div>
        );

        const shippingHeader = (
          <div className="shipping-cost-header">
            <span>&nbsp;</span>
          </div>
        );

        let postageSchemes, postageSchemeOptions;
        let hasPostageSchemeOptions = false;

        const radioStyle = {
          lineHeight: '16px',
          display: 'flex',
          justifyContent: 'start',
          fontSize: '14px',
          margin: '10px 0px 10px 0px',
          fontWeight: 300,
          paddingBottom: '5px'
        };

        //borderTop: '1px solid #e6e6e6',

        const postageSchemeValue = photoPrintItemWithRealPostageScheme && photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']);

        //console.log("postageSchemeId", postageSchemeValue);
        let totalWeightForAllItemsOfProductType = 0;
        if (photoPrintItemAddress){
          totalWeightForAllItemsOfProductType = fromJS(productsForProductType).reduce((totalWeight, itemForProduct) => {
            if (itemForProduct.get("productTypeId") === PRODUCT_TYPE_IDS.PHOTO_PRINT) {
              const itemQuantity = itemForProduct.get("quantity");
              const itemWeight   = itemForProduct.get('weight') === undefined ? 1 : itemForProduct.get('weight');
              return totalWeight + (itemQuantity * itemWeight);
            }
            return totalWeight;
          }, 0);
          //console.log(productsForProductType);
          //console.log(`Total weight for ProductType:${productsForProductType[0].productTypeId}`, totalWeightForAllItemsOfProductType)
          //console.log("photoPrintItemAddress", photoPrintItemAddress);
          postageSchemes = getAllPostageSchemesForProductTypeAndDestination({
            productTypeId: parseInt(productTypeId),
            destinationCountry: photoPrintItemAddress.get("country"),
            currency: currency,
            weight: totalWeightForAllItemsOfProductType,
            postcode: photoPrintItemAddress.get("postcode")
          });      
          // TODO: get all print item weights... ^

          //console.log("Postage Schemes", postageSchemes);

          hasPostageSchemeOptions = postageSchemes && postageSchemes.size > 1

          //${scheme.get("service_description")}
          if (hasPostageSchemeOptions){
            postageSchemeOptions = postageSchemes.sortBy(scheme => parseFloat(scheme.get('cost'))).map((scheme) => {
              //console.log("photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) ", photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) )
              const isChecked = photoPrintItemWithRealPostageScheme.getIn(['postageScheme', 'id']) === scheme.get("id");
              return (
                <Radio checked={isChecked} style={radioStyle} value={scheme.get("id")}>
                  <React.Fragment>
                    <div style={{marginBottom:'3px'}}>
                      <span>
                        {`${scheme.get("description")}`} — {` `}
                      </span>
                      <span>
                        <Currency amount={scheme.get("cost")}/>
                      </span>
                    </div>
                    <div style={{marginBottom:'3px'}}>
                      <span style={{fontSize: '12px', whiteSpace: 'pre-line'}}>
                        {scheme.get("service_description")}
                      </span>
                    </div>
                  </React.Fragment>
                </Radio>
              );
            })
          }
        }

        const scrollToPrintsPanel = e => {
          setTimeout(() => {
            if(this.printsPanelRef.current){
              this.printsPanelRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
            }
          }, 450)
        };

        const isPhotoPrint = parseInt(productTypeId) === PRODUCT_TYPE_IDS.PHOTO_PRINT;

        const shouldShowShippingPanel = photoPrintItemAddress && isPhotoPrint;

        return (
          <div className="order-summary--product-type-section">
            <div key={`productType-${productTypeId}`} className="order-summary--product-type-header-section shipping">
              

              <span className="order-summary--product-type-heading" style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>{productType.get('name')}</span>
                <span>
                  {!shouldShowShippingPanel && (
                    productShippingTotal === 0 ? (
                      <span className="pricing-text">Free</span>
                    ) : (
                      <span className="pricing-text">
                        <Currency amount={productShippingTotal}/>
                      </span>
                    )
                  )}

                  {shouldShowShippingPanel && (
                    printsShippingCost
                  )}
                </span>
              </span>

              {hasPostageSchemeOptions && (
                <div className="order-summary--product-shipping-options">
                  <p>Shipping option:</p>
                  {/* {!!totalWeightForAllItemsOfProductType ? `Weight ${totalWeightForAllItemsOfProductType}` : ''} */}
                  <Radio.Group
                    onChange={(e) => this.onChangePostageScheme(photoPrintItemWithRealPostageScheme, e)}
                    value={postageSchemeValue}
                    buttonStyle={"solid"}
                  >
                    {postageSchemeOptions}
                  </Radio.Group>
                  {/* <p>Estimated Arrival: 1-3 days</p> */}
                </div>
              )}
              
              {shouldShowShippingPanel && (
                <Collapse
                  bordered={false}
                  expandIconPosition={'left'}
                  onChange={(e) => scrollToPrintsPanel(e)}
                  //expandIcon={({ isActive }) => <AntIcon type="info-circle" style={{marginRight: '5px'}}/>}
                  //expandIcon={({ isActive }) => <AntIcon type="caret-down" rotate={isActive ? 180 : 0} />}
                  expandIcon={({ isActive }) =>
                    <div style={{
                      border: '1px solid #4C5666',
                      padding: '5px',
                      borderRadius: '2px',
                      marginTop: '5px'
                    }}>
                      <span style={{marginRight: '5px'}}>{isActive ? "Hide" : "Shipping Address"}</span>
                      <CaretDownOutlined rotate={isActive ? 180 : 0} />
                    </div>
                  }
                >
                  <Panel showArrow={true} header={shippingHeader} key="prints-shipping" style={printsShippingPanelStyle}>
                    <div style={{marginLeft: '0px'}}>
                      {photoPrintItemAddress && (
                        <div ref={this.printsPanelRef}>
                          <p
                          style={{ fontSize: 12, whiteSpace: "pre-line", border: '1px solid #e2e2e2', padding: '10px',background:'#fbfbfb', maxWidth: '300px', marginBottom: '10px' }}
                          onClick={() => this.props.onClickAddress(photoPrintItemWithRealPostageScheme)}>
                            {generateMultilineAddress(photoPrintItemAddress.toJS()).join("\n")}
                          </p>
                          <p>Phone: {photoPrintItemWithRealPostageScheme.get('shippingPhone')}</p>
                          <AntButton
                            type="default"
                            size="small"
                            style={
                              {
                                backgroundColor: '#4b5566',
                                borderColor: '#4b5566',
                                color: 'white',
                                fontSize: '12px',
                                padding: '6px 10px',
                                height: '30px',
                              }
                            }
                            onClick={() => this.props.onClickAddress(photoPrintItemWithRealPostageScheme)}
                          >
                            <EditOutlined /> Change Address
                          </AntButton>
                          <p style={{fontSize: '12px', marginTop:'5px', marginBottom: '5px'}}>
                            <span style={{color: "#636363", fontSize: '12px'}}>Printed & mailed on the same or next working day.<br/>We aim to deliver your prints within 2-3 working days.</span>
                          </p>
                          <p style={{fontSize: '12px'}}>
                            <span style={{color: "#ff5a5f", fontSize: '12px'}}>Sent in high-quality, sturdy packaging.</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </Panel>
                </Collapse>
              )}

            </div>
            <div className="order-summary--product-groupings">
              
            </div>
          </div>
        );
      });

    }
     
    return (
      <div className="order-summary">
        {itemsGroupedByProductType && (
          <div className="order-summary--product-types">
            <h2 className="order-summary--heading">Order Summary:</h2>
            <div className="order-summary--section">
              <p style={{ fontWeight: '500', marginBottom: '5px'}}>Products</p>
              <div style={{marginLeft: '5px'}}>
                {productSections}
              </div>
            </div>
            <div className="order-summary--section">
              <p style={{ fontWeight: '500', marginBottom: '5px'}}>Shipping</p>
              <div style={{marginLeft: '5px'}}>
                {shippingSections}
              </div>
            </div>
          </div>
        )}
        {/* {total && (
          <div className="order-summary--section">
            <div className="order-summary--grand-total">
              TOTAL: <Currency amount={total} />
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default OrderSummary;
