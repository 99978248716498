import React, { Component } from "react";
import PropTypes from "prop-types";
import * as ImmutablePropTypes from "react-immutable-proptypes";

import { routeCreators } from "../../lib/routes";
import MainContent from "../../components/MainContent/MainContent";
import Currency from "../../components/Formatters/Currency";
import Block from "../../components/Block/Block";
import Modal from "../../components/Modal/Modal";
//import PayPalButton from "../../components/PayPalButton/PayPalButton";
import { PayPalButton } from "react-paypal-button-v2";
import Button from "../../components/Button/Button";
import StripePaymentForm from "../../components/StripePaymentForm/StripePaymentForm";
import StripePaymentRequestButton from "../../components/StripePaymentRequestButton/StripePaymentRequestButton";
import Header from "../../components/Header/Header";
import FullScreenLoader from "../../components/FullScreenLoader/FullScreenLoader";
import SweetAlert from "../../components/SweetAlert/SweetAlert";
// eslint-disable-next-line
import currencyFormatter from "currency-formatter";

import "./PrePay.scss";

class PrePay extends Component {
  static propTypes = {
    balance: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    topupOptions: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    canMakeStripePaymentRequest: PropTypes.bool,
    paymentRequest: PropTypes.object,
    onPayPalAuthorization: PropTypes.func,
    onStripePaymentFormSubmit: PropTypes.func,
    onSwitchToCurrency: PropTypes.func,
    isPayingForTopup: PropTypes.bool,
    onStripePaymentFormConfirm: PropTypes.func,
    onPaymentComplete: PropTypes.func,
    onPaymentError: PropTypes.func
  };

  static defaultProps = {};

  state = {
    isStripePaymentFormVisible: null,
    selectedTopupOption: null,
    alert: null,
    isPayPalScriptLoaded: false,
    hasSuccessfulPayPalPayment: false,
    successfulPayPalPaymentId: null
  };

  componentDidMount() {
    this.loadPayPalScript();
  }

  loadPayPalScript = () => {
    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${payPalClientID}&currency=${this.props.currency}&disable-funding=credit,card,sofort`;
    //script.async = true;
    script.addEventListener("load", () => {
      console.log("PayPal script loaded");
      this.setState({isPayPalScriptLoaded : true})
    });
    document.body.appendChild(script);
  }

  clearAlert = () => {
    this.setState({
      alert: null,
    });
  };

  showSuccessConfirmation = () => {
    this.setState({
      alert: {
        type: "success",
        title: "Postcard Credit Purchased",
        text: "Your payment was successful",
        showCancelButton: false,
        onConfirm: this.clearAlert,
      },
    });
  };

  showErrorAlert = err => {
    this.setState({
      alert: {
        type: "error",
        title: "Problem with payment",
        text:
          "There was a problem processing your payment, please contact us if the problem persists.",
        showCancelButton: false,
        onConfirm: this.clearAlert,
      },
    });
  };

  handleSelectTopupOption = topupOption => {
    this.props.onSelectTopupOption(topupOption);
  };

  openStripePaymentFormModal = selectedTopupOption => {
    this.setState({
      isStripePaymentFormVisible: true,
      selectedTopupOption: selectedTopupOption,
    });
  };

  closeStripePaymentFormModal = () => {
    this.setState({
      isStripePaymentFormVisible: false,
      selectedTopupOption: null,
    });
  };

  handleStripePaymentFormSubmit = async ({ result, saveCardDetails }) => {
    try {
      await this.props.onStripePaymentFormSubmit({ result, saveCardDetails });
      this.showSuccessConfirmation();
    } catch (err) {
      this.showErrorAlert(err);
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: false,
      });
    }
  };

  handleConfirmStripePayment = async (result) => {
    try {
      const response = await this.props.handleConfirmStripePayment(result);
      if (response.error){
        this.showErrorAlert(response.error);
        // this.setState({
        //   alert: {
        //     type: 'error',
        //     title: 'Payment Error',
        //     text: "Something went wrong with your payment, Please try again later.",
        //     showCancelButton: false,
        //     confirmButtonText: 'OK',
        //     onConfirm: this.clearAlert
        //   }
        // });
      } else {
        console.log(response);
        return response
      }
    } catch (err) {
      console.warn('Error while confirming with Stripe:', err);
      return err;
    } finally {
    }

    // try {
    //   //await this.props.onStripePaymentFormConfirm();
    //   this.showSuccessConfirmation();
    // } catch (err) {
    //   this.showErrorAlert(err);
    //   console.warn("Error while paying for topup with stripe:", err);
    // } finally {
    //   this.setState({
    //     isStripePaymentFormVisible: false,
    //   });
    // }
  };

  handlePaymentError = async (errorMessage) => {
    try {
      //await this.props.onStripePaymentFormError();
      //this.showSuccessConfirmation();
      await this.props.onStripePaymentError(errorMessage)
      this.showErrorAlert(errorMessage);
    } catch (err) {
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: true,
      });
    }
  };

  handlePaymentComplete = async () => {
    try {
      await this.props.onStripePaymentComplete()
      this.showSuccessConfirmation();
    } catch (err) {
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: false,
      });
    }
  };

  handlePayWithSavedCard = async () => {
    try {
      await this.props.onPayWithSavedCard();
      this.showSuccessConfirmation();
    } catch (err) {
      this.showErrorAlert(err);
      console.warn("Error while paying for topup with stripe:", err);
    } finally {
      this.setState({
        isStripePaymentFormVisible: false,
      });
    }
  };

  handlePaypalAuthorization = async response => {
    console.log("Handling PayPal authorization");

    this.setState({
      successfulPayPalPaymentId: response.id,
      hasSuccessfulPayPalPayment: response.status === "COMPLETED"
    })

    try {
      await this.props.onPayPalAuthorization(response);
      console.log("Done onPayPalAuthorization");
      this.showSuccessConfirmation();
    } catch (err) {
      console.log("Error handling Paypal auth", err);
      this.showErrorAlert(err);
    } finally {
      console.log("Done");
    }
  };

  render() {
    const { balance, topupOptions } = this.props;
    // eslint-disable-next-line
    let totalAmount = null;

    if (this.props.selectedTopupOption) {
      totalAmount =
        this.props.selectedTopupOption.get("cost") +
        this.props.selectedTopupOption.get("free_value");
    }

    const alert = (
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />
    );

    const isSandbox = process.env.REACT_APP_ENVIRONMENT !== "production";
    // eslint-disable-next-line
    const payPalClientID = isSandbox
      ? process.env.REACT_APP_PAYPAL_SANDBOX_API_KEY
      : process.env.REACT_APP_PAYPAL_PRODUCTION_API_KEY;

    const paymentModeSelectionModal = (
      <Modal
        key="payment-mode-selection"
        isOpen={Boolean(this.props.selectedTopupOption)}
        onClose={this.props.onClearSelectedTopupOption}
        padded
      >
        {this.props.selectedTopupOption && (
          <div>
            <p>
              Credit your account with{" "}
              <Currency amount={this.props.selectedTopupOption.get("cost")} /> and receive{" "}
              <Currency amount={this.props.selectedTopupOption.get("free_value")} /> of free Postcard credit.
            </p>
            <p>Please choose a payment method:</p>
            <Button
              block
              theme="dark-blue"
              label="Pay with Credit/Debit Card"
              onClick={() => this.openStripePaymentFormModal(this.props.selectedTopupOption)}
            />
            {this.props.canMakeStripePaymentRequest && (
              <StripePaymentRequestButton paymentRequest={this.props.paymentRequest} padded />
            )}
            {/*<PayPalButton
              amount={this.props.selectedTopupOption.get("cost").toString()}
              options={{
                debug: isSandbox,
                clientId: payPalClientID,
                currency: this.props.currency,
              }}
              style={{ color: "blue", label: "pay", tagline: false }}
              onSuccess={(details, data) => {
                this.handlePaypalAuthorization(details);
              }}
            />*/}
            {/* <PayPalButton
              currency={this.props.currency}
              amount={this.props.selectedTopupOption.get("cost")}
              onAuthorize={this.handlePaypalAuthorization}
              disabled={this.props.isPayingForTopup}
              description={`${currencyFormatter.format(totalAmount, {
                code: this.props.currency,
              })} of PostSnap Prepay Credit`}
            />
             */}

            {(!this.props.isPayingForTopup && this.state.hasSuccessfulPayPalPayment && this.state.successfulPayPalPaymentId) && (
              <Button
                block
                priority="secondary"
                label="Re-confirm PayPal payment"
                onClick={() => this.props.onPayPalAuthorization({id: this.state.successfulPayPalPaymentId})}
              />
            )}
            {(!this.props.isPayingForTopup && this.state.isPayPalScriptLoaded && !this.state.hasSuccessfulPayPalPayment) && (
              <PayPalButton
                amount={this.props.selectedTopupOption.get("cost")}
                shippingPreference="NO_SHIPPING"
                currency={this.props.currency}
                options={{
                  debug: isSandbox,
                  clientId: payPalClientID,
                  currency: this.props.currency,
                  disableFunding: 'card,credit'
                }}
                style={
                  {
                    size: 'responsive',
                    color: "blue",
                    label: "pay",
                    tagline: false,
                    height: 44,
                  }
                }
                onSuccess={(details, data) => {
                  console.log("Transaction completed by", details.payer.name.given_name);
                  this.handlePaypalAuthorization(details);
                }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        description: `PostSnap Prepay Credit`, //127 chars
                        payment_descriptor: 'PostSnap', // 22 chars
                        amount: {
                          currency_code: this.props.currency,
                          value: this.props.selectedTopupOption.get("cost"),
                        },
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    }
                  });
                }}
                onError={(error) => console.log("PayPal Error", error)}
                catchError={(error) => console.log("Caught PayPal Error", error)}
                onApprove={(data, actions) => {
                  console.log("onApprove Data", data);
                  return actions.order.capture().then(details => {
                    console.log('data',data);
                    console.log('details',details);
                  });
                }}
                onCancel={() => console.log("The payment was cancelled")}
              />
            )}
          </div>
        )}
        <FullScreenLoader
          key="loader"
          message="Please wait while we process your payment"
          isVisible={this.props.isPayingForTopup}
        />
      </Modal>
    );

    const stripePaymentFormModal = (
      <Modal
        key="stripe-credit-card-payment-modal"
        padded
        isOpen={this.state.isStripePaymentFormVisible}
        onClose={this.closeStripePaymentFormModal}
        title="Card Payment"
        leftAction={
          <Button
            theme="muted"
            priority="tertiary"
            label="Cancel"
            onClick={this.closeStripePaymentFormModal}
          />
        }
      >
        <StripePaymentForm
          onSubmit={this.handleStripePaymentFormSubmit}
          onPayWithSavedCard={this.handlePayWithSavedCard}
          confirmStripePayment={this.handleConfirmStripePayment}
          onPaymentComplete={this.handlePaymentComplete}
          onPaymentError={this.handlePaymentError}
          isPaying={this.props.isPayingForTopup}
          isPayingWithSavedCard={this.props.isPayingForTopupWithSavedCard}
          currency={this.props.currency}
          enableSavedCard={false}
          amount={this.state.selectedTopupOption && this.state.selectedTopupOption.get("cost")}
        />
      </Modal>
    );

    const isNotInCorrectRegion = this.props.currency !== this.props.balance.currency;

    let mainContent;

    if (isNotInCorrectRegion) {
      mainContent = (
        <MainContent padded key="main-content">
          <Header
            title="Postcard Credit"
            leftAction={
              <Button
                label="Back"
                link={routeCreators.account()}
                priority="tertiary"
                theme="muted"
              />
            }
          />
          <div className="text-center">
            <p>
              You can only purchase postcard credit in the region your account was created in (
              {this.props.balance.currency})
            </p>
            <Button
              block
              label={`Switch to ${this.props.balance.currency}`}
              onClick={() => this.props.onSwitchToCurrency(this.props.balance.currency)}
            />
          </div>
        </MainContent>
      );
    } else {
      mainContent = (
        <MainContent scrollable padded={false} key="main-content">
          <Header
            title="Postcard Credit"
            rightAction={
              <Button
                label="History"
                link={routeCreators.topUpHistory()}
                priority="tertiary"
                theme="muted"
              />
            }
            leftAction={
              <Button
                label="Back"
                link={routeCreators.account()}
                priority="tertiary"
                theme="muted"
              />
            }
          />
          <div className="prepay-banner">
            <div className="prepay-banner__title">Your Postcard Credit Balance is:</div>
            <div className="prepay-banner__balance">
              <strong>
                <Currency amount={balance.amount} />
              </strong>
            </div>
          </div>
          <ul style={{ marginLeft: "0" }}>
            {topupOptions.sort((a, b) => a.get("cost") - b.get("cost")).map(topup => (
              <Block
                key={topup.get("id")}
                hasRightArrow
                onClick={() => this.handleSelectTopupOption(topup)}
              >
                <span style={{fontSize: '14px'}}>Top up <Currency amount={topup.get("cost")} /> and get{" "}
                  <Currency amount={topup.get("free_value")} /> free
                </span>
                <br/>
                <span style={{fontSize: '12px'}}>{topup.get('description')}</span>
                
              </Block>
            ))}
          </ul>
          <p style={{ fontSize: "12px",textAlign: 'center', maxWidth: '500px', margin: '0 auto', lineHeight: '20px', padding: '10px 20px' }}>
            You can spend Postcard Credit on our standard and large postcards <strong>only</strong>. Postcard Credit may not be used to pay for any other products. You'll have 1 year to spend any Postcard Credit you buy.
          </p>
          {/* <Footer floating padded>
            <p style={{ fontSize: "12px" }}>
              You can spend Postcard Credit on our standard and large postcards only. Postcard Credit may not be used to pay for any other products. You'll have 1 year to spend any Postcard Credit you buy.
            </p>
          </Footer> */}
        </MainContent>
      );
    }

    return [alert, paymentModeSelectionModal, stripePaymentFormModal, mainContent];
  }
}

export default PrePay;
