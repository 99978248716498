import React from "react";
import Form from "../../components/Form/Form";
import MainContent from "../../components/MainContent/MainContent";

import Button from "../../components/Button/Button";

import { Modal } from "antd";

import './ConvertGuestAccount.scss';

class PasswordWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.formData,
      passwordVisible: false
    };
  }

  togglePasswordVisiblity() {
    this.setState({
      passwordVisible: !this.state.passwordVisible
    }, () => {
      this.passwordInput.focus();
    });
  }

  render() {
    const {passwordVisible} = this.state;
    return (
      <div className="show-hide-password-wrapper">
        <input
          ref={(input) => { this.passwordInput = input; }} 
          type={passwordVisible ? "text" : "password"}
          className='form-control'
          value={this.props.value}
          required={this.props.required}
          onChange={(event) => this.props.onChange(event.target.value)} />
          <span
            className="show-hide-password--btn"
            onClick={() => this.togglePasswordVisiblity()}>
              {passwordVisible ? 'HIDE' : 'SHOW'}
          </span>
      </div>
    );
  }
}

const convertAccountSchema = {
  showErrorList: false,
  schema: {
    type: "object",
    required: ["first_name", "new_password"],
    properties: {
      email: { type: "string", title: "Email" },
      first_name: { type: "string", title: "First Name" },
      last_name: { type: "string", title: "Last Name" },
      new_password: {
        type: "string",
        title: "Create a Password",
        minLength: 7,
      },
      mobile: { type: "string", title: "Mobile" },
    },
  },
  uiSchema: {
    email: {
      "ui:widget": "email",
      "ui:disabled": true,
    },
    new_password: {
      "ui:widget": PasswordWidget,
      "ui:help": "(Must be 7 characters inc. at least one number.)",
      "ui:autocomplete": "new-password"
    },
    last_name:{
      "ui:autocomplete": "off"
    },
    mobile: {
      "ui:help": "So that we can contact you quickly if there are any problems with your orders.",
    },
  }
};

class ConvertGuestAccount extends React.Component {
  state = {
    formData: {
      email: this.props.email
    },
    isLoadingRequest: false,
    modalVisible: false,
  };

  handleFormSubmit = form => {
    this.setState(
      {
        isLoadingRequest: true,
      },
      () => {
        this.props.onSubmit(form.formData);
        this.setState({
          isLoadingRequest: false,
        });
      }
    );
  };

  handleFormChange = form => {
    this.setState({
      formData: form.formData,
    });
  };

  showBenefitsModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = e => {
    this.setState({
      modalVisible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false,
    });
  };


  render() {
    return (
      <MainContent centerContent padded>
        <div className="convert-account">
          <h2 className="text-center" style={{fontSize: '18px', fontWeight: '400'}}>
            Convert Your Guest Account
          </h2>
          <p style={{fontSize: '12px'}}>
            Get all the benefits of being a PostSnap customer by converting your guest account. All we need are a few details to complete your account
          </p>
          
          <Button
            block
            type="submit"
            priority="secondary"
            label="What are the benefits?"
            onClick={this.showBenefitsModal}
          />
          <Modal
            title={<span style={{fontSize: '16px', fontWeight: '400'}}>Customer Account Benefits</span>}
            visible={this.state.modalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <p>
              When upgrading to a customer account, you can enjoy the following benefits:
            </p>
            <ul>
              <li>You have the option to buy Postcard Credit to save money and speed up checkout.</li>
              <li>You can view your order history and resend orders you have sent previously.</li>
              <li>You can update your personal details such as name and email etc.</li>
              <li>You are given a dedicated PostSnap address book to store your frequently used addresses.</li>
            </ul>
          </Modal>
          <Form
            {...convertAccountSchema}
            formData={this.state.formData}
            onChange={this.handleFormChange}
            onSubmit={this.handleFormSubmit}
            autocomplete={"off"}
          >
            <Button
              block
              theme="dark-blue"
              type="submit"
              label="Convert My Guest Account"
              loadingLabel="Converting..."
              loading={this.state.isLoadingRequest}
              disabled={this.state.isLoadingRequest}
            />
          </Form>
        </div>
      </MainContent>
    );
  }
}

ConvertGuestAccount.propTypes = {};

ConvertGuestAccount.defaultProps = {};

export default ConvertGuestAccount;
