import { Modal as AntModal } from "antd";
import { PRODUCT_TYPE_IDS } from "../../data/products";
import * as designsData from "../../data/designs";
import postsnapApi from "../../lib/apis/postsnap";
import transformDesignLayers from "../../lib/transform-design-layers";
import { fromJS } from "immutable";
import PAGES_BY_PRODUCT_TYPE from "../../constants/pages-by-product-type";
import moment from "moment";
import { BORDER_WIDTHS } from "./components/TilePreview/tilePreviewCalculator";
import { FRAME_COLORS } from "../../store/ducks/ui-photo-tiles";
import * as STORAGE_KEYS from "../../constants/storage-keys";
import Cropper from "cropperjs";
import getMaxResolutionForDimensions from "../../lib/get-max-resolution-for-dimensions";

const { confirm } = AntModal;

export function findNextTileToEdit(tiles) {
  return tiles.findIndex(tile => !tile?.done);
}

export function getRecentlyUploaded() {
  return JSON.parse(localStorage.getItem("recentlyUploadedFiles"));
}

export function isLastTileEdited(tiles) {
  return tiles.filter(tile => !tile.done).length <= 1;
}

export function confirmBeforeNavAway() {
  confirm({
    title: "",
    content: `Are you sure you want to cancel? This will remove the unsaved item.`,
    okText: `Continue Editing`,
    cancelText: "Cancel",
    icon: null,
    onCancel: () => {
      window.location.href = "/";
      localStorage.removeItem(STORAGE_KEYS.WIP_PREBAG);
    },
    okButtonProps: {
      style: { backgroundColor: "#ffffff", color: "#000000", borderColor: "lightgray" },
    },
    cancelButtonProps: {
      style: { backgroundColor: "red", color: "#ffffff", borderColor: "red" },
    },
  });
}
export const TILE_EDITOR_MENUS = {
  CROP: "CROP",
  EFFECTS: "EFFECTS",
  COLOURS: "COLOURS",
  BORDER: "BORDER",
};
export const getItemDataSinglePack = async (tile, product) => {
  const productTypeId = PRODUCT_TYPE_IDS.PHOTO_TILE;
  const firstDesignForProductId = designsData.designsByProductId.get(product.id).first();
  const designId = firstDesignForProductId.get("id");

  // TODO: this should be only called once
  const designDetail = await postsnapApi.designs.getDetails(designId);
  const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

  const borderPrint = tile.border
    ? {
        width: BORDER_WIDTHS[tile.border.thickness],
        colour: tile.border.color ?? "#FFFFFF",
      }
    : undefined;

  const filter = tile.bnw ? { bnw: true } : undefined;

  const frameColor = product.photo_tile_framed ? getFrameColorFromUrl() : null;

  let itemData = fromJS({
    productDimensions: {
      width: product.width,
      height: product.height,
      dpi: product.dpi,
      bleed: {
        top: parseFloat(product.bleed_t),
        bottom: parseFloat(product.bleed_b),
        left: parseFloat(product.bleed_l),
        right: parseFloat(product.bleed_r),
      },
    },
    productTypeId,
    productId: product.id,
    designId,
    quantity: tile.quantity ?? 1,
    weight: product.weight,
    pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
    layers,
    product_options: {
      frameColor,
    }, // add here the photo tile specific options
    postDate: moment(),
    duplicateAlertShown: false,
    isBorderPrint: true,
  });

  let cropData = null;
  if (tile.crop) {
    cropData = {
      height: tile.crop.height,
      width: tile.crop.width,
      x: tile.crop.x,
      y: tile.crop.y,
      rotate: tile.crop.rotate,
      scaleX: tile.crop.scaleX,
      scaleY: tile.crop.scaleY,
      transform: tile.crop.transform,
      // omit croppedUrl
    };
  }

  const page0Index = itemData.get("layers").findIndex(layer => layer.get("page") === 0);
  if (page0Index === -1) {
    throw new Error("No page 0 found in itemData.layers");
  }

  itemData = itemData.setIn(["layers", page0Index, "config", "layout", 0, "image"], {
    cropData: cropData,
    borderPrint,
    filter,
    src: {
      highResUrl: tile?.image?.highResUrl,
      lowResUrl: tile?.image?.lowResUrl,
      uploadcareUuid: tile?.image?.uuid,
    },
  });

  return itemData;
};
export const getItemDataForPack = async (tileItems, product) => {
  const productTypeId = PRODUCT_TYPE_IDS.PHOTO_TILE;
  // const productMap = productsByProductTypeId
  //   .get(productTypeId)
  //   .find(p => p.get("id") === product.id);
  const firstDesignForProductId = designsData.designsByProductId.get(product.id).first();
  const designId = firstDesignForProductId.get("id");

  // The following request to Designs return cached results so no need to worry about multiple requests
  const designDetail = await postsnapApi.designs.getDetails(designId);
  const layers = transformDesignLayers(fromJS(designDetail.data.data.layers), productTypeId);

  const frameColor = product.photo_tile_framed ? getFrameColorFromUrl() : null;

  let itemData = fromJS({
    productDimensions: {
      width: product.width,
      height: product.height,
      dpi: product.dpi,
      bleed: {
        top: parseFloat(product.bleed_t),
        bottom: parseFloat(product.bleed_b),
        left: parseFloat(product.bleed_l),
        right: parseFloat(product.bleed_r),
      },
    },
    productTypeId,
    productId: product.id,
    designId,
    quantity: 1, // Always a single product no matter the number of tiles in the pack
    weight: product.weight,
    pages: PAGES_BY_PRODUCT_TYPE[productTypeId],
    layers,
    product_options: {
      frameColor,
    }, // add here the photo tile specific options
    postDate: moment(),
    duplicateAlertShown: false,
    isBorderPrint: true,
  });

  tileItems.forEach((tile, index) => {
    const borderPrint = tile.border
      ? {
          width: BORDER_WIDTHS[tile.border.thickness],
          colour: tile.border.color ?? "#FFFFFF", // MUST MATCH retro-colours.json
        }
      : undefined;

    const filter = tile.bnw ? { bnw: true } : undefined;
    let cropData = null;
    if (tile.crop) {
      cropData = {
        height: tile.crop.height,
        width: tile.crop.width,
        x: tile.crop.x,
        y: tile.crop.y,
        rotate: tile.crop.rotate,
        scaleX: tile.crop.scaleX,
        scaleY: tile.crop.scaleY,
        transform: tile.crop.transform,
        // omit croppedUrl
      };
    }

    // put the image data in the corresponding page
    const layerIndex = layers.findIndex(
      layer => layer.get("page") === index && layer.get("type") === "Layer::Photo"
    );

    itemData = itemData.setIn(["layers", layerIndex, "config", "layout", 0, "image"], {
      cropData: cropData,
      borderPrint,
      filter,
      ...(tile.image && {
        src: {
          highResUrl: tile.image.highResUrl,
          lowResUrl: tile.image.lowResUrl,
          uploadcareUuid: tile.image.uuid,
        },
      }),
    });
  });

  return itemData;
};

export function getFrameColorFromUrl(prop = "frame", defaultValue = FRAME_COLORS.WHITE) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(prop) ?? defaultValue;
}

export function getTileWidthFromContainerWidth(wrapperWidth) {
  return Math.min(wrapperWidth * 0.7, 370);
}

export async function getCroppedImageAsDataUrl(image, cropData, imageWidth, imageHeight) {
  return new Promise((resolve, reject) => {
    const imgElement = new Image();
    const divElement = document.createElement("div");
    divElement.appendChild(imgElement);
    // divElement.style.width = "100px";
    // divElement.style.height = "100px";

    let effectiveCropData = cropData;
    // If it is null then it means it was a landscape image, cropped at the its center
    // thus, we need to provide a proper cropData for the cropper
    if (effectiveCropData === null) {
      effectiveCropData = getDefaultCropDataLandscapeImage({
        width: imageWidth,
        height: imageHeight,
      });
    }

    imgElement.onload = () => {
      const cropper = new Cropper(imgElement, {
        data: effectiveCropData,
        autoCrop: true,
        autoCropArea: 1,
        aspectRatio: 1,
        ready: () => {
          const canvas = cropper.getCroppedCanvas();
          resolve(canvas.toDataURL() ?? null);
        },
      });
    };
    imgElement.onerror = reject; // Reject the promise if there's an error
    imgElement.src = image;
  });
}

// Stores the image dataURL for a specific image and and cropData, so it does not have to be fetched
// over the internet again.
export const inMemoryImageCache = {
  cache: new Map(),

  addItem: function(imageUrl, cropData, imageDataUrl) {
    this.cache.set(imageUrl, { cropData: JSON.stringify(cropData), imageDataUrl });
  },
  getItem: function(imageUrl, cropData) {
    const cached = this.cache.get(imageUrl);
    if (!cached) {
      return null;
    }
    if (JSON.stringify(cropData) !== cached.cropData) {
      return null;
    }

    return cached.imageDataUrl;
  },
};
export const getKey = (imageUrl, cropData) => {
  return `${imageUrl}-${JSON.stringify(cropData)}`;
};

export const getDefaultCropDataLandscapeImage = ({ width, height }) => {
  let heightRatio = width / height;

  const cropBoxSize = height;
  const x = (width - cropBoxSize) / 2;

  return {
    x: x,
    y: 0,
    width: cropBoxSize,
    height: cropBoxSize,
    rotate: 0,
    scaleX: 1,
    scaleY: 1,
  };
};
