import { WINDOW } from "./environment"
import { store } from "../index";
import { selectors as authSelectors } from "../store/ducks/auth";
import { selectors as basketSelectors } from "../store/ducks/basket";

const gtmEvent = async ({
  event,
  eventType = "",
  category = "",
  action = "",
  additionalProps = {}
}) => {
  try {
    if (WINDOW) {
      if (!WINDOW.dataLayer) WINDOW.dataLayer = []
    
      const state = store.getState();
      const isSignedIn = Boolean(authSelectors.getUser(state));
      const currency = basketSelectors.getCurrency(state);
      const basketCount = basketSelectors.getTotalQuantity(state);

      let sessionData = {
        'isSignedIn': isSignedIn,
        'currency': currency,
        'basketCount': basketCount,
      }

      let newData = {
        event: event || "webAppStage",
        eventType,
        category,
        action,
        sessionData,
        ...additionalProps
      }

      if(window.dataLayer.some((dle) => dle.event === "webAppStage" && dle.product)){
        console.log("Previous dle with product...")
        const previousEventsWithProducts = window.dataLayer.filter((dle) => dle.event === "webAppStage" && dle.product)
        const lastEventWithProduct = previousEventsWithProducts.slice(-1)[0]

        if (!newData.product && lastEventWithProduct){
          newData.product = lastEventWithProduct.product
        }
      }
      if(newData.event === "webAppStage") {
        console.log(`%c ${newData.event} => Stage:${newData.stage} | Product:${newData.product}`, 'background: #4C5666; color: white');
      }

      if(newData.event === "webAppEvent") {
        console.log(`%c ${newData.event} => Event:${newData.eventName} | Product:${newData.product}`, 'background: #FD5C63; color: white');
      }
      
      console.log(newData);

      if (process.env.REACT_APP_ENVIRONMENT === "production"){
        WINDOW.dataLayer.push(newData)
      }
    }
  } catch (e) {
    console.log("Failed to send to GTM", e);
  }
}

//Useful for debugging: https://gist.github.com/smhmic/82a180529a036c1cb0fd37c41d06f7ce

export default gtmEvent