import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import CheckoutContactDetails from "./CheckoutContactDetails";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import { selectors as basketSelectors, actions as basketActions } from "../../../store/ducks/basket";
import { selectors as authSelectors, actions as authActions } from "../../../store/ducks/auth";
import { actions as uiActions } from "../../../store/ducks/ui";
import {
  sharedShippingAddress,
  requiresMobile
} from "../../../data/products"
import KlaviyoTracking from "../../../utils/klaviyo";
import gtmEvent from "../../../utils/gtm";

class CheckoutContactDetailsContainer extends React.Component {

  state = {
    alert: null,
  };

  constructor(props) {
    super(props);
    const mode = this.props.location?.state?.mode || "new"
    this.state = {
      mode: mode
    };
  }

  componentDidMount(){
    if(this.props.items.size === 0){
      this.props.goToBasket()
    }

    if(this.props.isSignedIn && this.state.mode !== "edit"){
      // Signed in and not edit mode, skip this step
      KlaviyoTracking.trackCheckoutStarted(this.props.items, this.props.currency, this.props.orderSummary)
      this.props.setHasCompletedCheckoutSteps(true)
      this.props.goToBasket()
    }

    if (this.props.checkoutContactDetails && this.state.mode !== "edit"){
      // TODO: extract this logic

      const existingContactDataIsComplete = Object.keys(this.props.checkoutContactDetails.toJS()).includes("email")
      if (existingContactDataIsComplete && this.props.hasCompletedCheckoutSteps){
        this.props.goToBasket()
      }
    }

    gtmEvent({event: "webAppStage", additionalProps: {stage: 'Checkout - Contact Details'}})
  }

  goToShippingOptionsOrGuestOrCustomer = () => {
    const checkoutShippingNeeded = this.props.items.some(item => sharedShippingAddress(item.get('productTypeId')))
    if (checkoutShippingNeeded){
      this.props.goToCheckoutShippingOptions()
    } else{
      this.props.goToGuestOrCustomer()
    }
  }

  handleBack = () => {
    if (this.state.mode === "edit"){
      this.props.goToBasket()
    } else{
      //this.props.goToCheckoutShippingOptions()
      this.goToShippingOptionsOrGuestOrCustomer()
    }
  }

  handleNext = () => {
    this.props.goToBasket()
  }

  trackCheckoutStarted = () => {
    try{
      KlaviyoTracking.trackCheckoutStarted(this.props.items, this.props.currency, this.props.orderSummary)
    } catch (err) {
      console.warn("Failed to send Klaviyo Started Checkout", err.message);
    }
  }

  waitUntil = async (condition) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (!condition()) {
          return;
        }
        clearInterval(interval);
        resolve();
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        reject('Error waiting');
      }, 5000);
    });
  };

  handleSaveContactDetails = async (details) => {
    
    this.props.onSaveCheckoutContactDetails(details)
    if (true){ //details.marketing_opt_in
      //const hasSomePrints = this.props.items.some(item =>  PRODUCT_TYPE_IDS.PHOTO_PRINT === item.get('productTypeId'))
      //const isGBP = this.props.currency === "GBP"
      const trackUser = true //hasSomePrints && isGBP
      if (trackUser){
        await KlaviyoTracking.identifyUser(details.email, "", "", !this.props.isSignedIn)
        //console.log("Identfied user", window._learnq.isIdentified())
        await this.waitUntil(() => window._learnq.isIdentified() === true).then(() => {
          this.trackCheckoutStarted()
        }).catch((err) => console.warn(err))
        console.log("Identfied user", window._learnq.isIdentified())
      }      
    }

    if(this.props.hasChosenGuestRoute && !this.props.isSignedIn){
      // populate guest details
      this.props.setGuestCheckoutDetails(details)
    }

    // Complete checkout steps if we haven't already
    if(!this.props.hasCompletedCheckoutSteps){
      this.props.setHasCompletedCheckoutSteps(true)
    }

    this.props.goToBasket()
  }

  render() {

    const isEditing = this.state.mode === "edit"

    const checkoutContactDetails = this.props.isSignedIn ? {
      ...this.props.checkoutContactDetails.toJS(),
      email: this.props.user.get('email'),
      mobile: this.props.user.get('mobile'),
    } : this.props.checkoutContactDetails.toJS()

    const picCollage = this.props.items.some(item => item.get('picCollage'))
    const requiresMobileDetails = this.props.items.some(item => requiresMobile(item.get('productTypeId')))

    return [
      <Header
        key="top-header"
        theme="grey"
        title="Contact Details"
        leftAction={
          <Button theme="muted" priority="tertiary" label="Back" onClick={this.handleBack}/>
        }
        // rightAction={
        //   <Button priority="tertiary" label="Next" onClick={this.handleNext}/>
        // }
      />,
      <CheckoutContactDetails
        key="checkout-contact-details"
        items={this.props.items}
        currency={this.props.currency}
        isSignedIn={this.props.isSignedIn}
        hasChosenGuestRoute={this.props.hasChosenGuestRoute}
        checkoutContactDetails={checkoutContactDetails}
        onSaveContactDetails={this.handleSaveContactDetails}
        isEditingInIsolation={isEditing}
        picCollage={picCollage}
        requiresMobileDetails={requiresMobileDetails}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  guestToken: authSelectors.getGuestToken(state),
  hasGuestToken: Boolean(authSelectors.getGuestToken(state)),
  hasChosenGuestRoute: Boolean(authSelectors.hasChosenGuestRoute(state)),
  checkoutContactDetails: basketSelectors.getCheckoutContactDetails(state),
  checkoutShippingDetails: basketSelectors.getCheckoutShippingAddress(state),
  checkoutShippingOption: basketSelectors.getCheckoutShippingOption(state),
  hasCompletedCheckoutSteps: Boolean(basketSelectors.getHasCompletedCheckoutSteps(state)),
});

const mapDispatchToProps = dispatch => ({
  goToBasket: () => dispatch(push(routeCreators.basket())),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  goToGuestOrCustomer: () => dispatch(push(routeCreators.checkoutGuestOrCustomer())),
  onSaveCheckoutContactDetails: (contactDetails) => dispatch(basketActions.setCheckoutContactDetails(contactDetails)),
  goToCheckoutShippingOptions: () => dispatch(push(routeCreators.checkoutShippingOption())),
  setHasCompletedCheckoutSteps: (completedCheckoutSteps) => dispatch(basketActions.setHasCompletedCheckoutSteps(completedCheckoutSteps)),
  setGuestCheckoutDetails: details => dispatch(authActions.setGuestCheckoutDetails(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutContactDetailsContainer);
