import React, { Component, useState, useRef } from "react";
import PropTypes from "prop-types";
import debounce from 'lodash/debounce'; // Import debounce function from lodash

import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import MainContent from "../MainContent/MainContent";
import Footer from "../Footer/Footer";
import * as LAYER_TYPES from "../../constants/layer-types";
import colours from "../../pages/ThemeList/colours.json";
import retroColours from "../../pages/ThemeList/retro-colours.json";
import effects from "../../pages/ThemeList/effects.json";

import EditorFontOption from "../Editor/EditorFontOption";
import { CAPTION_TEXT_COLORS } from "../../constants/fill-options";

import {
  FONT_FAMILIES,
} from "../../constants/text-style-options";

import "./editPrintModal.scss";

import {
  Switch,
  Modal as AntModal,
  Tooltip
} from "antd";

import {
  CheckCircleFilled,
  InfoCircleFilled
} from '@ant-design/icons';

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgb(${r},${g},${b})`;
}

function ControlledInput({ text, textColour, textFont, onUpdate, maxCharacterCount }) {
  const [value, setValue] = useState(text);
  const handleChange = (e) => {
    const newValue = e.target.value.replace("\n", "");
    setValue(newValue);
    onUpdate(newValue);
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <textarea
      placeholder="Write text here or leave blank"
      value={value}
      onChange={handleChange}
      onPaste={handlePaste}
      style={{
        textAlign: 'center',
        color: textColour,
        fontFamily: textFont,
        background: textColour === 'rgb(255,255,255)' ? '#7f7f7f' : 'white',
        fontSize: '20px'
      }}
      maxLength={maxCharacterCount}              
    /> 
  )
}

class EditPrintModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isApplyAllWarningModalVisible: false,
    activeTool: "photo",
    saving: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const justOpened = prevProps.isOpen === false && this.props.isOpen === true
    if (justOpened){
      this.setState({activeTool: 'photo', saving: false})
    }
  }

  goToNext = () => {
    if (this.state.activeTool === "photo"){
      this.setState({activeTool: "frame"})
    } else if (this.state.activeTool === "frame"){
      this.setState({activeTool: "text"})
    } else if (this.state.activeTool === "text"){
      this.setState({
        saving: true
      },() => {
        this.props.onClose()
      })
      
    }
  }

  goToPrevious = () => {
    if (this.state.activeTool === "photo"){
      this.props.onClose()
    } else if (this.state.activeTool === "frame"){
      this.setState({activeTool: "photo"})
    } else if (this.state.activeTool === "text"){
      this.setState({activeTool: "frame"})
    }
  }

  setActiveToolTo = (tool) => {
    this.setState({
      activeTool: tool
    })
  }

  openCropper = (itemId) => {
    this.props.openCropper(itemId)
  }

  changeEffect = (effect) => {
    this.props.setEffect(effect)
    if (this.props.effectSetForAll){
      this.props.setEffectForAll(effect)
    }
  };

  changeEffectForAll = (checked, event, effect) => {
    this.props.setEffectSetForAll(checked)
    if (checked){
      this.props.setEffectForAll(effect)
    }
  }

  changeBackground = (background) => {
    this.props.setActiveBackground(background)
    if (this.props.backgroundSetForAll){
      this.props.setActiveBackgroundAll(background)
    }
  };

  applyBackgroundToAll = (checked, event, background) => {
    this.props.setBackgroundSetForAll(checked)
    if (checked){
      this.props.setActiveBackgroundAll(background)
    }
  }

  changeTextForAll = (checked) => {
    this.props.setTextSetForAll(checked)
    if (checked){
      this.props.setTextForAll()
    }
  }

  changeFontForAll = (checked) => {
    this.props.setFontSetForAll(checked)
    if (checked){
      this.props.setFontForAll()
    }
  }

  changeTextColourForAll = (checked) => {
    this.props.setTextColourSetForAll(checked)
    if (checked){
      this.props.setTextColourForAll()
    }
  }

  closeApplyAllWarning = () => {
    this.setState({
      isApplyAllWarningModalVisible: false
    })
  }

  proceedOnApplyAllWarning = () => {
    this.state.applyAllOnProceed()
    this.closeApplyAllWarning()
  }

  openApplyAllWarning = (checked, title, message, cancelBtn, proceedBtn, onProceed, onCancel) => {
    if (checked){
      this.setState({
        isApplyAllWarningModalVisible: true,
        applyAllWarningTitle: title,
        applyAllWarningMessage: message,
        applyAllCancelBtn: cancelBtn,
        applyAllProceedBtn: proceedBtn,
        applyAllOnProceed: onProceed,
        applyAllOnCancel: onCancel,
      })
    } else{
      onProceed()
    }
    
  }

  // updateText = (value) => {
  //   this.setState({
  //     currentText: value,
  //     isEditingText: true
  //   })
  // }

  // changeTextDebounced = debounce(async (value) => {
  //   console.log("value2", value);
  //   await this.changeText(value);
  // }, 300); // Adjust the delay as needed (in milliseconds)

  changeTextDebounced = debounce(async (value) => {
    await this.changeText(value);
  }, 300); // Adjust the delay as needed (in milliseconds)

  changeText = async (value) => {

    //console.log("value", value)
    //console.log("value replace", value.replace("\n", ""))
    let val = value.replace("\n", "")

    const indexOfTextLayer = this.props.item?.get("layers")
      .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    const itemConfig = this.props.item?.getIn(["layers", indexOfTextLayer, "config"]).toJS();

    let config = {
      text: val,
      color: itemConfig.color,
      font: itemConfig.font,
      size: itemConfig.size,
      captionText: false
    }
    this.setState({
      currentText: val,
      isEditingText: false
    }, async () => {
      await this.props.setText(config)
      if (this.props.textSetForAll){
        this.props.setTextForAll()
      }
    })
    
  };

  changeTextFont = async (font) => {
    // const indexOfTextLayer = this.props.item?.get("layers")
    //   .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    // const itemConfig = this.props.item?.getIn(["layers", indexOfTextLayer, "config"]).toJS();
    
    // let config = {
    //   text: itemConfig.text,
    //   color: itemConfig.color,
    //   font: font,
    //   size: itemConfig.size,
    //   captionText: false
    // }

    await this.props.setFont(font)
    if (this.props.fontSetForAll){
      this.props.setFontForAll()
    }
  };

  changeTextColour = async (colour) => {
    // const indexOfTextLayer = this.props.item?.get("layers")
    //   .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    // const itemConfig = this.props.item?.getIn(["layers", indexOfTextLayer, "config"]).toJS();
    // let config = {
    //   text: itemConfig.text,
    //   color: colour,
    //   font: itemConfig.font,
    //   size: itemConfig.size,
    //   captionText: false
    // }
    await this.props.setTextColour(colour)
    if (this.props.textColourSetForAll){
      this.props.setTextColourForAll()
    }
  };


  render() {
    
    const { item, items } = this.props;
    let selectedItemBackground = item?.toJS().layers.find(layer => layer.type === LAYER_TYPES.PHOTO)?.config.background;

    let selectedBackground = { "colour": retroColours[0] };
    let isEffect = false

    if (selectedItemBackground){
      selectedBackground = selectedItemBackground
    } else{
      if (this.props.selectedBackground){
        //selectedBackground = this.props.selectedBackground
      }
    }

    // if(this.props.selectedBackground){
    //   selectedBackground = this.props.selectedBackground
    // } else{
    //   selectedBackground = selectedItemBackground
    // }

    if (selectedBackground?.effect){
      isEffect = true
    }

    let text = ""
    let textFont = "Coustard"
    let textColour = "rgb(0,0,0)"
    let textSize = 40
    const indexOfTextLayer = item?.get("layers")
      .findIndex(layer => (layer.get("type") === LAYER_TYPES.TEXT && layer.get("id") !== "EXTRA_TEXT_LAYER" ));
    const itemConfig = item?.getIn(["layers", indexOfTextLayer, "config"]).toJS();
    text = itemConfig?.text
    textSize = itemConfig?.size
    //console.log("text", text)
    //console.log("state text", this.state.currentText)
    // if (this.state.isEditingText){
    //   text = this.state.currentText
    // }
    textFont = itemConfig?.font
    textColour = itemConfig?.color || "rgb(0,0,0)"

    let selectedFont = FONT_FAMILIES.find((font) => font.name === textFont);
    let maxCharacterCount = selectedFont?.characterLimit || 30
    //console.log("maxCharacterCount", maxCharacterCount)

    const effectsOn = item?.getIn(["product_options", "effects"])
    // const effectsOn = noItems ? false
    // : someItems && items[0].getIn(["product_options", "effects"])
    // ? true
    // : false

    let applyAllWarningModal = (
      <AntModal
        visible={this.state.isApplyAllWarningModalVisible}
        onCancel={() => this.closeApplyAllWarning()}
        title={<span style={{textAlign: 'center', display: 'block'}}>{this.state.applyAllWarningTitle}</span>}
        footer={null}
        >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
            <>
              <p>{this.state.applyAllWarningMessage}</p>
              <Button
                theme="dark-blue"
                priority={"primary"}
                label={this.state.applyAllProceedBtn}
                onClick={() => this.proceedOnApplyAllWarning()}
                block
              />
              <Button
                label={this.state.applyAllCancelBtn}
                onClick={() => this.closeApplyAllWarning()}
                block
                theme="dark-blue"
                priority="secondary"
                style={{border : '1px solid #4b5566'}}
              />
            </>
        </div>
      </AntModal>
    )

    return (
      <Modal
        isOpen={this.props.isOpen}
        animated={false}
        onClose={this.props.onClose}
        leftAction={<></>}
        title={"Personalise Your Print"}
        fullWidth={true}
        // rightAction={
        //   /<Button label="Done" priority="primary" theme="dark-blue" onClick={this.props.onClose} />
        // }
      >
        <MainContent scrollable padded>
          <div className="edit-print-modal-container">
            {applyAllWarningModal}
            {/* <h2 className="edit-print-modal-main-heading">Personalise Your Retro Print</h2> */}
            {item && (
              <div className="fixed-preview">
                <HtmlRenderer
                  key={`edit-render-${item.get('id')}`}
                  width={300}
                  isInteractive={false}
                  previewMode={true}
                  item={item.toJS()}
                  page={item.getIn(["pages", "front"])}
                  onClick={() => this.openCropper(item.get('id'))}
                  uniquekey={"edit-preview"}
                  lowResImages={true}
                />
              </div>
            )}
            {/* <p style={{textAlign:'center', marginTop: '20px', fontSize: '12px'}}>Tap your photo to crop, zoom or change</p> */}
            {this.state.activeTool === "photo" && (
              <div className="edit-section">
                <div className="edit-print-modal-button-section">
                  <h3 className="edit-print-modal-sub-heading">Edit Photo</h3>
                  {/* <h2 className="edit-print-modal-heading">Edit Photo</h2> */}
                  <Button
                    label="Crop, Zoom, Rotate or Change Photo"
                    priority="primary"
                    theme="dark-blue" onClick={() => this.openCropper(item.get('id'))}
                    style={{marginBottom: '0px', width: '100%', maxWidth: '430px'}}
                  />
                </div>
                <div className="edit-print-modal-section-divider"></div>
                <div className="edit-print-modal-title-container">
                  <h3 className="edit-print-modal-sub-heading">Photo Effect</h3>
                  {/* <div className="apply-all-container">
                    <span>Apply to all?</span>
                    <div className="apply-all-btn inline">
                      <Switch
                        style={{transform: 'scale(1.2)'}}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.props.effectSetForAll}
                        onChange={(checked, event) => this.openApplyAllWarning(
                          checked,
                          "Apply to all?",
                          "Are you sure you want to apply the selected photo effect to ALL retro prints in this order?",
                          "No",
                          "Yes - Apply Effect To All",
                          () => this.changeEffectForAll(checked, event, effectsOn),
                          this.closeApplyAllWarning
                          )
                        }
                      />   
                    </div>
                  </div> */}
                </div>
                
                <div className="edit-print-options">
                  <Button
                    style={{width: '100%', marginBottom: '0px', border: 'none', padding: '0px'}}
                    label={
                      <span>
                        Colour
                        {!effectsOn && (
                          <span style={{marginLeft: '10px'}}>
                            <CheckCircleFilled style={{ color: '#00C202', fontSize: '16px' }}/>
                          </span>
                        )}
                      </span>}
                    priority={!effectsOn ? "primary" : "secondary"}
                    theme="dark-blue"
                    onClick={() => this.changeEffect(false, item.get('id'))}
                  />
                  <Button
                    style={{width: '100%', marginBottom: '0px', border: 'none', padding: '0px'}}
                    label={
                    <span>
                      Black & White
                      {effectsOn && (
                        <span style={{marginLeft: '10px'}}>
                          <CheckCircleFilled style={{ color: '#00C202', fontSize: '16px' }}/>
                        </span>
                      )}
                    </span>}
                    priority={effectsOn ? "primary" : "secondary"}
                    theme="dark-blue"
                    onClick={() => this.changeEffect("black-and-white", item.get('id'))}
                  />
                </div>
              </div>
            )}
            {this.state.activeTool === "frame" && (
              <div className="edit-section">
                <div className="edit-print-modal-title-container">
                  <h3 className="edit-print-modal-sub-heading">Personalise Frame</h3>
                  {/* <div className="apply-all-container">
                    <span>Apply to all?</span>
                    <div className="apply-all-btn inline">
                      <Switch
                        style={{transform: 'scale(1.2)'}}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.props.backgroundSetForAll}
                        onChange={(checked, event) => this.openApplyAllWarning(
                          checked,
                          "Apply to all?",
                          "Are you sure you want to apply the selected frame to ALL retro prints in this order?",
                          "No",
                          "Yes - Apply Background To All",
                          () => this.applyBackgroundToAll(checked, event, selectedBackground),
                          this.closeApplyAllWarning
                          )
                        }
                      />      
                    </div>
                  </div> */}
                </div>
                {/* <h2 className="edit-print-modal-heading">Personalise Frame</h2> */}
                <h3 className="edit-print-modal-sub-heading">Colours</h3>
                <div className="edit-print-modal__slider">
                  {retroColours.map((colour, index) => {
                    return (
                      <div
                        onClick={() => this.changeBackground({colour: colour})}
                        className={`swatch-container retro-shape ${selectedBackground?.colour?.hex === colour.hex ? 'active' : ''}`}>
                        <div
                          className={`swatch ${colour.color === 'White' ? 'bordered' : ''}`}
                          style={{ backgroundColor: colour.hex, color: colour?.textColor }}
                        >
                          {/* <span style={{color:colour.textColor}}>{colour.color}</span> */}
                        </div>
                      </div>
                  )})}
                </div>
                <h3 className="edit-print-modal-sub-heading">
                  Foil Effects
                  <span style={{marginLeft: '5px', fontSize: '20px'}}>
                    <Tooltip title="Note: These are foil effects printed on photo paper and not foil paper" color={""} placement="topRight" key="foil">
                      <InfoCircleFilled style={{ fontSize: '20px', color: '#FF9800' }}/>
                    </Tooltip>
                  </span>
                </h3>
                <div className="edit-print-modal__slider">
                  {effects.filter(effect => effect.type === "Foil").map((effect, index) => (
                    <div
                      key={index}
                      onClick={() => this.changeBackground({effect: {name: effect.name, url: effect.link, id: effect.id}})}
                      className={`swatch-container retro-shape ${isEffect ? selectedBackground.effect.url === effect.link ? 'active' : '' : ''}`}
                    >
                      <div
                        className="swatch"
                        style={{ background: `url(${effect.link.replace(".jpeg", "-s.jpeg")})`, backgroundSize: 'cover' }}
                      ></div>
                    </div>
                  ))}
                </div>
                <h3 className="edit-print-modal-sub-heading">
                  Glitter Effects
                  <span style={{marginLeft: '5px', fontSize: '20px'}}>
                    <Tooltip title="Note: These are glitter effects printed on photo paper and not glitter paper" color={""} placement="topRight" key="glitter">
                      <InfoCircleFilled style={{ fontSize: '20px', color: '#FF9800' }}/>
                    </Tooltip>
                  </span>
                </h3>
                <div className="edit-print-modal__slider">
                  {effects.filter(effect => effect.type === "Glitter").map((effect, index) => (
                    <div
                      key={index}
                      onClick={() => this.changeBackground({effect: {name: effect.name, url: effect.link, id: effect.id}})}
                      className={`swatch-container retro-shape ${isEffect ? selectedBackground.effect.url === effect.link ? 'active' : '' : ''}`}
                    >
                      <div
                        className="swatch"
                        style={{ background: `url(${effect.link.replace(".jpeg", "-s.jpeg")})`, backgroundSize: 'cover' }}
                      ></div>
                    </div>
                  ))}
                </div>
                <h3 className="edit-print-modal-sub-heading">
                  Marble Effects
                  <span style={{marginLeft: '5px', fontSize: '20px'}}>
                    <Tooltip title="Note: These are marble effects printed on photo paper and not marble paper" color={""} placement="topRight" key="marble">
                      <InfoCircleFilled style={{ fontSize: '20px', color: '#FF9800' }}/>
                    </Tooltip>
                  </span>
                </h3>
                <div className="edit-print-modal__slider">
                  {effects.filter(effect => effect.type === "Marble").map((effect, index) => (
                    <div
                      key={index}
                      onClick={() => this.changeBackground({effect: {name: effect.name, url: effect.link, id: effect.id}})}
                      className={`swatch-container retro-shape ${isEffect ? selectedBackground.effect.url === effect.link ? 'active' : '' : ''}`}
                    >
                      <div
                        className="swatch"
                        style={{ background: `url(${effect.link.replace(".jpeg", "-s.jpeg")})`, backgroundSize: 'cover' }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {this.state.activeTool === "text" && (
              <div className="edit-section">
                <div className="edit-print-modal-title-container">
                  <h3 className="edit-print-modal-sub-heading">
                    Add Text
                    {/* <span style={{fontSize: '10px', background: '#FFEB3B', padding: '5px', marginLeft: '10px'}}>chars:{text?.length} (max:{maxCharacterCount})</span> */}
                  </h3>
                  {/* <div className="apply-all-container">
                    <span>Apply to all?</span>
                    <div className="apply-all-btn inline">
                      <Switch
                        style={{transform: 'scale(1.2)'}}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.props.textSetForAll}
                        onChange={(checked, event) => this.openApplyAllWarning(
                          checked,
                          "Apply to all?",
                          "Are you sure you want to apply the above text to ALL retro prints in this order?",
                          "No",
                          "Yes - Apply Text To All",
                          () => this.changeTextForAll(checked, event, text),
                          this.closeApplyAllWarning
                          )
                        }
                      />    
                    </div>
                  </div> */}
                </div>
                <div className="edit-print-text">
                  <ControlledInput
                    text={text}
                    textColour={textColour}
                    textFont={textFont}
                    onUpdate={(val) => this.changeTextDebounced(val)}
                    maxCharacterCount={maxCharacterCount}
                  />
                  {/* <textarea
                    placeholder="Write text here or leave blank"
                    value={text}
                    onChange={(e) => this.changeText(e.target.value)}
                    style={{
                      textAlign: 'center',
                      color: textColour,
                      fontFamily: textFont,
                      background: textColour === 'rgb(255,255,255)' ? '#7f7f7f' : 'white',
                      fontSize: '20px'
                    }}              
                  />  */}
                </div>
                <div className="edit-print-modal-title-container">
                  <h3 className="edit-print-modal-sub-heading">
                    Font 
                    {/* <span style={{fontSize: '10px', background: '#FFEB3B', padding: '5px', marginLeft: '10px'}}>size:{textSize}</span> */}
                  </h3>
                  {/* <div className="apply-all-container">
                    <span>Apply to all?</span>
                    <div className="apply-all-btn inline">
                      <Switch
                        style={{transform: 'scale(1.2)'}}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.props.fontSetForAll}
                        onChange={(checked, event) => this.openApplyAllWarning(
                          checked,
                          "Apply to all?",
                          "Are you sure you want to apply the selected font to ALL retro prints in this order?",
                          "No",
                          "Yes - Apply Font To All",
                          () => this.changeFontForAll(checked, event),
                          this.closeApplyAllWarning
                          )
                        }
                      />   
                    </div>
                  </div> */}
                </div>
                <div className="edit-print-text-tools">
                  <div className="edit-print-modal__slider">
                    {FONT_FAMILIES.map((option, index) => (
                      <EditorFontOption
                        key={index}
                        fontName={option.name}
                        label={option.displayName}
                        active={option.name === textFont}
                        onClick={() => this.changeTextFont(option.name)}
                      />
                    ))}
                  </div>
                  <div className="edit-print-modal-title-container">
                    <h3 className="edit-print-modal-sub-heading">Text Colour</h3>
                    {/* <div className="apply-all-container">
                      <span>Apply to all?</span>
                      <div className="apply-all-btn inline">
                        <Switch
                          style={{transform: 'scale(1.2)'}}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.props.textColourSetForAll}
                          onChange={(checked, event) => this.openApplyAllWarning(
                            checked,
                            "Apply to all?",
                            "Are you sure you want to apply the selected text colour to ALL retro prints in this order?",
                            "No",
                            "Yes - Apply Colour To All",
                            () => this.changeTextColourForAll(checked, event),
                            this.closeApplyAllWarning
                            )
                          }
                        />   
                      </div>
                    </div> */}
                  </div>
                  <div className="edit-print-modal__slider">
                    {retroColours.map(item => hexToRgb(item.hex)).map((colour, index) => {
                      return (
                        <div
                          onClick={() => this.changeTextColour(colour)}
                          className={`swatch-container ${textColour === colour ? 'active' : ''}`}>
                          <div
                            className={`swatch ${colour === 'White' ? 'bordered' : ''}`}
                            style={{ backgroundColor: colour }}
                          >
                            {/* <span style={{color:colour.textColor}}>{colour.color}</span> */}
                          </div>
                        </div>
                    )})}
                  </div>
                  {/* <div className="apply-all">
                    <div className="apply-all-text">
                      <p>Apply selected text colour to <strong>ALL</strong> retro prints in this order?</p>
                    </div>
                    <div className="apply-all-btn">
                      <Switch
                        style={{transform: 'scale(1.2)'}}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.props.textColourSetForAll}
                        onChange={(checked, event) => this.openApplyAllWarning(
                          checked,
                          "Apply to all?",
                          "Are you sure you want to apply the selected text colour to ALL retro prints in this order?",
                          "No",
                          "Yes - Apply Colour To All",
                          () => this.changeTextColourForAll(checked, event),
                          this.closeApplyAllWarning
                          )
                        }
                      />   
                    </div>
                  </div> */}
              </div>
            </div>
          )}
          </div>
        </MainContent>
        <Footer padded topShadow style={{borderTop: '1px solid #ededed', paddingTop: '10px'}}>
          <div className="pagination-btn-container">
            <Button
              style={{width: '100%', backgroundColor:"#ffffff", border: '2px solid #4b5566', color: '#4b5566'}}
              label={this.state.activeTool === "photo" ? "Back" : "Previous"}
              priority={"primary"}
              theme="dark-blue"
              onClick={() => this.goToPrevious()}
            />
            <Button
              style={{
                width: '100%',
                backgroundColor : this.state.activeTool === "text" ? "#66AD5C" : "#4b5566",
                borderColor : this.state.activeTool === "text" ? "#66AD5C" : "#4b5566"
              }}
              label={this.state.activeTool === "text" ? "Done" : "Next"}
              priority={"primary"}
              theme="dark-blue"
              onClick={() => this.goToNext()}
              loading={this.state.saving}
              loadingLabel={"Saving"}
            />
          </div>
        </Footer>
      </Modal>
    );
  }
}

export default EditPrintModal;
