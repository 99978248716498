import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as craftyClicksApi from "../../lib/apis/craftyclicks";
import * as addressSchemas from "../../form-schemas/address";
//import gtmEvent from "../../utils/gtm";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import Block from "../Block/Block";
//import generateMultilineAddress from "../../lib/generate-multiline-address";
import capitalizeWordsInString from "../../lib/capitalize-words-in-string";
import SweetAlert from "../SweetAlert/SweetAlert";
import { message } from "antd";

function createAddressStringFromDeliveryPoint(deliveryPoint, lookupResults) {
  return [capitalizeWordsInString(deliveryPoint.organisation_name), capitalizeWordsInString(deliveryPoint.line_1), deliveryPoint.line_2, deliveryPoint.line_3, lookupResults.town, lookupResults.postcode]
    .filter(part => part)
    .join(", ");
}

const TextInput = ({ id, label, children, value, onChange, required }) => (
  <div
    className={classNames("form-group", "field", {
      "form-group--has-floating-icon": children,
    })}
  >
    <label htmlFor={id} className="control-label">
      {label}
      {required && "*"}
    </label>
    <div className="form-control-container">
      <input
        type="text"
        className="form-control"
        value={value}
        onChange={onChange}
        id={id}
        label={label}
        required={required}
      />
      {children}
    </div>
  </div>
);

class UKPostcodeLookUpAddressForm extends Component {
  static propTypes = {
    formData: PropTypes.shape({
      first_name: PropTypes.string,
      postcode: PropTypes.string,
    }),
  };

  static defaultProps = {
    submitOnSelection: false,
    straightToEdit: false,
    isEditingInIsolation: false,
  };

  state = {
    postcodeLookupResult: {
      delivery_points: [],
    },
    isPostcodeLookupResultsModalVisible: false,
    isLookingUpResults: false,
    isNoPostcodeResultAlertVisible: false,
    isEditing: this.props.straightToEdit || false,
    hasSelectedAddress: this.props.formData.first_name || false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.straightToEdit && !prevProps.straightToEdit){
      this.setState({
        isEditing: true,
      });
    }

    if (!this.props.straightToEdit && prevProps.straightToEdit){
      this.setState({
        isEditing: false,
      });
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("nextProps", nextProps)
  //   // console.log("this props", this.props)
  //   // console.log("nextState", nextState)
  //   // console.log("this.state", this.state)
  //   return true
  // }

  // componentDidMount() {
  //   gtmEvent({event: "webAppStage", additionalProps: {stage: 'New Address'}})
  // }

  handleSubmit = (options) => {
    // if (e)
    //   e.preventDefault();

    const ignoreValidation = options.ignoreValidation

    if (!this.props.formData.first_name || this.props.formData.first_name.trim() === '') {
      message.warning("Please add a name to this address");
      return false;
    }
    if (!this.props.formData.line1 && !ignoreValidation) {
      message.warning("You must add a line 1 to your address - edit your address and add a line 1 before saving");
      this.editAddress()

    } else{
      this.props.onSubmit();
    }
    
    //this.props.onSubmit();
  };

  handleChange = (key, value) => {
    const newData = {
      formData: {
        ...this.props.formData,
        [key]: value,
      },
    }
    this.props.onChange(newData);
  };

  editAddress = () => {
    this.setState({isEditing: true}, () => {
      this.props.onIsEditing(true)
    });
  }


  isVerified = () => {
    const schema =
      addressSchemas.schema[this.props.formData.country] || addressSchemas.schema.default;

    const requiredFields = schema.required
    const anyMissing = requiredFields.filter(field => {
      return this.props.formData[field] === ""
    });

    if (anyMissing.length > 0){
      const problemField = schema.properties[anyMissing[0]].title
      message.warning(`Please add a ${problemField} to this address`);
      return false;
    } else {
      return true
    }
  }

  doneEditingAddress = () => {

    if(this.isVerified()){
      this.setState({isEditing: false}, () => {
        this.props.onIsEditing(false)
  
        if(this.props.submitOnSelection){
          this.handleSubmit({ignoreValidation: false})
        }
      });
    }
  }

  clearAddress = () => {
    this.setState({
      isEditing: false,
      hasSelectedAddress: false,
    }, () => {
      if(this.props.onReset){
        this.props.onReset({
          formData: {}
        });
      }
      
    });
  }

  getPostcodeLookupResults = async () => {
    this.setState({
      isLookingUpResults: true,
    });
    try {
      const result = await craftyClicksApi.addressLookupByPostcode(this.props.formData.postcode);
      this.setState({
        postcodeLookupResult: result,
        isPostcodeLookupResultsModalVisible: true,
        isLookingUpResults: false,
      });
    } catch (err) {
      this.setState({
        isLookingUpResults: false,
        isNoPostcodeResultAlertVisible: true,
      });
      console.warn("Postcode lookup error:", err);
    }
  };

  closePostcodeLookupResultsModal = () => {
    this.setState({
      isPostcodeLookupResultsModalVisible: false,
    });
  };

  manuallyEnterAddressFromNoResults = () => {
    this.setState({
      isPostcodeLookupResultsModalVisible: false,
      hasSelectedAddress: true,
      isEditing: true,
    });
  }

  handleSelectDeliveryPoint = deliveryPoint => {
    const addressFields = {
      postcode: this.state.postcodeLookupResult.postcode && this.state.postcodeLookupResult.postcode.toUpperCase(),
      city: this.state.postcodeLookupResult.town && this.state.postcodeLookupResult.town.toUpperCase(),
      company_name: deliveryPoint.company_name,
      line1: deliveryPoint.line_1,
      line2: deliveryPoint.line_2,
      line3: deliveryPoint.line_3,
    };

    this.props.onChange({
      formData: {
        ...this.props.formData,
        ...addressFields,
      },
    });

    this.setState({
      isPostcodeLookupResultsModalVisible: false,
      hasSelectedAddress: true,
    }, () => {
      // Use this to go straight to the shipping summary if appropriate...
      if(this.props.submitOnSelection){
        this.handleSubmit({ignoreValidation: true})
      }
      
    });
  };

  handleNoPostcodeResultAlertDismiss = () => {
    this.setState({
      isNoPostcodeResultAlertVisible: false,
    });
  };

  submitForm = () => {
    this.setState({isEditing: false}, () => {
      this.submitButton.node.click();
    });
  };

  doneEditing = () => {
    this.setState({isEditing: false});
  };


  render() {
    const schema =
      addressSchemas.schema[this.props.formData.country] || addressSchemas.schema.default;

    const lookupResultsModal = (
      <Modal
        key="lookup-results-modal"
        isOpen={this.state.isPostcodeLookupResultsModalVisible}
        onClose={this.closePostcodeLookupResultsModal}
        title="Choose Address"
      >
        {this.state.postcodeLookupResult.delivery_points.map((deliveryPoint, index) => (
          <Block
            key={index}
            onClick={() => this.handleSelectDeliveryPoint(deliveryPoint)}
            hasRightArrow
          >
            {createAddressStringFromDeliveryPoint(deliveryPoint, this.state.postcodeLookupResult)}
          </Block>
        ))}
          <Block
            key={'not-listed'}
            onClick={this.manuallyEnterAddressFromNoResults}
            hasRightArrow
          >
            Not listed? Add manually...
          </Block>
      </Modal>
    );

    const hasFirstName = this.props.formData.first_name !== "" && this.props.formData.first_name
    const hasPostCode = this.props.formData.postcode !== "" && this.props.formData.postcode
    // console.log("hasFirstName", hasFirstName)
    // console.log("hasPostCode", hasPostCode)
    // console.log("formData", this.props.formData)
    // console.log("this.state.isEditing", this.state.isEditing)
    const enableLookupButton = hasFirstName && hasPostCode

    return [
      lookupResultsModal,
      <SweetAlert
        type="error"
        key="no-postcode-results-alert"
        isOpen={this.state.isNoPostcodeResultAlertVisible}
        title="No Addresses Found"
        text="We can't find any addresses for that postcode, please make sure the postcode you have entered is a valid UK postcode."
        confirmButtonText="OK"
        showCancelButton={false}
        onConfirm={this.handleNoPostcodeResultAlertDismiss}
      />,
      <form className="form" key="form" onSubmit={() => this.handleSubmit({ignoreValidation: false})}>
        {!this.state.isEditing && (
          <div>
            <div className="form-group">
              <label htmlFor="uk-address-form-name" className="control-label">
                First Name*
              </label>
              <input
                id="uk-address-form-name"
                className="form-control"
                type="text"
                required
                value={this.props.formData.first_name || ""}
                onChange={(e) => {
                  //e.persist();
                  //const caretStart = e.target.selectionStart;
                  //const caretEnd = e.target.selectionEnd;
                  this.handleChange("first_name", e.target.value);
                  //setTimeout(() => { e.target.setSelectionRange(caretStart, caretEnd)}, 500);
                }}
              />
              <br/>
              <label htmlFor="uk-address-form-name" className="control-label">
                Last Name
              </label>
              <input
                id="uk-address-form-name"
                className="form-control"
                type="text"
                autoComplete="off"
                required
                value={this.props.formData.last_name || ""}
                onChange={(e) => {
                  //e.persist();
                  //const caretStart = e.target.selectionStart;
                  //const caretEnd = e.target.selectionEnd;
                  this.handleChange("last_name", e.target.value);
                  //setTimeout(() => { e.target.setSelectionRange(caretStart, caretEnd)}, 500);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="uk-address-form-postcode" className="control-label">
                Postcode*
              </label>
              <div className="form-control-container">
                <input
                  id="uk-address-form-postcode"
                  className="form-control"
                  type="text"
                  required
                  onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                  value={this.props.formData.postcode?.toUpperCase() || ""}
                  onChange={e => this.handleChange("postcode", e.target.value.toUpperCase())}
                />
              </div>
              <br/>
              <Button
                icon={this.state.isLookingUpResults ? null : "search"}
                theme="dark-blue"
                onClick={this.getPostcodeLookupResults}
                loading={this.state.isLookingUpResults}
                loadingLabel={this.state.isLookingUpResults ? "Searching" : null}
                label="Find Address"
                disabled={!enableLookupButton}
                block
              />
            </div>
            <Button
              type="submit"
              label="Submit"
              buttonRef={el => (this.submitButton = el)}
              className="hidden"
            />
          </div>
        )}
        {!this.state.hasSelectedAddress && (
          <p
            style={{
              opacity: 0.7,
              lineHeight: '18px',
              fontSize: 12,
            }}
          >
            Search for your address by entering a postcode above
          </p>
        )}
        {/* {this.state.hasSelectedAddress && !this.state.isEditing && (
          <div>
            <p>Your selected address:</p>
            <p className="selected-address">
              {generateMultilineAddress({
                ...this.props.formData,
                name: null,
              }).join("\n")}
              <br/>
              <br/>
              <Button
                size="small"
                priority="secondary"
                theme="success"
                onClick={this.editAddress}
                label="Edit"
              />
              <Button
                size="small"
                label="Use This Address"
                type="submit"
              />
            </p>
            <br />
            {/* <p>
              Tap &ldquo;<span className="text-primary">Save</span>&rdquo; to apply and save the
              address
            </p>
          </div>
        )} */}
        {this.state.isEditing && this.state.hasSelectedAddress &&  (
          <div>
            {/* <input
              id="uk-address-form-line1"
              className="form-control"
              type="text"
              required
              value={this.props.formData.line1 || ""}
              onChange={e => this.handleChange("line1", e.target.value)}
            /> */}
            <TextInput
              id="name"
              label={schema.properties.first_name.title}
              value={this.props.formData.first_name}
              onChange={e => this.handleChange("first_name", e.target.value)}
              required={schema.required.includes("first_name")}
            />
            <TextInput
              id="last_name"
              label={schema.properties.last_name.title}
              value={this.props.formData.last_name}
              onChange={e => this.handleChange("last_name", e.target.value)}
              required={schema.required.includes("last_name")}
            />
            <TextInput
              id="company_name"
              label={schema.properties.company_name.title}
              value={this.props.formData.company_name}
              onChange={e => this.handleChange("company_name", e.target.value)}
              required={schema.required.includes("company_name")}
            />
            <TextInput
              id="line1"
              label={schema.properties.line1.title}
              value={this.props.formData.line1}
              onChange={e => this.handleChange("line1", e.target.value)}
              required={schema.required.includes("line1")}
            />
            <TextInput
              id="line2"
              label={schema.properties.line2.title}
              value={this.props.formData.line2}
              onChange={e => this.handleChange("line2", e.target.value)}
              required={schema.required.includes("line2")}
            />
            <TextInput
              id="line3"
              label={schema.properties.line3.title}
              value={this.props.formData.line3}
              onChange={e => this.handleChange("line3", e.target.value)}
              required={schema.required.includes("line3")}
            />
            <TextInput
              id="city"
              label={schema.properties.city.title}
              value={this.props.formData.city}
              onChange={e => this.handleChange("city", e.target.value)}
              required={schema.required.includes("city")}
            />
            <TextInput
              id="county"
              label={schema.properties.county.title}
              value={this.props.formData.county}
              onChange={e => this.handleChange("county", e.target.value)}
              required={schema.required.includes("county")}
            />
            <TextInput
              id="postcode"
              label={schema.properties.postcode.title}
              value={this.props.formData.postcode}
              onChange={e => this.handleChange("postcode", e.target.value)}
              required={schema.required.includes("postcode")}
            />
            <p>* Required</p>
            <Button
              onClick={this.doneEditingAddress}
              block
              theme="dark-blue"
              label={this.props.isEditingInIsolation ? "Update" : "Update"}
            />
            <Button
              onClick={this.clearAddress}
              block
              theme="dark-blue"
              priority="secondary"
              label="Clear Address"
            />
          </div>
        )}
      </form>,
    ];
  }
}

export default UKPostcodeLookUpAddressForm;
