import { types as basketTypes } from "../ducks/basket";
import generateS3AssetUrlFromKey from "../../lib/generate-s3-asset-url-from-key";
import { preloadImage } from "../../lib/image-preloader";

export default store => next => action => {
  if (
    action.type === basketTypes.RENDER_LAYER &&
    action.payload &&
    action.payload.data &&
    action.payload.data.layerId === "FRONT_PREVIEW"
  ) {
    //console.log("Preloading render");
    preloadImage(generateS3AssetUrlFromKey(action.payload.data.s3_key));
    // try{
    //   await preloadImage(generateS3AssetUrlFromKey(action.payload.data.s3_key));
    // } catch (err) {
    //   console.log("Error while preloading image:", err);
    // } 
  }

  return next(action);
};
