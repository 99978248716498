import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routeCreators } from "../../../lib/routes";
import SweetAlert from "../../../components/SweetAlert/SweetAlert";
import CheckoutGuestOrCustomer from "./CheckoutGuestOrCustomer";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import { selectors as basketSelectors } from "../../../store/ducks/basket";
import { selectors as authSelectors, actions as authActions } from "../../../store/ducks/auth";
import { actions as uiActions } from "../../../store/ducks/ui";
import gtmEvent from "../../../utils/gtm";
import { sharedShippingAddress } from "../../../data/products"

class CheckoutGuestOrCustomerContainer extends React.Component {
  state = {
    alert: null,
  };

  componentDidMount(){
    if(this.props.items.size === 0){
      this.props.goToBasket()
    }
    if (this.props.hasChosenGuestRoute && this.props.hasCompletedCheckoutSteps){ 
      this.goToShippingIfNeeded()
    }

    if (this.props.isSignedIn){
      console.log("Is signed in proceeding to next step")
      this.goToShippingIfNeeded()
    }
    gtmEvent({event: "webAppStage", additionalProps: {stage: 'Checkout - Guest Or Customer'}})
  }

  goToShippingIfNeeded = () => {
    const checkoutShippingNeeded = this.props.items.some(item => sharedShippingAddress(item.get('productTypeId')))

    if (checkoutShippingNeeded){
      this.props.goToShipping()
    } else{
      this.props.goToContactDetails()
    }
    
  }

  handleChosenGuestRoute = () => {
    this.props.setHasChosenGuestRoute(true)
    this.goToShippingIfNeeded()
  }

  handleChosenSignInRoute = () => {
    this.props.setHasChosenGuestRoute(false)
    this.props.goToCheckoutSignIn()
  }

  handleChosenSignUpRoute = () => {
    this.props.setHasChosenGuestRoute(false)
    this.props.goToCheckoutSignUp()
  }

  render() {
    return [
      <SweetAlert
        key="alert"
        isOpen={this.state.alert !== null}
        onCancel={this.clearAlert}
        {...(this.state.alert || {})}
      />,
      <Header
        key="top-header"
        theme="grey"
        title="Checkout"
        leftAction={
          <Button theme="muted" priority="tertiary" label="Close" onClick={this.props.goToBasket}/>
        }
      />,
      <CheckoutGuestOrCustomer
        key="checkout-or-customer"
        items={this.props.items}
        currency={this.props.currency}
        isSignedIn={this.props.isSignedIn}
        guestDetails={this.props.guestDetails}
        chosenGuestRoute={this.handleChosenGuestRoute}
        chosenSignInRoute={this.handleChosenSignInRoute}
        chosenSignUpRoute={this.handleChosenSignUpRoute}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  currency: basketSelectors.getCurrency(state),
  items: basketSelectors.getItems(state),
  orderSummary: basketSelectors.getOrderSummary(state),
  isSignedIn: Boolean(authSelectors.getUser(state)),
  user: authSelectors.getUser(state),
  hasGuestDetails: authSelectors.hasGuestDetails(state),
  guestDetails: authSelectors.guestDetails(state),
  guestToken: authSelectors.getGuestToken(state),
  hasGuestToken: Boolean(authSelectors.getGuestToken(state)),
  hasChosenGuestRoute: Boolean(authSelectors.hasChosenGuestRoute(state)),
  hasCompletedCheckoutSteps: Boolean(basketSelectors.getHasCompletedCheckoutSteps(state)),
});

const mapDispatchToProps = dispatch => ({
  goToBasket: () => dispatch(push(routeCreators.basket())),
  showGuestCaptureOrAuthModal: (options) => dispatch(uiActions.showGuestCaptureOrAuthModal(options)),
  goToShipping: () => dispatch(push(routeCreators.checkoutShipping())),
  setHasChosenGuestRoute: (hasChosenGuestRoute) => dispatch(authActions.setHasChosenGuestRoute(hasChosenGuestRoute)),
  showAuthModal: () => dispatch(uiActions.showAuthModal()),
  goToCheckoutSignIn: () => dispatch(push(routeCreators.checkoutSignIn())),
  goToCheckoutSignUp: () => dispatch(push(routeCreators.checkoutSignUp())),
  goToContactDetails: () => dispatch(push(routeCreators.checkoutContactDetails())),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutGuestOrCustomerContainer);
